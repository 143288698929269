import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import InnerLayout from "../../../../components/layouts/innerLayout/innerLayout";
import InnerContainer from "../../../../components/layouts/innerContainer/innerContainer";
import VersionHistoryBtn from "../../../../components/buttons/versionHistoryBtn";
import RejectBtn from "../../../../components/buttons/rejectBtn";
import ApproveBtn from "../../../../components/buttons/approveBtn";
import errorToast from "../../../../components/utilits/errorToast";
import successToast from "../../../../components/utilits/successToast";
import moment from "moment";

import DownloadIcon from "../../../../assets/icons/downloadIcon";
import LoadAndError from "../../../../components/utilits/LoadAndError";
import {
  approveHomeAction,
  rejectHomeAction,
} from "../../../../redux/actions/homeActions";
import HomeCounterTable from "../../../../components/static-pages/home-page/tables/homeCounterTable";
import HomeCardsTable from "../../../../components/static-pages/home-page/tables/homeCardsTable";
import { approvalsViewCampaignAction, approveCampaignAction, rejectCampaignAction } from "../../../../redux/actions/campaignActions";

const CampaignApprovalsDetails = () => {
  const navigate = useNavigate();
  const [howItWorksData, setHowItWorksData] = useState({});
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [operation, setOperation] = useState("");
  const params = useParams();
  const [howItWorksDataId, SetHowItWorksDataId] = useState({ id: "" });

  useEffect(() => {
    setLoading(true);
    dispatch(approvalsViewCampaignAction(params.id, onSuccess, onError));
  }, [params.id]);
  const onSuccess = (data) => {
    SetHowItWorksDataId(data);
    setOperation(
      data?.data?.new_data == null
        ? "Delete"
        : data?.data?.new_data && data?.data?.old_data
          ? "Edit"
          : data?.data?.old_data == null
            ? "Create"
            : ""
    );
    setHowItWorksData(
      data?.data?.new_data === null
        ? data?.data?.old_data
        : data?.data?.new_data
    );
    console.log(data.data);
    setLoading(false);
    setError(false);
  };
  const onError = (data) => {
    errorToast(data.message);
    console.log(data, "error");
    setLoading(false);
    setError(true);
  };

  // Approve Notify Me
  const [updated, setUpdated] = useState(false);

  const approveHowItWorksFn = () => {
    dispatch(approveCampaignAction(params.id, onApproveSuccess, onApproveError));
  };

  const onApproveSuccess = (data) => {
    navigate("/static-pages/campaign/approvals");
    successToast(data.message);
    setLoading(false);
    setError(false);
  };
  const onApproveError = (data) => {
    console.log(data);
    errorToast(data.data + " " + data.message);
    setLoading(false);
    setError(true);
  };
  // Reject  Notify Me Api
  const rejectHowItWorksFn = () => {
    dispatch(rejectCampaignAction(params.id, onRejectSuccess, onRejectError));
  };

  const onRejectSuccess = (data) => {
    successToast(data.message);
    navigate("/static-pages/campaign/approvals");
    setLoading(false);
    setError(false);
  };
  const onRejectError = (data) => {
    console.log(data);
    errorToast(data.data + " " + data.message);
    setLoading(false);
    setError(true);
  };

  function ViewItemGenerator(title, details) {
    return (
      <>
        <span className="dark_grey_color">{title}</span>
        <p className="dark_title_color">{details}</p>
      </>
    );
  }
  const data1 = [
    {
      heading: "Approval ID",
      title: howItWorksDataId?.data?.id,
    },
  ];

  const buttonList = [
    <VersionHistoryBtn link={`/static-pages/campaign/version-history`} />,
    <ApproveBtn approveFn={approveHowItWorksFn} />,
    <RejectBtn showModal={rejectHowItWorksFn} />,
  ];
  return (
    <InnerLayout buttons={buttonList}>
      <div className="user_view_container mb-4">
        <div className="user_view_container mb-4">
          <InnerContainer error={error} oldData={howItWorksData?.old_data} newData={howItWorksData?.new_data}>
            <LoadAndError loader={loading} error={error}>
              <div className="d-flex flex-column flex-lg-row gap-4 fs_14 py-3 px-3 px-lg-5">
                <div className="col-12 col-lg-6 pe-0 pe-lg-5">
                {data1.map((item) => (
                  <div className="mb-2">
                    <h5 className=" fs_13 fn_Montserrat fw_500 m-0">
                      {item.heading}
                    </h5>
                    <>
                      {" "}
                      <p
                        className={` fs_13 fn_Nunito fw_500  ${
                          item.heading === "LinkedIn URL" ||
                          item.heading === "User ID"
                            ? "secondary_color"
                            : "dark_title_color"
                        } `}
                      >
                        {item.title}
                      </p>
                    </>
                  </div>
                ))}
                <div>
                    {ViewItemGenerator(
                      "Campaign Landing Page ID",
                      howItWorksData?.id
                    )}
                  </div>
                  <div>
                    {ViewItemGenerator(
                      "Navbar Title",
                      howItWorksData?.header_title
                    )}
                  </div>
                  <div>
                    {ViewItemGenerator(
                      "Header title",
                      howItWorksData?.section1_title
                    )}
                  </div>
                  <div>
                    {ViewItemGenerator(
                      "Header description",
                      howItWorksData?.section1_description
                    )}
                  </div>
                  <div>
                    {ViewItemGenerator(
                      "Header Button Text",
                      howItWorksData?.section1_button
                    )}
                  </div>
                  <div>
                    {ViewItemGenerator(
                      "Card One Title",
                      howItWorksData?.card_image1_title
                    )}
                  </div>
                  <div className="mb-3">
                    <span className="dark_grey_color fw_500 fs_16">Card One Image</span>{" "}
                    <div className="blog_create_image_container mt-2 position-relative d-flex  justify-content-center align-items-center">
                      <img
                        src={howItWorksData?.section1_card_image1}
                        className={"user-edit-profile-picture"}
                      />
                      <button
                        type="button"
                        className="position-absolute border-0 bg-white d-flex align-items-center fs_10 top-0 end-0"
                      >
                        <a
                          href={howItWorksData?.section1_card_image1}
                          download="file"
                        ></a>
                        <DownloadIcon width={8} />
                        <span className="ms-1">Download</span>
                      </button>
                    </div>
                  </div>
                  <HomeCardsTable
                    formikValues={howItWorksData}
                    name={"card1_description"}
                    editable={false}
                  />




                  <div>
                    {ViewItemGenerator(
                      "Card Two Title",
                      howItWorksData?.card_image2_title
                    )}
                  </div>
                  <div className="mb-3">
                    <span className="dark_grey_color fw_500 fs_16">Card Two Image</span>{" "}
                    <div className="blog_create_image_container mt-2 position-relative d-flex  justify-content-center align-items-center">
                      <img
                        src={howItWorksData?.section1_card_image2}
                        className={"user-edit-profile-picture"}
                      />
                      <button
                        type="button"
                        className="position-absolute border-0 bg-white d-flex align-items-center fs_10 top-0 end-0"
                      >
                        <a
                          href={howItWorksData?.section1_card_image2}
                          download="file"
                        ></a>
                        <DownloadIcon width={8} />
                        <span className="ms-1">Download</span>
                      </button>
                    </div>
                  </div>
                  <HomeCardsTable
                    formikValues={howItWorksData}
                    name={"card2_description"}
                    editable={false}
                  />


                  <div>
                    {ViewItemGenerator(
                      "Card Two Title",
                      howItWorksData?.card_image3_title
                    )}
                  </div>
                  <div className="mb-3">
                    <span className="dark_grey_color fw_500 fs_16">Card Two Image</span>{" "}
                    <div className="blog_create_image_container mt-2 position-relative d-flex  justify-content-center align-items-center">
                      <img
                        src={howItWorksData?.section1_card_image3}
                        className={"user-edit-profile-picture"}
                      />
                      <button
                        type="button"
                        className="position-absolute border-0 bg-white d-flex align-items-center fs_10 top-0 end-0"
                      >
                        <a
                          href={howItWorksData?.section1_card_image3}
                          download="file"
                        ></a>
                        <DownloadIcon width={8} />
                        <span className="ms-1">Download</span>
                      </button>
                    </div>
                  </div>
                  <HomeCardsTable
                    formikValues={howItWorksData}
                    name={"card3_description"}
                    editable={false}
                  />

                  <div>
                    {ViewItemGenerator(
                      "Opportunity Section Title",
                      howItWorksData?.opportunities_title
                    )}
                  </div>
                  <div>
                    {ViewItemGenerator(
                      "Opportunity Subtitle",
                      howItWorksData?.opportunities_subtitle
                    )}
                  </div>
                  <div>
                    {ViewItemGenerator(
                      "Opportunity Button Text",
                      howItWorksData?.opportunities_button
                    )}
                  </div>
                  {/* <HowItWorksReasonTable
                  name="reasons"
                  formikValues={howItWorksData}
                  editable={false}
                /> */}
                  <div>
                    {ViewItemGenerator(
                      "Counter Title",
                      howItWorksData?.section2_title
                    )}
                  </div>
                  <div>
                    {ViewItemGenerator(
                      "Counter Description",
                      howItWorksData?.section2_description
                    )}
                  </div>
                  <HomeCounterTable
                    formikValues={howItWorksData}
                    name={"section2_counter"}
                    editable={false}
                  />

                  <div>
                    <span className="dark_grey_color">Operation Type</span>
                    <p className="dark_title_color">{operation} </p>
                  </div>


                </div>
              </div>
            </LoadAndError>
          </InnerContainer>
        </div>
      </div>
    </InnerLayout>
  );
};

export default CampaignApprovalsDetails;
