import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import DeleteBtn from "../../../../components/buttons/deleteBtn";
import EditBtn from "../../../../components/buttons/editBtn";
import VersionHistoryBtn from "../../../../components/buttons/versionHistoryBtn";
import InnerContainer from "../../../../components/layouts/innerContainer/innerContainer";
import InnerLayout from "../../../../components/layouts/innerLayout/innerLayout";
import errorToast from "../../../../components/utilits/errorToast";
import { viewContactAction } from "../../../../redux/actions/contactUsActions";
import * as opporTypeActions from "../../../../redux/actions/opportunityTypeActions";
import moment from "moment";
import LoadingSpinnerTable from "../../../../components/utilits/loadingSpinnerTable";
import LoadAndError from "../../../../components/utilits/LoadAndError";
const ContactUsView = () => {
    const params = useParams()
    const dispatch = useDispatch()
    const [contactUs, SetContactUs] = useState({ id: "", name: "", createdAt: "", updatedAt: "" })
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    useEffect(() => {
        setLoading(true)
        dispatch(
            viewContactAction(params.id, onSuccess, onError)
        );
    }, [params.id])
    const onSuccess = (data) => {
        SetContactUs(data.data);
        setLoading(false);
        setError(false);
    };
    const onError = (data) => {
        errorToast(data.message)
        setLoading(false)
        setError(true);
    };
    const buttonList = [
        <VersionHistoryBtn link={`/static-pages/contact-us-query/version-history`} />,
        contactUs.editable && <>
            <DeleteBtn link={`/static-pages/contact-us-query/edit/${params.id}`} />,
        </>
    ];
    const data = [
        {
            heading: "Contact Us Query ID",
            title: contactUs.id,
        },
        {
            heading: "Query Name",
            title: contactUs.query_name,
        },

        {
            heading: "Phone Number",
            title: contactUs.phone_number
        },

        {
            heading: "Approved At",
            title: contactUs.createdAt === null ? "-" : moment(contactUs.createdAt
            ).format("DD-MM-YYYY HH:mm:ss"),
        },
        {
            heading: "Updated At",
            title: contactUs.updatedAt === null ? "-" : moment(contactUs.updatedAt
            ).format("DD-MM-YYYY HH:mm:ss"),
        },
    ];

    return (
        <InnerLayout buttons={buttonList}>
            <div className="opportunity-type-container">
                <InnerContainer>
                    {loading ? (
                        <LoadingSpinnerTable />
                    ) : (
                        <LoadAndError loader={loading} error={error}>
                            <div className="pt-3  ps-4 ps-lg-4 ms-3">
                                {data.map((item, index) => (
                                    <div className="mb-2">
                                        <h5 className="dark_grey_color fs_13 fn_Montserrat fw_500 m-0">
                                            {item.heading}
                                        </h5>
                                        <p
                                            className={` fs_13 fn_Nunito fw_500 dark_title_color  `}>
                                            {item.title}
                                        </p>
                                    </div>
                                ))}
                            </div>
                        </LoadAndError>
                    )}
                </InnerContainer>
            </div>


        </InnerLayout>
    );
}

export default ContactUsView