import React, { useEffect, useRef, useState } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import InnerLayout from "../../../../components/layouts/innerLayout/innerLayout";
import InnerContainer from "../../../../components/layouts/innerContainer/innerContainer";
import SelectFields from "../../../../components/form/selectFields/selectFields";
import SaveBtn from "../../../../components/buttons/saveBtn";
import InputField from "../../../../components/form/inputFields/inputField";
import ErrorMsg from "../../../../components/form/errorMsg";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  editFaq,
  faqOptions,
  viewFaqDetail,
} from "../../../../redux/actions/faqAction";
import successToast from "../../../../components/utilits/successToast";
import errorToast from "../../../../components/utilits/errorToast";
import {
  editStatic,
  staticSubmit,
  viewStaticDetail,
} from "../../../../redux/actions/staticAction";
import LoadingSpinnerTable from "../../../../components/utilits/loadingSpinnerTable";
import CreateTable from "../../../../components/opportunity/opportunties/tables/opporttunitiesCreateTable";
import OpportunitiesContentSection from "../../../../components/opportunity/opportunties/editSection/opportunitiesContentSection";
import * as opportunityEditorActions from "../../../../redux/actions/opportunityEditorActions";
import LoadAndError from "../../../../components/utilits/LoadAndError";
import SubmitBtn from "../../../../components/buttons/submit";
const StaticEdit = () => {
  const formRef = useRef();
  const navigate = useNavigate();
  const params = useParams();
  const dispatch = useDispatch();
  const [faqDetails, setFaqDetails] = useState([]);
  const [submitData, setSubmitData] = useState();
  const [notifyMeData, setNotifyMeData] = useState();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  useEffect(() => {
    setLoading(true);
    dispatch(viewStaticDetail(params.id, onSuccess, onError));
  }, []);

  const onSuccess = (data) => {
    console.log(data.data);
    setFaqDetails(data.data);
    console.log(data.data.content.length);
    dispatch(
      opportunityEditorActions.updateOppJson(
        data.data.content.length > 0 ? data.data.content : []
      )
    );
    console.log("SUCCESS");
    setLoading(false);
    setError(false);
  };
  const onError = (data) => {
    console.log("ERROR");
    setLoading(false);
    setError(true);
  };

  const initialValues = {
    name: faqDetails?.screen_name,
    content: faqDetails.content?.content_name,
  };

  const interestEditSchema = Yup.object().shape({
    name: Yup.string().trim().required("Required"),
    // content: Yup.string().trim().required("Required"),
  });

  const handleSubmit = () => {
    if (formRef.current) {
      formRef.current.handleSubmit();
    }
  };
  const onSubmit = (values, { resetForm }) => {
    console.log(values);
    dispatch(
      editStatic(
        faqDetails.id,
        {
          screen_name: values.name,
          content: opportunityJsonReducer.data,
        },
        onEditSuccess,
        onEditError
      )
    );
  };
  const onEditSuccess = (data) => {
    setNotifyMeData(data.data);
    successToast(data.message);
    setSubmitData(data.data.id);
    setSaveHide(true);
    // navigate("/static-pages/static-screen");
    setLoading(false);
    setError(false);
  };
  const onEditError = (data) => {
    console.log(data);
    errorToast(data.message);
    setLoading(false);
    setError(true);
  };
  // faq options
  const [opportunityType, setOpportunityType] = useState([]);
  useEffect(() => {
    dispatch(faqOptions(onNotifyMeSuccess, onNotifyMeError));
  }, []);
  const opportunityTypeOptions = [];
  const onNotifyMeSuccess = (data) => {
    data?.data.map((item, index) => {
      opportunityTypeOptions[index] = { value: item.name, label: item.name };
    });
    console.log(opportunityTypeOptions);
    setOpportunityType(opportunityTypeOptions);
    console.log("SUCCESS");
  };
  const onNotifyMeError = (data) => {
    console.log("ERROR");
  };
  const opportunityJsonReducer = useSelector(
    (state) => state.opportunityJsonReducer
  );
  function addNewSection() {
    dispatch(opportunityEditorActions.addSection());
  }
  function deleteSection(index) {
    console.log("deleteSection", index);
    dispatch(opportunityEditorActions.deleteSection(index));
  }

  //on submit api
  console.log(submitData);
  const [saveHide, setSaveHide] = useState(false);

  const submitFnc = () => {
    dispatch(staticSubmit(submitData, onSubmitSuccess, onSubmitError));
  };
  const onSubmitSuccess = (data) => {
    console.log(data);
    navigate("/static-pages/static-screen");
    successToast(data.message);
  };
  const onSubmitError = (data) => {
    console.log("ERROR==================", data);
    errorToast(data.message);
  };

  const buttonList = [
    !saveHide && <SaveBtn submitFn={handleSubmit} loader={loading} />,
    <SubmitBtn submitFn={submitFnc} saveHide={saveHide} />,
  ];
  return (
    <InnerLayout buttons={buttonList}>
      <div className="user_view_container">
        <InnerContainer>
          {loading ? (
            <LoadingSpinnerTable />
          ) : (
            <LoadAndError loader={loading} error={error}>
              <div className="row mt-2">
                <div className="col-12 col-sm-12 col-md-9 col-lg-9 col-xl-7">
                  <div className="row ps-1 ms-1 ps-lg-4 ms-lg-1">
                    <div className="ps-2  pt-3">
                      <h5 className="dark_grey_color text-nowrap fs_13 fn_Montserrat fw_500">
                        STATIC ID
                      </h5>
                      <p
                        className={` fs_13 fn_Nunito text-nowrap fw_500 secondary_color`}
                      >
                        {faqDetails.id}
                      </p>

                      <div className="ps-2  pt-3 mx-5">
                        <h5 className="dark_grey_color text-nowrap fs_13 fn_Montserrat fw_500">
                          STATIC ID
                        </h5>
                        <p
                          className={` fs_13 fn_Nunito text-nowrap fw_500 secondary_color`}
                        >
                          {faqDetails.id}
                        </p>

                        <Formik
                          innerRef={formRef}
                          initialValues={initialValues}
                          onSubmit={onSubmit}
                          enableReinitialize={true}
                          validationSchema={interestEditSchema}
                        >
                          {({
                            formik,
                            handleChange,
                            values,
                            setFieldValue,
                          }) => {
                            return (
                              <Form className="">
                                <div className="  col-12 col-sm-8 col-lg-6 col-xxl-4">
                                  <InputField
                                    id={"name"}
                                    label={"Screen Name"}
                                    type={"text"}
                                    placeholder={"Enter Screen Name"}
                                    name={"name"}
                                  />
                                  {/* <label
                              id="content"
                              className="mb-2 dark_grey_color fn_Montserrat fw_500 fs_14 ps-2"
                            >
                              Answer
                            </label>
                            <Field name={"content"}>
                              {({ field }) => (
                                <textarea
                                  placeholder="Enter Answer"
                                  id="content"
                                  {...field}
                                  type="text"
                                  className={
                                    "w-100 opportunities_create_summary_text_area p-2 "
                                  }
                                ></textarea>
                              )}
                            </Field>
                            <ErrorMsg name={"content"} /> */}
                                </div>
                                {/* <CreateTable
                      data={opportunityJsonReducer?.data}
                      editContent={false}
                      tableTitle={"Content"}
                      containerText="Add content"
                      link={`/blog/edit/content-edit/`}
                    /> */}
                              </Form>
                            );
                          }}
                        </Formik>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="px-4 px-lg-5">
                  {opportunityJsonReducer.data.map((item, index) => {
                    return (
                      <OpportunitiesContentSection
                        key={item.id}
                        index={index}
                        // updateSectionData={handleSectionSubmit}
                        numberOfSection={opportunityJsonReducer.data.length}
                        deleteSection={deleteSection}
                        addNewSection={addNewSection}
                      />
                    );
                  })}
                </div>
              </div>
            </LoadAndError>
          )}
        </InnerContainer>
      </div>
    </InnerLayout>
  );
};

export default StaticEdit;
export const faqName = [
  { value: "Type1", label: "Type1" },
  { value: "Type2", label: "Type2" },
  { value: "Type3", label: "Type3" },
  { value: "Type4", label: "Type4" },
];
