import React, { useRef, useState, useEffect } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import InnerLayout from "../../../../components/layouts/innerLayout/innerLayout";
import InnerContainer from "../../../../components/layouts/innerContainer/innerContainer";
import SelectFields from "../../../../components/form/selectFields/selectFields";
import ArrowDownIcon from "../../../../assets/icons/arrowdown";
import { useNavigate } from "react-router-dom";
import SaveBtn from "../../../../components/buttons/saveBtn";
import DeleteBtn from "../../../../components/buttons/deleteBtn";
import InputField from "../../../../components/form/inputFields/inputField";
import successToast from "../../../../components/utilits/successToast";
import errorToast from "../../../../components/utilits/errorToast";
import { useDispatch } from 'react-redux';
import { createFaqCategory } from "../../../../redux/actions/faqCategoryActions";
import SubmitBtn from "../../../../components/buttons/submit";

const FaqCategoryCreate = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const formRef = useRef();
  const amountRegex = /^[0-9]*$/s;
  const initialValues = {
    faqCategoryName: "",
  };

  const interestEditSchema = Yup.object().shape({
    faqCategoryName: Yup.string().trim().required("Required"),
  });

  const handleSubmit = () => {
    if (formRef.current) {
      formRef.current.handleSubmit();
    }
  };
  const onSubmit = (values, { resetForm }) => {
    console.log(values);
    dispatch(createFaqCategory({
      "name": values.faqCategoryName
    },
      onSuccess, onError));

  };

  const onSuccess = (data) => {
    console.log(data?.data?.id);
    setLoading(false)
    successToast(data.message)
    navigate(`/static-pages/faq-category/edit/${data?.data?.id}`)
  };
  const onError = (data) => {
    setLoading(false)
    errorToast(data.message)
  };




  // const submitFnc = () => {
  //   dispatch(dispatchAction
  //     (

  //       onSubmitSuccess,
  //       onSubmitError,
  //     ));
  // };
  // const onSubmitSuccess = (data) => {
  //   console.log(data);
  // successToast(data.message)
  // };
  // const onSubmitError = (data) => {
  //   console.log("ERROR==================", data);
  //   errorToast("Failed to export data")

  // };












  const buttonList = [<SaveBtn submitFn={handleSubmit} />,
    //  <SubmitBtn submitFn={submitFnc} />
  ];
  return (
    <InnerLayout buttons={buttonList}>
      <div className="user_view_container">
        <InnerContainer>
          <div className="row mt-2">
            <div className="col-12 col-sm-12 col-md-9 col-lg-9 col-xl-7">
              <div className="row ps-1 ms-1 ps-lg-4 ms-lg-1">
                <div className="ps-2  pt-3">


                  <Formik
                    innerRef={formRef}
                    initialValues={initialValues}
                    onSubmit={onSubmit}
                    enableReinitialize={true}
                    validationSchema={interestEditSchema}
                  >
                    {({ formik, handleChange, values, setFieldValue }) => {
                      return (
                        <Form className="">
                          <div className="   express-form-width">
                            <InputField
                              id={"FAQ Category Name"}
                              label={"FAQ Category Name"}
                              type={"text"}
                              placeholder={"Enter FAQ Category Name here"}
                              name={"faqCategoryName"}
                            />
                          </div>
                        </Form>
                      );
                    }}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </InnerContainer>
      </div>
    </InnerLayout>
  );
};

export default FaqCategoryCreate;
