import React, { useEffect, useRef, useState } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import InputField from "../../../../components/form/inputFields/inputField";
import SelectFields from "../../../../components/form/selectFields/selectFields";
import SaveBtn from "../../../../components/buttons/saveBtn";
import InnerLayout from "../../../../components/layouts/innerLayout/innerLayout";
import InnerContainer from "../../../../components/layouts/innerContainer/innerContainer";
import { useDispatch } from "react-redux";
import errorToast from "../../../../components/utilits/errorToast";
import { useNavigate } from "react-router-dom";
import successToast from "../../../../components/utilits/successToast";
import UploadIcon from "../../../../assets/icons/upload";
import CloseIcon from "../../../../assets/icons/closeIcon";
import ErrorMsg from "../../../../components/form/errorMsg";
import { createMediaRecAction } from "../../../../redux/actions/mediaRecogonitionActions";
import { uploadAddressProofFrontImageAction } from "../../../../redux/actions/userAction";
import LoadingSpinnerTable from "../../../../components/utilits/loadingSpinnerTable";

const MediaRecogonitionCreate = () => {
  const [file, setFile] = useState(null);
  const [fileTwo, setFileTwo] = useState(null);
  const [loading, setLoading] = useState(false);
  const nameregex = /^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$/;
  const [deskImageLink, setDeskImageLink] = useState({
    img: "",
    error: false,
  });
  const [mobImageLink, setMobImageLink] = useState({
    img: "",
    error: false,
  });
  const formRef = useRef();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [roleTypesOptions, setRoleTypesOptions] = useState([]);

  const initialValues = {
    profileImageFirst: "",
    profileImageSecond: "",
    name: "",
    title: "",
    url: "",
    description: "",
  };
  const loginSchema = Yup.object().shape({
    name: Yup.string("")
      .matches(nameregex, "Invalid Name")
      .required("Required"),
    title: Yup.string().trim().required("Required"),
    url: Yup.string().trim().required("Required"),
    description: Yup.string().trim().required("Required"),
  });
  const handleSubmit = () => {
    if (!deskImageLink.img || !mobImageLink.img) {
      if (!deskImageLink.img) {
        setDeskImageLink({ img: "", error: true });
      }
      if (!mobImageLink.img) {
        setMobImageLink({ img: "", error: true });
      }
    }
    if (formRef.current) {
      formRef.current.handleSubmit();
    }
  };
  const onSubmit = (values, { resetForm }) => {
    if (mobImageLink.img && deskImageLink.img)
      dispatch(
        createMediaRecAction(
          {
            name: values.name,
            designation: "AdvisorHR",
            title: values.title,
            description: values.description,
            url: values.url,
            logo: deskImageLink.img,
            profile_picture: mobImageLink.img
          },
          onSuccess,
          onError
        )
      );
    console.log(values);
  };

  const onSuccess = (data) => {
    successToast(data.message);                  
    navigate(`/static-pages/media-recogonition/edit/${data.data.id}`);
    setLoading(false);
  };

  const onError = (data) => {
    errorToast(data.data);
    setLoading(false);
  };
  function handleImageChange1(e, param1) {
    console.log(param1);
    if (e.target?.files.length !== 0) {
      setFile(URL.createObjectURL(e.target.files[0]));
    }
  }
  function handleImageChange2(e, param1) {
    console.log(param1);
    if (e.target?.files.length !== 0) {
      setFileTwo(URL.createObjectURL(e.target.files[0]));
    }
  }

  const imageUpload = (e) => {
    if (e.target.name === "deskImage") {
      dispatch(
        uploadAddressProofFrontImageAction(
          e.target.files[0],
          onDeskImageUploadSucess,
          onDeskImageUploadError
        )
      );
    } else if (e.target.name === "mobImage") {
      dispatch(
        uploadAddressProofFrontImageAction(
          e.target.files[0],
          onMobImageUploadSucess,
          onMobImageUploadError
        )
      );
    }
    e.target.value = null;
  };
  const onDeskImageUploadSucess = (data) => {
    setDeskImageLink({ img: data.data.data.location, error: false });
  };
  const onDeskImageUploadError = (data) => {
    setDeskImageLink({ img: "", error: true });
    console.log(data);
  };
  const onMobImageUploadSucess = (data) => {
    setMobImageLink({ img: data.data.data.location, error: false });
  };
  const onMobImageUploadError = (data) => {
    setMobImageLink({ img: "", error: true });
    console.log(data);
  };

  const buttonList = [<SaveBtn submitFn={handleSubmit} />];
  return (
    <>
      <InnerLayout buttons={buttonList}>
        <div className="admin_user_container">
          <InnerContainer>
            {loading ? (
              <LoadingSpinnerTable/>
            ) : (
            <div className="pt-3 mb-5 ps-2 ps-md-2 ps-lg-4 ">
              <Formik
                innerRef={formRef}
                initialValues={initialValues}
                onSubmit={onSubmit}
                validationSchema={loginSchema}
              >
                {({ setFieldValue, handleChange, values }) => (
                  <Form className="our-team-form-width">
                    <div className="row">
                      <div className="col-12 col-md-7 col-xl-6">

                        <div className="d-flex gap-5 flex-wrap mb-3">
                          <div>
                            <span className="mb-3 dark_grey_color fn_Montserrat fw_500 fs_14 ps-2">Logo</span>
                            <div className="blog_create_image_container mt-1 position-relative d-flex  justify-content-center align-items-center">
                              <input
                                multiple={false}
                                style={{ visibility: "hidden" }}
                                type="file"
                                id="deskImage"
                                className="upload_document_input"
                                name="deskImage"
                                accept="image/* ,capture=camera"
                                onChange={(e) => {
                                  imageUpload(e);
                                }}
                              />
                              {deskImageLink.img ? (
                                <>
                                  <img
                                    src={deskImageLink.img}
                                    className={"user-edit-profile-picture"}
                                  />
                                  <button
                                    type="button"
                                    onClick={(e) => {
                                      setDeskImageLink({ img: "", error: true });
                                    }}
                                    className="position-absolute border-0 cursor_pointer white_bg d-flex gap-2 py-1 px-2 user_edit_profile_picture_remove_container"
                                  >
                                    <CloseIcon fill="#666666" />
                                    <span className="dark_grey_color fs_13 fn_Montserrat fw_500 ">
                                      Remove
                                    </span>
                                  </button>
                                </>
                              ) : (
                                <label
                                  htmlFor="deskImage"
                                  className="d-flex flex-column align-items-center mt-2 justify-content-center fs_12"
                                >
                                  <UploadIcon width={30} height={30} />
                                  Choose Logo
                                </label>
                              )}
                            </div>
                            {deskImageLink.error && (
                              <span className="fs_12 ms-2 text-danger fw-semibold fn_Nunito">
                                Required!
                              </span>
                            )}
                          </div>
                          <div>
                            <span className="mb-3 dark_grey_color fn_Montserrat fw_500 fs_14 ps-2">Profile Picture</span>
                            <div className="blog_create_image_container mt-1 position-relative d-flex  justify-content-center align-items-center">
                              <input
                                multiple={false}
                                style={{ visibility: "hidden" }}
                                type="file"
                                id="mobImage"
                                className="upload_document_input"
                                name="mobImage"
                                accept="image/* ,capture=camera"
                                onChange={(e) => {
                                  imageUpload(e);
                                }}
                              />
                              {mobImageLink.img ? (
                                <>
                                  <img
                                    src={mobImageLink.img}
                                    className={"user-edit-profile-picture"}
                                  />
                                  <button
                                    type="button"
                                    onClick={(e) => {
                                      setMobImageLink({ img: "", error: true });
                                    }}
                                    className="position-absolute border-0 cursor_pointer white_bg d-flex gap-2 py-1 px-2 user_edit_profile_picture_remove_container"
                                  >
                                    <CloseIcon fill="#666666" />
                                    <span className="dark_grey_color fs_13 fn_Montserrat fw_500 ">
                                      Remove
                                    </span>
                                  </button>
                                </>
                              ) : (
                                <label
                                  htmlFor="mobImage"
                                  className="d-flex flex-column align-items-center mt-2 justify-content-center fs_12"
                                >
                                  <UploadIcon width={30} height={30} />
                                  Choose Profile Picture
                                </label>
                              )}
                            </div>
                            {mobImageLink.error && (
                              <span className="fs_12 ms-2 text-danger fw-semibold fn_Nunito">
                                Required!
                              </span>
                            )}
                          </div>
                        </div>

                        <InputField
                          id={"name"}
                          label={"Name"}
                          type={"text"}
                          placeholder={"Enter Name"}
                          name={"name"}
                        />
                        <InputField
                          id={"title"}
                          label={"Title"}
                          type={"text"}
                          placeholder={"Enter title"}
                          name={"title"}
                        />
                        <InputField
                          id={"url"}
                          label={"URL"}
                          type={"text"}
                          placeholder={"Enter URL"}
                          name={"url"}
                        />
                      </div>
                      <div className="col-12 col-md-7 col-xl-6">
                        <div className="ps-0 ps-xl-4">
                          <label
                            id="description"
                            className="mb-2 dark_grey_color fn_Montserrat fw_500 fs_14 ps-2"
                          >
                            Description
                          </label>
                          <Field name={"description"}>
                            {({ field }) => (
                              <textarea
                                id="description"
                                {...field}
                                type="text"
                                placeholder="Enter description"
                                className={
                                  "w-100 opportunities_create_summary_text_area p-2 "
                                }
                              ></textarea>
                            )}
                          </Field>

                          <ErrorMsg name={"description"} />
                        </div>
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
            )}
          </InnerContainer>
        </div>
      </InnerLayout>
    </>
  );
};

export default MediaRecogonitionCreate;

export const selectDesignation = [
  { value: "long term", label: "long term" },
  { value: "short term", label: "short term" },
];
