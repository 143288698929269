import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import DeleteBtn from "../../../../components/buttons/deleteBtn";
import EditBtn from "../../../../components/buttons/editBtn";
import VersionHistoryBtn from "../../../../components/buttons/versionHistoryBtn";
import InnerContainer from "../../../../components/layouts/innerContainer/innerContainer";
import InnerLayout from "../../../../components/layouts/innerLayout/innerLayout";
import AdminRoleCheckBox from "../../../../components/utilits/adminRoleCheckbox";
import "../admin-roles.css";
import * as adminRolesActions from "../../../../redux/actions/adminRoleActions";
import errorToast from "../../../../components/utilits/errorToast";
import { Form, Formik } from "formik";
import { addAdminRoleDefaultValue } from "../../admin-roles/admin-role/admin-role-edit";
import ApproveBtn from "../../../../components/buttons/approveBtn";
import RejectBtn from "../../../../components/buttons/rejectBtn";
import successToast from "../../../../components/utilits/successToast";
import LoadingSpinnerTable from "../../../../components/utilits/loadingSpinnerTable";
import LoadAndError from "../../../../components/utilits/LoadAndError";

const AdminRoleApprovalView = () => {
  const params = useParams();
  const navigate = useNavigate();
  const [updated, setUpdated] = useState(false);
  const [approvalDetails, setApprovalDetails] = useState({
    id: "",
    new_data: {
      role_name: "",
      permissions: {
        admin: {
          checker: false,
          maker: false,
          read_only: false,
        },
        user: {
          checker: false,
          maker: false,
          read_only: false,
        },
        opportunity: {
          checker: false,
          maker: false,
          read_only: false,
        },

        role: {
          checker: false,
          maker: false,
          read_only: false,
        },
        static_homepage: {
          checker: false,
          maker: false,
          read_only: false,
        },
      },
    },
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false)
  const dispatch = useDispatch();
  useEffect(() => {
    setLoading(true);
    dispatch(
      adminRolesActions.viewApprovalAdminRole(params.id, onSuccess, onError)
    );
  }, [updated]);
  const onSuccess = (data) => {
    setApprovalDetails(data.data);
    setLoading(false);
    setError(false)
  };
  const onError = (data) => {
    errorToast(data.message);
    setLoading(false);
    setError(true)
  };
  console.log(approvalDetails);
  const approveRole = () => {
    dispatch(
      adminRolesActions.approveAdminRole(
        approvalDetails?.id,
        onApproveSuccess,
        onApproveError
      )
    );
  };
  const onApproveSuccess = (data) => {
    setUpdated(!updated);
    successToast(data.message);
    navigate("/admin-roles/approvals");
    setLoading(false)
    setError(false)
  };
  const onApproveError = (data) => {
    console.log(data);
    errorToast(data.data);
    setLoading(false)
    setError(true)
  };
  const rejectRole = () => {
    dispatch(
      adminRolesActions.rejectAdminRole(
        approvalDetails?.id,
        onRejectSuccess,
        onRejectError
      )
    );
  };
  const onRejectSuccess = (data) => {
    setUpdated(!updated);
    successToast(data.message);
    navigate("/admin-roles/approvals");
    setLoading(false)
    setError(false)
  };
  const onRejectError = (data) => {
    console.log(data);
    errorToast(data.message);
    setLoading(false)
    setError(true)
  };
  const buttonList = [
    <VersionHistoryBtn
      link={`/admin-roles/version-history`}
    />,
    <ApproveBtn approveFn={approveRole} />,
    <RejectBtn showModal={rejectRole} />,
  ];
  const buttonListCompleted = [
    <VersionHistoryBtn
      link={`/admin-roles/version-history/view/${approvalDetails?.id}`}
    />,
  ];
  const initialValues = {
    id: approvalDetails?.id ?? "",
    roleName: approvalDetails?.new_data?.role_name ?? "",
    permissions: {
      admin: {
        checker:
          approvalDetails?.new_data?.permissions?.admin?.checker ?? false,
        maker: approvalDetails?.new_data?.permissions?.admin?.maker ?? false,
        read_only:
          approvalDetails?.new_data?.permissions?.admin?.read_only ?? false,
      },
      user: {
        checker: approvalDetails?.new_data?.permissions?.user?.checker ?? false,
        maker: approvalDetails?.new_data?.permissions?.user?.maker ?? false,
        read_only:
          approvalDetails?.new_data?.permissions?.user?.read_only ?? false,
      },
      opportunity: {
        checker:
          approvalDetails?.new_data?.permissions?.opportunity?.checker ?? false,
        maker:
          approvalDetails?.new_data?.permissions?.opportunity?.maker ?? false,
        read_only:
          approvalDetails?.new_data?.permissions?.opportunity?.read_only ??
          false,
      },

      role: {
        checker: approvalDetails?.new_data?.permissions?.role?.checker ?? false,
        maker: approvalDetails?.new_data?.permissions?.role?.maker ?? false,
        read_only:
          approvalDetails?.new_data?.permissions?.role?.read_only ?? false,
      },
      static_homepage: {
        checker:
          approvalDetails?.new_data?.permissions?.static_homepage?.checker ??
          false,
        maker:
          approvalDetails?.new_data?.permissions?.static_homepage?.maker ??
          false,
        read_only:
          approvalDetails?.new_data?.permissions?.static_homepage?.read_only ??
          false,
      },
    },
  };
  const initialValuesOldData = {
    id: approvalDetails?.id ?? "",
    roleName: approvalDetails?.old_data?.role_name ?? "",
    
    permissions: {
      admin: {
        checker:
          approvalDetails?.old_data?.permissions?.admin?.checker ?? false,
        maker: approvalDetails?.old_data?.permissions?.admin?.maker ?? false,
        read_only:
          approvalDetails?.old_data?.permissions?.admin?.read_only ?? false,
      },
      user: {
        checker: approvalDetails?.old_data?.permissions?.user?.checker ?? false,
        maker: approvalDetails?.old_data?.permissions?.user?.maker ?? false,
        read_only:
          approvalDetails?.old_data?.permissions?.user?.read_only ?? false,
      },
      opportunity: {
        checker:
          approvalDetails?.old_data?.permissions?.opportunity?.checker ?? false,
        maker:
          approvalDetails?.old_data?.permissions?.opportunity?.maker ?? false,
        read_only:
          approvalDetails?.old_data?.permissions?.opportunity?.read_only ??
          false,
      },

      role: {
        checker: approvalDetails?.old_data?.permissions?.role?.checker ?? false,
        maker: approvalDetails?.old_data?.permissions?.role?.maker ?? false,
        read_only:
          approvalDetails?.old_data?.permissions?.role?.read_only ?? false,
      },
      static_homepage: {
        checker:
          approvalDetails?.old_data?.permissions?.static_homepage?.checker ??
          false,
        maker:
          approvalDetails?.old_data?.permissions?.static_homepage?.maker ??
          false,
        read_only:
          approvalDetails?.old_data?.permissions?.static_homepage?.read_only ??
          false,
      },
    },
  };

  //   partnerData?.new_data == null
  //     ? partnerData?.old_data?.priority
  //     : partnerData?.new_data?.priority;

  return (
    <InnerLayout
      buttons={
        approvalDetails.approved === "pending"
          ? buttonList
          : buttonListCompleted
      }
    >
      <div className="admin_role_container">
        <InnerContainer error={error} oldData={approvalDetails?.old_data} newData={ approvalDetails?.new_data }>
          {loading ? (
            <LoadingSpinnerTable />
          ) : (
            <LoadAndError loader={loading} error={error}>
              <div className="pt-3  ps-2 ps-lg-4">
                <div className="mb-2">
                  <h5 className="dark_grey_color fs_12 fn_Montserrat fw_500 m-0">
                    Approval ID
                  </h5>
                  <p className={` fs_12 fn_Nunito fw_500 mt-1 dark_grey_color `}>
                    {approvalDetails?.id}
                  </p>
                </div>
                <div className="mb-2">
                  <h5 className="dark_grey_color fs_12 fn_Montserrat fw_500 m-0">
                    Role ID
                  </h5>
                  <p className={` fs_12 fn_Nunito fw_500 mt-1 dark_grey_color `}>
                    {approvalDetails?.new_data?.id === null ? approvalDetails?.old_data?.id : approvalDetails?.new_data?.id}
                  </p>
                </div>
                <div>
                  <h5 className="dark_grey_color fs_12 fn_Montserrat fw_500 m-0">
                    Role Name
                  </h5>
                  <p className={` fs_12 fn_Nunito fw_500 mt-1 secondary_color `}>
                    {approvalDetails?.new_data?.role_name}
                  </p>
                </div>
                <Formik
                  enableReinitialize={true}
                  initialValues={
                    approvalDetails?.new_data === null
                      ? initialValuesOldData
                      : initialValues
                  }
                >
                  <Form>
                    <div>
                      <h5 className="dark_grey_color fs_12 fn_Montserrat fw_500 m-0">
                        Permissions
                      </h5>
                      <div className="admin_role_view_permissions_width mt-3 mb-3">
                        <div className="row ms-0 ms-lg-3 ">
                          <div className="col-3">
                            <h5 className="dark_grey_color text-nowrap fs_12 fn_Montserrat fw_500 ">
                              Modules
                            </h5>
                          </div>
                          <div className="col-3">
                            <h5 className="dark_grey_color d-flex justify-content-center fs_12 text-nowrap fn_Montserrat fw_500 ">
                              Checker
                            </h5>
                          </div>
                          <div className="col-3">
                            <h5 className="dark_grey_color fs_12 d-flex justify-content-center  fn_Montserrat text-nowrap fw_500 ">
                              Maker
                            </h5>
                          </div>
                          <div className="col-3">
                            <h5 className="dark_grey_color text-nowrap d-flex justify-content-center fs_12 fn_Montserrat fw_500 ">
                              Read Only
                            </h5>
                          </div>
                        </div>
                        <div className="row ms-0 ms-lg-3 mt-2">
                          <div className="col-3">
                            <h5 className="dark_grey_color text-nowrap fs_12 fn_Montserrat fw_500 ">
                              Admin
                            </h5>
                          </div>
                          <div className="col-3">
                            <div className="d-flex justify-content-center">
                              <AdminRoleCheckBox
                                disabled={true}
                                name="permissions.admin.checker"
                              />
                            </div>
                          </div>
                          <div className="col-3">
                            <div className="d-flex justify-content-center">
                              <AdminRoleCheckBox
                                disabled={true}
                                name="permissions.admin.maker"
                              />
                            </div>
                          </div>
                          <div className="col-3">
                            <div className="d-flex justify-content-center">
                              <AdminRoleCheckBox
                                disabled={true}
                                name="permissions.admin.read_only"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="row ms-0 ms-lg-3 mt-2">
                          <div className="col-3">
                            <h5 className="dark_grey_color text-nowrap fs_12 fn_Montserrat fw_500 ">
                              User
                            </h5>
                          </div>
                          <div className="col-3">
                            <div className="d-flex justify-content-center">
                              <AdminRoleCheckBox
                                disabled={true}
                                name="permissions.user.checker"
                              />
                            </div>
                          </div>
                          <div className="col-3">
                            <div className="d-flex justify-content-center">
                              <AdminRoleCheckBox
                                disabled={true}
                                name="permissions.user.maker"
                              />
                            </div>
                          </div>
                          <div className="col-3">
                            <div className="d-flex justify-content-center">
                              <AdminRoleCheckBox
                                disabled={true}
                                name="permissions.user.read_only"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="row ms-0 ms-lg-3 mt-2">
                          <div className="col-3">
                            <h5 className="dark_grey_color text-nowrap fs_12 fn_Montserrat fw_500 ">
                              Opportunity
                            </h5>
                          </div>
                          <div className="col-3">
                            <div className="d-flex justify-content-center">
                              <AdminRoleCheckBox
                                disabled={true}
                                name="permissions.opportunity.checker"
                              />
                            </div>
                          </div>
                          <div className="col-3">
                            <div className="d-flex justify-content-center">
                              <AdminRoleCheckBox
                                disabled={true}
                                name="permissions.opportunity.maker"
                              />
                            </div>
                          </div>
                          <div className="col-3">
                            <div className="d-flex justify-content-center">
                              <AdminRoleCheckBox
                                disabled={true}
                                name="permissions.opportunity.read_only"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="row ms-0 ms-lg-3 mt-2">
                          <div className="col-3">
                            <h5 className="dark_grey_color text-nowrap fs_12 fn_Montserrat fw_500 ">
                              Role
                            </h5>
                          </div>
                          <div className="col-3">
                            <div className="d-flex justify-content-center">
                              <AdminRoleCheckBox
                                disabled={true}
                                name="permissions.role.checker"
                              />
                            </div>
                          </div>
                          <div className="col-3">
                            <div className="d-flex justify-content-center">
                              <AdminRoleCheckBox
                                disabled={true}
                                name="permissions.role.maker"
                              />
                            </div>
                          </div>
                          <div className="col-3">
                            <div className="d-flex justify-content-center">
                              <AdminRoleCheckBox
                                disabled={true}
                                name="permissions.role.read_only"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="row ms-0 ms-lg-3 mt-2">
                          <div className="col-3">
                            <h5 className="dark_grey_color text-nowrap fs_12 fn_Montserrat fw_500 ">
                              Static and home page
                            </h5>
                          </div>
                          <div className="col-3">
                            <div className="d-flex justify-content-center">
                              <AdminRoleCheckBox
                                disabled={true}
                                name="permissions.static_homepage.checker"
                              />
                            </div>
                          </div>
                          <div className="col-3">
                            <div className="d-flex justify-content-center">
                              <AdminRoleCheckBox
                                disabled={true}
                                name="permissions.static_homepage.maker"
                              />
                            </div>
                          </div>
                          <div className="col-3">
                            <div className="d-flex justify-content-center">
                              <AdminRoleCheckBox
                                disabled={true}
                                name="permissions.static_homepage.read_only"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="dark_grey_color fs_12 fn_Montserrat fw_500">
                        <span>Operation Type</span>
                        <p>
                          {approvalDetails?.new_data == null
                            ? "Delete"
                            : approvalDetails?.new_data && approvalDetails?.old_data
                              ? "Edit"
                              : approvalDetails?.old_data == null
                                ? "Create"
                                : ""}
                        </p>
                      </div>
                    </div>
                  </Form>
                </Formik>
              </div>
            </LoadAndError>
          )}
        </InnerContainer>
      </div>
    </InnerLayout>
  );
};

export default AdminRoleApprovalView;
