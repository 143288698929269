import React, { useState } from 'react'
import ApprovalsBtn from '../../../../components/buttons/approvalsBtn';
import CreateBtn from '../../../../components/buttons/createBtn';
import ExportBtn from '../../../../components/buttons/exportBtn';
import FilterBtn from '../../../../components/buttons/filterBtn';
import VersionHistoryBtn from '../../../../components/buttons/versionHistoryBtn';
import AdminUserFilter from '../../../../components/filter/adminUserFilter';
import ContactUsFilter from '../../../../components/filter/static-pages filter/contactUsFilter';
import InnerLayout from '../../../../components/layouts/innerLayout/innerLayout';
import ContactUsApprovalsTableContent from '../../../../components/static-pages/contact-us/tables/contactUsApprovalsTableContent';
import { contactApprovalsExportAction } from '../../../../redux/actions/contactUsActions';

const ContactUsApprovalsTable = () => {
  const [page, setPage] = useState(0);
  const [showFilter, setShowFilter] = useState(false);
  const [search, setSearch] = useState({ value: "" });
  const handleFilterClose = () => {
    setShowFilter(false);
  };
  const handleFilterOpen = () => {
    setShowFilter(true);
  };
  const handleSearch = (value) => {
    setSearch({ value: value });
    setPage(0)
  };
  const handlePage = (type) => {
    if (type === "+") {
      setPage((prev) => prev + 1)
    }
    else {
      setPage((prev) => prev - 1)
    }
  }
  const buttonList = [
    <VersionHistoryBtn link={`/static-pages/contact-us-query/version-history`} />,
    <CreateBtn link={`/static-pages/contact-us-query/create`} />,
    <ExportBtn dispatchAction={contactApprovalsExportAction} />,
    <FilterBtn filterFn={handleFilterOpen} />,
  ];
  return (
    <InnerLayout buttons={buttonList}>
      <ContactUsFilter
        filter={handleSearch}
        showFilter={showFilter}
        closeFilter={handleFilterClose}
      />

      <ContactUsApprovalsTableContent page={page} changePage={handlePage} search={search} />
    </InnerLayout>
  )
}

export default ContactUsApprovalsTable