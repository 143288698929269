import { authHeader } from "../config";
import { api } from "./api";

export const addUserReferralApi = (params) => {
  return api.post("/api/admin/user_referral/create", params, {
    headers: {
      "x-access-token": localStorage.getItem("accessToken"),
      "Content-Type": "application/json",
    },
  });
};
export const viewUserReferralListApi = (page = 0, params) => {
  return api.post(
    `/api/admin/admin-referral/view-user-referral?page_no=${page}`,
    params,
    {
      headers: {
        "x-access-token": localStorage.getItem("accessToken"),
        "Content-Type": "application/json",
      },
    }
  );
};
export const viewUserReferralApi = (id, params) => {
  return api.post(`/api/admin/user_referral/view-approval/${id}`, params, {
    headers: {
      "x-access-token": localStorage.getItem("accessToken"),
      "Content-Type": "application/json",
    },
  });
};
export const editUserReferralApi = (id, params) => {
  return api.post(`/api/admin/user_referral/edit/${id}`, params, {
    headers: {
      "x-access-token": localStorage.getItem("accessToken"),
      "Content-Type": "application/json",
    },
  });
};
export const deleteUserReferralApi = (id, params) => {
  return api.post(`/api/admin/user_referral/delete/${id}`, params, {
    headers: {
      "x-access-token": localStorage.getItem("accessToken"),
      "Content-Type": "application/json",
    },
  });
};
export const approveUserReferralApi = (params) => {
  return api.post(`/api/admin/user_referral/approve`, params, {
    headers: {
      "x-access-token": localStorage.getItem("accessToken"),
      "Content-Type": "application/json",
    },
  });
};
export const rejectUserReferralApi = (id, params) => {
  return api.post(`/api/admin/user_referral/reject/${id}`, params, {
    headers: {
      "x-access-token": localStorage.getItem("accessToken"),
      "Content-Type": "application/json",
    },
  });
};
export const approvalsUserReferralListpApi = (page = 0, params) => {
  return api.post(
    `/api/admin/user_referral/view-checker?page_no=${page}`,
    params,
    {
      headers: {
        "x-access-token": localStorage.getItem("accessToken"),
        "Content-Type": "application/json",
      },
    }
  );
};
export const viewApprovalUserReferralApi = (id, params) => {
  return api.post(`/api/admin/user_referral/view-checker/${id}`, params, {
    headers: {
      "x-access-token": localStorage.getItem("accessToken"),
      "Content-Type": "application/json",
    },
  });
};
export const versionUserReferralpApi = (page = 0, params) => {
  return api.post(
    `/api/admin/user_referral/view-version?page_no=${page}`,
    params,
    {
      headers: {
        "x-access-token": localStorage.getItem("accessToken"),
        "Content-Type": "application/json",
      },
    }
  );
};
export const viewVersionUserReferralApi = (id, params) => {
  return api.post(`/api/admin/user_referral/view-version/${id}`, params, {
    headers: {
      "x-access-token": localStorage.getItem("accessToken"),
      "Content-Type": "application/json",
    },
  });
};
export const UserReferralSubmitApi = (id, params) => {
  return api.post(`/api/admin/user_referral/on-submit/${id}`, params, {
    headers: {
      "x-access-token": localStorage.getItem("accessToken"),
      "Content-Type": "application/json",
    },
  });
};

export const UserReferralMainExportApi = (params) => {
  return api.post(
    `/api/admin/user_referral/export-main`,
    JSON.stringify(params),
    {
      headers: {
        "x-access-token": localStorage.getItem("accessToken"),
        "Content-Type": "application/json",
      },
    }
  );
};

export const UserReferralApprovalsExportApi = (page, params) => {
  return api.post(
    `/api/admin/user_referral/export-approval`,
    JSON.stringify(params),
    {
      headers: {
        "x-access-token": localStorage.getItem("accessToken"),
        "Content-Type": "application/json",
      },
    }
  );
};

export const UserReferralVersionExportApi = (page, params) => {
  return api.post(
    `/api/admin/user_referral/export-version`,
    JSON.stringify(params),
    {
      headers: {
        "x-access-token": localStorage.getItem("accessToken"),
        "Content-Type": "application/json",
      },
    }
  );
};
export const fetchOpportunitiesIdApi = (params) => {
  return api.post(
    `/api/admin/user_referral/featchOpportunity`,
    JSON.stringify(params),
    {
      headers: {
        "x-access-token": localStorage.getItem("accessToken"),
        "Content-Type": "application/json",
      },
    }
  );
};
