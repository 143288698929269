import React, { useState } from "react";
import ApprovalsBtn from "../../../../components/buttons/approvalsBtn";
import CreateBtn from "../../../../components/buttons/createBtn";
import ExportBtn from "../../../../components/buttons/exportBtn";
import FilterBtn from "../../../../components/buttons/filterBtn";
import VersionHistoryBtn from "../../../../components/buttons/versionHistoryBtn";
import LlpFilter from "../../../../components/filter/opportunity-pagesFilter.js/llpFilter";
import InnerLayout from "../../../../components/layouts/innerLayout/innerLayout";
import LlpTableContent from "../../../../components/opportunity/llp/tables/llpTableContent";
import { llpTableExport } from "../../../../redux/actions/llpActions";

const LlpTable = () => {
  const [page, setPage] = useState(0);
  const [showFilter, setShowFilter] = useState(false);
  const [search, setSearch] = useState({ value: "" });

  const handleFilterClose = () => {
    setShowFilter(false);
  };
  const handleFilterOpen = () => {
    setShowFilter(true);
  };
  const handleSearch = (value) => {
    setSearch({ value: value });
    setPage(0)
  };
  const handlePage = (type) => {
    if (type === "+") {
      setPage((prev) => prev + 1)
    }
    else {
      setPage((prev) => prev - 1)
    }
  }
  const buttonList = [
    <ApprovalsBtn link={`/opportunity/llp/approvals`} />,
    <VersionHistoryBtn link={`/opportunity/llp/version-history`} />,
    <CreateBtn link={`/opportunity/llp/create`} />,
    <ExportBtn dispatchAction={llpTableExport} />,
    <FilterBtn filterFn={handleFilterOpen} />,
  ];

  return (
    <InnerLayout buttons={
      buttonList
    }>
      <LlpFilter
        filter={handleSearch}
        showFilter={showFilter}
        closeFilter={handleFilterClose}
      />
      <LlpTableContent page={page} changePage={handlePage} search={search} />
    </InnerLayout>
  );
};

export default LlpTable;
