import React, { useState } from "react";
import CreateBtn from "../../../../components/buttons/createBtn";
import ExportBtn from "../../../../components/buttons/exportBtn";
import FilterBtn from "../../../../components/buttons/filterBtn";
import InnerLayout from "../../../../components/layouts/innerLayout/innerLayout";
import AdminUserFilter from "../../../../components/filter/adminUserFilter";
import { exportAdmin } from "../../../../redux/actions/AdminUserAction";
import FileSaver from 'file-saver';
import OurTeamApprovalTableContent from "../../../../components/static-pages/our-team/tables/ourTeamApprovalTableContent";
import { useDispatch } from "react-redux";
import OurTeamFilter from "../../../../components/filter/static-pages filter/ourTeamFilter";
import { ourTeamApprovalsExport } from "../../../../redux/actions/ourTeamAction";

const OurTeamApprovalTable = () => {
    const [page, setPage] = useState(0);
    const [showFilter, setShowFilter] = useState(false);
    const [search, setSearch] = useState({ value: "" });
    const dispatch = useDispatch();
    const handleFilterClose = () => {
      setShowFilter(false);     
    };
    const handleFilterOpen = () => {
      setShowFilter(true);
    };
    const handleSearch = (value) => {
      setSearch({ value: value });
      setPage(0)
    };
    const handlePage = (type) => {
      if (type === "+") {
        setPage((prev) => prev + 1)
      }
      else {
        setPage((prev) => prev - 1)
      }                                 
    }
    const exportFn = () => {
        dispatch(exportAdmin(
            onSuccess, onError
        ));
    }
    const onSuccess = (data) => {
        console.log("SUCCESS =====================================", data);
    };
    const onError = (data) => {
        console.log("ERROR==================", data);
        var type = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8";
        var blob = new Blob([data], { type: type });
        FileSaver.saveAs(blob, "file.xlsx");
    };               
    console.log(search);
    const buttonList = [
        <ExportBtn dispatchAction={ourTeamApprovalsExport} />,
        <FilterBtn filterFn={handleFilterOpen} />,
    ];

    return (
        <InnerLayout containerWidth="95%" buttons={buttonList}>
            <OurTeamFilter
                filter={handleSearch}
                showFilter={showFilter}
                closeFilter={handleFilterClose}
            />
            <OurTeamApprovalTableContent page={page} changePage={handlePage} search={search} />
        </InnerLayout>
    );
}
export default OurTeamApprovalTable




