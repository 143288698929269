import React, { useEffect, useRef, useState } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import DeleteBtn from "../../../components/buttons/deleteBtn";
import SaveBtn from "../../../components/buttons/saveBtn";
import ViewBtn from "../../../components/buttons/viewBtn";
import InnerContainer from "../../../components/layouts/innerContainer/innerContainer";
import InnerLayout from "../../../components/layouts/innerLayout/innerLayout";
import "../user-view.css";
import InputField from "../../../components/form/inputFields/inputField";
import CloseIcon from "../../../assets/icons/closeIcon";
import UploadIcon from "../../../assets/icons/upload";
import SelectFields from "../../../components/form/selectFields/selectFields";
import ArrowRightIcon from "../../../assets/icons/arrowrightIcon";
import ArrowDownIcon from "../../../assets/icons/arrowdown";
import Select, { components } from "react-select";
import ErrorMsg from "../../../components/form/errorMsg";
import moment from "moment";
// import Image from "../../../assets/images/default.png";
import { useDispatch } from "react-redux";
import * as userAction from "../../../redux/actions/userAction";
import errorToast from "../../../components/utilits/errorToast";
import successToast from "../../../components/utilits/successToast";
import DeleteModal from "../../../components/modals/deleteModal";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import LoadingSpinnerTable from "../../../components/utilits/loadingSpinnerTable";
import LoadAndError from "../../../components/utilits/LoadAndError";
import SubmitBtn from "../../../components/buttons/submit";

const UserEdit = () => {
  const [profileImageLink, setProfileImageLink] = useState({
    img: "",
    error: false,
  });
  const [addressBackImageLink, setAddressBackImageLink] = useState({
    img: "",
    error: false,
  });
  const [addressFrontImageLink, setAddressFrontImageLink] = useState({
    img: "",
    error: false,
  });
  const [panImageLink, setPanImageLink] = useState({
    img: "",
    error: false,
  });
  const [bankImageLink, setBankImageLink] = useState({
    img: "",
    error: false,
  });
  const phoneRegExp = /^[0-9]{10}$/;
  const din = /^[0-9]{8}$/;
  const amountRegex = /^[0-9]*$/;
  const investmentValueregex = /^[1-2]?[0-5]?[0-0]?[0-0]?[0-0]?[0-0]?([0-0])$/;
  const panRegex = /[A-Z]{5}[0-9]{4}[A-Z]{1}?$/;
  const accountnumberRegex = /^\d{11,14}$/;
  const ifscRegex = /^[A-Z]{4}0[A-Z0-9]{6}$/;
  const formRef = useRef();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [editUser, setEditUser] = useState(false);
  const [userDetails, setUserDetails] = useState(null);
  const params = useParams();
  const location = useLocation();
  const [show, setShow] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [loadingEdit, setLoadingEdit] = useState(false);
  const [profilePicture, setProfilePicture] = useState("");
  const [saved, setSaved] = useState(false);
  const [subLoading, setSubLoading] = useState(false);
  const navigate = useNavigate();
  const handleShow = () => {
    setDeleteData({ name: userDetails?.user_name, id: params.id });
    setShow(true);
  };
  const handleClose = () => setShow(false);
  const [deleteData, setDeleteData] = useState({ name: "", id: "" });
  // UsersView

  useEffect(() => {
    setLoading(true);
    dispatch(userAction.UsersView(params?.id, onSuccess, onError));
  }, []);
  const onSuccess = (data) => {
    setUserDetails(data.data);
    setLoading(false);
    setError(false);
    setProfileImageLink({
      img: data?.data?.profile_picture_location,
      error: false,
    });
    setPanImageLink({
      img: data?.data?.pan?.front_image_location,
      error: false,
    });
    setAddressBackImageLink({
      img: data?.data?.address_proof?.back_image_location,
      error: false,
    });
    setAddressFrontImageLink({
      img: data?.data?.address_proof?.front_image_location,
      error: false,
    });
    setBankImageLink({
      img: data?.data?.bank_account_details?.image_location,
      error: false,
    });
  };

  const onError = () => {
    setLoading(false);
    setError(true);
  };

  const [selectedOption, setSelectedOption] = useState({
    label: "",
    value: "",
  });

  const [changed, whenChanged] = useState(false);

  const initialValues = {
    // profileImage: userDetails?.profile_picture_location
    //   ? userDetails?.profile_picture_location
    //   : "",
    // addressFrontImage: { Image },
    // addressBackImage: { Image },
    // panImage: { Image },
    // bankImage: { Image },
    name: userDetails?.user_name ? userDetails?.user_name : "",
    fatherName: userDetails?.father_name ? userDetails?.father_name : "",
    email: userDetails?.email ? userDetails?.email : "",

    phone: userDetails?.phone_number ? userDetails?.phone_number : "",

    countryCode: userDetails?.country_code ? userDetails?.country_code : "",
    residentialStatus: userDetails?.residential_status
      ? userDetails?.residential_status
      : "",
    din: userDetails?.din ? userDetails?.din : "",
    dealType: userDetails?.deal_type_preference
      ? userDetails?.deal_type_preference
      : "",
    investmentValue: userDetails?.investment_value_preference
      ? userDetails?.investment_value_preference
      : "",
    kyc: userDetails?.kyc_process_status,

    pan: userDetails?.pan?.pan_no ? userDetails?.pan?.pan_no : "",
    bankAccountNumber: userDetails?.bank_account_details?.account_number
      ? userDetails?.bank_account_details?.account_number
      : "",
    bankAccountHolderName: userDetails?.bank_account_details?.bank_holder_name
      ? userDetails?.bank_account_details?.bank_holder_name
      : "",
    accountType: userDetails?.bank_account_details?.account_type
      ? userDetails?.bank_account_details?.account_type
      : "",
    bankName: userDetails?.bank_account_details?.bank_name
      ? userDetails?.bank_account_details?.bank_name
      : "",
    bankIfsc: userDetails?.bank_account_details?.ifsc_code
      ? userDetails?.bank_account_details?.ifsc_code
      : "",
    bankProof: userDetails?.bank_account_details?.ifsc_code
      ? userDetails?.bank_account_details?.ifsc_code
      : "",
    // dob: moment("10/20/2022").format("YYYY-MM-DD"),
    dob:
      userDetails?.dob === null
        ? ""
        : moment(userDetails?.dob).format("YYYY-MM-DD"),
    bankprof: userDetails?.bank_account_details?.deal_type_preference,
  };

  const loginSchema = Yup.object().shape({
    // profileImage: Yup.mixed(),
    // addressFrontImage: Yup.mixed(),
    // addressBackImage: Yup.mixed(),
    // panImage: Yup.mixed(),
    // bankImage: Yup.mixed(),
    name: Yup.string().trim().required("Required"),
    fatherName: Yup.string(),
    email: Yup.string().trim().email().required("Required"),
    dob: Yup.date().test("dob", "must be above 18 years", (value) => {
      if (!value) {
        return true;
      } else return moment().diff(moment(value), "years") >= 18;
    }),

    phone: Yup.string()
      .required("Required")
      .matches(phoneRegExp, "Please Enter Valid Phone Number"),
    countryCode: Yup.string(),
    residentialStatus: Yup.string(),
    din: Yup.string().trim().matches(din, "Please Enter Valid DIN Number"),
    /*** Invest***/
    dealType: Yup.string(),
    investmentValue: Yup.string().matches(
      amountRegex,
      "Please Enter Valid Investment Value"
    ),
    kyc: Yup.string().trim().nullable(),
    proofType: Yup.string(),
    pan: Yup.string().matches(panRegex, "Please Enter Valid Pan Number"),
    bankAccountNumber: Yup.string(),
    bankAccountHolderName: Yup.string(),
    accountType: Yup.string(),
    bankName: Yup.string(),
    bankIfsc: Yup.string(),
    // .matches(ifscRegex, "Please Enter Valid Account Number")
    bankProof: Yup.string(),
  });
  const dispatch = useDispatch();
  const handleSubmit = () => {
    if (formRef.current) {
      formRef.current.handleSubmit();
    }
  };
  const onSubmit = (values, { resetForm }) => {
    console.log(values);
    setLoadingEdit(true);
    dispatch(
      userAction.EditUser(
        params?.id,
        {
          user_name: values.name ? values.name : "",
          email: values.email,
          phone_number: values.phone,
          dob: values.dob ? values.dob : null,
          father_name: values.fatherName ? values.fatherName : "",
          country_code: values.countryCode,
          profile_picture_location: profileImageLink.img
            ? profileImageLink.img
            : "",
          residential_status: values.residentialStatus
            ? values.residentialStatus
            : null,
          din: values.din ? values.din : "",
          deal_type_preference: values.dealType ? values.dealType + "" : null,
          investment_value_preference: values.investmentValue
            ? values.investmentValue
            : null,
          kyc_process_status: values.kyc ? values.kyc : null,
          address_proof: {
            proof_type: values.proofType ? values.proofType : "",
            Other_proof: values.bankProof ? values.bankProof : "",
            front_image_location: addressFrontImageLink.img
              ? addressFrontImageLink.img
              : "",
            back_image_location: addressBackImageLink.img
              ? addressBackImageLink.img
              : "",
          },
          pan: {
            pan_no: values.pan ? values.pan : "",
            front_image_location: panImageLink.img ? panImageLink.img : "",
          },
          bank_account_details: {
            bank_holder_name: values.bankAccountHolderName
              ? values.bankAccountHolderName
              : "",
            account_type: values.accountType ? values.accountType : "",
            account_number: values.bankAccountNumber
              ? values.bankAccountNumber
              : null,
            bank_name: values.bankName ? values.bankName : "",
            ifsc_code: values.bankIfsc ? values.bankIfsc : "",
            image_location: bankImageLink.img ? bankImageLink.img : "",
          },
        },
        onEditSuccess,
        onEditError
      )
    );
  };
  const onEditSuccess = (data) => {
    console.log("SUBMIT SUCCESS");
    successToast(data.message);
    setSaved(true);
    setLoadingEdit(false);
    navigate("/");
    setError(false);
  };

  const onEditError = (data) => {
    // console.log(data);
    errorToast(data.data.data + " " + data.data.message);
    // console.log("SUBMIT ERROR");
    setLoadingEdit(false);
    // setError(true)
  };
  const handleDelete = () => {
    setLoadingDelete(true);
    dispatch(
      userAction.deleteUserAction(deleteData.id, onDeleteSuccess, onDeleteError)
    );
  };
  const onDeleteSuccess = (data) => {
    successToast(data.message);
    setLoadingDelete(false);
    setShow(false);
    navigate("/");
    setLoading(false);
    setError(false);
  };
  const onDeleteError = (data) => {
    errorToast(data.message);
    setLoadingDelete(false);
    setLoading(false);
    setError(true);
  };

  const DropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator {...props}>
        <ArrowDownIcon width={25} height={25} />
      </components.DropdownIndicator>
    );
  };
  const userSubmit = () => {
    setSubLoading(true);
    dispatch(
      userAction.userSubmitAction(
        params.id,
        onAdminSubmitSucess,
        onAdminSubmitError
      )
    );
  };
  const buttonList = [
    <ViewBtn link={`/user/user-list/view/${params?.id}`} data={userDetails} />,
    <SaveBtn submitFn={handleSubmit} loader={loadingEdit} />,
    // userDetails?.lastActionApproved &&
    <DeleteBtn showModal={handleShow} />,
    // <SubmitBtn saveHide={saved} loader={subLoading} submitFn={userSubmit} />
  ];

  function onAdminSubmitSucess(data) {
    successToast(data.message);
    setSubLoading(false);
    navigate(`/user/user-list`);
  }
  function onAdminSubmitError(data) {
    setSubLoading(false);
    errorToast(data.message);
  }
  const imageUpload = (e) => {
    if (e.target.name === "profileImage") {
      dispatch(
        userAction.uploadAddressProofFrontImageAction(
          e.target.files[0],
          onProfileUploadImageSuccess,
          onProfileUploadImageError
        )
      );
    } else if (e.target.name === "addressBackImageLink") {
      dispatch(
        userAction.uploadAddressProofFrontImageAction(
          e.target.files[0],
          onuploadAddressProofBackImageSuccess,
          onuploadAddressProofBackImageError
        )
      );
    } else if (e.target.name === "addressFrontImageLink") {
      dispatch(
        userAction.uploadAddressProofFrontImageAction(
          e.target.files[0],
          onuploadAddressProofFrontImageSuccess,
          onuploadAddressProofFrontImageError
        )
      );
    } else if (e.target.name === "panImageLink") {
      dispatch(
        userAction.uploadAddressProofFrontImageAction(
          e.target.files[0],
          onPanUploadImageSuccess,
          onPanUploadImageError
        )
      );
    } else if (e.target.name === "bankImageLink") {
      dispatch(
        userAction.uploadAddressProofFrontImageAction(
          e.target.files[0],
          onbankUploadImageSuccess,
          onbankUploadImageError
        )
      );
    }
    e.target.value = null;
  };

  const onProfileUploadImageSuccess = (data) => {
    setProfileImageLink({ img: data.data.data.location, error: false });
  };
  const onProfileUploadImageError = (data) => {
    console.log(data);
  };
  const onuploadAddressProofFrontImageSuccess = (data) => {
    setAddressFrontImageLink({ img: data.data.data.location, error: false });
  };

  const onuploadAddressProofFrontImageError = (data) => {
    console.log(data);
  };

  const onuploadAddressProofBackImageSuccess = (data) => {
    setAddressBackImageLink({ img: data.data.data.location, error: false });
  };
  const onuploadAddressProofBackImageError = (data) => {
    console.log(data);
  };

  const onPanUploadImageSuccess = (data) => {
    setPanImageLink({ img: data.data.data.location, error: false });
  };

  const onPanUploadImageError = (data) => {
    console.log(data);
  };

  const onbankUploadImageSuccess = (data) => {
    setBankImageLink({ img: data.data.data.location, error: false });
  };

  const onbankUploadImageError = (data) => {
    console.log(data);
  };
  console.log(userDetails?.residential_status);
  console.log(userDetails?.address_proof?.proof_type);

  return (
    <>
      <InnerLayout buttons={buttonList}>
        <div className="user_view_container">
          <InnerContainer>
            {loading ? (
              <LoadingSpinnerTable />
            ) : (
              <LoadAndError loader={loading} error={error}>
                <div>
                  <h3 className="dark_grey_color fn_Montserrat fw_500 fs_13 py-3 white_shade_bg">
                    {" "}
                    <span className="ps-4 ps-lg-4">Personal Information</span>
                  </h3>
                  <div className="ps-4 ps-lg-4 ms-2 pt-3">
                    <h5 className="dark_grey_color text-nowrap fs_13 fn_Montserrat fw_500">
                      User ID
                    </h5>
                    <p
                      className={` fs_13 fn_Nunito text-nowrap fw_500 secondary_color`}
                    >
                      {location?.state?.id}
                    </p>
                  </div>

                  <Formik
                    innerRef={formRef}
                    initialValues={initialValues}
                    onSubmit={onSubmit}
                    enableReinitialize={true}
                    validationSchema={loginSchema}
                  >
                    {({ formik, handleChange, values, setFieldValue }) => {
                      return (
                        <Form className="">
                          <div className="ps-4 ps-lg-4 ms-0 ms-lg-2 user-form-width">
                            <InputField
                              id={"name"}
                              label={"Name"}
                              type={"text"}
                              placeholder={"Enter Name"}
                              name={"name"}
                            />
                            <InputField
                              id={"dob"}
                              label={"Date Of Birth"}
                              type={"date"}
                              placeholder={"Select Date"}
                              name={"dob"}
                            />
                            <InputField
                              id={"fatherName"}
                              label={"Father's Name"}
                              type={"text"}
                              placeholder={"Enter Father's Name"}
                              name={"fatherName"}
                            />
                            <InputField
                              id={"email"}
                              label={"E-Mail"}
                              type={"text"}
                              placeholder={"Enter Email ID"}
                              name={"email"}
                            />
                            <InputField
                              id={"phone"}
                              label={"Phone Number"}
                              type={"text"}
                              placeholder={"Enter Phone Number"}
                              name={"phone"}
                            />
                            <InputField
                              id={"countryCode"}
                              label={"Country Code"}
                              type={"text"}
                              placeholder={"Enter Country Code"}
                              name={"countryCode"}
                            />
                            <SelectFields
                              default1={{
                                label:
                                  userDetails?.residential_status ===
                                    "residential"
                                    ? "Residential"
                                    : "NonResidential",
                                value: userDetails?.residential_status,
                              }}
                              Icon={
                                <ArrowDownIcon
                                  width={20}
                                  height={20}
                                  fill={"#A5A5A5"}
                                />
                              }
                              label={"Residential status"}
                              placeholder={"Select Residential status"}
                              name={"residentialStatus"}
                              options={residentialOptions}
                              id={"residentialStatus"}
                            />
                            <InputField
                              id={"din"}
                              label={"Director Indentification Number-DIN"}
                              type={"text"}
                              placeholder={"Enter DIN(optional)"}
                              name={"din"}
                            />
                            <div>
                              <span className="mb-2 dark_grey_color fn_Montserrat fw_500 fs_14 ps-2">
                                {" "}
                                Profile Picture
                              </span>
                              <div className="blog_create_image_container mt-1 position-relative d-flex  justify-content-center align-items-center">
                                <input
                                  multiple={false}
                                  style={{ visibility: "hidden" }}
                                  type="file"
                                  id="profileImage"
                                  className="upload_document_input"
                                  name="profileImage"
                                  accept="image/* ,capture=camera"
                                  onChange={(e) => {
                                    imageUpload(e);
                                  }}
                                />
                                {profileImageLink.img ? (
                                  <>
                                    <img
                                      src={profileImageLink.img}
                                      className={"user-edit-profile-picture"}
                                    />
                                    <button
                                      type="button"
                                      onClick={(e) => {
                                        setProfileImageLink({
                                          img: "",
                                          error: true,
                                        });
                                      }}
                                      className="position-absolute border-0 cursor_pointer white_bg d-flex gap-2 py-1 px-2 user_edit_profile_picture_remove_container"
                                    >
                                      <CloseIcon fill="#666666" />
                                      <span className="dark_grey_color fs_13 fn_Montserrat fw_500 ">
                                        Remove
                                      </span>
                                    </button>
                                  </>
                                ) : (
                                  <label
                                    htmlFor="profileImage"
                                    className="d-flex flex-column align-items-center mt-2 justify-content-center fs_12"
                                  >
                                    <UploadIcon width={30} height={30} />
                                    Choose Profile Picture
                                  </label>
                                )}
                              </div>
                              {profileImageLink.error && (
                                <span className="fs_12 ms-2 text-danger fw-semibold fn_Nunito">
                                  Required!
                                </span>
                              )}
                            </div>
                          </div>
                          <h3 className="dark_grey_color fn_Montserrat fw_500 mt-4 fs_13 py-3 white_shade_bg">
                            {" "}
                            <span className="ps-4">Investment Preference</span>
                          </h3>
                          <div className="ps-4 ms-2 mt-4 user-form-width">
                            <SelectFields
                              Icon={
                                <ArrowDownIcon
                                  width={20}
                                  height={20}
                                  fill={"#A5A5A5"}
                                />
                              }
                              default1={{
                                label:
                                  userDetails?.deal_type_preference ===
                                    "short_term"
                                    ? "Short term"
                                    : "Long term",
                                value: userDetails?.deal_type_preference,
                              }}
                              label={"Deal Type Preference"}
                              placeholder={"Select Deal Type Preference"}
                              name={"dealType"}
                              options={furnishingOptions}
                              id={"dealType"}
                            />
                            <InputField
                              id={"investmentValue"}
                              label={"Investment Value"}
                              type={"text"}
                              placeholder={
                                "Enter Investment Value(Integer Value: 5000)"
                              }
                              name={"investmentValue"}
                            />
                          </div>
                          <h3 className="dark_grey_color fn_Montserrat fw_500 mt-4 fs_13 py-3 white_shade_bg">
                            {" "}
                            <span className="ps-4">KYC</span>
                          </h3>
                          <div className="ps-4 ms-2 user-form-width">
                            <div className={`w-75`}>
                              <Field name="kyc">
                                {({ form, meta, Field, default1 }) => (
                                  <Select
                                    Icon={
                                      <ArrowRightIcon
                                        width={15}
                                        fill={"#A5A5A5"}
                                      />
                                    }
                                    defaultValue={{
                                      label: userDetails?.kyc_process_status,
                                      value: userDetails?.kyc_process_status,
                                    }}
                                    value={
                                      changed == false
                                        ? {
                                          label:
                                            userDetails?.kyc_process_status,
                                          value:
                                            userDetails?.kyc_process_status,
                                        }
                                        : kycstatus.find(
                                          (c) => c.value === selectedOption
                                        )
                                    }
                                    name="kyc"
                                    id="kyc"
                                    {...Field}
                                    components={{ DropdownIndicator }}
                                    styles={customStyles}
                                    type="text"
                                    className={
                                      meta.touched && meta.error
                                        ? `kyc_select-input-field w-100 kyc_selectField__border       focus_outline__none`
                                        : `kyc_select-input-field w-100     kyc_selectField__border   focus_outline__none`
                                    }
                                    // placeholder="SELECT KYC"
                                    options={kycstatus}
                                    onChange={(selectedOption) =>
                                      form.setFieldValue(
                                        selectedOption.value,
                                        whenChanged(true)
                                      )
                                    }
                                  />
                                )}
                              </Field>
                              <ErrorMsg name="kyc" />
                            </div>
                          </div>
                          <h3 className="dark_grey_color fn_Montserrat fw_500 mt-4 fs_13 py-3 white_shade_bg">
                            {" "}
                            <span className="ps-4">Address</span>
                          </h3>
                          <div className="ps-4 ms-2 mt-3 user-form-width">
                            <SelectFields
                              default1={{
                                label: userDetails?.address_proof?.proof_type,
                                value: userDetails?.address_proof?.proof_type,
                              }}
                              Icon={
                                <ArrowDownIcon
                                  width={20}
                                  height={20}
                                  fill={"#A5A5A5"}
                                />
                              }
                              label={"Select Proof Type"}
                              placeholder={"Select Proof Type"}
                              name={"proofType"}
                              options={proofType}
                              id={"proofType"}
                            />
                            <div className="mt-2 d-flex flex-column flex-lg-row gap-4 gap-lg-3 gap-xl-3">
                              <div>
                                <span className="mb-2 dark_grey_color fn_Montserrat fw_500 fs_14 ps-2">
                                  Front Image
                                </span>
                                <div className="blog_create_image_container mt-1 position-relative d-flex  justify-content-center align-items-center">
                                  <input
                                    multiple={false}
                                    style={{ visibility: "hidden" }}
                                    type="file"
                                    id="addressFrontImageLink"
                                    className="upload_document_input"
                                    name="addressFrontImageLink"
                                    accept="image/* ,capture=camera"
                                    onChange={(e) => {
                                      imageUpload(e);
                                    }}
                                  />
                                  {addressFrontImageLink.img ? (
                                    <>
                                      <img
                                        src={addressFrontImageLink.img}
                                        className={"user-edit-profile-picture"}
                                      />
                                      <button
                                        type="button"
                                        onClick={(e) => {
                                          setAddressFrontImageLink({
                                            img: "",
                                            error: true,
                                          });
                                        }}
                                        className="position-absolute border-0 cursor_pointer white_bg d-flex gap-2 py-1 px-2 user_edit_profile_picture_remove_container"
                                      >
                                        <CloseIcon fill="#666666" />
                                        <span className="dark_grey_color fs_13 fn_Montserrat fw_500 ">
                                          Remove
                                        </span>
                                      </button>
                                    </>
                                  ) : (
                                    <label
                                      htmlFor="addressFrontImageLink"
                                      className="d-flex flex-column align-items-center mt-2 justify-content-center fs_12"
                                    >
                                      <UploadIcon width={30} height={30} />
                                      Choose Profile Picture
                                    </label>
                                  )}
                                </div>
                                {addressBackImageLink.error && (
                                  <span className="fs_12 ms-2 text-danger fw-semibold fn_Nunito">
                                    Required!
                                  </span>
                                )}
                              </div>
                              <div>
                                <span className="mb-2 dark_grey_color fn_Montserrat fw_500 fs_14 ps-2">
                                  {" "}
                                  Back Image
                                </span>
                                <div className="blog_create_image_container mt-1 position-relative d-flex  justify-content-center align-items-center">
                                  <input
                                    multiple={false}
                                    style={{ visibility: "hidden" }}
                                    type="file"
                                    id="addressBackImageLink"
                                    className="upload_document_input"
                                    name="addressBackImageLink"
                                    accept="image/* ,capture=camera"
                                    onChange={(e) => {
                                      imageUpload(e);
                                    }}
                                  />
                                  {addressBackImageLink.img ? (
                                    <>
                                      <img
                                        src={addressBackImageLink.img}
                                        className={"user-edit-profile-picture"}
                                      />
                                      <button
                                        type="button"
                                        onClick={(e) => {
                                          setAddressBackImageLink({
                                            img: "",
                                            error: true,
                                          });
                                        }}
                                        className="position-absolute border-0 cursor_pointer white_bg d-flex gap-2 py-1 px-2 user_edit_profile_picture_remove_container"
                                      >
                                        <CloseIcon fill="#666666" />
                                        <span className="dark_grey_color fs_13 fn_Montserrat fw_500 ">
                                          Remove
                                        </span>
                                      </button>
                                    </>
                                  ) : (
                                    <label
                                      htmlFor="addressBackImageLink"
                                      className="d-flex flex-column align-items-center mt-2 justify-content-center fs_12"
                                    >
                                      <UploadIcon width={30} height={30} />
                                      Choose Profile Picture
                                    </label>
                                  )}
                                </div>
                                {addressBackImageLink.error && (
                                  <span className="fs_12 ms-2 text-danger fw-semibold fn_Nunito">
                                    Required!
                                  </span>
                                )}
                              </div>
                            </div>
                          </div>
                          <h3 className="dark_grey_color fn_Montserrat fw_500 mt-4 fs_13 py-3 white_shade_bg">
                            {" "}
                            <span className="ps-4">PAN Details</span>
                          </h3>
                          <div className="ps-4 ms-2 user-form-width">
                            <InputField
                              id={"pan"}
                              label={"PAN Number"}
                              type={"text"}
                              placeholder={"Enter PAN Number"}
                              name={"pan"}
                            />

                            <div>
                              <span className="mb-2 dark_grey_color fn_Montserrat fw_500 fs_14 ps-2">
                                PAN Image
                              </span>
                              <div className="blog_create_image_container mt-3 position-relative d-flex  justify-content-center align-items-center">
                                <input
                                  multiple={false}
                                  style={{ visibility: "hidden" }}
                                  type="file"
                                  id="panImageLink"
                                  className="upload_document_input"
                                  name="panImageLink"
                                  accept="image/* ,capture=camera"
                                  onChange={(e) => {
                                    imageUpload(e);
                                  }}
                                />
                                {panImageLink.img ? (
                                  <>
                                    <img
                                      src={panImageLink.img}
                                      className={"user-edit-profile-picture"}
                                    />
                                    <button
                                      type="button"
                                      onClick={(e) => {
                                        setPanImageLink({
                                          img: "",
                                          error: true,
                                        });
                                      }}
                                      className="position-absolute border-0 cursor_pointer white_bg d-flex gap-2 py-1 px-2 user_edit_profile_picture_remove_container"
                                    >
                                      <CloseIcon fill="#666666" />
                                      <span className="dark_grey_color fs_13 fn_Montserrat fw_500 ">
                                        Remove
                                      </span>
                                    </button>
                                  </>
                                ) : (
                                  <label
                                    htmlFor="panImageLink"
                                    className="d-flex flex-column align-items-center mt-2 justify-content-center fs_12"
                                  >
                                    <UploadIcon width={30} height={30} />
                                    Choose Profile Picture
                                  </label>
                                )}
                              </div>
                              {panImageLink.error && (
                                <span className="fs_12 ms-2 text-danger fw-semibold fn_Nunito">
                                  Required!
                                </span>
                              )}
                            </div>
                          </div>
                          <h3 className="dark_grey_color fn_Montserrat fw_500 mt-4 fs_13 py-3 white_shade_bg">
                            {" "}
                            <span className="ps-4">Bank Account Details</span>
                          </h3>
                          <div className="ps-4 ms-2 mt-3 user-form-width">
                            <InputField
                              id={"bankAccountNumber"}
                              label={"Bank Account Number"}
                              type={"text"}
                              placeholder={"Enter Bank Account Number"}
                              name={"bankAccountNumber"}
                            />
                            <InputField
                              id={"bankAccountHolderName"}
                              label={"Bank Account Holder Name"}
                              type={"text"}
                              placeholder={"Enter Bank Account Holder Name"}
                              name={"bankAccountHolderName"}
                            />
                            <InputField
                              id={"accountType"}
                              label={"Account Type"}
                              type={"text"}
                              placeholder={"Enter Account Type "}
                              name={"accountType"}
                            />
                            <InputField
                              id={"bankName"}
                              label={"Bank Name"}
                              type={"text"}
                              placeholder={"Enter bank Name"}
                              name={"bankName"}
                            />

                            <InputField
                              id={"bankIfsc"}
                              label={"Bank IFSC"}
                              type={"text"}
                              placeholder={"Enter IFSC Code"}
                              name={"bankIfsc"}
                            />
                            <SelectFields
                              Icon={
                                <ArrowDownIcon
                                  width={20}
                                  height={20}
                                  fill={"#A5A5A5"}
                                />
                              }
                              default1={{
                                label:
                                  userDetails?.bank_account_details
                                    ?.document_type,
                                value:
                                  userDetails?.bank_account_details
                                    ?.document_type,
                              }}
                              label={"Proof Type"}
                              placeholder={"Select Proof Type"}
                              name={"bankProof"}
                              options={bankproofType}
                              id={"bankProof"}
                            />
                            <div>
                              <span className=" dark_grey_color fn_Montserrat fw_500 fs_14 ps-2">
                                BANK Image
                              </span>
                              <div className="blog_create_image_container mt-3 position-relative d-flex  justify-content-center align-items-center">
                                <input
                                  multiple={false}
                                  style={{ visibility: "hidden" }}
                                  type="file"
                                  id="bankImageLink"
                                  className="upload_document_input"
                                  name="bankImageLink"
                                  accept="image/* ,capture=camera"
                                  onChange={(e) => {
                                    imageUpload(e);
                                  }}
                                />
                                {bankImageLink.img ? (
                                  <>
                                    <img
                                      src={bankImageLink.img}
                                      className={"user-edit-profile-picture"}
                                    />
                                    <button
                                      type="button"
                                      onClick={(e) => {
                                        setBankImageLink({
                                          img: "",
                                          error: true,
                                        });
                                      }}
                                      className="position-absolute border-0 cursor_pointer white_bg d-flex gap-2 py-1 px-2 user_edit_profile_picture_remove_container"
                                    >
                                      <CloseIcon fill="#666666" />
                                      <span className="dark_grey_color fs_13 fn_Montserrat fw_500 ">
                                        Remove
                                      </span>
                                    </button>
                                  </>
                                ) : (
                                  <label
                                    htmlFor="bankImageLink"
                                    className="d-flex flex-column align-items-center mt-2 justify-content-center fs_12"
                                  >
                                    <UploadIcon width={30} height={30} />
                                    Choose Profile Picture
                                  </label>
                                )}
                              </div>
                              {bankImageLink.error && (
                                <span className="fs_12 ms-2 text-danger fw-semibold fn_Nunito">
                                  Required!
                                </span>
                              )}
                            </div>
                          </div>
                        </Form>
                      );
                    }}
                  </Formik>
                </div>
                <DeleteModal
                  loader={loadingDelete}
                  handleClose={handleClose}
                  deleteFn={handleDelete}
                  data={deleteData}
                  show={show}
                />
              </LoadAndError>
            )}
          </InnerContainer>
        </div>
      </InnerLayout>
    </>
  );
};

export default UserEdit;
export const residentialOptions = [
  { value: "residential", label: "Residential" },
  { value: "non_residential", label: "Non Residential" },
];
export const furnishingOptions = [
  { value: "long_term", label: "Long term" },
  { value: "short_term", label: "Short term" },
];
export const kycstatus = [
  { value: "verified", label: "verified" },
  { value: "failed", label: "failed" },
];
export const proofType = [
  { value: "Aadhar Card", label: "Aadhar Card" },
  { value: "Passport", label: "Passport" },
  { value: "Driving License", label: "Driving License" },
];

export const bankproofType = [{ value: "Passbook", label: "Passbook" }];
export const customStyles = {
  indicatorSeparator: () => ({ display: "none" }),
  control: (base, state) => ({
    ...base,
    color: "#112F6A",
    fontFamily: "Montserrat",
    fontWeight: "600",
    fontSize: "15px",

    width: "100%",
    // Overwrittes the different states of border
    borderColor: state.isFocused ? "transparent" : "transparent",
    // Removes weird border around container
    boxShadow: state.isFocused ? null : null,
    "&:hover": {
      // Overwrittes the different states of border
      // borderColor: state.isFocused ? "#1D72DB" : "#F4F8FB",
    },
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? "#1D72DB" : null,
    borderBottom: "1px solid #F5F5F5",
    fontFamily: "Montserrat",
    fontSize: "15px",
    fontWeight: "500",
    "&:hover": {
      backgroundColor: state.isFocused ? "#1D72DB" : "#1D72DB",
    },
  }),
  placeholder: (defaultStyles) => {
    return {
      ...defaultStyles,
      color: "#000000DE",
      fontFamily: "Montserrat",
      fontSize: "13px",
      fontWeight: "600",
      // top: "0.6rem"
    };
  },
  dropdownIndicator: (provided) => ({
    ...provided,
    svg: {
      fill: "#323D5A",
    },
  }),
};
