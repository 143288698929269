import { call, takeLatest } from "redux-saga/effects";
import { OPPOR_TYPE_APPROVALS_EXPORT, OPPOR_TYPE_APPROVALS_LIST, OPPOR_TYPE_APPROVALS_VIEW, OPPOR_TYPE_APPROVE, OPPOR_TYPE_CREATE, OPPOR_TYPE_DELETE, OPPOR_TYPE_EDIT, OPPOR_TYPE_EXPORT, OPPOR_TYPE_LIST, OPPOR_TYPE_READ, OPPOR_TYPE_REJECT, OPPOR_TYPE_SUBMIT, OPPOR_TYPE_VERSION_EXPORT, OPPOR_TYPE_VERSION_LIST, OPPOR_TYPE_VERSION_VIEW } from "../actions/types";
import * as opporTypeApis from "../apis/opportunityTypeApis";

export function* addOpporTypeWorker(action) {
    try {
        const res = yield call(opporTypeApis.addOpporTypeApi, {
            opportunity_type_name: action.name
        });
        // console.log(res, "AddAdminRole");
        if (res.status === 200 && res.data.status === "success") {
            yield action.onSuccess(res.data);
            console.log(res.data);
        } else if (res.status === 200 && res.data.status === "fail") {
            console.log(res.data);
            yield action.onError(res);
        } else {
            yield action.onError("Something Went Wrong");
        }
    } catch (err) {
        console.log(err);
        yield action.onError("Something Went Wrong");
    }
}
export function* editOpporTypeWorker(action) {

    try {
        const res = yield call(opporTypeApis.editOpporTypeApi, action.id, {
            name: action.name,
        });
        // console.log(res, "AddAdminRole");
        if (res.status === 200 && res.data.status === "success") {
            yield action.onSuccess(res.data);
            console.log(res.data);
        } else if (res.status === 200 && res.data.status === "fail") {
            console.log(res);
            yield action.onError(res);
        } else {
            yield action.onError("Something Went Wrong");
        }
    } catch (err) {
        console.log(err);
        yield action.onError("Something Went Wrong");
    }

}

export function* deleteOpporTypeWorker(action) {

    try {
        const res = yield call(opporTypeApis.deleteOpporTypeApi, action.id,
        );
        // console.log(res, "AddAdminRole");
        if (res.status === 200 && res.data.status === "success") {
            yield action.onSuccess(res.data);
            console.log(res.data);
        } else if (res.status === 200 && res.data.status === "fail") {
            console.log(res);
            yield action.onError(res);
        } else {
            yield action.onError("Something Went Wrong");
        }
    } catch (err) {
        console.log(err);
        yield action.onError("Something Went Wrong");
    }

}

export function* viewOpporTypeWorker(action) {
    try {
        const res = yield call(opporTypeApis.viewOpporTypeApi,
            action.id,
        );
        // console.log(res, "AddAdminRole");
        if (res.status === 200 && res.data.status === "success") {
            yield action.onSuccess(res.data);
            console.log(res.data);
        } else if (res.status === 200 && res.data.status === "fail") {
            console.log(res.data);
            yield action.onError(res);
        } else {
            yield action.onError("Something Went Wrong!");
            console.log(res.data);
        }
    } catch (err) {
        console.log(err);
        yield action.onError("Something Went Wrong!!");
    }
}
export function* viewAllOpporTypeWorker(action) {
    try {
        const res = yield call(opporTypeApis.viewAllOpporTypeApi,
            action.pageNo,
            action.data,
        );
        if (res.status === 200 && res.data.status === "success") {
            yield action.onSuccess(res.data);
            console.log(res);
        } else if (res.status === 200 && res.data.status === "fail") {
            console.log(res);
            yield action.onError(res.data);
        } else {
            yield action.onError("Something Went Wrong");
        }
    } catch (err) {
        console.log(err);
        yield action.onError("Something Went Wrong");
    }

}
export function* rejectOpporTypeWorker(action) {
    try {
        const res = yield call(opporTypeApis.rejectOpporTypeApi,
            action.id
        );
        if (res.status === 200 && res.data.status === "success") {
            yield action.onSuccess(res.data);
            console.log(res.data);
        } else if (res.status === 200 && res.data.status === "fail") {
            console.log(res);
            yield action.onError(res.data);
        } else {
            yield action.onError("Something Went Wrong!");
            console.log(res.data);
        }
    } catch (err) {
        console.log(err);
        yield action.onError("Something Went Wrong!!");
    }
}
export function* approveOpporTypeWorker(action) {
    try {
        const res = yield call(opporTypeApis.approveOpporTypeApi,
            { opportunity_id: action.id }
        );
        // console.log(res, "AddAdminRole");
        if (res.status === 200 && res.data.status === "success") {
            yield action.onSuccess(res.data);
            console.log(res.data);
        } else if (res.status === 200 && res.data.status === "fail") {
            console.log(res);
            yield action.onError(res.data);
        } else {
            yield action.onError("Something Went Wrong!");
            console.log(res.data);
        }
    } catch (err) {
        console.log(err);
        yield action.onError("Something Went Wrong!!");
    }
}
export function* viewApprovalOpporTypeWorker(action) {
    try {
        const res = yield call(opporTypeApis.viewApprovalOpporTypeApi,
            action.id
        );
        // console.log(res, "AddAdminRole");
        if (res.status === 200 && res.data.status === "success") {
            yield action.onSuccess(res.data);
            console.log(res.data);
        } else if (res.status === 200 && res.data.status === "fail") {
            console.log(res);
            yield action.onError(res);
        } else {
            yield action.onError("Something Went Wrong!");
            console.log(res.data);
        }
    } catch (err) {
        console.log(err);
        yield action.onError("Something Went Wrong!!");
    }
}
export function* approvalsListOpporTypeWorker(action) {
    try {
        const res = yield call(opporTypeApis.approvalsListOpporTypeApi,
            action.pageNo,
            action.data,
        );
        if (res.status === 200 && res.data.status === "success") {
            yield action.onSuccess(res.data);
            console.log(res.data);
        } else if (res.status === 200 && res.data.status === "fail") {
            console.log(res);
            yield action.onError(res);
        } else {
            yield action.onError("Something Went Wrong!");
            console.log(res.data);
        }
    } catch (err) {
        console.log(err);
        yield action.onError("Something Went Wrong!!");
    }
}
export function* versionHistoryListOpporTypeWorker(action) {
    try {
        const res = yield call(opporTypeApis.versionHistoryListOpporTypeApi,
            action.pageNo,
            action.data,

        );
        if (res.status === 200 && res.data.status === "success") {
            yield action.onSuccess(res.data);
            console.log(res.data);
        } else if (res.status === 200 && res.data.status === "fail") {
            console.log(res);
            yield action.onError(res);
        } else {
            yield action.onError("Something Went Wrong!");
            console.log(res.data);
        }
    } catch (err) {
        console.log(err);
        yield action.onError("Something Went Wrong!!");
    }
}
export function* viewVersionHistoryOpporTypeWorker(action) {
    try {
        const res = yield call(opporTypeApis.viewVersionHistoryOpporTypeApi,
            action.id
        );
        if (res.status === 200 && res.data.status === "success") {
            yield action.onSuccess(res);
            console.log(res);
        } else if (res.status === 200 && res.data.status === "fail") {
            console.log(res);
            yield action.onError(res);
        } else {
            yield action.onError("Something Went Wrong!");
            console.log(res);
        }
    } catch (err) {
        console.log(err);
        yield action.onError("Something Went Wrong!!");
    }
}

//export
export function* opporTypeTableExportWorker(action) {
    try {
        const res = yield call(opporTypeApis.opporTypeTableExportApi,

        );
        if (res.status === 200 && res.data.status === "success") {
            yield action.onSuccess(res.data);
        } else {
            yield action.onError(res.data);
        }
    } catch (error) { }
}


export function* opporTypeApprovalsExportWorker(action) {
    try {
        const res = yield call(opporTypeApis.opporTypeApprovalsExportApi);
        console.log(res);
        if (res.status === 200 && res.data.status === "success") {
            console.log(res.data);
            yield action.onSuccess(res.data.data);
        } else {
            yield action.onError(res.data);
        }
    } catch (error) { }
}

export function* opporTypeVersionExportWorker(action) {
    try {
        const res = yield call(opporTypeApis.opporTypeVersionExportApi);
        console.log(res);
        if (res.status === 200 && res.data.status === "success") {
            yield action.onSuccess(res.data);
        } else {
            yield action.onError(res.data);
        }
    } catch (error) { }
}


export function* opporTypeSubmitWorker(action) {
    try {
        const res = yield call(opporTypeApis.opporTypeSubmitApi, action.id);
        console.log(res);
        if (res.status === 200 && res.data.status === "success") {
            yield action.onSuccess(res.data);
        } else {
            yield action.onError(res.data);
        }
    } catch (error) { }
}







export function* watchAddOpporTypeWorker() {
    yield takeLatest(OPPOR_TYPE_CREATE, addOpporTypeWorker);
}
export function* watchEditOpporTypeWorker() {
    yield takeLatest(OPPOR_TYPE_EDIT, editOpporTypeWorker);
}
export function* watchDeleteOpporTypeWorker() {
    yield takeLatest(OPPOR_TYPE_DELETE, deleteOpporTypeWorker);
}
export function* watchViewOpporTypeWorker() {
    yield takeLatest(OPPOR_TYPE_READ, viewOpporTypeWorker);
}

export function* watchViewAllOpporTypesWorker() {
    yield takeLatest(OPPOR_TYPE_LIST, viewAllOpporTypeWorker);

}

export function* watchRejectOpporTypeWorker() {
    yield takeLatest(OPPOR_TYPE_REJECT, rejectOpporTypeWorker);
}

export function* watchApproveOpporTypeWorker() {
    yield takeLatest(OPPOR_TYPE_APPROVE, approveOpporTypeWorker);
}
export function* watchViewApprovalOpporTypeWorker() {
    yield takeLatest(OPPOR_TYPE_APPROVALS_VIEW, viewApprovalOpporTypeWorker);
}
export function* watchApprovalsListOpporTypeWorker() {
    yield takeLatest(OPPOR_TYPE_APPROVALS_LIST, approvalsListOpporTypeWorker);
}

export function* watchVersionHistoryListOpporTypeWorker() {
    yield takeLatest(OPPOR_TYPE_VERSION_LIST, versionHistoryListOpporTypeWorker);
}
export function* watchViewVersionHistoryOpporTypeWorker() {
    yield takeLatest(OPPOR_TYPE_VERSION_VIEW, viewVersionHistoryOpporTypeWorker);

}


//export
export function* opporTypeTableExportWatcher() {
    yield takeLatest(
        OPPOR_TYPE_EXPORT,
        opporTypeTableExportWorker
    );
}
export function* opporTypeApprovalsExportWatcher() {
    yield takeLatest(OPPOR_TYPE_APPROVALS_EXPORT, opporTypeApprovalsExportWorker);
}

export function* opporTypeVersionExportWorkerWatcher() {
    yield takeLatest(OPPOR_TYPE_VERSION_EXPORT, opporTypeVersionExportWorker);
}
export function* opporTypeSubmitWorkerWatcher() {
    yield takeLatest(OPPOR_TYPE_SUBMIT, opporTypeSubmitWorker);
}
