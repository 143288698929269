import { authHeader } from "../config";
import { api } from "./api";

export const addWishlistApi = (params) => {
    return api.post("/api/opportunity-wishlist/create-opportunity-checker", JSON.stringify(params), {
        headers: {
            "x-access-token": localStorage.getItem("accessToken"),
            "Content-Type": "application/json",
        },
    });
};
export const createWishlistApi = (params) => {
    return api.post(`/api/opportunity-wishlist/create-wishlist`, JSON.stringify(params), {
        headers: {
            "x-access-token": localStorage.getItem("accessToken"),
            "Content-Type": "application/json",
        },
    });
};
export const editWishlistApi = (id, params) => {
    return api.post(`/api/opportunity-wishlist/edit-wishlist/${id}`, JSON.stringify(params), {
        headers: {
            "x-access-token": localStorage.getItem("accessToken"),
            "Content-Type": "application/json",
        },
    });
};
export const deleteWishlistApi = (id, params) => {
    return api.post(`/api/opportunity-wishlist/delete-wishlist/${id}`, JSON.stringify(params), {
        headers: {
            "x-access-token": localStorage.getItem("accessToken"),
            "Content-Type": "application/json",
        },
    });
};

export const viewAllWishlistApi = (pageNo, params) => {
    return api.post(`/api/opportunity-wishlist/view-wishlists?page_no=${pageNo}`, JSON.stringify(params), {
        headers: {
            "x-access-token": localStorage.getItem("accessToken"),
            "Content-Type": "application/json",
        },
    });
};
//Find all wishlist for drop down

export const viewFindAllWishListApi = (params) => {
    return api.post(`/api/opportunity-notify/create-NotifyAll`, JSON.stringify(params), {
        headers: {
            "x-access-token": localStorage.getItem("accessToken"),
            "Content-Type": "application/json",
        },
    });
};


export const viewWishlistApi = (id = "", params) => {
    return api.post(`/api/opportunity-wishlist/view-wishlist/${id}`, JSON.stringify(params), {
        headers: {
            "x-access-token": localStorage.getItem("accessToken"),
            "Content-Type": "application/json",
        },
    });
};

export const approveWishlistApi = (params) => {
    return api.post(`/api/opportunity-wishlist/approve-wishlist-checker`, JSON.stringify(params), {
        headers: {
            "x-access-token": localStorage.getItem("accessToken"),
            "Content-Type": "application/json",
        },
    });
};
export const approvalsListWishlistApi = (pageNo, params) => {
    return api.post(`/api/opportunity-wishlist/wishlist-approvals-checker?page_no=${pageNo}`, JSON.stringify(params), {
        headers: {
            "x-access-token": localStorage.getItem("accessToken"),
            "Content-Type": "application/json",
        },
    });
};

export const approvalsWishlistByIdApi = (id = "", params) => {
    return api.post(`/api/opportunity-wishlist/checker-wishlist/${id}`, JSON.stringify(params), {
        headers: {
            "x-access-token": localStorage.getItem("accessToken"),
            "Content-Type": "application/json",
        },
    });
};

export const versionHistoryListWishlistApi = (pageNo = 0, params) => {
    return api.post(`/api/opportunity-wishlist/version-history?page_no=${pageNo}`, JSON.stringify(params), {
        headers: {
            "x-access-token": localStorage.getItem("accessToken"),
            "Content-Type": "application/json",
        },
    });

};
export const viewVersionHistoryWishlistApi = (params) => {
    return api.post(`/api/opportunity/view-version-history`, JSON.stringify(params), {
        headers: {
            "x-access-token": localStorage.getItem("accessToken"),
            "Content-Type": "application/json",
        },
    });
};

export const versionHistoryByIdApi = (id = "", params) => {
    return api.post(`/api/opportunity-wishlist/version-history-id/${id}`, JSON.stringify(params), {
        headers: {
            "x-access-token": localStorage.getItem("accessToken"),
            "Content-Type": "application/json",
        },
    });

};

export const rejectWishlistApi = (id, params) => {
    return api.post(`/api/opportunity-wishlist/reject-wishlist/${id}`, JSON.stringify(params), {
        headers: {
            "x-access-token": localStorage.getItem("accessToken"),
            "Content-Type": "application/json",
        },
    });
};


export const viewWishListExportApi = (params) => {
    return api.post(`/api/opportunity-wishlist/export-wishlist`, JSON.stringify(params), {
        headers: {
            "x-access-token": localStorage.getItem("accessToken"),
            "Content-Type": "application/json",
        },
    });
};



export const wishlistTableExportApi = (params) => {
    return api.post(
        `/api/opportunity-wishlist/export-main-wishlist`,
        JSON.stringify(params),
        {
            headers: {
                "x-access-token": localStorage.getItem("accessToken"),
                "Content-Type": "application/json",
            },
        }
    );
};
export const wishlistApprovalsExportApi = (params) => {
    return api.post(
        `/api/opportunity-wishlist/export-approval-wishlist`,
        JSON.stringify(params),
        {
            headers: {
                "x-access-token": localStorage.getItem("accessToken"),
                "Content-Type": "application/json",
            },
        }
    );
};
export const wishlistVersionExportApi = (params) => {
    return api.post(
        `/api/opportunity-wishlist/export-version-wishlist`,
        JSON.stringify(params),
        {
            headers: {
                "x-access-token": localStorage.getItem("accessToken"),
                "Content-Type": "application/json",
            },
        }
    );
};





export const wishlistSubmitApi = (id, params) => {
    return api.post(
        `/api/opportunity-wishlist/on-submit/${id}`, JSON.stringify(params),
        {
            headers: {
                "x-access-token": localStorage.getItem("accessToken"),
                "Content-Type": "application/json",
            },
        }
    );
};