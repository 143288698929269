import { call, takeLatest } from "redux-saga/effects";
import { WISHLIST_APPROVALS_LIST, WISHLIST_APPROVE, WISHLIST_DELETE, WISHLIST_EDIT, WISHLIST_LIST, WISHLIST_READ, WISHLIST_VERSION_LIST, WISHLIST_CREATE, WISHLIST_FIND_ALL, VIEW_APPROVALS_WISHLIST_ID, WISHLIST_VERSION_HISTORY_BY_ID, WISHLIST_REJECT, WISHLIST_EXPORT, WISHLIST_APPROVALS_EXPORT, WISHLIST_VERSION_EXPORT, WISHLIST_SUBMIT } from "../actions/types";
import * as wishlistApis from "../apis/wishlistApis";

export function* addWishlistWorker(action) {
    try {
        const res = yield call(wishlistApis.addWishlistApi, {
            name: action.name
        });
        // console.log(res, "AddAdminRole");
        if (res.status === 200 && res.data.status === "success") {
            yield action.onSuccess(res.data);
            console.log(res.data);
        } else if (res.status === 200 && res.data.status === "fail") {
            console.log(res.data);
            yield action.onError(res);
        } else {
            yield action.onError("Something Went Wrong");
        }
    } catch (err) {
        console.log(err);
        yield action.onError("Something Went Wrong");
    }

}
export function* editWishlistWorker(action) {

    try {
        const res = yield call(wishlistApis.editWishlistApi, action.id, action.data);
        if (res.status === 200 && res.data.status === "success") {
            yield action.onSuccess(res.data);
            console.log(res.data);
        } else if (res.status === 200 && res.data.status === "fail") {
            console.log(res);
            yield action.onError(res);
        } else {
            yield action.onError("Something Went Wrong");
        }
    } catch (err) {
        console.log(err);
        yield action.onError("Something Went Wrong");
    }

}

export function* deleteWishlistWorker(action) {

    try {
        const res = yield call(wishlistApis.deleteWishlistApi, action.id,
        );
        if (res.status === 200 && res.data.status === "success") {
            yield action.onSuccess(res.data);
            console.log(res.data);
        } else if (res.status === 200 && res.data.status === "fail") {
            console.log(res);
            yield action.onError(res);
        } else {
            yield action.onError("Something Went Wrong");
        }
    } catch (err) {
        console.log(err);
        yield action.onError("Something Went Wrong");
    }

}

export function* viewWishlistWorker(action) {
    try {
        const res = yield call(wishlistApis.viewWishlistApi,
            action.id,
        );
        // console.log(res, "AddAdminRole");
        if (res.status === 200 && res.data.status === "success") {
            yield action.onSuccess(res.data);
            console.log(res.data);
        } else if (res.status === 200 && res.data.status === "fail") {
            console.log(res.data);
            yield action.onError(res);
        } else {
            yield action.onError("Something Went Wrong!");
            console.log(res.data);
        }
    } catch (err) {
        console.log(err);
        yield action.onError("Something Went Wrong!!");
    }
}
export function* viewAllWishlistWorker(action) {

    try {
        const res = yield call(wishlistApis.viewAllWishlistApi,
            action.page_no,
            action.data,
        );
        // console.log(res, "AddAdminRole");
        if (res.status === 200 && res.data.status === "success") {
            yield action.onSuccess(res.data);
            console.log(res);
        } else if (res.status === 200 && res.data.status === "fail") {
            console.log(res);
            yield action.onError(res.data);
        } else {
            yield action.onError("Something Went Wrong");
        }
    } catch (err) {
        console.log(err);
        yield action.onError("Something Went Wrong");
    }

}
export function* approveWishlistWorker(action) {
    try {
        const res = yield call(wishlistApis.approveWishlistApi,
            { wishlist_id: action.id }
        );
        // console.log(res, "AddAdminRole");
        if (res.status === 200 && res.data.status === "success") {
            yield action.onSuccess(res.data);
            console.log(res.data);
        } else if (res.status === 200 && res.data.status === "fail") {
            console.log(res);
            yield action.onError(res.data);
        } else {
            yield action.onError("Something Went Wrong!");
            console.log(res.data);
        }
    } catch (err) {
        console.log(err);
        yield action.onError("Something Went Wrong!!");
    }
}
export function* approvalsListWishlistWorker(action) {
    try {
        const res = yield call(wishlistApis.approvalsListWishlistApi,
            action.pageNo,
            action.data,
        );
        if (res.status === 200 && res.data.status === "success") {
            yield action.onSuccess(res.data);
            console.log(res.data);
        } else if (res.status === 200 && res.data.status === "fail") {
            console.log(res);
            yield action.onError(res);
        } else {
            yield action.onError("Something Went Wrong!");
            console.log(res.data);
        }
    } catch (err) {
        console.log(err);
        yield action.onError("Something Went Wrong!!");
    }
}
export function* versionHistoryListWishlistWorker(action) {
    try {
        const res = yield call(wishlistApis.versionHistoryListWishlistApi,
            action.pageNo,
            action.data,
        );
        if (res.status === 200 && res.data.status === "success") {
            yield action.onSuccess(res.data);
            console.log(res.data);
        } else if (res.status === 200 && res.data.status === "fail") {
            console.log(res);
            yield action.onError(res);
        } else {
            yield action.onError("Something Went Wrong!");
            console.log(res.data);
        }
    } catch (err) {
        yield action.onError("Something Went Wrong!!");
    }
}
export function* createWishlistWorker(action) {
    try {
        const res = yield call(wishlistApis.createWishlistApi, action.data
        );
        if (res.status === 200 && res.data.status === "success") {
            yield action.onSuccess(res);
            console.log(res);
        } else if (res.status === 200 && res.data.status === "fail") {
            console.log(res);
            yield action.onError(res.data);
        } else {
            yield action.onError("Something Went Wrong!");
            console.log(res);
        }
    } catch (err) {
        console.log(err);
        yield action.onError("Something Went Wrong!!");
    }
}

export function* wishListFindAllWorker(action) {

    try {
        const res = yield call(wishlistApis.viewFindAllWishListApi);
        // console.log(res, "AddAdminRole");
        if (res.status === 200 && res.data.status === "success") {
            yield action.onSuccess(res.data);
            console.log(res.data);
        } else if (res.status === 200 && res.data.status === "fail") {
            console.log(res);
            yield action.onError(res.data);
        } else {
            yield action.onError("Something Went Wrong");
        }
    } catch (err) {
        console.log(err);
        yield action.onError("Something Went Wrong");
    }

}


export function* wishListApprovalViewByIdWorker(action) {
    try {
        const res = yield call(wishlistApis.approvalsWishlistByIdApi, action.id,
        );
        if (res.status === 200 && res.data.status === "success") {
            yield action.onSuccess(res);
            console.log(res);
        } else if (res.status === 200 && res.data.status === "fail") {
            console.log(res);
            yield action.onError(res);
        } else {
            yield action.onError("Something Went Wrong!");
            console.log(res);
        }
    } catch (err) {
        console.log(err);
        yield action.onError("Something Went Wrong!!");
    }
}

export function* wishVersionHistoryByIdWorker(action) {
    try {
        const res = yield call(wishlistApis.versionHistoryByIdApi, action.id,
        );
        if (res.status === 200 && res.data.status === "success") {
            yield action.onSuccess(res);
        } else if (res.status === 200 && res.data.status === "fail") {
            yield action.onError(res);
        } else {
            yield action.onError("Something Went Wrong!");
        }
    } catch (err) {
        console.log(err);
        yield action.onError("Something Went Wrong!!");
    }
}


export function* wishListRejectByIdWorker(action) {
    try {
        const res = yield call(wishlistApis.rejectWishlistApi, action.id,
        );
        if (res.status === 200 && res.data.status === "success") {
            yield action.onSuccess(res);
            console.log(res);
        } else if (res.status === 200 && res.data.status === "fail") {
            console.log(res);
            yield action.onError(res);
        } else {
            yield action.onError("Something Went Wrong!");
            console.log(res);
        }
    } catch (err) {
        console.log(err);
        yield action.onError("Something Went Wrong!!");
    }
}


export function* wishListExportWorker(action) {

    try {
        const res = yield call(wishlistApis.viewWishListExportApi);
        // console.log(res, "AddAdminRole");
        if (res.status === 200 && res.data.status === "success") {
            yield action.onSuccess(res.data);
            console.log(res);
        } else if (res.status === 200 && res.data.status === "fail") {
            console.log(res);
            yield action.onError(res.data);
        } else {
            yield action.onError("Something Went Wrong");
        }
    } catch (err) {
        console.log(err);
        yield action.onError("Something Went Wrong");
    }

}

//export
export function* wishlistTableExportWorker(action) {
    try {
        const res = yield call(wishlistApis.wishlistTableExportApi,

        );
        if (res.status === 200 && res.data.status === "success") {
            yield action.onSuccess(res.data);
        } else {
            yield action.onError(res.data);
        }
    } catch (error) { }
}


export function* wishlistApprovalsExportWorker(action) {
    try {
        const res = yield call(wishlistApis.wishlistApprovalsExportApi);
        console.log(res);
        if (res.status === 200 && res.data.status === "success") {
            console.log(res.data);
            yield action.onSuccess(res.data.data);
        } else {
            yield action.onError(res.data);
        }
    } catch (error) { }
}

export function* wishlistVersionExportWorker(action) {
    try {
        const res = yield call(wishlistApis.wishlistVersionExportApi);
        console.log(res);
        if (res.status === 200 && res.data.status === "success") {
            yield action.onSuccess(res.data);
        } else {
            yield action.onError(res.data);
        }
    } catch (error) { }
}


export function* wishlistSubmitWorker(action) {
    try {
      const res = yield call(wishlistApis.wishlistSubmitApi, action.id);
      console.log(res);
      if (res.status === 200 && res.data.status === "success") {
        yield action.onSuccess(res.data);
      } else {
        yield action.onError(res.data);
      }
    } catch (error) { }
  }
  

// export function* watchAddWishlistWorker() {
//     yield takeLatest(OPPOR_TYPE_CREATE, addWishlistWorker);
// }
export function* watchCreateWishlistWorker() {
    yield takeLatest(WISHLIST_CREATE, createWishlistWorker);
}

export function* watchEditWishlistWorker() {
    yield takeLatest(WISHLIST_EDIT, editWishlistWorker);
}
export function* watchDeleteWishlistWorker() {
    yield takeLatest(WISHLIST_DELETE, deleteWishlistWorker);
}
export function* watchViewWishlistWorker() {
    yield takeLatest(WISHLIST_READ, viewWishlistWorker);
}

export function* watchViewAllWishlistWorker() {
    yield takeLatest(WISHLIST_LIST, viewAllWishlistWorker);
}

export function* watchApproveWishlistWorker() {
    yield takeLatest(WISHLIST_APPROVE, approveWishlistWorker);
}
export function* watchApprovalsListWishlistWorker() {
    yield takeLatest(WISHLIST_APPROVALS_LIST, approvalsListWishlistWorker);
}

export function* watchVersionHistoryListWishlistWorker() {
    yield takeLatest(WISHLIST_VERSION_LIST, versionHistoryListWishlistWorker);
}

//Find all section
export function* watchWishListFindAllWorker() {
    yield takeLatest(WISHLIST_FIND_ALL, wishListFindAllWorker);
}


export function* watchViewWishListApprovalByIdWorker() {
    yield takeLatest(VIEW_APPROVALS_WISHLIST_ID, wishListApprovalViewByIdWorker);
}

export function* watchViewVersionHistorylByIdWorker() {
    yield takeLatest(WISHLIST_VERSION_HISTORY_BY_ID, wishVersionHistoryByIdWorker);
}

export function* watchWishListRejectByIdWorker() {
    yield takeLatest(WISHLIST_REJECT, wishListRejectByIdWorker);
}





//export
export function* wishlistTableExportWatcher() {
    yield takeLatest(
        WISHLIST_EXPORT,
        wishlistTableExportWorker
    );
}
export function* wishlistApprovalsExportWatcher() {
    yield takeLatest(WISHLIST_APPROVALS_EXPORT, wishlistApprovalsExportWorker);
}

export function* wishlistVersionExportWorkerWatcher() {
    yield takeLatest(WISHLIST_VERSION_EXPORT, wishlistVersionExportWorker);
}
export function* wishlistSubmitWorkerWatcher() {
    yield takeLatest(WISHLIST_SUBMIT, wishlistSubmitWorker);
}


