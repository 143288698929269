import React, { useEffect, useState } from "react";
import errorToast from "../../../../components/utilits/errorToast";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import RejectBtn from "../../../../components/buttons/rejectBtn";
import VersionHistoryBtn from "../../../../components/buttons/versionHistoryBtn";
import InnerContainer from "../../../../components/layouts/innerContainer/innerContainer";
import InnerLayout from "../../../../components/layouts/innerLayout/innerLayout";
import "../notify-me.css";
import successToast from "../../../../components/utilits/successToast";
import {
  approveNotifyMe,
  readApprovalNotifyMe,
  rejectNotifyMe,
} from "../../../../redux/actions/notifyMeActions";
import ApproveBtn from "../../../../components/buttons/approveBtn";
import LoadingSpinnerTable from "../../../../components/utilits/loadingSpinnerTable";
import moment from "moment";
import LoadAndError from "../../../../components/utilits/LoadAndError";
const NotifyMeApprovalView = () => {

  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false)
  const [notifyMeApprovalViewData, SetNotifyMeApprovalView] = useState({
    id: "",
    name: "",
    createdAt: "",
    updatedAt: "",
  });

  // View version History Data
  useEffect(() => {
    setLoading(true);
    dispatch(readApprovalNotifyMe(params.id, onSuccess, onError));
  }, [params.id]);
  const onSuccess = (data) => {
    SetNotifyMeApprovalView(data?.data);
    console.log(notifyMeApprovalViewData, "kfjfdkhf");
    console.log(data.data);
    setLoading(false);
    setError(false)
  };
  const onError = (data) => {
    errorToast(data.message);
    console.log(notifyMeApprovalViewData, "error");
    setLoading(false);
    setError(true)
  };

  //Approve Notify Me
  const [updated, setUpdated] = useState(false);

  const approveNotifyMeFn = () => {
    dispatch(
      approveNotifyMe(
        params.id,
        onApproveSuccess,
        onApproveError
      )
    );
  };
  console.log(notifyMeApprovalViewData.id);

  const onApproveSuccess = (data) => {
    navigate("/opportunity/notify-me/approvals")
    setUpdated(true);
    successToast("Opportunity Approved Successfully");
  };
  const onApproveError = (data) => {
    console.log(data);
    errorToast(data.message);
  };
  //Reject  Notify Me Api
  const rejectNotifyMeFn = () => {
    dispatch(
      rejectNotifyMe(
        notifyMeApprovalViewData.id,
        onRejectSuccess,
        onRejectError
      )
    );
  };

  const onRejectSuccess = (data) => {
    setUpdated(true);
    successToast("Rejected Successfully");
    navigate("/opportunity/notify-me/approvals")
    setLoading(false)
    setError(false)
  };
  const onRejectError = (data) => {
    console.log(data);
    errorToast(data.message);
    setLoading(false)
    setError(true)
  };
  const buttonList = [
    <VersionHistoryBtn link={`/opportunity/notify-me/version-history`} />,
    <ApproveBtn approveFn={approveNotifyMeFn} />,
    <RejectBtn showModal={rejectNotifyMeFn} />,
  ];
  const data = [
    {
      heading: "Approval ID",
      title: notifyMeApprovalViewData.id,
    },
    {
      heading: "Opportunity ID",
      title: notifyMeApprovalViewData?.new_data == null ? notifyMeApprovalViewData?.old_data?.opportunity_id : notifyMeApprovalViewData?.new_data?.opportunity_id,
    },
    {
      heading: "Opportunity Name",
      title: notifyMeApprovalViewData?.new_data == null ? notifyMeApprovalViewData?.old_data?.title : notifyMeApprovalViewData?.new_data?.title,
    },
    {
      heading: "User ID",
      title: notifyMeApprovalViewData?.new_data == null ? notifyMeApprovalViewData?.old_data?.user_id : notifyMeApprovalViewData?.new_data?.user_id,
    },
    {
      heading: "User Name",
      title: notifyMeApprovalViewData?.new_data == null ? notifyMeApprovalViewData?.old_data?.user_name : notifyMeApprovalViewData?.new_data?.user_name,
    },
    {
      heading: "Notify Me ID",
      title: notifyMeApprovalViewData?.new_data == null ? notifyMeApprovalViewData?.old_data?.id : notifyMeApprovalViewData?.new_data?.id,
    },
    {
      heading: "Created At",
      title: moment(notifyMeApprovalViewData?.createdAt).format("DD-MM-YYYY HH:mm:ss"),
    },

    {
      heading: "Updated At",
      title: moment(notifyMeApprovalViewData?.updatedAt).format("DD-MM-YYYY HH:mm:ss"),
    },
    {
      heading: "Operation Type",
      title: notifyMeApprovalViewData?.new_data == null ? "Delete" : notifyMeApprovalViewData?.new_data && notifyMeApprovalViewData?.old_data ? "Edit" : notifyMeApprovalViewData?.old_data == null ? "Create" : ""
    }
  ];

  return (
    <InnerLayout buttons={buttonList}>
      <div className="notify-me-container">
        <InnerContainer error={error} oldData={notifyMeApprovalViewData?.old_data} newData={notifyMeApprovalViewData?.new_data}>
          {loading ? (
            <LoadingSpinnerTable/>
          ) : (
          <LoadAndError loader={loading} error={error}>
          <div className="pt-3  ps-4 ps-lg-4 ms-3">
            {data.map((item, index) => (
              <div className="mb-2">
                <h5
                  key={item.heading === "Notify Me ID"}
                  className="dark_grey_color  fs_13 fn_Montserrat fw_500 m-0"
                >
                  {item.heading}
                </h5>
                <p
                  className={` fs_13 fn_Nunito fw_500  ${item.heading === "Opportunity ID" ||
                    item.heading === "User ID"
                    ? "secondary_color"
                    : "dark_title_color"
                    } `}
                >
                  {item.title}
                </p>
              </div>
            ))}
          </div>
          </LoadAndError>
          )}
        </InnerContainer>
      </div>
    </InnerLayout>
  );
};

export default NotifyMeApprovalView;
