import { ADMIN_ADD_ROLE, ADMIN_EDIT_ROLE, ADMIN_DELETE_ROLE, ADMIN_VIEW_ROLE, ADMIN_VIEW_ROLES, ADMIN_APPROVE_ROLE, ADMIN_APPROVALS_LIST_ROLE, ADMIN_VERSION_HISTORY_LIST_ROLE, ADMIN_VERSION_HISTORY_VIEW_ROLE, ADMIN_APPROVALS_VIEW, ADMIN_REJECT_ROLE, ROLES_EXPORT, ROLES_APPROVALS_EXPORT, ROLES_VERSION_EXPORT, ADMIN_ROLE_SUBMIT } from "./types";

export const addAdminRole = (data, onSuccess, onError) => {
    return {
        type: ADMIN_ADD_ROLE,
        data,
        onSuccess,
        onError,
    };
};
export const viewAllAdminRole = (data, pageNo, onSuccess, onError) => {
    return {
        type: ADMIN_VIEW_ROLES,
        data,
        pageNo,
        onSuccess,
        onError,
    };
};

export const viewAdminRole = (id, onSuccess, onError) => {
    return {
        type: ADMIN_VIEW_ROLE,
        id,
        onSuccess,
        onError,
    };
}
export const editAdminRole = (id, roleName, permissions, onSuccess, onError) => {
    return {
        type: ADMIN_EDIT_ROLE,
        id,
        roleName,
        permissions,
        onSuccess,
        onError,
    };
}
export const approvalsAdminRoleList = (data, page, onSuccess, onError) => {
    return {
        type: ADMIN_APPROVALS_LIST_ROLE,
        data,
        page,
        onSuccess,
        onError,
    };
}
export const viewApprovalAdminRole = (id, onSuccess, onError) => {
    return {
        type: ADMIN_APPROVALS_VIEW,
        id,
        onSuccess,
        onError,
    };
}
export const approveAdminRole = (id, onSuccess, onError) => {
    return {
        type: ADMIN_APPROVE_ROLE,
        id,
        onSuccess,
        onError,
    };
}
export const rejectAdminRole = (id, onSuccess, onError) => {
    return {
        type: ADMIN_REJECT_ROLE,
        id,
        onSuccess,
        onError,
    };
}
export const deleteAdminRole = (id, onSuccess, onError) => {
    return {
        type: ADMIN_DELETE_ROLE,
        id,
        onSuccess,
        onError,
    };
}
export const versionHistoryListAdminRole = (data, pageNo, onSuccess, onError) => {
    return {
        type: ADMIN_VERSION_HISTORY_LIST_ROLE,
        data,
        pageNo,
        onSuccess,
        onError,
    };
}
export const viewVersionHistoryAdminRole = (id, onSuccess, onError) => {
    return {
        type: ADMIN_VERSION_HISTORY_VIEW_ROLE,
        id,
        onSuccess,
        onError,
    };
}





export const rolesTableExport = (onSuccess, onError) => {
    return {
        type: ROLES_EXPORT,
        onSuccess,
        onError,
    };
};
export const rolesApprovalsExport = (onSuccess, onError) => {
    return {
        type: ROLES_APPROVALS_EXPORT,
        onSuccess,
        onError,
    };
};
export const rolesVersionExport = (onSuccess, onError) => {
    return {
        type: ROLES_VERSION_EXPORT,
        onSuccess,
        onError,
    };
};


export const adminRoleSubmitAction = (id, onSuccess, onError) => {
    return {
        type: ADMIN_ROLE_SUBMIT,
        id,
        onSuccess,
        onError,
    };
};