import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import ApproveBtn from '../../../../components/buttons/approveBtn';
import RejectBtn from '../../../../components/buttons/rejectBtn';
import VersionHistoryBtn from '../../../../components/buttons/versionHistoryBtn';
import InnerContainer from '../../../../components/layouts/innerContainer/innerContainer'
import InnerLayout from '../../../../components/layouts/innerLayout/innerLayout'
import errorToast from '../../../../components/utilits/errorToast';
import successToast from '../../../../components/utilits/successToast';
import { approvalsViewLLPAction, approveLLPAction, rejectLLPAction } from '../../../../redux/actions/llpActions';
import moment from "moment";
import LoadingSpinnerTable from '../../../../components/utilits/loadingSpinnerTable';
import LoadAndError from '../../../../components/utilits/LoadAndError';

const LlpApprovalsView = () => {

  const [updated, setUpdated] = useState(false)

  const params = useParams()
  const navigate = useNavigate()
  const [approvalDetails, setApprovalDetails] = useState([])
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const dispatch = useDispatch();
  useEffect(() => {
    setLoading(true)
    dispatch(
      approvalsViewLLPAction(params.id, onSuccess, onError)
    );
  }, [updated])
  const onSuccess = (data) => {
    setApprovalDetails(data.data)
    setLoading(false)
    setError(false)
  };
  const onError = (data) => {
    errorToast(data.message)
    setLoading(false)
    setError(true)
  };
  console.log(params.id);
  console.log(approvalDetails);



  //approve 
  const approveLlp = () => {
    dispatch(
      approveLLPAction(params.id, onApproveSuccess, onApproveError)
    );
  }
  const onApproveSuccess = (data) => {

    setUpdated(!updated)
    successToast("Approved")
    navigate('/opportunity/llp/approvals')
    setLoading(false)
    setError(false)
  };
  const onApproveError = (data) => {
    console.log(data);
    errorToast(data.data)
    setLoading(false)
    setError(true)
  };
  const rejectLlp = () => {
    dispatch(
      rejectLLPAction(params.id, onRejectSuccess, onRejectError)
    );
  }
  const onRejectSuccess = (data) => {
    setUpdated(!updated)
    successToast(data.message)
    navigate('/opportunity/llp/approvals')
    setLoading(false)
    setError(false)
  };
  const onRejectError = (data) => {
    console.log(data);
    errorToast(data.message)
    setLoading(false)
    setError(true)
  };
  const data = [
    {
      heading: "Approval ID",
      title: approvalDetails?.id,
    },
    {
      heading: "Opportunity IDs",
      title: approvalDetails?.new_data == null ? approvalDetails?.old_data?.opportunity_id : approvalDetails?.new_data?.opportunity_id
    },
    {
      heading: " Name",
      title: approvalDetails?.new_data == null ? approvalDetails?.old_data?.name : approvalDetails?.new_data?.name
    },
    {
      heading: "Title",
      title: approvalDetails?.new_data == null ? approvalDetails?.old_data?.title : approvalDetails?.new_data?.title
    },
    {
      heading: "Registration Number",
      title: approvalDetails?.new_data == null ? approvalDetails?.old_data?.reg_no : approvalDetails?.new_data?.reg_no
    },

    {
      heading: "Status",
      title: approvalDetails?.new_data == null ? approvalDetails?.old_data?.status : approvalDetails?.new_data?.status
    },
    {
      heading: "Member Count",
      title: approvalDetails?.new_data == null ? approvalDetails?.old_data?.opportunity_id : approvalDetails?.new_data?.opportunity_id
    },
    {
      heading: "LLP ID",
      title: approvalDetails?.new_data == null ? approvalDetails?.old_data?.id : approvalDetails?.new_data?.id
    },
    {
      heading: "Created At",
      title: approvalDetails?.createdAt === null
        ? "-"
        : moment(approvalDetails?.createdAt).format("DD-MM-YYYY HH:mm:ss")
    }
    ,
    {
      heading: "Operation Type",
      title: approvalDetails?.new_data == null ? "Delete" : approvalDetails?.new_data && approvalDetails?.old_data ? "Edit" : approvalDetails?.old_data == null ? "Create" : ""
    }
  ];

  const buttonList = [
    <VersionHistoryBtn link={`/opportunity/llp/version-history`} />,
    <ApproveBtn approveFn={approveLlp} />,
    <RejectBtn showModal={rejectLlp} />
  ];

  return (
    <InnerLayout buttons={buttonList}>
      <div className="notify-me-container">
        <InnerContainer error={error} oldData={approvalDetails?.old_data} newData={approvalDetails?.new_data}>
          {loading ? (
            <LoadingSpinnerTable/>
          ) : (
          <LoadAndError loader={loading} error={error}>
          <div className="pt-2  ps-4 ps-lg-4 ms-3">
            <h5 className="dark_grey_color fs_13 fn_Montserrat fw_500 m-0">
              LLP Details
            </h5>
          </div>
          <div className="pt-4 mt-2  ps-4 ps-lg-4 ms-3">
            <div>
              {data.map((item, index) => (
                <div className="mb-3 ">
                  <h5 className="dark_grey_color fs_13 fn_Montserrat fw_500 m-0">
                    {item.heading}
                  </h5>
                  <p
                    className={` fs_13 fn_Nunito fw_500  ${item.heading === "Opportunity ID" ||
                      item.heading === "LLP ID" ||
                      item.heading === "Registration Number" ||
                      item.heading === "Member Count"
                      ? "secondary_color"
                      : "dark_title_color"
                      } `}
                  >
                    {item.title}
                  </p>
                </div>
              ))}
            </div>
          </div>
          </LoadAndError>
          )}
        </InnerContainer>
      </div>
      {/* <DeleteModal
        loader={loadingDelete}
        handleClose={handleClose}
        deleteFn={handleDelete}
        data={deleteData}
        show={show}
    /> */}
    </InnerLayout>
  )
}

export default LlpApprovalsView