import React, { useEffect, useRef, useState } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import InnerLayout from "../../../../components/layouts/innerLayout/innerLayout";
import InnerContainer from "../../../../components/layouts/innerContainer/innerContainer";
import SelectFields from "../../../../components/form/selectFields/selectFields";
import SaveBtn from "../../../../components/buttons/saveBtn";
import InputField from "../../../../components/form/inputFields/inputField";
import ErrorMsg from "../../../../components/form/errorMsg";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  editFaq,
  faqOptions,
  faqSubmit,
  viewFaqDetail,
} from "../../../../redux/actions/faqAction";
import successToast from "../../../../components/utilits/successToast";
import errorToast from "../../../../components/utilits/errorToast";
import LoadingSpinnerTable from "../../../../components/utilits/loadingSpinnerTable";
import LoadAndError from "../../../../components/utilits/LoadAndError";
import SubmitBtn from "../../../../components/buttons/submit";
import { editRefer, referSubmit, viewReferDetail, viewReferList } from "../../../../redux/actions/referAction";
import { documentSubmit, editDocument, viewDocumentDetail } from "../../../../redux/actions/documentAction";
import ReactQuill from "react-quill";

const DocumentEdit = () => {
  const [submitData, setSubmitData] = useState();
  const formRef = useRef();
  const navigate = useNavigate();
  const params = useParams();
  const dispatch = useDispatch();
  const [faqDetails, setFaqDetails] = useState([]);
  const [notifyMeData, setNotifyMeData] = useState();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  useEffect(() => {
    setLoading(true);
    dispatch(viewDocumentDetail(params.id, onSuccess, onError));
  }, []);

  const onSuccess = (data) => {
    console.log(data.data);
    setFaqDetails(data.data);
    console.log("SUCCESS");
    setLoading(false);
    setError(false);
  };
  const onError = (data) => {
    console.log("ERROR");
    setLoading(false);
    setError(true);
  };

  const initialValues = {
    Question: faqDetails.question,
    Answer: faqDetails.answer,
  };

  const interestEditSchema = Yup.object().shape({
    Question: Yup.string().trim().required("Required"),
    Answer: Yup.string().trim().required("Required"),
  });
  const handleSubmit = () => {
    if (formRef.current) {
      formRef.current.handleSubmit();
    }
  };
  const onSubmit = (values, { resetForm }) => {
    console.log(values);
    dispatch(
      editDocument(
        faqDetails.id,
        {
          "question": values.Question,
          "answer": values.Answer
        },
        onEditSuccess,
        onEditError
      )
    );
  };
  const onEditSuccess = (data) => {
    setNotifyMeData(data.data);
    setSubmitData(data.data.id);
    setSaveHide(true);
    successToast(data.message);
  };
  const onEditError = (data) => {
    console.log(data);
    errorToast(data.message);
  };



  //on submit api
  console.log(submitData);
  const [saveHide, setSaveHide] = useState(false);

  const submitFnc = () => {
    dispatch(documentSubmit(submitData, onSubmitSuccess, onSubmitError));
  };
  const onSubmitSuccess = (data) => {
    console.log(data);
    successToast(data.message);
    navigate("/static-pages/dashboard");
  };
  const onSubmitError = (data) => {
    console.log("ERROR==================", data);
    errorToast(data.message);
  };

  const buttonList = [
    !saveHide && <SaveBtn submitFn={handleSubmit} loader={loading} />,
    <SubmitBtn submitFn={submitFnc} saveHide={saveHide} />,
  ];

  return (
    <InnerLayout buttons={buttonList}>
      <div className="user_view_container">
        <InnerContainer>
          {loading ? (
            <LoadingSpinnerTable />
          ) : (
            <LoadAndError loader={loading} error={error}>
              <div className="row mt-2">
                <div className="col-12 col-sm-12 col-md-9 col-lg-9 col-xl-7">
                  <div className="row ps-1 ms-1 ps-lg-4 ms-lg-1">
                    <div className="ps-2  pt-3">
                      <h5 className="dark_grey_color text-nowrap fs_13 fn_Montserrat fw_500">
                        Refer ID
                      </h5>
                      <p
                        className={` fs_13 fn_Nunito text-nowrap fw_500 secondary_color`}
                      >
                        {params.id}
                      </p>

                      <Formik
                        innerRef={formRef}
                        initialValues={initialValues}
                        onSubmit={onSubmit}
                        enableReinitialize={true}
                        validationSchema={interestEditSchema}
                      >
                        {({ values, setFieldTouched, setFieldValue, touched, errors }) => (
                          <Form className="">
                            <div className="   express-form-width">
                              <InputField
                                id={"Question"}
                                label={"Question"}
                                type={"text"}
                                placeholder={"Enter Question here"}
                                name={"Question"}
                              />
                              <label
                                id="answer"
                                className="mb-2 dark_grey_color fn_Montserrat fw_500 fs_14 ps-2"
                              >
                                Answer
                              </label>
                              <div
                                style={{ height: "300px" }}
                                className="oppor_content_edit__border overflow-auto rounded-2 pt-3 mb-0"
                              >
                                <ReactQuill
                                  theme={"snow"}
                                  name={"Answer"}
                                  id={"answer"}
                                  value={values.Answer}
                                  onChange={(event) => setFieldValue("Answer", event)}
                                  onBlur={() => setFieldTouched("Answer", true)}
                                  valid={!errors.Answer}
                                  invalid={touched.Answer && !!errors.Answer}
                                  autoFocus={true}
                                /></div>
                              <ErrorMsg name={"Answer"} />
                            </div>
                          </Form>
                        )
                        }
                      </Formik>
                    </div>
                  </div>
                </div>
              </div>
            </LoadAndError>
          )}
        </InnerContainer>
      </div>
    </InnerLayout>
  );
};

export default DocumentEdit;
export const faqName = [
  { value: "Type1", label: "Type1" },
  { value: "Type2", label: "Type2" },
  { value: "Type3", label: "Type3" },
  { value: "Type4", label: "Type4" },
];
