import React, { useState } from 'react';
import { Field } from "formik";
import ErrorMsg from '../errorMsg';
import Select, { components } from 'react-select';
import "../../../components/form/selectFields/selectFields.css"
import ArrowRightIcon from '../../../assets/icons/arrowrightIcon';



const SelectFields = ({ label, values = "", multies= false, disabled = false, default1, Icon = <ArrowRightIcon width={15} fill={"#A5A5A5"} />, name, options, type, handleSelected, containerClassName = "mb-2", placeholder, id, errmsg, title = "" }) => {

    const DropdownIndicator = props => {
        return (
            <components.DropdownIndicator {...props}>
                {
                    Icon
                }
            </components.DropdownIndicator>
        );
    };
    const [selectedOption, setSelectedOption] = useState({
        label: "",
        value: "",
    });

    const [changed, whenChanged] = useState(false);
    return (
        <div className={`${containerClassName}`}>
            <label
                htmlFor="email"
                className="mb-2 ps-2 dark_grey_color fn_Montserrat fw_500 fs_14"
            >
                {label}
            </label>
            <Field name={name}>
                {({ form, meta, Field }) => (
                    <Select
                        isDisabled={disabled}
                        defaultValue={default1}
                        value={changed === false ? default1 : options.find((c) => c.value === selectedOption)}
                        name={name}
                        id={id}
                        {...Field}
                        isMulti={multies}
                        components={{ DropdownIndicator }}
                        styles={customStyles}
                        type={type}
                        className={
                            meta.touched && meta.error
                                ? `select-input-field w-100    selectField_error__border   focus_outline__none`
                                : `select-input-field w-100     selectField__border   focus_outline__none`
                        }
                        placeholder={placeholder}
                        options={options}
                        onChange={(selectedOption) => {
                            {
                                form.setFieldValue(
                                    name,
                                    selectedOption.value,
                                )
                                whenChanged(true)
                                setSelectedOption({ label: selectedOption.value, value: selectedOption.value })
                                // handleSelected({ label: selectedOption.value, value: selectedOption.value })
                            }

                        }
                        }
                    />
                )}
            </Field>
            <ErrorMsg name={name} />
        </div>
    )
}

export default SelectFields;

export const customStyles = {
    indicatorSeparator: () => ({ display: 'none' }),
    control: (base, state) => ({
        ...base,
        color: "#464646",
        fontFamily: "Nunito",
        fontWeight: "400",
        fontSize: "15px",
        // background: "#F4F8FB",
        // match with the menu
        borderRadius: "10px",
        width: "100%",
        // Overwrittes the different states of border
        borderColor: state.isFocused ? "transparent" : "transparent",
        // Removes weird border around container
        boxShadow: state.isFocused ? null : null,
        "&:hover": {
            // Overwrittes the different states of border
            // borderColor: state.isFocused ? "#1D72DB" : "#F4F8FB",
        },
    }),
    option: (provided, state) => ({
        ...provided,
        backgroundColor: state.isSelected ? "#0000002E" : null,
        borderBottom: "1px solid #F5F5F5",
        fontFamily: "Nunito",
        "&:hover": {
            backgroundColor: state.isFocused ? "#0000002E" : "#0000002E",
        },
    }),
    placeholder: (defaultStyles) => {
        return {
            ...defaultStyles,
            color: "#464646",
            fontFamily: "Nunito",
            fontSize: "14px",
            fontWeight: "400",
            opacity: "0.9",
            position: "absolute",
            // top: "0.6rem"
        };
    },
    dropdownIndicator: (provided) => ({
        ...provided,
        svg: {

            fill: "#323D5A",
        },
    }),

};