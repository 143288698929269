import React, { useEffect, useRef, useState } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import SaveBtn from "../../../components/buttons/saveBtn";
import InnerContainer from "../../../components/layouts/innerContainer/innerContainer";
import InnerLayout from "../../../components/layouts/innerLayout/innerLayout";
import "../user-view.css";
import InputField from "../../../components/form/inputFields/inputField";
import CloseIcon from "../../../assets/icons/closeIcon";
import UploadIcon from "../../../assets/icons/upload";
import SelectFields from "../../../components/form/selectFields/selectFields";
import ArrowRightIcon from "../../../assets/icons/arrowrightIcon";
import ArrowDownIcon from "../../../assets/icons/arrowdown";
import Select, { components } from "react-select";
import ErrorMsg from "../../../components/form/errorMsg";
import moment from "moment";
import * as userAction from "../../../redux/actions/userAction";
import { useDispatch } from "react-redux";
import successToast from "../../../components/utilits/successToast";
import errorToast from "../../../components/utilits/errorToast";
import { redirect, useNavigate } from "react-router-dom";
import usePermissionChecker from "../../../components/utilits/usePermissionChecker";

const UserCreate = () => {
  const phoneRegExp = /^[0-9]{10}$/;
  const din = /^[0-9]{8}$/;
  const investmentValueregex = /^[1-2]?[0-5]?[0-0]?[0-0]?[0-0]?[0-0]?([0-0])$/;
  const panRegex = /[A-Z]{5}[0-9]{4}[A-Z]{1}?$/;
  const accountnumberRegex = /^\d{11,16}$/;
  const ifscRegex = /^[A-Z]{4}[0-9]{7}$/;
  const formRef = useRef();
  const [file, setFile] = useState(null);
  const [addressFrontImage, setAddressFrontImage] = useState(null);
  const [addressBackImage, setAddressBackImage] = useState(null);
  const [panImage, setPanImage] = useState(null);
  const [bankImage, setBankImage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [adminId, setAdminId] = useState("");
  const [saved, setSaved] = useState(false);
  const [profilePicture, setProfilePicture] = useState("");
  const [uploadAddressProofFrontImage, setUploadAddressProofFrontImage] =
    useState("");
  const [uploadAddressProofBackImage, setUploadAddressProofBackImage] =
    useState("");
  const [panPicture, setPanPicture] = useState();
  const [bankPicture, setBankPicture] = useState();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const amountRegex = /^[0-9]*$/;
  const access = usePermissionChecker("maker");
  // useEffect(() => {
  //   !access &&
  //   console.log(access);
  //     // navigate("/user/user-list")
  // }, [])
  function handleImageChange(e, param1) {
    console.log(e);
    if (e.target?.files.length !== 0) {
      switch (param1) {
        case 0:
          setFile(URL.createObjectURL(e.target.files[0]));
          dispatch(
            userAction.uploadAddressProofFrontImageAction(
              e.target.files[0],
              onProfileUploadImageSuccess,
              onProfileUploadImageError
            )
          );
          break;
        case 1:
          setAddressFrontImage(URL.createObjectURL(e.target.files[0]));
          dispatch(
            userAction.uploadAddressProofFrontImageAction(
              e.target.files[0],
              onuploadAddressProofFrontImageSuccess,
              onuploadAddressProofFrontImageError
            )
          );
          break;
        case 2:
          setAddressBackImage(URL.createObjectURL(e.target.files[0]));
          dispatch(
            userAction.uploadAddressProofFrontImageAction(
              e.target.files[0],
              onuploadAddressProofBackImageSuccess,
              onuploadAddressProofBackImageError
            )
          );
          break;
        case 3:
          setPanImage(URL.createObjectURL(e.target.files[0]));
          dispatch(
            userAction.uploadAddressProofFrontImageAction(
              e.target.files[0],
              onPanUploadImageSuccess,
              onPanUploadImageError
            )
          );
          break;
        case 4:
          setBankImage(URL.createObjectURL(e.target.files[0]));
          dispatch(
            userAction.uploadAddressProofFrontImageAction(
              e.target.files[0],
              onbankUploadImageSuccess,
              onbankUploadImageError
            )
          );
          break;
      }
      // setAddressFrontImage(URL.createObjectURL(e.target.files[0]));
      e.target.value = null;
    }
  }
  const onProfileUploadImageSuccess = (data) => {
    console.log(data.data.data);

    setProfilePicture(data.data.data.location);
  };
  const onProfileUploadImageError = (data) => {
    console.log(data);
  };

  const onuploadAddressProofFrontImageSuccess = (data) => {
    console.log(data.data.data);

    setUploadAddressProofFrontImage(data.data.data.location);
  };

  const onuploadAddressProofFrontImageError = (data) => {
    console.log(data);
  };

  const onuploadAddressProofBackImageSuccess = (data) => {
    console.log(data.data.data);

    setUploadAddressProofBackImage(data.data.data.location);
  };

  const onuploadAddressProofBackImageError = (data) => {
    console.log(data);
  };

  const onPanUploadImageSuccess = (data) => {
    console.log(data.data.data);

    setPanPicture(data.data.data.location);
  };

  const onPanUploadImageError = (data) => {
    console.log(data);
  };

  const onbankUploadImageSuccess = (data) => {
    console.log(data.data.data);
    setBankPicture(data.data.data.location);
  };

  const onbankUploadImageError = (data) => {
    console.log(data);
  };

  const initialValues = {
    profileImage: "",
    addressFrontImage: "",
    addressBackImage: "",
    panImage: "",
    bankImage: "",
    name: "",
    fatherName: "",
    email: "",
    phone: "",
    countryCode: "",
    residentialStatus: "",
    din: "",
    dealType: "",
    investmentValue: "",
    kyc: "",
    proofType: "",
    pan: "",
    bankAccountNumber: "",
    bankAccountHolderName: "",
    accountType: "",
    bankName: "",
    bankIfsc: "",
    bankProof: "",
    dob:
      //  moment("").format("YYYY-MM-DD")
      "",
  };
  const loginSchema = Yup.object().shape({
    profileImage: Yup.mixed(),
    addressFrontImage: Yup.mixed(),
    panImage: Yup.mixed(),
    bankImage: Yup.mixed(),
    name: Yup.string("").required("Required"),
    fatherName: Yup.string(),
    email: Yup.string().trim().email().required("Required"),
    phone: Yup.string()
      .required("Required")
      .matches(phoneRegExp, "Please Enter Valid Phone Number"),
    countryCode: Yup.string(),
    residentialStatus: Yup.string(),
    din: Yup.string()
      // .required("Required")
      .matches(din, "Please Enter Valid DIN Number"),
    /*** Invest***/
    dealType: Yup.string(),
    investmentValue: Yup.string().trim().matches(
      amountRegex,
      "Please Enter Valid Investment Value"
    ),
    kyc: Yup.string(),
    proofType: Yup.string(),
    pan: Yup.string().trim().matches(panRegex, "Please Enter Valid Pan Number"),
    bankAccountNumber: Yup.string().trim(),
    bankAccountHolderName: Yup.string(),
    accountType: Yup.string(),
    bankName: Yup.string(),
    bankIfsc: Yup.string().trim(),
    // .matches(ifscRegex, "Please Enter Valid ifsc code"),
    bankProof: Yup.string(),
    dob: Yup.date().test("dob", "must be above 18 years", (value) => {
      if (!value) {
        return true;
      } else return moment().diff(moment(value), "years") >= 18;
    }),
  });
  const handleSubmit = () => {
    if (formRef.current) {
      formRef.current.handleSubmit();
    }
  };
  const onSubmit = (values, { resetForm }) => {
    console.log(values);
    setLoading(true);
    dispatch(
      userAction.AddUser(
        {
          user_name: values.name ? values.name : "",
          email: values.email,
          phone_number: values.phone,
          dob: values.dob ? values.dob : null,
          father_name: values.fatherName ? values.fatherName : "",
          country_code: values.countryCode,
          profile_picture_location: profilePicture ? profilePicture : "",
          residential_status: values.residentialStatus
            ? values.residentialStatus
            : null,
          din: values.din ? values.din : "",
          deal_type_preference: values.dealType ? values.dealType : null,
          investment_value_preference: values.investmentValue
            ? values.investmentValue
            : null,
          kyc_process_status: values.kyc ? values.kyc : null,
          address_proof: {
            proof_type: values.proofType ? values.proofType : "",
            Other_proof: values.bankProof ? values.bankProof : "",
            front_image_location: addressFrontImage ? addressFrontImage : "",
            back_image_location: addressBackImage ? addressBackImage : "",
          },
          pan: {
            pan_no: values.pan ? values.pan : "",
            front_image_location: panImage ? panImage : "",
          },
          bank_account_details: {
            bank_holder_name: values.bankAccountHolderName
              ? values.bankAccountHolderName
              : "",
            account_type: values.accountType ? values.accountType : "",
            account_number: values.bankAccountNumber
              ? values.bankAccountNumber
              : null,
            bank_name: values.bankName ? values.bankName : "",
            ifsc_code: values.bankIfsc ? values.bankIfsc : "",
            image_location: bankPicture ? bankPicture : "",
          },
        },
        onSuccess,
        onError
      )
    );
  };
  const onSuccess = (data) => {
    console.log(data.data.message);
    setLoading(false);
    setAdminId(data.data.data.checker_id.id);
    setSaved(true);
    successToast(data.data.message);
    navigate(`/user/user-list`);
  };
  const onError = (data) => {
    console.log(data);
    setLoading(false);
    errorToast(data.data.data + " " + data.data.message);
  };
  const DropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator {...props}>
        <ArrowDownIcon width={25} height={25} />
      </components.DropdownIndicator>
    );
  };
  const buttonList = [<SaveBtn loader={loading} submitFn={handleSubmit} />];
  return (
    <>
      <InnerLayout buttons={buttonList}>
        <div className="user_view_container">
          <InnerContainer>
            <div>
              <h3 className="dark_grey_color fn_Montserrat fw_500 fs_13 py-3 white_shade_bg">
                <span className="ps-4 ps-lg-4">Personal Information</span>
              </h3>
              <Formik
                innerRef={formRef}
                initialValues={initialValues}
                onSubmit={onSubmit}
                validationSchema={loginSchema}
              >
                {({ handleChange, setFieldValue }) => {
                  return (
                    <Form className="">
                      <div className="ps-2 ps-lg-4 ms-0 ms-lg-2 user-form-width">
                        <InputField
                          id={"name"}
                          label={"Name"}
                          type={"text"}
                          placeholder={"Enter Name"}
                          name={"name"}
                        />
                        <InputField
                          id={"dob"}
                          label={"Date Of Birth"}
                          type={"date"}
                          placeholder={"Select Date"}
                          name={"dob"}
                        />
                        <InputField
                          id={"fatherName"}
                          label={"Father's Name"}
                          type={"text"}
                          placeholder={"Enter Father's Name"}
                          name={"fatherName"}
                        />
                        <InputField
                          id={"email"}
                          label={"E-Mail"}
                          type={"text"}
                          placeholder={"Enter Email ID"}
                          name={"email"}
                        />
                        <InputField
                          id={"phone"}
                          label={"Phone Number"}
                          type={"text"}
                          placeholder={"Enter Phone Number"}
                          name={"phone"}
                        />
                        <InputField
                          id={"countryCode"}
                          label={"Country Code"}
                          type={"text"}
                          placeholder={"Enter Country Code"}
                          name={"countryCode"}
                        />
                        <SelectFields
                          Icon={
                            <ArrowDownIcon
                              width={20}
                              height={20}
                              fill={"#A5A5A5"}
                            />
                          }
                          label={"Residential status"}
                          placeholder={"Select Residential status"}
                          name={"residentialStatus"}
                          options={residentialOptions}
                          id={"residentialStatus"}
                        />
                        <InputField
                          id={"din"}
                          label={"Director Indentification Number-DIN"}
                          type={"text"}
                          placeholder={"Enter DIN(optional)"}
                          name={"din"}
                        />
                        <div>
                          <label className="dark_grey_color fn_Montserrat fw_500 fs_13 ">
                            Profile Picture
                          </label>
                          <div className="mt-3 ">
                            <div className="user_edit_profile_picture_container position-relative d-flex justify-content-center align-items-center">
                              <div>
                                <input
                                  multiple={false}
                                  type="file"
                                  id="backfiles"
                                  accept=".png, .jpg, .jpeg .webp"
                                  className="upload_document_input"
                                  name="profileImage"
                                  onChange={(e) => {
                                    setFieldValue(
                                      "profileImage",
                                      e.target.files[0]
                                    );
                                    handleImageChange(e, 0);
                                  }}
                                />
                                {file === null && (
                                  <label
                                    htmlFor="backfiles"
                                    className="d-flex justify-content-center"
                                  >
                                    <div className=" ">
                                      <div className="d-flex justify-content-center">
                                        <UploadIcon width={40} height={40} />
                                      </div>

                                      <div className="dark_grey_color d-flex justify-content-center cursor_pointer fn_Montserrat fw_500 fs_12 mt-3">
                                        Choose Profile Picture
                                      </div>
                                    </div>
                                  </label>
                                )}
                                {file !== null && (
                                  <img
                                    src={file}
                                    className={"user-edit-profile-picture"}
                                  />
                                )}
                              </div>
                              {file !== null && (
                                <button
                                  type="button"
                                  onClick={(e) => {
                                    setFile(null);
                                    let event = {
                                      target: {
                                        name: "profileImage",
                                        value: "",
                                      },
                                    };
                                    handleChange(event);
                                  }}
                                  className="position-absolute border-0 cursor_pointer white_bg d-flex gap-2 py-1 px-2 user_edit_profile_picture_remove_container"
                                >
                                  <CloseIcon fill="#666666" />
                                  <span className="dark_grey_color fs_13 fn_Montserrat fw_500 ">
                                    Remove
                                  </span>
                                </button>
                              )}
                            </div>
                            {<ErrorMsg name="profileImage" />}
                          </div>
                        </div>
                      </div>
                      <h3 className="dark_grey_color fn_Montserrat fw_500 mt-4 fs_13 py-3 white_shade_bg">
                        <span className="ps-4">Investment Preference</span>
                      </h3>
                      <div className="ps-2 ps-lg-4 ms-0 ms-lg-2 mt-4 user-form-width">
                        <SelectFields
                          Icon={
                            <ArrowDownIcon
                              width={20}
                              height={20}
                              fill={"#A5A5A5"}
                            />
                          }
                          label={"Deal Type Preference"}
                          placeholder={"Select Deal Type Preference"}
                          name={"dealType"}
                          options={furnishingOptions}
                          id={"dealType"}
                        />
                        <InputField
                          id={"investmentValue"}
                          label={"Investment Value"}
                          type={"text"}
                          placeholder={
                            "Enter Investment Value(Integer Value: 5000)"
                          }
                          name={"investmentValue"}
                        />
                      </div>
                      <h3 className="dark_grey_color fn_Montserrat fw_500 mt-4 fs_13 py-3 white_shade_bg">
                        <span className="ps-4">KYC</span>
                      </h3>
                      <div className="ps-2 ps-lg-4 ms-0 ms-lg-2 user-form-width">
                        <div className={`w-75`}>
                          <Field name="kyc">
                            {({ form, meta, Field }) => (
                              <Select
                                Icon={
                                  <ArrowRightIcon width={15} fill={"#A5A5A5"} />
                                }
                                name="kyc"
                                id="kyc"
                                {...Field}
                                components={{ DropdownIndicator }}
                                styles={customStyles}
                                type="text"
                                className={
                                  meta.touched && meta.error
                                    ? `kyc_select-input-field w-100 kyc_selectField__border       focus_outline__none`
                                    : `kyc_select-input-field w-100     kyc_selectField__border   focus_outline__none`
                                }
                                placeholder="SELECT KYC STATUS"
                                options={kycstatus}
                                onChange={(selectedOption) =>
                                  form.setFieldValue(
                                    "kyc",
                                    selectedOption.value
                                  )
                                }
                              />
                            )}
                          </Field>
                          <ErrorMsg name="kyc" />
                        </div>
                      </div>
                      <h3 className="dark_grey_color fn_Montserrat fw_500 mt-4 fs_13 py-3 white_shade_bg">
                        <span className="ps-4">Address</span>
                      </h3>
                      <div className="ps-2 ps-lg-4 ms-0 ms-lg-2 mt-3 user-form-width">
                        <SelectFields
                          Icon={
                            <ArrowDownIcon
                              width={20}
                              height={20}
                              fill={"#A5A5A5"}
                            />
                          }
                          label={"Select Proof Type"}
                          placeholder={"Select Proof Type"}
                          name={"proofType"}
                          options={proofType}
                          id={"proofType"}
                        />
                        <div className="mt-2 d-flex flex-column flex-lg-row gap-4 gap-lg-3 gap-xl-3">
                          <div>
                            <label className="dark_grey_color fn_Montserrat fw_500 fs_13 ">
                              Front Image
                            </label>
                            <div className="mt-3 ">
                              <div className="user_edit_front_back_image_container position-relative d-flex justify-content-center align-items-center">
                                <div>
                                  <input
                                    multiple={false}
                                    type="file"
                                    id="addressFrontImg"
                                    className="upload_document_input"
                                    name="addressFrontImage"
                                    accept="image/png, image/jpg, image/jpeg, image/webp"
                                    onChange={(e) => {
                                      setFieldValue(
                                        "addressFrontImage",
                                        e.target.files[0]
                                      );
                                      handleImageChange(e, 1);
                                    }}
                                  />
                                  {addressFrontImage === null && (
                                    <label
                                      htmlFor="addressFrontImg"
                                      className="d-flex justify-content-center"
                                    >
                                      <div className=" ">
                                        <div className="d-flex justify-content-center">
                                          <UploadIcon width={40} height={40} />
                                        </div>

                                        <div className="dark_grey_color d-flex justify-content-center cursor_pointer fn_Montserrat fw_500 fs_12 mt-3">
                                          Choose Profile Picture
                                        </div>
                                      </div>
                                    </label>
                                  )}
                                  {addressFrontImage !== null && (
                                    <img
                                      src={addressFrontImage}
                                      className={"user-edit-profile-picture"}
                                    />
                                  )}
                                </div>
                                {addressFrontImage !== null && (
                                  <button
                                    type="button"
                                    onClick={(e) => {
                                      setAddressFrontImage(null);
                                      let event = {
                                        target: {
                                          name: "addressFrontImage",
                                          value: "",
                                        },
                                      };
                                      handleChange(event);
                                    }}
                                    className="position-absolute border-0 cursor_pointer white_bg d-flex gap-2 py-1 px-2 user_edit_profile_picture_remove_container"
                                  >
                                    <CloseIcon fill="#666666" />
                                    <span className="dark_grey_color fs_13 fn_Montserrat fw_500 ">
                                      Remove
                                    </span>
                                  </button>
                                )}
                              </div>
                              {<ErrorMsg name="addressFrontImage" />}
                            </div>
                          </div>
                          <div>
                            <label className="dark_grey_color fn_Montserrat fw_500 fs_13 ">
                              Back Image
                            </label>
                            <div className="mt-3 ">
                              <div className="user_edit_front_back_image_container position-relative d-flex justify-content-center align-items-center">
                                <div>
                                  <input
                                    multiple={false}
                                    type="file"
                                    id="addressBackImg"
                                    className="upload_document_input"
                                    name="addressBackImage"
                                    accept="image/png, image/jpg, image/jpeg, image/webp"
                                    onChange={(e) => {
                                      setFieldValue(
                                        "addressBackImage",
                                        e.target.files[0]
                                      );
                                      handleImageChange(e, 2);
                                    }}
                                  />
                                  {addressBackImage === null && (
                                    <label
                                      htmlFor="addressBackImg"
                                      className="d-flex justify-content-center"
                                    >
                                      <div className=" ">
                                        <div className="d-flex justify-content-center">
                                          <UploadIcon width={40} height={40} />
                                        </div>

                                        <div className="dark_grey_color d-flex justify-content-center cursor_pointer fn_Montserrat fw_500 fs_12 mt-3">
                                          Choose Profile Picture
                                        </div>
                                      </div>
                                    </label>
                                  )}
                                  {addressBackImage !== null && (
                                    <img
                                      src={addressBackImage}
                                      className={"user-edit-profile-picture"}
                                    />
                                  )}
                                </div>
                                {addressBackImage !== null && (
                                  <button
                                    type="button"
                                    onClick={(e) => {
                                      setAddressBackImage(null);
                                      let event = {
                                        target: {
                                          name: "addressBackImage",
                                          value: "",
                                        },
                                      };
                                      handleChange(event);
                                    }}
                                    className="position-absolute border-0 cursor_pointer white_bg d-flex gap-2 py-1 px-2 user_edit_profile_picture_remove_container"
                                  >
                                    <CloseIcon fill="#666666" />
                                    <span className="dark_grey_color fs_13 fn_Montserrat fw_500 ">
                                      Remove
                                    </span>
                                  </button>
                                )}
                              </div>
                              {<ErrorMsg name="addressBackImage" />}
                            </div>
                          </div>
                        </div>
                      </div>
                      <h3 className="dark_grey_color fn_Montserrat fw_500 mt-4 fs_13 py-3 white_shade_bg">
                        <span className="ps-4">PAN Details</span>
                      </h3>
                      <div className="ps-2 ps-lg-4 ms-0 ms-lg-2 user-form-width">
                        <InputField
                          id={"pan"}
                          label={"PAN Number"}
                          type={"text"}
                          placeholder={"Enter PAN Number"}
                          name={"pan"}
                          InputFieldClassName={`text-uppercase`}
                        />
                        <div>
                          <label className="dark_grey_color fn_Montserrat fw_500 fs_13 ">
                            Front Image
                          </label>
                          <div className="mt-3 ">
                            <div className="user_edit_profile_picture_container position-relative d-flex justify-content-center align-items-center">
                              <div>
                                <input
                                  multiple={false}
                                  type="file"
                                  id="panImg"
                                  className="upload_document_input"
                                  name="panImage"
                                  accept="image/png, image/jpg, image/jpeg, image/webp"
                                  onChange={(e) => {
                                    setFieldValue(
                                      "panImage",
                                      e.target.files[0]
                                    );
                                    handleImageChange(e, 3);
                                  }}
                                />
                                {panImage === null && (
                                  <label
                                    htmlFor="panImg"
                                    className="d-flex justify-content-center"
                                  >
                                    <div className=" ">
                                      <div className="d-flex justify-content-center">
                                        <UploadIcon width={40} height={40} />
                                      </div>

                                      <div className="dark_grey_color d-flex justify-content-center cursor_pointer fn_Montserrat fw_500 fs_12 mt-3">
                                        Choose Profile Picture
                                      </div>
                                    </div>
                                  </label>
                                )}
                                {panImage !== null && (
                                  <img
                                    src={panImage}
                                    className={"user-edit-profile-picture"}
                                  />
                                )}
                              </div>
                              {panImage !== null && (
                                <button
                                  type="button"
                                  onClick={(e) => {
                                    setPanImage(null);
                                    let event = {
                                      target: { name: "panImage", value: "" },
                                    };
                                    handleChange(event);
                                  }}
                                  className="position-absolute border-0 cursor_pointer white_bg d-flex gap-2 py-1 px-2 user_edit_profile_picture_remove_container"
                                >
                                  <CloseIcon fill="#666666" />
                                  <span className="dark_grey_color fs_13 fn_Montserrat fw_500 ">
                                    Remove
                                  </span>
                                </button>
                              )}
                            </div>
                            {<ErrorMsg name="panImage" />}
                          </div>
                        </div>
                      </div>
                      <h3 className="dark_grey_color fn_Montserrat fw_500 mt-4 fs_13 py-3 white_shade_bg">
                        <span className="ps-4">Bank Account Details</span>
                      </h3>
                      <div className="ps-2 ps-lg-4 ms-0 ms-lg-2 mt-3 user-form-width">
                        <InputField
                          id={"bankAccountNumber"}
                          label={"Bank Account Number"}
                          type={"text"}
                          placeholder={"Enter Bank Account Number"}
                          name={"bankAccountNumber"}
                        />
                        <InputField
                          id={"bankAccountHolderName"}
                          label={"Bank Account Holder Name"}
                          type={"text"}
                          placeholder={"Enter Bank Account Holder Name"}
                          name={"bankAccountHolderName"}
                        />
                        <InputField
                          id={"accountType"}
                          label={"Account Type"}
                          type={"text"}
                          placeholder={"Enter Account Type "}
                          name={"accountType"}
                        />
                        <InputField
                          id={"bankName"}
                          label={"Bank Name"}
                          type={"text"}
                          placeholder={"Enter bank Name"}
                          name={"bankName"}
                        />
                        <InputField
                          id={"bankIfsc"}
                          label={"Bank IFSC"}
                          type={"text"}
                          placeholder={"Enter IFSC Code"}
                          name={"bankIfsc"}
                          InputFieldClassName={`text-uppercase`}
                        />
                        <SelectFields
                          Icon={
                            <ArrowDownIcon
                              width={20}
                              height={20}
                              fill={"#A5A5A5"}
                            />
                          }
                          label={"Proof Type"}
                          placeholder={"Select Proof Type"}
                          name={"bankProof"}
                          options={bankproofType}
                          id={"bankProof"}
                        />
                        <div>
                          <label className="dark_grey_color fn_Montserrat fw_500 fs_13 ">
                            Front Image
                          </label>
                          <div className="mt-3 ">
                            <div className="user_edit_profile_picture_container position-relative d-flex justify-content-center align-items-center">
                              <div>
                                <input
                                  multiple={false}
                                  type="file"
                                  id="bankImg"
                                  className="upload_document_input"
                                  name="bankImage"
                                  accept="image/png, image/jpg, image/jpeg, image/webp"
                                  onChange={(e) => {
                                    setFieldValue(
                                      "bankImage",
                                      e.target.files[0]
                                    );
                                    handleImageChange(e, 4);
                                  }}
                                />
                                {bankImage === null && (
                                  <label
                                    htmlFor="bankImg"
                                    className="d-flex justify-content-center"
                                  >
                                    <div className=" ">
                                      <div className="d-flex justify-content-center">
                                        <UploadIcon width={40} height={40} />
                                      </div>
                                      <div className="dark_grey_color d-flex justify-content-center cursor_pointer fn_Montserrat fw_500 fs_12 mt-3">
                                        Choose Profile Picture
                                      </div>
                                    </div>
                                  </label>
                                )}
                                {bankImage !== null && (
                                  <img
                                    src={bankImage}
                                    className={"user-edit-profile-picture"}
                                  />
                                )}
                              </div>
                              {bankImage !== null && (
                                <button
                                  type="button"
                                  onClick={(e) => {
                                    setBankImage(null);
                                    let event = {
                                      target: { name: "bankImage", value: "" },
                                    };
                                    handleChange(event);
                                  }}
                                  className="position-absolute border-0 cursor_pointer white_bg d-flex gap-2 py-1 px-2 user_edit_profile_picture_remove_container"
                                >
                                  <CloseIcon fill="#666666" />
                                  <span className="dark_grey_color fs_13 fn_Montserrat fw_500 ">
                                    Remove
                                  </span>
                                </button>
                              )}
                            </div>
                            {<ErrorMsg name="bankImage" />}
                          </div>
                        </div>
                      </div>
                    </Form>
                  );
                }}
              </Formik>
            </div>
          </InnerContainer>
        </div>
      </InnerLayout>
    </>
  );
};

export default UserCreate;

export const furnishingOptions = [
  { value: "long_term", label: "long term" },
  { value: "short_term", label: "short term" },
];
export const residentialOptions = [
  { value: "residential", label: "Residential" },
  { value: "non_residential", label: "Non Residential" },
];
export const kycstatus = [
  { value: "processing", label: "processing" },
  { value: "pending", label: "pending" },
  { value: "completed", label: "completed" },
  { value: "failed", label: "failed" },
  { value: "verified", label: "verified" },
  { value: "incompleted", label: "incompleted" },
];
export const proofType = [
  { value: "Aadhar Card", label: "Aadhar Card" },
  { value: "Passport", label: "Passport" },
  { value: "Driving License", label: "Driving License" },
];
export const bankproofType = [{ value: "Passbook", label: "Passbook" }];
export const customStyles = {
  indicatorSeparator: () => ({ display: "none" }),
  control: (base, state) => ({
    ...base,
    color: "#112F6A",
    fontFamily: "Montserrat",
    fontWeight: "600",
    fontSize: "15px",

    width: "100%",
    borderColor: state.isFocused ? "transparent" : "transparent",
    boxShadow: state.isFocused ? null : null,
    "&:hover": {},
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? "#0000002E" : null,
    borderBottom: "1px solid #F5F5F5",
    fontFamily: "Montserrat",
    fontSize: "15px",
    fontWeight: "500",
    "&:hover": {
      backgroundColor: state.isFocused ? "#0000002E" : "#0000002E",
    },
  }),
  placeholder: (defaultStyles) => {
    return {
      ...defaultStyles,
      color: "#000000DE",
      fontFamily: "Montserrat",
      fontSize: "13px",
      fontWeight: "600",
    };
  },
  dropdownIndicator: (provided) => ({
    ...provided,
    svg: {
      fill: "#323D5A",
    },
  }),
};
