import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import errorToast from "../../../../components/utilits/errorToast";
import { useNavigate } from "react-router-dom";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import InnerContainer from "../../../../components/layouts/innerContainer/innerContainer";
import InnerLayout from "../../../../components/layouts/innerLayout/innerLayout";
import "../notify-me.css";
import SaveBtn from "../../../../components/buttons/saveBtn";
import {notifyMeCreateOptions, notifySubmitAction } from "../../../../redux/actions/notifyMeActions";
import successToast from "../../../../components/utilits/successToast";
import { opportunitiesNameListAction } from "../../../../redux/actions/opportunitiesActions";
import AdminRoleCheckBox from "../../../../components/utilits/adminRoleCheckbox";
import ErrorMsg from "../../../../components/form/errorMsg";
import InputField from "../../../../components/form/inputFields/inputField";
import { notificationCreate } from "../../../../redux/actions/notificationActions";
import SelectMultiFields from "../../../../components/form/selectFields/selectMultiFields";
import ArrowDownIcon from "../../../../assets/icons/arrowdown";
const NotificationCreate = () => {
    const formRef = useRef()
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [userName, setUserName] = useState([]);
    const [opportunityType, setOpportunityType] = useState([]);
    const [subLoading, setSubLoading] = useState(false);
    const [adminId, setAdminId] = useState("");
    const [saved, setSaved] = useState(false)
    const [allUsers, setAllUsers] = useState([])
    useEffect(() => {
        dispatch(notifyMeCreateOptions(onNotifyMeSuccess, onNotifyMeError));
    }, []);
    const userNameOptions = [];
    const onNotifyMeSuccess = (data) => {
        data?.data.AllUsers.map((item, index) => {
            userNameOptions[index] = { value: item.id, label: item.user_name };
        });
        const defaultListItems = data?.data?.AllUsers?.map((item) => {
            return item.id
        })
        console.log(defaultListItems);
        setAllUsers(defaultListItems)
        console.log(userNameOptions);
        setUserName(userNameOptions);
    };
    const onNotifyMeError = (data) => {
        console.log("ERROR");
    };
    const initialValues = {
        userId: "",
        title: "",
        Subtitle: "",
        description: "",
        kyc: false,
        visibility: "public",
        isImportant: false,
        redirectionUrl: ""
    };
    const loginSchema = Yup.object().shape({
        // userId: Yup.string().trim().required("Required"),
        title: Yup.string().trim().required("Required"),
        Subtitle: Yup.string().trim().required("Required"),
        description: Yup.string().trim().required("Required"),
        redirectionUrl: Yup.string().trim().required("Required"),
    });
    const handleSubmit = () => {
        if (formRef.current) {
            formRef.current.handleSubmit()
        }
    }
    const handleSelectedOptions = (value) => {
        setSelectedOptions(value.map((item) => item.value));
    };
    const [selectedOptions, setSelectedOptions] = useState([]);
    console.log(selectedOptions);
    const [defaultUsersList, setDefaultUsersList] = useState([]);
    const onSubmit = (values, { resetForm }) => {
        console.log(values);
        dispatch(
            notificationCreate(
                {
                    "user_id": values.visibility === "user_oriented" ? selectedOptions : allUsers,
                    "title": values.title,
                    "sub_title": values.Subtitle,
                    "description": values.description,
                    "is_kyc": values.kyc,
                    "is_important": values.isImportant,
                    "redirection_url": values.redirectionUrl
                },
                onSuccess,
                onError
            )
        );
    };
    const onSuccess = (data) => {
        successToast(data.message)
        setAdminId(data.data.id)
        navigate(`/static-pages/notification/edit/${data.data.id}`)
        setSaved(true)
    };
    const onError = (data) => {
        console.log(data);
        errorToast(data.data);
    };

    //opp id
    const [opportunitiesList, setOpportunitiesList] = useState([])
    useEffect(() => {
        dispatch(opportunitiesNameListAction(onSuccessOpt, onErrorOpt));
    }, []);
    const onSuccessOpt = (data) => {
        const categoryList = [];
        data?.data.map((item, index) => {
            categoryList[index] = { value: item.id, label: `${item.title} id: ${item.id}` };
        });
        setOpportunitiesList(categoryList);
        console.log("SUCCESS");
    };
    const onErrorOpt = (data) => {
        console.log(data);
    };
    const notifySubmit = () => {
        setSubLoading(true);
        dispatch(
            notifySubmitAction(adminId, onAdminSubmitSucess, onAdminSubmitError)
        );
    };
    const buttonList = [
        <SaveBtn submitFn={handleSubmit} />,
    ];
    function onAdminSubmitSucess(data) {
        successToast(data.message);
        setSubLoading(false);

    }
    function onAdminSubmitError(data) {
        setSubLoading(false);
        errorToast(data.message);
    }
    console.log(allUsers);
    return (
        <InnerLayout buttons={buttonList}>
            <div className="notify-me-container">
                <InnerContainer>
                    <div className="  ps-2 ps-lg-4 ms-3">
                        <Formik
                            innerRef={formRef}
                            initialValues={initialValues}
                            onSubmit={onSubmit}
                            validationSchema={loginSchema}
                        >
                            {({ formik, handleChange, values, setFieldValue }) => {
                                return (
                                    <Form className='notify-me-form-width'>
                                         <div>
                                            <h5 className="fs_16 ms-2 mt-3 fw_400 dark_grey_color">
                                                Send Notification to
                                            </h5>
                                            <div className="d-flex align-items-center gap-2 ms-3 mb-2">
                                                <label>
                                                    <Field
                                                        disabled={false}
                                                        className={"llp_document_radio"}
                                                        type="radio"
                                                        name="visibility"
                                                        value="public"
                                                    />
                                                    <span className="ms-2">All Users</span>
                                                </label>
                                                <label className="">
                                                    <Field
                                                        disabled={false}
                                                        className={"llp_document_radio"}
                                                        type="radio"
                                                        name="visibility"
                                                        value="user_oriented"
                                                    />
                                                    <span className="ms-2">User Oriented</span>
                                                </label>
                                            </div>
                                        </div>
                                        {
                                            values.visibility === "user_oriented" &&
                                            (<SelectMultiFields
                                                disabled={false}
                                                updateSelected={handleSelectedOptions}
                                                selectedOptions={selectedOptions}
                                                Icon={
                                                    <ArrowDownIcon
                                                        width={20}
                                                        height={20}
                                                        fill={"#A5A5A5"}
                                                    />
                                                }
                                                default1={defaultUsersList}
                                                label={"Select Users"}
                                                placeholder={"Select Users"}
                                                name={"userId"}
                                                options={userName}
                                            />)
                                        }

                                        <InputField
                                            id={"title"}
                                            label={"title"}
                                            type={"text"}
                                            placeholder={"Enter Title"}
                                            name={"title"}
                                        />
                                        <div className=''>

                                            <label id="Subtitle" className='mb-2 dark_grey_color fn_Montserrat fw_500 fs_14 ps-2'>Subtitle</label>
                                            <Field
                                                name={"Subtitle"}>
                                                {({ field }) => (
                                                    <textarea id="Subtitle" {...field} type="text" className={'w-100 opportunities_create_summary_text_area p-2 '}  >
                                                    </textarea>
                                                )}
                                            </Field>
                                            <ErrorMsg
                                                name={"Subtitle"} />
                                        </div>
                                        <div className=''>
                                            <label id="description" className='mb-2 dark_grey_color fn_Montserrat fw_500 fs_14 ps-2'>Description</label>
                                            <Field
                                                name={"description"}>
                                                {({ field }) => (
                                                    <textarea id="description" {...field} type="text" className={'w-100 opportunities_create_summary_text_area p-2 '}  >
                                                    </textarea>
                                                )}
                                            </Field>
                                            <ErrorMsg
                                                name={"description"} />
                                        </div>
                                        <InputField
                                            id={"redirectionUrl"}
                                            label={"Redirection Url"}
                                            type={"text"}
                                            placeholder={"Enter Redirection Url"}
                                            name={"redirectionUrl"}
                                        />
                                        <div className="d-flex flex-column flex-md-row gap-5">
                                            <div>
                                                <label htmlFor="kyc" className='mb-2 dark_grey_color fn_Montserrat fw_500 fs_14'>Kyc Status</label>
                                                <AdminRoleCheckBox name="kyc" id="kyc" />
                                            </div>
                                            <div>
                                                <label htmlFor="isImportant" className='mb-2 dark_grey_color fn_Montserrat fw_500 fs_14'>Is Important</label>
                                                <AdminRoleCheckBox name="isImportant" id="isImportant" />
                                            </div>
                                        </div>
                                    </Form>
                                );
                            }}
                        </Formik>
                    </div>
                </InnerContainer>
            </div>
        </InnerLayout>
    );
};

export default NotificationCreate;



