import { APPROVE_FAQ, APPROVE_FAQ_CATEGORY, CREATE_FAQ, CREATE_FAQ_CATEGORY, DELETE_FAQ, DELETE_FAQ_CATEGORY, EDIT_FAQ, EDIT_FAQ_CATEGORY, FAQ_APPROVAL_TABLE, FAQ_APPROVAL_VIEW, FAQ_CATEGORY_APPROVALS_EXPORT, FAQ_CATEGORY_APPROVAL_TABLE, FAQ_CATEGORY_APPROVAL_VIEW, FAQ_CATEGORY_EXPORT, FAQ_CATEGORY_OPTIONS, FAQ_CATEGORY_SUBMIT, FAQ_CATEGORY_TABLE, FAQ_CATEGORY_VERSION_EXPORT, FAQ_CATEGORY_VERSION_HISTORY, FAQ_CATEGORY_VERSION_HISTORY_TABLE, FAQ_OPTIONS, FAQ_TABLE, FAQ_VERSION_HISTORY, FAQ_VERSION_HISTORY_TABLE, REJECT_FAQ, REJECT_FAQ_CATEGORY, VIEW_FAQ, VIEW_FAQ_CATEGORY } from "./types";


export const createFaqCategory = (
    data,
    onSuccess,
    onError
) => {
    return {
        type: CREATE_FAQ_CATEGORY,
        data,
        onSuccess,
        onError,
    };
};

export const approveCategory = (userID, onSuccess, onError) => {
    return {
        type: APPROVE_FAQ_CATEGORY,
        userID,
        onSuccess,
        onError,
    };
};

export const rejectCategory = (userID, onSuccess, onError) => {
    return {
        type: REJECT_FAQ_CATEGORY,
        userID,
        onSuccess,
        onError,
    };
};

export const viewCategoryDetail = (userID, onSuccess, onError) => {
    return {
        type: VIEW_FAQ_CATEGORY,
        userID,
        onSuccess,
        onError,
    };
};

export const viewCategoryList = (data, page_no, onSuccess, onError) => {
    return {
        type: FAQ_CATEGORY_TABLE,
        data,
        page_no,
        onSuccess,
        onError,
    };
};

export const editCategory = (
    userID,
    data,
    onSuccess,
    onError
) => {
    return {
        type: EDIT_FAQ_CATEGORY,
        userID,
        data,
        onSuccess,
        onError,
    };
};


export const deleteCategory = (userID, onSuccess, onError) => {
    return {
        type: DELETE_FAQ_CATEGORY,
        userID,
        onSuccess,
        onError,
    };
};


export const CategoryApprovalList = (data, page_no = 0, onSuccess, onError) => {
    return {
        type: FAQ_CATEGORY_APPROVAL_TABLE,
        data,
        page_no,
        onSuccess,
        onError,
    };
};

export const CategoryApprovalById = (userID, onSuccess, onError) => {
    return {
        type: FAQ_CATEGORY_APPROVAL_VIEW,
        userID,
        onSuccess,
        onError,
    };
};


export const versionHistoryCategoryList = (data, pageNo, onSuccess, onError) => {
    return {
        type: FAQ_CATEGORY_VERSION_HISTORY_TABLE,
        data,
        pageNo,
        onSuccess,
        onError,
    };
};

export const versionHistoryCategoryDetail = (id, onSuccess, onError) => {
    return {
        type: FAQ_CATEGORY_VERSION_HISTORY,
        id,
        onSuccess,
        onError,
    };
};
export const CategoryOptions = (id, onSuccess, onError) => {
    return {
        type: FAQ_CATEGORY_OPTIONS,
        id,
        onSuccess,
        onError,
    };
};

export const faqcategoryTableExport = (onSuccess, onError) => {
    return {
        type: FAQ_CATEGORY_EXPORT,
        onSuccess,
        onError,
    };
};
export const faqcategoryApprovalsExport = (onSuccess, onError) => {
    return {
        type: FAQ_CATEGORY_APPROVALS_EXPORT,
        onSuccess,
        onError,
    };
};
export const faqcategoryVersionExport = (onSuccess, onError) => {
    return {
        type: FAQ_CATEGORY_VERSION_EXPORT,
        onSuccess,
        onError,
    };
};


export const faqcategorySubmit = (id, onSuccess, onError) => {
    return {
        type: FAQ_CATEGORY_SUBMIT,
        id,
        onSuccess,
        onError,
    };
};  