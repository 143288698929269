import { MEDIA_REC_APPROVALS_LIST, MEDIA_REC_APPROVALS_VIEW, MEDIA_REC_APPROVAL_EXPORT, MEDIA_REC_APPROVE, MEDIA_REC_CREATE, MEDIA_REC_DELETE, MEDIA_REC_EDIT, MEDIA_REC_LIST, MEDIA_REC_MAIN_EXPORT, MEDIA_REC_READ, MEDIA_REC_REJECT, MEDIA_REC_SUBMIT, MEDIA_REC_VERSION_EXPORT, MEDIA_REC_VERSION_LIST, MEDIA_REC_VERSION_VIEW } from "./types";



export const viewMediaRecListAction = (data, page = 0, onSuccess, onError) => {
    return {
        type: MEDIA_REC_LIST,
        data,
        page,
        onSuccess,
        onError,
    };
};
export const viewMediaRecAction = (id, onSuccess, onError) => {
    return {
        type: MEDIA_REC_READ,
        id,
        onSuccess,
        onError,
    };
};

export const createMediaRecAction = (data, onSuccess, onError) => {
    return {
        type: MEDIA_REC_CREATE,
        data,
        onSuccess,
        onError,
    };
};
export const editMediaRecAction = (id, data, onSuccess, onError) => {
    return {
        type: MEDIA_REC_EDIT,
        id,
        data,
        onSuccess,
        onError,
    };
};
export const deleteMediaRecAction = (id, onSuccess, onError) => {
    return {
        type: MEDIA_REC_DELETE,
        id,
        onSuccess,
        onError,
    };
};
export const approveMediaRecAction = (id, onSuccess, onError) => {
    return {
        type: MEDIA_REC_APPROVE,
        id,
        onSuccess,
        onError,
    };
};
export const rejectMediaRecAction = (id, onSuccess, onError) => {
    return {
        type: MEDIA_REC_REJECT,
        id,
        onSuccess,
        onError,
    };
};
export const approvalsMediaRecListAction = (data, page, onSuccess, onError) => {
    return {
        type: MEDIA_REC_APPROVALS_LIST,
        data,
        page,
        onSuccess,
        onError,
    };
};
export const approvalsViewByIdMediaRecAction = (id, onSuccess, onError) => {
    return {
        type: MEDIA_REC_APPROVALS_VIEW,
        id,
        onSuccess,
        onError,
    };
};
export const versionHistoryViewAllMediaRecAction = (data, pageNo, onSuccess, onError) => {
    return {
        type: MEDIA_REC_VERSION_LIST,
        data,
        pageNo,
        onSuccess,
        onError,
    };
};
export const versionHistoryByIdMediaRecAction = (id, onSuccess, onError) => {
    return {
        type: MEDIA_REC_VERSION_VIEW,
        id,
        onSuccess,
        onError,
    };
};


export const mediaRecMainExportAction = (onSuccess, onError) => {
    return {
        type: MEDIA_REC_MAIN_EXPORT,
        onSuccess,
        onError,
    };
};
export const mediaRecApprovalsExportAction = (onSuccess, onError) => {
    return {
        type: MEDIA_REC_APPROVAL_EXPORT,
        onSuccess,
        onError,
    };
};
export const mediaRecVersionExportAction = (onSuccess, onError) => {
    return {
        type: MEDIA_REC_VERSION_EXPORT,
        onSuccess,
        onError,
    };
};



export const mediaRecSubmit = (id, onSuccess, onError) => {
    return {
        type: MEDIA_REC_SUBMIT,
        id,
        onSuccess,
        onError,
    };
};  