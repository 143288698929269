import React from 'react'
import RejectIcon from '../../assets/icons/rejectIcon'
import usePermissionChecker from '../utilits/usePermissionChecker';
import { AdminButton } from './versionHistoryBtn'

const RejectBtn = ({ showModal, submit }) => {

    const access = usePermissionChecker("checker");
    return (
        access &&
        <AdminButton onClick={showModal} color="#D20000" padding="3px 15px">
            <RejectIcon fill='#D20000' width={15} height={13} />
            <span className="ms-1 pdt_1">Reject</span>
        </AdminButton>
    )
}

export default RejectBtn;