import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import InnerContainer from "../../../../components/layouts/innerContainer/innerContainer";
import InnerLayout from "../../../../components/layouts/innerLayout/innerLayout";
import LoadAndError from "../../../../components/utilits/LoadAndError";
import VersionHistoryBtn from "../../../../components/buttons/versionHistoryBtn";
import EditBtn from "../../../../components/buttons/editBtn";
import { viewUserReferralAction } from "../../../../redux/actions/userReferralActions";

const UserReferralView = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [userReferralData, setUserReferralData] = useState({});
  const dispatch = useDispatch();
  const params = useParams();
  useEffect(() => {
    setLoading(true);
    dispatch(viewUserReferralAction(params.id, onSuccess, onError));
  }, []);
  const onSuccess = (data) => {
    console.log(data.data);
    setUserReferralData(data.data);
    console.log("SUCCESS");
    setLoading(false);
    setError(false);
  };
  const onError = (data) => {
    console.log("ERROR");
    setLoading(false);
    setError(true);
  };
  const buttonList = [
    <VersionHistoryBtn link={`/static-pages/userReferral/version-history`} />,
    <EditBtn link={`/static-pages/userReferral/edit/${params.id}`} />,
    // <DeleteBtn showModal={deletenotifyMebyid} />,
  ];

  function ViewItemGenerator(title, details) {
    return (
      <>
        <span className="dark_grey_color">{title}</span>
        <p className="dark_title_color">{details}</p>
      </>
    );
  }

  return (
    <InnerLayout buttons={buttonList}>
      <div className="user_view_container mb-4">
        <InnerContainer>
          <LoadAndError loader={loading} error={error}>
            <div className="d-flex flex-column flex-lg-row gap-4 fs_14 py-3 px-3 px-lg-5">
              <div className="col-12 col-lg-6 pe-0 pe-lg-5">
                <div>
                  {ViewItemGenerator("User ID", userReferralData?.user_id)}
                </div>
                <div>
                  {ViewItemGenerator(
                    "Opportunity ID",
                    userReferralData?.opportunity_id
                  )}
                </div>
              </div>
            </div>
          </LoadAndError>
        </InnerContainer>
      </div>
      {/* <DeleteModal
        loader={loadingDelete}
        handleClose={handleClose}
        deleteFn={handleDelete}
        data={deleteData}
        show={show}
      /> */}
    </InnerLayout>
  );
};

export default UserReferralView;
