import React, { useState, useRef, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import CloseIcon from "../../../../assets/icons/closeIcon";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import "./llpModal.css";
import InputField from "../../../form/inputFields/inputField";
import ErrorMsg from "../../../form/errorMsg";
import { notifyMeCreateOptions } from "../../../../redux/actions/notifyMeActions";
import { useDispatch } from "react-redux";
import SelectFields from "../../../form/selectFields/selectFields";
import {
  llpMemberAddAction,
  llpMemberEditAction,
} from "../../../../redux/actions/llpActions";
import successToast from "../../../utilits/successToast";
import errorToast from "../../../utilits/errorToast";
import { useParams } from "react-router-dom";
import moment from "moment";

const AddMemberLLP = ({ data, show, updated, closeModal }) => {
  const formRef = useRef();
  const amount = /^[0-9\b]+$/;
  const dispatch = useDispatch();
  const [userList, setUserList] = useState([]);
  const statusOption = [
    { label: "active", value: "active" },
    { label: "completed", value: "completed" },
    { label: "traded", value: "traded" },
    { label: "ended", value: "ended" },
  ];
  const params = useParams();

  const initialValues = {
    Name: data?.data?.user?.id ? data?.data?.user?.id : "",
    status: data?.data?.status ? data?.data?.status : "",
    amount: data?.data?.amount_invested ? data?.data?.amount_invested : "",
    payoutDate: data?.data?.payout_start_date
      ? moment(data?.data?.payout_start_date).format("YYYY-MM-DD")
      : "",
    traded_date: data?.data?.traded_date
      ? moment(data?.data?.traded_date).format("YYYY-MM-DD")
      : "",
    traded_amount: data?.data?.traded_amount ? data?.data?.traded_amount : "",
  };
  const today = new Date();
  today.setHours(0, 0, 0, 0);
  const loginSchema = Yup.object().shape({
    Name: Yup.string().trim().required("Required"),
    status: Yup.string().trim().required("Required"),
    amount: Yup.string()
      .trim()
      .matches(amount, "Enter valid amount")
      .required("Required"),
    payoutDate: Yup.date(),
    traded_date: Yup.date().when("status", {
      is: "traded",
      then: Yup.date().required("Required"),
    }),
    traded_amount: Yup.string()
      .trim()
      .matches(amount, "Enter valid amount")
      .when("status", {
        is: "traded",
        then: Yup.string()
          .trim()
          .matches(amount, "Enter valid amount")
          .required("Required"),
      }),
  });
  const onSubmit = (values, { resetForm }) => {
    resetForm();
    if (data.type === "edit") {
      dispatch(
        llpMemberEditAction(
          data?.data?.id,
          {
            user_id: values.Name,
            llp_id: params.id,
            amount_invested: values.amount,
            payout_start_date: values.payoutDate ? values.payoutDate : null,
            status: values.status,
            traded_date: values.traded_date ? values.traded_date : null,
            traded_amount: values.traded_amount ? values.traded_amount : null,
          },
          onSuccess,
          onError
        )
      );
    } else
      dispatch(
        llpMemberAddAction(
          {
            user_id: values.Name,
            llp_id: params.id,
            amount_invested: values.amount,
            payout_start_date: values.payoutDate ? values.payoutDate : null,
            status: values.status,
            traded_date: values.traded_date ? values.traded_date : null,
            traded_amount: values.traded_amount ? values.traded_amount : null,
          },
          onSuccess,
          onError
        )
      );
  };

  const onSuccess = (data) => {
    successToast(data.data.message);
    updated();
    closeModal();
  };
  const onError = (data) => {
    errorToast(data.data.data);
  };

  useEffect(() => {
    dispatch(notifyMeCreateOptions(onSuccessDrop, onErrorDrop));
  }, []);
  const onSuccessDrop = (data) => {
    const categoryList = [];
    data?.data?.AllUsers.map((item, index) => {
      categoryList[index] = {
        value: item.id,
        label: `${item.user_name}
     (${item.id})`,
      };
    });
    setUserList(categoryList);
    console.log("SUCCESS");
  };
  const onErrorDrop = (data) => {
    console.log(data);
  };
  return (
    <>
      <Modal
        centered
        show={show}
        onHide={closeModal}
        dialogClassName={`oppoptunities_modals_body mx-auto `}
        contentClassName={"oppoptunities_modals_body_content  p-2"}
      >
        <Modal.Body>
          <>
            <div className="d-flex justify-content-between  ">
              <h2 className="green_color fs_15 fn_Montserrat fw_600 pt-1">
                {data?.type === "edit" ? "Edit Member" : "Add Member"}
              </h2>
              <button
                onClick={closeModal}
                className="light_grey_color  fs_15 fn_Montserrat bg-transparent border-0 fw_600 "
              >
                <span className="pt-1">DISMISS</span>
                <span className="ms-2">
                  <CloseIcon width={13} height={13} fill="#A5A5A5" />
                </span>
              </button>
            </div>
            <Formik
              enableReinitialize={true}
              innerRef={formRef}
              initialValues={initialValues}
              onSubmit={onSubmit}
              validationSchema={loginSchema}
            >
              <Form className="mt-3">
                <SelectFields
                  label={"User"}
                  placeholder={"Select user"}
                  name={"Name"}
                  default1={
                    data?.data?.user?.user_name
                      ? {
                        label: data?.data.user?.user_name,
                        value: data?.data?.user?.id,
                      }
                      : ""
                  }
                  options={userList}
                  id={"user"}
                />
                <SelectFields
                  label={"Status"}
                  placeholder={"Select status"}
                  name={"status"}
                  default1={
                    data?.data?.status
                      ? { label: data?.data?.status, value: data?.data?.status }
                      : ""
                  }
                  options={statusOption}
                  id={"status"}
                />
                {/* <ErrorMsg name={"Description"} /> */}
                <InputField
                  id={"amount"}
                  label={"Investment amount"}
                  type={"text"}
                  placeholder={"Enter investment amount"}
                  name={"amount"}
                />
                <InputField
                  id={"payoutDate"}
                  label={"Invested Date"}
                  type={"date"}
                  placeholder={"Enter Invested date"}
                  name={"payoutDate"}
                />
                <InputField
                  id={"traded_date"}
                  label={"Traded Date"}
                  type={"date"}
                  placeholder={"Enter Traded date"}
                  name={"traded_date"}
                />
                <InputField
                  id={"traded_amount"}
                  label={"Traded amount"}
                  type={"text"}
                  placeholder={"Enter Traded amount"}
                  name={"traded_amount"}
                />
                {/* <ErrorMsg name={"Description"} /> */}

                <div className="d-flex justify-content-end mt-4">
                  {data?.type !== "view" && (
                    <button
                      type="submit"
                      className="secondary_color fs_15  fn_Montserrat fw_600 border-0 tertiary_bg px-4 px-lg-5 py-2 py-lg-2 rounded-2"
                    >
                      {data?.type === "edit" ? "UPDATE" : "CREATE"}
                    </button>
                  )}
                </div>
              </Form>
            </Formik>
          </>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AddMemberLLP;
