


import React from "react";

const DotMenuIcon = ({ fill = "#bcc7ce", width = 10, height = 16 }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 5.313 21.25">
      <path id="Icon_material-more-vert"
        data-name="Icon material-more-vert"
        d="M17.656,11.313A2.656,2.656,0,1,0,15,8.656,2.664,2.664,0,0,0,17.656,11.313Zm0,2.656a2.656,2.656,0,1,0,2.656,2.656A2.664,2.664,0,0,0,17.656,13.969Zm0,7.969a2.656,2.656,0,1,0,2.656,2.656A2.664,2.664,0,0,0,17.656,21.938Z"
        transform="translate(-15 -6)"
        fill={fill} />
    </svg>
  );
};

export default DotMenuIcon;

