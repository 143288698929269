import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import DeleteModal from "../../../modals/deleteModal";
import { TableContainer } from "../../../table/tableContainer";
import { tableContactUsHeader, } from "../../../table/tableData";
import TablePagination from "../../../table/tablePagination";
import LoadingSpinnerTable from "../../../utilits/loadingSpinnerTable";
import "../../../admin/tables/admintable.css";
import * as adminRolesActions from "../../../../redux/actions/adminRoleActions";
import successToast from "../../../utilits/successToast";
import errorToast from "../../../utilits/errorToast";
import { access } from "../../../utilits/access";
import { deleteContactAction, viewContactListAction } from "../../../../redux/actions/contactUsActions";
import moment from "moment"
import usePermissionChecker from "../../../utilits/usePermissionChecker";
const ContactUstableTableContent = ({ page, search, changePage }) => {
    const access = usePermissionChecker("maker");

    const dispatch = useDispatch();
    const handleShow = () => setShow(true);
    const handleClose = () => setShow(false);
    const [contactUsData, setContactUsData] = useState([]);
    const [deleteData, setDeleteData] = useState({ name: "", id: "" });
    const [show, setShow] = useState(false);
    const [loading, setLoading] = useState(false);
    const [loadingTable, setLoadingTable] = useState(false);
    const [pageData, setPageData] = useState({ noOfItems: 0, noOfPages: 1 })

    useEffect(() => {
        setLoadingTable(true);
        dispatch(
            viewContactListAction(
                {
                    search: {
                        contact_us_id: search?.value?.contactUsId,
                        phone_number: search?.value?.phone,
                        querry_name: search?.value?.name
                    }
                },
                page,
                onSuccess,
                onError
            )
        );
    }, [page, search, loading]);

    const onSuccess = (data) => {
        setContactUsData(data.data.data);
        setPageData({
            noOfItems: data.data.count,
            noOfPages: data.data.pages
        })
        setLoadingTable(false);
    };
    const onError = (data) => {
        errorToast(data.message);
        setLoadingTable(false);
    };


    const handleDelete = () => {
        setLoading(true);
        dispatch(
            deleteContactAction(
                deleteData.id,
                onDeleteSuccess,
                onDeleteError
            )
        );
    };
    const onDeleteSuccess = (data) => {
        console.log(data);
        successToast(data.message)
        setLoading(false)
        setShow(false)
    };
    const onDeleteError = (data) => {
        errorToast(data.message);
        setLoading(false);
    };
    function handlePagination(type) {
        if (type === "+") {
            if (page + 1 < pageData.noOfPages) changePage("+");
        }
        else if (type === "-")
            if (page > 0) changePage("-");
    }
    return (
        <TableContainer maxWidth="100%" width="90%">
            <div className="adminuser_table__container ">
                {loadingTable && <LoadingSpinnerTable />}
                <table className={"adminuser_table"}>
                    <thead className="adminuser_table__header">
                        <tr className="adminuser_table__row">
                            {tableContactUsHeader.map((item) => {
                                return (
                                    <th
                                        key={item}
                                        className={`${item === "Status" ? `${access ? "" : "text-start"} position-sticky end-0` : null
                                            } text-nowrap adminuser_table__heading `}
                                    >
                                        {item}
                                    </th>
                                );
                            })}
                        </tr>
                    </thead>
                    {loadingTable === false && (
                        <tbody className="adminuser_table__body">
                            {contactUsData.map((item, index) => {
                                return (
                                    <tr key={item.id} className="adminuser_table__row">
                                        <td className="text-nowrap dark_title_color adminuser_table__data ">
                                            {page > 0 ? 12 * page + (index + 1) : index + 1}
                                        </td>
                                        <td className="text-nowrap dark_title_color adminuser_table__data">
                                            {item.id}
                                        </td>
                                        <td className="text-nowrap secondary_color adminuser_table__data">
                                            {item.query_name}
                                        </td>
                                        <td className="text-nowrap   secondary_color adminuser_table__data adminrole_table_empty__data">
                                            {item.phone_number}
                                        </td>
                                        <td className="text-nowrap   secondary_color adminuser_table__data adminrole_table_empty__data">
                                            {item.email}
                                        </td>
                                        <td className="text-nowrap   secondary_color adminuser_table__data adminrole_table_empty__data">
                                            {item.createdAt === null
                                                ? "-"
                                                : moment(
                                                    item.createdAt
                                                ).format("DD-MM-YYYY HH:mm:ss")}
                                        </td>
                                        <td className="text-nowrap   secondary_color adminuser_table__data adminrole_table_empty__data">
                                            {item.updatedAt === null
                                                ? "-"
                                                : moment(
                                                    item.updatedAt
                                                ).format("DD-MM-YYYY HH:mm:ss")}
                                        </td>
                                        <td className="text-nowrap  position-sticky end-0 adminuser_table__data">
                                            <div className="d-flex w-100 align-items-center justify-content-start  gap-2">
                                                <Link
                                                    className="text-decoration-none primary_color"
                                                    to={
                                                        { pathname: `/static-pages/contact-us-query/view/${item.id}` }
                                                    }
                                                    state={item}
                                                >
                                                    View
                                                </Link>
                                                {
                                                    access && <>
                                                        {item.editable &&
                                                            <>
                                                                <span className="vr"></span>

                                                                <Link
                                                                    className="text-decoration-none secondary_color"
                                                                    to={
                                                                        { pathname: `/static-pages/contact-us-query/edit/${item.id}` }
                                                                    }
                                                                    state={item}
                                                                >
                                                                    Edit
                                                                </Link>
                                                                {item.lastActionApproved && (
                                                                    <>
                                                                        <span className="vr"></span>
                                                                        <button
                                                                            onClick={() => { setDeleteData({ name: item.query_name, id: item.id }); handleShow() }}
                                                                            className="text-decoration-none primary_color light_red_color border-0 bg-transparent "
                                                                        >
                                                                            Delete
                                                                        </button>
                                                                    </>)}
                                                            </>
                                                        }

                                                    </>}
                                            </div>
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>)}
                </table>
            </div>
            <DeleteModal
                loader={loading}
                handleClose={handleClose}
                deleteFn={handleDelete}
                data={deleteData}
                show={show}
            />
            <TablePagination
                paginate={handlePagination}
                currentPage={page}
                pageData={pageData}
            />
        </TableContainer>

    )
}
export default ContactUstableTableContent