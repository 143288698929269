import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import DeleteBtn from "../../../../components/buttons/deleteBtn";
import EditBtn from "../../../../components/buttons/editBtn";
import VersionHistoryBtn from "../../../../components/buttons/versionHistoryBtn";
import InnerContainer from "../../../../components/layouts/innerContainer/innerContainer";
import InnerLayout from "../../../../components/layouts/innerLayout/innerLayout";
import { access } from "../../../../components/utilits/access";
import errorToast from "../../../../components/utilits/errorToast";
import LoadAndError from "../../../../components/utilits/LoadAndError";
import LoadingSpinnerTable from "../../../../components/utilits/loadingSpinnerTable";
import * as opporTypeActions from "../../../../redux/actions/opportunityTypeActions";
import "../opportunity-type.css";
const OpportunityTypeRead = () => {
    const params = useParams()
    const dispatch = useDispatch()
    const [opporTypeViewData, SetOpporTypeViewData] = useState({ id: "", name: "", createdAt: "", updatedAt: "" })
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(false)
    useEffect(() => {
        setLoading(true)
        dispatch(
            opporTypeActions.viewOpporType(params.id, onSuccess, onError)
        );
    }, [params.id])
    const onSuccess = (data) => {
        SetOpporTypeViewData(data.data);
        setLoading(false)
        setError(false)
    };
    const onError = (data) => {
        errorToast(data.message)
        setLoading(false)
        setError(true)
    };
    console.log(opporTypeViewData);
    const buttonList = [
        <VersionHistoryBtn link={`/opportunity/opportunity-type/version-history`} />,
        <EditBtn link={`/opportunity/opportunity-type/edit/${opporTypeViewData.id}`} />,
    ];


    const data = [
        {
            heading: "Opportunity Type ID",
            title: opporTypeViewData.id,
        },
        {
            heading: "Opportunity Type Name",
            title: opporTypeViewData.name,
        },
        {
            heading: "Created At",
            title: opporTypeViewData.createdAt,
        },

        {
            heading: "Updated At",
            title: opporTypeViewData.updatedAt,
        },
    ];

    return (
        <InnerLayout buttons={buttonList}>
            <div className="opportunity-type-container">
                <InnerContainer>
                    {loading ? (
                        <LoadingSpinnerTable />
                    ) : (
                        <LoadAndError loader={loading} error={error}>
                            <div className="pt-3  ps-4 ps-lg-4 ms-3">
                                {data.map((item, index) => (
                                    <div className="mb-2">
                                        <h5 className="dark_grey_color fs_13 fn_Montserrat fw_500 m-0">
                                            {item.heading}
                                        </h5>
                                        <p
                                            className={` fs_13 fn_Nunito fw_500 dark_title_color  `}>
                                            {item.title}
                                        </p>
                                    </div>
                                ))}
                            </div>
                        </LoadAndError>
                    )}
                </InnerContainer>
            </div>
        </InnerLayout>
    );
};

export default OpportunityTypeRead;

