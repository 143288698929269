

import React from "react";

const DownloadIcon = ({ fill = "#666", width = 10, height = 16 }) => {
    return (

        <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 11.17 13.564">
        <path id="Icon_material-file-download" data-name="Icon material-file-download" d="M18.67,9.287H15.479V4.5H10.692V9.287H7.5l5.585,5.585ZM7.5,16.468v1.6H18.67v-1.6Z" transform="translate(-7.5 -4.5)" fill={fill}/>
      </svg>
      


    );
};

export default DownloadIcon;

