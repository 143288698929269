import { call, takeLatest } from "redux-saga/effects";
import {
  CONTACT_APPROVALS_EXPORT,
  CONTACT_APPROVALS_LIST,
  CONTACT_APPROVALS_VIEW,
  CONTACT_APPROVE,
  CONTACT_CREATE,
  CONTACT_DELETE,
  CONTACT_EDIT,
  CONTACT_LIST,
  CONTACT_MAIN_EXPORT,
  CONTACT_READ,
  CONTACT_REJECT,
  CONTACT_SUBMIT,
  CONTACT_VERSION_EXPORT,
  CONTACT_VERSION_LIST,
  CONTACT_VERSION_VIEW,
} from "../actions/types";
import * as contactUsAPis from "../apis/contactUsApis";

export function* addContactWorker(action) {
  try {
    const res = yield call(contactUsAPis.addContactApi, action.data);
    console.log(res, "AddAdminRole");
    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res.data);
      console.log(res.data);
    } else if (res.status === 200 && res.data.status === "fail") {
      console.log(res.data);
      yield action.onError(res);
    } else {
      yield action.onError("Something Went Wrong");
    }
  } catch (err) {
    console.log(err);
    yield action.onError("Something Went Wrong");
  }
}

export function* approvalsContactListWorker(action) {
  try {
    const res = yield call(
      contactUsAPis.approvalsContactListpApi,
      action.page,
      action.data
    );
    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res.data);
      console.log(res.data);
    } else if (res.status === 200 && res.data.status === "fail") {
      console.log(res);
      yield action.onError(res);
    } else {
      yield action.onError("Something Went Wrong!");
      console.log(res.data);
    }
  } catch (err) {
    console.log(err);
    yield action.onError("Something Went Wrong!!");
  }
}
export function* viewApprovalContactWorker(action) {
  try {
    const res = yield call(contactUsAPis.viewApprovalContactApi, action.id);
    console.log(res, "Contact approvals");
    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res.data);
      console.log(res.data);
    } else if (res.status === 200 && res.data.status === "fail") {
      console.log(res);
      yield action.onError(res);
    } else {
      yield action.onError("Something Went Wrong!");
      console.log(res.data);
    }
  } catch (err) {
    console.log(err);
    yield action.onError("Something Went Wrong!!");
  }
}

export function* approveContactWorker(action) {
  try {
    const res = yield call(contactUsAPis.approveContactApi, {
      contactus_id: action.ContactId,
    });
    // console.log(res, "AddAdminRole");
    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res.data);
      console.log(res.data);
    } else if (res.status === 200 && res.data.status === "fail") {
      console.log(res);
      yield action.onError(res.data);
    } else {
      yield action.onError("Something Went Wrong!");
      console.log(res.data);
    }
  } catch (err) {
    console.log(err);
    yield action.onError("Something Went Wrong!!");
  }
}

export function* rejectContactWorker(action) {
  try {
    const res = yield call(contactUsAPis.rejectContactApi, action.id);
    // console.log(res, "AddAdminRole");
    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res.data);
      console.log(res.data);
    } else if (res.status === 200 && res.data.status === "fail") {
      console.log(res);
      yield action.onError(res.data);
    } else {
      yield action.onError("Something Went Wrong!");
      console.log(res.data);
    }
  } catch (err) {
    console.log(err);
    yield action.onError("Something Went Wrong!!");
  }
}

export function* viewContactListWorker(action) {
  try {
    const res = yield call(contactUsAPis.viewContactListApi, action.id);
    // console.log(res, "AddAdminRole");
    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res.data);
      console.log(res);
    } else if (res.status === 200 && res.data.status === "fail") {
      console.log(res);
      yield action.onError(res.data);
    } else {
      yield action.onError("Something Went Wrong");
    }
  } catch (err) {
    console.log(err);
    yield action.onError("Something Went Wrong");
  }
}

export function* viewContactWorker(action) {
  try {
    const res = yield call(
      contactUsAPis.viewContactApi,
      action.page,
      action.data
    );
    // console.log(res, "AddAdminRole");
    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res.data);
      console.log(res.data);
    } else if (res.status === 200 && res.data.status === "fail") {
      console.log(res.data);
      yield action.onError(res);
    } else {
      yield action.onError("Something Went Wrong!");
      console.log(res.data);
    }
  } catch (err) {
    console.log(err);
    yield action.onError("Something Went Wrong!!");
  }
}
export function* editContactWorker(action) {
  try {
    const res = yield call(
      contactUsAPis.editContactApi,
      action.id,
      action.data
    );
    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res.data);
      console.log(res.data);
    } else if (res.status === 200 && res.data.status === "fail") {
      console.log(res);
      yield action.onError(res);
    } else {
      yield action.onError("Something Went Wrong");
    }
  } catch (err) {
    console.log(err);
    yield action.onError("Something Went Wrong");
  }
}
export function* deleteContactWorker(action) {
  try {
    const res = yield call(contactUsAPis.deleteContactApi, action.id);
    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res.data);
      console.log(res.data);
    } else if (res.status === 200 && res.data.status === "fail") {
      console.log(res);
      yield action.onError(res);
    } else {
      yield action.onError("Something Went Wrong");
    }
  } catch (err) {
    console.log(err);
    yield action.onError("Something Went Wrong");
  }
}

export function* versionListContactWorker(action) {
  try {
    const res = yield call(
      contactUsAPis.versionContactListpApi,
      action.page,
      action.data
    );
    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res.data);
      console.log(res.data);
    } else if (res.status === 200 && res.data.status === "fail") {
      console.log(res);
      yield action.onError(res);
    } else {
      yield action.onError("Something Went Wrong!");
      console.log(res.data);
    }
  } catch (err) {
    console.log(err);
    yield action.onError("Something Went Wrong!!");
  }
}

export function* versionViewContactWorker(action) {
  try {
    const res = yield call(contactUsAPis.viewVersionContactApi, action.id);
    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res);
      console.log(res.data);
    } else if (res.status === 200 && res.data.status === "fail") {
      console.log(res);
      yield action.onError(res);
    } else {
      yield action.onError("Something Went Wrong!");
      console.log(res);
    }
  } catch (err) {
    console.log(err);
    yield action.onError("Something Went Wrong!!");
  }
}

export function* contactMainExportWorker(action) {
  try {
    const res = yield call(contactUsAPis.contactMainExportApi);
    console.log(res);
    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res.data);
    }
  } catch (error) {}
}

export function* contactApprovalsExportWorker(action) {
  try {
    const res = yield call(contactUsAPis.contactApprovalsExportApi);
    console.log(res);
    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res.data);
    }
  } catch (error) {}
}

export function* contactVersionExportWorker(action) {
  try {
    const res = yield call(contactUsAPis.contactVersionExportApi);
    console.log(res);
    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res.data);
    }
  } catch (error) {}
}


export function* contactSubmitWorker(action) {
  try {
      const res = yield call(contactUsAPis.contactSubmitApi, action.id);
      console.log(res);
      if (res.status === 200 && res.data.status === "success") {
          yield action.onSuccess(res.data);
      } else {
          yield action.onError(res.data);
      }
  } catch (error) { }
}

/**********************************/
/************WATHCHERS**************/
/**********************************/

export function* watchAddContactWorker() {
  yield takeLatest(CONTACT_CREATE, addContactWorker);
}

export function* watchApprovalsContactListWorker() {
  yield takeLatest(CONTACT_APPROVALS_LIST, approvalsContactListWorker);
}
export function* watchViewApprovalContactWorker() {
  yield takeLatest(CONTACT_APPROVALS_VIEW, viewApprovalContactWorker);
}
export function* watchApproveContactWorker() {
  yield takeLatest(CONTACT_APPROVE, approveContactWorker);
}
export function* watchRejectContactWorker() {
  yield takeLatest(CONTACT_REJECT, rejectContactWorker);
}
export function* watchViewContactWorker() {
  yield takeLatest(CONTACT_LIST, viewContactWorker);
}
export function* watchViewContactListWorker() {
  yield takeLatest(CONTACT_READ, viewContactListWorker);
}
export function* watcheditContactWorker() {
  yield takeLatest(CONTACT_EDIT, editContactWorker);
}

export function* watchDeleteContactWorker() {
  yield takeLatest(CONTACT_DELETE, deleteContactWorker);
}
export function* watchVersionListContactWorker() {
  yield takeLatest(CONTACT_VERSION_LIST, versionListContactWorker);
}
export function* watchversionViewContactWorker() {
  yield takeLatest(CONTACT_VERSION_VIEW, versionViewContactWorker);
}

export function* watchContactMainExportWorker() {
  yield takeLatest(CONTACT_MAIN_EXPORT, contactMainExportWorker);
}
export function* watchContactApprovalsExportWorker() {
  yield takeLatest(CONTACT_APPROVALS_EXPORT, contactApprovalsExportWorker);
}
export function* watchContactVersionExportWorker() {
  yield takeLatest(CONTACT_VERSION_EXPORT, contactVersionExportWorker);
}
export function* watchcontactSubmitWorker() {
  yield takeLatest(
      CONTACT_SUBMIT,
      contactSubmitWorker
  );
}