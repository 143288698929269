export const ADMIN_LOGIN = "ADMIN_LOGIN";
export const ADMIN_LOGOUT = "ADMIN_LOGOUT";

/******ADMIN ROLES********/
export const ADMIN_ROLELIST_LOADING = "ADMIN_ROLELIST_LOADING";
export const ADMIN_ROLELIST_LOADED = "ADMIN_ROLELIST_LOADED";
export const ADMIN_ROLELIST_LOADING_FAILED = "ADMIN_ROLELIST_LOADING_FAILED";
export const ADMIN_ADD_ROLE = "ADMIN_ADD_ROLE";
export const ADMIN_VIEW_ROLES = "ADMIN_VIEW_ROLES";
export const ADMIN_VIEW_ROLE = "ADMIN_VIEW_ROLE";
export const ADMIN_EDIT_ROLE = "ADMIN_EDIT_ROLE";
export const ADMIN_DELETE_ROLE = "ADMIN_DELETE_ROLE";
export const ADMIN_APPROVE_ROLE = "ADMIN_APPROVE_ROLE";
export const ADMIN_REJECT_ROLE = "ADMIN_REJECT_ROLE";
export const ADMIN_APPROVALS_LIST_ROLE = "ADMIN_APPROVALS_LIST_ROLE";
export const ADMIN_APPROVALS_VIEW = "ADMIN_APPROVALS_VIEW";
export const ADMIN_VERSION_HISTORY_ROLE = "ADMIN_VERSION_HISTORY_ROLE";
export const ROLE_TYPE_DATA = "ROLE_TYPE_DATA";
export const ADMIN_VERSION_HISTORY_LIST_ROLE =
  "ADMIN_VERSION_HISTORY_LIST_ROLE";
export const ADMIN_VERSION_HISTORY_VIEW_ROLE =
  "ADMIN_VERSION_HISTORY_VIEW_ROLE";
export const ADMIN_APPROVAL_DETAILS = "ADMIN_APPROVAL_DETAILS";
export const ROLES_EXPORT = "ROLES_EXPORT";
export const ROLES_APPROVALS_EXPORT = "ROLES_APPROVALS_EXPORT";
export const ROLES_VERSION_EXPORT = "ROLES_VERSION_EXPORT";
export const ADMIN_ROLE_SUBMIT = "ADMIN_ROLE_SUBMIT";

/**************/

export const ADD_ADMIN = "ADD_ADMIN";
export const APPROVE_ADMIN = "APPROVE_ADMIN";
export const REJECT_ADMIN = "REJECT_ADMIN";
export const VIEW_ADMIN = "VIEW_ADMIN";
export const VIEW_ADMIN_LIST = "VIEW_ADMIN_LIST";
export const EDIT_ADMIN = "EDIT_ADMIN";
export const DELETE_ADMIN = "DELETE_ADMIN";
export const EDIT_ADMIN_CHECKER = "EDIT_ADMIN_CHECKER";
export const ADMIN_APPROVAL_LIST = "ADMIN_APPROVAL_LIST";
export const VERSION_HISTORY_LIST = "VERSION_HISTORY_LIST";
export const ADMIN_DETAILS_VERSION_HISTORY = "ADMIN_DETAILS_VERSION_HISTORY";
export const EXPORT_ADMIN = "EXPORT_ADMIN";
export const ADMIN_EXPORT = "ADMIN_EXPORT";
export const ADMIN_APPROVALS_EXPORT = "ADMIN_APPROVALS_EXPORT";
export const ADMIN_VERSION_EXPORT = "ADMIN_VERSION_EXPORT";
export const ADMIN_SUBMIT = "ADMIN_SUBMIT";

/******USERS********/
export const ADD_USER = "ADD_USER";
export const EDIT_USER = "EDIT_USER";
export const DELETE_USER = "DELETE_USER";
export const USER_EMAIL_OTP = "USER_EMAIL_OTP";
export const USER_PHONE_OTP = "USER_PHONE_OTP";
export const USER_APPROVAL_LIST = "USER_APPROVAL_LIST";
export const USER_LIST = "USER_LIST";
export const INDIVIDUAL_USER_APPROVAL_DETAILS =
  "INDIVIDUAL_USER_APPROVAL_DETAILS";
export const USER_VIEW_DETAILS = "USER_VIEW_DETAILS";
export const RESET_PASSWORD_EMAIL_VIEW = "RESET_PASSWORD_EMAIL_VIEW";
export const USER_VERSION_HISTORY_LIST = "USER_VERSION_HISTORY_LIST";
export const USER_VERSION_HISTORY_VIEW = "USER_VERSION_HISTORY_VIEW";
export const APPROVE_USER = "APPROVE_USER";
export const ADD_PROFILE_IMAGE = "ADD_PROFILE_IMAGE";
export const UPLOAD_ADDRESS_PROOF_FRONT_IMAGE =
  "UPLOAD_ADDRESS_PROOF_FRONT_IMAGE";
export const UPLOAD_ADDRESS_PROOF_BACK_IMAGE =
  "UPLOAD_ADDRESS_PROOF_BACK_IMAGE";
export const GET_PROFILE_IMAGE = "GET_PROFILE_IMAGE";
export const REJECT_USER = "REJECT_USER";
export const KYC_STATUS_USER = "KYC_STATUS_USER";
export const PAN_APPROVE_REJECT = "PAN_APPROVE_REJECT";
export const BANK_APPROVE_REJECT = "BANK_APPROVE_REJECT";
export const USER_EXPORT = "USER_EXPORT";
export const USER_APPROVALS_EXPORT = "USER_APPROVALS_EXPORT";
export const USER_VERSION_EXPORT = "USER_VERSION_EXPORT";
export const USER_RESET_EXPORT = "USER_RESET_EXPORT";
export const USER_EMAIL_EXPORT = "USER_EMAIL_EXPORT";
export const USER_PHONE_EXPORT = "USER_PHONE_EXPORT";
export const FORGOT_PASS_SEND_EMAIL = "FORGOT_PASS_SEND_EMAIL";
export const USER_SUBMIT = "USER_SUBMIT";

/***vikas images ****** */

export const ADD_PAN_IMAGE = "ADD_PAN_IMAGE";
export const ADD_BANK_IMAGE = "ADD_BANK_IMAGE";

export const ADDRESS_APPROVED = "ADDRESS_APPROVED";
export const ADDRESS_REJECTED = "ADDRESS_REJECTED";
export const PAN_APPROVED = "PAN_APPROVED";
export const PAN_REJECTED = "PAN_REJECTED";
export const BANK_APPROVED = "BANK_APPROVED";
export const BANK_REJECTED = "BANK_REJECTED";
/******OPPORTUNIY TYPES********/
export const OPPOR_TYPE_LIST = "OPPOR_TYPE_LIST";
export const OPPOR_TYPE_READ = "OPPOR_TYPE_READ";
export const OPPOR_TYPE_CREATE = "OPPOR_TYPE_CREATE";
export const OPPOR_TYPE_EDIT = "OPPOR_TYPE_EDIT";
export const OPPOR_TYPE_DELETE = "OPPOR_TYPE_DELETE";
export const OPPOR_TYPE_APPROVALS_LIST = "OPPOR_TYPE_APPROVALS_LIST";
export const OPPOR_TYPE_APPROVE = "OPPOR_TYPE_APPROVE";
export const OPPOR_TYPE_REJECT = "OPPOR_TYPE_REJECT";
export const OPPOR_TYPE_APPROVALS_VIEW = "OPPOR_TYPE_APPROVALS_VIEW";
export const OPPOR_TYPE_VERSION_LIST = "OPPOR_TYPE_VERSION_LIST";
export const OPPOR_TYPE_VERSION_VIEW = "OPPOR_TYPE_VERSION_VIEW";
export const OPPOR_TYPE_EXPORT = "OPPOR_TYPE_EXPORT";
export const OPPOR_TYPE_APPROVALS_EXPORT = "OPPOR_TYPE_APPROVALS_EXPORT";
export const OPPOR_TYPE_VERSION_EXPORT = "OPPOR_TYPE_VERSION_EXPORT";
export const OPPOR_TYPE_SUBMIT = "OPPOR_TYPE_SUBMIT";

/******WISHLIST********/
export const WISHLIST_LIST = "WISHLIST_LIST";
export const WISHLIST_READ = "WISHLIST_READ";
export const WISHLIST_CREATE = "WISHLIST_CREATE";
export const WISHLIST_EDIT = "WISHLIST_EDIT";
export const WISHLIST_DELETE = "WISHLIST_DELETE";
export const WISHLIST_APPROVE = "WISHLIST_APPROVE";
export const WISHLIST_REJECT = "WISHLIST_REJECT";
export const WISHLIST_APPROVALS_LIST = "WISHLIST_APPROVALS_LIST";
export const WISHLIST_APPROVALS_VIEW = "WISHLIST_APPROVALS_VIEW";
export const WISHLIST_VERSION_LIST = "WISHLIST_VERSION_LIST";
export const WISHLIST_VERSION_VIEW = "WISHLIST_VERSION_VIEW";
export const WISHLIST_FIND_ALL = "WISHLIST_FIND_ALL";
export const VIEW_APPROVALS_WISHLIST_ID = "VIEW_APPROVALS_WISHLIST_ID";
export const WISHLIST_VERSION_HISTORY_BY_ID = "WISHLIST_VERSION_HISTORY_BY_ID";
export const WISHLIST_EXPORT = "WISHLIST_EXPORT";
export const WISHLIST_APPROVALS_EXPORT = "WISHLIST_APPROVALS_EXPORT";
export const WISHLIST_VERSION_EXPORT = "WISHLIST_VERSION_EXPORT";
export const WISHLIST_SUBMIT = "WISHLIST_SUBMIT";

/******NOTIFY ME********/
export const NOTIFY_LIST = "NOTIFY_LIST";
export const NOTIFY_READ = "NOTIFY_READ";
export const NOTIFY_CREATE = "NOTIFY_CREATE";
export const NOTIFY_EDIT = "NOTIFY_EDIT";
export const NOTIFY_DELETE = "NOTIFY_DELETE";
export const NOTIFY_APPROVE = "NOTIFY_APPROVE";
export const NOTIFY_REJECT = "NOTIFY_REJECT";
export const NOTIFY_APPROVALS_LIST = "NOTIFY_APPROVALS_LIST";
export const NOTIFY_APPROVALS_VIEW = "NOTIFY_APPROVALS_VIEW";
export const NOTIFY_VERSION_LIST = "NOTIFY_VERSION_LIST";
export const NOTIFY_VERSION_VIEW = "NOTIFY_VERSION_VIEW";
export const CREATE_NOTIFYME_OPTIONS = "CREATE_NOTIFYME_OPTIONS";
export const NOTIFY_EXPORT = "NOTIFY_EXPORT";
export const NOTIFY_APPROVALS_EXPORT = "NOTIFY_APPROVALS_EXPORT";
export const NOTIFY_VERSION_EXPORT = "NOTIFY_VERSION_EXPORT";
export const NOTIFY_SUBMIT = "NOTIFY_SUBMIT";

/*****MEMBER TRADE********/
export const MEMBER_TRADE_LIST = "MEMBER_TRADE_LIST";
export const MEMBER_TRADE_READ = "MEMBER_TRADE_READ";
export const MEMBER_TRADE_CREATE = "MEMBER_TRADE_CREATE";
export const MEMBER_TRADE_EDIT = "MEMBER_TRADE_EDIT";
export const MEMBER_TRADE_DELETE = "MEMBER_TRADE_DELETE";
export const MEMBER_TRADE_APPROVE = "MEMBER_TRADE_APPROVE";
export const MEMBER_TRADE_REJECT = "MEMBER_TRADE_REJECT";
export const MEMBER_TRADE_APPROVALS_LIST = "MEMBER_TRADE_APPROVALS_LIST";
export const MEMBER_TRADE_APPROVALS_VIEW = "MEMBER_TRADE_APPROVALS_VIEW";
export const MEMBER_TRADE_VERSION_LIST = "MEMBER_TRADE_VERSION_LIST";
export const MEMBER_TRADE_VERSION_VIEW = "MEMBER_TRADE_VERSION_VIEW";
export const CREATE_MEMBER_TRADE_OPTIONS = "CREATE_MEMBER_TRADE_OPTIONS";
export const MEMBER_EXPORT = "MEMBER_EXPORT";
export const MEMBER_APPROVALS_EXPORT = "MEMBER_APPROVALS_EXPORT";
export const MEMBER_VERSION_EXPORT = "MEMBER_VERSION_EXPORT";
export const MEMBER_SUBMIT = "MEMBER_SUBMIT";

/******OPPORUNITIES*********/

export const FETCH_OPPORTUNITES_TYPE = "FETCH_OPPORTUNITES_TYPE";
export const OPPORTUNITES_CREATE = "OPPORTUNITES_CREATE";
export const PUBLISH_OPPORTUNITY = "PUBLISH_OPPORTUNITY";
export const APPROVE_OPPORTUNITY = "APPROVE_OPPORTUNITY";
export const REJECT_OPPORTUNITY = "REJECT_OPPORTUNITY";
export const EDIT_OPPORTUNITY = "EDIT_OPPORTUNITY";
export const DELETE_OPPORTUNITY = "DELETE_OPPORTUNITY";
export const FETCH_OPPORTUNITES_LIST = "FETCH_OPPORTUNITES_LIST";
export const FETCH_OPPORTUNITY_DETAILS = "FETCH_OPPORTUNITY_DETAILS";
export const FETCH_OPPORTUNITES_APPROVAL_LIST =
  "FETCH_OPPORTUNITES_APPROVAL_LIST";
export const OPPORTUNITES_VERSION_HISTORY_TABLE =
  "OPPORTUNITES_VERSION_HISTORY_TABLE";
export const OPPORTUNITES_ANALYTICS = "OPPORTUNITES_ANALYTICS";
export const OPPORTUNITES_VERSION_VIEW = "OPPORTUNITES_VERSION_VIEW";
export const OPPORTUNITES_NAME_LIST = "OPPORTUNITES_NAME_LIST";
export const OPPORTUNITES_APPROVALS_VIEW = "OPPORTUNITES_APPROVALS_VIEW";
export const OPPORTUNITIES_EXPORT = "OPPORTUNITIES_EXPORT";
export const OPPORTUNITIES_APPROVALS_EXPORT = "OPPORTUNITIES_APPROVALS_EXPORT";
export const OPPORTUNITIES_VERSION_EXPORT = "OPPORTUNITIES_VERSION_EXPORT";
export const OPPORTUNITIES_SUBMIT = "OPPORTUNITIES_SUBMIT";

/*****MEMBER TRADE BUY IN********/
export const MEMBER_TRADE_BUY_IN_LIST = "MEMBER_TRADE_BUY_IN_LIST";
export const MEMBER_TRADE_BUY_IN_READ = "MEMBER_TRADE_BUY_IN_READ";
export const MEMBER_TRADE_BUY_IN_CREATE = "MEMBER_TRADE_BUY_IN_CREATE";
export const MEMBER_TRADE_BUY_IN_EDIT = "MEMBER_TRADE_BUY_IN_EDIT";
export const MEMBER_TRADE_BUY_IN_DELETE = "MEMBER_TRADE_BUY_IN_DELETE";
export const MEMBER_TRADE_BUY_IN_APPROVE = "MEMBER_TRADE_BUY_IN_APPROVE";
export const MEMBER_TRADE_BUY_IN_REJECT = "MEMBER_TRADE_BUY_IN_REJECT";
export const MEMBER_TRADE_BUY_IN_APPROVALS_LIST = "MEMBER_TRADE_BUY_IN_APPROVALS_LIST";
export const MEMBER_TRADE_BUY_IN_APPROVALS_VIEW = "MEMBER_TRADE_BUY_IN_APPROVALS_VIEW";
export const MEMBER_TRADE_BUY_IN_VERSION_LIST = "MEMBER_TRADE_BUY_IN_VERSION_LIST";
export const MEMBER_TRADE_BUY_IN_VERSION_VIEW = "MEMBER_TRADE_BUY_IN_VERSION_VIEW";
export const CREATE_MEMBER_TRADE_BUY_IN_OPTIONS = "CREATE_MEMBER_TRADE_BUY_IN_OPTIONS";
export const MEMBER_TRADE_BUY_IN_EXPORT = " MEMBER_TRADE_BUY_IN_EXPORT";
export const MEMBER_TRADE_BUY_IN_APPROVALS_EXPORT = " MEMBER_TRADE_BUY_IN_APPROVALS_EXPORT";
export const MEMBER_TRADE_BUY_IN_VERSION_EXPORT = " MEMBER_TRADE_BUY_IN_VERSION_EXPORT";
export const MEMBER_TRADE_BUY_IN_SUBMIT = " MEMBER_TRADE_BUY_IN_SUBMIT";

/******OPPORUNITIES Editor*********/

export const OPPORTUNITES_UPDATE_JSON = "OPPORTUNITES_UPDATE_JSON";
export const OPPORTUNITES_CLEAR_JSON = "OPPORTUNITES_CLEAR_JSON";
export const ADD_SECTION = "ADD_SECTION";
export const DELETE_SECTION = "DELETE_SECTION";
export const OPPORTUNITES_UPDATE_PARENT_SECTION =
  "OPPORTUNITES_UPDATE_PARENT_SECTION";
export const OPPORTUNITES_UPDATE_SUB_SECTION =
  "OPPORTUNITES_UPDATE_SUB_SECTION";
export const OPPORTUNITES_UPDATE_CONTENT_SECTION =
  "OPPORTUNITES_UPDATE_CONTENT_SECTION";

/******Our Team********/
export const CREATE_OUR_TEAM = "CREATE_OUR_TEAM";
export const APPROVE_OUR_TEAM = "APPROVE_OUR_TEAM";
export const REJECT_OUR_TEAM = "REJECT_OUR_TEAM";
export const VIEW_OUR_TEAM = "VIEW_OUR_TEAM";
export const OUR_TEAM_TABLE = "OUR_TEAM_TABLE";
export const EDIT_OUR_TEAM = "EDIT_OUR_TEAM";
export const DELETE_OUR_TEAM = "DELETE_OUR_TEAM";
export const OUR_TEAM_APPROVAL_TABLE = "OUR_TEAM_APPROVAL_LIST_TABLE";
export const OUR_TEAM_APPROVAL_VIEW = "OUR_TEAM_APPROVAL_VIEW";
export const OUR_TEAM_VERSION_HISTORY_TABLE = "OUR_TEAM_VERSION_HISTORY_TABLE";
export const OUR_TEAM_VERSION_HISTORY = "OUR_TEAM_VERSION_HISTORY";
export const OUR_TEAM_OPTIONS = "OUR_TEAM_OPTIONS";
export const OUR_TEAM_EXPORT = "OUR_TEAM_EXPORT";
export const OUR_TEAM_APPROVALS_EXPORT = "OUR_TEAM_APPROVALS_EXPORT";
export const OUR_TEAM_VERSION_EXPORT = "OUR_TEAM_VERSION_EXPORT";
export const OUR_TEAM_SUBMIT = "OUR_TEAM_SUBMIT";

/****** EXPRESS********/
export const CREATE_EXPRESS_INTEREST = "CREATE_EXPRESS_INTEREST";
export const APPROVE_EXPRESS_INTEREST = "APPROVE_EXPRESS_INTEREST";
export const REJECT_EXPRESS_INTEREST = "REJECT_EXPRESS_INTEREST";
export const VIEW_EXPRESS_INTEREST = "VIEW_EXPRESS_INTEREST";
export const EXPRESS_INTEREST_TABLE = "EXPRESS_INTEREST_TABLE";
export const EDIT_EXPRESS_INTEREST = "EDIT_EXPRESS_INTEREST";
export const DELETE_EXPRESS_INTEREST = "DELETE_EXPRESS_INTEREST";
export const EXPRESS_INTEREST_APPROVAL_TABLE =
  "EXPRESS_INTEREST_APPROVAL_LIST_TABLE";
export const EXPRESS_INTEREST_APPROVAL_VIEW = "EXPRESS_INTEREST_APPROVAL_VIEW";
export const EXPRESS_INTEREST_VERSION_HISTORY_TABLE =
  "EXPRESS_INTEREST_VERSION_HISTORY_TABLE";
export const EXPRESS_INTEREST_VERSION_HISTORY =
  "EXPRESS_INTEREST_VERSION_HISTORY";
export const EXPRESS_INTEREST_OPTIONS = "EXPRESS_INTEREST_OPTIONS";
export const EXPRESS_EXPORT = "EXPRESS_EXPORT";
export const EXPRESS_APPROVALS_EXPORT = "EXPRESS_APPROVALS_EXPORT";
export const EXPRESS_VERSION_EXPORT = "EXPRESS_VERSION_EXPORT";
export const EXPRESS_SUBMIT = "EXPRESS_SUBMIT";

/****** TESTIMONIALS********/
export const CREATE_TESTIMONIALS = "CREATE_TESTIMONIALS";
export const APPROVE_TESTIMONIALS = "APPROVE_TESTIMONIALS";
export const REJECT_TESTIMONIALS = "REJECT_TESTIMONIALS";
export const VIEW_TESTIMONIALS = "VIEW_TESTIMONIALS";
export const TESTIMONIALS_TABLE = "TESTIMONIALS_TABLE";
export const EDIT_TESTIMONIALS = "EDIT_TESTIMONIALS";
export const DELETE_TESTIMONIALS = "DELETE_TESTIMONIALS";
export const TESTIMONIALS_APPROVAL_TABLE = "TESTIMONIALS_APPROVAL_LIST_TABLE";
export const TESTIMONIALS_APPROVAL_VIEW = "TESTIMONIALS_APPROVAL_VIEW";
export const TESTIMONIALS_VERSION_HISTORY_TABLE =
  "TESTIMONIALS_VERSION_HISTORY_TABLE";
export const TESTIMONIALS_VERSION_HISTORY = "TESTIMONIALS_VERSION_HISTORY";
export const TESTIMONIALS_OPTIONS = "TESTIMONIALS_OPTIONS";
export const TESTIMONIALS_EXPORT = "TESTIMONIALS_EXPORT";
export const TESTIMONIALS_APPROVALS_EXPORT = "TESTIMONIALS_APPROVALS_EXPORT";
export const TESTIMONIALS_VERSION_EXPORT = "TESTIMONIALS_VERSION_EXPORT";
export const TESTIMONIALS_SUBMIT = "TESTIMONIALS_SUBMIT";

/******LLP ACTIONS*********/
export const LLP_LIST = "LLP_LIST";
export const LLP_READ = "LLP_READ";
export const LLP_CREATE = "LLP_CREATE";
export const LLP_EDIT = "LLP_EDIT";
export const LLP_DELETE = "LLP_DELETE";
export const LLP_APPROVE = "LLP_APPROVE";
export const LLP_REJECT = "LLP_REJECT";
export const LLP_APPROVALS_LIST = "LLP_APPROVALS_LIST";
export const LLP_APPROVALS_VIEW = "LLP_APPROVALS_VIEW";
export const LLP_VERSION_LIST = "LLP_VERSION_LIST";
export const LLP_VERSION_VIEW = "LLP_VERSION_VIEW";
export const CREATE_LLP_OPTIONS = "CREATE_LLP_OPTIONS";
export const LLP_EXPORT = "LLP_EXPORT";
export const LLP_APPROVALS_EXPORT = "LLP_APPROVALS_EXPORT";
export const LLP_VERSION_EXPORT = "LLP_VERSION_EXPORT";
export const LLP_SUBMIT = "LLP_SUBMIT";
export const LLP_IMPORT_USERS = "LLP_IMPORT_USERS";

/******llp member details*********/
export const LLP_MEMBER_DELETE = "LLP_MEMBER_DELETE";
export const LLP_MEMBER_EDIT = "LLP_MEMBER_EDIT";
export const LLP_MEMBER_CREATE = "LLP_MEMBER_CREATE";
/******llp member details*********/
export const LLP_DOC_DELETE = "LLP_DOC_DELETE";
export const LLP_DOC_EDIT = "LLP_DOC_EDIT";
export const LLP_DOC_CREATE = "LLP_DOC_CREATE";

/******CONTACT US ACTIONS*********/
export const CONTACT_CREATE = "CONTACT_CREATE";
export const CONTACT_APPROVALS_LIST = "CONTACT_APPROVALS_LIST";
export const CONTACT_APPROVALS_VIEW = "CONTACT_APPROVALS_VIEW";
export const CONTACT_APPROVE = "CONTACT_APPROVE";
export const CONTACT_REJECT = "CONTACT_REJECT";
export const CONTACT_LIST = "CONTACT_LIST";
export const CONTACT_READ = "CONTACT_READ";
export const CONTACT_EDIT = "CONTACT_EDIT";
export const CONTACT_DELETE = "CONTACT_DELETE";
export const CONTACT_VERSION_LIST = "CONTACT_VERSION_LIST";
export const CONTACT_VERSION_VIEW = "CONTACT_VERSION_VIEW";
export const CREATE_CONTACT_OPTIONS = "CREATE_CONTACT_OPTIONS";
export const CONTACT_MAIN_EXPORT = "CONTACT_MAIN_EXPORT";
export const CONTACT_APPROVALS_EXPORT = "CONTACT_APPROVALS_EXPORT";
export const CONTACT_VERSION_EXPORT = "CONTACT_VERSION_EXPORT";
export const CONTACT_SUBMIT = "CONTACT_SUBMIT";

/******NEWSLETTER US ACTIONS*********/
export const NEWSLETTER_LIST = "NEWSLETTER_LIST";
export const NEWSLETTER_READ = "NEWSLETTER_READ";
export const NEWSLETTER_CREATE = "NEWSLETTER_CREATE";
export const NEWSLETTER_EDIT = "NEWSLETTER_EDIT";
export const NEWSLETTER_DELETE = "NEWSLETTER_DELETE";
export const NEWSLETTER_APPROVE = "NEWSLETTER_APPROVE";
export const NEWSLETTER_REJECT = "NEWSLETTER_REJECT";
export const NEWSLETTER_APPROVALS_LIST = "NEWSLETTER_APPROVALS_LIST";
export const NEWSLETTER_APPROVALS_VIEW = "NEWSLETTER_APPROVALS_VIEW";
export const NEWSLETTER_VERSION_LIST = "NEWSLETTER_VERSION_LIST";
export const NEWSLETTER_VERSION_VIEW = "NEWSLETTER_VERSION_VIEW";
export const CREATE_NEWSLETTER_OPTIONS = "CREATE_NEWSLETTER_OPTIONS";
export const NEWSLETTER_MAIN_EXPORT = "NEWSLETTER_MAIN_EXPORT";
export const NEWSLETTER_APPROVAL_EXPORT = "NEWSLETTER_APPROVAL_EXPORT";
export const NEWSLETTER_VERSION_EXPORT = "NEWSLETTER_VERSION_EXPORT";
export const NEWSLETTER_SUBMIT = "NEWSLETTER_SUBMIT";

/******NEWSLETTER US ACTIONS*********/
export const MEDIA_REC_LIST = "MEDIA_REC_LIST";
export const MEDIA_REC_READ = "MEDIA_REC_READ";
export const MEDIA_REC_CREATE = "MEDIA_REC_CREATE";
export const MEDIA_REC_EDIT = "MEDIA_REC_EDIT";
export const MEDIA_REC_DELETE = "MEDIA_REC_DELETE";
export const MEDIA_REC_APPROVE = "MEDIA_REC_APPROVE";
export const MEDIA_REC_REJECT = "MEDIA_REC_REJECT";
export const MEDIA_REC_APPROVALS_LIST = "MEDIA_REC_APPROVALS_LIST";
export const MEDIA_REC_APPROVALS_VIEW = "MEDIA_REC_APPROVALS_VIEW";
export const MEDIA_REC_VERSION_LIST = "MEDIA_REC_VERSION_LIST";
export const MEDIA_REC_VERSION_VIEW = "MEDIA_REC_VERSION_VIEW";
export const CREATE_MEDIA_REC_OPTIONS = "CREATE_MEDIA_REC_OPTIONS";
export const MEDIA_REC_MAIN_EXPORT = "MEDIA_REC_MAIN_EXPORT";
export const MEDIA_REC_APPROVAL_EXPORT = "MEDIA_REC_APPROVAL_EXPORT";
export const MEDIA_REC_VERSION_EXPORT = "MEDIA_REC_VERSION_EXPORT";
export const MEDIA_REC_SUBMIT = "MEDIA_REC_SUBMIT";

/******OUR PARTNERS*********/

export const PARTNERS_LIST = "PARTNERS_LIST";
export const PARTNERS_READ = "PARTNERS_READ";
export const PARTNERS_CREATE = "PARTNERS_CREATE";
export const PARTNERS_EDIT = "PARTNERS_EDIT";
export const PARTNERS_DELETE = "PARTNERS_DELETE";
export const PARTNERS_APPROVE = "PARTNERS_APPROVE";
export const PARTNERS_REJECT = "PARTNERS_REJECT";
export const PARTNERS_APPROVALS_LIST = "PARTNERS_APPROVALS_LIST";
export const PARTNERS_APPROVALS_VIEW = "PARTNERS_APPROVALS_VIEW";
export const PARTNERS_VERSION_LIST = "PARTNERS_VERSION_LIST";
export const PARTNERS_VERSION_VIEW = "PARTNERS_VERSION_VIEW";
export const CREATE_PARTNERS_OPTIONS = "CREATE_PARTNERS_OPTIONS";
export const PARTNERS_EXPORT = "PARTNERS_EXPORT";
export const PARTNERS_APPROVALS_EXPORT = "PARTNERS_APPROVALS_EXPORT";
export const PARTNERS_VERSION_EXPORT = "PARTNERS_VERSION_EXPORT";
export const PARTNERS_SUBMIT = "PARTNERS_SUBMIT";

/******OUR ADVISORS*********/

export const ADVISORS_LIST = "ADVISORS_LIST";
export const ADVISORS_READ = "ADVISORS_READ";
export const ADVISORS_CREATE = "ADVISORS_CREATE";
export const ADVISORS_EDIT = "ADVISORS_EDIT";
export const ADVISORS_DELETE = "ADVISORS_DELETE";
export const ADVISORS_APPROVE = "ADVISORS_APPROVE";
export const ADVISORS_REJECT = "ADVISORS_REJECT";
export const ADVISORS_APPROVALS_LIST = "ADVISORS_APPROVALS_LIST";
export const ADVISORS_APPROVALS_VIEW = "ADVISORS_APPROVALS_VIEW";
export const ADVISORS_VERSION_LIST = "ADVISORS_VERSION_LIST";
export const ADVISORS_VERSION_VIEW = "ADVISORS_VERSION_VIEW";
export const CREATE_ADVISORS_OPTIONS = "CREATE_ADVISORS_OPTIONS";
export const ADVISORS_EXPORT = "ADVISORS_EXPORT";
export const ADVISORS_APPROVALS_EXPORT = "ADVISORS_APPROVALS_EXPORT";
export const ADVISORS_VERSION_EXPORT = "ADVISORS_VERSION_EXPORT";
export const ADVISORS_SUBMIT = "ADVISORS_SUBMIT";

/******FAQ********/
export const CREATE_FAQ = "CREATE_FAQ";
export const APPROVE_FAQ = "APPROVE_FAQ";
export const REJECT_FAQ = "REJECT_FAQ";
export const VIEW_FAQ = "VIEW_FAQ";
export const FAQ_TABLE = "FAQ_TABLE";
export const EDIT_FAQ = "EDIT_FAQ";
export const DELETE_FAQ = "DELETE_FAQ";
export const FAQ_APPROVAL_TABLE = "FAQ_APPROVAL_LIST_TABLE";
export const FAQ_APPROVAL_VIEW = "FAQ_APPROVAL_VIEW";
export const FAQ_VERSION_HISTORY_TABLE = "FAQ_VERSION_HISTORY_TABLE";
export const FAQ_VERSION_HISTORY = "FAQ_VERSION_HISTORY";
export const FAQ_OPTIONS = "FAQ_OPTIONS";
export const FAQ_EXPORT = "FAQ_EXPORT";
export const FAQ_APPROVALS_EXPORT = "FAQ_APPROVALS_EXPORT";
export const FAQ_VERSION_EXPORT = "FAQ_VERSION_EXPORT";
export const FAQ_SUBMIT = "FAQ_SUBMIT";

/******FAQ CATEGORY********/
export const CREATE_FAQ_CATEGORY = "CREATE_FAQ_CATEGORY";
export const APPROVE_FAQ_CATEGORY = "APPROVE_FAQ_CATEGORY";
export const REJECT_FAQ_CATEGORY = "REJECT_FAQ_CATEGORY";
export const VIEW_FAQ_CATEGORY = "VIEW_FAQ_CATEGORY";
export const FAQ_CATEGORY_TABLE = "FAQ_CATEGORY_TABLE";
export const EDIT_FAQ_CATEGORY = "EDIT_FAQ_CATEGORY";
export const DELETE_FAQ_CATEGORY = "DELETE_FAQ_CATEGORY";
export const FAQ_CATEGORY_APPROVAL_TABLE = "FAQ_CATEGORY_APPROVAL_LIST_TABLE";
export const FAQ_CATEGORY_APPROVAL_VIEW = "FAQ_CATEGORY_APPROVAL_VIEW";
export const FAQ_CATEGORY_VERSION_HISTORY_TABLE =
  "FAQ_CATEGORY_VERSION_HISTORY_TABLE";
export const FAQ_CATEGORY_VERSION_HISTORY = "FAQ_CATEGORY_VERSION_HISTORY";
export const FAQ_CATEGORY_OPTIONS = "FAQ_CATEGORY_OPTIONS";
export const FAQ_CATEGORY_EXPORT = "FAQ_CATEGORY_EXPORT";
export const FAQ_CATEGORY_APPROVALS_EXPORT = "FAQ_CATEGORY_APPROVALS_EXPORT";
export const FAQ_CATEGORY_VERSION_EXPORT = "FAQ_CATEGORY_VERSION_EXPORT";
export const FAQ_CATEGORY_SUBMIT = "FAQ_CATEGORY_SUBMIT";

/******STATIC SCREENS********/
export const CREATE_STATIC = "CREATE_STATIC";
export const STATIC_APPROVAL_TABLE = "STATIC_APPROVAL_LIST_TABLE";
export const STATIC_APPROVAL_VIEW = "STATIC_APPROVAL_VIEW";
export const APPROVE_STATIC = "APPROVE_STATIC";
export const REJECT_STATIC = "REJECT_STATIC";
export const STATIC_TABLE = "STATIC_TABLE";
export const VIEW_STATIC = "VIEW_STATIC";
export const EDIT_STATIC = "EDIT_STATIC";
export const DELETE_STATIC = "DELETE_STATIC";
export const STATIC_VERSION_HISTORY_TABLE = "STATIC_VERSION_HISTORY_TABLE";
export const STATIC_VERSION_HISTORY = "STATIC_VERSION_HISTORY";
export const STATIC_OPTIONS = "STATIC_OPTIONS";
export const STATIC_MAIN_EXPORT = "STATIC_MAIN_EXPORT";
export const STATIC_APPROVAL_EXPORT = "STATIC_APPROVAL_EXPORT";
export const STATIC_VERSION_EXPORT = "STATIC_VERSION_EXPORT";
export const STATIC_SUBMIT = "STATIC_SUBMIT";

/******BLOG CATEGORY********/
export const CREATE_BLOG_CATEGORY = "CREATE_BLOG_CATEGORY";
export const APPROVE_BLOG_CATEGORY = "APPROVE_BLOG_CATEGORY";
export const REJECT_BLOG_CATEGORY = "REJECT_BLOG_CATEGORY";
export const VIEW_BLOG_CATEGORY = "VIEW_BLOG_CATEGORY";
export const BLOG_CATEGORY_TABLE = "BLOG_CATEGORY_TABLE";
export const EDIT_BLOG_CATEGORY = "EDIT_BLOG_CATEGORY";
export const DELETE_BLOG_CATEGORY = "DELETE_BLOG_CATEGORY";
export const BLOG_CATEGORY_APPROVAL_TABLE = "BLOG_CATEGORY_APPROVAL_LIST_TABLE";
export const BLOG_CATEGORY_APPROVAL_VIEW = "BLOG_CATEGORY_APPROVAL_VIEW";
export const BLOG_CATEGORY_VERSION_HISTORY_TABLE =
  "BLOG_CATEGORY_VERSION_HISTORY_TABLE";
export const BLOG_CATEGORY_VERSION_HISTORY = "BLOG_CATEGORY_VERSION_HISTORY";
export const BLOG_CATEGORY_MAIN_EXPORT = "BLOG_CATEGORY_MAIN_EXPORT";
export const BLOG_CATEGORY_APPROVAL_EXPORT = "BLOG_CATEGORY_APPROVAL_EXPORT";
export const BLOG_CATEGORY_VERSION_EXPORT = "BLOG_CATEGORY_VERSION_EXPORT";
export const BLOG_CATEGORY_SUBMIT = "BLOG_CATEGORY_SUBMIT";

/******BLOG ********/
export const CREATE_BLOG = "CREATE_BLOG";
export const APPROVE_BLOG = "APPROVE_BLOG";
export const REJECT_BLOG = "REJECT_BLOG";
export const VIEW_BLOG = "VIEW_BLOG";
export const BLOG_TABLE = "BLOG_TABLE";
export const EDIT_BLOG = "EDIT_BLOG";
export const DELETE_BLOG = "DELETE_BLOG";
export const BLOG_APPROVAL_TABLE = "BLOG_APPROVAL_LIST_TABLE";
export const BLOG_APPROVAL_VIEW = "BLOG_APPROVAL_VIEW";
export const BLOG_VERSION_HISTORY_TABLE = "BLOG_VERSION_HISTORY_TABLE";
export const BLOG_VERSION_HISTORY = "BLOG_VERSION_HISTORY";
export const BLOG_OPTIONS = "BLOG_OPTIONS";
export const BLOG_CATEGORY_OPTIONS = "BLOG_CATEGORY_OPTIONS";
export const BLOG_APPROVALS_EXPORT = "BLOG_APPROVALS_EXPORT";
export const BLOG_VERSION_EXPORT = "BLOG_VERSION_EXPORT";
export const BLOG_MAIN_EXPORT = "BLOG_MAIN_EXPORT";
export const BLOG_SUBMIT = "BLOG_SUBMIT";

/******HOW IT WORKS ACTIONS*********/
export const HOW_IT_WORKS_LIST = "HOW_IT_WORKS_LIST";
export const HOW_IT_WORKS_READ = "HOW_IT_WORKS_READ";
export const HOW_IT_WORKS_CREATE = "HOW_IT_WORKS_CREATE";
export const HOW_IT_WORKS_EDIT = "HOW_IT_WORKS_EDIT";
export const HOW_IT_WORKS_DELETE = "HOW_IT_WORKS_DELETE";
export const HOW_IT_WORKS_APPROVE = "HOW_IT_WORKS_APPROVE";
export const HOW_IT_WORKS_REJECT = "HOW_IT_WORKS_REJECT";
export const HOW_IT_WORKS_APPROVALS_LIST = "HOW_IT_WORKS_APPROVALS_LIST";
export const HOW_IT_WORKS_APPROVALS_VIEW = "HOW_IT_WORKS_APPROVALS_VIEW";
export const HOW_IT_WORKS_VERSION_LIST = "HOW_IT_WORKS_VERSION_LIST";
export const HOW_IT_WORKS_VERSION_VIEW = "HOW_IT_WORKS_VERSION_VIEW";
export const CREATE_HOW_IT_WORKS_OPTIONS = "CREATE_HOW_IT_WORKS_OPTIONS";
export const ACTIVATE_HOW_IT_WORKS_OPTIONS = "ACTIVATE_HOW_IT_WORKS_OPTIONS";
export const HOW_IT_WORKS_MAIN_EXPORT = "HOW_IT_WORKS_MAIN_EXPORT";
export const HOW_IT_WORKS_APPROVALS_EXPORT = "HOW_IT_WORKS_APPROVALS_EXPORT";
export const HOW_IT_WORKS_VERSION_EXPORT = "HOW_IT_WORKS_VERSION_EXPORT";
export const HOW_IT_WORKS_SUBMIT = "HOW_IT_WORKS_SUBMIT";

/*************HOME**************** */
export const HOME_LIST = "HOME_LIST";
export const HOME_READ = "HOME_READ";
export const HOME_CREATE = "HOME_CREATE";
export const HOME_EDIT = "HOME_EDIT";
export const HOME_DELETE = "HOME_DELETE";
export const HOME_APPROVE = "HOME_APPROVE";
export const HOME_REJECT = "HOME_REJECT";
export const HOME_APPROVALS_LIST = "HOME_APPROVALS_LIST";
export const HOME_APPROVALS_VIEW = "HOME_APPROVALS_VIEW";
export const HOME_VERSION_LIST = "HOME_VERSION_LIST";
export const HOME_VERSION_VIEW = "HOME_VERSION_VIEW";
export const CREATE_HOME_OPTIONS = "CREATE_HOME_OPTIONS";
export const ACTIVATE_HOME_OPTIONS = "ACTIVATE_HOME_OPTIONS";
export const HOME_MAIN_EXPORT = "HOME_MAIN_EXPORT";
export const HOME_APPROVALS_EXPORT = "HOME_APPROVALS_EXPORT";
export const HOME_VERSION_EXPORT = "HOME_VERSION_EXPORT";
export const HOME_SUBMIT = "HOME_SUBMIT";

/******CAMPAIGN OPPORTUNITY********/
export const CREATE_CAMPAIGN_OPPOR = "CREATE_CAMPAIGN_OPPOR";
export const APPROVE_CAMPAIGN_OPPOR = "APPROVE_CAMPAIGN_OPPOR";
export const REJECT_CAMPAIGN_OPPOR = "REJECT_CAMPAIGN_OPPOR";
export const VIEW_CAMPAIGN_OPPOR = "VIEW_CAMPAIGN_OPPOR";
export const CAMPAIGN_OPPOR_TABLE = "CAMPAIGN_OPPOR_TABLE";
export const EDIT_CAMPAIGN_OPPOR = "EDIT_CAMPAIGN_OPPOR";
export const DELETE_CAMPAIGN_OPPOR = "DELETE_CAMPAIGN_OPPOR";
export const CAMPAIGN_OPPOR_APPROVAL_TABLE =
  "CAMPAIGN_OPPOR_APPROVAL_LIST_TABLE";
export const CAMPAIGN_OPPOR_APPROVAL_VIEW = "CAMPAIGN_OPPOR_APPROVAL_VIEW";
export const CAMPAIGN_OPPOR_VERSION_HISTORY_TABLE =
  "CAMPAIGN_OPPOR_VERSION_HISTORY_TABLE";
export const CAMPAIGN_OPPOR_VERSION_HISTORY = "CAMPAIGN_OPPOR_VERSION_HISTORY";
export const CAMPAIGN_OPPOR_OPTIONS = "CAMPAIGN_OPPOR_OPTIONS";
export const CAMPAIGN_OPPOR_EXPORT = "CAMPAIGN_OPPOR_EXPORT";
export const CAMPAIGN_OPPOR_APPROVALS_EXPORT =
  "CAMPAIGN_OPPOR_APPROVALS_EXPORT";
export const CAMPAIGN_OPPOR_VERSION_EXPORT = "CAMPAIGN_OPPOR_VERSION_EXPORT";
export const CAMPAIGN_OPPOR_SUBMIT = "CAMPAIGN_OPPOR_SUBMIT";
export const CAMPAIGN_OPPOR_ACTIVE = "CAMPAIGN_OPPOR_ACTIVE";

/*************CAMPAIGN**************** */
export const CAMPAIGN_LIST = "CAMPAIGN_LIST";
export const CAMPAIGN_READ = "CAMPAIGN_READ";
export const CAMPAIGN_CREATE = "CAMPAIGN_CREATE";
export const CAMPAIGN_EDIT = "CAMPAIGN_EDIT";
export const CAMPAIGN_DELETE = "CAMPAIGN_DELETE";
export const CAMPAIGN_APPROVE = "CAMPAIGN_APPROVE";
export const CAMPAIGN_REJECT = "CAMPAIGN_REJECT";
export const CAMPAIGN_APPROVALS_LIST = "CAMPAIGN_APPROVALS_LIST";
export const CAMPAIGN_APPROVALS_VIEW = "CAMPAIGN_APPROVALS_VIEW";
export const CAMPAIGN_VERSION_LIST = "CAMPAIGN_VERSION_LIST";
export const CAMPAIGN_VERSION_VIEW = "CAMPAIGN_VERSION_VIEW";
export const CREATE_CAMPAIGN_OPTIONS = "CREATE_CAMPAIGN_OPTIONS";
export const ACTIVATE_CAMPAIGN_OPTIONS = "ACTIVATE_CAMPAIGN_OPTIONS";
export const CAMPAIGN_MAIN_EXPORT = "CAMPAIGN_MAIN_EXPORT";
export const CAMPAIGN_APPROVALS_EXPORT = "CAMPAIGN_APPROVALS_EXPORT";
export const CAMPAIGN_VERSION_EXPORT = "CAMPAIGN_VERSION_EXPORT";
export const CAMPAIGN_SUBMIT = "CAMPAIGN_SUBMIT";

/******INSIGHTS*********/
export const INSIGHTS_LIST = "INSIGHTS_LIST";
export const INSIGHTS_READ = "INSIGHTS_READ";
export const INSIGHTS_CREATE = "INSIGHTS_CREATE";
export const INSIGHTS_EDIT = "INSIGHTS_EDIT";
export const INSIGHTS_DELETE = "INSIGHTS_DELETE";
export const INSIGHTS_APPROVE = "INSIGHTS_APPROVE";
export const INSIGHTS_REJECT = "INSIGHTS_REJECT";
export const INSIGHTS_APPROVALS_LIST = "INSIGHTS_APPROVALS_LIST";
export const INSIGHTS_APPROVALS_VIEW = "INSIGHTS_APPROVALS_VIEW";
export const INSIGHTS_VERSION_LIST = "INSIGHTS_VERSION_LIST";
export const INSIGHTS_VERSION_VIEW = "INSIGHTS_VERSION_VIEW";
export const CREATE_INSIGHTS_OPTIONS = "CREATE_INSIGHTS_OPTIONS";
export const ACTIVATE_INSIGHTS_OPTIONS = "ACTIVATE_INSIGHTS_OPTIONS";
export const INSIGHTS_MAIN_EXPORT = "INSIGHTS_MAIN_EXPORT";
export const INSIGHTS_APPROVALS_EXPORT = "INSIGHTS_APPROVALS_EXPORT";
export const INSIGHTS_VERSION_EXPORT = "INSIGHTS_VERSION_EXPORT";
export const INSIGHTS_SUBMIT = "INSIGHTS_SUBMIT";

/******USER REFERRAL*********/
export const USER_REFERRAL_LIST = "USER_REFERRAL_LIST";
export const USER_REFERRAL_READ = "USER_REFERRAL_READ";
export const USER_REFERRAL_CREATE = "USER_REFERRAL_CREATE";
export const USER_REFERRAL_EDIT = "USER_REFERRAL_EDIT";
export const USER_REFERRAL_DELETE = "USER_REFERRAL_DELETE";
export const USER_REFERRAL_APPROVE = "USER_REFERRAL_APPROVE";
export const USER_REFERRAL_REJECT = "USER_REFERRAL_REJECT";
export const USER_REFERRAL_APPROVALS_LIST = "USER_REFERRAL_APPROVALS_LIST";
export const USER_REFERRAL_APPROVALS_VIEW = "USER_REFERRAL_APPROVALS_VIEW";
export const USER_REFERRAL_VERSION_LIST = "USER_REFERRAL_VERSION_LIST";
export const USER_REFERRAL_VERSION_VIEW = "USER_REFERRAL_VERSION_VIEW";
export const CREATE_USER_REFERRAL_OPTIONS = "CREATE_USER_REFERRAL_OPTIONS";
export const ACTIVATE_USER_REFERRAL_OPTIONS = "ACTIVATE_USER_REFERRAL_OPTIONS";
export const USER_REFERRAL_MAIN_EXPORT = "USER_REFERRAL_MAIN_EXPORT";
export const USER_REFERRAL_APPROVALS_EXPORT = "USER_REFERRAL_APPROVALS_EXPORT";
export const USER_REFERRAL_VERSION_EXPORT = "USER_REFERRAL_VERSION_EXPORT";
export const USER_REFERRAL_SUBMIT = "USER_REFERRAL_SUBMIT";
export const FETCH_OPPORTUNITES_ID = "FETCH_OPPORTUNITES_ID";

/*************PARTNER WITH US**************** */
export const PARTNER_WITH_US_LIST = "PARTNER_WITH_US_LIST";
export const PARTNER_WITH_US_READ = "PARTNER_WITH_US_READ";
export const PARTNER_WITH_US_CREATE = "PARTNER_WITH_US_CREATE";
export const PARTNER_WITH_US_EDIT = "PARTNER_WITH_US_EDIT";
export const PARTNER_WITH_US_DELETE = "PARTNER_WITH_US_DELETE";
export const PARTNER_WITH_US_APPROVE = "PARTNER_WITH_US_APPROVE";
export const PARTNER_WITH_US_REJECT = "PARTNER_WITH_US_REJECT";
export const PARTNER_WITH_US_APPROVALS_LIST = "PARTNER_WITH_US_APPROVALS_LIST";
export const PARTNER_WITH_US_APPROVALS_VIEW = "PARTNER_WITH_US_APPROVALS_VIEW";
export const PARTNER_WITH_US_VERSION_LIST = "PARTNER_WITH_US_VERSION_LIST";
export const PARTNER_WITH_US_VERSION_VIEW = "PARTNER_WITH_US_VERSION_VIEW";
export const CREATE_PARTNER_WITH_US_OPTIONS = "CREATE_PARTNER_WITH_US_OPTIONS";
export const ACTIVATE_PARTNER_WITH_US_OPTIONS =
  "ACTIVATE_PARTNER_WITH_US_OPTIONS";
export const PARTNER_WITH_US_MAIN_EXPORT = "PARTNER_WITH_US_MAIN_EXPORT";
export const PARTNER_WITH_US_APPROVALS_EXPORT =
  "PARTNER_WITH_US_APPROVALS_EXPORT";
export const PARTNER_WITH_US_VERSION_EXPORT = "PARTNER_WITH_US_VERSION_EXPORT";
export const PARTNER_WITH_US_SUBMIT = "PARTNER_WITH_US_SUBMIT";


/*************PARTNER WITH US FORM**************** */
export const PARTNER_WITH_US_FORM_LIST = "PARTNER_WITH_US_FORM_LIST";
export const PARTNER_WITH_US_FORM_READ = "PARTNER_WITH_US_FORM_READ";
// export const PARTNER_WITH_US_FORM_CREATE = "PARTNER_WITH_US_FORM_CREATE";
// export const PARTNER_WITH_US_FORM_EDIT = "PARTNER_WITH_US_FORM_EDIT";
// export const PARTNER_WITH_US_FORM_DELETE = "PARTNER_WITH_US_FORM_DELETE";
// export const PARTNER_WITH_US_FORM_APPROVE = "PARTNER_WITH_US_FORM_APPROVE";
// export const PARTNER_WITH_US_FORM_REJECT = "PARTNER_WITH_US_FORM_REJECT";
// export const PARTNER_WITH_US_FORM_APPROVALS_LIST = "PARTNER_WITH_US_FORM_APPROVALS_LIST";
// export const PARTNER_WITH_US_FORM_APPROVALS_VIEW = "PARTNER_WITH_US_FORM_APPROVALS_VIEW";
// export const PARTNER_WITH_US_FORM_VERSION_LIST = "PARTNER_WITH_US_FORM_VERSION_LIST";
// export const PARTNER_WITH_US_FORM_VERSION_VIEW = "PARTNER_WITH_US_FORM_VERSION_VIEW";
// export const CREATE_PARTNER_WITH_US_FORM_OPTIONS = "CREATE_PARTNER_WITH_US_FORM_OPTIONS";
// export const ACTIVATE_PARTNER_WITH_US_FORM_OPTIONS =
//   "ACTIVATE_PARTNER_WITH_US_FORM_OPTIONS";
// export const PARTNER_WITH_US_FORM_MAIN_EXPORT = "PARTNER_WITH_US_FORM_MAIN_EXPORT";
// export const PARTNER_WITH_US_FORM_APPROVALS_EXPORT =
//   "PARTNER_WITH_US_FORM_APPROVALS_EXPORT";
// export const PARTNER_WITH_US_FORM_VERSION_EXPORT = "PARTNER_WITH_US_FORM_VERSION_EXPORT";
// export const PARTNER_WITH_US_FORM_SUBMIT = "PARTNER_WITH_US_FORM_SUBMIT";

/******REFER AND EARN********/
export const CREATE_REFER = "CREATE_REFER";
export const APPROVE_REFER = "APPROVE_REFER";
export const REJECT_REFER = "REJECT_REFER";
export const VIEW_REFER = "VIEW_REFER";
export const REFER_TABLE = "REFER_TABLE";
export const EDIT_REFER = "EDIT_REFER";
export const DELETE_REFER = "DELETE_REFER";
export const REFER_APPROVAL_TABLE = "REFER_APPROVAL_LIST_TABLE";
export const REFER_APPROVAL_VIEW = "REFER_APPROVAL_VIEW";
export const REFER_VERSION_HISTORY_TABLE = "REFER_VERSION_HISTORY_TABLE";
export const REFER_VERSION_HISTORY = "REFER_VERSION_HISTORY";
export const REFER_OPTIONS = "REFER_OPTIONS";
export const REFER_EXPORT = "REFER_EXPORT";
export const REFER_APPROVALS_EXPORT = "REFER_APPROVALS_EXPORT";
export const REFER_VERSION_EXPORT = "REFER_VERSION_EXPORT";
export const REFER_SUBMIT = "REFER_SUBMIT";

/******REFER AND EARN********/
export const CREATE_BADGES = "CREATE_BADGES";
export const APPROVE_BADGES = "APPROVE_BADGES";
export const REJECT_BADGES = "REJECT_BADGES";
export const VIEW_BADGES = "VIEW_BADGES";
export const BADGES_TABLE = "BADGES_TABLE";
export const EDIT_BADGES = "EDIT_BADGES";
export const DELETE_BADGES = "DELETE_BADGES";
export const BADGES_APPROVAL_TABLE = "BADGES_APPROVAL_LIST_TABLE";
export const BADGES_APPROVAL_VIEW = "BADGES_APPROVAL_VIEW";
export const BADGES_VERSION_HISTORY_TABLE = "BADGES_VERSION_HISTORY_TABLE";
export const BADGES_VERSION_HISTORY = "BADGES_VERSION_HISTORY";
export const BADGES_OPTIONS = "BADGES_OPTIONS";
export const BADGES_EXPORT = "BADGES_EXPORT";
export const BADGES_APPROVALS_EXPORT = "BADGES_APPROVALS_EXPORT";
export const BADGES_VERSION_EXPORT = "BADGES_VERSION_EXPORT";
export const BADGES_SUBMIT = "BADGES_SUBMIT";

/******DOCUMENT********/
export const CREATE_DOCUMENT = "CREATE_DOCUMENT";
export const APPROVE_DOCUMENT = "APPROVE_DOCUMENT";
export const REJECT_DOCUMENT = "REJECT_DOCUMENT";
export const VIEW_DOCUMENT = "VIEW_DOCUMENT";
export const DOCUMENT_TABLE = "DOCUMENT_TABLE";
export const EDIT_DOCUMENT = "EDIT_DOCUMENT";
export const DELETE_DOCUMENT = "DELETE_DOCUMENT";
export const DOCUMENT_APPROVAL_TABLE = "DOCUMENT_APPROVAL_LIST_TABLE";
export const DOCUMENT_APPROVAL_VIEW = "DOCUMENT_APPROVAL_VIEW";
export const DOCUMENT_VERSION_HISTORY_TABLE = "DOCUMENT_VERSION_HISTORY_TABLE";
export const DOCUMENT_VERSION_HISTORY = "DOCUMENT_VERSION_HISTORY";
export const DOCUMENT_OPTIONS = "DOCUMENT_OPTIONS";
export const DOCUMENT_EXPORT = "DOCUMENT_EXPORT";
export const DOCUMENT_APPROVALS_EXPORT = "DOCUMENT_APPROVALS_EXPORT";
export const DOCUMENT_VERSION_EXPORT = "DOCUMENT_VERSION_EXPORT";
export const DOCUMENT_SUBMIT = "DOCUMENT_SUBMIT";


/******REFERD USER********/
export const REFERED_USER_CREATE = "REFERED_USER_CREATE";
export const REFERED_USER_APPROVE = "REFERED_USER_APPROVE";
export const REFERED_USER_REJECT = "REFERED_USER_REJECT";
export const REFERED_USER_READ = "REFERED_USER_READ";
export const REFERED_USER_LIST = "REFERED_USER_LIST";
export const REFERED_USER_EDIT = "REFERED_USER_EDIT";
export const REFERED_USER_DELETE = "REFERED_USER_DELETE";
export const REFERED_USER_APPROVALS_LIST = "REFERED_USER_APPROVALS_LIST";
export const REFERED_USER_APPROVALS_VIEW = "REFERED_USER_APPROVALS_VIEW";
export const REFERED_USER_VERSION_LIST = "REFERED_USER_VERSION_LIST";
export const REFERED_USER_VERSION_VIEW = "REFERED_USER_VERSION_VIEW";
export const REFERED_USER_OPTIONS = "REFERED_USER_OPTIONS";
export const REFERED_USER_EXPORT = "REFERED_USER_EXPORT";
export const REFERED_USER_APPROVALS_EXPORT = "REFERED_USER_APPROVALS_EXPORT";
export const REFERED_USER_VERSION_EXPORT = "REFERED_USER_VERSION_EXPORT";
export const REFERED_USER_SUBMIT = "REFERED_USER_SUBMIT";
/******INVITED********/
export const CREATE_INVITED = "CREATE_INVITED";
export const APPROVE_INVITED = "APPROVE_INVITED";
export const REJECT_INVITED = "REJECT_INVITED";
export const VIEW_INVITED = "VIEW_INVITED";
export const INVITED_TABLE = "INVITED_TABLE";
export const EDIT_INVITED = "EDIT_INVITED";
export const DELETE_INVITED = "DELETE_INVITED";
export const INVITED_APPROVAL_TABLE = "INVITED_APPROVAL_LIST_TABLE";
export const INVITED_APPROVAL_VIEW = "INVITED_APPROVAL_VIEW";
export const INVITED_VERSION_HISTORY_TABLE = "INVITED_VERSION_HISTORY_TABLE";
export const INVITED_VERSION_HISTORY = "INVITED_VERSION_HISTORY";
export const INVITED_REFERRAL_OPPOR_ID = "INVITED_REFERRAL_OPPOR_ID";
export const INVITED_EXPORT = "INVITED_EXPORT";
export const INVITED_APPROVALS_EXPORT = "INVITED_APPROVALS_EXPORT";
export const INVITED_VERSION_EXPORT = "INVITED_VERSION_EXPORT";
export const INVITED_SUBMIT = "INVITED_SUBMIT";


/******NOTIFICATION********/
export const NOTIFICATION_LIST = "NOTIFICATION_LIST";
export const NOTIFICATION_READ = "NOTIFICATION_READ";
export const NOTIFICATION_CREATE = "NOTIFICATION_CREATE";
export const NOTIFICATION_EDIT = "NOTIFICATION_EDIT";
export const NOTIFICATION_DELETE = "NOTIFICATION_DELETE";
export const NOTIFICATION_APPROVE = "NOTIFICATION_APPROVE";
export const NOTIFICATION_REJECT = "NOTIFICATION_REJECT";
export const NOTIFICATION_APPROVALS_LIST = "NOTIFICATION_APPROVALS_LIST";
export const NOTIFICATION_APPROVALS_VIEW = "NOTIFICATION_APPROVALS_VIEW";
export const NOTIFICATION_VERSION_LIST = "NOTIFICATION_VERSION_LIST";
export const NOTIFICATION_VERSION_VIEW = "NOTIFICATION_VERSION_VIEW";
export const CREATE_NOTIFICATIONME_OPTIONS = "CREATE_NOTIFICATIONME_OPTIONS";
export const NOTIFICATION_EXPORT = "NOTIFICATION_EXPORT";
export const NOTIFICATION_APPROVALS_EXPORT = "NOTIFICATION_APPROVALS_EXPORT";
export const NOTIFICATION_VERSION_EXPORT = "NOTIFICATION_VERSION_EXPORT";
export const NOTIFICATION_SUBMIT = "NOTIFICATION_SUBMIT";


/******NOTIFICATION********/
export const SOCIAL_LIST = "SOCIAL_LIST";
export const SOCIAL_READ = "SOCIAL_READ";
export const SOCIAL_CREATE = "SOCIAL_CREATE";
export const SOCIAL_EDIT = "SOCIAL_EDIT";
export const SOCIAL_DELETE = "SOCIAL_DELETE";
export const SOCIAL_APPROVE = "SOCIAL_APPROVE";
export const SOCIAL_REJECT = "SOCIAL_REJECT";
export const SOCIAL_APPROVALS_LIST = "SOCIAL_APPROVALS_LIST";
export const SOCIAL_APPROVALS_VIEW = "SOCIAL_APPROVALS_VIEW";
export const SOCIAL_VERSION_LIST = "SOCIAL_VERSION_LIST";
export const SOCIAL_VERSION_VIEW = "SOCIAL_VERSION_VIEW";
export const CREATE_SOCIALME_OPTIONS = "CREATE_SOCIALME_OPTIONS";
export const SOCIAL_EXPORT = "SOCIAL_EXPORT";
export const SOCIAL_APPROVALS_EXPORT = "SOCIAL_APPROVALS_EXPORT";
export const SOCIAL_VERSION_EXPORT = "SOCIAL_VERSION_EXPORT";
export const SOCIAL_SUBMIT = "SOCIAL_SUBMIT";

/******COMMUNITY BADGES********/
export const COMMUNITY_BADGES_CREATE = "COMMUNITY_BADGES_CREATE";
export const COMMUNITY_BADGES_APPROVE = "COMMUNITY_BADGES_APPROVE";
export const COMMUNITY_BADGES_REJECT = "COMMUNITY_BADGES_REJECT";
export const COMMUNITY_BADGES_READ = "COMMUNITY_BADGES_READ";
export const COMMUNITY_BADGES_LIST = "COMMUNITY_BADGES_LIST";
export const COMMUNITY_BADGES_EDIT = "COMMUNITY_BADGES_EDIT";
export const COMMUNITY_BADGES_DELETE = "COMMUNITY_BADGES_DELETE";
export const COMMUNITY_BADGES_APPROVALS_LIST = "COMMUNITY_BADGES_APPROVALS_LIST";
export const COMMUNITY_BADGES_APPROVALS_VIEW = "COMMUNITY_BADGES_APPROVALS_VIEW";
export const COMMUNITY_BADGES_VERSION_LIST = "COMMUNITY_BADGES_VERSION_LIST";
export const COMMUNITY_BADGES_VERSION_VIEW = "COMMUNITY_BADGES_VERSION_VIEW";
export const COMMUNITY_BADGES_OPTIONS = "COMMUNITY_BADGES_OPTIONS";
export const COMMUNITY_BADGES_EXPORT = "COMMUNITY_BADGES_EXPORT";
export const COMMUNITY_BADGES_APPROVALS_EXPORT = "COMMUNITY_BADGES_APPROVALS_EXPORT";
export const COMMUNITY_BADGES_VERSION_EXPORT = "COMMUNITY_BADGES_VERSION_EXPORT";
export const COMMUNITY_BADGES_SUBMIT = "COMMUNITY_BADGES_SUBMIT";



/******MEMBERTRADE SELLOUT********/
export const MEMTRADE_SELL_LIST = "MEMTRADE_SELL_LIST";
export const MEMTRADE_SELL_READ = "MEMTRADE_SELL_READ";
export const MEMTRADE_SELL_CREATE = "MEMTRADE_SELL_CREATE";
export const MEMTRADE_SELL_EDIT = "MEMTRADE_SELL_EDIT";
export const MEMTRADE_SELL_DELETE = "MEMTRADE_SELL_DELETE";
export const MEMTRADE_SELL_APPROVE = "MEMTRADE_SELL_APPROVE";
export const MEMTRADE_SELL_REJECT = "MEMTRADE_SELL_REJECT";
export const MEMTRADE_SELL_APPROVALS_LIST = "MEMTRADE_SELL_APPROVALS_LIST";
export const MEMTRADE_SELL_APPROVALS_VIEW = "MEMTRADE_SELL_APPROVALS_VIEW";
export const MEMTRADE_SELL_VERSION_LIST = "MEMTRADE_SELL_VERSION_LIST";
export const MEMTRADE_SELL_VERSION_VIEW = "MEMTRADE_SELL_VERSION_VIEW";
export const MEMTRADE_SELL_FIND_ALL = "MEMTRADE_SELL_FIND_ALL";
export const VIEW_APPROVALS_MEMTRADE_SELL_ID = "VIEW_APPROVALS_MEMTRADE_SELL_ID";
export const MEMTRADE_SELL_VERSION_HISTORY_BY_ID = "MEMTRADE_SELL_VERSION_HISTORY_BY_ID";
export const MEMTRADE_SELL_EXPORT = "MEMTRADE_SELL_EXPORT";
export const MEMTRADE_SELL_APPROVALS_EXPORT = "MEMTRADE_SELL_APPROVALS_EXPORT";
export const MEMTRADE_SELL_VERSION_EXPORT = "MEMTRADE_SELL_VERSION_EXPORT";
export const MEMTRADE_SELL_SUBMIT = "MEMTRADE_SELL_SUBMIT";



