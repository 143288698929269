import React, { useState } from "react";
import ApprovalsBtn from "../../../../components/buttons/approvalsBtn";
import CreateBtn from "../../../../components/buttons/createBtn";
import ExportBtn from "../../../../components/buttons/exportBtn";
import FilterBtn from "../../../../components/buttons/filterBtn";
import VersionHistoryBtn from "../../../../components/buttons/versionHistoryBtn";
import AdminUserFilter from "../../../../components/filter/adminUserFilter";
import InnerLayout from "../../../../components/layouts/innerLayout/innerLayout";
import WishlistTableContent from "../../../../components/opportunity/wishlist/tables/wishlistTableContent";
import { useDispatch } from "react-redux"; 
import successToast from "../../../../components/utilits/successToast";
import errorToast from "../../../../components/utilits/errorToast";
import { wishListExport, wishlistTableExport } from "../../../../redux/actions/wishlistActions";
import FileSaver from "file-saver";
import { access } from "../../../../components/utilits/access";
import WishlistFilter from "../../../../components/filter/opportunity-pagesFilter.js/wishlistFilter";
import MemTradeSellTableContent from "../../../../components/opportunity/memTradeSellOut/tables/memTradeSellOutTableContent";
import { memTradeSellTableExport } from "../../../../redux/actions/memTradeSellActions";
import VersionTablesFilter from "../../../../components/filter/versionTableFilter";

const MemTradeSellTable = () => {
  const [page, setPage] = useState(0);
  const [showFilter, setShowFilter] = useState(false);
  const [search, setSearch] = useState({ value: "" });
  const dispatch = useDispatch();
  const handleFilterClose = () => {
    setShowFilter(false);
  };
  const handleFilterOpen = () => {
    setShowFilter(true);
  };
  const handleSearch = (value) => {
    setSearch({ value: value });
    setPage(0)
  };
  const handlePage = (type) => {
    if (type === "+") {
      setPage((prev) => prev + 1)
    }
    else {
      setPage((prev) => prev - 1)
    }
  }

  // const handleExport = () => {
  //   setLoading(true);
  //   dispatch(wishListExport(onSuccess, onError));
  // };
  // console.log(search);
  // const onSuccess = (data) => {
  //   setExportData(data.data);
  //   var type =
  //     "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8";
  //   var blob = new Blob([exportData], { type: type });
  //   FileSaver.saveAs(blob, "file.xlsx");
  //   setLoading(false);
  // };
  // const onError = (data) => {
  //   // errorToast(data.data.message)
  //   setExportData(data.data);
  //   var type =
  //     "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8";
  //   var blob = new Blob([exportData], { type: type });
  //   FileSaver.saveAs(blob, "file.xlsx");

  //   console.log("The log ", data);
  //   errorToast("Something went wrong");
  //   setLoading(false);
  // };
  const buttonList = [
    <ApprovalsBtn link="/opportunity/memTradeSell/approvals" />,
    <VersionHistoryBtn link="/opportunity/memTradeSell/version-history" />,
    <CreateBtn link="/opportunity/memTradeSell/create" />,
    <ExportBtn dispatchAction={memTradeSellTableExport}/>,
    <FilterBtn filterFn={handleFilterOpen} />,
  ];
  return (
    <InnerLayout
      buttons={buttonList}
    >
     <VersionTablesFilter
        title='MemberTrade Id'
        placeholder='Enter MemberTrade Id'
        filter={handleSearch}
        showFilter={showFilter}
        closeFilter={handleFilterClose}
      />
      <MemTradeSellTableContent page={page} changePage={handlePage} search={search} />
    </InnerLayout>
  );
};

export default MemTradeSellTable;
