import React, { useEffect, useState } from "react";
import { TableContainer } from "../../../table/tableContainer";
import TablePagination from "../../../table/tablePagination";
import { Link } from "react-router-dom";
import "../../../admin/tables/admintable.css";
import {
  tableContactUsVersionHeader,
  tableOpportuniyTypeVersionHeader,
  tableOpportuniyTypeVersionRow,
} from "../../../table/tableData";
import * as opporTypeActions from "../../../../redux/actions/opportunityTypeActions";
import { useDispatch } from "react-redux";
import errorToast from "../../../utilits/errorToast";
import LoadingSpinnerTable from "../../../utilits/loadingSpinnerTable";
import moment from "moment";
import { versionListContactAction } from "../../../../redux/actions/contactUsActions";
import LoadAndError from "../../../utilits/LoadAndError";

const ContactUsVersionHistoryTableContent = ({ page, search, changePage }) => {
  const dispatch = useDispatch();
  const [versionData, setVersionData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [pageData, setPageData] = useState({ noOfItems: 0, noOfPages: 1 });
  useEffect(() => {
    dispatch(
      versionListContactAction(
        {
          search: {
            contact_us_id: search?.value?.Id,
          },
        },
        page,
        onSuccess,
        onError
      )
    );
  }, [page, search]);
  const onSuccess = (data) => {
    setVersionData(data?.data?.data);
    setPageData({
      noOfItems: data.data.count,
      noOfPages: data.data.pages,
    });
    setLoading(false);
    setError(false);
  };
  const onError = (data) => {
    errorToast(data.message);
    setLoading(false);
    setError(true);
  };
  function handlePagination(type) {
    if (type === "+") {
      if (page + 1 < pageData.noOfPages) changePage("+");
    } else if (type === "-") if (page > 0) changePage("-");
  }
  return (
    <TableContainer width="90%">
      <div className="adminuser_table__container position-relative">
        {loading && <LoadingSpinnerTable />}
        <table className={"adminuser_table"}>
          <thead className="adminuser_table__header">
            <tr className="adminuser_table__row">
              {tableContactUsVersionHeader.map((item) => {
                return (
                  <th
                    key={item + "x"}
                    className={`${
                      item === "Status"
                        ? " position-sticky end-0 text-center"
                        : ""
                    } text-nowrap adminuser_table__heading`}
                  >
                    {item}
                  </th>
                );
              })}
            </tr>
          </thead>
          {loading === false && (
            <tbody className="adminuser_table__body">
              <LoadAndError
                loader={loading}
                error={error}
                status={versionData.length === 0}
              >
                {versionData.map((item, index) => {
                  return (
                    <tr key={item.id} className="adminuser_table__row">
                      <td className="text-nowrap dark_title_color adminuser_table__data ">
                        {page > 0 ? 12 * page + (index + 1) : index + 1}
                      </td>
                      <td className="text-nowrap dark_title_color adminuser_table__data ">
                        {item?.id}
                      </td>
                      <td className="text-nowrap dark_title_color adminuser_table__data ">
                        {item?.contactus_id == null ? "--" : item?.contactus_id}
                      </td>
                      <td className="text-nowrap secondary_color adminuser_table__data">
                        {item?.new_data === null
                          ? item?.old_data?.query_name
                          : item?.new_data?.query_name}
                      </td>
                      <td className="text-nowrap dark_title_color  adminuser_table__data">
                        {item?.updated_by == null ? "--" : item?.updated_by}
                      </td>
                      <td className="text-nowrap dark_title_color  adminuser_table__data">
                        {moment(item.updatedAt).format("DD-MM-YYYY HH:mm:ss")}
                      </td>
                      <td className="text-nowrap  dark_title_color adminuser_table__data">
                        {item?.approved_by}
                      </td>
                      <td className="text-nowrap  dark_title_color adminuser_table__data">
                        {moment(item?.approved_date).format(
                          "DD-MM-YYYY HH:mm:ss"
                        )}
                      </td>

                      <td className="text-nowrap  position-sticky end-0 adminuser_table__data">
                        <div className="d-flex w-100  justify-content-center gap-2">
                          <Link
                            className="text-decoration-none primary_color"
                            to={{
                              pathname: `/static-pages/contact-us-query/version-history/view/${item.id}`,
                            }}
                          >
                            View
                          </Link>
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </LoadAndError>
            </tbody>
          )}
        </table>
      </div>
      <TablePagination
        paginate={handlePagination}
        currentPage={page}
        pageData={pageData}
      />
    </TableContainer>
  );
};

export default ContactUsVersionHistoryTableContent;
