import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import errorToast from "../../../../components/utilits/errorToast";
import { useNavigate } from "react-router-dom";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import InnerContainer from "../../../../components/layouts/innerContainer/innerContainer";
import InnerLayout from "../../../../components/layouts/innerLayout/innerLayout";
import "../notify-me.css";
import SelectFields from "../../../../components/form/selectFields/selectFields";
import SaveBtn from "../../../../components/buttons/saveBtn";
import { notifyMeCreate, notifyMeCreateOptions } from "../../../../redux/actions/notifyMeActions";
import successToast from "../../../../components/utilits/successToast";
import { opportunitiesNameListAction } from "../../../../redux/actions/opportunitiesActions";
import { memberTradeCreate } from "../../../../redux/actions/memberTradeAction";
const MemberTradeCreate = () => {
    const [submitData, setSubmitData] = useState();
    const formRef = useRef()
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [userName, setUserName] = useState([]);
    const [opportunityType, setOpportunityType] = useState([]);
    useEffect(() => {
        dispatch(notifyMeCreateOptions(onNotifyMeSuccess, onNotifyMeError));
    }, []);
    const userNameOptions = [];
    const onNotifyMeSuccess = (data) => {
        data?.data.AllUsers.map((item, index) => {
            userNameOptions[index] = { value: item.id, label: item.user_name };
        });
        console.log(userNameOptions);
        setUserName(userNameOptions);
    };
    const onNotifyMeError = (data) => {
        console.log("ERROR");
    };
    const initialValues = {
        username: "",
        opportunity: "",
    };
    const loginSchema = Yup.object().shape({
        username: Yup.string().trim().required("Required"),
        opportunity: Yup.string().trim().required("Required"),
    });
    const handleSubmit = () => {
        if (formRef.current) {
            formRef.current.handleSubmit()
        }
    }
    const onSubmit = (values, { resetForm }) => {
        dispatch(
            memberTradeCreate(
                {
                    user_id: values.username,
                    opportunity_id: values.opportunity
                },
                onSuccess,
                onError
            )
        );
    };
    const onSuccess = (data) => {
        successToast(data.message, "jsdhfdgh")
        setSubmitData(data.id)
        navigate(`/opportunity/member-trade/edit/${data?.data?.id}`)
    };
    const onError = (data) => {
        errorToast(data.data);
    };

    //opp id
    const [opportunitiesList, setOpportunitiesList] = useState([])
    useEffect(() => {
        dispatch(opportunitiesNameListAction(onSuccessOpt, onErrorOpt));
    }, []);
    const onSuccessOpt = (data) => {
        const categoryList = [];
        data?.data.map((item, index) => {
            categoryList[index] = { value: item.id, label: item.title };
        });
        setOpportunitiesList(categoryList);
        console.log("SUCCESS");
    };
    const onErrorOpt = (data) => {
        console.log(data);
    };






    const buttonList = [
        <SaveBtn submitFn={handleSubmit}
        />,
    ];
    return (
        <InnerLayout buttons={buttonList}>
            <div className="notify-me-container">
                <InnerContainer>
                    <div className="  ps-4 ps-lg-4 ms-3">
                        <Formik
                            innerRef={formRef}
                            initialValues={initialValues}
                            onSubmit={onSubmit}
                            validationSchema={loginSchema}
                        >
                            <Form className='notify-me-form-width'>
                                <SelectFields
                                    label={"User Name"}
                                    placeholder={"Select User"}
                                    name={"username"}
                                    options={userName}
                                    id={"username"}
                                />
                                <SelectFields
                                    label={"Opportunity Name"}
                                    placeholder={"Select Opportunity"}
                                    name={"opportunity"}
                                    options={opportunitiesList}
                                    id={"opportunity"}
                                />
                            </Form>
                        </Formik>
                    </div>
                </InnerContainer>
            </div>
        </InnerLayout>
    );
};

export default MemberTradeCreate;

// export const username = [
//     { value: "mohan", label: "mohan" },
//     { value: "Abijith", label: "Abijith" },
// ];
// export const opportunityType = [
//     { value: "share_hold", label: "share_hold" },
//     { value: "type2", label: "type2" },
// ];

