import { authHeader } from "../config";
import { api } from "./api";

export const createFaqApi = (params) => {
    return api.post("/api/homestatic/create-faq-static", params, {
        headers: {
            "x-access-token": localStorage.getItem("accessToken"),
            "Content-Type": "application/json",
        },
    });
};

export const approveFaqApi = (params) => {
    return api.post("/api/homestatic/approve-faq-static", JSON.stringify(params), {
        headers: {
            "x-access-token": localStorage.getItem("accessToken"),
            "Content-Type": "application/json",
        },
    });
};

export const rejectFaqApi = (id, params) => {
    return api.post(
        `/api/homestatic/reject-faq-static/${id}`,
        JSON.stringify(params),
        {
            headers: {
                "x-access-token": localStorage.getItem("accessToken"),
                "Content-Type": "application/json",
            },
        }
    );
};

export const viewFaqDetailApi = (id, params) => {
    return api.post(
        `/api/homestatic/view-faq-static/${id}`,
        JSON.stringify(params),
        {
            headers: {
                "x-access-token": localStorage.getItem("accessToken"),
                "Content-Type": "application/json",
            },
        }
    );
};



export const viewFaqListApi = (pageNo = 0, params) => {
    return api.post(
        `/api/homestatic/views-faq-statics?page_no=${pageNo}`,
        params,
        {
            headers: {
                "x-access-token": localStorage.getItem("accessToken"),
                "Content-Type": "application/json",
            },
        }
    );
};

export const editFaqApi = (id, params) => {
    return api.post(
        `/api/homestatic/edit-faq-static/${id}`,
        JSON.stringify(params),
        {
            headers: {
                "x-access-token": localStorage.getItem("accessToken"),
                "Content-Type": "application/json",
            },
        }
    );
};

export const deleteFaqApi = (id, params) => {
    return api.post(
        `/api/homestatic/delete-faq-static/${id}`,
        JSON.stringify(params),
        {
            headers: {
                "x-access-token": localStorage.getItem("accessToken"),
                "Content-Type": "application/json",
            },
        }
    );
};


export const faqApprovalListApi = (pageNo, params) => {
    return api.post(
        `/api/homestatic/views-faq-statics-checkkers?page_no=${pageNo}`,
        JSON.stringify(params),
        {
            headers: {
                "x-access-token": localStorage.getItem("accessToken"),
                "Content-Type": "application/json",
            },
        }
    );
};



export const faqApprovalByIdApi = (id, params) => {
    return api.post(
        `/api/homestatic/view-faq-static-checkker/${id}`,
        JSON.stringify(params),
        {
            headers: {
                "x-access-token": localStorage.getItem("accessToken"),
                "Content-Type": "application/json",
            },
        }
    );
};

export const versionHistoryFaqListApi = (pageNo, params) => {
    return api.post(
        `/api/homestatic/views-faq-statics-versions?page_no=${pageNo}`,
        JSON.stringify(params),
        {
            headers: {
                "x-access-token": localStorage.getItem("accessToken"),
                "Content-Type": "application/json",
            },
        }
    );
};

export const versionHistoryFaqDetailApi = (id, params) => {
    return api.post(
        `/api/homestatic/view-faq-static-version/${id}`,
        JSON.stringify(params),
        {
            headers: {
                "x-access-token": localStorage.getItem("accessToken"),
                "Content-Type": "application/json",
            },
        }
    );
};
export const faqOptionsApi = (params) => {
    return api.post(
        `/api/homestatic/views-all-faq-category-Names`,
        JSON.stringify(params),
        {
            headers: {
                "x-access-token": localStorage.getItem("accessToken"),
                "Content-Type": "application/json",
            },
        }
    );
};

export const faqTableExportApi = ( params) => {
    return api.post(
        `/api/homestatic/export-main-faq-statics`,
        JSON.stringify(params),
        {
            headers: {
                "x-access-token": localStorage.getItem("accessToken"),
                "Content-Type": "application/json",
            },
        }
    );
};
export const faqApprovalsExportApi = ( params) => {
    return api.post(
        `/api/homestatic/export-approval-faq-statics`,
        JSON.stringify(params),
        {
            headers: {
                "x-access-token": localStorage.getItem("accessToken"),
                "Content-Type": "application/json",
            },
        }                   
    );
};
export const faqVersionExportApi = ( params) => {
    return api.post(
        `/api/homestatic/export-version-faq-statics`,
        JSON.stringify(params),
        {
            headers: {
                "x-access-token": localStorage.getItem("accessToken"),
                "Content-Type": "application/json",
            },
        }
    );
};

export const faqSubmitApi = (id, params) => {
    return api.post(`/api/homestatic/on-submits/${id}`, params, {
        headers: {
            "x-access-token": localStorage.getItem("accessToken"),
            "Content-Type": "application/json",
        },
    });
};
