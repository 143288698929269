import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import EditIcon from "../../assets/icons/editIcon";
import { AdminButton } from "./versionHistoryBtn";
import usePermissionChecker from "../utilits/usePermissionChecker";

const EditBtn = ({ link = "/" }) => {
  const access = usePermissionChecker("maker");
  const navigate = useNavigate();
  return (
    access &&
    <AdminButton
      onClick={() => navigate(link)}
      color="#112F6A"
      padding="3px 15px"
    >
      <EditIcon fill="#112F6A" />
      <span
        className="ms-1 pdt_1">EDIT</span>
    </AdminButton>
  );
};

export default EditBtn;
