import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import errorToast from "../../../../components/utilits/errorToast";
import { useNavigate } from "react-router-dom";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import InnerContainer from "../../../../components/layouts/innerContainer/innerContainer";
import InnerLayout from "../../../../components/layouts/innerLayout/innerLayout";
import "../notify-me.css";
import SelectFields from "../../../../components/form/selectFields/selectFields";
import SaveBtn from "../../../../components/buttons/saveBtn";
import { notifyMeCreate, notifyMeCreateOptions, notifySubmitAction } from "../../../../redux/actions/notifyMeActions";
import successToast from "../../../../components/utilits/successToast";
import { opportunitiesNameListAction } from "../../../../redux/actions/opportunitiesActions";
import SubmitBtn from "../../../../components/buttons/submit";
import AdminRoleCheckBox from "../../../../components/utilits/adminRoleCheckbox";
import ErrorMsg from "../../../../components/form/errorMsg";
import InputField from "../../../../components/form/inputFields/inputField";
import { notificationCreate } from "../../../../redux/actions/notificationActions";
import { socialCreate } from "../../../../redux/actions/socialActions";
const SocialCreate = () => {
    const formRef = useRef()
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [userName, setUserName] = useState([]);
    const [opportunityType, setOpportunityType] = useState([]);
    const [subLoading, setSubLoading] = useState(false);
    const [adminId, setAdminId] = useState("");
    const [saved, setSaved] = useState(false)
    useEffect(() => {
        dispatch(notifyMeCreateOptions(onNotifyMeSuccess, onNotifyMeError));
    }, []);
    const userNameOptions = [];
    const onNotifyMeSuccess = (data) => {
        data?.data.AllUsers.map((item, index) => {
            userNameOptions[index] = { value: item.id, label: item.user_name };
        });
        console.log(userNameOptions);
        setUserName(userNameOptions);
    };
    const onNotifyMeError = (data) => {
        console.log("ERROR");
    };
    const initialValues = {
        userId: "",
        testimonial: false,
        redirectionUrl: ""
    };
    const loginSchema = Yup.object().shape({
        userId: Yup.string().trim().required("Required"),
        redirectionUrl: Yup.string().trim().required("Required"),
    });
    const handleSubmit = () => {
        if (formRef.current) {
            formRef.current.handleSubmit()
        }
    }
    const onSubmit = (values, { resetForm }) => {
        console.log(values);
        dispatch(
            socialCreate(
                {
                    "user_id": values.userId,
                    "is_testimonial": values.testimonial,
                    "link_url": values.redirectionUrl
                },
                onSuccess,
                onError
            )
        );
    };
    const onSuccess = (data) => {
        successToast(data.message)
        setAdminId(data.data.id)
        navigate(`/static-pages/social`)
        setSaved(true)
    };
    const onError = (data) => {
        errorToast(data.data);
    };

    //opp id
    const [opportunitiesList, setOpportunitiesList] = useState([])
    useEffect(() => {
        dispatch(opportunitiesNameListAction(onSuccessOpt, onErrorOpt));
    }, []);
    const onSuccessOpt = (data) => {
        const categoryList = [];
        data?.data.map((item, index) => {
            categoryList[index] = { value: item.id, label: `${item.title} id: ${item.id}` };
        });
        setOpportunitiesList(categoryList);
        console.log("SUCCESS");
    };
    const onErrorOpt = (data) => {
        console.log(data);
    };
    const notifySubmit = () => {
        setSubLoading(true);
        dispatch(
            notifySubmitAction(adminId, onAdminSubmitSucess, onAdminSubmitError)
        );
    };
    const buttonList = [
        <SaveBtn submitFn={handleSubmit} />,
    ];
    function onAdminSubmitSucess(data) {
        successToast(data.message);
        setSubLoading(false);

    }
    function onAdminSubmitError(data) {
        setSubLoading(false);
        errorToast(data.message);
    }
    return (
        <InnerLayout buttons={buttonList}>
            <div className="notify-me-container">
                <InnerContainer>
                    <div className="  ps-2 ps-lg-4 ms-3">
                        <Formik
                            innerRef={formRef}
                            initialValues={initialValues}
                            onSubmit={onSubmit}
                            validationSchema={loginSchema}
                        >
                            <Form className='notify-me-form-width'>
                                <SelectFields
                                    label={"User Name"}
                                    placeholder={"Select User"}
                                    name={"userId"}
                                    options={userName}
                                    id={"userId"}
                                />
                                <InputField
                                    id={"redirectionUrl"}
                                    label={"Redirection Url"}
                                    type={"text"}
                                    placeholder={"Enter Redirection Url"}
                                    name={"redirectionUrl"}
                                />
                                <div>
                                    <label htmlFor="testimonial" className='mb-2 dark_grey_color fn_Montserrat fw_500 fs_14'>Is Testimonial</label>
                                    <AdminRoleCheckBox name="testimonial" id="testimonial" />
                                </div>

                            </Form>
                        </Formik>
                    </div>
                </InnerContainer>
            </div>
        </InnerLayout>
    );
};

export default SocialCreate;



