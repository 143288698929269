import React from 'react'
import RejectIcon from '../../assets/icons/rejectIcon'
import usePermissionChecker from '../utilits/usePermissionChecker';
import { AdminButton } from './versionHistoryBtn'

const ResetPswrdBtn = ({ showModal, loader }) => {
    const access = usePermissionChecker("checker");
    return (
        access &&
        <AdminButton disabled={loader} onClick={showModal} color="#112F6A" padding="3px 15px">
            <span className='pdt_1'>Reset Password</span>
        </AdminButton>
    )
}

export default ResetPswrdBtn