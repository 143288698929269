import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useParams } from "react-router-dom";
import moment from "moment";
import * as ourTeamAction from "../../../../redux/actions/ourTeamAction";
import "./ourTeam.css";
import { TableContainer } from "../../../table/tableContainer";
import {

  ourteamHeader,

} from "../../../table/tableData";
import TablePagination from "../../../table/tablePagination";
import LoadingSpinnerTable from "../../../utilits/loadingSpinnerTable";
import errorToast from "../../../utilits/errorToast";
import successToast from "../../../utilits/successToast";
import DeleteModal from "../../../modals/deleteModal";
import usePermissionChecker from "../../../utilits/usePermissionChecker";

const OurTeamTableContent = ({ page, search, changePage }) => {
  const dispatch = useDispatch();
  const access = usePermissionChecker("maker");
  const params = useParams();
  const [ourTeamListData, setOutTeamUserListData] = useState([]);
  const [loadingTable, setLoadingTable] = useState(false);
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [deleteData, setDeleteData] = useState({ roleName: "", id: "" });
  const [pageData, setPageData] = useState({ noOfItems: 0, noOfPages: 1 })
  const handleDelete = () => {
    setLoadingDelete(true);
    dispatch(
      ourTeamAction.deleteOurTeam(deleteData.id, onDeleteSuccess, onDeleteError)
    );
  };
  const onDeleteSuccess = (data) => {
    successToast(data.message);
    setLoadingDelete(false);
    setShow(false);
  };
  const onDeleteError = (data) => {
    errorToast(data.message);
    setLoadingDelete(false);
  };
  useEffect(() => {
    setLoading(true);
    dispatch(ourTeamAction.viewOurTeamList(
      {
        search: {
          team_member_id: search?.value?.memberId,
          name: search?.value?.name,
          designation: search?.value?.designation,
          priority: search?.value?.priority,
        }
      },
      page,
      onSuccess,
      onError));
  }, [page, search, loadingDelete]);

  const onSuccess = (data) => {
    setOutTeamUserListData(data?.data?.data);
    setLoading(false);
    setPageData({
      noOfItems: data.data.count,
      noOfPages: data.data.pages
    })
  };

  const onError = (data) => {
    errorToast(data.message, "jkdsfujdbjid");
    setLoading(false);
  };
  function handlePagination(type) {
    if (type === "+") {
      if (page + 1 < pageData.noOfPages) changePage("+");
    }
    else if (type === "-")
      if (page > 0) changePage("-");
  }
  return (
    <TableContainer maxWidth="100%" width="90%">
      <div className="adminuser_table__container position-relative">
        {loading && <LoadingSpinnerTable />}
        <table className={"adminuser_table"}>
          <thead className="adminuser_table__header">
            <tr className="adminuser_table__row">
              {ourteamHeader.map((item) => {
                return (
                  <th
                    key={item}
                    className={`text-nowrap adminuser_table__heading ${item === "Status" ? `${access ? "" : "text-start"} position-sticky end-0 text-start` : null
                      } `}
                  >
                    {item}
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody className="adminuser_table__body">
            {ourTeamListData.map((item, index) => {
              return (
                <tr key={index} className="adminuser_table__row">
                  <td className="text-nowrap dark_title_color adminuser_table__data ">
                    {page > 0 ? 12 * page + (index + 1) : index + 1}
                  </td>
                  <td className="text-nowrap secondary_color adminuser_table__data">
                    {item.id}
                  </td>
                  <td className="text-nowrap secondary_color adminuser_table__data">
                    {item.name}
                  </td>
                  <td className="text-nowrap secondary_color adminuser_table__data">
                    {item.designation}
                  </td>
                  <td className="text-nowrap dark_title_color adminuser_table__data">
                    {item.linkedin_url}
                  </td>
                  <td title={item.description} className="text-nowrap adminuser_table_ellipsis dark_title_color adminuser_table__data">
                    {item.description}
                  </td>
                  <td className="text-nowrap dark_title_color adminuser_table__data">
                    {item.priority}
                  </td>
                  <td className="text-nowrap dark_title_color adminuser_table__data">
                    {item.our_team_checkkers?.[0]?.approved_date === null
                      ? "-"
                      : moment(item.our_team_checkkers?.[0]?.approved_date).format(
                        "DD/MM/YYYY HH:mm:ss"
                      )}
                  </td>
                  <td className="text-nowrap dark_title_color adminuser_table__data">
                    {item.our_team_checkkers?.[0]?.updatedAt === null
                      ? "-"
                      : moment(item.our_team_checkkers?.[0]?.updatedAt).format(
                        "DD/MM/YYYY HH:mm:ss"
                      )}
                  </td>
                  <td className="text-nowrap  adminuser_table__data position-sticky end-0">
                    <div className="d-flex justify-content-start w-100 gap-2">
                      <Link
                        className="text-decoration-none primary_color"
                        to={{
                          pathname: `/static-pages/our-team/view/${item?.id}`,
                        }}
                      >
                        View
                      </Link>
                      {
                        access && <>
                          {
                            item.editable && <>
                              <Link
                                className="text-decoration-none secondary_color"
                                to={{
                                  pathname: `/static-pages/our-team/edit/${item?.id}`,
                                }}
                              >
                                Edit
                              </Link>
                              {item.lastActionApproved && (
                                    <>
                              <button
                                onClick={() => {
                                  setDeleteData({
                                    name: item.name,
                                    id: item.id,
                                  });
                                  handleShow();
                                }}
                                className="text-decoration-none primary_color light_red_color border-0 bg-transparent "
                              >
                                Delete
                              </button>
                            </>)}
                            </>
                          }
                        </>
                      }
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <DeleteModal
        loader={loadingDelete}
        handleClose={handleClose}
        deleteFn={handleDelete}
        data={deleteData}
        show={show}
      />
      <TablePagination
        paginate={handlePagination}
        currentPage={page}
        pageData={pageData}
      />
    </TableContainer>
  );
};

export default OurTeamTableContent;
