import React, { useEffect, useRef, useState } from "react";
import "../our-advisors.css";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import SaveBtn from "../../../../components/buttons/saveBtn";
import InnerContainer from "../../../../components/layouts/innerContainer/innerContainer";
import InnerLayout from "../../../../components/layouts/innerLayout/innerLayout";
import DeleteBtn from "../../../../components/buttons/deleteBtn";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { viewAdvisorAction } from "../../../../redux/actions/ourAdvisorAction";
import errorToast from "../../../../components/utilits/errorToast";
import DownloadIcon from "../../../../assets/icons/downloadIcon";
import moment from "moment";
import VersionHistoryBtn from "../../../../components/buttons/versionHistoryBtn";
import EditBtn from "../../../../components/buttons/editBtn";
import LoadingSpinnerTable from "../../../../components/utilits/loadingSpinnerTable";
import LoadAndError from "../../../../components/utilits/LoadAndError";
import OurTeamProfileImage from "../../../../components/static-pages/our-team/modals/approvalProfileImage";

const OurAdvisorsRead = () => {
  const [file, setFile] = useState(null);
  const handleBackImageShow = () => setImageShow(true);
  const handleImageClose = () => setImageShow(false);
  const [imageShow, setImageShow] = useState(false);
  const [image, setImage] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  // viewAdvisorAction
  const [profile, setProfile] = useState(false);
  const [logo, setLogo] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();

  const [advisorRead, setAdvisorRead] = useState();

  useEffect(() => {
    setLoading(true);
    dispatch(viewAdvisorAction(params?.id, onSuccess, onError));
    // setImage(
    //   individualData?.new_data?.profile_picture_location
    //     .split("/")
    //     .pop()
    // );
  }, []);

  const onSuccess = (data) => {
    setAdvisorRead(data.data);
    setImage(advisorRead?.photo_url?.split("/").pop());
    setLoading(false);
    setError(false);
  };

  const onError = (data) => {
    console.log(data);
    errorToast(data.message);
    setLoading(false);
    setError(true);
  };

  const advisorViewData = [
    {
      heading: "Advisors ID",
      title: advisorRead?.id,
    },
    {
      heading: "Name",
      title: advisorRead?.name,
    },
    {
      heading: "Designation",
      title: advisorRead?.designation,
    },

    {
      heading: "Website URL",
      title: advisorRead?.website_url,
    },
    {
      heading: "Description",
      title: advisorRead?.description,
    },

    {
      heading: "Prirority",
      title: advisorRead?.priority,
    },
    {
      heading: "Approved At",
      title: moment(advisorRead?.createdAt).format("DD-MM-YYYY HH:mm:ss"),
    },
    {
      heading: "Updated At",
      title: moment(advisorRead?.updatedAt).format("DD-MM-YYYY HH:mm:ss"),
    },
  ];

  const buttonList = [
    <VersionHistoryBtn link="/static-pages/our-advisors/version-history" />,
    advisorRead?.editable && <>
      <EditBtn link={`/static-pages/our-advisors/edit/${advisorRead?.id}`} />,
    </>
  ];
  return (
    <InnerLayout buttons={buttonList}>
      <div className="our-advisors-container">
        <InnerContainer>
          {loading ? (
            <LoadingSpinnerTable />
          ) : (
            <LoadAndError loader={loading} error={error}>
              <div className="row mt-4">
                <div className="col-12 col-sm-12 col-md-6  col-xl-6">
                  <div className="row ps-1 ms-1 ps-lg-4 ms-lg-1">
                    {advisorViewData.map((item, index) => (
                      <div className="mb-2 ">
                        <h5 className="dark_grey_color text-nowrap fs_13 fn_Montserrat fw_500">
                          {item.heading}
                        </h5>
                        <p
                          className={` fs_13 fn_Nunito  fw_500 "dark_title_color"
                `}
                        >
                          {item.title}
                        </p>
                      </div>
                    ))}
                  </div>
                </div>
                <div className="col-12 col-md-6">
                  <div className="ps-0 ps-lg-4">
                    <div className="ps-4">
                      <h5 className="dark_grey_color text-nowrap fs_13 fn_Montserrat fw_500">
                        Profile Picture
                      </h5>
                      <div className="personal_information_image_container mt-2 position-relative">
                        <img
                          onClick={() => {
                            handleBackImageShow()
                            setLogo(false)
                            setProfile(true)
                          }}
                          src={advisorRead?.photo_url}
                          className="personal_information_img_size rounded-2"
                        />
                        <a href={DownloadIcon} download="file">
                          <button
                            type="button"
                            className="position-absolute border-0 bg-white d-flex align-items-center fs_10 top-0 end-0"
                          >
                            <DownloadIcon width={8} />
                            <span className="ms-1">Download</span>
                          </button>
                        </a>
                      </div>
                    </div>
                    <div className="ps-4 mt-4">
                      <h5 className="dark_grey_color text-nowrap fs_13 fn_Montserrat fw_500">
                        Website Logo
                      </h5>

                      <div className="personal_information_image_container mt-2 position-relative">
                        <img
                          onClick={() => {
                            handleBackImageShow()
                            setLogo(true)
                            setProfile(false)
                          }}
                          src={advisorRead?.website_icon}
                          className="personal_information_img_size rounded-2"
                        />
                        <a href={DownloadIcon} download="file">
                          <button
                            type="button"
                            className="position-absolute border-0 bg-white d-flex align-items-center fs_10 top-0 end-0"
                          >
                            <DownloadIcon width={8} />
                            <span className="ms-1">Download</span>
                          </button>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </LoadAndError>
          )}
        </InnerContainer>
      </div>
      {
        logo === true && (
          <OurTeamProfileImage
            handleClose={handleImageClose}
            show={imageShow}
            Profileimage={advisorRead?.website_icon}
            modalHeading={"LOGO"}
          />
        )
      }
      {
        profile === true && (
          <OurTeamProfileImage
            handleClose={handleImageClose}
            show={imageShow}
            Profileimage={advisorRead?.photo_url}
            modalHeading={"PROFILE PICTURE"}
          />
        )
      }
    </InnerLayout>
  );
};

export default OurAdvisorsRead;
