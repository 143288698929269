import React, { useRef } from "react";
import Modal from "react-bootstrap/Modal";
import CloseIcon from "../../../assets/icons/closeIcon";
import "./modals.css";
import { Document, Page, pdfjs } from "react-pdf";
import { useState } from "react";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;
const ViewImageModal = ({
  show,
  handleClose,
  image,
  popuHeading = "PROFILE IMAGE",
  Profileimage
}) => {
  //  console.log( Profileimage);
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    setPageNumber(1);
  }
  console.log(Profileimage);
  return (
    <Modal
      centered
      show={show}
      onHide={handleClose}
      dialogClassName={`image_modals_body mx-2 px-1 mx-sm-auto`}
      contentClassName={"delete_modals_body_content  p-1"}
    >
      <Modal.Body>
        <>
          <div className="d-flex justify-content-between  ">
            <h2 className="black_color_popup fs_15 fs_sm_13 fn_Montserrat fw_600 pt-2">
              {popuHeading}
            </h2>
            <button
              onClick={handleClose}
              className="light_grey_color  fs_15 fs_sm_13 fn_Montserrat bg-transparent border-0 fw_600 "
            >
              <span>DISMISS</span>
              <span className="ms-2">
                <CloseIcon width={13} height={13} fill="#A5A5A5" />
              </span>
            </button>
          </div>
          <div className="image_view_popup_size ">{
            Profileimage?.endsWith(".pdf") ?
              <div className={`w-100 h-100 `} style={{
                // width: "40em",
                // height: "40em",
                overflowY: 'scroll',
                overflowX: 'scroll'
              }}>
                <Document file={Profileimage} onLoadSuccess={onDocumentLoadSuccess}>
                  {Array.from({ length: numPages }, (_, index) => (
                    <Page
                      key={`page_${index + 1}`}
                      pageNumber={index + 1}
                      renderAnnotationLayer={false}
                      renderTextLayer={false}
                    />
                  ))}
                </Document></div>

              : <img
                src={Profileimage}
                className={`h-100 w-100 rounded-3`}
              />}

          </div>
        </>
      </Modal.Body>
    </Modal>
  );
};
export default ViewImageModal;
