import React, { useEffect, useState } from "react";
import "./opportunitiesEditContent.css";

const OpportunitiesContentEditTableTypeOne = ({
  item,
  index,
  deleteTheRow,
  onRowUpdate,
}) => {
  const [caption, setCaption] = useState(false);
  const [caption2, setCaption2] = useState(false);

  const [col1_title, setcol1_title] = useState("");
  const [col2_title, setcol2_title] = useState("");
  const [col1_caption, setcol1_caption] = useState("");
  const [col2_caption, setcol2_caption] = useState("");
  useEffect(() => {
    setcol1_title(item.col1_title);
    setcol2_title(item.col2_title);
    setcol1_caption(item.col1_caption);
    setcol2_caption(item.col2_caption);

    if (item.col1_caption != "" && item.col1_caption != null) {
      setCaption(true);
    }
    if (item.col2_caption != "" && item.col2_caption != null) {
      setCaption2(true);
    }
  }, []);

  const onChangeCol1 = (event) => {
    setcol1_title(event.target.value);
    onRowUpdate(event.target.value, index, "col1_title");
  };
  const onChangeCol2 = (event) => {
    setcol2_title(event.target.value);
    onRowUpdate(event.target.value, index, "col2_title");
  };
  const onChangeCol1Caption = (event) => {
    setcol1_caption(event.target.value);
    onRowUpdate(event.target.value, index, "col1_caption");
  };
  const onChangeCol2Caption = (event) => {
    setcol2_caption(event.target.value);
    onRowUpdate(event.target.value, index, "col2_caption");
  };

  return (
    <div className="d-flex flex-column flex-xl-row align-items-start w-100">
      <div
        className={`d-flex  flex-xl-column align-items-center mb-2 mb-xl-0 ${index !== 0 ? "mt-2" : ""
          }`}
      >
        <h6 className={`mb-lg-0 mb-xl-2 ${index === 0 ? "" : "invisible"}`}>
          Row
        </h6>
        <span className={``}>#{index + 1}</span>
      </div>
      <div className="d-flex flex-column flex-md-row w-100 ">
        <div
          className={`vr d-none  d-xl-block mx-4 ${index === 0 ? "mt-4" : ""} `}
        ></div>
        <div
          className={`ms-0 ms-lg-2 ms-xl-0 col  ${index !== 0 ? "mt-4" : ""}`}
        >
          {index === 0 && <h6>Column #1</h6>}
          <div
            className={`d-flex align-items-center  ${caption ? "col-10" : "w-100"
              }`}
          >
            <input
              value={col1_title}
              onChange={onChangeCol1}
              type="text"
              name="col1_title"
              className="oppor_content_edit__border w-100 rounded-2 py-2 focus_outline__none"
              id=""
            />{" "}
            {!caption ? (
              <button type="button"
                onClick={() => setCaption(!caption)}
                className="d-flex align-items-center  py-1 ms-3 rounded-3  flex-column bg-white oppor_content_edit__border "
              >
                <span className="lh-sm fs_12 dark_grey_color">+</span>
                <span className="lh-sm fs_12 fw_500 dark_grey_color">
                  Caption
                </span>
              </button>
            ) : null}
          </div>

          {caption && (
            <div
              className={`mt-4  ${caption ? "col-10" : "w-100"
                } position-relative`}
            >
              <button type="button"
                onClick={() => {
                  setcol2_caption("");
                  onRowUpdate("", index, "col2_caption");
                  setCaption(!caption);
                }}
                className="border-0 fw_500 fs_13 bg-transparent me-2 light_red_color oppor_content_edit_subsec_btn__delete  position-absolute end-0"
              >
                Delete
              </button>
              <input
                value={col1_caption}
                onChange={onChangeCol1Caption}
                type="text"
                name="col1_caption"
                className="oppor_content_edit__border rounded-2 py-2 w-100 focus_outline__none"
              />
            </div>
          )}
        </div>
        <div
          className={`vr mx-3  d-none d-md-block ${index === 0 ? "mt-4" : ""}`}
        ></div>
        <div
          className={`ms-0 ms-lg-2 ms-xl-0 col ${index !== 0 ? "mt-4" : ""}`}
        >
          {" "}
          {index === 0 && <h6>Column #2</h6>}
          <div
            className={`d-flex align-items-center ${caption2 ? "col-10" : "w-100"
              }`}
          >
            <input
              value={col2_title}
              onChange={onChangeCol2}
              type="text"
              name="col2_title"
              className="oppor_content_edit__border w-100 rounded-2 py-2 focus_outline__none"
              id=""
            />{" "}
            {!caption2 ? (
              <button type="button"
                onClick={() => setCaption2(!caption2)}
                className="d-flex align-items-center py-1 ms-3 rounded-3  flex-column bg-white oppor_content_edit__border "
              >
                <span className="lh-sm fs_12 dark_grey_color">+</span>
                <span className="lh-sm fs_12 fw_500 dark_grey_color">
                  Caption
                </span>
              </button>
            ) : null}
          </div>
          {caption2 && (
            <div
              className={`mt-4 position-relative ${caption2 ? "col-10" : "w-100"
                }`}
            >
              <button type="button"
                onClick={() => {
                  setcol2_caption("");
                  onRowUpdate("", index, "col2_caption");
                  setCaption2(!caption2);
                }}
                className="border-0 fw_500 fs_13 bg-transparent me-2 light_red_color oppor_content_edit_subsec_btn__delete  position-absolute end-0"
              >
                Delete
              </button>
              <input
                value={col2_caption}
                onChange={onChangeCol2Caption}
                type="text"
                name="col2_titcol2_captionle"
                className="oppor_content_edit__border rounded-2 py-2 w-100 focus_outline__none"
              />
            </div>
          )}
        </div>

        <button type="button"
          onClick={() => deleteTheRow(index)}
          className={`border-0 bg-white dark_grey_color ms-2 align-self-center mt-2 `}
        >
          x
        </button>
      </div>
    </div>
  );
};

export default OpportunitiesContentEditTableTypeOne;
