import { Action } from "@remix-run/router";
import {
  FETCH_OPPORTUNITES_TYPE,
  OPPORTUNITES_CREATE,
  FETCH_OPPORTUNITES_LIST,
  APPROVE_OPPORTUNITY,
  DELETE_OPPORTUNITY,
  EDIT_OPPORTUNITY,
  FETCH_OPPORTUNITY_DETAILS,
  FETCH_OPPORTUNITES_APPROVAL_LIST,
  OPPORTUNITES_VERSION_HISTORY_TABLE,
  OPPORTUNITES_ANALYTICS,
  OPPORTUNITES_VERSION_VIEW,
  OPPORTUNITES_NAME_LIST,
  OPPORTUNITES_APPROVALS_VIEW,
  REJECT_OPPORTUNITY,
  OPPORTUNITIES_EXPORT,
  OPPORTUNITIES_APPROVALS_EXPORT,
  OPPORTUNITIES_VERSION_EXPORT,
  OPPORTUNITIES_SUBMIT,
  PUBLISH_OPPORTUNITY,
} from "../actions/types";
import * as opportunitiesApi from "../apis/opportunitiesApis";
import { call, takeLatest } from "redux-saga/effects";

export function* createOpportunitiesWorker(action) {
  try {
    const res = yield call(opportunitiesApi.opportunitiesCreateApi, action.data);

    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res.data);
      console.log(res.data);
    } else if (res.status === 200 && res.data.status === "fail") {
      console.log(res.data);
      yield action.onError(res);
    } else {
      yield action.onError("Something Went Wrong");
    }
  } catch (err) {
    console.log(err);
    yield action.onError("Something Went Wrong");
  }
}

export function* fetchOpportunitiesTypeWorker(action) {
  try {
    const res = yield call(opportunitiesApi.fetchOpportunitiesTypeApi, {});

    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res.data);
      console.log(res.data);
    } else if (res.status === 200 && res.data.status === "fail") {
      console.log(res.data);
      yield action.onError(res);
    } else {
      yield action.onError("Something Went Wrong");
    }
  } catch (err) {
    console.log(err);
    yield action.onError("Something Went Wrong");
  }
}

export function* fetchOpportunitiesWorker(action) {
  try {
    const res = yield call(opportunitiesApi.fetchOpportunitiesApi,
      action.page_no,
      action.data,
    );
    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res.data);
    } else if (res.status === 200 && res.data.status === "fail") {
      yield action.onError(res);
    } else {
      yield action.onError("Something Went Wrong");
    }
  } catch (err) {
    yield action.onError("Something Went Wrong");
  }
}

export function* fetchOpportunitiesApprovalWorker(action) {
  try {
    const res = yield call(opportunitiesApi.fetchOpportunitiesApprovalApi,
      action.page_no,
      action.data,
    );

    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res.data);
      console.log(res.data);
    } else if (res.status === 200 && res.data.status === "fail") {
      console.log(res.data);
      yield action.onError(res);
    } else {
      yield action.onError("Something Went Wrong");
    }
  } catch (err) {
    console.log(err);
    yield action.onError("Something Went Wrong");
  }
}
export function* publishOpportunityWorker(action) {
  try {
    const res = yield call(opportunitiesApi.publishOpportunityApi, {
      opportunities_id: action.opportunityId,
    });
    console.log(res);
    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res.data);
      console.log(res.data);
    } else if (res.status === 200 && res.data.status === "fail") {
      console.log(res.data);
      yield action.onError(res);
    } else {
      yield action.onError("Something Went Wrong");
    }
  } catch (err) {
    console.log(err);
    yield action.onError("Something Went Wrong");
  }
}
export function* approveOpportunityWorker(action) {
  try {
    const res = yield call(opportunitiesApi.approveOpportunityApi, {
      opportunities_id: action.opportunityId,
    });
    console.log(res);
    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res.data);
      console.log(res.data);
    } else if (res.status === 200 && res.data.status === "fail") {
      console.log(res.data);
      yield action.onError(res);
    } else {
      yield action.onError("Something Went Wrong");
    }
  } catch (err) {
    console.log(err);
    yield action.onError("Something Went Wrong");
  }
}
export function* rejectOpportunityWorker(action) {
  try {
    const res = yield call(opportunitiesApi.rejectOpportunityApi,
      action.id
    );
    console.log(res);
    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res.data);
      console.log(res.data);
    } else if (res.status === 200 && res.data.status === "fail") {
      console.log(res.data);
      yield action.onError(res);
    } else {
      yield action.onError("Something Went Wrong");
    }
  } catch (err) {
    console.log(err);
    yield action.onError("Something Went Wrong");
  }
}

export function* DeleteOpportunityWorker(action) {
  try {
    const res = yield call(opportunitiesApi.deleteOpportunityApi, action.id);
    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res.data);
      console.log(res.data);
    } else if (res.status === 200 && res.data.status === "fail") {
      console.log(res.data);
      yield action.onError(res);
    } else {
      yield action.onError("Something Went Wrong");
    }
  } catch (err) {
    console.log(err);
    yield action.onError("Something Went Wrong");
  }
}

export function* EditOpportunityWorker(action) {
  try {
    const res = yield call(opportunitiesApi.editOpportunityApi,
      action.opportunityId, action.data);

    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res.data);
      console.log(res.data);
    } else if (res.status === 200 && res.data.status === "fail") {
      console.log(res.data);
      yield action.onError(res);
    } else {
      yield action.onError("Something Went Wrong");
    }
  } catch (err) {
    console.log(err);
    yield action.onError("Something Went Wrong");
  }
}

export function* FetchOpportunityDetailsWorker(action) {
  try {
    const res = yield call(opportunitiesApi.fetchOpportunityDetailsApi, {
      opportunityId: action.opportunityId,
    });
    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res.data);
      console.log(res.data);
    } else if (res.status === 200 && res.data.status === "fail") {
      console.log(res.data);
      yield action.onError(res);
    } else {
      yield action.onError("Something Went Wrong");
    }
  } catch (err) {
    console.log(err);
    yield action.onError("Something Went Wrong");
  }
}
export function* opportunitiesVersionTableWorker(action) {
  try {
    const res = yield call(opportunitiesApi.opportunitiesVersionTableApi,
      action.page,
      action.data,
    );
    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res.data);
      console.log(res.data);
    } else if (res.status === 200 && res.data.status === "fail") {
      console.log(res.data);
      yield action.onError(res);
    } else {
      yield action.onError("Something Went Wrong");
    }
  } catch (err) {
    console.log(err);
    yield action.onError("Something Went Wrong");
  }
}


export function* opportunitiesAnalyticsWorker(action) {
  try {
    const res = yield call(opportunitiesApi.opportunitiesAnalyticsApi,
      action.id
    );
    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res.data);
      console.log(res.data);
    } else if (res.status === 200 && res.data.status === "fail") {
      console.log(res.data);
      yield action.onError(res);
    } else {
      yield action.onError("Something Went Wrong");
    }
  } catch (err) {
    console.log(err);
    yield action.onError("Something Went Wrong");
  }
}


export function* opportunitiesVersionViewWorker(action) {
  try {
    const res = yield call(opportunitiesApi.opportunitiesVersionViewApi,
      action.id
    );
    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res.data);
      console.log(res.data);
    } else if (res.status === 200 && res.data.status === "fail") {
      console.log(res.data);
      yield action.onError(res);
    } else {
      yield action.onError("Something Went Wrong");
    }
  } catch (err) {
    console.log(err);
    yield action.onError("Something Went Wrong");
  }
}
export function* opportunitiesApprovalsViewWorker(action) {
  try {
    const res = yield call(opportunitiesApi.opportunitiesApprovalsViewApi,
      action.id
    );
    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res.data);
      console.log(res.data);
    } else if (res.status === 200 && res.data.status === "fail") {
      console.log(res.data);
      yield action.onError(res);
    } else {
      yield action.onError("Something Went Wrong");
    }
  } catch (err) {
    console.log(err);
    yield action.onError("Something Went Wrong");
  }
}
export function* opportunitiesNameListWorker(action) {
  try {
    const res = yield call(opportunitiesApi.opportunitiesNameListApi,
    );
    console.log('====================================');
    console.log(res);
    console.log('====================================');
    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res.data);
      console.log(res.data);
    } else if (res.status === 200 && res.data.status === "fail") {
      console.log(res.data);
      yield action.onError(res);
    } else {
      yield action.onError("Something Went Wrong");
    }
  } catch (err) {
    console.log(err);
    yield action.onError("Something Went Wrong");
  }
}



//export
export function* opportunitiesTableExportWorker(action) {
  try {
    const res = yield call(opportunitiesApi.opportunitiesTableExportApi,

    );
    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res.data);
    }
  } catch (error) { }
}


export function* opportunitiesApprovalsExportWorker(action) {
  try {
    const res = yield call(opportunitiesApi.opportunitiesApprovalsExportApi);
    console.log(res);
    if (res.status === 200 && res.data.status === "success") {
      console.log(res.data);
      yield action.onSuccess(res.data.data);
    } else {
      yield action.onError(res.data);
    }
  } catch (error) { }
}

export function* opportunitiesVersionExportWorker(action) {
  try {
    const res = yield call(opportunitiesApi.opportunitiesVersionExportApi);
    console.log(res);
    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res.data);
    }
  } catch (error) { }
}


export function* opportunitiesSubmitWorker(action) {
  try {
    const res = yield call(opportunitiesApi.opportunitiesSubmitApi, action.id);
    console.log(res);
    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res.data);
    }
  } catch (error) { }
}


export function* watchcreateOpportunitiesWorker() {
  yield takeLatest(OPPORTUNITES_CREATE, createOpportunitiesWorker);
}

export function* watchFetchOpportunitiesTypeWorker() {
  yield takeLatest(FETCH_OPPORTUNITES_TYPE, fetchOpportunitiesTypeWorker);
}

export function* watchFetchOpportunitiesWorker() {
  yield takeLatest(FETCH_OPPORTUNITES_LIST, fetchOpportunitiesWorker);
}

export function* watchFetchOpportunitiesApprovalWorker() {
  yield takeLatest(
    FETCH_OPPORTUNITES_APPROVAL_LIST,
    fetchOpportunitiesApprovalWorker
  );
}

export function* watchPublishOpportunityWorker() {
  yield takeLatest(PUBLISH_OPPORTUNITY, publishOpportunityWorker);
}
export function* watchApproveOpportunityWorker() {
  yield takeLatest(APPROVE_OPPORTUNITY, approveOpportunityWorker);
}
export function* watchRejectOpportunityWorker() {
  yield takeLatest(REJECT_OPPORTUNITY, rejectOpportunityWorker);
}

export function* watchDeleteOpportunityWorker() {
  yield takeLatest(DELETE_OPPORTUNITY, DeleteOpportunityWorker);
}

export function* watchEditOpportunityWorker() {
  yield takeLatest(EDIT_OPPORTUNITY, EditOpportunityWorker);
}

export function* watchFetchOpportunityDetailsWorker() {
  yield takeLatest(FETCH_OPPORTUNITY_DETAILS, FetchOpportunityDetailsWorker);
}
// opportunities 
export function* watchOpportunitiesVersionTableWorker() {
  yield takeLatest(OPPORTUNITES_VERSION_HISTORY_TABLE, opportunitiesVersionTableWorker);
}
export function* watchOpportunitiesVersionViewWorker() {
  yield takeLatest(OPPORTUNITES_VERSION_VIEW, opportunitiesVersionViewWorker);
}
export function* watchopportunitiesAnalyticsWorker() {
  yield takeLatest(OPPORTUNITES_ANALYTICS, opportunitiesAnalyticsWorker);
}
export function* watchOpportunitiesApprovalsViewWorker() {
  yield takeLatest(OPPORTUNITES_APPROVALS_VIEW, opportunitiesApprovalsViewWorker);
}
export function* watchOpportunitiesNameListWorker() {
  yield takeLatest(OPPORTUNITES_NAME_LIST, opportunitiesNameListWorker);
}

//export
export function* opportunitiesTableExportWatcher() {
  yield takeLatest(
    OPPORTUNITIES_EXPORT,
    opportunitiesTableExportWorker
  );
}
export function* opportunitiesApprovalsExportWatcher() {
  yield takeLatest(OPPORTUNITIES_APPROVALS_EXPORT, opportunitiesApprovalsExportWorker);
}

export function* opportunitiesVersionExportWorkerWatcher() {
  yield takeLatest(OPPORTUNITIES_VERSION_EXPORT, opportunitiesVersionExportWorker);
}
export function* opportunitiesSubmitWorkerWatcher() {
  yield takeLatest(OPPORTUNITIES_SUBMIT, opportunitiesSubmitWorker);
}
