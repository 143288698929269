import React, { useRef, useState } from "react";
import Modal from "react-bootstrap/Modal";
import CloseIcon from "../../../../assets/icons/closeIcon";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import InputField from "../../../form/inputFields/inputField";
import ErrorMsg from "../../../form/errorMsg";
import ReactQuill from "react-quill";

const PartnerWithUsFaqModal = ({ show = false, id, closeModal, reasonUpdator, formikValues }) => {
    const initialValues = {
        question: id === 0 || id ? formikValues[id]?.question : "",
        answer: id === 0 || id ? formikValues[id]?.answer : "",
    };
    const loginSchema = Yup.object().shape({
        question: Yup.string().trim().required("Required"),
        answer: Yup.string().trim().required("Required"),
    });
    const onSubmit = (values, { resetForm }) => {
        // resetForm();
        reasonUpdator(id, values)
        closeModal()
    };
    return (
        <Modal
            centered
            show={show}
            onHide={closeModal}
            dialogClassName={`oppoptunities_modals_body mx-auto `}
            contentClassName={"oppoptunities_modals_body_content  p-2"}
        >
            <Modal.Body>
                <>
                    <div className="d-flex justify-content-between  ">
                        <h2 className="green_color fs_15 fn_Montserrat fw_600 pt-1">
                            CONTENTS
                        </h2>
                        <button
                            onClick={closeModal}
                            className="light_grey_color  fs_15 fn_Montserrat bg-transparent border-0 fw_600 "
                        >
                            <span className="pt-1">DISMISS</span>
                            <span className="ms-2">
                                <CloseIcon width={13} height={13} fill="#A5A5A5" />
                            </span>
                        </button>
                    </div>
                    <Formik
                        initialValues={initialValues}
                        onSubmit={onSubmit}
                        validationSchema={loginSchema}
                    >
                        {({ values, setFieldTouched, setFieldValue, touched, errors }) => (
                            <Form className="mt-3">
                                <InputField
                                    id={"question"}
                                    label={"Question"}
                                    type={"text"}
                                    placeholder={"Enter the Question"}
                                    name={"question"}
                                />
                                <label className="mb-1 ms-2" htmlFor="answer">
                                    Answer
                                </label>
                                <div
                                    style={{ height: "300px" }}
                                    className="oppor_content_edit__border overflow-auto rounded-2 pt-3 mb-0"
                                >
                                    <ReactQuill
                                        theme={"snow"}
                                        name={"answer"}
                                        id={"answer"}
                                        value={values.answer}
                                        onChange={(event) => setFieldValue("answer", event)}
                                        onBlur={() => setFieldTouched("answer", true)}
                                        valid={!errors.answer}
                                        invalid={touched.answer && !!errors.answer}
                                        autoFocus={true}
                                    />
                                </div>

                                <ErrorMsg name={"answer"} />
                                <div className="d-flex justify-content-end mt-4">
                                    <button
                                        type="submit"
                                        className="secondary_color fs_15  fn_Montserrat fw_600 border-0 tertiary_bg px-4 px-lg-5 py-2 py-lg-2 rounded-2"
                                    >
                                        {id === 0 || id ? "EDIT" : " CREATE"}
                                    </button>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </>
            </Modal.Body>
        </Modal>
    )
}

export default PartnerWithUsFaqModal