import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { FaqApprovalList } from "../../../../redux/actions/faqAction";
import { approvalsHowItWorksListAction } from "../../../../redux/actions/howItWorksAction";
import { TableContainer } from "../../../table/tableContainer";
import {
  tableApprovalsHeader,
  tableCampaignApprovalsHeader,
  tableFaqsHeader,
  tableHomeApprovalHeader,
  tableHomeHeader,
  tableHpowItWorksHeader,
} from "../../../table/tableData";
import TablePagination from "../../../table/tablePagination";
import errorToast from "../../../utilits/errorToast";
import moment from "moment";
import LoadingSpinnerTable from "../../../utilits/loadingSpinnerTable";
import LoadAndError from "../../../utilits/LoadAndError";
import { approvalsHomeListAction } from "../../../../redux/actions/homeActions";
import { approvalsCampaignListAction } from "../../../../redux/actions/campaignActions";
const CampaignApprovalTableContent = ({
  tableContainerClassName = "adminuser_table__container",
  page,
  search,
  changePage,
  containerWidth = "90%",
  containerMargin = "1.5rem 0rem",
}) => {
  const dispatch = useDispatch();
  const [NotifyMeApprovalsData, setNotifyMeApprovalsData] = useState([]);
  const [pageData, setPageData] = useState({ noOfItems: 0, noOfPages: 1 });
  useEffect(() => {
    setLoading(true);
    dispatch(
      approvalsCampaignListAction(
        page,
        {
          search: {
            campaign_id: search?.value?.id,
          },
        },
        onSuccess,
        onError
      )
    );
  }, [page, search]);
  const onSuccess = (data) => {
    setNotifyMeApprovalsData(data?.data?.data);
    setLoading(false);
    setPageData({
      noOfItems: data.data.count,
      noOfPages: data.data.pages,
    });
  };
  const onError = (data) => {
    errorToast(data.message);
    setLoading(false);
    setError(true);
  };
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  function handlePagination(type) {
    if (type === "+") {
      if (page + 1 < pageData.noOfPages) changePage("+");
    } else if (type === "-") if (page > 0) changePage("-");
  }
  console.log(NotifyMeApprovalsData);
  return (
    <TableContainer width={containerWidth} margin={containerMargin}>
      <div className={`${tableContainerClassName}`}>
        {loading && <LoadingSpinnerTable />}
        <table className={"adminuser_table"}>
          <thead className="adminuser_table__header">
            <tr className="adminuser_table__row">
              {tableCampaignApprovalsHeader.map((entry) => {
                return (
                  <th
                    key={entry}
                    className={`${entry === "Status" ? "position-sticky end-0 " : null
                      } text-nowrap adminuser_table__heading`}
                  >
                    {entry}
                  </th>
                );
              })}
            </tr>
          </thead>
          {loading == false && (
            <tbody className="adminuser_table__body">
              <LoadAndError
                loader={loading}
                error={error}
                status={NotifyMeApprovalsData.length === 0}
              >
                {NotifyMeApprovalsData?.map((item, index) => {
                  return (
                    <tr key={item.id} className="adminuser_table__row">
                      <td className="text-nowrap dark_title_color adminuser_table__data ">
                        {page > 0 ? 12 * page + (index + 1) : index + 1}
                      </td>

                      <td className="text-nowrap dark_title_color adminuser_table__data">
                        {item?.id}
                      </td>
                      <td className="text-nowrap dark_title_color adminuser_table__data">
                        {item?.campaign_id
                        }
                      </td>
                      <td className="text-nowrap  adminuser_table_ellipsis adminuser_table__data">
                        {item?.new_data === null
                          ? item?.old_data?.header_title
                          : item?.new_data?.header_title}
                      </td>
                      {/* <td className="text-nowrap  dark_title_color adminuser_table_ellipsis adminuser_table__data">
                        {item?.new_data === null
                          ? item?.old_data?.header_subtitle
                          : item?.new_data?.header_subtitle}
                        header_subtitle
                      </td> */}
                      <td className="text-nowrap  dark_title_color adminuser_table__data">
                        {moment(
                          item?.createdAt == null ? "--" : item?.createdAt
                        ).format("DD-MM-YYYY HH:mm:ss")}
                      </td>
                      <td className="text-nowrap  dark_title_color adminuser_table__data">
                        {item.updated_by == null ? "--" : item.updated_by}
                      </td>
                      <td className="text-nowrap secondary_color  adminuser_table__data">
                        {moment(
                          item?.updatedAt == null ? "--" : item?.updatedAt
                        ).format("DD-MM-YYYY HH:mm:ss")}
                      </td>
                      <td className="text-nowrap  position-sticky end-0 adminuser_table__data">
                        <div className="d-flex w-100   gap-2">
                          <Link
                            className="text-decoration-none primary_color"
                            to={{
                              pathname: `/static-pages/campaign/approvals/view/${item.id}`,
                            }}
                          >
                            View
                          </Link>
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </LoadAndError>
            </tbody>
          )}
        </table>
      </div>
      <TablePagination
        paginate={handlePagination}
        currentPage={page}
        pageData={pageData}
      />
    </TableContainer>
  );
};

export default CampaignApprovalTableContent;
