import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Navigate, useLocation, useNavigate, useParams } from "react-router-dom";
import RejectBtn from "../../../../components/buttons/rejectBtn";
import VersionHistoryBtn from "../../../../components/buttons/versionHistoryBtn";
import InnerContainer from "../../../../components/layouts/innerContainer/innerContainer";
import InnerLayout from "../../../../components/layouts/innerLayout/innerLayout";
import errorToast from "../../../../components/utilits/errorToast";
import successToast from "../../../../components/utilits/successToast";
import * as opporTypeActions from "../../../../redux/actions/opportunityTypeActions";
import ApproveBtn from "../../../../components/buttons/approveBtn";
import moment from "moment";
import { approvalsViewNewsletterAction, approveNewsletterAction, rejectNewsletterAction } from "../../../../redux/actions/newsletterActions";
import LoadingSpinnerTable from "../../../../components/utilits/loadingSpinnerTable";
import LoadAndError from "../../../../components/utilits/LoadAndError";
const NewsLetterApprovalView = () => {
    const params = useParams();                                                 
    const navigate = useNavigate()
    const [updated, setUpdated] = useState(false);
    const [approvalData, setApprovalData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const dispatch = useDispatch();
    useEffect(() => {
        setLoading(true);
        dispatch(
            approvalsViewNewsletterAction(params.id, onSuccess, onError)
        );
    }, [updated]);

    const onSuccess = (data) => {
        setApprovalData(data.data)
        console.log(data);
        setLoading(false);
        setError(false);
    };
    const onError = (data) => {
        errorToast(data.message);
        setLoading(false);
        setError(true);
    };
    console.log(params.id);
    //approve
    const approveNewsLtr = () => {
        dispatch(
            approveNewsletterAction(params.id, onSuccessApr, onErrorApr)
        );
        navigate("/static-pages/newsletter/approvals")
    };
    const onSuccessApr = (data) => {
        setUpdated(true);
        successToast(data.message);
        console.log("data is", data.data);
        navigate("/static-pages/newsletter/approvals")
    };
    const onErrorApr = (data) => {
        console.log(data);
        errorToast(data.message);
    };

    //reject
    const rejectNewsLtr = () => {
        setUpdated(false)
        dispatch(
            rejectNewsletterAction(params.id, onRejectSuccess, onRejectError)
        );
    };
    const onRejectSuccess = (data) => {
        setUpdated(true);
        successToast("Rejected Successfully");
        navigate("/static-pages/newsletter/approvals")
    };
    const onRejectError = (data) => {
        console.log(data);
        errorToast(data.message);
    };
    const data = [
        {
            heading: "Approval ID",
            title: approvalData.id,
        },
        {
            heading: "Email Address",
            title: approvalData?.new_data == null ? approvalData?.old_data?.email : approvalData?.new_data?.email,
        },
        {
            heading: "Newsletter Id",
            title: approvalData?.new_data == null ? approvalData?.old_data?.id : approvalData?.new_data?.id,
        },
        {
            heading: "Status",
            title: approvalData?.new_data == null ? approvalData?.old_data?.status : approvalData?.new_data?.status,
        },

        {
            heading: "Approved At",
            title: moment(approvalData.createdAt).format("DD-MM-YYYY HH:mm:ss")
        },
        {
            heading: "Updated At",
            title: moment(approvalData.updatedAt).format("DD-MM-YYYY HH:mm:ss")
        },
        {
            heading: "Operation Type",
            title: approvalData?.new_data == null ? "Delete" : approvalData?.new_data && approvalData?.old_data ? "Edit" : approvalData?.old_data == null ? "Create" : ""
          }
    ];

    const buttonList = [
        <VersionHistoryBtn link={"/static-pages/newsletter/version-history"} />,
        <ApproveBtn approveFn={approveNewsLtr} />,
        <RejectBtn showModal={rejectNewsLtr} />,
    ];
    return (
        <InnerLayout buttons={buttonList}>
            <div className="opportunity-type-container">
                <InnerContainer error={error} oldData={approvalData?.old_data} newData={approvalData?.new_data}>
                    {loading ? (
                        <LoadingSpinnerTable/>
                    ) : (
          <LoadAndError loader={loading} error={error}>
                    <div className="pt-3  ps-4 ps-lg-4 ms-3">
                        {data.map((item, index) => (
                            <div className="mb-2">
                                <h5 className="dark_grey_color fs_13 fn_Montserrat fw_500 m-0">
                                    {item.heading}
                                </h5>
                                <p className={` fs_13 fn_Nunito fw_500 dark_title_color  `}>
                                    {item.title}
                                </p>
                            </div>
                        ))}
                    </div>
                    </LoadAndError>
                    )}
                </InnerContainer>
            </div>
        </InnerLayout>
    );
}

export default NewsLetterApprovalView