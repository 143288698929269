import { authHeader } from "../config";
import { api } from "./api";
export const createDocumentApi = (params) => {
    return api.post("/api/admin/dashboard-faq/create-faq", params, {
        headers: {
            "x-access-token": localStorage.getItem("accessToken"),
            "Content-Type": "application/json",
        },
    });
};
export const approveDocumentApi = (params) => {
    return api.post("/api/admin/dashboard-faq/approve", JSON.stringify(params), {
        headers: {
            "x-access-token": localStorage.getItem("accessToken"),
            "Content-Type": "application/json",
        },
    });
};

export const rejectDocumentApi = (id, params) => {
    return api.post(
        `/api/admin/dashboard-faq/reject/${id}`,
        JSON.stringify(params),
        {
            headers: {
                "x-access-token": localStorage.getItem("accessToken"),
                "Content-Type": "application/json",
            },
        }
    );
};
export const viewDocumentDetailApi = (id, params) => {
    return api.post(
        `/api/admin/dashboard-faq/view-approval/${id}`,
        JSON.stringify(params),
        {
            headers: {
                "x-access-token": localStorage.getItem("accessToken"),
                "Content-Type": "application/json",
            },
        }
    );
};
export const viewDocumentListApi = (pageNo = 0, params) => {
    return api.post(
        `/api/admin/dashboard-faq/view-approvals?page_no=${pageNo}`,
        params,
        {
            headers: {
                "x-access-token": localStorage.getItem("accessToken"),
                "Content-Type": "application/json",
            },
        }
    );
};
export const editDocumentApi = (id, params) => {
    return api.post(
        `/api/admin/dashboard-faq/edit/${id}`,
        JSON.stringify(params),
        {
            headers: {
                "x-access-token": localStorage.getItem("accessToken"),
                "Content-Type": "application/json",
            },
        }
    );
};
export const deleteDocumentApi = (id, params) => {
    return api.post(
        `/api/admin/dashboard-faq/delete/${id}`,
        JSON.stringify(params),
        {
            headers: {
                "x-access-token": localStorage.getItem("accessToken"),
                "Content-Type": "application/json",
            },
        }
    );
};
export const documentApprovalListApi = (pageNo, params) => {
    return api.post(
        `/api/admin/dashboard-faq/view-checker?page_no=${pageNo}`,
        JSON.stringify(params),
        {
            headers: {
                "x-access-token": localStorage.getItem("accessToken"),
                "Content-Type": "application/json",
            },
        }
    );
};
export const documentApprovalByIdApi = (id, params) => {
    return api.post(
        `/api/admin/dashboard-faq/view-checker/${id}`,
        JSON.stringify(params),
        {
            headers: {
                "x-access-token": localStorage.getItem("accessToken"),
                "Content-Type": "application/json",
            },
        }
    );
};
export const versionHistoryDocumentListApi = (pageNo, params) => {
    return api.post(
        `/api/admin/dashboard-faq/view-version?page_no=${pageNo}`,
        JSON.stringify(params),
        {
            headers: {
                "x-access-token": localStorage.getItem("accessToken"),
                "Content-Type": "application/json",
            },
        }
    );
};
export const versionHistoryDocumentDetailApi = (id, params) => {
    return api.post(
        `/api/admin/dashboard-faq/view-version/${id}`,
        JSON.stringify(params),
        {
            headers: {
                "x-access-token": localStorage.getItem("accessToken"),
                "Content-Type": "application/json",
            },
        }
    );
};
export const documentTableExportApi = (params) => {
    return api.post(
        `/api/admin/dashboard-faq/export-main-faq`,
        JSON.stringify(params),
        {
            headers: {
                "x-access-token": localStorage.getItem("accessToken"),
                "Content-Type": "application/json",
            },
        }
    );
};
export const documentApprovalsExportApi = (params) => {
    return api.post(
        `/api/admin/dashboard-faq/export-approval-faq`,
        JSON.stringify(params),
        {
            headers: {
                "x-access-token": localStorage.getItem("accessToken"),
                "Content-Type": "application/json",
            },
        }
    );
};
export const documentVersionExportApi = (params) => {
    return api.post(
        `/api/admin/dashboard-faq/export-version-faq`,
        JSON.stringify(params),
        {
            headers: {
                "x-access-token": localStorage.getItem("accessToken"),
                "Content-Type": "application/json",
            },
        }
    );
};
export const documentSubmitApi = (id, params) => {
    return api.post(`/api/admin/dashboard-faq/on-submit/${id}`, params, {
        headers: {
            "x-access-token": localStorage.getItem("accessToken"),
            "Content-Type": "application/json",
        },
    });
};
