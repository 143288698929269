


import React from "react";

const DeleteIcon = ({ fill = "#bcc7ce", width = 10, height = 16 }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width}
      height={height}
      viewBox="0 0 10.978 14.114">
      <path id="Icon_material-delete"
        data-name="Icon material-delete"
        d="M8.284,17.046a1.573,1.573,0,0,0,1.568,1.568h6.273a1.573,1.573,0,0,0,1.568-1.568V7.636H8.284ZM18.478,5.284H15.733L14.949,4.5H11.028l-.784.784H7.5V6.852H18.478Z"
        transform="translate(-7.5 -4.5)"
        fill={fill} />
    </svg>
  );
};

export default DeleteIcon;

