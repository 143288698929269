import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { versionHistoryViewAllMediaRecAction } from "../../../../redux/actions/mediaRecogonitionActions";
import { TableContainer } from "../../../table/tableContainer";
import {
  tableMediaRecogVersionHeader,
  testimonialsapprovalData,
  testimonialsapprovalHeader,
} from "../../../table/tableData";
import TablePagination from "../../../table/tablePagination";
import errorToast from "../../../utilits/errorToast";
import LoadAndError from "../../../utilits/LoadAndError";
import LoadingSpinnerTable from "../../../utilits/loadingSpinnerTable";
import successToast from "../../../utilits/successToast";
// import { adminApprovalList } from "../../../redux/actions/AdminUserAction";

const MediaRecogonitionVersionTableContent = ({ page, search, changePage }) => {
  const [userListData, setUserListData] = useState([]);
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);
  const [show, setShow] = useState(false);
  const [deleteData, setDeleteData] = useState({ name: "", id: "" });
  const [loadingTable, setLoadingTable] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [pageData, setPageData] = useState({ noOfItems: 0, noOfPages: 1 });
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {
    setLoadingTable(true);
    dispatch(
      versionHistoryViewAllMediaRecAction(
        {
          search: {
            media_recogn_id: search?.value?.Id,
          },
        },
        page,
        onSuccess,
        onError
      )
    );
  }, [search, page]);

  const onSuccess = (data) => {
    setUserListData(data?.data?.data);
    setLoadingTable(false);
    setPageData({
      noOfItems: data.data.count,
      noOfPages: data.data.pages,
    });
    setLoading(false);
    setError(false);
  };
  const onError = (data) => {
    console.log(data);
    errorToast(data.message);
    setLoadingTable(false);
    setLoading(false);
    setError(true);
  };
  function handlePagination(type) {
    if (type === "+") {
      if (page + 1 < pageData.noOfPages) changePage("+");
    } else if (type === "-") if (page > 0) changePage("-");
  }

  return (
    <TableContainer maxWidth="100%" width="90%">
      <div className="adminuser_table__container position-relative">
        {loading && <LoadingSpinnerTable />}
        <table className={"adminuser_table"}>
          <thead className="adminuser_table__header">
            <tr className="adminuser_table__row">
              {tableMediaRecogVersionHeader.map((item) => {
                return (
                  <th
                    key={item}
                    className={`text-nowrap adminuser_table__heading adminuser_approvals_table__heading ${
                      item === "Status" ? "position-sticky end-0" : null
                    }`}
                  >
                    {item}
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody className="adminuser_table__body">
            <LoadAndError
              loader={loading}
              error={error}
              status={userListData.length === 0}
            >
              {userListData.map((item, index) => {
                return (
                  <tr key={item.id} className="adminuser_table__row">
                    <td className="text-nowrap dark_title_color adminuser_table__data ">
                      {page > 0 ? 12 * page + (index + 1) : index + 1}
                    </td>
                    <td className="text-nowrap secondary_color adminuser_table__data">
                      {item?.id == null ? "--" : item?.id}
                    </td>
                    <td className="text-nowrap secondary_color adminuser_table__data">
                      {item?.media_recoginition_id == null
                        ? "--"
                        : item?.media_recoginition_id}
                    </td>
                    <td className="text-nowrap secondary_color adminuser_table__data">
                      {item?.new_data == null
                        ? item?.old_data?.name
                        : item?.new_data?.name}
                    </td>
                    <td className="text-nowrap secondary_color adminuser_table__data">
                      {item?.new_data == null
                        ? item?.old_data?.title
                        : item?.new_data?.title}
                    </td>

                    <td className="text-nowrap dark_title_color adminuser_table__data">
                      {item?.updatedAt == null
                        ? "--"
                        : moment(item?.updatedAt).format("DD-MM-YYYY HH:mm:ss")}
                    </td>
                    <td className="text-nowrap dark_title_color adminuser_table__data">
                      {item?.updated_by == null ? "--" : item?.updated_by}
                    </td>
                    <td className="text-nowrap dark_title_color adminuser_table__data">
                      {item?.approved_by == null ? "--" : item?.approved_by}
                    </td>
                    <td className="text-nowrap dark_title_color adminuser_table__data">
                      {item?.approved_date == null
                        ? "--"
                        : moment(item?.approved_date).format(
                            "DD-MM-YYYY HH:mm:ss"
                          )}
                    </td>
                    <td className="text-nowrap adminuser_table__data position-sticky end-0">
                      <div className="d-flex w-100 gap-2">
                        <Link
                          className="text-decoration-none primary_color"
                          to={{
                            pathname: `/static-pages/media-recogonition/version-history/view/${item?.id}`,
                          }}
                        >
                          View
                        </Link>{" "}
                      </div>
                    </td>
                  </tr>
                );
              })}
            </LoadAndError>
          </tbody>
        </table>
      </div>
      <TablePagination
        paginate={handlePagination}
        currentPage={page}
        pageData={pageData}
      />
    </TableContainer>
  );
};

export default MediaRecogonitionVersionTableContent;
