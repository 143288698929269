import React, { useEffect, useRef, useState } from "react";
import "../admin-roles.css";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import InputField from "../../../../components/form/inputFields/inputField";
import SaveBtn from "../../../../components/buttons/saveBtn";
import SubmitBtn from "../../../../components/buttons/submit";
import InnerLayout from "../../../../components/layouts/innerLayout/innerLayout";
import DeleteBtn from "../../../../components/buttons/deleteBtn";
import InnerContainer from "../../../../components/layouts/innerContainer/innerContainer";
import ViewBtn from "../../../../components/buttons/viewBtn";
import AdminRoleCheckBox from "../../../../components/utilits/adminRoleCheckbox";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import * as adminRolesActions from "../../../../redux/actions/adminRoleActions";
import errorToast from "../../../../components/utilits/errorToast";
import successToast from "../../../../components/utilits/successToast";
import DeleteModal from "../../../../components/modals/deleteModal";
import LoadingSpinnerTable from "../../../../components/utilits/loadingSpinnerTable";
import LoadAndError from "../../../../components/utilits/LoadAndError";
const AdminRoleEdit = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [adminRoleData, setAdminRoleData] = useState(
    location.state ?? addAdminRoleDefaultValue
  );
  // console.log(location.state ?? addAdminRoleDefaultValue);
  const [updated, setUpdated] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [deleteData, setDeleteData] = useState({ roleName: "", id: "" });
  const [saved, setSaved] = useState(false);
  const [subLoading, setSubLoading] = useState(false);

  const handleShow = () => {
    setDeleteData({
      roleName: adminRoleData?.role_name,
      id: adminRoleData?.id,
    });
    setShow(true);
  };
  const handleClose = () => setShow(false);
  const [show, setShow] = useState(false);
  const handleDelete = () => {
    setDeleteLoading(true);
    dispatch(
      adminRolesActions.deleteAdminRole(
        deleteData.id,
        onDeleteSuccess,
        onDeleteError
      )
    );
  };
  const onDeleteSuccess = (data) => {
    successToast(data.message);
    setDeleteLoading(false);
    setShow(false);
    navigate("/admin-roles");
    setLoading(false);
    setError(false);
  };
  const onDeleteError = (data) => {
    errorToast(data.message);
    setDeleteLoading(false);
    setLoading(false);
    setError(true);
  };
  const params = useParams();
  const dispatch = useDispatch();
  const formRef = useRef();
  const initialValues = {
    id: adminRoleData?.id,
    roleName: adminRoleData?.role_name,
    permissions: {
      admin: {
        checker: adminRoleData?.permissions?.admin?.checker,
        maker: adminRoleData?.permissions?.admin?.maker,
        read_only: adminRoleData?.permissions?.admin?.read_only,
      },
      user: {
        checker: adminRoleData?.permissions?.user?.checker,
        maker: adminRoleData?.permissions?.user?.maker,
        read_only: adminRoleData?.permissions?.user?.read_only,
      },
      opportunity: {
        checker: adminRoleData?.permissions?.opportunity?.checker,
        maker: adminRoleData?.permissions?.opportunity?.maker,
        read_only: adminRoleData?.permissions?.opportunity?.read_only,
      },

      role: {
        checker: adminRoleData?.permissions?.role?.checker
          ? adminRoleData?.permissions?.role?.checker
          : false,
        maker: adminRoleData?.permissions?.role?.maker
          ? adminRoleData?.permissions?.role?.maker
          : false,
        read_only: adminRoleData?.permissions?.role?.read_only
          ? adminRoleData?.permissions?.role?.checker
          : false,
      },
      static_homepage: {
        checker: adminRoleData?.permissions?.static_homepage?.checker,
        maker: adminRoleData?.permissions?.static_homepage?.maker,
        read_only: adminRoleData?.permissions?.static_homepage?.read_only,
      },
    },
  };
  const adminRoleSchema = Yup.object().shape({
    roleName: Yup.string().trim().trim().required("Required"),
  });
  const handleSubmit = () => {
    if (formRef.current) {
      formRef.current.handleSubmit();
    }
  };
  const onSubmit = (values) => {
    setLoading(true);
    dispatch(
      adminRolesActions.editAdminRole(
        values.id,
        values.roleName,
        values.permissions,
        onEditSuccess,
        onEditError
      )
    );
    setUpdated(!updated);
  };
  useEffect(() => {
    setLoading(true);
    dispatch(adminRolesActions.viewAdminRole(params.id, onSuccess, onError));
  }, [params.id, updated]);

  const onSuccess = (data) => {
    setAdminRoleData(data.data);
    setLoading(false);
    setError(false);
  };
  const onError = (data) => {
    // console.log(data);
    setLoading(false);
    setError(true);
  };
  const onEditSuccess = (data) => {
    setLoading(false);
    setAdminRoleData(data.data);
    successToast(data.message);
    setSaved(true);
    setError(false);
  };
  const onEditError = (data) => {
    setLoading(false);
    console.log(data);
    errorToast(data.data.data + " " + data.data.message);
    setError(true);
  };

  const adminRoleSubmit = () => {
    setSubLoading(true);
    dispatch(
      adminRolesActions.adminRoleSubmitAction(
        params.id,
        onAdminSubmitSucess,
        onAdminSubmitError
      )
    );
  };
  const buttonList = [
    <ViewBtn link={`/admin-roles/view/${adminRoleData?.id}`} />,
    < SaveBtn loader={loading} submitFn={handleSubmit} />,
    <DeleteBtn showModal={handleShow} />,
    <SubmitBtn saveHide={saved} loader={subLoading} submitFn={adminRoleSubmit} />,
  ];
  function onAdminSubmitSucess(data) {
    successToast(data.message);
    setSubLoading(false);
    navigate("/admin-roles");
  }
  function onAdminSubmitError(data) {
    setSubLoading(false);
    errorToast(data.message);
  }
  return (
    <>
      <InnerLayout buttons={buttonList}>
        <div className="admin_role_container ">
          <InnerContainer>
            {loading ? (
              <LoadingSpinnerTable />
            ) : (
              <LoadAndError loader={loading} error={error}>
                <div className="pt-3 mb-5 ps-2 ps-md-2 ps-lg-4 ">
                  <h5 className="dark_grey_color fs_13 fn_Montserrat fw_500 m-0">
                    Role ID
                  </h5>
                  <p className={` fs_13 fn_Nunito fw_500  secondary_color `}>
                    {adminRoleData?.id}
                  </p>
                  <Formik
                    enableReinitialize={true}
                    innerRef={formRef}
                    initialValues={initialValues}
                    onSubmit={onSubmit}
                    validationSchema={adminRoleSchema}
                  >
                    <Form>
                      <div className="admin-role-form-width">
                        <InputField
                          id={"roleName"}
                          label={"Role Name"}
                          type={"text"}
                          placeholder={"Enter Role Name"}
                          name={"roleName"}
                        />
                      </div>
                      <div>
                        <h5 className="dark_grey_color fs_12 fn_Montserrat fw_500 m-0">
                          Permissions
                        </h5>
                        <div className="admin_role_view_permissions_width mt-3 mb-3">
                          <div className="row ms-0 ms-lg-3 ">
                            <div className="col-3">
                              <h5 className="dark_grey_color text-nowrap fs_12 fn_Montserrat fw_500 ">
                                Modules
                              </h5>
                            </div>
                            <div className="col-3">
                              <h5 className="dark_grey_color d-flex justify-content-center fs_12 text-nowrap fn_Montserrat fw_500 ">
                                Checker
                              </h5>
                            </div>
                            <div className="col-3">
                              <h5 className="dark_grey_color fs_12 d-flex justify-content-center  fn_Montserrat text-nowrap fw_500 ">
                                Maker
                              </h5>
                            </div>
                            <div className="col-3">
                              <h5 className="dark_grey_color text-nowrap d-flex justify-content-center fs_12 fn_Montserrat fw_500 ">
                                Read Only
                              </h5>
                            </div>
                          </div>
                          <div className="row ms-0 ms-lg-3 mt-2">
                            <div className="col-3">
                              <h5 className="dark_grey_color text-nowrap fs_12 fn_Montserrat fw_500 ">
                                Admin
                              </h5>
                            </div>
                            <div className="col-3">
                              <div className="d-flex justify-content-center">
                                <AdminRoleCheckBox name="permissions.admin.checker" />
                              </div>
                            </div>
                            <div className="col-3">
                              <div className="d-flex justify-content-center">
                                <AdminRoleCheckBox name="permissions.admin.maker" />
                              </div>
                            </div>
                            <div className="col-3">
                              <div className="d-flex justify-content-center">
                                <AdminRoleCheckBox name="permissions.admin.read_only" />
                              </div>
                            </div>
                          </div>
                          <div className="row ms-0 ms-lg-3 mt-2">
                            <div className="col-3">
                              <h5 className="dark_grey_color text-nowrap fs_12 fn_Montserrat fw_500 ">
                                User
                              </h5>
                            </div>
                            <div className="col-3">
                              <div className="d-flex justify-content-center">
                                <AdminRoleCheckBox name="permissions.user.checker" />
                              </div>
                            </div>
                            <div className="col-3">
                              <div className="d-flex justify-content-center">
                                <AdminRoleCheckBox name="permissions.user.maker" />
                              </div>
                            </div>
                            <div className="col-3">
                              <div className="d-flex justify-content-center">
                                <AdminRoleCheckBox name="permissions.user.read_only" />
                              </div>
                            </div>
                          </div>
                          <div className="row ms-0 ms-lg-3 mt-2">
                            <div className="col-3">
                              <h5 className="dark_grey_color text-nowrap fs_12 fn_Montserrat fw_500 ">
                                Opportunity
                              </h5>
                            </div>
                            <div className="col-3">
                              <div className="d-flex justify-content-center">
                                <AdminRoleCheckBox name="permissions.opportunity.checker" />
                              </div>
                            </div>
                            <div className="col-3">
                              <div className="d-flex justify-content-center">
                                <AdminRoleCheckBox name="permissions.opportunity.maker" />
                              </div>
                            </div>
                            <div className="col-3">
                              <div className="d-flex justify-content-center">
                                <AdminRoleCheckBox name="permissions.opportunity.read_only" />
                              </div>
                            </div>
                          </div>
                          <div className="row ms-0 ms-lg-3 mt-2">
                            <div className="col-3">
                              <h5 className="dark_grey_color text-nowrap fs_12 fn_Montserrat fw_500 ">
                                Role
                              </h5>
                            </div>
                            <div className="col-3">
                              <div className="d-flex justify-content-center">
                                <AdminRoleCheckBox name="permissions.role.checker" />
                              </div>
                            </div>
                            <div className="col-3">
                              <div className="d-flex justify-content-center">
                                <AdminRoleCheckBox name="permissions.role.maker" />
                              </div>
                            </div>
                            <div className="col-3">
                              <div className="d-flex justify-content-center">
                                <AdminRoleCheckBox name="permissions.role.read_only" />
                              </div>
                            </div>
                          </div>

                          <div className="row ms-0 ms-lg-3 mt-2">
                            <div className="col-3">
                              <h5 className="dark_grey_color text-nowrap fs_12 fn_Montserrat fw_500 ">
                                Static Pages
                              </h5>
                            </div>
                            <div className="col-3">
                              <div className="d-flex justify-content-center">
                                <AdminRoleCheckBox name="permissions.static_homepage.checker" />
                              </div>
                            </div>
                            <div className="col-3">
                              <div className="d-flex justify-content-center">
                                <AdminRoleCheckBox name="permissions.static_homepage.maker" />
                              </div>
                            </div>
                            <div className="col-3">
                              <div className="d-flex justify-content-center">
                                <AdminRoleCheckBox name="permissions.static_homepage.read_only" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Form>
                  </Formik>
                </div>
              </LoadAndError>
            )}
          </InnerContainer>
        </div>
        <DeleteModal
          loader={deleteLoading}
          handleClose={handleClose}
          deleteFn={handleDelete}
          data={deleteData}
          show={show}
        />
      </InnerLayout>
    </>
  );
};

export default AdminRoleEdit;

export const addAdminRoleDefaultValue = {
  id: "",
  role_name: "",
  permissions: {
    admin: {
      checker: false,
      maker: false,
      read_only: false,
    },
    user: {
      checker: false,
      maker: false,
      read_only: false,
    },
    opportunity: {
      checker: false,
      maker: false,
      read_only: false,
    },

    role: {
      checker: false,
      maker: false,
      read_only: false,
    },
  },
};
