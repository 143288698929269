import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import ApproveBtn from "../../../../components/buttons/approveBtn";
import RejectBtn from "../../../../components/buttons/rejectBtn";
import VersionHistoryBtn from "../../../../components/buttons/versionHistoryBtn";
import InnerContainer from "../../../../components/layouts/innerContainer/innerContainer";
import InnerLayout from "../../../../components/layouts/innerLayout/innerLayout";
import errorToast from "../../../../components/utilits/errorToast";
import LoadAndError from "../../../../components/utilits/LoadAndError";
import LoadingSpinnerTable from "../../../../components/utilits/loadingSpinnerTable";
import successToast from "../../../../components/utilits/successToast";
import {
  viewApprovalById,
  approveAdmin,
  rejectAdmin,
} from "../../../../redux/actions/AdminUserAction";
import "../admin-user.css";
const AdminApprovalView = () => {
  const navigate = useNavigate();

  const params = useParams();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false)
  const [adminDetails, setAdminDetails] = useState([]);

  useEffect(() => {
    setLoading(true);
    console.log(params);
    dispatch(viewApprovalById(params.id, onSuccess, onError));
  }, []);

  const onSuccess = (data) => {
    console.log(data.data);
    setAdminDetails(data.data);
    console.log("SUCCESS");
    setLoading(false);
    setError(false)
  };

  const onError = (data) => {
    console.log(data);
    console.log("ERROR");
    setLoading(false);
    setError(true)
  };
  const approveAdminFunc = () => {
    dispatch(approveAdmin(params.id, onApproveSuccess, onApproveError));
  };
  const rejectAdminFunc = () => {
    dispatch(rejectAdmin(params.id, onRejectSuccess, onRejectError));
  };
  const buttonList = [
    <VersionHistoryBtn link={`/admin-users/version-history`} />,
    <ApproveBtn approveFn={approveAdminFunc} />,
    <RejectBtn showModal={rejectAdminFunc} />,
  ];

  const onApproveSuccess = (data) => {
    successToast(data.message);
    console.log(data);
    navigate("/admin-users/approvals")
    setLoading(false)
    setError(false)
  };
  const onApproveError = (data) => {
    errorToast(data.data + " " + data.message);
    console.log(data);
    setLoading(false)
    setError(true)
  };

  const onRejectSuccess = (data) => {
    successToast("Rejected Successfully");
    console.log(data);
    navigate("/admin-users/approvals")
    setLoading(false)
    setError(false)
  };

  const onRejectError = (data) => {
    errorToast(data.data + " " + data.message);
    console.log(data);
    setLoading(false)
    setError(true)
  };

  const data = [
    {
      heading: "Approval ID",
      title: adminDetails.id,
    },
    {
      heading: "Admin ID",
      title: adminDetails?.new_data === null ? adminDetails?.old_data?.id : adminDetails?.new_data?.id,
    },
    {
      heading: "E-Mail",
      title: adminDetails?.new_data === null ? adminDetails?.old_data?.email : adminDetails?.new_data?.email,
    },
    {
      heading: "Role ID",
      title: adminDetails?.new_data === null ? adminDetails?.old_data?.role_id : adminDetails?.new_data?.role_id,
    },
    {
      heading: "Created At",
      title: moment(adminDetails.createdAt).format("DD/MM/YY HH:mm:ss"),
    },
    {
      heading: "Last Login",
      title: "13:00:00 static",
    },
    {
      heading: "Last Action",
      title: "Lorem ipsum dolor no data from backend",
    },
    {
      heading: "Updated At",
      title: moment(adminDetails.updatedAt).format("DD/MM/YY HH:mm:ss"),
    },
    {
      heading: "Operation Type",
      title: adminDetails?.new_data == null ? "Delete" : adminDetails?.new_data && adminDetails?.old_data ? "Edit" : adminDetails?.old_data == null ? "Create" : ""
    }
  ];

  return (
    <InnerLayout buttons={buttonList}>
      <div className="admin_user_container">
        <InnerContainer error={error} oldData={adminDetails?.old_data} newData={adminDetails?.new_data}>
          {loading ? (
            <LoadingSpinnerTable/>
          ) : (
          <LoadAndError loader={loading} error={error}>
          <div className="pt-3  ps-4 ps-lg-4">
            {data.map((item, index) => (
              <div className="mb-2">
                <h5 className="dark_grey_color fs_13 fn_Montserrat fw_500 m-0">
                  {item.heading}
                </h5>
                <p
                  className={` fs_13 fn_Nunito fw_500 ${item.heading === "Admin ID" || item.heading === "E-Mail"
                    ? "secondary_color"
                    : "dark_title_color"
                    }`}
                >
                  {item.title}

                </p>
              </div>
            ))}
          </div>
          </LoadAndError>
          )}
        </InnerContainer>
      </div>
    </InnerLayout>
  );
};

export default AdminApprovalView;
