import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useParams } from "react-router-dom";
import ArrowRightIcon from "../../../../assets/icons/arrowrightIcon";
import InnerContainer from "../../../../components/layouts/innerContainer/innerContainer";
import InnerLayout from "../../../../components/layouts/innerLayout/innerLayout";
import LoadAndError from "../../../../components/utilits/LoadAndError";
import { versionHistoryAdminDetail } from "../../../../redux/actions/AdminUserAction";
import "../admin-user.css";

const AdminApprovalsVersionHistory = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const [adminVersionData, setAdminVersionData] = useState([])
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)

  useEffect(() => {
    setLoading(true)
    dispatch(versionHistoryAdminDetail(params.id, onSuccess, onError));
  }, []);

  const onSuccess = (data) => {
    console.log(data);
    setAdminVersionData(data?.data)
    setError(false)
    setLoading(false)
  };
  const onError = (data) => {
    console.log(data);
    setError(true)
    setLoading(false)
  };

  const data = [
    {
      heading: "Admin ID",
      title: adminVersionData?.id,
    },
    {
      heading: "Created At",
      title: moment(adminVersionData?.createdAt).format("DD/MM/YY HH:mm:ss"),
    },
    {
      heading: "Updated At",
      title: moment(adminVersionData?.updatedAt).format("DD/MM/YY HH:mm:ss"),
    },
    {
      heading: "Updated By",
      title: adminVersionData?.updated_by === null ? "-" : adminVersionData?.updated_by,
    },
    {
      heading: "Approved By",
      title: adminVersionData?.approved_by === null ? "-" : adminVersionData?.approved_by,

    },
    {
      heading: "Approved Date",
      title: moment(adminVersionData?.createdAt).format("DD/MM/YY HH:mm:ss"),

    },
  ];
  const newData = [
    {
      heading: "Checker Id",
      title: adminVersionData?.id,
    },
    {
      heading: "Name",
      title: adminVersionData?.new_data?.name,

    },
    {
      heading: "Admin Id",
      title: adminVersionData?.new_data?.id
      ,
    },
    {
      heading: "Approved By",
      title: adminVersionData?.approved_by === null ? "-" : adminVersionData?.approved_by,

    },
    {
      heading: "Approved Date",
      title: moment(adminVersionData?.approved_date).format("DD/MM/YY HH:mm:ss"),

    },
    {
      heading: "Updated Date",
      title: moment(adminVersionData?.new_data?.updatedAt).format("DD/MM/YY HH:mm:ss"),

    },
    {
      heading: "Updated By",
      title: adminVersionData?.new_data?.updated_by ,

    }
  ];
  const oldData = [
    {
      heading: "Checker Id",
      title: adminVersionData?.id,
    },
    {
      heading: "Name",
      title: adminVersionData?.old_data?.name ? adminVersionData?.old_data?.name : "-",

    },
    {
      heading: "Role Id",
      title: adminVersionData?.old_data?.role_id,

    },

    {
      heading: "Email",
      title: adminVersionData?.old_data?.email,

    },
    {
      heading: "Admin Id",
      title: adminVersionData?.old_data?.id,
    },

    {
      heading: "Approved Date",
      title: moment(adminVersionData?.old_data?.approved_date).format("DD/MM/YY HH:mm:ss"),

    },
    {
      heading: "Updated Date",
      title: moment(adminVersionData?.old_data?.updatedAt).format("DD/MM/YY HH:mm:ss"),

    },
    {
      heading: "Updated By",
      title: adminVersionData?.old_data?.updated_by ? adminVersionData?.old_data?.updated_by : "-" ,
    },
    {
      heading: "Approved By",
      title: adminVersionData?.old_data?.approved_by ? adminVersionData?.old_data?.approved_by : "-",
    },

  ];

  return (
    <>
      <InnerLayout
      >
        <div className="admin_user_container">
          <InnerContainer>
            <LoadAndError loader={loading} error={error}>
              <div className="pt-3 ps-5 row">
                {data.map((item, index) => (
                  <div key={item.heading} className="mb-2  col">
                    <h5 className="dark_grey_color text-nowrap fs_13 fn_Montserrat fw_500 m-0">
                      {item.heading}
                    </h5>
                    <p
                      className={` fs_13 fn_Nunito text-nowrap fw_500 ${item.heading === "Admin ID" || item.heading === "E-Mail"
                        ? "secondary_color"
                        : "dark_title_color"
                        }`}
                    >
                      {item.heading === "Admin ID" ? <Link
                        className="text-decoration-none secondary_color"
                        to={{
                          pathname: `/admin-users/view/${adminVersionData?.new_data?.id}`,
                        }}
                      >
                        {adminVersionData?.new_data?.id}
                      </Link> : item.title}
                    </p>
                  </div>
                ))}
              </div>
              <div className="white_shade_bg  py-1 d-flex">
                <div className="w-50">
                  <h5 className="fw_500 ps-4 dark_grey_color pt-2 text-nowrap fs_16 fn_Montserrat">
                    JSON Old Data
                  </h5>
                </div>
                <div className="w-50  d-none d-lg-block">
                  <h5 className="fw_500 ps-4  dark_grey_color pt-2 text-nowrap fs_16 fn_Montserrat">
                    JSON New Data
                  </h5>
                </div>
              </div>

              <div className="row mt-3 ps-0 ps-lg-4">
                <div className="col-12 col-lg-6">
                  <div className="admin_aprvls_vrsn_hstry_data_container  mt-3 pt-3 px-3">
                    <div className="d-flex justify-content-between">
                      <h5 className="fs_13 fn_Nunito fw_400 dark_title_color text-nowrap">
                        Old Data
                      </h5>
                      <ArrowRightIcon width={25} height={25} fill={"#A5A5A5"} />
                    </div>
                    {
                      adminVersionData?.old_data === null ? (<>
                        <div>Empty Data</div>
                      </>) : (
                        <>
                          {
                            oldData?.map((item, index) => {
                              return (
                                <React.Fragment key={item.heading}>
                                  <h5 className="dark_grey_color text-nowrap fs_13 fn_Montserrat fw_500 m-0">
                                    {item.heading}
                                  </h5>
                                  <p
                                    className={` fs_13 fn_Nunito text-nowrap fw_500 ${item.heading === "Admin ID" || item.heading === "E-Mail"
                                      ? "secondary_color"
                                      : "dark_title_color"
                                      }`}
                                  >
                                    {item.title}
                                  </p>
                                </React.Fragment>
                              )

                            })
                          }
                        </>
                      )
                    }


                  </div>
                </div>
                <div className="col-12 mt-5 mt-lg-0 col-lg-6">
                  <h5 className="fw_500 ps-4 d-block d-lg-none white_shade_bg  dark_grey_color py-3 text-nowrap fs_16 fn_Montserrat">
                    JSON New Data
                  </h5>
                  <div className="admin_aprvls_vrsn_hstry_data_container mt-4 mt-lg-3 pt-3 px-3">
                    <div className="d-flex justify-content-between">
                      <h5 className="fs_13 fn_Nunito fw_400 dark_title_color py-2 text-nowrap">
                        New Data
                      </h5>
                      <ArrowRightIcon width={25} height={25} fill={"#A5A5A5"} />
                    </div>
                    {
                      adminVersionData?.new_data === null ? (<>
                        <div>Empty Data</div>
                      </>) : (
                        <>{
                      newData.map((item, index) => {
                        return (
                          <React.Fragment key={item.heading}>
                            <h5 className="dark_grey_color text-nowrap fs_13 fn_Montserrat fw_500 m-0">
                              {item.heading}
                            </h5>
                            <p
                              className={` fs_13 fn_Nunito text-nowrap fw_500 ${item.heading === "Admin ID" || item.heading === "E-Mail"
                                ? "secondary_color"
                                : "dark_title_color"
                                }`}
                            >
                              {item.title}
                            </p>
                          </React.Fragment>
                        )

                      })
                    }</>)}
                    
                  </div>
                </div>
              </div>
            </LoadAndError>
          </InnerContainer>
        </div>
      </InnerLayout>
    </>
  );
};
export default AdminApprovalsVersionHistory;
