import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { TableContainer } from "../../../table/tableContainer";
import { tableCommunityBadgesHeader } from "../../../table/tableData";
import TablePagination from "../../../table/tablePagination";
import moment from "moment";
import { useDispatch } from "react-redux";
import errorToast from "../../../utilits/errorToast";
import successToast from "../../../utilits/successToast";
import DeleteModal from "../../../modals/deleteModal";
import LoadAndError from "../../../utilits/LoadAndError";
import LoadingSpinnerTable from "../../../utilits/loadingSpinnerTable";
import usePermissionChecker from "../../../utilits/usePermissionChecker";
import {
  deleteCommunityBadgesAction,
  viewAllCommunityBadgesAction,
} from "../../../../redux/actions/communityBadgesActions";

const CommunityBadgesTableContent = ({
  tableContainerClassName = "adminuser_table__container",
  page,
  search,
  changePage,
  containerWidth = "90%",
  containerMargin = "1.5rem 0rem",
}) => {
  const access = usePermissionChecker("maker");
  const [userListData, setUserListData] = useState([]);
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);
  const [show, setShow] = useState(false);
  const [deleteData, setDeleteData] = useState({ name: "", id: "" });
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [pageData, setPageData] = useState({ noOfItems: 0, noOfPages: 1 });
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
console.log(search?.Id);
  useEffect(() => {
    setLoading(true);
    dispatch(
      viewAllCommunityBadgesAction(
        page,
        {
          search: {
            badge_id: search?.Id,
          },
        },
        onSuccess,
        onError
      )
    );
  }, [search, page, loadingDelete]);

  const onSuccess = (data) => {
    setUserListData(data?.data.data);
    setPageData({
      noOfItems: data?.data?.count,
      noOfPages: data?.data?.pages,
    });
    setError(false);
    setLoading(false);
  };
  const onError = (data) => {
    console.log(data);
    setLoading(false);
    errorToast(data.message);
    setError(true);
  };

  const handleDelete = () => {
    setLoadingDelete(true);
    dispatch(
      deleteCommunityBadgesAction(deleteData.id, onDeleteSuccess, onDeleteError)
    );
  };
  const onDeleteSuccess = (data) => {
    successToast(data.message);
    setLoadingDelete(false);
    setShow(false);
    setError(false);
  };
  const onDeleteError = (data) => {
    errorToast(data.data + " " + data.message);
    setLoadingDelete(false);
    setError(true);
  };

  function handlePagination(type) {
    if (type === "+") {
      if (page + 1 < pageData.noOfPages) changePage("+");
    } else if (type === "-") if (page > 0) changePage("-");
  }
  console.log(userListData.badge_id);
  return (
    <TableContainer width={containerWidth} margin={containerMargin}>
      <div className={`${tableContainerClassName}`}>
        {loading && <LoadingSpinnerTable />}
        <table className={"adminuser_table"}>
          <thead className="adminuser_table__header">
            <tr className="adminuser_table__row">
              {tableCommunityBadgesHeader.map((entry) => {
                return (
                  <th
                    key={entry}
                    className={`${entry === "Status" ? "position-sticky end-0 " : null
                      } text-nowrap adminuser_table__heading ${!access && entry === "Status" ? "text-start" : ""
                      }`}
                  >
                    {entry}
                  </th>
                );
              })}
            </tr>
          </thead>
          {loading == false && (
            <tbody className="adminuser_table__body">
              <LoadAndError
                loader={loading}
                error={error}
                status={userListData.length === 0}
              >
                {userListData.map((item, index) => {
                  return (
                    <tr key={item.id} className="adminuser_table__row">
                      <td className="text-nowrap dark_title_color adminuser_table__data ">
                        {page > 0 ? 12 * page + (index + 1) : index + 1}
                      </td>
                      <td className="text-nowrap dark_title_color adminuser_table__data">
                        {item?.id == null ? "--" : item?.id}
                      </td>
                      <td className="text-nowrap dark_title_color adminuser_table__data">
                        {item?.user?.user_name == null
                          ? "--"
                          : item?.user?.user_name}
                      </td>
                      <td className="text-nowrap secondary_color adminuser_table__data">
                        {item?.points == null ? "--" : item?.points}
                      </td>
                      <td className="text-nowrap secondary_color adminuser_table__data">
                        {item?.points_type == null ? "--" : item?.points_type}
                      </td>
                      <td className="text-nowrap  dark_title_color adminuser_table__data">
                        {item?.createdAt == null
                          ? "--"
                          : moment(item?.createdAt).format(
                            "DD-MM-YYYY HH:mm:ss"
                          )}
                      </td>
                      <td className="text-nowrap  dark_title_color adminuser_table__data">
                        {item?.updatedAt == null
                          ? "--"
                          : moment(item?.updatedAt).format(
                            "DD-MM-YYYY HH:mm:ss"
                          )}
                      </td>
                      <td className="text-nowrap  position-sticky end-0 adminuser_table__data">
                        <div className="d-flex w-100  justify-content-start gap-2">
                          <Link
                            className="text-decoration-none primary_color"
                            to={{
                              pathname: `/static-pages/community-badges/view/${item.id}`,
                            }}
                          >
                            View
                          </Link>
                          {access && (
                            <>
                              {item.editable && (
                                <>
                                  <span className="vr"></span>
                                  <Link
                                    className="text-decoration-none secondary_color"
                                    to={{
                                      pathname: `/static-pages/community-badges/edit/${item.id}`,
                                    }}
                                  >
                                    Edit
                                  </Link>{" "}
                                  {item.lastActionApproved && (
                                    <>
                                      <span className="vr"></span>{" "}
                                      <button
                                        onClick={() => {
                                          setDeleteData({
                                            name: item?.id,
                                            id: item?.id,
                                          });
                                          handleShow();
                                        }}
                                        className="text-decoration-none border-0 bg-transparent primary_color light_red_color"
                                      >
                                        Delete
                                      </button>
                                    </>)}
                                </>
                              )}
                            </>
                          )}
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </LoadAndError>
            </tbody>
          )}
        </table>
      </div>
      <DeleteModal
        loader={loadingDelete}
        handleClose={handleClose}
        deleteFn={handleDelete}
        data={deleteData}
        show={show}
      />
      <TablePagination
        paginate={handlePagination}
        currentPage={page}
        pageData={pageData}
      />
    </TableContainer>
  );
};

export default CommunityBadgesTableContent;
