import React, { useEffect, useState } from "react";
import errorToast from "../../../../components/utilits/errorToast";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import InnerLayout from "../../../../components/layouts/innerLayout/innerLayout";
import InnerContainer from "../../../../components/layouts/innerContainer/innerContainer";
import VersionHistoryBtn from "../../../../components/buttons/versionHistoryBtn";
import EditBtn from "../../../../components/buttons/editBtn";
import DeleteBtn from "../../../../components/buttons/deleteBtn";
import {
  deleteExpress,
  viewExpressDetail,
} from "../../../../redux/actions/expressInterestAction";
import moment from "moment";
import successToast from "../../../../components/utilits/successToast";
import DeleteModal from "../../../../components/modals/deleteModal";
import { access } from "../../../../components/utilits/access";
import LoadingSpinnerTable from "../../../../components/utilits/loadingSpinnerTable";
import LoadAndError from "../../../../components/utilits/LoadAndError";

const ExpressInterestView = () => {
  const navigate = useNavigate();
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);
  const [show, setShow] = useState(false);
  const params = useParams();
  const dispatch = useDispatch();
  const [notifyMeViewData, SetNotifyMeViewData] = useState({
    id: "",
    name: "",
    createdAt: "",
    updatedAt: "",
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false)
  const [loadingDelete, setLoadingDelete] = useState(false);

  useEffect(() => {
    setLoading(true);
    dispatch(viewExpressDetail(params.id, onSuccess, onError));
  }, [params.id]);
  const onSuccess = (data) => {
    SetNotifyMeViewData(data.data);
    setLoading(false);
    console.log(data, "jdsgfdsugdsjgfdsjhgdshv");
    setError(false)
  };
  const onError = (data) => {
    errorToast(data.message);
    setLoading(false);
    console.log(data, "jdsgfdsugdsjgfdsjhgdshv");
    setError(true)
  };

  //delete
  const [deleteData, setDeleteData] = useState({ name: "", id: "" });

  const handleDelete = () => {
    setLoadingDelete(true);
    dispatch(deleteExpress(deleteData.id, onDeleteSuccess, onDeleteError));
  };
  const onDeleteSuccess = (data) => {
    successToast(data.message);
    setLoadingDelete(false);
    setShow(false);
    navigate("/notify-me");
    setLoading(false)
    setError(false)
  };
  const onDeleteError = (data) => {
    errorToast(data.message);
    setLoadingDelete(false);
    setLoading(false)
    setError(true)
  };
  console.log(notifyMeViewData);

  const deletenotifyMebyid = () => {
    setDeleteData({
      name: notifyMeViewData?.opportunity_type?.name,
      id: notifyMeViewData.id,
    });
    handleShow();
  };

  const buttonList = [
    <VersionHistoryBtn link="/opportunity/express-interest/version-history" />,
    notifyMeViewData.editable &&
    <EditBtn link={`/opportunity/express-interest/edit/${notifyMeViewData.id}`} />,
  ];

  const ViewDetails = [
    {
      heading: "Express Interest ID",
      title: notifyMeViewData?.id,
    },
    {
      heading: "Opportunity ID",
      title: notifyMeViewData?.opportunity_id,
    },
    {
      heading: "Opportunity Name",
      title: notifyMeViewData?.opportunity?.title,
    },
    {
      heading: "User ID",
      title: notifyMeViewData?.user_id,
    },
    {
      heading: "User Name",
      title: notifyMeViewData?.users?.user_name == null ? "--" : notifyMeViewData?.users?.user_name
    },
    {
      heading: "Amount",
      title: notifyMeViewData?.amount,
    },
    {
      heading: "Order ID",
      title: notifyMeViewData?.order_id,
    },
    {
      heading: "Payment Method",
      title: notifyMeViewData?.payment_method,
    },
    {
      heading: "Transaction Status",
      title: notifyMeViewData?.transaction_status,
    },
    {
      heading: "Transaction Error?",
      title: notifyMeViewData?.transaction_error_txt,
    },

    {
      heading: "Wallet Used?",
      title: notifyMeViewData?.wallet_used == true ? "True" : "False",
    },
    {
      heading: "Wallet Amount",
      title: notifyMeViewData?.wallet_amount,
    },
    {
      heading: "Wallet Transaction ID",
      title: notifyMeViewData?.wallet_transaction_id,
    },
    {
      heading: "Wallet Transaction Status",
      title: notifyMeViewData?.wallet_transaction_status,
    },
    {
      heading: "Wallet transaction Error?",
      title: notifyMeViewData?.wallet_transaction_error_txt,
    },
    {
      heading: "Payment Gateway Amount",
      title: notifyMeViewData?.pg_transaction_amount,
    },
    {
      heading: "Payment Gateway Transaction ID",
      title: notifyMeViewData?.pg_transaction_id,
    },
    {
      heading: "Payment Gateway Transaction Status",
      title: notifyMeViewData?.pg_transaction_status,
    },
    {
      heading: "Payment Gateway Transaction Error?",
      title: notifyMeViewData?.pg_transaction_error_txt,
    },
    {
      heading: "Backend Transaction ID",
      title: notifyMeViewData?.backend_transaction_id,
    },
    {
      heading: "Tenure",
      title: notifyMeViewData?.tenure,
    },
    {
      heading: "Created At",
      title: moment(
        notifyMeViewData?.createdAt == null ? "--" : notifyMeViewData?.createdAt
      ).format("DD-MM-YYYY HH:mm:ss"),
    },
    {
      heading: "Updated At",
      title: moment(
        notifyMeViewData?.updatedAt == null ? "--" : notifyMeViewData?.updatedAt
      ).format("DD-MM-YYYY HH:mm:ss"),
    },
  ];
  return (
    <InnerLayout
      buttons={
        buttonList

      }
    >
      <div className="user_view_container">
        <InnerContainer>
          {loading ? (
            <LoadingSpinnerTable />
          ) : (
            <LoadAndError loader={loading} error={error}>
              <div className="row mt-4">
                <div className="col-12 col-sm-12 col-md-9 col-lg-9 col-xl-7">
                  <div className="row ps-1 ms-1 ps-lg-4 ms-lg-1">
                    {ViewDetails.map((item, index) => (
                      <div className="mb-2 ">
                        <h5 className="dark_grey_color text-nowrap fs_13 fn_Montserrat fw_500">
                          {item.heading}
                        </h5>
                        <p
                          className={` fs_13 fn_Nunito  fw_500 ${item.heading === "Express Interest ID" ||
                            item.heading === "Opportunity ID" ||
                            item.heading === "Opportunity Name" ||
                            item.heading === "User ID" ||
                            item.heading === "User Name" ||
                            item.heading === "Order ID"
                            ? "secondary_color"
                            : "dark_title_color"
                            }`}
                        >
                          {item.title}
                        </p>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </LoadAndError>
          )}
        </InnerContainer>
      </div>
      <DeleteModal
        loader={loadingDelete}
        handleClose={handleClose}
        deleteFn={handleDelete}
        data={deleteData}
        show={show}
      />
    </InnerLayout>
  );
};

export default ExpressInterestView;
