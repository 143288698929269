import React, { useEffect, useRef, useState } from "react";
import "../testimonials.css";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import InputField from "../../../../components/form/inputFields/inputField";
import SelectFields from "../../../../components/form/selectFields/selectFields";
import SaveBtn from "../../../../components/buttons/saveBtn";
import InnerLayout from "../../../../components/layouts/innerLayout/innerLayout";
import DeleteBtn from "../../../../components/buttons/deleteBtn";
import InnerContainer from "../../../../components/layouts/innerContainer/innerContainer";
import ViewBtn from "../../../../components/buttons/viewBtn";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import LoadingSpinnerTable from "../../../../components/utilits/loadingSpinnerTable";
import DeleteModal from "../../../../components/modals/deleteModal";
import UploadIcon from "../../../../assets/icons/upload";
import CloseIcon from "../../../../assets/icons/closeIcon";
import ErrorMsg from "../../../../components/form/errorMsg";
import {
  editTestimonials,
  testimonialsSubmit,
  viewTestimonialsDetail,
} from "../../../../redux/actions/testimonialsAction";
import successToast from "../../../../components/utilits/successToast";
import { uploadAddressProofFrontImageAction } from "../../../../redux/actions/userAction";
import LoadAndError from "../../../../components/utilits/LoadAndError";
import SubmitBtn from "../../../../components/buttons/submit";
import errorToast from "../../../../components/utilits/errorToast";

const TestimonialsEdit = () => {
  const [submitData, setSubmitData] = useState();
  const formRef = useRef();
  const params = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [file, setFile] = useState();
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [delLoading, setDelLoading] = useState(false);
  const [deleteData, setDeleteData] = useState({ name: "", id: "" });
  const [show, setShow] = useState(false);
  const [roleTypesOptions, setRoleTypesOptions] = useState([]);
  const [adminDetails, setAdminDetails] = useState(null);
  useEffect(() => {
    setLoading(true);
    dispatch(viewTestimonialsDetail(params.id, onSuccess, onError));
  }, []);

  const onSuccess = (data) => {
    setAdminDetails(data?.data);
    setLoading(false);
    setDeskImageLink({ img: data?.data?.photo_url, error: false });
    setError(false);
  };

  const onError = () => {
    setLoading(false);
    console.log("ERROR");
    setError(true);
  };

  const initialValues = {
    name: adminDetails?.name,
    designation: adminDetails?.designation,
    userType: adminDetails?.user_type,
    description: adminDetails?.description,
    color: adminDetails?.color_code,
  };

  const loginSchema = Yup.object().shape({
    name: Yup.string("").required("Required"),
    designation: Yup.string(),
    userType: Yup.string().trim().required("Required"),
    description: Yup.string().trim().required("Required"),
    color: Yup.string(),
  });
  const handleSubmit = () => {
    if (!deskImageLink.img) {
      if (!deskImageLink.img) {
        setDeskImageLink({ img: "", error: true });
      }
    }
    if (formRef.current) {
      formRef.current.handleSubmit();
    }
  };
  const onSubmit = (values, { resetForm }) => {
    if (deskImageLink.img)
      dispatch(
        editTestimonials(
          params.id,
          {
            name: values.name,
            designation: values.designation,
            user_type: values.userType,
            description: values.description,
            photo_url: deskImageLink.img,
            color_code: values.color,
          },
          onSubmitSuccess,
          onSubmitError
        )
      );
  };
  function handleImageChange(e, param1) {
    console.log(param1);
    if (e.target?.files.length !== 0) {
      setFile(URL.createObjectURL(e.target.files[0]));
      e.target.value = null;
    }
  }

  const onSubmitSuccess = (data) => {
    console.log("SUBMIT SUCCESS");
    setSubmitData(data.data.id);
    setSaveHide(true);
    successToast(data.message);
  };

  const onSubmitError = (data) => {};
  //image
  const [deskImageLink, setDeskImageLink] = useState({
    img: "",
    error: false,
  });

  const imageUpload = (e) => {
    if (e.target.name === "deskImage") {
      dispatch(
        uploadAddressProofFrontImageAction(
          e.target.files[0],
          onDeskImageUploadSucess,
          onDeskImageUploadError
        )
      );
    }
    e.target.value = null;
  };
  const onDeskImageUploadSucess = (data) => {
    setDeskImageLink({ img: data.data.data.location, error: false });
  };
  const onDeskImageUploadError = (data) => {
    setDeskImageLink({ img: "", error: true });
  };
  //on submit api
  console.log(submitData);
  const [saveHide, setSaveHide] = useState(false);

  const submitFnc = () => {
    dispatch(testimonialsSubmit(submitData, onSubmitsSuccess, onSubmitsError));
  };
  const onSubmitsSuccess = (data) => {
    console.log(data);
    successToast(data.message);
    navigate("/static-pages/testimonials");
  };
  const onSubmitsError = (data) => {
    console.log("ERROR==================", data);
    errorToast(data.message);
  };
  const buttonList = [
    !saveHide && <SaveBtn submitFn={handleSubmit} loader={loading} />,
    <SubmitBtn submitFn={submitFnc} saveHide={saveHide} />,
  ];

  return (
    <>
      <InnerLayout buttons={buttonList}>
        <div className="admin_user_container">
          <InnerContainer>
            {loading ? (
              <LoadingSpinnerTable />
            ) : (
              <LoadAndError loader={loading} error={error}>
                <div className="pt-3 mb-5 ps-2 ps-md-2 ps-lg-4 ">
                  <h5 className="dark_grey_color fs_13 fn_Montserrat fw_500 m-0">
                    Testimonials ID
                  </h5>
                  <p className={` fs_13 fn_Nunito fw_500  secondary_color `}>
                    {adminDetails?.id}
                  </p>
                  <Formik
                    innerRef={formRef}
                    initialValues={initialValues}
                    enableReinitialize={true}
                    onSubmit={onSubmit}
                    validationSchema={loginSchema}
                  >
                    {({ setFieldValue, handleChange, values }) => (
                      <Form className="our-team-form-width">
                        <div className="row">
                          <div className="col-12 col-md-6">
                            <div className="mb-3">
                              <span className="mb-3 dark_grey_color fn_Montserrat fw_500 fs_14 ps-2">
                                Profile Picture
                              </span>
                              <div className="blog_create_image_container mt-1 position-relative d-flex  justify-content-center align-items-center">
                                <input
                                  multiple={false}
                                  style={{ visibility: "hidden" }}
                                  type="file"
                                  id="deskImage"
                                  className="upload_document_input"
                                  name="deskImage"
                                  accept="image/* ,capture=camera"
                                  onChange={(e) => {
                                    imageUpload(e);
                                  }}
                                />
                                {deskImageLink.img ? (
                                  <>
                                    <img
                                      src={deskImageLink.img}
                                      className={"user-edit-profile-picture"}
                                    />
                                    <button
                                      type="button"
                                      onClick={(e) => {
                                        setDeskImageLink({
                                          img: "",
                                          error: true,
                                        });
                                      }}
                                      className="position-absolute border-0 cursor_pointer white_bg d-flex gap-2 py-1 px-2 user_edit_profile_picture_remove_container"
                                    >
                                      <CloseIcon fill="#666666" />
                                      <span className="dark_grey_color fs_13 fn_Montserrat fw_500 ">
                                        Remove
                                      </span>
                                    </button>
                                  </>
                                ) : (
                                  <label
                                    htmlFor="deskImage"
                                    className="d-flex flex-column align-items-center mt-2 justify-content-center fs_12"
                                  >
                                    <UploadIcon width={30} height={30} />
                                    Choose Profile Picture
                                  </label>
                                )}
                              </div>
                              {deskImageLink.error && (
                                <span className="fs_12 ms-2 text-danger fw-semibold fn_Nunito">
                                  Required!
                                </span>
                              )}
                            </div>
                            <InputField
                              id={"name"}
                              label={"Name"}
                              type={"text"}
                              placeholder={"User name here"}
                              name={"name"}
                            />
                            <InputField
                              id={"designation"}
                              label={"Designation"}
                              type={"text"}
                              placeholder={"Enter Designation here"}
                              name={"designation"}
                            />
                            <InputField
                              id={"color"}
                              label={"Color Code"}
                              type={"text"}
                              placeholder={"Enter Color Code"}
                              name={"color"}
                            />

                            <SelectFields
                              default1={{
                                label: adminDetails?.user_type,
                                value: adminDetails?.user_type,
                              }}
                              label={"User Type"}
                              placeholder={"Select user type"}
                              name={"userType"}
                              options={selectDesignation}
                              id={"userType"}
                            />
                          </div>
                          <div className="col-12 col-md-6">
                            <div className="ps-0 ps-lg-4">
                              <label
                                id="description"
                                className="mb-2 dark_grey_color fn_Montserrat fw_500 fs_14 ps-2"
                              >
                                Summary
                              </label>
                              <Field name={"description"}>
                                {({ field }) => (
                                  <textarea
                                    id="description"
                                    {...field}
                                    type="text"
                                    className={
                                      "w-100 opportunities_create_summary_text_area p-2 "
                                    }
                                  ></textarea>
                                )}
                              </Field>
                              <ErrorMsg name={"description"} />
                            </div>
                          </div>
                        </div>
                      </Form>
                    )}
                  </Formik>
                </div>
              </LoadAndError>
            )}
          </InnerContainer>
        </div>
      </InnerLayout>
    </>
  );
};

export default TestimonialsEdit;

export const selectDesignation = [
  { value: "member", label: "Member" },
  { value: "distribution_partner", label: "Distribution Partner" },
  { value: "lease_partner", label: "Lease Partner" },
];
