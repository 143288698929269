// export const url = "https://upcide-api.firstfloor.site"
export const url = "https://api.upcide.com";
export const accessToken = localStorage.getItem("accessToken");
export const email = localStorage.getItem("email");
export const permissions =
  localStorage.getItem("permissions") &&
  JSON.parse(localStorage.getItem("permissions"));
export const ADMIN_DETAILS = {
  accessToken: accessToken,
  email: email ?? false,
};
export const authHeader = {
  headers: {
    "x-access-token": ADMIN_DETAILS.accessToken,
    "Content-Type": "application/json",
  },
};
export const sauthHeaderFile = {
  headers: {
    "x-access-token": ADMIN_DETAILS.accessToken,
    "Content-Type": "multipart/form-data",
  },
};
