import React, { useEffect, useRef, useState } from "react";
import DeleteBtn from "../../../../components/buttons/deleteBtn";
import SaveBtn from "../../../../components/buttons/saveBtn";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import InnerContainer from "../../../../components/layouts/innerContainer/innerContainer";
import InnerLayout from "../../../../components/layouts/innerLayout/innerLayout";
import "../memberTradeBuyIn.css";
import InputField from "../../../../components/form/inputFields/inputField";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import successToast from "../../../../components/utilits/successToast";
import errorToast from "../../../../components/utilits/errorToast";
import * as opporTypeActions from "../../../../redux/actions/opportunityTypeActions";
import DeleteModal from "../../../../components/modals/deleteModal";
import { useNavigate } from "react-router-dom";
import LoadingSpinnerTable from "../../../../components/utilits/loadingSpinnerTable";
import LoadAndError from "../../../../components/utilits/LoadAndError";
import SubmitBtn from "../../../../components/buttons/submit";
import {
  deleteMemberTradeBuyIn,
  editMemberTradeBuyIn,
  memberTradeBuyInSubmit,
  memberTradeBuyInSubmitAction,
  readApprovalMemberTradeBuyIn,
  readMemberTradeBuyIn,
} from "../../../../redux/actions/memberTradeBuyInAction";
import SelectFields from "../../../../components/form/selectFields/selectFields";
import ErrorMsg from "../../../../components/form/errorMsg";
import { notifyMeCreateOptions } from "../../../../redux/actions/notifyMeActions";
import { viewWishListFindAll } from "../../../../redux/actions/wishlistActions";
import AdminRoleCheckBox from "../../../../components/utilits/adminRoleCheckbox";
import { opportunitiesNameListAction } from "../../../../redux/actions/opportunitiesActions";

const MemberTradeBuyInEdit = () => {
  const [updated, setUpdated] = useState(false);
  const navigate = useNavigate();
  const [deleteData, setDeleteData] = useState({ name: "", id: "" });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [show, setShow] = useState(false);
  const [opporTypeData, setOpporTypeData] = useState({ name: "" });
  const [userName, setUserName] = useState([]);
  const [allUsers, setAllUsers] = useState([]);
  const [saved, setSaved] = useState(false);
  const [subLoading, setSubLoading] = useState(false);
  const params = useParams();
  const dispatch = useDispatch();
  const formRef = useRef();
  const [submitData, setSubmitData] = useState();

  //   const [wishlistData, setWishlistData] = useState([]);

  const handleShow = () => {
    setDeleteData({
      id: opporTypeData.id,
      // userId: opporTypeData.user_id,
      // opportunity: opporTypeData.opportunity_id,
      // amount: opporTypeData.amount,
      // identity: opporTypeData.disclosed_identity,
    });
    setShow(true);
  };
  const handleClose = () => setShow(false);

  useEffect(() => {
    dispatch(notifyMeCreateOptions(onNotifyMeSuccess, onNotifyMeError));
    dispatch(readMemberTradeBuyIn(params.id, onSuccess, onError));
  }, []);
  const userNameOptions = [];
  const onNotifyMeSuccess = (data) => {
    data?.data.AllUsers.map((item, index) => {
      userNameOptions[index] = { value: item.id, label: item.user_name };
    });
    console.log(userNameOptions);
    setUserName(userNameOptions);
  };
  const onNotifyMeError = (data) => {
    console.log("ERROR");
  };
 
  const initialValues = {
    userId: opporTypeData.user_id,
    opportunity: opporTypeData.opportunity_id,
    amount: opporTypeData.amount,
    disclosedIdentity: opporTypeData.disclosed_identity,
  };
  const loginSchema = Yup.object().shape({
    userId: Yup.string().required("Required"),
    opportunity: Yup.string().required("Required"),
    amount: Yup.string().required("Required"),
  });
  const handleSubmit = () => {
    if (formRef.current) {
      formRef.current.handleSubmit();
    }
  };
  const onSubmit = (values, { resetForm }) => {
    setLoading(true);
    setSaved(true);
    dispatch(
      editMemberTradeBuyIn(
        params.id,
        {
          user_id: values.userId,
          opportunity_id: values.opportunity,
          amount: values.amount,
          disclosed_identity: values.disclosedIdentity,
        },
        onEditSuccess,
        onEditError
      )
    );
    setUpdated(!updated);
  };

  const onEditSuccess = (data) => {
    successToast("Updated Successfully");
    setLoading(false);
    setOpporTypeData(data.data);
    setSubmitData(data.data);
    setSaved(true);
    setError(false);
    navigate(`/opportunity/memberTrade-buy-in`);
  };
  const onEditError = (data) => {
    setLoading(false);
    console.log(data);
    errorToast(data.message);
    setError(true);
  };
  //   console.log(submitData.id);
  const memberTradeBuyInSubmit = () => {
    setSubLoading(true);
    dispatch(
      memberTradeBuyInSubmitAction(
        submitData.id,
        onAdminSubmitSucess,
        onAdminSubmitError
      )
    );
  };

  
  const [opportunitiesList, setOpportunitiesList] = useState([])
    useEffect(() => {
        dispatch(opportunitiesNameListAction(onSuccessOpt, onErrorOpt));
    }, []);
    const onSuccessOpt = (data) => {
        const categoryList = [];
        data?.data.map((item, index) => {
            categoryList[index] = { value: item.id, label: `${item.title} id: ${item.id}` };
        });
        setOpportunitiesList(categoryList);
        console.log("SUCCESS");
    };
    const onErrorOpt = (data) => {
        console.log(data);
    };
  const buttonList = [
    <SaveBtn submitFn={handleSubmit} />,
    // <DeleteBtn showModal={handleShow} />,
    // <SubmitBtn
    //   loader={subLoading}
    //   saveHide={saved}
    //   submitFn={memberTradeBuyInSubmit}
    // />,
  ];
  function onAdminSubmitSucess(data) {
    successToast(data.message);
    setSubLoading(false);
    navigate("/opportunity/memberTrade-buy-in");
  }
  function onAdminSubmitError(data) {
    setSubLoading(false);
    errorToast(data.message);
  }
  useEffect(() => {
    setLoading(true);
    dispatch(readMemberTradeBuyIn(params.id, onSuccess, onError));
  }, [params.id, updated]);

  const onSuccess = (data) => {
    setOpporTypeData(data.data);
    // setWishlistData(data.data);
    setLoading(false);
    setError(false);
  };
  const onError = (data) => {
    errorToast(data.message);
    setLoading(false);
    setError(true);
  };
  const handleDelete = () => {
    setLoadingDelete(true);
    dispatch(deleteMemberTradeBuyIn(params.id, onDeleteSuccess, onDeleteError));
  };
  const onDeleteSuccess = (data) => {
    setLoadingDelete(false);
    successToast(data.data.data);
    setShow(false);
    setLoading(false);
    setError(false);
  };
  const onDeleteError = (data) => {
    errorToast(data.data.data);
    setLoadingDelete(false);
    setLoading(false);
    setError(true);
  };

  return (
    <InnerLayout buttons={buttonList}>
      <div className="opportunity-type-container">
        <InnerContainer>
          {loading ? (
            <LoadingSpinnerTable />
          ) : (
            <LoadAndError loader={loading} error={error}>
              <div className="pt-3  ps-4 ps-lg-4 ms-3">
                <h5 className="dark_grey_color fs_13 fn_Montserrat fw_500 m-0">
                  Member Trade Buy In ID
                </h5>
                <p
                  className={` fs_13 dark_title_color fn_Nunito fw_500  secondary_color `}
                >
                  {opporTypeData.id}
                </p>
                <Formik
                  enableReinitialize={true}
                  innerRef={formRef}
                  initialValues={initialValues}
                  onSubmit={onSubmit}
                  validationSchema={loginSchema}
                >
                  <Form className="opportunity-type-form-width">
                    <SelectFields
                      default1={{
                        label: opporTypeData?.user?.user_name,
                        value: opporTypeData?.user_id,
                      }}
                      label={"User Name"}
                      placeholder={"select the user name"}
                      name={"userId"}
                      options={userName}
                      id={"userId"}
                    />
                    <SelectFields
                      default1={{
                        label: opporTypeData?.opportunity_id,
                        value: opporTypeData?.opportunity_id,
                      }}
                      label={"Opportunity Id"}
                      placeholder={"Opportunity here"}
                      name={"opportunity"}
                      options={opportunitiesList}
                      id={"opportunity"}
                    />
                    {/* <label className="mb-2 ps-2 dark_grey_color fn_Montserrat fw_500 fs_14">
                  Disclose Identity
                </label>
                <div className="d-flex gap-5 mb-1">
                  <div className="d-flex align-items-center ">
                    <Field
                    default1={{
                        label: opporTypeData?.disclosed_identity
                        ,
                        value: opporTypeData?.disclosed_identity
                        ,
                      }}
                      className="radio mx-2"
                      type="radio"
                      id="identityTrue"
                      name="identity"
                      value="true"
                      //   onClick={() => {
                      //     setActive("city");
                      //   }}
                    />
                    <label
                      for="identityTrue"
                      //   className={
                      //     active === "city" ? "color_262c3d" : "color_747f9e"
                      //   }
                    >
                      True
                    </label>
                  </div>
                  <div className="d-flex align-items-center ">
                    <Field
                      className="radio mx-2"
                      type="radio"
                      id="identityFalse"
                      name="identity"
                      value="false"
                      //   onClick={() => {
                      //     setActive("city");
                      //   }}
                    />
                    <label
                      for="identityFalse"
                      //   className={
                      //     active === "city" ? "color_262c3d" : "color_747f9e"
                      //   }
                    >
                      False
                    </label>
                  </div>
                </div>
                <ErrorMsg
                  containerClassName="mb-1 justify-content-end justify-content-lg-start"
                  name="identity"
                /> */}
                    <InputField
                      id={"amount"}
                      label={"Amount"}
                      type={"text"}
                      placeholder={"Enter amount"}
                      name={"amount"}
                    />
                    <div>
                      <label
                        htmlFor="disclosedIdentity"
                        className="mb-2 dark_grey_color fn_Montserrat fw_500 fs_14"
                      >
                        Is Disclosed Identity
                      </label>
                      <AdminRoleCheckBox
                        name="disclosedIdentity"
                        id="disclosedIdentity"
                      />
                    </div>
                  </Form>
                </Formik>
              </div>
            </LoadAndError>
          )}
        </InnerContainer>
      </div>
      <DeleteModal
        loader={loadingDelete}
        handleClose={handleClose}
        deleteFn={handleDelete}
        data={deleteData}
        show={show}
      />
    </InnerLayout>
  );
};

export default MemberTradeBuyInEdit;
