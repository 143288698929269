import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import HowItWorksReasonTable from "../../../../components/howItWorks/howItWorks/tables/howItWorksReasonTable";
import DownloadIcon from "../../../../assets/icons/downloadIcon";
import InnerContainer from "../../../../components/layouts/innerContainer/innerContainer";
import InnerLayout from "../../../../components/layouts/innerLayout/innerLayout";
import LoadAndError from "../../../../components/utilits/LoadAndError";
import VersionHistoryBtn from "../../../../components/buttons/versionHistoryBtn";
import EditBtn from "../../../../components/buttons/editBtn";
import { viewInsightsAction } from "../../../../redux/actions/insightsAction";
import ShareableLink from "../../../../components/utilits/shareableLink";

const InsightsView = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [insightsData, setInsightsData] = useState({});
  const dispatch = useDispatch();
  const params = useParams();
  useEffect(() => {
    setLoading(true);
    dispatch(viewInsightsAction(params.id, onSuccess, onError));
  }, []);
  const onSuccess = (data) => {
    console.log(data.data);
    setInsightsData(data.data);
    console.log("SUCCESS");
    setLoading(false);
    setError(false);
  };
  const onError = (data) => {
    console.log("ERROR");
    setLoading(false);
    setError(true);
  };
  const buttonList = [
    <VersionHistoryBtn link={`/static-pages/insights/version-history`} />,
    <EditBtn link={`/static-pages/insights/edit/${params.id}`} />,
    // <DeleteBtn showModal={deletenotifyMebyid} />,
  ];

  function ViewItemGenerator(title, details) {
    return (
      <>
        <span className="dark_grey_color">{title}</span>
        <p className="dark_title_color">{details}</p>
      </>
    );
  }

  return (
    <InnerLayout buttons={buttonList}>
      <div className="user_view_container mb-4">
        <InnerContainer>
          <LoadAndError loader={loading} error={error}>
            <div className="d-flex flex-column flex-lg-row gap-4 fs_14 py-3 px-3 px-lg-5">
              <div className="col-12 col-lg-6 pe-0 pe-lg-5">
                <div>
                  <span className="dark_grey_color">{"Active"}</span>
                  <p
                    className={` ${
                      insightsData?.is_active
                        ? "text-success"
                        : "dark_title_color"
                    }`}
                  >
                    {insightsData?.is_active ? "True" : "False"}
                  </p>
                </div>
                <div>
                  {ViewItemGenerator(
                    "Header Title",
                    insightsData?.header_title
                  )}
                </div>
                <div>
                  {ViewItemGenerator(
                    "Insights Heading",
                    insightsData?.section1_heading
                  )}
                </div>
                <div>
                  {ViewItemGenerator(
                    "Insights Title",
                    insightsData?.section1_title
                  )}
                </div>
                <div>
                  {ViewItemGenerator(
                    "Video Title",
                    insightsData?.section3_heading
                  )}
                </div>
                <div>
                  {ViewItemGenerator("Video URL", insightsData?.video_url)}
                </div>
                <div>
                  {ViewItemGenerator(
                    "Video description",
                    insightsData?.section3_title
                  )}
                </div>
                <div>
                  {ViewItemGenerator(
                    "Insights Category Title",
                    insightsData?.section2_heading
                  )}
                </div>
                <div>
                  {ViewItemGenerator(
                    "Insights Category Subtitle",
                    insightsData?.section2_title
                  )}
                </div>
                <div>
                  {ViewItemGenerator(
                    "Opportunities Heading",
                    insightsData?.section4_heading
                  )}
                </div>
                <div>
                  {ViewItemGenerator(
                    "Opportunities Title",
                    insightsData?.section4_title
                  )}
                </div>
              </div>
              <div className="col-12 col-lg-6  pe-0 pe-lg-5">
                <ShareableLink
                  conatinerClassName="mb-3"
                  title="Insights"
                  link={"/insights/insights2"}
                />
                <HowItWorksReasonTable
                  name="reasons"
                  formikValues={insightsData}
                  editable={false}
                />
                <div className="mt-5 mt-lg-4 ">
                  <span className="">Thumb Nail Image</span>{" "}
                  <div className="blog_create_image_container mt-3 position-relative d-flex  justify-content-center align-items-center">
                    <img
                      src={insightsData?.video_tumbnail_image}
                      className={"user-edit-profile-picture"}
                    />
                    <button
                      type="button"
                      className="position-absolute border-0 bg-white d-flex align-items-center fs_10 top-0 end-0"
                    >
                      <a
                        href={insightsData?.video_tumbnail_image}
                        download="file"
                        // download={true}
                        target={"_blank"}
                        className="text-decoration-none text-dark"
                      >
                        <DownloadIcon width={8} />
                        <span className="ms-1">Download</span>
                      </a>
                    </button>
                  </div>
                </div>
                <div>
                  {ViewItemGenerator(
                    "Approved At",
                    insightsData?.approvedAt ? insightsData?.approvedAt : "-"
                  )}
                </div>
                <div>
                  {ViewItemGenerator(
                    "Updated At",
                    moment(insightsData?.updatedAt).format(
                      "DD-MM-YYYY HH:mm:ss"
                    )
                  )}
                </div>
              </div>
            </div>
          </LoadAndError>
        </InnerContainer>
      </div>
      {/* <DeleteModal
        loader={loadingDelete}
        handleClose={handleClose}
        deleteFn={handleDelete}
        data={deleteData}
        show={show}
      /> */}
    </InnerLayout>
  );
};

export default InsightsView;
