import React, { useState } from "react";
import { useDispatch } from "react-redux";
import ApprovalsBtn from "../../../../components/buttons/approvalsBtn";
import CreateBtn from "../../../../components/buttons/createBtn";
import ExportBtn from "../../../../components/buttons/exportBtn";
import FilterBtn from "../../../../components/buttons/filterBtn";
import VersionHistoryBtn from "../../../../components/buttons/versionHistoryBtn";
import AdminUserFilter from "../../../../components/filter/adminUserFilter";
import MemberTradeBuyInFilter from "../../../../components/filter/versionTableFilter";
import InnerLayout from "../../../../components/layouts/innerLayout/innerLayout";
import MemberTradeBuyInApprovalsTableContent from "../../../../components/opportunity/memberTradeBuyIn/tables/memberTradeBuyInApprovalsTableContent";
import OpportunityTypeApprovalsTableContent from "../../../../components/opportunity/opportunityType/tables/opportunityTypeApprovalsTableContent";
import { memberTradeBuyInApprovalsExport } from "../../../../redux/actions/memberTradeBuyInAction";
import { opprTypeApprovalsExport } from "../../../../redux/actions/opportunityTypeActions";

const MemberTradeBuyInApprovalsTable = () => {
  const [page, setPage] = useState(0);
  const [showFilter, setShowFilter] = useState(false);
  const [search, setSearch] = useState({ value: "" });
  const dispatch = useDispatch();
  const handleFilterClose = () => {
    setShowFilter(false);
  };
  const handleFilterOpen = () => {
    setShowFilter(true);
  };
  const handleSearch = (value) => {
    setSearch({ value: value });
    setPage(0);
  };
  const handlePage = (type) => {
    if (type === "+") {
      setPage((prev) => prev + 1);
    } else {
      setPage((prev) => prev - 1);
    }
  };
  const buttonList = [
    <VersionHistoryBtn
      link={`/opportunity/memberTrade-buy-in/version-history`}
    />,
    <CreateBtn link={`/opportunity/memberTrade-buy-in/create`} />,
    <ExportBtn dispatchAction={memberTradeBuyInApprovalsExport} />,
    <FilterBtn filterFn={handleFilterOpen} />,
  ];
  return (
    <InnerLayout buttons={buttonList}>
      <MemberTradeBuyInFilter
            title='Buy In Id'
            placeholder='Enter Buy In Id'
        filter={handleSearch}
        showFilter={showFilter}
        closeFilter={handleFilterClose}
      />

      <MemberTradeBuyInApprovalsTableContent
        page={page}
        changePage={handlePage}
        search={search}
      />
    </InnerLayout>
  );
};

export default MemberTradeBuyInApprovalsTable;
