import React, { useState } from "react";
import ApprovalsBtn from "../../../../components/buttons/approvalsBtn";
import CreateBtn from "../../../../components/buttons/createBtn";
import ExportBtn from "../../../../components/buttons/exportBtn";
import FilterBtn from "../../../../components/buttons/filterBtn";
import VersionHistoryBtn from "../../../../components/buttons/versionHistoryBtn";
import InnerLayout from "../../../../components/layouts/innerLayout/innerLayout";
import BlogCategoryTableContent from "../../../../components/static-pages/blogCategory/tables/blogCategoryTableContent";
import BlogCategoryFilter from "../../../../components/filter/blog-pagesFilter.js/blogCategoryFilter";
import { blogCategoryMainExportAction } from "../../../../redux/actions/blogCategoryActions";
const BlogCategoryTable = () => {
  const [showFilter, setShowFilter] = useState(false);
  const [page, setPage] = useState(0);

  const [search, setSearch] = useState("");
  const handleFilterClose = () => {
    setShowFilter(false);
  };
  const handleFilterOpen = () => {
    setShowFilter(true);
  };
  const handleSearch = (value) => {
    setSearch(value);
  };
  const handlePage = (type) => {
    if (type === "+") {
      setPage((prev) => prev + 1);
    } else {
      setPage((prev) => prev - 1);
    }
  };
  const buttonList = [
    <ApprovalsBtn link="/blog-category/approval" />,
    <VersionHistoryBtn link="/blog-category/version-history" />,
    <CreateBtn link="/blog-category/create" />,
    <ExportBtn dispatchAction={blogCategoryMainExportAction} />,
    <FilterBtn filterFn={handleFilterOpen} />,
  ];
  return (
    <InnerLayout buttons={buttonList}>
      <BlogCategoryFilter
        filter={handleSearch}
        showFilter={showFilter}
        closeFilter={handleFilterClose}
      />

      <BlogCategoryTableContent page={page} changePage={handlePage} search={search} />
    </InnerLayout>
  );
};

export default BlogCategoryTable;
