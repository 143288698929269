import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import InnerLayout from "../../../../components/layouts/innerLayout/innerLayout";
import InnerContainer from "../../../../components/layouts/innerContainer/innerContainer";
import VersionHistoryBtn from "../../../../components/buttons/versionHistoryBtn";
import RejectBtn from "../../../../components/buttons/rejectBtn";
import ApproveBtn from "../../../../components/buttons/approveBtn";
import errorToast from "../../../../components/utilits/errorToast";
import successToast from "../../../../components/utilits/successToast";
import LoadAndError from "../../../../components/utilits/LoadAndError";
import {
  approvalsViewInsightsAction,
  approveInsightsAction,
  rejectInsightsAction,
} from "../../../../redux/actions/insightsAction";

const UserReferralApprovalDetails = () => {
  const navigate = useNavigate();
  const [insightsData, setInsightsData] = useState({});
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const params = useParams();
  useEffect(() => {
    setLoading(true);
    dispatch(approvalsViewInsightsAction(params.id, onSuccess, onError));
  }, [params.id]);
  const onSuccess = (data) => {
    setInsightsData(data?.data?.new_data);
    console.log(data.data);
    setLoading(false);
    setError(false);
  };
  const onError = (data) => {
    errorToast(data.message);
    console.log(data, "error");
    setLoading(false);
    setError(true);
  };

  // Approve Notify Me
  const [updated, setUpdated] = useState(false);

  const approveUserReferralFn = () => {
    dispatch(
      approveInsightsAction(params.id, onApproveSuccess, onApproveError)
    );
  };

  const onApproveSuccess = (data) => {
    navigate("/static-pages/userReferral/approvals");
    successToast(data.message);
    setLoading(false);
    setError(false);
  };
  const onApproveError = (data) => {
    console.log(data);
    errorToast(data.data + " " + data.message);
    setLoading(false);
    setError(true);
  };
  // Reject  Notify Me Api
  const rejectUserReferralFn = () => {
    dispatch(rejectInsightsAction(params.id, onRejectSuccess, onRejectError));
  };

  const onRejectSuccess = (data) => {
    successToast(data.message);
    navigate("/static-pages/userReferral/approvals");
    setLoading(false);
    setError(false);
  };
  const onRejectError = (data) => {
    console.log(data);
    errorToast(data.data + " " + data.message);
    setLoading(false);
    setError(true);
  };

  function ViewItemGenerator(title, details) {
    return (
      <>
        <span className="dark_grey_color">{title}</span>
        <p className="dark_title_color">{details}</p>
      </>
    );
  }

  const buttonList = [
    <VersionHistoryBtn link={`/static-pages/userReferral/version-history`} />,
    <ApproveBtn approveFn={approveUserReferralFn} />,
    <RejectBtn showModal={rejectUserReferralFn} />,
  ];
  return (
    <InnerLayout buttons={buttonList}>
      <div className="user_view_container mb-4">
        <InnerContainer>
          <LoadAndError loader={loading} error={error}>
            <div className="d-flex flex-column flex-lg-row gap-4 fs_14 py-3 px-3 px-lg-5">
              <div className="col-12 col-lg-6 pe-0 pe-lg-5">
                <div>{ViewItemGenerator("User Id", insightsData?.user_id)}</div>
                <div>
                  {ViewItemGenerator(
                    "Opportunity Id",
                    insightsData?.opportunity_id
                  )}
                </div>
              </div>
            </div>
          </LoadAndError>
        </InnerContainer>
      </div>
    </InnerLayout>
  );
};

export default UserReferralApprovalDetails;
