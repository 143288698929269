import React from "react";
import InnerLayout from "../../../../components/layouts/innerLayout/innerLayout";
import InnerContainer from "../../../../components/layouts/innerContainer/innerContainer";
import { useNavigate, useParams } from "react-router-dom";
import VersionHistoryBtn from "../../../../components/buttons/versionHistoryBtn";
import EditBtn from "../../../../components/buttons/editBtn";
import DeleteBtn from "../../../../components/buttons/deleteBtn";
import FaqCategoryViewDetails from "../../../../components/static-pages/faqCategory/faqCategoryViewDetails";
import BlogCategoryViewDetails from "../../../../components/static-pages/blogCategory/blogCategoryViewDetails";
import BlogViewDetails from "../../../../components/static-pages/blog/blogViewDetails";

const BlogView = () => {
  const params = useParams();
  
  const buttonList = [
    <VersionHistoryBtn link="/blog/version-history" />,

    <EditBtn link={`/blog/edit/${params.id}`} />

  ];
  return (
    <InnerLayout buttons={buttonList}>
      <div className="user_view_container">
        <InnerContainer>
          <BlogViewDetails id={params.id} />
        </InnerContainer>
      </div>
    </InnerLayout>
  );
};

export default BlogView;
