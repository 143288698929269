import { authHeader } from "../config";
import { api, qs } from "./api";

export const addAdminApi = (params) => {
  return api.post("/api/admins/add-admin", JSON.stringify(params), {
    headers: {
      "x-access-token": localStorage.getItem("accessToken"),
      "Content-Type": "application/json",
    },
  });
};

export const approveAdminCheckerApi = (params) => {
  return api.post("/api/admins/approve-admin", JSON.stringify(params), {
    headers: {
      "x-access-token": localStorage.getItem("accessToken"),
      "Content-Type": "application/json",
    },
  });
};
export const rejectAdminApi = (params) => {
  return api.post(
    `/api/admins/reject-admin/${params.adminId}`,
    JSON.stringify(params),
    {
      headers: {
        "x-access-token": localStorage.getItem("accessToken"),
        "Content-Type": "application/json",
      },
    }
  );
};
export const viewAdminApi = (params) => {
  return api.post(
    `/api/admins/view-admin/${params.adminId}`,
    JSON.stringify(params),
    {
      headers: {
        "x-access-token": localStorage.getItem("accessToken"),
        "Content-Type": "application/json",
      },
    }
  );
};
export const viewAdminListApi = (page_no = 0, params) => {
  return api.post(`/api/admins/view-admins?page_no=${page_no}`, params, {
    headers: {
      "x-access-token": localStorage.getItem("accessToken"),
      "Content-Type": "application/json",
    },
  });
};

// /api/admins/view-admins?search=&page_no=0

export const editAdminApi = (id, params) => {
  return api.post(
    `/api/admins/edit-admin/${id}`,
    JSON.stringify(params),
    {
      headers: {
        "x-access-token": localStorage.getItem("accessToken"),
        "Content-Type": "application/json",
      },
    }
  );
};

export const deleteAdminApi = (params) => {
  return api.post(
    `/api/admins/delete-admin/${params.adminId}`,
    JSON.stringify(params),
    {
      headers: {
        "x-access-token": localStorage.getItem("accessToken"),
        "Content-Type": "application/json",
      },
    }
  );
};

export const editAdminCheckerApi = (id, params) => {
  return api.post(`/api/admins/edit-admin/${id}`, JSON.stringify(params), {
    headers: { "Content-Type": "application/json" },
  });
};

export const adminApprovalListApi = (page_no, params) => {
  return api.post(
    `/api/admins/admin-approvals?page_no=${page_no}`,
    JSON.stringify(params),
    {
      headers: {
        "x-access-token": localStorage.getItem("accessToken"),
        "Content-Type": "application/json",
      },
    }
  );
};

export const roleTypeDataApi = (params) => {
  return api.post(`/api/admins/find-role-names`, JSON.stringify(params), {
    headers: {
      "x-access-token": localStorage.getItem("accessToken"),
      "Content-Type": "application/json",
    },
  });
};

export const viewAdminApprovalApi = (params) => {
  return api.post(
    `/api/admins/view-approvals-id/${params.adminId}`,
    JSON.stringify(params),
    {
      headers: {
        "x-access-token": localStorage.getItem("accessToken"),
        "Content-Type": "application/json",
      },
    }
  );
};

export const versionHistoryListApi = (pageNo, params) => {
  return api.post(
    `/api/admins/view-version-history_admin?page_no=${pageNo}`,
    JSON.stringify(params),
    {
      headers: {
        "x-access-token": localStorage.getItem("accessToken"),
        "Content-Type": "application/json",
      },
    }
  );
};

export const versionHistoryAdminDetailApi = (id, params) => {
  return api.post(`/api/admins/version-admin/${id}`, JSON.stringify(params), {
    headers: {
      "x-access-token": localStorage.getItem("accessToken"),
      "Content-Type": "application/json",
    },
  });
};

export const adminTableExportApi = (params) => {
  return api.post(
    `/api/admins/export-main-admin`,
    JSON.stringify(params),
    {
      headers: {
        "x-access-token": localStorage.getItem("accessToken"),
        "Content-Type": "application/json",
      },
    }
  );
};
export const adminApprovalsExportApi = (params) => {
  return api.post(
    `/api/admins/export-approval-admin`,
    JSON.stringify(params),
    {
      headers: {
        "x-access-token": localStorage.getItem("accessToken"),
        "Content-Type": "application/json",
      },
    }
  );
};
export const adminVersionExportApi = (params) => {
  return api.post(
    `/api/admins/export-version-admin`,
    JSON.stringify(params),
    {
      headers: {
        "x-access-token": localStorage.getItem("accessToken"),
        "Content-Type": "application/json",
      },
    }
  );
};
export const adminSubmitApi = (id, params) => {
  return api.post(
    `/api/admins/on-submit/${id}`, JSON.stringify(params),
    {
      headers: {
        "x-access-token": localStorage.getItem("accessToken"),
        "Content-Type": "application/json",
      },
    }
  );
};


