import React from "react";
import timerIcon from "../../../../../assets/icons/opportunities/timer.svg";
import rupeeIcon from "../../../../../assets/icons/opportunities/rupee-icon.svg";
import monthlyIcon from "../../../../../assets/icons/opportunities/monthly-card-icon.svg";
import handIcon from "../../../../../assets/icons/opportunities/handleIcon.svg";
import styles from "../opportunitiesread.module.css";

const CardFrontTopSection = (props) => {
    const cardListItem = [
        {
          icon: monthlyIcon,
          title: "PAYOUT",
          value: `${props.data?.payout_duration}`
          ,
        },
        {
          icon: rupeeIcon,
          title: "IRR",
          value: `${props.data?.post_tax_irr}%`,
        },
        {
          icon: handIcon,
          title: "INVESTMENT",
          value: `₹${props.data?.payout_duration}`
          ,
        },
        {
          icon: timerIcon,
          title: "TENURE",
          value: 
        //   ${props.data?.tenure} 
          `XX Months`,
        },
      ];
  return (
    <div
      onClick={props.handleOnHover}
      style={{ gap: ".8rem" }}
      className={`px-3 px-sm-4 pt-2 d-flex flex-column align-items-center justify-content-center `}
    >
      <div className={`d-flex gap-2  w-100`}>
        {cardListItem.map((item, index) => {
          if (index < 2) {
            return (
              <div
                key={item.title}
                className={`w-50 ${styles.myhold_card_item_container} py-1`}
              >
                <div className={`d-flex align-items-center`}>
                  <div
                    className={`${styles.myhold_card_icon_monthly_container} mx-2`}
                  >
                    <img
                      src={item.icon}
                      alt="Payout monthly icon"
                      layout="fill"
                      objectFit="cover"
                      className={`${"a"}`}
                    />
                  </div>

                  <div className={`d-flex flex-column fontFam_montserrat mt-2`}>
                    <span
                      className={`lh-1 fs_10 black_color fw_500 text-nowrap ${styles.myhold_card_fs_9}`}
                    >
                      {item.title}
                    </span>
                    <span
                      className={`secondary_color fs_14 fw_500 text-nowrap `}
                    >
                      {item.value}
                    </span>
                  </div>
                </div>
              </div>
            );
          }
        })}
      </div>
      <div className={`d-flex gap-2  w-100`}>
        {cardListItem.map((item, index) => {
          if (index > 1) {
            return (
              <div
                key={item.title}
                className={`w-50 ${styles.myhold_card_item_container} py-1`}
              >
                <div className={`d-flex align-items-center`}>
                  <div
                    className={`${styles.myhold_card_icon_monthly_container} mx-2`}
                  >
                    <img
                      src={item.icon}
                      alt="Payout monthly icon"
                      layout="fill"
                      objectFit="cover"
                      className={`${"a"}`}
                    />
                  </div>

                  <div className={`d-flex flex-column fontFam_montserrat mt-2`}>
                    <span
                      className={`lh-1 fs_10 black_color fw_500 text-nowrap ${styles.myhold_card_fs_9}`}
                    >
                      {item.title}
                    </span>
                    <span
                      className={`secondary_color fs_14 fw_500 text-nowrap `}
                    >
                      {item.value}
                    </span>
                  </div>
                </div>
              </div>
            );
          }
        })}
      </div>
    </div>
  )
}

CardFrontTopSection.defaultProps = {
    payout: "Monthly",
    irr: "XX.X%",
    investment: "₹ XX,XX,XXX",
    tenure: "10 Months",
    data:{}
  };

export default CardFrontTopSection