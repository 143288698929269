import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import LoadingSpinnerTable from "../components/utilits/loadingSpinnerTable";

function HomePage() {
  const navigate = useNavigate();
  useEffect(() => {
    navigate("/user/user-list");
  }, []);

  return (
    <LoadingSpinnerTable containerClassName="position-absolute top-50 end-50 translate-middle" />
  );
}

export default HomePage;
