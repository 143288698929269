import {
  COMMUNITY_BADGES_APPROVALS_EXPORT,
  COMMUNITY_BADGES_APPROVALS_LIST,
  COMMUNITY_BADGES_APPROVALS_VIEW,
  COMMUNITY_BADGES_APPROVE,
  COMMUNITY_BADGES_CREATE,
  COMMUNITY_BADGES_DELETE,
  COMMUNITY_BADGES_EDIT,
  COMMUNITY_BADGES_EXPORT,
  COMMUNITY_BADGES_LIST,
  COMMUNITY_BADGES_READ,
  COMMUNITY_BADGES_REJECT,
  COMMUNITY_BADGES_SUBMIT,
  COMMUNITY_BADGES_VERSION_EXPORT,
  COMMUNITY_BADGES_VERSION_LIST,
  COMMUNITY_BADGES_VERSION_VIEW,
} from "./types";

export const createCommunityBadgesAction = (data, onSuccess, onError) => {
  return {
    type: COMMUNITY_BADGES_CREATE,
    data,
    onSuccess,
    onError,
  };
};
export const viewAllCommunityBadgesAction = (
  pageNo,
  search,
  onSuccess,
  onError
) => {
  return {
    type: COMMUNITY_BADGES_LIST,
    pageNo,
    search,
    onSuccess,
    onError,
  };
};

export const viewCommunityBadgesAction = (id, onSuccess, onError) => {
  return {
    type: COMMUNITY_BADGES_READ,
    id,
    onSuccess,
    onError,
  };
};
export const editCommunityBadgesAction = (id, data, onSuccess, onError) => {
  return {
    type: COMMUNITY_BADGES_EDIT,
    id,
    data,
    onSuccess,
    onError,
  };
};
export const approvalsCommunityBadgesActionList = (
  data,
  pageNo,
  onSuccess,
  onError
) => {
  return {
    type: COMMUNITY_BADGES_APPROVALS_LIST,
    data,
    pageNo,
    onSuccess,
    onError,
  };
};
export const viewApprovalCommunityBadgesAction = (id, onSuccess, onError) => {
  return {
    type: COMMUNITY_BADGES_APPROVALS_VIEW,
    id,
    onSuccess,
    onError,
  };
};
export const approveCommunityBadgesAction = (data, onSuccess, onError) => {
  return {
    type: COMMUNITY_BADGES_APPROVE,
    data,
    onSuccess,
    onError,
  };
};
export const rejectCommunityBadgesAction = (id, onSuccess, onError) => {
  return {
    type: COMMUNITY_BADGES_REJECT,
    id,
    onSuccess,
    onError,
  };
};
export const deleteCommunityBadgesAction = (id, onSuccess, onError) => {
  return {
    type: COMMUNITY_BADGES_DELETE,
    id,
    onSuccess,
    onError,
  };
};
export const versionHistoryListCommunityBadgesAction = (
  data,
  pageNo,
  onSuccess,
  onError
) => {
  return {
    type: COMMUNITY_BADGES_VERSION_LIST,
    data,
    pageNo,
    onSuccess,
    onError,
  };
};
export const viewVersionHistoryCommunityBadgesAction = (
  id,
  onSuccess,
  onError
) => {
  return {
    type: COMMUNITY_BADGES_VERSION_VIEW,
    id,
    onSuccess,
    onError,
  };
};

export const communityBadgesTableExport = (onSuccess, onError) => {
  return {
    type: COMMUNITY_BADGES_EXPORT,
    onSuccess,
    onError,
  };
};
export const communityBadgesApprovalsExport = (onSuccess, onError) => {
  return {
    type: COMMUNITY_BADGES_APPROVALS_EXPORT,
    onSuccess,
    onError,
  };
};
export const communityBadgesVersionExport = (onSuccess, onError) => {
  return {
    type: COMMUNITY_BADGES_VERSION_EXPORT,
    onSuccess,
    onError,
  };
};

export const communityBadgesSubmitAction = (id, onSuccess, onError) => {
  return {
    type: COMMUNITY_BADGES_SUBMIT,
    id,
    onSuccess,
    onError,
  };
};
