import React, { useState } from "react";
import DownloadIcon from "../../../assets/icons/downloadIcon";
import "../../../modules/user/user-view.css";
import RejectBtn from "../../buttons/rejectBtn";
import { saveAs } from "file-saver";
import RejectPanDescription from "../../modals/rejectPanDescription";
import ApproveBtn from "../../buttons/approveBtn";
import BankImageModal from "../modals/bankAccountImageModal";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import successToast from "../../utilits/successToast";
import errorToast from "../../utilits/errorToast";
import RejectbankBtn from "../../buttons/bankReject";
import { access } from "../../utilits/access";
import { rejectedApproveBankUserAction } from "../../../redux/actions/userAction";
import usePermissionChecker from "../../utilits/usePermissionChecker";
import { Document, Page } from "react-pdf";
import ViewImageModal from "../modals/viewImageModal";
import DummyImage from "../../../assets/images/defaultImage.png";
const Bank = ({ individualData, value, label }) => {
  const access = usePermissionChecker("checker")

  const bankAccountDetails = [
    {
      heading: "Bank Account Number",
      title: individualData?.bank_account_details?.account_number,
    },
    {
      heading: "Bank Account Holder Name",
      title: individualData?.bank_account_details?.bank_holder_name,
    },
    {
      heading: "Account Type",
      title: individualData?.bank_account_details?.account_type,
    },
    {
      heading: "Verified",
      title:
        individualData?.bank_account_details?.verified === true
          ? "Verified"
          : "Not Verified",
    },
    {
      heading: "Rejected",
      title:
        individualData?.bank_account_details?.rejected === true
          ? "Rejected"
          : "Not Rejected",
    },
    {
      heading: "Bank Name",
      title: individualData?.bank_account_details?.bank_name,
    },
    {
      heading: "Bank IFSC",
      title: individualData?.bank_account_details?.ifsc_code,
    },
    {
      heading: "Proof Type",
      title:
        individualData?.bank_account_details?.document_type === null
          ? "-"
          : individualData?.bank_account_details?.document_type,
    },
  ];

  const [show, setShow] = useState(false);
  const [bankApproved, setBankApproved] = useState(false);
  const [bankRejeceted, setBankRejeceted] = useState(false);
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);
  const [imageShow, setImageShow] = useState(false);
  const handleImageShow = () => setImageShow(true);
  const handleImageClose = () => setImageShow(false);
  const downloadImage = () => {
    saveAs(
      "https://upload.wikimedia.org/wikipedia/commons/thumb/c/c8/Julia_2022-10-09_0710Z.jpg/183px-Julia_2022-10-09_0710Z.jpg"
    ); // Put your image url here.
  };
  const [loadingApproved, setLoadingApproved] = useState(false);
  const [loadingRejected, setLoadingRejected] = useState(false);
  const [button, setButton] = useState(true);
  const [adressApprovalViewData, SetAdressMeApprovalView] = useState({
    id: "",
  });
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const params = useParams();
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    setPageNumber(1);
  }
  const handlebankaproved = () => {
    setLoadingApproved(true);
    dispatch(
      rejectedApproveBankUserAction(
        {
          id: individualData?.id,
          kyc_status: "verified",
        },
        onBankApproveSuccess,
        onBankrejectedError
      )
    );
  };

  const onBankApproveSuccess = (data) => {
    successToast(data.message);
    setLoadingApproved(false);
    // setShow(false);
    setBankApproved(true)

    console.log("address aproved");
  };
  const onBankrejectedError = (data) => {
    errorToast(data.message);
    setLoadingApproved(false);
  };


  return (
    <>
      <h3 className="dark_grey_color fn_Montserrat ps-4 ps-lg-5 mt-3 py-2 fw_500 white_shade_bg fs_13 ">
        Bank Account Details
      </h3>
      <div className="row mt-3 ps-1 ms-1 ps-lg-4 ms-lg-1">
        <div className="col-lg-12 col-xl-9">
          <div className="row">
            {bankAccountDetails.map((item, index) => (
              <div className="address_pan_bank_data_width mt-2">
                <h5 className="dark_grey_color fs_13 fn_Montserrat fw_500">
                  {item.heading}
                </h5>
                <p className={` fs_13 fn_Nunito fw_500 'dark_title_color'}`}>
                  {item.title}
                </p>
              </div>
            ))}
          </div>
          <div className="d-flex gap-4 gap-lg-5 flex-column flex-lg-row mt-0 mb-4">
            <div>
              <h5 className="dark_grey_color text-nowrap fs_13 fn_Montserrat fw_500">
                Front Image
              </h5>

              <div className="personal_information_image_container mt-2 position-relative">
                {individualData?.bank_account_details?.image_location?.endsWith(".pdf") ? (
                  <div
                    onClick={handleImageShow}
                    className={`w-100 h-100 overflow-hidden`}
                  >
                    <Document
                      file={individualData?.bank_account_details?.image_location}
                      onLoadSuccess={onDocumentLoadSuccess}
                    >
                      {Array.from({ length: numPages }, (_, index) => (
                        <Page
                          key={`page_${index + 1}`}
                          pageNumber={index + 1}
                          renderAnnotationLayer={false}
                          renderTextLayer={false}
                        />
                      ))}
                    </Document>
                  </div>
                ) : (<img
                  onClick={handleImageShow}
                  src={individualData?.bank_account_details?.image_location ?? DummyImage}
                  className="personal_information_img_size rounded-2"
                />)}
                {/* <a
                  href={`${individualData?.bank_account_details?.image_location}`}
                  download="file"
                >
                  <button
                    type="button"
                    className="position-absolute border-0 bg-white d-flex align-items-center fs_10 top-0 end-0"
                  >
                    <DownloadIcon width={8} />
                    <span className="ms-1">Download</span>
                  </button>
                </a> */}
              </div>
            </div>
          </div>
        </div>

        <div className="col-xl-3 col-lg-12">
          {individualData?.bank_account_details?.status === "verified" || bankApproved ?
            <span className="">Approved</span> : individualData?.bank_account_details?.status === "failed" || bankRejeceted ? <span className="">Rejected</span> :
              <div className="d-flex  gap-5 gap-lg-3 mb-3">
                {
                  access === true && <>
                    <ApproveBtn approveFn={handlebankaproved} />
                    <RejectBtn showModal={handleShow} /></>
                }

              </div>}
        </div>
      </div>
      <RejectPanDescription
        popup="bank"
        handleClose={handleClose}
        show={show}
        value={value}
        label={label}
        data={individualData}
      // submit={handleBankrejected}
      />
      {/* <BankImageModal
        handleClose={handleImageClose}
        show={imageShow}
        bank={individualData?.bank_account_details}
        image="https://pmmodiyojana.in/wp-content/uploads/2022/08/image-170-1024x683.png"
      /> */}

      <ViewImageModal
        popuHeading="BANK PROF IMAGE"
        handleClose={handleImageClose}
        show={imageShow}
        Profileimage={individualData?.bank_account_details?.image_location}
      />
    </>
  );
};

export default Bank;
