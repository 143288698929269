import React, { useState } from "react";
import DownloadIcon from "../../../assets/icons/downloadIcon";
import "../../../modules/user/user-view.css";
import RejectBtn from "../../buttons/rejectBtn";
import { saveAs } from "file-saver";
import RejectPanDescription from "../../modals/rejectPanDescription";
import ApproveBtn from "../../buttons/approveBtn";
import PanImageModal from "../modals/panCardImagesModal";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import successToast from "../../utilits/successToast";
import * as userAction from "../../../redux/actions/userAction";
import errorToast from "../../utilits/errorToast";
import RejectpanBtn from "../../buttons/rejectedpan";
import { access } from "../../utilits/access";
import usePermissionChecker from "../../utilits/usePermissionChecker";
import { Document, Page } from "react-pdf";
import ViewImageModal from "../modals/viewImageModal";
import DummyImage from "../../../assets/images/defaultImage.png";
const Pan = ({ individualData, value, label, }) => {
  const access = usePermissionChecker("checker")

  const panDetails = [
    {
      heading: "Pan Number",
      title:
        individualData?.pan?.pan_no === null
          ? "-"
          : individualData?.pan?.pan_no,
    },
    {
      heading: "Verified",
      title:
        individualData?.pan?.verified === true ? "Verified" : "Not Verified",
    },
    {
      heading: "Rejected",
      title:
        individualData?.pan?.rejected === true ? "Rejected" : "Not Rejected",
    },
  ];

  const [show, setShow] = useState(false);
  const [panApproved, setPanApproved] = useState(false);
  const [panRejected, setPanRejected] = useState(false);
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);
  const [imageShow, setImageShow] = useState(false);
  const handleImageShow = () => setImageShow(true);
  const handleImageClose = () => setImageShow(false);
  const [loadingPanapproved, setLoadingPanapproved] = useState(false);
  const [loadingRejected, setLoadingRejected] = useState(false);
  const [adressApprovalViewData, SetAdressMeApprovalView] = useState({
    id: "",
  });
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const params = useParams();
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    setPageNumber(1);
  }
  const downloadImage = () => {
    saveAs(
      "https://upload.wikimedia.org/wikipedia/commons/thumb/c/c8/Julia_2022-10-09_0710Z.jpg/183px-Julia_2022-10-09_0710Z.jpg"
    );
  };
  const handlePanaproved = () => {
    setLoadingPanapproved(true);
    dispatch(
      userAction.rejectedApproveAdressprofUserAction(
        {
          id: individualData?.id,
          kyc_status: "verified",
        },
        onPanapprovedSuccess,
        onAddressrejectedError
      )
    );
  };

  const onPanapprovedSuccess = (data) => {
    successToast(data.message);
    setLoadingPanapproved(false);
    setPanApproved(true)
    // setShow(false);

    console.log("address aproved");
  };
  const onAddressrejectedError = (data) => {
    errorToast(data.message);
    SetAdressMeApprovalView(false);
  };


  return (
    <>
      <h3 className="dark_grey_color fn_Montserrat ps-4 ps-lg-5 mt-3 py-2 fw_500 white_shade_bg fs_13 ">
        Pan
      </h3>
      <div className="row mt-3 ps-1 ms-1 ps-lg-4 ms-lg-1">
        <div className="col-lg-12 col-xl-9">
          <div className="row">
            {panDetails.map((item, index) => (
              <div className="address_pan_bank_data_width mt-2">
                <h5 className="dark_grey_color fs_13 fn_Montserrat fw_500">
                  {item.heading}
                </h5>
                <p className={` fs_13 fn_Nunito fw_500 'dark_title_color'}`}>
                  {item.title}
                </p>
              </div>
            ))}
          </div>
          <div className="d-flex gap-4 gap-lg-5 flex-column flex-lg-row mt-0 mb-4">
            <div>
              <h5 className="dark_grey_color text-nowrap fs_13 fn_Montserrat fw_500">
                Front Image
              </h5>

              <div className="personal_information_image_container mt-2 position-relative">

                {individualData?.pan?.front_image_location?.endsWith(".pdf") ? (
                  <div
                    onClick={handleImageShow}
                    className={`w-100 h-100 overflow-hidden`}
                  >
                    <Document
                      file={individualData?.pan?.front_image_location}
                      onLoadSuccess={onDocumentLoadSuccess}
                    >
                      {Array.from({ length: numPages }, (_, index) => (
                        <Page
                          key={`page_${index + 1}`}
                          pageNumber={index + 1}
                          renderAnnotationLayer={false}
                          renderTextLayer={false}
                        />
                      ))}
                    </Document>
                  </div>
                ) : (
                  <img
                    onClick={handleImageShow}
                    src={individualData?.pan?.front_image_location??DummyImage}
                    className="personal_information_img_size rounded-2"
                  />)}
                {/* <a
                  href={`${individualData?.pan?.front_image_location}`}
                  download="file"
                >
                  <button
                    // onClick={downloadImage}
                    type="button"
                    className="position-absolute border-0 bg-white d-flex align-items-center fs_10 top-0 end-0"
                  >
                    <DownloadIcon width={8} />
                    <span className="ms-1">Download</span>
                  </button>
                </a> */}
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-3 col-lg-12">
          <div className="d-flex  gap-5 gap-lg-3 mb-3">
            {
              individualData?.pan?.status === "verified" || panApproved ? (
                <span className="">Approved</span>
              ) : individualData?.pan?.status === "failed" || panRejected ? (
                "Rejected"
              ) : (
                <span className="d-flex gap-2">
                  <ApproveBtn approveFn={handlePanaproved} />
                  <RejectBtn showModal={handleShow} />
                </span>
              )
            }
          </div>
        </div>
      </div>
      <RejectPanDescription
        handleClose={handleClose}
        show={show}
        value={value}
        data={individualData}
        label={label}
        popup="pan"
      // submit={handlePanrejected}
      />
      {/* <PanImageModal
        handleClose={handleImageClose}
        show={imageShow}
        pan={individualData?.pan}
      // image="https://pmmodiyojana.in/wp-content/uploads/2022/08/image-170-1024x683.png"
      /> */}
      <ViewImageModal
        popuHeading="PAN PROF IMAGE"
        handleClose={handleImageClose}
        show={imageShow}
        Profileimage={individualData?.pan?.front_image_location}
      />
    </>
  );
};

export default Pan;
