import { call, takeLatest } from "redux-saga/effects";
import {
  APPROVE_BLOG_CATEGORY,
  CREATE_BLOG_CATEGORY,
  REJECT_BLOG_CATEGORY,
  VIEW_BLOG_CATEGORY,
  BLOG_CATEGORY_TABLE,
  EDIT_BLOG_CATEGORY,
  DELETE_BLOG_CATEGORY,
  BLOG_CATEGORY_APPROVAL_TABLE,
  BLOG_CATEGORY_APPROVAL_VIEW,
  BLOG_CATEGORY_VERSION_HISTORY_TABLE,
  BLOG_CATEGORY_VERSION_HISTORY,
  BLOG_CATEGORY_OPTIONS,
  BLOG_CATEGORY_MAIN_EXPORT,
  BLOG_CATEGORY_APPROVAL_EXPORT,
  BLOG_CATEGORY_VERSION_EXPORT,
  BLOG_CATEGORY_SUBMIT,
} from "../actions/types";
import {
  approveBlogCatgoryApi,
  blogCategoryApprovalsExportApi,
  blogCategoryMainExportApi,
  blogCategorySubmitApi,
  blogCategoryVersionExportApi,
  blogCatgoryApprovalByIdApi,
  blogCatgoryApprovalListApi,
  blogCatgoryOptionsApi,
  createBlogCatgoryApi,
  deleteBlogCatgoryApi,
  editBlogCatgoryApi,
  rejectBlogCatgoryApi,
  versionHistoryBlogCatgoryDetailApi,
  versionHistoryBlogCatgoryListApi,
  viewBlogCatgoryDetailApi,
  viewBlogCatgoryListApi,
} from "../apis/blogCategoryApis";

export function* createBlogCategoryWorker(action) {
  try {
    const res = yield call(createBlogCatgoryApi, action.data);
    console.log(res);

    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res.data);
    }
  } catch (error) {}
}

export function* approveBlogCategoryWorker(action) {
  try {
    const res = yield call(approveBlogCatgoryApi, {
      blog_id: action.userID,
    });
    console.log(res);
    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res.data);
    }
  } catch (error) {}
}

export function* rejectBlogCategoryWorker(action) {
  try {
    const res = yield call(rejectBlogCatgoryApi, action.userID);
    console.log(res);
    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res.data);
    }
  } catch (error) {}
}

export function* viewBlogCategoryDetailWorker(action) {
  try {
    const res = yield call(viewBlogCatgoryDetailApi, action.userID);
    console.log(res);
    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res.data);
    }
  } catch (error) {}
}

export function* viewBlogCategoryListWorker(action) {
  try {
    const res = yield call(viewBlogCatgoryListApi, action.page_no, action.data);
    console.log(res);
    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res.data.data);
      console.log(res.data.data);
    } else {
      yield action.onError(res.data);
    }
  } catch (error) {}
}

export function* editBlogCategoryWorker(action) {
  try {
    const res = yield call(editBlogCatgoryApi, action.id, action.data);
    console.log(res);
    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res.data);
    }
  } catch (error) {}
}

export function* deleteBlogCategoryWorker(action) {
  try {
    const res = yield call(deleteBlogCatgoryApi, action.userID);
    console.log(res);
    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res.data);
    }
  } catch (error) {}
}

export function* blogCategoryApprovalListWorker(action) {
  try {
    const res = yield call(
      blogCatgoryApprovalListApi,
      action.page_no,
      action.data
    );
    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res.data.data);
    } else {
      yield action.onError(res.data);
    }
  } catch (error) {}
}

export function* blogCategoryApprovalByIdWorker(action) {
  try {
    const res = yield call(blogCatgoryApprovalByIdApi, action.id);
    console.log(res);
    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res.data);
    }
  } catch (error) {}
}

export function* versionHistoryBlogCategoryListWorker(action) {
  try {
    const res = yield call(
      versionHistoryBlogCatgoryListApi,
      action.pageNo,
      action.data
    );
    console.log(res);
    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res.data);
    }
  } catch (error) {}
}

export function* versionHistoryBlogCategoryDetailWorker(action) {
  try {
    const res = yield call(versionHistoryBlogCatgoryDetailApi, action.id);
    console.log(res);
    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res.data);
    }
  } catch (error) {}
}

export function* blogCategoryOptionsWorker(action) {
  try {
    const res = yield call(blogCatgoryOptionsApi, {});
    console.log(res);
    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res.data);
    }
  } catch (error) {}
}

export function* blogCategoryMainExportWorker(action) {
  try {
    const res = yield call(blogCategoryMainExportApi);
    console.log(res);
    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res.data);
    }
  } catch (error) {}
}

export function* blogCategoryApprovalsExportWorker(action) {
  try {
    const res = yield call(blogCategoryApprovalsExportApi);
    console.log(res);
    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res.data);
    }
  } catch (error) {}
}

export function* blogCategoryVersionExportWorker(action) {
  try {
    const res = yield call(blogCategoryVersionExportApi);
    console.log(res);
    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res.data);
    }
  } catch (error) {}
}

export function* blogCategorySubmitWorker(action) {
  try {
    const res = yield call(blogCategorySubmitApi, action.id);
    console.log(res);
    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res.data);
    }
  } catch (error) {}
}

export function* createBlogCategoryWorkerWatcher() {
  yield takeLatest(CREATE_BLOG_CATEGORY, createBlogCategoryWorker);
}

export function* approveBlogCategoryWorkerWatcher() {
  yield takeLatest(APPROVE_BLOG_CATEGORY, approveBlogCategoryWorker);
}
export function* rejectBlogCategoryCaWorkerWatcher() {
  yield takeLatest(REJECT_BLOG_CATEGORY, rejectBlogCategoryWorker);
}
export function* viewBlogCategoryDetailWatcherWorker() {
  yield takeLatest(VIEW_BLOG_CATEGORY, viewBlogCategoryDetailWorker);
}
export function* viewBlogCategoryListWorkerWatcher() {
  yield takeLatest(BLOG_CATEGORY_TABLE, viewBlogCategoryListWorker);
}
export function* editBlogCategoryWorkerWatcher() {
  yield takeLatest(EDIT_BLOG_CATEGORY, editBlogCategoryWorker);
}
export function* deleteBlogCategoryWorkerWatcher() {
  yield takeLatest(DELETE_BLOG_CATEGORY, deleteBlogCategoryWorker);
}

export function* blogCategoryApprovalListWatcherWorker() {
  yield takeLatest(
    BLOG_CATEGORY_APPROVAL_TABLE,
    blogCategoryApprovalListWorker
  );
}

export function* blogCategoryApprovalByIdWatcherWorker() {
  yield takeLatest(BLOG_CATEGORY_APPROVAL_VIEW, blogCategoryApprovalByIdWorker);
}
export function* versionHistoryBlogCategoryListWatcher() {
  yield takeLatest(
    BLOG_CATEGORY_VERSION_HISTORY_TABLE,
    versionHistoryBlogCategoryListWorker
  );
}
export function* versionHistoryBlogCategoryDetailWatcher() {
  yield takeLatest(
    BLOG_CATEGORY_VERSION_HISTORY,
    versionHistoryBlogCategoryDetailWorker
  );
}

export function* watchBlogCategoryMainExportWorker() {
  yield takeLatest(BLOG_CATEGORY_MAIN_EXPORT, blogCategoryMainExportWorker);
}
export function* watchBlogCategoryApprovalsExportWorker() {
  yield takeLatest(
    BLOG_CATEGORY_APPROVAL_EXPORT,
    blogCategoryApprovalsExportWorker
  );
}
export function* watchBlogCategoryVersionExportWorker() {
  yield takeLatest(
    BLOG_CATEGORY_VERSION_EXPORT,
    blogCategoryVersionExportWorker
  );
}
export function* watchblogCategorySubmitWorker() {
  yield takeLatest(BLOG_CATEGORY_SUBMIT, blogCategorySubmitWorker);
}

// export function* blogCategoryOptionsWorkerWatcher() {
//     yield takeLatest(BLOG_CATEGORY_OPTIONS, BLOGOptionsWorker);
// }
