export const opportunityJsonStateData = {
  data: [
    {
      id: Date.now(),
      section_heading: "",
      subsections: [{ content: [] }
      ],
    },
  ],
};

export const newSection = {
  id: '',
  section_heading: "",
  subsections: [{ content: [] }
  ],
};

export const subSection = {
  id: "",
  title: "",
  title_image: "",
  content: [],
};

export const textSection = {
  id: "",
  type: "text",
  value: "",
};

export const fileSection = {
  id: "",
  type: "file",
  fileUrl: "",
  fileName: "",
  description: "",
};

export const rowItem = {
  id: "",
  col1_title: "",
  col1_caption: "",
  col2_title: "",
  col2_caption: "",
};

export const tableSection = {
  id: "",
  type: "table",
  table_type: "type1",
  heading: {
    col1: "",
    col2: "",
  },
  body: [
  ],
  footer: {
    col1: "",
    col2: "",
  },
  footerNotes: "",
};
