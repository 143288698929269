import { authHeader } from "../config";
import { api } from "./api";

export const createOurTeamApi = (params) => {
    return api.post("/api/homestatic/create-our-team", params, {
        headers: {
            "x-access-token": localStorage.getItem("accessToken"),
            "Content-Type": "application/json",
        },
    });
};

export const approveOurTeamApi = (params) => {
    return api.post(`/api/homestatic/approve-our-team`, JSON.stringify(params), {
        headers: {
            "x-access-token": localStorage.getItem("accessToken"),
            "Content-Type": "application/json",
        },
    });
};


export const rejectOurTeamApi = (id, params) => {
    return api.post(`/api/homestatic/reject-our-team/${id}`, JSON.stringify(params), {
        headers: {
            "x-access-token": localStorage.getItem("accessToken"),
            "Content-Type": "application/json",
        },
    });
};


export const viewOurTeamDetailApi = (params) => {
    return api.post(
        `/api/homestatic/view-our-team/${params.id}`,
        JSON.stringify(params),
        {
            headers: {
                "x-access-token": localStorage.getItem("accessToken"),
                "Content-Type": "application/json",
            },
        }
    );
};

export const deleteourteamApi = (id, params) => {
    return api.post(`/api/homestatic/delete-our-team/${id}`, JSON.stringify(params), {
        headers: {
            "x-access-token": localStorage.getItem("accessToken"),
            "Content-Type": "application/json",
        },
    });
};

export const viewOurTeamListApi = (page_no = 0, params) => {
    return api.post(
        `/api/homestatic/views-our-teams?page_no=${page_no}`,
        params,
        {
            headers: {
                "x-access-token": localStorage.getItem("accessToken"),
                "Content-Type": "application/json",
            },
        }
    );
};



export const editOurTeamApi = (id, params) => {
    return api.post(`/api/homestatic/edit-our-team/${id}`, JSON.stringify(params), {
        headers: {
            "x-access-token": localStorage.getItem("accessToken"),
            "Content-Type": "application/json",
        },
    });
};


//   api/homestatic/edit-our-team
// export const editOurTeamApi = (params) => {
//     return api.post(
//         `/api/admins/edit-admin/${params.adminId}`,
//         JSON.stringify(params),
//         {
//             headers: authHeader.headers,
//         }
//     );
// };

// export const deleteOurTeamApi = (params) => {
//     return api.post(
//         `/api/admins/delete-admin/${params.adminId}`,
//         JSON.stringify(params),
//         {
//             headers: authHeader.headers,
//         }
//     );
// };


export const OurTeamApprovalListApi = (pageNo, params) => {
    return api.post(
        `/api/homestatic/views-all-our-team-checkkers?page_no=${pageNo}`,
        JSON.stringify(params),
        {
            headers: {
                "x-access-token": localStorage.getItem("accessToken"),
                "Content-Type": "application/json",
            },
        }
    );
};



export const OurTeamApprovalByIdApi = (params) => {
    return api.post(
        `api/homestatic/view-one-our-team-checkker/${params.id}`,
        JSON.stringify(params),
        {
            headers: {
                "x-access-token": localStorage.getItem("accessToken"),
                "Content-Type": "application/json",
            },
        }
    );
};

export const OurTeamVersionHistoryListApi = (pageNo, params) => {
    return api.post(
        `/api/homestatic/views-our-teams-versions?page_no=${pageNo}`,
        JSON.stringify(params),
        {
            headers: {
                "x-access-token": localStorage.getItem("accessToken"),
                "Content-Type": "application/json",
            },
        }
    );
};

// export const versionHistoryOurTeamDetailApi = ( params) => {
//     return api.post(
//         `/api/homestatic/view-our-team-version/${params.id}`,
//         JSON.stringify(params),{
//           headers: {
// "x-access-token": localStorage.getItem("accessToken"),
//     "Content-Type": "application/json",
//     },
//         }
//     );
// };

export const versionHistoryOurTeamDetailApi = (id = "", params) => {
    return api.post(`/api/homestatic/view-our-team-version/${id}`, JSON.stringify(params), {
        headers: {
            "x-access-token": localStorage.getItem("accessToken"),
            "Content-Type": "application/json",
        },
    });

};
export const ourTeamOptionsApi = (id, params) => {
    return api.post(
        `/api/admins/version-admin/${id}`,
        JSON.stringify(params),
        {
            headers: {
                "x-access-token": localStorage.getItem("accessToken"),
                "Content-Type": "application/json",
            },
        }
    );
};
export const ourTeamTableExportApi = (params) => {
    return api.post(
        `/api/homestatic/export-main-our-team`,
        JSON.stringify(params),
        {
            headers: {
                "x-access-token": localStorage.getItem("accessToken"),
                "Content-Type": "application/json",
            },
        }
    );
};
export const ourTeamApprovalsExportApi = (params) => {
    return api.post(
        `/api/homestatic/export-approval-our-team`,
        JSON.stringify(params),
        {
            headers: {
                "x-access-token": localStorage.getItem("accessToken"),
                "Content-Type": "application/json",
            },
        }
    );
};
export const ourTeamVersionExportApi = (params) => {
    return api.post(
        `/api/homestatic/export-version-our-team`,
        JSON.stringify(params),
        {
            headers: {
                "x-access-token": localStorage.getItem("accessToken"),
                "Content-Type": "application/json",
            },
        }
    );
};


//on submit
export const ourTeamSubmitApi = (id, params) => {
    return api.post(`/api/homestatic/on-submitp/${id}`, params, {
        headers: {
            "x-access-token": localStorage.getItem("accessToken"),
            "Content-Type": "application/json",
        },
    });
};
