


import React from "react";

const ExportIcon = ({ fill = "#bcc7ce", width = 10, height = 16 }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 14 18">
      <path id="Icon_material-import-export" data-name="Icon material-import-export" d="M11.5,4.5l-4,3.99h3V15.5h2V8.49h3Zm7,14.01V11.5h-2v7.01h-3l4,3.99,4-3.99Z" transform="translate(-7.5 -4.5)" fill={fill} />
    </svg>

  );
};

export default ExportIcon;

