import React, { useEffect, useState } from "react";

import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { TableContainer } from "../../../table/tableContainer";
import { tableExpressInterestTableHeader } from "../../../table/tableData";
import errorToast from "../../../utilits/errorToast";

import TablePagination from "../../../table/tablePagination";
import {
  deleteExpress,
  viewExpressList,
} from "../../../../redux/actions/expressInterestAction";
import DeleteModal from "../../../modals/deleteModal";
import successToast from "../../../utilits/successToast";
import { access } from "../../../utilits/access";
import usePermissionChecker from "../../../utilits/usePermissionChecker";
import LoadAndError from "../../../utilits/LoadAndError";
import LoadingSpinnerTable from "../../../utilits/loadingSpinnerTable";
const ExpressInterestTableContent = ({
  tableContainerClassName = "adminuser_table__container",
  page,
  search,
  changePage,
  containerWidth = "90%",
  containerMargin = "1.5rem 0rem",
}) => {
  const access = usePermissionChecker("maker");
  const dispatch = useDispatch();
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);
  const [show, setShow] = useState(false);
  const [notifyData, setNotifyData] = useState([]);
  const [deleteData, setDeleteData] = useState({ name: "", id: "" });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [pageData, setPageData] = useState({ noOfItems: 0, noOfPages: 1 });
  useEffect(() => {
    setLoading(true);
    dispatch(
      viewExpressList(
        {
          search: {
            express_intrest_id: search?.value?.expressId,
            opportunity_id: search?.value?.opportunityId,
            opportunity_name: search?.value?.oppName,
            user_id: search?.value?.userId,
            user_name: search?.value?.userName,
          },
        },
        page,
        onSuccess,
        onError
      )
    );
  }, [search, page, loadingDelete]);
  const onSuccess = (data) => {
    setNotifyData(data?.data);
    console.log(notifyData);
    setLoading(false);
    setPageData({
      noOfItems: data?.count,
      noOfPages: data?.pages,
    });
  };
  const onError = (data) => {
    // errorToast(data.message);
    setLoading(false);
  };
  const handleDelete = () => {
    setLoadingDelete(true);
    dispatch(deleteExpress(deleteData.id, onDeleteSuccess, onDeleteError));
  };
  const onDeleteSuccess = (data) => {
    successToast(data.message);
    setLoadingDelete(false);
    setShow(false);
  };
  const onDeleteError = (data) => {
    errorToast(data.message);
    setLoadingDelete(false);
  };

  //pagination
  function handlePagination(type) {
    if (type === "+") {
      if (page + 1 < pageData.noOfPages) changePage("+");
    } else if (type === "-") if (page > 0) changePage("-");
  }

  return (
    <TableContainer width={containerWidth} margin={containerMargin}>
      <div className={`${tableContainerClassName}`}>
        {loading && <LoadingSpinnerTable />}
        <table className={"adminuser_table"}>
          <thead className="adminuser_table__header">
            <tr className="adminuser_table__row">
              {tableExpressInterestTableHeader.map((item) => {
                return (
                  <th
                    key={item}
                    className={`${
                      item === "Status"
                        ? `${access ? "" : "text-center"} position-sticky end-0`
                        : null
                    } text-nowrap adminuser_table__heading `}
                  >
                    {item}
                  </th>
                );
              })}
            </tr>
          </thead>
          {loading == false && (
            <tbody className="adminuser_table__body">
              <LoadAndError
                loader={loading}
                error={error}
                status={notifyData.length === 0}
              >
                {notifyData.map((item, index) => {
                  return (
                    <tr key={item.id} className="adminuser_table__row">
                      <td className="text-nowrap dark_title_color adminuser_table__data ">
                        {page > 0 ? 12 * page + (index + 1) : index + 1}
                      </td>
                      <td className="text-nowrap dark_title_color adminuser_table__data">
                        {item?.id}
                      </td>
                      <td className="text-nowrap secondary_color adminuser_table__data">
                        {item?.opportunity_id}
                      </td>
                      <td className="text-nowrap secondary_color adminuser_table_ellipsis adminuser_table__data">
                        {item?.opportunity?.title}
                      </td>
                      <td className="text-nowrap  dark_title_color adminuser_table__data">
                        {item?.user_id}
                      </td>
                      <td className="text-nowrap  dark_title_color adminuser_table__data">
                        {item?.users?.user_name}
                      </td>
                      <td className="text-nowrap  dark_title_color adminuser_table__data">
                        {item?.amount}
                      </td>
                      <td className="text-nowrap    adminuser_table__data">
                        {item?.transaction_status}
                      </td>

                      <td className="text-nowrap  position-sticky end-0 adminuser_table__data">
                        <div className="d-flex w-100 justify-content-center  gap-2">
                          <Link
                            className="text-decoration-none primary_color"
                            to={{
                              pathname: `/opportunity/express-interest/view/${item?.id}`,
                            }}
                          >
                            View
                          </Link>{" "}
                          {access && (
                            <>
                              {
                                <>
                                  <span className="vr"></span>
                                  <Link
                                    className="text-decoration-none secondary_color"
                                    to={{
                                      pathname: `/opportunity/express-interest/edit/${item?.id}`,
                                    }}
                                  >
                                    Edit
                                  </Link>
                                  <span className="vr"></span>{" "}


                                  <button
                                    onClick={() => {
                                      setDeleteData({
                                        name: item?.user?.user_names,
                                        id: item.id,
                                      });
                                      handleShow();
                                    }}
                                    className="text-decoration-none border-0 bg-transparent primary_color light_red_color"
                                  >
                                    Delete
                                  </button>
                                </>
                              }
                            </>
                          )}
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </LoadAndError>
            </tbody>
          )}
        </table>
      </div>
      <DeleteModal
        loader={loadingDelete}
        handleClose={handleClose}
        deleteFn={handleDelete}
        data={deleteData}
        show={show}
      />
      <TablePagination
        paginate={handlePagination}
        currentPage={page}
        pageData={pageData}
      />
    </TableContainer>
  );
};

export default ExpressInterestTableContent;
