import { APPROVE_INVITED, CREATE_INVITED, DELETE_INVITED, EDIT_INVITED, INVITED_APPROVALS_EXPORT, INVITED_APPROVAL_TABLE, INVITED_APPROVAL_VIEW, INVITED_EXPORT, INVITED_OPTIONS, INVITED_REFERRAL_OPPOR_ID, INVITED_SUBMIT, INVITED_TABLE, INVITED_VERSION_EXPORT, INVITED_VERSION_HISTORY, INVITED_VERSION_HISTORY_TABLE, REJECT_INVITED, VIEW_INVITED } from "./types";


export const createInvited = (
    data,
    onSuccess,
    onError
) => {
    return {
        type: CREATE_INVITED,
        data,
        onSuccess,
        onError,
    };
};
export const approveInvited = (id, onSuccess, onError) => {
    return {
        type: APPROVE_INVITED,
        id,
        onSuccess,
        onError,
    };
};

export const rejectInvited = (id, onSuccess, onError) => {
    return {
        type: REJECT_INVITED,
        id,
        onSuccess,
        onError,
    };
};

export const viewInvitedDetail = (id, onSuccess, onError) => {
    return {
        type: VIEW_INVITED,
        id,
        onSuccess,
        onError,
    };
};

export const viewInvitedList = (data, page_no = 0, onSuccess, onError) => {
    return {
        type: INVITED_TABLE,
        data,
        page_no,
        onSuccess,
        onError,
    };
};

export const editInvited = (
    id,
    data,
    onSuccess,
    onError
) => {
    return {
        type: EDIT_INVITED,
        id,
        data,
        onSuccess,
        onError,
    };
};


export const deleteInvited = (id, onSuccess, onError) => {
    return {
        type: DELETE_INVITED,
        id,
        onSuccess,
        onError,
    };
};



export const invitedApprovalList = (data, page_no = 0, onSuccess, onError) => {
    return {
        type: INVITED_APPROVAL_TABLE,
        data,
        page_no,
        onSuccess,
        onError,
    };
};

export const invitedApprovalById = (id, onSuccess, onError) => {
    return {
        type: INVITED_APPROVAL_VIEW,
        id,
        onSuccess,
        onError,
    };
};


export const versionHistoryInvitedList = (data, page, onSuccess, onError) => {
    return {
        type: INVITED_VERSION_HISTORY_TABLE,
        data,
        page,
        onSuccess,
        onError,
    };
};

export const versionHistoryInvitedDetail = (id, onSuccess, onError) => {
    return {
        type: INVITED_VERSION_HISTORY,
        id,
        onSuccess,
        onError,
    };
};
export const invitedReferralOptions = (onSuccess, onError) => {
    return {
        type: INVITED_REFERRAL_OPPOR_ID,
        onSuccess,
        onError,
    };
};

export const invitedTableExport = (onSuccess, onError) => {
    return {
        type: INVITED_EXPORT,
        onSuccess,
        onError,
    };
};
export const invitedApprovalsExport = (onSuccess, onError) => {
    return {
        type: INVITED_APPROVALS_EXPORT,
        onSuccess,
        onError,
    };
};
export const invitedVersionExport = (onSuccess, onError) => {
    return {
        type: INVITED_VERSION_EXPORT,
        onSuccess,
        onError,
    };
};



export const invitedSubmit = (id, onSuccess, onError) => {
    return {
        type: INVITED_SUBMIT,
        id,
        onSuccess,
        onError,
    };
};



