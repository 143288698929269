import React, { useEffect, useRef, useState } from "react";
import Modal from "react-bootstrap/Modal";
import CloseIcon from "../../../../assets/icons/closeIcon";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import "./opportunitiesmodals.css";
import InputField from "../../../form/inputFields/inputField";
import SelectFields from "../../../form/selectFields/selectFields";
import ArrowDownIcon from "../../../../assets/icons/arrowdown";
import { useDispatch } from "react-redux";
import { blogOptionsAction } from "../../../../redux/actions/blogActions";

const Insights = ({ show, handleClose, formikValues, id, reasonUpdator }) => {
  const [blogList, setBlogList] = useState([])
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(blogOptionsAction(onSuccessDrop, onErrorDrop));
  }, []);
  const onSuccessDrop = (data) => {
    const categoryList = [];
    data?.data.map((item, index) => {
      categoryList[index] = { value: item.id, label: item.title };
    });
    setBlogList(categoryList);
    console.log("SUCCESS");
  };
  const onErrorDrop = (data) => {
    console.log(data);
  };

  const initialValues = {
    insights: id === 0 || id ? formikValues[id] : "",
  };
  const loginSchema = Yup.object().shape({
    insights: Yup.string().trim().required("Required"),
  });

  const onSubmit = (values, { resetForm }) => {
    resetForm();
    handleClose();
    reasonUpdator(id, values)
  };
  const defaultInsight = blogList.filter((item) => item.value === formikValues[id])



  // console.log(formikValues[id]);


  return (
    <Modal
      centered
      show={show}
      onHide={handleClose}
      dialogClassName={`oppoptunities_modals_body mx-auto `}
      contentClassName={"oppoptunities_modals_body_content  p-2"}
    >
      <Modal.Body>
        <>
          <div className="d-flex justify-content-between  ">
            <h2 className="green_color fs_15 fs_sm_13 fn_Montserrat text-nowrap fw_600 pt-1">
              INSIGHTS
            </h2>
            <button
              type="button"
              onClick={handleClose}
              className="light_grey_color text-nowrap fs_15 fs_sm_13  fn_Montserrat bg-transparent border-0 fw_600 "
            >
              <span className="pt-1">DISMISS</span>
              <span className="ms-2">
                <CloseIcon width={13} height={13} fill="#A5A5A5" />
              </span>
            </button>
          </div>
          <Formik
            initialValues={initialValues}
            onSubmit={onSubmit}
            validationSchema={loginSchema}
            enableReinitialize={true}
          >
            <Form className="mt-3">
              <SelectFields
                Icon={<ArrowDownIcon width={20} height={20} fill={"#A5A5A5"} />}
                label={"Insight"}
                placeholder={"Select Insights"}
                name={"insights"}
                options={blogList}
                default1={defaultInsight ? defaultInsight : ""}
                id={"insights"}
              />
              <div className="d-flex justify-content-end mt-3">
                <button
                  type="submit"
                  className="secondary_color fs_15 fs_sm_13  fn_Montserrat fw_600 border-0 tertiary_bg px-4 px-lg-5 py-2 py-lg-2 rounded-2"
                >
                  {id === null ? "CREATE" : "UPDATE"}
                </button>
              </div>
            </Form>
          </Formik>
        </>
      </Modal.Body>
    </Modal>
  );
};
export default Insights;
export const selectInsights = [
  { value: "insights1", label: "insights1" },
  { value: "insights2", label: "insights2" },
  { value: "insights3", label: "insights3" },
  { value: "insights4", label: "insights4" },
];
