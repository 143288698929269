import React from 'react'
import { useNavigate } from 'react-router-dom'
import { AdminButton } from './versionHistoryBtn'

const AnalyticsBtn = ({ link = "/" }) => {
    const navigate = useNavigate()
    return (
        <AdminButton onClick={() => navigate(link)} color="#112F6A" padding="3px 15px">
            <span className='pdt_1'>ANALYTICS</span>
        </AdminButton>
    )
}

export default AnalyticsBtn