import React, { useState } from "react";
import { useDispatch } from "react-redux";
import ApprovalsBtn from "../../../../components/buttons/approvalsBtn";
import CreateBtn from "../../../../components/buttons/createBtn";
import ExportBtn from "../../../../components/buttons/exportBtn";
import FilterBtn from "../../../../components/buttons/filterBtn";
import VersionHistoryBtn from "../../../../components/buttons/versionHistoryBtn";
import AdminUserFilter from "../../../../components/filter/adminUserFilter";
import NotifyApprovalFilter from "../../../../components/filter/opportunity-pagesFilter.js/notifyApprovalFilter";
import VersionTablesFilter from "../../../../components/filter/versionTableFilter";
import InnerLayout from "../../../../components/layouts/innerLayout/innerLayout";
import NotifyMeTableContent from "../../../../components/opportunity/notifyMe/tables/notifyMeTableContent";
import NotificationTableContent from "../../../../components/static-pages/notification/tables/notificationTableContent";
import SocialTableContent from "../../../../components/static-pages/social/tables/socialTableContent";
import { access } from "../../../../components/utilits/access";
import { notificationTableExport } from "../../../../redux/actions/notificationActions";
import { notifyTableExport } from "../../../../redux/actions/notifyMeActions";
import { socialTableExport } from "../../../../redux/actions/socialActions";
import { notificationTableExportApi } from "../../../../redux/apis/nitificationApis";
const SocialTable = () => {
  const [page, setPage] = useState(0);
  const [showFilter, setShowFilter] = useState(false);
  const [search, setSearch] = useState({ value: "" });
  const dispatch = useDispatch();
  const handleFilterClose = () => {
    setShowFilter(false);
  };
  const handleFilterOpen = () => {
    setShowFilter(true);
  };
  const handleSearch = (value) => {
    setSearch({ value: value });
    setPage(0)
  };
  const handlePage = (type) => {
    if (type === "+") {
      setPage((prev) => prev + 1)
    }
    else {
      setPage((prev) => prev - 1)
    }
  }
  const buttonList = [
    <ApprovalsBtn link={`/static-pages/social/approvals`} />,
    <VersionHistoryBtn link={`/static-pages/social/version-history`} />,
    <CreateBtn link={`/static-pages/social/create`} />,
    <ExportBtn dispatchAction={socialTableExport} />,
    <FilterBtn filterFn={handleFilterOpen} />,
  ];
  return (
    <InnerLayout
      buttons={
        buttonList
      }
    >
      <VersionTablesFilter
        title="Social Contribution Id"
        placeholder="Enter Social Contribution Id"
        filter={handleSearch}
        showFilter={showFilter}
        closeFilter={handleFilterClose}
      />

      <SocialTableContent page={page} changePage={handlePage} search={search} />
    </InnerLayout>
  );
};

export default SocialTable;
