import {
  ACTIVATE_HOME_OPTIONS,
  HOME_APPROVALS_EXPORT,
  HOME_APPROVALS_LIST,
  HOME_APPROVALS_VIEW,
  HOME_APPROVE,
  HOME_CREATE,
  HOME_DELETE,
  HOME_EDIT,
  HOME_LIST,
  HOME_MAIN_EXPORT,
  HOME_READ,
  HOME_REJECT,
  HOME_SUBMIT,
  HOME_VERSION_EXPORT,
  HOME_VERSION_LIST,
  HOME_VERSION_VIEW,
} from "./types";

export const addHomeAction = (data, onSuccess, onError) => {
  return {
    type: HOME_CREATE,
    data,
    onSuccess,
    onError,
  };
};
export const approvalsHomeListAction = (page, search, onSuccess, onError) => {
  return {
    type: HOME_APPROVALS_LIST,

    page, search,
    onSuccess,
    onError,
  };
};
export const approvalsViewHomeAction = (id, onSuccess, onError) => {
  return {
    type: HOME_APPROVALS_VIEW,
    id,
    onSuccess,
    onError,
  };
};
export const approveHomeAction = (id, onSuccess, onError) => {
  return {
    type: HOME_APPROVE,
    id,
    onSuccess,
    onError,
  };
};
export const rejectHomeAction = (id, onSuccess, onError) => {
  return {
    type: HOME_REJECT,
    id,
    onSuccess,
    onError,
  };
};
export const viewHomeListAction = (page, search, onSuccess, onError) => {
  return {
    type: HOME_LIST,
    page,
    search,
    onSuccess,
    onError,
  };
};
export const viewHomeAction = (id, onSuccess, onError) => {
  return {
    type: HOME_READ,
    id,
    onSuccess,
    onError,
  };
};

export const editHomeAction = (id, data, onSuccess, onError) => {
  return {
    type: HOME_EDIT,
    id,
    data,
    onSuccess,
    onError,
  };
};
export const deleteHomeAction = (id, onSuccess, onError) => {
  return {
    type: HOME_DELETE,
    id,
    onSuccess,
    onError,
  };
};

export const versionListHomeAction = (page, search, onSuccess, onError) => {
  return {
    type: HOME_VERSION_LIST,

    page,
    search,
    onSuccess,
    onError,
  };
};
export const versionViewHomeAction = (id, onSuccess, onError) => {
  return {
    type: HOME_VERSION_VIEW,
    id,
    onSuccess,
    onError,
  };
};
export const activateHomeAction = (id, onSuccess, onError) => {
  return {
    type: ACTIVATE_HOME_OPTIONS,
    id,
    onSuccess,
    onError,
  };
};

export const homeMainExportAction = (onSuccess, onError) => {
  return {
    type: HOME_MAIN_EXPORT,
    onSuccess,
    onError,
  };
};
export const homeApprovalsExportAction = (onSuccess, onError) => {
  return {
    type: HOME_APPROVALS_EXPORT,
    onSuccess,
    onError,
  };
};
export const homeVersionExportAction = (onSuccess, onError) => {
  return {
    type: HOME_VERSION_EXPORT,
    onSuccess,
    onError,
  };
};

export const homeSubmitAction = (id, onSuccess, onError) => {
  return {
    type: HOME_SUBMIT,
    id,
    onSuccess,
    onError,
  };
};
