import { authHeader } from "../config";
import { api } from "./api";
    
export const deleteNotifyMeApi = (id, params) => {
    return api.post(`/api/opportunity-notify/delete-notifymde/${id}`, JSON.stringify(params), {
        headers: {
            "x-access-token": localStorage.getItem("accessToken"),
            "Content-Type": "application/json",
        },
    });
};

export const viewAllNotifyMeApi = (pageNo = 0, params) => {
    return api.post(`/api/opportunity-notify/view-notifyme-approvals?page_no=${pageNo}`, JSON.stringify(params), {
        headers: {
            "x-access-token": localStorage.getItem("accessToken"),
            "Content-Type": "application/json",
        },
    });
};
export const readNotifyMeApi = (id = "", params) => {
    return api.post(`/api/opportunity-notify/view-notifyme/${id}`, JSON.stringify(params), {
        headers: {
            "x-access-token": localStorage.getItem("accessToken"),
            "Content-Type": "application/json",
        },
    });
};
export const readApprovalNotifyMeApi = (id = "", params) => {
    return api.post(`/api/opportunity-notify/view-checker-notifyme/${id}`, JSON.stringify(params), {
        headers: {
            "x-access-token": localStorage.getItem("accessToken"),
            "Content-Type": "application/json",
        },
    });
};

export const versionTableNotifyMeApi = (pageNo = 0, params) => {
    return api.post(`/api/opportunity-notify/view-version-history-notifyme?page_no=${pageNo}`, JSON.stringify(params), {
        headers: {
            "x-access-token": localStorage.getItem("accessToken"),
            "Content-Type": "application/json",
        },
    });
};
export const notifyMeVersionHistoryViewApi = (id, params) => {
    return api.post(`/api/opportunity-notify/view-version-history-notifyme-findone/${id}`, JSON.stringify(params), {
        headers: {
            "x-access-token": localStorage.getItem("accessToken"),
            "Content-Type": "application/json",
        },
    });
};

export const notifyMeApprovalsTableApi = (pageNo = 0, params) => {
    return api.post(`/api/opportunity-notify/view-notifyme-approvals-checker?page_no=${pageNo}`, JSON.stringify(params), {
        headers: {
            "x-access-token": localStorage.getItem("accessToken"),
            "Content-Type": "application/json",
        },
    });
};

export const notifyMeCreateApi = (params) => {
    return api.post("/api/opportunity-notify/create-notify", JSON.stringify(params), {
        headers: {
            "x-access-token": localStorage.getItem("accessToken"),
            "Content-Type": "application/json",
        },
    });
};

export const notifyMeCreateOptionsApi = (params) => {
    return api.post(`/api/opportunity-notify/create-NotifyAll`, JSON.stringify(params), {
        headers: {
            "x-access-token": localStorage.getItem("accessToken"),
            "Content-Type": "application/json",
        },
    });
};

export const editNotifyMeApi = (id, params) => {
    return api.post(`/api/opportunity-notify/edit-notify/${id}`, JSON.stringify(params), {
        headers: {
            "x-access-token": localStorage.getItem("accessToken"),
            "Content-Type": "application/json",
        },
    });
};

export const approveNotifyMeApi = (params) => {
    return api.post(`/api/opportunity-notify/create-notify-checker`, JSON.stringify(params), {
        headers: {
            "x-access-token": localStorage.getItem("accessToken"),
            "Content-Type": "application/json",
        },
    });
};


export const rejectNotifyMeApi = (id, params) => {
    return api.post(`/api/opportunity-notify/reject-role/${id}`, JSON.stringify(params), {
        headers: {
            "x-access-token": localStorage.getItem("accessToken"),
            "Content-Type": "application/json",
        },
    });
};


                                         
export const notifyTableExportApi = (params) => {
    return api.post(
        `/api/opportunity-notify/export-main-express-notify`,
        JSON.stringify(params),
        {
            headers: {
                "x-access-token": localStorage.getItem("accessToken"),
                "Content-Type": "application/json",
            },
        }
    );
};
export const notifyApprovalsExportApi = (params) => {
    return api.post(
        `/api/opportunity-notify/export-approval-notify`,
        JSON.stringify(params),
        {
            headers: {
                "x-access-token": localStorage.getItem("accessToken"),
                "Content-Type": "application/json",
            },
        }
    );
};
export const notifyVersionExportApi = (params) => {
    return api.post(
        `/api/opportunity-notify/export-version-notify`,
        JSON.stringify(params),
        {
            headers: {
                "x-access-token": localStorage.getItem("accessToken"),
                "Content-Type": "application/json",
            },
        }
    );
};




export const notifySubmitApi = (id, params) => {
    return api.post(
      `/api/opportunity-notify/on-submit/${id}`, JSON.stringify(params),
      {
        headers: {
          "x-access-token": localStorage.getItem("accessToken"),
          "Content-Type": "application/json",
        },
      }
    );
  };
