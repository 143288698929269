import React, { useState } from "react";
import "../../../modules/user/user-view.css";
import DownloadIcon from "../../../assets/icons/downloadIcon";
import { saveAs } from "file-saver";
import moment from "moment";
import DummyImage from "../../../assets/images/defaultImage.png";
import ViewImageModal from "../modals/viewImageModal";
import { Document, Page, pdfjs } from "react-pdf";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;
const PersonalInformation = ({ individualData, image = "" }) => {
  const [imageShow, setImageShow] = useState(false);
  const handleImageShow = () => setImageShow(true);
  const handleImageClose = () => setImageShow(false);
  const personaldata = [
    {
      heading: "User ID",
      title: individualData?.id,
    },
    {
      heading: "Name",
      title: individualData?.user_name,
    },
    {
      heading: "DOB",
      title:
        individualData?.dob === null
          ? "--"
          : moment(individualData?.dob).format("DD-MM-YYYY"),
    },
    {
      heading: "Father’s Name",
      title:
        individualData?.father_name === null
          ? "--"
          : individualData?.father_name,
    },
    {
      heading: "E-Mail",
      title: individualData?.email,
    },
    {
      heading: "Phone Number",
      title: individualData?.phone_number,
    },
    {
      heading: "Country Code",
      title: individualData?.country_code,
    },
    {
      heading: "Residential Status",
      title:
        individualData?.residential_status === null
          ? "--"
          : individualData?.residential_status,
    },
    {
      heading: "E-Mail Verification",
      title:
        individualData?.phone_verified === false ? "Not verified" : "Verified",
    },
    {
      heading: "Phone Verification",
      title:
        individualData?.phone_verified === false ? "Not verified" : "Verified",
    },
    {
      heading: "DIN",
      title: individualData?.din,
    },
  ];

  const erpdetails = [
    {
      heading: "Created At",
      title: moment(individualData?.createdAt).format("DD/MM/YYYY HH:mm:ss"),
    },
    {
      heading: "Updated At",
      title: moment(individualData?.updatedAt).format("DD/MM/YYYY HH:mm:ss"),
    },
    {
      heading: "Last Login",
      title:
        individualData?.last_login === null
          ? "-"
          : moment(individualData?.last_login).format("DD/MM/YYYY HH:mm:ss"),
    },
    {
      heading: "Last Password Reset",
      title:
        individualData?.last_password_rest === null
          ? "-"
          : moment(individualData?.last_password_rest).format(
            "DD-MM-YYYY HH:mm:ss"
          ),
    },
    {
      heading: "Last Password Reset Request",
      title:
        individualData?.last_password_reset_request === null
          ? "-"
          : moment(individualData?.last_password_reset_request).format(
            "DD-MM-YYYY HH:mm:ss"
          ),
    },
  ];

  const investPreferance = [
    {
      heading: "Deal Type Preference",
      title:
        individualData?.deal_type_preference === null
          ? "-"
          : individualData?.deal_type_preference,
    },
    {
      heading: "Investment Value",
      title:
        individualData?.investment_value_preference === null
          ? "-"
          : individualData?.investment_value_preference,
    },
  ];
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    setPageNumber(1);
  }
  return (
    <>
      <section className="mt-4">
        <h3 className="dark_grey_color fn_Montserrat fw_500 fs_13 py-2 white_shade_bg ps-4 ps-lg-5">
          Personal Information
        </h3>
        <div className="row mt-4">
          <div className="col-12 col-sm-12  col-md-9 col-lg-9 col-xl-7">
            <div className="row text-wrap ps-1 ms-1 ps-lg-4 ms-lg-1 text-wrap">
              {personaldata.map((item, index) => (
                <div
                  className={`${item.heading === "E-Mail"
                    ? "mb-2 personal_information_width"
                    : "mb-2 personal_information_width"
                    }`}
                >
                  <h5 className="dark_grey_color fs_13 fn_Montserrat fw_500">
                    {item.heading}
                  </h5>
                  <p
                    className={` fs_13 text-wrap fn_Nunito fw_500 ${item.heading === "User ID" ||
                      item.heading === "E-Mail" ||
                      item.heading === "Phone Number"
                      ? "secondary_color"
                      : "dark_title_color"
                      }`}
                  >
                    <span
                      title={item.title}
                      className={
                        " text-break fontFam_mulish word_wrap  fs_14 fw_500"
                      }
                    >
                      {item.title}
                    </span>
                  </p>
                </div>
              ))}
              {/* {approveUser.user_name} */}
            </div>
          </div>
          <div className="col-12 col-sm-12 col-md-3 col-lg-3  col-xl-5">
            <div className="ps-3 ms-2 ps-lg-0 ms-lg-0">
              <h5 className="dark_grey_color  fs_13 fn_Montserrat fw_500 ">
                Profile Picture
              </h5>
              <div className="personal_information_image_container mt-2 position-relative">
                {individualData?.profile_picture_location?.endsWith(".pdf") ? (
                  <div
                    onClick={handleImageShow}
                    className={`w-100 h-100 overflow-hidden`}
                    style={
                      {
                        // width: "40em",
                        // height: "40em",
                        // overflowY: "scroll",
                        // overflowX: "scroll",
                      }
                    }
                  >
                    <Document
                      file={individualData?.profile_picture_location}
                      onLoadSuccess={onDocumentLoadSuccess}
                    >
                      {Array.from({ length: numPages }, (_, index) => (
                        <Page
                          key={`page_${index + 1}`}
                          pageNumber={index + 1}
                          renderAnnotationLayer={false}
                          renderTextLayer={false}
                        />
                      ))}
                    </Document>
                  </div>
                ) : (
                  <img
                    onClick={handleImageShow}
                    src={individualData?.profile_picture_location ?? DummyImage}
                    cross-origin="use-credentials"
                    className="personal_information_img_size rounded-2"
                  />
                )}
                <a
                  href={`${individualData?.profile_picture_location}`}
                  cross-origin="use-credentials"
                  download="file"
                >
                  {/* <button
                    type="button"
                    className="position-absolute border-0 bg-white d-flex align-items-center fs_10 top-0 end-0"
                  >
                    <DownloadIcon width={8} />
                    <span className="ms-1">Download</span>
                  </button> */}
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-12 col-xl-9 ">
          <div className="row mt-4 ps-1 ms-1 ps-lg-4 ms-lg-1">
            {erpdetails.map((item, index) => (
              <div className="personal_information_details_width mb-2 ">
                <h5 className="dark_grey_color  fs_13 fn_Montserrat fw_500">
                  {item.heading}
                </h5>
                <p
                  className={` fs_13 fn_Nunito  fw_500 ${item.heading === "User ID" ||
                    item.heading === "E-Mail" ||
                    item.heading === "Phone Number"
                    ? "secondary_color"
                    : "dark_title_color"
                    }`}
                >
                  {item.title}
                </p>
              </div>
            ))}
          </div>
        </div>

        <section>
          <h3 className="dark_grey_color fn_Montserrat mt-2 fw_500 white_shade_bg py-2 fs_13 ps-4 ps-lg-5">
            Investment Preference
          </h3>
          <div className="col-lg-12 col-xl-9 ">
            <div className="row mt-4  ps-1 ms-1 ps-lg-4 ms-lg-1 ">
              {investPreferance.map((item, index) => (
                <div className="mb-2 personal_information_details_width">
                  <h5 className="dark_grey_color  fs_13 fn_Montserrat fw_500">
                    {item.heading}
                  </h5>
                  <p
                    className={` fs_13 fn_Nunito  fw_500 ${item.heading === "User ID" ||
                      item.heading === "E-Mail" ||
                      item.heading === "Phone Number"
                      ? "secondary_color"
                      : "dark_title_color"
                      }`}
                  >
                    {item.title}
                  </p>
                </div>
              ))}
            </div>
          </div>
        </section>
      </section>
      <ViewImageModal
        handleClose={handleImageClose}
        show={imageShow}
        Profileimage={individualData?.profile_picture_location}
      />
    </>
  );
};

export default PersonalInformation;
