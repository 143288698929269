import React, { useState } from 'react'
import AdminUserVersionTableContent from '../../../../components/admin/tables/adminUserVersionTableContent';
import ExportBtn from '../../../../components/buttons/exportBtn';
import FilterBtn from '../../../../components/buttons/filterBtn';
import AdminUserFilter from '../../../../components/filter/adminUserFilter';
import InnerLayout from '../../../../components/layouts/innerLayout/innerLayout';
import { adminVersionExport, exportAdmin } from '../../../../redux/actions/AdminUserAction';
import { useDispatch } from "react-redux";
import FileSaver from 'file-saver';
import VersionTablesFilter from '../../../../components/filter/versionTableFilter';
import errorToast from '../../../../components/utilits/errorToast';

const AdminVersionHistoryTable = () => {
    const [page, setPage] = useState(0);
    const [showFilter, setShowFilter] = useState(false);
    const [search, setSearch] = useState("");
    const dispatch = useDispatch()
    const handleFilterClose = () => {
        setShowFilter(false);
    };
    const handleFilterOpen = () => {
        setShowFilter(true);
    };
    const handleSearch = (value) => { console.log("running"); setSearch(value); setPage(0) };
    const handlePage = (type) => {
        if (type === "+") {
            setPage((prev) => prev + 1)
        }
        else {
            setPage((prev) => prev - 1)
        }
    }

    const buttonList = [
        <ExportBtn  dispatchAction={adminVersionExport}/>,
        <FilterBtn filterFn={handleFilterOpen} />,
    ];
    return (
        <InnerLayout containerWidth="90%" buttons={buttonList}>
            <VersionTablesFilter
                title='Admin Id'
                placeholder='Enter Admin Id'
                filter={handleSearch}
                showFilter={showFilter}
                closeFilter={handleFilterClose}
            />
            <AdminUserVersionTableContent page={page} changePage={handlePage} search={search} />
        </InnerLayout>
    );
}

export default AdminVersionHistoryTable