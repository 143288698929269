import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import ApproveBtn from "../../../../components/buttons/approveBtn";
import RejectBtn from "../../../../components/buttons/rejectBtn";
import VersionHistoryBtn from "../../../../components/buttons/versionHistoryBtn";
import InnerContainer from "../../../../components/layouts/innerContainer/innerContainer";
import InnerLayout from "../../../../components/layouts/innerLayout/innerLayout";
import OurTeamViewProfileImage from "../../../../components/static-pages/our-team/modals/viewProfileImage";
import errorToast from "../../../../components/utilits/errorToast";
import successToast from "../../../../components/utilits/successToast";
import DownloadIcon from "../../../../assets/icons/downloadIcon";
import {
  viewApprovalById,
  approveAdmin,
  rejectAdmin,
} from "../../../../redux/actions/AdminUserAction";
import "../testimonials.css";
import {
  approveTestimonials,
  rejectTestimonials,
  testimonialsApprovalById,
} from "../../../../redux/actions/testimonialsAction";
import LoadingSpinnerTable from "../../../../components/utilits/loadingSpinnerTable";
import LoadAndError from "../../../../components/utilits/LoadAndError";
const TestimonialsApprovalView = () => {
  const navigate = useNavigate();
  const handleBackImageShow = () => setImageShow(true);
  const handleImageClose = () => setImageShow(false);
  const [imageShow, setImageShow] = useState(false);
  const params = useParams();
  const dispatch = useDispatch();
  const [adminDetails, setAdminDetails] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    setLoading(true);
    console.log(params);
    dispatch(testimonialsApprovalById(params.id, onSuccess, onError));
  }, []);

  const onSuccess = (data) => {
    console.log(data.data);
    setAdminDetails(data.data);
    console.log("SUCCESS");
    setLoading(false);
    setError(false);
  };

  const onError = (data) => {
    console.log(data);
    console.log("ERROR");
    setLoading(false);
    setError(true);
  };

  // approve
  const approveAdminFunc = () => {
    dispatch(approveTestimonials(params.id, onApproveSuccess, onApproveError));
  };

  const onApproveSuccess = (data) => {
    successToast(data.message);
    console.log(data);
    navigate("/static-pages/testimonials/approvals");
    setLoading(false);
    setError(false);
  };
  const onApproveError = (data) => {
    errorToast(data.message);
    console.log(data);
    setLoading(false);
    setError(true);
  };

  //reject
  const rejectAdminFunc = () => {
    dispatch(rejectTestimonials(params.id, onRejectSuccess, onRejectError));
  };
  const onRejectSuccess = (data) => {
    successToast(data.message);
    console.log(data);
    navigate("/static-pages/testimonials/approvals");
    setLoading(false);
    setError(false);
  };

  const onRejectError = (data) => {
    errorToast(data.message);
    console.log(data);
    setLoading(false);
    setError(true);
  };

  const buttonList = [
    <VersionHistoryBtn link={`/static-pages/testimonials/version-history`} />,
    <ApproveBtn approveFn={approveAdminFunc} />,
    <RejectBtn showModal={rejectAdminFunc} />,
  ];
  const data = [
    {
      heading: "Approval ID",
      title: adminDetails?.id,
    },
    {
      heading: "Name",
      title:
        adminDetails?.new_data == null
          ? adminDetails?.old_data?.name
          : adminDetails?.new_data?.name,
    },
    {
      heading: "Designation",
      title:
        adminDetails?.new_data == null
          ? adminDetails?.old_data?.designation
          : adminDetails?.new_data?.designation,
    },
    {
      heading: "User Type",
      title:
        adminDetails?.new_data == null
          ? adminDetails?.old_data?.user_type
          : adminDetails?.new_data?.user_type,
    },

    {
      heading: "Description",
      title:
        adminDetails?.new_data == null
          ? adminDetails?.old_data?.description
          : adminDetails?.new_data?.description,
    },
    {
      heading: "Testimonials ID",
      title:
        adminDetails?.new_data == null
          ? adminDetails?.old_data?.id
          : adminDetails?.new_data?.id,
    },
    {
      heading: "Color Code",
      title:
        adminDetails?.new_data == null
          ? adminDetails?.old_data?.color_code
          : adminDetails?.new_data?.color_code,
    },
    {
      heading: "Approved At",
      title: adminDetails?.createdAt,
    },
    {
      heading: "Updated At",
      title: adminDetails?.updatedAt,
    },
    {
      heading: "Operation Type",
      title:
        adminDetails?.new_data == null
          ? "Delete"
          : adminDetails?.new_data && adminDetails?.old_data
            ? "Edit"
            : adminDetails?.old_data == null
              ? "Create"
              : "",
    },
  ];

  return (
    <InnerLayout buttons={buttonList}>
      <div className="admin_user_container">
        <InnerContainer error={error} oldData={adminDetails?.old_data} newData={adminDetails?.new_data}>
          {loading ? (
            <LoadingSpinnerTable />
          ) : (
            <LoadAndError loader={loading} error={error}>
              <div className="row">
                <div className="col-12 col-md-5">
                  <div className="pt-3  ps-4 ps-lg-4 ms-3">
                    {data.map((item, index) => (
                      <div className="mb-2">
                        <h5 className="dark_grey_color fs_13 fn_Montserrat fw_500 m-0">
                          {item.heading}
                        </h5>
                        <p
                          className={` fs_13 fn_Nunito fw_500  ${item.heading === "LinkedIn URL" ||
                            item.heading === "User ID"
                            ? "secondary_color"
                            : "dark_title_color"
                            } `}
                        >
                          {item.title}
                        </p>
                      </div>
                    ))}
                  </div>
                </div>
                <div className="col-12 col-md-5">
                  <div className="ps-4">
                    <h5 className="dark_grey_color text-nowrap fs_13 fn_Montserrat fw_500">
                      Profile Picture
                    </h5>

                    <div className="personal_information_image_container mt-2 position-relative">
                      <img
                        onClick={handleBackImageShow}
                        src={
                          adminDetails?.new_data == null
                            ? adminDetails?.old_data?.photo_url
                            : adminDetails?.new_data?.photo_url
                        }
                        className="personal_information_img_size rounded-2"
                      />
                      <a href={DownloadIcon} download="file">
                        <button
                          // onClick={downloadImage}
                          type="button"
                          className="position-absolute border-0 bg-white d-flex align-items-center fs_10 top-0 end-0"
                        >
                          <DownloadIcon width={8} />
                          <span className="ms-1">Download</span>
                        </button>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </LoadAndError>
          )}
        </InnerContainer>
      </div>
      <OurTeamViewProfileImage
        handleClose={handleImageClose}
        show={imageShow}
        Profileimage={adminDetails?.new_data == null
          ? adminDetails?.old_data?.photo_url
          : adminDetails?.new_data?.photo_url}
        modalHeading={"PROFILE PICTURE"}
      />
    </InnerLayout>
  );
};

export default TestimonialsApprovalView;
