import React, { useEffect, useRef, useState } from "react";
import "../our-team.css";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import InputField from "../../../../components/form/inputFields/inputField";
import SelectFields from "../../../../components/form/selectFields/selectFields";
import SaveBtn from "../../../../components/buttons/saveBtn";
import InnerLayout from "../../../../components/layouts/innerLayout/innerLayout";
import DeleteBtn from "../../../../components/buttons/deleteBtn";
import InnerContainer from "../../../../components/layouts/innerContainer/innerContainer";
import ViewBtn from "../../../../components/buttons/viewBtn";
import { useNavigate, useParams } from "react-router-dom";
import { editOurTeam } from "../../../../redux/actions/ourTeamAction";
import * as ourTeamAction from "../../../../redux/actions/ourTeamAction";
import { useDispatch } from "react-redux";
import LoadingSpinnerTable from "../../../../components/utilits/loadingSpinnerTable";
import DeleteModal from "../../../../components/modals/deleteModal";
import UploadIcon from "../../../../assets/icons/upload";
import CloseIcon from "../../../../assets/icons/closeIcon";
import ErrorMsg from "../../../../components/form/errorMsg";
import ArrowDownIcon from "../../../../assets/icons/arrowdown";
import errorToast from "../../../../components/utilits/errorToast";
import successToast from "../../../../components/utilits/successToast";
import { uploadAddressProofFrontImageAction } from "../../../../redux/actions/userAction";
import LoadAndError from "../../../../components/utilits/LoadAndError";
import SubmitBtn from "../../../../components/buttons/submit";

const OurTeamEdit = () => {
  const [submitData, setSubmitData] = useState();
  const formRef = useRef();
  const params = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [file, setFile] = useState(null);
  const [ourTeamdata, setOurTeamdata] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false)
  const [delLoading, setDelLoading] = useState(false);
  const [ourTeamData, SetOurTeamData] = useState({ id: "" });
  const [loadingDelete, setLoadingDelete] = useState(false);
  useEffect(() => {
    setLoading(true);
    dispatch(ourTeamAction.viewOurTeamDetail(params.id, onSuccess, onError));
  }, [params.id]);
  const onSuccess = (data) => {
    SetOurTeamData(data);
    setLoading(false);
    setDeskImageLink({ img: data?.data?.photo_url, error: false })
    setError(false)
  };
  const onError = (data) => {
    errorToast(data.message);
    setLoading(false);
    setError(true)
    console.log(data, "jdsgfdsugdsjgfdsjhgdshv");
  };
  const initialValues = {
    name: ourTeamData?.data?.name,
    designation: ourTeamData?.data?.designation,
    linkedIn: ourTeamData?.data?.linkedin_url,
    priority: ourTeamData?.data?.priority,
    description: ourTeamData?.data?.description,
  };
  const loginSchema = Yup.object().shape({
    name: Yup.string("").required("Required"),
    designation: Yup.string().trim().required("Required"),
    linkedIn: Yup.string().trim().required("Required"),
    priority: Yup.number().positive("Priority should be greater than  zero").required("Required"),
    description: Yup.string().trim().required("Required"),
  });
  const handleSubmit = () => {
    if (!deskImageLink.img) {
      if (!deskImageLink.img) {
        setDeskImageLink({ img: "", error: true });
      }

    }
    if (formRef.current) {
      formRef.current.handleSubmit();
    }
  };
  const onSubmit = (values, { resetForm }) => {
    console.log(values);
    if (deskImageLink.img)
      dispatch(
        editOurTeam(
          params?.id,
          {
            name: values.name,
            linkedin_url: values.LinkedIn,
            description: values.description,
            designation: values.designation,
            photo_url: deskImageLink.img,
            priority: values.priority,
          },
          onSubmitSuccess,
          onSubmitError
        )
      );
  };


  const onSubmitSuccess = (data) => {
    setLoading(false);
    setOurTeamdata(data.data?.data);
    successToast(data.message);
    setSubmitData(data?.data?.id)
    console.log(data?.data?.id);
    setSaveHide(true)
  };
  const onSubmitError = (data) => {
    console.log("SUBMIT ERROR");
    setOurTeamdata(false);
    errorToast(data.data.message);
  };

  const handleDelete = () => {
    setDelLoading(true);
  };
  //on submit api
  console.log(submitData);
  const [saveHide, setSaveHide] = useState(false);

  const submitFnc = () => {
    dispatch(ourTeamAction.ourTeamSubmit
      (
        submitData,
        onSubmitsSuccess,
        onSubmitsError,
      ));
  };
  const onSubmitsSuccess = (data) => {
    console.log(data);
    successToast(data.message)
    navigate("/static-pages/our-team");
  };
  const onSubmitsError = (data) => {
    console.log("ERROR==================", data);
    errorToast(data.message)

  };
  const buttonList = [
    !saveHide && <SaveBtn submitFn={handleSubmit}
      loader={loading} />,
    <SubmitBtn submitFn={submitFnc} saveHide={saveHide} />];

  const [deskImageLink, setDeskImageLink] = useState({
    img: "",
    error: false,
  });

  const imageUpload = (e) => {
    if (e.target.name === "deskImage") {
      dispatch(
        uploadAddressProofFrontImageAction(
          e.target.files[0],
          onDeskImageUploadSucess,
          onDeskImageUploadError
        )
      );
    }
    e.target.value = null;
  };
  const onDeskImageUploadSucess = (data) => {
    setDeskImageLink({ img: data.data.data.location, error: false });
  };
  const onDeskImageUploadError = (data) => {
    setDeskImageLink({ img: "", error: true });
  };
  return (
    <>
      <InnerLayout buttons={buttonList}>
        <div className="admin_user_container">
          <InnerContainer>
            {loading ? (
              <LoadingSpinnerTable />
            ) : (
              <LoadAndError loader={loading} error={error}>
                <div className="pt-3 mb-5 ps-2 ps-md-2 ps-lg-4 ">
                  <h5 className="dark_grey_color fs_13 fn_Montserrat fw_500 m-0">
                    Team Member Id
                  </h5>
                  <p className={` fs_13 fn_Nunito fw_500  secondary_color `}>
                    {ourTeamData?.data?.id}
                  </p>
                  <Formik
                    innerRef={formRef}
                    initialValues={initialValues}
                    enableReinitialize={true}
                    onSubmit={onSubmit}
                    validationSchema={loginSchema}
                  >
                    {({ setFieldValue, handleChange, values }) => (
                      <Form className="our-team-form-width">
                        <div className="row">
                          <div className="col-12 col-md-6">
                            <div>
                              <span className="mb-3 dark_grey_color fn_Montserrat fw_500 fs_14 ps-2">Profile Picture</span>
                              <div className="blog_create_image_container mt-1 position-relative d-flex  justify-content-center align-items-center">
                                <input
                                  multiple={false}
                                  style={{ visibility: "hidden" }}
                                  type="file"
                                  id="deskImage"
                                  className="upload_document_input"
                                  name="deskImage"
                                  accept="image/* ,capture=camera"
                                  onChange={(e) => {
                                    imageUpload(e);
                                  }}
                                />
                                {deskImageLink.img ? (
                                  <>
                                    <img
                                      src={deskImageLink.img}
                                      className={"user-edit-profile-picture"}
                                    />
                                    <button
                                      type="button"
                                      onClick={(e) => {
                                        setDeskImageLink({ img: "", error: true });
                                      }}
                                      className="position-absolute border-0 cursor_pointer white_bg d-flex gap-2 py-1 px-2 user_edit_profile_picture_remove_container"
                                    >
                                      <CloseIcon fill="#666666" />
                                      <span className="dark_grey_color fs_13 fn_Montserrat fw_500 ">
                                        Remove
                                      </span>
                                    </button>
                                  </>
                                ) : (
                                  <label
                                    htmlFor="deskImage"
                                    className="d-flex flex-column align-items-center mt-2 justify-content-center fs_12"
                                  >
                                    <UploadIcon width={30} height={30} />
                                    Choose Profile Picture
                                  </label>
                                )}
                              </div>
                              {deskImageLink.error && (
                                <span className="fs_12 ms-2 text-danger fw-semibold fn_Nunito">
                                  Required!
                                </span>
                              )}
                            </div>
                            <InputField
                              id={"name"}
                              label={"name"}
                              type={"text"}
                              placeholder={"User name here"}
                              name={"name"}
                            />

                            <InputField
                              id={"designation"}
                              label={"designation"}
                              type={"text"}
                              placeholder={"Designation text here"}
                              name={"designation"}
                            />
                            <InputField
                              id={"linkedIn"}
                              label={"LinkedIn URL"}
                              type={"text"}
                              placeholder={"Enter LinkedIn URL"}
                              name={"linkedIn"}
                            />
                          </div>
                          <div className="col-12 col-md-6">
                            <div className="ps-0 ps-lg-4">
                              <InputField
                                id={"priority"}
                                label={"priority"}
                                type={"text"}
                                placeholder={"Select priority"}
                                name={"priority"}
                              />
                              <label
                                id="description"
                                className="mb-2 dark_grey_color fn_Montserrat fw_500 fs_14 ps-2"
                              >
                                Description
                              </label>
                              <Field name={"description"}>
                                {({ field }) => (
                                  <textarea
                                    id="description"
                                    {...field}
                                    type="text"
                                    className={
                                      "w-100 opportunities_create_summary_text_area p-2 "
                                    }
                                  ></textarea>
                                )}
                              </Field>

                              <ErrorMsg name={"designation"} />
                            </div>
                          </div>
                        </div>
                      </Form>
                    )}
                  </Formik>
                </div>
              </LoadAndError>
            )}
          </InnerContainer>
        </div>
      </InnerLayout>
    </>
  );
};

export default OurTeamEdit;

export const selectDesignation = [
  { value: "Founder", label: "Founder" },
  { value: "Co Founder", label: "Co Founder" },
  { value: "Cheif of staff", label: "Cheif of staff" },
  { value: "Cheif Growth officer", label: "Cheif Growth officer" },
];

export const selectPrirority = [
  { value: "1", label: "1" },
  { value: "2", label: "2" },
  { value: "3", label: "3" },
  { value: "4", label: "4" },
  { value: "5", label: "5" },
]
