import { call, takeLatest } from "redux-saga/effects";
import {
  LLP_APPROVALS_EXPORT,
  LLP_APPROVALS_LIST,
  LLP_APPROVALS_VIEW,
  LLP_APPROVE,
  LLP_CREATE,
  LLP_DELETE,
  LLP_DOC_CREATE,
  LLP_DOC_DELETE,
  LLP_DOC_EDIT,
  LLP_EDIT,
  LLP_EXPORT,
  LLP_IMPORT_USERS,
  LLP_LIST,
  LLP_MEMBER_CREATE,
  LLP_MEMBER_DELETE,
  LLP_MEMBER_EDIT,
  LLP_READ,
  LLP_REJECT,
  LLP_SUBMIT,
  LLP_VERSION_EXPORT,
  LLP_VERSION_LIST,
  LLP_VERSION_VIEW,
} from "../actions/types";

import * as llpApis from "../apis/llpApis";

export function* addLLPWorker(action) {
  try {
    const res = yield call(llpApis.addLLPApi, action.data);
    console.log(action.data);
    console.log(res, "AddAdminRole");
    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res.data);
      console.log(res.data);
    } else if (res.status === 200 && res.data.status === "fail") {
      console.log(res.data);
      yield action.onError(res);
    } else {
      yield action.onError("Something Went Wrong");
    }
  } catch (err) {
    console.log(err);
    yield action.onError("Something Went Wrong");
  }
}

export function* viewLLPWorker(action) {
  try {
    const res = yield call(llpApis.viewLLPApi, action.id);
    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res.data);
      console.log(res.data);
    } else if (res.status === 200 && res.data.status === "fail") {
      console.log(res.data);
      yield action.onError(res);
    } else {
      yield action.onError("Something Went Wrong!");
      console.log(res.data);
    }
  } catch (err) {
    console.log(err);
    yield action.onError("Something Went Wrong!!");
  }
}
export function* editLLPWorker(action) {
  try {
    const res = yield call(llpApis.editLLPApi, action.id, action.data);
    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res.data);
      console.log(res.data);
    } else if (res.status === 200 && res.data.status === "fail") {
      console.log(res);
      yield action.onError(res);
    } else {
      yield action.onError("Something Went Wrong");
    }
  } catch (err) {
    console.log(err);
    yield action.onError("Something Went Wrong");
  }
}
export function* llpdeleteLLPWorker(action) {
  try {
    const res = yield call(llpApis.deleteLLPApi, action.id);
    // console.log(res, "AddAdminRole");
    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res.data);
      console.log(res.data);
    } else if (res.status === 200 && res.data.status === "fail") {
      console.log(res);
      yield action.onError(res);
    } else {
      yield action.onError("Something Went Wrong");
    }
  } catch (err) {
    console.log(err);
    yield action.onError("Something Went Wrong");
  }
}
export function* viewLLPListWorker(action) {
  try {
    const res = yield call(llpApis.viewLLPListApi, action.pageNo, action.data);
    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res.data);
      console.log(res.data);
    } else if (res.status === 200 && res.data.status === "fail") {
      console.log(res);
      yield action.onError(res.data);
    } else {
      yield action.onError("Something Went Wrong");
    }
  } catch (err) {
    console.log(err);
    yield action.onError("Something Went Wrong");
  }
}

export function* approveLLPWorker(action) {
  try {
    const res = yield call(llpApis.approveLLPApi, {
      llp_checkker_id: action.llpId,
    });
    // console.log(res, "AddAdminRole");
    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res.data);
      console.log(res.data);
    } else if (res.status === 200 && res.data.status === "fail") {
      console.log(res);
      yield action.onError(res.data);
    } else {
      yield action.onError("Something Went Wrong!");
      console.log(res.data);
    }
  } catch (err) {
    console.log(err);
    yield action.onError("Something Went Wrong!!");
  }
}

export function* rejectLLPWorker(action) {
  try {
    const res = yield call(llpApis.rejectLLPApi, action.id);
    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res.data);
      console.log(res.data);
    } else if (res.status === 200 && res.data.status === "fail") {
      console.log(res);
      yield action.onError(res.data);
    } else {
      yield action.onError("Something Went Wrong!");
      console.log(res.data);
    }
  } catch (err) {
    console.log(err);
    yield action.onError("Something Went Wrong!!");
  }
}
export function* approvalsLLPListWorker(action) {
  try {
    const res = yield call(
      llpApis.approvalsLLPListpApi,
      action.page,
      action.data
    );
    // console.log(res, "AddAdminRole");
    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res.data);
      console.log(res.data);
    } else if (res.status === 200 && res.data.status === "fail") {
      console.log(res);
      yield action.onError(res);
    } else {
      yield action.onError("Something Went Wrong!");
      console.log(res.data);
    }
  } catch (err) {
    console.log(err);
    yield action.onError("Something Went Wrong!!");
  }
}
export function* viewApprovalLLPWorker(action) {
  try {
    const res = yield call(llpApis.viewApprovalLLPApi, action.id);
    console.log(res, "llp approvals");
    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res.data);
      console.log(res.data);
    } else if (res.status === 200 && res.data.status === "fail") {
      console.log(res);
      yield action.onError(res);
    } else {
      yield action.onError("Something Went Wrong!");
      console.log(res.data);
    }
  } catch (err) {
    console.log(err);
    yield action.onError("Something Went Wrong!!");
  }
}
export function* versionHistoryListversionListLLPWorker(action) {
  try {
    const res = yield call(
      llpApis.versionListLLPApi,
      action.page_no,
      action.data
    );
    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res.data);
      console.log(res.data);
    } else if (res.status === 200 && res.data.status === "fail") {
      console.log(res);
      yield action.onError(res);
    } else {
      yield action.onError("Something Went Wrong!");
      console.log(res.data);
    }
  } catch (err) {
    console.log(err);
    yield action.onError("Something Went Wrong!!");
  }
}
export function* versionViewLLPWorker(action) {
  try {
    const res = yield call(llpApis.viewVersionLLPApi, action.id);
    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res);
      console.log(res);
    } else if (res.status === 200 && res.data.status === "fail") {
      console.log(res);
      yield action.onError(res);
    } else {
      yield action.onError("Something Went Wrong!");
      console.log(res);
    }
  } catch (err) {
    console.log(err);
    yield action.onError("Something Went Wrong!!");
  }
}

//member details
export function* llpMemberDeleteWorker(action) {
  try {
    const res = yield call(llpApis.LlpMemberDeleteApi, action.id);
    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res);
      console.log(res.dataf);
    } else if (res.status === 200 && res.data.status === "fail") {
      console.log(res);
      yield action.onError(res);
    } else {
      yield action.onError("Something Went Wrong!");
      console.log(res);
    }
  } catch (err) {
    console.log(err);
    yield action.onError("Something Went Wrong!!");
  }
}
export function* llpMemberEditWorker(action) {
  try {
    const res = yield call(llpApis.LlpMemberEditApi, action.id, action.data);
    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res);
      console.log(res.dataf);
    } else if (res.status === 200 && res.data.status === "fail") {
      console.log(res);
      yield action.onError(res);
    } else {
      yield action.onError("Something Went Wrong!");
      console.log(res);
    }
  } catch (err) {
    console.log(err);
    yield action.onError("Something Went Wrong!!");
  }
}
export function* llpMemberAddWorker(action) {
  try {
    const res = yield call(llpApis.LlpMemberAddApi, action.data);
    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res);
      console.log(res.dataf);
    } else if (res.status === 200 && res.data.status === "fail") {
      console.log(res);
      yield action.onError(res);
    } else {
      yield action.onError("Something Went Wrong!");
      console.log(res);
    }
  } catch (err) {
    console.log(err);
    yield action.onError("Something Went Wrong!!");
  }
}

//DOCUMENTS
export function* llpDocDeleteWorker(action) {
  try {
    const res = yield call(llpApis.LlpDeleteDocumenApi, action.id);
    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res);
      console.log(res.dataf);
    } else if (res.status === 200 && res.data.status === "fail") {
      console.log(res);
      yield action.onError(res);
    } else {
      yield action.onError("Something Went Wrong!");
      console.log(res);
    }
  } catch (err) {
    console.log(err);
    yield action.onError("Something Went Wrong!!");
  }
}
export function* llpDocEditWorker(action) {
  try {
    const res = yield call(llpApis.LlpEditDocumentApi, action.id, action.data);
    console.log(res);
    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res);
      console.log(res.dataf);
    } else if (res.status === 200 && res.data.status === "fail") {
      console.log(res);
      yield action.onError(res);
    } else {
      yield action.onError("Something Went Wrong!");
      console.log(res);
    }
  } catch (err) {
    console.log(err);
    yield action.onError("Something Went Wrong!!");
  }
}
export function* llpDocAddWorker(action) {
  try {
    const res = yield call(llpApis.LlpAddDocumentApi, action.data);
    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res);
      console.log(res.dataf);
    } else if (res.status === 200 && res.data.status === "fail") {
      console.log(res);
      yield action.onError(res);
    } else {
      yield action.onError("Something Went Wrong!");
      console.log(res);
    }
  } catch (err) {
    console.log(err);
    yield action.onError("Something Went Wrong!!");
  }
}

//export
export function* llpTableExportWorker(action) {
  try {
    const res = yield call(llpApis.llpTableExportApi);
    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res.data);
    }
  } catch (error) {}
}

export function* llpApprovalsExportWorker(action) {
  try {
    const res = yield call(llpApis.llpApprovalsExportApi);
    console.log(res);
    if (res.status === 200 && res.data.status === "success") {
      console.log(res.data);
      yield action.onSuccess(res.data.data);
    } else {
      yield action.onError(res.data);
    }
  } catch (error) {}
}

export function* llpVersionExportWorker(action) {
  try {
    const res = yield call(llpApis.llpVersionExportApi);
    console.log(res);
    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res.data);
    }
  } catch (error) {}
}

export function* llpSubmitWorker(action) {
  try {
    const res = yield call(llpApis.llpSubmitApi, action.id);
    console.log(res);
    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res.data);
    }
  } catch (error) {}
}

export function* llpImportUsersWorker(action) {
  try {
    const res = yield call(llpApis.llpImportUsersApi, action.id);
    console.log(res);
    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res.data);
    }
  } catch (error) {}
}

/**********************************/
/************WATHCHERS**************/
/**********************************/
export function* watchAddLLPWorker() {
  yield takeLatest(LLP_CREATE, addLLPWorker);
}
export function* watchviewLLPListWorker() {
  yield takeLatest(LLP_LIST, viewLLPListWorker);
}
export function* watchViewLLPWorker() {
  yield takeLatest(LLP_READ, viewLLPWorker);
}
export function* watcheditLLPWorker() {
  yield takeLatest(LLP_EDIT, editLLPWorker);
}
export function* watchApproveLLPWorker() {
  yield takeLatest(LLP_APPROVE, approveLLPWorker);
}
export function* watchRejectLLPWorker() {
  yield takeLatest(LLP_REJECT, rejectLLPWorker);
}
export function* watchApprovalsLLPListWorker() {
  yield takeLatest(LLP_APPROVALS_LIST, approvalsLLPListWorker);
}
export function* watchViewApprovalLLPWorker() {
  yield takeLatest(LLP_APPROVALS_VIEW, viewApprovalLLPWorker);
}

export function* watchVersionHistoryListVersionListLLPWorker() {
  yield takeLatest(LLP_VERSION_LIST, versionHistoryListversionListLLPWorker);
}
export function* watchversionViewLLPWorker() {
  yield takeLatest(LLP_VERSION_VIEW, versionViewLLPWorker);
}
export function* watchdeleteLLPWorker() {
  yield takeLatest(LLP_DELETE, llpdeleteLLPWorker);
}

//member details

export function* watchLlpMemberDeleteWorker() {
  yield takeLatest(LLP_MEMBER_DELETE, llpMemberDeleteWorker);
}
export function* watchLlpMemberEditWorker() {
  yield takeLatest(LLP_MEMBER_EDIT, llpMemberEditWorker);
}
export function* watchLlpMemberAddWorker() {
  yield takeLatest(LLP_MEMBER_CREATE, llpMemberAddWorker);
}
//member details

export function* watchLlpDocDeleteWorker() {
  yield takeLatest(LLP_DOC_DELETE, llpDocDeleteWorker);
}
export function* watchLlpDocEditWorker() {
  yield takeLatest(LLP_DOC_EDIT, llpDocEditWorker);
}
export function* watchLlpDocAddWorker() {
  yield takeLatest(LLP_DOC_CREATE, llpDocAddWorker);
}
export function* llpSubmitWorkerWatcher() {
  yield takeLatest(LLP_SUBMIT, llpSubmitWorker);
}

//export
export function* llpTableExportWatcher() {
  yield takeLatest(LLP_EXPORT, llpTableExportWorker);
}
export function* llpApprovalsExportWatcher() {
  yield takeLatest(LLP_APPROVALS_EXPORT, llpApprovalsExportWorker);
}

export function* llpVersionExportWorkerWatcher() {
  yield takeLatest(LLP_VERSION_EXPORT, llpVersionExportWorker);
}

export function* llpImportUsersWorkerWatcher() {
  yield takeLatest(LLP_IMPORT_USERS, llpImportUsersWorker);
}
