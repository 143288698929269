import React, { useEffect, useState } from "react";
import styles from "../opportunitiesRead/opportunitiesread.module.css";

const OpportunitiesReadFundedAmount = ({
  done = 50,
  containerClassName = "col-12 col-lg-4 mt-2",
  data
}) => {
  let percentage = (data?.total_funded_amount / data?.total_funding_amount) * 100;

  const [style, setStyle] = useState({});
  useEffect(() => {
    const timer = setTimeout(() => {
      const newStyle = {
        opacity: 1,
        width: `${percentage}%`,
      };
      setStyle(newStyle);
    }, 200);

    return () => {
      clearTimeout(timer);
    };
  }, [percentage]);

  function nFormatter(num) {
    if (num >= 1000000000) {
      return (num / 1000000000).toFixed(1).replace(/\.0$/, "") + "G";
    }
    if (num >= 1000000) {
      return (num / 10000000).toFixed(1).replace(/\.0$/, "") + "CR";
    }
    if (num >= 1000000) {
      return (num / 1000000).toFixed(1).replace(/\.0$/, "") + "M";
    }
    if (num >= 1000) {
      return (num / 1000).toFixed(1).replace(/\.0$/, "") + "K";
    }
    return num;
  }

  return (
    <div className={`${containerClassName}`}>
      <div className="d-flex fw_600 align-items-center mb-2">
        <span className="secondary_color fs_16 me-2">
          {

            nFormatter(data?.total_funded_amount)
          }
        </span>
        <span className="light_green_color fs_13"> FUNDED OF  {nFormatter(data?.total_funding_amount)}
        </span>
      </div>

      <div className={`${styles.oppor_read_progress__track} position-relative`}>
        <div
          className={`${styles.oppor_read_progress__bar} text-white d-flex align-items-center justify-content-center h-100`}
          style={style}
        >
          <p className="d-none">{percentage}
            %</p>
        </div>
      </div>
    </div>
  );
};

export default OpportunitiesReadFundedAmount;
