import { authHeader } from "../config";
import { api } from "./api";
    
export const deleteNotificationApi = (id, params) => {
    return api.post(`/api/admin/notification/delete/${id}`, JSON.stringify(params), {
        headers: {
            "x-access-token": localStorage.getItem("accessToken"),
            "Content-Type": "application/json",
        },
    });
};

export const viewAllNotificationApi = (pageNo = 0, params) => {
    return api.post(`/api/admin/notification/view-approvals?page_no=${pageNo}`, JSON.stringify(params), {
        headers: {
            "x-access-token": localStorage.getItem("accessToken"),
            "Content-Type": "application/json",
        },
    });
};
export const readNotificationApi = (id = "", params) => {
    return api.post(`/api/admin/notification/view-approval/${id}`, JSON.stringify(params), {
        headers: {
            "x-access-token": localStorage.getItem("accessToken"),
            "Content-Type": "application/json",
        },
    });
};
export const readApprovalNotificationApi = (id = "", params) => {
    return api.post(`/api/admin/notification/view-checker/${id}`, JSON.stringify(params), {
        headers: {
            "x-access-token": localStorage.getItem("accessToken"),
            "Content-Type": "application/json",
        },
    });
};

export const versionTableNotificationApi = (pageNo = 0, params) => {
    return api.post(`/api/admin/notification/view-version?page_no=${pageNo}`, JSON.stringify(params), {
        headers: {
            "x-access-token": localStorage.getItem("accessToken"),
            "Content-Type": "application/json",
        },
    });
};
export const notificationVersionHistoryViewApi = (id, params) => {
    return api.post(`/api/admin/notification/view-version/${id}`, JSON.stringify(params), {
        headers: {
            "x-access-token": localStorage.getItem("accessToken"),
            "Content-Type": "application/json",
        },
    });
};

export const notificationApprovalsTableApi = (pageNo = 0, params) => {
    return api.post(`/api/admin/notification/view-checker?page_no=${pageNo}`, JSON.stringify(params), {
        headers: {
            "x-access-token": localStorage.getItem("accessToken"),
            "Content-Type": "application/json",
        },
    });
};

export const notificationCreateApi = (params) => {
    return api.post("/api/admin/notification/create", JSON.stringify(params), {
        headers: {
            "x-access-token": localStorage.getItem("accessToken"),
            "Content-Type": "application/json",
        },
    });
};



export const editNotificationApi = (id, params) => {
    return api.post(`/api/admin/notification/edit/${id}`, JSON.stringify(params), {
        headers: {
            "x-access-token": localStorage.getItem("accessToken"),
            "Content-Type": "application/json",
        },
    });
};

export const approveNotificationApi = (params) => {
    return api.post(`/api/admin/notification/approve`, JSON.stringify(params), {
        headers: {
            "x-access-token": localStorage.getItem("accessToken"),
            "Content-Type": "application/json",
        },
    });
};


export const rejectNotificationApi = (id, params) => {
    return api.post(`/api/admin/notification/reject/${id}`, JSON.stringify(params), {
        headers: {
            "x-access-token": localStorage.getItem("accessToken"),
            "Content-Type": "application/json",
        },
    });
};


                                         
export const notificationTableExportApi = (params) => {
    return api.post(
        `/api/admin/notification/export-main`,
        JSON.stringify(params),
        {
            headers: {
                "x-access-token": localStorage.getItem("accessToken"),
                "Content-Type": "application/json",
            },
        }
    );
};
export const notificationApprovalsExportApi = (params) => {
    return api.post(
        `/api/admin/notification/export-approval`,
        JSON.stringify(params),
        {
            headers: {
                "x-access-token": localStorage.getItem("accessToken"),
                "Content-Type": "application/json",
            },
        }
    );
};
export const notificationVersionExportApi = (params) => {
    return api.post(
        `/api/admin/notification/export-version`,
        JSON.stringify(params),
        {
            headers: {
                "x-access-token": localStorage.getItem("accessToken"),
                "Content-Type": "application/json",
            },
        }
    );
};




export const notificationSubmitApi = (id, params) => {
    return api.post(
      `/api/admin/notification/on-submit/${id}`, JSON.stringify(params),
      {
        headers: {
          "x-access-token": localStorage.getItem("accessToken"),
          "Content-Type": "application/json",
        },
      }
    );
  };
