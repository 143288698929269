import React, { useEffect, useRef, useState } from "react";
import "../admin-user.css";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import InputField from "../../../../components/form/inputFields/inputField";
import SelectFields from "../../../../components/form/selectFields/selectFields";
import SaveBtn from "../../../../components/buttons/saveBtn";
import InnerLayout from "../../../../components/layouts/innerLayout/innerLayout";
import InnerContainer from "../../../../components/layouts/innerContainer/innerContainer";
import { useDispatch } from "react-redux";
import {
  addAdmin,
  roleTypeData,
} from "../../../../redux/actions/AdminUserAction";
import errorToast from "../../../../components/utilits/errorToast";
import { useNavigate } from "react-router-dom";
import successToast from "../../../../components/utilits/successToast";
import ErrorMsg from "../../../../components/form/errorMsg";
import eyeOn from "../../../../assets/icons/eye-on.svg";
import eyeOff from "../../../../assets/icons/eye-off.svg";
const AdminCreate = () => {
  const formRef = useRef();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [roleTypesOptions, setRoleTypesOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [passwordVisible, setPasswordVisible] = useState();
  useEffect(() => {
    dispatch(roleTypeData(onRoleDataSuccess, onRoleDataError));
  }, []);
  const onRoleDataSuccess = (data) => {
    const roleTypes = [];
    data?.data.map((el, index) => {
      roleTypes[index] = {
        value: el.id,
        label: el.role_name,
      };
    });
    setRoleTypesOptions(roleTypes);
  };
  const onRoleDataError = (data) => {
    errorToast("Failed to load roles");
  };

  const initialValues = {
    name: "",
    email: "",
    password: "",
    roleName: "",
  };
  const loginSchema = Yup.object().shape({
    name: Yup.string().trim().required("Required"),
    email: Yup.string().trim().email().required("Required"),
    password: Yup.string()
      .matches(
        passwordRegex,
        "Eight letters ,One Character,One Number,One Capital,One small letter"
      )
      .required("Required"),
    roleId: Yup.string().trim().required("Required"),
  });
  const handleSubmit = () => {
    if (formRef.current) {
      formRef.current.handleSubmit();
    }
  };
  const onSubmit = (values, { resetForm }) => {
    setLoading(true);
    dispatch(
      addAdmin(
        {
          name: values.name,
          email: values.email,
          role_id: values.roleId,
          password: values.password,
        },
        onSuccess,
        onError
      )
    );
  };
  const onSuccess = (data) => {
    console.log(data);
    setLoading(false);
    navigate(`/admin-users`);
    successToast(data.message);
  };
  const onError = (data) => {
    errorToast(data.data + " " + data.message);
    setLoading(false);
  };
  const buttonList = [<SaveBtn loader={loading} submitFn={handleSubmit} />];
  return (
    <>
      <InnerLayout buttons={buttonList}>
        <div className="admin_user_container">
          <InnerContainer>
            <div className="pt-3 mb-5 ps-2 ps-md-2 ps-lg-4 ">
              <Formik
                innerRef={formRef}
                initialValues={initialValues}
                onSubmit={onSubmit}
                validationSchema={loginSchema}
              >
                <Form className="admin-user-form-width">
                  <InputField
                    id={"name"}
                    label={"Name"}
                    type={"text"}
                    placeholder={"Enter user name"}
                    name={"name"}
                  />
                  <InputField
                    id={"email"}
                    label={"E-Mail"}
                    type={"text"}
                    placeholder={"Enter Email ID"}
                    name={"email"}
                  />
                  <div>
                    <label
                      htmlFor="password"
                      className="ms-2 mb-1  fw_500 fs_14"
                    >
                      Password
                    </label>
                    <Field name={"password"}>
                      {({ field, meta }) => (
                        <div className="position-relative">
                          <input
                            autoComplete={"true"}
                            type={passwordVisible ? "text" : "password"}
                            {...field}
                            placeholder={"Enter your password"}
                            id={"password"}
                            className={
                              meta.touched && meta.error
                                ? `w-100    inputField inputField_error__border rounded-3  pb-2  focus_outline__none`
                                : ` w-100    inputField inputField__border f rounded-3 pb-2 focus_outline__none`
                            }
                          />{" "}
                          <img
                            className="login_password_eye__icon cursor_pointer"
                            onClick={() =>
                              setPasswordVisible(!passwordVisible)
                            }
                            src={passwordVisible ? eyeOn : eyeOff}
                            alt="show password or not"
                          />
                        </div>
                      )}
                    </Field>
                    <ErrorMsg name="password" />
                  </div>
                  <SelectFields
                    label={"Role Name"}
                    placeholder={"Role Name Here"}
                    name={"roleId"}
                    options={roleTypesOptions}
                    id={"roleId"}
                  />
                </Form>
              </Formik>
            </div>
          </InnerContainer>
        </div>
      </InnerLayout>
    </>
  );
};

export default AdminCreate;

export const passwordRegex =
  /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/;