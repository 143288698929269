import { call, takeLatest } from "redux-saga/effects";
import { APPROVE_TESTIMONIALS, CREATE_TESTIMONIALS, DELETE_TESTIMONIALS, EDIT_TESTIMONIALS, REJECT_TESTIMONIALS, TESTIMONIALS_APPROVALS_EXPORT, TESTIMONIALS_APPROVAL_TABLE, TESTIMONIALS_APPROVAL_VIEW, TESTIMONIALS_EXPORT, TESTIMONIALS_OPTIONS, TESTIMONIALS_SUBMIT, TESTIMONIALS_TABLE, TESTIMONIALS_VERSION_EXPORT, TESTIMONIALS_VERSION_HISTORY, TESTIMONIALS_VERSION_HISTORY_TABLE, VIEW_OUR_TEAM, VIEW_TESTIMONIALS } from "../actions/types";
import { approveTestimonialsApi, createTestimonialsApi, deleteTestimonialsApi, editTestimonialsApi, rejectTestimonialsApi, testimonialsApprovalByIdApi, testimonialsApprovalListApi, testimonialsApprovalsExportApi, testimonialsOptionsApi, testimonialsSubmitApi, testimonialsTableExportApi, testimonialsVersionExportApi, versionHistoryTestimonialsDetailApi, versionHistoryTestimonialsListApi, viewTestimonialsDetailApi, viewTestimonialsListApi } from "../apis/testimonialsApi";



export function* createTestimonialsWorker(action) {
    try {
        const res = yield call(createTestimonialsApi,
            action.data);

        if (res.status === 200 && res.data.status === "success") {
            console.log(res.data);
            yield action.onSuccess(res.data);
        } else {
            yield action.onError(res.data);
        }
    } catch (error) { }
}

export function* approveTestimonialsWorker(action) {
    try {
        const res = yield call(approveTestimonialsApi, {
            testimonial_Id: action.id,
        });
        console.log(res);
        if (res.status === 200 && res.data.status === "success") {
            yield action.onSuccess(res.data);
        } else {
            yield action.onError(res.data);
        }
    } catch (error) { }
}

export function* rejectTestimonialsWorker(action) {
    try {
        const res = yield call(rejectTestimonialsApi, action.id);
        console.log(res);
        if (res.status === 200 && res.data.status === "success") {
            yield action.onSuccess(res.data);
            console.log(res.data);
        } else {
            yield action.onError(res.data);
        }
    } catch (error) { }
}


export function* viewTestimonialsDetailWorker(action) {
    try {
        const res = yield call(viewTestimonialsDetailApi, action.id);
        console.log(res);
        if (res.status === 200 && res.data.status === "success") {
            yield action.onSuccess(res.data);
        } else {
            yield action.onError(res.data);
        }
    } catch (error) { }
}


export function* viewTestimonialsListWorker(action) {
    try {
        const res = yield call(viewTestimonialsListApi,
            action.page_no,
            action.data,
        );
        if (res.status === 200 && res.data.status === "success") {
            yield action.onSuccess(res.data.data);
            console.log(res.data);

        } else {
            yield action.onError(res.data);
        }
    } catch (error) { }
}

export function* editTestimonialsWorker(action) {
    try {
        const res = yield call(editTestimonialsApi, action.id, action.data);
        console.log(res);
        if (res.status === 200 && res.data.status === "success") {
            yield action.onSuccess(res.data);
        } else {
            yield action.onError(res.data);
        }
    } catch (error) { }
}


export function* deleteTestimonialsWorker(action) {
    try {
        const res = yield call(deleteTestimonialsApi, action.id);
        console.log(res);
        if (res.status === 200 && res.data.status === "success") {
            yield action.onSuccess(res.data);
        } else {
            yield action.onError(res.data);
        }
    } catch (error) { }
}




export function* testimonialsApprovalListWorker(action) {
    try {
        const res = yield call(testimonialsApprovalListApi,
            action.page_no,
            action.data,
        );
        if (res.status === 200 && res.data.status === "success") {
            yield action.onSuccess(res.data.data);
            console.log(res.data);
        } else {
            yield action.onError(res.data);
        }
    } catch (error) { }
}



export function* testimonialsApprovalByIdWorker(action) {
    try {
        const res = yield call(testimonialsApprovalByIdApi, action.id);
        console.log(res);
        if (res.status === 200 && res.data.status === "success") {
            yield action.onSuccess(res.data);
            console.log(res.data);
        } else {
            yield action.onError(res.data);
        }
    } catch (error) { }
}



export function* versionHistoryTestimonialsListWorker(action) {
    try {
        const res = yield call(versionHistoryTestimonialsListApi,
            action.page,
            action.data,
        );
        console.log(res);
        if (res.status === 200 && res.data.status === "success") {
            yield action.onSuccess(res.data);
            console.log(res.datas);
        } else {
            yield action.onError(res.data);
        }
    } catch (error) { }
}

export function* versionHistoryTestimonialsDetailWorker(action) {
    try {
        const res = yield call(versionHistoryTestimonialsDetailApi,
            action.id,
        );
        console.log(res);
        if (res.status === 200 && res.data.status === "success") {
            yield action.onSuccess(res.data);
        } else {
            yield action.onError(res.data);
        }
    } catch (error) { }
}

export function* testimonialsOptionsWorker(action) {
    try {
        const res = yield call(testimonialsOptionsApi, {});
        console.log(res);
        if (res.status === 200 && res.data.status === "success") {
            yield action.onSuccess(res.data);
        } else {
            yield action.onError(res.data);
        }
    } catch (error) { }
}


/**********************************/
/************WATHCHERS**************/
/**********************************/


export function* testimonialsTableExportWorker(action) {
    try {
        const res = yield call(testimonialsTableExportApi,

        );
        if (res.status === 200 && res.data.status === "success") {
            yield action.onSuccess(res.data);
        } else {
            yield action.onError(res.data);
        }
    } catch (error) { }
}


export function* testimonialsApprovalsExportWorker(action) {
    try {
        const res = yield call(testimonialsApprovalsExportApi);
        console.log(res);
        if (res.status === 200 && res.data.status === "success") {
            console.log(res.data);
            yield action.onSuccess(res.data.data);
        } else {
            yield action.onError(res.data);
        }
    } catch (error) { }
}

export function* testimonialsVersionExportWorker(action) {
    try {
        const res = yield call(testimonialsVersionExportApi);
        console.log(res);
        if (res.status === 200 && res.data.status === "success") {
            yield action.onSuccess(res.data);
        } else {
            yield action.onError(res.data);
        }
    } catch (error) { }
}


export function* testimonialsSubmitWorker(action) {
    try {
        const res = yield call(testimonialsSubmitApi, action.id);
        console.log(res);
        if (res.status === 200 && res.data.status === "success") {
            yield action.onSuccess(res.data);
        } else {
            yield action.onError(res.data);
        }
    } catch (error) { }
}






export function* createTestimonialsWorkerWatcher() {
    yield takeLatest(CREATE_TESTIMONIALS, createTestimonialsWorker);
}

export function* approveTestimonialsWorkerWatcher() {
    yield takeLatest(APPROVE_TESTIMONIALS, approveTestimonialsWorker);
}
export function* rejectTestimonialsWorkerWatcher() {
    yield takeLatest(REJECT_TESTIMONIALS, rejectTestimonialsWorker);
}
export function* viewTestimonialsDetailWatcherWorker() {
    yield takeLatest(VIEW_TESTIMONIALS, viewTestimonialsDetailWorker);
}
export function* viewTestimonialsListWorkerWatcher() {
    yield takeLatest(TESTIMONIALS_TABLE, viewTestimonialsListWorker);
}
export function* editTestimonialsWorkerWatcher() {
    yield takeLatest(EDIT_TESTIMONIALS, editTestimonialsWorker);

}
export function* deletetesTimonialsWorkerWatcher() {
    yield takeLatest(DELETE_TESTIMONIALS, deleteTestimonialsWorker);
}

export function* testimonialsApprovalListWatcherWorker() {
    yield takeLatest(TESTIMONIALS_APPROVAL_TABLE, testimonialsApprovalListWorker);
}

export function* testimonialsApprovalByIdWatcher() {
    yield takeLatest(TESTIMONIALS_APPROVAL_VIEW, testimonialsApprovalByIdWorker);
}



export function* versionHistoryTestimonialsListWatcher() {
    yield takeLatest(TESTIMONIALS_VERSION_HISTORY_TABLE, versionHistoryTestimonialsListWorker);
}
export function* versionHistoryTestimonialsDetailWatcher() {
    yield takeLatest(
        TESTIMONIALS_VERSION_HISTORY,
        versionHistoryTestimonialsDetailWorker
    );
}

export function* testimonialsOptionsWorkerWatcher() {
    yield takeLatest(TESTIMONIALS_OPTIONS
        , testimonialsOptionsWorker);
}

/****exports*****/

export function* testimonialsTableExportWatcher() {
    yield takeLatest(
        TESTIMONIALS_EXPORT,
        testimonialsTableExportWorker
    );
}
export function* testimonialsApprovalsExportWatcher() {
    yield takeLatest(TESTIMONIALS_APPROVALS_EXPORT, testimonialsApprovalsExportWorker);
}

export function* testimonialsVersionExportWorkerWatcher() {
    yield takeLatest(TESTIMONIALS_VERSION_EXPORT, testimonialsVersionExportWorker);
}


export function* testimonialsSubmitWorkerWatcher() {
    yield takeLatest(TESTIMONIALS_SUBMIT, testimonialsSubmitWorker);
}