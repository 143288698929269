import React, { useRef, useState, useEffect } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import InnerLayout from "../../../../components/layouts/innerLayout/innerLayout";
import InnerContainer from "../../../../components/layouts/innerContainer/innerContainer";
import SelectFields from "../../../../components/form/selectFields/selectFields";
import ArrowDownIcon from "../../../../assets/icons/arrowdown";
import "./express-interest.css";
import SaveBtn from "../../../../components/buttons/saveBtn";
import DeleteBtn from "../../../../components/buttons/deleteBtn";
import InputField from "../../../../components/form/inputFields/inputField";
import { editExpress, expressSubmit, viewExpressDetail } from "../../../../redux/actions/expressInterestAction";
import successToast from "../../../../components/utilits/successToast";
import errorToast from "../../../../components/utilits/errorToast";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { notifyMeCreateOptions } from "../../../../redux/actions/notifyMeActions";
import { useNavigate } from "react-router-dom";
import { opportunitiesNameListAction } from "../../../../redux/actions/opportunitiesActions";
import LoadingSpinnerTable from "../../../../components/utilits/loadingSpinnerTable";
import LoadAndError from "../../../../components/utilits/LoadAndError";
import SubmitBtn from "../../../../components/buttons/submit";

const ExpressInterestEdit = () => {
  const [submitData, setSubmitData] = useState();
  const formRef = useRef();
  const params = useParams()
  const [notifyMeViewData, SetNotifyMeViewData] = useState()
  const navigate = useNavigate()
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const amountRegex = /^[0-9]*$/s;
  const [notifyMeData, setNotifyMeData] = useState()
  const [userName, setUserName] = useState([]);
  const [opportunityType, setOpportunityType] = useState([]);
  const [saveHide, setSaveHide] = useState(false);

  useEffect(() => {
    setLoading(true);
    dispatch(notifyMeCreateOptions(onNotifyMeSuccess, onNotifyMeError));
  }, []);
  const userNameOptions = [];
  const onNotifyMeSuccess = (data) => {
    data?.data.AllUsers.map((item, index) => {
      userNameOptions[index] = { value: item.id, label: item.user_name };
    });

    console.log(userNameOptions);
    setUserName(userNameOptions);
    setLoading(false);
    setError(false)
    console.log("SUCCESS");
  };
  const onNotifyMeError = (data) => {
    console.log("ERROR");
    setLoading(false);
    setError(true);
  };
  useEffect(() => {
    setLoading(true);
    dispatch(
      viewExpressDetail(params.id, onSuccess, onError)
    );
  }, [params.id])
  const onSuccess = (data) => {
    SetNotifyMeViewData(data.data);
    console.log(data, "jdsgfdsugdsjgfdsjhgdshv")
    setLoading(false);
    setError(false)
  };
  const onError = (data) => {
    errorToast(data.message)
    console.log(data, "jdsgfdsugdsjgfdsjhgdshv")
    setLoading(false);
    setError(true)
  };
  const initialValues = {
    opportunity: notifyMeViewData?.opportunity?.id,
    user: notifyMeViewData?.user_id,
    Amount: notifyMeViewData?.amount,
    orderID: notifyMeViewData?.order_id,
    paymentMethod: notifyMeViewData?.payment_method,
    transactionStatus: notifyMeViewData?.transaction_status,
    transactionError: notifyMeViewData?.transaction_error_txt,
    walletUsed: notifyMeViewData?.wallet_used ? true : false,
    walletAmount: notifyMeViewData?.wallet_amount,
    walletTransactionID: notifyMeViewData?.wallet_transaction_id,
    walletTransactionStatus: notifyMeViewData?.wallet_transaction_status,
    walletTransactionError: notifyMeViewData?.wallet_transaction_error_txt,
    paymentGatewayAmount: notifyMeViewData?.pg_transaction_amount,
    paymentGatewayTransactionId: notifyMeViewData?.pg_transaction_id,
    paymentGatewayTransactionStatus: notifyMeViewData?.pg_transaction_status,
    paymentGatewayTransactionError: notifyMeViewData?.pg_transaction_error_txt,
    backendtransactionID: notifyMeViewData?.backend_transaction_id,
    tenure: notifyMeViewData?.tenure
  };
  const interestEditSchema = Yup.object().shape({
    opportunity: Yup.string().trim().required("Required"),
    user: Yup.string().trim().required("Required"),
    Amount: Yup.string()
      .matches(amountRegex, "Please Enter Valid Amount Value")
      .required("Required"),
    orderID: Yup.string().trim().required("Required"),
    paymentMethod: Yup.string().trim().required("Required"),
    transactionStatus: Yup.string().trim().required("Required"),
    transactionError: Yup.string().trim().required("Required"),
    walletUsed: Yup.boolean().required("Required"),
    walletAmount: Yup.string()
      .matches(amountRegex, "Please Enter Valid Amount Value")
      .required("Required"),
    walletTransactionID: Yup.string().trim().required("Required"),
    walletTransactionStatus: Yup.string().trim().required("Required"),
    walletTransactionError: Yup.string().trim().required("Required"),
    paymentGatewayAmount: Yup.string()
      .matches(amountRegex, "Please Enter Valid Amount Value")
      .required("Required"),
    paymentGatewayTransactionId: Yup.string().trim().required("Required"),
    paymentGatewayTransactionStatus: Yup.string().trim().required("Required"),
    paymentGatewayTransactionError: Yup.string().trim().required("Required"),
    backendtransactionID: Yup.string().trim().required("Required"),
    tenure: Yup.string()
      .matches(amountRegex, "Please Enter Valid Amount Value")
      .required("Required"),
  });

  const handleSubmit = () => {
    if (formRef.current) {
      formRef.current.handleSubmit();
    }
  };
  const onSubmit = (values) => {
    dispatch(
      editExpress(params.id,
        {
          opportunity_id: values.opportunity,
          user_id: values.user,
          amount: values.Amount,
          order_id: values.orderID,
          offline_transaction: "",
          payment_method: values.paymentMethod,
          transaction_status: values.transactionStatus,
          transaction_error_txt: values.transactionError,
          wallet_used: values.walletUsed,
          wallet_amount: values.walletAmount,
          pg_transaction_time: "",
          pg_transaction_webook_time: "",
          wallet_transaction_id: values.walletTransactionID,
          pg_transaction_id: values.paymentGatewayTransactionId,
          wallet_webook_time: "",
          wallet_transaction_status: values.walletTransactionStatus,
          wallet_transaction_error_txt: values.walletTransactionError,
          pg_transaction_amount: values.paymentGatewayAmount,
          pg_transaction_status: values.paymentGatewayTransactionStatus,
          pg_transaction_error_txt: values.paymentGatewayTransactionError,
          backend_transaction_id: values.backendtransactionID,
          tenure: values.tenure
        },
        onEditSuccess, onEditError)
    );
  };

  const onEditSuccess = (data) => {
    setNotifyMeData(data.data)
    setSaveHide(true)
    successToast(data.message)
    setLoading(false)
    setError(false)
    navigate("/opportunity/express-interest")
  };
  const onEditError = (data) => {
    console.log(data);
    errorToast(data.message)
    setLoading(false)
    setError(true)
  };

  const [opportunitiesList, setOpportunitiesList] = useState([])
  useEffect(() => {
    dispatch(opportunitiesNameListAction(onSuccessOpt, onErrorOpt));
  }, []);
  const onSuccessOpt = (data) => {
    const categoryList = [];
    data?.data.map((item, index) => {
      categoryList[index] = { value: item.id, label: `${item.title}${" "}id:${item.id}` };
    });
    setOpportunitiesList(categoryList);
    console.log("SUCCESS");
  };
  const onErrorOpt = (data) => {
    console.log(data);
  };

  const submitFnc = () => {
    dispatch(expressSubmit
      (
        params.id,
        onSubmitSuccess,
        onSubmitError,
      ));
  };
  const onSubmitSuccess = (data) => {
    console.log(data);
    successToast(data.message)
    navigate("/opportunity/express-interest")
  };
  const onSubmitError = (data) => {
    console.log("ERROR==================", data);
    errorToast(data.message)

  };


  const defaultUser = userName.filter((item) => {
    return item.value === notifyMeViewData?.user_id
  })
  const buttonList = [
    <SaveBtn submitFn={handleSubmit}
      loader={loading} />,
    // <SubmitBtn submitFn={submitFnc} saveHide={saveHide} />
  ];
  return (
    <InnerLayout buttons={buttonList}>
      <div className="user_view_container">
        <InnerContainer>
          {loading ? (
            <LoadingSpinnerTable />
          ) : (
            <LoadAndError loader={loading} error={error}>
              <div className="row mt-2">
                <div className="col-12 col-sm-12 col-md-9 col-lg-9 col-xl-7">
                  <div className="row ps-1 ms-1 ps-lg-4 ms-lg-1">
                    <div className="ps-2  pt-3">
                      <h5 className="dark_grey_color text-nowrap fs_13 fn_Montserrat fw_500">
                        User ID
                      </h5>
                      <p
                        className={` fs_13 fn_Nunito text-nowrap fw_500 secondary_color`}
                      >
                        {notifyMeViewData?.id}
                      </p>

                      <Formik
                        innerRef={formRef}
                        initialValues={initialValues}
                        onSubmit={onSubmit}
                        enableReinitialize={true}
                        validationSchema={interestEditSchema}
                      >
                        {({ formik, handleChange, values, setFieldValue }) => {
                          return (
                            <Form className="">
                              <div className="   express-form-width">
                                <SelectFields
                                  default1={{ label: notifyMeViewData?.opportunity?.title, value: notifyMeViewData?.opportunity?.id }}
                                  label={"Opportunity"}
                                  Icon={
                                    <ArrowDownIcon
                                      width={20}
                                      height={20}
                                      fill={"#A5A5A5"}
                                    />
                                  }
                                  placeholder={"Select Opportunity"}
                                  name={"opportunity"}
                                  options={opportunitiesList}
                                  id={"opportunity"}
                                />
                                <SelectFields
                                  default1={defaultUser}
                                  label={"User"}
                                  Icon={
                                    <ArrowDownIcon
                                      width={20}
                                      height={20}
                                      fill={"#A5A5A5"}
                                    />
                                  }
                                  placeholder={"Select User"}
                                  name={"user"}
                                  options={userName}
                                  id={"user"}
                                />

                                <InputField
                                  id={"Amount"}
                                  label={"Amount"}
                                  type={"text"}
                                  placeholder={"Enter Amount"}
                                  name={"Amount"}
                                />
                                <InputField
                                  id={"Order ID"}
                                  label={"Order ID"}
                                  type={"text"}
                                  placeholder={"Enter Order ID"}
                                  name={"orderID"}
                                />
                                <SelectFields
                                  default1={{ label: notifyMeViewData?.payment_method, value: notifyMeViewData?.payment_method }}
                                  label={"Payment Method"}
                                  Icon={
                                    <ArrowDownIcon
                                      width={20}
                                      height={20}
                                      fill={"#A5A5A5"}
                                    />
                                  }
                                  placeholder={"Select payment Method"}
                                  name={"paymentMethod"}
                                  options={paymentMethod}
                                  id={"Payment Method"}
                                />
                                <SelectFields
                                  default1={{ label: notifyMeViewData?.pg_transaction_status, value: notifyMeViewData?.pg_transaction_status }}
                                  label={"Transaction Status"}
                                  Icon={
                                    <ArrowDownIcon
                                      width={20}
                                      height={20}
                                      fill={"#A5A5A5"}
                                    />
                                  }
                                  placeholder={"Select Transaction Status"}
                                  name={"transactionStatus"}
                                  options={transactionStatus}
                                  id={"Transaction Status"}
                                />
                                <InputField
                                  id={"Transaction Error"}
                                  label={"Transaction Error?"}
                                  type={"text"}
                                  placeholder={"Enter transaction Error"}
                                  name={"transactionError"}
                                />

                                <SelectFields
                                  default1={{ label: notifyMeViewData?.wallet_used === true ? "True" : "False", value: notifyMeViewData?.wallet_used }}
                                  label={"Wallet Used?"}
                                  Icon={
                                    <ArrowDownIcon
                                      width={20}
                                      height={20}
                                      fill={"#A5A5A5"}
                                    />
                                  }
                                  placeholder={"Select True/False"}
                                  name={"walletUsed"}
                                  options={walletUsed}
                                  id={"Wallet Used"}
                                />

                                <InputField
                                  id={"Wallet Amount"}
                                  label={"Wallet Amount"}
                                  type={"text"}
                                  placeholder={"Enter Wallet Amount"}
                                  name={"walletAmount"}
                                />
                                <InputField
                                  id={"Wallet Transaction ID"}
                                  label={"Wallet Transaction ID"}
                                  type={"text"}
                                  placeholder={"Enter Wallet Transaction ID"}
                                  name={"walletTransactionID"}
                                />
                                <SelectFields
                                  default1={{ label: notifyMeViewData?.wallet_transaction_status, value: notifyMeViewData?.wallet_transaction_status }}
                                  label={"Wallet Transaction Status"}
                                  Icon={
                                    <ArrowDownIcon
                                      width={20}
                                      height={20}
                                      fill={"#A5A5A5"}
                                    />
                                  }
                                  placeholder={"Select Wallet Transaction Status"}
                                  name={"walletTransactionStatus"}
                                  options={walletTransactionStatus}
                                  id={"Wallet Transaction Status"}
                                />
                                <InputField
                                  id={"Wallet transaction Error"}
                                  label={"Wallet transaction Error?"}
                                  type={"text"}
                                  placeholder={"Enter Wallet transaction Error"}
                                  name={"walletTransactionError"}
                                />
                                <InputField
                                  id={"Payment Gateway Amount"}
                                  label={"Payment Gateway Amount"}
                                  type={"text"}
                                  placeholder={"Enter Payment Gateway Amount"}
                                  name={"paymentGatewayAmount"}
                                />
                                <InputField
                                  id={"Payment Gateway Transaction ID"}
                                  label={"Payment Gateway Transaction ID"}
                                  type={"text"}
                                  placeholder={"Enter Payment  Transaction ID"}
                                  name={"paymentGatewayTransactionId"}
                                />
                                <SelectFields
                                  default1={{ label: notifyMeViewData?.pg_transaction_status, value: notifyMeViewData?.pg_transaction_status }}
                                  label={"Payment Transaction Status"}
                                  Icon={
                                    <ArrowDownIcon
                                      width={20}
                                      height={20}
                                      fill={"#A5A5A5"}
                                    />
                                  }
                                  placeholder={"Select Payment Gateway Transaction Status"}
                                  name={"paymentGatewayTransactionStatus"}
                                  options={paymentGatewayTransactionStatus}
                                  id={"Payment Gateway Transaction Status"}
                                />
                                <InputField
                                  id={"Payment Gateway Transaction Error?"}
                                  label={"Payment Gateway Transaction Error?"}
                                  type={"text"}
                                  placeholder={"Enter Payment Gateway Transaction Error?"}
                                  name={"paymentGatewayTransactionError"}
                                />
                                <InputField
                                  id={"Backend Transaction ID"}
                                  label={"Backend Transaction ID"}
                                  type={"text"}
                                  placeholder={"Enter Backend Transaction ID"}
                                  name={"backendtransactionID"}
                                />
                                <InputField
                                  id={"Tenure"}
                                  label={"Tenure"}
                                  type={"text"}
                                  placeholder={"Enter Tenure"}
                                  name={"tenure"}
                                />
                              </div>
                            </Form>
                          );
                        }}
                      </Formik>
                    </div>
                  </div>
                </div>
              </div>
            </LoadAndError>
          )}
        </InnerContainer>
      </div>
    </InnerLayout>
  );
};

export default ExpressInterestEdit;

export const opportunity = [
  { value: "Type1", label: "Type1" },
  { value: "Type2", label: "Type2" },
  { value: "Type3", label: "Type3" },
  { value: "Type4", label: "Type4" },
];

export const user = [
  { value: "Type1", label: "Type1" },
  { value: "Type2", label: "Type2" },
  { value: "Type3", label: "Type3" },
  { value: "Type4", label: "Type4" },
];

export const paymentMethod = [
  { value: "Online", label: "Online" },
  { value: "Cash", label: "Cash" },
];

export const transactionStatus = [
  {
    value: "Success",
    label: "Success",
  },
  {
    value: "Pending",
    label: "Pending",
  },
];

export const walletUsed = [
  {
    value: "True",
    label: "True",
  },
  {
    value: "False",
    label: "False",
  },
];


export const walletTransactionStatus = [
  {
    value: "Success",
    label: "Success",
  },
  {
    value: "Pending",
    label: "Pending",
  },
];


export const paymentGatewayTransactionStatus = [
  {
    value: "Success",
    label: "Success",
  },
  {
    value: "Pending",
    label: "Pending",
  },
];