import { call, takeLatest } from "redux-saga/effects";
import {
  ACTIVATE_HOME_OPTIONS,
  HOME_APPROVALS_EXPORT,
  HOME_APPROVALS_LIST,
  HOME_APPROVALS_VIEW,
  HOME_APPROVE,
  HOME_CREATE,
  HOME_DELETE,
  HOME_EDIT,
  HOME_LIST,
  HOME_MAIN_EXPORT,
  HOME_READ,
  HOME_REJECT,
  HOME_SUBMIT,
  HOME_VERSION_EXPORT,
  HOME_VERSION_LIST,
  HOME_VERSION_VIEW,
} from "../actions/types";
import {
  activateHomeApi,
  addHomeApi,
  approvalsHomeListpApi,
  approveHomeApi,
  deleteHomeApi,
  editHomeApi,
  homeApprovalsExportApi,
  homeMainExportApi,
  homeSubmitApi,
  homeVersionExportApi,
  rejectHomeApi,
  versionHomeListpApi,
  viewApprovalHomeApi,
  viewHomeApi,
  viewHomeListApi,
  viewVersionHomeApi,
} from "../apis/homeApis";

export function* addHomeWorker(action) {
  try {
    const res = yield call(addHomeApi, action.data);
    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res.data);
      console.log(res.data);
    } else if (res.status === 200 && res.data.status === "fail") {
      console.log(res.data);
      yield action.onError(res);
    } else {
      yield action.onError("Something Went Wrong");
    }
  } catch (err) {
    console.log(err);
    yield action.onError("Something Went Wrong");
  }
}

export function* viewHomeWorker(action) {
  try {
    const res = yield call(viewHomeApi, action.id);
    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res.data);
      console.log(res.data);
    } else if (res.status === 200 && res.data.status === "fail") {
      console.log(res.data);
      yield action.onError(res);
    } else {
      yield action.onError("Something Went Wrong!");
      console.log(res.data);
    }
  } catch (err) {
    console.log(err);
    yield action.onError("Something Went Wrong!!");
  }
}
export function* editHomeWorker(action) {
  try {
    const res = yield call(editHomeApi, action.id, action.data);
    console.log(res);
    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res.data);
      console.log(res.data);
    } else if (res.status === 200 && res.data.status === "fail") {
      console.log(res);
      yield action.onError(res);
    } else {
      yield action.onError("Something Went Wrong");
    }
  } catch (err) {
    console.log(err);
    yield action.onError("Something Went Wrong");
  }
}
export function* deleteHomeWorker(action) {
  try {
    const res = yield call(deleteHomeApi, action.id);
    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res.data);
      console.log(res.data);
    } else if (res.status === 200 && res.data.status === "fail") {
      console.log(res.data);
      yield action.onError(res);
    } else {
      yield action.onError("Something Went Wrong");
    }
  } catch (err) {
    console.log(err);
    yield action.onError("Something Went Wrong");
  }
}
export function* viewHomeListWorker(action) {
  try {
    const res = yield call(viewHomeListApi, action.page, action.search);
    console.log(res);
    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res.data);
      console.log(res.data);
    } else if (res.status === 200 && res.data.status === "fail") {
      console.log(res);
      yield action.onError(res.data);
    } else {
      yield action.onError("Something Went Wrong");
    }
  } catch (err) {
    console.log(err);
    yield action.onError("Something Went Wrong");
  }
}

export function* approveHomeWorker(action) {
  try {
    const res = yield call(approveHomeApi, { home_page_id: action.id });
    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res.data);
      console.log(res.data);
    } else if (res.status === 200 && res.data.status === "fail") {
      console.log(res);
      yield action.onError(res.data);
    } else {
      yield action.onError("Something Went Wrong!");
      console.log(res.data);
    }
  } catch (err) {
    console.log(err);
    yield action.onError("Something Went Wrong!!");
  }
}

export function* rejectHomeWorker(action) {
  try {
    const res = yield call(rejectHomeApi, action.id);
    console.log(res, "rekect Home");
    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res.data);
      console.log(res.data);
    } else if (res.status === 200 && res.data.status === "fail") {
      console.log(res);
      yield action.onError(res.data);
    } else {
      yield action.onError("Something Went Wrong!");
      console.log(res.data);
    }
  } catch (err) {
    console.log(err);
    yield action.onError("Something Went Wrong!!");
  }
}
export function* approvalsHomeListWorker(action) {
  try {
    const res = yield call(approvalsHomeListpApi, action.page, action.search);
    // console.log(res, "AddAdminRole");
    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res.data);
      console.log(res.data);
    } else if (res.status === 200 && res.data.status === "fail") {
      console.log(res);
      yield action.onError(res);
    } else {
      yield action.onError("Something Went Wrong!");
      console.log(res.data);
    }
  } catch (err) {
    console.log(err);
    yield action.onError("Something Went Wrong!!");
  }
}
export function* viewApprovalHomeWorker(action) {
  try {
    const res = yield call(viewApprovalHomeApi, action.id);
    console.log(res, "Home approvals");
    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res.data);
      console.log(res.data);
    } else if (res.status === 200 && res.data.status === "fail") {
      console.log(res);
      yield action.onError(res);
    } else {
      yield action.onError("Something Went Wrong!");
      console.log(res.data);
    }
  } catch (err) {
    console.log(err);
    yield action.onError("Something Went Wrong!!");
  }
}
export function* versionListHomeWorker(action) {
  try {
    const res = yield call(versionHomeListpApi, action.page, action.search);
    // console.log(res, "AddAdminRole");
    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res.data);
      console.log(res.data);
    } else if (res.status === 200 && res.data.status === "fail") {
      console.log(res);
      yield action.onError(res);
    } else {
      yield action.onError("Something Went Wrong!");
      console.log(res.data);
    }
  } catch (err) {
    console.log(err);
    yield action.onError("Something Went Wrong!!");
  }
}
export function* activateHomeWorker(action) {
  try {
    const res = yield call(activateHomeApi, action.id);
    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res);
      console.log(res.data);
    } else if (res.status === 200 && res.data.status === "fail") {
      console.log(res);
      yield action.onError(res);
    } else {
      yield action.onError("Something Went Wrong!");
      console.log(res);
    }
  } catch (err) {
    console.log(err);
    yield action.onError("Something Went Wrong!!");
  }
}
export function* versionViewHomeWorker(action) {
  try {
    const res = yield call(viewVersionHomeApi, action.id);
    // console.log('====================================');
    // console.log(res);
    // console.log('====================================');
    if (res.status === 200 && res.data?.status === "success") {
      yield action.onSuccess(res);
      // console.log(res.data);
    } else if (res.status === 200 && res.data?.status === "fail") {
      // console.log(res);
      yield action.onError(res);
    } else {
      yield action.onError("Something Went Wrong!");
      console.log(res);
    }
  } catch (err) {
    console.log(err);
    yield action.onError("Something Went Wrong!!");
  }
}

export function* homeMainExportWorker(action) {
  try {
    const res = yield call(homeMainExportApi);
    console.log(res);
    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res.data);
    }
  } catch (error) { }
}

export function* homeApprovalsExportWorker(action) {
  try {
    const res = yield call(homeApprovalsExportApi);
    console.log(res);
    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res.data);
    }
  } catch (error) { }
}

export function* homeVersionExportWorker(action) {
  try {
    const res = yield call(homeVersionExportApi);
    console.log(res);
    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res.data);
    }
  } catch (error) { }
}

export function* homeSubmitWorker(action) {
  try {
    const res = yield call(homeSubmitApi, action.id);
    console.log(res);
    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res.data);
    }
  } catch (error) { }
}

/**********************************/
/************WATHCHERS**************/
/**********************************/

export function* watchAddHomeWorker() {
  yield takeLatest(HOME_CREATE, addHomeWorker);
}
export function* watchApprovalsHomeListWorker() {
  yield takeLatest(HOME_APPROVALS_LIST, approvalsHomeListWorker);
}
export function* watchViewApprovalHomeWorker() {
  yield takeLatest(HOME_APPROVALS_VIEW, viewApprovalHomeWorker);
}
export function* watchViewHomeWorker() {
  yield takeLatest(HOME_READ, viewHomeWorker);
}
export function* watchViewHomeListWorker() {
  yield takeLatest(HOME_LIST, viewHomeListWorker);
}
export function* watchEditHomeWorker() {
  yield takeLatest(HOME_EDIT, editHomeWorker);
}
export function* watchApproveHomeWorker() {
  yield takeLatest(HOME_APPROVE, approveHomeWorker);
}
export function* watchRejectHomeWorker() {
  yield takeLatest(HOME_REJECT, rejectHomeWorker);
}
export function* watchDeleteHomeWorker() {
  yield takeLatest(HOME_DELETE, deleteHomeWorker);
}
export function* watchVersionListHomeWorker() {
  yield takeLatest(HOME_VERSION_LIST, versionListHomeWorker);
}
export function* watchVersionViewHomeWorker() {
  yield takeLatest(HOME_VERSION_VIEW, versionViewHomeWorker);
}
export function* WatchActivateHomeWorker() {
  yield takeLatest(ACTIVATE_HOME_OPTIONS, activateHomeWorker);
}

export function* watchHomeMainExportWorker() {
  yield takeLatest(HOME_MAIN_EXPORT, homeMainExportWorker);
}
export function* watchHomeApprovalsExportWorker() {
  yield takeLatest(HOME_APPROVALS_EXPORT, homeApprovalsExportWorker);
}
export function* watchHomeVersionExportWorker() {
  yield takeLatest(HOME_VERSION_EXPORT, homeVersionExportWorker);
}
export function* watchHomeSubmitWorker() {
  yield takeLatest(HOME_SUBMIT, homeSubmitWorker);
}
