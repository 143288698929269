import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "../../../admin/tables/admintable.css";
import errorToast from "../../../utilits/errorToast";
import { TableContainer } from "../../../table/tableContainer";
import TablePagination from "../../../table/tablePagination";
import LoadingSpinnerTable from "../../../utilits/loadingSpinnerTable";
import {
  tableLlpDocumentHeader,
  llpDocumentTableData,
} from "../../../table/tableData";
import DocumentModal from "../modals/Documentmodal";
import DeleteModal from "../../../modals/deleteModal";
import successToast from "../../../utilits/successToast";
import { llpDocDeleteAction } from "../../../../redux/actions/llpActions";
import { useDispatch } from "react-redux";

const LlpDocumnetTable = ({
  documentData = [],
  updated,
  showModal,
  loading = false, access
}) => {
  const dispatch = useDispatch();
  const [deleteData, setDeleteData] = useState({ name: "", id: "" });
  const [showDocDelete, setShowDocDelete] = useState(false);
  const [loadingDocDelete, setLoadingDocDelete] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const handleDocClose = () => setShowDocDelete(false);
  function handleDocDelete() {
    setLoadingDocDelete(true);
    dispatch(
      llpDocDeleteAction(deleteData.id, onDocDeleteSuccess, onDocDeleteError)
    );
  }
  const onDocDeleteSuccess = (data) => {
    successToast("Delete Request SuccessFully Added");
    setLoadingDocDelete(false);
    updated();
    handleDocClose();
  };
  const onDocDeleteError = (data) => {
    errorToast(data.data.data);
    setLoadingDocDelete(false);
    console.log(data, "ERROR");
  };
  const pages = Math.ceil(
    documentData?.length === 0 ? 0 : documentData?.length / 10
  );
  const documentDataCount = documentData?.length;
  const handlePagination = (value) => {
    if (documentDataCount > 10) {
      if (value === "+") {
        if (currentPage + 1 !== pages) {
          setCurrentPage((prev) => prev + 1);
        }
      } else if (value === "-")
        if (currentPage > 0) {
          setCurrentPage((prev) => prev - 1);
        }
    }
  };

  // const [llpVersionTableData, setLlpVersionTableData] = useState([]);
  return (
    <TableContainer width="96%">
      <div className="memberuser-table-container">
        {loading && <LoadingSpinnerTable />}
        <table className={"adminuser_table"}>
          <thead className="adminuser_table__header">
            <tr className="adminuser_table__row">
              {tableLlpDocumentHeader.map((item) => {
                return (
                  <th
                    key={item + "x"}
                    className={`${item === "Status"
                      ? " position-sticky end-0 text-center"
                      : ""
                      } text-nowrap adminuser_table__heading`}
                  >
                    {item}
                  </th>
                );
              })}
            </tr>
          </thead>
          {loading === false && (
            <tbody className="adminuser_table__body">
              {documentData?.map((item, index) => {
                if (currentPage === 0 && index < 10) {
                  return (
                    <tr key={item.id} className="adminuser_table__row">
                      <td className="text-nowrap dark_title_color adminuser_table__data ">
                        {index + 1}
                      </td>

                      <td className="text-nowrap dark_title_color  adminuser_table__data">
                        {item.name}
                      </td>
                      <td className="text-nowrap dark_title_color  adminuser_table__data">
                        {item.description}
                      </td>
                      <td className="text-nowrap dark_title_color  adminuser_table__data">
                        {item.file_src}
                      </td>
                      <td className="text-nowrap dark_title_color  adminuser_table__data">
                        {item.visiblity}
                      </td>
                      <td className="text-nowrap dark_title_color  adminuser_table__data">
                        {item?.visiblity_users?.length}
                      </td>
                      <td className="text-nowrap dark_title_color  adminuser_table__data">
                        {item.downloadable === true ? "True" : "False"}
                      </td>

                      <td className="text-nowrap  position-sticky end-0 adminuser_table__data">
                        <div className="d-flex w-100  justify-content-center gap-2">
                          <span
                            className="text-decoration-none cursor_pointer primary_color"
                            onClick={() => showModal("view", item)}
                          >
                            View
                          </span>
                          {access &&
                            <> <span className="vr"></span>
                              <span
                                onClick={() => showModal("edit", item)}
                                className="text-decoration-none cursor_pointer secondary_color"
                              >
                                Edit
                              </span>
                              <span className="vr"></span>

                              <button
                                onClick={() => {
                                  setDeleteData({
                                    name: item.name,
                                    id: item.id,
                                  });
                                  setShowDocDelete(true);
                                }}
                                className="text-decoration-none border-0 bg-transparent primary_color light_red_color"
                              >
                                Delete
                              </button></>}
                        </div>
                      </td>
                    </tr>
                  );
                } else if (
                  currentPage > 0 &&
                  index + 1 >= currentPage * 10 + 1 &&
                  index + 1 < (currentPage + 1) * 10 + 1
                ) {
                  return (
                    <tr key={item.id} className="adminuser_table__row">
                      <td className="text-nowrap dark_title_color adminuser_table__data ">
                        {index + 1}
                      </td>

                      <td className="text-nowrap dark_title_color  adminuser_table__data">
                        {item.name}
                      </td>
                      <td className="text-nowrap dark_title_color  adminuser_table__data">
                        {item.description}
                      </td>
                      <td className="text-nowrap dark_title_color  adminuser_table__data">
                        {item.file_src}
                      </td>
                      <td className="text-nowrap dark_title_color  adminuser_table__data">
                        {item.visiblity}
                      </td>
                      <td className="text-nowrap dark_title_color  adminuser_table__data">
                        {item?.visiblity_users?.length}
                      </td>
                      <td className="text-nowrap dark_title_color  adminuser_table__data">
                        {item.downloadable === true ? "True" : "False"}
                      </td>

                      <td className="text-nowrap  position-sticky end-0 adminuser_table__data">
                        <div className="d-flex w-100  justify-content-center gap-2">
                          <span
                            className="text-decoration-none primary_color"
                            onClick={() => showModal("view", item)}
                          >
                            View
                          </span>
                          {access &&
                            <> <span className="vr"></span>
                              <span
                                onClick={() => showModal("edit", item)}
                                className="text-decoration-none secondary_color"
                              >
                                Edit
                              </span>
                              <span className="vr"></span>

                              <button
                                onClick={() => {
                                  setDeleteData({
                                    name: item.name,
                                    id: item.id,
                                  });
                                  setShowDocDelete(true);
                                }}
                                className="text-decoration-none border-0 bg-transparent primary_color light_red_color"
                              >
                                Delete
                              </button></>}
                        </div>
                      </td>
                    </tr>
                  );
                }
              })}
            </tbody>
          )}
        </table>
      </div>
      <DeleteModal
        loader={loadingDocDelete}
        handleClose={handleDocClose}
        deleteFn={handleDocDelete}
        data={deleteData}
        show={showDocDelete}
      />
      <TablePagination
        limit={10}
        paginate={handlePagination}
        currentPage={currentPage}
        pageData={{
          noOfItems: documentDataCount ? documentDataCount : 0,
          noOfPages: pages === 0 ? 1 : pages,
        }}
      />
    </TableContainer>
  );
};

export default LlpDocumnetTable;
