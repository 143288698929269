import { authHeader } from "../config";
import { api } from "./api";

export const createTestimonialsApi = (params) => {
    return api.post("/api/homestatic/create-testimonial", JSON.stringify(params), {
        headers: {
            "x-access-token": localStorage.getItem("accessToken"),
            "Content-Type": "application/json",
        },
    });
};

export const approveTestimonialsApi = (params) => {
    return api.post("/api/homestatic/approve-testimonial", JSON.stringify(params), {
        headers: {
            "x-access-token": localStorage.getItem("accessToken"),
            "Content-Type": "application/json",
        },
    });
};

export const rejectTestimonialsApi = (id, params) => {
    return api.post(
        `/api/homestatic/reject-testimonial/${id}`,
        JSON.stringify(params),
        {
            headers: {
                "x-access-token": localStorage.getItem("accessToken"),
                "Content-Type": "application/json",
            },
        }
    );
};

export const viewTestimonialsDetailApi = (id, params) => {
    return api.post(
        `/api/homestatic/view-testimonial/${id}`,
        JSON.stringify(params),
        {
            headers: {
                "x-access-token": localStorage.getItem("accessToken"),
                "Content-Type": "application/json",
            },
        }
    );
};

export const viewTestimonialsListApi = (pageNo, params) => {
    return api.post(
        `/api/homestatic/views-testimonial?page_no=${pageNo}`,
        JSON.stringify(params),
        {
            headers: {
                "x-access-token": localStorage.getItem("accessToken"),
                "Content-Type": "application/json",
            },
        }
    );
};

export const editTestimonialsApi = (id, params) => {
    return api.post(
        `/api/homestatic/edit-testimonial/${id}`,
        JSON.stringify(params),
        {
            headers: {
                "x-access-token": localStorage.getItem("accessToken"),
                "Content-Type": "application/json",
            },
        }
    );
};

export const deleteTestimonialsApi = (id, params) => {
    return api.post(
        `/api/homestatic/delete-tsetimonial/${id}`,
        JSON.stringify(params),
        {
            headers: {
                "x-access-token": localStorage.getItem("accessToken"),
                "Content-Type": "application/json",
            },
        }
    );
};


export const testimonialsApprovalListApi = (pageNo, params) => {
    return api.post(
        `/api/homestatic/views-all-testimonial-checkkers?page_no=${pageNo}`,
        JSON.stringify(params),
        {
            headers: {
                "x-access-token": localStorage.getItem("accessToken"),
                "Content-Type": "application/json",
            },
        }
    );
};

export const testimonialsApprovalByIdApi = (id, params) => {
    return api.post(
        `/api/homestatic/view-one-testimonial-checkker/${id}`,
        JSON.stringify(params),
        {
            headers: {
                "x-access-token": localStorage.getItem("accessToken"),
                "Content-Type": "application/json",
            },
        }
    );
};

export const versionHistoryTestimonialsListApi = (pageNo, params) => {
    return api.post(
        `/api/homestatic/views-testimonial-versions?page_no=${pageNo}`,
        JSON.stringify(params),
        {
            headers: {
                "x-access-token": localStorage.getItem("accessToken"),
                "Content-Type": "application/json",
            },
        }
    );
};

export const versionHistoryTestimonialsDetailApi = (id, params) => {
    return api.post(
        `/api/homestatic/view-testimonial-version/${id}`,
        JSON.stringify(params),
        {
            headers: {
                "x-access-token": localStorage.getItem("accessToken"),
                "Content-Type": "application/json",
            },
        }
    );
};
export const testimonialsOptionsApi = (id, params) => {
    return api.post(
        `/api/admins/version-admin/${id}`,
        JSON.stringify(params),
        {
            headers: {
                "x-access-token": localStorage.getItem("accessToken"),
                "Content-Type": "application/json",
            },
        }
    );
};


export const testimonialsTableExportApi = ( params) => {
    return api.post(
        `/api/homestatic/export-main-testimonial`,
        JSON.stringify(params),
        {
            headers: {
                "x-access-token": localStorage.getItem("accessToken"),
                "Content-Type": "application/json",
            },
        }
    );
};
export const testimonialsApprovalsExportApi = ( params) => {
    return api.post(
        `/api/homestatic/export-approval-testimonial`,
        JSON.stringify(params),
        {
            headers: {
                "x-access-token": localStorage.getItem("accessToken"),
                "Content-Type": "application/json",
            },
        }                   
    );
};
export const testimonialsVersionExportApi = ( params) => {
    return api.post(
        `/api/homestatic/export-version-testimonial`,
        JSON.stringify(params),
        {
            headers: {
                "x-access-token": localStorage.getItem("accessToken"),
                "Content-Type": "application/json",
            },
        }
    );
};


export const testimonialsSubmitApi = (id, params) => {
    return api.post(`/api/homestatic/on-submitt/${id}`, params, {
        headers: {
            "x-access-token": localStorage.getItem("accessToken"),
            "Content-Type": "application/json",
        },
    });
};
