import React, { useRef, useState, useEffect } from "react";
import DeleteBtn from "../../../../components/buttons/deleteBtn";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import InnerContainer from "../../../../components/layouts/innerContainer/innerContainer";
import InnerLayout from "../../../../components/layouts/innerLayout/innerLayout";
import "../wishlist.css";
import SelectFields from "../../../../components/form/selectFields/selectFields";
import SaveBtn from "../../../../components/buttons/saveBtn";
import { createWishlist, viewWishListFindAll, wishlistSubmitAction } from "../../../../redux/actions/wishlistActions";
import successToast from "../../../../components/utilits/successToast";
import errorToast from "../../../../components/utilits/errorToast";
import { useDispatch } from 'react-redux';
import { opportunitiesNameListAction } from "../../../../redux/actions/opportunitiesActions";
import { useNavigate } from "react-router-dom";
import SubmitBtn from "../../../../components/buttons/submit";
import { createMemTradeSell, memTradeSellSubmitAction, viewMemTradeSellFindAll } from "../../../../redux/actions/memTradeSellActions";
import InputField from "../../../../components/form/inputFields/inputField";
import AdminRoleCheckBox from "../../../../components/utilits/adminRoleCheckbox";



const MemTradeSellCreate = () => {
    const amountRegex = /^[0-9]*$/s;
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const [allUsers, setAllUsers] = useState([]); //For dropdown of user name
    const [allOpportunities, setOpportunities] = useState([]); //For dropdown of user name
    const [subLoading, setSubLoading] = useState(false);
    const [adminId, setAdminId] = useState("");
    const [saved, setSaved] = useState(false)
    const formRef = useRef();
    const dispatch = useDispatch();
    const initialValues = {
        username: "",
        llpId: "",
        opportunity: "",
        AmountSold: "",
        AmountAsked: "",
        identity: false,
    };
    const loginSchema = Yup.object().shape({
        username: Yup.string().trim().required("Required"),
        llpId: Yup.string().trim().required("Required"),
        opportunity: Yup.string().trim().required("Required"),
        AmountSold: Yup.string().trim()
            .matches(amountRegex, "Please Enter Valid Amount Value")
            .required("Required"),
        AmountAsked: Yup.string().trim()
            .matches(amountRegex, "Please Enter Valid Amount Value")
            .required("Required"),
    });
    const handleSubmit = () => {
        if (formRef.current) {
            formRef.current.handleSubmit()
        }
    }
    useEffect((id) => {
        setLoading(true)
        dispatch(viewWishListFindAll(onSuccessDrop, onErrorDrop));
    }, [])

    const onSubmit = (values, { resetForm }) => {
        console.log(values);
        setLoading(true)
        console.log(values.opportunity)
        dispatch(
            createMemTradeSell(
                {
                    "user_id": values.username,
                    "llp_id": values.llpId,
                    "opportunity_id": values.opportunity,
                    "amount_to_be_sold": values.AmountSold,
                    "amount_to_be_asked": values.AmountAsked,
                    "disclose_identity": values.identity
                }
                ,
                onSuccess,
                onError)
        );
    };
    const onSuccess = (data) => {
        console.log(data);
        setLoading(false)
        successToast(data.data.message)
        setAdminId(data.data.data.id)
        navigate(`/opportunity/memTradeSell`)
        setSaved(true)
    };
    const onError = (data) => {
        setLoading(false)
        errorToast(data.message)
    };

    //For dropDwon fetch
    const onSuccessDrop = (data) => {
        const roleTypes = [];
        const opportunityType = [];
        console.log(data.data);
        data?.data.AllUsers.map((el, index) => {
            roleTypes[index] = { value: el.id, label: el.user_name };
        });
        setAllUsers(roleTypes);
    };

    const onErrorDrop = (data) => {
        console.log("ERROR");
    };

    //opp id
    const [opportunitiesList, setOpportunitiesList] = useState([])
    useEffect(() => {
        dispatch(opportunitiesNameListAction(onSuccessOpt, onErrorOpt));
    }, []);
    const onSuccessOpt = (data) => {
        const categoryList = [];
        data?.data.map((item, index) => {
            categoryList[index] = { value: item.id, label: `${item.title} id: ${item.id}` };
        });
        setOpportunitiesList(categoryList);
        console.log("SUCCESS");
    };
    const onErrorOpt = (data) => {
        console.log(data);
    };

    //LLP  id
    const [llpList, setLlpList] = useState([])
    useEffect(() => {
        dispatch(viewMemTradeSellFindAll(onSuccessLlp, onErrorLlp));
    }, []);
    const onSuccessLlp = (data) => {
        const categoryList = [];
        data?.data.map((item, index) => {
            categoryList[index] = { value: item.id, label: `${item.name} id: ${item.id}` };
        });
        setLlpList(categoryList);
        console.log("SUCCESS");
    };
    const onErrorLlp = (data) => {
        console.log(data);
    };






    const wishlistSubmit = () => {
        setSubLoading(true);
        dispatch(
            memTradeSellSubmitAction(adminId, onAdminSubmitSucess, onAdminSubmitError)
        );
    };
    const buttonList = [
        <SaveBtn submitFn={handleSubmit} />,
    ];
    function onAdminSubmitSucess(data) {
        successToast(data.message);
        setSubLoading(false);

    }
    function onAdminSubmitError(data) {
        setSubLoading(false);
        errorToast(data.message);
    }
    return (
        <InnerLayout buttons={buttonList}>
            <div className="wishlist-container">
                <InnerContainer>
                    <div className="  ps-4 ps-lg-4 ms-3">
                        <Formik
                            innerRef={formRef}
                            initialValues={initialValues}
                            onSubmit={onSubmit}
                            validationSchema={loginSchema}
                        >
                            <Form className='wishlist-form-width'>
                                <SelectFields
                                    label={"User Name"}
                                    placeholder={"User name here"}
                                    name={"username"}
                                    options={allUsers}
                                    id={"username"}
                                />
                                <SelectFields
                                    label={"Llp Name"}
                                    placeholder={"Enter Llp Name here"}
                                    name={"llpId"}
                                    options={llpList}
                                    id={"llpId"}
                                />
                                <SelectFields
                                    label={"Opportunity"}
                                    placeholder={"Opportunity here"}
                                    name={"opportunity"}
                                    options={opportunitiesList}
                                    id={"opportunity"}
                                />
                                <InputField
                                    id={"AmountSold"}
                                    label={"Amount to be sold"}
                                    type={"text"}
                                    placeholder={"Enter Amount"}
                                    name={"AmountSold"}
                                />
                                <InputField
                                    id={"AmountAsked"}
                                    label={"Amount to be Asked"}
                                    type={"text"}
                                    placeholder={"Enter Amount"}
                                    name={"AmountAsked"}
                                />
                                <div>
                                    <label htmlFor="identity" className='mb-2 dark_grey_color fn_Montserrat fw_500 fs_14'>Disclose Identity</label>
                                    <AdminRoleCheckBox name="identity" id="identity" />
                                </div>
                            </Form>
                        </Formik>
                    </div>
                </InnerContainer>
            </div>
        </InnerLayout>
    );
};

export default MemTradeSellCreate;

