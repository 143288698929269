import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import LoadingSpinnerTable from "../../../utilits/loadingSpinnerTable";
import errorToast from "../../../utilits/errorToast";
import successToast from "../../../utilits/successToast";
import DeleteModal from "../../../modals/deleteModal";
import { TableContainer } from "../../../table/tableContainer";
import TablePagination from "../../../table/tablePagination";
import "../../../admin/tables/admintable.css";
import {
  tableNotifyMeHeader,
  tableNotifyMeRow,
} from "../../../table/tableData";
import { Link } from "react-router-dom";
import {
  deleteNotifyMe,
  viewAllNotifyMe,
} from "../../../../redux/actions/notifyMeActions";
import moment from "moment";
import { access } from "../../../utilits/access";
import usePermissionChecker from "../../../utilits/usePermissionChecker";

const NotifyMeTableContent = ({
  tableContainerClassName = "adminuser_table__container",
  page,
  search,
  changePage,
  containerWidth = "90%",
  containerMargin = "1.5rem 0rem",
}) => {
  const access = usePermissionChecker("maker");

  const dispatch = useDispatch();
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);
  const [show, setShow] = useState(false);
  const [notifyData, setNotifyData] = useState([]);
  const [deleteData, setDeleteData] = useState({ name: "", id: "" });
  const [loading, setLoading] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [pageData, setPageData] = useState({ noOfItems: 0, noOfPages: 1 });
  useEffect(() => {
    setLoading(true);
    dispatch(
      viewAllNotifyMe(
        {
          search: {
            notify_id: search?.value?.notifyId,
            opportunity_id: search?.value?.opportunityId,
            user_id: search?.value?.userId,
          },
        },
        page,
        onSuccess,
        onError
      )
    );
  }, [page, search, loadingDelete]);
  const onSuccess = (data) => {
    setNotifyData(data.data.data);
    console.log(notifyData);
    setLoading(false);
    setPageData({
      noOfItems: data.data.count,
      noOfPages: data.data.pages,
    });
  };
  const onError = (data) => {
    errorToast(data.message);
    setLoading(false);
  };
  const handleDelete = () => {
    setLoadingDelete(true);
    dispatch(deleteNotifyMe(deleteData.id, onDeleteSuccess, onDeleteError));
  };
  const onDeleteSuccess = (data) => {
    successToast(data.message);
    setLoadingDelete(false);
    setShow(false);
  };
  const onDeleteError = (data) => {
    errorToast(data.message);
    setLoadingDelete(false);
  };

  //pagination
  function handlePagination(type) {
    if (type === "+") {
      if (page + 1 < pageData.noOfPages) changePage("+");
    } else if (type === "-") if (page > 0) changePage("-");
  }
  return (
    <TableContainer width={containerWidth} margin={containerMargin}>
      <div className={`${tableContainerClassName}`}>
        {loading && <LoadingSpinnerTable />}
        <table className={"adminuser_table"}>
          <thead className="adminuser_table__header">
            <tr className="adminuser_table__row">
              {tableNotifyMeHeader.map((item) => {
                return (
                  <th
                    key={item}
                    className={`${
                      item === "Status" ? "position-sticky end-0" : null
                    } text-nowrap adminuser_table__heading`}
                  >
                    {item}
                  </th>
                );
              })}
            </tr>
          </thead>
          {loading == false && (
            <tbody className="adminuser_table__body">
              {notifyData.map((item, index) => {
                return (
                  <tr key={item.id} className="adminuser_table__row">
                    <td className="text-nowrap dark_title_color adminuser_table__data ">
                      {index + 1}
                    </td>
                    <td className="text-nowrap dark_title_color adminuser_table__data">
                      {item.id}
                    </td>
                    <td className="text-nowrap secondary_color adminuser_table__data">
                      {item.opportunity_id === null ? "-" : item.opportunity_id}
                    </td>
                    <td className="text-nowrap secondary_color  adminuser_table__data">
                      {item.user_id === null ? "-" : item.user_id}
                    </td>
                    <td className="text-nowrap  dark_title_color adminuser_table__data">
                      {item?.user?.user_name}
                    </td>
                    <td className="text-nowrap  dark_title_color adminuser_table__data">
                      {moment(item.createdAt).format("DD-MM-YYYY HH:mm:ss")}
                    </td>
                    <td className="text-nowrap  dark_title_color adminuser_table__data">
                      {moment(item.updatedAt).format("DD-MM-YYYY HH:mm:ss")}
                    </td>
                    <td className="text-nowrap  position-sticky end-0 adminuser_table__data">
                      <div className="d-flex w-100   gap-2">
                        <Link
                          className="text-decoration-none primary_color"
                          to={{
                            pathname: `/opportunity/notify-me/view/${item.id}`,
                          }}
                        >
                          View
                        </Link>
                        {access && item?.editable && (
                          <>
                            <span className="vr"></span>{" "}
                            <Link
                              className="text-decoration-none secondary_color"
                              to={{
                                pathname: `/opportunity/notify-me/edit/${item.id}`,
                              }}
                            >
                              Edit
                            </Link>{" "}
                            {item.lastActionApproved && (
                              <>
                                <span className="vr"></span>{" "}
                                <button
                                  onClick={() => {
                                    setDeleteData({
                                      name: item.id,
                                      id: item.id,
                                    });
                                    handleShow();
                                  }}
                                  className="text-decoration-none border-0 bg-transparent primary_color light_red_color"
                                >
                                  Delete
                                </button>
                              </>
                            )}
                          </>
                        )}
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          )}
        </table>
      </div>
      <DeleteModal
        loader={loadingDelete}
        handleClose={handleClose}
        deleteFn={handleDelete}
        data={deleteData}
        show={show}
      />
      <TablePagination
        paginate={handlePagination}
        currentPage={page}
        pageData={pageData}
      />
    </TableContainer>
  );
};

export default NotifyMeTableContent;
