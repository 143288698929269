import { call, takeLatest } from "redux-saga/effects";
import {
  ADMIN_ADD_ROLE,
  ADMIN_APPROVALS_LIST_ROLE,
  ADMIN_APPROVALS_VIEW,
  ADMIN_APPROVE_ROLE,
  ADMIN_DELETE_ROLE,
  ADMIN_EDIT_ROLE,
  ADMIN_REJECT_ROLE,
  ADMIN_ROLE_SUBMIT,
  ADMIN_VERSION_HISTORY_LIST_ROLE,
  ADMIN_VERSION_HISTORY_VIEW_ROLE,
  ADMIN_VIEW_ROLE,
  ADMIN_VIEW_ROLES,
  ROLES_APPROVALS_EXPORT,
  ROLES_EXPORT,
  ROLES_VERSION_EXPORT,
} from "../actions/types";
import * as adminRoleApis from "../apis/adminRolesApis";

export function* addAdminRoleWorker(action) {
  try {
    const res = yield call(adminRoleApis.addAdminRoleApi, action.data);
    console.log(res, "AddAdminRole");
    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res.data);
      console.log(res.data);
    } else if (res.status === 200 && res.data.status === "fail") {
      console.log(res.data);
      yield action.onError(res);
    } else {
      yield action.onError("Something Went Wrong");
    }
  } catch (err) {
    console.log(err);
    yield action.onError("Something Went Wrong");
  }
}

export function* viewAdminRoleWorker(action) {
  try {
    const res = yield call(adminRoleApis.viewAdminRoleApi, action.id);
    // console.log(res, "AddAdminRole");
    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res.data);
      console.log(res.data);
    } else if (res.status === 200 && res.data.status === "fail") {
      console.log(res.data);
      yield action.onError(res);
    } else {
      yield action.onError("Something Went Wrong!");
      console.log(res.data);
    }
  } catch (err) {
    console.log(err);
    yield action.onError("Something Went Wrong!!");
  }
}
export function* editAdminRoleWorker(action) {
  try {
    const res = yield call(adminRoleApis.editAdminRoleApi, action.id, {
      rolename: action.roleName,
      permissions: action.permissions,
    });
    console.log(res, "AddAdminRole");
    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res.data);
      console.log(res.data);
    } else if (res.status === 200 && res.data.status === "fail") {
      console.log(res);
      yield action.onError(res);
    } else {
      yield action.onError("Something Went Wrong");
    }
  } catch (err) {
    console.log(err);
    yield action.onError("Something Went Wrong");
  }
}
export function* deleteAdminRoleWorker(action) {
  try {
    const res = yield call(adminRoleApis.deleteAdminRoleApi, action.id);
    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res.data);
      console.log(res.data);
    } else if (res.status === 200 && res.data.status === "fail") {
      console.log(res);
      yield action.onError(res);
    } else {
      yield action.onError("Something Went Wrong");
    }
  } catch (err) {
    console.log(err);
    yield action.onError("Something Went Wrong");
  }
}
export function* viewAllAdminRoleWorker(action) {
  try {
    const res = yield call(
      adminRoleApis.viewAllAdminRoleApi,
      action.pageNo,
      action.data
    );
    // console.log(res, "AddAdminRole");
    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res.data);
      console.log(res);
      console.log(res, "AddAdminRole");
    } else if (res.status === 200 && res.data.status === "fail") {
      console.log(res);
      yield action.onError(res.data);
    } else {
      yield action.onError("Something Went Wrong");
    }
  } catch (err) {
    console.log(err);
    yield action.onError("Something Went Wrong");
  }
}

export function* approveAdminRoleWorker(action) {
  try {
    const res = yield call(adminRoleApis.approveAdminRoleApi, {
      role_id: action.id,
    });
    // console.log(res, "AddAdminRole");
    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res.data);
      console.log(res.data);
    } else if (res.status === 200 && res.data.status === "fail") {
      console.log(res);
      yield action.onError(res.data);
    } else {
      yield action.onError("Something Went Wrong!");
      console.log(res.data);
    }
  } catch (err) {
    console.log(err);
    yield action.onError("Something Went Wrong!!");
  }
}

export function* rejectAdminRoleWorker(action) {
  try {
    const res = yield call(adminRoleApis.rejectAdminRoleApi, action.id);
    // console.log(res, "AddAdminRole");
    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res.data);
      console.log(res.data);
    } else if (res.status === 200 && res.data.status === "fail") {
      console.log(res);
      yield action.onError(res.data);
    } else {
      yield action.onError("Something Went Wrong!");
      console.log(res.data);
    }
  } catch (err) {
    console.log(err);
    yield action.onError("Something Went Wrong!!");
  }
}
export function* approvalsListAdminRoleWorker(action) {
  try {
    const res = yield call(
      adminRoleApis.approvalsListAdminRoleApi,
      action.page,
      action.data
    );
    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res.data);
      console.log(res.data);
    } else if (res.status === 200 && res.data.status === "fail") {
      console.log(res);
      yield action.onError(res);
    } else {
      yield action.onError("Something Went Wrong!");
      console.log(res.data);
    }
  } catch (err) {
    console.log(err);
    yield action.onError("Something Went Wrong!!");
  }
}
export function* viewApprovalAdminRoleWorker(action) {
  try {
    const res = yield call(adminRoleApis.viewApprovalsAdminRoleApi, action.id);
    // console.log(res, "AddAdminRole");
    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res.data);
      console.log(res.data);
    } else if (res.status === 200 && res.data.status === "fail") {
      console.log(res);
      yield action.onError(res);
    } else {
      yield action.onError("Something Went Wrong!");
      console.log(res.data);
    }
  } catch (err) {
    console.log(err);
    yield action.onError("Something Went Wrong!!");
  }
}
export function* versionHistoryListAdminRoleWorker(action) {
  try {
    const res = yield call(
      adminRoleApis.versionHistoryListAdminRoleApi,
      action.pageNo,
      action.data
    );
    // console.log(res, "AddAdminRole");
    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res.data);
      console.log(res.data);
    } else if (res.status === 200 && res.data.status === "fail") {
      console.log(res);
      yield action.onError(res);
    } else {
      yield action.onError("Something Went Wrong!");
      console.log(res.data);
    }
  } catch (err) {
    console.log(err);
    yield action.onError("Something Went Wrong!!");
  }
}
export function* viewVersionHistoryAdminRoleWorker(action) {
  try {
    const res = yield call(
      adminRoleApis.viewVersionHistoryAdminRoleApi,
      action.id
    );
    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res);
      console.log(res);
    } else if (res.status === 200 && res.data.status === "fail") {
      console.log(res);
      yield action.onError(res);
    } else {
      yield action.onError("Something Went Wrong!");
      console.log(res);
    }
  } catch (err) {
    console.log(err);
    yield action.onError("Something Went Wrong!!");
  }
}

export function* adminRoleSubmitWorker(action) {
  try {
    const res = yield call(adminRoleApis.adminRoleSubmitApi, action.id);
    console.log(res);
    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res.data);
    }
  } catch (error) {}
}

/**********************************/
/************WATHCHERS**************/
/**********************************/
//export
export function* rolesTableExportWorker(action) {
  try {
    const res = yield call(adminRoleApis.rolesTableExportApi);
    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res.data);
    }
  } catch (error) {}
}

export function* rolesApprovalsExportWorker(action) {
  try {
    const res = yield call(adminRoleApis.rolesApprovalsExportApi);
    console.log(res);
    if (res.status === 200 && res.data.status === "success") {
      console.log(res.data);
      yield action.onSuccess(res.data.data);
    } else {
      yield action.onError(res.data);
    }
  } catch (error) {}
}

export function* rolesVersionExportWorker(action) {
  try {
    const res = yield call(adminRoleApis.rolesVersionExportApi);
    console.log(res);
    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res.data);
    }
  } catch (error) {}
}

export function* watchAddAdminRoleWorker() {
  yield takeLatest(ADMIN_ADD_ROLE, addAdminRoleWorker);
}
export function* watchViewAllAdminRoleWorker() {
  yield takeLatest(ADMIN_VIEW_ROLES, viewAllAdminRoleWorker);
}
export function* watchViewAdminRoleWorker() {
  yield takeLatest(ADMIN_VIEW_ROLE, viewAdminRoleWorker);
}
export function* watchEditAdminRoleWorker() {
  yield takeLatest(ADMIN_EDIT_ROLE, editAdminRoleWorker);
}
export function* watchApproveAdminRoleWorker() {
  yield takeLatest(ADMIN_APPROVE_ROLE, approveAdminRoleWorker);
}
export function* watchRejectAdminRoleWorker() {
  yield takeLatest(ADMIN_REJECT_ROLE, rejectAdminRoleWorker);
}
export function* watchApprovalsListAdminRoleWorker() {
  yield takeLatest(ADMIN_APPROVALS_LIST_ROLE, approvalsListAdminRoleWorker);
}
export function* watchViewApprovalAdminRoleWorker() {
  yield takeLatest(ADMIN_APPROVALS_VIEW, viewApprovalAdminRoleWorker);
}
export function* watchDeleteAdminRoleWorker() {
  yield takeLatest(ADMIN_DELETE_ROLE, deleteAdminRoleWorker);
}
export function* watchVersionHistoryListAdminRoleWorker() {
  yield takeLatest(
    ADMIN_VERSION_HISTORY_LIST_ROLE,
    versionHistoryListAdminRoleWorker
  );
}
export function* watchViewVersionHistoryAdminRoleWorker() {
  yield takeLatest(
    ADMIN_VERSION_HISTORY_VIEW_ROLE,
    viewVersionHistoryAdminRoleWorker
  );
}

//export
export function* rolesTableExportWatcher() {
  yield takeLatest(ROLES_EXPORT, rolesTableExportWorker);
}
export function* rolesApprovalsExportWatcher() {
  yield takeLatest(ROLES_APPROVALS_EXPORT, rolesApprovalsExportWorker);
}

export function* rolesVersionExportWorkerWatcher() {
  yield takeLatest(ROLES_VERSION_EXPORT, rolesVersionExportWorker);
}
export function* watchAdminRoleSubmitWorker() {
  yield takeLatest(ADMIN_ROLE_SUBMIT, adminRoleSubmitWorker);
}
