import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import InnerLayout from "../../../../components/layouts/innerLayout/innerLayout";
import InnerContainer from "../../../../components/layouts/innerContainer/innerContainer";
import VersionHistoryBtn from "../../../../components/buttons/versionHistoryBtn";
import RejectBtn from "../../../../components/buttons/rejectBtn";
import ApproveBtn from "../../../../components/buttons/approveBtn";
import errorToast from "../../../../components/utilits/errorToast";
import successToast from "../../../../components/utilits/successToast";
import moment from "moment";
import HowItWorksReasonTable from "../../../../components/howItWorks/howItWorks/tables/howItWorksReasonTable";
import DownloadIcon from "../../../../assets/icons/downloadIcon";
import LoadAndError from "../../../../components/utilits/LoadAndError";
import {
  approvalsViewInsightsAction,
  approveInsightsAction,
  rejectInsightsAction,
} from "../../../../redux/actions/insightsAction";

const InsightsApprovalDetails = () => {
  const navigate = useNavigate();
  const [insightsData, setInsightsData] = useState({});
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const params = useParams();
  const [insightsDataId, SetInsightsDataId] = useState({ id: "" });

  useEffect(() => {
    setLoading(true);
    dispatch(approvalsViewInsightsAction(params.id, onSuccess, onError));
  }, [params.id]);
  const onSuccess = (data) => {
    SetInsightsDataId(data);
    setInsightsData(data?.data);
    console.log(data.data);
    setLoading(false);
    setError(false);
  };
  const onError = (data) => {
    errorToast(data.message);
    console.log(data, "error");
    setLoading(false);
    setError(true);
  };

  // Approve Notify Me
  const [updated, setUpdated] = useState(false);

  const approveInsightsFn = () => {
    dispatch(
      approveInsightsAction(params.id, onApproveSuccess, onApproveError)
    );
  };

  const onApproveSuccess = (data) => {
    navigate("/static-pages/insights/approvals");
    successToast(data.message);
    setLoading(false);
    setError(false);
  };
  const onApproveError = (data) => {
    console.log(data);
    errorToast(data.data + " " + data.message);
    setLoading(false);
    setError(true);
  };
  // Reject  Notify Me Api
  const rejectInsightsFn = () => {
    dispatch(rejectInsightsAction(params.id, onRejectSuccess, onRejectError));
  };

  const onRejectSuccess = (data) => {
    successToast(data.message);
    navigate("/static-pages/insights/approvals");
    setLoading(false);
    setError(false);
  };
  const onRejectError = (data) => {
    console.log(data);
    errorToast(data.data + " " + data.message);
    setLoading(false);
    setError(true);
  };

  function ViewItemGenerator(title, details) {
    return (
      <>
        <span className="dark_grey_color">{title}</span>
        <p className="dark_title_color">{details}</p>
      </>
    );
  }
  const data1 = [
    {
      heading: "Approval ID",
      title: insightsDataId?.data?.id,
    },
  ];

  const buttonList = [
    <VersionHistoryBtn link={`/static-pages/insights/version-history`} />,
    <ApproveBtn approveFn={approveInsightsFn} />,
    <RejectBtn showModal={rejectInsightsFn} />,
  ];
  return (
    <InnerLayout buttons={buttonList}>
      <div className="user_view_container mb-4">
        <InnerContainer error={error} oldData={insightsData?.old_data} newData={insightsData?.new_data}>
          <LoadAndError loader={loading} error={error}>
            <div className="d-flex flex-column flex-lg-row gap-4 fs_14 py-3 px-3 px-lg-5">
              <div className="col-12 col-lg-6 pe-0 pe-lg-5">
              {data1.map((item) => (
                  <div className="mb-2">
                    <h5 className=" fs_13 fn_Montserrat fw_500 m-0">
                      {item.heading}
                    </h5>
                    <>
                      {" "}
                      <p
                        className={` fs_13 fn_Nunito fw_500  ${
                          item.heading === "LinkedIn URL" ||
                          item.heading === "User ID"
                            ? "secondary_color"
                            : "dark_title_color"
                        } `}
                      >
                        {item.title}
                      </p>
                    </>
                  </div>
                ))}
                <div>
                  {ViewItemGenerator(
                    "Insights Id",
                    insightsData?.new_data === null ?
                    insightsData?.old_data?.id :
                    insightsData?.new_data?.id
                  )}
                </div>
                <div>
                  {ViewItemGenerator(
                    "Header Title",
                    insightsData?.new_data === null ?
                    insightsData?.old_data?.header_title :
                    insightsData?.new_data?.header_title
                  )}
                </div>
                <div>
                  {ViewItemGenerator(
                    "Insights Heading",
                    insightsData?.new_data === null ?
                    insightsData?.old_data?.section1_heading :
                    insightsData?.new_data?.section1_heading
                  )}
                </div>
                <div>
                  {ViewItemGenerator(
                    "Insights Title",
                    insightsData?.new_data === null ?
                    insightsData?.old_data?.section1_title :
                    insightsData?.new_data?.section1_title
                  )}
                </div>
                <div>
                  {ViewItemGenerator(
                    "Video Title",
                    insightsData?.new_data === null ?
                    insightsData?.old_data?.section3_heading :
                    insightsData?.new_data?.section3_heading
                  )}
                </div>
                <div>
                  {ViewItemGenerator("Video URL", insightsData?.video_url)}
                </div>
                <div>
                  {ViewItemGenerator(
                    "Video description",
                    insightsData?.new_data === null ?
                    insightsData?.old_data?.section3_title :
                    insightsData?.new_data?.section3_title
                  )}
                </div>
                <div>
                  {ViewItemGenerator(
                    "Insights Category Title",
                    insightsData?.new_data === null ?
                    insightsData?.old_data?.section2_heading :
                    insightsData?.new_data?.section2_heading
                  )}
                </div>
                <div>
                  {ViewItemGenerator(
                    "Insights Category Subtitle",
                    insightsData?.new_data === null ?
                    insightsData?.old_data?.section2_title :
                    insightsData?.new_data?.section2_title
                  )}
                </div>
                <div>
                  {ViewItemGenerator(
                    "Opportunities Heading",
                    insightsData?.new_data === null ?
                    insightsData?.old_data?.section4_heading :
                    insightsData?.new_data?.section4_heading
                  )}
                </div>
                <div>
                  {ViewItemGenerator(
                    "Opportunities Title",
                    insightsData?.new_data === null ?
                    insightsData?.old_data?.section4_title :
                    insightsData?.new_data?.section4_title
                  )}
                </div>
              </div>
              <div className="col-12 col-lg-6  pe-0 pe-lg-5">
                <HowItWorksReasonTable
                  name="reasons"
                  formikValues={insightsData}
                  editable={false}
                />
                <div className="d-flex gap-5 flex-wrap mb-2">
                  <div>
                    <span className="">Thumb Nail Image</span>{" "}
                    <div className="blog_create_image_container mt-2 position-relative d-flex  justify-content-center align-items-center">
                      <img
                        src=
                        {insightsData?.new_data === null ?
                    insightsData?.old_data?.video_tumbnail_image :
                    insightsData?.new_data?.video_tumbnail_image}
                        className={"user-edit-profile-picture"}
                      />
                      <button
                        type="button"
                        className="position-absolute border-0 bg-white d-flex align-items-center fs_10 top-0 end-0"
                      >
                        <a
                          href=
                          {insightsData?.new_data === null ?
                            insightsData?.old_data?.video_tumbnail_image :
                            insightsData?.new_data?.video_tumbnail_image}
                          download="file"
                        ></a>
                        <DownloadIcon width={8} />
                        <span className="ms-1">Download</span>
                      </button>
                    </div>
                  </div>
                </div>
                <div>
                  {ViewItemGenerator(
                    "Approved At",
                    insightsData?.approvedAt ? insightsData?.approvedAt : "-"
                  )}
                </div>
                <div>
                  {ViewItemGenerator(
                    "Updated At",
                    moment(insightsData?.updatedAt).format(
                      "DD-MM-YYYY HH:mm:ss"
                    )
                  )}
                </div>
              </div>
            </div>
          </LoadAndError>
        </InnerContainer>
      </div>
    </InnerLayout>
  );
};

export default InsightsApprovalDetails;
