import { MEMTRADE_SELL_APPROVALS_LIST, MEMTRADE_SELL_APPROVALS_VIEW, MEMTRADE_SELL_APPROVE, MEMTRADE_SELL_CREATE, MEMTRADE_SELL_DELETE, MEMTRADE_SELL_EDIT, MEMTRADE_SELL_LIST, MEMTRADE_SELL_READ, MEMTRADE_SELL_REJECT, MEMTRADE_SELL_VERSION_LIST, MEMTRADE_SELL_FIND_ALL, VIEW_APPROVALS_MEMTRADE_SELL_ID, MEMTRADE_SELL_VERSION_HISTORY_BY_ID, MEMTRADE_SELL_EXPORT, MEMTRADE_SELL_APPROVALS_EXPORT, MEMTRADE_SELL_VERSION_EXPORT, MEMTRADE_SELL_SUBMIT } from "./types";

export const createMemTradeSell = (data, onSuccess, onError) => {
    return {
        type: MEMTRADE_SELL_CREATE,
        data,
        onSuccess,
        onError,
    };
};
export const viewAllMemTradeSell = (data, page_no = 0, onSuccess, onError) => {
    return {
        type: MEMTRADE_SELL_LIST,
        data,
        page_no,
        onSuccess,
        onError,
    };
};

export const viewMemTradeSell = (id, onSuccess, onError) => {
    return {
        type: MEMTRADE_SELL_READ,
        id,
        onSuccess,
        onError,
    };
}
export const editMemTradeSell = (id, data, onSuccess, onError) => {
    return {
        type: MEMTRADE_SELL_EDIT,
        id,
        data,
        onSuccess,
        onError,
    };
}
export const approvalsMemTradeSell = (data, pageNo = 0, onSuccess, onError) => {
    return {
        type: MEMTRADE_SELL_APPROVALS_LIST,
        data,
        pageNo,
        onSuccess,
        onError,
    };
}
export const approvalsViewMemTradeSell = (id, onSuccess, onError) => {
    return {
        type: MEMTRADE_SELL_APPROVALS_VIEW,
        id,
        onSuccess,
        onError,
    };
}
export const approveMemTradeSell = (id, onSuccess, onError) => {
    return {
        type: MEMTRADE_SELL_APPROVE,
        id,
        onSuccess,
        onError,
    };
}
export const rejectMemTradeSell = (id, onSuccess, onError) => {
    return {
        type: MEMTRADE_SELL_REJECT,
        id,
        onSuccess,
        onError,
    };
}
export const deleteMemTradeSell = (id, onSuccess, onError) => {
    return {
        type: MEMTRADE_SELL_DELETE,
        id,
        onSuccess,
        onError,
    };
}
export const versionHistoryListMemTradeSell = (data, pageNo, onSuccess, onError) => {
    return {
        type: MEMTRADE_SELL_VERSION_LIST,
        data,
        pageNo,
        onSuccess,
        onError,
    };
}
export const viewVersionMemTradeSell = (id, onSuccess, onError) => {
    return {
        type: MEMTRADE_SELL_APPROVALS_VIEW,
        id,
        onSuccess,
        onError,
    };
}
//For dropdown of memTradeSell
export const viewMemTradeSellFindAll = (onSuccess, onError) => {
    return {
        type: MEMTRADE_SELL_FIND_ALL,
        onSuccess,
        onError,
    };
}

export const viewApprovalMemTradeSellById = (id, onSuccess, onError) => {
    return {
        type: VIEW_APPROVALS_MEMTRADE_SELL_ID,
        id,
        onSuccess,
        onError,
    };
}

export const viewVersionHistoryMemTradeSellById = (id, onSuccess, onError) => {
    return {
        type: MEMTRADE_SELL_VERSION_HISTORY_BY_ID,
        id,
        onSuccess,
        onError,
    };
}


export const memTradeSellTableExport = (onSuccess, onError) => {
    return {
        type: MEMTRADE_SELL_EXPORT,
        onSuccess,
        onError,
    };
};
export const memTradeSellApprovalsExport = (onSuccess, onError) => {
    return {
        type: MEMTRADE_SELL_APPROVALS_EXPORT,
        onSuccess,
        onError,
    };
};
export const memTradeSellVersionExport = (onSuccess, onError) => {
    return {
        type: MEMTRADE_SELL_VERSION_EXPORT,
        onSuccess,
        onError,
    };
};
export const memTradeSellSubmitAction = (id, onSuccess, onError) => {
    return {
      type: MEMTRADE_SELL_SUBMIT,
      id,
      onSuccess,
      onError,
    };
  };