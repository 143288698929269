import styled from "@emotion/styled";
import React from "react";
import "./innerLayout.css";
import useBreadcrumbs from "use-react-router-breadcrumbs";
import { NavLink, useLocation } from "react-router-dom";
const InnerLayout = ({ children, buttons = [], containerWidth = "93%" }) => {
  const InnerLayerContainer = styled("div")(
    {
      flexWrap: "wrap",
      width: "88%",
      maxWidth: "1700px",
    },
    (props) => ({
      width: props.width,
    })
  );

  const location = useLocation();
  const DynamicUserBreadcrumb = ({ match }) => (
    <span>
      {match?.route?.path?.endsWith("view/:id")
        ? "/ VIEW / "
        : match?.route?.path?.endsWith("/analytics/:id")
        ? "/ ANALYTICS / "
        : "/ EDIT / "}
      {match?.params?.id}
    </span>
  );

  const routes = [
    // { path:"/",  breadcrumb: " ADMIN" },
    // **ROUTES FOR ADMIN PAGES**
    { path: "/admin-users", breadcrumb: " / ADMIN USERS " },
    { path: "/admin-users/create", breadcrumb: " / CREATE " },
    { path: "/admin-users/edit/:id", breadcrumb: DynamicUserBreadcrumb },
    { path: "/admin-users/view/:id", breadcrumb: DynamicUserBreadcrumb },
    { path: "/admin-users/approvals", breadcrumb: " / APPROVALS " },
    {
      path: "/admin-users/approvals/view/:id",
      breadcrumb: DynamicUserBreadcrumb,
    },
    { path: "/admin-users/version-history", breadcrumb: " / VERSION HISTORY " },
    {
      path: "/admin-users/version-history/view/:id",
      breadcrumb: DynamicUserBreadcrumb,
    },
    // **ROUTES FOR ADMIN ROLES PAGES**
    { path: "/admin-roles", breadcrumb: " / ROLES " },
    { path: "/admin-roles/create", breadcrumb: " / CREATE " },
    { path: "/admin-roles/edit/:id", breadcrumb: DynamicUserBreadcrumb },
    { path: "/admin-roles/view/:id", breadcrumb: DynamicUserBreadcrumb },
    { path: "/admin-roles/version-history", breadcrumb: " / VERSION HISTORY " },
    {
      path: "/admin-roles/version-history/view/:id",
      breadcrumb: DynamicUserBreadcrumb,
    },
    { path: "/admin-roles/approvals", breadcrumb: " / APPROVALS " },
    {
      path: "/admin-roles/approvals/view/:id",
      breadcrumb: DynamicUserBreadcrumb,
    },

    // ********************************************
    // **ROUTES FOR USER PAGES**
    { path: "/user/user-list", breadcrumb: " / USER-LIST " },
    { path: "/", breadcrumb: null },
    {
      path: "/user/reset-password-email",
      breadcrumb: " / RESET PASSWORD EMAIL ",
    },
    { path: "/user/user-list/create", breadcrumb: " / CREATE " },
    { path: "/user/user-list/edit/:id", breadcrumb: DynamicUserBreadcrumb },
    { path: "/user/user-list/view/:id", breadcrumb: DynamicUserBreadcrumb },
    { path: "/user/email-otp", breadcrumb: " / EMAIL OTP " },
    { path: "/user/phone-otp", breadcrumb: " / PHONE OTP " },
    { path: "/user/user-list/approvals", breadcrumb: " / APPROVALS " },
    {
      path: "/user/user-list/approvals/view/:id",
      breadcrumb: DynamicUserBreadcrumb,
    },
    {
      path: "/user/user-list/version-history",
      breadcrumb: " / VERSION HISTORY ",
    },
    {
      path: "/user/user-list/version-history/view/:id",
      breadcrumb: DynamicUserBreadcrumb,
    },

    // ********************************************
    // **ROUTES FOR OPPORTUNITY PAGES**
    // { path: "/", breadcrumb: "HOME" },
    // **ROUTES FOR OPPORTUNITIES PAGES**
    { path: "/opportunity/opportunities", breadcrumb: " / OPPORTUNITIES " },
    { path: "/opportunity/opportunities/create", breadcrumb: " / CREATE " },
    {
      path: "/opportunity/opportunities/edit/:id",
      breadcrumb: DynamicUserBreadcrumb,
    },
    {
      path: "/opportunity/opportunities/analytics/:id",
      breadcrumb: DynamicUserBreadcrumb,
    },
    {
      path: "/opportunity/opportunities/view/:id",
      breadcrumb: DynamicUserBreadcrumb,
    },
    {
      path: "/opportunity/opportunities/version-history",
      breadcrumb: "/ VERSION HISTORY ",
    },
    {
      path: "/opportunity/opportunities/approvals",
      breadcrumb: "/ APPROVALS ",
    },
    {
      path: "/opportunity/opportunities/approvals/view/:id",
      breadcrumb: DynamicUserBreadcrumb,
    },
    {
      path: "/opportunity/opportunities/version-history/view/:id",
      breadcrumb: DynamicUserBreadcrumb,
    },
    // **ROUTES FOR OPPORTUNITY TYPE PAGES**
    {
      path: "/opportunity/opportunity-type",
      breadcrumb: " / OPPORTUNITY TYPES ",
    },
    { path: "/opportunity/opportunity-type/create", breadcrumb: "/ CREATE " },
    {
      path: "/opportunity/opportunity-type/edit/:id",
      breadcrumb: DynamicUserBreadcrumb,
    },
    {
      path: "/opportunity/opportunity-type/view/:id",
      breadcrumb: DynamicUserBreadcrumb,
    },
    {
      path: "/opportunity/opportunity-type/version-history",
      breadcrumb: "/ VERSION HISTORY ",
    },
    {
      path: "/opportunity/opportunity-type/approvals",
      breadcrumb: "/ APPROVALS ",
    },
    {
      path: "/opportunity/opportunity-type/approvals/view/:id",
      breadcrumb: DynamicUserBreadcrumb,
    },
    {
      path: "/opportunity/opportunity-type/version-history/view/:id",
      breadcrumb: DynamicUserBreadcrumb,
    },
    // *ROUTES FOR WISHLIST PAGES*
    { path: "/opportunity/wishlist", breadcrumb: " / WISHLIST " },
    { path: "/opportunity/wishlist/create", breadcrumb: "/ CREATE " },
    {
      path: "/opportunity/wishlist/edit/:id",
      breadcrumb: DynamicUserBreadcrumb,
    },
    {
      path: "/opportunity/wishlist/view/:id",
      breadcrumb: DynamicUserBreadcrumb,
    },
    { path: "/opportunity/wishlist/approvals", breadcrumb: "/ APPROVALS " },
    {
      path: "/opportunity/wishlist/approvals/view/:id",
      breadcrumb: DynamicUserBreadcrumb,
    },
    {
      path: "/opportunity/wishlist/version-history",
      breadcrumb: "/ VERSION HISTORY ",
    },
    {
      path: "/opportunity/wishlist/version-history/view/:id",
      breadcrumb: DynamicUserBreadcrumb,
    },
    // *ROUTES FOR NOTIFY ME PAGES*
    { path: "/opportunity/notify-me", breadcrumb: " / NOTIFY ME " },
    { path: "/opportunity/notify-me/create", breadcrumb: "/ CREATE " },
    {
      path: "/opportunity/notify-me/edit/:id",
      breadcrumb: DynamicUserBreadcrumb,
    },
    {
      path: "/opportunity/notify-me/view/:id",
      breadcrumb: DynamicUserBreadcrumb,
    },
    { path: "/opportunity/notify-me/approvals", breadcrumb: "/ APPROVALS " },
    {
      path: "/opportunity/notify-me/approvals/view/:id",
      breadcrumb: DynamicUserBreadcrumb,
    },
    {
      path: "/opportunity/notify-me/version-history",
      breadcrumb: "/ VERSION HISTORY ",
    },
    {
      path: "/opportunity/notify-me/version-history/view/:id",
      breadcrumb: DynamicUserBreadcrumb,
    },
    // *ROUTES FOR MEMBER TRADE PAGES*
    { path: "/opportunity/member-trade", breadcrumb: " / MEMBER TRADE " },
    { path: "/opportunity/member-trade/create", breadcrumb: "/ CREATE " },
    {
      path: "/opportunity/member-trade/edit/:id",
      breadcrumb: DynamicUserBreadcrumb,
    },
    {
      path: "/opportunity/member-trade/view/:id",
      breadcrumb: DynamicUserBreadcrumb,
    },
    { path: "/opportunity/member-trade/approvals", breadcrumb: "/ APPROVALS " },
    {
      path: "/opportunity/member-trade/approvals/view/:id",
      breadcrumb: DynamicUserBreadcrumb,
    },
    {
      path: "/opportunity/member-trade/version-history",
      breadcrumb: "/ VERSION HISTORY ",
    },
    {
      path: "/opportunity/member-trade/version-history/view/:id",
      breadcrumb: DynamicUserBreadcrumb,
    },
    // *ROUTES FOR LLP PAGES*
    { path: "/opportunity/llp", breadcrumb: " / LLP " },
    { path: "/opportunity/llp/create", breadcrumb: "/ CREATE " },
    { path: "/opportunity/llp/edit/:id", breadcrumb: DynamicUserBreadcrumb },
    { path: "/opportunity/llp/view/:id", breadcrumb: DynamicUserBreadcrumb },
    { path: "/opportunity/llp/approvals", breadcrumb: "/ APPROVALS " },
    {
      path: "/opportunity/llp/approvals/view/:id",
      breadcrumb: DynamicUserBreadcrumb,
    },
    {
      path: "/opportunity/llp/version-history",
      breadcrumb: "/ VERSION HISTORY ",
    },
    {
      path: "/opportunity/llp/version-history/view/:id",
      breadcrumb: DynamicUserBreadcrumb,
    },

    // *ROUTES FOR EXPRESS INTEREST PAGES*
    {
      path: "/opportunity/express-interest",
      breadcrumb: " / EXPRESS INTEREST ",
    },
    { path: "/opportunity/express-interest/create", breadcrumb: "/ CREATE " },
    {
      path: "/opportunity/express-interest/edit/:id",
      breadcrumb: DynamicUserBreadcrumb,
    },
    {
      path: "/opportunity/express-interest/view/:id",
      breadcrumb: DynamicUserBreadcrumb,
    },
    {
      path: "/opportunity/express-interest/approvals",
      breadcrumb: "/ APPROVALS ",
    },
    {
      path: "/opportunity/express-interest/approvals/view/:id",
      breadcrumb: DynamicUserBreadcrumb,
    },
    {
      path: "/opportunity/express-interest/version-history",
      breadcrumb: "/ VERSION HISTORY ",
    },
    {
      path: "/opportunity/express-interest/version-history/view/:id",
      breadcrumb: DynamicUserBreadcrumb,
    },

    // ********************************************
    // { path:"/",  breadcrumb: " STATIC PAGES " },
    // *ROUTES FOR OUR TEAM PAGES*
    { path: "/static-pages/our-team", breadcrumb: " / OUR TEAM " },
    { path: "/static-pages/our-team/create", breadcrumb: "/ CREATE " },
    {
      path: "/static-pages/our-team/edit/:id",
      breadcrumb: DynamicUserBreadcrumb,
    },
    {
      path: "/static-pages/our-team/view/:id",
      breadcrumb: DynamicUserBreadcrumb,
    },
    { path: "/static-pages/our-team/approvals", breadcrumb: "/ APPROVALS " },
    {
      path: "/static-pages/our-team//approvals/view/:id",
      breadcrumb: DynamicUserBreadcrumb,
    },
    {
      path: "/static-pages/our-team/version-history",
      breadcrumb: "/ VERSION HISTORY ",
    },
    {
      path: "/static-pages/our-team/version-history/view/:id",
      breadcrumb: DynamicUserBreadcrumb,
    },

    // *ROUTES FOR OUR ADVISORS PAGES*
    { path: "/static-pages/our-advisors", breadcrumb: " / OUR ADVISORS " },
    { path: "/static-pages/our-advisors/create", breadcrumb: "/ CREATE " },
    {
      path: "/static-pages/our-advisors/edit/:id",
      breadcrumb: DynamicUserBreadcrumb,
    },
    {
      path: "/static-pages/our-advisors/view/:id",
      breadcrumb: DynamicUserBreadcrumb,
    },
    {
      path: "/static-pages/our-advisors/approvals",
      breadcrumb: "/ APPROVALS ",
    },
    {
      path: "/static-pages/our-advisors/approvals/view/:id",
      breadcrumb: DynamicUserBreadcrumb,
    },
    {
      path: "/static-pages/our-advisors/version-history",
      breadcrumb: "/ VERSION HISTORY ",
    },
    {
      path: "/static-pages/our-advisors/version-history/view/:id",
      breadcrumb: DynamicUserBreadcrumb,
    },

    // *ROUTES FOR OUR PARTNERS PAGES*
    { path: "/static-pages/our-partners", breadcrumb: " / OUR PARTNERS " },
    { path: "/static-pages/our-partners/create", breadcrumb: "/ CREATE " },
    {
      path: "/static-pages/our-partners/edit/:id",
      breadcrumb: DynamicUserBreadcrumb,
    },
    {
      path: "/static-pages/our-partners/view/:id",
      breadcrumb: DynamicUserBreadcrumb,
    },
    {
      path: "/static-pages/our-partners/approvals",
      breadcrumb: "/ APPROVALS ",
    },
    {
      path: "/static-pages/our-partners/approvals/view/:id",
      breadcrumb: DynamicUserBreadcrumb,
    },
    {
      path: "/static-pages/our-partners/version-history",
      breadcrumb: "/ VERSION HISTORY ",
    },
    {
      path: "/static-pages/our-partners/version-history/view/:id",
      breadcrumb: DynamicUserBreadcrumb,
    },

    // *ROUTES FOR FAQS PAGES*
    { path: "/static-pages/faqs", breadcrumb: " / FAQS " },
    { path: "/static-pages/faqs/create", breadcrumb: "/ CREATE " },
    { path: "/static-pages/faqs/edit/:id", breadcrumb: DynamicUserBreadcrumb },
    { path: "/static-pages/faqs/view/:id", breadcrumb: DynamicUserBreadcrumb },
    { path: "/static-pages/faqs/approvals", breadcrumb: "/ APPROVALS " },
    {
      path: "/static-pages/faqs/approvals/view/:id",
      breadcrumb: DynamicUserBreadcrumb,
    },
    {
      path: "/static-pages/faqs/version-history",
      breadcrumb: "/ VERSION HISTORY ",
    },
    {
      path: "/static-pages/faqs/version-history/view/:id",
      breadcrumb: DynamicUserBreadcrumb,
    },

    // *ROUTES FOR FAQ CATEGORY PAGES*
    { path: "/static-pages/faq-category", breadcrumb: " / FAQ CATEGORY " },
    { path: "/static-pages/faq-category/create", breadcrumb: "/ CREATE " },
    {
      path: "/static-pages/faq-category/edit/:id",
      breadcrumb: DynamicUserBreadcrumb,
    },
    {
      path: "/static-pages/faq-category/view/:id",
      breadcrumb: DynamicUserBreadcrumb,
    },
    {
      path: "/static-pages/faq-category/approvals",
      breadcrumb: "/ APPROVALS ",
    },
    {
      path: "/static-pages/faq-category/approvals/view/:id",
      breadcrumb: DynamicUserBreadcrumb,
    },
    {
      path: "/static-pages/faq-category/version-history",
      breadcrumb: "/ VERSION HISTORY ",
    },
    {
      path: "/static-pages/faq-category/version-history/view/:id",
      breadcrumb: DynamicUserBreadcrumb,
    },

    // *ROUTES FOR TESTIMONIALS PAGES*
    { path: "/static-pages/testimonials", breadcrumb: " / TESTIMONIALS " },
    { path: "/static-pages/testimonials/create", breadcrumb: "/ CREATE " },
    {
      path: "/static-pages/testimonials/edit/:id",
      breadcrumb: DynamicUserBreadcrumb,
    },
    {
      path: "/static-pages/testimonials/view/:id",
      breadcrumb: DynamicUserBreadcrumb,
    },
    {
      path: "/static-pages/testimonials/approvals",
      breadcrumb: "/ APPROVALS ",
    },
    {
      path: "/static-pages/testimonials/approvals/view/:id",
      breadcrumb: DynamicUserBreadcrumb,
    },
    {
      path: "/static-pages/testimonials/version-history",
      breadcrumb: "/ VERSION HISTORY ",
    },
    {
      path: "/static-pages/testimonials/version-history/view/:id",
      breadcrumb: DynamicUserBreadcrumb,
    },

    // *ROUTES FOR MEDIA & RECOGNITIONS PAGES*
    {
      path: "/static-pages/media-recogonition",
      breadcrumb: " / MEDIA & RECOGNITIONS ",
    },
    {
      path: "/static-pages/media-recogonition/create",
      breadcrumb: "/ CREATE ",
    },
    {
      path: "/static-pages/media-recogonition/edit/:id",
      breadcrumb: DynamicUserBreadcrumb,
    },
    {
      path: "/static-pages/media-recogonition/view/:id",
      breadcrumb: DynamicUserBreadcrumb,
    },
    {
      path: "/static-pages/media-recogonition/approvals",
      breadcrumb: "/ APPROVALS ",
    },
    {
      path: "/static-pages/media-recogonition/approvals/view/:id",
      breadcrumb: DynamicUserBreadcrumb,
    },
    {
      path: "/static-pages/media-recogonition/version-history",
      breadcrumb: "/ VERSION HISTORY ",
    },
    {
      path: "/static-pages/media-recogonition/version-history/view/:id",
      breadcrumb: DynamicUserBreadcrumb,
    },

    // *ROUTES FOR CONTACT US QUERY PAGES*
    {
      path: "/static-pages/contact-us-query",
      breadcrumb: " / CONTACT US QUERY ",
    },
    {
      path: "/static-pages/contact-us-query/create",
      breadcrumb: "/ CREATE ",
    },
    {
      path: "/static-pages/contact-us-query/edit/:id",
      breadcrumb: DynamicUserBreadcrumb,
    },
    {
      path: "/static-pages/contact-us-query/view/:id",
      breadcrumb: DynamicUserBreadcrumb,
    },
    {
      path: "/static-pages/contact-us-query/approvals",
      breadcrumb: "/ APPROVALS ",
    },
    {
      path: "/static-pages/contact-us-query/approvals/view/:id",
      breadcrumb: DynamicUserBreadcrumb,
    },
    {
      path: "/static-pages/contact-us-query/version-history",
      breadcrumb: "/ VERSION HISTORY ",
    },
    {
      path: "/static-pages/contact-us-query/version-history/view/:id",
      breadcrumb: DynamicUserBreadcrumb,
    },

    //* ROUTES FOR HOW IT WORKS PAGES*
    { path: "/static-pages/how-It-Works", breadcrumb: " / HOW IT WORKS " },
    {
      path: "/static-pages/how-It-Works/create",
      breadcrumb: "/ CREATE ",
    },
    {
      path: "/static-pages/how-It-Works/edit/:id",
      breadcrumb: DynamicUserBreadcrumb,
    },
    {
      path: "/static-pages/how-It-Works/view/:id",
      breadcrumb: DynamicUserBreadcrumb,
    },
    {
      path: "/static-pages/how-It-Works/approvals",
      breadcrumb: "/ APPROVALS ",
    },
    {
      path: "/static-pages/how-It-Works/approvals/view/:id",
      breadcrumb: DynamicUserBreadcrumb,
    },
    {
      path: "/static-pages/how-It-Works/version-history",
      breadcrumb: "/ VERSION HISTORY ",
    },
    {
      path: "/static-pages/how-It-Works/version-history/view/:id",
      breadcrumb: DynamicUserBreadcrumb,
    },

    //* ROUTES FOR STATIC SCREEN PAGES*

    { path: "/static-pages/static-screen", breadcrumb: " / STATIC SCREEN " },
    {
      path: "/static-pages/static-screen/create",
      breadcrumb: "/ CREATE ",
    },
    {
      path: "/static-pages/static-screen/edit/:id",
      breadcrumb: DynamicUserBreadcrumb,
    },
    {
      path: "/static-pages/static-screen/view/:id",
      breadcrumb: DynamicUserBreadcrumb,
    },
    {
      path: "/static-pages/static-screen/approvals",
      breadcrumb: "/ APPROVALS",
    },
    {
      path: "/static-pages/static-screen/approvals/view/:id",
      breadcrumb: DynamicUserBreadcrumb,
    },
    {
      path: "/static-pages/static-screen/version-history",
      breadcrumb: "/ VERSION HISTORY ",
    },
    {
      path: "/static-pages/static-screen/version-history/view/:id",
      breadcrumb: DynamicUserBreadcrumb,
    },

    // *ROUTES FOR NEWSLETTER PAGES*
    { path: "/static-pages/newsletter", breadcrumb: " / NEWSLETTER " },
    { path: "/static-pages/newsletter/create", breadcrumb: "/ CREATE " },
    {
      path: "/static-pages/newsletter/edit/:id",
      breadcrumb: DynamicUserBreadcrumb,
    },
    {
      path: "/static-pages/newsletter/view/:id",
      breadcrumb: DynamicUserBreadcrumb,
    },
    { path: "/static-pages/newsletter/approvals", breadcrumb: "/ APPROVALS " },
    {
      path: "/static-pages/newsletter/approvals/view/:id",
      breadcrumb: DynamicUserBreadcrumb,
    },
    {
      path: "/static-pages/newsletter/version-history",
      breadcrumb: "/ VERSION HISTORY ",
    },
    {
      path: "/static-pages/newsletter/version-history/view/:id",
      breadcrumb: DynamicUserBreadcrumb,
    },

    // *ROUTES FOR BLOG PAGES*
    { path: "/blog-category", breadcrumb: " / BLOG CATEGORY " },
    { path: "/blog-category/create", breadcrumb: " / CREATE " },
    { path: "/blog-category/edit/:id", breadcrumb: DynamicUserBreadcrumb },
    { path: "/blog-category/view/:id", breadcrumb: DynamicUserBreadcrumb },
    { path: "/blog-category/approval", breadcrumb: " / APPROVALS " },
    {
      path: "/blog-category/approval/view/:id",
      breadcrumb: DynamicUserBreadcrumb,
    },
    { path: "/blog/version-history", breadcrumb: " / VERSION HISTORY " },
    {
      path: "/blog/version-history/version-history-view/:id",
      breadcrumb: DynamicUserBreadcrumb,
    },

    // *ROUTES FOR BLOG CATEGORY PAGES*
    { path: "/blog", breadcrumb: " / BLOG " },
    { path: "/blog/create", breadcrumb: " / CREATE " },
    { path: "/blog/edit/:id", breadcrumb: DynamicUserBreadcrumb },
    { path: "/blog/view/:id", breadcrumb: DynamicUserBreadcrumb },
    { path: "/blog/approval", breadcrumb: " / APPROVALS " },
    { path: "/blog/approval/view/:id", breadcrumb: DynamicUserBreadcrumb },
    {
      path: "/blog-category/version-history",
      breadcrumb: " / VERSION HISTORY ",
    },
    {
      path: "/blog-category/version-history/view/:id",
      breadcrumb: DynamicUserBreadcrumb,
    },

    //* ROUTES FOR INSIGHTS PAGES*
    { path: "/static-pages/insights", breadcrumb: " / INSIGHTS " },
    {
      path: "/static-pages/insights/create",
      breadcrumb: "/ CREATE ",
    },
    {
      path: "/static-pages/insights/edit/:id",
      breadcrumb: DynamicUserBreadcrumb,
    },
    {
      path: "/static-pages/insights/view/:id",
      breadcrumb: DynamicUserBreadcrumb,
    },
    {
      path: "/static-pages/insights/approvals",
      breadcrumb: "/ APPROVALS ",
    },
    {
      path: "/static-pages/insights/approvals/view/:id",
      breadcrumb: DynamicUserBreadcrumb,
    },
    {
      path: "/static-pages/insights/version-history",
      breadcrumb: "/ VERSION HISTORY ",
    },
    {
      path: "/static-pages/insights/version-history/view/:id",
      breadcrumb: DynamicUserBreadcrumb,
    },

    //* ROUTES FOR USER REFERRAL PAGES*
    { path: "/static-pages/userReferral", breadcrumb: " / USER REFERRAL " },
    {
      path: "/static-pages/userReferral/create",
      breadcrumb: "/ CREATE ",
    },
    {
      path: "/static-pages/userReferral/edit/:id",
      breadcrumb: DynamicUserBreadcrumb,
    },
    {
      path: "/static-pages/userReferral/view/:id",
      breadcrumb: DynamicUserBreadcrumb,
    },
    {
      path: "/static-pages/userReferral/approvals",
      breadcrumb: "/ APPROVALS ",
    },
    {
      path: "/static-pages/userReferral/approvals/view/:id",
      breadcrumb: DynamicUserBreadcrumb,
    },
    {
      path: "/static-pages/userReferral/version-history",
      breadcrumb: "/ VERSION HISTORY ",
    },
    {
      path: "/static-pages/userReferral/version-history/view/:id",
      breadcrumb: DynamicUserBreadcrumb,
    },

    //*ROUTES FOR HOME*
    { path: "/static-pages/home", breadcrumb: " / HOME " },
    {
      path: "/static-pages/home/create",
      breadcrumb: "/ CREATE ",
    },
    {
      path: "/static-pages/home/edit/:id",
      breadcrumb: DynamicUserBreadcrumb,
    },
    {
      path: "/static-pages/home/view/:id",
      breadcrumb: DynamicUserBreadcrumb,
    },
    {
      path: "/static-pages/home/approvals",
      breadcrumb: "/ APPROVALS ",
    },
    {
      path: "/static-pages/home/approvals/view/:id",
      breadcrumb: DynamicUserBreadcrumb,
    },
    {
      path: "/static-pages/home/version-history",
      breadcrumb: "/ VERSION HISTORY ",
    },
    {
      path: "/static-pages/home/version-history/view/:id",
      breadcrumb: DynamicUserBreadcrumb,
    },

    //*ROUTES FOR Campaign*
    { path: "/static-pages/campaign", breadcrumb: " / CAMPAIGN " },
    {
      path: "/static-pages/campaign/create",
      breadcrumb: "/ CREATE ",
    },
    {
      path: "/static-pages/campaign/edit/:id",
      breadcrumb: DynamicUserBreadcrumb,
    },
    {
      path: "/static-pages/campaign/view/:id",
      breadcrumb: DynamicUserBreadcrumb,
    },
    {
      path: "/static-pages/campaign/approvals",
      breadcrumb: "/ APPROVALS ",
    },
    {
      path: "/static-pages/campaign/approvals/view/:id",
      breadcrumb: DynamicUserBreadcrumb,
    },
    {
      path: "/static-pages/campaign/version-history",
      breadcrumb: "/ VERSION HISTORY ",
    },
    {
      path: "/static-pages/campaign/version-history/view/:id",
      breadcrumb: DynamicUserBreadcrumb,
    },

    //*ROUTES FOR Campaign Oppor*
    {
      path: "/static-pages/campaignOppor",
      breadcrumb: " / CAMPAIGN OPPORTUNITY ",
    },
    {
      path: "/static-pages/campaignOppor/create",
      breadcrumb: "/ CREATE ",
    },
    {
      path: "/static-pages/campaignOppor/edit/:id",
      breadcrumb: DynamicUserBreadcrumb,
    },
    {
      path: "/static-pages/campaignOppor/view/:id",
      breadcrumb: DynamicUserBreadcrumb,
    },
    {
      path: "/static-pages/campaignOppor/approvals",
      breadcrumb: "/ APPROVALS ",
    },
    {
      path: "/static-pages/campaignOppor/approvals/view/:id",
      breadcrumb: DynamicUserBreadcrumb,
    },
    {
      path: "/static-pages/campaignOppor/version-history",
      breadcrumb: "/ VERSION HISTORY ",
    },
    {
      path: "/static-pages/campaignOppor/version-history/view/:id",
      breadcrumb: DynamicUserBreadcrumb,
    },
    //*ROUTES FOR PARTNER WITH US*
    { path: "/static-pages/partner-with-us", breadcrumb: " / PARTNER WITH US " },
    {
      path: "/static-pages/partner-with-us/create",
      breadcrumb: "/ CREATE ",
    },
    {
      path: "/static-pages/partner-with-us/edit/:id",
      breadcrumb: DynamicUserBreadcrumb,
    },
    {
      path: "/static-pages/partner-with-us/view/:id",
      breadcrumb: DynamicUserBreadcrumb,
    },
    {
      path: "/static-pages/partner-with-us/approvals",
      breadcrumb: "/ APPROVALS ",
    },
    {
      path: "/static-pages/partner-with-us/approvals/view/:id",
      breadcrumb: DynamicUserBreadcrumb,
    },
    {
      path: "/static-pages/partner-with-us/version-history",
      breadcrumb: "/ VERSION HISTORY ",
    },
    {
      path: "/static-pages/partner-with-us/version-history/view/:id",
      breadcrumb: DynamicUserBreadcrumb,
    },

    //*ROUTES FOR PARTNER WITH US FORM*
    { path: "/static-pages/partnerWithUsForm", breadcrumb: " / PARTNER WITH US FORM " },
    {
      path: "/static-pages/partnerWithUsForm/create",
      breadcrumb: "/ CREATE ",
    },
    {
      path: "/static-pages/partnerWithUsForm/edit/:id",
      breadcrumb: DynamicUserBreadcrumb,
    },
    {
      path: "/static-pages/partnerWithUsForm/view/:id",
      breadcrumb: DynamicUserBreadcrumb,
    },
    // {
    //   path: "/static-pages/partnerWithUsForm/approvals",
    //   breadcrumb: "/ APPROVALS ",
    // },
    // {
    //   path: "/static-pages/partnerWithUsForm/approvals/view/:id",
    //   breadcrumb: DynamicUserBreadcrumb,
    // },
    // {
    //   path: "/static-pages/partnerWithUsForm/version-history",
    //   breadcrumb: "/ VERSION HISTORY ",
    // },
    // {
    //   path: "/static-pages/partnerWithUsForm/version-history/view/:id",
    //   breadcrumb: DynamicUserBreadcrumb,
    // },
    /**********REFERRED USERS********/
    { path: "/static-pages/referred-users", breadcrumb: " / REFERRED USERS " },
    {
      path: "/static-pages/referred-users/create",
      breadcrumb: "/ CREATE ",
    },
    {
      path: "/static-pages/referred-users/edit/:id",
      breadcrumb: DynamicUserBreadcrumb,
    },
    {
      path: "/static-pages/referred-users/view/:id",
      breadcrumb: DynamicUserBreadcrumb,
    },
    {
      path: "/static-pages/referred-users/approvals",
      breadcrumb: "/ APPROVALS ",
    },
    {
      path: "/static-pages/referred-users/approvals/view/:id",
      breadcrumb: DynamicUserBreadcrumb,
    },
    {
      path: "/static-pages/referred-users/version-history",
      breadcrumb: "/ VERSION HISTORY ",
    },
    {
      path: "/static-pages/referred-users/version-history/view/:id",
      breadcrumb: DynamicUserBreadcrumb,
    },
    /**********REFERRED USERS********/
    { path: "/static-pages/refer", breadcrumb: " / REFER AND EARN FAQ " },
    {
      path: "/static-pages/refer/create",
      breadcrumb: "/ CREATE ",
    },
    {
      path: "/static-pages/refer/edit/:id",
      breadcrumb: DynamicUserBreadcrumb,
    },
    {
      path: "/static-pages/refer/view/:id",
      breadcrumb: DynamicUserBreadcrumb,
    },
    {
      path: "/static-pages/refer/approvals",
      breadcrumb: "/ APPROVALS ",
    },
    {
      path: "/static-pages/refer/approvals/view/:id",
      breadcrumb: DynamicUserBreadcrumb,
    },
    {
      path: "/static-pages/refer/version-history",
      breadcrumb: "/ VERSION HISTORY ",
    },
    {
      path: "/static-pages/refer/version-history/view/:id",
      breadcrumb: DynamicUserBreadcrumb,
    },

    /**********COMMUNITY BADGES********/
    { path: "/static-pages/community-badges", breadcrumb: " / COMMUNITY BADGES " },
    {
      path: "/static-pages/community-badges/create",
      breadcrumb: "/ CREATE ",
    },
    {
      path: "/static-pages/community-badges/edit/:id",
      breadcrumb: DynamicUserBreadcrumb,
    },
    {
      path: "/static-pages/community-badges/view/:id",
      breadcrumb: DynamicUserBreadcrumb,
    },
    {
      path: "/static-pages/community-badges/approvals",
      breadcrumb: "/ APPROVALS ",
    },
    {
      path: "/static-pages/community-badges/approvals/view/:id",
      breadcrumb: DynamicUserBreadcrumb,
    },
    {
      path: "/static-pages/community-badges/version-history",
      breadcrumb: "/ VERSION HISTORY ",
    },
    {
      path: "/static-pages/community-badges/version-history/view/:id",
      breadcrumb: DynamicUserBreadcrumb,
    },

    /***********INVITED MEMBERS*******/
    { path: "/static-pages/invited", breadcrumb: " / INVITED MEMBERS " },
    {
      path: "/static-pages/invited/create",
      breadcrumb: "/ CREATE ",
    },
    {
      path: "/static-pages/invited/edit/:id",
      breadcrumb: DynamicUserBreadcrumb,
    },
    {
      path: "/static-pages/invited/view/:id",
      breadcrumb: DynamicUserBreadcrumb,
    },
    {
      path: "/static-pages/invited/approvals",
      breadcrumb: "/ APPROVALS ",
    },
    {
      path: "/static-pages/invited/approvals/view/:id",
      breadcrumb: DynamicUserBreadcrumb,
    },
    {
      path: "/static-pages/invited/version-history",
      breadcrumb: "/ VERSION HISTORY ",
    },
    {
      path: "/static-pages/invited/version-history/view/:id",
      breadcrumb: DynamicUserBreadcrumb,
    },

    /***********DASHBOARD FAQ*******/
    { path: "/static-pages/dashboard", breadcrumb: " / DASHBOARD FAQ " },
    {
      path: "/static-pages/dashboard/create",
      breadcrumb: "/ CREATE ",
    },
    {
      path: "/static-pages/dashboard/edit/:id",
      breadcrumb: DynamicUserBreadcrumb,
    },
    {
      path: "/static-pages/dashboard/view/:id",
      breadcrumb: DynamicUserBreadcrumb,
    },
    {
      path: "/static-pages/dashboard/approvals",
      breadcrumb: "/ APPROVALS ",
    },
    {
      path: "/static-pages/dashboard/approvals/view/:id",
      breadcrumb: DynamicUserBreadcrumb,
    },
    {
      path: "/static-pages/dashboard/version-history",
      breadcrumb: "/ VERSION HISTORY ",
    },
    {
      path: "/static-pages/dashboard/version-history/view/:id",
      breadcrumb: DynamicUserBreadcrumb,
    },

    /***********COMMUNITY BADGES FAQ*******/
    { path: "/static-pages/badges", breadcrumb: " / COMMUNITY BADGES FAQ " },
    {
      path: "/static-pages/badges/create",
      breadcrumb: "/ CREATE ",
    },
    {
      path: "/static-pages/badges/edit/:id",
      breadcrumb: DynamicUserBreadcrumb,
    },
    {
      path: "/static-pages/badges/view/:id",
      breadcrumb: DynamicUserBreadcrumb,
    },
    {
      path: "/static-pages/badges/approvals",
      breadcrumb: "/ APPROVALS ",
    },
    {
      path: "/static-pages/badges/approvals/view/:id",
      breadcrumb: DynamicUserBreadcrumb,
    },
    {
      path: "/static-pages/badges/version-history",
      breadcrumb: "/ VERSION HISTORY ",
    },
    {
      path: "/static-pages/badges/version-history/view/:id",
      breadcrumb: DynamicUserBreadcrumb,
    },

    /***********NOTIFICATION*******/
    { path: "/static-pages/notification", breadcrumb: " / NOTIFICATION " },
    {
      path: "/static-pages/notification/create",
      breadcrumb: "/ CREATE ",
    },
    {
      path: "/static-pages/notification/edit/:id",
      breadcrumb: DynamicUserBreadcrumb,
    },
    {
      path: "/static-pages/notification/view/:id",
      breadcrumb: DynamicUserBreadcrumb,
    },
    {
      path: "/static-pages/notification/approvals",
      breadcrumb: "/ APPROVALS ",
    },
    {
      path: "/static-pages/notification/approvals/view/:id",
      breadcrumb: DynamicUserBreadcrumb,
    },
    {
      path: "/static-pages/notification/version-history",
      breadcrumb: "/ VERSION HISTORY ",
    },
    {
      path: "/static-pages/notification/version-history/view/:id",
      breadcrumb: DynamicUserBreadcrumb,
    },


     /***********SOCIAL CONTRIBUTOR*******/
     { path: "/static-pages/social", breadcrumb: " / SOCIAL CONTRIBUTOR " },
     {
       path: "/static-pages/social/create",
       breadcrumb: "/ CREATE ",
     },
     {
       path: "/static-pages/social/edit/:id",
       breadcrumb: DynamicUserBreadcrumb,
     },
     {
       path: "/static-pages/social/view/:id",
       breadcrumb: DynamicUserBreadcrumb,
     },
     {
       path: "/static-pages/social/approvals",
       breadcrumb: "/ APPROVALS ",
     },
     {
       path: "/static-pages/social/approvals/view/:id",
       breadcrumb: DynamicUserBreadcrumb,
     },
     {
       path: "/static-pages/social/version-history",
       breadcrumb: "/ VERSION HISTORY ",
     },
     {
       path: "/static-pages/social/version-history/view/:id",
       breadcrumb: DynamicUserBreadcrumb,
     },

      /***********MEMBER TRADE BUY IN*******/
      { path: "/opportunity/memberTrade-buy-in", breadcrumb: " / MEMBER TRADE BUY IN " },
      {
        path: "/opportunity/memberTrade-buy-in/create",
        breadcrumb: "/ CREATE ",
      },
      {
        path: "/opportunity/memberTrade-buy-in/edit/:id",
        breadcrumb: DynamicUserBreadcrumb,
      },
      {
        path: "/opportunity/memberTrade-buy-in/view/:id",
        breadcrumb: DynamicUserBreadcrumb,
      },
      {
        path: "/opportunity/memberTrade-buy-in/approvals",
        breadcrumb: "/ APPROVALS ",
      },
      {
        path: "/opportunity/memberTrade-buy-in/approvals/view/:id",
        breadcrumb: DynamicUserBreadcrumb,
      },
      {
        path: "/opportunity/memberTrade-buy-in/version-history",
        breadcrumb: "/ VERSION HISTORY ",
      },
      {
        path: "/opportunity/memberTrade-buy-in/version-history/view/:id",
        breadcrumb: DynamicUserBreadcrumb,
      },


      /***********MEMBER TRADE SELL OUT*******/
      { path: "/opportunity/memTradeSell", breadcrumb: " / MEMBER TRADE SELL OUT " },
      {
        path: "/opportunity/memTradeSell/create",
        breadcrumb: "/ CREATE ",
      },
      {
        path: "/opportunity/memTradeSell/edit/:id",
        breadcrumb: DynamicUserBreadcrumb,
      },
      {
        path: "/opportunity/memTradeSell/view/:id",
        breadcrumb: DynamicUserBreadcrumb,
      },
      {
        path: "/opportunity/memTradeSell/approvals",
        breadcrumb: "/ APPROVALS ",
      },
      {
        path: "/opportunity/memTradeSell/approvals/view/:id",
        breadcrumb: DynamicUserBreadcrumb,
      },
      {
        path: "/opportunity/memTradeSell/version-history",
        breadcrumb: "/ VERSION HISTORY ",
      },
      {
        path: "/opportunity/memTradeSell/version-history/view/:id",
        breadcrumb: DynamicUserBreadcrumb,
      },
  ];
  const breadcrumbs = useBreadcrumbs(routes, { disableDefaults: true });

  return (
    <>
      <InnerLayerContainer
        width={containerWidth}
        className={`justify-content-between fn_Montserrat gap-3 mt-4  d-flex align-items-center`}
      >
        <div className="innerLayout_breadcrmub__container">
          <span className={`light_grey_color fn_Montserrat fw_600 fs_14 `}>
            {location.pathname.startsWith("/admin") && "ADMIN"}
            {location.pathname.startsWith("/user") && "USER"}
            {location.pathname.startsWith("/opportunity") && "OPPORTUNITY"}
            {location.pathname.startsWith("/static-pages") && "STATIC PAGES"}
            {location.pathname.startsWith("/blog") && "BLOG"}
            {breadcrumbs.map(({ match, breadcrumb }) => (
              <NavLink
                className={
                  "text-decoration-none fn_Montserrat fs_14 light_grey_color"
                }
                key={match.pathname}
                to={match.pathname}
              >
                {breadcrumb}
              </NavLink>
            ))}
          </span>
        </div>

        <div className="innerLayout_btns__container flex-wrap d-flex gap-3">
          {buttons.map((item, i) => {
            return <React.Fragment key={i + "e"}>{item}</React.Fragment>;
          })}
        </div>
      </InnerLayerContainer>
      {children}
    </>
  );
};

export default InnerLayout;
