import React, { useEffect } from "react";
import { TableContainer } from "../../../table/tableContainer";
import { Link, useNavigate } from "react-router-dom";
import TablePagination from "../../../table/tablePagination";
import "../../../admin/tables/admintable.css";
import * as opporTypeActions from "../../../../redux/actions/opportunityTypeActions";
import {
  tableOpportuniyTypeHeader,
  tableOpportuniyTypeRow,
} from "../../../table/tableData";
import { useDispatch } from "react-redux";
import errorToast from "../../../utilits/errorToast";
import successToast from "../../../utilits/successToast";
import { useState } from "react";
import LoadingSpinnerTable from "../../../utilits/loadingSpinnerTable";
import DeleteModal from "../../../modals/deleteModal";
import { access } from "../../../utilits/access";
import moment from "moment";
import usePermissionChecker from "../../../utilits/usePermissionChecker";
const OpportunityTypeTableContent = ({ page, search, changePage }) => {
  const dispatch = useDispatch();
  const access = usePermissionChecker("maker");
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);
  const [show, setShow] = useState(false);
  const [opporTypeTableData, setOpporTypeTableData] = useState([]);
  const [deleteData, setDeleteData] = useState({ name: "", id: "" });
  const [loading, setLoading] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const navigate = useNavigate();
  const [pageData, setPageData] = useState({ noOfItems: 0, noOfPages: 1 })
  console.log(search);
  useEffect(() => {
    setLoading(true);
    dispatch(
      opporTypeActions.viewAllOpporType(
        {
          search: {
            opportunity_type_id: search?.value?.opportunityId,
            opportunity_type_name: search?.value?.oppName
          }
        },
        page,
        onSuccess,
        onError)
    );
  }, [page, search]);
  const onSuccess = (data) => {
    console.log(data);
    setOpporTypeTableData(data?.data?.data);
    setLoading(false);
    setPageData({
      noOfItems: data?.data?.count,
      noOfPages: data?.data?.pages
    })
  };
  const onError = (data) => {
    errorToast(data.message);
    setLoading(false);
  };
  const handleDelete = () => {
    setLoadingDelete(true);
    dispatch(
      opporTypeActions.deleteOpporType(
        deleteData.id,
        onDeleteSuccess,
        onDeleteError
      )
    );
  };
  const onDeleteSuccess = (data) => {
    setLoadingDelete(false);
    successToast(data.message);
    setShow(false);
  };
  const onDeleteError = (data) => {
    errorToast(data.data.data);
    setLoadingDelete(false);
  };

  function handlePagination(type) {
    if (type === "+") {
      if (page + 1 < pageData.noOfPages) changePage("+");
    }
    else if (type === "-")
      if (page > 0) changePage("-");
  }
  return (
    <TableContainer width="90%">
      <div className="adminuser_table__container">
        {loading && <LoadingSpinnerTable />}
        <table className={"adminuser_table"}>
          <thead className="adminuser_table__header">
            <tr className="adminuser_table__row">
              {tableOpportuniyTypeHeader.map((item) => {
                return (
                  <th
                    key={item}
                    className={`${item === "Status" ? " position-sticky end-0" : null
                      } text-nowrap adminuser_table__heading`}
                  >
                    {item}
                  </th>
                );
              })}
            </tr>
          </thead>
          {loading === false && (
            <tbody className="adminuser_table__body">
              {opporTypeTableData.map((item, index) => {
                return (
                  <tr key={item.id} className="adminuser_table__row">
                    <td className="text-nowrap dark_title_color adminuser_table__data ">
                      {page > 0 ? 12 * page + (index + 1) : index + 1}
                    </td>
                    <td className="text-nowrap dark_title_color adminuser_table__data">
                      {item.id}
                    </td>
                    <td className="text-nowrap secondary_color adminuser_table__data">
                      {item.name}
                    </td>
                    <td className="text-nowrap dark_title_color  adminuser_table__data">
                      {moment(item.createdAt).format("DD-MM-YYYY HH:mm:ss")}
                    </td>
                    <td className="text-nowrap  dark_title_color adminuser_table__data">
                      {moment(item.updatedAt).format("DD-MM-YYYY HH:mm:ss")}
                    </td>
                    <td className="text-nowrap    adminuser_table__data adminrole_table_empty__data"></td>

                    <td className="text-nowrap  position-sticky end-0 adminuser_table__data">
                      <div className="d-flex w-100   gap-2">
                        <Link
                          className="text-decoration-none primary_color"
                          to={{
                            pathname: `/opportunity/opportunity-type/view/${item.id}`,
                          }}
                        >
                          View
                        </Link>{" "}
                        {
                          access && item?.editable && <>
                            <span className="vr"></span>{" "}
                            <Link
                              className="text-decoration-none secondary_color"
                              to={{
                                pathname: `/opportunity/opportunity-type/edit/${item.id}`,
                              }}
                            >
                              Edit
                            </Link>{" "}
                            <span className="vr"></span>{" "}
                            <button
                              onClick={() => {
                                setDeleteData({
                                  name: item.name,
                                  id: item.id,
                                });
                                handleShow();
                              }}
                              className="text-decoration-none primary_color light_red_color border-0 bg-transparent "
                            >
                              Delete
                            </button>
                          </>
                        }
                      </div>
                    </td>



                  </tr>
                );
              })}
            </tbody>
          )}
        </table>
      </div>
      <DeleteModal
        loader={loadingDelete}
        handleClose={handleClose}
        deleteFn={handleDelete}
        data={deleteData}
        show={show}
      />
      <TablePagination
        paginate={handlePagination}
        currentPage={page}
        pageData={pageData}
      />
    </TableContainer>
  );
};

export default OpportunityTypeTableContent;
