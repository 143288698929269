import { authHeader } from "../config";
import { api } from "./api";

export const addLLPApi = (params) => {
  return api.post("/api/llp//create-llp", params, {
    headers: {
      "x-access-token": localStorage.getItem("accessToken"),
      "Content-Type": "application/json",
    },
  });
};
export const viewLLPListApi = (page, params) => {
  return api.post(`/api/llp/views-all-llp-maintable?page_no=${page}`, params, {
    headers: {
      "x-access-token": localStorage.getItem("accessToken"),
      "Content-Type": "application/json",
    },
  });
};
export const viewLLPApi = (id, params) => {
  return api.post(`/api/llp/view-one-llp-maintable/${id}`, params, {
    headers: {
      "x-access-token": localStorage.getItem("accessToken"),
      "Content-Type": "application/json",
    },
  });
};
export const editLLPApi = (id, params) => {
  return api.post(`/api/llp/edit-llp/${id}`, params, {
    headers: {
      "x-access-token": localStorage.getItem("accessToken"),
      "Content-Type": "application/json",
    },
  });
};
export const deleteLLPApi = (id, params) => {
  return api.post(`/api/llp/delete-llp/${id}`, params, {
    headers: {
      "x-access-token": localStorage.getItem("accessToken"),
      "Content-Type": "application/json",
    },
  });
};
export const approveLLPApi = (params) => {
  return api.post("/api/llp/approve-llp", params, {
    headers: {
      "x-access-token": localStorage.getItem("accessToken"),
      "Content-Type": "application/json",
    },
  });
};
export const rejectLLPApi = (id, params) => {
  return api.post(`/api/llp/reject-llp/${id}`, params, {
    headers: {
      "x-access-token": localStorage.getItem("accessToken"),
      "Content-Type": "application/json",
    },
  });
};
export const approvalsLLPListpApi = (page = 0, params) => {
  return api.post(`/api/llp/view-all-llpchecker?page_no=${page}`, params, {
    headers: {
      "x-access-token": localStorage.getItem("accessToken"),
      "Content-Type": "application/json",
    },
  });
};
export const viewApprovalLLPApi = (id, params) => {
  return api.post(`/api/llp/view-one-llpchecker/${id}`, params, {
    headers: {
      "x-access-token": localStorage.getItem("accessToken"),
      "Content-Type": "application/json",
    },
  });
};
export const versionListLLPApi = (page, params) => {
  return api.post(
    `/api/llp/view-all-llp-versions?search=&page_no=${page}`,
    params,
    {
      headers: {
        "x-access-token": localStorage.getItem("accessToken"),
        "Content-Type": "application/json",
      },
    }
  );
};
export const viewVersionLLPApi = (id, params) => {
  return api.post(`/api/llp/view-One-llp-version/${id}`, params, {
    headers: {
      "x-access-token": localStorage.getItem("accessToken"),
      "Content-Type": "application/json",
    },
  });
};

//member details
export const LlpMemberDeleteApi = (id, params) => {
  return api.post(`/api/llp/delete-member-detail/${id}`, params, {
    headers: {
      "x-access-token": localStorage.getItem("accessToken"),
      "Content-Type": "application/json",
    },
  });
};
export const LlpMemberEditApi = (id, params) => {
  return api.post(`/api/llp/edit-member-detail/${id}`, params, {
    headers: {
      "x-access-token": localStorage.getItem("accessToken"),
      "Content-Type": "application/json",
    },
  });
};
export const LlpMemberAddApi = (params) => {
  return api.post(`/api/llp/add-member-detail`, params, {
    headers: {
      "x-access-token": localStorage.getItem("accessToken"),
      "Content-Type": "application/json",
    },
  });
};

//Document
export const LlpAddDocumentApi = (params) => {
  return api.post(`/api/llp/add-document`, params, {
    headers: {
      "x-access-token": localStorage.getItem("accessToken"),
      "Content-Type": "application/json",
    },
  });
};
export const LlpEditDocumentApi = (id, params) => {
  return api.post(`/api/llp/edit-document-detail/${id}`, params, {
    headers: {
      "x-access-token": localStorage.getItem("accessToken"),
      "Content-Type": "application/json",
    },
  });
};
export const LlpDeleteDocumenApi = (id, params) => {
  return api.post(`/api/llp/delete-document/${id}`, params, {
    headers: {
      "x-access-token": localStorage.getItem("accessToken"),
      "Content-Type": "application/json",
    },
  });
};
export const llpSubmitApi = (id, params) => {
  return api.post(`/api/llp/on-submit/${id}`, params, {
    headers: {
      "x-access-token": localStorage.getItem("accessToken"),
      "Content-Type": "application/json",
    },
  });
};

//export

export const llpTableExportApi = (params) => {
  return api.post(`/api/llp/export-main-llp`, JSON.stringify(params), {
    headers: {
      "x-access-token": localStorage.getItem("accessToken"),
      "Content-Type": "application/json",
    },
  });
};
export const llpApprovalsExportApi = (params) => {
  return api.post(`/api/llp/export-approval-llp`, JSON.stringify(params), {
    headers: {
      "x-access-token": localStorage.getItem("accessToken"),
      "Content-Type": "application/json",
    },
  });
};
export const llpVersionExportApi = (params) => {
  return api.post(`/api/llp/export-main-llp`, JSON.stringify(params), {
    headers: {
      "x-access-token": localStorage.getItem("accessToken"),
      "Content-Type": "application/json",
    },
  });
};
export const llpImportUsersApi = (params) => {
  return api.post(`/api/llp/import-llp-users`, params, {
    headers: {
      "x-access-token": localStorage.getItem("accessToken"),
      "Content-Type": "application/json",
    },
  });
};
