import React, { useRef, useState, useEffect } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import InnerLayout from "../../../../components/layouts/innerLayout/innerLayout";
import InnerContainer from "../../../../components/layouts/innerContainer/innerContainer";
import SelectFields from "../../../../components/form/selectFields/selectFields";
import { useParams, useNavigate } from "react-router-dom";
import SaveBtn from "../../../../components/buttons/saveBtn";
import InputField from "../../../../components/form/inputFields/inputField";
import { useDispatch } from "react-redux";
import successToast from "../../../../components/utilits/successToast";
import errorToast from "../../../../components/utilits/errorToast";
import LoadingSpinnerTable from "../../../../components/utilits/loadingSpinnerTable";
import LoadAndError from "../../../../components/utilits/LoadAndError";
import SubmitBtn from "../../../../components/buttons/submit";
import { viewWishListFindAll } from "../../../../redux/actions/wishlistActions";
import {
  communityBadgesSubmitAction,
  deleteCommunityBadgesAction,
  editCommunityBadgesAction,
  viewAllCommunityBadgesAction,
  viewCommunityBadgesAction,
} from "../../../../redux/actions/communityBadgesActions";

const CommunityBadgesEdit = () => {
  const params = useParams();
  const navigate = useNavigate();
  const [submitData, setSubmitData] = useState();
  const [allUsers, setAllUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [deleteData, setDeleteData] = useState();
  const [wishlistData, setWishlistData] = useState([]);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const dispatch = useDispatch();
  const [opporList, setOpporList] = useState([]);
  useEffect(() => {
    setLoading(true);
    dispatch(viewCommunityBadgesAction(params?.id, onSuccess, onError));
    dispatch(viewWishListFindAll(onSuccessDrop, onErrorDrop));
  }, []);

  //For viewing
  const onSuccess = (data) => {
    console.log("Hey", data.data);
    setLoading(false);
    setError(false);
    setWishlistData(data.data);
  };
  const onError = (data) => {
    setLoading(false);
    setError(true);
    errorToast(data.message);
  };
  const onSuccessDrop = (data) => {
    const roleTypes = [];
    data?.data.AllUsers.map((el, index) => {
      roleTypes[index] = { value: el.id, label: el.user_name };
    });
    setAllUsers(roleTypes);
  };
  const onErrorDrop = (data) => {
    console.log("ERROR");
  };
  const formRef = useRef();
  const initialValues = {
    userId: wishlistData?.user_id ? wishlistData?.user_id : "",
    points: wishlistData?.points ? wishlistData?.points : "",
    type: wishlistData?.points_type ? wishlistData?.points_type : "",
  };

  const interestEditSchema = Yup.object().shape({
    userId: Yup.string().trim().required("Required"),
    points: Yup.string().trim().required("Required"),
    type: Yup.string(),
  });

  const handleSubmit = () => {
    if (formRef.current) {
      formRef.current.handleSubmit();
    }
  };
  const onSubmit = (values) => {
    setLoading(true);
    dispatch(
      editCommunityBadgesAction(
        params?.id,
        {
          user_id: values.userId,
          points: values.points,
          points_type: values.type,
        },
        onSuccessEdit,
        onErrorEdit
      )
    );
  };

  //Edit
  const onSuccessEdit = (data) => {
    console.log(data.data);
    setLoading(false);
    setError(false);
    setSubmitData(data.data);
    setSaveHide(true);
    setWishlistData(data.data);
    successToast(data.message);
  };
  const onErrorEdit = (data) => {
    setLoading(false);
    errorToast(data.data.message);
    setError(true);
    // errorToast(data.data.message)
  };

  //For deletion
  const onDeleteSuccess = (data) => {
    console.log(data.data);
    setLoading(false);
    setLoading(false);
    successToast(data.message);
    navigate("/static-pages/community-badges");
  };
  const onDeleteError = (data) => {
    setLoading(false);
    errorToast(data.data.message);
    setError(true);
  };

  const handleDelete = () => {
    setLoadingDelete(true);
    dispatch(
      deleteCommunityBadgesAction(deleteData.id, onDeleteSuccess, onDeleteError)
    );
  };

  //on submit api
  console.log(submitData);
  const [saveHide, setSaveHide] = useState(false);

  const submitFnc = () => {
    dispatch(
      communityBadgesSubmitAction(params.id, onSubmitSuccess, onSubmitError)
    );
  };
  const onSubmitSuccess = (data) => {
    console.log(data);
    successToast(data.message);
    navigate("/static-pages/community-badges");
  };
  const onSubmitError = (data) => {
    console.log("ERROR==================", data);
    errorToast(data.message);
  };

  const defaultPointsType = opporList.filter((item) => {
    return item.value === wishlistData?.points_type;
  });

  const buttonList = [
    !saveHide && <SaveBtn submitFn={handleSubmit} loader={loading} />,
    <SubmitBtn submitFn={submitFnc} saveHide={saveHide} />,
    // <DeleteBtn showModal={handleShow}/>
  ];
  return (
    <InnerLayout buttons={buttonList}>
      <div className="user_view_container">
        <InnerContainer>
          {loading ? (
            <LoadingSpinnerTable />
          ) : (
            <LoadAndError loader={loading} error={error}>
              <div className="row mt-2">
                <div className="col-12 col-sm-12 col-md-9 col-lg-9 col-xl-7">
                  <div className="row ps-1 ms-1 ps-lg-4 ms-lg-1">
                    <div className="ps-2  pt-3">
                      <h5 className="dark_grey_color text-nowrap fs_13 fn_Montserrat fw_500">
                        Badges ID
                      </h5>
                      <p
                        className={` fs_13 fn_Nunito text-nowrap fw_500 secondary_color`}
                      >
                        {wishlistData?.id}
                      </p>

                      <Formik
                        innerRef={formRef}
                        initialValues={initialValues}
                        onSubmit={onSubmit}
                        enableReinitialize={true}
                        validationSchema={interestEditSchema}
                      >
                        {({}) => {
                          return (
                            <Form className="">
                              <div className="express-form-width">
                                <SelectFields
                                  default1={{
                                    label: wishlistData?.user?.user_name,
                                    value: wishlistData?.user_id,
                                  }}
                                  label={"User Name"}
                                  placeholder={"select the user name"}
                                  name={"userId"}
                                  options={allUsers}
                                  id={"userId"}
                                />{" "}
                                <InputField
                                  id={"points"}
                                  label={"Enter Points"}
                                  type={"text"}
                                  placeholder={"Points here"}
                                  name={"points"}
                                />
                                <SelectFields
                                  default1={{
                                    label: wishlistData?.points_type,
                                    value: wishlistData?.points_type,
                                  }}
                                  options={pointsType}
                                  label={"Select type"}
                                  placeholder={"Select the type"}
                                  name={"type"}
                                  id={"type"}
                                />
                              </div>
                            </Form>
                          );
                        }}
                      </Formik>
                    </div>
                  </div>
                </div>
              </div>
            </LoadAndError>
          )}
        </InnerContainer>
      </div>
    </InnerLayout>
  );
};

export default CommunityBadgesEdit;

export const pointsType = [
  { value: "testimonial", label: "Testimonial" },
  { value: "social_post", label: "Social post" },
  { value: "social_contributor", label: "Social contributor" },
  { value: "financial_guru", label: "Financial guru" },
  { value: "community_buider", label: "Community Buider" },
  { value: "market_champion", label: "Market champion" },
];
