import { act } from "react-dom/test-utils";
import { call, takeLatest } from "redux-saga/effects";
import {
  ACTIVATE_USER_REFERRAL_OPTIONS,
  USER_REFERRAL_APPROVALS_EXPORT,
  USER_REFERRAL_APPROVALS_LIST,
  USER_REFERRAL_APPROVALS_VIEW,
  USER_REFERRAL_APPROVE,
  USER_REFERRAL_DELETE,
  USER_REFERRAL_EDIT,
  USER_REFERRAL_LIST,
  USER_REFERRAL_MAIN_EXPORT,
  USER_REFERRAL_READ,
  USER_REFERRAL_REJECT,
  USER_REFERRAL_SUBMIT,
  USER_REFERRAL_VERSION_EXPORT,
  USER_REFERRAL_VERSION_LIST,
  USER_REFERRAL_VERSION_VIEW,
  USER_REFERRAL_CREATE,
  FETCH_OPPORTUNITES_ID,
} from "../actions/types.js";
import {
  // activateUserReferralApi,
  addUserReferralApi,
  approvalsUserReferralListpApi,
  approveUserReferralApi,
  deleteUserReferralApi,
  editUserReferralApi,
  UserReferralApprovalsExportApi,
  UserReferralMainExportApi,
  UserReferralSubmitApi,
  UserReferralVersionExportApi,
  rejectUserReferralApi,
  versionUserReferralpApi,
  viewApprovalUserReferralApi,
  viewUserReferralApi,
  viewUserReferralListApi,
  viewVersionUserReferralApi,
  fetchOpportunitiesIdApi,
} from "../apis/userReferralApis";

export function* addUserReferralWorker(action) {
  try {
    const res = yield call(addUserReferralApi, action.data);
    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res.data);
      console.log(res.data);
    } else if (res.status === 200 && res.data.status === "fail") {
      console.log(res.data);
      yield action.onError(res);
    } else {
      yield action.onError("Something Went Wrong");
    }
  } catch (err) {
    console.log(err);
    yield action.onError("Something Went Wrong");
  }
}

export function* viewUserReferralWorker(action) {
  try {
    const res = yield call(viewUserReferralApi, action.id);
    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res.data);
      console.log(res.data);
    } else if (res.status === 200 && res.data.status === "fail") {
      console.log(res.data);
      yield action.onError(res);
    } else {
      yield action.onError("Something Went Wrong!");
      console.log(res.data);
    }
  } catch (err) {
    console.log(err);
    yield action.onError("Something Went Wrong!!");
  }
}
export function* editUserReferralWorker(action) {
  try {
    const res = yield call(editUserReferralApi, action.id, action.data);
    console.log(res);
    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res.data);
      console.log(res.data);
    } else if (res.status === 200 && res.data.status === "fail") {
      console.log(res);
      yield action.onError(res);
    } else {
      yield action.onError("Something Went Wrong");
    }
  } catch (err) {
    console.log(err);
    yield action.onError("Something Went Wrong");
  }
}
export function* deleteUserReferralRoleWorker(action) {
  try {
    const res = yield call(deleteUserReferralApi, action.id);
    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res.data);
      console.log(res.data);
    } else if (res.status === 200 && res.data.status === "fail") {
      console.log(res.data);
      yield action.onError(res);
    } else {
      yield action.onError("Something Went Wrong");
    }
  } catch (err) {
    console.log(err);
    yield action.onError("Something Went Wrong");
  }
}
export function* viewUserReferralListWorker(action) {
  try {
    const res = yield call(viewUserReferralListApi, action.page, action.data);
    console.log(res);
    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res.data);
      console.log(res.data);
    } else if (res.status === 200 && res.data.status === "fail") {
      console.log(res);
      yield action.onError(res.data);
    } else {
      yield action.onError("Something Went Wrong");
    }
  } catch (err) {
    console.log(err);
    yield action.onError("Something Went Wrong");
  }
}

export function* approveUserReferralWorker(action) {
  try {
    const res = yield call(approveUserReferralApi, { insights_id: action.id });
    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res.data);
      console.log(res.data);
    } else if (res.status === 200 && res.data.status === "fail") {
      console.log(res);
      yield action.onError(res.data);
    } else {
      yield action.onError("Something Went Wrong!");
      console.log(res.data);
    }
  } catch (err) {
    console.log(err);
    yield action.onError("Something Went Wrong!!");
  }
}

export function* rejectUserReferralWorker(action) {
  try {
    const res = yield call(rejectUserReferralApi, action.id);
    console.log(res, "rekect UserReferral");
    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res.data);
      console.log(res.data);
    } else if (res.status === 200 && res.data.status === "fail") {
      console.log(res);
      yield action.onError(res.data);
    } else {
      yield action.onError("Something Went Wrong!");
      console.log(res.data);
    }
  } catch (err) {
    console.log(err);
    yield action.onError("Something Went Wrong!!");
  }
}
export function* approvalsUserReferralListWorker(action) {
  try {
    const res = yield call(
      approvalsUserReferralListpApi,
      action.page,
      action.data
    );
    // console.log(res, "AddAdminRole");
    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res.data);
      console.log(res.data);
    } else if (res.status === 200 && res.data.status === "fail") {
      console.log(res);
      yield action.onError(res);
    } else {
      yield action.onError("Something Went Wrong!");
      console.log(res.data);
    }
  } catch (err) {
    console.log(err);
    yield action.onError("Something Went Wrong!!");
  }
}
export function* viewApprovalUserReferralWorker(action) {
  try {
    const res = yield call(viewApprovalUserReferralApi, action.id);
    console.log(res, "UserReferral approvals");
    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res.data);
      console.log(res.data);
    } else if (res.status === 200 && res.data.status === "fail") {
      console.log(res);
      yield action.onError(res);
    } else {
      yield action.onError("Something Went Wrong!");
      console.log(res.data);
    }
  } catch (err) {
    console.log(err);
    yield action.onError("Something Went Wrong!!");
  }
}
export function* versionListUserReferralWorker(action) {
  try {
    const res = yield call(versionUserReferralpApi, action.page, action.data);
    // console.log(res, "AddAdminRole");
    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res.data);
      console.log(res.data);
    } else if (res.status === 200 && res.data.status === "fail") {
      console.log(res);
      yield action.onError(res);
    } else {
      yield action.onError("Something Went Wrong!");
      console.log(res.data);
    }
  } catch (err) {
    console.log(err);
    yield action.onError("Something Went Wrong!!");
  }
}
export function* versionViewUserReferralWorker(action) {
  try {
    const res = yield call(viewVersionUserReferralApi, action.id);
    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res);
      console.log(res.data);
    } else if (res.status === 200 && res.data.status === "fail") {
      console.log(res);
      yield action.onError(res);
    } else {
      yield action.onError("Something Went Wrong!");
      console.log(res);
    }
  } catch (err) {
    console.log(err);
    yield action.onError("Something Went Wrong!!");
  }
}

export function* UserReferralMainExportWorker(action) {
  try {
    const res = yield call(UserReferralMainExportApi);
    console.log(res);
    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res.data);
    }
  } catch (error) {}
}

export function* UserReferralApprovalsExportWorker(action) {
  try {
    const res = yield call(UserReferralApprovalsExportApi);
    console.log(res);
    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res.data);
    }
  } catch (error) {}
}

export function* UserReferralVersionExportWorker(action) {
  try {
    const res = yield call(UserReferralVersionExportApi);
    console.log(res);
    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res.data);
    }
  } catch (error) {}
}
export function* UserReferralSubmitWorker(action) {
  try {
    const res = yield call(UserReferralSubmitApi, action.id);
    console.log(res);
    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res.data);
    }
  } catch (error) {}
}

export function* fetchOpportunitiesIdWorker(action) {
  try {
    const res = yield call(fetchOpportunitiesIdApi, {});

    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res.data);
      console.log(res.data);
    } else if (res.status === 200 && res.data.status === "fail") {
      console.log(res.data);
      yield action.onError(res);
    } else {
      yield action.onError("Something Went Wrong");
    }
  } catch (err) {
    console.log(err);
    yield action.onError("Something Went Wrong");
  }
}
export function* watchUserReferralSubmitWorker() {
  yield takeLatest(USER_REFERRAL_SUBMIT, UserReferralSubmitWorker);
}

/**********************************/
/************WATHCHERS**************/
/**********************************/

export function* watchAddUserReferralWorker() {
  yield takeLatest(USER_REFERRAL_CREATE, addUserReferralWorker);
}
export function* watchApprovalsUserReferralListWorker() {
  yield takeLatest(
    USER_REFERRAL_APPROVALS_LIST,
    approvalsUserReferralListWorker
  );
}
export function* watchViewApprovalUserReferralWorker() {
  yield takeLatest(
    USER_REFERRAL_APPROVALS_VIEW,
    viewApprovalUserReferralWorker
  );
}
export function* watchViewUserReferralWorker() {
  yield takeLatest(USER_REFERRAL_READ, viewUserReferralWorker);
}
export function* watchViewUserReferralListWorker() {
  yield takeLatest(USER_REFERRAL_LIST, viewUserReferralListWorker);
}
export function* watcheditUserReferralWorker() {
  yield takeLatest(USER_REFERRAL_EDIT, editUserReferralWorker);
}
export function* watchApproveUserReferralWorker() {
  yield takeLatest(USER_REFERRAL_APPROVE, approveUserReferralWorker);
}
export function* watchRejectUserReferralWorker() {
  yield takeLatest(USER_REFERRAL_REJECT, rejectUserReferralWorker);
}
export function* watchDeleteUserReferralWorker() {
  yield takeLatest(USER_REFERRAL_DELETE, deleteUserReferralRoleWorker);
}
export function* watchversionListUserReferralWorker() {
  yield takeLatest(USER_REFERRAL_VERSION_LIST, versionListUserReferralWorker);
}
export function* watchVersionViewUserReferralWorker() {
  yield takeLatest(USER_REFERRAL_VERSION_VIEW, versionViewUserReferralWorker);
}

export function* watchUserReferralMainExportWorker() {
  yield takeLatest(USER_REFERRAL_MAIN_EXPORT, UserReferralMainExportWorker);
}
export function* watchUserReferralApprovalsExportWorker() {
  yield takeLatest(
    USER_REFERRAL_APPROVALS_EXPORT,
    UserReferralApprovalsExportWorker
  );
}
export function* watchUserReferralVersionExportWorker() {
  yield takeLatest(
    USER_REFERRAL_VERSION_EXPORT,
    UserReferralVersionExportWorker
  );
}
export function* watchFetchOpportunitiesIdWorker() {
  yield takeLatest(FETCH_OPPORTUNITES_ID, fetchOpportunitiesIdWorker);
}
