import { call, takeLatest } from "redux-saga/effects";
import { APPROVE_DOCUMENT, CREATE_DOCUMENT, DELETE_DOCUMENT, EDIT_DOCUMENT, DOCUMENT_APPROVALS_EXPORT, DOCUMENT_APPROVAL_TABLE, DOCUMENT_APPROVAL_VIEW, DOCUMENT_EXPORT, DOCUMENT_OPTIONS, DOCUMENT_SUBMIT, DOCUMENT_TABLE, DOCUMENT_VERSION_EXPORT, DOCUMENT_VERSION_HISTORY, DOCUMENT_VERSION_HISTORY_TABLE, REJECT_DOCUMENT, VIEW_DOCUMENT } from "../actions/types";
import { approveDocumentApi, createDocumentApi, deleteDocumentApi, documentApprovalByIdApi, documentApprovalListApi, documentApprovalsExportApi, documentSubmitApi, documentTableExportApi, documentVersionExportApi, editDocumentApi, rejectDocumentApi, versionHistoryDocumentDetailApi, versionHistoryDocumentListApi, viewDocumentDetailApi, viewDocumentListApi } from "../apis/documentApi";

export function* createDocumentWorker(action) {
    try {
        const res = yield call(createDocumentApi,
            action.data
        );
        console.log(res);
        if (res.status === 200 && res.data.status === "success") {
            yield action.onSuccess(res.data);
        } else {
            yield action.onError(res.data);
        }
    } catch (error) { }
}

export function* approveDocumentWorker(action) {
    try {
        const res = yield call(approveDocumentApi, {
            dashboard_id: action.id,
        });
        console.log(res);
        if (res.status === 200 && res.data.status === "success") {
            yield action.onSuccess(res.data);
        } else {
            yield action.onError(res.data);
        }
    } catch (error) { }
}

export function* rejectDocumentWorker(action) {
    try {
        const res = yield call(rejectDocumentApi, action.id);
        console.log(res);
        if (res.status === 200 && res.data.status === "success") {
            yield action.onSuccess(res.data);
        } else {
            yield action.onError(res.data);
        }
    } catch (error) { }
}


export function* viewDocumentDetailWorker(action) {
    try {
        const res = yield call(viewDocumentDetailApi, action.id);
        console.log(res);
        if (res.status === 200 && res.data.status === "success") {
            yield action.onSuccess(res.data);
        } else {
            yield action.onError(res.data);
        }
    } catch (error) { }
}


export function* viewDocumentListWorker(action) {
    try {

        const res = yield call(viewDocumentListApi,
            action.page_no,
            action.data,
        );
        console.log(res);
        if (res.status === 200 && res.data.status === "success") {
            yield action.onSuccess(res.data.data);
            console.log(res.data.data);
        } else {
            yield action.onError(res.data);
        }
    } catch (error) { }
}

export function* editDocumentWorker(action) {
    try {
        const res = yield call(editDocumentApi, action.id, action.data);
        console.log(res);
        if (res.status === 200 && res.data.status === "success") {
            yield action.onSuccess(res.data);
        } else {
            yield action.onError(res.data);
        }
    } catch (error) { }
}


export function* deleteDocumentWorker(action) {
    try {
        const res = yield call(deleteDocumentApi, action.id);
        console.log(res);
        if (res.status === 200 && res.data.status === "success") {
            yield action.onSuccess(res.data);
        } else {
            yield action.onError(res.data);
        }
    } catch (error) { }
}




export function* documentApprovalListWorker(action) {
    console.log(action.search,
        action.page_no,)
    try {
        const res = yield call(documentApprovalListApi,
            action.page_no,
            action.data,
        );
        if (res.status === 200 && res.data.status === "success") {
            yield action.onSuccess(res.data.data);
            console.log(res.data);
        } else {
            yield action.onError(res.data);
        }
    } catch (error) { }
}



export function* documentApprovalByIdWorker(action) {
    try {
        const res = yield call(documentApprovalByIdApi, action.id);
        console.log(res);
        if (res.status === 200 && res.data.status === "success") {
            yield action.onSuccess(res.data);
            console.log(res.data)
        } else {
            yield action.onError(res.data);
        }
    } catch (error) { }
}



export function* versionHistoryDocumentListWorker(action) {
    try {
        const res = yield call(versionHistoryDocumentListApi,
            action.page,
            action.data,
        );
        console.log(res);
        if (res.status === 200 && res.data.status === "success") {
            yield action.onSuccess(res.data);
        } else {
            yield action.onError(res.data);
        }
    } catch (error) { }
}

export function* versionHistoryDocumentDetailWorker(action) {
    try {
        const res = yield call(versionHistoryDocumentDetailApi,
            action.id,
        );
        console.log(res);
        if (res.status === 200 && res.data.status === "success") {
            yield action.onSuccess(res.data);
        } else {
            yield action.onError(res.data);
        }
    } catch (error) { }
}




/*********WATCHERS********/

export function* documentTableExportWorker(action) {
    try {
        const res = yield call(documentTableExportApi,

        );
        if (res.status === 200 && res.data.status === "success") {
            yield action.onSuccess(res.data);
        } else {
            yield action.onError(res.data);
        }
    } catch (error) { }
}


export function* documentApprovalsExportWorker(action) {
    try {
        const res = yield call(documentApprovalsExportApi);
        console.log(res);
        if (res.status === 200 && res.data.status === "success") {
            console.log(res.data);
            yield action.onSuccess(res.data.data);
        } else {
            yield action.onError(res.data);
        }
    } catch (error) { }
}

export function* documentVersionExportWorker(action) {
    try {
        const res = yield call(documentVersionExportApi);
        console.log(res);
        if (res.status === 200 && res.data.status === "success") {
            yield action.onSuccess(res.data);
        } else {
            yield action.onError(res.data);
        }
    } catch (error) { }
}



export function* documentSubmitWorker(action) {
    try {
        const res = yield call(documentSubmitApi, action.id);
        console.log(res);
        if (res.status === 200 && res.data.status === "success") {
            yield action.onSuccess(res.data);
        } else {
            yield action.onError(res.data);
        }
    } catch (error) { }
}






export function* createDocumentWorkerWatcher() {
    yield takeLatest(CREATE_DOCUMENT, createDocumentWorker);
}

export function* approveDocumentWorkerWatcher() {
    yield takeLatest(APPROVE_DOCUMENT, approveDocumentWorker);
}
export function* rejectDocumentWorkerWatcher() {
    yield takeLatest(REJECT_DOCUMENT, rejectDocumentWorker);
}
export function* viewDocumentDetailWatcherWorker() {
    yield takeLatest(VIEW_DOCUMENT, viewDocumentDetailWorker);
}
export function* viewDocumentListWorkerWatcher() {
    yield takeLatest(DOCUMENT_TABLE, viewDocumentListWorker);
}
export function* editDocumentWorkerWatcher() {
    yield takeLatest(EDIT_DOCUMENT, editDocumentWorker);

}
export function* deleteDocumentWorkerWatcher() {
    yield takeLatest(DELETE_DOCUMENT, deleteDocumentWorker);
}

export function* documentApprovalListWatcherWorker() {
    yield takeLatest(DOCUMENT_APPROVAL_TABLE, documentApprovalListWorker);
}

export function* documentApprovalByIdWatcher() {
    yield takeLatest(DOCUMENT_APPROVAL_VIEW, documentApprovalByIdWorker);
}



export function* versionHistoryDocumentListWatcher() {
    yield takeLatest(DOCUMENT_VERSION_HISTORY_TABLE, versionHistoryDocumentListWorker);
}
export function* versionHistoryDocumentDetailWatcher() {
    yield takeLatest(
        DOCUMENT_VERSION_HISTORY,
        versionHistoryDocumentDetailWorker
    );
}

/****exports*****/

export function* documentTableExportWatcher() {
    yield takeLatest(
        DOCUMENT_EXPORT,
        documentTableExportWorker
    );
}
export function* documentApprovalsExportWatcher() {
    yield takeLatest(DOCUMENT_APPROVALS_EXPORT, documentApprovalsExportWorker);
}

export function* documentVersionExportWorkerWatcher() {
    yield takeLatest(DOCUMENT_VERSION_EXPORT, documentVersionExportWorker);
}



export function* documentSubmitWorkerWatcher() {
    yield takeLatest(DOCUMENT_SUBMIT, documentSubmitWorker);
}