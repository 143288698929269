import {
  ACTIVATE_CAMPAIGN_OPTIONS,
  CAMPAIGN_APPROVALS_EXPORT,
  CAMPAIGN_APPROVALS_LIST,
  CAMPAIGN_APPROVALS_VIEW,
  CAMPAIGN_APPROVE,
  CAMPAIGN_CREATE,
  CAMPAIGN_DELETE,
  CAMPAIGN_EDIT,
  CAMPAIGN_LIST,
  CAMPAIGN_MAIN_EXPORT,
  CAMPAIGN_READ,
  CAMPAIGN_REJECT,
  CAMPAIGN_SUBMIT,
  CAMPAIGN_VERSION_EXPORT,
  CAMPAIGN_VERSION_LIST,
  CAMPAIGN_VERSION_VIEW,
} from "./types";

export const addCampaignAction = (data, onSuccess, onError) => {
  return {
    type: CAMPAIGN_CREATE,
    data,
    onSuccess,
    onError,
  };
};
export const approvalsCampaignListAction = (
  page,
  search,
  onSuccess,
  onError
) => {
  return {
    type: CAMPAIGN_APPROVALS_LIST,

    page,
    search,
    onSuccess,
    onError,
  };
};
export const approvalsViewCampaignAction = (id, onSuccess, onError) => {
  return {
    type: CAMPAIGN_APPROVALS_VIEW,
    id,
    onSuccess,
    onError,
  };
};
export const approveCampaignAction = (id, onSuccess, onError) => {
  return {
    type: CAMPAIGN_APPROVE,
    id,
    onSuccess,
    onError,
  };
};
export const rejectCampaignAction = (id, onSuccess, onError) => {
  return {
    type: CAMPAIGN_REJECT,
    id,
    onSuccess,
    onError,
  };
};
export const viewCampaignListAction = (page, search, onSuccess, onError) => {
  return {
    type: CAMPAIGN_LIST,
    page,
    search,
    onSuccess,
    onError,
  };
};
export const viewCampaignAction = (id, onSuccess, onError) => {
  return {
    type: CAMPAIGN_READ,
    id,
    onSuccess,
    onError,
  };
};

export const editCampaignAction = (id, data, onSuccess, onError) => {
  return {
    type: CAMPAIGN_EDIT,
    id,
    data,
    onSuccess,
    onError,
  };
};
export const deleteCampaignAction = (id, onSuccess, onError) => {
  return {
    type: CAMPAIGN_DELETE,
    id,
    onSuccess,
    onError,
  };
};

export const versionListCampaignAction = (page, search, onSuccess, onError) => {
  return {
    type: CAMPAIGN_VERSION_LIST,
    page,
    search,
    onSuccess,
    onError,
  };
};
export const versionViewCampaignAction = (id, onSuccess, onError) => {
  return {
    type: CAMPAIGN_VERSION_VIEW,
    id,
    onSuccess,
    onError,
  };
};
export const activateCampaignAction = (id, onSuccess, onError) => {
  return {
    type: ACTIVATE_CAMPAIGN_OPTIONS,
    id,
    onSuccess,
    onError,
  };
};

export const campaignMainExportAction = (onSuccess, onError) => {
  return {
    type: CAMPAIGN_MAIN_EXPORT,
    onSuccess,
    onError,
  };
};
export const campaignApprovalsExportAction = (onSuccess, onError) => {
  return {
    type: CAMPAIGN_APPROVALS_EXPORT,
    onSuccess,
    onError,
  };
};
export const campaignVersionExportAction = (onSuccess, onError) => {
  return {
    type: CAMPAIGN_VERSION_EXPORT,
    onSuccess,
    onError,
  };
};

export const campaignSubmitAction = (id, onSuccess, onError) => {
  return {
    type: CAMPAIGN_SUBMIT,
    id,
    onSuccess,
    onError,
  };
};
