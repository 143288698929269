import React, { useEffect, useState } from "react";
import ExportBtn from '../../../../components/buttons/exportBtn'
import FilterBtn from '../../../../components/buttons/filterBtn'
import AdminUserFilter from "../../../../components/filter/adminUserFilter";
import VersionTablesFilter from "../../../../components/filter/versionTableFilter";
import InnerLayout from '../../../../components/layouts/innerLayout/innerLayout'
import FaqVersionHistoryTableContent from '../../../../components/static-pages/faqs/tables/faqVersionHistoryTableContent'
import ReferVersionHistoryTableContent from "../../../../components/static-pages/referAndEarn/tables/referVersionHistoryTableContent";
import { faqVersionExport } from "../../../../redux/actions/faqAction";
import { referVersionExport } from "../../../../redux/actions/referAction";

const ReferVersionHistoryTable = () => {
  const [page, setPage] = useState(0);
  const [showFilter, setShowFilter] = useState(false);
  const [search, setSearch] = useState({ value: "" });
  const handleFilterClose = () => {
    setShowFilter(false);
  };
  const handleFilterOpen = () => {
    setShowFilter(true);
  };
  const handleSearch = (value) => {
    setSearch({ value: value });
    setPage(0)
  };
  const handlePage = (type) => {
    if (type === "+") {
      setPage((prev) => prev + 1)
    }
    else {
      setPage((prev) => prev - 1)
    }
  }

  const buttonList = [
    <ExportBtn dispatchAction={referVersionExport}/>,
    <FilterBtn filterFn={handleFilterOpen} />
  ]
  return (
    <InnerLayout buttons={buttonList}>
      <ReferVersionHistoryTableContent page={page} changePage={handlePage} search={search} />
      <VersionTablesFilter
        title='Refer Id'
        placeholder='Enter Refer Id'
        filter={handleSearch}
        showFilter={showFilter}
        closeFilter={handleFilterClose}
      />
    </InnerLayout>
  )
}

export default ReferVersionHistoryTable