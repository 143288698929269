import { all } from "redux-saga/effects";
import { editWishlist, viewAllWishlist } from "../actions/wishlistActions";
import {
  rolesApprovalsExportWatcher,
  rolesTableExportWatcher,
  rolesTableExportWorker,
  rolesVersionExportWorkerWatcher,
  watchAddAdminRoleWorker,
  watchAdminRoleSubmitWorker,
  watchApprovalsListAdminRoleWorker,
  watchApproveAdminRoleWorker,
  watchDeleteAdminRoleWorker,
  watchEditAdminRoleWorker,
  watchRejectAdminRoleWorker,
  watchVersionHistoryListAdminRoleWorker,
  watchViewAdminRoleWorker,
  watchViewAllAdminRoleWorker,
  watchViewApprovalAdminRoleWorker,
  watchViewVersionHistoryAdminRoleWorker,
} from "./adminRoleSaga";
import {
  addAdminWorkerWatcher,
  adminApprovalsExportWatcher,
  adminTableExportWatcher,
  adminVersionExportWorkerWatcher,
  approveAdminWatcher,
  deleteAdminWorkerWatcher,
  editAdminWorkerWatcher,
  rejectAdminWatcher,
  roleTypeWorkerWatcher,
  versionHistoryAdminDetailWatcher,
  versionHistoryListWatcher,
  viewAdminApprovalDetailWatcher,
  viewAdminApprovalListWatcher,
  viewAdminDetailWatcherWorker,
  viewAdminListWorkerWatcher,
  watchAdminSubmitWorker,
} from "./adminUserSaga";
import { watchLoginWorker, watchLogoutWorker } from "./loginSaga";
import {
  notifyApprovalsExportWatcher,
  notifySubmitWorkerWatcher,
  notifyTableExportWatcher,
  notifyVersionExportWorkerWatcher,
  watchapproveNotifyMeWorker,
  watcheditNotifyMeWorker,
  WatchernotifyMeCreateOptionsWorker,
  WatchernotifyMeCreateWorker,
  watchnotifyMeApprovalsTableWorker,
  watchnotifyMeVersionHistoryWorker,
  watchNotifyMeWorker,
  watchreadApprovalNotifyMeWorker,
  watchreadNotifyMeWorker,
  watchrejectNotifyMeWorker,
  watchversionTableNotifyMeWorker,
  watchViewAllNotifyMeWorker,
} from "./notifyMeSaga";
import {
  opporTypeApprovalsExportWatcher,
  opporTypeSubmitWorkerWatcher,
  opporTypeTableExportWatcher,
  opporTypeVersionExportWorkerWatcher,
  watchAddOpporTypeWorker,
  watchApprovalsListOpporTypeWorker,
  watchApproveOpporTypeWorker,
  watchDeleteOpporTypeWorker,
  watchEditOpporTypeWorker,
  watchRejectOpporTypeWorker,
  watchVersionHistoryListOpporTypeWorker,
  watchViewAllOpporTypesWorker,
  watchViewApprovalOpporTypeWorker,
  watchViewOpporTypeWorker,
  watchViewVersionHistoryOpporTypeWorker,
} from "./opportunityTypeSaga";
import {
  addUserWorkerWatcher,
  EditUserWorkerWatcher,
  watchUserEmailOtpWorker,
  watchUserPhoneOtpWorker,
  watchApprovalsListUserWatcher,
  UserListWatcher,
  ApprovalUserListWatcher,
  watchindividualUserDetailWatcher,
  watchResetPasswordEmailViewWorker,
  UserVersionHistoryListWatcher,
  UserVersionHistoryViewWatcher,
  UserApprovalViewViewWatcher,
  ApproveUserViewWatcher,
  deleteUserWorkerWatcher,
  watchprofileImageUploadWorker,
  watchprofileImageUploadWorker2,
  watchuploadAddressProofFrontImageWorker,
  watchuploadAddressProofBackImageWorker,
  watchpanImageUploadWorker,
  watchbankImageUploadWorker,
  watchgetProfileImageWorker,
  addessApprovedWorkerWatcher,
  // watchuserExportWorker,
  addessRejectedWorkerWatcher,
  addessPanWorkerWatcher,
  panRejectedWorkerWatcher,
  panApprovedWorkerWatcher,
  bankApprovedWorkerWatcher,
  bankRejectedWorkerWatcher,
  RejectWorkerWatcher,
  kycStatusUserWorkerWatcher,
  rejectedApprovePanUserWorkerWatcher,
  rejectedApproveBankUserWorkerWatcher,
  userTableExportWorker,
  userApprovalsExportWatcher,
  userVersionExportWorkerWatcher,
  userResetExportWatcher,
  userEmailExportWatcher,
  userPhoneExportWorkerWatcher,
  userTableExportWatcher,
  watchForgotPasswordSendEmailWorker,
  watchUserSubmitWorker,
} from "./userSaga";
import {
  editWishlistWorker,
  watchApprovalsListWishlistWorker,
  watchApproveWishlistWorker,
  watchCreateWishlistWorker,
  watchDeleteWishlistWorker,
  watchEditWishlistWorker,
  watchVersionHistoryListWishlistWorker,
  watchViewAllWishlistWorker,
  watchViewVersionHistorylByIdWorker,
  watchViewWishListApprovalByIdWorker,
  watchViewWishlistWorker,
  watchWishListFindAllWorker,
  watchWishListRejectByIdWorker,
  wishlistApprovalsExportWatcher,
  wishlistSubmitWorkerWatcher,
  wishlistTableExportWatcher,
  wishlistVersionExportWorkerWatcher,
} from "./wishlistSaga";
import {
  opportunitiesApprovalsExportWatcher,
  opportunitiesSubmitWorkerWatcher,
  opportunitiesTableExportWatcher,
  opportunitiesVersionExportWorkerWatcher,
  watchApproveOpportunityWorker,
  watchcreateOpportunitiesWorker,
  watchDeleteOpportunityWorker,
  watchEditOpportunityWorker,
  watchFetchOpportunitiesApprovalWorker,
  watchFetchOpportunitiesTypeWorker,
  watchFetchOpportunitiesWorker,
  watchFetchOpportunityDetailsWorker,
  watchopportunitiesAnalyticsWorker,
  watchOpportunitiesApprovalsViewWorker,
  watchOpportunitiesNameListWorker,
  watchOpportunitiesVersionTableWorker,
  watchOpportunitiesVersionViewWorker,
  watchPublishOpportunityWorker,
  watchRejectOpportunityWorker,
} from "../sagas/opportunitiesSaga";
import {
  partnersApprovalsExportWatcher,
  partnersSubmitWorkerWatcher,
  partnersTableExportWatcher,
  partnersTableExportWorker,
  partnersVersionExportWorkerWatcher,
  watchaddPartnerWorker,
  watchApprovalsPartnerListWorker,
  watchApprovePartnerWorker,
  watchDeletePartnerWorker,
  watcheditPartnerWorker,
  watchRejectPartnerWorker,
  watchVersionHistoryListPartnerWorker,
  watchViewApprovalPartnerWorker,
  watchViewParnterWorker,
  watchViewPartnerListWorker,
  watchViewVersionHistoryPartnerWorker,
} from "./ourPartnersSaga";
import {
  approveFaqWorkerWatcher,
  createFaqWorkerWatcher,
  deleteFaqWorkerWatcher,
  editFaqWorkerWatcher,
  faqApprovalByIdWatcher,
  faqApprovalListWatcherWorker,
  faqApprovalsExportWatcher,
  faqOptionsWorkerWatcher,
  faqSubmitWorkerWatcher,
  faqTableExportWatcher,
  faqVersionExportWorkerWatcher,
  rejectFaqWorkerWatcher,
  versionHistoryFaqDetailWatcher,
  versionHistoryFaqListWatcher,
  viewFaqDetailWatcherWorker,
  viewFaqListWorkerWatcher,
} from "./faqSaga";
import {
  approveTestimonialsWorkerWatcher,
  createTestimonialsWorkerWatcher,
  deletetesTimonialsWorkerWatcher,
  editTestimonialsWorkerWatcher,
  rejectTestimonialsWorkerWatcher,
  testimonialsApprovalByIdWatcher,
  testimonialsApprovalListWatcherWorker,
  testimonialsApprovalsExportWatcher,
  testimonialsOptionsWorkerWatcher,
  testimonialsSubmitWorkerWatcher,
  testimonialsTableExportWatcher,
  testimonialsVersionExportWorkerWatcher,
  versionHistoryTestimonialsDetailWatcher,
  versionHistoryTestimonialsListWatcher,
  viewTestimonialsDetailWatcherWorker,
  viewTestimonialsListWorkerWatcher,
} from "./testimonialsSaga";

import {
  llpApprovalsExportWatcher,
  llpImportUsersWorkerWatcher,
  llpSubmitWorkerWatcher,
  llpTableExportWatcher,
  llpVersionExportWorkerWatcher,
  watchAddLLPWorker,
  watchApprovalsLLPListWorker,
  watchApproveLLPWorker,
  watchdeleteLLPWorker,
  watcheditLLPWorker,
  watchLlpDocAddWorker,
  watchLlpDocDeleteWorker,
  watchLlpDocEditWorker,
  watchLlpMemberAddWorker,
  watchLlpMemberDeleteWorker,
  watchLlpMemberEditWorker,
  watchRejectLLPWorker,
  watchVersionHistoryListVersionListLLPWorker,
  watchversionViewLLPWorker,
  watchViewApprovalLLPWorker,
  watchviewLLPListWorker,
  watchViewLLPListWorker,
  watchViewLLPWorker,
} from "./llpSaga";

import {
  advisorsApprovalsExportWatcher,
  advisorsSubmitWorkerWatcher,
  advisorsTableExportWatcher,
  advisorsVersionExportWorkerWatcher,
  watchaddAdvisorWorker,
  watchApprovalsAdvisorListWorker,
  watchApproveAdvisorWorker,
  watchDeleteAdvisorWorker,
  watcheditAdvisorWorker,
  watchRejectAdvisorWorker,
  watchVersionHistoryListAdvisorWorker,
  watchViewAdvisorListWorker,
  watchViewAdvisorWorker,
  watchViewApprovalAdvisorWorker,
  watchViewVersionHistoryAdvisorWorker,
} from "./ourAdvisorsSaga";
import {
  approveExpressWorkerWatcher,
  createExpressWorkerWatcher,
  deleteExpressWorkerWatcher,
  editExpressWorkerWatcher,
  expressApprovalByIdWatcher,
  ExpressApprovalListWatcherWorker,
  expressApprovalsExportWatcher,
  expressSubmitWorkerWatcher,
  expressTableExportWatcher,
  expressVersionExportWorkerWatcher,
  rejectExpressWorkerWatcher,
  versionHistoryExpressDetailWatcher,
  versionHistoryExpressListWatcher,
  viewExpressDetailWatcherWorker,
  viewExpressListWorkerWatcher,
} from "./expressSaga";
import {
  approveFaqCategoryWorkerWatcher,
  createFaqCategoryWorkerWatcher,
  deleteFaqCategoryWorkerWatcher,
  editFaqCategoryWorkerWatcher,
  faqCategoryApprovalByIdWatcher,
  faqCategoryApprovalListWatcherWorker,
  faqcategoryApprovalsExportWatcher,
  faqCategoryOptionsWorkerWatcher,
  faqcategorySubmitWorkerWatcher,
  faqcategoryTableExportWatcher,
  faqcategoryVersionExportWorkerWatcher,
  rejectFaqCategoryCaWorkerWatcher,
  versionHistoryFaqCategoryDetailWatcher,
  versionHistoryFaqCategoryListWatcher,
  viewFaqCategoryDetailWatcherWorker,
  viewFaqCategoryListWorkerWatcher,
} from "./faqCatgorySaga";
import {
  mediaRecSubmitWorkerWatcher,
  viewVersionHistoryAllMediaWorker,
  viewVersionHistoryAllWorker,
  viewVersionHistoryByIdMediaWorker,
  watchAddMediaRecWorker,
  watchApprovalsMediaRecListWorker,
  watchApproveMediaRecWorker,
  watchDeleteMediaRecWorker,
  watchMediaApprovalsByIdWorker,
  watchMediaApprovalsTableWorker,
  watchMediaApprovedWorker,
  watchMediaEditWorker,
  watchMediaQueryVersionHistoryWorker,
  watchMediaRecApprovalsExportWorker,
  watchMediaRecMainExportWorker,
  watchMediaRecVersionExportWorker,
  watchMediaRejectWorker,
  watchMediaVersionHistoryByIdWorker,
  watchViewMediaRecListWorker,
  watchViewMediaRecWorker,
} from "./mediaRecogonitionsSaga";

import {
  approveOurTeamWorkerWatcher,
  createOutTeamWorkerWatcher,
  deleteOurTeamWorkerWatcher,
  editOurTeamWorkerWatcher,
  OurTeamApprovalByIdWatcher,
  OurTeamApprovalListWatcherWorker,
  ourTeamApprovalsExportWatcher,
  ourTeamSubmitWorkerWatcher,
  ourTeamTableExportWatcher,
  ourTeamTableExportWorker,
  ourTeamVersionExportWorkerWatcher,
  rejectOurTeamWorkerWatcher,
  versionHistoryOurTeamDetailWatcher,
  versionHistoryOurTeamDetailWorker,
  versionHistoryOurTeamListWatcher,
  viewOurTeamDetailWatcherWorker,
  viewOurTeamListWorkerWatcher,
} from "./ourTeamSaga";
import {
  watchAddContactWorker,
  watchApprovalsContactListWorker,
  watchApproveContactWorker,
  watchContactApprovalsExportWorker,
  watchContactMainExportWorker,
  watchcontactSubmitWorker,
  watchContactVersionExportWorker,
  watchDeleteContactWorker,
  watcheditContactWorker,
  watchRejectContactWorker,
  watchVersionListContactWorker,
  watchversionViewContactWorker,
  watchViewApprovalContactWorker,
  watchViewContactListWorker,
  watchViewContactWorker,
} from "./contactUsSaga";
import {
  newsLetterSubmitWorkerWatcher,
  watchAddNewsLetterWorker,
  watchApprovalsNewsLetterListWorker,
  watchApproveNewsLetterWorker,
  watchDeleteNewsletterWorker,
  watcheditNewsLetterWorker,
  watchNewsLetterApprovalsExportWorker,
  watchNewsLetterMainExportWorker,
  watchNewsLetterVersionExportWorker,
  watchRejectNewsLetterWorker,
  watchversionListNewsletterWorker,
  watchVersionViewNewsletterWorker,
  watchViewApprovalNewsLetterWorker,
  watchViewNewsLetterListWorker,
  watchViewNewsLetterWorker,
} from "./newsletterSaga";
import {
  approveStaticWorkerWatcher,
  createStaticWorkerWatcher,
  deleteStaticWorkerWatcher,
  editStaticWorkerWatcher,
  rejectStaticWorkerWatcher,
  staticApprovalByIdWatcher,
  staticApprovalListWatcherWorker,
  staticOptionsWorkerWatcher,
  versionHistoryStaticDetailWatcher,
  versionHistoryStaticListWatcher,
  viewStaticDetailWatcherWorker,
  viewStaticListWorkerWatcher,
  watchStaticApprovalsExportWorker,
  watchStaticMainExportWorker,
  watchStaticSubmitWorker,
  watchStaticVersionExportWorker,
} from "./staticSaga";
import {
  approveBlogCategoryWorkerWatcher,
  blogCategoryApprovalByIdWatcher,
  blogCategoryApprovalByIdWatcherWorker,
  blogCategoryApprovalListWatcherWorker,
  blogCategoryOptionsWorkerWatcher,
  createBlogCategoryWorkerWatcher,
  deleteBlogCategoryWorkerWatcher,
  editBlogCategoryWorkerWatcher,
  rejectBlogCategoryCaWorkerWatcher,
  versionHistoryBlogCategoryDetailWatcher,
  versionHistoryBlogCategoryListWatcher,
  viewBlogCategoryDetailWatcherWorker,
  viewBlogCategoryListWorkerWatcher,
  watchBlogCategoryApprovalsExportWorker,
  watchBlogCategoryMainExportWorker,
  watchblogCategorySubmitWorker,
  watchBlogCategoryVersionExportWorker,
} from "./blogCategorySagas";
import {
  approveBlogWorkerWatcher,
  blogApprovalByIdWorkerWatcher,
  blogApprovalListWatcherWorker,
  createBlogWorkerWatcher,
  deleteBlogWorkerWatcher,
  editBlogWorkerWatcher,
  rejectBlogWorkerWatcher,
  versionHistoryBlogDetailWatcher,
  versionHistoryBlogListWorker,
  versionHistoryBlogListWorkerWatcher,
  viewBlogDetailWatcherWorker,
  viewBlogListWorkerWatcher,
  watchBlogApprovalsExportWorker,
  watchBlogCategoryOptionsWorker,
  watchBlogMainExportWorker,
  watchBlogOptionsWorker,
  watchblogSubmitWorker,
  watchBlogVersionExportWorker,
} from "./blogSagas";
// import { blogApprovalListWatcherWorker, versionHistoryBlogListWorker, versionHistoryBlogListWorkerWatcher, viewBlogDetailWatcherWorker, viewBlogListWorkerWatcher, watchBlogCategoryOptionsWorker } from "./blogSagas";
// import { WatchActivateHowItWorksWorker, watchAddHowItWorksWorker, watchApprovalsHowItWorksListWorker, watchApproveHowItWorksWorker, watchDeleteHowItWorksWorker, watcheditHowItWorksWorker, watchRejectHowItWorksWorker, watchversionListHowItWorksWorker, watchVersionViewHowItWorksWorker, watchViewApprovalHowItWorksWorker, watchViewHowItWorksListWorker, watchViewHowItWorksWorker } from "./howItWorksSaga";
// import { memberApprovalsExportWatcher, memberSubmitWorkerWatcher, memberTableExportWatcher, memberVersionExportWorkerWatcher, watchapproveMemberTradeWorker, watcheditMemberTradeWorker, WatcherMemberTradeCreateOptionsWorker, WatcherMemberTradeCreateWorker, watchMemberTradeApprovalsTableWorker, watchMemberTradeVersionHistoryWorker, watchMemberTradeWorker, watchreadApprovalMemberTradeWorker, watchreadMemberTradeWorker, watchrejectMemberTradeWorker, watchversionTableMemberTradeWorker, watchViewAllMemberTradeWorker } from "./memberTradeSaga";
// import { WatchActivateHowItWorksWorker, watchAddHowItWorksWorker, watchApprovalsHowItWorksListWorker, watchApproveHowItWorksWorker, watchDeleteHowItWorksWorker, watcheditHowItWorksWorker, watchhowItWorksApprovalsExportWorker, watchhowItWorksMainExportWorker, watchhowItWorksVersionExportWorker, watchRejectHowItWorksWorker, watchversionListHowItWorksWorker, watchVersionViewHowItWorksWorker, watchViewApprovalHowItWorksWorker, watchViewHowItWorksListWorker, watchViewHowItWorksWorker } from "./howItWorksSaga";
// import { versionViewHomeWorker, watchAddHomeWorker, watchApprovalsHomeListWorker, watchApproveHomeWorker, watchEditHomeWorker, watchHomeApprovalsExportWorker, watchHomeMainExportWorker, watchHomeSubmitWorker, watchHomeVersionExportWorker, watchRejectHomeWorker, watchVersionListHomeWorker, watchVersionViewHomeWorker, watchViewApprovalHomeWorker, watchViewHomeListWorker, watchViewHomeWorker } from "./homeSaga";
import {
  approveCampaignOpporWorkerWatcher,
  campaignOpporActiveWorkerWatcher,
  CampaignOpporApprovalByIdWatcher,
  CampaignOpporApprovalListWatcherWorker,
  campaignOpporApprovalsExportWatcher,
  campaignOpporSubmitWorkerWatcher,
  campaignOpporTableExportWatcher,
  campaignOpporVersionExportWorkerWatcher,
  createCampaignOpporWorkerWatcher,
  deleteCampaignOpporWorkerWatcher,
  editCampaignOpporWorkerWatcher,
  rejectCampaignOpporWorkerWatcher,
  versionHistoryCampaignOpporDetailWatcher,
  versionHistoryCampaignOpporListWatcher,
  viewCampaignOpporDetailWatcherWorker,
  viewCampaignOpporListWorkerWatcher,
} from "./campaignOpporSaga";
import {
  memberApprovalsExportWatcher,
  memberSubmitWorkerWatcher,
  memberTableExportWatcher,
  memberVersionExportWorkerWatcher,
  watchapproveMemberTradeWorker,
  watcheditMemberTradeWorker,
  WatcherMemberTradeCreateOptionsWorker,
  WatcherMemberTradeCreateWorker,
  watchMemberTradeApprovalsTableWorker,
  watchMemberTradeVersionHistoryWorker,
  watchMemberTradeWorker,
  watchreadApprovalMemberTradeWorker,
  watchreadMemberTradeWorker,
  watchrejectMemberTradeWorker,
  watchversionTableMemberTradeWorker,
  watchViewAllMemberTradeWorker,
} from "./memberTradeSaga";
import {
  WatchActivateHowItWorksWorker,
  watchAddHowItWorksWorker,
  watchApprovalsHowItWorksListWorker,
  watchApproveHowItWorksWorker,
  watchDeleteHowItWorksWorker,
  watcheditHowItWorksWorker,
  watchhowItWorksApprovalsExportWorker,
  watchhowItWorksMainExportWorker,
  watchHowItWorksSubmitWorker,
  watchhowItWorksVersionExportWorker,
  watchRejectHowItWorksWorker,
  watchversionListHowItWorksWorker,
  watchVersionViewHowItWorksWorker,
  watchViewApprovalHowItWorksWorker,
  watchViewHowItWorksListWorker,
  watchViewHowItWorksWorker,
} from "./howItWorksSaga";
import {
  versionViewHomeWorker,
  WatchActivateHomeWorker,
  watchAddHomeWorker,
  watchApprovalsHomeListWorker,
  watchApproveHomeWorker,
  watchDeleteHomeWorker,
  watchEditHomeWorker,
  watchHomeApprovalsExportWorker,
  watchHomeMainExportWorker,
  watchHomeSubmitWorker,
  watchHomeVersionExportWorker,
  watchRejectHomeWorker,
  watchVersionListHomeWorker,
  watchVersionViewHomeWorker,
  watchViewApprovalHomeWorker,
  watchViewHomeListWorker,
  watchViewHomeWorker,
} from "./homeSaga";
import {
  WatchActivateCampaignWorker,
  watchAddCampaignWorker,
  watchApprovalsCampaignListWorker,
  watchApproveCampaignWorker,
  watchCampaignApprovalsExportWorker,
  watchCampaignMainExportWorker,
  watchCampaignSubmitWorker,
  watchCampaignVersionExportWorker,
  watchDeleteCampaignWorker,
  watchEditCampaignWorker,
  watchRejectCampaignWorker,
  watchVersionListCampaignWorker,
  watchVersionViewCampaignWorker,
  watchViewApprovalCampaignWorker,
  watchViewCAMPAIGNCampaignWorker,
  watchViewCampaignListWorker,
  watchViewCampaignWorker,
} from "./campaignSaga";
import {
  WatchActivateInsightsWorker,
  watchAddInsightsWorker,
  watchApprovalsInsightsListWorker,
  watchApproveInsightsWorker,
  watchDeleteInsightsWorker,
  watcheditInsightsWorker,
  watchInsightsApprovalsExportWorker,
  watchInsightsMainExportWorker,
  watchInsightsSubmitWorker,
  watchInsightsVersionExportWorker,
  watchRejectInsightsWorker,
  watchversionListInsightsWorker,
  watchVersionViewInsightsWorker,
  watchViewApprovalInsightsWorker,
  watchViewInsightsListWorker,
  watchViewInsightsWorker,
} from "./insightsSaga";
import {
  WatchActivatePartnerWithUsWorker,
  watchAddPartnerWithUsWorker,
  watchApprovalsPartnerWithUsListWorker,
  watchApprovePartnerWithUsWorker,
  watchDeletePartnerWithUsWorker,
  watchEditPartnerWithUsWorker,
  watchPartnerWithUsApprovalsExportWorker,
  watchPartnerWithUsMainExportWorker,
  watchPartnerWithUsSubmitWorker,
  watchPartnerWithUsVersionExportWorker,
  watchRejectPartnerWithUsWorker,
  watchVersionListPartnerWithUsWorker,
  watchVersionViewPartnerWithUsWorker,
  watchViewApprovalPartnerWithUsWorker,
  watchViewPartnerWithUsListWorker,
  watchViewPartnerWithUsWorker,
} from "./partnerWithUsSaga";
import {
  approveReferWorkerWatcher,
  createReferWorkerWatcher,
  deleteReferWorkerWatcher,
  editReferWorkerWatcher,
  ReferApprovalByIdWatcher,
  ReferApprovalListWatcherWorker,
  ReferApprovalsExportWatcher,
  ReferOptionsWorkerWatcher,
  ReferSubmitWorkerWatcher,
  ReferTableExportWatcher,
  ReferVersionExportWorkerWatcher,
  rejectReferWorkerWatcher,
  versionHistoryReferDetailWatcher,
  versionHistoryReferListWatcher,
  viewReferDetailWatcherWorker,
  viewReferListWorkerWatcher,
} from "./referSaga";
import {
  approveBadgesWorkerWatcher,
  badgesApprovalByIdWatcher,
  badgesApprovalListWatcherWorker,
  badgesApprovalsExportWatcher,
  badgesSubmitWorkerWatcher,
  badgesTableExportWatcher,
  badgesVersionExportWorkerWatcher,
  createBadgesWorkerWatcher,
  deleteBadgesWorkerWatcher,
  editBadgesWorkerWatcher,
  rejectBadgesWorkerWatcher,
  versionHistoryBadgesDetailWatcher,
  versionHistoryBadgesListWatcher,
  viewBadgesDetailWatcherWorker,
  viewBadgesListWorkerWatcher,
} from "./badgesSaga";
import {
  approveDocumentWorkerWatcher,
  createDocumentWorkerWatcher,
  deleteDocumentWorkerWatcher,
  documentApprovalByIdWatcher,
  documentApprovalListWatcherWorker,
  documentApprovalsExportWatcher,
  documentSubmitWorkerWatcher,
  documentTableExportWatcher,
  documentVersionExportWorkerWatcher,
  editDocumentWorkerWatcher,
  rejectDocumentWorkerWatcher,
  versionHistoryDocumentDetailWatcher,
  versionHistoryDocumentListWatcher,
  viewDocumentDetailWatcherWorker,
  viewDocumentListWorkerWatcher,
} from "./documentSaga";
import {
  referedUserApprovalsExportWatcher,
  referedUserSubmitWorkerWatcher,
  referedUserTableExportWatcher,
  referedUserVersionExportWorkerWatcher,
  watchAddReferedUserWorker,
  watchApprovalsListReferedUserWorker,
  watchApproveReferedUserWorker,
  watchDeleteReferedUserWorker,
  watchEditReferedUserWorker,
  watchRejectReferedUserWorker,
  watchVersionHistoryListReferedUserWorker,
  watchViewAllReferedUsersWorker,
  watchViewApprovalReferedUserWorker,
  watchViewReferedUserWorker,
  watchViewVersionHistoryReferedUserWorker,
} from "./referedUserSaga";
import {
  approveInvitedWorkerWatcher,
  createInvitedWorkerWatcher,
  deleteInvitedWorkerWatcher,
  editInvitedWorkerWatcher,
  invitedApprovalByIdWatcher,
  invitedApprovalListWatcherWorker,
  invitedApprovalsExportWatcher,
  invitedReferralOptionsWorkerWatcher,
  invitedSubmitWorkerWatcher,
  invitedTableExportWatcher,
  invitedVersionExportWorkerWatcher,
  rejectInvitedWorkerWatcher,
  versionHistoryInvitedDetailWatcher,
  versionHistoryInvitedListWatcher,
  viewInvitedDetailWatcherWorker,
  viewInvitedListWorkerWatcher,
} from "./invitedSaga";
import {
  WatchActivateUserReferralWorker,
  watchAddUserReferralWorker,
  watchApprovalsUserReferralListWorker,
  watchApproveUserReferralWorker,
  watchDeleteUserReferralWorker,
  watcheditUserReferralWorker,
  watchFetchOpportunitiesIdWorker,
  watchRejectUserReferralWorker,
  watchUserReferralApprovalsExportWorker,
  watchUserReferralMainExportWorker,
  watchUserReferralSubmitWorker,
  watchUserReferralVersionExportWorker,
  watchversionListUserReferralWorker,
  watchVersionViewUserReferralWorker,
  watchViewApprovalUserReferralWorker,
  watchViewUserReferralListWorker,
  watchViewUserReferralWorker,
} from "./userReferralSaga";
import { socialApprovalsExportWatcher, socialSubmitWorkerWatcher, socialTableExportWatcher, socialVersionExportWorkerWatcher, watchapproveSocialWorker, watcheditSocialWorker, WatcherSocialCreateWorker, watchreadApprovalSocialWorker, watchreadSocialWorker, watchrejectSocialWorker, watchSocialApprovalsTableWorker, watchSocialVersionHistoryWorker, watchSocialWorker, watchversionTableSocialWorker, watchViewAllSocialWorker } from "./socialSaga";
import {
  notificationApprovalsExportWatcher,
  notificationSubmitWorkerWatcher,
  notificationTableExportWatcher,
  notificationVersionExportWorkerWatcher,
  watchapproveNotificationWorker,
  watcheditNotificationWorker,
  WatcherNotificationCreateWorker,
  watchNotificationApprovalsTableWorker,
  watchNotificationVersionHistoryWorker,
  watchNotificationWorker,
  watchreadApprovalNotificationWorker,
  watchreadNotificationWorker,
  watchrejectNotificationWorker,
  watchversionTableNotificationWorker,
  watchViewAllNotificationWorker,
} from "./notificationSaga";
import {
  communityBadgesApprovalsExportWatcher,
  communityBadgesSubmitWorkerWatcher,
  communityBadgesTableExportWatcher,
  communityBadgesVersionExportWorkerWatcher,
  watchAddCommunityBadgesWorker,
  watchApprovalsListCommunityBadgesWorker,
  watchApproveCommunityBadgesWorker,
  watchDeleteCommunityBadgesWorker,
  watchEditCommunityBadgesWorker,
  watchRejectCommunityBadgesWorker,
  watchVersionHistoryListCommunityBadgesWorker,
  watchViewAllCommunityBadgessWorker,
  watchViewApprovalCommunityBadgesWorker,
  watchViewCommunityBadgesWorker,
  watchViewVersionHistoryCommunityBadgesWorker,
} from "./communityBadgesSaga";
import { memberTradeBuyInApprovalsExportWatcher, memberTradeBuyInSubmitWorkerWatcher, memberTradeBuyInTableExportWatcher, memberTradeBuyInVersionExportWorkerWatcher, watchapproveMemberTradeBuyInWorker, watcheditMemberTradeBuyInWorker, WatcherMemberTradeBuyInCreateOptionsWorker, watcherMemberTradeBuyInCreateWorker, WatcherMemberTradeBuyInCreateWorker, watchMemberTradeBuyInApprovalsTableWorker, watchMemberTradeBuyInVersionHistoryWorker, watchMemberTradeBuyInWorker, watchreadApprovalMemberTradeBuyInWorker, watchreadMemberTradeBuyInWorker, watchrejectMemberTradeBuyInWorker, watchversionTableMemberTradeBuyInWorker, watchViewAllMemberTradeBuyInWorker } from "./memberTradeBuyInSaga";
import { memTradeSellApprovalsExportWatcher, memTradeSellSubmitWorkerWatcher, memTradeSellTableExportWatcher, memTradeSellVersionExportWorkerWatcher, watchApprovalsListMemTradeSellWorker, watchApproveMemTradeSellWorker, watchCreateMemTradeSellWorker, watchDeleteMemTradeSellWorker, watchEditMemTradeSellWorker, watchMemTradeSellFindAllWorker, watchMemTradeSellRejectByIdWorker, watchMemTradeSellViewVersionHistorylByIdWorker, watchVersionHistoryListMemTradeSellWorker, watchViewAllMemTradeSellWorker, watchViewMemTradeSellApprovalByIdWorker, watchViewMemTradeSellWorker } from "./memTradeSellSaga";
import { watchPartnerWithUsFormMainExportWorker, watchPartnerWithUsFormSubmitWorker, watchViewPartnerWithUsFormListWorker, watchViewPartnerWithUsFormWorker } from "./partnerWithUsFormSaga";

// import { watchapproveMemberTradeWorker, watcheditMemberTradeWorker, WatcherMemberTradeCreateOptionsWorker, WatcherMemberTradeCreateWorker, watchMemberTradeApprovalsTableWorker, watchMemberTradeVersionHistoryWorker, watchMemberTradeWorker, watchreadApprovalMemberTradeWorker, watchreadMemberTradeWorker, watchrejectMemberTradeWorker, watchversionTableMemberTradeWorker, watchViewAllMemberTradeWorker } from "./memberTradeSaga";

export default function* rootSaga() {
  yield all([
    watchLoginWorker(),
    watchLogoutWorker(),
    addAdminWorkerWatcher(),
    watchAddAdminRoleWorker(),
    watchViewAllAdminRoleWorker(),
    watchViewAdminRoleWorker(),
    watchEditAdminRoleWorker(),
    watchApproveAdminRoleWorker(),
    watchRejectAdminRoleWorker(),
    watchApprovalsListAdminRoleWorker(),
    watchViewApprovalAdminRoleWorker(),
    watchDeleteAdminRoleWorker(),
    viewAdminListWorkerWatcher(),
    watchVersionHistoryListAdminRoleWorker(),
    watchViewVersionHistoryAdminRoleWorker(),
    viewAdminDetailWatcherWorker(),
    watchAdminRoleSubmitWorker(),
    roleTypeWorkerWatcher(),
    editAdminWorkerWatcher(),
    addUserWorkerWatcher(),
    viewAdminDetailWatcherWorker(),
    watchViewOpporTypeWorker(),
    watchEditOpporTypeWorker(),
    watchAddOpporTypeWorker(),
    watchApprovalsListOpporTypeWorker(),
    watchVersionHistoryListOpporTypeWorker(),
    watchViewVersionHistoryOpporTypeWorker(),
    opporTypeSubmitWorkerWatcher(),

    viewAdminDetailWatcherWorker(),
    EditUserWorkerWatcher(),
    watchUserEmailOtpWorker(),
    watchUserPhoneOtpWorker(),
    viewAdminApprovalListWatcher(),
    viewAdminApprovalDetailWatcher(),
    approveAdminWatcher(),
    versionHistoryAdminDetailWatcher(),
    versionHistoryListWatcher(),
    //EXPORT ADMIN
    adminTableExportWatcher(),
    adminApprovalsExportWatcher(),
    adminVersionExportWorkerWatcher(),
    watchAdminSubmitWorker(),
    //EXPORT ROLES
    rolesTableExportWatcher(),
    rolesApprovalsExportWatcher(),
    rolesVersionExportWorkerWatcher(),
    //EXPORT OPP TYPE
    opporTypeTableExportWatcher(),
    opporTypeApprovalsExportWatcher(),
    opporTypeVersionExportWorkerWatcher(),
    // watchindividualUserApprovalDetailWatcher(),
    watchViewAllWishlistWorker(),

    /***Notify Me */
    watchViewAllNotifyMeWorker(),
    watchNotifyMeWorker(),
    watchreadNotifyMeWorker(),
    watchversionTableNotifyMeWorker(),
    watchnotifyMeVersionHistoryWorker(),
    watchnotifyMeApprovalsTableWorker(),
    watchreadApprovalNotifyMeWorker(),
    WatchernotifyMeCreateWorker(),
    WatchernotifyMeCreateOptionsWorker(),
    watcheditNotifyMeWorker(),
    watchapproveNotifyMeWorker(),
    watchrejectNotifyMeWorker(),
    notifySubmitWorkerWatcher(),
    notifyTableExportWatcher(),
    notifyApprovalsExportWatcher(),
    notifyVersionExportWorkerWatcher(),

    rejectAdminWatcher(),
    deleteAdminWorkerWatcher(),
    UserListWatcher(),
    ApprovalUserListWatcher(),
    // watchindividualUserApprovalDetailWatcher(),
    watchindividualUserDetailWatcher(),
    watchResetPasswordEmailViewWorker(),
    UserVersionHistoryListWatcher(),
    UserVersionHistoryViewWatcher(),

    watchindividualUserDetailWatcher(),
    watchDeleteWishlistWorker(),
    watchViewWishlistWorker(),
    watchEditWishlistWorker(),
    watchApproveWishlistWorker(),
    watchApprovalsListWishlistWorker(),
    watchVersionHistoryListWishlistWorker(),
    watchRejectOpporTypeWorker(),
    watchViewApprovalOpporTypeWorker(),
    watchCreateWishlistWorker(),
    watchWishListFindAllWorker(),
    watchViewWishListApprovalByIdWorker(),
    watchViewVersionHistorylByIdWorker(),
    ApproveUserViewWatcher(),
    UserApprovalViewViewWatcher(),
    wishlistSubmitWorkerWatcher(),
    //export
    wishlistTableExportWatcher(),
    wishlistApprovalsExportWatcher(),
    wishlistVersionExportWorkerWatcher(),
    // EditUserWorkerWatcher(),
    deleteUserWorkerWatcher(),
    watchUserSubmitWorker(),
    watchWishListRejectByIdWorker(),
    kycStatusUserWorkerWatcher(),
    rejectedApprovePanUserWorkerWatcher(),
    rejectedApproveBankUserWorkerWatcher(),
    watchForgotPasswordSendEmailWorker(),
    //export
    userTableExportWatcher(),
    userApprovalsExportWatcher(),
    userVersionExportWorkerWatcher(),
    userResetExportWatcher(),
    userEmailExportWatcher(),
    userPhoneExportWorkerWatcher(),
    /*******IMAGE UPLOAD  USER*******/

    watchprofileImageUploadWorker(),
    watchuploadAddressProofFrontImageWorker(),
    watchuploadAddressProofBackImageWorker(),
    watchpanImageUploadWorker(),
    watchbankImageUploadWorker(),
    watchgetProfileImageWorker(),
    addessApprovedWorkerWatcher(),
    // watchuserExportWorker(),
    RejectWorkerWatcher(),
    addessRejectedWorkerWatcher(),
    panApprovedWorkerWatcher(),
    panRejectedWorkerWatcher(),
    bankApprovedWorkerWatcher(),
    bankRejectedWorkerWatcher(),
    watchApproveOpporTypeWorker(),
    watchViewAllOpporTypesWorker(),
    watchDeleteOpporTypeWorker(),

    /********OPPORTUNITIES */
    watchcreateOpportunitiesWorker(),
    watchFetchOpportunitiesWorker(),
    watchFetchOpportunitiesTypeWorker(),
    watchPublishOpportunityWorker(),
    watchApproveOpportunityWorker(),
    watchDeleteOpportunityWorker(),
    watchEditOpportunityWorker(),
    watchFetchOpportunityDetailsWorker(),
    watchFetchOpportunitiesApprovalWorker(),
    //
    watchOpportunitiesVersionTableWorker(),
    watchopportunitiesAnalyticsWorker(),
    watchOpportunitiesVersionViewWorker(),
    watchOpportunitiesNameListWorker(),
    watchOpportunitiesApprovalsViewWorker(),
    watchRejectOpportunityWorker(),
    opportunitiesSubmitWorkerWatcher(),
    /****************LLP***********/
    watchAddLLPWorker(),
    watchApproveLLPWorker(),
    watchApprovalsLLPListWorker(),
    watchViewApprovalLLPWorker(),
    watchVersionHistoryListVersionListLLPWorker(),
    watchversionViewLLPWorker(),
    watchviewLLPListWorker(),
    watchViewLLPWorker(),
    watchdeleteLLPWorker(),
    watcheditLLPWorker(),
    watchLlpMemberDeleteWorker(),
    watchLlpMemberEditWorker(),
    watchLlpMemberAddWorker(),
    watchLlpDocDeleteWorker(),
    watchLlpDocEditWorker(),
    watchLlpDocAddWorker(),
    watchRejectLLPWorker(),
    llpTableExportWatcher(),
    llpApprovalsExportWatcher(),
    llpVersionExportWorkerWatcher(),
    llpSubmitWorkerWatcher(),
    llpImportUsersWorkerWatcher(),
    /****************llp***********/

    /****************OUR PARTNERS***********/
    watchaddPartnerWorker(),
    watchViewParnterWorker(),
    watchViewPartnerListWorker(),
    watcheditPartnerWorker(),
    watchApprovePartnerWorker(),
    watchViewApprovalPartnerWorker(),
    watchRejectPartnerWorker(),
    watchDeletePartnerWorker(),
    watchApprovalsPartnerListWorker(),
    watchViewApprovalPartnerWorker(),
    watchVersionHistoryListPartnerWorker(),
    watchViewVersionHistoryPartnerWorker(),
    partnersTableExportWatcher(),
    partnersApprovalsExportWatcher(),
    partnersVersionExportWorkerWatcher(),
    partnersSubmitWorkerWatcher(),
    opportunitiesTableExportWatcher(),
    opportunitiesApprovalsExportWatcher(),
    opportunitiesVersionExportWorkerWatcher(),

    /****************FAQ***********/
    createFaqWorkerWatcher(),
    viewFaqListWorkerWatcher(),
    viewFaqDetailWatcherWorker(),
    editFaqWorkerWatcher(),
    deleteFaqWorkerWatcher(),
    faqApprovalListWatcherWorker(),
    versionHistoryFaqListWatcher(),
    versionHistoryFaqDetailWatcher(),
    faqApprovalByIdWatcher(),
    approveFaqWorkerWatcher(),
    rejectFaqWorkerWatcher(),
    faqOptionsWorkerWatcher(),
    faqSubmitWorkerWatcher(),
    faqTableExportWatcher(),
    faqApprovalsExportWatcher(),
    faqVersionExportWorkerWatcher(),

    /*************EXPRESS INTEREST **********/
    createExpressWorkerWatcher(),
    approveExpressWorkerWatcher(),
    rejectExpressWorkerWatcher(),
    viewExpressDetailWatcherWorker(),
    viewExpressListWorkerWatcher(),
    editExpressWorkerWatcher(),
    deleteExpressWorkerWatcher(),
    ExpressApprovalListWatcherWorker(),
    expressApprovalByIdWatcher(),
    versionHistoryExpressListWatcher(),
    versionHistoryExpressDetailWatcher(),
    expressTableExportWatcher(),
    expressApprovalsExportWatcher(),
    expressVersionExportWorkerWatcher(),
    expressSubmitWorkerWatcher(),

    /*************OUR ADVISORS **********/
    watchaddAdvisorWorker(),
    watchViewAdvisorWorker(),
    watchViewAdvisorListWorker(),
    watcheditAdvisorWorker(),
    watchApproveAdvisorWorker(),
    watchRejectAdvisorWorker(),
    watchApprovalsAdvisorListWorker(),
    watchViewApprovalAdvisorWorker(),
    watchDeleteAdvisorWorker(),
    watchVersionHistoryListAdvisorWorker(),
    watchViewVersionHistoryAdvisorWorker(),
    advisorsTableExportWatcher(),
    advisorsApprovalsExportWatcher(),
    advisorsVersionExportWorkerWatcher(),
    advisorsSubmitWorkerWatcher(),
    /****************TESTIMONIALS***********/
    createTestimonialsWorkerWatcher(),
    editTestimonialsWorkerWatcher(),
    approveTestimonialsWorkerWatcher(),
    rejectTestimonialsWorkerWatcher(),
    viewTestimonialsDetailWatcherWorker(),
    deletetesTimonialsWorkerWatcher(),
    testimonialsApprovalListWatcherWorker(),
    testimonialsApprovalByIdWatcher(),
    versionHistoryTestimonialsListWatcher(),
    versionHistoryTestimonialsDetailWatcher(),
    testimonialsOptionsWorkerWatcher(),
    viewTestimonialsListWorkerWatcher(),
    testimonialsTableExportWatcher(),
    testimonialsApprovalsExportWatcher(),
    testimonialsVersionExportWorkerWatcher(),
    testimonialsSubmitWorkerWatcher(),

    /****************FAQ CATEGORY ***********/
    createFaqCategoryWorkerWatcher(),
    approveFaqCategoryWorkerWatcher(),
    rejectFaqCategoryCaWorkerWatcher(),
    viewFaqCategoryDetailWatcherWorker(),
    viewFaqCategoryListWorkerWatcher(),
    editFaqCategoryWorkerWatcher(),
    deleteFaqCategoryWorkerWatcher(),
    faqCategoryApprovalListWatcherWorker(),
    faqCategoryApprovalByIdWatcher(),
    versionHistoryFaqCategoryListWatcher(),
    versionHistoryFaqCategoryDetailWatcher(),
    faqCategoryOptionsWorkerWatcher(),
    faqcategoryTableExportWatcher(),
    faqcategoryApprovalsExportWatcher(),
    faqcategoryVersionExportWorkerWatcher(),
    faqcategorySubmitWorkerWatcher(),

    /****************MEDIA RECOGNITION***********/
    watchAddMediaRecWorker(),
    watchViewMediaRecWorker(),
    watchDeleteMediaRecWorker(),
    watchViewMediaRecListWorker(),
    watchMediaQueryVersionHistoryWorker(),
    watchMediaVersionHistoryByIdWorker(),
    watchMediaApprovalsTableWorker(),
    watchMediaApprovalsByIdWorker(),
    watchMediaApprovedWorker(),
    watchMediaRejectWorker(),
    watchMediaEditWorker(),
    watchMediaRecMainExportWorker(),
    watchMediaRecApprovalsExportWorker(),
    watchMediaRecVersionExportWorker(),
    mediaRecSubmitWorkerWatcher(),

    /** our team**** */
    viewOurTeamListWorkerWatcher(),
    viewOurTeamDetailWatcherWorker(),
    editOurTeamWorkerWatcher(),
    deleteOurTeamWorkerWatcher(),
    OurTeamApprovalListWatcherWorker(),
    OurTeamApprovalByIdWatcher(),
    versionHistoryOurTeamListWatcher(),
    versionHistoryOurTeamDetailWatcher(),
    createOutTeamWorkerWatcher(),
    approveOurTeamWorkerWatcher(),
    rejectOurTeamWorkerWatcher(),
    ourTeamTableExportWorker(),
    ourTeamApprovalsExportWatcher(),
    ourTeamVersionExportWorkerWatcher(),
    ourTeamTableExportWatcher(),
    ourTeamApprovalsExportWatcher(),
    ourTeamVersionExportWorkerWatcher(),
    ourTeamSubmitWorkerWatcher(),

    /*****CONTACT US**** */
    watchAddContactWorker(),
    watchApprovalsContactListWorker(),
    watchViewApprovalContactWorker(),
    watchApproveContactWorker(),
    watchRejectContactWorker(),
    watchViewContactWorker(),
    watchViewContactListWorker(),
    watcheditContactWorker(),
    watchVersionListContactWorker(),
    watchversionViewContactWorker(),
    watchDeleteContactWorker(),
    watchContactMainExportWorker(),
    watchContactApprovalsExportWorker(),
    watchContactVersionExportWorker(),
    watchcontactSubmitWorker(),

    /*****NEWS LETTER**** */
    watchAddNewsLetterWorker(),
    watchApprovalsNewsLetterListWorker(),
    watchViewApprovalNewsLetterWorker(),
    watchApproveNewsLetterWorker(),
    watchRejectNewsLetterWorker(),
    watchversionListNewsletterWorker(),
    watchVersionViewNewsletterWorker(),
    watchViewNewsLetterListWorker(),
    watchViewNewsLetterWorker(),
    watcheditNewsLetterWorker(),
    watchDeleteNewsletterWorker(),
    watchNewsLetterMainExportWorker(),
    watchNewsLetterApprovalsExportWorker(),
    watchNewsLetterVersionExportWorker(),
    newsLetterSubmitWorkerWatcher(),

    /****************STATIC***********/
    createStaticWorkerWatcher(),
    staticApprovalListWatcherWorker(),
    staticApprovalByIdWatcher(),
    approveStaticWorkerWatcher(),
    rejectStaticWorkerWatcher(),
    viewStaticListWorkerWatcher(),
    viewStaticDetailWatcherWorker(),
    editStaticWorkerWatcher(),
    deleteStaticWorkerWatcher(),
    versionHistoryStaticListWatcher(),
    versionHistoryStaticDetailWatcher(),
    staticOptionsWorkerWatcher(),
    watchStaticMainExportWorker(),
    watchStaticApprovalsExportWorker(),
    watchStaticVersionExportWorker(),
    watchStaticSubmitWorker(),

    /********BLOG CATEGORY******* */
    createBlogCategoryWorkerWatcher(),
    approveBlogCategoryWorkerWatcher(),
    rejectBlogCategoryCaWorkerWatcher(),
    viewBlogCategoryDetailWatcherWorker(),
    viewBlogCategoryListWorkerWatcher(),
    editBlogCategoryWorkerWatcher(),
    deleteBlogCategoryWorkerWatcher(),
    blogCategoryApprovalListWatcherWorker(),
    blogCategoryApprovalByIdWatcherWorker(),
    versionHistoryBlogCategoryListWatcher(),
    versionHistoryBlogCategoryDetailWatcher(),
    watchBlogCategoryMainExportWorker(),
    watchBlogCategoryApprovalsExportWorker(),
    watchBlogCategoryVersionExportWorker(),
    watchblogCategorySubmitWorker(),

    /**********BLOG*********/
    viewBlogListWorkerWatcher(),
    blogApprovalListWatcherWorker(),
    versionHistoryBlogListWorkerWatcher(),
    watchBlogCategoryOptionsWorker(),
    viewBlogDetailWatcherWorker(),
    createBlogWorkerWatcher(),
    blogApprovalByIdWorkerWatcher(),
    approveBlogWorkerWatcher(),
    rejectBlogWorkerWatcher(),
    versionHistoryBlogDetailWatcher(),
    deleteBlogWorkerWatcher(),
    watchBlogOptionsWorker(),
    editBlogWorkerWatcher(),
    watchBlogMainExportWorker(),
    watchBlogApprovalsExportWorker(),
    watchBlogVersionExportWorker(),
    watchblogSubmitWorker(),

    /*****HOW IT WORKS**** */
    watchAddHowItWorksWorker(),
    watchApprovalsHowItWorksListWorker(),
    watchViewApprovalHowItWorksWorker(),
    watchApproveHowItWorksWorker(),
    watchRejectHowItWorksWorker(),
    watchversionListHowItWorksWorker(),

    watchVersionViewHowItWorksWorker(),
    watchViewHowItWorksListWorker(),
    watchViewHowItWorksWorker(),
    watcheditHowItWorksWorker(),
    watchDeleteHowItWorksWorker(),
    WatchActivateHowItWorksWorker(),
    watchhowItWorksMainExportWorker(),
    watchhowItWorksApprovalsExportWorker(),
    watchhowItWorksVersionExportWorker(),
    watchHowItWorksSubmitWorker(),

    /***MemberTrade Me */
    watchViewAllMemberTradeWorker(),
    watchMemberTradeWorker(),
    watchreadMemberTradeWorker(),
    watchversionTableMemberTradeWorker(),
    watchMemberTradeVersionHistoryWorker(),
    watchMemberTradeApprovalsTableWorker(),
    watchreadApprovalMemberTradeWorker(),
    WatcherMemberTradeCreateWorker(),
    WatcherMemberTradeCreateOptionsWorker(),
    watcheditMemberTradeWorker(),
    watchapproveMemberTradeWorker(),
    watchrejectMemberTradeWorker(),
    memberTableExportWatcher(),
    memberApprovalsExportWatcher(),
    memberVersionExportWorkerWatcher(),
    memberSubmitWorkerWatcher(),
    /***************HOME*** */
    watchAddHomeWorker(),
    watchViewHomeWorker(),
    watchEditHomeWorker(),
    watchHomeSubmitWorker(),
    watchViewApprovalHomeWorker(),
    watchApproveHomeWorker(),
    watchRejectHomeWorker(),
    watchVersionViewHomeWorker(),
    watchViewHomeListWorker(),
    watchHomeMainExportWorker(),
    watchApprovalsHomeListWorker(),
    watchHomeApprovalsExportWorker(),
    watchHomeVersionExportWorker(),
    watchVersionListHomeWorker(),


    /***MemberTrade buy in */
    watchViewAllMemberTradeBuyInWorker(),
    watchMemberTradeBuyInWorker(),
    watchreadMemberTradeBuyInWorker(),
    watchversionTableMemberTradeBuyInWorker(),
    watchMemberTradeBuyInVersionHistoryWorker(),
    watchMemberTradeBuyInApprovalsTableWorker(),
    watchreadApprovalMemberTradeBuyInWorker(),
    watcherMemberTradeBuyInCreateWorker(),
    WatcherMemberTradeBuyInCreateOptionsWorker(),
    watcheditMemberTradeBuyInWorker(),
    watchapproveMemberTradeBuyInWorker(),
    watchrejectMemberTradeBuyInWorker(),
    memberTradeBuyInTableExportWatcher(),
    memberTradeBuyInApprovalsExportWatcher(),
    memberTradeBuyInVersionExportWorkerWatcher(),
    memberTradeBuyInSubmitWorkerWatcher(),

    /****************CAMPAIGN OPPOR***********/
    createCampaignOpporWorkerWatcher(),
    viewCampaignOpporListWorkerWatcher(),
    viewCampaignOpporDetailWatcherWorker(),
    editCampaignOpporWorkerWatcher(),
    deleteCampaignOpporWorkerWatcher(),
    CampaignOpporApprovalListWatcherWorker(),
    versionHistoryCampaignOpporListWatcher(),
    versionHistoryCampaignOpporDetailWatcher(),
    CampaignOpporApprovalByIdWatcher(),
    approveCampaignOpporWorkerWatcher(),
    rejectCampaignOpporWorkerWatcher(),
    campaignOpporSubmitWorkerWatcher(),
    campaignOpporTableExportWatcher(),
    campaignOpporApprovalsExportWatcher(),
    campaignOpporVersionExportWorkerWatcher(),
    watchDeleteHomeWorker(),
    WatchActivateHomeWorker(),
    campaignOpporActiveWorkerWatcher(),

    /******CAMPAIGN********* */
    watchAddCampaignWorker(),
    watchEditCampaignWorker(),
    watchCampaignSubmitWorker(),
    watchViewCampaignWorker(),
    watchViewApprovalCampaignWorker(),
    watchApproveCampaignWorker(),
    watchRejectCampaignWorker(),
    watchVersionViewCampaignWorker(),
    watchViewCampaignListWorker(),
    watchDeleteCampaignWorker(),
    WatchActivateCampaignWorker(),
    watchCampaignMainExportWorker(),
    watchCampaignApprovalsExportWorker(),
    watchCampaignVersionExportWorker(),
    watchApprovalsCampaignListWorker(),
    watchVersionListCampaignWorker(),

    /*****INSIGHTS**** */
    watchAddInsightsWorker(),
    watchApprovalsInsightsListWorker(),
    watchViewApprovalInsightsWorker(),
    watchApproveInsightsWorker(),
    watchRejectInsightsWorker(),
    watchversionListInsightsWorker(),
    watchVersionViewInsightsWorker(),
    watchViewInsightsListWorker(),
    watchViewInsightsWorker(),
    watcheditInsightsWorker(),
    watchDeleteInsightsWorker(),
    WatchActivateInsightsWorker(),
    watchInsightsMainExportWorker(),
    watchInsightsApprovalsExportWorker(),
    watchInsightsVersionExportWorker(),
    watchInsightsSubmitWorker(),

    /*****USER REFERRAL**** */
    watchAddUserReferralWorker(),
    watchApprovalsUserReferralListWorker(),
    watchViewApprovalUserReferralWorker(),
    watchApproveUserReferralWorker(),
    watchRejectUserReferralWorker(),
    watchversionListUserReferralWorker(),
    watchVersionViewUserReferralWorker(),
    watchViewUserReferralListWorker(),
    watchViewUserReferralWorker(),
    watcheditUserReferralWorker(),
    watchDeleteUserReferralWorker(),
    watchUserReferralMainExportWorker(),
    watchUserReferralApprovalsExportWorker(),
    watchUserReferralVersionExportWorker(),
    watchFetchOpportunitiesIdWorker(),
    watchUserReferralSubmitWorker(),

    /******CAMPAIGN********* */
    watchAddPartnerWithUsWorker(),
    watchEditPartnerWithUsWorker(),
    watchPartnerWithUsSubmitWorker(),
    watchViewPartnerWithUsWorker(),
    watchViewApprovalPartnerWithUsWorker(),
    watchApprovePartnerWithUsWorker(),
    watchRejectPartnerWithUsWorker(),
    watchVersionViewPartnerWithUsWorker(),
    watchViewPartnerWithUsListWorker(),
    watchDeletePartnerWithUsWorker(),
    WatchActivatePartnerWithUsWorker(),
    watchPartnerWithUsMainExportWorker(),
    watchPartnerWithUsApprovalsExportWorker(),
    watchPartnerWithUsVersionExportWorker(),
    watchApprovalsPartnerWithUsListWorker(),
    watchVersionListPartnerWithUsWorker(),



    /*******PARTNER WITH US FORM *********/
    // watchAddPartnerWithUsFormWorker(),
    // watchEditPartnerWithUsFormWorker(),
    // watchPartnerWithUsFormSubmitWorker(),
    watchViewPartnerWithUsFormWorker(),
    // watchViewApprovalPartnerWithUsFormWorker(),
    // watchApprovePartnerWithUsFormWorker(),
    // watchRejectPartnerWithUsFormWorker(),
    // watchVersionViewPartnerWithUsFormWorker(),
    watchViewPartnerWithUsFormListWorker(),
    // watchDeletePartnerWithUsFormWorker(),
    // WatchActivatePartnerWithUsFormWorker(),
    // watchPartnerWithUsFormMainExportWorker(),
    // watchPartnerWithUsFormApprovalsExportWorker(),
    // watchPartnerWithUsFormVersionExportWorker(),
    // watchApprovalsPartnerWithUsFormListWorker(),
    // watchVersionListPartnerWithUsFormWorker(),

    /****************REFER AND***********/
    createReferWorkerWatcher(),
    viewReferListWorkerWatcher(),
    viewReferDetailWatcherWorker(),
    editReferWorkerWatcher(),
    deleteReferWorkerWatcher(),
    ReferApprovalListWatcherWorker(),
    versionHistoryReferListWatcher(),
    versionHistoryReferDetailWatcher(),
    ReferApprovalByIdWatcher(),
    approveReferWorkerWatcher(),
    rejectReferWorkerWatcher(),
    ReferSubmitWorkerWatcher(),
    ReferTableExportWatcher(),
    ReferApprovalsExportWatcher(),
    ReferVersionExportWorkerWatcher(),
    /****************BADGES***********/
    createBadgesWorkerWatcher(),
    viewBadgesListWorkerWatcher(),
    viewBadgesDetailWatcherWorker(),
    editBadgesWorkerWatcher(),
    deleteBadgesWorkerWatcher(),
    badgesApprovalListWatcherWorker(),
    versionHistoryBadgesListWatcher(),
    versionHistoryBadgesDetailWatcher(),
    badgesApprovalByIdWatcher(),
    approveBadgesWorkerWatcher(),
    rejectBadgesWorkerWatcher(),
    badgesSubmitWorkerWatcher(),
    badgesTableExportWatcher(),
    badgesApprovalsExportWatcher(),
    badgesVersionExportWorkerWatcher(),

    /************DOCUMENT***********/
    createDocumentWorkerWatcher(),
    viewDocumentListWorkerWatcher(),
    viewDocumentDetailWatcherWorker(),
    editDocumentWorkerWatcher(),
    deleteDocumentWorkerWatcher(),
    documentApprovalListWatcherWorker(),
    versionHistoryDocumentListWatcher(),
    versionHistoryDocumentDetailWatcher(),
    documentApprovalByIdWatcher(),
    approveDocumentWorkerWatcher(),
    rejectDocumentWorkerWatcher(),
    documentSubmitWorkerWatcher(),
    documentTableExportWatcher(),
    documentApprovalsExportWatcher(),
    documentVersionExportWorkerWatcher(),

    watchViewAllReferedUsersWorker(),
    watchAddReferedUserWorker(),
    watchEditReferedUserWorker(),
    watchDeleteReferedUserWorker(),
    watchViewReferedUserWorker(),
    watchRejectReferedUserWorker(),
    watchApproveReferedUserWorker(),
    watchViewApprovalReferedUserWorker(),
    watchApprovalsListReferedUserWorker(),
    watchVersionHistoryListReferedUserWorker(),
    watchViewVersionHistoryReferedUserWorker(),
    referedUserTableExportWatcher(),
    referedUserApprovalsExportWatcher(),
    referedUserVersionExportWorkerWatcher(),
    referedUserSubmitWorkerWatcher(),

    /************INVITED MEMBERS***************/
    createInvitedWorkerWatcher(),
    viewInvitedListWorkerWatcher(),
    viewInvitedDetailWatcherWorker(),
    editInvitedWorkerWatcher(),
    deleteInvitedWorkerWatcher(),
    invitedApprovalListWatcherWorker(),
    versionHistoryInvitedListWatcher(),
    versionHistoryInvitedDetailWatcher(),
    invitedApprovalByIdWatcher(),
    approveInvitedWorkerWatcher(),
    rejectInvitedWorkerWatcher(),
    invitedSubmitWorkerWatcher(),
    invitedTableExportWatcher(),
    invitedApprovalsExportWatcher(),
    invitedVersionExportWorkerWatcher(),
    invitedReferralOptionsWorkerWatcher(),

    /***Notification*/
    watchViewAllNotificationWorker(),
    watchNotificationWorker(),
    watchreadNotificationWorker(),
    watchversionTableNotificationWorker(),
    watchNotificationVersionHistoryWorker(),
    watchNotificationApprovalsTableWorker(),
    watchreadApprovalNotificationWorker(),
    WatcherNotificationCreateWorker(),
    watcheditNotificationWorker(),
    watchapproveNotificationWorker(),
    watchrejectNotificationWorker(),
    notificationSubmitWorkerWatcher(),
    notificationTableExportWatcher(),
    notificationApprovalsExportWatcher(),
    notificationVersionExportWorkerWatcher(),

    /***SOCIAL*/
    watchViewAllSocialWorker(),
    watchSocialWorker(),
    watchreadSocialWorker(),
    watchversionTableSocialWorker(),
    watchSocialVersionHistoryWorker(),
    watchSocialApprovalsTableWorker(),
    watchreadApprovalSocialWorker(),
    WatcherSocialCreateWorker(),
    watcheditSocialWorker(),
    watchapproveSocialWorker(),
    watchrejectSocialWorker(),
    socialSubmitWorkerWatcher(),
    socialTableExportWatcher(),
    socialApprovalsExportWatcher(),
    socialVersionExportWorkerWatcher(),


    /********COMMUNITY BADGES */
    watchViewAllCommunityBadgessWorker(),
    watchAddCommunityBadgesWorker(),
    watchEditCommunityBadgesWorker(),
    watchDeleteCommunityBadgesWorker(),
    watchViewCommunityBadgesWorker(),
    watchRejectCommunityBadgesWorker(),
    watchApproveCommunityBadgesWorker(),
    watchViewApprovalCommunityBadgesWorker(),
    watchApprovalsListCommunityBadgesWorker(),
    watchVersionHistoryListCommunityBadgesWorker(),
    watchViewVersionHistoryCommunityBadgesWorker(),
    communityBadgesTableExportWatcher(),
    communityBadgesApprovalsExportWatcher(),
    communityBadgesVersionExportWorkerWatcher(),
    communityBadgesSubmitWorkerWatcher(),


    /********MEMTRADE SELL OUT */
    watchViewAllMemTradeSellWorker(),
    watchDeleteMemTradeSellWorker(),
    watchViewMemTradeSellWorker(),
    watchEditMemTradeSellWorker(),
    watchApproveMemTradeSellWorker(),
    watchApprovalsListMemTradeSellWorker(),
    watchVersionHistoryListMemTradeSellWorker(),
    watchCreateMemTradeSellWorker(),
    watchViewMemTradeSellApprovalByIdWorker(),
    memTradeSellSubmitWorkerWatcher(),
    memTradeSellTableExportWatcher(),
    memTradeSellApprovalsExportWatcher(),
    memTradeSellVersionExportWorkerWatcher(),
    watchMemTradeSellRejectByIdWorker(),
    watchMemTradeSellViewVersionHistorylByIdWorker(),
    watchMemTradeSellFindAllWorker()
  ]);
}
