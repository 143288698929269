import { APPROVE_FAQ, CREATE_FAQ, DELETE_FAQ, EDIT_FAQ, FAQ_APPROVALS_EXPORT, FAQ_APPROVAL_TABLE, FAQ_APPROVAL_VIEW, FAQ_EXPORT, FAQ_OPTIONS, FAQ_SUBMIT, FAQ_TABLE, FAQ_VERSION_EXPORT, FAQ_VERSION_HISTORY, FAQ_VERSION_HISTORY_TABLE, REJECT_FAQ, VIEW_FAQ } from "./types";


export const createFaq = (
    data,
    onSuccess,
    onError
) => {
    return {
        type: CREATE_FAQ,
        data,
        onSuccess,
        onError,
    };
};

export const approveFaq = (id, onSuccess, onError) => {
    return {
        type: APPROVE_FAQ,
        id,
        onSuccess,
        onError,
    };
};

export const rejectFaq = (id, onSuccess, onError) => {
    return {
        type: REJECT_FAQ,
        id,
        onSuccess,
        onError,
    };
};

export const viewFaqDetail = (id, onSuccess, onError) => {
    return {
        type: VIEW_FAQ,
        id,
        onSuccess,
        onError,
    };
};

export const viewFaqList = (data, page_no = 0, onSuccess, onError) => {
    return {
        type: FAQ_TABLE,
        data,
        page_no,
        onSuccess,
        onError,
    };
};

export const editFaq = (
    id,
    data,
    onSuccess,
    onError
) => {
    return {
        type: EDIT_FAQ,
        id,
        data,
        onSuccess,
        onError,
    };
};


export const deleteFaq = (id, onSuccess, onError) => {
    return {
        type: DELETE_FAQ,
        id,
        onSuccess,
        onError,
    };
};



export const FaqApprovalList = (data, page_no = 0, onSuccess, onError) => {
    return {
        type: FAQ_APPROVAL_TABLE,
        data,
        page_no,
        onSuccess,
        onError,
    };
};

export const FaqApprovalById = (id, onSuccess, onError) => {
    return {
        type: FAQ_APPROVAL_VIEW,
        id,
        onSuccess,
        onError,
    };
};


export const versionHistoryFaqList = (data, page, onSuccess, onError) => {
    return {
        type: FAQ_VERSION_HISTORY_TABLE,
        data,
        page,
        onSuccess,
        onError,
    };
};

export const versionHistoryFaqDetail = (id, onSuccess, onError) => {
    return {
        type: FAQ_VERSION_HISTORY,
        id,
        onSuccess,
        onError,
    };
};
export const faqOptions = (onSuccess, onError) => {
    return {
        type: FAQ_OPTIONS,
        onSuccess,
        onError,
    };
};

export const faqTableExport = (onSuccess, onError) => {
    return {
        type: FAQ_EXPORT,
        onSuccess,
        onError,
    };
};
export const faqApprovalsExport = (onSuccess, onError) => {
    return {
        type: FAQ_APPROVALS_EXPORT,
        onSuccess,
        onError,
    };
};
export const faqVersionExport = (onSuccess, onError) => {
    return {
        type: FAQ_VERSION_EXPORT,
        onSuccess,
        onError,
    };
};



export const faqSubmit = (id, onSuccess, onError) => {
    return {
        type: FAQ_SUBMIT,
        id,
        onSuccess,
        onError,
    };
};



