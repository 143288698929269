import { call, takeLatest } from "redux-saga/effects";
import {
  ACTIVATE_PARTNER_WITH_US_OPTIONS,
  PARTNER_WITH_US_APPROVALS_EXPORT,
  PARTNER_WITH_US_APPROVALS_LIST,
  PARTNER_WITH_US_APPROVALS_VIEW,
  PARTNER_WITH_US_APPROVE,
  PARTNER_WITH_US_CREATE,
  PARTNER_WITH_US_DELETE,
  PARTNER_WITH_US_EDIT,
  PARTNER_WITH_US_FORM_LIST,
  PARTNER_WITH_US_FORM_MAIN_EXPORT,
  PARTNER_WITH_US_FORM_READ,
  PARTNER_WITH_US_FORM_SUBMIT,
  PARTNER_WITH_US_LIST,
  PARTNER_WITH_US_MAIN_EXPORT,
  PARTNER_WITH_US_READ,
  PARTNER_WITH_US_REJECT,
  PARTNER_WITH_US_SUBMIT,
  PARTNER_WITH_US_VERSION_EXPORT,
  PARTNER_WITH_US_VERSION_LIST,
  PARTNER_WITH_US_VERSION_VIEW,
} from "../actions/types";
import { activatepartnerWithUsApi, addpartnerWithUsApi, approvalspartnerWithUsListpApi, approvepartnerWithUsApi, deletepartnerWithUsApi, editpartnerWithUsApi, partnerWithUsApprovalsExportApi, partnerWithUsMainExportApi, partnerWithUsSubmitApi, partnerWithUsVersionExportApi, rejectpartnerWithUsApi, versionpartnerWithUsListpApi, viewApprovalpartnerWithUsApi, viewpartnerWithUsApi, viewpartnerWithUsListApi, viewVersionpartnerWithUsApi } from "../apis/partnerWithUsApi";
import { partnerWithUsFormMainExportApi, partnerWithUsFormSubmitApi, viewpartnerWithUsFormApi, viewpartnerWithUsFormListApi } from "../apis/partnerWithUsFormApi";

// export function* addPartnerWithUsFormWorker(action) {
//   try {
//     const res = yield call(addpartnerWithUsFormApi, action.data);
//     console.log(res);
//     if (res.status === 200 && res.data.status === "success") {
//       yield action.onSuccess(res.data);
//       console.log(res.data);
//     } else if (res.status === 200 && res.data.status === "fail") {
//       console.log(res.data);
//       yield action.onError(res);
//     } else {
//       yield action.onError("Something Went Wrong");
//     }
//   } catch (err) {
//     console.log(err);
//     yield action.onError("Something Went Wrong");
//   }
// }

export function* viewPartnerWithUsFormWorker(action) {
  try {
    const res = yield call(viewpartnerWithUsFormApi, action.id);
    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res.data);
      console.log(res.data);
    } else if (res.status === 200 && res.data.status === "fail") {
      console.log(res.data);
      yield action.onError(res);
    } else {
      yield action.onError("Something Went Wrong!");
      console.log(res.data);
    }
  } catch (err) {
    console.log(err);
    yield action.onError("Something Went Wrong!!");
  }
}
// export function* editPartnerWithUsFormWorker(action) {
//   try {
//     const res = yield call(editpartnerWithUsFormApi, action.id, action.data);
//     console.log(res);
//     if (res.status === 200 && res.data.status === "success") {
//       yield action.onSuccess(res.data);
//       console.log(res.data);
//     } else if (res.status === 200 && res.data.status === "fail") {
//       console.log(res);
//       yield action.onError(res);
//     } else {
//       yield action.onError("Something Went Wrong");
//     }
//   } catch (err) {
//     console.log(err);
//     yield action.onError("Something Went Wrong");
//   }
// }
// export function* deletePartnerWithUsFormWorker(action) {
//   try {
//     const res = yield call(deletepartnerWithUsFormApi, action.id);
//     if (res.status === 200 && res.data.status === "success") {
//       yield action.onSuccess(res.data);
//       console.log(res.data);
//     } else if (res.status === 200 && res.data.status === "fail") {
//       console.log(res.data);
//       yield action.onError(res);
//     } else {
//       yield action.onError("Something Went Wrong");
//     }
//   } catch (err) {
//     console.log(err);
//     yield action.onError("Something Went Wrong");
//   }
// }
export function* viewPartnerWithUsFormListWorker(action) {
  try {
    const res = yield call(viewpartnerWithUsFormListApi, action.page, action.search);
    console.log(res);
    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res.data);
      console.log(res.data);
    } else if (res.status === 200 && res.data.status === "fail") {
      console.log(res);
      yield action.onError(res.data);
    } else {
      yield action.onError("Something Went Wrong");
    }
  } catch (err) {
    console.log(err);
    yield action.onError("Something Went Wrong");
  }
}

// export function* approvePartnerWithUsFormWorker(action) {
//   try {
//     const res = yield call(approvepartnerWithUsFormApi, { partner_id: action.id });
//     if (res.status === 200 && res.data.status === "success") {
//       yield action.onSuccess(res.data);
//       console.log(res.data);
//     } else if (res.status === 200 && res.data.status === "fail") {
//       console.log(res);
//       yield action.onError(res.data);
//     } else {
//       yield action.onError("Something Went Wrong!");
//       console.log(res.data);
//     }
//   } catch (err) {
//     console.log(err);
//     yield action.onError("Something Went Wrong!!");
//   }
// }

// export function* rejectPartnerWithUsFormWorker(action) {
//   try {
//     const res = yield call(rejectpartnerWithUsFormApi, action.id);
//     console.log(res, "rekect PartnerWithUsForm");
//     if (res.status === 200 && res.data.status === "success") {
//       yield action.onSuccess(res.data);
//       console.log(res.data);
//     } else if (res.status === 200 && res.data.status === "fail") {
//       console.log(res);
//       yield action.onError(res.data);
//     } else {
//       yield action.onError("Something Went Wrong!");
//       console.log(res.data);
//     }
//   } catch (err) {
//     console.log(err);
//     yield action.onError("Something Went Wrong!!");
//   }
// }
// export function* approvalsPartnerWithUsFormListWorker(action) {
//   try {
//     const res = yield call(
//       approvalspartnerWithUsFormListpApi,
//       action.page,
//       action.search
//     );
//     // console.log(res, "AddAdminRole");
//     if (res.status === 200 && res.data.status === "success") {
//       yield action.onSuccess(res.data);
//       console.log(res.data);
//     } else if (res.status === 200 && res.data.status === "fail") {
//       console.log(res);
//       yield action.onError(res);
//     } else {
//       yield action.onError("Something Went Wrong!");
//       console.log(res.data);
//     }
//   } catch (err) {
//     console.log(err);
//     yield action.onError("Something Went Wrong!!");
//   }
// }
// export function* viewApprovalPartnerWithUsFormWorker(action) {
//   try {
//     const res = yield call(viewApprovalpartnerWithUsFormApi, action.id);
//     console.log(res, "PartnerWithUsForm approvals");
//     if (res.status === 200 && res.data.status === "success") {
//       yield action.onSuccess(res.data);
//       console.log(res.data);
//     } else if (res.status === 200 && res.data.status === "fail") {
//       console.log(res);
//       yield action.onError(res);
//     } else {
//       yield action.onError("Something Went Wrong!");
//       console.log(res.data);
//     }
//   } catch (err) {
//     console.log(err);
//     yield action.onError("Something Went Wrong!!");
//   }
// }
// export function* versionListPartnerWithUsFormWorker(action) {
//   try {
//     const res = yield call(versionpartnerWithUsFormListpApi, action.page, action.search);
//     // console.log(res, "AddAdminRole");
//     if (res.status === 200 && res.data.status === "success") {
//       yield action.onSuccess(res.data);
//       console.log(res.data);
//     } else if (res.status === 200 && res.data.status === "fail") {
//       console.log(res);
//       yield action.onError(res);
//     } else {
//       yield action.onError("Something Went Wrong!");
//       console.log(res.data);
//     }
//   } catch (err) {
//     console.log(err);
//     yield action.onError("Something Went Wrong!!");
//   }
// }
// export function* activatePartnerWithUsFormWorker(action) {
//   try {
//     const res = yield call(activatepartnerWithUsFormApi, action.id);
//     if (res.status === 200 && res.data.status === "success") {
//       yield action.onSuccess(res);
//       console.log(res.data);
//     } else if (res.status === 200 && res.data.status === "fail") {
//       console.log(res);
//       yield action.onError(res);
//     } else {
//       yield action.onError("Something Went Wrong!");
//       console.log(res);
//     }
//   } catch (err) {
//     console.log(err);
//     yield action.onError("Something Went Wrong!!");
//   }
// }
// export function* versionViewPartnerWithUsFormWorker(action) {
//   try {
//     const res = yield call(viewVersionpartnerWithUsFormApi, action.id);
//     // console.log('====================================');
//     // console.log(res);
//     // console.log('====================================');
//     if (res.status === 200 && res.data?.status === "success") {
//       yield action.onSuccess(res);
//       // console.log(res.data);
//     } else if (res.status === 200 && res.data?.status === "fail") {
//       // console.log(res);
//       yield action.onError(res);
//     } else {
//       yield action.onError("Something Went Wrong!");
//       console.log(res);
//     }
//   } catch (err) {
//     console.log(err);
//     yield action.onError("Something Went Wrong!!");
//   }
// }

// export function* partnerWithUsFormMainExportWorker(action) {
//   try {
//     const res = yield call(partnerWithUsFormMainExportApi);
//     console.log(res);
//     if (res.status === 200 && res.data.status === "success") {
//       yield action.onSuccess(res.data);
//     } else {
//       yield action.onError(res.data);
//     }
//   } catch (error) { }
// }

// export function* partnerWithUsFormApprovalsExportWorker(action) {
//   try {
//     const res = yield call(partnerWithUsFormApprovalsExportApi);
//     console.log(res);
//     if (res.status === 200 && res.data.status === "success") {
//       yield action.onSuccess(res.data);
//     } else {
//       yield action.onError(res.data);
//     }
//   } catch (error) { }
// }

// export function* partnerWithUsFormVersionExportWorker(action) {
//   try {
//     const res = yield call(partnerWithUsFormVersionExportApi);
//     console.log(res);
//     if (res.status === 200 && res.data.status === "success") {
//       yield action.onSuccess(res.data);
//     } else {
//       yield action.onError(res.data);
//     }
//   } catch (error) { }
// }

// export function* partnerWithUsFormSubmitWorker(action) {
//   try {
//     const res = yield call(partnerWithUsFormSubmitApi, action.id);
//     console.log(res);
//     if (res.status === 200 && res.data.status === "success") {
//       yield action.onSuccess(res.data);
//     } else {
//       yield action.onError(res.data);
//     }
//   } catch (error) { }
// }

/**********************************/
/************WATHCHERS**************/
/**********************************/

// export function* watchAddPartnerWithUsFormWorker() {
//   yield takeLatest(PARTNER_WITH_US_FORM_CREATE, addPartnerWithUsFormWorker);
// }
// export function* watchApprovalsPartnerWithUsFormListWorker() {
//   yield takeLatest(PARTNER_WITH_US_FORM_APPROVALS_LIST, approvalsPartnerWithUsFormListWorker);
// }
// export function* watchViewApprovalPartnerWithUsFormWorker() {
//   yield takeLatest(PARTNER_WITH_US_FORM_APPROVALS_VIEW, viewApprovalPartnerWithUsFormWorker);
// }
export function* watchViewPartnerWithUsFormWorker() {
  yield takeLatest(PARTNER_WITH_US_FORM_READ, viewPartnerWithUsFormWorker);
}
export function* watchViewPartnerWithUsFormListWorker() {
  yield takeLatest(PARTNER_WITH_US_FORM_LIST, viewPartnerWithUsFormListWorker);
}
// export function* watchEditPartnerWithUsFormWorker() {
//   yield takeLatest(PARTNER_WITH_US_FORM_EDIT, editPartnerWithUsFormWorker);
// }
// export function* watchApprovePartnerWithUsFormWorker() {
//   yield takeLatest(PARTNER_WITH_US_FORM_APPROVE, approvePartnerWithUsFormWorker);
// }
// export function* watchRejectPartnerWithUsFormWorker() {
//   yield takeLatest(PARTNER_WITH_US_FORM_REJECT, rejectPartnerWithUsFormWorker);
// }
// export function* watchDeletePartnerWithUsFormWorker() {
//   yield takeLatest(PARTNER_WITH_US_FORM_DELETE, deletePartnerWithUsFormWorker);
// }
// export function* watchVersionListPartnerWithUsFormWorker() {
//   yield takeLatest(PARTNER_WITH_US_FORM_VERSION_LIST, versionListPartnerWithUsFormWorker);
// }
// export function* watchVersionViewPartnerWithUsFormWorker() {
//   yield takeLatest(PARTNER_WITH_US_FORM_VERSION_VIEW, versionViewPartnerWithUsFormWorker);
// }
// export function* WatchActivatePartnerWithUsFormWorker() {
//   yield takeLatest(ACTIVATE_PARTNER_WITH_US_FORM_OPTIONS, activatePartnerWithUsFormWorker);
// }
// export function* watchPartnerWithUsFormMainExportWorker() {
//   yield takeLatest(PARTNER_WITH_US_FORM_MAIN_EXPORT, partnerWithUsFormMainExportWorker);
// }
// export function* watchPartnerWithUsFormApprovalsExportWorker() {
//   yield takeLatest(PARTNER_WITH_US_FORM_APPROVALS_EXPORT, partnerWithUsFormApprovalsExportWorker);
// }
// export function* watchPartnerWithUsFormVersionExportWorker() {
//   yield takeLatest(PARTNER_WITH_US_FORM_VERSION_EXPORT, partnerWithUsFormVersionExportWorker);
// }
// export function* watchPartnerWithUsFormSubmitWorker() {
//   yield takeLatest(PARTNER_WITH_US_FORM_SUBMIT, partnerWithUsFormSubmitWorker);
// }
