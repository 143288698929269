import arrowUp from "../../../assets/icons/arrowup.svg";
// import arrowDown from "../../../assets/icons/arrowdown.svg";
import CloseIcon from "../../../assets/icons/closeIcon";
import React, { useState } from "react";
import "./mainlayout.css";
import upicdeLogo from "../../../assets/images/Upcidelogo.svg";
import ArrowDownIcon from "../../../assets/icons/arrowdown";
import { useLocation, useNavigate } from "react-router-dom";
import usePermissionChecker from "../../utilits/usePermissionChecker";

const LayoutSibarBar = ({ handleClose, sideBarStatus }) => {
  const [opened, setOpened] = useState({
    users: false,
    admin: false,
    opportunity: false,
    staticPages: false,
    blog: false,
  });
  const navigate = useNavigate();
  const location = useLocation();
  const checkerAccess = usePermissionChecker("checker");
  return (
    <>
      {sideBarStatus && (
        <div className="mainlayout_sidebar_Active d-lg-none"></div>
      )}
      <div
        className={`${
          sideBarStatus ? "d-block" : "mainlayout_sidebar_display"
        }   mainlayout_sidebar_container  bg-white `}
      >
        <div className="mainlayout_sidebar_inner__container  position-sticky">
          <div
            className={`mx-3 mx-lg-auto  mainlayout_logo_container d-flex align-items-center justify-content-between`}
          >
            <img
              className="mainlayout_upcide__logo"
              src={upicdeLogo}
              alt="upcide logo"
            />

            <span onClick={handleClose} className={`d-inline-block d-lg-none`}>
              <CloseIcon fill="rgba(72,71,71,1)" width={16} height={16} />
            </span>
          </div>

          <nav className="mt-4 mx-4">
            <h3 className="fn_Nunito fs_12 dark_title_color mb-3">
              NAVIGATION
            </h3>
            <div className="mb-2">
              <div
                onClick={() =>
                  setOpened((prevState) => ({
                    ...prevState,
                    users: !opened?.users,
                  }))
                }
                className="d-flex cursor_pointer justify-content-between align-items-center"
              >
                <h4 className="fs_13 fw_600">USERS</h4>
                {opened?.users ? (
                  <img
                    src={arrowUp}
                    alt="open the list icon"
                    className="mb-1"
                  />
                ) : (
                  <div className="mb-1">
                    <ArrowDownIcon width={28} height={28} />
                  </div>
                )}
              </div>
              {opened?.users && (
                <ul className="list-unstyled ms-3">
                  {sideBarNavList?.users?.map((item) => {
                    return (
                      <li
                        onClick={() => {
                          item?.path !== "" && navigate(`${item?.path}`);
                        }}
                        key={item?.title}
                        className={` cursor_pointer
                          ${
                            item.path === "/user/user-list"
                              ? location.pathname.startsWith("/user/user-list")
                                ? "secondary_color"
                                : "light_grey_color"
                              : location.pathname.includes(item.path)
                              ? "secondary_color"
                              : "light_grey_color"
                          }
                          mb-3 fw_600 fs_12 `}
                      >
                        {item?.title}
                      </li>
                    );
                  })}
                </ul>
              )}
            </div>
            <div className="mb-2">
              <div
                onClick={() =>
                  setOpened((prevState) => ({
                    ...prevState,
                    admin: !opened.admin,
                  }))
                }
                className="d-flex cursor_pointer justify-content-between align-items-center"
              >
                <h4 className="fs_13 fw_600">ADMIN</h4>{" "}
                {opened?.admin ? (
                  <img
                    src={arrowUp}
                    alt="open the list icon"
                    className="mb-1"
                  />
                ) : (
                  <div className="mb-1 ms-2">
                    <ArrowDownIcon width={28} height={28} />
                  </div>
                )}
              </div>
              {opened?.admin && (
                <ul className="list-unstyled ms-3">
                  {sideBarNavList.admin.map((item) => {
                    return (
                      <li
                        onClick={() => {
                          item.path !== "" && navigate(`${item.path}`);
                        }}
                        key={item.title}
                        className={`
            cursor_pointer mb-2 pb-1
                fw_600 fs_12  ${
                  location.pathname.includes(item.path)
                    ? "secondary_color"
                    : "light_grey_color"
                }`}
                      >
                        {item.title}
                      </li>
                    );
                  })}
                </ul>
              )}
            </div>
            <div className="mb-2">
              <div
                onClick={() =>
                  setOpened((prevState) => ({
                    ...prevState,
                    opportunity: !opened.opportunity,
                  }))
                }
                className="d-flex cursor_pointer justify-content-between align-items-center"
              >
                <h4 className="fs_13 fw_600">OPPORTUNITY</h4>
                {opened.opportunity ? (
                  <img
                    src={arrowUp}
                    alt="open the list icon"
                    className="mb-1"
                  />
                ) : (
                  <div className="mb-1 ms-2">
                    <ArrowDownIcon width={28} height={28} />
                  </div>
                )}
              </div>
              {opened.opportunity && (
                <ul className="list-unstyled ms-3">
                  {sideBarNavList.opportunity.map((item) => {
                    return (
                      <li
                        onClick={() => {
                          item.path !== "" && navigate(`${item.path}`);
                        }}
                        key={item.title}
                        className={` cursor_pointer mb-2 pb-1 fw_600 fs_12 ${
                          location.pathname.includes(item.path)
                            ? "secondary_color"
                            : "light_grey_color"
                        }`}
                      >
                        {item.title}
                      </li>
                    );
                  })}
                </ul>
              )}
            </div>
            <div className="mb-2">
              <div
                onClick={() =>
                  setOpened((prevState) => ({
                    ...prevState,
                    staticPages: !opened.staticPages,
                  }))
                }
                className="d-flex cursor_pointer justify-content-between align-items-center"
              >
                <h4 className="fs_13 fw_600">STATIC PAGES & HOME</h4>{" "}
                {opened.staticPages ? (
                  <img
                    src={arrowUp}
                    alt="open the list icon"
                    className="mb-1"
                  />
                ) : (
                  <div className="mb-1 ms-2">
                    <ArrowDownIcon width={28} height={28} />
                  </div>
                )}
              </div>
              {opened.staticPages && (
                <ul className="list-unstyled ms-3">
                  {sideBarNavList.StaticPages.map((item) => {
                    return (
                      <li
                        onClick={() => {
                          item.path !== "" && navigate(`${item.path}`);
                        }}
                        key={item.title}
                        className={`
            cursor_pointer ${
              checkerAccess
                ? "mb-2 pb-1"
                : item.title === "USER REFERRAL" ||
                  item.title === "INVITED MEMBERS"
                ? "mb-0 pb-0"
                : "mb-2 pb-1"
            }
                fw_600 fs_12  ${
                  item.path === "/static-pages/campaign"
                    ? location.pathname.includes(item.path) &&
                      !location.pathname.startsWith(
                        "/static-pages/campaignOppor"
                      )
                      ? "secondary_color"
                      : "light_grey_color"
                    : item.path === "/static-pages/refer"
                    ? location.pathname.includes(item.path) &&
                      !location.pathname.startsWith(
                        "/static-pages/referred-users"
                      )
                      ? "secondary_color"
                      : "light_grey_color"
                    : location.pathname.startsWith(item.path)
                    ? "secondary_color"
                    : "light_grey_color"
                }`}
                      >
                        {checkerAccess && <>{item.title}</>}
                        {!checkerAccess && (
                          <>
                            {item.title === "USER REFERRAL" ||
                            item.title === "INVITED MEMBERS"
                              ? null
                              : item.title}
                          </>
                        )}
                        {/* {item.title} */}
                      </li>
                    );
                  })}
                </ul>
              )}
            </div>
            <div className="mb-2">
              <div
                onClick={() =>
                  setOpened((prevState) => ({
                    ...prevState,
                    blog: !opened.blog,
                  }))
                }
                className="d-flex cursor_pointer justify-content-between align-items-center"
              >
                <h4 className="fs_13 fw_600">BLOG</h4>{" "}
                {opened.blog ? (
                  <img
                    src={arrowUp}
                    alt="open the list icon"
                    className="mb-1"
                  />
                ) : (
                  <div className="mb-1 ms-2">
                    <ArrowDownIcon width={28} height={28} />
                  </div>
                )}
              </div>
              {opened.blog && (
                <ul className="list-unstyled ms-3">
                  {sideBarNavList.blog.map((item) => {
                    return (
                      <li
                        onClick={() => {
                          item.path !== "" && navigate(`${item.path}`);
                        }}
                        key={item.title}
                        className={`
            cursor_pointer mb-2 pb-1
                fw_600 fs_12
                
                ${
                  item.path === "/blog"
                    ? location.pathname.includes(item.path) &&
                      !location.pathname.startsWith("/blog-category")
                      ? "secondary_color"
                      : "light_grey_color"
                    : location.pathname.startsWith(item.path)
                    ? "secondary_color"
                    : "light_grey_color"
                }`}
                      >
                        {item.title}
                      </li>
                    );
                  })}
                </ul>
              )}
            </div>
          </nav>
        </div>
      </div>
    </>
  );
};
const sideBarNavList = {
  users: [
    {
      title: "USERS LIST",
      path: "/user/user-list",
    },
    { title: "RESET PASSWORD EMAIL", path: "/user/reset-password-email" },
    { title: "EMAIL OTP", path: "/user/email-otp" },
    { title: "PHONE OTP", path: "/user/phone-otp" },
  ],

  admin: [
    { title: "ADMIN USERS", path: "/admin-users" },
    { title: "ROLES", path: "/admin-roles" },
  ],
  opportunity: [
    { title: "OPPORTUNITIES", path: "/opportunity/opportunities" },
    { title: "OPPORTUNITY TYPES", path: "/opportunity/opportunity-type" },
    { title: "WISHLIST", path: "/opportunity/wishlist" },
    { title: "NOTIFY ME", path: "/opportunity/notify-me" },
    { title: "LLP", path: "/opportunity/llp" },
    { title: "EXPRESS INTEREST", path: "/opportunity/express-interest" },
    { title: "MEMBER TRADE", path: "/opportunity/member-trade" },
    { title: "MEMBER TRADE BUY IN", path: "/opportunity/memberTrade-buy-in" },
    { title: "MEMBER TRADE SELL OUT", path: "/opportunity/memTradeSell" },
  ],
  StaticPages: [
    { title: "OUR TEAM", path: "/static-pages/our-team" },
    { title: "OUR PARTNERS", path: "/static-pages/our-partners" },
    { title: "OUR ADVISOR", path: "/static-pages/our-advisors" },
    { title: "FAQ CATEGORY", path: "/static-pages/faq-category" },
    { title: "FAQS", path: "/static-pages/faqs" },
    { title: "TESTIMONIALS", path: "/static-pages/testimonials" },
    {
      title: "MEDIA AND RECOGNITION",
      path: "/static-pages/media-recogonition",
    },
    { title: "NEWSLETTER", path: "/static-pages/newsletter" },
    { title: "STATIC SCREEN", path: "/static-pages/static-screen" },
    { title: "HOW IT WORKS", path: "/static-pages/how-It-Works" },
    { title: "CONTACT US", path: "/static-pages/contact-us-query" },
    { title: "HOME PAGE", path: "/static-pages/home" },
    { title: "CAMPAIGN LANDING PAGE 1", path: "/static-pages/campaign" },
    { title: "CAMPAIGN LANDING PAGE 2", path: "/static-pages/campaignOppor" },
    { title: "INSIGHTS", path: "/static-pages/insights" },
    { title: "PARTNER WITH US", path: "/static-pages/partner-with-us" },
    { title: "PARTNER WITH US FORM", path: "/static-pages/partnerWithUsForm" },
    { title: "REFER AND EARN FAQ", path: "/static-pages/refer" },
    { title: "REFERRED USERS", path: "/static-pages/referred-users" },
    { title: "BADGES FAQ", path: "/static-pages/badges" },
    { title: "INVITED MEMBERS", path: "/static-pages/invited" },
    { title: "DASHBOARD FAQ", path: "/static-pages/dashboard" },
    { title: "NOTIFICATION", path: "/static-pages/notification" },
    { title: "USER REFERRAL", path: "/static-pages/userReferral" },
    { title: "SOCIAL CONTRIBUTOR", path: "/static-pages/social" },
    { title: "COMMUNITY BADGES", path: "/static-pages/community-badges" },
  ],
  blog: [
    { title: "BLOG", path: "/blog" },
    { title: "BLOG CATEGORY", path: "/blog-category" },
  ],
};
export default LayoutSibarBar;
