import React, { useState } from "react";
import { useDispatch } from "react-redux";
import ApprovalsBtn from "../../../../components/buttons/approvalsBtn";
import CreateBtn from "../../../../components/buttons/createBtn";
import ExportBtn from "../../../../components/buttons/exportBtn";
import FilterBtn from "../../../../components/buttons/filterBtn";
import VersionHistoryBtn from "../../../../components/buttons/versionHistoryBtn";
import AdminUserFilter from "../../../../components/filter/adminUserFilter";
import MemberTradeFilter from "../../../../components/filter/opportunity-pagesFilter.js/memberTradeApprovalFilter";
import NotifyApprovalFilter from "../../../../components/filter/opportunity-pagesFilter.js/notifyApprovalFilter";
import InnerLayout from "../../../../components/layouts/innerLayout/innerLayout";
import MemberTradeTableContent from "../../../../components/opportunity/memberTrade/tables/memberTradeTableContent";
import NotifyMeTableContent from "../../../../components/opportunity/notifyMe/tables/notifyMeTableContent";
import { access } from "../../../../components/utilits/access";
import { rolesTableExport } from "../../../../redux/actions/adminRoleActions";
const MemberTradeTable = () => {
  const [page, setPage] = useState(0);
  const [showFilter, setShowFilter] = useState(false);
  const [search, setSearch] = useState({ value: "" });
  const dispatch = useDispatch();
  const handleFilterClose = () => {
    setShowFilter(false);
  };
  const handleFilterOpen = () => {
    setShowFilter(true);
  };
  const handleSearch = (value) => {
    setSearch({ value: value });
    setPage(0)
  };
  const handlePage = (type) => {
    if (type === "+") {
      setPage((prev) => prev + 1)
    }                             
    else {
      setPage((prev) => prev - 1)
    }
  }
  console.log(search);
  const buttonList = [
    <ApprovalsBtn link={`/opportunity/member-trade/approvals`} />,
    <VersionHistoryBtn link={`/opportunity/member-trade/version-history`} />,
    <CreateBtn link={`/opportunity/member-trade/create`} />,
    <ExportBtn dispatchAction={rolesTableExport}/>,
    <FilterBtn filterFn={handleFilterOpen} />,
  ];

  return (
    <InnerLayout
      buttons={
        buttonList
      }
    >
      <MemberTradeFilter
        filter={handleSearch}
        showFilter={showFilter}
        closeFilter={handleFilterClose}
      />
      <MemberTradeTableContent page={page} changePage={handlePage} search={search} />
    </InnerLayout>
  );
};

export default MemberTradeTable;
