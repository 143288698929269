import React from "react";

const EditIcon = ({ fill = "#bcc7ce", width = 10, height = 16 }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 13.362 13.362">
      <path id="Icon_material-edit" data-name="Icon material-edit" d="M4.5,15.075v2.783H7.283l8.209-8.209L12.709,6.866ZM17.645,7.5a.739.739,0,0,0,0-1.047L15.908,4.713a.739.739,0,0,0-1.047,0L13.5,6.072l2.783,2.783L17.645,7.5Z" transform="translate(-4.5 -4.496)"
        fill={fill} />
    </svg>

  );
};

export default EditIcon;
