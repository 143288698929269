import React, { useRef, useState } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import InnerLayout from "../../../../components/layouts/innerLayout/innerLayout";
import InnerContainer from "../../../../components/layouts/innerContainer/innerContainer";
import { useNavigate } from "react-router-dom";
import SaveBtn from "../../../../components/buttons/saveBtn";
import InputField from "../../../../components/form/inputFields/inputField";
import successToast from "../../../../components/utilits/successToast";
import errorToast from "../../../../components/utilits/errorToast";
import { useDispatch } from "react-redux";
import LoadingSpinnerTable from "../../../../components/utilits/loadingSpinnerTable";
import SelectFields from "../../../../components/form/selectFields/selectFields";
import { useEffect } from "react";
import { createCommunityBadgesAction } from "../../../../redux/actions/communityBadgesActions";
import { notifyMeCreateOptions } from "../../../../redux/actions/notifyMeActions";

const CommunityBadgesCreate = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [userName, setUserName] = useState([]);
  const dispatch = useDispatch();
  const formRef = useRef();
  const regExp = /^[0-9]+$/;

  useEffect(() => {
    dispatch(notifyMeCreateOptions(onNotifyMeSuccess, onNotifyMeError));
  }, []);
  const userNameOptions = [];
  const onNotifyMeSuccess = (data) => {
    data?.data.AllUsers.map((item, index) => {
      userNameOptions[index] = { value: item.id, label: item.user_name };
    });
    console.log(userNameOptions);
    setUserName(userNameOptions);
  };
  const onNotifyMeError = (data) => {
    console.log("ERROR");
  };

  const initialValues = {
    userId: "",
    points: "",
    type: "",
  };
  const interestEditSchema = Yup.object().shape({
    userId: Yup.string().trim().required("Required"),
    points: Yup.string()
      .matches(regExp, "Enter only numbers")
      .required("Required"),
    type: Yup.string().trim().required("Required"),
  });

  const handleSubmit = () => {
    if (formRef.current) {
      formRef.current.handleSubmit();
    }
  };
  const onSubmit = (values) => {
    dispatch(
      createCommunityBadgesAction(
        {
          user_id: values.userId,
          points: values.points,
          points_type: values.type,
        },
        onSuccess,
        onError
      )
    );
  };

  const onSuccess = (data) => {
    successToast("Refered User Created");
    navigate(`/static-pages/community-badges/edit/${data.data.id}`);
    setLoading(false);
  };
  const onError = (data) => {
    console.log(data);
    errorToast(data.message);
    setLoading(false);
  };
  const buttonList = [
    <SaveBtn submitFn={handleSubmit} loader={loading} />,
    // <SubmitBtn submitFn={submitFnc} saveHide={saveHide} />,
  ];
  return (
    <InnerLayout buttons={buttonList}>
      <div className="user_view_container">
        <InnerContainer>
          {loading ? (
            <LoadingSpinnerTable />
          ) : (
            <div className="row mt-2">
              <div className="col-12 col-sm-12 col-md-9 col-lg-9 col-xl-7">
                <div className="row ps-1 ms-1 ps-lg-4 ms-lg-1">
                  <div className="ps-2  pt-3">
                    <Formik
                      innerRef={formRef}
                      initialValues={initialValues}
                      onSubmit={onSubmit}
                      enableReinitialize={true}
                      validationSchema={interestEditSchema}
                    >
                      <Form className="">
                        <div className="express-form-width">
                          <SelectFields
                            label={"User Name"}
                            placeholder={"select the user name"}
                            name={"userId"}
                            options={userName}
                            id={"userId"}
                          />{" "}
                          <InputField
                            id={"points"}
                            label={"Enter Points"}
                            type={"text"}
                            placeholder={"Points here"}
                            name={"points"}
                          />
                          <SelectFields
                            options={pointsType}
                            label={"Points Type"}
                            placeholder={"Select type"}
                            name={"type"}
                            id={"type"}
                          />
                        </div>
                      </Form>
                    </Formik>
                  </div>
                </div>
              </div>
            </div>
          )}
        </InnerContainer>
      </div>
    </InnerLayout>
  );
};

export default CommunityBadgesCreate;

export const pointsType = [
  { value: "testimonial", label: "Testimonial" },
  { value: "social_post", label: "Social post" },
  { value: "social_contributor", label: "Social contributor" },
  { value: "financial_guru", label: "Financial guru" },
  { value: "community_buider", label: "Community Buider" },
  { value: "market_champion", label: "Market champion" },
];
