import React from "react";
import "./filtersidebar.css";
import InputField from "../form/inputFields/inputField";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import ArrowRightIcon from "../../assets/icons/arrowrightIcon";
import ErrorMsg from "../form/errorMsg";
const EmailOtpFilter = ({ showFilter = false, closeFilter, filter }) => {
    const alphaLettersRegex = /^[A-Za-z]+$/;
    const initialValues = {
        userId: "",
        email: "",
        validity: "",
        isUsed: "",
        atleastOneIsRequired: ""
    };
    const filterSchema = Yup.object().shape({
        userId: Yup.string(),
        email: Yup.string().trim().email("Email must be a valid email"),
        validity: Yup.string(),
        isUsed: Yup.string(),
        atleastOneIsRequired: Yup.string().trim().when(["userId", "email", "validity", "isUsed"], {
            is: (userId, email, validity, isUsed) => !userId && !email && !validity && !isUsed,
            then: Yup.string().trim().required("At least one field is Required!"),
            otherwise: Yup.string()
        })
    });
    const onSubmit = (values, { resetForm }) => {
        filter(values)
    };
    return (
        showFilter && (
            <div
                className={` filtersidebar_main__container p-4 bg-white fs_Montserrat`}
            >
                <div className="d-flex align-items-center justify-content-between mb-3">
                    <h5 className="secondary_color fs_20 fw_400 mb-1">Filters</h5>
                    <button
                        onClick={closeFilter}
                        className="d-flex align-items-center border-0  bg-white"
                    >
                        <span className="fs_14 dark_grey_color">Back</span>
                        <ArrowRightIcon width={20} height={20} fill="#666666" />
                    </button>
                </div>
                <Formik
                    initialValues={initialValues}
                    onSubmit={onSubmit}
                    validationSchema={filterSchema}
                >
                    <Form className="filtersidebar_form__container h-100 position-relative d-flex flex-column">
                        <div>
                            <InputField
                                placeholder="Enter User ID"
                                label="User Id"
                                name="userId"
                                labelClassName="mb-1 ms-2 dark_grey_color fn_Montserrat fw_500 fs_14"
                                containerClassName=" d-flex flex-column "
                                errMsgContainerClassName="mt-1 justify-content-end"
                            />

                            <InputField
                                name="email"
                                labelClassName="mb-1 ms-2 dark_grey_color fn_Montserrat fw_500 fs_14"
                                containerClassName=" d-flex flex-column "
                                errMsgContainerClassName="mt-1 justify-content-end"
                            />

                            {/* <InputField
                                placeholder="Enter Validity"
                                label="Validity"
                                name="validity"
                                labelClassName="mb-1 ms-2 dark_grey_color fn_Montserrat fw_500 fs_14"
                                containerClassName=" d-flex flex-column "
                                errMsgContainerClassName="mt-1 justify-content-end"
                            />
                            <InputField
                                placeholder="Enter Value"
                                label="Is Used"
                                name="isUsed"
                                labelClassName="mb-1 ms-2 dark_grey_color fn_Montserrat fw_500 fs_14"
                                containerClassName=" d-flex flex-column "
                                errMsgContainerClassName="mt-1 justify-content-end"
                            /> */}
                            <ErrorMsg
                                containerClassName={"mt - 1 justify-content-end justify-content-lg-start"}
                                name="atleastOneIsRequired" />
                        </div>
                        <div className=" mt-3  mt-lg-auto  mb-5 d-flex gap-4 fs_Nunito py-2 fs_12">
                            <button
                                type="submit"
                                className={`secondary_bg  px-4 py-2 border-0 rounded-2 text-white`}
                            >
                                Apply Changes
                            </button>
                            <button
                                type="reset"
                                onClick={() => filter("")}
                                className="bg-white fw_500 p-0 border-0 dark_grey_color"
                            >
                                Reset Filters
                            </button>
                        </div>
                    </Form>
                </Formik>
            </div>
        )
    );
};

export default EmailOtpFilter;
