import React from 'react'
import usePermissionChecker from '../utilits/usePermissionChecker';
import { AdminButton } from './versionHistoryBtn'

const ReverifyBtn = ({ reverify }) => {
    const access = usePermissionChecker("checker");
    return (
        access &&
        <AdminButton onClick={() => reverify()} color="#FAD100" padding="5px 15px">RE-VERIFY</AdminButton>
    )
}

export default ReverifyBtn