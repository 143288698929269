import React, { useState } from 'react'
import ExportBtn from '../../../components/buttons/exportBtn';
import FilterBtn from '../../../components/buttons/filterBtn';
import VersionTablesFilter from '../../../components/filter/versionTableFilter';
import InnerLayout from '../../../components/layouts/innerLayout/innerLayout';
import UserVersionTableContent from '../../../components/user/tables/userVersionTableContent';
import { userVersionExport } from '../../../redux/actions/userAction';
const UserVersionTable = () => {
  const [showFilter, setShowFilter] = useState(false);
  const [page, setPage] = useState(0);
  const [search, setSearch] = useState("");
  const handleFilterClose = () => {
    setShowFilter(false);
  };
  const handleFilterOpen = () => {
    setShowFilter(true);
  };
  const handleSearch = (value) => { setSearch(value); setPage(0) };
  const handlePage = (type) => {
    if (type === "+") {
      setPage((prev) => prev + 1)
    }
    else {
      setPage((prev) => prev - 1)
    }
  }
  const buttonList = [
    <ExportBtn dispatchAction={userVersionExport} />,
    <FilterBtn filterFn={handleFilterOpen} />,
  ];
  return (
    <InnerLayout buttons={buttonList}>
      <VersionTablesFilter
        title='User Id'
        placeholder='Enter User Id'
        filter={handleSearch}
        showFilter={showFilter}
        closeFilter={handleFilterClose}
      />
      <UserVersionTableContent page={page} changePage={handlePage} search={search} /></InnerLayout>
  )
}

export default UserVersionTable