import React, { useEffect, useState } from "react";
import ApprovalsBtn from "../../../../components/buttons/approvalsBtn";
import CreateBtn from "../../../../components/buttons/createBtn";
import ExportBtn from "../../../../components/buttons/exportBtn";
import FilterBtn from "../../../../components/buttons/filterBtn";
import VersionHistoryBtn from "../../../../components/buttons/versionHistoryBtn";
import AdminUserFilter from "../../../../components/filter/adminUserFilter";
import HowItWorksTableContent from "../../../../components/howItWorks/howItWorks/tables/howItWorksTableContent";
import InnerLayout from "../../../../components/layouts/innerLayout/innerLayout";
import FaqTableContent from "../../../../components/static-pages/faqs/tables/faqTableContent";
import { howItWorksMainExportAction } from "../../../../redux/actions/howItWorksAction";

const HowItWorksTable = () => {
  const [showFilter, setShowFilter] = useState(false);
  const [search, setSearch] = useState();
  const [page, setPage] = useState(0);
  const handleFilterClose = () => {
    setShowFilter(false);
  };
  const handleFilterOpen = () => {
    setShowFilter(true);
  };
  const handleSearch = (value) => {
    setSearch({ value: value });
    setPage(0)
  };
  const handlePage = (type) => {
    if (type === "+") {
      setPage((prev) => prev + 1)
    }
    else {
      setPage((prev) => prev - 1)
    }
  }
  const buttonList = [
    <ApprovalsBtn link='/static-pages/how-It-Works/approvals' />,
    <VersionHistoryBtn link='/static-pages/how-It-Works/version-history' />,
    <CreateBtn link='/static-pages/how-It-Works/create' />,
    <ExportBtn dispatchAction={howItWorksMainExportAction} />,
    <FilterBtn filterFn={handleFilterOpen} />,
  ];
  return (
    <InnerLayout buttons={buttonList}>
      <AdminUserFilter
      title="How It Works Id"
      placeholder="Enter How It Works Id"
        filter={handleSearch}
        showFilter={showFilter}
        closeFilter={handleFilterClose}
      />
      <HowItWorksTableContent page={page} changePage={handlePage} search={search} />
    </InnerLayout>
  );
};

export default HowItWorksTable;
