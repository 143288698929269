import { api } from "./api";

export const opportunitiesCreateApi = (params) => {
  return api.post(
    `/api/opportunities/create-opportunities`,
    params,
    {
      headers: {
        "x-access-token": localStorage.getItem("accessToken"),
        "Content-Type": "application/json",
      },
    }
  );
};

export const fetchOpportunitiesTypeApi = (params) => {
  return api.post(
    `/api/opportunities/view-opportunity_names`,
    JSON.stringify(params),
    {
      headers: {
        "x-access-token": localStorage.getItem("accessToken"),
        "Content-Type": "application/json",
      },
    }
  );
};

export const fetchOpportunityDetailsApi = (params) => {
  return api.post(
    `/api/opportunities/view-opportunity/${params.opportunityId}`,
    JSON.stringify(params),
    {
      headers: {
        "x-access-token": localStorage.getItem("accessToken"),
        "Content-Type": "application/json",
      },
    }
  );
};

export const publishOpportunityApi = (params) => {
  return api.post(
    `/api/opportunities/publish-opportunity`,
    params,
    {
      headers: {
        "x-access-token": localStorage.getItem("accessToken"),
        "Content-Type": "application/json",
      },
    }
  );
};
export const approveOpportunityApi = (params) => {
  return api.post(
    `/api/opportunities/approve-opportunities-checker`,
    params,
    {
      headers: {
        "x-access-token": localStorage.getItem("accessToken"),
        "Content-Type": "application/json",
      },
    }
  );
};
export const rejectOpportunityApi = (id, params) => {
  return api.post(
    `/api/opportunities/reject/${id}`,
    JSON.stringify(params),
    {
      headers: {
        "x-access-token": localStorage.getItem("accessToken"),
        "Content-Type": "application/json",
      },
    }
  );
};

export const deleteOpportunityApi = (id, params) => {
  return api.post(
    `api/opportunities/delete-opportunity/${id}`,
    params,
    {
      headers: {
        "x-access-token": localStorage.getItem("accessToken"),
        "Content-Type": "application/json",
      },
    }
  );
};

export const editOpportunityApi = (id, params) => {
  return api.post(
    `/api/opportunities/edit-opportunities/${id}`,
    params,
    {
      headers: {
        "x-access-token": localStorage.getItem("accessToken"),
        "Content-Type": "application/json",
      },
    }
  );
};

export const fetchOpportunitiesApi = (page, params) => {
  return api.post(
    `/api/opportunities/view-opportunities-approved?page_no=${page}`,
    JSON.stringify(params),
    {
      headers: {
        "x-access-token": localStorage.getItem("accessToken"),
        "Content-Type": "application/json",
      },
    }
  );
};

export const fetchOpportunitiesApprovalApi = (page, params) => {
  return api.post(
    `/api/opportunities/view-opportunities-checkers?page_no=${page}`,
    JSON.stringify(params),
    {
      headers: {
        "x-access-token": localStorage.getItem("accessToken"),
        "Content-Type": "application/json",
      },
    }
  );
};

//opportunities version
export const opportunitiesVersionTableApi = (page, params) => {
  return api.post(
    `/api/opportunities/view-version-history?page_no=${page}`,
    JSON.stringify(params),
    {
      headers: {
        "x-access-token": localStorage.getItem("accessToken"),
        "Content-Type": "application/json",
      },
    }
  );
};
export const opportunitiesAnalyticsApi = (id, params) => {
  return api.post(
    `/api/opportunities/read-opportunities-analytics/${id}`,
    JSON.stringify(params),
    {
      headers: {
        "x-access-token": localStorage.getItem("accessToken"),
        "Content-Type": "application/json",
      },
    }
  );
};
export const opportunitiesVersionViewApi = (id, params) => {
  return api.post(
    `/api/opportunities/view-version-historys/${id}`,
    JSON.stringify(params),
    {
      headers: {
        "x-access-token": localStorage.getItem("accessToken"),
        "Content-Type": "application/json",
      },
    }
  );
};
export const opportunitiesApprovalsViewApi = (id, params) => {
  return api.post(
    `/api/opportunities/view-one-opportunities-checkers/${id}`,
    JSON.stringify(params),
    {
      headers: {
        "x-access-token": localStorage.getItem("accessToken"),
        "Content-Type": "application/json",
      },
    }
  );
};

export const opportunitiesNameListApi = (params) => {
  return api.post(
    `/api/opportunities/read-opportunities-title-id`,
    JSON.stringify(params),
    {
      headers: {
        "x-access-token": localStorage.getItem("accessToken"),
        "Content-Type": "application/json",
      },
    }
  );
};



export const opportunitiesTableExportApi = (params) => {
  return api.post(
    `/api/opportunities/export-main-opportunities`,
    JSON.stringify(params),
    {
      headers: {
        "x-access-token": localStorage.getItem("accessToken"),
        "Content-Type": "application/json",
      },
    }
  );
};
export const opportunitiesApprovalsExportApi = (params) => {
  return api.post(
    `/api/opportunities/export-approval-opportunities`,
    JSON.stringify(params),
    {
      headers: {
        "x-access-token": localStorage.getItem("accessToken"),
        "Content-Type": "application/json",
      },
    }
  );
};
export const opportunitiesVersionExportApi = (params) => {
  return api.post(
    `/api/opportunities/export-verrsion-opportunities`,
    JSON.stringify(params),
    {
      headers: {
        "x-access-token": localStorage.getItem("accessToken"),
        "Content-Type": "application/json",
      },
    }
  );
};




export const opportunitiesSubmitApi = (id, params) => {
  return api.post(
    `/api/opportunities/on-submit/${id}`, JSON.stringify(params),
    {
      headers: {
        "x-access-token": localStorage.getItem("accessToken"),
        "Content-Type": "application/json",
      },
    }
  );
};