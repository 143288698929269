import { authHeader } from "../config";
import { api } from "./api";

export const addMemTradeSellApi = (params) => {
    return api.post("/api/admin/admin_member_trade_sell_out/create", JSON.stringify(params), {
        headers: {
            "x-access-token": localStorage.getItem("accessToken"),
            "Content-Type": "application/json",
        },
    });
};
export const createMemTradeSellApi = (params) => {
    return api.post(`/api/admin/admin_member_trade_sell_out/create`, JSON.stringify(params), {
        headers: {
            "x-access-token": localStorage.getItem("accessToken"),
            "Content-Type": "application/json",
        },
    });
};
export const editMemTradeSellApi = (id, params) => {
    return api.post(`/api/admin/admin_member_trade_sell_out/edit/${id}`, JSON.stringify(params), {
        headers: {
            "x-access-token": localStorage.getItem("accessToken"),
            "Content-Type": "application/json",
        },
    });
};
export const deleteMemTradeSellApi = (id, params) => {
    return api.post(`/api/admin/admin_member_trade_sell_out/delete/${id}`, JSON.stringify(params), {
        headers: {
            "x-access-token": localStorage.getItem("accessToken"),
            "Content-Type": "application/json",
        },
    });
};

export const viewAllMemTradeSellApi = (pageNo, params) => {
    return api.post(`/api/admin/admin_member_trade_sell_out/view-approvals?page_no=${pageNo}`, JSON.stringify(params), {
        headers: {
            "x-access-token": localStorage.getItem("accessToken"),
            "Content-Type": "application/json",
        },
    });
};




export const viewMemTradeSellApi = (id = "", params) => {
    return api.post(`/api/admin/admin_member_trade_sell_out/view-approval/${id}`, JSON.stringify(params), {
        headers: {
            "x-access-token": localStorage.getItem("accessToken"),
            "Content-Type": "application/json",
        },
    });
};

export const approveMemTradeSellApi = (params) => {
    return api.post(`/api/admin/admin_member_trade_sell_out/approve`, JSON.stringify(params), {
        headers: {
            "x-access-token": localStorage.getItem("accessToken"),
            "Content-Type": "application/json",
        },
    });
};
export const approvalsListMemTradeSellApi = (pageNo, params) => {
    return api.post(`/api/admin/admin_member_trade_sell_out/view-checker?page_no=${pageNo}`, JSON.stringify(params), {
        headers: {
            "x-access-token": localStorage.getItem("accessToken"),
            "Content-Type": "application/json",
        },
    });
};

export const approvalsMemTradeSellByIdApi = (id = "", params) => {
    return api.post(`/api/admin/admin_member_trade_sell_out/view-checker/${id}`, JSON.stringify(params), {
        headers: {
            "x-access-token": localStorage.getItem("accessToken"),
            "Content-Type": "application/json",
        },
    });
};

export const versionHistoryListMemTradeSellApi = (pageNo = 0, params) => {
    return api.post(`/api/admin/admin_member_trade_sell_out/view-version?page_no=${pageNo}`, JSON.stringify(params), {
        headers: {
            "x-access-token": localStorage.getItem("accessToken"),
            "Content-Type": "application/json",
        },
    });

};

//Find all wishlist for drop down

export const viewFindAllMemTradeSellApi = (params) => {
    return api.post(`/api/llp/list-llp-users-id`, JSON.stringify(params), {
        headers: {
            "x-access-token": localStorage.getItem("accessToken"),
            "Content-Type": "application/json",
        },
    });
};
// export const viewVersionHistoryMemTradeSellApi = (params) => {
//     return api.post(`/api/opportunity/view-version-history`, JSON.stringify(params), {
//         headers: {
//             "x-access-token": localStorage.getItem("accessToken"),
//             "Content-Type": "application/json",
//         },
//     });
// };

export const versionHistoryByIdMemTradeSellApi = (id = "", params) => {
    return api.post(`/api/admin/admin_member_trade_sell_out/view-version/${id}`, JSON.stringify(params), {
        headers: {
            "x-access-token": localStorage.getItem("accessToken"),
            "Content-Type": "application/json",
        },
    });

};

export const rejectMemTradeSellApi = (id, params) => {
    return api.post(`/api/admin/admin_member_trade_sell_out/reject/${id}`, JSON.stringify(params), {
        headers: {
            "x-access-token": localStorage.getItem("accessToken"),
            "Content-Type": "application/json",
        },
    });
};


export const viewMemTradeSellExportApi = (params) => {
    return api.post(`/api/admin/admin_member_trade_sell_out/export-main`, JSON.stringify(params), {
        headers: {
            "x-access-token": localStorage.getItem("accessToken"),
            "Content-Type": "application/json",
        },
    });
};



export const memTradeSellTableExportApi = (params) => {
    return api.post(
        `/api/admin/admin_member_trade_sell_out/export-main`,
        JSON.stringify(params),
        {
            headers: {
                "x-access-token": localStorage.getItem("accessToken"),
                "Content-Type": "application/json",
            },
        }
    );
};
export const memTradeSellApprovalsExportApi = (params) => {
    return api.post(
        `/api/admin/admin_member_trade_sell_out/export-approval`,
        JSON.stringify(params),
        {
            headers: {
                "x-access-token": localStorage.getItem("accessToken"),
                "Content-Type": "application/json",
            },
        }
    );
};
export const memTradeSellVersionExportApi = (params) => {
    return api.post(
        `/api/admin/admin_member_trade_sell_out/export-version`,
        JSON.stringify(params),
        {
            headers: {
                "x-access-token": localStorage.getItem("accessToken"),
                "Content-Type": "application/json",
            },
        }
    );
};





export const memTradeSellSubmitApi = (id, params) => {
    return api.post(
        `/api/admin/admin_member_trade_sell_out/on-submit/${id}`, JSON.stringify(params),
        {
            headers: {
                "x-access-token": localStorage.getItem("accessToken"),
                "Content-Type": "application/json",
            },
        }
    );
};