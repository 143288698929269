import React, { useState } from "react";
import ExportBtn from '../../../../components/buttons/exportBtn'
import FilterBtn from '../../../../components/buttons/filterBtn'

import VersionTablesFilter from "../../../../components/filter/versionTableFilter";
import InnerLayout from '../../../../components/layouts/innerLayout/innerLayout'
import OpportunitiesVersionHistoryTableContent from "../../../../components/opportunity/opportunties/tables/opportunitiesVersionHistoryTableContent";
import { opportunitiesVersionExport } from "../../../../redux/actions/opportunitiesActions";

const OpportunitiesVersionHistoryTable = () => {
  const [page, setPage] = useState(0);
  const [showFilter, setShowFilter] = useState(false);
  const [search, setSearch] = useState();
  const handleFilterClose = () => {
    setShowFilter(false);
  };
  const handleFilterOpen = () => {
    setShowFilter(true);
  };
  const handleSearch = (value) => { setSearch(value); setPage(0) };
  const handlePage = (type) => {
    if (type === "+") {
      setPage((prev) => prev + 1)
    }
    else {
      setPage((prev) => prev - 1)
    }
  }

  const buttonList = [
    <ExportBtn dispatchAction={opportunitiesVersionExport}/>,
    <FilterBtn filterFn={handleFilterOpen} />
  ]
  return (
    <InnerLayout buttons={buttonList}>
      <VersionTablesFilter
        title='Opportunities Id'
        placeholder='Opportunities Id'
        filter={handleSearch}
        showFilter={showFilter}
        closeFilter={handleFilterClose}
      />
      <OpportunitiesVersionHistoryTableContent page={page} changePage={handlePage} search={search} />

    </InnerLayout>
  )
}
export default OpportunitiesVersionHistoryTable