import React, { useState } from 'react'
import ExportBtn from '../../../../components/buttons/exportBtn'
import FilterBtn from '../../../../components/buttons/filterBtn'
import AdminUserFilter from '../../../../components/filter/adminUserFilter'
import VersionTablesFilter from '../../../../components/filter/versionTableFilter'
import InnerLayout from '../../../../components/layouts/innerLayout/innerLayout'
import NewsLetterVersionHistoryTableContent from '../../../../components/static-pages/newsletter/tables/newLetterVersionHistoryTableContent'
import { newsLetterVersionExportAction } from '../../../../redux/actions/newsletterActions'


const NewsLetterVersionHistoryTable = () => {
    const [page, setPage] = useState(0);
    const [showFilter, setShowFilter] = useState(false);
    const [search, setSearch] = useState({ value: "" });
    const handleFilterClose = () => {
        setShowFilter(false);
    };
    const handleFilterOpen = () => {
        setShowFilter(true);
    };
    const handleSearch = (value) => {
        setSearch({ value: value });
        setPage(0)
    };
    const handlePage = (type) => {
        if (type === "+") {
            setPage((prev) => prev + 1)
        }
        else {
            setPage((prev) => prev - 1)
        }
    }
    const buttonList = [

        <ExportBtn dispatchAction={newsLetterVersionExportAction} />,
        <FilterBtn filterFn={handleFilterOpen} />,
    ];
    return (
        <InnerLayout buttons={buttonList}>
            <AdminUserFilter
                showFilter={showFilter}
                closeFilter={handleFilterClose}
            />
            <VersionTablesFilter
                title='News Letter Id'
                placeholder='Enter News Letter Id'
                filter={handleSearch}
                showFilter={showFilter}
                closeFilter={handleFilterClose}
            />
            <NewsLetterVersionHistoryTableContent page={page} changePage={handlePage} search={search} />
        </InnerLayout>
    )
}

export default NewsLetterVersionHistoryTable