import { call, takeLatest } from "redux-saga/effects";
import {
  ACTIVATE_PARTNER_WITH_US_OPTIONS,
  PARTNER_WITH_US_APPROVALS_EXPORT,
  PARTNER_WITH_US_APPROVALS_LIST,
  PARTNER_WITH_US_APPROVALS_VIEW,
  PARTNER_WITH_US_APPROVE,
  PARTNER_WITH_US_CREATE,
  PARTNER_WITH_US_DELETE,
  PARTNER_WITH_US_EDIT,
  PARTNER_WITH_US_LIST,
  PARTNER_WITH_US_MAIN_EXPORT,
  PARTNER_WITH_US_READ,
  PARTNER_WITH_US_REJECT,
  PARTNER_WITH_US_SUBMIT,
  PARTNER_WITH_US_VERSION_EXPORT,
  PARTNER_WITH_US_VERSION_LIST,
  PARTNER_WITH_US_VERSION_VIEW,
} from "../actions/types";
import { activatepartnerWithUsApi, addpartnerWithUsApi, approvalspartnerWithUsListpApi, approvepartnerWithUsApi, deletepartnerWithUsApi, editpartnerWithUsApi, partnerWithUsApprovalsExportApi, partnerWithUsMainExportApi, partnerWithUsSubmitApi, partnerWithUsVersionExportApi, rejectpartnerWithUsApi, versionpartnerWithUsListpApi, viewApprovalpartnerWithUsApi, viewpartnerWithUsApi, viewpartnerWithUsListApi, viewVersionpartnerWithUsApi } from "../apis/partnerWithUsApi";

export function* addPartnerWithUsWorker(action) {
  try {
    const res = yield call(addpartnerWithUsApi, action.data);
    console.log(res);
    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res.data);
      console.log(res.data);
    } else if (res.status === 200 && res.data.status === "fail") {
      console.log(res.data);
      yield action.onError(res);
    } else {
      yield action.onError("Something Went Wrong");
    }
  } catch (err) {
    console.log(err);
    yield action.onError("Something Went Wrong");
  }
}

export function* viewPartnerWithUsWorker(action) {
  try {
    const res = yield call(viewpartnerWithUsApi, action.id);
    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res.data);
      console.log(res.data);
    } else if (res.status === 200 && res.data.status === "fail") {
      console.log(res.data);
      yield action.onError(res);
    } else {
      yield action.onError("Something Went Wrong!");
      console.log(res.data);
    }
  } catch (err) {
    console.log(err);
    yield action.onError("Something Went Wrong!!");
  }
}
export function* editPartnerWithUsWorker(action) {
  try {
    const res = yield call(editpartnerWithUsApi, action.id, action.data);
    console.log(res);
    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res.data);
      console.log(res.data);
    } else if (res.status === 200 && res.data.status === "fail") {
      console.log(res);
      yield action.onError(res);
    } else {
      yield action.onError("Something Went Wrong");
    }
  } catch (err) {
    console.log(err);
    yield action.onError("Something Went Wrong");
  }
}
export function* deletePartnerWithUsWorker(action) {
  try {
    const res = yield call(deletepartnerWithUsApi, action.id);
    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res.data);
      console.log(res.data);
    } else if (res.status === 200 && res.data.status === "fail") {
      console.log(res.data);
      yield action.onError(res);
    } else {
      yield action.onError("Something Went Wrong");
    }
  } catch (err) {
    console.log(err);
    yield action.onError("Something Went Wrong");
  }
}
export function* viewPartnerWithUsListWorker(action) {
  try {
    const res = yield call(viewpartnerWithUsListApi, action.page, action.search);
    console.log(res);
    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res.data);
      console.log(res.data);
    } else if (res.status === 200 && res.data.status === "fail") {
      console.log(res);
      yield action.onError(res.data);
    } else {
      yield action.onError("Something Went Wrong");
    }
  } catch (err) {
    console.log(err);
    yield action.onError("Something Went Wrong");
  }
}

export function* approvePartnerWithUsWorker(action) {
  try {
    const res = yield call(approvepartnerWithUsApi, { partner_id: action.id });
    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res.data);
      console.log(res.data);
    } else if (res.status === 200 && res.data.status === "fail") {
      console.log(res);
      yield action.onError(res.data);
    } else {
      yield action.onError("Something Went Wrong!");
      console.log(res.data);
    }
  } catch (err) {
    console.log(err);
    yield action.onError("Something Went Wrong!!");
  }
}

export function* rejectPartnerWithUsWorker(action) {
  try {
    const res = yield call(rejectpartnerWithUsApi, action.id);
    console.log(res, "rekect PartnerWithUs");
    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res.data);
      console.log(res.data);
    } else if (res.status === 200 && res.data.status === "fail") {
      console.log(res);
      yield action.onError(res.data);
    } else {
      yield action.onError("Something Went Wrong!");
      console.log(res.data);
    }
  } catch (err) {
    console.log(err);
    yield action.onError("Something Went Wrong!!");
  }
}
export function* approvalsPartnerWithUsListWorker(action) {
  try {
    const res = yield call(
      approvalspartnerWithUsListpApi,
      action.page,
      action.search
    );
    // console.log(res, "AddAdminRole");
    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res.data);
      console.log(res.data);
    } else if (res.status === 200 && res.data.status === "fail") {
      console.log(res);
      yield action.onError(res);
    } else {
      yield action.onError("Something Went Wrong!");
      console.log(res.data);
    }
  } catch (err) {
    console.log(err);
    yield action.onError("Something Went Wrong!!");
  }
}
export function* viewApprovalPartnerWithUsWorker(action) {
  try {
    const res = yield call(viewApprovalpartnerWithUsApi, action.id);
    console.log(res, "PartnerWithUs approvals");
    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res.data);
      console.log(res.data);
    } else if (res.status === 200 && res.data.status === "fail") {
      console.log(res);
      yield action.onError(res);
    } else {
      yield action.onError("Something Went Wrong!");
      console.log(res.data);
    }
  } catch (err) {
    console.log(err);
    yield action.onError("Something Went Wrong!!");
  }
}
export function* versionListPartnerWithUsWorker(action) {
  try {
    const res = yield call(versionpartnerWithUsListpApi, action.page, action.search);
    // console.log(res, "AddAdminRole");
    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res.data);
      console.log(res.data);
    } else if (res.status === 200 && res.data.status === "fail") {
      console.log(res);
      yield action.onError(res);
    } else {
      yield action.onError("Something Went Wrong!");
      console.log(res.data);
    }
  } catch (err) {
    console.log(err);
    yield action.onError("Something Went Wrong!!");
  }
}
export function* activatePartnerWithUsWorker(action) {
  try {
    const res = yield call(activatepartnerWithUsApi, action.id);
    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res);
      console.log(res.data);
    } else if (res.status === 200 && res.data.status === "fail") {
      console.log(res);
      yield action.onError(res);
    } else {
      yield action.onError("Something Went Wrong!");
      console.log(res);
    }
  } catch (err) {
    console.log(err);
    yield action.onError("Something Went Wrong!!");
  }
}
export function* versionViewPartnerWithUsWorker(action) {
  try {
    const res = yield call(viewVersionpartnerWithUsApi, action.id);
    // console.log('====================================');
    // console.log(res);
    // console.log('====================================');
    if (res.status === 200 && res.data?.status === "success") {
      yield action.onSuccess(res);
      // console.log(res.data);
    } else if (res.status === 200 && res.data?.status === "fail") {
      // console.log(res);
      yield action.onError(res);
    } else {
      yield action.onError("Something Went Wrong!");
      console.log(res);
    }
  } catch (err) {
    console.log(err);
    yield action.onError("Something Went Wrong!!");
  }
}

export function* partnerWithUsMainExportWorker(action) {
  try {
    const res = yield call(partnerWithUsMainExportApi);
    console.log(res);
    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res.data);
    }
  } catch (error) { }
}

export function* partnerWithUsApprovalsExportWorker(action) {
  try {
    const res = yield call(partnerWithUsApprovalsExportApi);
    console.log(res);
    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res.data);
    }
  } catch (error) { }
}

export function* partnerWithUsVersionExportWorker(action) {
  try {
    const res = yield call(partnerWithUsVersionExportApi);
    console.log(res);
    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res.data);
    }
  } catch (error) { }
}

export function* partnerWithUsSubmitWorker(action) {
  try {
    const res = yield call(partnerWithUsSubmitApi, action.id);
    console.log(res);
    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res.data);
    }
  } catch (error) { }
}

/**********************************/
/************WATHCHERS**************/
/**********************************/

export function* watchAddPartnerWithUsWorker() {
  yield takeLatest(PARTNER_WITH_US_CREATE, addPartnerWithUsWorker);
}
export function* watchApprovalsPartnerWithUsListWorker() {
  yield takeLatest(PARTNER_WITH_US_APPROVALS_LIST, approvalsPartnerWithUsListWorker);
}
export function* watchViewApprovalPartnerWithUsWorker() {
  yield takeLatest(PARTNER_WITH_US_APPROVALS_VIEW, viewApprovalPartnerWithUsWorker);
}
export function* watchViewPartnerWithUsWorker() {
  yield takeLatest(PARTNER_WITH_US_READ, viewPartnerWithUsWorker);
}
export function* watchViewPartnerWithUsListWorker() {
  yield takeLatest(PARTNER_WITH_US_LIST, viewPartnerWithUsListWorker);
}
export function* watchEditPartnerWithUsWorker() {
  yield takeLatest(PARTNER_WITH_US_EDIT, editPartnerWithUsWorker);
}
export function* watchApprovePartnerWithUsWorker() {
  yield takeLatest(PARTNER_WITH_US_APPROVE, approvePartnerWithUsWorker);
}
export function* watchRejectPartnerWithUsWorker() {
  yield takeLatest(PARTNER_WITH_US_REJECT, rejectPartnerWithUsWorker);
}
export function* watchDeletePartnerWithUsWorker() {
  yield takeLatest(PARTNER_WITH_US_DELETE, deletePartnerWithUsWorker);
}
export function* watchVersionListPartnerWithUsWorker() {
  yield takeLatest(PARTNER_WITH_US_VERSION_LIST, versionListPartnerWithUsWorker);
}
export function* watchVersionViewPartnerWithUsWorker() {
  yield takeLatest(PARTNER_WITH_US_VERSION_VIEW, versionViewPartnerWithUsWorker);
}
export function* WatchActivatePartnerWithUsWorker() {
  yield takeLatest(ACTIVATE_PARTNER_WITH_US_OPTIONS, activatePartnerWithUsWorker);
}
export function* watchPartnerWithUsMainExportWorker() {
  yield takeLatest(PARTNER_WITH_US_MAIN_EXPORT, partnerWithUsMainExportWorker);
}
export function* watchPartnerWithUsApprovalsExportWorker() {
  yield takeLatest(PARTNER_WITH_US_APPROVALS_EXPORT, partnerWithUsApprovalsExportWorker);
}
export function* watchPartnerWithUsVersionExportWorker() {
  yield takeLatest(PARTNER_WITH_US_VERSION_EXPORT, partnerWithUsVersionExportWorker);
}
export function* watchPartnerWithUsSubmitWorker() {
  yield takeLatest(PARTNER_WITH_US_SUBMIT, partnerWithUsSubmitWorker);
}
