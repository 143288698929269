import React, { useEffect, useState } from "react";
import errorToast from "../../../../components/utilits/errorToast";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import InnerLayout from '../../../../components/layouts/innerLayout/innerLayout'
import InnerContainer from '../../../../components/layouts/innerContainer/innerContainer'
import VersionHistoryBtn from '../../../../components/buttons/versionHistoryBtn';
import ApprovalsBtn from '../../../../components/buttons/approvalsBtn';
import RejectBtn from '../../../../components/buttons/rejectBtn';
import ApproveBtn from '../../../../components/buttons/approveBtn';
import successToast from "../../../../components/utilits/successToast";
import { approveExpress, expressApprovalById, rejectExpress } from "../../../../redux/actions/expressInterestAction";
import { ExpressApprovalByIdApi } from "../../../../redux/apis/expessApi";
import moment from "moment";
import LoadingSpinnerTable from "../../../../components/utilits/loadingSpinnerTable";
import LoadAndError from "../../../../components/utilits/LoadAndError";

const ExpressInterestApprovalsDetails = () => {

  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false)
  const [notifyMeApprovalViewData, SetNotifyMeApprovalView] = useState({
    id: "",
    name: "",
    createdAt: "",
    updatedAt: "",
  });

  // View version History Data
  useEffect(() => {
    setLoading(true);
    dispatch(expressApprovalById(params.id, onSuccess, onError));
    console.log(params.id)

  }, [params.id]);
  const onSuccess = (data) => {
    SetNotifyMeApprovalView(data?.data);
    console.log(notifyMeApprovalViewData, "kfjfdkhf");
    console.log(data.data);
    setLoading(false);
    setError(false)
  };
  const onError = (data) => {
    errorToast(data.message);
    console.log(notifyMeApprovalViewData, "error");
    setLoading(false);
    setError(true)
  };

  // Approve Notify Me

  const [updated, setUpdated] = useState(false);

  const approveNotifyMeFn = () => {
    dispatch(
      approveExpress(
        notifyMeApprovalViewData?.id,
        onApproveSuccess,
        onApproveError
      )
    );
  };
  console.log(notifyMeApprovalViewData.id);

  const onApproveSuccess = (data) => {
    navigate("/opportunity/express-interest/approvals")
    setUpdated(true);
    successToast(data.message);
    setLoading(false)
    setError(false)
  };
  const onApproveError = (data) => {
    console.log(data);
    errorToast(data.message);
    setLoading(false)
    setError(true)
  };
  //Reject  Notify Me Api
  const rejectNotifyMeFn = () => {
    dispatch(
      rejectExpress(
        params.id,
        onRejectSuccess,
        onRejectError
      )
    );
  };

  const onRejectSuccess = (data) => {
    setUpdated(true);
    successToast("Rejected Successfully");
    navigate("/opportunity/express-interest/approvals")
    setLoading(false)
    setError(false)
  };
  const onRejectError = (data) => {
    console.log(data);
    errorToast(data.message +" "+ data.data);
    setLoading(false)
    setError(true)
  };
  const ApproveDetails = [
    {
      heading: "Approval ID",
      title: notifyMeApprovalViewData?.id,
    },
    {                 
      heading: "Opportunity ID",
      title: notifyMeApprovalViewData?.new_data == null ? notifyMeApprovalViewData?.old_data?.opportunity_id : notifyMeApprovalViewData?.new_data?.opportunity_id,
    },
    {
      heading: "Opportunity Name",
      title: notifyMeApprovalViewData?.new_data == null ? notifyMeApprovalViewData?.old_data?.opportunity?.title : notifyMeApprovalViewData?.new_data?.title,
    },
    {
      heading: "User ID",
      title: notifyMeApprovalViewData?.new_data == null ? notifyMeApprovalViewData?.old_data?.user_id : notifyMeApprovalViewData?.new_data?.user_id,
    },
    {
      heading: "User Name",
      title: notifyMeApprovalViewData?.new_data == null ? notifyMeApprovalViewData?.old_data?.userexpress?.user_name : notifyMeApprovalViewData?.new_data?.user_name,
    },
    {
      heading: "Amount",
      title: notifyMeApprovalViewData?.new_data == null ? notifyMeApprovalViewData?.old_data?.amount : notifyMeApprovalViewData?.new_data?.amount,
    },
    {
      heading: "Order ID",
      title: notifyMeApprovalViewData?.new_data == null ? notifyMeApprovalViewData?.old_data?.order_id : notifyMeApprovalViewData?.new_data?.order_id,
    },
    {                 
      heading: "Express Interest ID",
      title: notifyMeApprovalViewData?.new_data == null ? notifyMeApprovalViewData?.old_data?.id : notifyMeApprovalViewData?.new_data?.id,
    },
    {
      heading: "Payment Method",
      title: notifyMeApprovalViewData?.new_data == null ? notifyMeApprovalViewData?.old_data?.payment_method : notifyMeApprovalViewData?.new_data?.payment_method,
    },
    {
      heading: "Transaction Status",
      title: notifyMeApprovalViewData?.new_data == null ? notifyMeApprovalViewData?.old_data?.transaction_status : notifyMeApprovalViewData?.new_data?.transaction_status,    },
    {
      heading: "Transaction Error?",
      title: notifyMeApprovalViewData?.new_data == null ? notifyMeApprovalViewData?.old_data?.transaction_error_txt : notifyMeApprovalViewData?.new_data?.transaction_error_txt,
    },
    {
      heading: "Offline Transaction",
      title: notifyMeApprovalViewData?.new_data == null ? notifyMeApprovalViewData?.old_data?.transaction_error_txt : notifyMeApprovalViewData?.new_data?.transaction_error_txt,
    },
    {
      heading: "Wallet Used?",
      title: notifyMeApprovalViewData?.new_data == null ? notifyMeApprovalViewData?.old_data?.wallet_used == false ? "False" : "True" : notifyMeApprovalViewData?.new_data?.wallet_used == false ? "False" : "True",    },
    {
      heaading: "Wallet amount",
      title: notifyMeApprovalViewData?.new_data == null ? notifyMeApprovalViewData?.old_data?.wallet_amount : notifyMeApprovalViewData?.new_data?.wallet_amount,    },
    {
      heading: "Wallet Transaction ID",
      title: notifyMeApprovalViewData?.new_data == null ? notifyMeApprovalViewData?.old_data?.wallet_transaction_id : notifyMeApprovalViewData?.new_data?.wallet_transaction_id,    },
    {
      heading: "Wallet Transaction Status",
      title: notifyMeApprovalViewData?.new_data == null ? notifyMeApprovalViewData?.old_data?.wallet_transaction_status : notifyMeApprovalViewData?.new_data?.wallet_transaction_status,    },
    {
      heading: "Wallet transaction Error?",
      title: notifyMeApprovalViewData?.new_data == null ? notifyMeApprovalViewData?.old_data?.wallet_transaction_error_txt : notifyMeApprovalViewData?.new_data?.wallet_transaction_error_txt,    },
    {
      heading: "Payment Gateway Amount",
      title: notifyMeApprovalViewData?.new_data == null ? notifyMeApprovalViewData?.old_data?.pg_transaction_amount : notifyMeApprovalViewData?.new_data?.pg_transaction_amount,    },
    {
      heading: "Payment Gateway Transaction ID",
      title: notifyMeApprovalViewData?.new_data == null ? notifyMeApprovalViewData?.old_data?.pg_transaction_id : notifyMeApprovalViewData?.new_data?.pg_transaction_id,
    },
    {
      heading: "Payment Gateway Transaction Status",
      title: notifyMeApprovalViewData?.new_data == null ? notifyMeApprovalViewData?.old_data?.pg_transaction_status : notifyMeApprovalViewData?.new_data?.pg_transaction_status,    },
    {
      heading: "Payment Gateway Transaction Error?",
      title: notifyMeApprovalViewData?.new_data == null ? notifyMeApprovalViewData?.old_data?.pg_transaction_error_txt : notifyMeApprovalViewData?.new_data?.pg_transaction_error_txt,
    },
    {
      heading: "Backend Transaction ID",
      title: notifyMeApprovalViewData?.new_data == null ? notifyMeApprovalViewData?.old_data?.backend_transaction_id : notifyMeApprovalViewData?.new_data?.backend_transaction_id,    },
    {
      heading: "Tenure",
      title: notifyMeApprovalViewData?.new_data == null ? notifyMeApprovalViewData?.old_data?.tenure : notifyMeApprovalViewData?.new_data?.tenure,
    },
    {
      heading: "Created At",
      title: moment(notifyMeApprovalViewData?.createdAt == null ? "--" : notifyMeApprovalViewData?.createdAt).format("DD-MM-YYYY HH:mm:ss"),
    },
    {
      heading: "Updated At",
      title: moment(notifyMeApprovalViewData?.updatedAt == null ? "--" : notifyMeApprovalViewData?.updatedAt).format("DD-MM-YYYY HH:mm:ss"),
    },
    {
      heading: "Operation Type",
      title: notifyMeApprovalViewData?.new_data == null ? "Delete" : notifyMeApprovalViewData?.new_data && notifyMeApprovalViewData?.old_data ? "Edit" : notifyMeApprovalViewData?.old_data == null ? "Create" : ""
    }
  ];

  const buttonList = [
    <VersionHistoryBtn link='/opportunity/express-interest/version-history' />,
    <ApproveBtn approveFn={approveNotifyMeFn} />,
    <RejectBtn showModal={rejectNotifyMeFn} />
  ]
  return (
    <InnerLayout buttons={buttonList}>
      <div className="user_view_container">
        <InnerContainer error={error} oldData={notifyMeApprovalViewData?.old_data} newData={notifyMeApprovalViewData?.new_data}>
          {loading ? (
            <LoadingSpinnerTable/>
          ) : (
          <LoadAndError loader={loading} error={error}>
          <div className="row mt-4">
            <div className="col-12 col-sm-12 col-md-9 col-lg-9 col-xl-7">
              <div className="row ps-1 ms-1 ps-lg-4 ms-lg-1">
                {ApproveDetails.map((item, index) => (
                  <div className="mb-2 ">
                    <h5 className="dark_grey_color text-nowrap fs_13 fn_Montserrat fw_500">
                      {item.heading}
                    </h5>
                    <p
                      className={` fs_13 fn_Nunito  fw_500 ${item.heading === "Express Interest ID" ||
                        item.heading === "Opportunity ID" ||
                        item.heading === "Opportunity Name" ||
                        item.heading === "User ID" ||
                        item.heading === "User Name" ||
                        item.heading === "Order ID"
                        ? "secondary_color"
                        : "dark_title_color"
                        }`}
                    >
                      {item.title}
                    </p>
                  </div>
                ))}
              </div>
            </div>
          </div>
          </LoadAndError>
          )}
        </InnerContainer>
      </div>
    </InnerLayout>
  )
}

export default ExpressInterestApprovalsDetails