import React, { useState } from "react";
import { tableHowitWorksContentHeader } from "../../../table/tableData";
import { Link } from "react-router-dom";
import EditIcon from "../../../../assets/icons/editIcon";
import DeleteIcon from "../../../../assets/icons/deleteIcon";

import ErrorMsg from "../../../form/errorMsg";
import HowItWorksContentModal from "../modals/howItWorksContentModal";

const HowItWorksContentFourTable = ({
    name = "",
    tableTitle,
    setFieldValue,
    formikValues,
    editable = true
}) => {
    const [show, setShow] = useState(false);
    const [reasonEditId, setReasonEditId] = useState(null);
    function handleClose() {
        setShow(false)
    }
    function handleModalOpen(id) {
        if (id === 0 || id) {
            setReasonEditId(id)
            setShow(true)
            console.log(reasonEditId)
        }
        else {
            setReasonEditId(null)
            setShow(true)
        }
    }
    function handleReasonUpdator(id, values) {
        if (id === 0 || id) {
            let array = [...formikValues[name]];
            array[id] = { title: values.title, description: values.description };
            setFieldValue(name, array)
        }
        else {
            setFieldValue(name, [...formikValues[name], { title: values.title, description: values.description }])
        }
    }
    function reasonDeleter(index) {
        let array = [...formikValues[name]];
        if (index !== -1) {
            array.splice(index, 1);
            setFieldValue(name, array);
        }
    }
    return (
        <div className="mb-4">
            <div className="d-flex justify-content-between">
                <h2 className="dark_grey_color fs_13 fn_Montserrat fw_500">
                    {tableTitle}
                </h2>
                {editable && <button
                    type="button"
                    onClick={() => handleModalOpen(null)}
                    className="light_green_color fs_13 fn_Montserrat bg-transparent border-0 fw_500"
                >
                    Create Table
                </button>}
            </div>
            <div className={`opportunities_create_table_container`}>
                <table className={"adminuser_table"}>
                    <thead className="adminuser_table__header">
                        <tr className="adminuser_table__row">
                            {tableHowitWorksContentHeader.map((item) => {
                                return (
                                    <th
                                        key={item}
                                        className={`text-nowrap ${item == "Icon" ? "ps-2" : "ps-4"
                                            }  adminuser_table__heading`}
                                    >
                                        {item}
                                    </th>
                                );
                            })}
                        </tr>
                    </thead>
                    <tbody className="adminuser_table__body">
                        {formikValues[name]?.length > 0 && formikValues[name]?.map((item, index) => {
                            return (
                                <tr key={index} className="">
                                    <td className="text-nowrap ps-4 dark_title_color adminuser_table__data ">
                                        {index + 1}
                                    </td>
                                    <td className="text-nowrap ps-4 dark_title_color adminuser_table__data ">
                                        {item.title}
                                    </td>
                                    <td
                                        title={item.description}
                                        className="text-nowrap ps-4 dark_title_color  adminuser_table__data adminuser_table_ellipsis">
                                        {item.description}
                                    </td>
                                    <td className="text-nowrap ps-4 adminuser_table__data  end-0">
                                        {editable && <div className="d-flex gap-3">
                                            <button
                                                onClick={() => handleModalOpen(index)}
                                                type="button" className="border-0 bg-transparent">
                                                <EditIcon fill="#112F6A" />
                                            </button>
                                            <button onClick={() => reasonDeleter(index)} type="button" className="border-0 bg-transparent">
                                                <DeleteIcon fill="#D20000" />
                                            </button>
                                        </div>}
                                    </td>
                                </tr>
                            );
                        })
                        }

                    </tbody>
                </table>
            </div>
            {editable && <ErrorMsg name={name} />}
            {editable && <HowItWorksContentModal
                formikValues={formikValues[name]}
                closeModal={handleClose}
                reasonUpdator={handleReasonUpdator}
                id={reasonEditId}
                show={show}
            />}
        </div>
    );
};

export default HowItWorksContentFourTable;
