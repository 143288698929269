import React from "react";
import Modal from "react-bootstrap/Modal";
import CloseIcon from "../../../../assets/icons/closeIcon";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import InputField from "../../../form/inputFields/inputField";
import ErrorMsg from "../../../form/errorMsg";
import SelectFields from "../../../form/selectFields/selectFields";

const HomeCounterModal = ({
  show = false,
  id,
  closeModal,
  reasonUpdator,
  formikValues,
}) => {
  const initialValues = {
    counterValue: id === 0 || id ? formikValues[id]?.value : "",
    counterUnit: id === 0 || id ? formikValues[id]?.unit : "",
    counterDesc: id === 0 || id ? formikValues[id]?.description : "",
  };
  const loginSchema = Yup.object().shape({
    counterValue: Yup.number().typeError("Number Only").required(),
    counterUnit: Yup.string(),
    counterDesc: Yup.string().trim().required("Required"),
  });
  const onSubmit = (values, { resetForm }) => {
    resetForm();
    reasonUpdator(id, values);
    closeModal();
  };

  const unitOptions = [
    {
      label: "cr",
      value: "cr",
    },
    {
      label: "lk",
      value: "lk",
    },
    {
      label: "k",
      value: "k",
    },
  ];

  return (
    <Modal
      centered
      show={show}
      onHide={closeModal}
      dialogClassName={`oppoptunities_modals_body mx-auto `}
      contentClassName={"oppoptunities_modals_body_content  p-2"}
    >
      <Modal.Body>
        <>
          <div className="d-flex justify-content-between  ">
            <h2 className="green_color fs_15 fn_Montserrat fw_600 pt-1">
              Counter
            </h2>
            <button
              onClick={closeModal}
              className="light_grey_color  fs_15 fn_Montserrat bg-transparent border-0 fw_600 "
            >
              <span className="pt-1">DISMISS</span>
              <span className="ms-2">
                <CloseIcon width={13} height={13} fill="#A5A5A5" />
              </span>
            </button>
          </div>
          <Formik
            // innerRef={formRef}
            initialValues={initialValues}
            onSubmit={onSubmit}
            validationSchema={loginSchema}
          >
            <Form className="mt-3">
              <InputField
                id={"counterValue"}
                label={"Counter Value"}
                type={"text"}
                placeholder={"Enter Counter Value"}
                name={"counterValue"}
              />
              <SelectFields
                label={"Counter Unit"}
                placeholder={"Enter counter unit"}
                name={"counterUnit"}
                options={unitOptions}
                default1={
                  formikValues[id]?.unit
                    ? {
                      label: formikValues[id]?.unit,
                      value: formikValues[id]?.unit,
                    }
                    : ""
                }
                id={"counterUnit"}
              />

              <label className="mb-1 ms-2" htmlFor="Description">
                Counter Description
              </label>
              <Field name={"counterDesc"}>
                {({ field }) => (
                  <textarea
                    style={{ height: "4rem" }}
                    id="counterDesc"
                    {...field}
                    type="text"
                    placeholder="Enter counter Description"
                    className={"w-100 mb-0 faq_message_container p-2 "}
                  ></textarea>
                )}
              </Field>
              <ErrorMsg name={"counterDesc"} />
              <div className="d-flex justify-content-end mt-4">
                <button
                  type="submit"
                  className="secondary_color fs_15  fn_Montserrat fw_600 border-0 tertiary_bg px-4 px-lg-5 py-2 py-lg-2 rounded-2"
                >
                  {id === 0 || id ? "EDIT" : " CREATE"}
                </button>
              </div>
            </Form>
          </Formik>
        </>
      </Modal.Body>
    </Modal>
  );
};

export default HomeCounterModal;
