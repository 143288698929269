import {
  APPROVE_BLOG_CATEGORY,
  CREATE_BLOG_CATEGORY,
  DELETE_BLOG_CATEGORY,
  EDIT_BLOG_CATEGORY,
  BLOG_CATEGORY_APPROVAL_TABLE,
  BLOG_CATEGORY_APPROVAL_VIEW,
  BLOG_CATEGORY_OPTIONS,
  BLOG_CATEGORY_TABLE,
  BLOG_CATEGORY_VERSION_HISTORY,
  BLOG_CATEGORY_VERSION_HISTORY_TABLE,
  REJECT_BLOG_CATEGORY,
  VIEW_BLOG_CATEGORY,
  BLOG_CATEGORY_EXPORT,
  BLOG_CATEGORY_MAIN_EXPORT,
  BLOG_CATEGORY_APPROVAL_EXPORT,
  BLOG_CATEGORY_VERSION_EXPORT,
  BLOG_CATEGORY_SUBMIT,
} from "./types";

export const createBlogCategoryAction = (data, onSuccess, onError) => {
  return {
    type: CREATE_BLOG_CATEGORY,
    data,
    onSuccess,
    onError,
  };
};

export const approveBlogCategoryAction = (userID, onSuccess, onError) => {
  return {
    type: APPROVE_BLOG_CATEGORY,
    userID,
    onSuccess,
    onError,
  };
};

export const rejectBlogCategoryAction = (userID, onSuccess, onError) => {
  return {
    type: REJECT_BLOG_CATEGORY,
    userID,
    onSuccess,
    onError,
  };
};

export const viewBlogCategoryDetailAction = (userID, onSuccess, onError) => {
  return {
    type: VIEW_BLOG_CATEGORY,
    userID,
    onSuccess,
    onError,
  };
};

export const viewBlogCategoryListAction = (
  data,
  page_no,
  onSuccess,
  onError
) => {
  return {
    type: BLOG_CATEGORY_TABLE,
    data,
    page_no,
    onSuccess,
    onError,
  };
};

export const editBlogCategoryAction = (id, data, onSuccess, onError) => {
  return {
    type: EDIT_BLOG_CATEGORY,
    id,
    data,
    onSuccess,
    onError,
  };
};

export const deleteBlogCategoryAction = (userID, onSuccess, onError) => {
  return {
    type: DELETE_BLOG_CATEGORY,
    userID,
    onSuccess,
    onError,
  };
};

export const blogCategoryApprovalListAction = (
  data,
  page_no = 0,
  onSuccess,
  onError
) => {
  return {
    type: BLOG_CATEGORY_APPROVAL_TABLE,
    data,
    page_no,
    onSuccess,
    onError,
  };
};

export const blogCategoryApprovalByIdAction = (id, onSuccess, onError) => {
  return {
    type: BLOG_CATEGORY_APPROVAL_VIEW,
    id,
    onSuccess,
    onError,
  };
};

export const versionHistoryBlogCategoryListAction = (
  data,
  pageNo,
  onSuccess,
  onError
) => {
  return {
    type: BLOG_CATEGORY_VERSION_HISTORY_TABLE,
    data,
    pageNo,
    onSuccess,
    onError,
  };
};

export const versionHistoryBlogCategoryDetailAction = (
  id,
  onSuccess,
  onError
) => {
  return {
    type: BLOG_CATEGORY_VERSION_HISTORY,
    id,
    onSuccess,
    onError,
  };
};
export const blogCategoryOptionsAction = (onSuccess, onError) => {
  return {
    type: BLOG_CATEGORY_OPTIONS,
    onSuccess,
    onError,
  };
};

export const blogCategoryMainExportAction = (onSuccess, onError) => {
  return {
    type: BLOG_CATEGORY_MAIN_EXPORT,
    onSuccess,
    onError,
  };
};
export const blogCategoryApprovalsExportAction = (onSuccess, onError) => {
  return {
    type: BLOG_CATEGORY_APPROVAL_EXPORT,
    onSuccess,
    onError,
  };
};
export const blogCategoryVersionExportAction = (onSuccess, onError) => {
  return {
    type: BLOG_CATEGORY_VERSION_EXPORT,
    onSuccess,
    onError,
  };
};
export const blogCategorySubmit = (id, onSuccess, onError) => {
  return {
    type: BLOG_CATEGORY_SUBMIT,
    id,
    onSuccess,
    onError,
  };
};
