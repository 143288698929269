import { authHeader } from "../config";
import { api } from "./api";

export const addAdvisorApi = (params) => {
    return api.post("/api/homestatic/create-our-advisor", params, {
        headers: {
            "x-access-token": localStorage.getItem("accessToken"),
            "Content-Type": "application/json",
        },
    });
};
export const viewAdvisorListApi = (page = 0, params) => {
    return api.post(
        `/api/homestatic/views-our-advisors?page_no=${page}`,
        params,
        {
            headers: {
                "x-access-token": localStorage.getItem("accessToken"),
                "Content-Type": "application/json",
            },
        }
    );
};
export const viewAdvisorApi = (id, params) => {
    return api.post(`/api/homestatic/view-our-advisor/${id}`, params, {
        headers: {
            "x-access-token": localStorage.getItem("accessToken"),
            "Content-Type": "application/json",
        },
    });
};
export const editAdvisorApi = (id, params) => {
    return api.post(`/api/homestatic/edit-our-advisor/${id}`, params, {
        headers: {
            "x-access-token": localStorage.getItem("accessToken"),
            "Content-Type": "application/json",
        },
    });
};
export const deleteAdvisorApi = (id, params) => {
    return api.post(`/api/homestatic/delete-our-advisor/${id}`, params, {
        headers: {
            "x-access-token": localStorage.getItem("accessToken"),
            "Content-Type": "application/json",
        },
    });
};
export const approveAdvisorApi = (params) => {
    return api.post(`/api/homestatic/approve-our-advisor`, params, {
        headers: {
            "x-access-token": localStorage.getItem("accessToken"),
            "Content-Type": "application/json",
        },
    });
};
export const rejectAdvisorApi = (id, params) => {
    return api.post(`/api/homestatic/reject-our-advisor/${id}`, params, {
        headers: {
            "x-access-token": localStorage.getItem("accessToken"),
            "Content-Type": "application/json",
        },
    });
};
export const approvalsAdvisorListpApi = (page = 0, params) => {
    return api.post(
        `/api/homestatic/views-all-our-advisor-checkkers?page_no=${page}`,
        params,
        {
            headers: {
                "x-access-token": localStorage.getItem("accessToken"),
                "Content-Type": "application/json",
            },
        }
    );
};
export const viewApprovalAdvisorApi = (id, params) => {
    return api.post(`/api/homestatic/view-one-our-advisor-checkker/${id}`, params, {
        headers: {
            "x-access-token": localStorage.getItem("accessToken"),
            "Content-Type": "application/json",
        },
    });
};
export const versionAdvisorListpApi = (page = 0, params) => {
    return api.post(`/api/homestatic/views-our-advisors-versions?search=dddd&page_no=${page}`, params, {
        headers: {
            "x-access-token": localStorage.getItem("accessToken"),
            "Content-Type": "application/json",
        },
    });
};
export const viewVersionAdvisorApi = (id, params) => {
    return api.post(`/api/homestatic/view-our-advisor-version/${id}`, params, {
        headers: {
            "x-access-token": localStorage.getItem("accessToken"),
            "Content-Type": "application/json",
        },
    });
};

export const advisorsTableExportApi = ( params) => {
    return api.post(
        `/api/homestatic/export-main-our-advisors`,
        JSON.stringify(params),
        {
            headers: {
                "x-access-token": localStorage.getItem("accessToken"),
                "Content-Type": "application/json",
            },
        }
    );
};
export const advisorsApprovalsExportApi = ( params) => {
    return api.post(
        `/api/homestatic/export-approval-our-advisors`,
        JSON.stringify(params),
        {
            headers: {
                "x-access-token": localStorage.getItem("accessToken"),
                "Content-Type": "application/json",
            },
        }                   
    );
};
export const advisorsVersionExportApi = ( params) => {
    return api.post(
        `/api/homestatic/export-version-our-advisors`,
        JSON.stringify(params),
        {
            headers: {
                "x-access-token": localStorage.getItem("accessToken"),
                "Content-Type": "application/json",
            },
        }
    );
};


//on submit
export const advisorsSubmitApi = (id, params) => {
    return api.post(`/api/homestatic/on-submita/${id}`, params, {
        headers: {
            "x-access-token": localStorage.getItem("accessToken"),
            "Content-Type": "application/json",
        },
    });
};
