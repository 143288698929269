import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { TableContainer } from "../../../table/tableContainer";
import TablePagination from "../../../table/tablePagination";
import "../../../admin/tables/admintable.css";
import { Link } from "react-router-dom";
import {
  tableNotificationVersionHeader,
  tableNotifyMeVersionHeader,
  tableNotifyMeVersionRow,
  tableSocialVersionHeader,
} from "../../../table/tableData";
import errorToast from "../../../utilits/errorToast";
import LoadingSpinnerTable from "../../../utilits/loadingSpinnerTable";
import { versionTableNotifyMe } from "../../../../redux/actions/notifyMeActions";
import moment from "moment";
import { versionTableNotification } from "../../../../redux/actions/notificationActions";
import { versionTableSocial } from "../../../../redux/actions/socialActions";

const SocialVersionTableContent = ({ page, search, changePage }) => {
  const dispatch = useDispatch();
  const [notifyMeVersionTableData, setNotifyMeVersionTableData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pageData, setPageData] = useState({ noOfItems: 0, noOfPages: 1 });
  console.log(search);
  useEffect(() => {
    setLoading(true);
    dispatch(
      versionTableSocial(
        {
          search: {
            social_id: search?.value?.Id,
          },
        },
        page,
        onSuccess,
        onError
      )
    );
  }, [page, search]);
  const onSuccess = (data) => {
    setNotifyMeVersionTableData(data?.data?.data);
    console.log();
    setLoading(false);
    setPageData({
      noOfItems: data.data.count,
      noOfPages: data.data.pages,
    });
  };
  const onError = (data) => {
    errorToast(data.message);
    setLoading(false);
  };
  //pagination
  function handlePagination(type) {
    if (type === "+") {
      if (page + 1 < pageData.noOfPages) changePage("+");
    } else if (type === "-") if (page > 0) changePage("-");
  }

  return (
    <TableContainer width="90%">
      <div className="adminuser_table__container">
        {loading && <LoadingSpinnerTable />}
        <table className={"adminuser_table"}>
          <thead className="adminuser_table__header">
            <tr className="adminuser_table__row">
              {tableSocialVersionHeader.map((item) => {
                return (
                  <th
                    key={item + "x"}
                    className={`${
                      item === "Status"
                        ? " position-sticky end-0 text-center"
                        : ""
                    } text-nowrap adminuser_table__heading`}
                  >
                    {item}
                  </th>
                );
              })}
            </tr>
          </thead>
          {loading === false && (
            <tbody className="adminuser_table__body">
              {notifyMeVersionTableData.map((item, index) => {
                return (
                  <tr key={item.id} className="adminuser_table__row">
                    <td className="text-nowrap dark_title_color adminuser_table__data ">
                      {page > 0 ? 12 * page + (index + 1) : index + 1}
                    </td>
                    <td className="text-nowrap secondary_color  adminuser_table__data ">
                      {item?.id == null ? "-" : item?.id}
                    </td>
                    <td className="text-nowrap secondary_color  adminuser_table__data ">
                      {item.social_id == null ? "-" : item.social_id}
                    </td>
                    {/* <td className="text-nowrap dark_title_color adminuser_table_ellipsis adminuser_table__data">
                                                {item.new_data == null ? item.old_data?.user_name : item.new_data?.user_name}
                                            </td> */}
                    <td className="text-nowrap dark_title_color  adminuser_table__data">
                      {item.new_data == null
                        ? item?.old_data?.link_url
                        : item?.new_data?.link_url}
                    </td>
                    <td className="text-nowrap dark_title_color  adminuser_table__data">
                      {item.new_data == null
                        ? item?.old_data?.is_testimonial === true
                          ? "True"
                          : "False"
                        : item?.new_data?.is_testimonial === true
                        ? "True"
                        : "False"}
                    </td>
                    <td className="text-nowrap dark_title_color  adminuser_table__data">
                      {item?.updated_by == null ? "-" : item?.updated_by}
                    </td>
                    <td className="text-nowrap  dark_title_color adminuser_table__data">
                      {moment(item?.updatedAt).format("DD-MM-YYYY HH:mm:ss")}
                    </td>
                    <td className="text-nowrap  dark_title_color adminuser_table__data">
                      {item?.approved_by == null ? "-" : item?.approved_by}
                    </td>
                    <td className="text-nowrap  dark_title_color adminuser_table__data">
                      {moment(item?.approved_date).format(
                        "DD-MM-YYYY HH:mm:ss"
                      )}
                    </td>
                    <td className="text-nowrap  position-sticky end-0 adminuser_table__data">
                      <div className="d-flex w-100  justify-content-center gap-2">
                        <Link
                          className="text-decoration-none primary_color"
                          to={{
                            pathname: `/static-pages/social/version-history/view/${item.id}`,
                          }}
                        >
                          View
                        </Link>{" "}
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          )}
        </table>
      </div>
      <TablePagination
        paginate={handlePagination}
        currentPage={page}
        pageData={pageData}
      />
    </TableContainer>
  );
};

export default SocialVersionTableContent;
