import React, { useEffect, useState } from "react";

import "./opportunitiesEditContent.css";
import OpportunitiesSubSection from "./OpportunitiesSubSection";
import { useDispatch, useSelector } from "react-redux";
import * as opportunityEditorActions from "../../../../redux/actions/opportunityEditorActions";
import * as defaultSectionValues from "../../../../redux/constants/opportunityJsonState";

const OpportunitiesContentSection = ({
  addNewSection,
  deleteSection,
  numberOfSection,
  updateSectionData,
  type = "edit",
  index,
}) => {
  const dispactch = useDispatch();
  const opportunityJsonReducer = useSelector(
    (state) => state.opportunityJsonReducer
  );

  const [data, setData] = useState(opportunityJsonReducer.data[index]);
  const [title, setTitle] = useState("");

  useEffect(() => {
    setData(opportunityJsonReducer.data[index]);
    setTitle(opportunityJsonReducer.data[index]?.section_heading);
  }, []);

  useEffect(() => {
    updateReducer();
  }, [data]);

  useEffect(() => {
    console.log("OpportunitiesContentSection opportunityJsonReducer", opportunityJsonReducer);
  }, [opportunityJsonReducer]);


  const updateReducer = () => {
    // stateDate.data.
    // if (type === "edit") {
      dispactch(opportunityEditorActions.updateParentSection(index, data));
    // }
  };
  const onTitleUpdate = (text) => {
    setTitle(text);
    setData({
      ...data,
      section_heading: text,
    });
  };
  function addNewSubSection() {
    console.log(defaultSectionValues?.subSection);
    var val = { ...data };
    val?.subsections.push({ ...defaultSectionValues?.subSection, id: Date.now() });
    console.log("val", val);
    setData({
      ...val,
    });
  }
  function handleDeleteSubSection(sIndex) {
    var val = data?.subsections;
    val.splice(sIndex, 1);
    setData({
      ...data,
      subsections: [...val],
    });
  }

  return (
    <div
      className={`oppor_contentedit__container  position-relative bg-white mt-3 fs_14 fw_500`}
    >
      <div className="oppor_contentedit__header d-flex justify-content-between px-3 py-2 white_shade_bg">
        <span className={`dark_grey_color fs_13 fw_500`}>Content Section</span>
        {numberOfSection === 0 ? (
          <button type="button"
            onClick={() => addNewSection()}
            className="light_green_color fs_13 fw_500 border-0 white_shade_bg"
          >
            Add
          </button>
        ) : (
          <div className="d-flex gap-1">
            <button type="button"
              onClick={addNewSection}
              className="light_green_color fs_13 fw_500 border-0 white_shade_bg"
            >
              Add New Section
            </button>
            <button type="button" className="secondary_color fs_13 fw_500 border-0 white_shade_bg">
              Edit
            </button>
            <button
              onClick={() => {
                deleteSection(index);
              }}
              className="light_red_color fs_13 fw_500 border-0 bg-white"
            >
              Delete
            </button>
          </div>
        )}
      </div>

      <div className="d-flex flex-column my-3 py-3 px-3">
        {numberOfSection === 0 ? (
          <span className="position-absolute translate-middle top-50 start-50 dark_grey_color fw_600 fs_13">
            CLICK ON THE “ADD” BUTTON TO ADD SECTION
          </span>
        ) : (
          <div className="dark_grey_color">
            <div className="d-flex flex-column">
              <label htmlFor="sectionTitle" className="fs_14 ms-2 fw_500 mb-1">
                Section Title
              </label>
              <input
                value={title}
                onChange={(event) => {
                  onTitleUpdate(event.target.value);
                }}
                placeholder="Lorem ipsum dolor Quas"
                id="sectionTitle"
                className="oppor_content_edit__input rounded-3   col-11 col-sm-7 col-md-5 col-xl-4 "
              />
            </div>
            {data?.subsections?.length !== 0 &&
              data?.subsections?.map((item, sIndex) => (
                <React.Fragment key={item.id}>
                  <OpportunitiesSubSection
                    key={item.id}
                    parentIndex={index}
                    id={sIndex}
                    index={sIndex}
                    deleteSubSection={handleDeleteSubSection}
                  />
                </React.Fragment>
              ))}
            <div className="text-center mt-3">
              <button type="button"
                onClick={() => addNewSubSection()}
                className="w-75  oppor_content_edit__border  oppor_content_edit_btn__addsubsec rounded-3 bg-white dark_title_color fn_Nunito fs_13 fw_500"
              >
                {" "}
                + Add Subsection
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default OpportunitiesContentSection;
