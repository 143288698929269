import React, { useEffect, useRef, useState } from "react";
import DeleteBtn from "../../../../components/buttons/deleteBtn";
import SaveBtn from "../../../../components/buttons/saveBtn";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import InnerContainer from "../../../../components/layouts/innerContainer/innerContainer";
import InnerLayout from "../../../../components/layouts/innerLayout/innerLayout";
import InputField from "../../../../components/form/inputFields/inputField";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import successToast from "../../../../components/utilits/successToast";
import errorToast from "../../../../components/utilits/errorToast";
import * as opporTypeActions from "../../../../redux/actions/opportunityTypeActions";
import DeleteModal from "../../../../components/modals/deleteModal";
import { useNavigate } from "react-router-dom";
import {
  contactSubmit,
  editContactAction,
  viewContactAction,
} from "../../../../redux/actions/contactUsActions";
import LoadingSpinnerTable from "../../../../components/utilits/loadingSpinnerTable";
import LoadAndError from "../../../../components/utilits/LoadAndError";
import SubmitBtn from "../../../../components/buttons/submit";

const ContactUsEdit = () => {
  const [updated, setUpdated] = useState(false);
  const navigate = useNavigate();
  const [submitData, setSubmitData] = useState();
  const [deleteData, setDeleteData] = useState({ name: "", id: "" });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [show, setShow] = useState(false);
  const [opporTypeData, setOpporTypeData] = useState({ name: "" });
  const params = useParams();
  const dispatch = useDispatch();
  const phoneRegex = /^[0-9]{10}$/;
  const formRef = useRef();

  //initaial values
  const [contactUs, SetContactUs] = useState({
    id: "",
    name: "",
    createdAt: "",
    updatedAt: "",
  });
  useEffect(() => {
    setLoading(true);
    dispatch(viewContactAction(params.id, onSuccess, onError));
  }, [params.id]);
  const onSuccess = (data) => {
    SetContactUs(data.data);
    setLoading(false);
    setError(false);
  };
  const onError = (data) => {
    errorToast(data.message, "hvhv");
    setLoading(false);
    setError(true);
  };

  //delete

  const handleShow = () => {
    setDeleteData({
      name: opporTypeData.name,
      id: opporTypeData.id,
    });
    setShow(true);
  };
  const handleClose = () => setShow(false);
  const initialValues = {
    queryName: contactUs?.query_name,
    phoneNumber: contactUs?.phone_number,
    email: contactUs?.email,
  };
  const loginSchema = Yup.object().shape({
    queryName: Yup.string().trim().required("Required"),
    email: Yup.string().trim().email().required("Required"),
    phoneNumber: Yup.string()
      .matches(phoneRegex, "not a valid number")
      .required("Required"),
  });
  const handleSubmit = () => {
    if (formRef.current) {
      formRef.current.handleSubmit();
    }
  };

  ///EDIT THE VALUE
  const onSubmit = (values, { resetForm }) => {
    setLoading(true);
    dispatch(
      editContactAction(
        params.id,
        {
          query_name: values.queryName,
          phone_number: values.phoneNumber,
          email: values.email,
        },
        onEditSuccess,
        onEditError
      )
    );
    setUpdated(!updated);
  };

  const onEditSuccess = (data) => {
    setLoading(false);
    setError(false);
    setOpporTypeData(data.data);
    successToast(data.message);
    setSubmitData(data.data.id)
    setSaveHide(true);
    // navigate("/static-pages/contact-us-query");
  };
  const onEditError = (data) => {
    setLoading(false);
    console.log(data);
    errorToast(data.message);
    setError(false);
  };

   //on submit api
   console.log(submitData);
   const [saveHide, setSaveHide] = useState(false);
 
   const submitFnc = () => {
     dispatch(contactSubmit
       (
         submitData,
         onSubmitSuccess,
         onSubmitError,
       ));
   };
   const onSubmitSuccess = (data) => {
     console.log(data);
     navigate("/static-pages/contact-us-query");
     successToast(data.message)
   };
   const onSubmitError = (data) => {
     console.log("ERROR==================", data);
     errorToast(data.message)
 
   };
  const buttonList = [ !saveHide && <SaveBtn submitFn={handleSubmit}
    loader={loading} />,
  <SubmitBtn submitFn={submitFnc} saveHide={saveHide} />];

  const handleDelete = () => {
    setLoadingDelete(true);
    dispatch(
      opporTypeActions.deleteOpporType(
        params.id,
        onDeleteSuccess,
        onDeleteError
      )
    );
  };
  const onDeleteSuccess = (data) => {
    setLoadingDelete(false);
    successToast(data.data.data);
    setError(false);
    setShow(false);
  };
  const onDeleteError = (data) => {
    errorToast(data.data.data);
    setLoadingDelete(false);
    setError(true);
  };
  return (
    <InnerLayout buttons={buttonList}>
      <div className="opportunity-type-container">
        <InnerContainer>
          {loading ? (
            <LoadingSpinnerTable/>
          ) : (
          <LoadAndError loader={loading} error={error}>
          <div className="pt-3  ps-4 ps-lg-4 ms-3">
            <h5 className="dark_grey_color fs_13 fn_Montserrat fw_500 m-0">
              Contact Us Query ID
            </h5>
            <p
              className={` fs_13 dark_title_color fn_Nunito fw_500  secondary_color `}
            >
              {contactUs?.id}
            </p>
            <Formik
              enableReinitialize={true}
              innerRef={formRef}
              initialValues={initialValues}
              onSubmit={onSubmit}
              validationSchema={loginSchema}
            >
              <Form className="opportunity-type-form-width">
                <InputField
                  id={"queryName"}
                  label={"Query Name"}
                  type={"text"}
                  placeholder={"Name Text"}
                  name={"queryName"}
                />
                <InputField
                  id={"phoneNumber"}
                  label={"Phone Number"}
                  type={"text"}
                  placeholder={"Name Text"}
                  name={"phoneNumber"}
                />
                <InputField
                  id={"email"}
                  label={"Email"}
                  type={"text"}
                  placeholder={"Enter Email"}
                  name={"email"}
                />
              </Form>
            </Formik>
          </div>
          </LoadAndError>
          )}
        </InnerContainer>
      </div>
      <DeleteModal
        loader={loadingDelete}
        handleClose={handleClose}
        deleteFn={handleDelete}
        data={deleteData}
        show={show}
      />
    </InnerLayout>
  );
};

export default ContactUsEdit;
