import React, { useEffect, useRef, useState } from "react";
import DeleteBtn from "../../../../components/buttons/deleteBtn";
import SaveBtn from "../../../../components/buttons/saveBtn";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import InnerContainer from "../../../../components/layouts/innerContainer/innerContainer";
import InnerLayout from "../../../../components/layouts/innerLayout/innerLayout";
import "../opportunity-type.css";
import InputField from "../../../../components/form/inputFields/inputField";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import successToast from "../../../../components/utilits/successToast";
import errorToast from "../../../../components/utilits/errorToast";
import * as opporTypeActions from "../../../../redux/actions/opportunityTypeActions";
import DeleteModal from "../../../../components/modals/deleteModal";
import { useNavigate } from "react-router-dom";
import LoadingSpinnerTable from "../../../../components/utilits/loadingSpinnerTable";
import LoadAndError from "../../../../components/utilits/LoadAndError";
import SubmitBtn from "../../../../components/buttons/submit";

const OpportunityTypeEdit = () => {
    const [updated, setUpdated] = useState(false)
    const navigate = useNavigate();
    const [deleteData, setDeleteData] = useState({ name: "", id: "" });
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false)
    const [loadingDelete, setLoadingDelete] = useState(false);
    const [show, setShow] = useState(false);
    const [opporTypeData, setOpporTypeData] = useState({ name: "" })
    const [saved, setSaved] = useState(false);
    const [subLoading, setSubLoading] = useState(false);
    const params = useParams();
    const dispatch = useDispatch();
    const formRef = useRef()
    const handleShow = () => {
        setDeleteData({
            name: opporTypeData.name,
            id: opporTypeData.id,
        }); setShow(true)
    };
    const handleClose = () => setShow(false);
    const initialValues = {
        opportunityTypeName: opporTypeData.name,

    };
    const loginSchema = Yup.object().shape({
        opportunityTypeName: Yup.string().trim().required("Required"),
    });
    const handleSubmit = () => {
        if (formRef.current) {
            formRef.current.handleSubmit()
        }
    }
    const onSubmit = (values, { resetForm }) => {
        setLoading(true)
        setSaved(true);
        dispatch(
            opporTypeActions.editOpporType(params.id, values.opportunityTypeName, values.permissions, onEditSuccess, onEditError)
        );
        setUpdated(!updated)
    };
    const opporTypeSubmit = () => {
        setSubLoading(true);
        dispatch(
            opporTypeActions.opprTypeSubmitAction(params.id, onAdminSubmitSucess, onAdminSubmitError)
        );
    };
    const buttonList = [
        <SaveBtn submitFn={handleSubmit} />,
        // <DeleteBtn showModal={handleShow} />,
        <SubmitBtn loader={subLoading} saveHide={saved} submitFn={opporTypeSubmit} />
    ];
    function onAdminSubmitSucess(data) {
        successToast(data.message);
        setSubLoading(false);
        navigate("/opportunity/opportunity-type")
    }
    function onAdminSubmitError(data) {
        setSubLoading(false);
        errorToast(data.message);
    }
    useEffect(() => {
        setLoading(true);
        dispatch(
            opporTypeActions.viewOpporType(params.id, onSuccess, onError)
        );
    }, [params.id, updated]);

    const onEditSuccess = (data) => {
        successToast("Updated Successfully")
        setLoading(false)
        setOpporTypeData(data.data)
        setSaved(true)
        setError(false);
    };
    const onEditError = (data) => {
        setLoading(false)
        console.log(data);
        errorToast(data.message)
        setError(true);
    };
    const onSuccess = (data) => {
        setOpporTypeData(data.data)
        setLoading(false);
        setError(false)
    };
    const onError = (data) => {
        errorToast(data.message)
        setLoading(false);
        setError(true)
    };
    const handleDelete = () => {
        setLoadingDelete(true);
        dispatch(
            opporTypeActions.deleteOpporType(
                params.id,
                onDeleteSuccess,
                onDeleteError
            )
        );
    };
    const onDeleteSuccess = (data) => {
        setLoadingDelete(false);
        successToast(data.data.data);
        setShow(false);
        setLoading(false)
        setError(false)
    };
    const onDeleteError = (data) => {
        errorToast(data.data.data);
        setLoadingDelete(false);
        setLoading(false)
        setError(true)
    };



    return (
        <InnerLayout buttons={buttonList}>
            <div className="opportunity-type-container">
                <InnerContainer>
                    {loading ? (
                        <LoadingSpinnerTable />
                    ) : (
                        <LoadAndError loader={loading} error={error}>
                            <div className="pt-3  ps-4 ps-lg-4 ms-3">
                                <h5 className="dark_grey_color fs_13 fn_Montserrat fw_500 m-0">
                                    Opportunity Type ID
                                </h5>
                                <p className={` fs_13 dark_title_color fn_Nunito fw_500  secondary_color `}>
                                    {opporTypeData.id}
                                </p>
                                <Formik
                                    enableReinitialize={true}
                                    innerRef={formRef}
                                    initialValues={initialValues}
                                    onSubmit={onSubmit}
                                    validationSchema={loginSchema}
                                >
                                    <Form className='opportunity-type-form-width'>
                                        <InputField
                                            id={"opportunityTypeName"}
                                            label={"Opportunity Type Name"}
                                            type={"text"}
                                            placeholder={"Enter Opportunity Type Name"}
                                            name={"opportunityTypeName"}
                                        />
                                    </Form>
                                </Formik>
                            </div>
                        </LoadAndError>
                    )}
                </InnerContainer>
            </div>
            <DeleteModal
                loader={loadingDelete}
                handleClose={handleClose}
                deleteFn={handleDelete}
                data={deleteData}
                show={show}
            />

        </InnerLayout>
    );
};

export default OpportunityTypeEdit;

