

import React from "react";

const AddIcon = ({ fill = "#bcc7ce", width = 10, height = 16 }) => {
  return (



    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 12.654 12.654">
      <path id="Icon_material-add" data-name="Icon material-add" d="M20.154,14.731H14.731v5.423H12.923V14.731H7.5V12.923h5.423V7.5h1.808v5.423h5.423Z" transform="translate(-7.5 -7.5)" fill={fill} />
    </svg>

  );
};

export default AddIcon;

