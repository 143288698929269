import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import ArrowRightIcon from "../../../../assets/icons/arrowrightIcon";
import InnerContainer from "../../../../components/layouts/innerContainer/innerContainer";
import InnerLayout from "../../../../components/layouts/innerLayout/innerLayout";
import { versionHistoryAdminDetail } from "../../../../redux/actions/AdminUserAction";
import successToast from "../../../../components/utilits/successToast";
import errorToast from "../../../../components/utilits/errorToast";
import { useParams, useLocation } from 'react-router-dom';
import { versionHistoryByIdMediaRecAction } from "../../../../redux/actions/mediaRecogonitionActions";
import LoadingSpinnerTable from "../../../../components/utilits/loadingSpinnerTable";
import LoadAndError from "../../../../components/utilits/LoadAndError";

const MediaRecogonitionVersionView = () => {
    const params = useParams();
    const dispatch = useDispatch();
    const location = useLocation();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
  
    const [userVersionHistoryData, setuserVersionHistoryData] = useState({
      id: "",
      createdAt: "",
      updatedAt: "",
      new_data: { updated_by: "", updated_At: "" },
    });
  
    useEffect(() => {
        setLoading(true);
      dispatch(
        versionHistoryByIdMediaRecAction(params?.id, onSuccess, onError)
      );
    }, []);
  
    const onSuccess = (data) => {
      setuserVersionHistoryData(data?.data);
      setLoading(false);
      setError(false);
    };
  
    const onError = (data) => {
      errorToast(data.message);
      setLoading(false);
      setError(true);
    };

    console.log("The data:", userVersionHistoryData);

    

    const data = [
        {
            heading: "Media & Recogonition ID",
            title: userVersionHistoryData?.id ? userVersionHistoryData?.id : "--"
        },
        {
            heading: "Created At",
            title: userVersionHistoryData?.createdAt ? moment(userVersionHistoryData?.createdAt).format("DD-MM-YYYY HH:mm:ss") : "--"
        },
        {
            heading: "Updated At",
            title: userVersionHistoryData?.updatedAt ? moment(userVersionHistoryData?.updatedAt).format("DD-MM-YYYY HH:mm:ss") : "--"
        },
        {
            heading: "Updated By",
            title: userVersionHistoryData?.updated_by ? userVersionHistoryData?.updated_by : "--"
        },
        
    ];


    const newData = [
        {
            heading: "Checker Id",
            title: userVersionHistoryData?.new_data?.id ? userVersionHistoryData?.new_data?.id : "--"
        },
        {
            heading: "Name",
            title: userVersionHistoryData?.new_data?.name ? userVersionHistoryData?.new_data?.name : "--"

        },
        {
            heading: "Title",
            title: userVersionHistoryData?.new_data?.title ? userVersionHistoryData?.new_data?.title : "--"

        },
        {
            heading: "Created Date",
            title: userVersionHistoryData?.new_data?.createdAt ? moment(userVersionHistoryData?.new_data?.createdAt).format("DD-MM-YYYY HH:mm:ss") : "--"

        },
        {
            heading: "Description",
            title: userVersionHistoryData?.new_data?.description ? userVersionHistoryData?.new_data?.description : "--"

        },
        {
            heading: "Updated At",
            title: userVersionHistoryData?.updatedAt ? moment(userVersionHistoryData?.updatedAt).format("DD-MM-YYYY HH:mm:ss") : "--"
        }
    ];
    const oldData = [
        {
            heading: "Checker Id",
            title: userVersionHistoryData?.old_data?.id ? userVersionHistoryData?.old_data?.id : "--"
        },
        {
            heading: "Name",
            title: userVersionHistoryData?.old_data?.name ? userVersionHistoryData?.old_data?.name : "--"

        },
        {
            heading: "Title",
            title: userVersionHistoryData?.old_data?.title ? userVersionHistoryData?.old_data?.title : "--"

        },
        {
            heading: "Created Date",
            title: userVersionHistoryData?.old_data?.createdAt ? moment(userVersionHistoryData?.old_data?.createdAt).format("DD-MM-YYYY HH:mm:ss") : "--"

        },
        {
            heading: "Description",
            title: userVersionHistoryData?.old_data?.description ? userVersionHistoryData?.old_data?.description : "--"

        },
        {
            heading: "Updated At",
            title: userVersionHistoryData?.updatedAt ? moment(userVersionHistoryData?.updatedAt).format("DD-MM-YYYY HH:mm:ss") : "--"
        }

    ];

    return (
        <>
            <InnerLayout
            >
                <div className="admin_user_container">
                    <InnerContainer>
                        {loading ? (
                            <LoadingSpinnerTable/>
                        ) : (
          <LoadAndError loader={loading} error={error}>
                        <div className="pt-3 ps-5 row">
                            {data.map((item, index) => (
                                <div className="mb-2  col">
                                    <h5 className="dark_grey_color text-nowrap fs_13 fn_Montserrat fw_500 m-0">
                                        {item.heading}
                                    </h5>
                                    <p
                                        className={` fs_13 fn_Nunito text-nowrap fw_500 ${item.heading === "Admin ID" || item.heading === "E-Mail"
                                            ? "secondary_color"
                                            : "dark_title_color"
                                            }`}
                                    >
                                        {item.title}
                                    </p>
                                </div>
                            ))}
                        </div>
                        <div className="white_shade_bg  py-1 d-flex">
                            <div className="w-50">
                                <h5 className="fw_500 ps-4 dark_grey_color pt-2 text-nowrap fs_16 fn_Montserrat">
                                    JSON Old Data
                                </h5>
                            </div>
                            <div className="w-50  d-none d-lg-block">
                                <h5 className="fw_500 ps-4  dark_grey_color pt-2 text-nowrap fs_16 fn_Montserrat">
                                    JSON New Data
                                </h5>
                            </div>
                        </div>

                        <div className="row mt-3 ps-0 ps-lg-4">
                            <div className="col-12 col-lg-6">
                                <div className="admin_aprvls_vrsn_hstry_data_container  mt-3 pt-3 px-3">
                                    <div className="d-flex justify-content-between">
                                        <h5 className="fs_13 fn_Nunito fw_400 dark_title_color text-nowrap">
                                            Old Data
                                        </h5>
                                        <ArrowRightIcon width={25} height={25} fill={"#A5A5A5"} />
                                    </div>
                                    {
                                        userVersionHistoryData?.old_data === null ? (<>
                                            <div>Empty Data</div>
                                        </>) : (
                                            <>
                                                {
                                                    oldData?.map((item, index) => {
                                                        return (
                                                            <>
                                                                <h5 className="dark_grey_color text-nowrap fs_13 fn_Montserrat fw_500 m-0">
                                                                    {item.heading}
                                                                </h5>
                                                                <p
                                                                    className={` fs_13 fn_Nunito text-nowrap fw_500 ${item.heading === "Admin ID" || item.heading === "E-Mail"
                                                                        ? "secondary_color"
                                                                        : "dark_title_color"
                                                                        }`}
                                                                >
                                                                    {item.title}
                                                                </p>
                                                            </>
                                                        )

                                                    })
                                                }
                                            </>
                                        )
                                    }


                                </div>
                            </div>
                            <div className="col-12 mt-5 mt-lg-0 col-lg-6">
                                <h5 className="fw_500 ps-4 d-block d-lg-none white_shade_bg  dark_grey_color py-3 text-nowrap fs_16 fn_Montserrat">
                                    JSON New Data
                                </h5>
                                <div className="admin_aprvls_vrsn_hstry_data_container mt-4 mt-lg-3 pt-3 px-3">
                                    <div className="d-flex justify-content-between">
                                        <h5 className="fs_13 fn_Nunito fw_400 dark_title_color py-2 text-nowrap">
                                            New Data
                                        </h5>
                                        <ArrowRightIcon width={25} height={25} fill={"#A5A5A5"} />
                                    </div>
                                    {
                                        userVersionHistoryData?.new_data === null ? (<>
                                            <div>Empty Data</div>
                                        </>) : (
                                            <>{
                                        newData.map((item, index) => {
                                            return (
                                                <>
                                                    <h5 className="dark_grey_color text-nowrap fs_13 fn_Montserrat fw_500 m-0">
                                                        {item.heading}
                                                    </h5>
                                                    <p
                                                        className={` fs_13 fn_Nunito text-nowrap fw_500 ${item.heading === "Admin ID" || item.heading === "E-Mail"
                                                            ? "secondary_color"
                                                            : "dark_title_color"
                                                            }`}
                                                    >
                                                        {item.title}
                                                    </p>
                                                </>
                                            )

                                        })
                                    }</>)}
                                    
                                </div>
                            </div>
                        </div>
                        </LoadAndError>
                        )}
                    </InnerContainer>
                </div>
            </InnerLayout>
        </>
    );
};
export default MediaRecogonitionVersionView;
