import { PARTNERS_APPROVALS_EXPORT, PARTNERS_APPROVALS_LIST, PARTNERS_APPROVALS_VIEW, PARTNERS_APPROVE, PARTNERS_CREATE, PARTNERS_DELETE, PARTNERS_EDIT, PARTNERS_EXPORT, PARTNERS_LIST, PARTNERS_READ, PARTNERS_REJECT, PARTNERS_SUBMIT, PARTNERS_VERSION_EXPORT, PARTNERS_VERSION_LIST, PARTNERS_VERSION_VIEW } from "./types";


export const addParnterAction = (data, onSuccess, onError) => {
    return {
        type: PARTNERS_CREATE,
        data,
        onSuccess,
        onError,
    };
};
export const viewParnterListAction = (data, pageNo, onSuccess, onError) => {
    return {
        type: PARTNERS_LIST,
        data,
        pageNo,
        onSuccess,
        onError,
    };
};
export const viewParnterAction = (id, onSuccess, onError) => {
    return {
        type: PARTNERS_READ,
        id,
        onSuccess,
        onError,
    };
};
export const deletePartnerAction = (id, onSuccess, onError) => {
    return {
        type: PARTNERS_DELETE,
        id,
        onSuccess,
        onError,
    };
};
export const editPartnerAction = (id, data, onSuccess, onError) => {
    return {
        type: PARTNERS_EDIT,
        id,
        data,
        onSuccess,
        onError,
    };
};


export const parnterApproveAction = (id, onSuccess, onError) => {
    return {
        type: PARTNERS_APPROVE,
        id,
        onSuccess,
        onError,
    };
};
export const parnterRejectAction = (id, onSuccess, onError) => {
    return {
        type: PARTNERS_REJECT,
        id,
        onSuccess,
        onError,
    };
};



export const viewParnterApprovalAction = (id, onSuccess, onError) => {
    return {
        type: PARTNERS_APPROVALS_VIEW,
        id,
        onSuccess,
        onError,
    };
};
export const viewParnterApprovalsListAction = (data, pageNo, onSuccess, onError) => {
    return {
        type: PARTNERS_APPROVALS_LIST,
        data,
        pageNo,
        onSuccess,
        onError,
    };
};



export const viewParnterVersionListAction = (data, pageNo, onSuccess, onError) => {
    return {
        type: PARTNERS_VERSION_LIST,
        data, pageNo,
        onSuccess,
        onError,
    };
};
export const viewParnterVersionAction = (id, onSuccess, onError) => {
    return {
        type: PARTNERS_VERSION_VIEW,
        id,
        onSuccess,
        onError,
    };
};
export const partnersTableExport = (onSuccess, onError) => {
    return {
        type: PARTNERS_EXPORT,
        onSuccess,
        onError,
    };
};
export const partnersApprovalsExport = (onSuccess, onError) => {
    return {
        type: PARTNERS_APPROVALS_EXPORT,
        onSuccess,
        onError,
    };
};
export const partnersVersionExport = (onSuccess, onError) => {
    return {
        type: PARTNERS_VERSION_EXPORT,
        onSuccess,
        onError,
    };
};


export const partnersSubmit = (id, onSuccess, onError) => {
    return {
        type: PARTNERS_SUBMIT,
        id,
        onSuccess,
        onError,
    };
  };  
  