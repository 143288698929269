import { CREATE_NOTIFYME_OPTIONS, NOTIFY_APPROVALS_EXPORT, NOTIFY_APPROVALS_LIST, NOTIFY_APPROVALS_VIEW, NOTIFY_APPROVE, NOTIFY_CREATE, NOTIFY_DELETE, NOTIFY_EDIT, NOTIFY_EXPORT, NOTIFY_LIST, NOTIFY_READ, NOTIFY_REJECT, NOTIFY_SUBMIT, NOTIFY_VERSION_EXPORT, NOTIFY_VERSION_LIST, NOTIFY_VERSION_VIEW } from "./types";

export const viewAllNotifyMe = (data, pageNo, onSuccess, onError) => {
    return {
        type: NOTIFY_LIST,
        data,
        pageNo,
        onSuccess,
        onError,
    };
};
export const readApprovalNotifyMe = (id, onSuccess, onError) => {
    return {
        type: NOTIFY_APPROVALS_VIEW,
        id,
        onSuccess,
        onError,
    };
}
export const readNotifyMe = (id, onSuccess, onError) => {
    return {
        type: NOTIFY_READ,
        id,
        onSuccess,
        onError,
    };
}
export const versionTableNotifyMe = (data, pageNo, onSuccess, onError) => {
    return {
        type: NOTIFY_VERSION_LIST,
        data,
        pageNo,
        onSuccess,
        onError,
    };
}
export const deleteNotifyMe = (id, onSuccess, onError) => {
    return {
        type: NOTIFY_DELETE,
        id,
        onSuccess,
        onError,
    };
}
export const notifyMeVersionHistoryview = (id, onSuccess, onError) => {
    return {
        type: NOTIFY_VERSION_VIEW,
        id,
        onSuccess,
        onError,
    };
}
export const notifyMeApprovalsTable = (data, pageNo = 0, onSuccess, onError) => {
    return {
        type: NOTIFY_APPROVALS_LIST,
        data,
        pageNo,
        onSuccess,
        onError,
    };
}


export const notifyMeCreate = (
    data,
    onSuccess,
    onError
) => {
    return {
        type: NOTIFY_CREATE,
        data,
        onSuccess,
        onError,
    };
};

export const notifyMeCreateOptions = (onSuccess, onError) => {
    return {
        type: CREATE_NOTIFYME_OPTIONS,
        onSuccess,
        onError,
    };
};
export const editNotifyMe = (id, data, onSuccess, onError) => {
    return {
        type: NOTIFY_EDIT,
        id,
        data,
        onSuccess,
        onError,
    }
};

export const approveNotifyMe = (id, onSuccess, onError) => {
    return {
        type: NOTIFY_APPROVE,
        id,
        onSuccess,
        onError,
    };
}

export const rejectNotifyMe = (id, onSuccess, onError) => {
    return {
        type: NOTIFY_REJECT,
        id,
        onSuccess,
        onError,
    };
}


export const notifyTableExport = (onSuccess, onError) => {
    return {
        type: NOTIFY_EXPORT,
        onSuccess,
        onError,
    };
};
export const notifyApprovalsExport = (onSuccess, onError) => {
    return {
        type: NOTIFY_APPROVALS_EXPORT,
        onSuccess,
        onError,
    };
};                                    
export const notifyVersionExport = (onSuccess, onError) => {
    return {
        type: NOTIFY_VERSION_EXPORT,
        onSuccess,
        onError,
    };
};

export const notifySubmitAction = (id, onSuccess, onError) => {
    return {
      type: NOTIFY_SUBMIT,
      id,
      onSuccess,
      onError,
    };
  };