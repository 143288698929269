import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import DeleteBtn from "../../../../components/buttons/deleteBtn";
import EditBtn from "../../../../components/buttons/editBtn";
import VersionHistoryBtn from "../../../../components/buttons/versionHistoryBtn";
import InnerContainer from "../../../../components/layouts/innerContainer/innerContainer";
import InnerLayout from "../../../../components/layouts/innerLayout/innerLayout";
import DeleteModal from "../../../../components/modals/deleteModal";
import errorToast from "../../../../components/utilits/errorToast";
import LoadAndError from "../../../../components/utilits/LoadAndError";
import successToast from "../../../../components/utilits/successToast";
import {
  deleteAdmin,
  viewAdminDetail,
} from "../../../../redux/actions/AdminUserAction";
import "../admin-user.css";

const AdminView = () => {
  const [show, setShow] = useState(false);
  const navigate = useNavigate();
  const [adminDetails, setAdminDetails] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [delLoading, setDelLoading] = useState(false);
  const [deleteData, setDeleteData] = useState({ name: "", id: "" });
  const dispatch = useDispatch();
  const params = useParams();

  useEffect(() => {
    setLoading(true);
    dispatch(viewAdminDetail(params.id, onSuccess, onError));
  }, []);

  const onSuccess = (data) => {
    setAdminDetails(data.data);
    setError(false);
    setLoading(false);
  };
  const onError = (data) => {
    errorToast(data.message)
    setError(true);
    setLoading(false);
  };
  const handleDelete = () => {
    setDelLoading(true);
    dispatch(deleteAdmin(deleteData.id, onDeleteSuccess, onDeleteError));
  };
  const onDeleteSuccess = (data) => {
    successToast(data.message)
    setDelLoading(false);
    setShow(false);
    navigate("/admin-users");
  };
  const onDeleteError = (data) => {
    errorToast(data.data)
    setDelLoading(false);
    setShow(false);
  };
  const buttonList = [
    <VersionHistoryBtn link={`/admin-users/version-history`} />,
    adminDetails?.editable && <EditBtn link={`/admin-users/edit/${params.id}`} />,
    adminDetails?.editable && adminDetails?.lastActionApproved && <DeleteBtn
      showModal={() => {
        setShow(true);
        setDeleteData({ name: adminDetails.name, id: params.id });
      }}
    />,
  ];

  return (
    <InnerLayout buttons={buttonList}>
      <div className="admin_user_container">
        <InnerContainer>
          <LoadAndError loader={loading} error={error}>
            <div className="pt-3  ps-4 ps-lg-4">
              {Object.entries(adminDetails).map(([key, value]) => {
                if (
                  key === "id" ||
                  key === "name" ||
                  key === "email" ||
                  key === "role_id" ||
                  key === "createdAt" ||
                  key === "last_login_time" ||
                  // key === "password" ||
                  key === "updatedAt"
                )
                  return (
                    <div className="mb-2" key={key}>
                      <h5 className="dark_grey_color fs_13 fn_Montserrat fw_500 m-0">
                        {key === "id"
                          ? "Admin ID"
                          : key === "name"
                            ? "Name"
                            : key === "email"
                              ? "E-mail"
                              : key === "role_id"
                                ? "Role ID"
                                : key === "createdAt"
                                  ? "Created At"
                                  : key === "last_login_time"
                                    ? "Last Login"
                                    : key === "updatedAt"
                                      ? "Updated At"
                                      // : key === "password"
                                      //   ? "Password"
                                      : null}
                      </h5>
                      <p
                        className={` fs_13 fn_Nunito fw_500 ${key === "id" || key === "email"
                          ? "secondary_color"
                          : "dark_title_color"
                          }`}
                      >
                        {key == "createdAt"
                          ? moment(value).format("hh:mm:ss")
                          : key === "updatedAt"
                            ? moment(value).format("DD/MM/YYYY hh:mm:ss")
                            : value != null
                              ? value
                              : "--"}
                      </p>
                    </div>
                  );
              })}
            </div>
          </LoadAndError>
        </InnerContainer>
      </div>
      <DeleteModal
        loader={delLoading}
        handleClose={() => setShow(false)}
        deleteFn={handleDelete}
        data={deleteData}
        show={show}
      />
    </InnerLayout>
  );
};

export default AdminView;
