import { call, takeLatest } from "redux-saga/effects";
import {
  COMMUNITY_BADGES_APPROVALS_EXPORT,
  COMMUNITY_BADGES_APPROVALS_LIST,
  COMMUNITY_BADGES_APPROVALS_VIEW,
  COMMUNITY_BADGES_APPROVE,
  COMMUNITY_BADGES_CREATE,
  COMMUNITY_BADGES_DELETE,
  COMMUNITY_BADGES_EDIT,
  COMMUNITY_BADGES_EXPORT,
  COMMUNITY_BADGES_LIST,
  COMMUNITY_BADGES_READ,
  COMMUNITY_BADGES_REJECT,
  COMMUNITY_BADGES_SUBMIT,
  COMMUNITY_BADGES_VERSION_EXPORT,
  COMMUNITY_BADGES_VERSION_LIST,
  COMMUNITY_BADGES_VERSION_VIEW,
} from "../actions/types";
import {
  addCommunityBadgesApi,
  approvalsListCommunityBadgesApi,
  approveCommunityBadgesApi,
  communityBadgesApprovalsExportApi,
  communityBadgesSubmitApi,
  communityBadgesTableExportApi,
  communityBadgesVersionExportApi,
  deleteCommunityBadgesApi,
  editCommunityBadgesApi,
  rejectCommunityBadgesApi,
  versionHistoryListCommunityBadgesApi,
  viewAllCommunityBadgesApi,
  viewApprovalCommunityBadgesApi,
  viewCommunityBadgesApi,
  viewVersionHistoryCommunityBadgesApi,
} from "../apis/communityBadgesApis";
import * as referedUserApi from "../apis/referedUserApis";

export function* addCommunityBadgesWorker(action) {
  try {
    const res = yield call(addCommunityBadgesApi, action.data);
    console.log(res, "AddAdminRole");
    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res.data);
      console.log(res.data);
    } else if (res.status === 200 && res.data.status === "fail") {
      console.log(res.data);
      yield action.onError(res);
    } else {
      yield action.onError("Something Went Wrong");
    }
  } catch (err) {
    console.log(err);
    yield action.onError("Something Went Wrong");
  }
}
export function* editCommunityBadgesWorker(action) {
  try {
    const res = yield call(editCommunityBadgesApi, action.id, action.data);
    // console.log(res, "AddAdminRole");
    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res.data);
      console.log(res.data);
    } else if (res.status === 200 && res.data.status === "fail") {
      console.log(res);
      yield action.onError(res);
    } else {
      yield action.onError("Something Went Wrong");
    }
  } catch (err) {
    console.log(err);
    yield action.onError("Something Went Wrong");
  }
}

export function* deleteCommunityBadgesWorker(action) {
  try {
    const res = yield call(deleteCommunityBadgesApi, action.id);
    // console.log(res, "AddAdminRole");
    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res.data);
      console.log(res.data);
    } else if (res.status === 200 && res.data.status === "fail") {
      console.log(res);
      yield action.onError(res);
    } else {
      yield action.onError("Something Went Wrong");
    }
  } catch (err) {
    console.log(err);
    yield action.onError("Something Went Wrong");
  }
}

export function* viewCommunityBadgesWorker(action) {
  try {
    const res = yield call(viewCommunityBadgesApi, action.id);
    // console.log(res, "AddAdminRole");
    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res.data);
      console.log(res.data);
    } else if (res.status === 200 && res.data.status === "fail") {
      console.log(res.data);
      yield action.onError(res);
    } else {
      yield action.onError("Something Went Wrong!");
      console.log(res.data);
    }
  } catch (err) {
    console.log(err);
    yield action.onError("Something Went Wrong!!");
  }
}
export function* viewAllCommunityBadgesWorker(action) {
  try {
    const res = yield call(
      viewAllCommunityBadgesApi,
      action.pageNo,
      action.search
    );
    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res.data);
      console.log(res);
    } else if (res.status === 200 && res.data.status === "fail") {
      console.log(res);
      yield action.onError(res.data);
    } else {
      yield action.onError("Something Went Wrong");
    }
  } catch (err) {
    console.log(err);
    yield action.onError("Something Went Wrong");
  }
}
export function* rejectCommunityBadgesWorker(action) {
  try {
    const res = yield call(rejectCommunityBadgesApi, action.id);
    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res.data);
      console.log(res.data);
    } else if (res.status === 200 && res.data.status === "fail") {
      console.log(res);
      yield action.onError(res.data);
    } else {
      yield action.onError("Something Went Wrong!");
      console.log(res.data);
    }
  } catch (err) {
    console.log(err);
    yield action.onError("Something Went Wrong!!");
  }
}
export function* approveCommunityBadgesWorker(action) {
  try {
    const res = yield call(approveCommunityBadgesApi, action.data);
    // console.log(res, "AddAdminRole");
    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res.data);
      console.log(res.data);
    } else if (res.status === 200 && res.data.status === "fail") {
      console.log(res);
      yield action.onError(res.data);
    } else {
      yield action.onError("Something Went Wrong!");
      console.log(res.data);
    }
  } catch (err) {
    console.log(err);
    yield action.onError("Something Went Wrong!!");
  }
}
export function* viewApprovalCommunityBadgesWorker(action) {
  try {
    const res = yield call(viewApprovalCommunityBadgesApi, action.id);
    // console.log(res, "AddAdminRole");
    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res.data);
      console.log(res.data);
    } else if (res.status === 200 && res.data.status === "fail") {
      console.log(res);
      yield action.onError(res);
    } else {
      yield action.onError("Something Went Wrong!");
      console.log(res.data);
    }
  } catch (err) {
    console.log(err);
    yield action.onError("Something Went Wrong!!");
  }
}
export function* approvalsListCommunityBadgesWorker(action) {
  try {
    const res = yield call(
      approvalsListCommunityBadgesApi,
      action.pageNo,
      action.data
    );
    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res.data);
      console.log(res.data);
    } else if (res.status === 200 && res.data.status === "fail") {
      console.log(res);
      yield action.onError(res);
    } else {
      yield action.onError("Something Went Wrong!");
      console.log(res.data);
    }
  } catch (err) {
    console.log(err);
    yield action.onError("Something Went Wrong!!");
  }
}
export function* versionHistoryListCommunityBadgesWorker(action) {
  try {
    const res = yield call(
      versionHistoryListCommunityBadgesApi,
      action.pageNo,
      action.data
    );
    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res.data);
      console.log(res.data);
    } else if (res.status === 200 && res.data.status === "fail") {
      console.log(res);
      yield action.onError(res);
    } else {
      yield action.onError("Something Went Wrong!");
      console.log(res.data);
    }
  } catch (err) {
    console.log(err);
    yield action.onError("Something Went Wrong!!");
  }
}
export function* viewVersionHistoryCommunityBadgesWorker(action) {
  try {
    const res = yield call(viewVersionHistoryCommunityBadgesApi, action.id);
    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res);
      console.log(res);
    } else if (res.status === 200 && res.data.status === "fail") {
      console.log(res);
      yield action.onError(res);
    } else {
      yield action.onError("Something Went Wrong!");
      console.log(res);
    }
  } catch (err) {
    console.log(err);
    yield action.onError("Something Went Wrong!!");
  }
}

//export
export function* communityBadgesTableExportWorker(action) {
  try {
    const res = yield call(communityBadgesTableExportApi);
    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res.data);
    }
  } catch (error) {}
}

export function* communityBadgesApprovalsExportWorker(action) {
  try {
    const res = yield call(communityBadgesApprovalsExportApi);
    console.log(res);
    if (res.status === 200 && res.data.status === "success") {
      console.log(res.data);
      yield action.onSuccess(res.data.data);
    } else {
      yield action.onError(res.data);
    }
  } catch (error) {}
}

export function* communityBadgesVersionExportWorker(action) {
  try {
    const res = yield call(communityBadgesVersionExportApi);
    console.log(res);
    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res.data);
    }
  } catch (error) {}
}

export function* communityBadgesSubmitWorker(action) {
  try {
    const res = yield call(communityBadgesSubmitApi, action.id);
    console.log(res);
    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res.data);
    }
  } catch (error) {}
}

export function* watchAddCommunityBadgesWorker() {
  yield takeLatest(COMMUNITY_BADGES_CREATE, addCommunityBadgesWorker);
}
export function* watchEditCommunityBadgesWorker() {
  yield takeLatest(COMMUNITY_BADGES_EDIT, editCommunityBadgesWorker);
}
export function* watchDeleteCommunityBadgesWorker() {
  yield takeLatest(COMMUNITY_BADGES_DELETE, deleteCommunityBadgesWorker);
}
export function* watchViewCommunityBadgesWorker() {
  yield takeLatest(COMMUNITY_BADGES_READ, viewCommunityBadgesWorker);
}

export function* watchViewAllCommunityBadgessWorker() {
  yield takeLatest(COMMUNITY_BADGES_LIST, viewAllCommunityBadgesWorker);
}

export function* watchRejectCommunityBadgesWorker() {
  yield takeLatest(COMMUNITY_BADGES_REJECT, rejectCommunityBadgesWorker);
}

export function* watchApproveCommunityBadgesWorker() {
  yield takeLatest(COMMUNITY_BADGES_APPROVE, approveCommunityBadgesWorker);
}
export function* watchViewApprovalCommunityBadgesWorker() {
  yield takeLatest(
    COMMUNITY_BADGES_APPROVALS_VIEW,
    viewApprovalCommunityBadgesWorker
  );
}
export function* watchApprovalsListCommunityBadgesWorker() {
  yield takeLatest(
    COMMUNITY_BADGES_APPROVALS_LIST,
    approvalsListCommunityBadgesWorker
  );
}

export function* watchVersionHistoryListCommunityBadgesWorker() {
  yield takeLatest(
    COMMUNITY_BADGES_VERSION_LIST,
    versionHistoryListCommunityBadgesWorker
  );
}
export function* watchViewVersionHistoryCommunityBadgesWorker() {
  yield takeLatest(
    COMMUNITY_BADGES_VERSION_VIEW,
    viewVersionHistoryCommunityBadgesWorker
  );
}

//export
export function* communityBadgesTableExportWatcher() {
  yield takeLatest(COMMUNITY_BADGES_EXPORT, communityBadgesTableExportWorker);
}
export function* communityBadgesApprovalsExportWatcher() {
  yield takeLatest(
    COMMUNITY_BADGES_APPROVALS_EXPORT,
    communityBadgesApprovalsExportWorker
  );
}

export function* communityBadgesVersionExportWorkerWatcher() {
  yield takeLatest(
    COMMUNITY_BADGES_VERSION_EXPORT,
    communityBadgesVersionExportWorker
  );
}
export function* communityBadgesSubmitWorkerWatcher() {
  yield takeLatest(COMMUNITY_BADGES_SUBMIT, communityBadgesSubmitWorker);
}
