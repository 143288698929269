import {
  ADVISORS_APPROVALS_EXPORT,
  ADVISORS_APPROVALS_LIST,
  ADVISORS_APPROVALS_VIEW,
  ADVISORS_APPROVE,
  ADVISORS_CREATE,
  ADVISORS_DELETE,
  ADVISORS_EDIT,
  ADVISORS_EXPORT,
  ADVISORS_LIST,
  ADVISORS_READ,
  ADVISORS_REJECT,
  ADVISORS_SUBMIT,
  ADVISORS_VERSION_EXPORT,
  ADVISORS_VERSION_LIST,
  ADVISORS_VERSION_VIEW,

} from "./types";

export const viewAdvisorListAction = (data, pageNo = 0, onSuccess, onError) => {
  return {
    type: ADVISORS_LIST,
    data,
    pageNo,
    onSuccess,
    onError,
  };
};
export const viewAdvisorAction = (id, onSuccess, onError) => {
  return {
    type: ADVISORS_READ,
    id,
    onSuccess,
    onError,
  };
};
export const addAdvisorsAction = (data, onSuccess, onError) => {
  return {
    type: ADVISORS_CREATE,
    data,
    onSuccess,
    onError,
  };
};
export const editAdvisorAction = (id, data, onSuccess, onError) => {
  return {
    type: ADVISORS_EDIT,
    id,
    data,
    onSuccess,
    onError,
  };
};
export const deleteAdvisorAction = (id, onSuccess, onError) => {
  return {
    type: ADVISORS_DELETE,
    id,
    onSuccess,
    onError,
  };
};
export const approveAdvisorAction = (user_Id, onSuccess, onError) => {
  return {
    type: ADVISORS_APPROVE,
    user_Id,
    onSuccess,
    onError,
  };
};
export const rejectAdvisorAction = (id, onSuccess, onError) => {
  return {
    type: ADVISORS_REJECT,
    id,
    onSuccess,
    onError,
  };
};
export const approvalsAdvisorListAction = (
  data,
  page,
  onSuccess,
  onError
) => {
  return {
    type: ADVISORS_APPROVALS_LIST,
    data,
    page,
    onSuccess,
    onError,
  };
};
export const approvalsViewAdvisorAction = (id, onSuccess, onError) => {
  return {
    type: ADVISORS_APPROVALS_VIEW,
    id,
    onSuccess,
    onError,
  };
};
export const versionListAdvisorAction = (data, page, onSuccess, onError) => {
  return {
    type: ADVISORS_VERSION_LIST,
    data,
    page,
    onSuccess,
    onError,
  };
};
export const versionViewAdvisorAction = (id, onSuccess, onError) => {
  return {
    type: ADVISORS_VERSION_VIEW,
    id,
    onSuccess,
    onError,
  };
};
export const advisorsTableExport = (onSuccess, onError) => {
  return {
    type: ADVISORS_EXPORT,
    onSuccess,
    onError,
  };
};
export const advisorsApprovalsExport = (onSuccess, onError) => {
  return {
    type: ADVISORS_APPROVALS_EXPORT,
    onSuccess,
    onError,
  };
};
export const advisorsVersionExport = (onSuccess, onError) => {
  return {
    type: ADVISORS_VERSION_EXPORT,
    onSuccess,
    onError,
  };
};



export const advisorsSubmit = (id, onSuccess, onError) => {
  return {
    type: ADVISORS_SUBMIT,
    id,
    onSuccess,
    onError,
  };
};  
