import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import InnerLayout from "../../../../components/layouts/innerLayout/innerLayout";
import InnerContainer from "../../../../components/layouts/innerContainer/innerContainer";
import VersionHistoryBtn from "../../../../components/buttons/versionHistoryBtn";
import EditBtn from "../../../../components/buttons/editBtn";
import DeleteBtn from "../../../../components/buttons/deleteBtn";
import { deleteFaq, viewFaqDetail } from "../../../../redux/actions/faqAction";
import DeleteModal from "../../../../components/modals/deleteModal";
import successToast from "../../../../components/utilits/successToast";
import errorToast from "../../../../components/utilits/errorToast";
import { viewHowItWorksAction } from "../../../../redux/actions/howItWorksAction";
import HowItWorksReasonTable from "../../../../components/howItWorks/howItWorks/tables/howItWorksReasonTable";
import DownloadIcon from "../../../../assets/icons/downloadIcon";
import HowItWorksOpportunityTable from "../../../../components/howItWorks/howItWorks/tables/howItWorksOpportunityTable";
import HowItWorksContentFourTable from "../../../../components/howItWorks/howItWorks/tables/howItWorksContentFourTable";
import LoadAndError from "../../../../components/utilits/LoadAndError";
import HomeFeaturesTable from "../../../../components/static-pages/home-page/tables/homeCardsTable";
import HomeCounterTable from "../../../../components/static-pages/home-page/tables/homeCounterTable";
import { viewHomeAction } from "../../../../redux/actions/homeActions";

const HomeView = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [howItWorksData, setHowItWorksData] = useState({});
  const dispatch = useDispatch();
  const params = useParams();
  useEffect(() => {
    setLoading(true);
    dispatch(viewHomeAction(params.id, onSuccess, onError));
  }, []);
  const onSuccess = (data) => {
    console.log(data.data);
    setHowItWorksData(data.data);
    console.log("SUCCESS");
    setLoading(false);
    setError(false);
  };
  const onError = (data) => {
    console.log("ERROR");
    setLoading(false);
    setError(true);
  };
  // const [deleteData, setDeleteData] = useState({ name: "", id: "" });
  // const [loadingDelete, setLoadingDelete] = useState(false);
  // const [show, setShow] = useState(false);
  // const handleShow = () => setShow(true);
  // const handleClose = () => setShow(false);

  // const handleDelete = () => {
  //   setLoadingDelete(true);
  //   dispatch(
  //     deleteFaq(
  //       faqDetails.id,
  //       onDeleteSuccess,
  //       onDeleteError
  //     )
  //   );
  // };
  // const onDeleteSuccess = (data) => {
  //   successToast(data.message);
  //   setLoadingDelete(false);
  //   setShow(false);
  //   navigate("/faqs")

  // };
  // const onDeleteError = (data) => {
  //   errorToast(data.message);
  //   setLoadingDelete(false);
  // };

  const buttonList = [
    <VersionHistoryBtn link={`/static-pages/home/version-history`} />,
    howItWorksData.editable && <EditBtn link={`/static-pages/home/edit/${params.id}`} />,
    // <DeleteBtn showModal={deletenotifyMebyid} />,
  ];

  function ViewItemGenerator(title, details) {
    return (
      <>
        <span className="dark_grey_color fw_500 fs_16">{title}</span>
        <p className=" fs_14 mb-3 mt-1">{details}</p>
      </>
    );
  }

  return (
    <InnerLayout buttons={buttonList}>
      <div className="user_view_container mb-4">
        <div className="user_view_container mb-4">
          <InnerContainer>
            <LoadAndError loader={loading} error={error}>
              <div className="d-flex flex-column flex-lg-row gap-4 fs_14 py-3 px-3 px-lg-5">
                <div className="col-12 col-lg-6 pe-0 pe-lg-5">
                  <div>
                    {ViewItemGenerator(
                      "Header Title",
                      howItWorksData?.header_title
                    )}
                  </div>
                  <div>
                    {ViewItemGenerator(
                      "Header Subtitle",
                      howItWorksData?.header_subtitle
                    )}
                  </div>
                  <div>
                    {ViewItemGenerator(
                      "Button Text",
                      howItWorksData?.header_button
                    )}
                  </div>
                  <div>
                    {ViewItemGenerator(
                      "Opportunity Section Title",
                      howItWorksData?.section2_title
                    )}
                  </div>
                  <div>
                    {ViewItemGenerator(
                      "Opportunity Section SubTitle",
                      howItWorksData?.section2_sub_title
                    )}
                  </div>
                  <div>
                    {ViewItemGenerator(
                      "Opportunity Button Text",
                      howItWorksData?.section2_button
                    )}
                  </div>
                  {/* <HowItWorksReasonTable
                  name="reasons"
                  formikValues={howItWorksData}
                  editable={false}
                /> */}
                  <div>
                    {ViewItemGenerator(
                      "Counter Title",
                      howItWorksData?.section3_title
                    )}
                  </div>
                  <div>
                    {ViewItemGenerator(
                      "Counter Subtitle",
                      howItWorksData?.section3_sub_title
                    )}
                  </div>
                  <HomeCounterTable
                    formikValues={howItWorksData}
                    name={"section3_counter"}
                    editable={false}
                  />

                  <div>
                    {ViewItemGenerator(
                      "Feature Title",
                      howItWorksData?.section4_title
                    )}
                  </div>
                  <div>
                    {ViewItemGenerator(
                      "Feature Subtitle",
                      howItWorksData?.section4_sub_title
                    )}
                  </div>
                  <HomeFeaturesTable
                    formikValues={howItWorksData}
                    name={"section4_counter"}
                    editable={false}
                  />
                </div>
                <div className="col-12 col-lg-6  pe-0 pe-lg-5">
                  <div className="mb-3">
                    <span className="dark_grey_color fw_500 fs_16">Thumb Nail Image</span>{" "}
                    <div className="blog_create_image_container mt-2 position-relative d-flex  justify-content-center align-items-center">
                      <img
                        src={howItWorksData?.video_tumbnail_image}
                        className={"user-edit-profile-picture"}
                      />
                      <button
                        type="button"
                        className="position-absolute border-0 bg-white d-flex align-items-center fs_10 top-0 end-0"
                      >
                        <a
                          href={howItWorksData?.video_tumbnail_image}
                          download="file"
                        ></a>
                        <DownloadIcon width={8} />
                        <span className="ms-1">Download</span>
                      </button>
                    </div>
                  </div>
                  <div>
                    {ViewItemGenerator("Video Url", howItWorksData?.video_url)}
                  </div>
                  <div>
                    {ViewItemGenerator(
                      " Video Subtitle",
                      howItWorksData?.video_description
                    )}
                  </div>
                  <div>
                    {ViewItemGenerator(
                      "Insights title",
                      howItWorksData?.insights_title
                    )}
                  </div>
                  <div>
                    {ViewItemGenerator(
                      "Insights Subtitle",
                      howItWorksData?.insights_sub_title
                    )}
                  </div>
                  <div>
                    {ViewItemGenerator(
                      "Tesimonials title",
                      howItWorksData?.testimonial_title
                    )}
                  </div>
                  <div>
                    {ViewItemGenerator(
                      "Testimonials Subtitle",
                      howItWorksData?.testimonial_sub_title
                    )}
                  </div>
                  {/* <div>
                  {ViewItemGenerator(
                    "Approved At",
                    howItWorksData?.approvedAt
                      ? howItWorksData?.approvedAt
                      : "-"
                  )}
                </div>
                <div>
                  {ViewItemGenerator(
                    "Updated At",
                    moment(howItWorksData?.updatedAt).format(
                      "DD-MM-YYYY HH:mm:ss"
                    )
                  )}
                </div> */}
                </div>
              </div>
            </LoadAndError>
          </InnerContainer>
        </div>
      </div>
      {/* <DeleteModal
        loader={loadingDelete}
        handleClose={handleClose}
        deleteFn={handleDelete}
        data={deleteData}
        show={show}
      /> */}
    </InnerLayout>
  );
};

export default HomeView;
