import React, { useEffect, useRef, useState } from "react";
import "../our-advisors.css";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import ApproveBtn from "../../../../components/buttons/approveBtn";
import RejectBtn from "../../../../components/buttons/rejectBtn";
import VersionHistoryBtn from "../../../../components/buttons/versionHistoryBtn";
import InnerContainer from "../../../../components/layouts/innerContainer/innerContainer";
import InnerLayout from "../../../../components/layouts/innerLayout/innerLayout"
import DownloadIcon from "../../../../assets/icons/downloadIcon";
import { useDispatch } from "react-redux";
import { approvalsViewAdvisorAction, approveAdvisorAction, rejectAdvisorAction, viewAdvisorAction } from "../../../../redux/actions/ourAdvisorAction";
import { useNavigate, useParams } from "react-router-dom";
import successToast from "../../../../components/utilits/successToast";
import errorToast from "../../../../components/utilits/errorToast";
import moment from "moment";
import LoadingSpinnerTable from "../../../../components/utilits/loadingSpinnerTable";
import LoadAndError from "../../../../components/utilits/LoadAndError";
import OurTeamProfileImage from "../../../../components/static-pages/our-team/modals/approvalProfileImage";
const OurAdvisorsApprovalsView = () => {
  const [file, setFile] = useState(null);
  const [updated, setUpdated] = useState(false)
  const handleBackImageShow = () => setImageShow(true);
  const handleImageClose = () => setImageShow(false);
  const [imageShow, setImageShow] = useState(false);
  const formRef = useRef();
  const dispatch = useDispatch();
  const params = useParams()
  const navigate = useNavigate()
  const [wishlistData, setWishlistData] = useState([]);
  const [error, setError] = useState(false);
  const [image, setImage] = useState('');
  const [loading, setLoading] = useState(false);
  const [profile, setProfile] = useState(false);
  const [logo, setLogo] = useState(false);



  useEffect(() => {
    setLoading(true)
    console.log("the id", params?.id);
    dispatch(
      approvalsViewAdvisorAction(params?.id, onSuccess, onError)
    );
  }, [params.id, updated])

  const onSuccess = (data) => {
    setWishlistData(data?.data);
    setLoading(false)
    setImage(data.data?.new_data?.photo_url
      .split("/").pop());
    setError(false);
  };
  const onError = (data) => {
    errorToast(data.data.message)
    //errorToast("went wrong")
    setLoading(false)
    setError(true)
  };
  const approveAdvisor = () => {
    dispatch(
      approveAdvisorAction(params?.id, onApproveSuccess, onApproveError)
    );
  }
  const onApproveSuccess = (data) => {
    setUpdated(!updated)
    successToast(data.message)
    navigate('/static-pages/our-advisors/approvals')
    setLoading(false)
    setError(false)
  };
  const onApproveError = (data) => {
    console.log(data);
    errorToast(data.data)
    setLoading(false)
    setError(true)
  };


  const rejectRole = () => {
    dispatch(
      rejectAdvisorAction(params?.id, onRejectSuccess, onRejectError)
    );
  }
  const onRejectSuccess = (data) => {
    setUpdated(!updated)
    successToast(data.message)
    navigate('/static-pages/our-advisors/approvals')
    setLoading(false)
    setError(false)
  };
  const onRejectError = (data) => {
    console.log(data);
    errorToast(data.message)
    setLoading(false)
    setError(true)
  };



  const buttonList = [
    <VersionHistoryBtn link={`/static-pages/our-advisors/version-history`} />,
    <ApproveBtn approveFn={approveAdvisor} />,
    <RejectBtn showModal={rejectRole} />,
  ];
  const data = [
    {
      heading: "Approval ID",
      title: wishlistData?.id ? wishlistData?.id : "--"
    },
    {
      heading: "Name",
      title: wishlistData?.new_data == null ? wishlistData?.old_data?.name : wishlistData?.new_data?.name
    },
    {
      heading: " Designation",
      title: wishlistData?.new_data == null ? wishlistData?.old_data?.designation : wishlistData?.new_data?.designation
    },
    {
      heading: "Description",
      title: wishlistData?.new_data == null ? wishlistData?.old_data?.description : wishlistData?.new_data?.description
    },
    {
      heading: "Website Url",
      title: wishlistData?.new_data == null ? wishlistData?.old_data?.website_url : wishlistData?.new_data?.website_url
    },
    {
      heading: "Prirority",
      title: wishlistData?.new_data == null ? wishlistData?.old_data?.priority : wishlistData?.new_data?.priority
    },
    {
      heading: "Advisors Id",
      title: wishlistData?.new_data == null ? wishlistData?.old_data?.id : wishlistData?.new_data?.id
    },
    {
      heading: "Created At",
      title: wishlistData?.createdAt ? moment(wishlistData?.createdAt).format("DD-MM-YYYY HH:mm:ss") : "--"
    },

    {
      heading: "Updated At",
      title: wishlistData?.updatedAt ? moment(wishlistData?.updatedAt).format("DD-MM-YYYY HH:mm:ss") : "--"
    },
    {
      heading: "Operation Type",
      title: wishlistData?.new_data == null ? "Delete" : wishlistData?.new_data && wishlistData?.old_data ? "Edit" : wishlistData?.old_data == null ? "Create" : ""
    }
  ];
  return (
    <InnerLayout buttons={buttonList}>
      <div className="notify-me-container">
        <InnerContainer error={error} oldData={wishlistData?.old_data} newData={wishlistData?.new_data}>
          {loading ? (
            <LoadingSpinnerTable />
          ) : (
            <LoadAndError loader={loading} error={error}>
              <div className="d-lg-flex">
                <div className="pt-4 mt-2  ps-4 ps-lg-4 ms-3 col-6 ">
                  <div>
                    {data.map((item, index) => (
                      <div className="mb-3 ">
                        <h5 className="dark_grey_color fs_13 fn_Montserrat fw_500 m-0">
                          {item.heading}
                        </h5>
                        <p
                          className={` fs_13 fn_Nunito text-wrap fw_500  ${item.heading === "Opportunity ID" ||
                            item.heading === "LLP ID" ||
                            item.heading === "Registration Number" ||
                            item.heading === "Member Count"
                            ? "secondary_color"
                            : "dark_title_color"
                            } `}
                        >
                          {item.title}
                        </p>
                      </div>
                    ))}
                  </div>
                </div>
                <div className=" pt-4 mt-2  ps-4 ps-lg-4 mx-3 col-5">
                  <div className="ps-lg-4 ps-0">
                    <h5 className="dark_grey_color text-nowrap fs_13 fn_Montserrat fw_500">
                      Profile Picture
                    </h5>

                    <div className="personal_information_image_container mt-2 position-relative">
                      <img
                         onClick={() => {
                          handleBackImageShow()
                          setLogo(false)
                          setProfile(true)
                        }}
                        src={wishlistData?.new_data == null ? wishlistData?.old_data?.photo_url : wishlistData?.new_data?.photo_url}
                        className="personal_information_img_size rounded-2"
                      />
                      <a href={wishlistData?.new_data == null ? wishlistData?.old_data?.photo_url : wishlistData?.new_data?.photo_url} download="file">
                        <button
                          type="button"
                          className="position-absolute border-0 bg-white d-flex align-items-center fs_10 top-0 end-0"
                        >
                          <DownloadIcon width={8} />
                          <span className="ms-1">Download</span>
                        </button>
                      </a>
                    </div>
                  </div>
                  <div className="ps-lg-4 ps-0 mt-4">
                    <h5 className="dark_grey_color text-nowrap fs_13 fn_Montserrat fw_500">
                      Website Logo
                    </h5>
                    <div className="personal_information_image_container mt-2 position-relative">
                      <img
                        onClick={() => {
                          handleBackImageShow()
                          setLogo(true)
                          setProfile(false)
                        }}
                        src={wishlistData?.new_data == null ? wishlistData?.old_data?.website_icon : wishlistData?.new_data?.website_icon}
                        className="personal_information_img_size rounded-2"
                      />
                      <a href={wishlistData?.new_data == null ? wishlistData?.old_data?.website_icon : wishlistData?.new_data?.website_icon} download="file">
                        <button
                          type="button"
                          className="position-absolute border-0 bg-white d-flex align-items-center fs_10 top-0 end-0"
                        >
                          <DownloadIcon width={8} />
                          <span className="ms-1">Download</span>
                        </button>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </LoadAndError>
          )}
        </InnerContainer>
      </div>
      {
        logo === true && (
          <OurTeamProfileImage
            handleClose={handleImageClose}
            show={imageShow}
            Profileimage={wishlistData?.new_data == null ? wishlistData?.old_data?.website_icon : wishlistData?.new_data?.website_icon}
            modalHeading={"LOGO"}
          />
        )
      }
      {
        profile === true && (
          <OurTeamProfileImage
            handleClose={handleImageClose}
            show={imageShow}
            Profileimage={wishlistData?.new_data == null ? wishlistData?.old_data?.photo_url : wishlistData?.new_data?.photo_url}
            modalHeading={"PROFILE PICTURE"}
          />
        )
      }
    </InnerLayout>
  );
};

export default OurAdvisorsApprovalsView;
