import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { TableContainer } from "../../../table/tableContainer";
import moment from "moment";
import { tableOpportunitiesVersionHeader } from "../../../table/tableData";
import TablePagination from "../../../table/tablePagination";
import errorToast from "../../../utilits/errorToast";
import LoadingSpinnerTable from "../../../utilits/loadingSpinnerTable";
import { opportunitiesVersionTable } from "../../../../redux/actions/opportunitiesActions";
import LoadAndError from "../../../utilits/LoadAndError";
const OpportunitiesVersionHistoryTableContent = ({
  page = 0,
  search,
  changePage,
}) => {
  const dispatch = useDispatch();
  const [notifyMeVersionTableData, setNotifyMeVersionTableData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [pageData, setPageData] = useState({ noOfItems: 0, noOfPages: 1 });
  const navigate = useNavigate();
  useEffect(() => {
    setLoading(true);
    dispatch(
      opportunitiesVersionTable(
        {
          search: {
            id: search?.Id,
          },
        },
        page,
        onSuccess,
        onError
      )
    );
  }, [search, page]);
  const onSuccess = (data) => {
    setNotifyMeVersionTableData(data?.data?.data);
    setPageData({
      noOfItems: data.data.count,
      noOfPages: data.data.pages,
    });
    setError(false);
    setLoading(false);
  };
  const onError = (data) => {
    errorToast(data?.data?.data + " " + data?.data?.message);
    data?.data?.data === "You are not authorized to view" &&
      navigate("/opportunity/opportunities", { replace: true });
    setError(true);
    setLoading(false);
  };

  function handlePagination(type) {
    if (type === "+") {
      if (page + 1 < pageData.noOfPages) changePage("+");
    } else if (type === "-") if (page > 0) changePage("-");
  }
  return (
    <TableContainer width="90%">
      <div className="adminuser_table__container">
        {loading && <LoadingSpinnerTable />}
        <table className={"adminuser_table"}>
          <thead className="adminuser_table__header">
            <tr className="adminuser_table__row">
              {tableOpportunitiesVersionHeader.map((item) => {
                return (
                  <th
                    key={item + "x"}
                    className={`${
                      item === "Status."
                        ? " position-sticky end-0 text-center"
                        : ""
                    } text-nowrap adminuser_table__heading`}
                  >
                    {item}
                  </th>
                );
              })}
            </tr>
          </thead>

          <tbody className="adminuser_table__body">
            <LoadAndError
              loader={loading}
              error={error}
              status={notifyMeVersionTableData.length === 0}
            >
              {notifyMeVersionTableData.map((item, index) => {
                return (
                  <tr key={item.id} className="adminuser_table__row">
                    <td className="text-nowrap dark_title_color adminuser_table__data ">
                      {page > 0 ? 12 * page + (index + 1) : index + 1}
                    </td>
                    <td className="text-nowrap secondary_color  adminuser_table__data ">
                      {item?.id === null ? "--" : item?.id}
                    </td>
                    <td className="text-nowrap secondary_color  adminuser_table__data ">
                      {item?.opportunities_id ? item?.opportunities_id : "-"}
                    </td>
                    <td className="text-nowrap adminuser_table_ellipsis dark_title_color adminuser_table__data">
                      {item?.new_data === null
                        ? item?.old_data?.opportunity_type_id
                        : item?.new_data?.opportunity_type_id}
                    </td>
                    <td className="text-nowrap adminuser_table_ellipsis dark_title_color adminuser_table__data">
                      {item?.new_data === null
                        ? item?.old_data?.title
                        : item?.new_data?.title}
                    </td>
                    <td className="text-nowrap dark_title_color  adminuser_table__data">
                      {item?.new_data === null
                        ? item?.old_data?.opportunity_type_name
                        : item?.new_data?.opportunity_type_name}
                    </td>
                    <td className="text-nowrap dark_title_color  adminuser_table__data">
                      {item?.new_data === null
                        ? item?.old_data.status
                        : item?.new_data?.status}
                    </td>
                    <td className="text-nowrap dark_title_color  adminuser_table__data">
                      {item?.new_data === null
                        ? item?.old_data?.total_funding_amount
                        : item?.new_data?.total_funding_amount}
                    </td>
                    <td className="text-nowrap dark_title_color  adminuser_table__data">
                      {item?.new_data === null
                        ? item?.old_data?.total_funded_amount
                        : item?.new_data?.total_funded_amount}
                    </td>
                    <td className="text-nowrap dark_title_color  adminuser_table__data">
                      {item?.new_data === null
                        ? moment(item?.old_data?.available_from_date).format(
                            "DD-MM-YYYY HH:mm:ss"
                          )
                        : moment(item?.new_data?.available_from_date).format(
                            "DD-MM-YYYY HH:mm:ss"
                          )}
                    </td>
                    <td className="text-nowrap dark_title_color  adminuser_table__data">
                      {item?.new_data === null
                        ? moment(item?.old_data?.available_till_date).format(
                            "DD-MM-YYYY HH:mm:ss"
                          )
                        : moment(item?.new_data?.available_till_date).format(
                            "DD-MM-YYYY HH:mm:ss"
                          )}
                    </td>
                    <td className="text-nowrap dark_title_color  adminuser_table__data">
                      {item?.createdAt == null
                        ? "--"
                        : moment(item?.item?.createdAt).format(
                            "DD-MM-YYYY HH:mm:ss"
                          )}
                    </td>
                    <td className="text-nowrap dark_title_color  adminuser_table__data">
                      {item?.createdAt == null
                        ? "--"
                        : moment(item?.item?.updatedAt).format(
                            "DD-MM-YYYY HH:mm:ss"
                          )}
                    </td>

                    <td className="text-nowrap  dark_title_color adminuser_table__data">
                      {item?.updated_by === null ? "--" : item?.updated_by}
                    </td>
                    <td className="text-nowrap  dark_title_color adminuser_table__data">
                      {item.approved_by == null ? "--" : item.approved_by}
                    </td>
                    <td className="text-nowrap  dark_title_color adminuser_table__data">
                      {item.approved_date == null
                        ? "--"
                        : moment(item.approved_date).format(
                            "DD-MM-YYYY HH:mm:ss"
                          )}
                    </td>
                    <td className="text-nowrap  position-sticky end-0 adminuser_table__data">
                      <div className="d-flex w-100  justify-content-center gap-2">
                        <Link
                          className="text-decoration-none primary_color"
                          to={{
                            pathname: `/opportunity/opportunities/version-history/view/${item.id}`,
                          }}
                        >
                          View
                        </Link>{" "}
                      </div>
                    </td>
                  </tr>
                );
              })}
            </LoadAndError>
          </tbody>
        </table>
      </div>
      <TablePagination
        paginate={handlePagination}
        currentPage={page}
        pageData={pageData}
      />
    </TableContainer>
  );
};

export default OpportunitiesVersionHistoryTableContent;
