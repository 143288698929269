import React, { useRef, useState } from "react";
import DeleteBtn from "../../../../components/buttons/deleteBtn";
import SaveBtn from "../../../../components/buttons/saveBtn";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import InnerContainer from "../../../../components/layouts/innerContainer/innerContainer";
import InnerLayout from "../../../../components/layouts/innerLayout/innerLayout";
import "../opportunity-type.css";
import InputField from "../../../../components/form/inputFields/inputField";
import * as opporTypeActions from "../../../../redux/actions/opportunityTypeActions";
import successToast from "../../../../components/utilits/successToast";
import errorToast from "../../../../components/utilits/errorToast";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import SubmitBtn from "../../../../components/buttons/submit";

const OpportunityTypeCreate = () => {
    const [loading, setLoading] = useState(false)
    const formRef = useRef();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [subLoading, setSubLoading] = useState(false);
    const [adminId, setAdminId] = useState("");
    const [saved, setSaved] = useState(false)
    const initialValues = {
        opportunityTypeName: "",

    };
    const loginSchema = Yup.object().shape({
        opportunityTypeName: Yup.string().trim().required("Required"),
    });
    const handleSubmit = () => {
        if (formRef.current) {
            formRef.current.handleSubmit()
        }
    }
    const onSubmit = (values) => {
        setLoading(true)
        dispatch(
            opporTypeActions.createOpporType(values.opportunityTypeName, onSuccess, onError)
        );
    };
    const onSuccess = (data) => {
        console.log(data);
        setLoading(false)
        setAdminId(data.data.id)
        setSaved(true)
        successToast(data.message)
        navigate(`/opportunity/opportunity-type/edit/${data.data.id}`)
    };
    const onError = (data) => {
        setLoading(false)
        errorToast(data.message)
    };
    const opporTypeSubmit = () => {
        setSubLoading(true);
        dispatch(
            opporTypeActions.opprTypeSubmitAction(adminId, onAdminSubmitSucess, onAdminSubmitError)
        );
    };
    const buttonList = [
        <SaveBtn loader={loading} submitFn={handleSubmit} />,
    ];

    function onAdminSubmitSucess(data) {
        successToast(data.message);
        setSubLoading(false);

    }
    function onAdminSubmitError(data) {
        setSubLoading(false);
        errorToast(data.message);
    }
    return (
        <InnerLayout buttons={buttonList}>
            <div className="opportunity-type-container">
                <InnerContainer>
                    <div className="pt-3  ps-4 ps-lg-4 ms-3">
                        {/* <h5 className="dark_grey_color fs_13 fn_Montserrat fw_500 m-0">
                            Opportunity Type ID
                        </h5>
                        <p className={` fs_13 dark_title_color fn_Nunito fw_500  secondary_color `}>
                            0123asdf456jkl7w
                        </p> */}
                        <Formik
                            innerRef={formRef}
                            initialValues={initialValues}
                            onSubmit={onSubmit}
                            validationSchema={loginSchema}
                        >
                            <Form className='opportunity-type-form-width'>
                                <InputField
                                    id={"opportunityTypeName"}
                                    label={"Opportunity Type Name"}
                                    type={"text"}
                                    placeholder={"Enter Opportunity Type Name"}
                                    name={"opportunityTypeName"}
                                />
                            </Form>
                        </Formik>
                    </div>
                </InnerContainer>
            </div>
        </InnerLayout>
    );
};

export default OpportunityTypeCreate;

