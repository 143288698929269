import { CREATE_NOTIFYME_OPTIONS, NOTIFY_APPROVALS_EXPORT, NOTIFY_APPROVALS_LIST, NOTIFY_APPROVALS_VIEW, NOTIFY_APPROVE, NOTIFY_CREATE, NOTIFY_DELETE, NOTIFY_EDIT, NOTIFY_EXPORT, NOTIFY_LIST, NOTIFY_READ, NOTIFY_REJECT, NOTIFY_SUBMIT, NOTIFY_VERSION_EXPORT, NOTIFY_VERSION_LIST, NOTIFY_VERSION_VIEW } from "../actions/types";
import { call, takeLatest } from "redux-saga/effects";
import { approveNotifyMeApi, deleteNotifyMeApi, editNotifyMeApi, notifyApprovalsExportApi, notifyMeApprovalsTableApi, notifyMeCreateApi, notifyMeCreateOptionsApi, notifyMeVersionHistoryViewApi, notifySubmitApi, notifyTableExportApi, notifyVersionExportApi, readApprovalNotifyMeApi, readNotifyMeApi, rejectNotifyMeApi, versionTableNotifyMeApi, viewAllNotifyMeApi } from "../apis/notifyMeApis";
import { readApprovalNotifyMe } from "../actions/notifyMeActions";
export function* deleteNotifyMeWorker(action) {

    try {     
        const res = yield call(deleteNotifyMeApi, action.id,
        );
        // console.log(res, "AddAdminRole");
        if (res.status === 200 && res.data.status === "success") {
            yield action.onSuccess(res.data);
            console.log(res.data);
        } else if (res.status === 200 && res.data.status === "fail") {
            console.log(res);
            yield action.onError(res);
        } else {
            yield action.onError("Something Went Wrong");
        }
    } catch (err) {
        console.log(err);
        yield action.onError("Something Went Wrong");
    }

}
export function* readNotifyMeWorker(action) {
    try {
        const res = yield call(readNotifyMeApi,
            action.id,
        );
        // console.log(res, "AddAdminRole");
        if (res.status === 200 && res.data.status === "success") {
            yield action.onSuccess(res.data);
            console.log(res.data);
        } else if (res.status === 200 && res.data.status === "fail") {
            console.log(res.data);
            yield action.onError(res);
        } else {
            yield action.onError("Something Went Wrong!");
            console.log(res.data);
        }
    } catch (err) {
        console.log(err);
        yield action.onError("Something Went Wrong!!");
    }
}
export function* readApprovalNotifyMeWorker(action) {
    try {
        const res = yield call(readApprovalNotifyMeApi,
            action.id,
        );
        // console.log(res, "AddAdminRole");
        if (res.status === 200 && res.data.status === "success") {
            yield action.onSuccess(res.data);
            console.log(res.data);
        } else if (res.status === 200 && res.data.status === "fail") {
            console.log(res.data);
            yield action.onError(res);
        } else {
            yield action.onError("Something Went Wrong!");
            console.log(res.data);
        }
    } catch (err) {
        console.log(err);
        yield action.onError("Something Went Wrong!!");
    }
}
export function* viewAllNotifyMeWorker(action) {
    try {
        const res = yield call(viewAllNotifyMeApi,
            action.pageNo,
            action.data,
        );
        // console.log(res, "AddAdminRole");
        if (res.status === 200 && res.data.status === "success") {
            yield action.onSuccess(res.data);
            console.log(res, "hgcdhgvdh");
        } else if (res.status === 200 && res.data.status === "fail") {
            console.log(res);
            yield action.onError(res.data);
        } else {
            yield action.onError("Something Went Wrong");
        }
    } catch (err) {
        console.log(err);
        yield action.onError("Something Went Wrong");
    }
}

export function* versionTableNotifyMeWorker(action) {
    try {
        const res = yield call(versionTableNotifyMeApi,
            action.pageNo,
            action.data,
        );
        if (res.status === 200 && res.data.status === "success") {
            yield action.onSuccess(res.data);
            console.log(res.data);
        } else if (res.status === 200 && res.data.status === "fail") {
            console.log(res);
            yield action.onError(res);
        } else {
            yield action.onError("Something Went Wrong!");
            console.log(res.data);
        }
    } catch (err) {
        console.log(err);
        yield action.onError("Something Went Wrong!!");
    }
}

export function* notifyMeVersionHistoryWorker(action) {
    try {
        const res = yield call(notifyMeVersionHistoryViewApi, action.id
        );
        if (res.status === 200 && res.data.status === "success") {
            yield action.onSuccess(res);
            console.log(res);
        } else if (res.status === 200 && res.data.status === "fail") {
            console.log(res);
            yield action.onError(res);
        } else {
            yield action.onError("Something Went Wrong!");
            console.log(res);
        }
    } catch (err) {
        console.log(err);
        yield action.onError("Something Went Wrong!!");
    }
}

export function* notifyMeApprovalsTableWorker(action) {
    try {
        const res = yield call(notifyMeApprovalsTableApi,
            action.pageNo,
            action.data,
        );
        if (res.status === 200 && res.data.status === "success") {
            yield action.onSuccess(res.data);
            console.log(res.data);
        } else if (res.status === 200 && res.data.status === "fail") {
            console.log(res);
            yield action.onError(res);
        } else {
            yield action.onError("Something Went Wrong!");
            console.log(res.data);
        }
    } catch (err) {
        console.log(err);
        yield action.onError("Something Went Wrong!!");
    }
}
export function* notifyMeCreateWorker(action) {
    try {
        const res = yield call(notifyMeCreateApi, action.data);

        if (res.status === 200 && res.data.status === "success") {
            console.log(res.data);
            yield action.onSuccess(res.data);
        } else {
            yield action.onError(res.data);
        }
    } catch (error) { }
}

export function* notifyMeCreateOptionsWorker(action) {
    try {
        const res = yield call(notifyMeCreateOptionsApi, {});
        console.log(res);
        if (res.status === 200 && res.data.status === "success") {
            yield action.onSuccess(res.data);
            console.log(res.data);
        } else {
            yield action.onError(res.data);
        }
    } catch (error) { }
}


export function* editNotifyMeWorker(action) {

    try {
        const res = yield call(editNotifyMeApi, action.id, action.data);
        if (res.status === 200 && res.data.status === "success") {
            yield action.onSuccess(res.data);
            console.log(res.data);
        } else if (res.status === 200 && res.data.status === "fail") {
            console.log(res);
            yield action.onError(res);
        } else {
            yield action.onError("Something Went Wrong");
        }
    } catch (err) {
        console.log(err);
        yield action.onError("Something Went Wrong");
    }

}

export function* approveNotifyMeWorker(action) {
    try {
        const res = yield call(approveNotifyMeApi,
            { notifyme_id: action.id }
        );
        // console.log(res, "AddAdminRole");
        if (res.status === 200 && res.data.status === "success") {
            yield action.onSuccess(res.data);
            console.log(res.data);
        } else if (res.status === 200 && res.data.status === "fail") {
            console.log(res);
            yield action.onError(res.data);
        } else {
            yield action.onError("Something Went Wrong!");
            console.log(res.data);
        }
    } catch (err) {
        console.log(err);
        yield action.onError("Something Went Wrong!!");
    }
}


export function* rejectNotifyMeWorker(action) {
    try {
        const res = yield call(rejectNotifyMeApi,
            action.id
        );
        // console.log(res, "AddAdminRole");
        if (res.status === 200 && res.data.status === "success") {
            yield action.onSuccess(res.data);
            console.log(res.data);
        } else if (res.status === 200 && res.data.status === "fail") {
            console.log(res);
            yield action.onError(res.data);
        } else {
            yield action.onError("Something Went Wrong!");
            console.log(res.data);
        }
    } catch (err) {
        console.log(err);
        yield action.onError("Something Went Wrong!!");
    }
}


//export
export function* notifyTableExportWorker(action) {
    try {
        const res = yield call(notifyTableExportApi,

        );
        if (res.status === 200 && res.data.status === "success") {
            yield action.onSuccess(res.data);
        } else {
            yield action.onError(res.data);
        }
    } catch (error) { }
}


export function* notifyApprovalsExportWorker(action) {
    try {
        const res = yield call(notifyApprovalsExportApi);
        console.log(res);
        if (res.status === 200 && res.data.status === "success") {
            console.log(res.data);
            yield action.onSuccess(res.data.data);
        } else {
            yield action.onError(res.data);
        }
    } catch (error) { }
}

export function* notifyVersionExportWorker(action) {
    try {
        const res = yield call(notifyVersionExportApi);
        console.log(res);
        if (res.status === 200 && res.data.status === "success") {
            yield action.onSuccess(res.data);
        } else {
            yield action.onError(res.data);
        }
    } catch (error) { }
}

export function* notifySubmitWorker(action) {
    try {
      const res = yield call(notifySubmitApi, action.id);
      console.log(res);
      if (res.status === 200 && res.data.status === "success") {
        yield action.onSuccess(res.data);
      } else {
        yield action.onError(res.data);
      }
    } catch (error) { }
  }
  
  
      
export function* watchViewAllNotifyMeWorker() {
    yield takeLatest(NOTIFY_LIST, viewAllNotifyMeWorker);
}
export function* watchNotifyMeWorker() {
    yield takeLatest(NOTIFY_DELETE, deleteNotifyMeWorker);
}
export function* watchreadNotifyMeWorker() {
    yield takeLatest(NOTIFY_READ, readNotifyMeWorker);
}
export function* watchreadApprovalNotifyMeWorker() {
    yield takeLatest(NOTIFY_APPROVALS_VIEW, readApprovalNotifyMeWorker);
}

export function* watchversionTableNotifyMeWorker() {
    yield takeLatest(NOTIFY_VERSION_LIST, versionTableNotifyMeWorker);
}
export function* watchnotifyMeVersionHistoryWorker() {
    yield takeLatest(NOTIFY_VERSION_VIEW, notifyMeVersionHistoryWorker);
}
export function* watchnotifyMeApprovalsTableWorker() {
    yield takeLatest(NOTIFY_APPROVALS_LIST, notifyMeApprovalsTableWorker);
}
export function* WatchernotifyMeCreateWorker() {
    yield takeLatest(NOTIFY_CREATE, notifyMeCreateWorker);
}
export function* WatchernotifyMeCreateOptionsWorker() {
    yield takeLatest(CREATE_NOTIFYME_OPTIONS, notifyMeCreateOptionsWorker);
}

export function* watcheditNotifyMeWorker() {
    yield takeLatest(NOTIFY_EDIT, editNotifyMeWorker);
}

export function* watchapproveNotifyMeWorker() {
    yield takeLatest(NOTIFY_APPROVE, approveNotifyMeWorker);
}


export function* watchrejectNotifyMeWorker() {
    yield takeLatest(NOTIFY_REJECT, rejectNotifyMeWorker);
}

//export
export function* notifyTableExportWatcher() {
    yield takeLatest(
        NOTIFY_EXPORT,
        notifyTableExportWorker
    );
}
export function* notifyApprovalsExportWatcher() {
    yield takeLatest(NOTIFY_APPROVALS_EXPORT, notifyApprovalsExportWorker);
}

export function* notifyVersionExportWorkerWatcher() {
    yield takeLatest(NOTIFY_VERSION_EXPORT, notifyVersionExportWorker);
}
export function* notifySubmitWorkerWatcher() {
    yield takeLatest(NOTIFY_SUBMIT, notifySubmitWorker);
}
