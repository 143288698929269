import React, { useEffect, useState } from "react";
import { TableContainer } from "../../../table/tableContainer";
import TablePagination from "../../../table/tablePagination";
import "../../../admin/tables/admintable.css";
import { Link } from "react-router-dom";
import {
  tableMemTradeSellApprovalHeader,
  tableWishlistApprovalsHeader,
  tableWishlistApprovalsRow,
} from "../../../table/tableData";
import { useDispatch } from "react-redux";
import { approvalsWishlist } from "../../../../redux/actions/wishlistActions";
import errorToast from "../../../utilits/errorToast";
import LoadingSpinnerTable from "../../../utilits/loadingSpinnerTable";
import moment from "moment";
import { approvalsMemTradeSell } from "../../../../redux/actions/memTradeSellActions";

const MemTradeSellApprovalsTableContent = ({ page, search, changePage }) => {
  const dispatch = useDispatch();
  const [wishlistApprovalsData, setWishlistApprovalsData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pageData, setPageData] = useState({ noOfItems: 0, noOfPages: 1 });
  useEffect(() => {
    setLoading(true);
    dispatch(
      approvalsMemTradeSell(
        {
          search: {
            member_trade_id: search.value.Id,
          },
        },
        page,
        onSuccess,
        onError
      )
    );
  }, [page, search]);

  const onSuccess = (data) => {
    setWishlistApprovalsData(data.data.data);
    setLoading(false);
    setPageData({
      noOfItems: data?.data?.count,
      noOfPages: data?.data?.pages,
    });
  };
  const onError = (data) => {
    errorToast(data.message);
    setLoading(false);
  };

  //pagination
  function handlePagination(type) {
    if (type === "+") {
      if (page + 1 < pageData.noOfPages) changePage("+");
    } else if (type === "-") if (page > 0) changePage("-");
  }
  return (
    <TableContainer width="90%">
      <div className="adminuser_table__container ">
        {loading && <LoadingSpinnerTable />}
        <table className={"adminuser_table"}>
          <thead className="adminuser_table__header">
            <tr className="adminuser_table__row">
              {tableMemTradeSellApprovalHeader.map((item) => {
                return (
                  <th
                    key={item + "x"}
                    className={`${
                      item === "Status"
                        ? " position-sticky end-0 text-center"
                        : ""
                    } text-nowrap adminuser_table__heading`}
                  >
                    {item}
                  </th>
                );
              })}
            </tr>
          </thead>
          {loading == false && (
            <tbody className="adminuser_table__body">
              {wishlistApprovalsData.map((item, index) => {
                return (
                  <tr key={item.id} className="adminuser_table__row">
                    <td className="text-nowrap dark_title_color adminuser_table__data ">
                      {page > 0 ? 12 * page + (index + 1) : index + 1}
                    </td>
                    <td className="text-nowrap dark_title_color adminuser_table__data ">
                      {item?.id === null ? "-" : item?.id}
                    </td>
                    <td className="text-nowrap dark_title_color  adminuser_table__data">
                      {item?.member_trade_id ? item?.member_trade_id : "-"}
                    </td>
                    <td className="text-nowrap secondary_color adminuser_table__data">
                      {item?.new_data === null
                        ? item?.old_data?.opportunity_id
                        : item?.new_data?.opportunity_id}
                    </td>
                    <td className="text-nowrap dark_title_color  adminuser_table__data">
                      {item?.new_data === null
                        ? item?.old_data?.user?.user_name
                        : item?.new_data?.user_name}
                    </td>
                    <td className="text-nowrap dark_title_color  adminuser_table__data">
                      {item?.createdAt === null ? "-" : item?.createdAt}
                    </td>
                    <td className="text-nowrap dark_title_color  adminuser_table__data">
                      {item?.updatedAt === null
                        ? "-"
                        : moment(item?.updatedAt).format("DD-MM-YYYY HH:mm:ss")}
                    </td>
                    <td className="text-nowrap  position-sticky end-0 adminuser_table__data">
                      <div className="d-flex w-100  justify-content-center gap-2">
                        <Link
                          className="text-decoration-none primary_color"
                          to={{
                            pathname: `/opportunity/memTradeSell/approvals/view/${item.id}`,
                          }}
                        >
                          View
                        </Link>{" "}
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          )}
        </table>
      </div>
      <TablePagination
        paginate={handlePagination}
        currentPage={page}
        pageData={pageData}
      />
    </TableContainer>
  );
};

export default MemTradeSellApprovalsTableContent;
