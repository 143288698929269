import React, { useState } from "react";
import useScreenSizeDetector from "../../../utilits/useScreenSizeDetector";
import styles from "./opportunitiesread.module.css";
import StickyBox from "react-sticky-box";

const OpportunitiesReadHeadTag = ({ data = [] }) => {
  const { width } = useScreenSizeDetector();
  let res = data.sort(({ priority: a }, { priority: b }) => a - b);
  console.log(res);
  return (
    <>
      <div
        className={`${styles.oppor_read_headtag__container} bg-white mt-0 mt-md-2`}
      >
        <ul
          className={`list-unstyled position-relative d-flex full_black_color fs_14 fs_sm_10 px-2 px-lg-5 py-3 gap-1 gap-lg-3 ${styles.nav_scroll}`}
        >
          {res?.map((item) => {
            {
              return (
                <li
                  style={{ backgroundColor: `${item?.color_code}` }}
                  className={`rounded-pill py-2 px-3  text-nowrap ${styles.oppor_read_headtag_color__mainbg} fw_500`}
                >
                  {item.name}
                </li>
              );
            }
          })}

          {/* {width > 768 ? ( */}
          {/* <>
              <li
                className={`rounded-pill py-2 px-3 text-nowrap ${styles.oppor_read_headtag_color__bg} fw_500`}
              >
                INVOICE DISCOUNTING
              </li>
              <li
                className={`rounded-pill py-2 px-3 text-nowrap ${styles.oppor_read_headtag_color__bg} fw_500`}
              >
                INVOICE DISCOUNTING
              </li>
            </> */}
          {/* // ) : ( */}
          {/* <>
              <li
                onClick={() => setTags(!tags)}
                className={`rounded-pill cursor_pointer py-2 text-white fw_500 px-3 text-nowrap ${styles.oppor_read_headtag_color__bg} fw_500`}
              >
                +2 tags
              </li>
              <li
                className={`rounded-circle py-2 text-white fw_500 px-3 text-nowrap ${styles.oppor_read_headtag_color__bg} fw_500`}
              ></li>
              <li
                className={`rounded-circle py-2 text-white fw_500 px-3 text-nowrap ${styles.oppor_read_headtag_color__bg} fw_500`}
              ></li>
              <li
                className={`rounded-circle py-2 text-white fw_500 px-3 text-nowrap ${styles.oppor_read_headtag_color__bg} fw_500`}
              ></li>
              {
                <li
                  className={`position-absolute gap-2 ${styles.navbar_tags_invoice_discount}`}
                >
                  <div
                    className={`${tags ? "d-block" : "d-none"} ${
                      styles.oppor_nav_list_tagdropdown
                    }  align-items-center fw_600  fn_Montserrat cursor_pointer fs_10 secondary_color fs_md_12 ms-2  px-1 py-1 gap-2`}
                  >
                    <div className=" d-flex  gap-2 p-1">
                      <div
                        className={`bg-white d-inline-block py-2 px-2 ${styles.oppor_nav_list_tagdropdown__item}`}
                      >
                        INVOICE DISCOUNTING
                      </div>
                      <div
                        className={`bg-white d-inline-block py-2 px-1 ${styles.oppor_nav_list_tagdropdown__item}`}
                      >
                        INVOICE DISCOUNTING
                      </div>
                    </div>
                    <div className=" d-flex justify-content-center mt-2">
                      <div
                        className={` bg-white d-inline-block py-2 px-1 ${styles.oppor_nav_list_tagdropdown__item}`}
                      >
                        INVOICE DISCOUNTING
                      </div>
                    </div>
                  </div>
                </li>
              }
            </> */}
          {/* )} */}
        </ul>
      </div>
    </>
  );
};

export default OpportunitiesReadHeadTag;
