import { ACTIVATE_HOW_IT_WORKS_OPTIONS, HOW_IT_WORKS_APPROVALS_EXPORT, HOW_IT_WORKS_APPROVALS_LIST, HOW_IT_WORKS_APPROVALS_VIEW, HOW_IT_WORKS_APPROVE, HOW_IT_WORKS_CREATE, HOW_IT_WORKS_DELETE, HOW_IT_WORKS_EDIT, HOW_IT_WORKS_LIST, HOW_IT_WORKS_MAIN_EXPORT, HOW_IT_WORKS_READ, HOW_IT_WORKS_REJECT, HOW_IT_WORKS_SUBMIT, HOW_IT_WORKS_VERSION_EXPORT, HOW_IT_WORKS_VERSION_LIST, HOW_IT_WORKS_VERSION_VIEW } from "./types";



export const addHowItWorksAction = (data, onSuccess, onError) => {
    return {
        type: HOW_IT_WORKS_CREATE,
        data,
        onSuccess,
        onError,
    };
};
export const approvalsHowItWorksListAction = (search, page, onSuccess, onError) => {
    return {
        type: HOW_IT_WORKS_APPROVALS_LIST,
        search,
        page,
        onSuccess,
        onError,
    };
};
export const approvalsViewHowItWorksAction = (id, onSuccess, onError) => {
    return {
        type: HOW_IT_WORKS_APPROVALS_VIEW,
        id,
        onSuccess,
        onError,
    };
};
export const approveHowItWorksAction = (id, onSuccess, onError) => {
    return {
        type: HOW_IT_WORKS_APPROVE,
        id,
        onSuccess,
        onError,
    };
};
export const rejectHowItWorksAction = (id, onSuccess, onError) => {
    return {
        type: HOW_IT_WORKS_REJECT,
        id,
        onSuccess,
        onError,
    };
};
export const viewHowItWorksListAction = (page, onSuccess, onError) => {
    return {
        type: HOW_IT_WORKS_LIST,
        page,
        onSuccess,
        onError,
    };
};
export const viewHowItWorksAction = (id, onSuccess, onError) => {
    return {
        type: HOW_IT_WORKS_READ,
        id,
        onSuccess,
        onError,
    };
};

export const editHowItWorksAction = (id, data, onSuccess, onError) => {
    return {
        type: HOW_IT_WORKS_EDIT,
        id,
        data,
        onSuccess,
        onError,
    };
};
export const deleteHowItWorksAction = (id, onSuccess, onError) => {
    return {
        type: HOW_IT_WORKS_DELETE,
        id,
        onSuccess,
        onError,
    };
};

export const versionListHowItWorksAction = (data, page, onSuccess, onError) => {
    return {
        type: HOW_IT_WORKS_VERSION_LIST,
        data,
        page,
        onSuccess,
        onError,
    };
};
export const versionViewHowItWorksAction = (data, onSuccess, onError) => {
    return {
        type: HOW_IT_WORKS_VERSION_VIEW,
        data,
        onSuccess,
        onError,
    };
};
export const activateHowItWorksAction = (id, onSuccess, onError) => {
    return {
        type: ACTIVATE_HOW_IT_WORKS_OPTIONS,
        id,
        onSuccess,
        onError,
    };
};


export const howItWorksMainExportAction = (onSuccess, onError) => {
    return {
        type: HOW_IT_WORKS_MAIN_EXPORT,
        onSuccess,
        onError,
    };
};
export const howItWorksApprovalsExportAction = (onSuccess, onError) => {
    return {
        type: HOW_IT_WORKS_APPROVALS_EXPORT,
        onSuccess,
        onError,
    };
};
export const howItWorksVersionExportAction = (onSuccess, onError) => {
    return {
        type: HOW_IT_WORKS_VERSION_EXPORT,
        onSuccess,
        onError,
    };
};
export const howItWorksCategorySubmit = (id, onSuccess, onError) => {
    return {
        type: HOW_IT_WORKS_SUBMIT,
        id,
        onSuccess,
        onError,
    };
};  
