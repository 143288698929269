import React, { useState } from "react";
import ApprovalsBtn from "../../../../components/buttons/approvalsBtn";
import CreateBtn from "../../../../components/buttons/createBtn";
import ExportBtn from "../../../../components/buttons/exportBtn";
import FilterBtn from "../../../../components/buttons/filterBtn";
import VersionHistoryBtn from "../../../../components/buttons/versionHistoryBtn";
import HomeFilter from "../../../../components/filter/static-pages filter/homeFilter";
import InnerLayout from "../../../../components/layouts/innerLayout/innerLayout";
import HomeTableContent from "../../../../components/static-pages/home-page/tables/homeTableContent";
import { homeMainExportAction } from "../../../../redux/actions/homeActions";


const HomeTable = () => {
  const [showFilter, setShowFilter] = useState(false);
  const [search, setSearch] = useState();
  const [page, setPage] = useState(0);
  const handleFilterClose = () => {
    setShowFilter(false);
  };
  const handleFilterOpen = () => {
    setShowFilter(true);
  };
  const handleSearch = (value) => {
    setSearch({ value: value });
    setPage(0)
  };
  const handlePage = (type) => {
    if (type === "+") {
      setPage((prev) => prev + 1)
    }
    else {
      setPage((prev) => prev - 1)
    }
  }
  const buttonList = [
    <ApprovalsBtn link='/static-pages/home/approvals' />,
    <VersionHistoryBtn link='/static-pages/home/version-history' />,
    <CreateBtn link='/static-pages/home/create' />,
    <ExportBtn dispatchAction={homeMainExportAction} />,
    <FilterBtn filterFn={handleFilterOpen} />,
  ];
  return (
    <InnerLayout buttons={buttonList}>
      <HomeFilter
      title="Enter ID"
        filter={handleSearch}
        showFilter={showFilter}
        closeFilter={handleFilterClose}
      />
      <HomeTableContent page={page} changePage={handlePage} search={search} />
    </InnerLayout>
  );
};

export default HomeTable;
