import React, { useState } from 'react'
import CreateBtn from '../../../../components/buttons/createBtn'
import ExportBtn from '../../../../components/buttons/exportBtn'
import FilterBtn from '../../../../components/buttons/filterBtn'
import VersionHistoryBtn from '../../../../components/buttons/versionHistoryBtn'
import AdminUserFilter from '../../../../components/filter/adminUserFilter'
import FaqFilter from '../../../../components/filter/static-pages filter/faqFilter'
import VersionTablesFilter from '../../../../components/filter/versionTableFilter'
import InnerLayout from '../../../../components/layouts/innerLayout/innerLayout'
import DocumentApprovalTableContent from '../../../../components/static-pages/document/tables/documentApprovalTableContent'
import FaqApprovalTableContent from '../../../../components/static-pages/faqs/tables/faqApprovalTableContent'
import ReferApprovalTableContent from '../../../../components/static-pages/referAndEarn/tables/referApprovalTableContent'
import { documentApprovalsExport } from '../../../../redux/actions/documentAction'
import { faqApprovalsExport } from '../../../../redux/actions/faqAction'
import { referApprovalsExport } from '../../../../redux/actions/referAction'

const DocumentApprovalTable = () => {
  const [page, setPage] = useState(0);
  const [showFilter, setShowFilter] = useState(false);
  const [search, setSearch] = useState({ value: "" });
  const handleFilterClose = () => {
    setShowFilter(false);
  };
  const handleFilterOpen = () => {
    setShowFilter(true);
  };
  const handleSearch = (value) => {
    setSearch({ value: value });
    setPage(0)
  };
  const handlePage = (type) => {
    if (type === "+") {
      setPage((prev) => prev + 1)
    }
    else {
      setPage((prev) => prev - 1)
    }
  }
  const buttonList = [
    <VersionHistoryBtn link='/static-pages/dashboard/version-history' />,
    <CreateBtn link='/static-pages/dashboard/create' />,
    <ExportBtn dispatchAction={documentApprovalsExport} />,
    <FilterBtn filterFn={handleFilterOpen}
    />
  ]
  return (
    <InnerLayout buttons={buttonList}>
      <VersionTablesFilter
        title='Dashboard Id'
        placeholder='Enter Dashboard Id'
        filter={handleSearch}
        showFilter={showFilter}
        closeFilter={handleFilterClose}
      />
      <DocumentApprovalTableContent page={page} changePage={handlePage} search={search} />
    </InnerLayout>
  )
}

export default DocumentApprovalTable;  