import React, { useRef, useState, useEffect } from "react";
import DeleteBtn from "../../../../components/buttons/deleteBtn";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import InnerContainer from "../../../../components/layouts/innerContainer/innerContainer";
import InnerLayout from "../../../../components/layouts/innerLayout/innerLayout";
import "../wishlist.css";
import SelectFields from "../../../../components/form/selectFields/selectFields";
import SaveBtn from "../../../../components/buttons/saveBtn";
import { createWishlist, viewWishListFindAll, wishlistSubmitAction } from "../../../../redux/actions/wishlistActions";
import successToast from "../../../../components/utilits/successToast";
import errorToast from "../../../../components/utilits/errorToast";
import { useDispatch } from 'react-redux';
import { opportunitiesNameListAction } from "../../../../redux/actions/opportunitiesActions";
import { useNavigate } from "react-router-dom";
import SubmitBtn from "../../../../components/buttons/submit";



const WishlistCreate = () => {
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const [allUsers, setAllUsers] = useState([]); //For dropdown of user name
    const [allOpportunities, setOpportunities] = useState([]); //For dropdown of user name
    const [subLoading, setSubLoading] = useState(false);
    const [adminId, setAdminId] = useState("");
    const [saved, setSaved] = useState(false)
    const formRef = useRef();
    const dispatch = useDispatch();
    const initialValues = {
        username: "",
        opportunity: "",
    };
    const loginSchema = Yup.object().shape({
        username: Yup.string().trim().required("Required"),
        opportunity: Yup.string().trim().required("Required"),
    });
    const handleSubmit = () => {
        if (formRef.current) {
            formRef.current.handleSubmit()
        }
    }

    useEffect((id) => {
        setLoading(true)
        dispatch(viewWishListFindAll(onSuccessDrop, onErrorDrop));
    }, [])

    const onSubmit = (values, { resetForm }) => {
        //  resetForm();
        console.log(values);
        setLoading(true)
        console.log(values.opportunity)
        dispatch(
            createWishlist({
                user_id: values.username,
                opportunity_id: values.opportunity
            },
                onSuccess,
                onError)
        );
    };
    const onSuccess = (data) => {
        console.log(data);
        setLoading(false)
        successToast(data.data.message)
        setAdminId(data.data.data.id)
        navigate(`/opportunity/wishlist/edit/${data.data.data.id}`)
        setSaved(true)
    };
    const onError = (data) => {
        setLoading(false)
        errorToast(data.message)
    };

    //For dropDwon fetch
    const onSuccessDrop = (data) => {
        const roleTypes = [];
        const opportunityType = [];
        console.log(data.data);
        data?.data.AllUsers.map((el, index) => {
            roleTypes[index] = { value: el.id, label: el.user_name };
        });
        setAllUsers(roleTypes);
    };

    const onErrorDrop = (data) => {
        console.log("ERROR");
    };

    //opp id
    const [opportunitiesList, setOpportunitiesList] = useState([])
    useEffect(() => {
        dispatch(opportunitiesNameListAction(onSuccessOpt, onErrorOpt));
    }, []);
    const onSuccessOpt = (data) => {
        const categoryList = [];
        data?.data.map((item, index) => {
            categoryList[index] = { value: item.id, label: `${item.title} id: ${item.id}` };
        });
        setOpportunitiesList(categoryList);
        console.log("SUCCESS");
    };
    const onErrorOpt = (data) => {
        console.log(data);
    };

    const wishlistSubmit = () => {
        setSubLoading(true);
        dispatch(
            wishlistSubmitAction(adminId, onAdminSubmitSucess, onAdminSubmitError)
        );
    };
    const buttonList = [
        <SaveBtn submitFn={handleSubmit} />,
    ];
    function onAdminSubmitSucess(data) {
        successToast(data.message);
        setSubLoading(false);

    }
    function onAdminSubmitError(data) {
        setSubLoading(false);
        errorToast(data.message);
    }
    return (
        <InnerLayout buttons={buttonList}>
            <div className="wishlist-container">
                <InnerContainer>
                    <div className="  ps-4 ps-lg-4 ms-3">
                        <Formik
                            innerRef={formRef}
                            initialValues={initialValues}
                            onSubmit={onSubmit}
                            validationSchema={loginSchema}
                        >
                            <Form className='wishlist-form-width'>
                                <SelectFields
                                    label={"User Name"}
                                    placeholder={"User name here"}
                                    name={"username"}
                                    options={allUsers}
                                    id={"username"}
                                />
                                <SelectFields
                                    label={"Opportunity"}
                                    placeholder={"Opportunity here"}
                                    name={"opportunity"}
                                    options={opportunitiesList}
                                    id={"opportunity"}
                                />
                            </Form>
                        </Formik>
                    </div>
                </InnerContainer>
            </div>
        </InnerLayout>
    );
};

export default WishlistCreate;

export const username = [
    { value: "mohan", label: "mohan" },
    { value: "Abijith", label: "Abijith" },
];
export const opportunityType = [
    { value: "share", label: "share" },
    { value: "type2", label: "type2" },
];

