import {
  ACTIVATE_USER_REFERRAL_OPTIONS,
  FETCH_OPPORTUNITES_ID,
  USER_REFERRAL_APPROVALS_EXPORT,
  USER_REFERRAL_APPROVALS_LIST,
  USER_REFERRAL_APPROVALS_VIEW,
  USER_REFERRAL_APPROVE,
  USER_REFERRAL_CREATE,
  USER_REFERRAL_DELETE,
  USER_REFERRAL_EDIT,
  USER_REFERRAL_LIST,
  USER_REFERRAL_MAIN_EXPORT,
  USER_REFERRAL_READ,
  USER_REFERRAL_REJECT,
  USER_REFERRAL_SUBMIT,
  USER_REFERRAL_VERSION_EXPORT,
  USER_REFERRAL_VERSION_LIST,
  USER_REFERRAL_VERSION_VIEW,
} from "./types";

export const addUserReferralAction = (id, data, onSuccess, onError) => {
  return {
    type: USER_REFERRAL_CREATE,
    id,
    data,
    onSuccess,
    onError,
  };
};
export const approvalsUserReferralListAction = (
  data,
  page,
  onSuccess,
  onError
) => {
  return {
    type: USER_REFERRAL_APPROVALS_LIST,
    data,
    page,
    onSuccess,
    onError,
  };
};
export const approvalsViewUserReferralAction = (id, onSuccess, onError) => {
  return {
    type: USER_REFERRAL_APPROVALS_VIEW,
    id,
    onSuccess,
    onError,
  };
};
export const approveUserReferralAction = (id, onSuccess, onError) => {
  return {
    type: USER_REFERRAL_APPROVE,
    id,
    onSuccess,
    onError,
  };
};
export const rejectUserReferralAction = (id, onSuccess, onError) => {
  return {
    type: USER_REFERRAL_REJECT,
    id,
    onSuccess,
    onError,
  };
};
export const viewUserReferralListAction = (data, page, onSuccess, onError) => {
  return {
    type: USER_REFERRAL_LIST,
    data,
    page,
    onSuccess,
    onError,
  };
};
export const viewUserReferralAction = (id, onSuccess, onError) => {
  return {
    type: USER_REFERRAL_READ,
    id,
    onSuccess,
    onError,
  };
};

export const editUserReferralAction = (id, data, onSuccess, onError) => {
  return {
    type: USER_REFERRAL_EDIT,
    id,
    data,
    onSuccess,
    onError,
  };
};
export const deleteUserReferralAction = (id, onSuccess, onError) => {
  return {
    type: USER_REFERRAL_DELETE,
    id,
    onSuccess,
    onError,
  };
};

export const versionListUserReferralAction = (
  data,
  page,
  onSuccess,
  onError
) => {
  return {
    type: USER_REFERRAL_VERSION_LIST,
    data,
    page,
    onSuccess,
    onError,
  };
};
export const versionViewUserReferralAction = (id, onSuccess, onError) => {
  return {
    type: USER_REFERRAL_VERSION_VIEW,
    id,
    onSuccess,
    onError,
  };
};
export const activateUserReferralAction = (id, onSuccess, onError) => {
  return {
    type: ACTIVATE_USER_REFERRAL_OPTIONS,
    id,
    onSuccess,
    onError,
  };
};

export const UserReferralMainExportAction = (onSuccess, onError) => {
  return {
    type: USER_REFERRAL_MAIN_EXPORT,
    onSuccess,
    onError,
  };
};
export const UserReferralApprovalsExportAction = (onSuccess, onError) => {
  return {
    type: USER_REFERRAL_APPROVALS_EXPORT,
    onSuccess,
    onError,
  };
};
export const UserReferralVersionExportAction = (onSuccess, onError) => {
  return {
    type: USER_REFERRAL_VERSION_EXPORT,
    onSuccess,
    onError,
  };
};
export const UserReferralCategorySubmit = (id, onSuccess, onError) => {
  return {
    type: USER_REFERRAL_SUBMIT,
    id,
    onSuccess,
    onError,
  };
};
export const fetchOpportunitiesID = (id, onSuccess, onError) => {
  return {
    id,
    type: FETCH_OPPORTUNITES_ID,
    onSuccess,
    onError,
  };
};
