import React, { useEffect, useState } from "react";
import "./login.css";
import upicdeLogo from "../../assets/images/Upcidelogo.svg";
import { Formik, Form, Field } from "formik";
import eyeOn from "../../assets/icons/eye-on.svg";
import eyeOff from "../../assets/icons/eye-off.svg";
import * as Yup from "yup";
import ErrorMsg from "../../components/form/errorMsg";
import { useDispatch } from "react-redux";
import * as loginAction from "../../redux/actions/loginAction";
import { useNavigate, useParams } from "react-router-dom";
import { Spinner } from "react-bootstrap";
import errorToast from "../../components/utilits/errorToast";
import successToast from "../../components/utilits/successToast";
const Login = () => {
  const [passwordVisible, setPasswordVisible] = useState();
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();

  const initialValues = {
    email: "",
    password: "",
  };
  const loginSchema = Yup.object().shape({
    email: Yup.string().trim().email().required("Required"),
    password: Yup.string().trim().required("Required"),
  });
  const onSubmit = (values, { resetForm }) => {
    console.log(values);
    setLoading(true)
    dispatch(
      loginAction.login(values.email, values.password, onSuccess, onError)
    );
  };
  const onSuccess = (data) => {
    console.log(data);
    setLoading(false)
    successToast("Logged Successfully")
    navigate('/user/user-list')
  };

  const onError = (data) => {
    setLoading(false)
    errorToast(data.message)
  };


  return (
    <div className={`container-fluid fn_Montserrat`}>
      <div className={`row login_bg_image vh-100`}>
        <div className={` col-12 col-lg-6 col-xl-7`}>
          <div
            className={`text-center h-100 d-flex flex-column align-items-center justify-content-center`}
          >
            <img
              className="login_upicde__logo"
              src={upicdeLogo}
              alt="upcide-logo"
            />

            <h1 className={`fs_26  mt-4 secondary_color fn_Raleway fw_500`}>
              Admin Login
            </h1>
          </div>
        </div>

        <div
          className={`col-12 col-lg-6 col-xl-5 d-flex align-items-center  login_form_main__container`}
        >
          <section className={`container`}>
            <div className="col-12 col-10 col-md-8 col-lg-10 col-xl-10 col-xxl-9  mx-auto">
              <h2 className={`tertiary_color fs_24 mb-3`}>Log in</h2>
              <Formik
                initialValues={initialValues}
                onSubmit={onSubmit}
                validationSchema={loginSchema}
              >
                <Form>
                  <div>
                    <label
                      htmlFor="email"
                      className=" ms-2  mb-1 text-white fw_500 fs_14"
                    >
                      Email Address
                    </label>
                    <Field name={"email"}>
                      {({ field, meta }) => (
                        <input
                          {...field}
                          placeholder={"Enter your email address"}
                          id={"email"}
                          className={
                            meta.touched && meta.error
                              ? `w-100   login_input login_input_error__border rounded-1   focus_outline__none`
                              : ` w-100    login_input login_input__border rounded-1 focus_outline__none`
                          }
                        />
                      )}
                    </Field>
                    <ErrorMsg name="email" />
                  </div>
                  <div>
                    <label
                      htmlFor="password"
                      className="ms-2 mb-1 text-white fw_500 fs_14"
                    >
                      Password
                    </label>
                    <Field name={"password"}>
                      {({ field, meta }) => (
                        <div className="position-relative">
                          <input
                            autoComplete={"true"}
                            type={passwordVisible ? "text" : "password"}
                            {...field}
                            placeholder={"Enter your password"}
                            id={"password"}
                            className={
                              meta.touched && meta.error
                                ? ` w-100   login_input   login_input_error__border rounded-1 focus_outline__none`
                                : ` w-100   login_input login_input__border rounded-1 focus_outline__none`
                            }
                          />{" "}
                          <img
                            className="login_password_eye__icon cursor_pointer"
                            onClick={() => setPasswordVisible(!passwordVisible)}
                            src={passwordVisible ? eyeOn : eyeOff}
                            alt="show password or not"
                          />
                        </div>
                      )}
                    </Field>
                    <ErrorMsg name="password" />
                  </div>

                  <button
                    type="submit"
                    disabled={loading}
                    className="border-0 fs_12  mt-4 login_btn w-100 tertiary_bg rounded bg fw_700"
                  >
                    {loading ? <Spinner animation="border" as={"span"} size="sm" variant="secondary" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </Spinner> : "LOG IN"}

                  </button>
                </Form>
              </Formik>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};

export default Login;
