


import React from "react";

const UploadIcon = ({ fill = "#666", width = 10, height = 16 }) => {
  return (
<svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 39.374 35">
  <path id="Icon_material-open-in-browser" data-name="Icon material-open-in-browser" d="M39.5,6H8.875A4.374,4.374,0,0,0,4.5,10.375v26.25A4.374,4.374,0,0,0,8.875,41h8.75V36.625H8.875V14.75H39.5V36.625H30.75V41H39.5a4.388,4.388,0,0,0,4.375-4.375V10.375A4.374,4.374,0,0,0,39.5,6ZM24.187,19.125l-8.75,8.75H22V41h4.375V27.875h6.562Z" transform="translate(-4.5 -6)" fill={fill}/>
</svg>
  );
};

export default UploadIcon;

