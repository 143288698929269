import { CONTACT_APPROVALS_EXPORT, CONTACT_APPROVALS_LIST, CONTACT_APPROVALS_VIEW, CONTACT_APPROVE, CONTACT_CREATE, CONTACT_DELETE, CONTACT_EDIT, CONTACT_LIST, CONTACT_MAIN_EXPORT, CONTACT_READ, CONTACT_REJECT, CONTACT_SUBMIT, CONTACT_VERSION_EXPORT, CONTACT_VERSION_LIST, CONTACT_VERSION_VIEW } from "./types";



export const addContactAction = (data, onSuccess, onError) => {
    return {
        type: CONTACT_CREATE,
        data,
        onSuccess,
        onError,
    };
};
export const approvalsContactListAction = (data, page, onSuccess, onError) => {
    return {
        type: CONTACT_APPROVALS_LIST,
        data,
        page,
        onSuccess,
        onError,
    };
};
export const approvalsViewContactAction = (id, onSuccess, onError) => {
    return {
        type: CONTACT_APPROVALS_VIEW,
        id,
        onSuccess,
        onError,
    };
};
export const approveContactAction = (ContactId, onSuccess, onError) => {
    return {
        type: CONTACT_APPROVE,
        ContactId,
        onSuccess,
        onError,
    };
};
export const rejectContactAction = (id, onSuccess, onError) => {
    return {
        type: CONTACT_REJECT,
        id,
        onSuccess,
        onError,
    };
};
export const viewContactListAction = (data, page, onSuccess, onError) => {
    return {
        type: CONTACT_LIST,
        data,
        page,
        onSuccess,
        onError,
    };
};
export const viewContactAction = (id, onSuccess, onError) => {
    return {
        type: CONTACT_READ,
        id,
        onSuccess,
        onError,
    };
};

export const editContactAction = (id, data, onSuccess, onError) => {
    return {
        type: CONTACT_EDIT,
        id,
        data,
        onSuccess,
        onError,
    };
};
export const deleteContactAction = (id, onSuccess, onError) => {
    return {
        type: CONTACT_DELETE,
        id,
        onSuccess,
        onError,
    };
};


export const versionListContactAction = (data, page, onSuccess, onError) => {
    return {
        type: CONTACT_VERSION_LIST,
        data,
        page,
        onSuccess,
        onError,
    };
};
export const versionViewContactAction = (id, onSuccess, onError) => {
    return {
        type: CONTACT_VERSION_VIEW,
        id,
        onSuccess,
        onError,
    };
};



export const contactMainExportAction = (onSuccess, onError) => {
    return {
        type: CONTACT_MAIN_EXPORT,
        onSuccess,
        onError,
    };
};
export const contactApprovalsExportAction = (onSuccess, onError) => {
    return {
        type: CONTACT_APPROVALS_EXPORT,
        onSuccess,
        onError,
    };
};
export const contactVersionExportAction = (onSuccess, onError) => {
    return {
        type: CONTACT_VERSION_EXPORT,
        onSuccess,
        onError,
    };
};
export const contactSubmit = (id, onSuccess, onError) => {
    return {
        type: CONTACT_SUBMIT,
        id,
        onSuccess,
        onError,
    };
};  