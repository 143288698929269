import React, { useRef } from "react";
import Modal from "react-bootstrap/Modal";
import CloseIcon from "../../../../assets/icons/closeIcon";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import "./opportunitiesmodals.css";
import InputField from "../../../form/inputFields/inputField";
import SelectFields from "../../../form/selectFields/selectFields";
import ArrowDownIcon from "../../../../assets/icons/arrowdown";

const Payouts = ({ show, handleClose, formikValues, id, reasonUpdator }) => {
  const formRef = useRef();
  const amountRegex = /^[0-9]*$/;
  const initialValues = {
    date: id === 0 || id ? formikValues[id]?.date : "",
    amount: id === 0 || id ? formikValues[id]?.amount : "",
    paid: id === 0 || id ? formikValues[id]?.paid : "",
    paid_on_date: id === 0 || id ? formikValues[id]?.paid_on_date : "",
    amount_paid: id === 0 || id ? formikValues[id]?.amount_paid : "",
  };
  const loginSchema = Yup.object().shape({
    date: Yup.date().required("Required"),
    amount: Yup.string().trim().required("Required"),
    paid: Yup.string().trim(),
    paid_on_date: Yup.date(),
    amount_paid: Yup.string(),
  });
  const handleSubmit = () => {
    if (formRef.current) {
      formRef.current.handleSubmit();
    }
  };
  const onSubmit = (values, { resetForm }) => {
    resetForm();
    reasonUpdator(id, values)
    handleClose()
  };

  return (
    <Modal
      centered
      show={show}
      onHide={handleClose}
      dialogClassName={`oppoptunities_modals_body mx-auto `}
      contentClassName={"oppoptunities_modals_body_content  p-2"}
    >
      <Modal.Body>
        <>
          <div className="d-flex justify-content-between  ">
            <h2 className="green_color fs_15 fn_Montserrat fw_600 pt-1">
              PAYOUTS
            </h2>
            <button
              type="button"
              onClick={handleClose}
              className="light_grey_color  fs_15 fn_Montserrat bg-transparent border-0 fw_600 "
            >
              <span className="pt-1">DISMISS</span>
              <span className="ms-2">
                <CloseIcon width={13} height={13} fill="#A5A5A5" />
              </span>
            </button>
          </div>
          <Formik
            innerRef={formRef}
            initialValues={initialValues}
            onSubmit={onSubmit}
            validationSchema={loginSchema}
            enableReinitialize={true}
          >
            <Form className="mt-3">
              <InputField
                id={"date"}
                label={"Date"}
                type={"date"}
                placeholder={"Enter Date"}
                name={"date"}
              />
              <InputField
                id={"amount"}
                label={"Amount"}
                type={"text"}
                placeholder={"Enter Amount"}
                name={"amount"}
              />
              <SelectFields
                Icon={<ArrowDownIcon width={20} height={20} fill={"#A5A5A5"} />}
                label={"Paid?"}
                default1={formikValues[id]?.paid ? { label: "Yes", value: true } : { label: "No", value: false }}
                placeholder={"Select Yes / No"}
                name={"paid"}
                options={selectPriority}
                id={"paid"}
              />
              <InputField
                id={"paidDate"}
                label={"Paid On Date"}
                type={"date"}
                placeholder={"Enter Date"}
                name={"paid_on_date"}
              />
              <InputField
                id={"paidamount"}
                label={"Amount Paid"}
                type={"text"}
                placeholder={"Enter Amount"}
                name={"amount_paid"}
              />
              <div className="d-flex justify-content-end mt-4">
                <button
                  type="submit"
                  className="secondary_color fs_15  fn_Montserrat fw_600 border-0 tertiary_bg px-4 px-lg-5 py-2 py-lg-2 rounded-2"
                >
                  {id === null ? "CREATE" : "UPDATE"}
                </button>
              </div>
            </Form>
          </Formik>
        </>
      </Modal.Body>
    </Modal>
  );
};
export default Payouts;
export const selectPriority = [
  { value: true, label: "Yes" },
  { value: false, label: "No" },
];
