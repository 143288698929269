import { CREATE_MEMBER_TRADE_OPTIONS, CREATE_MemberTrade_OPTIONS, MEMBER_TRADE_APPROVALS_LIST, MEMBER_TRADE_APPROVALS_VIEW, MEMBER_TRADE_APPROVE, MEMBER_TRADE_CREATE, MEMBER_TRADE_DELETE, MEMBER_TRADE_EDIT, MEMBER_TRADE_LIST, MEMBER_TRADE_READ, MEMBER_TRADE_REJECT, MEMBER_TRADE_VERSION_LIST, MEMBER_TRADE_VERSION_VIEW, MEMBER_EXPORT, MEMBER_APPROVALS_EXPORT, MEMBER_VERSION_EXPORT, MEMBER_SUBMIT } from "./types";

export const viewAllMemberTrade = (data, pageNo, onSuccess, onError) => {
    return {
        type: MEMBER_TRADE_LIST,
        data,
        pageNo,
        onSuccess,
        onError,
    };
};
export const readApprovalMemberTrade = (id, onSuccess, onError) => {
    return {
        type: MEMBER_TRADE_APPROVALS_VIEW,
        id,
        onSuccess,
        onError,
    };
}
export const readMemberTrade = (id, onSuccess, onError) => {
    return {
        type: MEMBER_TRADE_READ,
        id,
        onSuccess,
        onError,
    };
}
export const versionTableMemberTrade = (data, pageNo, onSuccess, onError) => {
    return {
        type: MEMBER_TRADE_VERSION_LIST,
        data,
        pageNo,
        onSuccess,
        onError,
    };
}
export const deleteMemberTrade = (id, onSuccess, onError) => {
    return {
        type: MEMBER_TRADE_DELETE,
        id,
        onSuccess,
        onError,
    };
}
export const memberTradeVersionHistoryview = (id, onSuccess, onError) => {
    return {
        type: MEMBER_TRADE_VERSION_VIEW,
        id,
        onSuccess,
        onError,
    };
}
export const memberTradeApprovalsTable = (data, pageNo = 0, onSuccess, onError) => {
    return {
        type: MEMBER_TRADE_APPROVALS_LIST,
        data,
        pageNo,
        onSuccess,
        onError,
    };
}


export const memberTradeCreate = (
    data,
    onSuccess,
    onError
) => {
    return {
        type: MEMBER_TRADE_CREATE,
        data,
        onSuccess,
        onError,
    };
};

export const memberTradeCreateOptions = (onSuccess, onError) => {
    return {
        type: CREATE_MEMBER_TRADE_OPTIONS,
        onSuccess,
        onError,
    };
};
export const editMemberTrade = (id, data, onSuccess, onError) => {
    return {
        type: MEMBER_TRADE_EDIT,
        id,
        data,
        onSuccess,
        onError,
    }
};                     

export const approveMemberTrade = (id, onSuccess, onError) => {
    return {
        type: MEMBER_TRADE_APPROVE,
        id,
        onSuccess,
        onError,
    };
}

export const rejectMemberTrade = (id, onSuccess, onError) => {
    return {
        type: MEMBER_TRADE_REJECT,
        id,
        onSuccess,
        onError,
    };
}


export const memberTableExport = (onSuccess, onError) => {
    return {
        type: MEMBER_EXPORT,
        onSuccess,
        onError,
    };
};
export const memberApprovalsExport = (onSuccess, onError) => {
    return {
        type: MEMBER_APPROVALS_EXPORT,
        onSuccess,
        onError,
    };
};
export const memberVersionExport = (onSuccess, onError) => {
    return {
        type: MEMBER_VERSION_EXPORT,
        onSuccess,
        onError,
    };
};  



export const memberSubmit = (id, onSuccess, onError) => {
    return {
        type: MEMBER_SUBMIT,
        id,
        onSuccess,
        onError,
    };
};  