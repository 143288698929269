import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import DeleteBtn from "../../../components/buttons/deleteBtn";
import OpportunityBtn from "../../../components/buttons/opportunityBtn";
import InnerContainer from "../../../components/layouts/innerContainer/innerContainer";
import InnerLayout from "../../../components/layouts/innerLayout/innerLayout";
import NotifyMeTableContent from "../../../components/opportunity/notifyMe/tables/notifyMeTableContent";
import OpportunitiesNotifyMeContent from "../../../components/opportunity/opportunties/tables/OpportunitiesNotifyTableContent";
import OpportunitiesWishlist from "../../../components/opportunity/opportunties/tables/opportunitiesWishlist";
import WishlistTableContent from "../../../components/opportunity/wishlist/tables/wishlistTableContent";
import { opportunitiesAnalyticsAction } from "../../../redux/actions/opportunitiesActions";
import "./opportunities.css";
const OpportunitiesAnalytics = () => {
  const dispatch = useDispatch();
  const [analyticsWishlistData, setAnalyticsWishlistData] = useState([])
  const [analyticsNotifyMeData, setAnalyticsNotifyMeData] = useState([])
  const [analyticsData, setAnalyticsData] = useState([])
  const params = useParams();
  useEffect(() => {
    dispatch(opportunitiesAnalyticsAction(params?.id, onSuccess, onError));
  }, []);
  console.log(params.id);
  const onSuccess = (data) => {
    setAnalyticsData(data?.data);
    setAnalyticsWishlistData(data?.data?.wishlist);
    setAnalyticsNotifyMeData(data.data?.notify_me
    );
  };
  console.log(analyticsWishlistData);
  console.log(analyticsNotifyMeData);
  const onError = (data) => {
    console.log(data);
  };
  const buttonList = [<OpportunityBtn link={`/opportunity/opportunities/view/${params.id}`} />

    // <DeleteBtn />
  ];
  return (
    <InnerLayout buttons={buttonList}>
      <InnerContainer containerClassName="oppor_edit_inner__container mb-3 dark_title_color">
        <div className="py-2 px-0 px-sm-4">
          <div className="d-flex mx-3 mx-sm-2 justify-content-between flex-column flex-sm-row align-items-sm-center mb-4 mb-sm-0">
            <div className="mb-4 mb-sm-0">
              <h5 className="fs_14 fw_500 mb-0">Opportunity ID</h5>
              <span className="secondary_color fs_14 fn_Nunito fw_500">
                {params.id}
              </span>
            </div>
            <div className="d-flex align-items-center align-self-center gap-4 me-0 me-sm-3">
              <div className="d-flex flex-column text-center">
                <span className="secondary_color fw_500 fs_14 fn_Nunito">
                  {analyticsData.wishlistCount}
                </span>
                <span className="dark_title_color fw_500 fs_14">Wishlist</span>
              </div>
              <div className="vr my-1"></div>
              <div className="d-flex flex-column text-center">
                <span className="secondary_color fw_500 fs_14 fn_Nunito">
                  {analyticsData.notifyCount}
                </span>
                <span className="dark_title_color fw_500 fs_14">Notify Me</span>
              </div>{" "}
              <div className="vr my-1"></div>
              <div className="d-flex flex-column text-center">
                <span className="secondary_color fw_500 fs_14 fn_Nunito">
                  {analyticsData.referral}
                </span>
                <span className="dark_title_color fw_500 fs_14">Referrals</span>
              </div>
            </div>
          </div>
          <div className="d-flex mx-0 mx-sm-2 flex-column align-items-center mt-2">
            <span className="align-self-start ms-3 dark_title_color my-1 fw_500 fs_14">
              Wishlist
            </span>
            <OpportunitiesWishlist
              wishlist={analyticsWishlistData}
              containerMargin="0rem 0rem 1rem 0rem"
              tableContainerClassName="opportunities_analytics_table__container"
              containerWidth="100%"
            />
            <span className="align-self-start ms-3 my-1 dark_title_color fw_500 fs_14">
              Notify Me
            </span>
            <OpportunitiesNotifyMeContent
              notifyMe={analyticsNotifyMeData}
              containerMargin="0rem 0rem 1rem 0rem"
              tableContainerClassName="opportunities_analytics_table__container"
              containerWidth="100%"
            />
          </div>
        </div>
      </InnerContainer>{" "}
    </InnerLayout>
  );
};
export default OpportunitiesAnalytics;
