import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "../../admin/tables/admintable.css";
import errorToast from "../../utilits/errorToast";
import { TableContainer } from "../../table/tableContainer";
import TablePagination from "../../table/tablePagination";
import LoadingSpinnerTable from "../../utilits/loadingSpinnerTable";
import {
  tableOurAdvisorHeader,
  OurAdvisorTableData,
} from "../../table/tableData";
import { useDispatch } from "react-redux";
import {
  deleteAdvisorAction,
  viewAdvisorListAction,
} from "../../../redux/actions/ourAdvisorAction";
import moment from "moment";
import DeleteModal from "../../modals/deleteModal";
import successToast from "../../utilits/successToast";
import usePermissionChecker from "../../utilits/usePermissionChecker";
import LoadAndError from "../../utilits/LoadAndError";

const OurAdvisorsTableContent = ({ page, search, changePage }) => {
  const access = usePermissionChecker("maker");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);
  const [show, setShow] = useState(false);
  const dispatch = useDispatch();
  const [deleteData, setDeleteData] = useState({ name: "", id: "" });
  const navigate = useNavigate();
  const [pageData, setPageData] = useState({ noOfItems: 0, noOfPages: 1 })
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [advisorTableData, setAdvisorTableData] = useState([]);


  const handleDelete = () => {
    setLoadingDelete(true);
    dispatch(
      deleteAdvisorAction(deleteData.id, onDeleteSuccess, onDeleteError)
    );
  };
  const onDeleteSuccess = (data) => {
    console.log(data);
    successToast(data.message);
    setLoadingDelete(false);
    setShow(false);
  };
  const onDeleteError = (data) => {
    errorToast(data.message);
    setLoadingDelete(false);
  };
  console.log(search);
  useEffect(() => {
    setLoading(true);
    dispatch(viewAdvisorListAction(
      {
        search: {
          advisors_id: search?.value?.advisorId,
          name: search?.value?.name,
          designation: search?.value?.designation,
          priority: search?.value?.priority

        }
      },
      page,
      onSuccess,
      onError
    ));
  }, [search, page, loadingDelete]);
  const onSuccess = (data) => {
    setAdvisorTableData(data?.data?.data);
    setPageData({
      noOfItems: data.data.count,
      noOfPages: data.data.pages
    })

    setLoading(false);
  };

  console.log(advisorTableData);
  const onError = (data) => {
    errorToast(data.message);
    setLoading(false);
  };

  function handlePagination(type) {
    if (type === "+") {
      if (page + 1 < pageData.noOfPages) changePage("+");
    }
    else if (type === "-")
      if (page > 0) changePage("-");
  }
  function handlePagination(type) {
    if (type === "+") {
      if (page + 1 < pageData.noOfPages) changePage("+");
    }
    else if (type === "-")
      if (page > 0) changePage("-");
  }

  return (
    <TableContainer width="90%">
      <div className="adminuser_table__container">
        {loading && <LoadingSpinnerTable />}
        <table className={"adminuser_table"}>
          <thead className="adminuser_table__header">
            <tr className="adminuser_table__row">
              {tableOurAdvisorHeader.map((item) => {
                return (
                  <th
                    key={item + "x"}
                    className={`${item === "Status"
                      ? " position-sticky end-0 text-start"
                      : ""
                      } text-nowrap adminuser_table__heading`}
                  >
                    {item}
                  </th>
                );
              })}
            </tr>
          </thead>
          {loading === false && (
            <tbody className="adminuser_table__body">
              <LoadAndError loader={loading} error={error} status={advisorTableData.length === 0}>
                {advisorTableData.map((item, index) => {
                  return (
                    <tr key={item.id} className="adminuser_table__row">
                      <td className="text-nowrap dark_title_color adminuser_table__data ">
                        {page > 0 ? 12 * page + (index + 1) : index + 1}
                      </td>
                      <td className="text-nowrap secondary_color  adminuser_table__data ">
                        {item.id}
                      </td>
                      <td className="text-nowrap dark_title_color  adminuser_table__data">
                        {item.name}
                      </td>
                      <td className="text-nowrap dark_title_color adminuser_table__data">
                        {item.designation}
                      </td>
                      <td className="text-nowrap dark_title_color  adminuser_table__data">
                        {item.website_url}
                      </td>
                      <td className="text-nowrap adminuser_table_ellipsis  dark_title_color adminuser_table__data">
                        {item.description}
                      </td>
                      <td className="text-nowrap  dark_title_color adminuser_table__data">
                        {item.priority}
                      </td>
                      <td className="text-nowrap  dark_title_color adminuser_table__data">
                        {moment(
                          item?.our_advisor_checkkers?.[0]?.approved_date
                        ).format("DD-MM-YYYY HH:mm:ss")}
                      </td>
                      <td className="text-nowrap  dark_title_color adminuser_table__data">
                        {moment(item?.our_advisor_checkkers?.[0]?.updatedAt).format(
                          "DD-MM-YYYY HH:mm:ss"
                        )}
                      </td>

                      <td className="text-nowrap  position-sticky end-0 adminuser_table__data">
                        <div className="d-flex w-100  justify-content-start gap-2">
                          <Link
                            className="text-decoration-none primary_color"
                            to={{
                              pathname: `/static-pages/our-advisors/view/${item.id}`,
                            }}
                          >
                            View
                          </Link>
                          {
                            access && <>
                              {
                                item.editable &&
                                <>
                                  <span className="vr"></span>
                                  <Link
                                    className="text-decoration-none secondary_color"
                                    to={{
                                      pathname: `/static-pages/our-advisors/edit/${item.id}`,
                                    }}
                                  >
                                    Edit
                                  </Link>
                                  <span className="vr"></span>
                                  {item.lastActionApproved && (
                                    <>
                                  <button
                                    onClick={() => {
                                      setDeleteData({ name: item?.name, id: item.id });
                                      handleShow();
                                    }}
                                    className="text-decoration-none border-0 bg-transparent primary_color light_red_color"
                                  >
                                    Delete
                                  </button>
                                </>)}
                                </>
                              }

                            </>
                          }
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </LoadAndError>
            </tbody>
          )}
        </table>
      </div>
      <DeleteModal
        loader={loading}
        handleClose={handleClose}
        deleteFn={handleDelete}
        data={deleteData}
        show={show}
      />
      <TablePagination
        paginate={handlePagination}
        currentPage={page}
        pageData={pageData}
      />
    </TableContainer>
  );
};

export default OurAdvisorsTableContent;
