import React, { useState } from "react";
import ExportBtn from "../../../../components/buttons/exportBtn";
import FilterBtn from "../../../../components/buttons/filterBtn";
import AdminUserFilter from "../../../../components/filter/adminUserFilter";
import VersionTablesFilter from "../../../../components/filter/versionTableFilter";
import InnerLayout from "../../../../components/layouts/innerLayout/innerLayout";
import MemberTradeBuyInVersionTableContent from "../../../../components/opportunity/memberTradeBuyIn/tables/memberTradeBuyInVersionTableContent";
import OpportunityTypeVersionTableContent from "../../../../components/opportunity/opportunityType/tables/opportunityTypeVersionTableContent";
import { memberTradeBuyInVersionExport } from "../../../../redux/actions/memberTradeBuyInAction";
import { opprTypeVersionExport } from "../../../../redux/actions/opportunityTypeActions";

const MemberTradeBuyInVersionTable = () => {
  const [page, setPage] = useState(0);
  const [showFilter, setShowFilter] = useState(false);
  const [search, setSearch] = useState({ value: "" });
  const handleFilterClose = () => {
    setShowFilter(false);
  };
  const handleFilterOpen = () => {
    setShowFilter(true);
  };
  const handleSearch = (value) => {
    setSearch({ value: value });
    setPage(0);
  };
  const handlePage = (type) => {
    if (type === "+") {
      setPage((prev) => prev + 1);
    } else {
      setPage((prev) => prev - 1);
    }
  };
  console.log(search);
  const buttonList = [
    <ExportBtn dispatchAction={memberTradeBuyInVersionExport} />,
    <FilterBtn filterFn={handleFilterOpen} />,
  ];
  return (
    <InnerLayout buttons={buttonList}>
      <VersionTablesFilter
        title="Buy In Id"
        placeholder="Enter Buy In Id"
        filter={handleSearch}
        showFilter={showFilter}
        closeFilter={handleFilterClose}
      />
      <MemberTradeBuyInVersionTableContent
        page={page}
        changePage={handlePage}
        search={search}
      />
    </InnerLayout>
  );
};

export default MemberTradeBuyInVersionTable;
