import React from "react";
import { useNavigate } from "react-router-dom";
import usePermissionChecker from "../utilits/usePermissionChecker";
import { AdminButton } from "./versionHistoryBtn";
const ApprovalsBtn = ({ link = "/" }) => {
  const navigate = useNavigate();
  const access = usePermissionChecker("checker");
  return (
    access && (
      <AdminButton
        onClick={() => navigate(link)}
        color="#112F6A"
        padding="3px 15px"
      >
        <span className="pdt_1">APPROVALS</span>
      </AdminButton>
    )
  );
};

export default ApprovalsBtn;
