import React, { useRef, useEffect, useState } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import InnerLayout from "../../../../components/layouts/innerLayout/innerLayout";
import InnerContainer from "../../../../components/layouts/innerContainer/innerContainer";
import SelectFields from "../../../../components/form/selectFields/selectFields";
import { useNavigate, useParams } from "react-router-dom";
import SaveBtn from "../../../../components/buttons/saveBtn";
import DeleteBtn from "../../../../components/buttons/deleteBtn";
import InputField from "../../../../components/form/inputFields/inputField";
import ErrorMsg from "../../../../components/form/errorMsg";
import { createFaq, faqOptions, FaqOptions } from "../../../../redux/actions/faqAction";
import { useDispatch } from "react-redux";
import successToast from "../../../../components/utilits/successToast";
import errorToast from "../../../../components/utilits/errorToast";
import { campaignOpporSubmit, createCampaignOppor, editCampaignOppor, viewCampaignOpporDetail } from "../../../../redux/actions/campaignOpporAction";
import SubmitBtn from "../../../../components/buttons/submit";

const CampaignOpporEdit = () => {
  const [submitData, setSubmitData] = useState();
  const navigate = useNavigate();
  const formRef = useRef();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [faqDetails, setFaqDetails] = useState([]);
  const dispatch = useDispatch();
  const params = useParams()
  const [opportunityType, setOpportunityType] = useState([]);
  console.log(params.id);
  useEffect(() => {
    setLoading(true);
    dispatch(viewCampaignOpporDetail(params.id, onViewSuccess, onViewError));
  }, []);
  const onViewSuccess = (data) => {
    console.log(data.data);
    setFaqDetails(data.data);
    console.log("SUCCESS");
    setLoading(false);
    setError(false);
  };
  const onViewError = (data) => {
    console.log("ERROR");
    setLoading(false);
    setError(true);
  };
  const onNotifyMeError = (data) => {
    console.log("ERROR");
  };

  const initialValues = {
    header: faqDetails?.header,
    opporHeading: faqDetails?.section1_heading,
    opporSubtitle: faqDetails?.section1_title,
    buttonText: faqDetails?.section1_button,
    signUpTitle: faqDetails?.sign_up_title,
    testimonialTitle: faqDetails?.testimonial_title,
    testimonialSubTitle: faqDetails?.testimonial_sub_title,
    ourPartnersSubTitle: faqDetails?.our_partner_sub_title,
  };

  const interestEditSchema = Yup.object().shape({
    header: Yup.string().trim().required("Required"),
    opporHeading: Yup.string().trim().required("Required"),
    opporSubtitle: Yup.string().trim().required("Required"),
    buttonText: Yup.string().trim().required("Required"),
    signUpTitle: Yup.string().trim().required("Required"),
    testimonialTitle: Yup.string().trim().required("Required"),
    testimonialSubTitle: Yup.string().trim().required("Required"),
    ourPartnersSubTitle: Yup.string().trim().required("Required"),
  });

  const handleSubmit = () => {
    if (formRef.current) {
      formRef.current.handleSubmit();
    }
  };
  const onSubmit = (values, { resetForm }) => {
    console.log(values);
    dispatch(
      editCampaignOppor(
        params.id,
        {
          header: values.header,
          section1_heading: values.opporHeading,
          section1_title: values.opporSubtitle,
          section1_button: values.buttonText,
          sign_up_title: values.signUpTitle,
          testimonial_title: values.testimonialTitle,
          testimonial_sub_title: values.testimonialSubTitle,
          our_partner_sub_title: values.ourPartnersSubTitle
        },
        onSuccess,
        onError
      )
    );
  };


  const onSuccess = (data) => {
    successToast(data.message);
    setSubmitData(data.data.id)
    setSaveHide(true)
    // navigate(`/static-pages/campaignOppor/edit/${data?.data?.id}`)
  };

  const onError = (data) => {
    errorToast(data.data);
  };



  //on submit api
  console.log(submitData);
  const [saveHide, setSaveHide] = useState(false);

  const submitFnc = () => {
    dispatch(campaignOpporSubmit
      (
        submitData,
        onSubmitSuccess,
        onSubmitError,
      ));
  };
  const onSubmitSuccess = (data) => {
    console.log(data);
    successToast(data.message)
    navigate("/static-pages/campaignOppor")
  };
  const onSubmitError = (data) => {
    console.log("ERROR==================", data);
    errorToast(data.message)

  };
  const buttonList = [
    !saveHide && <SaveBtn submitFn={handleSubmit}
      loader={loading} />,
    <SubmitBtn submitFn={submitFnc} saveHide={saveHide} />];
  return (
    <InnerLayout buttons={buttonList}>
      <div className="user_view_container">
        <InnerContainer>
          <div className="ps-2  pt-3">
            <Formik
              innerRef={formRef}
              initialValues={initialValues}
              onSubmit={onSubmit}
              enableReinitialize={true}
              validationSchema={interestEditSchema}
            >
              {({ formik, handleChange, values, setFieldValue }) => {
                return (
                  <Form className="px-4 py-3 col-12  col-xxl-10">
                    <div className="d-flex flex-column gap-0 gap-lg-5 flex-lg-row fs_14">
                      <div className="col-12 col-lg-6 pe-0 pe-lg-5">
                        <label
                          id="header"
                          className="mb-2 dark_grey_color fn_Montserrat fw_500 fs_14 ps-2"
                        >
                          Header Title
                        </label>
                        <Field name={"header"}>
                          {({ field }) => (
                            <textarea
                              id="header"
                              {...field}
                              type="text"
                              className={
                                "w-100 opportunities_create_summary_text_area p-2 "
                              }
                            ></textarea>
                          )}
                        </Field>
                        <ErrorMsg name={"header"} />
                        <InputField
                          id={"opporHeading"}
                          label={"Opportunity Heading"}
                          type={"text"}
                          placeholder={"Enter Opportunity Heading"}
                          name={"opporHeading"}
                        />
                        <label
                          id="opporSubtitle"
                          className="mb-2 dark_grey_color fn_Montserrat fw_500 fs_14 ps-2"
                        >
                          Opportunity Subtitle
                        </label>
                        <Field name={"opporSubtitle"}>
                          {({ field }) => (
                            <textarea
                              id="opporSubtitle"
                              {...field}
                              type="text"
                              className={
                                "w-100 opportunities_create_summary_text_area p-2 "
                              }
                            ></textarea>
                          )}
                        </Field>
                        <ErrorMsg name={"opporSubtitle"} />
                        <InputField
                          id={"buttonText"}
                          label={"Button Text"}
                          type={"text"}
                          placeholder={"Enter Button text"}
                          name={"buttonText"}
                        />
                        <label
                          id="signUpTitle"
                          className="mb-2 dark_grey_color fn_Montserrat fw_500 fs_14 ps-2"
                        >
                          Sign Up Title
                        </label>
                        <Field name={"signUpTitle"}>
                          {({ field }) => (
                            <textarea
                              id="signUpTitle"
                              {...field}
                              type="text"
                              className={
                                "w-100 opportunities_create_summary_text_area p-2 "
                              }
                            ></textarea>
                          )}
                        </Field>
                        <ErrorMsg name={"signUpTitle"} />
                      </div>
                      <div className="col-12 col-lg-6 pe-0 pe-lg-5">
                        <label
                          id="testimonialTitle"
                          className="mb-2 dark_grey_color fn_Montserrat fw_500 fs_14 ps-2"
                        >
                          Testimonial Title
                        </label>
                        <Field name={"testimonialTitle"}>
                          {({ field }) => (
                            <textarea
                              id="testimonialTitle"
                              {...field}
                              type="text"
                              className={
                                "w-100 opportunities_create_summary_text_area p-2 "
                              }
                            ></textarea>
                          )}
                        </Field>
                        <ErrorMsg name={"testimonialTitle"} />
                        <label
                          id="testimonialSubTitle"
                          className="mb-2 dark_grey_color fn_Montserrat fw_500 fs_14 ps-2"
                        >
                          Testimonial Sub Title
                        </label>
                        <Field name={"testimonialSubTitle"}>
                          {({ field }) => (
                            <textarea
                              id="testimonialSubTitle"
                              {...field}
                              type="text"
                              className={
                                "w-100 opportunities_create_summary_text_area p-2 "
                              }
                            ></textarea>
                          )}
                        </Field>
                        <ErrorMsg name={"testimonialSubTitle"} />
                        <label
                          id="ourPartnersSubTitle"
                          className="mb-2 dark_grey_color fn_Montserrat fw_500 fs_14 ps-2"
                        >
                          Our Partners SubTitle
                        </label>
                        <Field name={"ourPartnersSubTitle"}>
                          {({ field }) => (
                            <textarea
                              id="ourPartnersSubTitle"
                              {...field}
                              type="text"
                              className={
                                "w-100 opportunities_create_summary_text_area p-2 "
                              }
                            ></textarea>
                          )}
                        </Field>
                        <ErrorMsg name={"ourPartnersSubTitle"} />
                      </div>
                    </div>
                  </Form>
                );
              }}
            </Formik>
          </div>
        </InnerContainer>
      </div>
    </InnerLayout>
  );
};

export default CampaignOpporEdit;

