import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import RejectBtn from "../../../../components/buttons/rejectBtn";
import VersionHistoryBtn from "../../../../components/buttons/versionHistoryBtn";
import InnerContainer from "../../../../components/layouts/innerContainer/innerContainer";
import InnerLayout from "../../../../components/layouts/innerLayout/innerLayout";
import errorToast from "../../../../components/utilits/errorToast";
import successToast from "../../../../components/utilits/successToast";
import * as opporTypeActions from "../../../../redux/actions/opportunityTypeActions";
import ApproveBtn from "../../../../components/buttons/approveBtn";
import moment from "moment";
import EditBtn from "../../../../components/buttons/editBtn";
import { viewNewsletterAction } from "../../../../redux/actions/newsletterActions";
import DeleteBtn from "../../../../components/buttons/deleteBtn";
import LoadingSpinnerTable from "../../../../components/utilits/loadingSpinnerTable";
import LoadAndError from "../../../../components/utilits/LoadAndError";

const NewsLetterView = () => {
    const params = useParams();
    const [updated, setUpdated] = useState(false);
    const [newsData, setNewsData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const dispatch = useDispatch();
    useEffect(() => {
        setLoading(true);
        dispatch(
            viewNewsletterAction(params.id, onSuccess, onError)
        );
    }, [updated]);
    const onSuccess = (data) => {
        setNewsData(data.data)
        setLoading(false);
        setError(false);
    };
    const onError = (data) => {
        errorToast(data.message);
        setLoading(false);
        setError(true);
    };

    const data = [
        {
            heading: "Newsletter ID",
            title: newsData?.id
        },
        {
            heading: "Email Address",
            title: newsData?.email
        },

        {
            heading: "Status",
            title: newsData?.status
        },

        {
            heading: "Approved At",
            title: moment(newsData?.createdAt).format("DD-MM-YYYY HH:mm:ss")
        },
        {
            heading: "Updated At",
            title: moment(newsData?.updatedAt).format("DD-MM-YYYY HH:mm:ss")
        },
    ];
    const buttonList = [
        <VersionHistoryBtn link={`/static-pages/newsletter/version-history`} />,
        newsData?.editable && <>
            <EditBtn link={`/static-pages/newsletter/edit/${newsData.id}`} />
        </>
    ];
    return (
        <InnerLayout buttons={buttonList}>
            <div className="opportunity-type-container">
                <InnerContainer>
                    {loading ? (
                        <LoadingSpinnerTable />
                    ) : (
                        <LoadAndError loader={loading} error={error}>
                            <div className="pt-3  ps-4 ps-lg-4 ms-3">
                                {data.map((item, index) => (
                                    <div className="mb-2">
                                        <h5 className="dark_grey_color fs_13 fn_Montserrat fw_500 m-0">
                                            {item.heading}
                                        </h5>
                                        <p className={` fs_13 fn_Nunito fw_500 dark_title_color  `}>
                                            {item.title}
                                        </p>
                                    </div>
                                ))}
                            </div>
                        </LoadAndError>
                    )}
                </InnerContainer>
            </div>
        </InnerLayout>
    );
}

export default NewsLetterView