import React, { useState } from "react";
import AdminRoleApprovalTableContent from "../../../../components/admin/tables/adminRolesApprovalTableContent";
import CreateBtn from "../../../../components/buttons/createBtn";
import ExportBtn from "../../../../components/buttons/exportBtn";
import FilterBtn from "../../../../components/buttons/filterBtn";
import AdminRolesFilter from "../../../../components/filter/adminFilter/adminRoleFilter";
import AdminUserFilter from "../../../../components/filter/adminUserFilter";
import InnerLayout from "../../../../components/layouts/innerLayout/innerLayout";
import { rolesApprovalsExport } from "../../../../redux/actions/adminRoleActions";

const AdminRoleApprovalsTable = () => {
  const [showFilter, setShowFilter] = useState(false);
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(0);
  const handleFilterClose = () => {
    setShowFilter(false);
  };                        
  const handleFilterOpen = () => {
    setShowFilter(true);
  };
  const handleSearch = (value) => { setSearch(value); setPage(0) };
  const handlePage = (type) => {
    if (type === "+") {
      setPage((prev) => prev + 1);
    } else {
      setPage((prev) => prev - 1);
    }
  };
  const buttonList = [<CreateBtn link="/admin-roles/create" />, , <ExportBtn dispatchAction={rolesApprovalsExport}/>, <FilterBtn filterFn={handleFilterOpen} />];
  return (
    <InnerLayout buttons={buttonList}>
      <AdminRolesFilter
        filter={handleSearch}
        showFilter={showFilter}
        closeFilter={handleFilterClose}
      />
      <AdminRoleApprovalTableContent page={page}
        changePage={handlePage}
        search={search} />
    </InnerLayout>
  );
};

export default AdminRoleApprovalsTable;
