import React, { useState } from 'react'
import { Link } from "react-scroll";
import styles from "./opportunitiesread.module.css"

const OpportunitiesList = ({ data = [] }) => {
    const [activeItem, setActiveItem] = useState();

    return (
        <div className={` mt-5 ${styles.overflow_navbar}`}>
            <div
                className={`border_bottom  d-flex gap-5 mx-3 mx-lg-5 mb-5  align-items-center`}
            >
                {data?.map((item) => {
                    return <Link
                        onClick={() => setActiveItem(item.section_heading)}
                        className={
                            activeItem === item?.section_heading
                                ? `cursor_pointer active_border_bottom pb-2 ms-4 text-nowrap fn_Montserrat secondary_color fs_15 fw_600 text-decoration-none navbar_hover_color`
                                : `cursor_pointer text-decoration-none pb-2 ms-4 text-nowrap light_grey_color fn_Montserrat fs_15 fw_600 navbar_hover_color`
                        }
                        smooth={false}
                        to={item?.section_heading}
                        isDynamic={true}
                        offset={-120}
                    >
                  {item?.section_heading}
                    </Link>
                })}

                {/* <Link
                    onClick={() => setActiveItem("financials")}
                    className={
                        activeItem === "financials"
                            ? `cursor_pointer active_border_bottom pb-2 ms-4 text-nowrap fn_Montserrat secondary_color fs_15 fw_600 text-decoration-none navbar_hover_color`
                            : `cursor_pointer text-decoration-none pb-2 ms-4 text-nowrap light_grey_color fn_Montserrat fs_15 fw_600 navbar_hover_color`
                    }
                    smooth={false}
                    to="financials"
                    isDynamic={true}
                    offset={-120}
                >
                    FINANCIALS
                </Link>

                <Link
                    onClick={() => setActiveItem("keyhighlights")}
                    className={
                        activeItem === "keyhighlights"
                            ? `cursor_pointer active_border_bottom pb-2 ms-4 text-nowrap fn_Montserrat secondary_color fs_15 fw_600 text-decoration-none navbar_hover_color`
                            : `cursor_pointer text-decoration-none pb-2 ms-4 text-nowrap light_grey_color fn_Montserrat fs_15 fw_600 navbar_hover_color`
                    }
                    smooth={false}
                    to="keyhighlights"
                    isDynamic={true}
                    offset={-120}
                >
                    KEY HIGHLIGHTS
                </Link>
                <Link
                    onClick={() => setActiveItem("opportunitynote")}
                    className={
                        activeItem === "opportunitynote"
                            ? `cursor_pointer active_border_bottom pb-2 ms-4 text-nowrap fn_Montserrat secondary_color fs_15 fw_600 text-decoration-none navbar_hover_color`
                            : `cursor_pointer text-decoration-none pb-2 ms-4 text-nowrap light_grey_color fn_Montserrat fs_15 fw_600 navbar_hover_color`
                    }
                    smooth={false}
                    to="opportunitynote"
                    isDynamic={true}
                    offset={-120}
                >
                    OPPORTUNITY NOTE
                </Link>
                <Link
                    onClick={() => setActiveItem("resources")}
                    className={
                        activeItem === "resources"
                            ? `cursor_pointer active_border_bottom pb-2 ms-4 text-nowrap fn_Montserrat secondary_color fs_15 fw_600 text-decoration-none navbar_hover_color`
                            : `cursor_pointer text-decoration-none pb-2 ms-4 text-nowrap light_grey_color fn_Montserrat fs_15 fw_600 navbar_hover_color`
                    }
                    smooth={false}
                    to="resources"
                    isDynamic={true}
                    offset={-120}
                >
                    RESOURCES
                </Link>

                <Link
                    onClick={() => setActiveItem("faqs")}
                    className={
                        activeItem === "faqs"
                            ? `cursor_pointer active_border_bottom pb-2 ms-4 text-nowrap fn_Montserrat secondary_color fs_15 fw_600 text-decoration-none navbar_hover_color`
                            : `cursor_pointer text-decoration-none pb-2 ms-4 text-nowrap light_grey_color fn_Montserrat fs_15 fw_600 navbar_hover_color`
                    }
                    smooth={false}
                    to="faqs"
                    isDynamic={true}
                    offset={-120}
                >
                    FAQS
                </Link> */}
            </div>
        </div>
    )
}

export default OpportunitiesList