import React, { useRef, useState } from "react";
import "../testimonials.css";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import InputField from "../../../../components/form/inputFields/inputField";
import SelectFields from "../../../../components/form/selectFields/selectFields";
import SaveBtn from "../../../../components/buttons/saveBtn";
import InnerLayout from "../../../../components/layouts/innerLayout/innerLayout";
import InnerContainer from "../../../../components/layouts/innerContainer/innerContainer";
import { useDispatch } from "react-redux";
import {
  addAdmin,
  roleTypeData,
} from "../../../../redux/actions/AdminUserAction";
import errorToast from "../../../../components/utilits/errorToast";
import { useNavigate } from "react-router-dom";
import successToast from "../../../../components/utilits/successToast";
import UploadIcon from "../../../../assets/icons/upload";
import CloseIcon from "../../../../assets/icons/closeIcon";
import ErrorMsg from "../../../../components/form/errorMsg";
import { createTestimonials } from "../../../../redux/actions/testimonialsAction";
import { uploadAddressProofFrontImageAction } from "../../../../redux/actions/userAction";
import LoadingSpinnerTable from "../../../../components/utilits/loadingSpinnerTable";

const TestimonialsCreate = () => {
  const [file, setFile] = useState(null);

  const formRef = useRef();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const initialValues = {
    profileImage: "",
    name: "",
    designation: "",
    userType: "",
    description: "",
    color: "",
  };
  const loginSchema = Yup.object().shape({
    name: Yup.string("").required("Required"),
    designation: Yup.string().trim(),
    userType: Yup.string().trim().required("Required"),
    description: Yup.string().trim().required("Required"),
    color: Yup.string()
  });
  const handleSubmit = () => {
    if (!deskImageLink.img) {
      if (!deskImageLink.img) {
        setDeskImageLink({ img: "", error: true });
      }

    }
    if (formRef.current) {
      formRef.current.handleSubmit();
    }
  };
  const onSubmit = (values, { resetForm }) => {
    if (deskImageLink.img)
      dispatch(
        createTestimonials(
          {
            name: values.name,
            designation: values.designation,
            user_type: values.userType,
            description: values.description,
            photo_url: deskImageLink.img,
            color_code: values.color
          },
          onSuccess,
          onError
        )
      );
    console.log(values);
  };

  const onSuccess = (data) => {
    successToast(data.message);
    navigate(`/static-pages/testimonials/edit/${data.data.id}`,
    )
    setLoading(false);
  };

  const onError = (data) => {
    errorToast(data.data);
    setLoading(false);
  };

  function handleImageChange(e, param1) {
    console.log(param1);
    if (e.target?.files.length !== 0) {
      setFile(URL.createObjectURL(e.target.files[0]));

    }
  }

  // image
  const [deskImageLink, setDeskImageLink] = useState({
    img: "",
    error: false,
  });
  const imageUpload = (e) => {
    if (e.target.name === "deskImage") {
      dispatch(
        uploadAddressProofFrontImageAction(
          e.target.files[0],
          onDeskImageUploadSucess,
          onDeskImageUploadError
        )
      );
    }
    e.target.value = null;
  };
  const onDeskImageUploadSucess = (data) => {
    setDeskImageLink({ img: data.data.data.location, error: false });
    console.log(data.data.data.location);
  };
  const onDeskImageUploadError = (data) => {
    setDeskImageLink({ img: "", error: true });
    console.log(data);
  };
  console.log(deskImageLink?.img);
  const buttonList = [<SaveBtn submitFn={handleSubmit} />];
  return (
    <>
      <InnerLayout buttons={buttonList}>
        <div className="admin_user_container">
          <InnerContainer>
            {loading ? (
              <LoadingSpinnerTable />
            ) : (
              <div className="pt-3 mb-5 ps-2 ps-md-2 ps-lg-4 ">

                <Formik
                  innerRef={formRef}
                  initialValues={initialValues}
                  onSubmit={onSubmit}
                  validationSchema={loginSchema}
                >
                  {({ setFieldValue, handleChange, values }) => (
                    <Form className="our-team-form-width">
                      <div className='row'>
                        <div className='col-12 col-md-6'>

                          <div className="mb-3">
                            <span className="mb-3 dark_grey_color fn_Montserrat fw_500 fs_14 ps-2">Profile picture</span>
                            <div className="blog_create_image_container mt-1 position-relative d-flex  justify-content-center align-items-center">
                              <input
                                multiple={false}
                                style={{ visibility: "hidden" }}
                                type="file"
                                id="deskImage"
                                className="upload_document_input"
                                name="deskImage"
                                accept="image/* ,capture=camera"
                                onChange={(e) => {
                                  imageUpload(e);
                                }}
                              />
                              {deskImageLink.img ? (
                                <>
                                  <img
                                    src={deskImageLink.img}
                                    className={"user-edit-profile-picture"}
                                  />
                                  <button
                                    type="button"
                                    onClick={(e) => {
                                      setDeskImageLink({ img: "", error: true });
                                    }}
                                    className="position-absolute border-0 cursor_pointer white_bg d-flex gap-2 py-1 px-2 user_edit_profile_picture_remove_container"
                                  >
                                    <CloseIcon fill="#666666" />
                                    <span className="dark_grey_color fs_13 fn_Montserrat fw_500 ">
                                      Remove
                                    </span>
                                  </button>
                                </>
                              ) : (
                                <label
                                  htmlFor="deskImage"
                                  className="d-flex flex-column align-items-center mt-2 justify-content-center fs_12"
                                >
                                  <UploadIcon width={30} height={30} />
                                  Choose Profile Picture
                                </label>
                              )}
                            </div>
                            {deskImageLink.error && (
                              <span className="fs_12 ms-2 text-danger fw-semibold fn_Nunito">
                                Required!
                              </span>
                            )}
                          </div>
                          <InputField
                            id={"name"}
                            label={"Name"}
                            type={"text"}
                            placeholder={"Enter Name"}
                            name={"name"}
                          />
                          <InputField
                            id={"designation"}
                            label={"Designation"}
                            type={"text"}
                            placeholder={"Enter Designation"}
                            name={"designation"}
                          />
                          <InputField
                            id={"color"}
                            label={"Color Code"}
                            type={"text"}
                            placeholder={"Enter Color Code"}
                            name={"color"}
                          />
                          {/* <SelectFields
                          label={"Designation"}
                          placeholder={"Select designation"}
                          name={"designation"}
                          options={selectDesignation}
                          id={"designation"}
                        /> */}


                          <SelectFields
                            label={"User Type"}
                            placeholder={"Select user type"}
                            name={"userType"}
                            options={selectDesignation}
                            id={"userType"}
                          />


                        </div>
                        <div className='col-12 col-md-6'>
                          <div className='ps-0 ps-lg-4'>

                            <label id="description" className='mb-2 dark_grey_color fn_Montserrat fw_500 fs_14 ps-2'>Description</label>
                            <Field
                              name={"description"}>
                              {({ field }) => (
                                <textarea id="description" {...field} type="text" className={'w-100 opportunities_create_summary_text_area p-2 '}  >
                                </textarea>
                              )}
                            </Field>

                            <ErrorMsg
                              name={"description"} />
                          </div>
                        </div>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            )}
          </InnerContainer>
        </div>
      </InnerLayout>
    </>
  );
};

export default TestimonialsCreate;

export const selectDesignation = [
  { value: "member", label: "Member" },
  { value: "distribution_partner", label: "Distribution Partner" },
  { value: "lease_partner", label: "Lease Partner" },
];
