import React from "react";
import styles from "./opportunitiesread.module.css";
import OpportunitiesReadFundedAmount from "./opportunitiesReadFunededAmount";
import InvestmentIcon from "../../../../assets/icons/opportunities/investment-icon.svg";
import KeyIcon from "../../../../assets/icons/opportunities/key-icon.svg";
import MonthlyIcon from "../../../../assets/icons/opportunities/monthly-icon.svg";
import OwnershipIcon from "../../../../assets/icons/opportunities/ownership-icon.svg";
import OpportuinityIcon from "../../../../assets/icons/opportunities/opportuinity-size-icon.svg";
import RuppeIcon from "../../../../assets/icons/opportunities/ruppe-icon.svg";
import Hourglass from "../../../../assets/icons/opportunities/hourglass-icon.svg";
import SpeedoMeterIcon from "../../../../assets/icons/opportunities/speedometer-icon.svg";
import speedometerIconHigh from "../../../../assets/icons/opportunities/speedo/speedoMeter-high.png";
import speedometerIconLow from "../../../../assets/icons/opportunities/speedo/speedoMeter-low.png";
import speedometerIconMid from "../../../../assets/icons/opportunities/speedo/speedoMeter-mid.png";
const OpportunitiesReadTitle = ({
  title = "Provide Batteries for EV on Lease to a VC funded Energy Operator",
  data = [],
}) => {
  return (
    <>
      <div className="row mx-lg-4">
        <h1 className={`secondary_color fs_28 fw_400 col-12 col-lg-8`}>
          {data?.title}
        </h1>
        <OpportunitiesReadFundedAmount data={data} />
      </div>

      <div className={`row ms-2 g-2 ms-sm-4`}>
        {data?.card_icons?.map((item) => {
          return (
            <div className={`col-xxl-3   col-xl-4 mb-lg-3 mb-1 col-6 `}>
              <div className="d-flex ">
                <div
                  className={`${styles.oppor_read_featurecard__imgContianer} bg-white d-flex justify-content-center     align-items-center`}
                >
                  <img
                    src={item.icon_image_location}
                    className={`${styles.oppor_read_featurecard__img}`}
                  />
                </div>

                <div className=" d-flex flex-column">
                  <span className="ps-lg-3 fs_sm_13 ps-2 fs_24 fs_md_14 secondary_color  fw_600 text-nowrap">
                    {item?.title}
                  </span>
                  <span className="ps-lg-3 fs_sm_10 ps-2 fs_12 fs_md_8 light_grey_color adminuser_table_ellipsis  fw_600">
                    {item.sub_title}
                  </span>
                  {/* <span className="ps-lg-3 ps-2 fs_sm_10 fs_12 fs_md_8  light_grey_color  fw_600">
                    {item.subdescription}
                  </span> */}
                </div>
              </div>
            </div>
          );
        })}
      </div>

      {/* {Utilitiesdetail.map((item, index) => {
        return (
          <div
            className={`${styles.oppor_read_uitlities__container} d-flex justify-content-between align-items-center ms-lg-5 me-lg-5  mt-lg-3 mt-2 ms-1  me-1  `}
          >
            <div className=" d-flex flex-column ">
              <span className="secondary_color fontFam_roboto  fs_16 fs_md_12 fw_700">
                {item.detail}
              </span>
              <span className="patina_color fontFam_roboto fs_400 fs_md_12 fs_14 ">
                {item.subdetail}
              </span>
            </div>
            <div
              className={` ${styles.oppor_read_uitlities_img_speedo__container}`}
            >
              <img src={item.image} />
            </div>
          </div>
        );
      })} */}

      {data?.odometer_section?.map((item, index) => {
        return (
          <div
            className={`${styles.oppor_read_uitlities__container} d-flex justify-content-between align-items-center ms-lg-5 me-lg-5  mt-lg-3 mt-2 ms-1  me-1  `}
          >
            <div className=" d-flex flex-column ">
              <span className="secondary_color fontFam_roboto  fs_16 fs_md_12 fw_700">
                {item.title}
              </span>
              <span className="patina_color fontFam_roboto fs_400 fs_md_12 fs_14 ">
                {item.sub_title}
              </span>
            </div>
            <div
              className={` ${styles.oppor_read_uitlities_img_speedo__container}`}
            >
              <img
                src={
                  item?.odometer_reading?.includes("high")
                    ? speedometerIconHigh
                    : item.odometer_reading?.includes("mid")
                      ? speedometerIconMid
                      : item.odometer_reading?.includes("low")
                        ? speedometerIconLow
                        : speedometerIconLow
                }
              />
            </div>
          </div>
        );
      })}
    </>
  );
};

export default OpportunitiesReadTitle;
export const Utilitiesdetail = [
  {
    detail: `Months of Operations`,
    subdetail: `36M+`,
    image: SpeedoMeterIcon,
  },
  {
    detail: `Customer Profile`,
    subdetail: `B2C Subscriptions`,
    image: SpeedoMeterIcon,
  },
  {
    detail: `Portfolio size of VC shareholder`,
    subdetail: ` $50M-100M`,
    image: SpeedoMeterIcon,
  },
  {
    detail: ` Cash Balance / Annual Cash Burn`,
    subdetail: `  179 %`,
    image: SpeedoMeterIcon,
  },
  {
    detail: `   Asset Recoverability & Monetization`,
    subdetail: `68 %`,
    image: SpeedoMeterIcon,
  },
];
export const opporReadFeaturesCard = [
  {
    image: RuppeIcon,
    heading: `XX.X%`,
    description: `Pre Tax Return (IRR)`,
    subdescription: `  What’s IRR?`,
  },
  {
    image: Hourglass,
    heading: `30 Months`,
    description: ` Tenure`,
  },
  {
    image: OpportuinityIcon,
    heading: `  INR ~2cr`,
    description: ` Total Opportunity`,
    subdescription: `   Size`,
  },

  {
    image: InvestmentIcon,
    heading: `  ₹ XX,XXX`,
    description: `     Minimum`,
    subdescription: ` Investment`,
  },

  {
    image: OwnershipIcon,
    heading: `  Ownership`,
    description: `  Asset Rights`,
    subdescription: ` Retained with LLP`,
  },
  {
    image: KeyIcon,
    heading: `  Deposits`,
    description: `  Upfront Deposits  `,
    subdescription: `   Taken from lesse ~6%`,
  },

  {
    image: MonthlyIcon,
    heading: `      Monthly`,
    description: `  Payouts in hand of`,
    subdescription: `    LLP Partner`,
  },

  {
    image: RuppeIcon,
    heading: `Insurance`,
    description: `Along with 3 year `,
    subdescription: `  Warranty`,
  },
];
