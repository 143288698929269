import "bootstrap/dist/css/bootstrap.min.css";
import MainLayout from "./components/layouts/mainLayout/mainLayout";
import MainRoute from "./routes/mainRoute";
import { Provider } from "react-redux";
import store from "./redux/store";
import { ToastContainer } from "react-toastify";

function App() {
  return (
    <Provider store={store}>
      <MainRoute />
    </Provider>
  );
}

export default App;
