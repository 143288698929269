import { call, takeLatest } from "redux-saga/effects";
import { APPROVE_CAMPAIGN_OPPOR, CREATE_CAMPAIGN_OPPOR, DELETE_CAMPAIGN_OPPOR, EDIT_CAMPAIGN_OPPOR, CAMPAIGN_OPPOR_APPROVALS_EXPORT, CAMPAIGN_OPPOR_APPROVAL_TABLE, CAMPAIGN_OPPOR_APPROVAL_VIEW, CAMPAIGN_OPPOR_EXPORT, CAMPAIGN_OPPOR_OPTIONS, CAMPAIGN_OPPOR_SUBMIT, CAMPAIGN_OPPOR_TABLE, CAMPAIGN_OPPOR_VERSION_EXPORT, CAMPAIGN_OPPOR_VERSION_HISTORY, CAMPAIGN_OPPOR_VERSION_HISTORY_TABLE, REJECT_CAMPAIGN_OPPOR, VIEW_CampaignOppor, VIEW_CAMPAIGN_OPPOR, CAMPAIGN_OPPOR_ACTIVE } from "../actions/types";
import { approveCampaignOpporApi, campaignOpporActiveApi, campaignOpporApprovalByIdApi, campaignOpporApprovalListApi, campaignOpporApprovalsExportApi, campaignOpporSubmitApi, campaignOpporTableExportApi, campaignOpporVersionExportApi, createCampaignOpporApi, deleteCampaignOpporApi, editCampaignOpporApi, rejectCampaignOpporApi, versionHistoryCampaignOpporDetailApi, versionHistoryCampaignOpporListApi, viewCampaignOpporDetailApi, viewCampaignOpporListApi } from "../apis/campaignOpporApi";
import { approveFaqApi, createFaqApi, deleteFaqApi, editFaqApi, faqApprovalByIdApi, faqApprovalListApi, faqApprovalsExportApi, faqOptionsApi, faqSubmitApi, faqTableExportApi, faqVersionExportApi, rejectFaqApi, versionHistoryFaqDetailApi, versionHistoryFaqListApi, viewFaqDetailApi, viewFaqListApi } from "../apis/faqApi";

export function* createCampaignOpporWorker(action) {
    try {
        const res = yield call(createCampaignOpporApi,
            action.data
        );
        console.log(res);

        if (res.status === 200 && res.data.status === "success") {
            yield action.onSuccess(res.data);
        } else {
            yield action.onError(res.data);
        }
    } catch (error) { }
}

export function* approveCampaignOpporWorker(action) {
    try {
        const res = yield call(approveCampaignOpporApi, {
            campaign_id: action.id,
        });
        console.log(res);
        if (res.status === 200 && res.data.status === "success") {
            yield action.onSuccess(res.data);
        } else {
            yield action.onError(res.data);
        }
    } catch (error) { }
}

export function* rejectCampaignOpporWorker(action) {
    try {
        const res = yield call(rejectCampaignOpporApi, action.id);
        console.log(res);
        if (res.status === 200 && res.data.status === "success") {
            yield action.onSuccess(res.data);
        } else {
            yield action.onError(res.data);
        }
    } catch (error) { }
}


export function* viewCampaignOpporDetailWorker(action) {
    try {
        const res = yield call(viewCampaignOpporDetailApi, action.id);
        console.log(res);
        if (res.status === 200 && res.data.status === "success") {
            yield action.onSuccess(res.data);
        } else {
            yield action.onError(res.data);
        }
    } catch (error) { }
}


export function* viewCampaignOpporListWorker(action) {
    try {

        const res = yield call(viewCampaignOpporListApi,
            action.page_no,
            action.data,
        );
        console.log(res);
        if (res.status === 200 && res.data.status === "success") {
            yield action.onSuccess(res.data.data);
            console.log(res.data.data);
        } else {
            yield action.onError(res.data);
        }
    } catch (error) { }
}

export function* editCampaignOpporWorker(action) {
    try {
        const res = yield call(editCampaignOpporApi, action.id, action.data);
        console.log(res);
        if (res.status === 200 && res.data.status === "success") {
            yield action.onSuccess(res.data);
        } else {
            yield action.onError(res.data);
        }
    } catch (error) { }
}


export function* deleteCampaignOpporWorker(action) {
    try {
        const res = yield call(deleteCampaignOpporApi, action.id);
        console.log(res);
        if (res.status === 200 && res.data.status === "success") {
            yield action.onSuccess(res.data);
        } else {
            yield action.onError(res.data);
        }
    } catch (error) { }
}




export function* CampaignOpporApprovalListWorker(action) {
    console.log(action.search,
        action.page_no,)
    try {
        const res = yield call(campaignOpporApprovalListApi,
            action.page_no,
            action.data,
        );
        if (res.status === 200 && res.data.status === "success") {
            yield action.onSuccess(res.data.data);
            console.log(res.data);
        } else {
            yield action.onError(res.data);
        }
    } catch (error) { }
}



export function* CampaignOpporApprovalByIdWorker(action) {
    try {
        const res = yield call(campaignOpporApprovalByIdApi, action.id);
        console.log(res);
        if (res.status === 200 && res.data.status === "success") {
            yield action.onSuccess(res.data);
            console.log(res.data)
        } else {
            yield action.onError(res.data);
        }
    } catch (error) { }
}



export function* versionHistoryCampaignOpporListWorker(action) {
    try {
        const res = yield call(versionHistoryCampaignOpporListApi,
            action.page,
            action.data,
        );
        console.log(res);
        if (res.status === 200 && res.data.status === "success") {
            yield action.onSuccess(res.data);
        } else {
            yield action.onError(res.data);
        }
    } catch (error) { }
}

export function* versionHistoryCampaignOpporDetailWorker(action) {
    try {
        const res = yield call(versionHistoryCampaignOpporDetailApi,
            action.id,
        );
        console.log(res);
        if (res.status === 200 && res.data.status === "success") {
            yield action.onSuccess(res.data);
        } else {
            yield action.onError(res.data);
        }
    } catch (error) { }
}


/*********WATCHERS********/

export function* campaignOpporTableExportWorker(action) {
    try {
        const res = yield call(campaignOpporTableExportApi,

        );
        if (res.status === 200 && res.data.status === "success") {
            yield action.onSuccess(res.data);
        } else {
            yield action.onError(res.data);
        }
    } catch (error) { }
}


export function* campaignOpporApprovalsExportWorker(action) {
    try {
        const res = yield call(campaignOpporApprovalsExportApi);
        console.log(res);
        if (res.status === 200 && res.data.status === "success") {
            console.log(res.data);
            yield action.onSuccess(res.data.data);
        } else {
            yield action.onError(res.data);
        }
    } catch (error) { }
}

export function* campaignOpporVersionExportWorker(action) {
    try {
        const res = yield call(campaignOpporVersionExportApi);
        console.log(res);
        if (res.status === 200 && res.data.status === "success") {
            yield action.onSuccess(res.data);
        } else {
            yield action.onError(res.data);
        }
    } catch (error) { }
}



export function* campaignOpporSubmitWorker(action) {
    try {
        const res = yield call(campaignOpporSubmitApi, action.id);
        console.log(res);
        if (res.status === 200 && res.data.status === "success") {
            yield action.onSuccess(res.data);
        } else {
            yield action.onError(res.data);
        }
    } catch (error) { }
}

export function* campaignOpporActiveWorker(action) {
    try {
        const res = yield call(campaignOpporActiveApi, action.id);
        console.log(res);
        if (res.status === 200 && res.data.status === "success") {
            yield action.onSuccess(res.data);
        } else {
            yield action.onError(res.data);
        }
    } catch (error) { }
}






export function* createCampaignOpporWorkerWatcher() {
    yield takeLatest(CREATE_CAMPAIGN_OPPOR, createCampaignOpporWorker);
}

export function* approveCampaignOpporWorkerWatcher() {
    yield takeLatest(APPROVE_CAMPAIGN_OPPOR, approveCampaignOpporWorker);
}
export function* rejectCampaignOpporWorkerWatcher() {
    yield takeLatest(REJECT_CAMPAIGN_OPPOR, rejectCampaignOpporWorker);
}
export function* viewCampaignOpporDetailWatcherWorker() {
    yield takeLatest(VIEW_CAMPAIGN_OPPOR, viewCampaignOpporDetailWorker);
}
export function* viewCampaignOpporListWorkerWatcher() {
    yield takeLatest(CAMPAIGN_OPPOR_TABLE, viewCampaignOpporListWorker);
}
export function* editCampaignOpporWorkerWatcher() {
    yield takeLatest(EDIT_CAMPAIGN_OPPOR, editCampaignOpporWorker);

}
export function* deleteCampaignOpporWorkerWatcher() {
    yield takeLatest(DELETE_CAMPAIGN_OPPOR, deleteCampaignOpporWorker);
}

export function* CampaignOpporApprovalListWatcherWorker() {
    yield takeLatest(CAMPAIGN_OPPOR_APPROVAL_TABLE, CampaignOpporApprovalListWorker);
}

export function* CampaignOpporApprovalByIdWatcher() {
    yield takeLatest(CAMPAIGN_OPPOR_APPROVAL_VIEW, CampaignOpporApprovalByIdWorker);
}
export function* versionHistoryCampaignOpporListWatcher() {
    yield takeLatest(CAMPAIGN_OPPOR_VERSION_HISTORY_TABLE, versionHistoryCampaignOpporListWorker);
}
export function* versionHistoryCampaignOpporDetailWatcher() {
    yield takeLatest(
        CAMPAIGN_OPPOR_VERSION_HISTORY,
        versionHistoryCampaignOpporDetailWorker
    );
}



/****exports*****/

export function* campaignOpporTableExportWatcher() {
    yield takeLatest(
        CAMPAIGN_OPPOR_EXPORT,
        campaignOpporTableExportWorker
    );
}
export function* campaignOpporApprovalsExportWatcher() {
    yield takeLatest(CAMPAIGN_OPPOR_APPROVALS_EXPORT, campaignOpporApprovalsExportWorker);
}

export function* campaignOpporVersionExportWorkerWatcher() {
    yield takeLatest(CAMPAIGN_OPPOR_VERSION_EXPORT, campaignOpporVersionExportWorker);
}



export function* campaignOpporSubmitWorkerWatcher() {
    yield takeLatest(CAMPAIGN_OPPOR_SUBMIT, campaignOpporSubmitWorker);
}
export function* campaignOpporActiveWorkerWatcher() {
    yield takeLatest(CAMPAIGN_OPPOR_ACTIVE, campaignOpporActiveWorker);
}