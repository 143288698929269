import {
  OPPORTUNITES_UPDATE_JSON,
  OPPORTUNITES_CLEAR_JSON,
  ADD_SECTION,
  DELETE_SECTION,
  OPPORTUNITES_UPDATE_PARENT_SECTION,
  OPPORTUNITES_UPDATE_SUB_SECTION,
  OPPORTUNITES_UPDATE_CONTENT_SECTION
} from "../actions/types";

import {
  opportunityJsonStateData,
  newSection,
  subSection,
  tableSection
} from "../constants/opportunityJsonState";

export const opportunityJsonReducer = (
  state = opportunityJsonStateData,
  action
) => {
  // console.log(action);
  newSection.subsections = [];
  subSection.content = [];
  tableSection.body = [];
  switch (action.type) {
    case OPPORTUNITES_UPDATE_JSON:
      if (action.newJson == null || action.newJson.length == 0 || action.newJson == '') {
        console.log("opportunityJsonStateData", opportunityJsonStateData);
        console.log("opportunityJsonStateData action", action);
        return {
          ...state,
          data: [...opportunityJsonStateData.data],
        };
      }
      return {
        ...state,
        data: [...action.newJson],
      };
    // case OPPORTUNITES_CLEAR_JSON:
    //   return {
    //     ...state,
    //     data: [...opportunityJsonStateData.data],
    //   };
    case OPPORTUNITES_CLEAR_JSON:
      return {
        ...state,
        data: [
          {
            id: Date.now(),
            section_heading: "",
            subsections: [{ content: [{}] }
            ],
          },
        ],
      };
    case ADD_SECTION:
      var newSectioData = newSection;
      newSectioData.id = Date.now();
      return {
        ...state,
        data: [...state.data, newSectioData],
      };

    case DELETE_SECTION:
      var data = state.data;
      data.splice(action.index, 1);
      return {
        ...state,
        data: [...data],
      };
    case OPPORTUNITES_UPDATE_PARENT_SECTION:
      var data = state.data;
      data[action.index] = action.data;
      return {
        data: [...data],
      };

    case OPPORTUNITES_UPDATE_SUB_SECTION:
      var data = state.data;
      data[action.parentIndex].subsections[action.index] = action.data;
      return {
        data: [...data],
      };

    case OPPORTUNITES_UPDATE_CONTENT_SECTION:
      var data = state.data;
      data[action.sectionIndex].subsections[action.subSectionIndex].content[action.index] = action.data;
      return {
        data: [...data],
      };
    default:
      return state;
  }
};
