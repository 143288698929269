import React, { useState } from 'react'
import CreateBtn from '../../../../components/buttons/createBtn';
import ExportBtn from '../../../../components/buttons/exportBtn';
import FilterBtn from '../../../../components/buttons/filterBtn';
import VersionHistoryBtn from '../../../../components/buttons/versionHistoryBtn';
import AdminUserFilter from '../../../../components/filter/adminUserFilter';
import ExpressFilter from '../../../../components/filter/opportunity-pagesFilter.js/expressInterestFilter';
import InnerLayout from '../../../../components/layouts/innerLayout/innerLayout'
import ExpressInterestApprovalTableContent from '../../../../components/opportunity/expressInterest/tables/expressInterestApprovalTableContent'
import { expressApprovalsExport } from '../../../../redux/actions/expressInterestAction';
         
const ExpressInterestApprovalsTable = () => {
  const [page, setPage] = useState(0);
  const [showFilter, setShowFilter] = useState(false);
  const [search, setSearch] = useState({ value: "" });
  const handleFilterClose = () => {
    setShowFilter(false);
  };
  const handleFilterOpen = () => {
    setShowFilter(true);
  };
  const handleSearch = (value) => {
    setSearch({ value: value });
    setPage(0)
  };
  const handlePage = (type) => {
    if (type === "+") {
      setPage((prev) => prev + 1)
    }
    else {
      setPage((prev) => prev - 1)
    }
  }
  const buttonList = [
    <VersionHistoryBtn link={`/opportunity/express-interest/version-history`} />,
    <ExportBtn  dispatchAction={expressApprovalsExport}/>,
    <FilterBtn filterFn={handleFilterOpen} />,
  ];
  return (
    <InnerLayout buttons={buttonList}>
      <ExpressInterestApprovalTableContent page={page} changePage={handlePage} search={search}
      />
      <ExpressFilter
        filter={handleSearch}
        showFilter={showFilter}
        closeFilter={handleFilterClose}
      />
    </InnerLayout>
  )
}

export default ExpressInterestApprovalsTable