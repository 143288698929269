import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import ArrowRightIcon from '../../../../assets/icons/arrowrightIcon';
import InnerContainer from '../../../../components/layouts/innerContainer/innerContainer';
import InnerLayout from '../../../../components/layouts/innerLayout/innerLayout';
import errorToast from '../../../../components/utilits/errorToast';
import LoadAndError from '../../../../components/utilits/LoadAndError';
import { versionViewContactAction } from '../../../../redux/actions/contactUsActions';


const ContactUsVersionView = () => {
    const params = useParams();
    const [versionHistoyData, setVersionHistoyData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const dispatch = useDispatch();
    useEffect(() => {
        setLoading(true);
        dispatch(
            versionViewContactAction(
                params.id,
                onSuccess,
                onError,
            )
        );
    }, [params.id]);
    console.log(params.id);
    const onSuccess = (data) => {
        setVersionHistoyData(data?.data?.data);
        setLoading(false);
        setError(false);
    };
    console.log(params.id);
    console.log(versionHistoyData);
    const onError = (data) => {
        errorToast(data.message);
        setLoading(false);
        setError(true);
    };
    const data = [
        {
            heading: "Contact Us Query ID",
            title: versionHistoyData.contactus_id === null ? "--" : versionHistoyData.contactus_id,

        },
        {
            heading: "Created At",
            title: moment(versionHistoyData.createdAt === null ? "--" : versionHistoyData.createdAt).format("DD-MM-YYYY HH:mm:ss"),
        },
        {
            heading: "Phone Number",
            title: versionHistoyData.phone_number == null ? "--" : versionHistoyData.phone_number,
        },
        {
            heading: "Updated At",
            title: moment(versionHistoyData.updatedAt == null ? "--" : versionHistoyData.updatedAt).format("DD-MM-YYYY HH:mm:ss"),
        },
        {
            heading: "Updated By",
            title: versionHistoyData.updated_by == null ? "--" : versionHistoyData.updated_by,
        },
    ];

    const newdata = [
        {
            heading: "Query Name",
            title: versionHistoyData.new_data?.query_name,
        },
        {
            heading: "Contact Us Id",
            title: versionHistoyData?.contactus_id,
        },
        {
            heading: "Phone Number",
            title: versionHistoyData.new_data?.phone_number,
        },
    ]

    const olddata = [
        {
            heading: "Query Name",
            title: versionHistoyData.old_data?.query_name,
        },
        {
            heading: "Contact Us Id",
            title: versionHistoyData?.contactus_id,
        },
        {
            heading: "Phone Number",
            title: versionHistoyData.old_data?.phone_number,
        },
    ]
    console.log(versionHistoyData, "one");
    return (
        <>
            <InnerLayout >
                <div className="opportunity-type-container">
                    <InnerContainer>
          <LoadAndError loader={loading} error={error}>
                        <div className="pt-3 ps-5 row">
                            {data.map((item, index) => (
                                <div className="mb-2  col">
                                    <h5 className="dark_grey_color text-nowrap fs_13 fn_Montserrat fw_500 m-0">
                                        {item.heading}
                                    </h5>
                                    <p
                                        className={` fs_13 fn_Nunito text-nowrap fw_500 ${item.heading === "Role ID"
                                            ? "secondary_color"
                                            : "dark_title_color"
                                            }`}
                                    >
                                        {item.title}
                                    </p>
                                </div>
                            ))}
                        </div>
                        <div className="white_shade_bg  py-1 d-flex">
                            <div className="w-50">
                                <h5 className="fw_500 ps-4 dark_grey_color pt-2 text-nowrap fs_16 fn_Montserrat">
                                    JSON Old Data
                                </h5>
                            </div>
                            <div className="w-50  d-none d-lg-block">
                                <h5 className="fw_500 ps-4  dark_grey_color pt-2 text-nowrap fs_16 fn_Montserrat">
                                    JSON New Data
                                </h5>
                            </div>
                        </div>

                        <div className="row mt-3 ps-0 ps-lg-4">
                            <div className="col-12 col-lg-6">
                                <div className="admin_role_aprvls_vrsn_hstry_data_container  mt-3 pt-4   px-3">
                                    <div className="d-flex justify-content-between align-items-center">
                                        <h5 className="fs_13 fn_Nunito fw_400 dark_title_color text-nowrap">
                                            Old Data
                                        </h5>
                                        <ArrowRightIcon width={25} height={25} fill={"#A5A5A5"} />
                                    </div>
                                    {
                                        versionHistoyData.old_data === null ? (
                                            <div>Empty data</div>
                                        ) : (<>
                                            {
                                                olddata.map((item, index) => {
                                                    return (
                                                        <div className='d-flex flex-column'>
                                                            <h5 className='dark_grey_color  fs_13 fn_Montserrat fw_500 m-0'>{item.heading}</h5>
                                                            <p className={` fs_13 fn_Nunito  fw_500 ${item.heading === "Notify Me ID" ? 'secondary_color' : 'dark_title_color'}`}>{item.title}</p>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </>)
                                    }
                                </div>
                                <div></div>
                            </div>
                            <div className="col-12 mt-5 mt-lg-0 col-lg-6">
                                <h5 className="fw_500 ps-4 d-block d-lg-none white_shade_bg  dark_grey_color py-3 text-nowrap fs_16 fn_Montserrat">
                                    JSON New Data
                                </h5>
                                <div className="admin_role_aprvls_vrsn_hstry_data_container    mt-4 mt-lg-3 pt-3 px-3">
                                    <div className="d-flex justify-content-between">
                                        <h5 className="fs_13 fn_Nunito fw_400 dark_title_color text-nowrap">
                                            New Data
                                        </h5>
                                        <ArrowRightIcon width={25} height={25} fill={"#A5A5A5"} />
                                    </div>
                                    {
                                        versionHistoyData.new_data === null ? (
                                            <div>Empty data</div>
                                        ) : (<>{
                                        newdata.map((item, index) => {
                                            return (
                                                <div className='d-flex flex-column'>
                                                    <h5 className='dark_grey_color  fs_13 fn_Montserrat fw_500 m-0'>{item.heading}</h5>
                                                    <p className={` fs_13 fn_Nunito  fw_500 ${item.heading === "Notify Me ID" ? 'secondary_color' : 'dark_title_color'}`}>{item.title}</p>
                                                </div>
                                            )
                                        })
                                    }</>)}
                                    
                                </div>
                            </div>
                        </div>
                        </LoadAndError>
                    </InnerContainer>
                </div>
            </InnerLayout>
        </>
    )
}
export default ContactUsVersionView