import React, { useEffect, useState } from "react";
import errorToast from "../../../../components/utilits/errorToast";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import DeleteBtn from "../../../../components/buttons/deleteBtn";
import EditBtn from "../../../../components/buttons/editBtn";
import VersionHistoryBtn from "../../../../components/buttons/versionHistoryBtn";
import InnerContainer from "../../../../components/layouts/innerContainer/innerContainer";
import InnerLayout from "../../../../components/layouts/innerLayout/innerLayout";
import "../our-team.css";
import {
  deleteNotifyMe,
  readNotifyMe,
} from "../../../../redux/actions/notifyMeActions";
import DeleteModal from "../../../../components/modals/deleteModal";
import successToast from "../../../../components/utilits/successToast";
import DownloadIcon from "../../../../assets/icons/downloadIcon";
import ViewImageModal from "../../../../components/user/modals/viewImageModal";
import * as ourTeamAction from "../../../../redux/actions/ourTeamAction";
import OurTeamProfileImage from "../../../../components/static-pages/our-team/modals/approvalProfileImage";
import moment from "moment";
import LoadingSpinnerTable from "../../../../components/utilits/loadingSpinnerTable";
import LoadAndError from "../../../../components/utilits/LoadAndError";
const OurTeamRead = () => {
  const navigate = useNavigate();
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);
  const [show, setShow] = useState(false);
  const [imageShow, setImageShow] = useState(false);
  const params = useParams();
  const dispatch = useDispatch();
  const handleBackImageShow = () => setImageShow(true);
  const handleImageClose = () => setImageShow(false);
  const [notifyMeViewData, SetNotifyMeViewData] = useState({
    id: "",
    name: "",
    createdAt: "",
    updatedAt: "",
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false)
  const [loadingDelete, setLoadingDelete] = useState(false);

  useEffect(() => {
    setLoading(true)
    dispatch(
      ourTeamAction.viewOurTeamDetail(params.id, onSuccess, onError)
    );
  }, [params.id])
  const onSuccess = (data) => {
    SetNotifyMeViewData(data);
    setLoading(false)
    console.log(data, "suceeee")
    setError(false)
  };
  const onError = (data) => {
    errorToast(data.message)
    setLoading(false)
    console.log(data, "jdsgfdsugdsjgfdsjhgdshv")
    setError(true)
  };

  //delete
  const [deleteData, setDeleteData] = useState({ name: "", id: "" });

  const handleDelete = () => {
    setLoadingDelete(true);
    dispatch(
      ourTeamAction.deleteOurTeam(
        deleteData.id,
        onDeleteSuccess,
        onDeleteError,
      )
    );
  };
  const onDeleteSuccess = (data) => {
    successToast(data.message);
    setLoadingDelete(false);
    setShow(false);
    navigate("/our-team")
    setLoading(false)
    setError(false)
  };
  const onDeleteError = (data) => {
    errorToast(data.message);
    setLoadingDelete(false);
    setLoading(false)
    setError(true)
  };
  console.log(notifyMeViewData);

  const deletenotifyMebyid = () => {
    setDeleteData({

      name: notifyMeViewData?.data?.name,
      id: notifyMeViewData?.data?.id,
    });
    handleShow();
  };
  const buttonList = [
    <VersionHistoryBtn link={`/static-pages/our-team/version-history`} />,

    notifyMeViewData?.data?.editable && <>
      <EditBtn link={`/static-pages/our-team/edit/${notifyMeViewData?.data?.id}`} />,
      <DeleteBtn showModal={deletenotifyMebyid} />
    </>,
  ];
  const data = [
    {
      heading: "Team Member ID ",
      title: notifyMeViewData?.data?.id,
    },
    {
      heading: "Name",
      title: notifyMeViewData?.data?.name,
    },
    {
      heading: "Designation",
      title: notifyMeViewData?.data?.designation
    },
    {
      heading: "LinkedIn URL",
      title: notifyMeViewData?.data?.linkedin_url

    },
    {
      heading: "Description",
      title: notifyMeViewData?.data?.description

    },

    {
      heading: "Prirority",
      title: notifyMeViewData?.data?.priority
    },

    {
      heading: "Approved At",
      title: moment(notifyMeViewData?.data?.createdAt).format("DD/MM/YYYY HH:mm:ss")
    },
    {
      heading: "Updated At",
      title: moment(notifyMeViewData?.data?.updatedAt).format("DD/MM/YYYY HH:mm:ss")
    },
  ];



  return (
    <InnerLayout buttons={buttonList}>
      <div className="admin_user_container">
        <InnerContainer>
          {loading ? (
            <LoadingSpinnerTable />
          ) : (
            <LoadAndError loader={loading} error={error}>
              <div className="row">
                <div className="col-12 col-md-5">
                  <div className="pt-3  ps-4 ps-lg-4 ms-3">
                    {data.map((item, index) => (
                      <div className="mb-2">
                        <h5 className="dark_grey_color fs_13 fn_Montserrat fw_500 m-0">
                          {item.heading}
                        </h5>
                        <p
                          className={` fs_13 fn_Nunito fw_500  ${item.heading === "LinkedIn URL" ||
                            item.heading === "User ID"
                            ? "secondary_color"
                            : "dark_title_color"
                            } `}
                        >
                          {item.title}
                        </p>
                      </div>
                    ))}
                  </div>
                </div>
                <div className="col-12 col-md-5">
                  <div className="ps-5">
                    <h5 className="dark_grey_color text-nowrap fs_13 fn_Montserrat fw_500">
                      Profile Picture
                    </h5>

                    <div className="personal_information_image_container mt-2 position-relative">
                      <img
                        onClick={handleBackImageShow}
                        src={notifyMeViewData?.data?.photo_url}
                        className="personal_information_img_size rounded-2"
                      />
                      <a href={DownloadIcon} download="file">
                        <button
                          // onClick={downloadImage}
                          type="button"
                          className="position-absolute border-0 bg-white d-flex align-items-center fs_10 top-0 end-0"
                        >
                          <DownloadIcon width={8} />
                          <span className="ms-1">Download</span>
                        </button>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </LoadAndError>
          )}
        </InnerContainer>
      </div>
      <DeleteModal
        loader={loadingDelete}
        handleClose={handleClose}
        deleteFn={handleDelete}
        data={deleteData}
        show={show}
      />
      <OurTeamProfileImage
        handleClose={handleImageClose}
        show={imageShow}
        // image={}
        Profileimage={notifyMeViewData?.data?.photo_url}

      />
    </InnerLayout>
  );
};

export default OurTeamRead;
