import { authHeader } from "../config";
import { api } from "./api";

export const createBlogApi = (params) => {
  return api.post("/api/admin/blog/create-blog", params, {
    headers: {
      "x-access-token": localStorage.getItem("accessToken"),
      "Content-Type": "application/json",
    },
  });
};

export const approveBlogApi = (params) => {
  return api.post("/api/admin/blog/approve-blog", JSON.stringify(params), {
    headers: {
      "x-access-token": localStorage.getItem("accessToken"),
      "Content-Type": "application/json",
    },
  });
};

export const rejectBlogApi = (id, params) => {
  return api.post(`/api/admin/blog/reject-blog/${id}`, JSON.stringify(params), {
    headers: {
      "x-access-token": localStorage.getItem("accessToken"),
      "Content-Type": "application/json",
    },
  });
};

export const viewBlogDetailApi = (id, params) => {
  return api.post(
    `/api/admin/blog/find-one-blog/${id}`,
    JSON.stringify(params),
    {
      headers: {
        "x-access-token": localStorage.getItem("accessToken"),
        "Content-Type": "application/json",
      },
    }
  );
};

export const viewBlogListApi = (page = 0, params) => {
  return api.post(
    `/api/admin/blog/find-all-blog?page_no=${page}`,
    JSON.stringify(params),
    {
      headers: {
        "x-access-token": localStorage.getItem("accessToken"),
        "Content-Type": "application/json",
      },
    }
  );
};

export const editBlogApi = (id, params) => {
  return api.post(`/api/admin/blog/edit-blog/${id}`, JSON.stringify(params), {
    headers: {
      "x-access-token": localStorage.getItem("accessToken"),
      "Content-Type": "application/json",
    },
  });
};

export const deleteBlogApi = (id, params) => {
  return api.post(`/api/admin/blog/delete-blog/${id}`, JSON.stringify(params), {
    headers: {
      "x-access-token": localStorage.getItem("accessToken"),
      "Content-Type": "application/json",
    },
  });
};

export const blogSubmitApi = (id, params) => {
  return api.post(`/api/admin/blog/on-submit/${id}`, params, {
    headers: {
      "x-access-token": localStorage.getItem("accessToken"),
      "Content-Type": "application/json",
    },
  });
};

export const blogApprovalListApi = (page = 0, params) => {
  return api.post(
    `/api/admin/blog/find-all-checkker-blog?page_no=${page}`,
    JSON.stringify(params),
    {
      headers: {
        "x-access-token": localStorage.getItem("accessToken"),
        "Content-Type": "application/json",
      },
    }
  );
};

export const blogApprovalByIdApi = (id, params) => {
  return api.post(
    `/api/admin/blog/find-one-checkker-blog/${id}`,
    JSON.stringify(params),
    {
      headers: {
        "x-access-token": localStorage.getItem("accessToken"),
        "Content-Type": "application/json",
      },
    }
  );
};

export const versionHistoryBlogListApi = (pageNo = 0, params) => {
  return api.post(
    `/api/admin/blog/views-blog-versions?page_no=${pageNo}`,
    JSON.stringify(params),
    {
      headers: {
        "x-access-token": localStorage.getItem("accessToken"),
        "Content-Type": "application/json",
      },
    }
  );
};

export const versionHistoryBlogDetailApi = (id, params) => {
  return api.post(
    `/api/admin/blog/view-blog-version/${id}`,
    JSON.stringify(params),
    {
      headers: {
        "x-access-token": localStorage.getItem("accessToken"),
        "Content-Type": "application/json",
      },
    }
  );
};
export const blogCategoryOptionsApi = (params) => {
  return api.post(
    `/api/admin/blog/find-blog-category-name-id`,
    JSON.stringify(params),
    {
      headers: {
        "x-access-token": localStorage.getItem("accessToken"),
        "Content-Type": "application/json",
      },
    }
  );
};
export const blogOptionsApi = (params) => {
  return api.post(`/api/admin/blog/find-blog-name-id`, JSON.stringify(params), {
    headers: {
      "x-access-token": localStorage.getItem("accessToken"),
      "Content-Type": "application/json",
    },
  });
};

export const blogMainExportApi = (page, params) => {
  return api.post(`/api/admin/blog/export-main-blog`, JSON.stringify(params), {
    headers: {
      "x-access-token": localStorage.getItem("accessToken"),
      "Content-Type": "application/json",
    },
  });
};

export const blogApprovalsExportApi = (page, params) => {
  return api.post(
    `/api/admin/blog/export-approval-blog`,
    JSON.stringify(params),
    {
      headers: {
        "x-access-token": localStorage.getItem("accessToken"),
        "Content-Type": "application/json",
      },
    }
  );
};

export const blogVersionExportApi = (page, params) => {
  return api.post(
    `/api/admin/blog/export-version-blog`,
    JSON.stringify(params),
    {
      headers: {
        "x-access-token": localStorage.getItem("accessToken"),
        "Content-Type": "application/json",
      },
    }
  );
};
