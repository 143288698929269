import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { adminApprovalList } from "../../../redux/actions/AdminUserAction";
import { TableContainer } from "../../table/tableContainer";
import { tableAdminApprovalHeader } from "../../table/tableData";
import TablePagination from "../../table/tablePagination";
import LoadAndError from "../../utilits/LoadAndError";
import LoadingSpinnerTable from "../../utilits/loadingSpinnerTable";
import "./admintable.css";
const AdminUserApprovalTableContent = ({ page, search, changePage }) => {
  const [adminApprovalsData, setAdminApprovalsData] = useState([]);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [pageData, setPageData] = useState({ noOfItems: 0, noOfPages: 1 });
  const dispatch = useDispatch();
  useEffect(() => {
    setLoading(true);
    dispatch(
      adminApprovalList(
        {
          search: {
            admin_id: search.adminId,
            name: search.name,
            email: search.email,
            role_name: search.roleName,
            role_id: search.roleId,
          },
        },
        page,
        onSuccess,
        onError
      )
    );
  }, [page, search]);

  const onSuccess = (data) => {
    setAdminApprovalsData(data.data);
    setPageData({
      noOfItems: data.count,
      noOfPages: data.pages,
    });
    setError(false);
    setLoading(false);
    console.log(data.data);
  };
  const onError = (data) => {
    console.log(data, "ERROR");
    setError(true);
    setLoading(false);
  };
  function handlePagination(type) {
    if (type === "+") {
      if (page + 1 < pageData.noOfPages) changePage("+");
    } else if (type === "-") if (page > 0) changePage("-");
  }
  return (
    <TableContainer maxWidth="100%" width="90%">
      <div className="adminuser_table__container position-relative">
        {loading && <LoadingSpinnerTable />}
        <table className={"adminuser_table"}>
          <thead className="adminuser_table__header">
            <tr className="adminuser_table__row">
              {tableAdminApprovalHeader.map((item) => {
                return (
                  <th
                    key={item}
                    className={`text-nowrap adminuser_table__heading adminuser_approvals_table__heading ${
                      item === "Status" ? "position-sticky end-0" : null
                    }`}
                  >
                    {item}
                  </th>
                );
              })}
            </tr>
          </thead>

          <tbody className="adminuser_table__body">
            <LoadAndError
              loader={loading}
              error={error}
              status={adminApprovalsData.length === 0}
            >
              {adminApprovalsData.map((item, index) => {
                return (
                  <tr key={item.id} className="adminuser_table__row">
                    <td className="text-nowrap dark_title_color adminuser_table__data ">
                      {page > 0 ? 12 * page + (index + 1) : index + 1}
                    </td>
                    <td className="text-nowrap dark_title_color adminuser_table__data">
                      {item.id === null ? "-" : item.id}
                    </td>
                    <td className="text-nowrap dark_title_color adminuser_table__data">
                      {item.admin_id === null ? "-" : item.admin_id}
                    </td>
                    <td className="text-nowrap dark_title_color adminuser_table__data">
                      {item?.new_data === null
                        ? item?.old_data?.name
                        : item?.new_data?.name}
                    </td>
                    <td className="text-nowrap dark_title_color adminuser_table__data">
                      {item?.new_data === null
                        ? item?.old_data?.email
                        : item?.new_data?.email}
                    </td>
                    <td
                      title={
                        item?.new_data === null
                          ? item?.old_data?.role_id
                          : item?.new_data?.role_id
                      }
                      className="text-nowrap secondary_color adminuser_table__data adminuser_table_mid_ellipsis"
                    >
                      <Link
                        className="text-decoration-none secondary_color"
                        to={{
                          pathname: `/admin-roles/view/${
                            item?.new_data === null
                              ? item?.new_data?.role_id
                              : item?.old_data?.role_id
                          }`,
                        }}
                      >
                        {item?.new_data === null
                          ? item?.old_data?.role_id
                          : item?.new_data?.role_id}
                      </Link>
                    </td>
                    <td className="text-nowrap dark_title_color adminuser_table__data">
                      {item.new_data === null
                        ? moment(
                            item?.old_data?.last_login_time === null
                              ? "--"
                              : item?.old_data?.last_login_time
                          ).format("DD/MM/YYYY HH:MM:ss")
                        : moment(
                            item?.new_data?.last_login_time === null
                              ? "--"
                              : item?.new_data?.last_login_time
                          ).format("DD/MM/YYYY HH:MM:ss")}
                    </td>
                    <td className="text-nowrap dark_title_color adminuser_table__data">
                      {item?.last_action ? item?.last_action : "-"}
                    </td>
                    <td className="text-nowrap dark_title_color adminuser_table__data">
                      <Link
                        className="text-decoration-none secondary_color"
                        to={{
                          pathname: `/admin-users/view/${
                            item?.updated_by === null ? "-" : item?.updated_by
                          }`,
                        }}
                      >
                        {item?.updated_by === null ? "-" : item?.updated_by}
                      </Link>
                    </td>
                    <td className="text-nowrap dark_title_color adminuser_table__data ">
                      {item?.updatedAt
                        ? moment(item?.updatedAt).format("DD/MM/YYYY HH:MM:ss")
                        : "--"}
                    </td>
                    <td className="text-nowrap adminuser_table__data position-sticky end-0">
                      <div className="d-flex w-100 gap-2">
                        <Link
                          className="text-decoration-none primary_color"
                          to={{
                            pathname: `/admin-users/approvals/view/${item.id}`,
                          }}
                        >
                          View
                        </Link>{" "}
                      </div>
                    </td>
                  </tr>
                );
              })}
            </LoadAndError>
          </tbody>
        </table>
      </div>
      <TablePagination
        paginate={handlePagination}
        currentPage={page}
        pageData={pageData}
      />
    </TableContainer>
  );
};

export default AdminUserApprovalTableContent;
