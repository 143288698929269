import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import styles from "../../../opportunity/opportunties/opportunitiesRead/opportunitiesReadHeadTag";
import OpportunitiesOverview from "../opportunitiesRead/opportunities-overview";
import Financials from "../opportunitiesRead/opportunitiesFinancials";
import OpportunitiesKeyhighlights from "../opportunitiesRead/opportunitiesKeyHighlights";
import OpportunitiesNote from "../opportunitiesRead/opportunitiesNotes";
import Resources from "../opportunitiesRead/opportunitiesResources";
import { Link } from "react-scroll";
import StickyBox from "react-sticky-box";
import "./opportunitiesTable.css";

const CreateTable = ({
  tableTitle,
  containerclassName = "opportunities_create_table_container",
  data = [],
  link = "/opportunity/opportunties/edit/content-edit/",
  editContent,
}) => {
  console.log(data);
  const navigate = useNavigate();
  const params = useParams();
  return (
    <div className="mb-4">
      <div className="d-flex justify-content-between">
        <h2 className="dark_grey_color fs_13 fn_Montserrat fw_500">
          {tableTitle}
        </h2>
        {editContent && <button
          onClick={() =>
            navigate(`${link}${params.id}`)
          }
          className="light_green_color fs_13 fn_Montserrat bg-transparent border-0 fw_500"
        >
          Edit Content Section
        </button>}
      </div>
      <div className={`${containerclassName}`}>

        {data?.length > 0 && data?.map((item) => {
          return (
            <div
              className="d-lg-flex ms-lg-5 mt-lg-3 mt-3 justify-content-center"
              id="overview"
            >
              <div className="col-lg-3  me-lg-5">
                <StickyBox offsetBottom={30}>
                  <h3 className="mb-4 fn_Montserrat fw_600 secondary_color pt-3">
                    {item?.section_heading}
                  </h3>
                  <ul className={`d-lg-block d-none list-unstyled p-0`}>
                    {item?.subsections?.map((subItem) => {
                      return (
                        <Link
                          smooth={false}
                          to={subItem?.title}
                          activeClass="active"
                          spy={true}
                          isDynamic={true}
                          // offset={-130}
                          className="text-decoration-none  cursor_pointer"
                        >
                          <li className="mb-2 fn_Montserrat fw_600 light_green_color">
                            {subItem?.title}
                          </li>
                        </Link>
                      );
                    })}
                  </ul>
                </StickyBox>
              </div>
              <div className="col-lg-6 col-12  ms-lg-5">
                {" "}
                {item?.subsections?.map((subsectionsItems) => {
                  return (
                    <div className="" id={subsectionsItems?.title}>
                      <h4
                        className={`${styles.overview_title} fs_20  fn_Montserrat fw_400 light_green_color bor w-25 pb-2 pt-4`}
                      >
                        {subsectionsItems?.title}
                      </h4>
                      {subsectionsItems?.content?.map((contentItem) => {
                        if (contentItem?.type === "text")
                          return (
                            <div
                              className="fn_Nunito fs_15 popup_title_color fw_600 p-0 ms-3"
                              dangerouslySetInnerHTML={{
                                __html: contentItem?.value,
                              }}
                            ></div>
                          );
                        else if (contentItem?.type === "table") {
                          // if (contentItem?.table_type === "type1") {
                          //   return <>table</>;
                          // } '

                          if (contentItem?.table_type) {
                            return (
                              <>
                                {" "}
                                {contentItem?.heading.col1 ? (
                                  <>
                                    <div className="d-flex mb-3 gap-lg-5">
                                      <div className="col-lg-5 col-6 col-md-6 col-sm-6">
                                        <span className="secondary_color fn_Nunito fw_600 fs_16 ">
                                          {contentItem?.heading.col1}
                                        </span>
                                      </div>

                                      <div className="col-lg-5 col-6 col-md-6 col-sm-6  d-flex flex-column">
                                        <span className="secondary_color fn_Montserrat fw_600 fs_16   ps-3 ps-lg-0 ">
                                          {contentItem?.heading.col2}
                                        </span>
                                      </div>
                                    </div>
                                    <hr className="w-100" />{" "}
                                  </>
                                ) : null}
                                {contentItem?.body?.map((tableContent) => {
                                  return (
                                    <div className="d-flex mb-3 gap-lg-5">
                                      <div className="col-lg-5 col-6 col-md-6 col-sm-6  d-flex flex-column">
                                        <span className="plankton_green fn_Nunito fw_600 fs_16   ">
                                          {tableContent.col1_title}
                                        </span>
                                        {tableContent.col1_caption && (
                                          <span className="fs_14 fw_400 fn_Nunito black_color">
                                            {tableContent.col1_caption}
                                          </span>
                                        )}
                                      </div>
                                      <div className="col-lg-5 col-6 col-md-6 col-sm-6  d-flex flex-column">
                                        <span className="secondary_color fn_Montserrat fw_600 fs_16   ">
                                          {tableContent.col2_title}{" "}
                                          <span className="light_green_color"></span>
                                        </span>
                                        {tableContent.col2_caption && (
                                          <span className="fs_14 fw_400 fn_Nunito popup_title_color   fs_md_12">
                                            {tableContent.col2_caption}
                                          </span>
                                        )}
                                      </div>
                                    </div>
                                  );
                                })}
                                <hr className="w-100" />
                                <div className="d-flex mb-3 gap-lg-5">
                                  <div className="col-lg-5 col-6 col-md-6 col-sm-6">
                                    <span className="secondary_color fn_Montserrat fw_600 fs_16 ">
                                      {contentItem?.footer.col1}
                                    </span>
                                  </div>
                                  <div className="col-lg-5 col-6 col-md-6 col-sm-6">
                                    <span className="secondary_color fn_Montserrat fw_600 fs_16  ">
                                      {contentItem?.footer.col2}
                                    </span>
                                  </div>
                                </div>
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: contentItem?.footerNotes,
                                  }}
                                ></div>
                                {/* <ol
                                  className={` popup_title_color fs_15  fn_Nunito fw_600 p-0 ms-3`}
                                >
                                  <li className="mb-2"> {text}</li>
                                  <li className="mb-2"> {text}</li>
                                  <li className="mb-2"> {text}</li>
                                </ol> */}
                              </>
                            );
                          }
                        }
                      })}

                      {/* <ul
                        className={`fn_Nunito fs_15 popup_title_color fw_600 p-0 ms-3`}
                      >
                        <li className="mb-2"> {item.detail}</li>
                        <li className="mb-2"> {item.detail}</li>
                        <li className="mb-2"> {item.detail}</li>
                      </ul> */}
                    </div>
                  );
                })}
              </div>
            </div>
          );
        })}

        {/* <OpportunitiesOverview /> */}
        {/* <Financials />
        <OpportunitiesKeyhighlights />
        <OpportunitiesNote />
        <Resources /> */}
      </div>
    </div>
  );
};
export default CreateTable;
