import React, { useEffect, useRef, useState } from "react";
import "../admin-user.css";
import { Formik, Form, Field } from "formik";
import eyeOn from "../../../../assets/icons/eye-on.svg";
import eyeOff from "../../../../assets/icons/eye-off.svg";
import * as Yup from "yup";
import InputField from "../../../../components/form/inputFields/inputField";
import SelectFields from "../../../../components/form/selectFields/selectFields";
import SaveBtn from "../../../../components/buttons/saveBtn";
import InnerLayout from "../../../../components/layouts/innerLayout/innerLayout";
import DeleteBtn from "../../../../components/buttons/deleteBtn";
import InnerContainer from "../../../../components/layouts/innerContainer/innerContainer";
import ViewBtn from "../../../../components/buttons/viewBtn";
import { useNavigate, useParams } from "react-router-dom";
import {
  adminSubmitAction,
  deleteAdmin,
  editAdmin,
  roleTypeData,
  viewAdminDetail,
} from "../../../../redux/actions/AdminUserAction";
import { useDispatch } from "react-redux";
import LoadingSpinnerTable from "../../../../components/utilits/loadingSpinnerTable";
import DeleteModal from "../../../../components/modals/deleteModal";
import successToast from "../../../../components/utilits/successToast";
import LoadAndError from "../../../../components/utilits/LoadAndError";
import SubmitBtn from "../../../../components/buttons/submit";
import errorToast from "../../../../components/utilits/errorToast";
import ErrorMsg from "../../../../components/form/errorMsg";
import { passwordRegex } from "./admin-create";
const AdminEdit = () => {
  const formRef = useRef();
  const params = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [delLoading, setDelLoading] = useState(false);
  const [deleteData, setDeleteData] = useState({ name: "", id: "" });
  const [show, setShow] = useState(false);
  const [roleTypesOptions, setRoleTypesOptions] = useState([]);
  const [adminDetails, setAdminDetails] = useState(null);
  const [saved, setSaved] = useState(false);
  const [subLoading, setSubLoading] = useState(false);
  const [passwordVisible, setPasswordVisible] = useState();
  useEffect(() => {
    setLoading(true);
    dispatch(viewAdminDetail(params.id, onSuccess, onError));
    dispatch(roleTypeData(onRoleDataSuccess, onRoleDataError));
  }, []);
  const onSuccess = (data) => {
    setAdminDetails(data?.data);
    setLoading(false);
    setError(false);
  };
  const onError = (data) => {
    errorToast(data.data);
    setLoading(false);
    setError(true);
  };
  const onRoleDataSuccess = (data) => {
    const roleTypes = [];
    data?.data.map((el, index) => {
      roleTypes[index] = { value: el.id, label: el.role_name, id: el.id };
    });
    setRoleTypesOptions(roleTypes);
  };
  const onRoleDataError = (data) => {
    errorToast("Failed to fetch admin role");
    // console.log("ERROR");
  };
  const initialValues = {
    name: adminDetails?.name ? adminDetails.name : "",
    email: adminDetails?.email ? adminDetails.email : "",
    password: adminDetails?.password ? adminDetails.password : "",
    role_id: adminDetails?.role_id ? adminDetails?.role_id : "",
  };
  const loginSchema = Yup.object().shape({
    name: Yup.string().trim().required("Required"),
    email: Yup.string().trim().email().required("Required"),
    password: Yup.string().trim().matches(
      passwordRegex,
      "Eight letters ,One Character,One Number,One Capital,One small letter"
    ),
    role_id: Yup.string().trim().required("Required"),
  });
  const handleSubmit = () => {
    if (formRef.current) {
      formRef.current.handleSubmit();
    }
  };
  console.log(adminDetails);
  const onSubmit = (values) => {
    dispatch(
      editAdmin(
        params?.id,
        {
          "name":"adminC",
          "email":"abcdez@admin.com",
          "role_id":"bfccb5a3-46f1-4f8c-be71-634a00105b1a",
          "password":"admin"
      },
        onSubmitSuccess,
        onSubmitError
      )
    );
  };
  const onSubmitSuccess = (data) => {
    setSaved(true);
    successToast(data.data.message);
  };
  const onSubmitError = (data) => {
    errorToast(data.data);
  };
  const handleDelete = () => {
    setDelLoading(true);
    dispatch(deleteAdmin(deleteData.id, onDeleteSuccess, onDeleteError));
  };
  const onDeleteSuccess = (data) => {
    successToast(data.message);
    setDelLoading(false);
    setShow(false);
    navigate("/admin-users");
    setLoading(false);
    setError(false);
  };
  const onDeleteError = (data) => {
    errorToast(data.data);
    setDelLoading(false);
    setShow(false);
    setLoading(false);
    setError(true);
  };
  const defaultRole = roleTypesOptions?.filter(
    (item) => adminDetails?.role_id === item.value
  );
  const adminSubmit = () => {
    setSubLoading(true);
    dispatch(
      adminSubmitAction(params.id, onAdminSubmitSucess, onAdminSubmitError)
    );
  };
  const buttonList = [
    <ViewBtn link={`/admin-users/view/${adminDetails?.id}`} />,
     <SaveBtn submitFn={handleSubmit} />,
      // <DeleteBtn
      //   showModal={() => {
      //     setShow(true);
      //     setDeleteData({ name: adminDetails.name, id: params.id });
      //   }}
      // />
    // adminDetails?.editable && (
    //   <SubmitBtn saveHide={saved} loader={subLoading} submitFn={adminSubmit} />
    // ),
  ];
  function onAdminSubmitSucess(data) {
    successToast(data.message);
    setSubLoading(false);
    navigate("/admin-users");
  }
  function onAdminSubmitError(data) {
    errorToast(data.data);
    setSubLoading(false);
    errorToast(data.message);
  }

  return (
    <>
      <InnerLayout buttons={buttonList}>
        <div className="admin_user_container">
          <InnerContainer>
            {loading ? (
              <LoadingSpinnerTable />
            ) : (
              <LoadAndError loader={loading} error={error}>
                <div className="pt-3 mb-5 ps-2 ps-md-2 ps-lg-4 ">
                  <h5 className="dark_grey_color fs_13 fn_Montserrat fw_500 m-0">
                    Admin ID
                  </h5>
                  <p className={` fs_13 fn_Nunito fw_500  secondary_color `}>
                    {adminDetails?.id}
                  </p>
                  <Formik
                    innerRef={formRef}
                    initialValues={initialValues}
                    enableReinitialize={true}
                    onSubmit={onSubmit}
                    validationSchema={loginSchema}
                  >
                    <Form className="admin-user-form-width">
                      <InputField
                        id={"name"}
                        label={"Name"}
                        type={"text"}
                        placeholder={"Enter user name"}
                        name={"name"}
                      />
                      <InputField
                        id={"email"}
                        label={"E-Mail"}
                        type={"text"}
                        placeholder={"Enter Email ID"}
                        name={"email"}
                      />
                      <div>
                        <label
                          htmlFor="password"
                          className="ms-2 mb-1  fw_500 fs_14"
                        >
                          Password
                        </label>
                        <Field name={"password"}>
                          {({ field, meta }) => (
                            <div className="position-relative">
                              <input
                                autoComplete={"true"}
                                type={passwordVisible ? "text" : "password"}
                                {...field}
                                placeholder={"Enter your password"}
                                id={"password"}
                                className={
                                  meta.touched && meta.error
                                    ? `w-100    inputField inputField_error__border rounded-3  pb-2  focus_outline__none`
                                    : ` w-100    inputField inputField__border f rounded-3 pb-2 focus_outline__none`
                                }
                              />
                              <img
                                className="login_password_eye__icon cursor_pointer"
                                onClick={() =>
                                  setPasswordVisible(!passwordVisible)
                                }
                                src={passwordVisible ? eyeOn : eyeOff}
                                alt="show password or not"
                              />
                            </div>
                          )}
                        </Field>
                        <ErrorMsg name="password" />
                      </div>
                      <SelectFields
                        default1={defaultRole[0]}
                        label={"Role Name"}
                        placeholder={"Role Name Here"}
                        name={"role_id"}
                        options={roleTypesOptions}
                        id={"role_id"}
                      />
                    </Form>
                  </Formik>
                </div>
              </LoadAndError>
            )}
          </InnerContainer>
        </div>
        <DeleteModal
          loader={delLoading}
          handleClose={() => setShow(false)}
          deleteFn={handleDelete}
          data={deleteData}
          show={show}
        />
      </InnerLayout>
    </>
  );
};

export default AdminEdit;
