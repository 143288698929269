import { authHeader } from "../config";
import { api } from "./api";

export const addHomeApi = (params) => {
    return api.post("/api/admin/homepage/create-homepage", params, {
        headers: {
            "x-access-token": localStorage.getItem("accessToken"),
            "Content-Type": "application/json",
        },
    });
};
export const viewHomeListApi = (page = 0, params) => {
    return api.post(`/api/admin/homepage/view-homepage?page_no=${page}`, params, {
        headers: {
            "x-access-token": localStorage.getItem("accessToken"),
            "Content-Type": "application/json"
        },
    });
};
export const viewHomeApi = (id, params) => {
    return api.post(`/api/admin/homepage/view-approval/${id}`, params, {
        headers: {
            "x-access-token": localStorage.getItem("accessToken"),
            "Content-Type": "application/json",
        },
    });
};
export const editHomeApi = (id, params) => {
    return api.post(`/api/admin/homepage/edit/${id}`, params, {
        headers: {
            "x-access-token": localStorage.getItem("accessToken"),
            "Content-Type": "application/json",
        },
    });
};
export const deleteHomeApi = (id, params) => {
    return api.post(`/api/admin/homepage/delete/${id}`, params, {
        headers: {
            "x-access-token": localStorage.getItem("accessToken"),
            "Content-Type": "application/json",
        },
    });
};
export const approveHomeApi = (params) => {
    return api.post(`/api/admin/homepage/approve`, params, {
        headers: {
            "x-access-token": localStorage.getItem("accessToken"),
            "Content-Type": "application/json",
        },
    });
}
export const rejectHomeApi = (id, params) => {
    return api.post(`/api/admin/homepage/reject/${id}`, params, {
        headers: {
            "x-access-token": localStorage.getItem("accessToken"),
            "Content-Type": "application/json",
        },
    });
};
export const approvalsHomeListpApi = (page = 0, params) => {
    return api.post(`/api/admin/homepage/view-checker?page_no=${page}`, params, {
        headers: {
            "x-access-token": localStorage.getItem("accessToken"),
            "Content-Type": "application/json",
        },
    });
}
export const viewApprovalHomeApi = (id, params) => {
    return api.post(`/api/admin/homepage/view-checker/${id}`, params, {
        headers: {
            "x-access-token": localStorage.getItem("accessToken"),
            "Content-Type": "application/json",
        },
    });
};
export const versionHomeListpApi = (page = 0, params) => {
    return api.post(`/api/admin/homepage/view-version?page_no=${page}`, params, {
        headers: {
            "x-access-token": localStorage.getItem("accessToken"),
            "Content-Type": "application/json",
        },
    });
}
export const viewVersionHomeApi = (id, params) => {
    return api.post(`/api/admin/homepage/view-version/${id}`, params, {
        headers: {
            "x-access-token": localStorage.getItem("accessToken"),
            "Content-Type": "application/json",
        },
    });
};
export const activateHomeApi = (id, params) => {
    return api.post(`/api/admin/homepage/is-active-update/${id}`, params, {
        headers: {
            "x-access-token": localStorage.getItem("accessToken"),
            "Content-Type": "application/json",
        },
    });
};




export const homeMainExportApi = (page, params) => {
    return api.post(
        `/api/admin/homepage/export-main-homepage`,
        JSON.stringify(params),
        {
            headers: {
                "x-access-token": localStorage.getItem("accessToken"),
                "Content-Type": "application/json",
            },
        }
    );
};

export const homeApprovalsExportApi = (page, params) => {
    return api.post(
        `/api/admin/homepage/export-approval-homepage`,
        JSON.stringify(params),
        {
            headers: {
                "x-access-token": localStorage.getItem("accessToken"),
                "Content-Type": "application/json",
            },
        }
    );
};

export const homeVersionExportApi = (page, params) => {
    return api.post(
        `/api/admin/homepage/export-version-homepage`,
        JSON.stringify(params),
        {
            headers: {
                "x-access-token": localStorage.getItem("accessToken"),
                "Content-Type": "application/json",
            },
        }
    );
};


export const homeSubmitApi = (id, params) => {
    return api.post(
        `/api/admin/homepage/on-submit/${id}`, JSON.stringify(params),
        {
            headers: {
                "x-access-token": localStorage.getItem("accessToken"),
                "Content-Type": "application/json",
            },
        }
    );
};
