import React, { useState } from "react";
import {} from "formik";

import Select, { components } from "react-select";
import "../../../components/form/selectFields/selectFields.css";
import ArrowRightIcon from "../../../assets/icons/arrowrightIcon";

const SelectMultiFields = ({
  disabled = true,
  label,
  default1 = [
    {
      label: "",
      value: "",
    },
  ],
  Icon = <ArrowRightIcon width={15} fill={"#A5A5A5"} />,
  name,
  options,
  type,
  containerClassName = "mb-2",
  placeholder,
  id,
  selectedError = false,
  selectedOptions = [],
  updateSelected = () => {},
}) => {
  const [changed, whenChanged] = useState(false);
  const DropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator {...props}>
        {Icon}
      </components.DropdownIndicator>
    );
  };
  
  return (
    <div className={`${containerClassName}`}>
      <label
        htmlFor="email"
        className="mb-2 ps-2 dark_grey_color fn_Montserrat fw_500 fs_14"
      >
        {label}
      </label>

      <Select
        // isClearable={false}
        isDisabled={disabled}
        defaultValue={default1}
        value={
          changed
            ? options.find((item) => item.value === selectedOptions)
            : default1
        }
        name={name}
        id={id}
        isMulti
        components={{ DropdownIndicator }}
        styles={customStyles}
        type={type}
        className={
          selectedError
            ? `select-input-field w-100    selectField_error__border   focus_outline__none`
            : `select-input-field w-100     selectField__border   focus_outline__none`
        }
        placeholder={placeholder}
        options={options}
        onChange={(selectedOption) => {
          whenChanged(true);
          updateSelected(selectedOption);
        }}
      />
    </div>
  );
};

export default SelectMultiFields;
export const customStyles = {
  indicatorSeparator: () => ({ display: "none" }),
  control: (base, state) => ({
    ...base,
    color: "#464646",
    fontFamily: "Nunito",
    fontWeight: "400",
    fontSize: "15px",
    borderRadius: "10px",
    width: "100%",
    borderColor: state.isFocused ? "transparent" : "transparent",
    boxShadow: state.isFocused ? null : null,
    "&:hover": {},
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? "#0000002E" : null,
    borderBottom: "1px solid #F5F5F5",
    fontFamily: "Nunito",
    "&:hover": {
      backgroundColor: state.isFocused ? "#0000002E" : "#0000002E",
    },
  }),
  placeholder: (defaultStyles) => {
    return {
      ...defaultStyles,
      color: "#464646",
      fontFamily: "Nunito",
      fontSize: "14px",
      fontWeight: "400",
      opacity: "0.9",
      position: "absolute",
    };
  },
  dropdownIndicator: (provided) => ({
    ...provided,
    svg: {
      fill: "#323D5A",
    },
  }),
};
