import React, { useEffect, useRef, useState } from "react";
import DeleteBtn from "../../../../components/buttons/deleteBtn";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useNavigate, } from 'react-router-dom'
import successToast from "../../../../components/utilits/successToast";
import errorToast from "../../../../components/utilits/errorToast";
import InnerContainer from "../../../../components/layouts/innerContainer/innerContainer";
import InnerLayout from "../../../../components/layouts/innerLayout/innerLayout";
import "../notify-me.css";
import SelectFields from "../../../../components/form/selectFields/selectFields";
import SaveBtn from "../../../../components/buttons/saveBtn";
import { deleteNotifyMe, editNotifyMe, notifyMeCreateOptions, readNotifyMe } from "../../../../redux/actions/notifyMeActions";
import DeleteModal from "../../../../components/modals/deleteModal";
import { opportunitiesNameListAction } from "../../../../redux/actions/opportunitiesActions";
import { editMemberTrade, memberSubmit, readMemberTrade } from "../../../../redux/actions/memberTradeAction";
import { flush } from "redux-saga/effects";
import LoadAndError from "../../../../components/utilits/LoadAndError";
import LoadingSpinnerTable from "../../../../components/utilits/loadingSpinnerTable";
import SubmitBtn from "../../../../components/buttons/submit";
const MemberTradeEdit = () => {
    const [submitData, setSubmitData] = useState();
    const navigate = useNavigate();
    const handleShow = () => setShow(true);
    const handleClose = () => setShow(false);
    const [show, setShow] = useState(false);
    const params = useParams()
    const [updated, setUpdated] = useState(false)
    const formRef = useRef()
    const dispatch = useDispatch();
    const [error, setError] = useState(false)
    const [loading, setLoading] = useState(false)

    // for default value
    const [notifyMeViewData, SetNotifyMeViewData] = useState({ id: "", name: "", createdAt: "", updatedAt: "" })
    console.log(notifyMeViewData.notifyMeViewData?.user_name)
    useEffect(() => {
        setLoading(true);
        dispatch(
            readMemberTrade(params.id, onSuccess, onError)
        );
    }, [params.id]);
    const onSuccess = (data) => {
        SetNotifyMeViewData(data.data)
        console.log(data.data, "fjdsgdshdfggfdgfgf");
        setLoading(false)
        setError(false)
    };
    const onError = (data) => {
        errorToast(data.message)
        setLoading(false)
        setError(true)
    };


    /** sropdown* */
    const [userName, setUserName] = useState([]);
    const [opportunityType, setOpportunityType] = useState([]);
    useEffect(() => {
        dispatch(notifyMeCreateOptions(onNotifyMeSuccess, onNotifyMeError));
    }, []);
    const userNameOptions = [];
    const opportunityTypeOptions = [];
    const onNotifyMeSuccess = (data) => {
        data?.data?.AllUsers.map((item, index) => {
            userNameOptions[index] = { value: item.id, label: item.user_name };
            console.log();
        });
        data?.data?.AllOpportunities?.map((el, index) => {
            opportunityTypeOptions[index] = { value: el.id, label: el.name };
            console.log(el.id);
        });
        console.log(userNameOptions);
        console.log(opportunityTypeOptions);
        setUserName(userNameOptions);
        setOpportunityType(opportunityTypeOptions);
        console.log("SUCCESS");
    };
    const onNotifyMeError = (data) => {
        console.log("ERROR");
    };
    // for edit api
    const [notifyMeData, setNotifyMeData] = useState()
    const initialValues = {
        username: notifyMeViewData?.user_id,
        opportunity: notifyMeViewData?.opportunity?.id,
    };

    const loginSchema = Yup.object().shape({
        username: Yup.string().trim().required("Required"),
        opportunity: Yup.string().trim().required("Required"),
    });
    const handleSubmit = () => {
        if (formRef.current) {
            formRef.current.handleSubmit()
        }
    }
    const onSubmit = (values, { resetForm }) => {
        dispatch(
            editMemberTrade(
                params.id,
                {
                    user_id: values?.username,
                    opportunity_id: values?.opportunity
                },
                onEditSuccess, onEditError)
        );
        setUpdated(!updated)
    };
    const onEditSuccess = (data) => {
        setNotifyMeData(data.data)
        successToast(data.message)
        setLoading(false)
        setError(false)
        setSubmitData(data.data.id)
        setSaveHide(true)
    };
    const onEditError = (data) => {
        console.log(data);
        errorToast(data.message)
        setLoading(false)
        setError(true)
    };


    //opp id
    const [opportunitiesList, setOpportunitiesList] = useState([])
    useEffect(() => {
        dispatch(opportunitiesNameListAction(onSuccessOpt, onErrorOpt));
    }, []);
    const onSuccessOpt = (data) => {
        const categoryList = [];
        data?.data.map((item, index) => {
            categoryList[index] = { value: item.id, label: `${item.title}${" "}id:${item.id}` };
        });
        setOpportunitiesList(categoryList);
        console.log("SUCCESS");
    };
    const onErrorOpt = (data) => {
        console.log(data);
    };
    //on submit api
    console.log(submitData);
    const [saveHide, setSaveHide] = useState(false);

    const submitFnc = () => {
        dispatch(memberSubmit
            (
                submitData,
                onSubmitSuccess,
                onSubmitError,
            ));
    };
    const onSubmitSuccess = (data) => {
        console.log(data);
        successToast(data.message)
        navigate("/opportunity/member-trade")
    };
    const onSubmitError = (data) => {
        console.log("ERROR==================", data);
        errorToast(data.message)

    };

    const defaultUser = userName.filter((item) => {
        return item.value === notifyMeViewData?.user_id
    })
    const buttonList = [
        !saveHide && <SaveBtn submitFn={handleSubmit}
            loader={loading} />,
        <SubmitBtn submitFn={submitFnc} saveHide={saveHide} />];
    return (
        <InnerLayout buttons={buttonList}>
            <div className="notify-me-container">
                <InnerContainer>
                    {loading ? <LoadingSpinnerTable /> : (
                        <LoadAndError loader={loading} error={error}>
                            <div className="pt-3  ps-4 ps-lg-4 ms-3">
                                <h5 className="dark_grey_color fs_13 fn_Montserrat fw_500 m-0">
                                    Member Trade ID
                                </h5>
                                <p
                                    className={` fs_13 fn_Nunito fw_500 dark_title_color  `}>
                                    {notifyMeViewData.id}
                                </p>
                            </div>
                            <div className="  ps-4 ps-lg-4 ms-3">
                                <Formik
                                    innerRef={formRef}
                                    initialValues={initialValues}
                                    onSubmit={onSubmit}
                                    validationSchema={loginSchema}
                                >
                                    <Form className='notify-me-form-width'>
                                        <SelectFields
                                            default1={defaultUser}
                                            label={"User Name"}
                                            placeholder={"User name here"}
                                            name={"username"}
                                            options={userName}
                                            id={"username"}
                                        />
                                        <SelectFields
                                            default1={{
                                                label: notifyMeViewData?.opportunity?.title
                                                , value: notifyMeViewData?.opportunity?.id
                                            }}
                                            label={"Opportunity Name"}
                                            placeholder={"Opportunity here"}
                                            name={"opportunity"}
                                            options={opportunitiesList}
                                            id={"opportunity"}
                                        />
                                    </Form>
                                </Formik>
                            </div>
                        </LoadAndError>
                    )}
                </InnerContainer>
            </div>
        </InnerLayout>
    );
};

export default MemberTradeEdit;

export const username = [
    { value: "Iman", label: "Iman" },
    { value: "Abijith", label: "Abijith" },
];
export const opportunityType = [
    { value: "Type1", label: "type1" },
    { value: "type2", label: "type2" },
];

