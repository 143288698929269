import React, { useRef, useState } from "react";
import Modal from "react-bootstrap/Modal";
import CloseIcon from "../../../../assets/icons/closeIcon";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import "./opportunitiesmodals.css";
import InputField from "../../../form/inputFields/inputField";
import SelectFields from "../../../form/selectFields/selectFields";
import ArrowDownIcon from "../../../../assets/icons/arrowdown";
import { useDispatch } from "react-redux";
import ImageUploadField from "../../../form/inputFields/imageUploadField";
import { uploadAddressProofFrontImageAction, uploadProfileImageAction } from "../../../../redux/actions/userAction";
import { useEffect } from "react";

const OverViewCard = ({ show, handleClose, data = {}, formikValues, id, reasonUpdator }) => {
  const formRef = useRef();
  const [icon_image_location, setIcon_image_location] = useState(null);
  const [updated, setUpdated] = useState(false);
  const dispatch = useDispatch();
  const [file, setFile] = useState(
    { name: formikValues[id]?.icon_image_location ? formikValues[id]?.icon_image_location : "" }
  );
  const [fileUrl, setFileUrl] = useState({
    url: formikValues[id]?.icon_image_location ? formikValues[id]?.icon_image_location : "",
    error: false,
  });
  const initialValues = {
    title: id === 0 || id ? formikValues[id]?.title : "",
    sub_title: id === 0 || id ? formikValues[id]?.sub_title : "",
    info_box: id === 0 || id ? formikValues[id]?.info_box : "",
  };

  const loginSchema = Yup.object().shape({
    title: Yup.string().trim(),
    sub_title: Yup.string().trim(),
    info_box: Yup.string().trim(),
  });

  const onSubmit = (values, { resetForm }) => {
    console.log("helkl0p");
    // if (fileUrl.url) {
      resetForm();
      reasonUpdator(id, values, fileUrl.url)
      handleClose()
      setFileUrl({ url: "", error: false })
      setFile("")
    // }
    // else {
    //   setFileUrl({ url: "", error: true });
    // }
  };
  useEffect(() => {
    setFileUrl(
      {
        url: formikValues[id]?.icon_image_location ? formikValues[id]?.icon_image_location : "",
        error: false,
      }
    );
    setFile({ name: formikValues[id]?.icon_image_location })
  }, [updated, formikValues[id]]);
  function handleUploadFile(e) {
    if (e.target?.files.length !== 0) {
      setFile(e.target.files[0]);
      dispatch(
        uploadAddressProofFrontImageAction(
          e.target.files[0],
          onUploadFileSuccess,
          onUploadFileError
        )
      );
    }
    e.target.value = null;
  }
  const onUploadFileSuccess = (data) => {
    setFileUrl({ url: data.data.data.location, error: false });
    // console.log(data.data.data);
  };
  const onUploadFileError = (data) => {
    setFileUrl({ url: "", error: true });
    console.log(data);
  };



  return (
    <Modal
      centered
      show={show}
      onHide={() => {
        handleClose(); setUpdated(!updated)
      }}
      dialogClassName={`oppoptunities_modals_body mx-auto `}
      contentClassName={"oppoptunities_modals_body_content  p-2"}
    >
      <Modal.Body>
        <>
          <div className="d-flex justify-content-between  ">
            <h2 className="green_color fs_15 fn_Montserrat fw_600 pt-1">
              OVERVIEW
            </h2>
            <button
              type="button"
              onClick={() => { handleClose(); setUpdated(!updated) }}
              className="light_grey_color  fs_15 fn_Montserrat bg-transparent border-0 fw_600 "
            >
              <span className="pt-1">DISMISS</span>
              <span className="ms-2">
                <CloseIcon width={13} height={13} fill="#A5A5A5" />
              </span>
            </button>
          </div>
          <Formik
            innerRef={formRef}
            initialValues={initialValues}
            onSubmit={onSubmit}
            validationSchema={loginSchema}
          >
            <Form className="mt-3">
              <InputField
                id={"title"}
                label={"Title"}
                type={"text"}
                placeholder={"Enter Title"}
                name={"title"}
              />
              <InputField
                id={"subtitle"}
                label={"Subtitle"}
                type={"text"}
                placeholder={"Enter Subtitle"}
                name={"sub_title"}
              />
              <InputField
                id={"inbox"}
                label={"Info Box"}
                type={"text"}
                placeholder={"Enter Info box content"}
                name={"info_box"}
              />
              <div>
                <span className="dark_grey_color fn_Montserrat fw_500 fs_14  ps-2">
                  Upload
                </span>
                <div className="llp_document_upload d-flex justify-content-between align-items-center pe-3">
                  <input
                    className="position-absolute"
                    type={"file"}
                    style={{ visibility: "hidden" }}
                    id="overviewCardfile"
                    name="fileUrl"
                    onChange={(e) => {
                      handleUploadFile(e);
                    }}
                  />
                  <label className=" w-75 py-2 ps-3  " htmlFor="overviewCardfile">
                    {
                      <span>
                        {fileUrl.url ? file.name : "Click to upload"}
                      </span>
                    }
                  </label>
                  {fileUrl.url && <span
                    onClick={() => {
                      setFileUrl({ url: "", error: true });
                      setFile("");
                    }}
                  >
                    <CloseIcon width={10} height={10} fill="#A5A5A5" />
                  </span>}
                </div>
                {fileUrl.error && (
                  <span className="fs_12 ms-2 text-danger fw-semibold fn_Nunito">
                    Required!
                  </span>
                )}
              </div>
              <div className="d-flex justify-content-end mt-4">
                <button
                  type="submit"
                  className="secondary_color fs_15  fn_Montserrat fw_600 border-0 tertiary_bg px-4 px-lg-5 py-2 py-lg-2 rounded-2"
                >
                  {id === null ? "CREATE" : "UPDATE"}
                </button>
              </div>
            </Form>
          </Formik>
        </>
      </Modal.Body>
    </Modal>
  );
};
export default OverViewCard;
export const selectPriority = [
  { value: "Priority1", label: "Priority1" },
  { value: "Priority2", label: "Priority2" },
  { value: "Priority2", label: "Priority3" },
  { value: "Priority4", label: "Priority4" },
];
