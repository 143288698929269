import React, { useRef } from "react";
import Modal from "react-bootstrap/Modal";
import CloseIcon from "../../../../assets/icons/closeIcon";
import "./modals.css";

const OurTeamProfileImage = ({
    show,
    handleClose,
    image,
    popuHeading = "PAN CARD IMAGE",
    modalHeading = "PROFILE PICTURE",
    Profileimage
}) => {
    console.log(Profileimage);
    return (
        <Modal
            centered
            show={show}
            onHide={handleClose}
            dialogClassName={`image_modals_body mx-2 px-1 mx-sm-auto`}
            contentClassName={"delete_modals_body_content  p-1"}
        >
            <Modal.Body>
                <>
                    <div className="d-flex justify-content-between  ">
                        <h2 className="black_color_popup fs_15 fs_sm_13 fn_Montserrat fw_600 pt-2">
                            {modalHeading}
                        </h2>
                        <button
                            onClick={handleClose}
                            className="light_grey_color  fs_15 fs_sm_13 fn_Montserrat bg-transparent border-0 fw_600 "
                        >
                            <span>DISMISS</span>
                            <span className="ms-2">
                                <CloseIcon width={13} height={13} fill="#A5A5A5" />
                            </span>
                        </button>
                    </div>
                    <div className="image_view_popup_size ">
                        <img
                            src={Profileimage}
                            className={`h-100 w-100 rounded-3`}
                        />
                    </div>
                </>
            </Modal.Body>
        </Modal>
    );
};
export default OurTeamProfileImage;
