import { authHeader } from "../config";
import { api } from "./api";
export const addReferedUserApi = (params) => {
  return api.post("/api/admin/admin-referral/create", params, {
    headers: {
      "x-access-token": localStorage.getItem("accessToken"),
      "Content-Type": "application/json",
    },
  });
};
export const editReferedUserApi = (id, params) => {
  return api.post(`/api/admin/admin-referral/edit/${id}`, params, {
    headers: {
      "x-access-token": localStorage.getItem("accessToken"),
      "Content-Type": "application/json",
    },
  });
};
export const deleteReferedUserApi = (id, params) => {
  return api.post(
    `/api/admin/admin-referral/delete/${id}`,
    JSON.stringify(params),
    {
      headers: {
        "x-access-token": localStorage.getItem("accessToken"),
        "Content-Type": "application/json",
      },
    }
  );
};
export const viewAllReferedUserApi = (pageNo, params) => {
  return api.post(
    `/api/admin/admin-referral/view-approvals?page_no=${pageNo}`,
    params,
    {
      headers: {
        "x-access-token": localStorage.getItem("accessToken"),
        "Content-Type": "application/json",
      },
    }
  );
};
export const viewReferedUserApi = (id = "", params) => {
  return api.post(
    `/api/admin/admin-referral/view-approval/${id}`,
    JSON.stringify(params),
    {
      headers: {
        "x-access-token": localStorage.getItem("accessToken"),
        "Content-Type": "application/json",
      },
    }
  );
};
export const rejectReferedUserApi = (id = "", params) => {
  return api.post(
    `/api/admin/admin-referral/reject/${id}`,
    JSON.stringify(params),
    {
      headers: {
        "x-access-token": localStorage.getItem("accessToken"),
        "Content-Type": "application/json",
      },
    }
  );
};
export const approveReferedUserApi = (params) => {
  return api.post(`/api/admin/admin-referral/approve`, params, {
    headers: {
      "x-access-token": localStorage.getItem("accessToken"),
      "Content-Type": "application/json",
    },
  });
};
export const approvalsListReferedUserApi = (pageNo, params) => {
  return api.post(
    `/api/admin/admin-referral/view-checker?page_no=${pageNo}`,
    params,
    {
      headers: {
        "x-access-token": localStorage.getItem("accessToken"),
        "Content-Type": "application/json",
      },
    }
  );
};
export const viewApprovalReferedUserApi = (id, params) => {
  return api.post(
    `/api/admin/admin-referral/view-checker/${id}`,
    JSON.stringify(params),
    {
      headers: {
        "x-access-token": localStorage.getItem("accessToken"),
        "Content-Type": "application/json",
      },
    }
  );
};
export const versionHistoryListReferedUserApi = (pageNo = 0, params) => {
  return api.post(
    `/api/admin/referred_user/view-version?page_no=${pageNo}`,
    params,
    {
      headers: {
        "x-access-token": localStorage.getItem("accessToken"),
        "Content-Type": "application/json",
      },
    }
  );
};
export const viewVersionHistoryReferedUserApi = (id, params) => {
  return api.post(
    `/api/admin/referred_user/view-version/${id}`,
    JSON.stringify(params),
    {
      headers: {
        "x-access-token": localStorage.getItem("accessToken"),
        "Content-Type": "application/json",
      },
    }
  );
};
export const referedUserTableExportApi = (params) => {
  return api.post(
    `/api/admin/admin-referral/export-main`,
    JSON.stringify(params),
    {
      headers: {
        "x-access-token": localStorage.getItem("accessToken"),
        "Content-Type": "application/json",
      },
    }
  );
};
export const referedUserApprovalsExportApi = (params) => {
  return api.post(
    `/api/admin/admin-referral/export-approval`,
    JSON.stringify(params),
    {
      headers: {
        "x-access-token": localStorage.getItem("accessToken"),
        "Content-Type": "application/json",
      },
    }
  );
};
export const referedUserVersionExportApi = (params) => {
  return api.post(
    `/api/admin/admin-referral/export-version`,
    JSON.stringify(params),
    {
      headers: {
        "x-access-token": localStorage.getItem("accessToken"),
        "Content-Type": "application/json",
      },
    }
  );
};
export const referedUserSubmitApi = (id, params) => {
  return api.post(
    `/api/admin/admin-referral/on-submit/${id}`,
    JSON.stringify(params),
    {
      headers: {
        "x-access-token": localStorage.getItem("accessToken"),
        "Content-Type": "application/json",
      },
    }
  );
};
