import React, { useState } from "react";
import { useDispatch } from "react-redux";
import ExportBtn from "../../../components/buttons/exportBtn";
import FilterBtn from "../../../components/buttons/filterBtn";
import AdminUserFilter from "../../../components/filter/adminUserFilter";
import UserFilter from "../../../components/filter/userFilter/userFilter";
import InnerLayout from "../../../components/layouts/innerLayout/innerLayout";
import UserApprovalsTableContent from "../../../components/user/tables/userApprovalsTableContent";
import errorToast from "../../../components/utilits/errorToast";
import FileSaver from "file-saver";
import { userApprovalsExport } from "../../../redux/actions/userAction";

const UserApprovalsTable = () => {
  const dispatch = useDispatch()
  const [page, setPage] = useState(0);
  const [showFilter, setShowFilter] = useState(false);
  const [search, setSearch] = useState("");
  const handleFilterClose = () => {
    setShowFilter(false);
  };
  const handleFilterOpen = () => {
    setShowFilter(true);
  };
  const handleSearch = (value) => { setSearch(value); setPage(0) };
  const handlePage = (type) => {
    if (type === "+") {
      setPage((prev) => prev + 1)
    }
    else {
      setPage((prev) => prev - 1)
    }
  }
  const [exportData, setExportData] = useState();
  const exportFnc = () => {
    dispatch(userApprovalsExport
      (
        onSuccess,
        onError,
      ));
  };

  const onSuccess = (data) => {
    console.log(data);
    setExportData(data);
    var type =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8";
    var blob = new Blob([data], { type: type });
    FileSaver.saveAs(blob, "file.xlsx");
  };
  const onError = (data) => {
    console.log("ERROR==================", data);
    errorToast("Failed to export data")
  };
  const buttonList = [<ExportBtn dispatchAction={userApprovalsExport} />, <FilterBtn filterFn={handleFilterOpen} />];
  return (
    <InnerLayout buttons={buttonList}>
      <UserFilter
        showFilter={showFilter}
        closeFilter={handleFilterClose}
        filter={handleSearch}
      />
      <UserApprovalsTableContent page={page} changePage={handlePage} search={search} />
    </InnerLayout>
  );
};

export default UserApprovalsTable;
