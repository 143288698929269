import { APPROVE_DOCUMENT, CREATE_DOCUMENT, DELETE_DOCUMENT, EDIT_DOCUMENT, DOCUMENT_APPROVALS_EXPORT, DOCUMENT_APPROVAL_TABLE, DOCUMENT_APPROVAL_VIEW, DOCUMENT_EXPORT, DOCUMENT_OPTIONS, DOCUMENT_SUBMIT, DOCUMENT_TABLE, DOCUMENT_VERSION_EXPORT, DOCUMENT_VERSION_HISTORY, DOCUMENT_VERSION_HISTORY_TABLE, REJECT_DOCUMENT, VIEW_DOCUMENT } from "./types";


export const createDocument = (
    data,
    onSuccess,
    onError
) => {
    return {
        type: CREATE_DOCUMENT,
        data,
        onSuccess,
        onError,
    };
};

export const approveDocument = (id, onSuccess, onError) => {
    return {
        type: APPROVE_DOCUMENT,
        id,
        onSuccess,
        onError,
    };
};

export const rejectDocument = (id, onSuccess, onError) => {
    return {
        type: REJECT_DOCUMENT,
        id,
        onSuccess,
        onError,
    };
};

export const viewDocumentDetail = (id, onSuccess, onError) => {
    return {
        type: VIEW_DOCUMENT,
        id,
        onSuccess,
        onError,
    };
};

export const viewDocumentList = (data, page_no = 0, onSuccess, onError) => {
    return {
        type: DOCUMENT_TABLE,
        data,
        page_no,
        onSuccess,
        onError,
    };
};

export const editDocument = (
    id,
    data,
    onSuccess,
    onError
) => {
    return {
        type: EDIT_DOCUMENT,
        id,
        data,
        onSuccess,
        onError,
    };
};


export const deleteDocument = (id, onSuccess, onError) => {
    return {
        type: DELETE_DOCUMENT,
        id,
        onSuccess,
        onError,
    };
};



export const documentApprovalList = (data, page_no = 0, onSuccess, onError) => {
    return {
        type: DOCUMENT_APPROVAL_TABLE,
        data,
        page_no,
        onSuccess,
        onError,
    };
};

export const documentApprovalById = (id, onSuccess, onError) => {
    return {
        type: DOCUMENT_APPROVAL_VIEW,
        id,
        onSuccess,
        onError,
    };
};


export const versionHistoryDocumentList = (data, page, onSuccess, onError) => {
    return {
        type: DOCUMENT_VERSION_HISTORY_TABLE,
        data,
        page,
        onSuccess,
        onError,
    };
};

export const versionHistoryDocumentDetail = (id, onSuccess, onError) => {
    return {
        type: DOCUMENT_VERSION_HISTORY,
        id,
        onSuccess,
        onError,
    };
};
export const documentOptions = (onSuccess, onError) => {
    return {
        type: DOCUMENT_OPTIONS,
        onSuccess,
        onError,
    };
};

export const documentTableExport = (onSuccess, onError) => {
    return {
        type: DOCUMENT_EXPORT,
        onSuccess,
        onError,
    };
};
export const documentApprovalsExport = (onSuccess, onError) => {
    return {
        type: DOCUMENT_APPROVALS_EXPORT,
        onSuccess,
        onError,
    };
};
export const documentVersionExport = (onSuccess, onError) => {
    return {
        type: DOCUMENT_VERSION_EXPORT,
        onSuccess,
        onError,
    };
};



export const documentSubmit = (id, onSuccess, onError) => {
    return {
        type: DOCUMENT_SUBMIT,
        id,
        onSuccess,
        onError,
    };
};



