import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import InnerLayout from "../../../../components/layouts/innerLayout/innerLayout";
import InnerContainer from "../../../../components/layouts/innerContainer/innerContainer";
import VersionHistoryBtn from "../../../../components/buttons/versionHistoryBtn";
import ApprovalsBtn from "../../../../components/buttons/approvalsBtn";
import RejectBtn from "../../../../components/buttons/rejectBtn";
import ApproveBtn from "../../../../components/buttons/approveBtn";
import errorToast from "../../../../components/utilits/errorToast";
import successToast from "../../../../components/utilits/successToast";
import moment from "moment";
import {
  approvalsViewHowItWorksAction,
  approveHowItWorksAction,
  rejectHowItWorksAction,
} from "../../../../redux/actions/howItWorksAction";
import HowItWorksReasonTable from "../../../../components/howItWorks/howItWorks/tables/howItWorksReasonTable";
import DownloadIcon from "../../../../assets/icons/downloadIcon";
import HowItWorksOpportunityTable from "../../../../components/howItWorks/howItWorks/tables/howItWorksOpportunityTable";
import HowItWorksContentFourTable from "../../../../components/howItWorks/howItWorks/tables/howItWorksContentFourTable";
import LoadAndError from "../../../../components/utilits/LoadAndError";

const HowItWorksApprovalDetails = () => {
  const navigate = useNavigate();
  const [howItWorksData, setHowItWorksData] = useState({});
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [operation, setOperation] = useState("");
  const [howItWorksDataId, SetHowItWorksDataId] = useState({ id: "" });

  const params = useParams();
  useEffect(() => {
    setLoading(true);
    dispatch(approvalsViewHowItWorksAction(params.id, onSuccess, onError));
  }, [params.id]);
  const onSuccess = (data) => {
    SetHowItWorksDataId(data);
    setOperation(
      data?.data?.new_data == null
        ? "Delete"
        : data?.data?.new_data && data?.data?.old_data
        ? "Edit"
        : data?.data?.old_data == null
        ? "Create"
        : ""
    );
    setHowItWorksData(
      data?.data?.new_data === null
        ? data?.data?.old_data
        : data?.data?.new_data
    );
    console.log(data.data);
    setLoading(false);
    setError(false);
  };
  const onError = (data) => {
    errorToast(data.message);
    console.log(data, "error");
    setLoading(false);
    setError(true);
  };

  // Approve Notify Me
  const [updated, setUpdated] = useState(false);

  const approveHowItWorksFn = () => {
    dispatch(
      approveHowItWorksAction(params.id, onApproveSuccess, onApproveError)
    );
  };

  const onApproveSuccess = (data) => {
    navigate("/static-pages/how-It-Works/approvals");
    successToast(data.message);
    setLoading(false);
    setError(false);
  };
  const onApproveError = (data) => {
    console.log(data);
    errorToast(data.data + " " + data.message);
    setLoading(false);
    setError(true);
  };
  // Reject  Notify Me Api
  const rejectHowItWorksFn = () => {
    dispatch(rejectHowItWorksAction(params.id, onRejectSuccess, onRejectError));
  };

  const onRejectSuccess = (data) => {
    successToast(data.message);
    navigate("/static-pages/how-It-Works/approvals");
    setLoading(false);
    setError(false);
  };
  const onRejectError = (data) => {
    console.log(data);
    errorToast(data.data + " " + data.message);
    setLoading(false);
    setError(true);
  };

  function ViewItemGenerator(title, details) {
    return (
      <>
        <span className="dark_grey_color">{title}</span>
        <p className="dark_title_color">{details}</p>
      </>
    );
  }
  const data1 = [
    {
      heading: "Approval ID",
      title: howItWorksDataId?.data?.id,
    },
  ];

  const buttonList = [
    <VersionHistoryBtn link={`/static-pages/how-It-Works/version-history`} />,
    <ApproveBtn approveFn={approveHowItWorksFn} />,
    <RejectBtn showModal={rejectHowItWorksFn} />,
  ];
  return (
    <InnerLayout buttons={buttonList}>
      <div className="user_view_container mb-4">
        <InnerContainer
          error={error}
          oldData={howItWorksData?.old_data}
          newData={howItWorksData?.new_data}
        >
          <LoadAndError loader={loading} error={error}>
            <div className="d-flex flex-column flex-lg-row gap-4 fs_14 py-3 px-3 px-lg-5">
              <div className="col-12 col-lg-6 pe-0 pe-lg-5">
                {data1.map((item) => (
                  <div className="mb-2">
                    <h5 className=" fs_13 fn_Montserrat fw_500 m-0">
                      {item.heading}
                    </h5>
                    <>
                      {" "}
                      <p
                        className={` fs_13 fn_Nunito fw_500  ${
                          item.heading === "LinkedIn URL" ||
                          item.heading === "User ID"
                            ? "secondary_color"
                            : "dark_title_color"
                        } `}
                      >
                        {item.title}
                      </p>
                    </>
                  </div>
                ))}
                <div>{ViewItemGenerator("Title", howItWorksData?.title)}</div>
                <div>
                  {ViewItemGenerator("Subtitle", howItWorksData?.subtitle)}
                </div>
                <div>
                  {ViewItemGenerator(
                    "Video Title",
                    howItWorksData?.video_title
                  )}
                </div>
                <div>
                  {ViewItemGenerator("Video URL", howItWorksData?.video_url)}
                </div>
                <div>
                  {ViewItemGenerator("How It Works Id", howItWorksData?.id)}
                </div>
                <div>
                  {ViewItemGenerator(
                    "Reason Title",
                    howItWorksData?.reason_title
                  )}
                </div>
                <div>
                  {ViewItemGenerator(
                    "Reason Subtitle",
                    howItWorksData?.reason_subtitle
                  )}
                </div>
                <HowItWorksReasonTable
                  name="reasons"
                  formikValues={howItWorksData}
                  editable={false}
                />
                <div>
                  {ViewItemGenerator(
                    "Section 2 Title",
                    howItWorksData?.section2_title
                  )}
                </div>
                <div>
                  {ViewItemGenerator(
                    "Section 2 Subtitle",
                    howItWorksData?.section2_sub_title
                  )}
                </div>
                <div className="d-flex gap-5 flex-wrap mb-2">
                  <div>
                    <span className="">Section 2 Mobile Image</span>{" "}
                    <div className="blog_create_image_container mt-2 position-relative d-flex  justify-content-center align-items-center">
                      <img
                        src={howItWorksData?.section2_image_desktop}
                        className={"user-edit-profile-picture"}
                      />
                      <button
                        type="button"
                        className="position-absolute border-0 bg-white d-flex align-items-center fs_10 top-0 end-0"
                      >
                        <a
                          href={howItWorksData?.section2_image_desktop}
                          download="file"
                        ></a>
                        <DownloadIcon width={8} />
                        <span className="ms-1">Download</span>
                      </button>
                    </div>
                  </div>
                  <div>
                    {" "}
                    <span className="">Section 2 Mobile Image</span>{" "}
                    <div className="blog_create_image_container mt-2 position-relative d-flex  justify-content-center align-items-center">
                      <a
                        href={howItWorksData?.section2_image_mobile}
                        download="file"
                      ></a>

                      <img
                        src={howItWorksData?.section2_image_mobile}
                        className={"user-edit-profile-picture"}
                      />
                      <button
                        type="button"
                        className="position-absolute border-0 bg-white d-flex align-items-center fs_10 top-0 end-0"
                      >
                        <a
                          href={howItWorksData?.section2_image_mobile}
                          download="file"
                        ></a>
                        <DownloadIcon width={8} />
                        <span className="ms-1">Download</span>
                      </button>
                    </div>
                  </div>
                </div>
                <div className="mt-5">
                  <span className="">Thumb Nail Image</span>{" "}
                  <div className="blog_create_image_container mt-2 position-relative d-flex  justify-content-center align-items-center">
                    <img
                      src={howItWorksData?.you_tube_tumbnail_image}
                      className={"user-edit-profile-picture"}
                    />
                    <button
                      type="button"
                      className="position-absolute border-0 bg-white d-flex align-items-center fs_10 top-0 end-0"
                    >
                      <a
                        href={howItWorksData?.you_tube_tumbnail_image}
                        download="file"
                      ></a>
                      <DownloadIcon width={8} />
                      <span className="ms-1">Download</span>
                    </button>
                  </div>
                </div>
              </div>
              <div className="col-12 col-lg-6  pe-0 pe-lg-5">
                <div>
                  {ViewItemGenerator(
                    "Section 3 Title",
                    howItWorksData?.section3_title
                  )}
                </div>
                <div>
                  {ViewItemGenerator(
                    "Section 3 Button",
                    howItWorksData?.section3_button
                  )}
                </div>
                <HowItWorksOpportunityTable
                  formikValues={howItWorksData}
                  name={"section3_opportunities"}
                  editable={false}
                />
                <div>
                  {ViewItemGenerator(
                    "Section 4 Title",
                    howItWorksData?.section4_title
                  )}
                </div>
                <HowItWorksContentFourTable
                  formikValues={howItWorksData}
                  name={"section4_content"}
                  editable={false}
                />
                <div>
                  {ViewItemGenerator(
                    "Approved At",
                    howItWorksData?.approvedAt
                      ? howItWorksData?.approvedAt
                      : "-"
                  )}
                </div>
                <div>
                  {ViewItemGenerator(
                    "Updated At",
                    moment(howItWorksData?.updatedAt).format(
                      "DD-MM-YYYY HH:mm:ss"
                    )
                  )}
                </div>
                <div>
                  <span className="dark_grey_color">Operation Type</span>
                  <p className="dark_title_color">{operation} </p>
                </div>
              </div>
            </div>
          </LoadAndError>
        </InnerContainer>
      </div>
    </InnerLayout>
  );
};

export default HowItWorksApprovalDetails;
