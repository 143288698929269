import { authHeader } from "../config";
import { api } from "./api";

export const addAdminRoleApi = (params) => {
    return api.post("/api/role/create-role", params, {
        headers: {
            "x-access-token": localStorage.getItem("accessToken"),
            "Content-Type": "application/json",
        },
    });
};
export const viewAllAdminRoleApi = (pageNo = 0, params) => {
    return api.post(`/api/role/view-roles?page_no=${pageNo}`, JSON.stringify(params), {
        headers: {
            "x-access-token": localStorage.getItem("accessToken"),
            "Content-Type": "application/json",
        },
    });
};
export const viewAdminRoleApi = (id = "", params) => {
    return api.post(`/api/role/role-view/${id}`, JSON.stringify(params), {
        headers: {
            "x-access-token": localStorage.getItem("accessToken"),
            "Content-Type": "application/json",
        },
    });
};
export const editAdminRoleApi = (id, params) => {
    return api.post(`/api/role/edit-role/${id}`, JSON.stringify(params), {
        headers: {
            "x-access-token": localStorage.getItem("accessToken"),
            "Content-Type": "application/json",
        },
    });
};
export const approveAdminRoleApi = (params) => {
    return api.post(`/api/role/approve-role`, JSON.stringify(params), {
        headers: {
            "x-access-token": localStorage.getItem("accessToken"),
            "Content-Type": "application/json",
        },
    });
};


export const rejectAdminRoleApi = (id, params) => {
    return api.post(`/api/role/reject-role/${id}`, JSON.stringify(params), {
        headers: {
            "x-access-token": localStorage.getItem("accessToken"),
            "Content-Type": "application/json",
        },
    });
};
export const approvalsListAdminRoleApi = (page = 0, params) => {
    return api.post(`/api/role/view-checkers?page_no=${page}`, JSON.stringify(params), {
        headers: {
            "x-access-token": localStorage.getItem("accessToken"),
            "Content-Type": "application/json",
        },
    });
};

export const viewApprovalsAdminRoleApi = (id, params) => {
    return api.post(`/api/role/view-checker/${id}`, JSON.stringify(params), {
        headers: {
            "x-access-token": localStorage.getItem("accessToken"),
            "Content-Type": "application/json",
        },
    });
};
export const deleteAdminRoleApi = (id, params) => {
    return api.post(`/api/role/delete-role/${id}`, JSON.stringify(params), {
        headers: {
            "x-access-token": localStorage.getItem("accessToken"),
            "Content-Type": "application/json",
        },
    });
};

export const versionHistoryListAdminRoleApi = (pageNo = 0, params) => {
    return api.post(`/api/role/version-history?page_no=${pageNo}`, JSON.stringify(params), {
        headers: {
            "x-access-token": localStorage.getItem("accessToken"),
            "Content-Type": "application/json",
        },
    });
};
export const viewVersionHistoryAdminRoleApi = (id = "", params) => {
    return api.post(`/api/role/version-history-findone/${id}`, JSON.stringify(params), {
        headers: {
            "x-access-token": localStorage.getItem("accessToken"),
            "Content-Type": "application/json",
        },
    });
};



export const rolesTableExportApi = (params) => {
    return api.post(
        `/api/role/export-main-role`,
        JSON.stringify(params),
        {
            headers: {
                "x-access-token": localStorage.getItem("accessToken"),
                "Content-Type": "application/json",
            },
        }
    );
};
export const rolesApprovalsExportApi = (params) => {
    return api.post(
        `/api/role/export-approval-role`,
        JSON.stringify(params),
        {
            headers: {
                "x-access-token": localStorage.getItem("accessToken"),
                "Content-Type": "application/json",
            },
        }
    );
};
export const rolesVersionExportApi = (params) => {
    return api.post(
        `/api/role/export-version-role`,
        JSON.stringify(params),
        {
            headers: {
                "x-access-token": localStorage.getItem("accessToken"),
                "Content-Type": "application/json",
            },
        }
    );
};



export const adminRoleSubmitApi = (id, params) => {
    return api.post(
        `/api/role/on-submit/${id}`, JSON.stringify(params),
        {
            headers: {
                "x-access-token": localStorage.getItem("accessToken"),
                "Content-Type": "application/json",
            },
        }
    );
};


