import React from "react";
import FilterIcon from "../../assets/icons/filterIcon";
import { AdminButton } from "./versionHistoryBtn";

const FilterBtn = ({ filterFn }) => {
  return (
    <AdminButton onClick={() => filterFn()} padding="5px 10px" color="#666666">
      <FilterIcon fill="#666666" width={19} height={13} />
      <span className="ms-2">FILTER</span>
    </AdminButton>
  );
};

export default FilterBtn;
