import React, { useState } from 'react'
import CreateBtn from '../../../../components/buttons/createBtn'
import ExportBtn from '../../../../components/buttons/exportBtn'
import FilterBtn from '../../../../components/buttons/filterBtn'
import VersionHistoryBtn from '../../../../components/buttons/versionHistoryBtn'
import InnerLayout from '../../../../components/layouts/innerLayout/innerLayout'
import FaqCategoryApprovalTableContent from '../../../../components/static-pages/faqCategory/tables/faqCategoryApprovalTableContent'
import { useDispatch } from "react-redux";
import successToast from "../../../../components/utilits/successToast";
import errorToast from "../../../../components/utilits/errorToast";
import AdminUserFilter from "../../../../components/filter/adminUserFilter";
import FaqCategoryFilter from '../../../../components/filter/static-pages filter/FaqCategoryFilter'
import { faqcategoryApprovalsExport } from '../../../../redux/actions/faqCategoryActions'
                    
const FaqCategoryApprovalTable = () => {
  const [page, setPage] = useState(0);
  const [showFilter, setShowFilter] = useState(false);
  const [search, setSearch] = useState({ value: "" });
  const handleFilterClose = () => {
    setShowFilter(false);     
  };
  const handleFilterOpen = () => {
    setShowFilter(true);
  };
  const handleSearch = (value) => {
    setSearch({ value: value });
    setPage(0)
  };
  const handlePage = (type) => {
    if (type === "+") {
      setPage((prev) => prev + 1)
    }
    else {
      setPage((prev) => prev - 1)
    }                                 
  }
  console.log(search);
  const buttonList = [
    <VersionHistoryBtn link='/static-pages/faq-category/version-history' />,
    <CreateBtn link='/static-pages/faq-category/create' />,
    <ExportBtn dispatchAction={faqcategoryApprovalsExport}/>,
    <FilterBtn filterFn={handleFilterOpen} />
  ]
  return (
    <InnerLayout buttons={buttonList}>
      <FaqCategoryFilter
        filter={handleSearch}
        showFilter={showFilter}
        closeFilter={handleFilterClose}
      />
      <FaqCategoryApprovalTableContent  page={page} changePage={handlePage} search={search}/>
    </InnerLayout>
  )
}

export default FaqCategoryApprovalTable    