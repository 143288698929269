import React, { useRef, useState } from "react";
import Modal from "react-bootstrap/Modal";
import CloseIcon from "../../../../assets/icons/closeIcon";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { useEffect } from "react";    
import SelectFields from "../../../form/selectFields/selectFields";
import { useDispatch } from "react-redux";
import { opportunitiesNameListAction } from "../../../../redux/actions/opportunitiesActions";

const HowItWorksOpportunityModal = ({ show = false, id, closeModal, reasonUpdator, formikValues }) => {
    const [opportunitiesList, setOpportunitiesList] = useState([])
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(opportunitiesNameListAction(onSuccessDrop, onErrorDrop));
    }, []);
    const onSuccessDrop = (data) => {
        const categoryList = [];
        data?.data.map((item, index) => {
            categoryList[index] = { value: item.id, label: item.title };
        });
        setOpportunitiesList(categoryList);
        console.log("SUCCESS");
    };
    const onErrorDrop = (data) => {
        console.log(data);
    };

    const initialValues = {
        opportunity: id === 0 || id ? formikValues[id]?.title : "",
    };
    const loginSchema = Yup.object().shape({
        opportunity: Yup.string().trim().required("Required"),
    });
    const onSubmit = (values, { resetForm }) => {
        resetForm();
        reasonUpdator(id, values)
        closeModal()
    };
    return (
        <Modal
            centered
            show={show}
            onHide={closeModal}
            dialogClassName={`oppoptunities_modals_body mx-auto `}
            contentClassName={"oppoptunities_modals_body_content  p-2"}
        >
            <Modal.Body>
                <>
                    <div className="d-flex justify-content-between  ">
                        <h2 className="green_color fs_15 fn_Montserrat fw_600 pt-1">
                            Opportunity
                        </h2>
                        <button
                            onClick={closeModal}
                            className="light_grey_color  fs_15 fn_Montserrat bg-transparent border-0 fw_600 "
                        >
                            <span className="pt-1">DISMISS</span>
                            <span className="ms-2">
                                <CloseIcon width={13} height={13} fill="#A5A5A5" />
                            </span>
                        </button>
                    </div>
                    <Formik
                        initialValues={initialValues}
                        onSubmit={onSubmit}
                        validationSchema={loginSchema}
                    >
                        <Form className="mt-03">
                            <SelectFields
                                label={"Opportunity"}
                                placeholder={"Select Opportunity"}
                                name={"opportunity"}
                                options={opportunitiesList}
                                id={"opportunity"}
                            />
                            <div className="d-flex justify-content-end mt-4">
                                <button
                                    type="submit"
                                    className="secondary_color fs_15  fn_Montserrat fw_600 border-0 tertiary_bg px-4 px-lg-5 py-2 py-lg-2 rounded-2"
                                >
                                    {id === 0 || id ? "EDIT" : " CREATE"}
                                </button>
                            </div>
                        </Form>
                    </Formik>
                </>
            </Modal.Body>
        </Modal>
    )
}

export default HowItWorksOpportunityModal