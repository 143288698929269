import { authHeader } from "../config";
import { api } from "./api";
export const createReferApi = (params) => {
    return api.post("/api/admin/refer-and-earn-faq/create-faq", params, {
        headers: {
            "x-access-token": localStorage.getItem("accessToken"),
            "Content-Type": "application/json",
        },
    });
};
export const approveReferApi = (params) => {
    return api.post("/api/admin/refer-and-earn-faq/approve", JSON.stringify(params), {
        headers: {
            "x-access-token": localStorage.getItem("accessToken"),
            "Content-Type": "application/json",
        },
    });
};

export const rejectReferApi = (id, params) => {
    return api.post(
        `/api/admin/refer-and-earn-faq/reject/${id}`,
        JSON.stringify(params),
        {
            headers: {
                "x-access-token": localStorage.getItem("accessToken"),
                "Content-Type": "application/json",
            },
        }
    );
};
export const viewReferDetailApi = (id, params) => {
    return api.post(
        `/api/admin/refer-and-earn-faq/view-approval/${id}`,
        JSON.stringify(params),
        {
            headers: {
                "x-access-token": localStorage.getItem("accessToken"),
                "Content-Type": "application/json",
            },
        }
    );
};
export const viewReferListApi = (pageNo = 0, params) => {
    return api.post(
        `/api/admin/refer-and-earn-faq/view-approvals?page_no=${pageNo}`,
        params,
        {
            headers: {
                "x-access-token": localStorage.getItem("accessToken"),
                "Content-Type": "application/json",
            },
        }
    );
};
export const editReferApi = (id, params) => {
    return api.post(
        `/api/admin/refer-and-earn-faq/edit/${id}`,
        JSON.stringify(params),
        {
            headers: {
                "x-access-token": localStorage.getItem("accessToken"),
                "Content-Type": "application/json",
            },
        }
    );
};
export const deleteReferApi = (id, params) => {
    return api.post(
        `/api/admin/refer-and-earn-faq/delete/${id}`,
        JSON.stringify(params),
        {
            headers: {
                "x-access-token": localStorage.getItem("accessToken"),
                "Content-Type": "application/json",
            },
        }
    );
};
export const referApprovalListApi = (pageNo, params) => {
    return api.post(
        `/api/admin/refer-and-earn-faq/view-checker?page_no=${pageNo}`,
        JSON.stringify(params),
        {
            headers: {
                "x-access-token": localStorage.getItem("accessToken"),
                "Content-Type": "application/json",
            },
        }
    );
};
export const referApprovalByIdApi = (id, params) => {
    return api.post(
        `/api/admin/refer-and-earn-faq/view-checker/${id}`,
        JSON.stringify(params),
        {
            headers: {
                "x-access-token": localStorage.getItem("accessToken"),
                "Content-Type": "application/json",
            },
        }
    );
};
export const versionHistoryReferListApi = (pageNo, params) => {
    return api.post(
        `/api/admin/refer-and-earn-faq/view-version?page_no=${pageNo}`,
        JSON.stringify(params),
        {
            headers: {
                "x-access-token": localStorage.getItem("accessToken"),
                "Content-Type": "application/json",
            },
        }
    );
};
export const versionHistoryReferDetailApi = (id, params) => {
    return api.post(
        `/api/admin/refer-and-earn-faq/view-version/${id}`,
        JSON.stringify(params),
        {
            headers: {
                "x-access-token": localStorage.getItem("accessToken"),
                "Content-Type": "application/json",
            },
        }
    );
};
export const referTableExportApi = (params) => {
    return api.post(
        `/api/admin/refer-and-earn-faq/export-main-faq`,
        JSON.stringify(params),
        {
            headers: {
                "x-access-token": localStorage.getItem("accessToken"),
                "Content-Type": "application/json",
            },
        }
    );
};
export const referApprovalsExportApi = (params) => {
    return api.post(
        `/api/admin/refer-and-earn-faq/export-approval-faq`,
        JSON.stringify(params),
        {
            headers: {
                "x-access-token": localStorage.getItem("accessToken"),
                "Content-Type": "application/json",
            },
        }
    );
};
export const referVersionExportApi = (params) => {
    return api.post(
        `/api/admin/refer-and-earn-faq/export-version-faq`,
        JSON.stringify(params),
        {
            headers: {
                "x-access-token": localStorage.getItem("accessToken"),
                "Content-Type": "application/json",
            },
        }
    );
};
export const referSubmitApi = (id, params) => {
    return api.post(`/api/admin/refer-and-earn-faq/on-submit/${id}`, params, {
        headers: {
            "x-access-token": localStorage.getItem("accessToken"),
            "Content-Type": "application/json",
        },
    });
};
