import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import ArrowRightIcon from "../../../../assets/icons/arrowrightIcon";
import InnerContainer from "../../../../components/layouts/innerContainer/innerContainer";
import InnerLayout from "../../../../components/layouts/innerLayout/innerLayout";
import errorToast from "../../../../components/utilits/errorToast";
import { versionHistoryAdminDetail } from "../../../../redux/actions/AdminUserAction";
import "../our-team.css";
import * as ourTeamAction from "../../../../redux/actions/ourTeamAction";
import LoadingSpinnerTable from "../../../../components/utilits/loadingSpinnerTable";
import LoadAndError from "../../../../components/utilits/LoadAndError";
const OurTeamVersionHistoryView = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const [adminVersionData, setAdminVersionData] = useState([]);
  const [versionViewData, SetVersionViewData] = useState({
    id: "",
    createdAt: "",
    updatedAt: "",
    new_data: { updated_by: "", updated_At: "" },
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);

  useEffect(() => {
    setLoading(true);
    dispatch(
      ourTeamAction.versionHistoryOurTeamDetail(params.id, onSuccess, onError)
    );
  }, []);
  const onSuccess = (data) => {
    SetVersionViewData(data);
    setLoading(false);
    console.log(data, "suceeee");
    setError(false)
  };
  const onError = (data) => {
    errorToast(data.message);
    setLoading(false);
    console.log(data, "jdsgfdsugdsjgfdsjhgdshv");
    setError(true)
  };
  console.log(versionViewData);



  const data = [
    {
      heading: "Team Member ID",
      title: versionViewData?.id,
    },
    {
      heading: "Created At",
      title:
        versionViewData.createdAt === null
          ? "-"
          : moment(versionViewData.createdAt).format("DD/MM/YY HH:mm:ss"),
    },
    {
      heading: "Updated At",
      title:
        versionViewData.updatedAt === null
          ? "-"
          : moment(versionViewData.updatedAt).format("DD/MM/YY HH:mm:ss"),
    },
    {
      heading: "Updated By",
      title:
        versionViewData.updated_by === null ? "-" : versionViewData?.updated_by,
    },
  ];

  const newData = [
    {
      heading: "Id",
      title: versionViewData?.id,
    },
    {
      heading: "Name",
      title: versionViewData.new_data?.name,
    },
    {
      heading: "linkedin_url",
      title: versionViewData.new_data?.linkedin_url,
    },

    {
      heading: "designation",
      title: versionViewData.new_data?.designation,
    },
    {
      heading: "priority",
      title: versionViewData.new_data?.priority,
    },
    {
      heading: "description",
      title: versionViewData.new_data?.description,
    },

    {
      heading: "Created Date",
      title: moment(versionViewData.new_data?.createdAt).format(
        "DD/MM/YY HH:mm:ss"
      ),
    },
    {
      heading: "Updated Date",
      title: moment(versionViewData.new_data?.updatedAt).format(
        "DD/MM/YY HH:mm:ss"
      ),
    },
  ];
  const oldData = [
    {
      heading: "Id",
      title:
        versionViewData?.id === null
          ? "-"
          : versionViewData?.id,
    },
    {
      heading: "Name",
      title:
        versionViewData.old_data?.name === null
          ? "-"
          : versionViewData.old_data?.name,
    },
    {
      heading: "linkedin_url",
      title:
        versionViewData.old_data?.linkedin_url === null
          ? "-"
          : versionViewData.old_data?.linkedin_url,
    },

    {
      heading: "designation",
      title:
        versionViewData.old_data?.designation === null
          ? "-"
          : versionViewData.old_data?.designation,
    },
    {
      heading: "priority",
      title:
        versionViewData.old_data?.priority === null
          ? "-"
          : versionViewData.old_data?.priority,
    },
    {
      heading: "description",
      title:
        versionViewData.old_data?.description === null
          ? "-"
          : versionViewData.old_data?.description,
    },

    {
      heading: "Created Date",
      title: moment(versionViewData.old_data?.createdAt).format(
        "DD/MM/YY HH:mm:ss"
      ),
    },
    {
      heading: "Updated Date",
      title: moment(versionViewData.old_data?.updatedAt).format(
        "DD/MM/YY HH:mm:ss"
      ),
    },
  ];

  return (
    <>
      <InnerLayout>
        <div className="admin_user_container">
          <InnerContainer>
            {loading ? (
              <LoadingSpinnerTable />
            ) : (
              <LoadAndError loader={loading} error={error}>
                <div className="pt-3 ps-5 row">
                  {data.map((item, index) => (
                    <div className="mb-2  col">
                      <h5 className="dark_grey_color text-nowrap fs_13 fn_Montserrat fw_500 m-0">
                        {item.heading}
                      </h5>
                      <p
                        className={` fs_13 fn_Nunito text-nowrap fw_500 ${item.heading === "Admin ID" || item.heading === "E-Mail"
                          ? "secondary_color"
                          : "dark_title_color"
                          }`}
                      >
                        {item.title}
                      </p>
                    </div>
                  ))}
                </div>
                <div className="white_shade_bg  py-1 d-flex">
                  <div className="w-50">
                    <h5 className="fw_500 ps-4 dark_grey_color pt-2 text-nowrap fs_16 fn_Montserrat">
                      JSON Old Data
                    </h5>
                  </div>
                  <div className="w-50  d-none d-lg-block">
                    <h5 className="fw_500 ps-4  dark_grey_color pt-2 text-nowrap fs_16 fn_Montserrat">
                      JSON New Data
                    </h5>
                  </div>
                </div>

                <div className="row mt-3 ps-0 ps-lg-4">
                  <div className="col-12 col-lg-6">
                    <div className="admin_aprvls_vrsn_hstry_data_container  mt-3 pt-3 px-3">
                      <div className="d-flex justify-content-between">
                        <h5 className="fs_13 fn_Nunito fw_400 dark_title_color text-nowrap">
                          Old Data
                        </h5>
                        <ArrowRightIcon width={25} height={25} fill={"#A5A5A5"} />
                      </div>
                      {versionViewData?.old_data === null ? (
                        <>
                          <div>Empty Data</div>
                        </>
                      ) : (
                        <>
                          {oldData?.map((item, index) => {
                            return (
                              <>
                                <h5 className="dark_grey_color text-nowrap fs_13 fn_Montserrat fw_500 m-0">
                                  {item.heading}
                                </h5>
                                <p
                                  className={` fs_13 fn_Nunito text-nowrap fw_500 ${item.heading === "Admin ID" ||
                                    item.heading === "E-Mail"
                                    ? "secondary_color"
                                    : "dark_title_color"
                                    }`}
                                >
                                  {item.title}
                                </p>
                              </>
                            );
                          })}
                        </>
                      )}
                    </div>
                  </div>
                  <div className="col-12 mt-5 mt-lg-0 col-lg-6">
                    <h5 className="fw_500 ps-4 d-block d-lg-none white_shade_bg  dark_grey_color py-3 text-nowrap fs_16 fn_Montserrat">
                      JSON New Data
                    </h5>
                    <div className="admin_aprvls_vrsn_hstry_data_container mt-4 mt-lg-3 pt-3 px-3">
                      <div className="d-flex justify-content-between">
                        <h5 className="fs_13 fn_Nunito fw_400 dark_title_color py-2 text-nowrap">
                          New Data
                        </h5>
                        <ArrowRightIcon width={25} height={25} fill={"#A5A5A5"} />
                      </div>
                      {versionViewData?.new_data === null ? (
                        <>
                          <div>Empty Data</div>
                        </>
                      ) : (
                        <>
                          {newData.map((item, index) => {
                            return (
                              <>
                                <h5 className="dark_grey_color text-nowrap fs_13 fn_Montserrat fw_500 m-0">
                                  {item.heading}
                                </h5>
                                <p
                                  className={` fs_13 fn_Nunito text-nowrap fw_500 ${item.heading === "Admin ID" ||
                                    item.heading === "E-Mail"
                                    ? "secondary_color"
                                    : "dark_title_color"
                                    }`}
                                >
                                  {item.title}
                                </p>
                              </>
                            );
                          })}</>)}

                    </div>
                  </div>
                </div>
              </LoadAndError>
            )}
          </InnerContainer>
        </div>
      </InnerLayout>
    </>
  );
};
export default OurTeamVersionHistoryView;
