import React, { useRef, useState } from "react";
import Modal from "react-bootstrap/Modal";
import CloseIcon from "../../../../assets/icons/closeIcon";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import InputField from "../../../form/inputFields/inputField";
import ErrorMsg from "../../../form/errorMsg";

const HomeCardsModal = ({ show = false, id, closeModal, reasonUpdator, formikValues }) => {
    const initialValues = {
        title: id === 0 || id ? formikValues[id]?.title : "",
        desc: id === 0 || id ? formikValues[id]?.description : "",
    };
    const loginSchema = Yup.object().shape({
        title: Yup.string().trim(),
        desc: Yup.string().trim(),
    });
    const onSubmit = (values, { resetForm }) => {
        resetForm();
        reasonUpdator(id, values)
        closeModal()
    };
    return (
        <Modal
            centered
            show={show}
            onHide={closeModal}
            dialogClassName={`oppoptunities_modals_body mx-auto `}
            contentClassName={"oppoptunities_modals_body_content  p-2"}
        >
            <Modal.Body>
                <>
                    <div className="d-flex justify-content-between  ">
                        <h2 className="green_color fs_15 fn_Montserrat fw_600 pt-1">
                            Card Description
                        </h2>
                        <button
                            onClick={closeModal}
                            className="light_grey_color  fs_15 fn_Montserrat bg-transparent border-0 fw_600 "
                        >
                            <span className="pt-1">DISMISS</span>
                            <span className="ms-2">
                                <CloseIcon width={13} height={13} fill="#A5A5A5" />
                            </span>
                        </button>
                    </div>
                    <Formik
                        // innerRef={formRef}
                        initialValues={initialValues}
                        onSubmit={onSubmit}
                        validationSchema={loginSchema}
                    >
                        <Form className="mt-3">
                            <InputField
                                id={"title"}
                                label={" Title"}
                                type={"text"}
                                placeholder={"Enter title"}
                                name={"title"}
                            />
                            <label className="mb-1 ms-2" htmlFor="desc">
                                description
                            </label>
                            <Field name={"desc"}>
                                {({ field }) => (
                                    <textarea
                                        style={{ height: "4rem" }}
                                        id="desc"
                                        {...field}
                                        type="text"
                                        placeholder="Enter description"
                                        className={"w-100 mb-0 faq_message_container p-2 "}
                                    ></textarea>
                                )}
                            </Field>
                            <ErrorMsg name={"desc"} />
                            <div className="d-flex justify-content-end mt-4">
                                <button
                                    type="submit"
                                    className="secondary_color fs_15  fn_Montserrat fw_600 border-0 tertiary_bg px-4 px-lg-5 py-2 py-lg-2 rounded-2"
                                >
                                    {id === 0 || id ? "EDIT" : " CREATE"}
                                </button>
                            </div>
                        </Form>
                    </Formik>
                </>
            </Modal.Body>
        </Modal>
    )
}

export default HomeCardsModal