import { call, takeLatest } from "redux-saga/effects";
import { APPROVE_FAQ, CREATE_FAQ, DELETE_FAQ, EDIT_FAQ, FAQ_APPROVALS_EXPORT, FAQ_APPROVAL_TABLE, FAQ_APPROVAL_VIEW, FAQ_EXPORT, FAQ_OPTIONS, FAQ_SUBMIT, FAQ_TABLE, FAQ_VERSION_EXPORT, FAQ_VERSION_HISTORY, FAQ_VERSION_HISTORY_TABLE, REJECT_FAQ, VIEW_FAQ } from "../actions/types";
import { approveFaqApi, createFaqApi, deleteFaqApi, editFaqApi, faqApprovalByIdApi, faqApprovalListApi, faqApprovalsExportApi, faqOptionsApi, faqSubmitApi, faqTableExportApi, faqVersionExportApi, rejectFaqApi, versionHistoryFaqDetailApi, versionHistoryFaqListApi, viewFaqDetailApi, viewFaqListApi } from "../apis/faqApi";

export function* createFaqWorker(action) {
    try {
        const res = yield call(createFaqApi,
            action.data
        );
        console.log(res);

        if (res.status === 200 && res.data.status === "success") {
            yield action.onSuccess(res.data);
        } else {
            yield action.onError(res.data);
        }
    } catch (error) { }
}

export function* approveFaqWorker(action) {
    try {
        const res = yield call(approveFaqApi, {
            user_Id: action.id,
        });
        console.log(res);
        if (res.status === 200 && res.data.status === "success") {
            yield action.onSuccess(res.data);
        } else {
            yield action.onError(res.data);
        }
    } catch (error) { }
}

export function* rejectFaqWorker(action) {
    try {
        const res = yield call(rejectFaqApi, action.id);
        console.log(res);
        if (res.status === 200 && res.data.status === "success") {
            yield action.onSuccess(res.data);
        } else {
            yield action.onError(res.data);
        }
    } catch (error) { }
}


export function* viewFaqDetailWorker(action) {
    try {
        const res = yield call(viewFaqDetailApi, action.id);
        console.log(res);
        if (res.status === 200 && res.data.status === "success") {
            yield action.onSuccess(res.data);
        } else {
            yield action.onError(res.data);
        }
    } catch (error) { }
}


export function* viewFaqListWorker(action) {
    try {

        const res = yield call(viewFaqListApi,
            action.page_no,
            action.data,
        );
        console.log(res);
        if (res.status === 200 && res.data.status === "success") {
            yield action.onSuccess(res.data.data);
            console.log(res.data.data);
        } else {
            yield action.onError(res.data);
        }
    } catch (error) { }
}

export function* editFaqWorker(action) {
    try {
        const res = yield call(editFaqApi, action.id, action.data);
        console.log(res);
        if (res.status === 200 && res.data.status === "success") {
            yield action.onSuccess(res.data);
        } else {
            yield action.onError(res.data);
        }
    } catch (error) { }
}


export function* deleteFaqWorker(action) {
    try {
        const res = yield call(deleteFaqApi, action.id);
        console.log(res);
        if (res.status === 200 && res.data.status === "success") {
            yield action.onSuccess(res.data);
        } else {
            yield action.onError(res.data);
        }
    } catch (error) { }
}




export function* faqApprovalListWorker(action) {
    console.log(action.search,
        action.page_no,)
    try {
        const res = yield call(faqApprovalListApi,
            action.page_no,
            action.data,
        );
        if (res.status === 200 && res.data.status === "success") {
            yield action.onSuccess(res.data.data);
            console.log(res.data);
        } else {
            yield action.onError(res.data);
        }
    } catch (error) { }
}



export function* faqApprovalByIdWorker(action) {
    try {
        const res = yield call(faqApprovalByIdApi, action.id);
        console.log(res);
        if (res.status === 200 && res.data.status === "success") {
            yield action.onSuccess(res.data);
            console.log(res.data)
        } else {
            yield action.onError(res.data);
        }
    } catch (error) { }
}



export function* versionHistoryFaqListWorker(action) {
    try {
        const res = yield call(versionHistoryFaqListApi,
            action.page,
            action.data,
        );
        console.log(res);
        if (res.status === 200 && res.data.status === "success") {
            yield action.onSuccess(res.data);
        } else {
            yield action.onError(res.data);
        }
    } catch (error) { }
}

export function* versionHistoryFaqDetailWorker(action) {
    try {
        const res = yield call(versionHistoryFaqDetailApi,
            action.id,
        );
        console.log(res);
        if (res.status === 200 && res.data.status === "success") {
            yield action.onSuccess(res.data);
        } else {
            yield action.onError(res.data);
        }
    } catch (error) { }
}

export function* faqOptionsWorker(action) {
    try {
        const res = yield call(faqOptionsApi,);
        console.log(res);
        if (res.status === 200 && res.data.status === "success") {
            yield action.onSuccess(res.data);
            console.log(res.data);

        } else {
            yield action.onError(res.data);
        }
    } catch (error) { }
}


/*********WATCHERS********/

export function* faqTableExportWorker(action) {
    try {
        const res = yield call(faqTableExportApi,

        );
        if (res.status === 200 && res.data.status === "success") {
            yield action.onSuccess(res.data);
        } else {
            yield action.onError(res.data);
        }
    } catch (error) { }
}


export function* faqApprovalsExportWorker(action) {
    try {
        const res = yield call(faqApprovalsExportApi);
        console.log(res);
        if (res.status === 200 && res.data.status === "success") {
            console.log(res.data);
            yield action.onSuccess(res.data.data);
        } else {
            yield action.onError(res.data);
        }
    } catch (error) { }
}

export function* faqVersionExportWorker(action) {
    try {
        const res = yield call(faqVersionExportApi);
        console.log(res);
        if (res.status === 200 && res.data.status === "success") {
            yield action.onSuccess(res.data);
        } else {
            yield action.onError(res.data);
        }
    } catch (error) { }
}



export function* faqSubmitWorker(action) {
    try {
        const res = yield call(faqSubmitApi, action.id);
        console.log(res);
        if (res.status === 200 && res.data.status === "success") {
            yield action.onSuccess(res.data);
        } else {
            yield action.onError(res.data);
        }
    } catch (error) { }
}






export function* createFaqWorkerWatcher() {
    yield takeLatest(CREATE_FAQ, createFaqWorker);
}

export function* approveFaqWorkerWatcher() {
    yield takeLatest(APPROVE_FAQ, approveFaqWorker);
}
export function* rejectFaqWorkerWatcher() {
    yield takeLatest(REJECT_FAQ, rejectFaqWorker);
}
export function* viewFaqDetailWatcherWorker() {
    yield takeLatest(VIEW_FAQ, viewFaqDetailWorker);
}
export function* viewFaqListWorkerWatcher() {
    yield takeLatest(FAQ_TABLE, viewFaqListWorker);
}
export function* editFaqWorkerWatcher() {
    yield takeLatest(EDIT_FAQ, editFaqWorker);

}
export function* deleteFaqWorkerWatcher() {
    yield takeLatest(DELETE_FAQ, deleteFaqWorker);
}

export function* faqApprovalListWatcherWorker() {
    yield takeLatest(FAQ_APPROVAL_TABLE, faqApprovalListWorker);
}

export function* faqApprovalByIdWatcher() {
    yield takeLatest(FAQ_APPROVAL_VIEW, faqApprovalByIdWorker);
}



export function* versionHistoryFaqListWatcher() {
    yield takeLatest(FAQ_VERSION_HISTORY_TABLE, versionHistoryFaqListWorker);
}
export function* versionHistoryFaqDetailWatcher() {
    yield takeLatest(
        FAQ_VERSION_HISTORY,
        versionHistoryFaqDetailWorker
    );
}

export function* faqOptionsWorkerWatcher() {
    yield takeLatest(FAQ_OPTIONS, faqOptionsWorker);
} 

/****exports*****/

export function* faqTableExportWatcher() {
    yield takeLatest(
        FAQ_EXPORT,
        faqTableExportWorker
    );
}
export function* faqApprovalsExportWatcher() {
    yield takeLatest(FAQ_APPROVALS_EXPORT, faqApprovalsExportWorker);
}

export function* faqVersionExportWorkerWatcher() {
    yield takeLatest(FAQ_VERSION_EXPORT, faqVersionExportWorker);
}



export function* faqSubmitWorkerWatcher() {
    yield takeLatest(FAQ_SUBMIT, faqSubmitWorker);
}