import React from "react";
import InnerLayout from "../../../../components/layouts/innerLayout/innerLayout";
import InnerContainer from "../../../../components/layouts/innerContainer/innerContainer";
import { useNavigate, useParams } from "react-router-dom";
import VersionHistoryBtn from "../../../../components/buttons/versionHistoryBtn";
import EditBtn from "../../../../components/buttons/editBtn";
import DeleteBtn from "../../../../components/buttons/deleteBtn";
import FaqCategoryViewDetails from "../../../../components/static-pages/faqCategory/faqCategoryViewDetails";
import BlogCategoryViewDetails from "../../../../components/static-pages/blogCategory/blogCategoryViewDetails";
import ReferredUsersViewDetails from "../../../../components/static-pages/referred-users/referredUsersViewDetails";
import { useState } from "react";

const ReferredUsersView = () => {
  const params = useParams()
  const [editable, setEditable] = useState(false)
  const buttonList = [
    <VersionHistoryBtn link="/static-pages/referred-users/version-history" />,
    editable && <EditBtn link={`/static-pages/referred-users/edit/${params.id}`} />
  ];


  return (
    <InnerLayout buttons={buttonList}>
      <div className="user_view_container">
        <InnerContainer>
          <ReferredUsersViewDetails handleEditable={(value) => setEditable(value)} id={params.id} />
        </InnerContainer>
      </div>
    </InnerLayout>
  );
};

export default ReferredUsersView;
