import React, { useRef, useState } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { useNavigate, useParams } from "react-router-dom";
import InputField from "../../../../components/form/inputFields/inputField";
import { useDispatch } from "react-redux";
import successToast from "../../../../components/utilits/successToast";
import errorToast from "../../../../components/utilits/errorToast";
import InnerLayout from "../../../../components/layouts/innerLayout/innerLayout";
import InnerContainer from "../../../../components/layouts/innerContainer/innerContainer";
import SaveBtn from "../../../../components/buttons/saveBtn";
import {
  addUserReferralAction,
  fetchOpportunitiesID,
  UserReferralCategorySubmit,
} from "../../../../redux/actions/userReferralActions";
import SelectFields from "../../../../components/form/selectFields/selectFields";
import { useEffect } from "react";

const UserReferralCreate = () => {
  const navigate = useNavigate();
  const formRef = useRef();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const params = useParams();
  const [opportunitiesId, setOpportunitiesId] = useState([]);
  const [submitData, setSubmitData] = useState();
  const [deskImageLink, setDeskImageLink] = useState({
    img: "",
    error: false,
  });
  const initialValues = {
    userID: "",
    opportunityID: "",
  };

  const interestEditSchema = Yup.object().shape({
    userID: Yup.string().trim().required("Required"),
    opportunityID: Yup.string().trim().required("Required"),
  });
  const handleSubmit = () => {
    if (!deskImageLink.img) {
      setDeskImageLink({ img: "", error: true });
    }
    if (formRef.current) {
      formRef.current.handleSubmit();
    }
  };
  const onSubmit = (values, { resetForm }) => {
    console.log(values);
    dispatch(
      addUserReferralAction(
        {
          user_id: values.userID,
          opportunity_id: values.opportunityID,
        },
        onSuccess,
        onError
      )
    );
  };
  useEffect(() => {
    dispatch(fetchOpportunitiesID(params.id, onDropSuccess, onDropError));
  }, []);

  const onDropSuccess = (data) => {
    const oppId = [];
    data?.data.map((item, index) => {
      oppId[index] = { value: item.id, label: item.name };
    });
    setOpportunitiesId(oppId);
  };
  const onDropError = (msg) => {
    console.log(msg);
  };
  const onSuccess = (data) => {
    console.log("====================================");
    console.log(data);
    console.log("====================================");
    successToast(data.message);
    setSubmitData(data.data.id);
    setSaveHide(true);
    setLoading(false);
    navigate(`/static-pages/userReferral/edit/${data.data.id}`);
  };
  const onError = (data) => {
    console.log("====================================");
    console.log(data);
    console.log("====================================");
    errorToast(data.data);
    setLoading(false);
  };

  //on submit api
  console.log(submitData);
  const [saveHide, setSaveHide] = useState(false);

  const submitFnc = () => {
    dispatch(
      UserReferralCategorySubmit(submitData, onSubmitSuccess, onSubmitError)
    );
  };
  const onSubmitSuccess = (data) => {
    console.log(data);
    //  navigate("/static-pages/how-It-Works")
    successToast(data.message);
  };
  const onSubmitError = (data) => {
    console.log("ERROR==================", data);
    errorToast(data.message);
  };

  const buttonList = [
    !saveHide && <SaveBtn submitFn={handleSubmit} loader={loading} />,
    // <SubmitBtn submitFn={submitFnc} saveHide={saveHide} />
  ];
  return (
    <InnerLayout buttons={buttonList}>
      <div className="user_view_container mb-4">
        <InnerContainer>
          <Formik
            innerRef={formRef}
            initialValues={initialValues}
            onSubmit={onSubmit}
            enableReinitialize={true}
            validationSchema={interestEditSchema}
          >
            {({ formik, handleChange, errors, values, setFieldValue }) => {
              // console.log(errors);
              return (
                <Form className="px-4 py-3 col-12  col-xxl-10">
                  <div className="d-flex flex-column gap-0 gap-lg-5 flex-lg-row fs_14">
                    <div className="col-12 col-lg-6 pe-0 pe-lg-5">
                      <InputField
                        id={"userID"}
                        label={"User ID"}
                        type={"text"}
                        placeholder={"Enter User ID"}
                        name={"userID"}
                      />
                      <SelectFields
                        label={"Opportunity ID"}
                        placeholder={"Select Opportunity ID"}
                        name={"opportunityID"}
                        options={opportunitiesId}
                        id={"opportunity_id"}
                      />
                    </div>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </InnerContainer>
      </div>
    </InnerLayout>
  );
};

export default UserReferralCreate;
