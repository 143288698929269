import React from "react";
import ExportIcon from "../../assets/icons/exportIcon";
import { AdminButton } from "./versionHistoryBtn";

const ImportUserBtn = ({ importFn}) => {
    return (
        <AdminButton onClick={() => importFn()} type="submit" padding="5px 10px" color="#666666">
            <ExportIcon fill="#666666" />
            <span className="ms-1">Import Users</span>
        </AdminButton>
    );
};

export default ImportUserBtn;
