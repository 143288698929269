import React, { useEffect, useState } from "react";
import ArrowRightIcon from "../../../assets/icons/arrowrightIcon";
import ApproveBtn from "../../../components/buttons/approveBtn";
import RejectBtn from "../../../components/buttons/rejectBtn";
import InnerContainer from "../../../components/layouts/innerContainer/innerContainer";
import InnerLayout from "../../../components/layouts/innerLayout/innerLayout";
import "../user-view.css";
import { useDispatch } from "react-redux";
import errorToast from "../../../components/utilits/errorToast";
import * as userAction from "../../../redux/actions/userAction";
import { useLocation, useParams } from "react-router-dom";
import moment from "moment";
import LoadingSpinnerTable from "../../../components/utilits/loadingSpinnerTable";
import LoadAndError from "../../../components/utilits/LoadAndError";
import { JSONTree } from 'react-json-tree';

const USerVersionHistoryView = () => {
  const params = useParams();
  const location = useLocation();
  const [userVersionHistoryData, setuserVersionHistoryData] = useState({
    id: "",
    createdAt: "",
    updatedAt: "",
    new_data: { updated_by: "", updated_At: "" },
  });
  const [userVersionHistoryNewData, setUserVersionHistoryNewData] = useState([]);
  const [userVersionHistoryOldData, setUserVersionHistoryOldData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false)
  const dispatch = useDispatch();
  useEffect(() => {
    setLoading(true);
    dispatch(userAction.UserVersionHistoryView(params?.id, onSuccess, onError));
  }, []);
  const onSuccess = (data) => {
    setuserVersionHistoryData(data?.data?.data);
    setUserVersionHistoryNewData(data?.data?.data?.new_data);
    setUserVersionHistoryOldData(data?.data?.data?.old_data);
    setLoading(false);
    setError(false)
  };
  const onError = (data) => {
    errorToast(data.message);
    setLoading(false);
    setError(true)
  };

  console.log(userVersionHistoryData, "vvvbcbvbvbvbvb");
  const data = [
    {
      heading: "User ID",
      title: userVersionHistoryData?.id
        ? userVersionHistoryData?.id
        : "-",
    },
    {
      heading: "Created At",
      title: moment(userVersionHistoryData?.createdAt).format(
        "DD-MM-YYYY HH:mm:ss"
      )
        ? moment(userVersionHistoryData?.createdAt).format(
          "DD-MM-YYYY HH:mm:ss"
        )
        : "-",
    },
    {
      heading: "Updated At",
      title: moment(userVersionHistoryData?.old_data?.updatedAt).format(
        "DD-MM-YYYY HH:mm:ss"
      )
        ? moment(userVersionHistoryData?.updatedAt).format(
          "DD-MM-YYYY HH:mm:ss"
        )
        : "-",
    },
    {
      heading: "Updated By",
      title: userVersionHistoryData?.updated_by
        ? userVersionHistoryData?.updated_by
        : "-",
    },
    {
      heading: "Approved By",
      title: userVersionHistoryData?.approved_by
        ? userVersionHistoryData?.approved_by
        : "-",
    },
    {
      heading: "Approved Date",
      title: moment(userVersionHistoryData?.approved_date).format(
        "DD-MM-YYYY HH:mm:ss"
      )
        ? moment(userVersionHistoryData?.approved_date).format(
          "DD-MM-YYYY HH:mm:ss"
        )
        : "-",
    },
  ];

  const newdata = [
    {
      heading: "User ID",
      title: userVersionHistoryData?.new_data?.id
        ? userVersionHistoryData?.new_data?.id
        : "-",
    },
    {
      heading: "Approved By",
      title: userVersionHistoryData?.new_data?.approved_by
        ? userVersionHistoryData?.new_data?.approved_by
        : "-",
    },
    {
      heading: "Updated At",
      title: moment(userVersionHistoryData?.new_data?.updated_At).format(
        "DD-MM-YYYY HH:mm:ss"
      )
        ? moment(userVersionHistoryData?.new_data?.updated_At).format(
          "DD-MM-YYYY HH:mm:ss"
        )
        : "-",
    },

    {
      heading: "Approved Date",
      title: moment(userVersionHistoryData?.new_data?.approved_date).format(
        "DD-MM-YYYY HH:mm:ss"
      )
        ? moment(userVersionHistoryData?.new_data?.approved_date).format(
          "DD-MM-YYYY HH:mm:ss"
        )
        : "-",
    },
    {
      heading: "User Name",
      title: userVersionHistoryData?.new_data?.user_name
        ? userVersionHistoryData?.new_data?.user_name
        : "-",
    },
    // {
    //   heading: "Created At",
    //   title: moment(userVersionHistoryData?.new_data?.createdAt).format(
    //     "DD-MM-YYYY HH:mm:ss"
    //   )
    //     ? moment(userVersionHistoryData?.new_data?.createdAt).format(
    //         "DD-MM-YYYY HH:mm:ss"
    //       )
    //     : "-",
    // },
    // {
    //   heading: "Pan Number",
    //   title: userVersionHistoryData?.new_data?.pan?.pan_no
    //     ? userVersionHistoryData?.new_data?.pan?.pan_no
    //     : "-",
    // },
    // {
    //   heading: "Pan User ID",
    //   title: userVersionHistoryData?.new_data?.pan?.user_id
    //     ? userVersionHistoryData?.new_data?.pan?.user_id
    //     : "-",
    // },
    // {
    //   heading: "Bank Account Number",
    //   title: userVersionHistoryData?.new_data?.bank_account_details
    //     ?.account_number
    //     ? userVersionHistoryData?.new_data?.bank_account_details?.account_number
    //     : "-",
    // },
    // {
    //   heading: "Account Type",
    //   title: userVersionHistoryData?.new_data?.bank_account_details
    //     ?.account_type
    //     ? userVersionHistoryData?.new_data?.bank_account_details?.account_type
    //     : "-",
    // },
    // {
    //   heading: "Bank holder Name",
    //   title: userVersionHistoryData?.new_data?.bank_account_details
    //     ?.bank_holder_name
    //     ? userVersionHistoryData?.new_data?.bank_account_details
    //         ?.bank_holder_name
    //     : "-",
    // },
    // {
    //   heading: "Bank Name",
    //   title: userVersionHistoryData?.new_data?.bank_account_details?.bank_name
    //     ? userVersionHistoryData?.new_data?.bank_account_details?.bank_name
    //     : "-",
    // },
    // {
    //   heading: "IFSC Code",
    //   title: userVersionHistoryData?.new_data?.bank_account_details?.ifsc_code
    //     ? userVersionHistoryData?.new_data?.bank_account_details?.ifsc_code
    //     : "-",
    // },
    // {
    //   heading: "Bank User ID",
    //   title: userVersionHistoryData?.new_data?.bank_account_details?.user_id
    //     ? userVersionHistoryData?.new_data?.bank_account_details?.user_id
    //     : "-",
    // },
    // {
    //   heading: "Address Proof Type",
    //   title: userVersionHistoryData?.new_data?.address_proof?.proof_type
    //     ? userVersionHistoryData?.new_data?.address_proof?.proof_type
    //     : "-",
    // },
    // {
    //   heading: "Created At",
    //   title: moment(userVersionHistoryData?.new_data?.createdAt).format(
    //     "DD-MM-YYYY HH:mm:ss"
    //   )
    //     ? moment(userVersionHistoryData?.new_data?.createdAt).format(
    //         "DD-MM-YYYY HH:mm:ss"
    //       )
    //     : "-",
    // },
    // {
    //   heading: "Deal Type Preference",
    //   title: userVersionHistoryData?.new_data?.deal_type_preference
    //     ? userVersionHistoryData?.new_data?.deal_type_preference
    //     : "-",
    // },
    // {
    //   heading: "Din",
    //   title: userVersionHistoryData?.new_data?.din
    //     ? userVersionHistoryData?.new_data?.din
    //     : "-",
    // },
    // {
    //   heading: "Date Of Birth",
    //   title: userVersionHistoryData?.new_data?.dob
    //     ? userVersionHistoryData?.new_data?.dob
    //     : "-",
    // },
    // {
    //   heading: "Email ID",
    //   title: userVersionHistoryData?.new_data?.email
    //     ? userVersionHistoryData?.new_data?.email
    //     : "-",
    // },
    // {
    //   heading: "Father Name",
    //   title: userVersionHistoryData?.new_data?.father_name
    //     ? userVersionHistoryData?.new_data?.father_name
    //     : "-",
    // },
    // {
    //   heading: "Investment Value Preference",
    //   title: userVersionHistoryData?.new_data?.investment_value_preference
    //     ? userVersionHistoryData?.new_data?.investment_value_preference
    //     : "-",
    // },
    // {
    //   heading: "Occupation",
    //   title: userVersionHistoryData?.new_data?.occupation
    //     ? userVersionHistoryData?.new_data?.occupation
    //     : "-",
    // },
  ];
  const olddata = [
    {
      heading: "User ID",
      title: userVersionHistoryData?.old_data?.id
        ? userVersionHistoryData?.old_data?.id
        : "-",
    },
    {
      heading: "Created At",
      title: moment(userVersionHistoryData?.old_data?.createdAt).format(
        "DD-MM-YYYY HH:mm:ss"
      )
        ? moment(userVersionHistoryData?.old_data?.createdAt).format(
          "DD-MM-YYYY HH:mm:ss"
        )
        : "-",
    },
    {
      heading: "Updated At",
      title: moment(userVersionHistoryData?.old_data?.updated_At).format(
        "DD-MM-YYYY HH:mm:ss"
      )
        ? moment(userVersionHistoryData?.old_data?.updated_At).format(
          "DD-MM-YYYY HH:mm:ss"
        )
        : "-",
    },
    {
      heading: "User Name",
      title: userVersionHistoryData?.old_data?.user_name
        ? userVersionHistoryData?.old_data?.user_name
        : "-",
    },
  ];

  // {
  //   Object.entries(userVersionHistoryData).map(([key, value]) =>
  //     console.log(
  //       `${key}: ${typeof value === "string" || typeof value === "number" ? value : "not"
  //       }`
  //     )
  //   );
  // }

  const theme = {
    scheme: 'monokai',
    author: 'wimer hazenberg (http://www.monokai.nl)',
    base00: '#272822',
    base01: '#383830',
    base02: '#49483e',
    base03: '#75715e',
    base04: '#a59f85',
    base05: '#f8f8f2',
    base06: '#f5f4f1',
    base07: '#f9f8f5',
    base08: '#f92672',
    base09: '#464646',
    base0A: '#f4bf75',
    base0B: '#464646',
    base0C: '#a1efe4',
    base0D: '#464646',
    base0E: '#ae81ff',
    base0F: '#cc6633',
  };
  return (
    <>
      <InnerLayout>
        <div className="user_view_container">
          <InnerContainer>
            {
              loading ? (
                <LoadingSpinnerTable />
              ) : (
                <LoadAndError loader={loading} error={error}>
                  <div className="pt-3 ps-5 row">
                    {data.map((item, index) => (
                      <div className="mb-2  col-sm-4 col-md-3  col-xl-2 col-lg-3">
                        <h5 className="dark_grey_color  fs_13 fn_Montserrat fw_500 m-0">
                          {item.heading}
                        </h5>
                        <p
                          className={` fs_13 fn_Nunito  fw_500 ${item.heading === "Notify Me ID"
                            ? "secondary_color"
                            : "dark_title_color"
                            }`}
                        >
                          {item.title}
                        </p>
                      </div>
                    ))}
                  </div>
                  <div className="white_shade_bg  py-1 d-flex">
                    <div className="w-50">
                      <h5 className="fw_500 ps-4 dark_grey_color pt-2 text-nowrap fs_16 fn_Montserrat">
                        JSON Old Data
                      </h5>
                    </div>
                    <div className="w-50  d-none d-lg-block">
                      <h5 className="fw_500 ps-4  dark_grey_color pt-2 text-nowrap fs_16 fn_Montserrat">
                        JSON New Data
                      </h5>
                    </div>
                  </div>

                  <div className="row mt-3 ps-0 ps-lg-4">
                    <div className="col-12 col-lg-6">
                      <div className="notify_me_aprvls_vrsn_hstry_data_container  mt-3 pt-3 px-3">
                        <div className="d-flex justify-content-between">
                          <h5 className="fs_13 fn_Nunito fw_400 dark_title_color text-nowrap">
                            Old Data
                          </h5>
                          <ArrowRightIcon width={25} height={25} fill={"#A5A5A5"} />
                        </div>
                        {userVersionHistoryData.old_data === null ? (
                          <div>Empty data</div>
                        ) : (
                          <>
                            <JSONTree theme={theme} data={userVersionHistoryOldData} />;
                            {/* {olddata.map((item, index) => {
                              return (
                                <div className="d-flex flex-column">
                                  <h5 className="dark_grey_color  fs_13 fn_Montserrat fw_500 m-0">
                                    {item.heading}
                                  </h5>
                                  <p
                                    className={` fs_13 fn_Nunito  fw_500 ${item.heading === "Notify Me ID"
                                      ? "secondary_color"
                                      : "dark_title_color"
                                      }`}
                                  >
                                    {item.title}
                                  </p>
                                </div>
                              );
                            })} */}
                          </>
                        )}
                      </div>
                    </div>
                    <div className="col-12 mt-5 mt-lg-0 col-lg-6">
                      <h5 className="fw_500 ps-4 d-block d-lg-none white_shade_bg  dark_grey_color py-3 text-nowrap fs_16 fn_Montserrat">
                        JSON New Data
                      </h5>
                      <div className="notify_me_aprvls_vrsn_hstry_data_container    mt-4 mt-lg-3 pt-3 px-3">
                        <div className="d-flex justify-content-between">
                          <h5 className="fs_13 fn_Nunito fw_400 dark_title_color py-2 text-nowrap">
                            New Data
                          </h5>
                          <ArrowRightIcon width={25} height={25} fill={"#A5A5A5"} />
                        </div>
                        {userVersionHistoryData.new_data === null ? (
                          <div>Empty data</div>
                        ) : (
                          <> <JSONTree theme={theme} data={userVersionHistoryNewData} />;
                          </>)}

                      </div>
                    </div>
                  </div>
                </LoadAndError>
              )}
          </InnerContainer>
        </div>
      </InnerLayout>
    </>
  );
};
export default USerVersionHistoryView;
