import React, { useState } from "react";
import AdminRolesTableContent from "../../../../components/admin/tables/adminRolesTableContent";
import ApprovalsBtn from "../../../../components/buttons/approvalsBtn";
import CreateBtn from "../../../../components/buttons/createBtn";
import ExportBtn from "../../../../components/buttons/exportBtn";
import FilterBtn from "../../../../components/buttons/filterBtn";
import VersionHistoryBtn from "../../../../components/buttons/versionHistoryBtn";
import AdminRolesFilter from "../../../../components/filter/adminFilter/adminRoleFilter";
import InnerLayout from "../../../../components/layouts/innerLayout/innerLayout";
import { rolesTableExport } from "../../../../redux/actions/adminRoleActions";

const AdminRolesTable = () => {
  const [showFilter, setShowFilter] = useState(false);
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(0);
  const handleFilterClose = () => {
    setShowFilter(false);
  };
  const handleFilterOpen = () => {
    setShowFilter(true);
  };
  const handleSearch = (value) => {
    console.log("running");
    setSearch(value);
    setPage(0)
  };
  const handlePage = (type) => {
    if (type === "+") {
      setPage((prev) => prev + 1);
    } else {
      setPage((prev) => prev - 1);
    }
  };

  const buttonList = [
    <CreateBtn link="/admin-roles/create" />,
    <ApprovalsBtn link="/admin-roles/approvals" />,
    <VersionHistoryBtn link="/admin-roles/version-history" />,
    <ExportBtn dispatchAction={rolesTableExport} />,
    <FilterBtn filterFn={handleFilterOpen} />,
  ];

  return (
    <InnerLayout buttons={buttonList}>
      <AdminRolesFilter
        filter={handleSearch}
        showFilter={showFilter}
        closeFilter={handleFilterClose}
      />
      <AdminRolesTableContent
        page={page}
        changePage={handlePage}
        search={search}
      />
    </InnerLayout>
  );
};

export default AdminRolesTable;
