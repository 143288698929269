import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import ApproveBtn from "../../../../components/buttons/approveBtn";
import RejectBtn from "../../../../components/buttons/rejectBtn";
import VersionHistoryBtn from "../../../../components/buttons/versionHistoryBtn";
import InnerContainer from "../../../../components/layouts/innerContainer/innerContainer";
import InnerLayout from "../../../../components/layouts/innerLayout/innerLayout";
import OurTeamViewProfileImage from "../../../../components/static-pages/our-team/modals/viewProfileImage";
import errorToast from "../../../../components/utilits/errorToast";
import successToast from "../../../../components/utilits/successToast";
import DownloadIcon from "../../../../assets/icons/downloadIcon";
import * as ourTeamAction from "../../../../redux/actions/ourTeamAction";
import {
  viewApprovalById,
  approveAdmin,
  rejectAdmin,
} from "../../../../redux/actions/AdminUserAction";

import "../our-team.css";
import LoadingSpinnerTable from "../../../../components/utilits/loadingSpinnerTable";
import LoadAndError from "../../../../components/utilits/LoadAndError";
const OurTeamApprovalView = () => {
  const navigate = useNavigate();
  const [adminDetails, setAdminDetails] = useState([]);
  const [show, setShow] = useState(false);
  const [imageShow, setImageShow] = useState(false)
  const params = useParams()
  const dispatch = useDispatch()
  const handleBackImageShow = () => setImageShow(true);
  const handleImageClose = () => setImageShow(false);
  const [notifyMeViewData, SetNotifyMeViewData] = useState({ id: "", })
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [file, setFile] = useState(null);
  const [image, setImage] = useState('');
  useEffect(() => {
    setLoading(true)
    dispatch(
      ourTeamAction.OurTeamApprovalById(params.id, onSuccess, onError)
    );
  }, [])
  const onSuccess = (data) => {
    SetNotifyMeViewData(data);
    setLoading(false)
    console.log(data, "suceeee")
    setImage(data.data?.new_data?.photo_url
      .split("/").pop());
    setError(false)
  };
  const onError = (data) => {
    errorToast(data.message)
    setLoading(false)
    console.log(data, "jdsgfdsugdsjgfdsjhgdshv")
    setError(true)
  };


  console.log("The data", notifyMeViewData);


  // useEffect(() => {
  //   console.log(params);
  //   dispatch(viewApprovalById(params.id, onSuccess, onError));
  // }, []);

  // const onSuccess = (data) => {
  //   console.log(data.data);
  //   setAdminDetails(data.data);
  //   console.log("SUCCESS");
  // };

  // const onError = (data) => {
  //   console.log(data);
  //   console.log("ERROR");
  // };
  const approveAdminFunc = () => {
    console.log("The id:", params?.id);
    dispatch(ourTeamAction.approveOurTeam(params?.id, onApproveSuccess, onApproveError));
  };
  const rejectAdminFunc = () => {
    console.log("The id:", params?.id);
    dispatch(ourTeamAction.rejectOurTeam(params?.id, onRejectSuccess, onRejectError));
  };
  const buttonList = [
    <VersionHistoryBtn link={`/static-pages/our-team/version-history`} />,
    <ApproveBtn approveFn={approveAdminFunc} />,
    <RejectBtn showModal={rejectAdminFunc} />,
  ];

  const onApproveSuccess = (data) => {
    successToast(data.message);
    console.log(data);
    navigate("/static-pages/our-team/approvals")
    setLoading(false)
    setError(false)
  };
  const onApproveError = (data) => {
    errorToast(data.message);
    console.log(data);
    setLoading(false)
    setError(true)
  };

  const onRejectSuccess = (data) => {
    successToast("Rejected Successfully");
    navigate("/static-pages/our-team/approvals")
    setLoading(false)
    setError(false)
  };

  const onRejectError = (data) => {
    errorToast(data.message);
    console.log(data);
    setLoading(false)
    setError(true)
  };

  const data = [
    {
      heading: "Approval ID",
      title: notifyMeViewData?.data?.id,
    },
    {
      heading: "Name",
      title: notifyMeViewData?.data?.new_data == null ? notifyMeViewData?.data?.old_data?.name : notifyMeViewData?.data?.new_data?.name,
    },
    {
      heading: "Designation",
      title: notifyMeViewData?.data?.new_data == null ? notifyMeViewData?.data?.old_data?.designation : notifyMeViewData?.data?.new_data?.designation,
    },
    {
      heading: "LinkedIn URL",
      title: notifyMeViewData?.data?.new_data == null ? notifyMeViewData?.data?.old_data?.linkedin_url : notifyMeViewData?.data?.new_data?.linkedin_url,
    },
    {
      heading: "Description",
      title: notifyMeViewData?.data?.new_data == null ? notifyMeViewData?.data?.old_data?.description : notifyMeViewData?.data?.new_data?.description,
    },

    {
      heading: "Prirority",
      title: notifyMeViewData?.data?.new_data == null ? notifyMeViewData?.data?.old_data?.priority : notifyMeViewData?.data?.new_data?.priority,
    },
    {
      heading: "Team Member",
      title: notifyMeViewData?.data?.new_data == null ? notifyMeViewData?.data?.old_data?.id : notifyMeViewData?.data?.new_data?.id,
    },
    {
      heading: "Approved At",
      title: moment(notifyMeViewData?.data?.createdAt).format("DD/MM/YYYY HH:mm:ss")
    },
    {
      heading: "Updated At",
      title: moment(notifyMeViewData?.data?.updatedAt).format("DD/MM/YYYY HH:mm:ss")
    },
    {
      heading: "Operation Type",
      title: notifyMeViewData?.data?.new_data == null ? "Delete" : notifyMeViewData?.data?.new_data && notifyMeViewData?.data?.old_data ? "Edit" : notifyMeViewData?.data?.old_data == null ? "Create" : ""
    }
  ];

  return (
    <InnerLayout buttons={buttonList}>
      <div className="admin_user_container">
        <InnerContainer error={error} oldData={notifyMeViewData?.data?.old_data} newData={notifyMeViewData?.data?.new_data}>
          {loading ? (
            <LoadingSpinnerTable />
          ) : (
            <LoadAndError loader={loading} error={error}>
              <div className="row">
                <div className='col-12 col-md-5'>
                  <div className="pt-3  ps-4 ps-lg-4 ms-3">
                    {data.map((item, index) => (
                      <div className="mb-2">
                        <h5 className="dark_grey_color fs_13 fn_Montserrat fw_500 m-0">
                          {item.heading}
                        </h5>
                        {
                          item.heading === "LinkedIn URL" ? (<>
                            <Link
                              // to={item.title}
                              className={` fs_13 text-decoration-none fn_Nunito cursor_pointer fw_500  ${item.heading === "LinkedIn URL" || item.heading === "User ID" ? 'secondary_color' : 'dark_title_color'} `}>
                              {item.title}
                            </Link>
                          </>) : (<>   <p
                            className={` fs_13 fn_Nunito fw_500  ${item.heading === "LinkedIn URL" || item.heading === "User ID" ? 'secondary_color' : 'dark_title_color'} `}>
                            {item.title}
                          </p></>)
                        }

                      </div>
                    ))}
                  </div>
                </div>
                <div className='col-12 col-md-5'>
                  <div className="ps-4">
                    <h5 className="dark_grey_color text-nowrap fs_13 fn_Montserrat fw_500">
                      Profile Picture
                    </h5>
                    <div className="personal_information_image_container mt-2 position-relative">
                      <a href={notifyMeViewData?.data?.new_data == null ? notifyMeViewData?.data?.old_data?.photo_url : notifyMeViewData?.data?.new_data?.photo_url} download>
                        <img
                          src={notifyMeViewData?.data?.new_data == null ? notifyMeViewData?.data?.old_data?.photo_url : notifyMeViewData?.data?.new_data?.photo_url}
                          className="personal_information_img_size rounded-2"
                        />
                      </a>

                      <a href={notifyMeViewData?.data?.new_data == null ? notifyMeViewData?.data?.old_data?.photo_url : notifyMeViewData?.data?.new_data?.photo_url} download="file">
                        <button
                          type="button"
                          className="position-absolute border-0 bg-white d-flex align-items-center fs_10 top-0 end-0"
                        >
                          <DownloadIcon width={8} />
                          <span className="ms-1">Download</span>
                        </button>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </LoadAndError>
          )}
        </InnerContainer>
      </div>
      <OurTeamViewProfileImage
        handleClose={handleImageClose}
        show={imageShow}
        Profileimage={DownloadIcon}
      />
    </InnerLayout>
  );
};

export default OurTeamApprovalView;
