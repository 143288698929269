import React, { useState } from "react";
import ApprovalsBtn from "../../../../components/buttons/approvalsBtn";
import CreateBtn from "../../../../components/buttons/createBtn";
import ExportBtn from "../../../../components/buttons/exportBtn";
import FilterBtn from "../../../../components/buttons/filterBtn";
import VersionHistoryBtn from "../../../../components/buttons/versionHistoryBtn";
import AdminUserFilter from "../../../../components/filter/adminUserFilter";
import InnerLayout from "../../../../components/layouts/innerLayout/innerLayout";
import FaqCategoryTableContent from "../../../../components/static-pages/faqCategory/tables/faqCategoryTableContent";
import { useDispatch } from "react-redux";
import FaqCategoryFilter from "../../../../components/filter/static-pages filter/FaqCategoryFilter";
import { faqcategoryTableExport } from "../../../../redux/actions/faqCategoryActions";

const FaqCategoryTable = () => {
  const [page, setPage] = useState(0);
  const [showFilter, setShowFilter] = useState(false);
  const [search, setSearch] = useState({ value: "" });
  const dispatch = useDispatch();
  const handleFilterClose = () => {
    setShowFilter(false);     
  };
  const handleFilterOpen = () => {
    setShowFilter(true);
  };
  const handleSearch = (value) => {
    setSearch({ value: value });
    setPage(0)
  };
  const handlePage = (type) => {
    if (type === "+") {
      setPage((prev) => prev + 1)
    }
    else {
      setPage((prev) => prev - 1)
    }                                 
  }
  const buttonList = [
    <ApprovalsBtn link="/static-pages/faq-category/approvals" />,
    <VersionHistoryBtn link="/static-pages/faq-category/version-history" />,
    <CreateBtn link="/static-pages/faq-category/create" />,
    <ExportBtn  dispatchAction={faqcategoryTableExport}/>,
    <FilterBtn filterFn={handleFilterOpen} />,
  ];
  return (
    <InnerLayout buttons={buttonList}>
      <FaqCategoryFilter
        filter={handleSearch}    
        showFilter={showFilter}
        closeFilter={handleFilterClose}
      />

      <FaqCategoryTableContent  page={page} changePage={handlePage} search={search} />
    </InnerLayout>
  );
};

export default FaqCategoryTable;
