import { authHeader } from "../config";
import { api } from "./api";

export const addCampaignApi = (params) => {
    return api.post("/api/admin/campaign-landing-page/create-campaign", params, {
        headers: {
            "x-access-token": localStorage.getItem("accessToken"),
            "Content-Type": "application/json",
        },
    });
};
export const viewCampaignListApi = (page = 0, params) => {
    return api.post(`/api/admin/campaign-landing-page/view-campaign?page_no=${page}`, params, {
        headers: {
            "x-access-token": localStorage.getItem("accessToken"),
            "Content-Type": "application/json"
        },
    });
};
export const viewCampaignApi = (id, params) => {
    return api.post(`/api/admin/campaign-landing-page/view-campaign/${id}`, params, {
        headers: {
            "x-access-token": localStorage.getItem("accessToken"),
            "Content-Type": "application/json",
        },
    });
};
export const editCampaignApi = (id, params) => {
    return api.post(`/api/admin/campaign-landing-page/edit/${id}`, params, {
        headers: {
            "x-access-token": localStorage.getItem("accessToken"),
            "Content-Type": "application/json",
        },
    });
};
export const deleteCampaignApi = (id, params) => {
    return api.post(`/api/admin/campaign-landing-page/delete/${id}`, params, {
        headers: {
            "x-access-token": localStorage.getItem("accessToken"),
            "Content-Type": "application/json",
        },
    });
};
export const approveCampaignApi = (params) => {
    return api.post(`/api/admin/campaign-landing-page/approve`, params, {
        headers: {
            "x-access-token": localStorage.getItem("accessToken"),
            "Content-Type": "application/json",
        },
    });
}
export const rejectCampaignApi = (id, params) => {
    return api.post(`/api/admin/campaign-landing-page/reject/${id}`, params, {
        headers: {
            "x-access-token": localStorage.getItem("accessToken"),
            "Content-Type": "application/json",
        },
    });
};
export const approvalsCampaignListpApi = (page = 0, params) => {
    return api.post(`/api/admin/campaign-landing-page/view-checker?page_no=${page}`, params, {
        headers: {
            "x-access-token": localStorage.getItem("accessToken"),
            "Content-Type": "application/json",
        },
    });
}
export const viewApprovalCampaignApi = (id, params) => {
    return api.post(`/api/admin/campaign-landing-page/view-checker/${id}`, params, {
        headers: {
            "x-access-token": localStorage.getItem("accessToken"),
            "Content-Type": "application/json",
        },
    });
};
export const versionCampaignListpApi = (page = 0, params) => {
    return api.post(`/api/admin/campaign-landing-page/view-version?page_no=${page}`, params, {
        headers: {
            "x-access-token": localStorage.getItem("accessToken"),
            "Content-Type": "application/json",
        },
    });
}
export const viewVersionCampaignApi = (id, params) => {
    return api.post(`/api/admin/campaign-landing-page/view-version/${id}`, params, {
        headers: {
            "x-access-token": localStorage.getItem("accessToken"),
            "Content-Type": "application/json",
        },
    });
};
export const activateCampaignApi = (id, params) => {
    return api.post(`/api/admin/campaign-landing-page/is-active-update/${id}`, params, {
        headers: {
            "x-access-token": localStorage.getItem("accessToken"),
            "Content-Type": "application/json",
        },
    });
};




export const campaignMainExportApi = (page, params) => {
    return api.post(
        `/api/admin/campaign-landing-page/export-main-campaign`,
        JSON.stringify(params),
        {
            headers: {
                "x-access-token": localStorage.getItem("accessToken"),
                "Content-Type": "application/json",
            },
        }
    );
};

export const campaignApprovalsExportApi = (page, params) => {
    return api.post(
        `/api/admin/campaign-landing-page/export-approval-campaign`,
        JSON.stringify(params),
        {
            headers: {
                "x-access-token": localStorage.getItem("accessToken"),
                "Content-Type": "application/json",
            },
        }
    );
};

export const campaignVersionExportApi = (page, params) => {
    return api.post(
        `/api/admin/campaign-landing-page/export-version-campaign`,
        JSON.stringify(params),
        {
            headers: {
                "x-access-token": localStorage.getItem("accessToken"),
                "Content-Type": "application/json",
            },
        }
    );
};


export const campaignSubmitApi = (id, params) => {
    return api.post(
        `/api/admin/campaign-landing-page/on-submit/${id}`, JSON.stringify(params),
        {
            headers: {
                "x-access-token": localStorage.getItem("accessToken"),
                "Content-Type": "application/json",
            },
        }
    );
};
