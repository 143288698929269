import React, { useState } from 'react'
import ExportBtn from '../../../components/buttons/exportBtn';
import FilterBtn from '../../../components/buttons/filterBtn';
import AdminUserFilter from '../../../components/filter/adminUserFilter';
import EmailOtpFilter from '../../../components/filter/emailOtpFilter';
import InnerLayout from '../../../components/layouts/innerLayout/innerLayout';
import UserEmailOTPTableContent from '../../../components/user/tables/userEmailOTPTableContent';
import { userEmailExport } from '../../../redux/actions/userAction';


const UserEmailOtpTable = () => {
    const [page, setPage] = useState(0);
    const [showFilter, setShowFilter] = useState(false);
    const [search, setSearch] = useState("");

    const handleFilterClose = () => {
        setShowFilter(false);
    };
    const handleFilterOpen = () => {
        setShowFilter(true);
    };
    const handleSearch = (value) => { setSearch(value); setPage(0) };
    const handlePage = (type) => {
        if (type === "+") {
            setPage((prev) => prev + 1)
        }
        else {
            setPage((prev) => prev - 1)
        }
    }
    const buttonList = [
        <ExportBtn dispatchAction={userEmailExport} />,
        <FilterBtn filterFn={handleFilterOpen} />,
    ];
    return (
        <InnerLayout buttons={buttonList}>
            <EmailOtpFilter showFilter={showFilter} closeFilter={handleFilterClose} filter={handleSearch} />
            <UserEmailOTPTableContent page={page} changePage={handlePage} search={search} /></InnerLayout>
    )
}

export default UserEmailOtpTable