import React, { useEffect, useState } from "react";
import "../../../modules/user/user-view.css";
import DownloadIcon from "../../../assets/icons/downloadIcon";
import { saveAs } from "file-saver";
import ViewImageModal from "../modals/viewImageModal";
import moment from "moment";
import ApprovalViewProfileModal from "../modals/approvalsViewModals/viewImageModal";
import * as userAction from "../../../redux/actions/userAction";
import { useDispatch } from "react-redux";
import errorToast from "../../../components/utilits/errorToast";
import successToast from "../../../components/utilits/successToast";

const PersonalInformationApprovalView = ({
  approveUser,
  location,
  image = "",
  individualApprovalData,
}) => {
  const [imageShow, setImageShow] = useState(false);
  const handleImageShow = () => setImageShow(true);
  const handleImageClose = () => setImageShow(false);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const downloadImage = () => {
    saveAs(
      `https://upcide2.firstfloor.site/api/users/get-profile/${image}`
    );
  };
  console.log(individualApprovalData);
  
  const EmailHiding =(value='oooo', type="email")=> {
    if (type === "email") {
      let sliced = value?.split("@");
      return (
        sliced[0]?.substring(0, 3) +
        "****" +
        "@" +
        sliced[1]?.substring(0, 7) +
        "...."
      );
    } 
  }

  const personaldata = [
    {
      heading: "Approval ID",
      title: individualApprovalData?.id,
    },
    {
      heading: "User ID",
      title: individualApprovalData?.new_data == null ? individualApprovalData?.old_data?.id : individualApprovalData?.new_data?.id,
    },
    {
      heading: "Name",
      title: individualApprovalData?.new_data == null ? individualApprovalData?.old_data?.user_name : individualApprovalData?.new_data?.user_name,
    },
    {
      heading: "DOB",
      title:moment(individualApprovalData?.new_data == null ? individualApprovalData?.old_data?.dob : individualApprovalData?.new_data?.dob).format("DD-MM-YYYY"),
    },
    {
      heading: "Father's Name",
      title: individualApprovalData?.new_data == null ? individualApprovalData?.old_data?.father_name : individualApprovalData?.new_data?.father_name,
    },
    {
      heading: "E-Mail",
      title: individualApprovalData?.new_data == null ? individualApprovalData?.old_data?.email : individualApprovalData?.new_data?.email,
    },
    {
      heading: "Phone Number",
      title: individualApprovalData?.new_data == null ? individualApprovalData?.old_data?.phone_number : individualApprovalData?.new_data?.phone_number,
    },
    {
      heading: "Country Code",
      title: individualApprovalData?.new_data == null ? individualApprovalData?.old_data?.country_code : individualApprovalData?.new_data?.country_code,
    },
    {
      heading: "Residential Status",
      title: individualApprovalData?.new_data == null ? individualApprovalData?.old_data?.residential_status === "residential" ? "Residential" : "Non Residential"  : individualApprovalData?.new_data?.residential_status === "residential" ? "Residential" : "Non Residential" ,
    },
    {
      heading: "E-Mail Verification",
      title: individualApprovalData?.new_data == null ? individualApprovalData?.old_data?.phone_verified === true? "Yes" : "No"  : individualApprovalData?.new_data?.phone_verified === true? "Yes" : "No" ,    },
    {
      heading: "Phone Verification",
      title: individualApprovalData?.new_data == null ? individualApprovalData?.old_data?.phone_verified === true? "Yes" : "No"   : individualApprovalData?.new_data?.phone_verified === true? "Yes" : "No"  ,    },
    {
      heading: "DIN",
      title: individualApprovalData?.new_data == null ? individualApprovalData?.old_data?.din : individualApprovalData?.new_data?.din,
    },
    {
      heading: "User ID",
      title: individualApprovalData?.new_data == null ? individualApprovalData?.old_data?.id : individualApprovalData?.new_data?.id,
    },
  ];

  const erpdetails = [
    {
      heading: "Created At",
      title: moment(individualApprovalData?.createdAt).format(
        "DD-MM-YYYY HH:mm:ss"
      ),
    },
    {
      heading: "Updated At",
      title: moment(individualApprovalData?.updatedAt).format(
        "DD-MM-YYYY HH:mm:ss"
      ),
    },
    {
      heading: "Last Login",
      title: moment(individualApprovalData?.new_data == null ? individualApprovalData?.old_data?.last_login :  individualApprovalData?.new_data?.last_login).format(
        "DD-MM-YYYY HH:mm:ss"
      ),
    },
    {
      heading: "Last Password Reset",
      title: moment(individualApprovalData?.new_data == null ? individualApprovalData?.old_data?.last_password_rest :  individualApprovalData?.new_data?.last_password_rest).format(
        "DD-MM-YYYY HH:mm:ss"
      ),
    },
    {
      heading: "Last Password Reset Request",
      title: moment(individualApprovalData?.new_data == null ? individualApprovalData?.old_data?.last_password_reset_request :  individualApprovalData?.new_data?.last_password_reset_request).format(
        "DD-MM-YYYY HH:mm:ss"
      ),
    },
  ];

  const investPreferance = [
    {
      heading: "Deal Type Preference",
      title:
        individualApprovalData?.new_data === null
          ? individualApprovalData?.old_data?.deal_type_preference
          : individualApprovalData?.new_data?.deal_type_preference,
    },
    {
      heading: "Investment Value",
      title:
        individualApprovalData?.new_data === null
          ? individualApprovalData?.old_data?.investment_value_preference
          : individualApprovalData?.new_data?.investment_value_preference,
    },
  ];


  return (
    <>
      <section className="mt-4">
        <h3 className="dark_grey_color fn_Montserrat fw_500 fs_13 py-2 white_shade_bg ps-4 ps-lg-5">
          Personal Information
        </h3>
        <div className="row mt-4">
          <div className="col-12 col-sm-12 col-md-9 col-lg-9 col-xl-7">
            <div className="row ps-1 ms-1 ps-lg-4 ms-lg-1">
              {personaldata.map((item, index) => (
                <div className="mb-2 personal_information_width">
                  <h5 className="dark_grey_color text-nowrap fs_13 fn_Montserrat fw_500">
                    {item.heading}
                  </h5>
                  <p
                    className={` fs_13 fn_Nunito fw_500 ${item.heading === "User ID" ||
                      item.heading === "E-Mail" ||
                      item.heading === "Phone Number"
                      ? "secondary_color"
                      : "dark_title_color"
                      }`}
                  >
                    <span
            title={item.heading === "E-Mail" ? item.title : null}
            className={ item.heading === "E-Mail" ? 
            " text-break fontFam_mulish cursor_pointer fs_14 fw_500" : 
            " text-break fontFam_mulish fs_14 fw_500" }
          >
                    {item.heading === "E-Mail" ? EmailHiding(item.title, "email") : item.title }
                    </span>
                    {/* {item.title} */}
                  </p>
                </div>
              ))}
            </div>
          </div>
          <div className="col-12 col-sm-12 col-md-3 col-lg-3  col-xl-5">
            <div className="ps-3 ms-2 ps-lg-0 ms-lg-0">
              <h5 className="dark_grey_color text-nowrap fs_13 fn_Montserrat fw_500 ">
                Profile Picture
              </h5>
              <div className="personal_information_image_container mt-2 position-relative">
                <img
                  onClick={handleImageShow}
                  src={individualApprovalData?.new_data == null ? individualApprovalData?.old_data?.profile_picture_location :  individualApprovalData?.new_data?.profile_picture_location }
                  cross-origin="use-credentials"
                  className="personal_information_img_size rounded-2"
                />

                {/* <a
                  // href={`https://upcide2.firstfloor.site/api/users/get-profile/${image}`}
                  // download="file"
                  // cross-origin="use-credentials"
                > */}
                <button
                  type="button"
                  className="position-absolute border-0 bg-white d-flex align-items-center fs_10 top-0 end-0"
                  onClick={() => {
                    downloadImage()
                  }}
                >
                  <DownloadIcon width={8} />
                  <span className="ms-1">Download</span>
                </button>
                {/* </a> */}
                {/* <a
                   href={`https://upcide2.firstfloor.site/api/users/get-profile/${image}`}
               
                  download={`https://upcide2.firstfloor.site/api/users/get-profile/${image}`}
                >
                  <button
                   
                    type="button"
                    className="position-absolute border-0 bg-white d-flex align-items-center fs_10 top-0 end-0"
                  >
                    <DownloadIcon width={8} />
                    <span className="ms-1">Download</span>
                  </button>
                </a> */}
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-12 col-xl-9 ">
          <div className="row mt-4 ps-1 ms-1 ps-lg-4 ms-lg-1">
            {erpdetails.map((item, index) => (
              <div className="personal_information_details_width mb-2 ">
                <h5 className="dark_grey_color  fs_13 fn_Montserrat fw_500">
                  {item.heading}
                </h5>
                <p
                  className={` fs_13 fn_Nunito  fw_500 ${item.heading === "User ID" ||
                    item.heading === "E-Mail" ||
                    item.heading === "Phone Number"
                    ? "secondary_color"
                    : "dark_title_color"
                    }`}
                >
                  {item.title}
                </p>
              </div>
            ))}
          </div>
        </div>

        <section>
          <h3 className="dark_grey_color fn_Montserrat mt-2 fw_500 white_shade_bg py-2 fs_13 ps-4 ps-lg-5">
            Investment Preference
          </h3>
          <div className="col-lg-12 col-xl-9 ">
            <div className="row mt-4  ps-1 ms-1 ps-lg-4 ms-lg-1 ">
              {investPreferance.map((item, index) => (
                <div className="mb-2 personal_information_details_width">
                  <h5 className="dark_grey_color  fs_13 fn_Montserrat fw_500">
                    {item.heading}
                  </h5>
                  <p
                    className={` fs_13 fn_Nunito  fw_500 ${item.heading === "User ID" ||
                      item.heading === "E-Mail" ||
                      item.heading === "Phone Number"
                      ? "secondary_color"
                      : "dark_title_color"
                      }`}
                  >
                    {item.title}
                  </p>
                </div>
              ))}
            </div>
          </div>
        </section>
      </section>
      <ApprovalViewProfileModal
        handleClose={handleImageClose}
        show={imageShow}
        profile={individualApprovalData}
        image={individualApprovalData?.new_data == null ? individualApprovalData?.old_data?.profile_picture_location :  individualApprovalData?.new_data?.profile_picture_location}
      />
    </>
  );
};

export default PersonalInformationApprovalView;
