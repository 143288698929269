import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import InnerLayout from "../../../../components/layouts/innerLayout/innerLayout";
import InnerContainer from "../../../../components/layouts/innerContainer/innerContainer";
import VersionHistoryBtn from "../../../../components/buttons/versionHistoryBtn";
import EditBtn from "../../../../components/buttons/editBtn";
import DeleteBtn from "../../../../components/buttons/deleteBtn";
import DeleteModal from "../../../../components/modals/deleteModal";
import successToast from "../../../../components/utilits/successToast";
import errorToast from "../../../../components/utilits/errorToast";
import DownloadIcon from "../../../../assets/icons/downloadIcon";

import LoadAndError from "../../../../components/utilits/LoadAndError";
import HomeCounterTable from "../../../../components/static-pages/home-page/tables/homeCounterTable";
import { viewCampaignAction } from "../../../../redux/actions/campaignActions";
import HomeCardsTable from "../../../../components/static-pages/home-page/tables/homeCardsTable";
import { viewPartnerWithUsAction } from "../../../../redux/actions/partnerWithUsAction";
import PartnerWithUsFaq from "../../../../components/static-pages/partner-with-us/tables/partnerWithUsFaq";
import PartnerWithUsAnimation from "../../../../components/static-pages/partner-with-us/tables/partnerWithUsAnimation";

const PartnerWithUsView = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [howItWorksData, setHowItWorksData] = useState({});
  const [partnersAni, setPartnersAni] = useState({});
  const [faq1, setfaq1] = useState({});
  const [faq2, setfaq2] = useState({});
  const dispatch = useDispatch();
  const params = useParams();
  useEffect(() => {
    setLoading(true);
    dispatch(viewPartnerWithUsAction(params.id, onSuccess, onError));
  }, []);
  const onSuccess = (data) => {
    console.log(data.data);
    setHowItWorksData(data.data);
    setPartnersAni(data.data?.partners_guide);
    setfaq1(data.data?.partner_faq_section1);
    setfaq2(data.data?.partner_faq_section2);
    console.log("SUCCESS");
    setLoading(false);
    setError(false);
  };
  const onError = (data) => {            
    console.log("ERROR");
    setLoading(false);
    setError(true);
  };
  // const [deleteData, setDeleteData] = useState({ name: "", id: "" });
  // const [loadingDelete, setLoadingDelete] = useState(false);
  // const [show, setShow] = useState(false);
  // const handleShow = () => setShow(true);
  // const handleClose = () => setShow(false);

  // const handleDelete = () => {
  //   setLoadingDelete(true);
  //   dispatch(
  //     deleteFaq(
  //       faqDetails.id,
  //       onDeleteSuccess,
  //       onDeleteError
  //     )
  //   );
  // };
  // const onDeleteSuccess = (data) => {
  //   successToast(data.message);
  //   setLoadingDelete(false);
  //   setShow(false);
  //   navigate("/faqs")

  // };
  // const onDeleteError = (data) => {
  //   errorToast(data.message);
  //   setLoadingDelete(false);
  // };

  const buttonList = [
    <VersionHistoryBtn link={`/static-pages/partner-with-us/version-history`} />,
    howItWorksData.editable && <EditBtn link={`/static-pages/partner-with-us/edit/${params.id}`} />,
    // <DeleteBtn showModal={deletenotifyMebyid} />,
  ];
  function ViewItemGenerator(title, details) {
    return (
      <>
        <span className="dark_grey_color fw_500 fs_14">{title}</span>
        <p className=" fs_14 mb-3 mt-1">{details}</p>
      </>
    );
  }
  return (
    <InnerLayout buttons={buttonList}>
      <div className="user_view_container mb-4">
        <div className="user_view_container mb-4">
          <InnerContainer>
            <LoadAndError loader={loading} error={error}>
              <div className="d-flex flex-column flex-lg-row gap-4 fs_14 py-3 px-3 px-lg-5">
                <div className="col-12 col-lg-6 pe-0 pe-lg-5">
                  <div>
                    {ViewItemGenerator(
                      "Partner With Us Header",
                      howItWorksData?.section1_heading
                    )}
                  </div>
                  <div>
                    {ViewItemGenerator(
                      "Partner With Us Title",
                      howItWorksData?.section1_title
                    )}
                  </div>
                  <div>
                    {ViewItemGenerator(
                      "Partner With Us description",
                      howItWorksData?.section2_heading
                    )}
                  </div>
                  <div>
                    {ViewItemGenerator(
                      "Opportunity Section Heading",
                      howItWorksData?.opportunity_title
                    )}
                  </div>
                  <div>
                    {ViewItemGenerator(
                      "Opportunity Section Description",
                      howItWorksData?.opportunity_subtitle
                    )}
                  </div>
                  <PartnerWithUsAnimation
                    tableTitle="Partners Guide"
                    editable={false}
                    formikValues={howItWorksData}
                    name={"partners_guide"}
                  />
                  <PartnerWithUsFaq
                    tableTitle="Faq Type 1"
                    formikValues={howItWorksData}
                    name={"partner_faq_section1"}
                    editable={false}
                  />
                  <PartnerWithUsFaq
                    tableTitle="Faq Type 2"
                    formikValues={howItWorksData}
                    name={"partner_faq_section2"}
                    editable={false}
                  />
                  {/* <HomeCounterTable
                    formikValues={howItWorksData}
                    name={"section2_counter"}
                    editable={false}
                  /> */}
                </div>
              </div>
            </LoadAndError>
          </InnerContainer>
        </div>
      </div>
    </InnerLayout>
  );
};

export default PartnerWithUsView;
