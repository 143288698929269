import React, { useEffect, useState } from "react";
import VersionHistoryBtn from "../../../../components/buttons/versionHistoryBtn";
import CreateBtn from "../../../../components/buttons/createBtn";
import ExportBtn from "../../../../components/buttons/exportBtn";     
import ApprovalsBtn from "../../../../components/buttons/approvalsBtn";
import FilterBtn from "../../../../components/buttons/filterBtn";
import InnerLayout from "../../../../components/layouts/innerLayout/innerLayout";
import AdminUserFilter from "../../../../components/filter/adminUserFilter";
import AdminUserTableContent from "../../../../components/admin/tables/adminUserTableContent";
import FileSaver from 'file-saver';
import { exportAdmin } from "../../../../redux/actions/AdminUserAction";
import { useDispatch } from "react-redux";
import OurTeamTableContent from "../../../../components/static-pages/our-team/tables/ourTeamTableContent";
import TestimonialsTableContent from "../../../../components/static-pages/testimonials/tables/TestimonialsTableContent";
import TestimonialFilter from "../../../../components/filter/static-pages filter/testimonialFilter";
import { testimonialsTableExport } from "../../../../redux/actions/testimonialsAction";
                                      
export default function TestimonialsTable() {
  const [page, setPage] = useState(0);
  const [showFilter, setShowFilter] = useState(false);
  const [search, setSearch] = useState({ value: "" });
  const handleFilterClose = () => {
    setShowFilter(false);     
  };
  const handleFilterOpen = () => {
    setShowFilter(true);
  };
  const handleSearch = (value) => {
    setSearch({ value: value });
    setPage(0)
  };
  const handlePage = (type) => {
    if (type === "+") {
      setPage((prev) => prev + 1)
    }
    else {
      setPage((prev) => prev - 1)
    }                                 
  }

  // const exportFn = () => {
  //   dispatch(exportAdmin(
  //     onSuccess, onError
  //   ));
  // }
  // const onSuccess = (data) => {
  //   console.log("SUCCESS =====================================", data);
  // };
  // const onError = (data) => {
  //   console.log("ERROR==================", data);
  //   var type = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8";
  //   var blob = new Blob([data], { type: type });
  //   FileSaver.saveAs(blob, "file.xlsx");
  // };
  const buttonList = [

    <ApprovalsBtn link="/static-pages/testimonials/approvals" />,
    <VersionHistoryBtn link="/static-pages/testimonials/version-history" />,
    <CreateBtn link="/static-pages/testimonials/create" />,
    <ExportBtn  dispatchAction={testimonialsTableExport}/>,
    <FilterBtn filterFn={handleFilterOpen} />,
  ];


  return (
    <InnerLayout buttons={buttonList}>
      <TestimonialFilter
        filter={handleSearch}
        showFilter={showFilter}
        closeFilter={handleFilterClose}
      />
      <TestimonialsTableContent page={page} changePage={handlePage} search={search} />
    </InnerLayout>
  );
}
