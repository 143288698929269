import React, { useRef, useState } from "react";
import Modal from "react-bootstrap/Modal";
import CloseIcon from "../../../../assets/icons/closeIcon";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import "./opportunitiesmodals.css";
import InputField from "../../../form/inputFields/inputField";
import SelectFields from "../../../form/selectFields/selectFields";
import ArrowDownIcon from "../../../../assets/icons/arrowdown";
import { useDispatch } from "react-redux";
import { uploadAddressProofFrontImageAction, uploadProfileImageAction } from "../../../../redux/actions/userAction";
import ImageUploadField from "../../../form/inputFields/imageUploadField";
import { useEffect } from "react";
import { numRegex } from "./tags";
import Compressor from "compressorjs";

const Assets = ({ show, handleClose, data = {}, formikValues, id, reasonUpdator }) => {

  const formRef = useRef();
  const dispatch = useDispatch();
  const [location, setLocation] = useState(null);
  const [updated, setUpdated] = useState(false);
  const [file, setFile] = useState(
    { name: formikValues[id]?.location ? formikValues[id]?.location : "" }
  );
  const [fileUrl, setFileUrl] = useState({
    url: formikValues[id]?.location ? formikValues[id]?.location : "",
    error: false,
  });
  const initialValues = {
    priority: id === 0 || id ? formikValues[id]?.priority : "",
    type: id === 0 || id ? formikValues[id]?.type : "",
  };
  const loginSchema = Yup.object().shape({
    priority: Yup.string().trim().matches(numRegex, "Number only"),
    type: Yup.string().trim(),
  });
  const onSubmit = (values, { resetForm }) => {
    if (fileUrl.url) {
      resetForm();
      reasonUpdator(id, values, fileUrl.url)
      handleClose()
      setFileUrl({ url: "", error: false })
      setFile("")
    }
    else {
      setFileUrl({ url: "", error: true });
    }
  };
  useEffect(() => {
    setFileUrl(
      {
        url: formikValues[id]?.location ? formikValues[id]?.location : "",
        error: false,
      }
    );
    setFile({ name: formikValues[id]?.location })
  }, [updated, formikValues[id]]);
  function handleUploadAssetsFile(e) {
    if (e.target?.files.length !== 0) {
      setFile(e.target.files[0]);
      const file = e.target.files[0];
      const fileType = file.type;
      if (fileType.startsWith('image/')) {
        new Compressor(e.target.files[0], {
          quality: 0.8,
          success(result) {
            const file = new File([result], result.name, { type: result.type })
            dispatch(
              uploadAddressProofFrontImageAction(
                file,
                onUploadFileSuccess,
                onUploadFileError
              )
            );
          }
        })
      } else {
        dispatch(
          uploadAddressProofFrontImageAction(
            e.target.files[0],
            onUploadFileSuccess,
            onUploadFileError
          ))
      }
    }
    e.target.value = null;
  }
  const onUploadFileSuccess = (data) => {
    setFileUrl({ url: data.data.data.location, error: false });
    // console.log(data.data.data);
  };
  const onUploadFileError = (data) => {
    setFileUrl({ url: "", error: true });
    console.log(data);
  };



  return (
    <Modal
      centered
      show={show}
      onHide={() => {
        handleClose(); setUpdated(!updated)
      }}
      dialogClassName={`oppoptunities_modals_body mx-auto `}
      contentClassName={"oppoptunities_modals_body_content  p-2"}
    >
      <Modal.Body>
        <>
          <div className="d-flex justify-content-between  ">
            <h2 className="green_color fs_15 fn_Montserrat fw_600 pt-1">
              ASSETS{" "}
            </h2>
            <button
              type="button"
              onClick={() => { handleClose(); setUpdated(!updated) }}
              className="light_grey_color  fs_15 fn_Montserrat bg-transparent border-0 fw_600 "
            >
              <span className="pt-1">DISMISS</span>
              <span className="ms-2">
                <CloseIcon width={13} height={13} fill="#A5A5A5" />
              </span>
            </button>
          </div>
          <Formik
            innerRef={formRef}
            initialValues={initialValues}
            onSubmit={onSubmit}
            validationSchema={loginSchema}
          >
            <Form className="mt-3">
              <InputField
                id={"priority"}
                label={"Enter Priority"}
                type={"text"}
                placeholder={"Enter Priority"}
                name={"priority"}
              />
              <SelectFields
                Icon={<ArrowDownIcon width={20} height={20} fill={"#A5A5A5"} />}
                label={"Type"}
                placeholder={"Select the Type of asset"}
                name={"type"}
                options={assetType}
                id={"asset"}
                default1={formikValues[id]?.type ? { label: formikValues[id]?.type, value: formikValues[id]?.type } : ""}
              />
              {/* <ImageUploadField
                inputClassName={"admin-create-password"}
                id={"location"}
                type={"file"}
                label={"Upload"}
                placeholder={"Click to upload"}
                name={"location"}
                handleImageChange={handleImageChange}
              /> */}

              <div>
                <span className="dark_grey_color fn_Montserrat fw_500 fs_14  ps-2">
                  Upload
                </span>
                <div className="llp_document_upload d-flex justify-content-between align-items-center pe-3">
                  <input
                    className="position-absolute"
                    type={"file"}
                    style={{ visibility: "hidden" }}
                    id="assetFile"
                    name="fileUrl"
                    onChange={(e) => {
                      handleUploadAssetsFile(e);
                    }}
                  />
                  <label className=" w-75 py-2 ps-3  " htmlFor="assetFile">
                    {
                      <p>
                        {fileUrl.url ? file.name : "Click to upload"}
                      </p>
                    }
                  </label>
                  {fileUrl.url && <span
                    onClick={() => {
                      setFileUrl({ url: "", error: true });
                      setFile("");
                    }}
                  >
                    <CloseIcon width={10} height={10} fill="#A5A5A5" />
                  </span>}
                </div>
                {fileUrl.error && (
                  <span className="fs_12 ms-2 text-danger fw-semibold fn_Nunito">
                    Required!
                  </span>
                )}
              </div>

              <div className="d-flex justify-content-end mt-4">
                <button
                  type="submit"
                  className="secondary_color fs_15  fn_Montserrat fw_600 border-0 tertiary_bg px-4 px-lg-5 py-2 py-lg-2 rounded-2"
                >
                  {id === null ? "CREATE" : "UPDATE"}
                </button>
              </div>
            </Form>
          </Formik>
        </>
      </Modal.Body>
    </Modal >
  );
};
export default Assets;
export const selectPriority = [
  { value: "Priority1", label: "Priority1" },
  { value: "Priority2", label: "Priority2" },
  { value: "Priority2", label: "Priority3" },
  { value: "Priority4", label: "Priority4" },
];
export const assetType = [
  { value: "image", label: "image" },
  { value: "video", label: "video" },
];
