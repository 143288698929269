import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import InnerLayout from "../../../../components/layouts/innerLayout/innerLayout";
import InnerContainer from "../../../../components/layouts/innerContainer/innerContainer";
import VersionHistoryBtn from "../../../../components/buttons/versionHistoryBtn";
import EditBtn from "../../../../components/buttons/editBtn";
import DeleteBtn from "../../../../components/buttons/deleteBtn";
import DeleteModal from "../../../../components/modals/deleteModal";
import successToast from "../../../../components/utilits/successToast";
import errorToast from "../../../../components/utilits/errorToast";
import DownloadIcon from "../../../../assets/icons/downloadIcon";

import LoadAndError from "../../../../components/utilits/LoadAndError";
import HomeCounterTable from "../../../../components/static-pages/home-page/tables/homeCounterTable";
import { viewCampaignAction } from "../../../../redux/actions/campaignActions";
import HomeCardsTable from "../../../../components/static-pages/home-page/tables/homeCardsTable";
import ShareableLink from "../../../../components/utilits/shareableLink";

const CampaignView = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [howItWorksData, setHowItWorksData] = useState({});
  const dispatch = useDispatch();
  const params = useParams();
  useEffect(() => {
    setLoading(true);
    dispatch(viewCampaignAction(params.id, onSuccess, onError));
  }, []);
  const onSuccess = (data) => {
    console.log(data.data);
    setHowItWorksData(data.data);
    console.log("SUCCESS");
    setLoading(false);
    setError(false);
  };
  const onError = (data) => {
    console.log("ERROR");
    setLoading(false);
    setError(true);
  };
  // const [deleteData, setDeleteData] = useState({ name: "", id: "" });
  // const [loadingDelete, setLoadingDelete] = useState(false);
  // const [show, setShow] = useState(false);
  // const handleShow = () => setShow(true);
  // const handleClose = () => setShow(false);

  // const handleDelete = () => {
  //   setLoadingDelete(true);
  //   dispatch(
  //     deleteFaq(
  //       faqDetails.id,
  //       onDeleteSuccess,
  //       onDeleteError
  //     )
  //   );
  // };
  // const onDeleteSuccess = (data) => {
  //   successToast(data.message);
  //   setLoadingDelete(false);
  //   setShow(false);
  //   navigate("/faqs")

  // };
  // const onDeleteError = (data) => {
  //   errorToast(data.message);
  //   setLoadingDelete(false);
  // };

  const buttonList = [
    <VersionHistoryBtn link={`/static-pages/campaign/version-history`} />,
    howItWorksData.editable && <EditBtn link={`/static-pages/campaign/edit/${params.id}`} />,
    // <DeleteBtn showModal={deletenotifyMebyid} />,
  ];

  function ViewItemGenerator(title, details) {
    return (
      <>
        <span className="dark_grey_color fw_500 fs_14">{title}</span>
        <p className=" fs_14 mb-3 mt-1">{details}</p>
      </>
    );
  }

  return (
    <InnerLayout buttons={buttonList}>
      <div className="user_view_container mb-4">
        <div className="user_view_container mb-4">
          <InnerContainer>
            <LoadAndError loader={loading} error={error}>
              <div className="d-flex flex-column flex-lg-row gap-4 fs_14 py-3 px-3 px-lg-5">
                <div className="col-12 col-lg-6 pe-0 pe-lg-5">
                  <div>
                    {ViewItemGenerator(
                      "Navbar Title",
                      howItWorksData?.header_title
                    )}
                  </div>
                  <div>
                    {ViewItemGenerator(
                      "Header title",
                      howItWorksData?.section1_title
                    )}
                  </div>
                  <div>
                    {ViewItemGenerator(
                      "Header description",
                      howItWorksData?.section1_description
                    )}
                  </div>
                  <div>
                    {ViewItemGenerator(
                      "Header Button Text",
                      howItWorksData?.section1_button
                    )}
                  </div>
                  <div>
                    {ViewItemGenerator(
                      "Card One Title",
                      howItWorksData?.card_image1_title
                    )}
                  </div>
                  <div className="mb-3">
                    <span className="dark_grey_color fw_500 fs_16">Card One Image</span>{" "}
                    <div className="blog_create_image_container mt-2 position-relative d-flex  justify-content-center align-items-center">
                      <img
                        src={howItWorksData?.section1_card_image1}
                        className={"user-edit-profile-picture"}
                      />
                      <button
                        type="button"
                        className="position-absolute border-0 bg-white d-flex align-items-center fs_10 top-0 end-0"
                      >
                        <a
                          href={howItWorksData?.section1_card_image1}
                          download="file"
                        ></a>
                        <DownloadIcon width={8} />
                        <span className="ms-1">Download</span>
                      </button>
                    </div>
                  </div>
                  <HomeCardsTable
                    formikValues={howItWorksData}
                    name={"card1_description"}
                    editable={false}
                  />
                  <div>
                    {ViewItemGenerator(
                      "Card Two Title",
                      howItWorksData?.card_image2_title
                    )}
                  </div>
                  <div className="mb-3">
                    <span className="dark_grey_color fw_500 fs_16">Card Two Image</span>{" "}
                    <div className="blog_create_image_container mt-2 position-relative d-flex  justify-content-center align-items-center">
                      <img
                        src={howItWorksData?.section1_card_image2}
                        className={"user-edit-profile-picture"}
                      />
                      <button
                        type="button"
                        className="position-absolute border-0 bg-white d-flex align-items-center fs_10 top-0 end-0"
                      >
                        <a
                          href={howItWorksData?.section1_card_image2}
                          download="file"
                        ></a>
                        <DownloadIcon width={8} />
                        <span className="ms-1">Download</span>
                      </button>
                    </div>
                  </div>
                  <HomeCardsTable
                    formikValues={howItWorksData}
                    name={"card2_description"}
                    editable={false}
                  />


                  <div>
                    {ViewItemGenerator(
                      "Card Two Title",
                      howItWorksData?.card_image3_title
                    )}
                  </div>
                  <div className="mb-3">
                    <span className="dark_grey_color fw_500 fs_16">Card Two Image</span>{" "}
                    <div className="blog_create_image_container mt-2 position-relative d-flex  justify-content-center align-items-center">
                      <img
                        src={howItWorksData?.section1_card_image3}
                        className={"user-edit-profile-picture"}
                      />
                      <button
                        type="button"
                        className="position-absolute border-0 bg-white d-flex align-items-center fs_10 top-0 end-0"
                      >
                        <a
                          href={howItWorksData?.section1_card_image3}
                          download="file"
                        ></a>
                        <DownloadIcon width={8} />
                        <span className="ms-1">Download</span>
                      </button>
                    </div>
                  </div>
                  <HomeCardsTable
                    formikValues={howItWorksData}
                    name={"card3_description"}
                    editable={false}
                  />

                  <div>
                    {ViewItemGenerator(
                      "Opportunity Section Title",
                      howItWorksData?.opportunities_title
                    )}
                  </div>
                  <div>
                    {ViewItemGenerator(
                      "Opportunity Subtitle",
                      howItWorksData?.opportunities_subtitle
                    )}
                  </div>
                  <div>
                    {ViewItemGenerator(
                      "Opportunity Button Text",
                      howItWorksData?.opportunities_button
                    )}
                  </div>
                  {/* <HowItWorksReasonTable
                  name="reasons"
                  formikValues={howItWorksData}
                  editable={false}
                /> */}
                  <div>
                    {ViewItemGenerator(
                      "Counter Title",
                      howItWorksData?.section2_title
                    )}
                  </div>
                  <div>
                    {ViewItemGenerator(
                      "Counter Description",
                      howItWorksData?.section2_description
                    )}
                  </div>
                  <HomeCounterTable
                    formikValues={howItWorksData}
                    name={"section2_counter"}
                    editable={false}
                  />
                </div>
                <div className="col-12 col-lg-6 pe-0 pe-lg-5">
                  <ShareableLink title="Campaign Link" link={"/campaign"} />
                </div>
              </div>
            </LoadAndError>
          </InnerContainer>
        </div>
      </div>
      {/* <DeleteModal
        loader={loadingDelete}
        handleClose={handleClose}
        deleteFn={handleDelete}
        data={deleteData}
        show={show}
      /> */}
    </InnerLayout>
  );
};

export default CampaignView;
