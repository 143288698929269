import React, { useState, useEffect } from "react";
import ApprovalsBtn from "../../../../components/buttons/approvalsBtn";
import ApproveBtn from "../../../../components/buttons/approveBtn";
import RejectBtn from "../../../../components/buttons/rejectBtn";
import VersionHistoryBtn from "../../../../components/buttons/versionHistoryBtn";
import InnerContainer from "../../../../components/layouts/innerContainer/innerContainer";
import InnerLayout from "../../../../components/layouts/innerLayout/innerLayout";
import "../wishlist.css";
import { useDispatch } from "react-redux";
import errorToast from "../../../../components/utilits/errorToast";
import successToast from "../../../../components/utilits/successToast";
import moment from "moment";
import { useParams, useNavigate } from "react-router-dom";
import { approvalsViewWishlist, approveWishlist, rejectWishlist, viewApprovalWishlistById, viewWishlist } from "../../../../redux/actions/wishlistActions";
import LoadingSpinnerTable from "../../../../components/utilits/loadingSpinnerTable";
import LoadAndError from "../../../../components/utilits/LoadAndError";

const WishlistApprovalView = () => {
    const params = useParams()
    const dispatch = useDispatch()
    const navigate = useNavigate();
    const [wishlistData, setWishlistData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false)

    useEffect(() => {
        setLoading(true)
         console.log("the id",params.id);
        dispatch(
            viewApprovalWishlistById(params.id, onSuccess, onError)
        );
    }, [])

    //View wishlist by id
    const onSuccess = (data) => {
        console.log(data.data);
        setWishlistData(data.data.data);
        setLoading(false)
        setError(false)
    };
    const onError = (data) => {
        errorToast(data.message)
        //errorToast("went wrong")
        setLoading(false)
        setError(true)
    };

    const data = [
        {
            heading: "Approval ID",
            title: wishlistData?.id ? wishlistData?.id : "-",
        },
        {
            heading: "Opportunity ID",
            title: wishlistData?.new_data == null ? wishlistData?.old_data?.opportunity_id : wishlistData?.new_data?.opportunity_id,
        },
        {                     
            heading: "Opportunity Name",
            title: wishlistData?.new_data == null ? wishlistData?.old_data?.title : wishlistData?.new_data?.title,
        },
        {
            heading: "User ID",
            title: wishlistData?.new_data == null ? wishlistData?.old_data?.user_id : wishlistData?.new_data?.user_id,
        },
        {
            heading: "Wishlist ID",
            title: wishlistData?.new_data == null ? wishlistData?.old_data?.id : wishlistData?.new_data?.id,
        },
        {
            heading: "User Name",
            title: wishlistData?.new_data == null ? wishlistData?.old_data?.user_name : wishlistData?.new_data?.user_name,
        },

        {
            heading: "Created At",
            title: (wishlistData?.createdAt) ? moment(wishlistData?.createdAt).format("DD-MM-YYYY HH:mm:ss") : "-",
        },

        {
            heading: "Updated At",
            title: (wishlistData?.updatedAt) ? moment(wishlistData?.updatedAt).format("DD-MM-YYYY HH:mm:ss") : "-",
        },
        {
            heading: "Operation Type",
            title: wishlistData?.new_data == null ? "Delete" : wishlistData?.new_data && wishlistData?.old_data ? "Edit" : wishlistData?.old_data == null ? "Create" : ""
          }
    ];

    //Approval , reject sections

    const approveRole = () => {
        dispatch(
           approveWishlist(wishlistData?.id,onApproveSuccess,onApproveError)
        );
    }

    const onApproveSuccess = (data) => {
        console.log(data.data);
        successToast(data.message)
        setLoading(false)
        navigate("/opportunity/wishlist/approvals")
        setError(false)
    };
    const onApproveError = (data) => {
        errorToast(data.message)
        //errorToast("went wrong")
        setLoading(false)
        setError(true)
    };

    //Reject Section
    const rejectWishListFn = () => {
        dispatch(
           rejectWishlist(wishlistData?.id,onRejectSuccess,onRejectError)
        );
    }

    const onRejectSuccess = (data) => {
        // successToast(data.data.message)
        successToast(data.message)
        setLoading(false)
        navigate("/opportunity/wishlist/approvals")
        setError(false)
    };
    const onRejectError = (data) => {
        errorToast(data.data.data)
        setLoading(false)
        setError(true)
    };
    const buttonList = [
        <VersionHistoryBtn link='/opportunity/wishlist/version-history'/>,
        <ApproveBtn approveFn={approveRole}/>,
        <RejectBtn showModal={rejectWishListFn}/>
    ];
    return (
        <InnerLayout buttons={buttonList}>
            <div className="wishlist-container">
                <InnerContainer error={error} oldData={wishlistData?.old_data} newData={wishlistData?.new_data}>
                    {loading ? (
                         <LoadingSpinnerTable/>
                    ):(
          <LoadAndError loader={loading} error={error}>
                    <div className="pt-3  ps-4 ps-lg-4 ms-3">
                        {data.map((item, index) => (
                            <div key={item.id} className="mb-2">
                                <h5 className="dark_grey_color fs_13 fn_Montserrat fw_500 m-0">
                                    {item.heading}
                                </h5>
                                <p
                                    className={` fs_13 fn_Nunito fw_500  ${item.heading === "Opportunity ID" || item.heading === "User ID" ? 'secondary_color' : 'dark_title_color'} `}>
                                    {item.title}
                                </p>
                            </div>
                        ))}
                    </div>
                    </LoadAndError>
                    )}
                </InnerContainer>
            </div>
        </InnerLayout>
    );
};

export default WishlistApprovalView;






