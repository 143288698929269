import React, { useRef } from "react";
import { Spinner } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import CloseIcon from "../../../../assets/icons/closeIcon";
import "../../../../components/modals/modals.css";

const ImportUsersModal = ({ show, handleClose, importFn, loader = false }) => {
    return (
        <Modal
            centered
            backdrop={"static"}
            show={show}
            onHide={handleClose}
            dialogClassName={`delete_modals_body mx-2 px-1 mx-sm-auto`}
            contentClassName={"delete_modals_body_content  p-1"}
        >
            <Modal.Body>
                <>
                    <div className="d-flex justify-content-between  ">
                        <h2 className="green_color fs_15 fs_sm_13 fn_Montserrat fw_600 pt-2">
                            Import Users
                        </h2>
                        <button
                            onClick={handleClose}
                            className="light_grey_color  fs_15 fs_sm_13 fn_Montserrat bg-transparent border-0 fw_600 "
                        >
                            <span>DISMISS</span>
                            <span className="ms-2">
                                <CloseIcon width={13} height={13} fill="#A5A5A5" />
                            </span>
                        </button>
                    </div>
                    <p className="fn_Montserrat fs_17 fs_sm_15 mt-2 text-nowrap popup_title_color fw_400">
                        Are you sure you want to Import Users
                        ?
                    </p>
                    <div className="d-flex gap-4 justify-content-end mt-4">
                        <button
                            onClick={handleClose}
                            className="secondary_color fs_15 fs_sm_15 fn_Montserrat fw_600 delete_popup_button bg-white px-3 px-lg-4 py-2 py-lg-2 rounded-2"
                        >
                            DON'T DO IT
                        </button>
                        <button
                            disabled={loader}
                            onClick={() => { importFn() }}
                            className="secondary_color fs_15 fs_sm_15 fn_Montserrat fw_600 border-0 tertiary_bg px-4 px-lg-5 py-2 py-lg-2 rounded-2"
                        >
                            {loader ? (
                                <Spinner
                                    as="span"
                                    size="sm"
                                    animation="border"
                                    variant="secondary"
                                    role="status"
                                >
                                    <span className="visually-hidden">Loading...</span>
                                </Spinner>
                            ) : (
                                "YES"
                            )}
                        </button>
                    </div>
                </>
            </Modal.Body>
        </Modal>
    );
};
export default ImportUsersModal;
