import { CREATE_Notification_OPTIONS, NOTIFICATION_APPROVALS_EXPORT, NOTIFICATION_APPROVALS_LIST, NOTIFICATION_APPROVALS_VIEW, NOTIFICATION_APPROVE, NOTIFICATION_CREATE, NOTIFICATION_DELETE, NOTIFICATION_EDIT, NOTIFICATION_EXPORT, NOTIFICATION_LIST, NOTIFICATION_READ, NOTIFICATION_REJECT, NOTIFICATION_SUBMIT, NOTIFICATION_VERSION_EXPORT, NOTIFICATION_VERSION_LIST, NOTIFICATION_VERSION_VIEW } from "./types";

export const viewAllNotification = (data, pageNo, onSuccess, onError) => {
    return {
        type: NOTIFICATION_LIST,
        data,
        pageNo,
        onSuccess,
        onError,
    };
};
export const readApprovalNotification = (id, onSuccess, onError) => {
    return {
        type: NOTIFICATION_APPROVALS_VIEW,
        id,
        onSuccess,
        onError,
    };
}
export const readNotification = (id, onSuccess, onError) => {
    return {
        type: NOTIFICATION_READ,
        id,
        onSuccess,
        onError,
    };
}
export const versionTableNotification = (data, pageNo, onSuccess, onError) => {
    return {
        type: NOTIFICATION_VERSION_LIST,
        data,
        pageNo,
        onSuccess,
        onError,
    };
}
export const deleteNotification = (id, onSuccess, onError) => {
    return {
        type: NOTIFICATION_DELETE,
        id,
        onSuccess,
        onError,
    };
}
export const notificationVersionHistoryview = (id, onSuccess, onError) => {
    return {
        type: NOTIFICATION_VERSION_VIEW,
        id,
        onSuccess,
        onError,
    };
}
export const notificationApprovalsTable = (data, pageNo = 0, onSuccess, onError) => {
    return {
        type: NOTIFICATION_APPROVALS_LIST,
        data,
        pageNo,
        onSuccess,
        onError,
    };
}


export const notificationCreate = (
    data,
    onSuccess,
    onError
) => {
    return {
        type: NOTIFICATION_CREATE,
        data,
        onSuccess,
        onError,
    };
};


export const editNotification = (id, data, onSuccess, onError) => {
    return {
        type: NOTIFICATION_EDIT,
        id,
        data,
        onSuccess,
        onError,
    }
};

export const approveNotification = (id, onSuccess, onError) => {
    return {
        type: NOTIFICATION_APPROVE,
        id,
        onSuccess,
        onError,
    };
}

export const rejectNotification = (id, onSuccess, onError) => {
    return {
        type: NOTIFICATION_REJECT,
        id,
        onSuccess,
        onError,
    };
}


export const notificationTableExport = (onSuccess, onError) => {
    return {
        type: NOTIFICATION_EXPORT,
        onSuccess,
        onError,
    };
};
export const notificationApprovalsExport = (onSuccess, onError) => {
    return {
        type: NOTIFICATION_APPROVALS_EXPORT,
        onSuccess,
        onError,
    };
};
export const notificationVersionExport = (onSuccess, onError) => {
    return {
        type: NOTIFICATION_VERSION_EXPORT,
        onSuccess,
        onError,
    };
};

export const notificationSubmitAction = (id, onSuccess, onError) => {
    return {
        type: NOTIFICATION_SUBMIT,
        id,
        onSuccess,
        onError,
    };
};