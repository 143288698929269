import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { publishOpportunity } from "../../redux/actions/opportunitiesActions";
import errorToast from "../utilits/errorToast";
import successToast from "../utilits/successToast";
import usePermissionChecker from "../utilits/usePermissionChecker";
import { AdminButton } from "./versionHistoryBtn";

const PublishBtn = ({
  id = "",
  publishFn = false,
  link = "/opportunity/opportunities",
  disabled = true,
}) => {
  const access = usePermissionChecker("checker");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const publishFnc = () => {
    if (publishFn) {
      publishFn();
    } else dispatch(publishOpportunity(id, onSuccess, onError));
  };
  const onSuccess = (data) => {
    successToast(data?.data?.message);
    navigate(link);
  };
  const onError = (data) => {
    errorToast(data?.data?.data);
  };
  return (
    access && (
      <AdminButton
        disabled={!disabled}
        onClick={publishFnc}
        color={`${!disabled ? "grey" : "#669E98"}`}
        padding="3px 15px"
      >
        <span className="ms-1 pdt_1">PUBLISH</span>
      </AdminButton>
    )
  );
};

export default PublishBtn;
