import { authHeader } from "../config";
import { api } from "./api";

export const createFaqCatgoryApi = (params) => {
    return api.post("/api/homestatic/create-faq-category", params, {
        headers: {
            "x-access-token": localStorage.getItem("accessToken"),
            "Content-Type": "application/json",
        },
    });
};

export const approveFaqCatgoryApi = (params) => {
    return api.post("/api/homestatic/approve-faq-category", JSON.stringify(params), {
        headers: {
            "x-access-token": localStorage.getItem("accessToken"),
            "Content-Type": "application/json",
        },
    });
};

export const rejectFaqCatgoryApi = (id, params) => {
    return api.post(
        `/api/homestatic/reject-faq-category/${id}`,
        JSON.stringify(params),
        {
            headers: {
                "x-access-token": localStorage.getItem("accessToken"),
                "Content-Type": "application/json",
            },
        }
    );
};

export const viewFaqCatgoryDetailApi = (id, params) => {
    return api.post(
        `/api/homestatic/view-faq-category/${id}`,
        JSON.stringify(params),
        {
            headers: {
                "x-access-token": localStorage.getItem("accessToken"),
                "Content-Type": "application/json",
            },
        }
    );
};



export const viewFaqCatgoryListApi = (page = 0, params) => {
    return api.post(
        `/api/homestatic/views-faq-categorys?page_no=${page}`,
        params,
        {
            headers: {
                "x-access-token": localStorage.getItem("accessToken"),
                "Content-Type": "application/json",
            },
        }
    );
};

export const editFaqCatgoryApi = (id, params) => {
    return api.post(
        `/api/homestatic/edit-faq-category/${id}`,
        JSON.stringify(params),
        {
            headers: {
                "x-access-token": localStorage.getItem("accessToken"),
                "Content-Type": "application/json",
            },
        }
    );
};

export const deleteFaqCatgoryApi = (id, params) => {
    return api.post(
        `/api/homestatic/delete-faq-category/${id}`,
        JSON.stringify(params),
        {
            headers: {
                "x-access-token": localStorage.getItem("accessToken"),
                "Content-Type": "application/json",
            },
        }
    );
};


export const faqCatgoryApprovalListApi = (page = 0, params) => {
    return api.post(
        `/api/homestatic/views-faq-categorys-checkkers?page_no=${page}`,
        JSON.stringify(params),
        {
            headers: {
                "x-access-token": localStorage.getItem("accessToken"),
                "Content-Type": "application/json",
            },
        }
    );
};



export const faqCatgoryApprovalByIdApi = (id, params) => {
    return api.post(
        `/api/homestatic/view-faq-category-checkker/${id}`,
        JSON.stringify(params),
        {
            headers: {
                "x-access-token": localStorage.getItem("accessToken"),
                "Content-Type": "application/json",
            },
        }
    );
};

export const versionHistoryFaqCatgoryListApi = (pageNo = 0, params) => {
    return api.post(
        `/api/homestatic/views-faq-categorys-versions?page_no=${pageNo}`,
        JSON.stringify(params),
        {
            headers: {
                "x-access-token": localStorage.getItem("accessToken"),
                "Content-Type": "application/json",
            },
        }
    );
};

export const versionHistoryFaqCatgoryDetailApi = (id, params) => {
    return api.post(
        `/api/homestatic/view-faq-category-version/${id}`,
        JSON.stringify(params),
        {
            headers: {
                "x-access-token": localStorage.getItem("accessToken"),
                "Content-Type": "application/json",
            },
        }
    );
};
export const faqCatgoryOptionsApi = (id, params) => {
    return api.post(
        `/api/admins/version-admin/${id}`,
        JSON.stringify(params),
        {
            headers: {
                "x-access-token": localStorage.getItem("accessToken"),
                "Content-Type": "application/json",
            },
        }
    );
};

export const faqcategoryTableExportApi = ( params) => {
    return api.post(
        `/api/homestatic/export-main-faq-category`,
        JSON.stringify(params),
        {
            headers: {
                "x-access-token": localStorage.getItem("accessToken"),
                "Content-Type": "application/json",
            },
        }
    );
};
export const faqcategoryApprovalsExportApi = ( params) => {
    return api.post(
        `/api/homestatic/export-approval-faq-category`,
        JSON.stringify(params),
        {
            headers: {
                "x-access-token": localStorage.getItem("accessToken"),
                "Content-Type": "application/json",
            },
        }                   
    );
};
export const faqcategoryVersionExportApi = ( params) => {
    return api.post(
        `/api/homestatic/export-version-faq-category`,
        JSON.stringify(params),
        {
            headers: {
                "x-access-token": localStorage.getItem("accessToken"),
                "Content-Type": "application/json",
            },
        }
    );
};


//on submit
export const faqcategorySubmitApi = (id, params) => {
    return api.post(`/api/homestatic/on-submit/${id}`, params, {
        headers: {
            "x-access-token": localStorage.getItem("accessToken"),
            "Content-Type": "application/json",
        },
    });
};

