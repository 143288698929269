import React from "react";
import styles from "./opportunitiesread.module.css";
import UpcideImage from "../../../../assets/images/upcide-icon.svg";
import useScreenSizeDetector from "../../../utilits/useScreenSizeDetector";
const Something = () => {
    const { width } = useScreenSizeDetector()
    return (
        <>
            <div className={`${styles.something_container} mt-5 py-4 py-lg-5 px-2 px-lg-0`}>
                <div className="d-flex  justify-content-center  align-items-center gap-lg-5 gap-3">
                    <div className={`${styles.upcide_image_container}  `}> <img src={UpcideImage} width={width > 768 ? 120 : 90} height={width > 768 ? 120 : 90} /></div>
                    <div>
                        <h2 className={`${styles.something_text}  fs_30 fs_sm_20 fw_600 fn_Montserrat  white_color`}>
                            Want Something More <span className="tertiary_color fw_600 fn_Montserrat">interesting</span>?
                            <p className="fs_14 opacity-50 fw_500 fn_Montserrat pt-2">
                                HAVE A LOOK BELOW IN OUR HANDPICKED SELECTION OF ASSETS
                            </p>
                        </h2>
                    </div>
                </div>

            </div>
        </>
    );
};

export default Something;
