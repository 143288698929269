import { APPROVE_BADGES, CREATE_BADGES, DELETE_BADGES, EDIT_BADGES, BADGES_APPROVALS_EXPORT, BADGES_APPROVAL_TABLE, BADGES_APPROVAL_VIEW, BADGES_EXPORT, BADGES_OPTIONS, BADGES_SUBMIT, BADGES_TABLE, BADGES_VERSION_EXPORT, BADGES_VERSION_HISTORY, BADGES_VERSION_HISTORY_TABLE, REJECT_BADGES, VIEW_BADGES } from "./types";


export const createBadges = (
    data,
    onSuccess,
    onError
) => {
    return {
        type: CREATE_BADGES,
        data,
        onSuccess,
        onError,
    };
};

export const approveBadges = (id, onSuccess, onError) => {
    return {
        type: APPROVE_BADGES,
        id,
        onSuccess,
        onError,
    };
};

export const rejectBadges = (id, onSuccess, onError) => {
    return {
        type: REJECT_BADGES,
        id,
        onSuccess,
        onError,
    };
};

export const viewBadgesDetail = (id, onSuccess, onError) => {
    return {
        type: VIEW_BADGES,
        id,
        onSuccess,
        onError,
    };
};

export const viewBadgesList = (data, page_no = 0, onSuccess, onError) => {
    return {
        type: BADGES_TABLE,
        data,
        page_no,
        onSuccess,
        onError,
    };
};

export const editBadges = (
    id,
    data,
    onSuccess,
    onError
) => {
    return {
        type: EDIT_BADGES,
        id,
        data,
        onSuccess,
        onError,
    };
};


export const deleteBadges = (id, onSuccess, onError) => {
    return {
        type: DELETE_BADGES,
        id,
        onSuccess,
        onError,
    };
};



export const badgesApprovalList = (data, page_no = 0, onSuccess, onError) => {
    return {
        type: BADGES_APPROVAL_TABLE,
        data,
        page_no,
        onSuccess,
        onError,
    };
};

export const badgesApprovalById = (id, onSuccess, onError) => {
    return {
        type: BADGES_APPROVAL_VIEW,
        id,
        onSuccess,
        onError,
    };
};


export const versionHistoryBadgesList = (data, page, onSuccess, onError) => {
    return {
        type: BADGES_VERSION_HISTORY_TABLE,
        data,
        page,
        onSuccess,
        onError,
    };
};

export const versionHistoryBadgesDetail = (id, onSuccess, onError) => {
    return {
        type: BADGES_VERSION_HISTORY,
        id,
        onSuccess,
        onError,
    };
};
export const badgesOptions = (onSuccess, onError) => {
    return {
        type: BADGES_OPTIONS,
        onSuccess,
        onError,
    };
};

export const badgesTableExport = (onSuccess, onError) => {
    return {
        type: BADGES_EXPORT,
        onSuccess,
        onError,
    };
};
export const badgesApprovalsExport = (onSuccess, onError) => {
    return {
        type: BADGES_APPROVALS_EXPORT,
        onSuccess,
        onError,
    };
};
export const badgesVersionExport = (onSuccess, onError) => {
    return {
        type: BADGES_VERSION_EXPORT,
        onSuccess,
        onError,
    };
};



export const badgesSubmit = (id, onSuccess, onError) => {
    return {
        type: BADGES_SUBMIT,
        id,
        onSuccess,
        onError,
    };
};



