import { APPROVE_REFER, CREATE_REFER, DELETE_REFER, EDIT_REFER, REFER_APPROVALS_EXPORT, REFER_APPROVAL_TABLE, REFER_APPROVAL_VIEW, REFER_EXPORT, REFER_OPTIONS, REFER_SUBMIT, REFER_TABLE, REFER_VERSION_EXPORT, REFER_VERSION_HISTORY, REFER_VERSION_HISTORY_TABLE, REJECT_REFER, VIEW_REFER } from "./types";


export const createRefer = (
    data,
    onSuccess,
    onError
) => {
    return {
        type: CREATE_REFER,
        data,
        onSuccess,
        onError,
    };
};

export const approveRefer = (id, onSuccess, onError) => {
    return {
        type: APPROVE_REFER,
        id,
        onSuccess,
        onError,
    };
};

export const rejectRefer = (id, onSuccess, onError) => {
    return {
        type: REJECT_REFER,
        id,
        onSuccess,
        onError,
    };
};

export const viewReferDetail = (id, onSuccess, onError) => {
    return {
        type: VIEW_REFER,
        id,
        onSuccess,
        onError,
    };
};

export const viewReferList = (data, page_no = 0, onSuccess, onError) => {
    return {
        type: REFER_TABLE,
        data,
        page_no,
        onSuccess,
        onError,
    };
};

export const editRefer = (
    id,
    data,
    onSuccess,
    onError
) => {
    return {
        type: EDIT_REFER,
        id,
        data,
        onSuccess,
        onError,
    };
};


export const deleteRefer = (id, onSuccess, onError) => {
    return {
        type: DELETE_REFER,
        id,
        onSuccess,
        onError,
    };
};



export const referApprovalList = (data, page_no = 0, onSuccess, onError) => {
    return {
        type: REFER_APPROVAL_TABLE,
        data,
        page_no,
        onSuccess,
        onError,
    };
};

export const referApprovalById = (id, onSuccess, onError) => {
    return {
        type: REFER_APPROVAL_VIEW,
        id,
        onSuccess,
        onError,
    };
};


export const versionHistoryReferList = (data, page, onSuccess, onError) => {
    return {
        type: REFER_VERSION_HISTORY_TABLE,
        data,
        page,
        onSuccess,
        onError,
    };
};

export const versionHistoryReferDetail = (id, onSuccess, onError) => {
    return {
        type: REFER_VERSION_HISTORY,
        id,
        onSuccess,
        onError,
    };
};
export const referOptions = (onSuccess, onError) => {
    return {
        type: REFER_OPTIONS,
        onSuccess,
        onError,
    };
};

export const referTableExport = (onSuccess, onError) => {
    return {
        type: REFER_EXPORT,
        onSuccess,
        onError,
    };
};
export const referApprovalsExport = (onSuccess, onError) => {
    return {
        type: REFER_APPROVALS_EXPORT,
        onSuccess,
        onError,
    };
};
export const referVersionExport = (onSuccess, onError) => {
    return {
        type: REFER_VERSION_EXPORT,
        onSuccess,
        onError,
    };
};



export const referSubmit = (id, onSuccess, onError) => {
    return {
        type: REFER_SUBMIT,
        id,
        onSuccess,
        onError,
    };
};



