import React, { useEffect, useRef, useState } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import InnerLayout from "../../../../components/layouts/innerLayout/innerLayout";
import InnerContainer from "../../../../components/layouts/innerContainer/innerContainer";
import SelectFields from "../../../../components/form/selectFields/selectFields";
import SaveBtn from "../../../../components/buttons/saveBtn";
import InputField from "../../../../components/form/inputFields/inputField";
import ErrorMsg from "../../../../components/form/errorMsg";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  editFaq,
  faqOptions,
  faqSubmit,
  viewFaqDetail,
} from "../../../../redux/actions/faqAction";
import successToast from "../../../../components/utilits/successToast";
import errorToast from "../../../../components/utilits/errorToast";
import LoadingSpinnerTable from "../../../../components/utilits/loadingSpinnerTable";
import LoadAndError from "../../../../components/utilits/LoadAndError";
import SubmitBtn from "../../../../components/buttons/submit";
import { editRefer, referSubmit, viewReferDetail, viewReferList } from "../../../../redux/actions/referAction";
import { opportunitiesNameListAction } from "../../../../redux/actions/opportunitiesActions";
import { editInvited, invitedReferralOptions, invitedSubmit, viewInvitedDetail } from "../../../../redux/actions/invitedAction";

const InvitedEdit = () => {
  const phoneRegExp = /^[0-9]{10}$/;
  const [submitData, setSubmitData] = useState();
  const formRef = useRef();
  const navigate = useNavigate();
  const params = useParams();
  const dispatch = useDispatch();
  const [faqDetails, setFaqDetails] = useState([]);
  const [notifyMeData, setNotifyMeData] = useState();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  useEffect(() => {
    setLoading(true);
    dispatch(viewInvitedDetail(params.id, onSuccess, onError));
  }, []);

  const onSuccess = (data) => {
    console.log(data.data);
    setFaqDetails(data.data);
    console.log("SUCCESS");
    setLoading(false);
    setError(false);
  };
  const onError = (data) => {
    console.log("ERROR");
    setLoading(false);
    setError(true);
  };

  const initialValues = {
    name: faqDetails.user_name,
    phone: faqDetails.phone_number,
    email: faqDetails.email,
    opportunity: faqDetails.opportunity_id,
  };

  const interestEditSchema = Yup.object().shape({
    name: Yup.string("").required("Required"),
    phone: Yup.string()
      .required("Required")
      .matches(phoneRegExp, "Please Enter Valid Phone Number"),
    email: Yup.string().trim().email().required("Required"),
  });
  const handleSubmit = () => {
    if (formRef.current) {
      formRef.current.handleSubmit();
    }
  };
  const onSubmit = (values, { resetForm }) => {
    console.log(values);
    dispatch(
      editInvited(
        faqDetails.id,
        {
          "user_name": values.name,
          "phone_number": values.phone,
          "email": values.email,
          "opportunity_id": values.opportunity_id
        },
        onEditSuccess,
        onEditError
      )
    );
  };
  const onEditSuccess = (data) => {
    setNotifyMeData(data.data);
    setSubmitData(data.data.id);
    setSaveHide(true);
    successToast(data.message);
  };
  const onEditError = (data) => {
    console.log(data);
    errorToast(data.message);
  };



  //on submit api
  console.log(submitData);
  const [saveHide, setSaveHide] = useState(false);

  const submitFnc = () => {
    dispatch(invitedSubmit(submitData, onSubmitSuccess, onSubmitError));
  };
  const onSubmitSuccess = (data) => {
    console.log(data);
    successToast(data.message);
    navigate("/static-pages/invited");
  };
  const onSubmitError = (data) => {
    console.log("ERROR==================", data);
    errorToast(data.message);
  };


  //opp id
  const [opportunitiesList, setOpportunitiesList] = useState([])
  useEffect(() => {
    dispatch(invitedReferralOptions(onSuccessOpt, onErrorOpt));
  }, []);
  const onSuccessOpt = (data) => {
    const categoryList = [];
    data?.data?.AllOpportunities?.map((item, index) => {
      categoryList[index] = { value: item.id, label: `${item.title} id: ${item.id}` };
    });
    setOpportunitiesList(categoryList);
    console.log("SUCCESS");
  };
  const onErrorOpt = (data) => {
    console.log(data);
  };

  const buttonList = [
    !saveHide && <SaveBtn submitFn={handleSubmit} loader={loading} />,
    <SubmitBtn submitFn={submitFnc} saveHide={saveHide} />,
  ];

  return (
    <InnerLayout buttons={buttonList}>
      <div className="user_view_container">
        <InnerContainer>
          {loading ? (
            <LoadingSpinnerTable />
          ) : (
            <LoadAndError loader={loading} error={error}>
              <div className="row mt-2">
                <div className="col-12 col-sm-12 col-md-9 col-lg-9 col-xl-7">
                  <div className="row ps-1 ms-1 ps-lg-4 ms-lg-1">
                    <div className="ps-2  pt-3">
                      <h5 className="dark_grey_color text-nowrap fs_13 fn_Montserrat fw_500">
                        Refer ID
                      </h5>
                      <p
                        className={` fs_13 fn_Nunito text-nowrap fw_500 secondary_color`}
                      >
                        {params.id}
                      </p>

                      <Formik
                        innerRef={formRef}
                        initialValues={initialValues}
                        onSubmit={onSubmit}
                        enableReinitialize={true}
                        validationSchema={interestEditSchema}
                      >
                        {({ formik, handleChange, values, setFieldValue }) => {
                          return (
                            <Form className="">
                              <div className="   express-form-width">
                                <InputField
                                  id={"name"}
                                  label={"Name"}
                                  type={"text"}
                                  placeholder={"Enter Name"}
                                  name={"name"}
                                />
                                <InputField
                                  id={"phone"}
                                  label={"Phone Number"}
                                  type={"text"}
                                  placeholder={"Enter Phone Number"}
                                  name={"phone"}
                                />
                                <InputField
                                  id={"email"}
                                  label={"E-Mail"}
                                  type={"text"}
                                  placeholder={"Enter Email ID"}
                                  name={"email"}
                                />
                                <SelectFields
                                  default1={{
                                    label: faqDetails?.opportunity_id
                                    , value: faqDetails?.opportunity_id
                                  }}
                                  label={"Opportunity Name (Optional)"}
                                  placeholder={"Select Opportunity"}
                                  name={"opportunity"}
                                  options={opportunitiesList}
                                  id={"opportunity"}
                                />
                              </div>
                            </Form>
                          );
                        }}
                      </Formik>
                    </div>
                  </div>
                </div>
              </div>
            </LoadAndError>
          )}
        </InnerContainer>
      </div>
    </InnerLayout>
  );
};

export default InvitedEdit;
export const faqName = [
  { value: "Type1", label: "Type1" },
  { value: "Type2", label: "Type2" },
  { value: "Type3", label: "Type3" },
  { value: "Type4", label: "Type4" },
];
