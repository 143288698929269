import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import React, { useEffect, useRef, useState } from "react";
import "../our-partners.css"
import InnerLayout from "../../../../components/layouts/innerLayout/innerLayout";
import InnerContainer from "../../../../components/layouts/innerContainer/innerContainer";
import ArrowRightIcon from "../../../../assets/icons/arrowrightIcon"
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import moment from "moment"
import { viewParnterVersionAction } from "../../../../redux/actions/ourParnterActions";
import errorToast from "../../../../components/utilits/errorToast";
import LoadingSpinnerTable from "../../../../components/utilits/loadingSpinnerTable";
import LoadAndError from "../../../../components/utilits/LoadAndError";

const OurPartnerVersionHistoryView = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false)
  const [partnerVersionData, setPartnerVersionData] = useState([])

  useEffect(() => {
    setLoading(true);
    dispatch(viewParnterVersionAction(params?.id, onSuccess, onError));
  }, []);

  const onSuccess = (data) => {
    console.log(data);
    setPartnerVersionData(data?.data?.data)
    setLoading(false);
    setError(false)
  };

  const onError = (data) => {
    errorToast(data.message)
    console.log(data);
    setLoading(false);
    setError(true)
  };
  console.log(partnerVersionData);

  const data = [
    {
      heading: "Parnters Id",
      title: partnerVersionData?.id === null ? "--" : partnerVersionData?.id,
    },
    {
      heading: "Created At",
      title: partnerVersionData?.createdAt == null ? "--" : moment(partnerVersionData?.createdAt).format("DD/MM/YY HH:mm:ss"),
    },
    {
      heading: "Updated At",
      title: partnerVersionData?.updatedAt === null ? "--" : moment(partnerVersionData?.updatedAt).format("DD/MM/YY HH:mm:ss"),
    },
    {
      heading: "Updated By",
      title: partnerVersionData?.updated_by === null ? "--" : partnerVersionData?.updated_by,
    },

  ];


  const newData = [

    {
      heading: "Name",
      title: partnerVersionData?.new_data?.name,

    },
   
    {
      heading: "Description",
      title: partnerVersionData?.new_data?.description === null ? "-" : partnerVersionData?.new_data?.description,

    },
    {
      heading: "Designation",
      title: partnerVersionData?.new_data?.designation === null ? "-" : partnerVersionData?.new_data?.designation,

    },
    {
      heading: "Linkedin Url",
      title: partnerVersionData?.new_data?.linkedin_url === null ? "-" : partnerVersionData?.new_data?.linkedin_url,

    },

  
  ];

  const oldData = [

    {
      heading: "Name",
      title: partnerVersionData?.old_data?.name,

    },
  
    {
      heading: "Description",
      title: partnerVersionData?.old_data?.description === null ? "-" : partnerVersionData?.old_data?.description,

    },
    {
      heading: "Designation",
      title: partnerVersionData?.old_data?.designation === null ? "-" : partnerVersionData?.old_data?.designation,

    },
    {
      heading: "Linkedin Url",
      title: partnerVersionData?.old_data?.linkedin_url === null ? "-" : partnerVersionData?.old_data?.linkedin_url,

    },
 

  ];


  return (
    <>
      <InnerLayout
      >
        <div className="admin_user_container">
          <InnerContainer>
            {loading ? (
              <LoadingSpinnerTable />
            ) : (
              <LoadAndError loader={loading} error={error}>
                <div className="pt-3 ps-5 row">
                  {data.map((item, index) => (
                    <div className="mb-2  col">
                      <h5 className="dark_grey_color text-nowrap fs_13 fn_Montserrat fw_500 m-0">
                        {item.heading}
                      </h5>
                      <p
                        className={` fs_13 fn_Nunito text-nowrap fw_500 ${item.heading === "Admin ID" || item.heading === "E-Mail"
                          ? "secondary_color"
                          : "dark_title_color"
                          }`}
                      >
                        {item.title}
                      </p>
                    </div>
                  ))}
                </div>
                <div className="white_shade_bg  py-1 d-flex">
                  <div className="w-50">
                    <h5 className="fw_500 ps-4 dark_grey_color pt-2 text-nowrap fs_16 fn_Montserrat">
                      JSON Old Data
                    </h5>
                  </div>
                  <div className="w-50  d-none d-lg-block">
                    <h5 className="fw_500 ps-4  dark_grey_color pt-2 text-nowrap fs_16 fn_Montserrat">
                      JSON New Data
                    </h5>
                  </div>
                </div>

                <div className="row mt-3 ps-0 ps-lg-4">
                  <div className="col-12 col-lg-6">
                    <div className="admin_aprvls_vrsn_hstry_data_container  mt-3 pt-3 px-3">
                      <div className="d-flex justify-content-between">
                        <h5 className="fs_13 fn_Nunito fw_400 dark_title_color text-nowrap">
                          Old Data
                        </h5>
                        <ArrowRightIcon width={25} height={25} fill={"#A5A5A5"} />
                      </div>
                      {
                        partnerVersionData?.old_data === null ? (<>
                          <div>Empty Data</div>
                        </>) : (
                          <>
                            {
                              oldData?.map((item, index) => {
                                return (
                                  <>
                                    <h5 className="dark_grey_color text-nowrap fs_13 fn_Montserrat fw_500 m-0">
                                      {item.heading}
                                    </h5>
                                    <p
                                      className={` fs_13 fn_Nunito text-nowrap fw_500 ${item.heading === "Admin ID" || item.heading === "E-Mail"
                                        ? "secondary_color"
                                        : "dark_title_color"
                                        }`}
                                    >
                                      {item.title}
                                    </p>
                                  </>
                                )

                              })
                            }
                          </>
                        )
                      }


                    </div>
                  </div>
                  <div className="col-12 mt-5 mt-lg-0 col-lg-6">
                    <h5 className="fw_500 ps-4 d-block d-lg-none white_shade_bg  dark_grey_color py-3 text-nowrap fs_16 fn_Montserrat">
                      JSON New Data
                    </h5>
                    <div className="admin_aprvls_vrsn_hstry_data_container mt-4 mt-lg-3 pt-3 px-3">
                      <div className="d-flex justify-content-between">
                        <h5 className="fs_13 fn_Nunito fw_400 dark_title_color py-2 text-nowrap">
                          New Data
                        </h5>
                        <ArrowRightIcon width={25} height={25} fill={"#A5A5A5"} />
                      </div>
                      {
                        partnerVersionData?.new_data === null ? (<>
                          <div>Empty Data</div>
                        </>) : (
                          <>
                            {
                              newData.map((item, index) => {
                                return (
                                  <>
                                    <h5 className="dark_grey_color text-nowrap fs_13 fn_Montserrat fw_500 m-0">
                                      {item.heading}
                                    </h5>
                                    <p
                                      className={` fs_13 fn_Nunito text-nowrap fw_500 ${item.heading === "Admin ID" || item.heading === "E-Mail"
                                        ? "secondary_color"
                                        : "dark_title_color"
                                        }`}
                                    >
                                      {item.title}
                                    </p>
                                  </>
                                )

                              })
                            }</>)}

                    </div>
                  </div>
                </div>
              </LoadAndError>
            )}
          </InnerContainer>
        </div>
      </InnerLayout>
    </>
  );
}

export default OurPartnerVersionHistoryView