import { call, put, takeLatest } from "redux-saga/effects";
import {
  ADMIN_LOGIN_FAIL,
  ADMIN_LOGIN_LOADING,
  ADMIN_LOGIN_SUCCESS,
} from "../actions/returnTypes";
import { ADMIN_LOGIN, ADMIN_LOGOUT } from "../actions/types";
import { adminLoginApi } from "../apis/loginApis";
import { ADMIN_DETAILS } from "../config";
export function* loginWorker(action) {
  try {
    yield put({ type: ADMIN_LOGIN_LOADING });
    const res = yield call(adminLoginApi, {
      email: action.email,
      password: action.password,
    });
    if (res.status === 200 && res.data.status === "success") {
      localStorage.setItem("accessToken", res.data.data.access_token);
      localStorage.setItem(
        "role_name",
        res.data.data.Role_Permissions.role_name
      );
      localStorage.setItem(
        "permissions",
        JSON.stringify(res.data.data.Role_Permissions.permissions)
      );
      localStorage.setItem("email", res.data.data.email);
      yield put({
        type: ADMIN_LOGIN_SUCCESS,
        token: res.data.data.access_token,
        message: res.data.message,
      });
      yield action.onSuccess(res.data.message);
    } else {
      yield put({
        type: ADMIN_LOGIN_FAIL,
        message: res.data.message,
      });
      yield action.onError(res.data);
    }
  } catch (error) {}
}

export function* logoutWorker() {
  try {
    yield localStorage.clear();
    yield window.location.reload();
    ADMIN_DETAILS.accessToken = "";
    ADMIN_DETAILS.email = "";
  } catch (err) {
    console.log(err);
  }
}

export function* watchLoginWorker() {
  yield takeLatest(ADMIN_LOGIN, loginWorker);
}

export function* watchLogoutWorker() {
  yield takeLatest(ADMIN_LOGOUT, logoutWorker);
}
