import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import DeleteModal from "../../../modals/deleteModal";
import { TableContainer } from "../../../table/tableContainer";
import { tableContactUsHeader, tableNewsLetterHeader, } from "../../../table/tableData";
import TablePagination from "../../../table/tablePagination";
import LoadingSpinnerTable from "../../../utilits/loadingSpinnerTable";
import "../../../admin/tables/admintable.css";
import * as adminRolesActions from "../../../../redux/actions/adminRoleActions";
import successToast from "../../../utilits/successToast";
import errorToast from "../../../utilits/errorToast";
import { access } from "../../../utilits/access";
import { deleteNewsletterAction, viewNewsletterListAction } from "../../../../redux/actions/newsletterActions";
import moment from "moment";
import usePermissionChecker from "../../../utilits/usePermissionChecker";
import LoadAndError from "../../../utilits/LoadAndError";

const NewsLetterTableContent = ({ page, search, changePage }) => {
    const dispatch = useDispatch();
    const access = usePermissionChecker("maker");

    const handleShow = () => setShow(true);
    const handleClose = () => setShow(false);
    const [newsData, setNewsData] = useState([]);
    const [deleteData, setDeleteData] = useState({ name: "", id: "" });
    const [show, setShow] = useState(false);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [loadingTable, setLoadingTable] = useState(false);
    const [pageData, setPageData] = useState({ noOfItems: 0, noOfPages: 1 })
    console.log(search);
    useEffect(() => {
        setLoadingTable(true);
        dispatch(
            viewNewsletterListAction(
                {
                    search: {
                        news_letter_id: search?.value?.newsLtrId,
                        email: search?.value?.email,
                        status: search?.value?.statuss
                    }
                },
                page,
                onSuccess,
                onError)
        );
    }, [search, page, loading]);
    const onSuccess = (data) => {
        setNewsData(data.data.data);
        setPageData({
            noOfItems: data.data.count,
            noOfPages: data.data.pages
        })
        setLoadingTable(false);
        setError(false)
        setLoading(false)
    };
    const onError = (data) => {
        errorToast(data.message);
        setLoadingTable(false);
        setError(true)
        setLoading(false)
    };

    const handleDelete = () => {
        setLoading(true);
        dispatch(
            deleteNewsletterAction(
                deleteData.id,
                onDeleteSuccess,
                onDeleteError
            )
        );
    };
    const onDeleteSuccess = (data) => {
        console.log(data);
        successToast(data.message)
        setLoading(false)
        setShow(false)
        setError(false)
        setLoading(false)
    };
    const onDeleteError = (data) => {
        errorToast(data.message);
        setLoading(false);
        setError(true)
        setLoading(false)
    };


    function handlePagination(type) {
        if (type === "+") {
            if (page + 1 < pageData.noOfPages) changePage("+");
        }
        else if (type === "-")
            if (page > 0) changePage("-");
    }

    return (
        <TableContainer maxWidth="100%" width="90%">
            <div className="adminuser_table__container ">
                {loadingTable && <LoadingSpinnerTable />}
                <table className={"adminuser_table"}>
                    <thead className="adminuser_table__header">
                        <tr className="adminuser_table__row">
                            {tableNewsLetterHeader.map((item) => {
                                return (
                                    <th
                                        key={item.id}
                                        className={`${item === "Status" ? `${access ? "" : "text-start"} position-sticky end-0` : null
                                            } text-nowrap adminuser_table__heading `}
                                    >
                                        {item}
                                    </th>
                                );
                            })}
                        </tr>
                    </thead>
                    {loadingTable === false && (
                        <tbody className="adminuser_table__body">
                            <LoadAndError loader={loading} error={error} status={newsData.length === 0}>
                                {newsData.map((item, index) => {
                                    return (
                                        <tr key={item.id} className="adminuser_table__row">
                                            <td className="text-nowrap dark_title_color adminuser_table__data ">
                                                {page > 0 ? 12 * page + (index + 1) : index + 1}
                                            </td>
                                            <td className="text-nowrap dark_title_color adminuser_table__data">
                                                {item.id}
                                            </td>
                                            <td className="text-nowrap secondary_color adminuser_table__data">
                                                {item?.email}
                                            </td>
                                            {/* <td className="text-nowrap   secondary_color adminuser_table__data adminrole_table_empty__data">
                                                {item?.status}
                                            </td> */}
                                            <td className="text-nowrap   secondary_color adminuser_table__data adminrole_table_empty__data">
                                                {moment(item?.createdAt == null ? "--" : item?.createdAt).format("DD-MM-YYYY HH:mm:ss")}
                                            </td>
                                            <td className="text-nowrap   secondary_color adminuser_table__data adminrole_table_empty__data">
                                                {moment(item?.updatedAt == null ? "--" : item?.updatedAt).format("DD-MM-YYYY HH:mm:ss")}
                                            </td>

                                            <td className="text-nowrap  position-sticky end-0 adminuser_table__data">
                                                <div className="d-flex w-100 align-items-center justify-content-start   gap-2">
                                                    <Link
                                                        className="text-decoration-none primary_color"
                                                        to={
                                                            { pathname: `/static-pages/newsletter/view/${item.id}` }
                                                        }
                                                        state={item}
                                                    >
                                                        View
                                                    </Link>{" "}
                                                    {
                                                        access && <>
                                                            {
                                                                item.editable && <>
                                                                    <span className="vr"></span>{" "}
                                                                    <Link
                                                                        className="text-decoration-none secondary_color"
                                                                        to={
                                                                            { pathname: `/static-pages/newsletter/edit/${item.id}` }
                                                                        }
                                                                        state={item}
                                                                    >
                                                                        Edit
                                                                    </Link>{" "}
                                                                    {item.lastActionApproved && (
                                                                        <>
                                                                            <span className="vr"></span>{" "}
                                                                            <button
                                                                                onClick={() => { setDeleteData({ name: item?.email, id: item.id }); handleShow() }}
                                                                                className="text-decoration-none primary_color light_red_color border-0 bg-transparent "
                                                                            >
                                                                                Delete
                                                                            </button>
                                                                        </>)}
                                                                </>
                                                            }

                                                        </>
                                                    }
                                                </div>
                                            </td>
                                        </tr>
                                    );
                                })}
                            </LoadAndError>
                        </tbody>)}
                </table>
            </div>
            <DeleteModal
                loader={loading}
                handleClose={handleClose}
                deleteFn={handleDelete}
                data={deleteData}
                show={show}
            />
            <TablePagination
                paginate={handlePagination}
                currentPage={page}
                pageData={pageData}
            />
        </TableContainer>

    )
}
export default NewsLetterTableContent