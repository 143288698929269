

import React from "react";

const ViewIcon = ({ fill = "#bcc7ce", width = 10, height = 16 }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 19.008 12.96">
      <path id="Icon_material-remove-red-eye" data-name="Icon material-remove-red-eye" d="M11,6.75a10.218,10.218,0,0,0-9.5,6.48,10.21,10.21,0,0,0,19.008,0A10.218,10.218,0,0,0,11,6.75Zm0,10.8a4.32,4.32,0,1,1,4.32-4.32A4.322,4.322,0,0,1,11,17.55Zm0-6.912A2.592,2.592,0,1,0,13.6,13.23,2.589,2.589,0,0,0,11,10.638Z" transform="translate(-1.5 -6.75)" fill={fill} />
    </svg>

  );
};

export default ViewIcon;

