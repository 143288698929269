import React from "react";
// import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import upcideLogo from "../../../../assets/images/opportunitesViewAdminCarousel.jpg";
import BackButton from "../../../../assets/icons/opportunitiesArrowLeft.svg";
import NextButton from "../../../../assets/icons/opportunities/carouselRightArrow.svg";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import styles from "./opportunitiesread.module.css";
import dummyImage from "../../../../assets/images/dummy-post-horisontal.jpg";
// import Image from "next/image";
// import BackBtn from "../../assets/icons/opportunitiesicons/previous-icon.svg";
// import NextBtn from "../../assets/icons/opportunitiesicons/next-icon.svg";
// import styles from "../../styles/opportunities/OpportunitiesDetail.module.css";
// import dummyImage from "../../assets/images/dummy_image.jpg";

// import useScreenSizeDetector from "../useScreenSizeDetector";

const Slider = ({ data = [], operationType }) => {
  //   const width = useScreenSizeDetector();
  return (
    <>
      <div className="d-flex justify-content-between mx-5">
        <p className=" fw_500">
          Opportunity Type: {data.opportunity_type_name}
        </p>
        <p className=" fw_500">Operation Type: {operationType}</p>
      </div>
      <div className=" d-flex justify-content-center w-100">
        <div
          className={`${styles.carousel_container} w-100 position-relative mx-md-5 `}
        >
          <div
            className={` position-absolute  ${styles.closed_text} py-lg-2 px-lg-5   py-1 px-3`}
          >
            <span>{data?.status}</span>
          </div>
          {/* <span className="textabcd">ncjdusijn</span> */}
          <Carousel
            className="w-100"
            showThumbs={false}
            onClickItem={(e) => console.log(e)}
            showStatus={false}
            showArrows={true}
            showIndicators={false}
            renderArrowPrev={(onClickHandler, isSelected, index, label) => {
              return (
                <span
                  onClick={onClickHandler}
                  onKeyDown={onClickHandler}
                  value={index}
                  key={index}
                  role="button"
                  tabIndex={0}
                  aria-label={`${label} ${index + 1}`}
                  className={`${styles.previous_icon_container}`}
                >
                  <span className={`${styles.previous} position-relative `}>
                    <span className="previous_icon">
                      <img width={50} height={50} src={BackButton} />
                    </span>
                  </span>
                </span>
              );
            }}
            renderArrowNext={(onClickHandler, isSelected, index, label) => {
              return (
                <span
                  onClick={onClickHandler}
                  onKeyDown={onClickHandler}
                  value={index}
                  key={index}
                  role="button"
                  tabIndex={0}
                  aria-label={`${label} ${index + 1}`}
                  className={`${styles.next_icon_container}`}
                >
                  <span className={`position-relative ${styles.next}`}>
                    <span className={`${styles.next_icon}`}>
                      <img width={50} height={50} src={NextButton} />
                    </span>
                  </span>
                </span>
              );
            }}
          >
            {/* return ( */}

            {data?.assets?.map((item) => {
              return (
                <div className="carousel_image_container">
                  <img
                    className={`${styles.carousel_image}`}
                    src={item.location ? item.location : ""}
                    //   src={item.locatuion
                    //     ? item.locatuion : item.location ? item.location : dummyImage}
                    //   alt="../../../../assets/images/Upcidelogo.svg"
                    //   width="1200px"
                    //   height={width < 992 ? "230px" : "500px"}
                    object-fit="cover"
                  />
                </div>
              );
            })}
            {/* ); */}
            {/* 
          <div className="carousel_image_container">
            <img
              className={`${styles.carousel_image}`}
              src={upcideLogo}
              //   src={item.locatuion
              //     ? item.locatuion : item.location ? item.location : dummyImage}
              alt="Slide images"
            //   width="1200px"
            //   height={width < 992 ? "230px" : "500px"}
            /><span className="textabcd">image2</span>
          </div>
          <div className="carousel_image_container">
            <img
              className={`${styles.carousel_image}`}
              src={upcideLogo}
              //   src={item.locatuion
              //     ? item.locatuion : item.location ? item.location : dummyImage}
              alt="Slide images"
            //   width="1200px"
            //   height={width < 992 ? "230px" : "500px"}
            />image3
          </div> */}
          </Carousel>
        </div>
        {/* <Carousel>
                <div>
                    <img src="../../../../assets/images/Upcidelogo.svg" />
                    <p className="legend">Legend 1</p>
                </div>
                <div>
                    <img src="../../../../assets/images/Upcidelogo.svg" />
                    <p className="legend">Legend 2</p>
                </div>
                <div>
                    <img src="../../../../assets/images/Upcidelogo.svg" />
                    <p className="legend">Legend 3</p>
                </div>
            </Carousel> */}
      </div>
    </>
  );
};

export default Slider;
