import React, { useState, useEffect, useRef } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import OpportunitiesAddSubBarSection from "./OpportunitiesAddSubBarSection";
import "./opportunitiesEditContent.css";

import { useDispatch, useSelector } from "react-redux";
import * as opportunityEditorActions from "../../../../redux/actions/opportunityEditorActions";
import * as defaultSectionValues from "../../../../redux/constants/opportunityJsonState";
import errorToast from "../../../utilits/errorToast";
import { uploadAddressProofFrontImageAction } from "../../../../redux/actions/userAction";

const OpportunitiesContectEditText = ({
  id,
  deleteTheTextSection,
  mainData,
  sectionIndex,
  subSectionIndex,
  index,
}) => {
  const quillRef = useRef();

  const [value, setValue] = useState("");

  const dispatch = useDispatch();
  const opportunityJsonReducer = useSelector(
    (state) => state.opportunityJsonReducer
  );

  const [data, setData] = useState(
    opportunityJsonReducer.data[sectionIndex]?.subsections[subSectionIndex]?.content[index]
  );

  useEffect(() => {
    var newData =
      opportunityJsonReducer.data[sectionIndex]?.subsections[subSectionIndex]?.content[index];
    setData(newData);
    setValue(newData.value);
  }, []);

  useEffect(() => {
    updateReducer();
  }, [data]);

  useEffect(() => {
    console.log(
      "OpportunitiesSubSection opportunityJsonReducer",
      opportunityJsonReducer
    );
  }, [opportunityJsonReducer]);

  const updateReducer = () => {
    // stateDate.data.`
    dispatch(
      opportunityEditorActions.updateContentSection(
        index,
        subSectionIndex,
        sectionIndex,
        data
      )
    );
  };
  const onValueUpdate = (text) => {
    setValue(text);
    setData({
      ...data,
      value: text,
    });
  };

  const handleChange = (content, delta, source, editor) => {
    setValue(content);
    onValueUpdate(content);
    console.log("OpportunitiesContectEditText handleChange", content, editor.getContents());
  }


  const imageHandler = () => {
    const editor = quillRef.current.getEditor();
    const input = document.createElement("input");
    input.setAttribute("type", "file");
    input.setAttribute("accept", "image/*");
    input.click();
    input.onchange = async () => {
      const file = input.files[0];
      if (/^image\//.test(file.type)) {
        const range = editor.getSelection();
        dispatch(uploadAddressProofFrontImageAction(file, (data) => {
          const url = data.data.data.location
          editor.insertEmbed(range.index, "image", url);
        }, (data) => {
          console.log(data);
          errorToast("falied to upload image")
        }))
      } else {
        console.log("upload image");
        alert("upload image");
      }
    };
  };

  const [settings] = useState({
    toolbar: {
      container: [
        ["bold", "italic", "underline", "strike"], // toggled buttons
        ["blockquote", "code-block"],
        [{ header: 1 }, { header: 2 }], // custom button values
        ["image"],
        [{ list: "ordered" }, { list: "bullet" }],
        [{ script: "sub" }, { script: "super" }], // superscript/subscript
        [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
        [{ direction: "rtl" }], // text direction
        [{ size: ["small", "large", "huge"] }], // custom dropdown
        [{ header: [1, 2, 3, 4, 5, 6, false] }],
        [{ color: [] }, { background: [] }], // dropdown with defaults from theme
        [{ font: [] }],
        [{ align: [] }],
        [{ table: true }],

        ["clean"], // remove formatting button
      ],
      handlers: {
        image: imageHandler,
      },
    },
  });

  return (
    <>
      <div key={data.id} className="dark_grey_color mt-4 mb-1 pt-1">
        <div className="w-100 d-flex justify-content-between mt-3">
          <span></span>
          <button type="button"
            onClick={() => {
              deleteTheTextSection(index);
            }}
            className="light_red_color fs_13 fw_500 border-0 white_shade_bg"
          >
            Delete
          </button>
        </div>
        <div className=" rounded-3 pt-0">
          <div className="oppor_content_edit__border  rounded-2 pt-3 mb-0">
            <ReactQuill
              ref={quillRef}
              modules={settings}
              onChange={handleChange} value={value} theme="snow" />
          </div>
        </div>
      </div>
    </>
  );
};

export default OpportunitiesContectEditText;
