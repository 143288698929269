import React, { useRef } from "react";
import Modal from "react-bootstrap/Modal";
import CloseIcon from "../../../../assets/icons/closeIcon";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import "./opportunitiesmodals.css";
import InputField from "../../../form/inputFields/inputField";
import ErrorMsg from "../../../form/errorMsg";
import ReactQuill from "react-quill";
import { useState } from "react";

const Faq = ({ show, handleClose, formikValues, id, reasonUpdator }) => {
  const initialValues = {
    question: id === 0 || id ? formikValues[id]?.question : "",
    answer: id === 0 || id ? formikValues[id]?.answer : "",
  };
  // const loginSchema = Yup.object().shape({
  //   // question: Yup.string().trim(),
  //   // answer: Yup.string().trim(),
  // });

  const onSubmit = (values, { resetForm }) => {
    resetForm();
    handleClose();
    reasonUpdator(id, values);
  };

  // const [settings, setSettings] = useState({
  //   modules: {
  //     toolbar: [
  //       ["bold", "italic", "underline", "strike"], // toggled buttons
  //       ["blockquote", "code-block"],
  //       [{ header: 1 }, { header: 2 }], // custom button values
  //       [{ list: "ordered" }, { list: "bullet" }],
  //       [{ script: "sub" }, { script: "super" }], // superscript/subscript
  //       [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
  //       [{ direction: "rtl" }], // text direction
  //       [{ size: ["small", "large", "huge"] }], // custom dropdown
  //       [{ header: [1, 2, 3, 4, 5, 6, false] }],
  //       [{ color: [] }, { background: [] }], // dropdown with defaults from theme
  //       [{ font: [] }],
  //       [{ align: [] }],

  //       ["clean"], // remove formatting button
  //     ],
  //   },
  // });


  return (
    <Modal
      centered
      show={show}
      onHide={handleClose}
      dialogClassName={`oppoptunities_modals_body mx-auto `}
      contentClassName={"oppoptunities_modals_body_content  p-2"}
    >
      <Modal.Body>
        <>
          <div className="d-flex justify-content-between  ">
            <h2 className="green_color fs_15 fn_Montserrat fw_600 pt-1">FAQ</h2>
            <button
              type="button"
              onClick={handleClose}
              className="light_grey_color  fs_15 fn_Montserrat bg-transparent border-0 fw_600 "
            >
              <span className="pt-1">DISMISS</span>
              <span className="ms-2">
                <CloseIcon width={13} height={13} fill="#A5A5A5" />
              </span>
            </button>
          </div>
          <Formik
            // innerRef={formRef}
            initialValues={initialValues}
            onSubmit={onSubmit}
            // validationSchema={loginSchema}
          >
            {({ values, setFieldTouched, setFieldValue, touched, errors }) => (
              <Form className="mt-3">
                <InputField
                  id={"question"}
                  label={"Question"}
                  type={"text"}
                  placeholder={"Enter question"}
                  name={"question"}
                />
                <label
                  className="mb-2 dark_grey_color fn_Montserrat mt-2 fw_500 fs_14 ps-2"
                >
                  Answer
                </label>
                <div
                  style={{ height: "300px" }}
                  className="oppor_content_edit__border overflow-auto rounded-2 pt-3 mb-0"
                >
                  <ReactQuill
                    theme={"snow"}
                    name={"answer"}
                    id={"answer"}
                    value={values.answer}
                    onChange={(event) => setFieldValue("answer", event)}
                    onBlur={() => setFieldTouched("answer", true)}
                    valid={!errors.answer}
                    invalid={touched.answer && !!errors.answer}
                    autoFocus={true}
                  />
                </div>

                <div className="d-flex justify-content-end mt-0 pt-4 pb-5 position-relative">
                  <button
                    type="submit"
                    className="secondary_color position-absolute fs_15  fn_Montserrat fw_600 border-0 tertiary_bg px-4 px-lg-5 py-2 py-lg-2 rounded-2"
                  >
                    {id === null ? "CREATE" : "UPDATE"}
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </>
      </Modal.Body>
    </Modal>
  );
};
export default Faq;
export const selectPriority = [
  { value: "Priority1", label: "Priority1" },
  { value: "Priority2", label: "Priority2" },
  { value: "Priority2", label: "Priority3" },
  { value: "Priority4", label: "Priority4" },
];
