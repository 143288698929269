import React, { useState } from 'react'
import AdminUserVersionTableContent from '../../../../components/admin/tables/adminUserVersionTableContent';
import ExportBtn from '../../../../components/buttons/exportBtn';
import FilterBtn from '../../../../components/buttons/filterBtn';
import AdminUserFilter from '../../../../components/filter/adminUserFilter';
import InnerLayout from '../../../../components/layouts/innerLayout/innerLayout';
import { exportAdmin } from '../../../../redux/actions/AdminUserAction';
import { useDispatch } from "react-redux";
import FileSaver from 'file-saver';
import MediaRecogonitionVersionTableContent from '../../../../components/static-pages/media-recogonitions/tables/mediaRecogonitionsVersionTableContent';
import VersionTablesFilter from '../../../../components/filter/versionTableFilter';
import { mediaRecVersionExportAction } from '../../../../redux/actions/mediaRecogonitionActions';

const MediaRecogonitionVersionTable = () => {
  const [page, setPage] = useState(0);
  const [showFilter, setShowFilter] = useState(false);
  const [search, setSearch] = useState({ value: "" });
  const dispatch = useDispatch();
  const handleFilterClose = () => {
    setShowFilter(false);
  };
  const handleFilterOpen = () => {
    setShowFilter(true);
  };
  const handleSearch = (value) => {
    setSearch({ value: value });
    setPage(0)
  };
  const handlePage = (type) => {
    if (type === "+") {
      setPage((prev) => prev + 1)
    }
    else {
      setPage((prev) => prev - 1)
    }
  }
  const exportFn = () => {
    dispatch(exportAdmin(
      onSuccess, onError
    ));
  }
  const onSuccess = (data) => {
    console.log("SUCCESS =====================================", data);
  };


  const onError = (data) => {
    console.log("ERROR==================", data);
    var type = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8";
    var blob = new Blob([data], { type: type });
    FileSaver.saveAs(blob, "file.xlsx");
  };
  const buttonList = [
    <ExportBtn dispatchAction={mediaRecVersionExportAction} />,
    <FilterBtn filterFn={handleFilterOpen} />,
  ];

  return (
    <InnerLayout containerWidth="90%" buttons={buttonList}>
      <VersionTablesFilter
        title='Media And Recognition Id'
        placeholder='Enter Media And Recognition Id'
        filter={handleSearch}
        showFilter={showFilter}
        closeFilter={handleFilterClose}
      />
      <MediaRecogonitionVersionTableContent page={page} changePage={handlePage} search={search} />
    </InnerLayout>
  );
}

export default MediaRecogonitionVersionTable