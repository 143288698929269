import {
  OPPORTUNITES_UPDATE_JSON,
  OPPORTUNITES_CLEAR_JSON,
  ADD_SECTION,
  OPPORTUNITES_UPDATE_SUB_SECTION,
  DELETE_SECTION,
  OPPORTUNITES_UPDATE_PARENT_SECTION,
  OPPORTUNITES_UPDATE_CONTENT_SECTION,
} from "./types";

export const addSection = () => {
  return {
    type: ADD_SECTION,
  };
};
export const deleteSection = (index) => {
  return {
    type: DELETE_SECTION,
    index: index,
  };
};
export const updateOppJson = (newJson) => {
  return {
    type: OPPORTUNITES_UPDATE_JSON,
    newJson: newJson,
  };
};

export const updateParentSection = (index, data) => {
  return {
    type: OPPORTUNITES_UPDATE_PARENT_SECTION,
    index: index,
    data: data,
  };
};

export const updateSubSection = (index, parentIndex, data) => {
  return {
    type: OPPORTUNITES_UPDATE_SUB_SECTION,
    parentIndex: parentIndex,
    index: index,
    data: data,
  };
};

export const updateContentSection = (index, subSectionIndex, sectionIndex, data) => {
  return {
    type: OPPORTUNITES_UPDATE_CONTENT_SECTION,
    sectionIndex: sectionIndex,
    subSectionIndex: subSectionIndex,
    index: index,
    data: data,
  };
};

export const clearOppJson = () => {
  return {
    type: OPPORTUNITES_CLEAR_JSON,
  };
};
