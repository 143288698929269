import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { ADMIN_DETAILS } from "../redux/config";
const ProtectedRoute = ({
  component: Component,
  restricted,
  auth,
  ...rest
}) => {
  return !ADMIN_DETAILS.accessToken ? <Outlet /> : <Navigate to="/" />;
};

export default ProtectedRoute;
