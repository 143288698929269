import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
// import { versionHistoryAdminList } from "../../../redux/actions/AdminUserAction";
import moment from "moment";
import "./testimonials.css";
import { TableContainer } from "../../../table/tableContainer";
import { testimonialsData, testimonialsHeader } from "../../../table/tableData";
import TablePagination from "../../../table/tablePagination";
import LoadingSpinnerTable from "../../../utilits/loadingSpinnerTable";
import {
  deleteTestimonials,
  viewTestimonialsList,
} from "../../../../redux/actions/testimonialsAction";
import successToast from "../../../utilits/successToast";
import errorToast from "../../../utilits/errorToast";
import DeleteModal from "../../../modals/deleteModal";
import usePermissionChecker from "../../../utilits/usePermissionChecker";

const TestimonialsTableContent = ({ page, search, changePage }) => {
  const access = usePermissionChecker("maker");
  const dispatch = useDispatch();
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);
  const [show, setShow] = useState(false);
  const [versionHistoryList, setVersionHistoryList] = useState([]);
  const [loadingTable, setLoadingTable] = useState(false);
  const [loading, setLoading] = useState(false);
  const [deleteData, setDeleteData] = useState({ name: "", id: "" });
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [pageData, setPageData] = useState({ noOfItems: 0, noOfPages: 1 })


  useEffect(() => {
    setLoadingTable(true);
    dispatch(
      viewTestimonialsList(
        {
          search: {
            testimonial_id: search?.value?.testimonialId,
            name: search?.value?.name,
            designation: search?.value?.designation
          }
        },
        page,
        onSuccess,
        onError
      ));
  }, [search, page]);

  const onSuccess = (data) => {
    console.log("dtaattat", data.data?.data);
    setVersionHistoryList(data?.data);
    setPageData({
      noOfItems: data.count,
      noOfPages: data.pages
    })
    setLoadingTable(false);
  };

  console.log(versionHistoryList);
  const onError = (data) => {
    console.log(data);
    setLoadingTable(false);
  };
  const handleDelete = () => {
    setLoadingDelete(true);
    dispatch(deleteTestimonials(deleteData.id, onDeleteSuccess, onDeleteError));
  };
  const onDeleteSuccess = (data) => {
    successToast(data.message);
    setLoadingDelete(false);
    setShow(false);
  };
  const onDeleteError = (data) => {
    errorToast(data.message);
    setLoadingDelete(false);
  };

  function handlePagination(type) {
    if (type === "+") {
      if (page + 1 < pageData.noOfPages) changePage("+");
    }
    else if (type === "-")
      if (page > 0) changePage("-");
  }

  return (
    <TableContainer maxWidth="100%" width="90%">
      <div className="adminuser_table__container position-relative">
        {loadingTable && <LoadingSpinnerTable />}
        <table className={"adminuser_table"}>
          <thead className="adminuser_table__header">
            <tr className="adminuser_table__row">
              {testimonialsHeader.map((item) => {
                return (
                  <th
                    key={item}
                    className={`text-nowrap adminuser_table__heading ${item === "Status" ? `${access ? "" : "text-start"} position-sticky end-0 text-start` : null
                      } `}
                  >
                    {item}
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody className="adminuser_table__body">
            {versionHistoryList.map((item, index) => {
              return (
                <tr key={index} className="adminuser_table__row">
                  <td className="text-nowrap dark_title_color adminuser_table__data ">
                    {page > 0 ? 12 * page + (index + 1) : index + 1}
                  </td>

                  <td className="text-nowrap secondary_color adminuser_table__data">
                    {item?.id}
                  </td>

                  <td className="text-nowrap secondary_color adminuser_table__data">
                    {item.name}
                  </td>

                  <td className="text-nowrap adminuser_table_ellipsis secondary_color adminuser_table__data">
                    {item.designation}
                  </td>

                  <td className="text-nowrap dark_title_color adminuser_table__data">
                    {item.user_type}
                  </td>

                  <td className="text-nowrap adminuser_table_ellipsis dark_title_color adminuser_table__data">
                    {item.description}
                  </td>

                  <td className="text-nowrap dark_title_color adminuser_table__data">

                    {
                      item.testimonial_checkkers?.[0]?.approved_date == null
                        ? "--" : moment(
                          item.testimonial_checkkers?.[0]?.approved_date
                        ).format("DD-MM-YYYY HH:mm:ss")
                    }

                  </td>

                  <td className="text-nowrap dark_title_color adminuser_table__data">

                    {
                      item.testimonial_checkkers?.[0]?.updatedAt == null
                        ? "--" : moment(
                          item.testimonial_checkkers?.[0]?.updatedAt
                        ).format("DD-MM-YYYY HH:mm:ss")
                    }
                  </td>
                  <td className="text-nowrap  adminuser_table__data position-sticky end-0">
                    <div className="d-flex justify-content-start w-100 gap-2">
                      <Link
                        className="text-decoration-none primary_color"
                        to={{
                          pathname: `/static-pages/testimonials/view/${item.id}`,
                        }}
                      >
                        View
                      </Link>
                      {
                        access && <>
                          {
                            item.editable && <>
                              <Link
                                className="text-decoration-none secondary_color"
                                to={{
                                  pathname: `/static-pages/testimonials/edit/${item.id}`,
                                }}
                              >
                                Edit
                              </Link>
                              {item.lastActionApproved && (
                                <>
                                  <Link
                                    className="text-decoration-none light_red_color"
                                    onClick={() => {
                                      setDeleteData({
                                        name: item.name,
                                        id: item.id,
                                      });
                                      handleShow();
                                    }}
                                  >
                                    Delete
                                  </Link>
                                </>)}
                            </>
                          }

                        </>
                      }
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <TablePagination
        paginate={handlePagination}
        currentPage={page}
        pageData={pageData}
      />
      <DeleteModal
        loader={loadingDelete}
        handleClose={handleClose}
        deleteFn={handleDelete}
        data={deleteData}
        show={show}
      />
    </TableContainer>
  );
};

export default TestimonialsTableContent;
