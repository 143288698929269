import styled from '@emotion/styled'
import React from 'react'
import { useNavigate } from 'react-router-dom';
import usePermissionChecker from '../utilits/usePermissionChecker';

export const AdminButton = styled('button')({
    color: "#669E98",
    boxShadow: "0px 0px 6px #00000029",
    borderRadius: "15px",
    border: "none",
    backgroundColor: "white",
    padding: "0",
    fontSize: "12px",
    fontFamily: "Montserrat",
    fontWeight: 600,
    lineHeight: "1.3rem",
    display: "flex",
    alignItems: "center"
},
    props => ({
        padding: props.padding,
        color: props.color,
        backgroundColor: props.backgroundColor,
    })
);


const VersionHistoryBtn = ({ link = "/" }) => {
    const access = usePermissionChecker("checker");
    const navigate = useNavigate()
    return (
        access &&
        <AdminButton onClick={() => navigate(link)} padding="3px 15px"> <span className='pdt_1'>VERSION HISTORY</span></AdminButton>
    )
}

export default VersionHistoryBtn