import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import InnerLayout from "../../../../components/layouts/innerLayout/innerLayout";
import InnerContainer from "../../../../components/layouts/innerContainer/innerContainer";
import VersionHistoryBtn from "../../../../components/buttons/versionHistoryBtn";
import EditBtn from "../../../../components/buttons/editBtn";
import DeleteBtn from "../../../../components/buttons/deleteBtn";
import { deleteFaq, viewFaqDetail } from "../../../../redux/actions/faqAction";
import DeleteModal from "../../../../components/modals/deleteModal";
import successToast from "../../../../components/utilits/successToast";
import errorToast from "../../../../components/utilits/errorToast";
import { viewHowItWorksAction } from "../../../../redux/actions/howItWorksAction";
import HowItWorksReasonTable from "../../../../components/howItWorks/howItWorks/tables/howItWorksReasonTable";
import DownloadIcon from "../../../../assets/icons/downloadIcon";
import HowItWorksOpportunityTable from "../../../../components/howItWorks/howItWorks/tables/howItWorksOpportunityTable";
import HowItWorksContentFourTable from "../../../../components/howItWorks/howItWorks/tables/howItWorksContentFourTable";
import LoadAndError from "../../../../components/utilits/LoadAndError";

const HowItWorksView = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [howItWorksData, setHowItWorksData] = useState({});
  const dispatch = useDispatch();
  const params = useParams();
  useEffect(() => {
    setLoading(true);
    dispatch(viewHowItWorksAction(params.id, onSuccess, onError));
  }, []);
  const onSuccess = (data) => {
    console.log(data.data);
    setHowItWorksData(data.data);
    console.log("SUCCESS");
    setLoading(false);
    setError(false);
  };
  const onError = (data) => {
    console.log("ERROR");
    setLoading(false);
    setError(true);
  };
  // const [deleteData, setDeleteData] = useState({ name: "", id: "" });
  // const [loadingDelete, setLoadingDelete] = useState(false);
  // const [show, setShow] = useState(false);
  // const handleShow = () => setShow(true);
  // const handleClose = () => setShow(false);

  // const handleDelete = () => {
  //   setLoadingDelete(true);
  //   dispatch(
  //     deleteFaq(
  //       faqDetails.id,
  //       onDeleteSuccess,
  //       onDeleteError
  //     )
  //   );
  // };
  // const onDeleteSuccess = (data) => {
  //   successToast(data.message);
  //   setLoadingDelete(false);
  //   setShow(false);
  //   navigate("/faqs")

  // };
  // const onDeleteError = (data) => {
  //   errorToast(data.message);
  //   setLoadingDelete(false);
  // };

  const buttonList = [
    <VersionHistoryBtn link={`/static-pages/how-It-Works/version-history`} />,
    howItWorksData.editable && <EditBtn link={`/static-pages/how-It-Works/edit/${params.id}`} />,
    // <DeleteBtn showModal={deletenotifyMebyid} />,
  ];

  function ViewItemGenerator(title, details) {
    return (
      <>
        <span className="dark_grey_color">{title}</span>
        <p className="dark_title_color">{details}</p>
      </>
    );
  }

  return (
    <InnerLayout buttons={buttonList}>
      <div className="user_view_container mb-4">
        <InnerContainer>
          <LoadAndError loader={loading} error={error}>
            <div className="d-flex flex-column flex-lg-row gap-4 fs_14 py-3 px-3 px-lg-5">
              <div className="col-12 col-lg-6 pe-0 pe-lg-5">
                <div>
                  <span className="dark_grey_color">{"Active"}</span>
                  <p
                    className={` ${howItWorksData?.is_active
                        ? "text-success"
                        : "dark_title_color"
                      }`}
                  >
                    {howItWorksData?.is_active ? "True" : "False"}
                  </p>
                </div>
                <div>{ViewItemGenerator("Title", howItWorksData?.title)}</div>
                <div>
                  {ViewItemGenerator("Subtitle", howItWorksData?.subtitle)}
                </div>
                <div>
                  {ViewItemGenerator(
                    "Video Title",
                    howItWorksData?.video_title
                  )}
                </div>
                <div>
                  {ViewItemGenerator("Video URL", howItWorksData?.video_url)}
                </div>
                <div>
                  {ViewItemGenerator(
                    "Reason Title",
                    howItWorksData?.reason_title
                  )}
                </div>
                <div>
                  {ViewItemGenerator(
                    "Reason Subtitle",
                    howItWorksData?.reason_subtitle
                  )}
                </div>
                <HowItWorksReasonTable
                  name="reasons"
                  formikValues={howItWorksData}
                  editable={false}
                />
                <div>
                  {ViewItemGenerator(
                    "Section 2 Title",
                    howItWorksData?.section2_title
                  )}
                </div>
                <div>
                  {ViewItemGenerator(
                    "Section 2 Subtitle",
                    howItWorksData?.section2_sub_title
                  )}
                </div>
                <div className="d-flex gap-5 flex-wrap">
                  <div>
                    {" "}
                    <span className="">Section 2 Mobile Image</span>{" "}
                    <div className="blog_create_image_container mt-2 position-relative d-flex  justify-content-center align-items-center">
                      <img
                        src={howItWorksData?.section2_image_desktop}
                        className={"user-edit-profile-picture"}
                      />
                      <button
                        type="button"
                        className="position-absolute border-0 bg-white d-flex align-items-center fs_10 top-0 end-0"
                      >
                        <a
                          href={howItWorksData?.section2_image_desktop}
                          download="file"
                          target={"_blank"}
                          className="text-decoration-none text-dark"
                        >
                          <DownloadIcon width={8} />
                          <span className="ms-1">Download</span>
                        </a>
                      </button>
                    </div>
                  </div>
                  <div>
                    {" "}
                    <span className="">Section 2 Mobile Image</span>{" "}
                    <div className="blog_create_image_container mt-2 position-relative d-flex  justify-content-center align-items-center">
                      <img
                        src={howItWorksData?.section2_image_mobile}
                        className={"user-edit-profile-picture"}
                      />
                      <button
                        type="button"
                        className="position-absolute border-0 bg-white d-flex align-items-center fs_10 top-0 end-0"
                      >
                        <a
                          href={howItWorksData?.section2_image_mobile}
                          download="file"
                          // download={true}
                          target={"_blank"}
                          className="text-decoration-none text-dark"
                        >
                          <DownloadIcon width={8} />
                          <span className="ms-1">Download</span>
                        </a>
                      </button>
                    </div>
                  </div>
                </div>
                <div className="mt-5 mt-lg-4">
                    <span className="">Thumb Nail Image</span>{" "}
                    <div className="blog_create_image_container mt-3 position-relative d-flex  justify-content-center align-items-center">
                      <img
                        src={howItWorksData?.you_tube_tumbnail_image}
                        className={"user-edit-profile-picture"}
                      />
                      <button
                        type="button"
                        className="position-absolute border-0 bg-white d-flex align-items-center fs_10 top-0 end-0"
                      >
                        <a
                          href={howItWorksData?.you_tube_tumbnail_image}
                          download="file"
                          // download={true}
                          target={"_blank"}
                          className="text-decoration-none text-dark"
                        >
                          <DownloadIcon width={8} />
                          <span className="ms-1">Download</span>
                        </a>
                      </button>
                    </div>
                  </div>
              </div>
              <div className="col-12 col-lg-6  pe-0 pe-lg-5">
                <div>
                  {ViewItemGenerator(
                    "Section 3 Title",
                    howItWorksData?.section3_title
                  )}
                </div>
                <div>
                  {ViewItemGenerator(
                    "Section 3 Button",
                    howItWorksData?.section3_button
                  )}
                </div>
                <HowItWorksOpportunityTable
                  formikValues={howItWorksData}
                  name={"section3_opportunities"}
                  editable={false}
                />
                <div>
                  {ViewItemGenerator(
                    "Section 4 Title",
                    howItWorksData?.section4_title
                  )}
                </div>
                <HowItWorksContentFourTable
                  formikValues={howItWorksData}
                  name={"section4_content"}
                  editable={false}
                />
                <div>
                  {ViewItemGenerator(
                    "Approved At",
                    howItWorksData?.approvedAt
                      ? howItWorksData?.approvedAt
                      : "-"
                  )}
                </div>
                <div>
                  {ViewItemGenerator(
                    "Updated At",
                    moment(howItWorksData?.updatedAt).format(
                      "DD-MM-YYYY HH:mm:ss"
                    )
                  )}
                </div>
              </div>
            </div>
          </LoadAndError>
        </InnerContainer>
      </div>
      {/* <DeleteModal
        loader={loadingDelete}
        handleClose={handleClose}
        deleteFn={handleDelete}
        data={deleteData}
        show={show}
      /> */}
    </InnerLayout>
  );
};

export default HowItWorksView;
