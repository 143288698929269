import { Field, Form, Formik } from "formik";
import React, { useRef } from "react";
import Modal from "react-bootstrap/Modal";
import CloseIcon from "../../assets/icons/closeIcon";
import ErrorMsg from "../form/errorMsg";
import "./modals.css";
import * as Yup from "yup";
import { rejectedAdressprofUserAction, rejectedApproveAdressprofUserAction, rejectedApproveBankUserAction, rejectedApprovePanUserAction } from "../../redux/actions/userAction";
import successToast from "../utilits/successToast";
import errorToast from "../utilits/errorToast";
import { useDispatch } from "react-redux";

const RejectPanDescription = ({
  show,
  value,
  label,
  popup,
  data,
  handleClose,
  deleteContent = "REJECT PAN DESCRIPTION",
}) => {
  const dispatch = useDispatch()
  const initialValues = {
    message: "",
  };
  const panRejectionSchema = Yup.object().shape({
    message: Yup.string().trim().required("Summary is required"),
  });
  // console.log(data?.id);
  const onSubmit = (values, { resetForm }) => {
    console.log(values);
    if (popup === "address") {
      dispatch(
        rejectedApproveAdressprofUserAction(
          {
            id: data?.id,
            kyc_status: "failed",
            rejected_message: values.message
          },
          onAddressrejectedSuccess,
          onAddressrejectedError
        )
      );
    }
    else if (popup === "pan") {
      console.log(data.id);
      dispatch(
        rejectedApprovePanUserAction(
          {
            id: data.id,
            kyc_status: "failed",
            rejected_message: values.message
          },
          onAddressrejectedSuccess,
          onAddressrejectedError
        )
      );
    }
    else if (popup == "bank") {
      console.log(data.id);
      dispatch(
        rejectedApproveBankUserAction(
          {
            id: data?.id,
            kyc_status: "failed",
            rejected_message: values.message
          },
          onAddressrejectedSuccess,
          onAddressrejectedError
        )
      );
    }

  };

  const onAddressrejectedSuccess = (data) => {
    successToast(data.message);
    handleClose();
    value(!label)
  };
  const onAddressrejectedError = (data) => {
    errorToast(data.message);
  };
  return (
    <Modal
      centered
      show={show}
      onHide={handleClose}
      dialogClassName={`delete_modals_body mx-2 px-1 mx-sm-auto`}
      contentClassName={"delete_modals_body_content  p-1"}
    >
      <Modal.Body>
        <Formik
          initialValues={initialValues}
          validationSchema={panRejectionSchema}
          onSubmit={onSubmit}
        >
          <Form>
            <div className="d-flex justify-content-between  ">
              <h2 className="green_color fs_15 fs_sm_13 fn_Montserrat fw_600 pt-2">
                {deleteContent}
              </h2>
              <button
                type="submit"
                onClick={handleClose}
                className="light_grey_color  fs_15 fs_sm_13 fn_Montserrat bg-transparent border-0 fw_600 "
              >
                <span>DISMISS</span>
                <span className="ms-2">
                  <CloseIcon width={13} height={13} fill="#A5A5A5" />
                </span>
              </button>
            </div>
            <label
              id="message"
              className="mb-2 dark_black_color fn_Nunito mt-2 fw_600 fs_17 ps-2"
            >
              Please type Rejection Message
            </label>
            <Field name={"message"}>
              {({ field }) => (
                <textarea
                  id="message"
                  {...field}
                  type="text"
                  className={"w-100 rejection_message_container p-2 "}
                ></textarea>
              )}
            </Field>

            <ErrorMsg name={"message"} />
            <div className="d-flex justify-content-end mt-2">
              <button
                type="submit"
                className="secondary_color fs_15  fn_Montserrat fw_600 border-0 tertiary_bg px-4 px-lg-5 py-2 py-lg-2 rounded-2"
              >
                SUBMIT
              </button>
            </div>
          </Form>
        </Formik>
      </Modal.Body>
    </Modal>
  );
};
export default RejectPanDescription;
