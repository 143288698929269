import React from 'react'
import { useNavigate } from 'react-router-dom'
import ViewIcon from '../../assets/icons/viewIcon'
import { AdminButton } from './versionHistoryBtn'

const ViewBtn = ({ link = "/", data = {}
}) => {
    const navigate = useNavigate()
    return (
        <AdminButton onClick={() => navigate(link, { state: data })} padding="3px 15px" color="#C8A700">
            <ViewIcon fill='#C8A700' width={18} height={13} />
            <span className="ms-1 pdt_1">View</span>
        </AdminButton>
    )
}

export default ViewBtn
