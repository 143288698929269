import React, { Children, useEffect, useState } from "react";
import "../innerContainer/innerContainer.css";
import arrow_left from "../../../assets/icons/arrowleft.svg";
import { useLocation, useNavigate } from "react-router-dom";
import { JSONTree } from "react-json-tree";

const InnerContainer = ({
  children,
  oldData = {},
  error = false,
  newData = {},
  containerClassName = "position-relative",
  overflowClassName = `admin_view_table_overFlow_container`,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const theme = {
    scheme: "tomorrow",
    author: "chris kempson (http://chriskempson.com)",
    base00: "#1d1f21",
    base01: "#282a2e",
    base02: "#373b41",
    base03: "#969896",
    base04: "#b4b7b4",
    base05: "#c5c8c6",
    base06: "#e0e0e0",
    base07: "#ffffff",
    base08: "#cc6666",
    base09: "#de935f",
    base0A: "#f0c674",
    base0B: "#b5bd68",
    base0C: "#8abeb7",
    base0D: "#81a2be",
    base0E: "#b294bb",
    base0F: "#a3685a",
  };

  const [old_data, setOld_Data] = useState({});
  const [new_data, setNew_Data] = useState({});

  useEffect(() => {
    var flag = false;
    if (newData == null) {
      setNew_Data({});
      flag = true;
      if (oldData != null) {
        setOld_Data(oldData);
      }
    }
    if (oldData == null) {
      if (newData != null) {
        setNew_Data(newData);
      }
      setOld_Data({});
      flag = true;
    }

    // if(!flag){

    // }
    if (oldData != null && newData != null) {
      var changedOldData = {};
      var changedNewData = {};
      var arrayList = oldData == null || oldData == {} ? newData : oldData;
      for (const key in arrayList) {
        if (newData[key] != oldData[key]) {
          changedOldData[key] = oldData[key];
          changedNewData[key] = newData[key];
        }
      }
      setNew_Data(changedNewData);
      setOld_Data(changedOldData);
    }
  }, [JSON.stringify(oldData), JSON.stringify(newData)]);
  // todo make

  return (
    <div
      className={`admin_view_table_container   mt-4 mb-1 ${containerClassName}`}
    >
      <div className=" admin_view_table_container_top_position white_shade_bg">
        <button
          onClick={() => navigate(-1)}
          className="admin_view_back_button d-flex align-items-center ps-4 pt-3"
        >
          <img src={arrow_left} alt="user_photo" />
          <span className="ps-3 dark_grey_color fs_13 fn_Montserrat fw_500">
            Back
          </span>
        </button>
        <hr className="admin_view_hr_line"></hr>
      </div>
      <div className={`${overflowClassName}`}>
        {children}

        {location.pathname.includes("approvals") && !error && (
          <>
            {" "}
            <div className="white_shade_bg  py-1 d-flex mt-4">
              <div className="w-50">
                <h5 className="fw_500 ps-4 dark_grey_color pt-2 text-nowrap fs_16 fn_Montserrat">
                  Old Data
                </h5>
              </div>
              <div className="w-50  d-none d-lg-block">
                <h5 className="fw_500 ps-4  dark_grey_color pt-2 text-nowrap fs_16 fn_Montserrat">
                  Changed Data
                </h5>
              </div>
            </div>
            <div className="row mt-3 ps-0 ps-lg-4">
              <div className="col-12 col-lg-6">
                <div className="notify_me_aprvls_vrsn_hstry_data_container  mt-3 pt-3 px-3">
                  <div className="d-flex justify-content-between">
                    <h5 className="fs_13 fn_Nunito fw_400 dark_title_color text-nowrap">
                      Old Data
                    </h5>
                  </div>
                  <JSONTree theme={theme} data={old_data} />;
                </div>
              </div>
              <div className="col-12 mt-5 mt-lg-0 col-lg-6">
                <h5 className="fw_500 ps-4 d-block d-lg-none white_shade_bg  dark_grey_color py-3 text-nowrap fs_16 fn_Montserrat">
                  Changed Data
                </h5>
                <div className="notify_me_aprvls_vrsn_hstry_data_container    mt-4 mt-lg-3 pt-3 px-3">
                  <div className="d-flex justify-content-between">
                    <h5 className="fs_13 fn_Nunito fw_400 dark_title_color py-2 text-nowrap">
                      Changed Data
                    </h5>
                  </div>
                  <div className="h-100">
                    <JSONTree theme={theme} data={new_data} />;
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default InnerContainer;
