import { call, takeLatest } from "redux-saga/effects";
import {
  APPROVE_OUR_TEAM,
  CREATE_OUR_TEAM,
  DELETE_OUR_TEAM,
  EDIT_OUR_TEAM,
  OUR_TEAM_APPROVALS_EXPORT,
  OUR_TEAM_APPROVAL_TABLE,
  OUR_TEAM_APPROVAL_VIEW,
  OUR_TEAM_EXPORT,
  OUR_TEAM_OPTIONS,
  OUR_TEAM_SUBMIT,
  OUR_TEAM_TABLE,
  OUR_TEAM_VERSION_EXPORT,
  OUR_TEAM_VERSION_HISTORY,
  OUR_TEAM_VERSION_HISTORY_TABLE,
  REJECT_OUR_TEAM,
  VIEW_OUR_TEAM,
} from "../actions/types";
import {
  approveOurTeamApi,
  createOurTeamApi,
  createOutTeamApi,
  deleteourteamApi,
  deleteOurTeamApi,
  editOurTeamApi,
  OurTeamApprovalByIdApi,
  OurTeamApprovalListApi,
  ourTeamApprovalsExportApi,
  ourTeamOptionsApi,
  ourTeamSubmitApi,
  ourTeamTableExportApi,
  ourTeamVersionExportApi,
  OurTeamVersionHistoryListApi,
  rejectOurTeamApi,
  versionHistoryOurTeamDetailApi,
  versionHistoryOurTeamListApi,
  viewOurTeamDetailApi,
  viewOurTeamListApi,
} from "../apis/ourTeamApi";

export function* createOutTeamWorker(action) {
  try {
    const res = yield call(createOurTeamApi, action.data

    );
    console.log(action.data);
    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res.data);
      console.log(res.data);
    } else {
      yield action.onError(res.data);
    }
  } catch (error) { }
}

export function* approveOurTeamWorker(action) {
  try {
    const res = yield call(approveOurTeamApi, {
      user_Id: action.id
    });
    console.log(res);
    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res.data);
    }
  } catch (error) { }
}

export function* rejectOurTeamWorker(action) {
  try {
    const res = yield call(rejectOurTeamApi, action.id);
    console.log(res);
    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res.data);
    }
  } catch (error) { }
}

export function* viewOurTeamDetailWorker(action) {
  try {
    const res = yield call(viewOurTeamDetailApi, {
      id: action.id,
    });
    console.log(res);
    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res.data);
    }
  } catch (error) { }
}
/***list our team */
export function* viewOurTeamListWorker(action) {
  try {
    const res = yield call(viewOurTeamListApi,
      action.page_no,
      action.data,
    );
    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res.data);
      console.log(res.data);
    } else {
      yield action.onError(res.data);
    }
  } catch (error) { }
}

export function* editOurTeamWorker(action) {
  try {
    const res = yield call(editOurTeamApi,
      action.id,
      action.data
    );
    console.log(res);
    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res.data);
      console.log(res.data);
    } else {
      yield action.onError(res.data);
    }
  } catch (error) {
    console.log(error);
    yield action.onError("Something Went Wrong");

  }
}

export function* deleteOurTeamWorker(action) {
  try {
    const res = yield call(deleteourteamApi, action.id);
    console.log(res);
    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res.data);
    }
  } catch (error) { }
}

export function* OurTeamApprovalListWorker(action) {
  try {
    const res = yield call(OurTeamApprovalListApi,
      action.page_no,
      action.data,
    );
    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res.data);
    }
  } catch (error) { }
}

export function* OurTeamApprovalByIdWorker(action) {
  try {
    const res = yield call(OurTeamApprovalByIdApi, {
      id: action.id,
    });
    console.log(res);
    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res.data);
    }
  } catch (error) { }
}

export function* versionHistoryOurTeamListWorker(action) {
  try {
    const res = yield call(OurTeamVersionHistoryListApi,
      action.page_no,
      action.data,
    );
    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res.data);
    }
  } catch (error) { }
}


export function* versionHistoryOurTeamDetailWorker(action) {
  try {
    const res = yield call(versionHistoryOurTeamDetailApi, action.id);
    console.log(res);
    if (res.status === 200 && res.data.status === "success") {
      console.log(res.data);
      yield action.onSuccess(res.data.data);
    } else {
      yield action.onError(res.data);
    }
  } catch (error) { }
}

export function* ourTeamOptionsWorker(action) {
  try {
    const res = yield call(ourTeamOptionsApi, {});
    console.log(res);
    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res.data);
    }
  } catch (error) { }
}



//export
export function* ourTeamTableExportWorker(action) {
  try {
    const res = yield call(ourTeamTableExportApi,

    );
    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res.data);
    }
  } catch (error) { }
}


export function* ourTeamApprovalsExportWorker(action) {
  try {
    const res = yield call(ourTeamApprovalsExportApi);
    console.log(res);
    if (res.status === 200 && res.data.status === "success") {
      console.log(res.data);
      yield action.onSuccess(res.data.data);
    } else {
      yield action.onError(res.data);
    }
  } catch (error) { }
}
                                                                                  
export function* ourTeamVersionExportWorker(action) {
  try {
    const res = yield call(ourTeamVersionExportApi);
    console.log(res);
    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res.data);
    }
  } catch (error) { }
}



export function* ourTeamSubmitWorker(action) {
  try {
      const res = yield call(ourTeamSubmitApi, action.id);
      console.log(res);
      if (res.status === 200 && res.data.status === "success") {
          yield action.onSuccess(res.data);
      } else {
          yield action.onError(res.data);
      }
  } catch (error) { }
}





export function* createOutTeamWorkerWatcher() {
  yield takeLatest(CREATE_OUR_TEAM, createOutTeamWorker);
}                                            

export function* approveOurTeamWorkerWatcher() {
  yield takeLatest(APPROVE_OUR_TEAM, approveOurTeamWorker);
}
export function* rejectOurTeamWorkerWatcher() {
  yield takeLatest(REJECT_OUR_TEAM, rejectOurTeamWorker);
}
export function* viewOurTeamDetailWatcherWorker() {
  yield takeLatest(VIEW_OUR_TEAM, viewOurTeamDetailWorker);
}
export function* viewOurTeamListWorkerWatcher() {
  yield takeLatest(OUR_TEAM_TABLE, viewOurTeamListWorker);
}
export function* editOurTeamWorkerWatcher() {
  yield takeLatest(EDIT_OUR_TEAM, editOurTeamWorker);
}
export function* deleteOurTeamWorkerWatcher() {
  yield takeLatest(DELETE_OUR_TEAM, deleteOurTeamWorker);
}

export function* OurTeamApprovalListWatcherWorker() {
  yield takeLatest(OUR_TEAM_APPROVAL_TABLE, OurTeamApprovalListWorker);
}

export function* OurTeamApprovalByIdWatcher() {
  yield takeLatest(OUR_TEAM_APPROVAL_VIEW, OurTeamApprovalByIdWorker);
}

export function* versionHistoryOurTeamListWatcher() {
  yield takeLatest(
    OUR_TEAM_VERSION_HISTORY_TABLE,
    versionHistoryOurTeamListWorker
  );
}
export function* versionHistoryOurTeamDetailWatcher() {
  yield takeLatest(OUR_TEAM_VERSION_HISTORY, versionHistoryOurTeamDetailWorker);
}

export function* ourTeamOptionsWorkerWatcher() {
  yield takeLatest(OUR_TEAM_OPTIONS, ourTeamOptionsWorker);
}


//export
export function* ourTeamTableExportWatcher() {
  yield takeLatest(
    OUR_TEAM_EXPORT,
    ourTeamTableExportWorker
  );
}
export function* ourTeamApprovalsExportWatcher() {
  yield takeLatest(OUR_TEAM_APPROVALS_EXPORT, ourTeamApprovalsExportWorker);
}

export function* ourTeamVersionExportWorkerWatcher() {
  yield takeLatest(OUR_TEAM_VERSION_EXPORT, ourTeamVersionExportWorker);
}


export function* ourTeamSubmitWorkerWatcher() {
  yield takeLatest(OUR_TEAM_SUBMIT, ourTeamSubmitWorker);
}