import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { TableContainer } from "../../../table/tableContainer";
import { tableCampaignHeader, tableHomeHeader, tablePartnerWithUsFormHeader, tablePartnerWithUsHeader } from "../../../table/tableData";
import moment from "moment";
import TablePagination from "../../../table/tablePagination";
import DeleteModal from "../../../modals/deleteModal";
import successToast from "../../../utilits/successToast";
import errorToast from "../../../utilits/errorToast";
import LoadingSpinnerTable from "../../../utilits/loadingSpinnerTable";
import LoadAndError from "../../../utilits/LoadAndError";
import usePermissionChecker from "../../../utilits/usePermissionChecker";
import {
  activateHomeAction,
  deleteHomeAction,
  viewHomeListAction,
} from "../../../../redux/actions/homeActions";
import { activateCampaignAction, deleteCampaignAction, viewCampaignListAction } from "../../../../redux/actions/campaignActions";
import { activatePartnerWithUsAction, deletePartnerWithUsAction, viewPartnerWithUsListAction } from "../../../../redux/actions/partnerWithUsAction";
import { viewPartnerWithUsFormListAction } from "../../../../redux/actions/partnerWithUsFormAction";

const PartnerWithUsFormTableContent = ({
  tableContainerClassName = "adminuser_table__container",
  containerWidth = "90%",
  page,
  search,
  changePage,
  containerMargin = "1.5rem 0rem",
}) => {
  const [userListData, setUserListData] = useState([]);
  const access = usePermissionChecker("maker");
  const checkerAccess = usePermissionChecker("checker");
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);
  const [show, setShow] = useState(false);
  const [deleteData, setDeleteData] = useState({ name: "", id: "" });
  const [loadingTable, setLoadingTable] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [pageData, setPageData] = useState({ noOfItems: 0, noOfPages: 1 });
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [activeStatus, setActiveStatus] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    setLoading(true);
    dispatch(
      viewPartnerWithUsFormListAction(
        page,
        {
          search: {
            form_id: search?.value?.id,
          },
        },
        onSuccess,
        onError
      )
    );
  }, [search, page, activeStatus,]);

  const onSuccess = (data) => {
    console.log(data.data.data);
    setUserListData(data.data.data);
    setPageData({
      noOfItems: data.data.count,
      noOfPages: data.data.pages,
    });
    setLoading(false);
  };
  const onError = (data) => {
    console.log(data);
    setLoading(false);
  };

  function handlePagination(type) {
    if (type === "+") {
      if (page + 1 < pageData.noOfPages) changePage("+");
    } else if (type === "-") if (page > 0) changePage("-");
  }

  const handleDelete = () => {
    setLoadingDelete(true);
    dispatch(deletePartnerWithUsAction(deleteData?.id, onDeleteSuccess, onDeleteError));
  };
  const onDeleteSuccess = (data) => {
    successToast(data.message);
    setLoadingDelete(false);
    setShow(false);
    setError(false);
    setLoading(false);
  };
  const onDeleteError = (data) => {
    errorToast(data.message);
    setLoadingDelete(false);
    setError(true);
    setLoading(false);
  };

  const handleActivate = (id) => {
    dispatch(activatePartnerWithUsAction(id, onActivateSuccess, onActivateError));
  };
  function onActivateSuccess(data) {
    console.log(data.data.data);
    successToast(data.data.data);
    setActiveStatus(!activeStatus);
  }
  function onActivateError(data) {
    errorToast(data.message);
    console.log(data);
  }

  console.log("====================================");
  console.log(checkerAccess);
  console.log("====================================");
  return (
    <TableContainer width={containerWidth} margin={containerMargin}>
      <div className={`${tableContainerClassName}`}>
        <table className={"adminuser_table"}>
          <thead className="adminuser_table__header">
            <tr className="adminuser_table__row">
              {tablePartnerWithUsFormHeader.map((entry) => {
                return (
                  <th
                    key={entry}
                    className={`${entry === "Status"
                      ? `${access ? "" : "text-start"} position-sticky end-0`
                      : null
                      } text-nowrap adminuser_table__heading `}
                  >
                    {entry}
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody className="adminuser_table__body">
            <LoadAndError
              loader={true}
              status={userListData.length === 0}
            >
              {userListData?.map((item, index) => {
                return (
                  <tr key={item?.id} className="adminuser_table__row">
                    <td className="text-nowrap dark_title_color adminuser_table__data ">
                      {page > 0 ? 12 * page + (index + 1) : index + 1}
                    </td>
                    <td className="text-nowrap dark_title_color adminuser_table__data">
                      {item?.id === null ? "--" : item?.id}
                    </td>
                    <td className="text-nowrap dark_title_color adminuser_table__data">
                      {item?.partner_type === null ? "--" : item?.partner_type}
                    </td>
                    {/* <td className="text-nowrap dark_title_color  adminuser_table_ellipsis adminuser_table__data">
                      {item?.representaion === null ? "--" : item?.representaion}
                    </td>
                    <td className="text-nowrap dark_title_color  adminuser_table_ellipsis adminuser_table__data">
                      {item?.i_want_to === null ? "--" : item?.i_want_to}
                    </td>
                    <td className="text-nowrap dark_title_color  adminuser_table_ellipsis adminuser_table__data">
                      {item?.for === null ? "--" : item?.for}
                    </td>
                    <td className="text-nowrap dark_title_color  adminuser_table_ellipsis adminuser_table__data">
                      {item?.i_propose === null ? "--" : item?.i_propose}
                    </td>
                    <td className="text-nowrap dark_title_color  adminuser_table_ellipsis adminuser_table__data">
                      {item?.file === null ? "--" : item?.file}
                    </td> */}
                    <td className="text-nowrap dark_title_color  adminuser_table_ellipsis adminuser_table__data">
                      {item?.name === null ? "--" : item?.name}
                    </td>
                    <td className="text-nowrap dark_title_color  adminuser_table_ellipsis adminuser_table__data">
                      {item?.email_address === null ? "--" : item?.email_address}
                    </td>
                    <td className="text-nowrap dark_title_color  adminuser_table_ellipsis adminuser_table__data">
                      {item?.mobile_number === null ? "--" : item?.mobile_number}
                    </td>
                    {/* <td className="text-nowrap  dark_title_color adminuser_table_ellipsis adminuser_table__data">
                      {item?.is_active === null
                        ? "--"
                        : item?.is_active
                          ? "True"
                          : "False"}
                    </td>
                    <td className="text-nowrap  dark_title_color adminuser_table__data">
                      {item?.createdAt === null
                        ? "--"
                        : moment(item?.createdAt).format("DD-MM-YYYY HH:mm:ss")}
                    </td>

                    <td className="text-nowrap secondary_color  adminuser_table__data">
                      {item.updatedAt === null
                        ? "-"
                        : moment(item.updatedAt).format("DD-MM-YYYY HH:mm:ss")}
                    </td> */}
                    <td className="text-nowrap  position-sticky end-0 adminuser_table__data">
                      <div className="d-flex w-100  justify-content-start gap-2">
                        <Link
                          className="text-decoration-none primary_color"
                          to={{
                            pathname: `/static-pages/partnerWithUsForm/view/${item?.id}`,
                          }}
                        >
                          View
                        </Link>
                        {/* {access && item?.editable && (
                          <>
                            <span className="vr"></span>
                            <Link
                              className="text-decoration-none secondary_color"
                              to={{
                                pathname: `/static-pages/partnerWithUsForm/edit/${item?.id}`,
                              }}
                            >
                              Edit
                            </Link>
                            <span className="vr"></span>{" "}
                            <button
                              onClick={() => {
                                setDeleteData({
                                  name:
                                    item?.section1_heading === null
                                      ? "--"
                                      : item?.section1_heading,
                                  id: item.id,
                                });
                                handleShow();
                              }}
                              className="text-decoration-none primary_color light_red_color border-0 bg-transparent "
                            >
                              Delete
                            </button>{" "}
                          </>
                        )} */}
                        {checkerAccess && (
                          <>
                            <span className="vr"></span>
                            <button
                              disabled={item?.is_active}
                              onClick={() => {
                                !item?.is_active && handleActivate(item.id);
                              }}
                              className={`border-0 bg-transparent ${item?.is_active ? "text-success fw_600" : ""
                                } `}
                            >
                              {item?.is_active ? "Active" : "Activate"}
                            </button>
                          </>
                        )}
                      </div>
                    </td>
                  </tr>
                );
              })}
            </LoadAndError>
          </tbody>
          {/* )} */}
        </table>
      </div>
      <DeleteModal
        loader={loadingDelete}
        handleClose={handleClose}
        deleteFn={handleDelete}
        data={deleteData}
        show={show}
      />
      <TablePagination
        paginate={handlePagination}
        currentPage={page}
        pageData={pageData}
      />
    </TableContainer>
  );
};
export default PartnerWithUsFormTableContent;
