import React, { useRef, useState, useEffect } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import InnerLayout from "../../../../components/layouts/innerLayout/innerLayout";
import InnerContainer from "../../../../components/layouts/innerContainer/innerContainer";
import SelectFields from "../../../../components/form/selectFields/selectFields";
import ArrowDownIcon from "../../../../assets/icons/arrowdown";
import "./express-interest.css";
import SaveBtn from "../../../../components/buttons/saveBtn";
import DeleteBtn from "../../../../components/buttons/deleteBtn";
import InputField from "../../../../components/form/inputFields/inputField";
import {
  createExpress,
  expressSubmit,
} from "../../../../redux/actions/expressInterestAction";
import { useDispatch } from "react-redux";
import errorToast from "../../../../components/utilits/errorToast";
import successToast from "../../../../components/utilits/successToast";
import { notifyMeCreateOptions } from "../../../../redux/actions/notifyMeActions";
import { opportunitiesNameListAction } from "../../../../redux/actions/opportunitiesActions";
import { useNavigate } from "react-router-dom";
import SubmitBtn from "../../../../components/buttons/submit";

const ExpressInterestCreate = () => {
  const formRef = useRef();
  const dispatch = useDispatch();
  const [userName, setUserName] = useState([]);
  const [submitData, setSubmitData] = useState();
  const navigate = useNavigate();
  const [opportunityType, setOpportunityType] = useState([]);
  useEffect(() => {
    dispatch(notifyMeCreateOptions(onNotifyMeSuccess, onNotifyMeError));
  }, []);
  const userNameOptions = [];
  const onNotifyMeSuccess = (data) => {
    data?.data.AllUsers.map((item, index) => {
      userNameOptions[index] = { value: item.id, label: item.user_name };
    });
    setUserName(userNameOptions);
    console.log("SUCCESS");
  };
  const onNotifyMeError = (data) => {
    console.log("ERROR");
  };

  //

  const amountRegex = /^[0-9]*$/s;
  const initialValues = {
    opportunity: "",
    user: "",
    Amount: "",
    orderID: "",
    paymentMethod: "",
    transactionStatus: "",
    transactionError: "",
    walletUsed: "",
    walletAmount: "",
    walletTransactionID: "",
    walletTransactionStatus: "",
    walletTransactionError: "",
    paymentGatewayAmount: "",
    paymentGatewayTransactionId: "",
    paymentGatewayTransactionStatus: "",
    paymentGatewayTransactionError: "",
    backendtransactionID: "",
    tenure: "",
  };

  const interestEditSchema = Yup.object().shape({
    opportunity: Yup.string().trim().required("Required"),
    user: Yup.string().trim().required("Required"),
    Amount: Yup.string()
      .matches(amountRegex, "Please Enter Valid Amount Value")
      .required("Required"),
    orderID: Yup.string().trim(),
    paymentMethod: Yup.string().trim(),
    transactionStatus: Yup.string().trim(),
    transactionError: Yup.string().trim(),
    walletUsed: Yup.boolean(),
    walletAmount: Yup.string()
      .matches(amountRegex, "Please Enter Valid Amount Value")
      ,
    walletTransactionID: Yup.string().trim(),
    walletTransactionStatus: Yup.string().trim(),
    walletTransactionError: Yup.string().trim(),
    paymentGatewayAmount: Yup.string()
      .matches(amountRegex, "Please Enter Valid Amount Value")
      ,
    paymentGatewayTransactionId: Yup.string().trim(),
    paymentGatewayTransactionStatus: Yup.string().trim(),
    paymentGatewayTransactionError: Yup.string().trim(),
    backendtransactionID: Yup.string().trim(),
    tenure: Yup.string()
      .matches(amountRegex, "Please Enter Valid Amount Value")
  });

  const handleSubmit = () => {
    if (formRef.current) {
      formRef.current.handleSubmit();
    }
  };
  const onSubmit = (values, { resetForm }) => {
    console.log(values.transactionStatus);
    dispatch(
      createExpress(
        {
          opportunity_id: values.opportunity,
          user_id: values.user,
          amount: values.Amount,
          order_id: values.orderID,
          offline_transaction: true,
          payment_method: values.paymentMethod,
          transaction_status: values.transactionStatus,
          transaction_error_txt: values.transactionError,
          wallet_used: values.walletUsed,
          wallet_amount: values.walletAmount,
          pg_transaction_time: "time",
          pg_transaction_webook_time: "2022-09-08",
          wallet_transaction_id: values.walletTransactionID,
          pg_transaction_id: values.paymentGatewayTransactionId,
          wallet_webook_time: "wallet",
          wallet_transaction_status: values.walletTransactionStatus,
          wallet_transaction_error_txt: values.walletTransactionError,
          pg_transaction_amount: values.paymentGatewayAmount,
          pg_transaction_status: values.paymentGatewayTransactionStatus,
          pg_transaction_error_txt: values.paymentGatewayTransactionError,
          backend_transaction_id: values.backendtransactionID,
          tenure: values.tenure,
        },
        onSuccess,
        onError
      )
    );
  };

  const onSuccess = (data) => {
    console.log(data);
    setSubmitData(data.data.id);
    setSaveHide(true);
    successToast(data.message);
    navigate(`/opportunity/express-interest`);
  };

  const onError = (data) => {
    errorToast(data.message);
    console.log(data);
  };

  //opp id
  const [opportunitiesList, setOpportunitiesList] = useState([]);
  useEffect(() => {
    dispatch(opportunitiesNameListAction(onSuccessOpt, onErrorOpt));
  }, []);
  const onSuccessOpt = (data) => {
    const categoryList = [];
    data?.data.map((item, index) => {
      categoryList[index] = {
        value: item.id,
        label: `${item.title}${" "}id:${item.id}`,
      };
    });
    setOpportunitiesList(categoryList);
    console.log("SUCCESS");
  };
  const onErrorOpt = (data) => {
    console.log(data);
  };

  //on submit api
  console.log(submitData);
  const [saveHide, setSaveHide] = useState(false);

  const buttonList = [!saveHide && <SaveBtn submitFn={handleSubmit} />];
  return (
    <InnerLayout buttons={buttonList}>
      <div className="user_view_container">
        <InnerContainer>
          <div className="row mt-2">
            <div className="col-12 col-sm-12 col-md-9 col-lg-9 col-xl-7">
              <div className="row ps-1 ms-1 ps-lg-4 ms-lg-1">
                <div className="ps-2  pt-3">
                  <Formik
                    innerRef={formRef}
                    initialValues={initialValues}
                    onSubmit={onSubmit}
                    enableReinitialize={true}
                    validationSchema={interestEditSchema}
                  >
                    {({ formik, handleChange, values, setFieldValue }) => {
                      return (
                        <Form className="">
                          <div className="   express-form-width">
                            <SelectFields
                              label={"Opportunity"}
                              Icon={
                                <ArrowDownIcon
                                  width={20}
                                  height={20}
                                  fill={"#A5A5A5"}
                                />
                              }
                              placeholder={"Select Opportunity"}
                              name={"opportunity"}
                              options={opportunitiesList}
                              id={"opportunity"}
                            />
                            <SelectFields
                              label={"User"}
                              Icon={
                                <ArrowDownIcon
                                  width={20}
                                  height={20}
                                  fill={"#A5A5A5"}
                                />
                              }
                              placeholder={"Select User"}
                              name={"user"}
                              options={userName}
                              id={"user"}
                            />

                            <InputField
                              id={"Amount"}
                              label={"Amount"}
                              type={"text"}
                              placeholder={"Enter Amount"}
                              name={"Amount"}
                            />
                            <InputField
                              id={"Order ID"}
                              label={"Order ID"}
                              type={"text"}
                              placeholder={"Enter Order ID"}
                              name={"orderID"}
                            />
                            <SelectFields
                              label={"Payment Method"}
                              Icon={
                                <ArrowDownIcon
                                  width={20}
                                  height={20}
                                  fill={"#A5A5A5"}
                                />
                              }
                              placeholder={"Select payment Method"}
                              name={"paymentMethod"}
                              options={paymentMethod}
                              id={"Payment Method"}
                            />
                            <SelectFields
                              label={"Transaction Status"}
                              Icon={
                                <ArrowDownIcon
                                  width={20}
                                  height={20}
                                  fill={"#A5A5A5"}
                                />
                              }
                              placeholder={"Select Transaction Status"}
                              name={"transactionStatus"}
                              options={transactionStatus}
                              id={"Transaction Status"}
                            />
                            <InputField
                              id={"Transaction Error"}
                              label={"Transaction Error?"}
                              type={"text"}
                              placeholder={"Enter transaction Error"}
                              name={"transactionError"}
                            />

                            <SelectFields
                              label={"Wallet Used?"}
                              Icon={
                                <ArrowDownIcon
                                  width={20}
                                  height={20}
                                  fill={"#A5A5A5"}
                                />
                              }
                              placeholder={"Select True/False"}
                              name={"walletUsed"}
                              options={walletUsed}
                              id={"Wallet Used"}
                            />

                            <InputField
                              id={"Wallet Amount"}
                              label={"Wallet Amount"}
                              type={"text"}
                              placeholder={"Enter Wallet Amount"}
                              name={"walletAmount"}
                            />
                            <InputField
                              id={"Wallet Transaction ID"}
                              label={"Wallet Transaction ID"}
                              type={"text"}
                              placeholder={"Enter Wallet Transaction ID"}
                              name={"walletTransactionID"}
                            />
                            <SelectFields
                              label={"Wallet Transaction Status"}
                              Icon={
                                <ArrowDownIcon
                                  width={20}
                                  height={20}
                                  fill={"#A5A5A5"}
                                />
                              }
                              placeholder={"Select Wallet Transaction Status"}
                              name={"walletTransactionStatus"}
                              options={walletTransactionStatus}
                              id={"Wallet Transaction Status"}
                            />
                            <InputField
                              id={"Wallet transaction Error"}
                              label={"Wallet transaction Error?"}
                              type={"text"}
                              placeholder={"Enter Wallet transaction Error"}
                              name={"walletTransactionError"}
                            />
                            <InputField
                              id={"Payment Gateway Amount"}
                              label={"Payment Gateway Amount"}
                              type={"text"}
                              placeholder={"Enter Payment Gateway Amount"}
                              name={"paymentGatewayAmount"}
                            />
                            <InputField
                              id={"Payment Gateway Transaction ID"}
                              label={"Payment Gateway Transaction ID"}
                              type={"text"}
                              placeholder={
                                "Enter Payment Gateway Transaction ID"
                              }
                              name={"paymentGatewayTransactionId"}
                            />
                            <SelectFields
                              label={"Payment Gateway Transaction Status"}
                              Icon={
                                <ArrowDownIcon
                                  width={20}
                                  height={20}
                                  fill={"#A5A5A5"}
                                />
                              }
                              placeholder={
                                "Select Payment Gateway Transaction Status"
                              }
                              name={"paymentGatewayTransactionStatus"}
                              options={paymentGatewayTransactionStatus}
                              id={"Payment Gateway Transaction Status"}
                            />
                            <InputField
                              id={"Payment Gateway Transaction Error?"}
                              label={"Payment Gateway Transaction Error?"}
                              type={"text"}
                              placeholder={
                                "Enter Payment Gateway Transaction Error?"
                              }
                              name={"paymentGatewayTransactionError"}
                            />
                            <InputField
                              id={"Backend Transaction ID"}
                              label={"Backend Transaction ID"}
                              type={"text"}
                              placeholder={"Enter Backend Transaction ID"}
                              name={"backendtransactionID"}
                            />
                            <InputField
                              id={"Tenure"}
                              label={"Tenure"}
                              type={"text"}
                              placeholder={"Enter Tenure"}
                              name={"tenure"}
                            />
                          </div>
                        </Form>
                      );
                    }}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </InnerContainer>
      </div>
    </InnerLayout>
  );
};

export default ExpressInterestCreate;

export const opportunity = [
  { value: "Type1", label: "Type1" },
  { value: "Type2", label: "Type2" },
  { value: "Type3", label: "Type3" },
  { value: "Type4", label: "Type4" },
];

export const user = [
  { value: "Type1", label: "Type1" },
  { value: "Type2", label: "Type2" },
  { value: "Type3", label: "Type3" },
  { value: "Type4", label: "Type4" },
];

export const paymentMethod = [
  { value: "online", label: "online" },
  { value: "bank", label: "bank" },
  { value: "wallet", label: "wallet" },
  { value: "online & wallet", label: "online & wallet" },
];

export const transactionStatus = [
  {
    value: "processing",
    label: "processing",
  },
  {
    value: "success",
    label: "success",
  },
  {
    value: "error",
    label: "error",
  },
];

export const walletUsed = [
  {
    value: true,
    label: "True",
  },
  {
    value: false,
    label: "False",
  },
];

export const walletTransactionStatus = [
  {
    value: "processing",
    label: "processing",
  },
  {
    value: "success",
    label: "success",
  },
  {
    value: "error",
    label: "error",
  },
];

export const paymentGatewayTransactionStatus = [
  {
    value: "processing",
    label: "processing",
  },
  {
    value: "success",
    label: "success",
  },
  {
    value: "error",
    label: "error",
  },
  {
    value: "cancelled",
    label: "cancelled",
  },
];
