import React, { useEffect, useState } from "react";
import errorToast from "../../../../components/utilits/errorToast";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import DeleteBtn from "../../../../components/buttons/deleteBtn";
import EditBtn from "../../../../components/buttons/editBtn";
import VersionHistoryBtn from "../../../../components/buttons/versionHistoryBtn";
import InnerContainer from "../../../../components/layouts/innerContainer/innerContainer";
import InnerLayout from "../../../../components/layouts/innerLayout/innerLayout";
import { deleteNotifyMe, readNotifyMe } from "../../../../redux/actions/notifyMeActions";
import DeleteModal from "../../../../components/modals/deleteModal";
import successToast from "../../../../components/utilits/successToast";
import DownloadIcon from "../../../../assets/icons/downloadIcon";
import ViewImageModal from "../../../../components/user/modals/viewImageModal";
import OurTeamProfileImage from "../../../../components/static-pages/our-team/modals/approvalProfileImage";
import { viewMediaRecAction } from "../../../../redux/actions/mediaRecogonitionActions";
import moment from "moment";
import LoadingSpinnerTable from "../../../../components/utilits/loadingSpinnerTable";
import LoadAndError from "../../../../components/utilits/LoadAndError";

const MediaRecogonitionRead = () => {

  const [logo, setLogo] = useState(false);
  const [profile, setProfile] = useState(false);
  const navigate = useNavigate();
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);
  const [show, setShow] = useState(false);
  const [imageShow, setImageShow] = useState(false)
  const params = useParams()
  const dispatch = useDispatch()
  const handleBackImageShow = () => setImageShow(true);
  const handleImageClose = () => setImageShow(false);
  const [notifyMeViewData, SetNotifyMeViewData] = useState({ id: "", name: "", createdAt: "", updatedAt: "" })
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);

  useEffect(() => {
    setLoading(true)
    dispatch(
      viewMediaRecAction(params?.id, onSuccess, onError)
    );
  }, [params.id])
  const onSuccess = (data) => {
    SetNotifyMeViewData(data.data);
    setLoading(false)
    setError(false);
  };
  const onError = (data) => {
    errorToast(data.message)
    setLoading(false)
    setError(true);
  };

  //delete
  const [deleteData, setDeleteData] = useState({ name: "", id: "" });

  const handleDelete = () => {
    setLoadingDelete(true);
    // dispatch(
    //   deleteNotifyMe(
    //     notifyMeViewData.id,
    //     onDeleteSuccess,
    //     onDeleteError,
    //   )
    // );
  };
  // const onDeleteSuccess = (data) => {
  //   successToast(data.message);
  //   setLoadingDelete(false);
  //   setShow(false);
  //   navigate("/notify-me")
  // };
  // const onDeleteError = (data) => {
  //   errorToast(data.message);
  //   setLoadingDelete(false);
  // };
  // console.log(notifyMeViewData);

  const deletenotifyMebyid = () => {
    // setDeleteData({
    //   name: notifyMeViewData.user_name,
    //   id: notifyMeViewData.opportunity_id,
    // });
    // handleShow();
  }
  const buttonList = [
    <VersionHistoryBtn link={`/static-pages/media-recogonition/version-history`} />,
    notifyMeViewData?.editable && <>

      <EditBtn link={`/static-pages/media-recogonition/edit/${notifyMeViewData.id}`} />,
    </>    //<DeleteBtn showModal={deletenotifyMebyid} />
  ];
  const data = [
    {
      heading: "Media & Recognitions ID",
      title: notifyMeViewData?.id ? notifyMeViewData?.id : "--",
    },
    {
      heading: "Name",
      title: notifyMeViewData?.name ? notifyMeViewData?.name : "--",
    },
    {
      heading: "Title",
      title: notifyMeViewData?.title ? notifyMeViewData?.title : "--",
    },
    {
      heading: "Description",
      title: notifyMeViewData?.description ? notifyMeViewData?.description : "--",
    },
    {
      heading: "URL",
      title: notifyMeViewData?.logo_url ? notifyMeViewData?.logo_url : "--",
    },
    {
      heading: "Approved At",
      title: notifyMeViewData?.updatedAt ? moment(notifyMeViewData?.updatedAt).format("DD-MM-YYYY HH:mm:ss") : "--",
    },
    {
      heading: "Updated At",
      title: notifyMeViewData?.createdAt ? moment(notifyMeViewData?.createdAt).format("DD-MM-YYYY HH:mm:ss") : "--",
    },
  ];
  return (
    <InnerLayout buttons={buttonList}>
      <div className="admin_user_container">
        <InnerContainer>
          {loading ? (
            <LoadingSpinnerTable />
          ) : (
            <LoadAndError loader={loading} error={error}>
              <div className="row">
                <div className='col-12 col-md-5'>
                  <div className="pt-3  ps-4 ps-lg-4 ms-3">
                    {data.map((item, index) => (
                      <div className="mb-2">
                        <h5 className="dark_grey_color fs_13 fn_Montserrat fw_500 m-0">
                          {item.heading}
                        </h5>
                        <p
                          className={` fs_13 fn_Nunito fw_500  ${item.heading === "LinkedIn URL" || item.heading === "User ID" ? 'secondary_color' : 'dark_title_color'} `}>
                          {item.title}
                        </p>
                      </div>
                    ))}
                  </div>
                </div>
                <div className='col-12 col-md-5'>
                  <div className="ps-5">
                    <h5 className="dark_grey_color text-nowrap fs_13 fn_Montserrat fw_500">
                      Logo
                    </h5>

                    <div className="personal_information_image_container mt-2 position-relative">
                      <img
                        onClick={() => {
                          handleBackImageShow()
                          setLogo(true)
                          setProfile(false)
                        }}
                        src={notifyMeViewData?.logo_url}

                        className="personal_information_img_size rounded-2"
                      />
                      <a
                        href={DownloadIcon}
                        download="file"
                      >
                        <button
                          // onClick={downloadImage}
                          type="button"
                          className="position-absolute border-0 bg-white d-flex align-items-center fs_10 top-0 end-0"
                        >
                          <DownloadIcon width={8} />
                          <span className="ms-1">Download</span>
                        </button>
                      </a>
                    </div>
                  </div>
                  <div className="ps-5 mt-3">
                    <h5 className="dark_grey_color text-nowrap fs_13 fn_Montserrat fw_500">
                      Profile Picture
                    </h5>

                    <div className="personal_information_image_container mt-2 position-relative">
                      <img
                        onClick={() => {
                          handleBackImageShow()
                          setLogo(false)
                          setProfile(true)
                        }}
                        src={notifyMeViewData?.photo_url}
                        className="personal_information_img_size rounded-2"
                      />
                      <a
                        href={DownloadIcon}
                        download="file"
                      >
                        <button
                          // onClick={downloadImage}
                          type="button"
                          className="position-absolute border-0 bg-white d-flex align-items-center fs_10 top-0 end-0"
                        >
                          <DownloadIcon width={8} />
                          <span className="ms-1">Download</span>
                        </button>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </LoadAndError>
          )}
        </InnerContainer>
      </div>
      <DeleteModal
        loader={loadingDelete}
        handleClose={handleClose}
        deleteFn={handleDelete}
        data={deleteData}
        show={show}
      />
      {
        logo === true && (
          <OurTeamProfileImage
            handleClose={handleImageClose}
            show={imageShow}
            Profileimage={notifyMeViewData?.logo_url}
            modalHeading={"LOGO"}
          />
        )
      }
      {
        profile === true && (
          <OurTeamProfileImage
            handleClose={handleImageClose}
            show={imageShow}
            Profileimage={notifyMeViewData?.photo_url}
            modalHeading={"PROFILE PICTURE"}
          />
        )
      }
    </InnerLayout>
  );
};

export default MediaRecogonitionRead;

