import React, { useState } from 'react'
import CreateBtn from '../../../../components/buttons/createBtn'
import ExportBtn from '../../../../components/buttons/exportBtn'
import FilterBtn from '../../../../components/buttons/filterBtn'
import VersionHistoryBtn from '../../../../components/buttons/versionHistoryBtn'
import HomeFilter from '../../../../components/filter/static-pages filter/homeFilter'
import InnerLayout from '../../../../components/layouts/innerLayout/innerLayout'
import CampaignApprovalTableContent from '../../../../components/static-pages/campaign/tables/campaignApprovalTableContent'
import HomeApprovalsTableContent from '../../../../components/static-pages/home-page/tables/homeApprovalTableContent'
import PartnerWithUsApprovalTableContent from '../../../../components/static-pages/partner-with-us/tables/partnerWithUsApprovalTableContent'
import { campaignApprovalsExportAction } from '../../../../redux/actions/campaignActions'
import { partnerWithUsApprovalsExportAction } from '../../../../redux/actions/partnerWithUsAction'


const PartnerWithUsApprovalsTable = () => {
  const [showFilter, setShowFilter] = useState(false);
  const [search, setSearch] = useState();
  const [page, setPage] = useState(0);
  const handleFilterClose = () => {
    setShowFilter(false);
  };
  const handleFilterOpen = () => {
    setShowFilter(true);
  };
  const handleSearch = (value) => {
    setSearch({ value: value });
    setPage(0)
  };
  const handlePage = (type) => {
    if (type === "+") {
      setPage((prev) => prev + 1)
    }
    else {
      setPage((prev) => prev - 1)
    }
  }
  const buttonList = [
    <VersionHistoryBtn link='/static-pages/partner-with-us/version-history' />,
    <CreateBtn link='/static-pages/partner-with-us/create' />,
    <ExportBtn dispatchAction={campaignApprovalsExportAction} />,
    <FilterBtn filterFn={handleFilterOpen}
    />
  ]
  return (
    <InnerLayout buttons={buttonList}>
      <HomeFilter
        title={"Partners Id"}
        filter={handleSearch}
        showFilter={showFilter}
        closeFilter={handleFilterClose}
      />
      <PartnerWithUsApprovalTableContent page={page} changePage={handlePage} search={search} />
    </InnerLayout>
  )
}
export default PartnerWithUsApprovalsTable;  