import { authHeader } from "../config";
import { api } from "./api";

export const createExpressApi = (params) => {
    return api.post("/api/express_intrests/create-express-intrest", params, {
        headers: {
            "x-access-token": localStorage.getItem("accessToken"),
            "Content-Type": "application/json",
        },
    });
};

export const approveExpressApi = (params) => {
    return api.post(`/api/express_intrests/approve-express-interest`, JSON.stringify(params), {
        headers: {
            "x-access-token": localStorage.getItem("accessToken"),
            "Content-Type": "application/json",
        },
    });
};

export const rejectExpressApi = (id, params) => {
    return api.post(
        `/api/express_intrests/reject-express-intrest/${id}`,
        JSON.stringify(params),
        {
            headers: {
                "x-access-token": localStorage.getItem("accessToken"),
                "Content-Type": "application/json",
            },
        }
    );
};

export const viewExpressDetailApi = (id, params) => {
    return api.post(
        `/api/express_intrests/view-express-intrest/${id}`,
        JSON.stringify(params),
        {
            headers: {
                "x-access-token": localStorage.getItem("accessToken"),
                "Content-Type": "application/json",
            },
        }
    );
};

export const viewExpressListApi = (pageNo, params) => {
    return api.post(
        `/api/express_intrests/express-intrest-approvals?page_no=${pageNo}`,
        JSON.stringify(params),
        {
            headers: {
                "x-access-token": localStorage.getItem("accessToken"),
                "Content-Type": "application/json",
            },
        }
    );
};

export const editExpressApi = (id, params) => {
    return api.post(
        `/api/express_intrests/edit-express-intrest/${id}`,
        params,
        {
            headers: {
                "x-access-token": localStorage.getItem("accessToken"),
                "Content-Type": "application/json",
            },
        }
    );
};

export const deleteExpressApi = (id, params) => {
    return api.post(
        `/api/express_intrests/delete-express-intrest/${id}`,
        JSON.stringify(params),
        {
            headers: {
                "x-access-token": localStorage.getItem("accessToken"),
                "Content-Type": "application/json",
            },
        }
    );
};


export const ExpressApprovalListApi = (page_no, params) => {
    return api.post(
        `/api/express_intrests/express-intrest-checker?page_no=${page_no}`,
        JSON.stringify(params),
        {
            headers: {
                "x-access-token": localStorage.getItem("accessToken"),
                "Content-Type": "application/json",
            },
        }
    );
};



export const expressApprovalByIdApi = (id, params) => {
    return api.post(
        `/api/express_intrests/checker-express-intrest/${id}`,
        JSON.stringify(params),
        {
            headers: {
                "x-access-token": localStorage.getItem("accessToken"),
                "Content-Type": "application/json",
            },
        }
    );
};

export const versionHistoryExpressListApi = (page = 0, params) => {
    return api.post(
        `/api/express_intrests/version-express-intrest?page_no=${page}`,
        JSON.stringify(params),
        {
            headers: {
                "x-access-token": localStorage.getItem("accessToken"),
                "Content-Type": "application/json",
            },
        }
    );
};

export const versionHistoryExpressDetailApi = (id, params) => {
    return api.post(
        `/api/express_intrests/version-history-express-intrest/${id}`,
        JSON.stringify(params),
        {
            headers: {
                "x-access-token": localStorage.getItem("accessToken"),
                "Content-Type": "application/json",
            },
        }
    );
};
export const ExpressOptionsApi = (id, params) => {
    return api.post(
        `/api/admins/version-admin/${id}`,
        JSON.stringify(params),
        {
            headers: {
                "x-access-token": localStorage.getItem("accessToken"),
                "Content-Type": "application/json",
            },
        }
    );
};




export const expressTableExportApi = (params) => {
    return api.post(
        `/api/express_intrests/export-main-express-intrest`,
        JSON.stringify(params),
        {
            headers: {
                "x-access-token": localStorage.getItem("accessToken"),
                "Content-Type": "application/json",
            },
        }
    );
};
export const expressApprovalsExportApi = (params) => {
    return api.post(
        `/api/express_intrests/export-approval-express-intrest`,
        JSON.stringify(params),
        {
            headers: {
                "x-access-token": localStorage.getItem("accessToken"),
                "Content-Type": "application/json",
            },
        }
    );
};
export const expressVersionExportApi = (params) => {
    return api.post(
        `/api/express_intrests/export-version-express-intrest`,
        JSON.stringify(params),
        {
            headers: {
                "x-access-token": localStorage.getItem("accessToken"),
                "Content-Type": "application/json",
            },
        }
    );
};
                       
//onsubmit
export const expressSubmitApi = (id, params) => {
    return api.post(
        `/api/express_intrests/on-submit/${id}`,
        JSON.stringify(params),
        {
            headers: {
                "x-access-token": localStorage.getItem("accessToken"),
                "Content-Type": "application/json",
            },
        }
    );
};



