import React, { useEffect, useRef, useState } from "react";
import { Formik, Form, Field, FastField } from "formik";
import * as Yup from "yup";
import InnerLayout from "../../../../components/layouts/innerLayout/innerLayout";
import InnerContainer from "../../../../components/layouts/innerContainer/innerContainer";
import SelectFields from "../../../../components/form/selectFields/selectFields";
import SaveBtn from "../../../../components/buttons/saveBtn";
import InputField from "../../../../components/form/inputFields/inputField";
import ErrorMsg from "../../../../components/form/errorMsg";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { editFaq, faqOptions, viewFaqDetail } from "../../../../redux/actions/faqAction";
import successToast from "../../../../components/utilits/successToast";
import errorToast from "../../../../components/utilits/errorToast";
import HowItWorksContentFourTable from "../../../../components/howItWorks/howItWorks/tables/howItWorksContentFourTable";
import HowItWorksOpportunityTable from "../../../../components/howItWorks/howItWorks/tables/howItWorksOpportunityTable";
import UploadIcon from "../../../../assets/icons/upload";
import CloseIcon from "../../../../assets/icons/closeIcon";
import HowItWorksReasonTable from "../../../../components/howItWorks/howItWorks/tables/howItWorksReasonTable";
import { editHowItWorksAction, howItWorksCategorySubmit, viewHowItWorksAction } from "../../../../redux/actions/howItWorksAction";
import { uploadAddressProofFrontImageAction } from "../../../../redux/actions/userAction";
import LoadAndError from "../../../../components/utilits/LoadAndError";
import SubmitBtn from "../../../../components/buttons/submit";

const HowItWorksEdit = () => {
  const formRef = useRef();
  const navigate = useNavigate();
  const params = useParams();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [submitData, setSubmitData] = useState();
  const [error, setError] = useState(false);
  const [howItWorksData, setHowItWorksData] = useState({});
  const [deskImageLink, setDeskImageLink] = useState({
    img: "",
    error: false,
  });
  const [mobImageLink, setMobImageLink] = useState({
    img: "",
    error: false,
  });
  const [thumbNail, setThumbNail] = useState({
    img: "",
    error: false,
  });
  useEffect(() => {
    setLoading(true);
    dispatch(viewHowItWorksAction(params.id, onSuccess, onError));
  }, []);
  const onSuccess = (data) => {
    console.log(data.data);
    setHowItWorksData(data.data);
    console.log("SUCCESS");
    setDeskImageLink({ img: data.data.section2_image_desktop, error: false })
    setMobImageLink({ img: data.data.section2_image_mobile, error: false })
    setThumbNail({ img: data.data.you_tube_tumbnail_image, error: false })
    setLoading(false);
    setError(false);
  };
  const onError = (data) => {
    console.log("ERROR");
    setLoading(false);
    setError(true);
  };

  const initialValues = {
    title: howItWorksData?.title ? howItWorksData?.title : "",
    subtitle: howItWorksData?.subtitle ? howItWorksData?.subtitle : "",
    videoUrl: howItWorksData?.video_url ? howItWorksData?.video_url : "",
    videoTitle: howItWorksData?.video_title ? howItWorksData?.video_title : "",
    videoDesc: howItWorksData?.video_description ? howItWorksData?.video_description : "",
    reasonTitle: howItWorksData?.reason_title ? howItWorksData?.reason_title : "",
    reasonSubtitle: howItWorksData?.reason_subtitle ? howItWorksData?.reason_subtitle : "",
    reasons: howItWorksData?.reasons ? howItWorksData?.reasons : [],
    sectionTwoTitle: howItWorksData?.section2_title ? howItWorksData?.section2_title : "",
    sectionTwoSubTitle: howItWorksData?.section2_sub_title ? howItWorksData?.section2_sub_title : "",
    sectionThreeTitle: howItWorksData?.section3_title ? howItWorksData?.section3_title : "",
    sectionThreeButton: howItWorksData?.section3_button ? howItWorksData?.section3_button : "",
    opportunities: howItWorksData?.section3_opportunities ? howItWorksData?.section3_opportunities : [],
    sectionFourTitle: howItWorksData?.section4_title ? howItWorksData?.section4_title : "",
    sectionFourContent: howItWorksData?.section4_content ? howItWorksData?.section4_content : [],
  };

  const interestEditSchema = Yup.object().shape({
    title: Yup.string().trim().required("Required"),
    subtitle: Yup.string().trim().required("Required"),
    videoTitle: Yup.string().trim().required("Required"),
    videoUrl: Yup.string().trim().required("Required"),
    videoDesc: Yup.string().trim().required("Required"),
    reasonTitle: Yup.string().trim().required("Required"),
    reasonSubtitle: Yup.string().trim().required("Required"),
    reasons: Yup.array().min(1, "Atleast One is required").required("Required"),
    sectionTwoTitle: Yup.string().trim().required("Required"),
    sectionTwoSubTitle: Yup.string().trim().required("Required"),
    sectionThreeTitle: Yup.string().trim().required("Required"),
    sectionThreeButton: Yup.string().trim().required("Required"),
    opportunities: Yup.array()
      .min(1, "Atleast One is required")
      .required("Required"),
    sectionFourTitle: Yup.string().trim().required("Required"),
    sectionFourContent: Yup.array()
      .min(1, "Atleast One is required")
      .required("Required"),
  });
  const handleSubmit = () => {
    if (!deskImageLink.img || !mobImageLink.img) {
      if (!deskImageLink.img) {
        setDeskImageLink({ img: "", error: true });
      }
      if (!mobImageLink.img) {
        setMobImageLink({ img: "", error: true });
      }
      if (!thumbNail.img) {
        setThumbNail({ img: "", error: true });
      }
    }
    if (formRef.current) {
      formRef.current.handleSubmit();
    }
  };
  const onSubmit = (values, { resetForm }) => {
    if (mobImageLink.img && deskImageLink.img && thumbNail.img) {
      dispatch(
        editHowItWorksAction(
          params.id,
          {
            title: values.title,
            subtitle: values.subtitle,
            video_url: values.videoUrl,
            video_title: values.videoTitle,
            video_description: values.videoDesc,
            reason_title_number: values.reasons.length + "",
            reason_title: values.reasonTitle,
            reason_subtitle: values.reasonSubtitle,
            you_tube_tumbnail_image: thumbNail.img,
            reasons: values.reasons,
            section2_title: values.sectionTwoTitle,
            section2_sub_title: values.sectionTwoSubTitle,
            section2_image_desktop: deskImageLink.img,
            section2_image_mobile: mobImageLink.img,
            section3_title: values.sectionThreeTitle,
            section3_button: values.sectionThreeButton,
            section3_opportunities: values.opportunities,
            section4_title: values.sectionFourTitle,
            section4_content: values.sectionFourContent
          },
          onEditSuccess,
          onEditError
        )
      );
    }
  };
  const onEditSuccess = (data) => {
    console.log(data);
    // setNotifyMeData(data.data)
    successToast(data.message)
    setSubmitData(data.data.id)
    setSaveHide(true)
    // navigate("/static-pages/how-It-Works")
    setLoading(false)
    setError(false)
  };
  const onEditError = (data) => {
    console.log(data);
    errorToast(data.message)
    setLoading(false)
    setError(true)
  };

  const imageUpload = (e) => {
    if (e.target.name === "deskImage") {
      dispatch(
        uploadAddressProofFrontImageAction(
          e.target.files[0],
          onDeskImageUploadSucess,
          onDeskImageUploadError
        )
      );
    }
    else if (e.target.name === "mobImage") {
      dispatch(
        uploadAddressProofFrontImageAction(
          e.target.files[0],
          onMobImageUploadSucess,
          onMobImageUploadError
        )
      );
    }
    else if (e.target.name === "thumbNail") {
      dispatch(
        uploadAddressProofFrontImageAction(
          e.target.files[0],
          onthumbNailImageUploadSucess,
          onthumbNailImageUploadError
        )
      );
    }
    e.target.value = null;
  };
  const onDeskImageUploadSucess = (data) => {
    setDeskImageLink({ img: data.data.data.location, error: false });
  };
  const onDeskImageUploadError = (data) => {
    setDeskImageLink({ img: "", error: true });
  };
  const onMobImageUploadSucess = (data) => {
    setMobImageLink({ img: data.data.data.location, error: false });
  };
  const onMobImageUploadError = (data) => {
    setMobImageLink({ img: "", error: true });
  };
  const onthumbNailImageUploadSucess = (data) => {
    setThumbNail({ img: data.data.data.location, error: false });
  };
  const onthumbNailImageUploadError = (data) => {
    setThumbNail({ img: "", error: true });
  };


  
   //on submit api
   console.log(submitData);
   const [saveHide, setSaveHide] = useState(false);
 
   const submitFnc = () => {
     dispatch(howItWorksCategorySubmit
       (
         submitData,
         onSubmitSuccess,
         onSubmitError,
       ));
   };
   const onSubmitSuccess = (data) => {
     console.log(data);
     navigate("/static-pages/how-It-Works")
     successToast(data.message)
   };
   const onSubmitError = (data) => {
     console.log("ERROR==================", data);
     errorToast(data.message)
 
   };


  const buttonList = [ !saveHide && <SaveBtn submitFn={handleSubmit}
  loader={loading} />,
<SubmitBtn submitFn={submitFnc} saveHide={saveHide} />];
  return (
    <InnerLayout buttons={buttonList}>
      <div className="user_view_container">
        <InnerContainer>
          <LoadAndError loader={loading} error={error}>
            <Formik
              innerRef={formRef}
              initialValues={initialValues}
              onSubmit={onSubmit}
              enableReinitialize={true}
              validationSchema={interestEditSchema}
            >
              {({ formik, handleChange, values, setFieldValue }) => {
                return (
                  <Form className="px-4 py-3 col-12  col-xxl-10">
                    <div className="d-flex flex-column gap-0 gap-lg-5 flex-lg-row fs_14">
                      <div className="col-12 col-lg-6 pe-0 pe-lg-5">
                        <InputField
                          id={"title"}
                          label={"Title"}
                          type={"text"}
                          placeholder={"Enter Title"}
                          name={"title"}
                        />
                        <label className="mb-1 ms-2" htmlFor="Subtitle">
                          Subtitle
                        </label>
                        <Field name={"subtitle"}>
                          {({ field }) => (
                            <textarea
                              style={{ height: "3.5rem" }}
                              id="Subtitle"
                              {...field}
                              type="text"
                              placeholder="Enter the Meta category description"
                              className={"w-100 mb-0 faq_message_container p-2 "}
                            ></textarea>
                          )}
                        </Field>
                        <ErrorMsg name={"subtitle"} />
                        <InputField
                          id={"Video Title"}
                          label={"Video Title"}
                          type={"text"}
                          placeholder={"Enter Video Title"}
                          name={"videoTitle"}
                        />
                        <InputField
                          id={"Video URL"}
                          label={"Video URL"}
                          type={"text"}
                          placeholder={"Enter URL"}
                          name={"videoUrl"}
                        />
                        <label className="mb-1 ms-2" htmlFor="videoDescription">
                          Video description
                        </label>
                        <Field name={"videoDesc"}>
                          {({ field }) => (
                            <textarea
                              style={{ height: "3.5rem" }}
                              id="videoDescription"
                              {...field}
                              type="text"
                              placeholder="Enter Video description"
                              className={"w-100 mb-0 faq_message_container p-2 "}
                            ></textarea>
                          )}
                        </Field>
                        <ErrorMsg name={"videoDesc"} />
                        <InputField
                          id={"Reason Title"}
                          label={"Reason Title"}
                          type={"text"}
                          placeholder={"Enter URL"}
                          name={"reasonTitle"}
                        />{" "}
                        <label className="mb-1 ms-2" htmlFor="Subtitle">
                          Reason Subtitle
                        </label>
                        <Field name={"reasonSubtitle"}>
                          {({ field }) => (
                            <textarea
                              style={{ height: "3.5rem" }}
                              id="Subtitle"
                              {...field}
                              type="text"
                              placeholder="Enter Subtitl"
                              className={"w-100 mb-0 faq_message_container p-2 "}
                            ></textarea>
                          )}
                        </Field>
                        <ErrorMsg name={"reasonSubtitle"} />
                        <HowItWorksReasonTable
                          setFieldValue={setFieldValue}
                          formikValues={values}
                          name={"reasons"}
                        />
                        <InputField
                          id={"Section 2 Title"}
                          label={"Section 2 Title"}
                          type={"text"}
                          placeholder={"Enter title here"}
                          name={"sectionTwoTitle"}
                        />
                        <label className="mb-1 ms-2" htmlFor="Section 2 Subtitle">
                          Section 2 Subtitle
                        </label>
                        <Field name={"sectionTwoSubTitle"}>
                          {({ field }) => (
                            <textarea
                              style={{ height: "3.5rem" }}
                              id="Section 2 Subtitle"
                              {...field}
                              type="text"
                              placeholder="Enter subtitle here"
                              className={"w-100 mb-0 faq_message_container p-2 "}
                            ></textarea>
                          )}
                        </Field>
                        <ErrorMsg name={"sectionTwoSubTitle"} />
                        <div className="d-flex gap-5 flex-wrap">
                          <div>
                            <span className="">Section 2 DeskTop Image</span>
                            <div className="blog_create_image_container mt-1 position-relative d-flex  justify-content-center align-items-center">
                              <input
                                multiple={false}
                                style={{ visibility: "hidden" }}
                                type="file"
                                id="deskImage"
                                className="upload_document_input"
                                name="deskImage"
                                accept="image/* ,capture=camera"
                                onChange={(e) => {
                                  imageUpload(e);
                                }}
                              />
                              {deskImageLink.img ? (
                                <>
                                  <img
                                    src={deskImageLink.img}
                                    className={"user-edit-profile-picture"}
                                  />
                                  <button
                                    type="button"
                                    onClick={(e) => {
                                      setDeskImageLink({ img: "", error: true });
                                    }}
                                    className="position-absolute border-0 cursor_pointer white_bg d-flex gap-2 py-1 px-2 user_edit_profile_picture_remove_container"
                                  >
                                    <CloseIcon fill="#666666" />
                                    <span className="dark_grey_color fs_13 fn_Montserrat fw_500 ">
                                      Remove
                                    </span>
                                  </button>
                                </>
                              ) : (
                                <label
                                  htmlFor="deskImage"
                                  className="d-flex flex-column align-items-center mt-2 justify-content-center fs_12"
                                >
                                  <UploadIcon width={30} height={30} />
                                  Choose Profile Picture
                                </label>
                              )}
                            </div>
                            {deskImageLink.error && (
                              <span className="fs_12 ms-2 text-danger fw-semibold fn_Nunito">
                                Required!
                              </span>
                            )}
                          </div>
                          <div>
                            <span className="">Section 2 Mob Image</span>
                            <div className="blog_create_image_container mt-1 position-relative d-flex  justify-content-center align-items-center">
                              <input
                                multiple={false}
                                style={{ visibility: "hidden" }}
                                type="file"
                                id="mobImage"
                                className="upload_document_input"
                                name="mobImage"
                                accept="image/* ,capture=camera"
                                onChange={(e) => {
                                  imageUpload(e);
                                }}
                              />
                              {mobImageLink.img ? (
                                <>
                                  <img
                                    src={mobImageLink.img}
                                    className={"user-edit-profile-picture"}
                                  />
                                  <button
                                    type="button"
                                    onClick={(e) => {
                                      setMobImageLink({ img: "", error: true });
                                    }}
                                    className="position-absolute border-0 cursor_pointer white_bg d-flex gap-2 py-1 px-2 user_edit_profile_picture_remove_container"
                                  >
                                    <CloseIcon fill="#666666" />
                                    <span className="dark_grey_color fs_13 fn_Montserrat fw_500 ">
                                      Remove
                                    </span>
                                  </button>
                                </>
                              ) : (
                                <label
                                  htmlFor="mobImage"
                                  className="d-flex flex-column align-items-center mt-2 justify-content-center fs_12"
                                >
                                  <UploadIcon width={30} height={30} />
                                  Choose Profile Picture
                                </label>
                              )}
                            </div>
                            {mobImageLink.error && (
                              <span className="fs_12 ms-2 text-danger fw-semibold fn_Nunito">
                                Required!
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="mt-3">
                          <span className="">Thumb Nail Image</span>
                          <div className="blog_create_image_container mt-1 position-relative d-flex  justify-content-center align-items-center">
                            <input
                              multiple={false}
                              style={{ visibility: "hidden" }}
                              type="file"
                              id="thumbNail"
                              className="upload_document_input"
                              name="thumbNail"
                              accept="image/* ,capture=camera"
                              onChange={(e) => {
                                imageUpload(e);
                              }}
                            />
                            {thumbNail.img ? (
                              <>
                                <img
                                  src={thumbNail.img}
                                  className={"user-edit-profile-picture"}
                                />
                                <button
                                  type="button"
                                  onClick={(e) => {
                                    setThumbNail({ img: "", error: true });
                                  }}
                                  className="position-absolute border-0 cursor_pointer white_bg d-flex gap-2 py-1 px-2 user_edit_profile_picture_remove_container"
                                >
                                  <CloseIcon fill="#666666" />
                                  <span className="dark_grey_color fs_13 fn_Montserrat fw_500 ">
                                    Remove
                                  </span>
                                </button>
                              </>
                            ) : (
                              <label
                                htmlFor="thumbNail"
                                className="d-flex flex-column align-items-center mt-2 justify-content-center fs_12"
                              >
                                <UploadIcon width={30} height={30} />
                                Choose Profile Picture
                              </label>
                            )}
                          </div>
                          {thumbNail.error && (
                            <span className="fs_12 ms-2 text-danger fw-semibold fn_Nunito">
                              Required!
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="col-12 col-lg-6 pe-0 pe-lg-5">
                        <InputField
                          id={"Section 3 Title"}
                          label={"Section 3 Title"}
                          type={"text"}
                          placeholder={"Enter title here"}
                          name={"sectionThreeTitle"}
                        />

                        <InputField
                          id={"Section 3 Button"}
                          label={"Section 3 Button"}
                          type={"text"}
                          placeholder={"Enter button text here"}
                          name={"sectionThreeButton"}
                        />
                        <HowItWorksOpportunityTable
                          setFieldValue={setFieldValue}
                          formikValues={values}
                          name={"opportunities"}
                        />
                        <InputField
                          id={"Section 4 Title"}
                          label={"Section 4 Title"}
                          type={"text"}
                          placeholder={"Enter title here"}
                          name={"sectionFourTitle"}
                        />
                        <HowItWorksContentFourTable
                          setFieldValue={setFieldValue}
                          formikValues={values}
                          name={"sectionFourContent"}
                        />

                        <div>
                          <span className="dark_grey_color">{"Approved At"}</span>
                          <p className="dark_title_color">{howItWorksData?.approvedAt ? howItWorksData?.approvedAt : "-"}</p>
                        </div>
                        <div>
                          <span className="dark_grey_color">{"Updated At"}</span>
                          <p className="dark_title_color">{howItWorksData?.updatedAt ? howItWorksData?.updatedAt : "-"}</p>
                        </div>

                      </div>
                    </div>
                  </Form>
                );
              }}
            </Formik>
          </LoadAndError>
        </InnerContainer>

      </div>
    </InnerLayout>
  );
};

export default HowItWorksEdit;
export const faqName = [
  { value: "Type1", label: "Type1" },
  { value: "Type2", label: "Type2" },
  { value: "Type3", label: "Type3" },
  { value: "Type4", label: "Type4" },
];
