import React, { useState } from "react";
import { useDispatch } from "react-redux";
import ApprovalsBtn from "../../../components/buttons/approvalsBtn";
import CreateBtn from "../../../components/buttons/createBtn";
import ExportBtn from "../../../components/buttons/exportBtn";
import FilterBtn from "../../../components/buttons/filterBtn";
import VersionHistoryBtn from "../../../components/buttons/versionHistoryBtn";
import InnerLayout from "../../../components/layouts/innerLayout/innerLayout";
import UserListTableContent from "../../../components/user/tables/userListTableContent";
import UserFilter from "../../../components/filter/userFilter/userFilter";
import { userTableExport } from "../../../redux/actions/userAction";
const UserlistTable = () => {
  const [page, setPage] = useState(0);
  const [showFilter, setShowFilter] = useState(false);
  const [search, setSearch] = useState("");
  const dispatch = useDispatch()
  const handleFilterClose = () => {
    setShowFilter(false);
  };
  const handleFilterOpen = () => {
    setShowFilter(true);
  };
  const handleSearch = (value) => { setSearch(value); setPage(0) };
  const handlePage = (type) => {
    if (type === "+") {
      setPage((prev) => prev + 1)
    }
    else {
      setPage((prev) => prev - 1)
    }
  }
  const buttonList = [
    <VersionHistoryBtn link="/user/user-list/version-history" />,
    <ApprovalsBtn link="/user/user-list/approvals" />,
    <CreateBtn link="/user/user-list/create" />,
    <ExportBtn dispatchAction={userTableExport} />,
    <FilterBtn filterFn={handleFilterOpen} />,
  ];
  return (
    <InnerLayout
      buttons={    
        buttonList
      }
    >
      <UserFilter
        showFilter={showFilter}
        closeFilter={handleFilterClose}
        filter={handleSearch}
      />
      <UserListTableContent page={page} changePage={handlePage} search={search} />
    </InnerLayout>
  );
};

export default UserlistTable;
