import React, { useState } from "react";
import CreateBtn from "../../../../components/buttons/createBtn";
import ExportBtn from "../../../../components/buttons/exportBtn";
import FilterBtn from "../../../../components/buttons/filterBtn";
import InnerLayout from "../../../../components/layouts/innerLayout/innerLayout";
import AdminUserFilter from "../../../../components/filter/adminUserFilter";
import AdminUserApprovalTableContent from "../../../../components/admin/tables/adminUserApprovalTableContent";
import { exportAdmin } from "../../../../redux/actions/AdminUserAction";
import { useDispatch } from "react-redux";
import FileSaver from 'file-saver';
import OurTeamApprovalTableContent from "../../../../components/static-pages/our-team/tables/ourTeamApprovalTableContent";
import TestimonialsApprovalTableContent from "../../../../components/static-pages/testimonials/tables/testimonialsApprovalTableContent";
import MediaRecogonitionApprovalsTableContent from "../../../../components/static-pages/media-recogonitions/tables/mediaRecogonitionsApprovalsTableContent";
import MediaAndRecFilter from "../../../../components/filter/static-pages filter/mediaAndRecFilter";
import VersionHistoryBtn from "../../../../components/buttons/versionHistoryBtn";
import { mediaRecApprovalsExportAction } from "../../../../redux/actions/mediaRecogonitionActions";

const MediaRecogonitionApprovalsTable = () => {
  const [page, setPage] = useState(0);
  const [showFilter, setShowFilter] = useState(false);
  const [search, setSearch] = useState({ value: "" });
  const dispatch = useDispatch();
  const handleFilterClose = () => {
    setShowFilter(false);
  };
  const handleFilterOpen = () => {
    setShowFilter(true);
  };
  const handleSearch = (value) => {
    setSearch({ value: value });
    setPage(0)
  };
  const handlePage = (type) => {
    if (type === "+") {
      setPage((prev) => prev + 1)
    }
    else {
      setPage((prev) => prev - 1)
    }
  }
  const exportFn = () => {
    dispatch(exportAdmin(
      onSuccess, onError
    ));
  }
  const onSuccess = (data) => {
    console.log("SUCCESS =====================================", data);
  };
  const onError = (data) => {
    console.log("ERROR==================", data);
    var type = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8";
    var blob = new Blob([data], { type: type });
    FileSaver.saveAs(blob, "file.xlsx");
  };
  const buttonList = [
    <VersionHistoryBtn link="/static-pages/media-recogonition/version-history" />,
    <CreateBtn link={`/static-pages/media-recogonition/create`} />,
    <ExportBtn dispatchAction={mediaRecApprovalsExportAction} />,
    <FilterBtn filterFn={handleFilterOpen} />,
  ];

  return (
    <InnerLayout containerWidth="95%" buttons={buttonList}>
      <MediaAndRecFilter
        filter={handleSearch}
        showFilter={showFilter}
        closeFilter={handleFilterClose}
      />
      <MediaRecogonitionApprovalsTableContent page={page} changePage={handlePage} search={search} />
    </InnerLayout>
  );
}
export default MediaRecogonitionApprovalsTable




