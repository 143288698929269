import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import DeleteBtn from "../../../../components/buttons/deleteBtn";
import EditBtn from "../../../../components/buttons/editBtn";
import VersionHistoryBtn from "../../../../components/buttons/versionHistoryBtn";
import InnerContainer from "../../../../components/layouts/innerContainer/innerContainer";
import InnerLayout from "../../../../components/layouts/innerLayout/innerLayout";
import errorToast from "../../../../components/utilits/errorToast";
import { deleteWishlist, viewWishlist } from "../../../../redux/actions/wishlistActions";
import "../wishlist.css";
import moment from "moment";
import successToast from "../../../../components/utilits/successToast";
import LoadingSpinnerTable from "../../../../components/utilits/loadingSpinnerTable";
import LoadAndError from "../../../../components/utilits/LoadAndError";

const WishlistRead = () => {
    const params = useParams()
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [wishlistData, setWishlistData] = useState({ id: "", name: "", createdAt: "", updatedAt: "" })
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(false)
    useEffect(() => {
        setLoading(true)
        dispatch(
            viewWishlist(params.id, onSuccess, onError)

        );
    }, [params.id])
    const onSuccess = (data) => {
        console.log(data.data);
        setWishlistData(data.data);
        setLoading(false)
        setError(false)
    };
    const onError = (data) => {
        errorToast(data.data.message)
        //errorToast("went wrong")
        setLoading(false)
        setError(true)
    };

    //Delete section
    const handleShow = () => {
        console.log("The id", wishlistData.id);
        dispatch(
            deleteWishlist(wishlistData.id, onDeleteSuccess, onDeleteError)
        );
    };

    const onDeleteSuccess = (data) => {
        console.log(data.data);
        setLoading(false);
        successToast(data.message)
        navigate("/wishlist")
        setError(false)
    };
    const onDeleteError = (data) => {
        setLoading(false)
        errorToast(data?.data?.data)
        setError(true)
    };


    const buttonList = [
        <VersionHistoryBtn link={`/opportunity/wishlist/version-history`} />,
        wishlistData?.editable &&
        <EditBtn link={`/opportunity/wishlist/edit/${wishlistData.id}`} />,
        // <DeleteBtn />
    ];
    const data = [
        {
            heading: "Wishlist ID",
            title: wishlistData?.id ? wishlistData?.id : "-",
        },
        {
            heading: "Opportunity ID",
            title: wishlistData?.opportunity_id ? wishlistData?.opportunity_id : "-",

        },
        {
            heading: "Opportunity Name",
            title: wishlistData?.opportunity?.title ? wishlistData?.opportunity?.title : "-",
        },
        {
            heading: "User ID",
            title: wishlistData?.user_id ? wishlistData?.user_id : "-",
        },
        {
            heading: "User Name",
            title: wishlistData?.user?.user_name ? wishlistData?.user?.user_name : "-",
        },

        {
            heading: "Created At",
            title: (wishlistData?.createdAt) ? moment(wishlistData?.createdAt).format("DD-MM-YYYY HH:mm:ss") : "-",
        },

        {
            heading: "Updated At",
            title: (wishlistData?.updatedAt) ? moment(wishlistData?.updatedAt).format("DD-MM-YYYY HH:mm:ss") : "-",
        },
    ];
    return (
        <InnerLayout buttons={buttonList}>
            <div className="wishlist-container">
                <InnerContainer>
                    {loading ? (
                        <LoadingSpinnerTable/>
                    ) : (
          <LoadAndError loader={loading} error={error}>
                    <div className="pt-3  ps-4 ps-lg-4 ms-3">
                        {data.map((item, index) => (
                            <div className="mb-2">
                                <h5 className="dark_grey_color fs_13 fn_Montserrat fw_500 m-0">
                                    {item.heading}
                                </h5>
                                <p
                                    className={` fs_13 fn_Nunito fw_500  ${item.heading === "Opportunity ID" || item.heading === "User ID" ? 'secondary_color' : 'dark_title_color'} `}>
                                    {item.title}
                                </p>
                            </div>
                        ))}
                    </div>
                    </LoadAndError>
                    )}
                </InnerContainer>
            </div>
        </InnerLayout>
    );
};

export default WishlistRead;

