import React, { useEffect, useState } from "react";
import ApprovalsBtn from "../../../../components/buttons/approvalsBtn";
import CreateBtn from "../../../../components/buttons/createBtn";
import ExportBtn from "../../../../components/buttons/exportBtn";
import FilterBtn from "../../../../components/buttons/filterBtn";
import VersionHistoryBtn from "../../../../components/buttons/versionHistoryBtn";
import AdminUserFilter from "../../../../components/filter/adminUserFilter";
import FaqFilter from "../../../../components/filter/static-pages filter/faqFilter";
import InnerLayout from "../../../../components/layouts/innerLayout/innerLayout";
import FaqTableContent from "../../../../components/static-pages/faqs/tables/faqTableContent";
import { faqTableExport } from "../../../../redux/actions/faqAction";

const FaqsTable = () => {
  const [page, setPage] = useState(0);
  const [showFilter, setShowFilter] = useState(false);
  const [search, setSearch] = useState({ value: "" });
  const handleFilterClose = () => {
    setShowFilter(false);     
  };
  const handleFilterOpen = () => {
    setShowFilter(true);
  };
  const handleSearch = (value) => {
    setSearch({ value: value });
    setPage(0)
  };
  const handlePage = (type) => {
    if (type === "+") {
      setPage((prev) => prev + 1)
    }
    else {
      setPage((prev) => prev - 1)
    }                                 
  }
  const buttonList = [
    <ApprovalsBtn link='/static-pages/faqs/approvals' />,
    <VersionHistoryBtn link='/static-pages/faqs/version-history' />,
    <CreateBtn link='/static-pages/faqs/create' />,
    <ExportBtn  dispatchAction={faqTableExport}/>,
    <FilterBtn filterFn={handleFilterOpen} />,
  ];
  return (
    <InnerLayout buttons={buttonList}>
      <FaqFilter
        filter={handleSearch}
        showFilter={showFilter}
        closeFilter={handleFilterClose}
      />
      <FaqTableContent page={page} changePage={handlePage} search={search} />
    </InnerLayout>
  );
};

export default FaqsTable;
 