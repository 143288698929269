import React, { useState, useEffect } from "react";
import ArrowRightIcon from "../../../../assets/icons/arrowrightIcon";
import ApprovalsBtn from "../../../../components/buttons/approvalsBtn";
import RejectBtn from "../../../../components/buttons/rejectBtn";
import InnerContainer from "../../../../components/layouts/innerContainer/innerContainer";
import InnerLayout from "../../../../components/layouts/innerLayout/innerLayout";
import "../wishlist.css";
import successToast from "../../../../components/utilits/successToast";
import errorToast from "../../../../components/utilits/errorToast";
import moment from "moment";
import { useDispatch } from 'react-redux';
import { useParams, useLocation } from 'react-router-dom';
import { viewVersionHistoryById } from "../../../../redux/actions/wishlistActions";
import LoadingSpinnerTable from "../../../../components/utilits/loadingSpinnerTable";
import LoadAndError from "../../../../components/utilits/LoadAndError";

const WishlistVersionHistory = () => {
    const params = useParams();
    const dispatch = useDispatch();
    const location = useLocation();

    const [userVersionHistoryData, setuserVersionHistoryData] = useState({
        id: "",
        createdAt: "",
        updatedAt: "",
        new_data: { updated_by: "", updated_At: "" },
    });
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false)

    useEffect(() => {
        setLoading(true);
        dispatch(
            viewVersionHistoryById(params.id, onSuccess, onError)
        );
    }, []);

    const onSuccess = (data) => {

        setuserVersionHistoryData(data?.data?.data);
        setLoading(false);
        setError(false)
    };

    const onError = (data) => {
        errorToast(data.message);
        setLoading(false);
        setError(true)
    };

    console.log("The data", userVersionHistoryData);

    //The assignment of data
    const data = [
        {
            heading: "User ID",
            title: userVersionHistoryData?.id ? userVersionHistoryData?.id : "-",
        },
        {
            heading: "Created At",
            title: moment(userVersionHistoryData?.createdAt).format(
                "DD-MM-YYYY HH:mm:ss"
            ) ? moment(userVersionHistoryData?.createdAt).format(
                "DD-MM-YYYY HH:mm:ss"
            ) : "-"
        },
        {
            heading: "Updated At",
            title: moment(userVersionHistoryData?.updatedAt).format("DD-MM-YYYY HH:mm:ss") ? moment(userVersionHistoryData?.updatedAt).format("DD-MM-YYYY HH:mm:ss") : "-"
        },
        {
            heading: "Updated By",
            title: userVersionHistoryData?.updated_by ? userVersionHistoryData?.updated_by : "-",
        },
        {
            heading: "Approved By",
            title: userVersionHistoryData?.approved_by ? userVersionHistoryData?.approved_by : "-",
        },
        {
            heading: "Approved Date",
            title: moment(userVersionHistoryData?.approved_date).format("DD-MM-YYYY HH:mm:ss") ? moment(userVersionHistoryData?.approved_date).format("DD-MM-YYYY HH:mm:ss") : "-",
        },
    ];

   
    const olddata = [
        {
            heading: "Opportunity Id",
            title: userVersionHistoryData?.old_data?.opportunity_id ? userVersionHistoryData?.old_data?.opportunity_id : "-"
        },
        {
            heading: "Opportunity Name",
            title: userVersionHistoryData?.old_data?.title ? userVersionHistoryData?.old_data?.title : "-"
        },
        {
            heading: "user Id",
            title: userVersionHistoryData?.old_data?.user_id ? userVersionHistoryData?.old_data?.user_id : "-"
        },
        {
            heading: "user Name",
            title: userVersionHistoryData?.old_data?.user_name ? userVersionHistoryData?.old_data?.user_name : "-"
        },

    ]
    const newdata = [
        {
            heading: "Opportunity Id",
            title: userVersionHistoryData?.new_data?.opportunity_id ? userVersionHistoryData?.new_data?.opportunity_id : "-"
        },
        {
            heading: "Opportunity Name",
            title: userVersionHistoryData?.new_data?.title ? userVersionHistoryData?.new_data?.title : "-"
        },
        {
            heading: "user Id",
            title: userVersionHistoryData?.new_data?.user_id ? userVersionHistoryData?.new_data?.user_id : "-"
        },
        {
            heading: "user Name",
            title: userVersionHistoryData?.new_data?.user_name ? userVersionHistoryData?.new_data?.user_name : "-"
        },

    ]




    // const buttonList = [
    //     <ApprovalsBtn />,
    //     <RejectBtn />
    // ];
    return (
        <InnerLayout
        //  buttons={buttonList}
        >
            <div className="wishlist-container">
                <InnerContainer>
                    {loading ? (
                        <LoadingSpinnerTable />
                    ) : (
                        <LoadAndError loader={loading} error={error}>
                            <div className='pt-3 ps-5 row'>
                                {data.map((item, index) => (
                                    <div className="mb-2  col-sm-4 col-md-3  col-xl-2 col-lg-3">
                                        <h5 className="dark_grey_color  fs_13 fn_Montserrat fw_500 m-0">
                                            {item.heading}
                                        </h5>
                                        <p
                                            className={` fs_13 fn_Nunito  fw_500 ${item.heading === "Notify Me ID"
                                                ? "secondary_color"
                                                : "dark_title_color"
                                                }`}
                                        >
                                            {item.title}
                                        </p>
                                    </div>
                                ))}
                            </div>
                            <div className='white_shade_bg  py-1 d-flex'>
                                <div className='w-50'>
                                    <h5 className='fw_500 ps-4 dark_grey_color pt-2 text-nowrap fs_16 fn_Montserrat'>JSON Old Data</h5>
                                </div>
                                <div className='w-50  d-none d-lg-block'>
                                    <h5 className='fw_500 ps-4  dark_grey_color pt-2 text-nowrap fs_16 fn_Montserrat'>JSON New Data</h5>
                                </div>
                            </div>
                            <div className='row mt-3 ps-0 ps-lg-4'>
                                <div className='col-12 col-lg-6'>
                                    <div className='wishlist_aprvls_vrsn_hstry_data_container  mt-3 pt-3 px-3'>
                                        <div className="d-flex justify-content-between">
                                            <h5 className='fs_13 fn_Nunito fw_400 dark_title_color text-nowrap'>Old Data</h5>
                                            <ArrowRightIcon width={25} height={25} fill={"#A5A5A5"} />
                                        </div>
                                        {
                                            userVersionHistoryData.old_data === null ? (
                                                <div>Empty data</div>
                                            ) : (<>
                                                {
                                                    olddata.map((item, index) => {
                                                        return (
                                                            <div className='d-flex flex-column'>
                                                                <h5 className='dark_grey_color  fs_13 fn_Montserrat fw_500 m-0'>{item.heading}</h5>
                                                                <p className={` fs_13 fn_Nunito  fw_500 ${item.heading === "Notify Me ID" ? 'secondary_color' : 'dark_title_color'}`}>{item.title}</p>
                                                            </div>
                                                        )
                                                    })

                                                }
                                            </>)
                                        }
                                    </div>
                                </div>
                                <div className='col-12 mt-5 mt-lg-0 col-lg-6'>
                                    <h5 className='fw_500 ps-4 d-block d-lg-none white_shade_bg  dark_grey_color py-3 text-nowrap fs_16 fn_Montserrat'>JSON New Data</h5>
                                    <div className='wishlist_aprvls_vrsn_hstry_data_container    mt-4 mt-lg-3 pt-3 px-3'>
                                        <div className="d-flex justify-content-between">
                                            <h5 className='fs_13 fn_Nunito fw_400 dark_title_color py-2 text-nowrap'>New Data</h5>
                                            <ArrowRightIcon width={25} height={25} fill={"#A5A5A5"} />
                                        </div>
                                        {
                                            userVersionHistoryData.new_data === null ? (
                                                <div>Empty data</div>
                                            ) : (<>
                                                {
                                                    newdata.map((item, index) => {
                                                        return (
                                                            <div className='d-flex flex-column'>
                                                                <h5 className='dark_grey_color  fs_13 fn_Montserrat fw_500 m-0'>{item.heading}</h5>
                                                                <p className={` fs_13 fn_Nunito  fw_500 ${item.heading === "Phone Number" ? 'secondary_color' : 'dark_title_color'}`}>{item.title}</p>
                                                            </div>
                                                        )
                                                    })

                                                }</>)}
                                    </div>

                                </div>
                            </div>
                        </LoadAndError>
                    )}
                </InnerContainer>
            </div>
        </InnerLayout>
    );
};

export default WishlistVersionHistory;
const data = [
    {
        heading: "Wishlist ID",
        title: "0123asdf456jkl7w"
    },
    {
        heading: "Created At",
        title: "06/09/2022 13:00:00"
    },
    {
        heading: "Updated At",
        title: "06/09/2022 13:00:00"
    },
    {
        heading: "Updated By",
        title: "Lorem ipsum Dolor"
    },

]




