import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import React, { useEffect, useRef, useState } from "react";
import InputField from "../../../../components/form/inputFields/inputField";
import SelectFields from "../../../../components/form/selectFields/selectFields";
import InnerContainer from "../../../../components/layouts/innerContainer/innerContainer";
import InnerLayout from "../../../../components/layouts/innerLayout/innerLayout";
import PreviewBtn from "../../../../components/buttons/previewBtn";
import SaveBtn from "../../../../components/buttons/saveBtn";
import DeleteBtn from "../../../../components/buttons/deleteBtn";
import ArrowRightIcon from "../../../../assets/icons/arrowrightIcon";
import "../our-partners.css";
import ErrorMsg from "../../../../components/form/errorMsg";
import CloseIcon from "../../../../assets/icons/closeIcon";
import UploadIcon from "../../../../assets/icons/upload";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { editPartnerAction, partnersSubmit, viewParnterAction } from "../../../../redux/actions/ourParnterActions";
import errorToast from "../../../../components/utilits/errorToast";
import { uploadAddressProofFrontImageAction, uploadProfileImageAction } from "../../../../redux/actions/userAction";
import successToast from "../../../../components/utilits/successToast";
import LoadingSpinnerTable from "../../../../components/utilits/loadingSpinnerTable";
import LoadAndError from "../../../../components/utilits/LoadAndError";
import SubmitBtn from "../../../../components/buttons/submit";

const OurPartnersEdit = () => {
  const [submitData, setSubmitData] = useState();
  const navigate = useNavigate();
  const [file, setFile] = useState(null);
  const [updated, setUpdated] = useState(false);
  const [image, setImage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const params = useParams()
  const formRef = useRef();
  const dispatch = useDispatch();
  const [partnerData, setPartnerData] = useState({
    name: "",
    designation: "", description: "",

  })
  const handleSubmit = () => {
    if (!deskImageLink.img) {
      if (!deskImageLink.img) {
        setDeskImageLink({ img: "", error: true });
      }
      if (!mobImageLink.img) {
        setMobImageLink({ img: "", error: true });
      }
    }
    if (formRef.current) {
      formRef.current.handleSubmit();
    }
  };

  useEffect(() => {
    setLoading(true);
    dispatch(viewParnterAction(params.id, onSuccess, onError)
    );
  }, [params.id]);
  const onSuccess = (data) => {
    setPartnerData(data?.data);
    setLoading(false);
    console.log(data.data);
    setDeskImageLink({ img: data?.data?.photo_url, error: false })
    setMobImageLink({ img: data.data.website_icon, error: false })
    setError(false)
  };
  const onError = (data) => {
    errorToast(data.data.data);
    setLoading(false);
    setError(true)
  };
  const designation = [
    { value: "Project Manager", label: "Project Manager" },
    { value: "Manager", label: "Manager" },
    { value: "Advisor", label: "Advisor" },
  ];
  const priority = [
    { value: 1, label: 1 },
    { value: 2, label: 2 },
    { value: 3, label: 3 },
    { value: 4, label: 4 },
    { value: 5, label: 5 },
  ];

  const initialValues = {
    Name: partnerData?.name,
    Designation: partnerData?.designation,
    website_url: partnerData.website_url,
    Description: partnerData?.description,
    Prirority: partnerData?.priority,
  };
  const loginSchema = Yup.object().shape({
    Name: Yup.string().trim().required("Required"),
    Designation: Yup.string().trim().required("Required"),
    website_url: Yup.string().trim().required("Required"),
    Description: Yup.string().trim().required("Required"),
    Prirority: Yup.number().positive("Priority should be greater than  zero").required("Required"),
  });
  const onSubmit = (values, { resetForm }) => {
    if (mobImageLink.img && deskImageLink.img)
      dispatch(
        editPartnerAction(
          params.id,
          {
            name: values.Name,
            website_url: values.website_url,
            website_icon: mobImageLink.img,
            description: values.Description,
            designation: values.Designation,
            photo_url: deskImageLink.img,
            priority: values.Prirority,
          },
          onEditSuccess,
          onEditError)
      );

  };
  function onEditSuccess(data) {
    console.log(data);
    successToast(data.message)
    setUpdated(!updated)
    setSubmitData(data?.data?.id)
    setSaveHide(true)
    setLoading(false)
    setError(false)
  }

  function onEditError(data) {
    console.log(data.message);

    setUpdated(!updated)
    setLoading(false)
    setError(true)
  }
  function handleImageChange(e, param1) {
    console.log(param1);
    if (e.target?.files.length !== 0) {
      setFile(URL.createObjectURL(e.target.files[0]));
      dispatch(
        uploadProfileImageAction(
          e.target.files[0],
          onProfileUploadImageSuccess,
          onProfileUploadImageError
        )
      );
      e.target.value = null;
    }
  }
  const onProfileUploadImageSuccess = (data) => {
    console.log(data.data.data);
    setImage(data.data.data.location);
  };
  const onProfileUploadImageError = (data) => {
    console.log(data);
  };

  //image
  const [deskImageLink, setDeskImageLink] = useState({
    img: "",
    error: false,
  });
  const [mobImageLink, setMobImageLink] = useState({
    img: "",
    error: false,
  });
  const imageUpload = (e) => {
    if (e.target.name === "deskImage") {
      dispatch(
        uploadAddressProofFrontImageAction(
          e.target.files[0],
          onDeskImageUploadSucess,
          onDeskImageUploadError
        )
      );
    } else if (e.target.name === "mobImage") {
      dispatch(
        uploadAddressProofFrontImageAction(
          e.target.files[0],
          onMobImageUploadSucess,
          onMobImageUploadError
        )
      );
    }
    e.target.value = null;
  };
  const onDeskImageUploadSucess = (data) => {
    setDeskImageLink({ img: data.data.data.location, error: false });
  };
  const onDeskImageUploadError = (data) => {
    setDeskImageLink({ img: "", error: true });
  };
  const onMobImageUploadSucess = (data) => {
    setMobImageLink({ img: data.data.data.location, error: false });
  };
  const onMobImageUploadError = (data) => {
    setMobImageLink({ img: "", error: true });
  };



  //on submit api
  console.log(submitData);
  const [saveHide, setSaveHide] = useState(false);

  const submitFnc = () => {
    dispatch(partnersSubmit
      (
        submitData,
        onSubmitsSuccess,
        onSubmitsError,
      ));
  };
  const onSubmitsSuccess = (data) => {
    console.log(data);
    successToast(data.message)
    navigate("/static-pages/our-partners")
  };
  const onSubmitsError = (data) => {
    console.log("ERROR==================", data);
    errorToast(data.message)

  };
  const buttonList = [
    !saveHide && <SaveBtn submitFn={handleSubmit}
      loader={loading} />,
    <SubmitBtn submitFn={submitFnc} saveHide={saveHide} />];
  return (
    <InnerLayout buttons={buttonList}>
      <div className="our-partners-container">
        <InnerContainer>
          {loading ? (
            <LoadingSpinnerTable />
          ) : (
            <LoadAndError loader={loading} error={error}>
              <div className="pt-3  ps-4 ps-lg-4 ms-4">
                <h5 className="dark_grey_color fs_13 fn_Montserrat fw_500 m-0">
                  Partners ID
                </h5>
                <p className={` fs_13 fn_Nunito fw_500 dark_title_color  `}>
                  {partnerData?.id}
                </p>
              </div>
              <div className="  ps-4 ps-lg-4 ms-3">
                <Formik
                  enableReinitialize={true}
                  innerRef={formRef}
                  initialValues={initialValues}
                  onSubmit={onSubmit}
                  validationSchema={loginSchema}
                >
                  {({ formik, handleChange, values, setFieldValue }) => {
                    return (
                      <Form className="">
                        <div className="our-partners-form-width">
                          <div className="row">
                            <div className="col-12 col-md-6">
                              <div className="mb-3">
                                <span className="">Profile Picture</span>
                                <div className="blog_create_image_container mt-1 position-relative d-flex  justify-content-center align-items-center">
                                  <input
                                    multiple={false}
                                    style={{ visibility: "hidden" }}
                                    type="file"
                                    id="deskImage"
                                    className="upload_document_input"
                                    name="deskImage"
                                    accept="image/* ,capture=camera"
                                    onChange={(e) => {
                                      imageUpload(e);
                                    }}
                                  />
                                  {deskImageLink.img ? (
                                    <>
                                      <img
                                        src={deskImageLink.img}
                                        className={"user-edit-profile-picture"}
                                      />
                                      <button
                                        type="button"
                                        onClick={(e) => {
                                          setDeskImageLink({ img: "", error: true });
                                        }}
                                        className="position-absolute border-0 cursor_pointer white_bg d-flex gap-2 py-1 px-2 user_edit_profile_picture_remove_container"
                                      >
                                        <CloseIcon fill="#666666" />
                                        <span className="dark_grey_color fs_13 fn_Montserrat fw_500 ">
                                          Remove
                                        </span>
                                      </button>
                                    </>
                                  ) : (
                                    <label
                                      htmlFor="deskImage"
                                      className="d-flex flex-column align-items-center mt-2 justify-content-center fs_12"
                                    >
                                      <UploadIcon width={30} height={30} />
                                      Choose Profile Picture
                                    </label>
                                  )}
                                </div>
                                {deskImageLink.error && (
                                  <span className="fs_12 ms-2 text-danger fw-semibold fn_Nunito">
                                    Required!
                                  </span>
                                )}
                              </div>
                              <div className="mb-3">
                                <span className="">Website Logo</span>
                                <div className="blog_create_image_container mt-2 position-relative d-flex  justify-content-center align-items-center">
                                  <input
                                    multiple={false}
                                    style={{ visibility: "hidden" }}
                                    type="file"
                                    id="mobImage"
                                    className="upload_document_input"
                                    name="mobImage"
                                    accept="image/* ,capture=camera"
                                    onChange={(e) => {
                                      imageUpload(e);
                                    }}
                                  />
                                  {mobImageLink.img ? (
                                    <>
                                      <img
                                        src={mobImageLink.img}
                                        className={"user-edit-profile-picture"}
                                      />
                                      <button
                                        type="button"
                                        onClick={(e) => {
                                          setMobImageLink({ img: "", error: true });
                                        }}
                                        className="position-absolute border-0 cursor_pointer white_bg d-flex gap-2 py-1 px-2 user_edit_profile_picture_remove_container"
                                      >
                                        <CloseIcon fill="#666666" />
                                        <span className="dark_grey_color fs_13 fn_Montserrat fw_500 ">
                                          Remove
                                        </span>
                                      </button>
                                    </>
                                  ) : (
                                    <label
                                      htmlFor="mobImage"
                                      className="d-flex flex-column align-items-center mt-2 justify-content-center fs_12"
                                    >
                                      <UploadIcon width={30} height={30} />
                                      Choose Profile Picture
                                    </label>
                                  )}
                                </div>
                                {mobImageLink.error && (
                                  <span className="fs_12 ms-2 text-danger fw-semibold fn_Nunito">
                                    Required!
                                  </span>
                                )}
                              </div>
                              <InputField
                                id={"Name"}
                                label={"Name"}
                                type={"text"}
                                placeholder={"Enter Name"}
                                name={"Name"}
                              />
                              <InputField
                                id={"Designation"}
                                label={"Designation"}
                                type={"text"}
                                placeholder={"Designation text here"}
                                name={"Designation"}
                              />

                              <InputField
                                id={"website_url"}
                                label={"website_url URL"}
                                type={"text"}
                                placeholder={"website_url URL"}
                                name={"website_url"}
                              />
                            </div>
                            <div className="ps-0 ps-lg-4 ms-0 ms-lg-2 user-form-width">

                              <InputField
                                id={"Prirority"}
                                label={"Prirority"}
                                type={"text"}
                                placeholder={"Select priority"}
                                name={"Prirority"}
                              />
                              <label
                                id="Description"
                                className="mb-2 dark_grey_color fn_Nunito mt-2 fw_500 fs_15 ps-1 ms-1"
                              >
                                Description
                              </label>
                              <Field name={"Description"}>
                                {({ field }) => (
                                  <textarea
                                    id="Description"
                                    {...field}
                                    placeholder={"Enter Description"}
                                    type="text"
                                    rows="4"
                                    className="w-100 llp_message_container p-2 "
                                  ></textarea>
                                )}
                              </Field>
                              <ErrorMsg name={"Description"} />
                            </div>
                          </div>
                        </div>
                      </Form>
                    );
                  }}
                </Formik>
              </div>
            </LoadAndError>
          )}
        </InnerContainer>
      </div>
    </InnerLayout>
  );
};

export default OurPartnersEdit;
