import React, { useEffect, useState } from 'react'
import { Link } from "react-router-dom";
import { TableContainer } from "../../../table/tableContainer";
import {

  tableFaqCategoryHeader,
} from "../../../table/tableData";
import TablePagination from "../../../table/tablePagination";
import moment from 'moment';
import { useDispatch } from 'react-redux';
import errorToast from '../../../utilits/errorToast';
import successToast from '../../../utilits/successToast';
import { deleteCategory, viewCategoryList } from '../../../../redux/actions/faqCategoryActions';
import DeleteModal from "../../../modals/deleteModal";
import usePermissionChecker from '../../../utilits/usePermissionChecker';
import LoadAndError from '../../../utilits/LoadAndError';
import LoadingSpinnerTable from '../../../utilits/loadingSpinnerTable';

const FaqCategoryTableContent = ({
  tableContainerClassName = "adminuser_table__container",
  page, search, changePage,
  containerWidth = "90%",
  containerMargin = "1.5rem 0rem",
}) => {
  const access = usePermissionChecker("maker");
  const [userListData, setUserListData] = useState([]);
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);
  const [show, setShow] = useState(false);
  const [deleteData, setDeleteData] = useState({ name: "", id: "" });
  const [loadingTable, setLoadingTable] = useState(false);
  const [pageData, setPageData] = useState({ noOfItems: 0, noOfPages: 1 })
  const [loadingDelete, setLoadingDelete] = useState(false);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {
    setLoadingTable(true);
    dispatch(viewCategoryList(
      {
        search: {
          faq_category_id: search?.value?.faqCategoryId,
          name: search?.value?.name,
        }
      },
      page,
      onSuccess,
      onError));
  }, [search, page, loading]);

  const onSuccess = (data) => {
    setUserListData(data?.data);
    console.log("The", data?.data);
    setPageData({
      noOfItems: data.count,
      noOfPages: data.pages
    })
    setLoadingTable(false);
    setError(false)
    setLoading(false)
  };
  const onError = (data) => {
    console.log(data);
    errorToast(data.message);
    setLoadingTable(false);
    setError(false)
    setLoading(false)
  };


  const handleDelete = () => {
    setLoadingDelete(true);
    dispatch(
      deleteCategory(
        deleteData.id,
        onDeleteSuccess,
        onDeleteError
      )
    );
  };
  const onDeleteSuccess = (data) => {
    successToast(data.message);
    setLoadingDelete(false);
    setShow(false);
    setError(false)
    setLoading(false)
  };
  const onDeleteError = (data) => {
    errorToast(data.message);
    setLoadingDelete(false);
    setError(false)
    setLoading(false)
  };

  function handlePagination(type) {
    if (type === "+") {
      if (page + 1 < pageData.noOfPages) changePage("+");
    }
    else if (type === "-")
      if (page > 0) changePage("-");
  }

  return (
    <TableContainer width={containerWidth} margin={containerMargin}>
      <div className={`${tableContainerClassName}`}>
        {loading && <LoadingSpinnerTable />}
        <table className={"adminuser_table"}>
          <thead className="adminuser_table__header">
            <tr className="adminuser_table__row">
              {tableFaqCategoryHeader.map((entry) => {
                return (
                  <th
                    key={entry}
                    className={`${entry === "Status" ? `${access ? "" : "text-start"} position-sticky end-0 text-start` : null
                      } text-nowrap adminuser_table__heading `}
                  >
                    {entry}
                  </th>
                );
              })}
            </tr>
          </thead>
          {loading == false && (
            <tbody className="adminuser_table__body">
              <LoadAndError loader={loading} error={error} status={userListData.length === 0}>
                {userListData.map((item, index) => {
                  return (
                    <tr key={item.id} className="adminuser_table__row">
                      <td className="text-nowrap dark_title_color adminuser_table__data ">
                        {page > 0 ? 12 * page + (index + 1) : index + 1}
                      </td>
                      <td className="text-nowrap dark_title_color adminuser_table__data">
                        {item?.id == null ? "--" : item?.id}
                      </td>
                      <td className="text-nowrap secondary_color adminuser_table__data">
                        {item?.name == null ? "--" : item?.name}
                      </td>
                      <td className="text-nowrap  dark_title_color adminuser_table__data">
                        {item?.createdAt == null ? "--" : moment(item?.createdAt).format("DD-MM-YYYY HH:mm:ss")}
                      </td>
                      <td className="text-nowrap  dark_title_color adminuser_table__data">
                        {item?.updatedAt == null ? "--" : moment(item?.updatedAt).format("DD-MM-YYYY HH:mm:ss")}
                      </td>
                      <td className="text-nowrap  position-sticky end-0 adminuser_table__data">
                        <div className="d-flex w-100 justify-content-start  gap-2">
                          <Link
                            className="text-decoration-none primary_color"
                            to={{
                              pathname: `/static-pages/faq-category/view/${item.id}`,
                            }}
                          >
                            View
                          </Link>
                          {
                            access && item.editable && <>

                              <span className="vr"></span>
                              <Link
                                className="text-decoration-none secondary_color"
                                to={{
                                  pathname: `/static-pages/faq-category/edit/${item.id}`,
                                }}
                              >
                                Edit
                              </Link>{" "}
                              <span className="vr"></span>{" "}
                              {item.lastActionApproved && (
                                    <>
                              <button
                                onClick={() => {
                                  setDeleteData({
                                    name: item.name,
                                    id: item?.id,
                                  });
                                  handleShow();
                                }}
                                className="text-decoration-none border-0 bg-transparent primary_color light_red_color"
                              >
                                Delete
                              </button>

                            </>)}
                            </>
                          }
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </LoadAndError>
            </tbody>
          )}
        </table>
      </div>
      <DeleteModal
        loader={loadingDelete}
        handleClose={handleClose}
        deleteFn={handleDelete}
        data={deleteData}
        show={show}
      />
      <TablePagination
        paginate={handlePagination}
        currentPage={page}
        pageData={pageData}
      />
    </TableContainer>
  );
};

export default FaqCategoryTableContent;
