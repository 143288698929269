import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { TableContainer } from "../../../table/tableContainer";
import TablePagination from "../../../table/tablePagination";
import { tableMainOpportunitiesHeader, tableOpportunitiesHeader } from "../../../table/tableData";
import errorToast from "../../../utilits/errorToast";
import { useDispatch } from "react-redux";
import {
  deleteOpportunity,
  fetchOpportunitiesList,
} from "../../../../redux/actions/opportunitiesActions";
import moment from "moment";
import LoadingSpinnerTable from "../../../utilits/loadingSpinnerTable";
import LoadAndError from "../../../utilits/LoadAndError";
import usePermissionChecker from "../../../utilits/usePermissionChecker";
import DeleteModal from "../../../modals/deleteModal";
import successToast from "../../../utilits/successToast";
const OpportunitiesTableContent = ({ page, search, changePage }) => {
  const dispatch = useDispatch();
  const [opportunitiesData, setOpportunitiesData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [error, setError] = useState(false);
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);
  const [pageData, setPageData] = useState({ noOfItems: 0, noOfPages: 1 });
  const [deleteData, setDeleteData] = useState({ name: "", id: "" });
  const [show, setShow] = useState(false);
  const access = usePermissionChecker("maker");
  useEffect(() => {
    setLoading(true);
    dispatch(
      fetchOpportunitiesList(
        {
          search: {
            opportunities_id: search.opportunityId,
            opportunities_name: search.oppName,
          },
        },
        page,
        onSuccess,
        onError
      )
    );
  }, [search, page, deleteLoading]);
  const onSuccess = (data) => {
    setOpportunitiesData(data.data.data);
    setPageData({
      noOfItems: data.data.count,
      noOfPages: data.data.pages,
    });
    setError(false);
    setLoading(false);
  };
  const onError = (data) => {
    errorToast(data.message);
    setError(true)
    setLoading(false);
  };
  const handleDelete = () => {
    setDeleteLoading(true);
    dispatch(deleteOpportunity(deleteData.id, onDeleteSuccess, onDeleteError));
  };
  const onDeleteSuccess = (data) => {
    successToast(data.message);
    setDeleteLoading(false);
    setShow(false);
  };
  const onDeleteError = (data) => {
    errorToast(data.message);
    setDeleteLoading(false);
  };
  function handlePagination(type) {
    if (type === "+") {
      if (page + 1 < pageData.noOfPages) changePage("+");
    } else if (type === "-") if (page > 0) changePage("-");
  }

  return (
    <TableContainer width="90%">
      <div className="adminuser_table__container">
        {loading && <LoadingSpinnerTable />}
        <table className={"adminuser_table"}>
          <thead className="adminuser_table__header">
            <tr className="adminuser_table__row">
              {tableMainOpportunitiesHeader.map((item) => {
                return (
                  <th
                    key={item}
                    className={`${item === "Status" ? " position-sticky end-0" : null
                      } text-nowrap adminuser_table__heading`}
                  >
                    {item}
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody className="adminuser_table__body">
            <LoadAndError
              loader={loading}
              error={error}
              status={opportunitiesData.length === 0}
            >
              {opportunitiesData.map((item, index) => {
                return (
                  <tr key={item.id} className="adminuser_table__row">
                    <td className="text-nowrap dark_title_color adminuser_table__data ">
                      {page > 0 ? 12 * page + (index + 1) : index + 1}
                    </td>
                    <td className="text-nowrap dark_title_color adminuser_table__data">
                      {item?.id}
                    </td>
                    <td
                      itle={item?.title}
                      className="text-nowrap secondary_color  adminuser_table__data"
                    >
                      {item?.opportunity_type?.name}
                    </td>
                    <td className="text-nowrap adminuser_table_ellipsis dark_title_color adminuser_table__data">
                      {item?.title}
                    </td>
                    <td className="text-nowrapy  dark_title_color adminuser_table__data">
                      {item?.status}
                    </td>
                    <td className="text-nowrap  dark_title_color adminuser_table__data">
                      {item?.total_funding_amount}
                    </td>
                    <td className="text-nowrap  dark_title_color adminuser_table__data">
                      {item?.total_funded_amount}
                    </td>

                    <td className="text-nowrap  dark_title_color adminuser_table__data">
                      {moment(item?.createdAt).format("DD/MM/YYYY HH:mm:ss")}
                    </td>
                    <td className="text-nowrap  dark_title_color adminuser_table__data">
                      {moment(item?.updatedAt).format("DD/MM/YYYY HH:mm:ss")}
                    </td>
                    <td className="text-nowrap  dark_title_color adminuser_table__data">
                      {moment(item?.available_from_date).format(
                        "DD/MM/YYYY HH:mm:ss"
                      )}
                    </td>
                    <td className="text-nowrap  dark_title_color adminuser_table__data">
                      {moment(item?.available_till_date).format(
                        "DD/MM/YYYY HH:mm:ss"
                      )}
                    </td>

                    <td className="text-nowrap  position-sticky end-0 adminuser_table__data">
                      {
                        <span className="d-flex w-100   gap-2">
                          <Link
                            className="text-decoration-none primary_color "
                            to={{
                              pathname: `/opportunity/opportunities/view/${item.id}`,
                            }}
                          >
                            View
                          </Link>{" "}
                          {access && item?.editable && (
                            <>
                              <span className="vr"></span>
                              <Link
                                className="text-decoration-none secondary_color"
                                to={{
                                  pathname: `/opportunity/opportunities/edit/${item.id}`,
                                }}
                              >
                                Edit
                              </Link>{" "}
                              {item?.lastActionApproved &&
                                <>
                                  <span className="vr"></span>
                                  <Link
                                    onClick={() => {
                                      setDeleteData({
                                        name: item.title,
                                        id: item.id,
                                      });
                                      handleShow();
                                    }}
                                    className="text-decoration-none primary_color light_red_color"
                                  >
                                    Delete
                                  </Link></>}
                            </>
                          )}
                        </span>
                      }
                    </td>
                  </tr>
                );
              })}
            </LoadAndError>
          </tbody>
        </table>
      </div>
      <DeleteModal
        loader={loading}
        handleClose={handleClose}
        deleteFn={handleDelete}
        data={deleteData}
        show={show}
      />
      <TablePagination
        paginate={handlePagination}
        currentPage={page}
        pageData={pageData}
      />
    </TableContainer>
  );
};

export default OpportunitiesTableContent;
