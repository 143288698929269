import { authHeader } from "../config";
import { api } from "./api";

export const deleteMemberTradeApi = (id, params) => {
    return api.post(`/api/admin/dashboard/membertradenotify/delete-member-notifyme/${id}`, JSON.stringify(params), {
        headers: {
            "x-access-token": localStorage.getItem("accessToken"),
            "Content-Type": "application/json",
        },
    });
};                          

export const viewAllMemberTradeApi = (pageNo = 0, params) => {
    return api.post(`/api/admin/dashboard/membertradenotify/view-member-notifyme-approvals?page_no=${pageNo}`, JSON.stringify(params), {
        headers: {
            "x-access-token": localStorage.getItem("accessToken"),
            "Content-Type": "application/json",
        },
    });
};
export const readMemberTradeApi = (id = "", params) => {
    return api.post(`/api/admin/dashboard/membertradenotify/view-member-notifyme/${id}`, JSON.stringify(params), {
        headers: {
            "x-access-token": localStorage.getItem("accessToken"),
            "Content-Type": "application/json",
        },
    });
};
export const readApprovalMemberTradeApi = (id = "", params) => {
    return api.post(`/api/admin/dashboard/membertradenotify/view-checker-member-notifyme/${id}`, JSON.stringify(params), {
        headers: {
            "x-access-token": localStorage.getItem("accessToken"),
            "Content-Type": "application/json",
        },
    });
};

export const versionTableMemberTradeApi = (pageNo = 0, params) => {
    return api.post(`/api/admin/dashboard/membertradenotify/view-version-history-member-notifyme?page_no=${pageNo}`, JSON.stringify(params), {
        headers: {
            "x-access-token": localStorage.getItem("accessToken"),
            "Content-Type": "application/json",
        },
    });
};
export const memberTradeVersionHistoryViewApi = (id, params) => {
    return api.post(`/api/admin/dashboard/membertradenotify/view-version-history-member-notifyme-findone/${id}`, JSON.stringify(params), {
        headers: {
            "x-access-token": localStorage.getItem("accessToken"),
            "Content-Type": "application/json",
        },
    });
};

export const memberTradeApprovalsTableApi = (pageNo = 0, params) => {
    return api.post(`/api/admin/dashboard/membertradenotify/view-member-notifyme-approvals-checker?page_no=${pageNo}`, JSON.stringify(params), {
        headers: {
            "x-access-token": localStorage.getItem("accessToken"),
            "Content-Type": "application/json",
        },
    });
};

export const memberTradeCreateApi = (params) => {
    return api.post("/api/admin/dashboard/membertradenotify/create-member-notify", JSON.stringify(params), {
        headers: {
            "x-access-token": localStorage.getItem("accessToken"),
            "Content-Type": "application/json",
        },
    });
};

export const memberTradeCreateOptionsApi = (params) => {
    return api.post(`/api/opportunity-notify/create-NotifyAll`, JSON.stringify(params), {
        headers: {
            "x-access-token": localStorage.getItem("accessToken"),
            "Content-Type": "application/json",
        },
    });
};

export const editMemberTradeApi = (id, params) => {
    return api.post(`/api/admin/dashboard/membertradenotify/edit-member-notify/${id}`, JSON.stringify(params), {
        headers: {
            "x-access-token": localStorage.getItem("accessToken"),
            "Content-Type": "application/json",
        },
    });
};

export const approveMemberTradeApi = (params) => {
    return api.post(`/api/admin/dashboard/membertradenotify/approve-member-notify-checker`, JSON.stringify(params), {
        headers: {
            "x-access-token": localStorage.getItem("accessToken"),
            "Content-Type": "application/json",
        },
    });
};


export const rejectMemberTradeApi = (id, params) => {
    return api.post(`/api/admin/dashboard/membertradenotify/reject/${id}`, JSON.stringify(params), {
        headers: {
            "x-access-token": localStorage.getItem("accessToken"),
            "Content-Type": "application/json",
        },
    });
};




                                         
export const memberTableExportApi = (params) => {
    return api.post(
        `/api/admin/dashboard/membertradenotify/export-main-member`,
        JSON.stringify(params),
        {                                                    
            headers: {
                "x-access-token": localStorage.getItem("accessToken"),
                "Content-Type": "application/json",
            },
        }                            
    );
};
export const memberApprovalsExportApi = (params) => {
    return api.post(
        `/api/admin/dashboard/membertradenotify/export-approval-member`,
        JSON.stringify(params),
        {
            headers: {
                "x-access-token": localStorage.getItem("accessToken"),
                "Content-Type": "application/json",
            },
        }
    );
};
export const memberVersionExportApi = (params) => {
    return api.post(
        `/api/admin/dashboard/membertradenotify/export-version-member`,
        JSON.stringify(params),
        {
            headers: {
                "x-access-token": localStorage.getItem("accessToken"),
                "Content-Type": "application/json",
            },
        }
    );
};

//on submit
export const memberSubmitApi = (id, params) => {
    return api.post(`/api/admin/dashboard/membertradenotify/on-submit/${id}`, params, {
        headers: {
            "x-access-token": localStorage.getItem("accessToken"),
            "Content-Type": "application/json",
        },
    });
};
