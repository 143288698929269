import { authHeader } from "../config";
import { api } from "./api";

export const deleteMemberTradeBuyInApi = (id, params) => {
    return api.post(`/api/admin/admin_member_trade_buy_in/delete/${id}`, JSON.stringify(params), {
        headers: {
            "x-access-token": localStorage.getItem("accessToken"),
            "Content-Type": "application/json",
        },
    });
};                          

export const viewAllMemberTradeBuyInApi = (pageNo = 0, params) => {
    return api.post(`/api/admin/admin_member_trade_buy_in/view-approvals?page_no=${pageNo}`, JSON.stringify(params), {
        headers: {
            "x-access-token": localStorage.getItem("accessToken"),
            "Content-Type": "application/json",
        },
    });
};
export const readMemberTradeBuyInApi = (id = "", params) => {
    return api.post(`/api/admin/admin_member_trade_buy_in/view-approval/${id}`, JSON.stringify(params), {
        headers: {
            "x-access-token": localStorage.getItem("accessToken"),
            "Content-Type": "application/json",
        },
    });
};
export const readApprovalMemberTradeBuyInApi = (id = "", params) => {
    return api.post(`/api/admin/admin_member_trade_buy_in/view-checker/${id}`, JSON.stringify(params), {
        headers: {
            "x-access-token": localStorage.getItem("accessToken"),
            "Content-Type": "application/json",
        },
    });
};

export const versionTableMemberTradeBuyInApi = (pageNo = 0, params) => {
    return api.post(`/api/admin/admin_member_trade_buy_in/view-version?page_no=${pageNo}`, JSON.stringify(params), {
        headers: {
            "x-access-token": localStorage.getItem("accessToken"),
            "Content-Type": "application/json",
        },
    });
};
export const memberTradeBuyInVersionHistoryViewApi = (id, params) => {
    return api.post(`/api/admin/admin_member_trade_buy_in/view-version/${id}`, JSON.stringify(params), {
        headers: {
            "x-access-token": localStorage.getItem("accessToken"),
            "Content-Type": "application/json",
        },
    });
};

export const memberTradeBuyInApprovalsTableApi = (pageNo = 0, params) => {
    return api.post(`/api/admin/admin_member_trade_buy_in/view-checker?page_no=${pageNo}`, JSON.stringify(params), {
        headers: {
            "x-access-token": localStorage.getItem("accessToken"),
            "Content-Type": "application/json",
        },
    });
};

export const memberTradeBuyInCreateApi = (params) => {
    return api.post("/api/admin/admin_member_trade_buy_in/create", JSON.stringify(params), {
        headers: {
            "x-access-token": localStorage.getItem("accessToken"),
            "Content-Type": "application/json",
        },
    });
};

export const memberTradeBuyInCreateOptionsApi = (params) => {
    return api.post(`/api/opportunity-notify/create-NotifyAll`, JSON.stringify(params), {
        headers: {
            "x-access-token": localStorage.getItem("accessToken"),
            "Content-Type": "application/json",
        },
    });
};

export const editMemberTradeBuyInApi = (id, params) => {
    return api.post(`/api/admin/admin_member_trade_buy_in/edit/${id}`, JSON.stringify(params), {
        headers: {
            "x-access-token": localStorage.getItem("accessToken"),
            "Content-Type": "application/json",
        },
    });
};

export const approveMemberTradeBuyInApi = (params) => {
    return api.post(`/api/admin/admin_member_trade_buy_in/approve`, JSON.stringify(params), {
        headers: {
            "x-access-token": localStorage.getItem("accessToken"),
            "Content-Type": "application/json",
        },
    });
};


export const rejectMemberTradeBuyInApi = (id, params) => {
    return api.post(`/api/admin/admin_member_trade_buy_in/reject/${id}`, JSON.stringify(params), {
        headers: {
            "x-access-token": localStorage.getItem("accessToken"),
            "Content-Type": "application/json",
        },
    });
};




                                         
export const memberTradeBuyInTableExportApi = (params) => {
    return api.post(
        `/api/admin/admin_member_trade_buy_in/export-main`,
        JSON.stringify(params),
        {                                                    
            headers: {
                "x-access-token": localStorage.getItem("accessToken"),
                "Content-Type": "application/json",
            },
        }                            
    );
};
export const memberTradeBuyInApprovalsExportApi = (params) => {
    return api.post(
        `/api/admin/admin_member_trade_buy_in/export-version`,
        JSON.stringify(params),
        {
            headers: {
                "x-access-token": localStorage.getItem("accessToken"),
                "Content-Type": "application/json",
            },
        }
    );
};
export const memberTradeBuyInVersionExportApi = (params) => {
    return api.post(
        `/api/admin/admin_member_trade_buy_in/export-version`,
        JSON.stringify(params),
        {
            headers: {
                "x-access-token": localStorage.getItem("accessToken"),
                "Content-Type": "application/json",
            },
        }
    );
};

//on submit
export const memberTradeBuyInSubmitApi = (id, params) => {
    return api.post(`/api/admin/admin_member_trade_buy_in/on-submit/${id}`, params, {
        headers: {
            "x-access-token": localStorage.getItem("accessToken"),
            "Content-Type": "application/json",
        },
    });
};
