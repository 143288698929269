import { Field } from "formik";
import React from "react";
import "./admminRoleCheckBox.css"
const AdminRoleCheckBox = ({ defaultCheck = false, checked, name = "", disabled = false, id = "" }) => {
    return (
        <>
            <div
                className={`d-flex align-items-center `}
            >
                <label className={`selectpaymeth_checkbox_label `}>
                    <Field id={id} disabled={disabled} type="checkbox" name={name} />
                    <span className={`selectpaymeth_checkbox_span`}></span>
                </label>
            </div>

        </>
    );
};

export default AdminRoleCheckBox;
