import React, { useRef, useState, useEffect, } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import InnerLayout from "../../../../components/layouts/innerLayout/innerLayout";
import InnerContainer from "../../../../components/layouts/innerContainer/innerContainer";
import SelectFields from "../../../../components/form/selectFields/selectFields";
import ArrowDownIcon from "../../../../assets/icons/arrowdown";
import { useParams, useNavigate } from "react-router-dom";
import SaveBtn from "../../../../components/buttons/saveBtn";
import DeleteBtn from "../../../../components/buttons/deleteBtn";
import InputField from "../../../../components/form/inputFields/inputField";
import { useDispatch } from 'react-redux';
import successToast from "../../../../components/utilits/successToast";
import errorToast from "../../../../components/utilits/errorToast";
import { deleteCategory, editCategory, faqcategorySubmit, viewCategoryDetail } from "../../../../redux/actions/faqCategoryActions";
import LoadingSpinnerTable from "../../../../components/utilits/loadingSpinnerTable";
import LoadAndError from "../../../../components/utilits/LoadAndError";
import SubmitBtn from "../../../../components/buttons/submit";

const FaqCategoryEdit = () => {
  const [submitData, setSubmitData] = useState();
  const params = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [deleteData, setDeleteData] = useState();
  const [wishlistData, setWishlistData] = useState([]);

  const dispatch = useDispatch();

  useEffect((id) => {
    setLoading(true)
    dispatch(viewCategoryDetail(params?.id, onSuccess, onError));
  }, [])

  //For viewing
  const onSuccess = (data) => {
    console.log("Hey", data.data);
    setLoading(false);
    setWishlistData(data.data);
    setError(false);
  };
  const onError = (data) => {
    setLoading(false)
    errorToast(data.message)
    setError(true);
  };

  const formRef = useRef();

  const initialValues = {
    faqCategoryName: wishlistData.name,
  };

  const interestEditSchema = Yup.object().shape({
    faqCategoryName: Yup.string().trim().required("Required"),
  });

  const handleSubmit = () => {
    if (formRef.current) {
      formRef.current.handleSubmit();
    }
  };
  const onSubmit = (values, { resetForm }) => {
    setLoading(true)
    dispatch(
      editCategory(params?.id, {
        "name": values.faqCategoryName
      }, onSuccessEdit, onErrorEdit)
    );
  };

  //Edit
  const onSuccessEdit = (data) => {
    setSubmitData(data.data.id)
    setSaveHide(true)
    setLoading(false);
    setWishlistData(data.data)
    successToast(data.message)
    setError(false);
  };
  const onErrorEdit = (data) => {
    setLoading(false)
    errorToast(data.data.message)
    setError(true);
    // errorToast(data.data.message)
  };

  //For deletion
  const onDeleteSuccess = (data) => {
    console.log(data.data);
    setLoading(false);
    successToast(data.message)
    setError(false);
  };
  const onDeleteError = (data) => {
    setLoading(false)
    errorToast(data.data.message)
    setError(true);
  };

  const handleShow = () => {
    dispatch(
      deleteCategory(params?.id, onDeleteSuccess, onDeleteError)
    );
  };


  //on submit api
  console.log(submitData);
  const [saveHide, setSaveHide] = useState(false);

  const submitFnc = () => {
    dispatch(faqcategorySubmit
      (
        submitData,
        onSubmitSuccess,
        onSubmitError,
      ));
  };
  const onSubmitSuccess = (data) => {
    console.log(data);
    successToast(data.message)
    navigate("/static-pages/faq-category")

  };
  const onSubmitError = (data) => {
    console.log("ERROR==================", data);
    errorToast(data.message)

  };
  const buttonList = [
    !saveHide && <SaveBtn submitFn={handleSubmit}
      loader={loading} />,
    <SubmitBtn submitFn={submitFnc} saveHide={saveHide} />];
  return (
    <InnerLayout buttons={buttonList}>
      <div className="user_view_container">
        <InnerContainer>
          {loading ? (
            <LoadingSpinnerTable />
          ) : (
            <LoadAndError loader={loading} error={error}>
              <div className="row mt-2">
                <div className="col-12 col-sm-12 col-md-9 col-lg-9 col-xl-7">
                  <div className="row ps-1 ms-1 ps-lg-4 ms-lg-1">
                    <div className="ps-2  pt-3">
                      <h5 className="dark_grey_color text-nowrap fs_13 fn_Montserrat fw_500">
                        FAQ Category ID
                      </h5>
                      <p
                        className={` fs_13 fn_Nunito text-nowrap fw_500 secondary_color`}
                      >
                        {wishlistData?.id}
                      </p>

                      <Formik
                        innerRef={formRef}
                        initialValues={initialValues}
                        onSubmit={onSubmit}
                        enableReinitialize={true}
                        validationSchema={interestEditSchema}
                      >
                        {({ formik, handleChange, values, setFieldValue }) => {
                          return (
                            <Form className="">
                              <div className="   express-form-width">
                                <InputField
                                  id={"faqCategoryName"}
                                  label={"FAQ Category Name"}
                                  type={"text"}
                                  placeholder={"Enter FAQ Category Name here"}
                                  name={"faqCategoryName"}
                                />
                              </div>
                            </Form>
                          );
                        }}
                      </Formik>
                    </div>
                  </div>
                </div>
              </div>
            </LoadAndError>
          )}
        </InnerContainer>
      </div>
    </InnerLayout>
  );
};

export default FaqCategoryEdit;
