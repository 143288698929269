import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import InnerLayout from "../../../../components/layouts/innerLayout/innerLayout";
import InnerContainer from "../../../../components/layouts/innerContainer/innerContainer";
import VersionHistoryBtn from "../../../../components/buttons/versionHistoryBtn";
import RejectBtn from "../../../../components/buttons/rejectBtn";
import ApproveBtn from "../../../../components/buttons/approveBtn";
import errorToast from "../../../../components/utilits/errorToast";
import successToast from "../../../../components/utilits/successToast";
import { approveFaq, FaqApprovalById, rejectFaq } from "../../../../redux/actions/faqAction";
import moment from "moment";
import LoadingSpinnerTable from "../../../../components/utilits/loadingSpinnerTable";
import LoadAndError from "../../../../components/utilits/LoadAndError";
import { approveRefer, referApprovalById, rejectRefer } from "../../../../redux/actions/referAction";
import { approveBadges, badgesApprovalById, rejectBadges } from "../../../../redux/actions/badgesAction";

const BadgesApprovalDetails = () => {
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [notifyMeApprovalViewData, SetNotifyMeApprovalView] = useState({
    id: "",
    name: "",
    createdAt: "",
    updatedAt: "",
  });
  useEffect(() => {
    setLoading(true);
    dispatch(badgesApprovalById(params.id, onSuccess, onError));
  }, [params.id]);
  const onSuccess = (data) => {
    SetNotifyMeApprovalView(data?.data);
    console.log(notifyMeApprovalViewData, "kfjfdkhf");
    console.log(data.data);
    setLoading(false);
    setError(false);
  };
  const onError = (data) => {
    errorToast(data.message);
    console.log(notifyMeApprovalViewData, "error");
    setLoading(false);
    setError(true);
  };

  // Approve Notify Me
  const [updated, setUpdated] = useState(false);

  const approveNotifyMeFn = () => {
    dispatch(
      approveBadges(
        notifyMeApprovalViewData.id,
        onApproveSuccess,
        onApproveError
      )
    );
  };
  console.log(notifyMeApprovalViewData.id);

  const onApproveSuccess = (data) => {
    navigate("/static-pages/badges/approvals")
    setUpdated(true);
    successToast(data.message);
    setLoading(false);
    setError(false);
  };
  const onApproveError = (data) => {
    console.log(data);
    errorToast(data.message);
    setLoading(false);
    setError(true);
  };
  // Reject  Notify Me Api
  const rejectNotifyMeFn = () => {
    dispatch(
      rejectBadges(
        notifyMeApprovalViewData.id,
        onRejectSuccess,
        onRejectError
      )
    );
  };

  const onRejectSuccess = (data) => {
    setUpdated(true);
    successToast(data.message);
    navigate("/static-pages/badges/approvals")
    setLoading(false);
    setError(false);

  };
  const onRejectError = (data) => {
    console.log(data);
    errorToast(data.message);
    setLoading(false);
    setError(true);
  };
  const data = [
    {
      heading: "Approval Id",
      title: notifyMeApprovalViewData?.id,
    },
    {
      heading: "Badges Id",
      title: notifyMeApprovalViewData?.new_data == null ? notifyMeApprovalViewData?.old_data?.id : notifyMeApprovalViewData?.new_data?.id,
    },
    {
      heading: "Question",
      title: notifyMeApprovalViewData?.new_data == null ? notifyMeApprovalViewData?.old_data?.question : notifyMeApprovalViewData?.new_data?.question,
    },
    {
      heading: "Answer",
      title: notifyMeApprovalViewData?.new_data == null ? notifyMeApprovalViewData?.old_data?.answer : notifyMeApprovalViewData?.new_data?.answer,
    },
    {
      heading: "Approved At",
      title: moment(notifyMeApprovalViewData?.new_data?.createdAt).format("DD-MM-YYYY HH:mm:ss"),
    },
    {
      heading: "Updated At",
      title: moment(notifyMeApprovalViewData?.new_data?.updatedAt).format("DD-MM-YYYY HH:mm:ss"),
    },
    {
      heading: "Operation Type",
      title: notifyMeApprovalViewData?.new_data == null ? "Delete" : notifyMeApprovalViewData?.new_data && notifyMeApprovalViewData?.old_data ? "Edit" : notifyMeApprovalViewData?.old_data == null ? "Create" : ""
    }

  ];
  const buttonList = [<VersionHistoryBtn link="/static-pages/faqs/version-history" />, <ApproveBtn approveFn={approveNotifyMeFn} />, <RejectBtn showModal={rejectNotifyMeFn} />];
  return (
    <InnerLayout buttons={buttonList}>
      <div className="user_view_container">
        <InnerContainer error={error} oldData={notifyMeApprovalViewData?.old_data} newData={notifyMeApprovalViewData?.new_data}>
          {loading ? (
            <LoadingSpinnerTable />
          ) : (
            <LoadAndError loader={loading} error={error}>
              <div className="row mt-4">
                <div className="col-12 col-sm-12 col-md-9 col-lg-9 col-xl-7">
                  <div className="row ps-1 ms-1 ps-lg-4 ms-lg-1">
                    {data.map((item, index) => (
                      <div className="mb-2 ">
                        <h5 className="dark_grey_color text-nowrap fs_13 fn_Montserrat fw_500">
                          {item.heading}
                        </h5>
                        <p
                          className={` fs_13 fn_Nunito  fw_500 ${item.heading === "FAQ ID"

                            ? "secondary_color"
                            : "dark_title_color"
                            }`}
                        >
                          {item.title}
                        </p>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </LoadAndError>
          )}
        </InnerContainer>
      </div>
    </InnerLayout>
  );
};

export default BadgesApprovalDetails;
