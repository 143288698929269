import { call, takeLatest } from "redux-saga/effects";
import { APPROVE_BADGES, CREATE_BADGES, DELETE_BADGES, EDIT_BADGES, BADGES_APPROVALS_EXPORT, BADGES_APPROVAL_TABLE, BADGES_APPROVAL_VIEW, BADGES_EXPORT, BADGES_OPTIONS, BADGES_SUBMIT, BADGES_TABLE, BADGES_VERSION_EXPORT, BADGES_VERSION_HISTORY, BADGES_VERSION_HISTORY_TABLE, REJECT_BADGES, VIEW_BADGES } from "../actions/types";
import { approveBadgesApi, badgesApprovalByIdApi, badgesApprovalListApi, badgesApprovalsExportApi, badgesSubmitApi, badgesTableExportApi, badgesVersionExportApi, createBadgesApi, deleteBadgesApi, editBadgesApi, rejectBadgesApi, versionHistoryBadgesDetailApi, versionHistoryBadgesListApi, viewBadgesDetailApi, viewBadgesListApi } from "../apis/badgesApi";

export function* createBadgesWorker(action) {
    try {
        const res = yield call(createBadgesApi,
            action.data
        );
        console.log(res);

        if (res.status === 200 && res.data.status === "success") {
            yield action.onSuccess(res.data);
        } else {
            yield action.onError(res.data);
        }
    } catch (error) { }
}

export function* approveBadgesWorker(action) {
    try {
        const res = yield call(approveBadgesApi, {
            badges_id: action.id,
        });
        console.log(res);
        if (res.status === 200 && res.data.status === "success") {
            yield action.onSuccess(res.data);
        } else {
            yield action.onError(res.data);
        }
    } catch (error) { }
}

export function* rejectBadgesWorker(action) {
    try {
        const res = yield call(rejectBadgesApi, action.id);
        console.log(res);
        if (res.status === 200 && res.data.status === "success") {
            yield action.onSuccess(res.data);
        } else {
            yield action.onError(res.data);
        }
    } catch (error) { }
}


export function* viewBadgesDetailWorker(action) {
    try {
        const res = yield call(viewBadgesDetailApi, action.id);
        console.log(res);
        if (res.status === 200 && res.data.status === "success") {
            yield action.onSuccess(res.data);
        } else {
            yield action.onError(res.data);
        }
    } catch (error) { }
}


export function* viewBadgesListWorker(action) {
    try {

        const res = yield call(viewBadgesListApi,
            action.page_no,
            action.data,
        );
        console.log(res);
        if (res.status === 200 && res.data.status === "success") {
            yield action.onSuccess(res.data.data);
            console.log(res.data.data);
        } else {
            yield action.onError(res.data);
        }
    } catch (error) { }
}

export function* editBadgesWorker(action) {
    try {
        const res = yield call(editBadgesApi, action.id, action.data);
        console.log(res);
        if (res.status === 200 && res.data.status === "success") {
            yield action.onSuccess(res.data);
        } else {
            yield action.onError(res.data);
        }
    } catch (error) { }
}


export function* deleteBadgesWorker(action) {
    try {
        const res = yield call(deleteBadgesApi, action.id);
        console.log(res);
        if (res.status === 200 && res.data.status === "success") {
            yield action.onSuccess(res.data);
        } else {
            yield action.onError(res.data);
        }
    } catch (error) { }
}




export function* badgesApprovalListWorker(action) {
    console.log(action.search,
        action.page_no,)
    try {
        const res = yield call(badgesApprovalListApi,
            action.page_no,
            action.data,
        );
        if (res.status === 200 && res.data.status === "success") {
            yield action.onSuccess(res.data.data);
            console.log(res.data);
        } else {
            yield action.onError(res.data);
        }
    } catch (error) { }
}



export function* badgesApprovalByIdWorker(action) {
    try {
        const res = yield call(badgesApprovalByIdApi, action.id);
        console.log(res);
        if (res.status === 200 && res.data.status === "success") {
            yield action.onSuccess(res.data);
            console.log(res.data)
        } else {
            yield action.onError(res.data);
        }
    } catch (error) { }
}



export function* versionHistoryBadgesListWorker(action) {
    try {
        const res = yield call(versionHistoryBadgesListApi,
            action.page,
            action.data,
        );
        console.log(res);
        if (res.status === 200 && res.data.status === "success") {
            yield action.onSuccess(res.data);
        } else {
            yield action.onError(res.data);
        }
    } catch (error) { }
}

export function* versionHistoryBadgesDetailWorker(action) {
    try {
        const res = yield call(versionHistoryBadgesDetailApi,
            action.id,
        );
        console.log(res);
        if (res.status === 200 && res.data.status === "success") {
            yield action.onSuccess(res.data);
        } else {
            yield action.onError(res.data);
        }
    } catch (error) { }
}




/*********WATCHERS********/

export function* badgesTableExportWorker(action) {
    try {
        const res = yield call(badgesTableExportApi,

        );
        if (res.status === 200 && res.data.status === "success") {
            yield action.onSuccess(res.data);
        } else {
            yield action.onError(res.data);
        }
    } catch (error) { }
}


export function* badgesApprovalsExportWorker(action) {
    try {
        const res = yield call(badgesApprovalsExportApi);
        console.log(res);
        if (res.status === 200 && res.data.status === "success") {
            console.log(res.data);
            yield action.onSuccess(res.data.data);
        } else {
            yield action.onError(res.data);
        }
    } catch (error) { }
}

export function* badgesVersionExportWorker(action) {
    try {
        const res = yield call(badgesVersionExportApi);
        console.log(res);
        if (res.status === 200 && res.data.status === "success") {
            yield action.onSuccess(res.data);
        } else {
            yield action.onError(res.data);
        }
    } catch (error) { }
}



export function* badgesSubmitWorker(action) {
    try {
        const res = yield call(badgesSubmitApi, action.id);
        console.log(res);
        if (res.status === 200 && res.data.status === "success") {
            yield action.onSuccess(res.data);
        } else {
            yield action.onError(res.data);
        }
    } catch (error) { }
}

export function* createBadgesWorkerWatcher() {
    yield takeLatest(CREATE_BADGES, createBadgesWorker);
}

export function* approveBadgesWorkerWatcher() {
    yield takeLatest(APPROVE_BADGES, approveBadgesWorker);
}
export function* rejectBadgesWorkerWatcher() {
    yield takeLatest(REJECT_BADGES, rejectBadgesWorker);
}
export function* viewBadgesDetailWatcherWorker() {
    yield takeLatest(VIEW_BADGES, viewBadgesDetailWorker);
}
export function* viewBadgesListWorkerWatcher() {
    yield takeLatest(BADGES_TABLE, viewBadgesListWorker);
}
export function* editBadgesWorkerWatcher() {
    yield takeLatest(EDIT_BADGES, editBadgesWorker);

}
export function* deleteBadgesWorkerWatcher() {
    yield takeLatest(DELETE_BADGES, deleteBadgesWorker);
}

export function* badgesApprovalListWatcherWorker() {
    yield takeLatest(BADGES_APPROVAL_TABLE, badgesApprovalListWorker);
}

export function* badgesApprovalByIdWatcher() {
    yield takeLatest(BADGES_APPROVAL_VIEW, badgesApprovalByIdWorker);
}



export function* versionHistoryBadgesListWatcher() {
    yield takeLatest(BADGES_VERSION_HISTORY_TABLE, versionHistoryBadgesListWorker);
}
export function* versionHistoryBadgesDetailWatcher() {
    yield takeLatest(
        BADGES_VERSION_HISTORY,
        versionHistoryBadgesDetailWorker
    );
}


/****exports*****/

export function* badgesTableExportWatcher() {
    yield takeLatest(
        BADGES_EXPORT,
        badgesTableExportWorker
    );
}
export function* badgesApprovalsExportWatcher() {
    yield takeLatest(BADGES_APPROVALS_EXPORT, badgesApprovalsExportWorker);
}
export function* badgesVersionExportWorkerWatcher() {
    yield takeLatest(BADGES_VERSION_EXPORT, badgesVersionExportWorker);
}
export function* badgesSubmitWorkerWatcher() {
    yield takeLatest(BADGES_SUBMIT, badgesSubmitWorker);
}