import { OPPOR_TYPE_APPROVALS_EXPORT, OPPOR_TYPE_APPROVALS_LIST, OPPOR_TYPE_APPROVALS_VIEW, OPPOR_TYPE_APPROVE, OPPOR_TYPE_CREATE, OPPOR_TYPE_DELETE, OPPOR_TYPE_EDIT, OPPOR_TYPE_EXPORT, OPPOR_TYPE_LIST, OPPOR_TYPE_READ, OPPOR_TYPE_REJECT, OPPOR_TYPE_SUBMIT, OPPOR_TYPE_VERSION_EXPORT, OPPOR_TYPE_VERSION_LIST, OPPOR_TYPE_VERSION_VIEW } from "./types";

export const createOpporType = (name, onSuccess, onError) => {
    return {
        type: OPPOR_TYPE_CREATE,
        name,
        onSuccess,
        onError,
    };
};
export const viewAllOpporType = (data, pageNo, onSuccess, onError) => {
    return {
        type: OPPOR_TYPE_LIST,
        data,
        pageNo,
        onSuccess,
        onError,
    };
};

export const viewOpporType = (id, onSuccess, onError) => {
    return {
        type: OPPOR_TYPE_READ,
        id,
        onSuccess,
        onError,
    };
}
export const editOpporType = (id, name, permissions, onSuccess, onError) => {
    return {
        type: OPPOR_TYPE_EDIT,
        id, name,
        permissions,
        onSuccess,
        onError,
    };
}
export const approvalsOpporTypeList = (data, pageNo, onSuccess, onError) => {
    return {
        type: OPPOR_TYPE_APPROVALS_LIST,
        data,
        pageNo,
        onSuccess,
        onError,
    };
}
export const viewApprovalOpporType = (id, onSuccess, onError) => {
    return {
        type: OPPOR_TYPE_APPROVALS_VIEW,
        id,
        onSuccess,
        onError,
    };
}
export const approveOpporType = (id, onSuccess, onError) => {
    return {
        type: OPPOR_TYPE_APPROVE,
        id,
        onSuccess,
        onError,
    };
}
export const rejectOpporType = (id, onSuccess, onError) => {
    return {
        type: OPPOR_TYPE_REJECT,
        id,
        onSuccess,
        onError,
    };
}
export const deleteOpporType = (id, onSuccess, onError) => {
    return {
        type: OPPOR_TYPE_DELETE,
        id,
        onSuccess,
        onError,
    };
}
export const versionHistoryListOpporType = (data, pageNo, onSuccess, onError) => {
    return {
        type: OPPOR_TYPE_VERSION_LIST,
        data,
        pageNo,
        onSuccess,
        onError,
    };
}
export const viewVersionHistoryOpporType = (id, onSuccess, onError) => {
    return {
        type: OPPOR_TYPE_VERSION_VIEW,
        id,
        onSuccess,
        onError,
    };
}




export const opprTypeTableExport = (onSuccess, onError) => {
    return {
        type: OPPOR_TYPE_EXPORT,
        onSuccess,
        onError,
    };
};
export const opprTypeApprovalsExport = (onSuccess, onError) => {
    return {
        type: OPPOR_TYPE_APPROVALS_EXPORT,
        onSuccess,
        onError,
    };
};
export const opprTypeVersionExport = (onSuccess, onError) => {
    return {
        type: OPPOR_TYPE_VERSION_EXPORT,
        onSuccess,
        onError,
    };
};

export const opprTypeSubmitAction = (id, onSuccess, onError) => {
    return {
      type: OPPOR_TYPE_SUBMIT,
      id,
      onSuccess,
      onError,
    };
  };