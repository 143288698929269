import { CREATE_SOCIAL_OPTIONS, SOCIAL_APPROVALS_EXPORT, SOCIAL_APPROVALS_LIST, SOCIAL_APPROVALS_VIEW, SOCIAL_APPROVE, SOCIAL_CREATE, SOCIAL_DELETE, SOCIAL_EDIT, SOCIAL_EXPORT, SOCIAL_LIST, SOCIAL_READ, SOCIAL_REJECT, SOCIAL_SUBMIT, SOCIAL_VERSION_EXPORT, SOCIAL_VERSION_LIST, SOCIAL_VERSION_VIEW } from "./types";

export const viewAllSocial = (data, pageNo, onSuccess, onError) => {
    return {
        type: SOCIAL_LIST,
        data,
        pageNo,
        onSuccess,
        onError,
    };
};
export const readApprovalSocial = (id, onSuccess, onError) => {
    return {
        type: SOCIAL_APPROVALS_VIEW,
        id,
        onSuccess,
        onError,
    };
}
export const readSocial = (id, onSuccess, onError) => {
    return {
        type: SOCIAL_READ,
        id,
        onSuccess,
        onError,
    };
}
export const versionTableSocial = (data, pageNo, onSuccess, onError) => {
    return {
        type: SOCIAL_VERSION_LIST,
        data,
        pageNo,
        onSuccess,
        onError,
    };
}
export const deleteSocial = (id, onSuccess, onError) => {
    return {
        type: SOCIAL_DELETE,
        id,
        onSuccess,
        onError,
    };
}
export const socialVersionHistoryview = (id, onSuccess, onError) => {
    return {
        type: SOCIAL_VERSION_VIEW,
        id,
        onSuccess,
        onError,
    };
}
export const socialApprovalsTable = (data, pageNo = 0, onSuccess, onError) => {
    return {
        type: SOCIAL_APPROVALS_LIST,
        data,
        pageNo,
        onSuccess,
        onError,
    };
}


export const socialCreate = (
    data,
    onSuccess,
    onError
) => {
    return {
        type: SOCIAL_CREATE,
        data,
        onSuccess,
        onError,
    };
};


export const editSocial = (id, data, onSuccess, onError) => {
    return {
        type: SOCIAL_EDIT,
        id,
        data,
        onSuccess,
        onError,
    }
};

export const approveSocial = (id, onSuccess, onError) => {
    return {
        type: SOCIAL_APPROVE,
        id,
        onSuccess,
        onError,
    };
}

export const rejectSocial = (id, onSuccess, onError) => {
    return {
        type: SOCIAL_REJECT,
        id,
        onSuccess,
        onError,
    };
}


export const socialTableExport = (onSuccess, onError) => {
    return {
        type: SOCIAL_EXPORT,
        onSuccess,
        onError,
    };
};
export const socialApprovalsExport = (onSuccess, onError) => {
    return {
        type: SOCIAL_APPROVALS_EXPORT,
        onSuccess,
        onError,
    };
};
export const socialVersionExport = (onSuccess, onError) => {
    return {
        type: SOCIAL_VERSION_EXPORT,
        onSuccess,
        onError,
    };
};

export const socialSubmitAction = (id, onSuccess, onError) => {
    return {
        type: SOCIAL_SUBMIT,
        id,
        onSuccess,
        onError,
    };
};