import React, { useRef } from "react";
import { Formik, Form } from "formik";
import { Field } from "formik";
import Select, { components } from "react-select";
import * as Yup from "yup";
import "../../../modules/user/user-view.css";
import ErrorMsg from "../../form/errorMsg";
import ApplyBtn from "../../buttons/applyBtn";
import ReverifyBtn from "../../buttons/reverifyBtn";
import ArrowDownIcon from "../../../assets/icons/arrowdown";
import ArrowRightIcon from "../../../assets/icons/arrowrightIcon";
import { access } from "../../utilits/access";
import { kycStatusUserAction } from "../../../redux/actions/userAction";
import { useDispatch } from "react-redux";
import successToast from "../../utilits/successToast";
import usePermissionChecker from "../../utilits/usePermissionChecker";

const Kyc = ({ individualData, value, label }) => {
  const access = usePermissionChecker("checker")

  const dispatch = useDispatch()
  const kycdetails = [
    {
      heading: "KYC Process Status",
      title: individualData?.kyc_process_status,
    },
    {
      heading: "KYC Last Updated At",
      title:
        individualData?.kyc_last_updated_at === null
          ? "-"
          : individualData?.kyc_last_updated_at,
    },
  ];
  const formRef = useRef();
  const initialValues = {
    kyc: "",
  };
  const loginSchema = Yup.object().shape({
    kyc: Yup.string().trim().required("Required"),
  });
  const handleSubmit = () => {
    if (formRef.current) {
      formRef.current.handleSubmit();
    }
  };
  const onSubmit = (values, { resetForm }) => {
    console.log(values);
    console.log("zdfysdgfyesguysdgfs========================");
    dispatch(
      kycStatusUserAction(
        {
          user_id: individualData?.id,
          kyc_status: values?.kyc
        },
        onSuccess,
        onError
      )
    )
  };

  const onSuccess = (data) => {
    value(!label)
    // successToast(data.data.data.message);
  };
  const onError = (data) => {
    console.log(data);
    // setLoading(false);
    // errorToast(data.message);
  };


  const reverify = () => {
    dispatch(
      kycStatusUserAction(
        {
          user_id: individualData?.id,
          kyc_status: "pending"
        },
        onSuccess,
        onError
      )
    )
  }
  const DropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator {...props}>
        <ArrowDownIcon width={25} height={25} />
      </components.DropdownIndicator>
    );
  };
  return (
    <>
      <h3 className="dark_grey_color fn_Montserrat ps-4 ps-lg-5 mt-2 py-2 fw_500 fs_13 white_shade_bg">
        KYC
      </h3>
      <div className="row mt-4">
        <div className="col-12 col-lg-12 col-xl-6   ">
          <div className="row ps-1 ms-1 ps-lg-4 ms-lg-1">
            {kycdetails.map((item, index) => (
              <div className="mt-2 kyc_information_details_width">
                <h5 className="dark_grey_color  fs_13 fn_Montserrat fw_500">
                  {item.heading}
                </h5>
                <p
                  className={` fs_13 fn_Nunito  fw_500 ${item.heading === "User ID" ||
                    item.heading === "E-Mail" ||
                    item.heading === "Phone Number"
                    ? "secondary_color"
                    : "dark_title_color"
                    }`}
                >
                  {item.title}
                </p>
              </div>
            ))}
          </div>
        </div>
        {
          access === true && <>
            <div className="col-lg-12 col-xl-6">
              <Formik
                innerRef={formRef}
                initialValues={initialValues}
                onSubmit={onSubmit}
                validationSchema={loginSchema}
              >
                <div className="d-flex justify-content-start  ms-0 ms-xl-4 ps-2 ms-2 ps-lg-4 ms-lg-3">
                  <div className="d-flex align-items-center  flex-column flex-lg-row gap-3">

                    <Form className="kyc-status-form-width">
                      <div className={``}>
                        <Field name="kyc">
                          {({ form, meta, Field, default1 }) => (
                            <Select
                              Icon={
                                <ArrowRightIcon width={15} fill={"#A5A5A5"} />
                              }
                              name="kyc"
                              id="kyc"
                              {...Field}
                              components={{ DropdownIndicator }}
                              styles={customStyles}
                              type="text"
                              className={
                                meta.touched && meta.error
                                  ? `kyc_select-input-field w-100 kyc_selectField__border       focus_outline__none`
                                  : `kyc_select-input-field w-100     kyc_selectField__border   focus_outline__none`
                              }
                              placeholder="SELECT KYC STATUS"
                              options={kycstatus}
                              onChange={(selectedOption) =>
                                form.setFieldValue("kyc", selectedOption.value)
                              }
                            />
                          )}
                        </Field>
                        <ErrorMsg name="kyc" />
                      </div>
                    </Form>

                    <div className="d-flex align-items-center gap-3">

                      <span className="d-flex gap-2">
                        <ApplyBtn filterFn={handleSubmit} />
                        <ReverifyBtn reverify={reverify} />
                      </span>
                    </div>
                  </div>
                </div>
              </Formik>
            </div></>}


      </div>
    </>
  );
};

export default Kyc;

export const customStyles = {
  indicatorSeparator: () => ({ display: "none" }),
  control: (base, state) => ({
    ...base,
    color: "#112F6A",
    fontFamily: "Montserrat",
    fontWeight: "600",
    fontSize: "15px",

    width: "100%",
    // Overwrittes the different states of border
    borderColor: state.isFocused ? "transparent" : "transparent",
    // Removes weird border around container
    boxShadow: state.isFocused ? null : null,
    "&:hover": {
      // Overwrittes the different states of border
      // borderColor: state.isFocused ? "#1D72DB" : "#F4F8FB",
    },
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? "#1D72DB" : null,
    borderBottom: "1px solid #F5F5F5",
    fontFamily: "Montserrat",
    fontSize: "15px",
    fontWeight: "500",
    "&:hover": {
      backgroundColor: state.isFocused ? "#1D72DB" : "#1D72DB",
    },
  }),
  placeholder: (defaultStyles) => {
    return {
      ...defaultStyles,
      color: "#000000DE",
      fontFamily: "Montserrat",
      fontSize: "13px",
      fontWeight: "600",
      // top: "0.6rem"
    };
  },
  dropdownIndicator: (provided) => ({
    ...provided,
    svg: {
      fill: "#323D5A",
    },
  }),
};

const kycstatus = [
  { value: "failed", label: "failed" },
  { value: "verified", label: "verified" },
];
