import React, { useEffect, useRef, useState } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import InputField from "../../../../components/form/inputFields/inputField";
import SelectFields from "../../../../components/form/selectFields/selectFields";
import SaveBtn from "../../../../components/buttons/saveBtn";
import InnerLayout from "../../../../components/layouts/innerLayout/innerLayout";
import InnerContainer from "../../../../components/layouts/innerContainer/innerContainer";
import { useDispatch } from "react-redux";
import {
  addAdmin,
  roleTypeData,
} from "../../../../redux/actions/AdminUserAction";
import errorToast from "../../../../components/utilits/errorToast";
import successToast from "../../../../components/utilits/successToast";
import UploadIcon from "../../../../assets/icons/upload";
import CloseIcon from "../../../../assets/icons/closeIcon";
import ErrorMsg from "../../../../components/form/errorMsg";
import { editMediaRecAction, viewMediaRecAction, mediaRecSubmit } from "../../../../redux/actions/mediaRecogonitionActions";
import { useParams, useNavigate } from "react-router-dom";
import { uploadAddressProofFrontImageAction } from "../../../../redux/actions/userAction";
import LoadingSpinnerTable from "../../../../components/utilits/loadingSpinnerTable";
import LoadAndError from "../../../../components/utilits/LoadAndError";
import SubmitBtn from "../../../../components/buttons/submit";

const MediaRecogonitionEdit = () => {
  const [submitData, setSubmitData] = useState();
  const params = useParams();
  const navigate = useNavigate();
  const [file, setFile] = useState(null);
  const [fileTwo, setFileTwo] = useState(null);
  const [notifyMeViewData, SetNotifyMeViewData] = useState([]);
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false);
  const nameregex = /^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$/;
  const [deskImageLink, setDeskImageLink] = useState({
    img: "",
    error: false,
  });
  const [mobImageLink, setMobImageLink] = useState({
    img: "",
    error: false,
  });
  const formRef = useRef()
  useEffect(() => {
    setLoading(true);
    setLoading(true)
    dispatch(
      viewMediaRecAction(params.id, onFetchSuccess, onFetchError)
    );
  }, [params.id])
  const onFetchSuccess = (data) => {
    SetNotifyMeViewData(data?.data);
    setDeskImageLink({ img: data.data.logo_url, error: false })
    setMobImageLink({ img: data.data.photo_url, error: false })
    setLoading(false);
    setError(false);
  };
  const onFetchError = (data) => {
    errorToast(data.message)
    setLoading(false);
    setError(true);
  };

  console.log("The value:::", notifyMeViewData);

  const dispatch = useDispatch();
  console.log(notifyMeViewData?.name);

  const initialValues = {

    name: notifyMeViewData?.name,
    title: notifyMeViewData?.title,
    url: notifyMeViewData?.know_more_url,
    description: notifyMeViewData?.description,
  };
  const loginSchema = Yup.object().shape({
    name: Yup.string("").matches(nameregex, "Invalid Name").required("Required"),
    title: Yup.string().trim().required("Required"),
    url: Yup.string().trim().required("Required"),
    description: Yup.string().trim().required("Required"),
  });
  const handleSubmit = () => {
    if (!deskImageLink.img || !mobImageLink.img) {
      if (!deskImageLink.img) {
        setDeskImageLink({ img: "", error: true });
      }
      if (!mobImageLink.img) {
        setMobImageLink({ img: "", error: true });
      }
    }
    if (formRef.current) {
      formRef.current.handleSubmit();
    }
  };
  const onSubmit = (values, { resetForm }) => {

    console.log("the edit", params.id);
    if (mobImageLink.img && deskImageLink.img)
      dispatch(
        editMediaRecAction(
          params.id,
          {
            name: values.name,
            designation: "AdvisorHR",
            title: values.title,
            description: values.description,
            url: values.url,
            logo: deskImageLink.img,
            profile_picture: mobImageLink.img
          },
          onSuccess,
          onError
        )
      );
    console.log(values);
  };

  const onSuccess = (data) => {
    setSubmitData(data.data.id)
    setSaveHide(true)
    successToast(data.message);
  };

  const onError = (data) => {
    errorToast(data.data);
  };

  const imageUpload = (e) => {
    if (e.target.name === "deskImage") {
      dispatch(
        uploadAddressProofFrontImageAction(
          e.target.files[0],
          onDeskImageUploadSucess,
          onDeskImageUploadError
        )
      );
    } else if (e.target.name === "mobImage") {
      dispatch(
        uploadAddressProofFrontImageAction(
          e.target.files[0],
          onMobImageUploadSucess,
          onMobImageUploadError
        )
      );
    }
    e.target.value = null;
  };
  const onDeskImageUploadSucess = (data) => {
    setDeskImageLink({ img: data.data.data.location, error: false });
  };
  const onDeskImageUploadError = (data) => {
    setDeskImageLink({ img: "", error: true });
  };
  const onMobImageUploadSucess = (data) => {
    setMobImageLink({ img: data.data.data.location, error: false });
  };
  const onMobImageUploadError = (data) => {
    setMobImageLink({ img: "", error: true });
  };

  //on submit api
  console.log(submitData);
  const [saveHide, setSaveHide] = useState(false);

  const submitFnc = () => {
    dispatch(
      mediaRecSubmit
      (
        submitData,
        onSubmitSuccess,
        onSubmitError,
      ));
  };
  const onSubmitSuccess = (data) => {
    console.log(data);
    successToast(data.message)
    navigate("/static-pages/media-recogonition");
  };
  const onSubmitError = (data) => {
    console.log("ERROR==================", data);
    errorToast(data.message)

  };
  const buttonList = [
    !saveHide && <SaveBtn submitFn={handleSubmit}
      loader={loading} />,
    <SubmitBtn submitFn={submitFnc} saveHide={saveHide} />];

  return (
    <>
      <InnerLayout buttons={buttonList}>
        <div className="admin_user_container">
          <InnerContainer>
            {loading ? (
              <LoadingSpinnerTable />
            ) : (
              <LoadAndError loader={loading} error={error}>
                <div className="pt-3 mb-5 ps-2 ps-md-2 ps-lg-4 ">
                  <Formik
                    enableReinitialize={true}
                    innerRef={formRef}
                    initialValues={initialValues}
                    onSubmit={onSubmit}
                    validationSchema={loginSchema}
                  >
                    {({ setFieldValue, handleChange, values }) => (
                      <Form className="our-team-form-width">
                        <div className="row">
                          <div className="col-12 col-md-7 col-xl-6">
                            <h5 className="dark_grey_color fs_13 fn_Montserrat fw_500 m-0">
                              Media & Recognitions ID
                            </h5>
                            <p
                              className={` fs_13 fn_Nunito fw_500  secondary_color `}
                            >
                              {/* {adminDetails?.id} */}
                              {params.id}
                            </p>
                            <div className="d-flex gap-5 flex-wrap mb-3 ">
                              <div>
                                <span className="mb-3 dark_grey_color fn_Montserrat fw_500 fs_14 ps-2">Logo</span>
                                <div className="blog_create_image_container mt-1 position-relative d-flex  justify-content-center align-items-center">
                                  <input
                                    multiple={false}
                                    style={{ visibility: "hidden" }}
                                    type="file"
                                    id="deskImage"
                                    className="upload_document_input"
                                    name="deskImage"
                                    accept="image/* ,capture=camera"
                                    onChange={(e) => {
                                      imageUpload(e);
                                    }}
                                  />
                                  {deskImageLink.img ? (
                                    <>
                                      <img
                                        src={deskImageLink.img}
                                        className={"user-edit-profile-picture"}
                                      />
                                      <button
                                        type="button"
                                        onClick={(e) => {
                                          setDeskImageLink({ img: "", error: true });
                                        }}
                                        className="position-absolute border-0 cursor_pointer white_bg d-flex gap-2 py-1 px-2 user_edit_profile_picture_remove_container"
                                      >
                                        <CloseIcon fill="#666666" />
                                        <span className="dark_grey_color fs_13 fn_Montserrat fw_500 ">
                                          Remove
                                        </span>
                                      </button>
                                    </>
                                  ) : (
                                    <label
                                      htmlFor="deskImage"
                                      className="d-flex flex-column align-items-center mt-2 justify-content-center fs_12"
                                    >
                                      <UploadIcon width={30} height={30} />
                                      Choose Profile Picture
                                    </label>
                                  )}
                                </div>
                                {deskImageLink.error && (
                                  <span className="fs_12 ms-2 text-danger fw-semibold fn_Nunito">
                                    Required!
                                  </span>
                                )}
                              </div>
                              <div>
                                <span className="mb-3 dark_grey_color fn_Montserrat fw_500 fs_14 ps-2">Profile Picture</span>
                                <div className="blog_create_image_container mt-1 position-relative d-flex  justify-content-center align-items-center">
                                  <input
                                    multiple={false}
                                    style={{ visibility: "hidden" }}
                                    type="file"
                                    id="mobImage"
                                    className="upload_document_input"
                                    name="mobImage"
                                    accept="image/* ,capture=camera"
                                    onChange={(e) => {
                                      imageUpload(e);
                                    }}
                                  />
                                  {mobImageLink.img ? (
                                    <>
                                      <img
                                        src={mobImageLink.img}
                                        className={"user-edit-profile-picture"}
                                      />
                                      <button
                                        type="button"
                                        onClick={(e) => {
                                          setMobImageLink({ img: "", error: true });
                                        }}
                                        className="position-absolute border-0 cursor_pointer white_bg d-flex gap-2 py-1 px-2 user_edit_profile_picture_remove_container"
                                      >
                                        <CloseIcon fill="#666666" />
                                        <span className="dark_grey_color fs_13 fn_Montserrat fw_500 ">
                                          Remove
                                        </span>
                                      </button>
                                    </>
                                  ) : (
                                    <label
                                      htmlFor="mobImage"
                                      className="d-flex flex-column align-items-center mt-2 justify-content-center fs_12"
                                    >
                                      <UploadIcon width={30} height={30} />
                                      Choose Profile Picture
                                    </label>
                                  )}
                                </div>
                                {mobImageLink.error && (
                                  <span className="fs_12 ms-2 text-danger fw-semibold fn_Nunito">
                                    Required!
                                  </span>
                                )}
                              </div>
                            </div>

                            <InputField
                              id={"name"}
                              label={"Name"}
                              type={"text"}
                              placeholder={"Enter Name"}
                              name={"name"}
                            />
                            <InputField
                              id={"title"}
                              label={"Title"}
                              type={"text"}
                              placeholder={"Enter title"}
                              name={"title"}
                            />
                            <InputField
                              id={"url"}
                              label={"URL"}
                              type={"text"}
                              placeholder={"Enter URL"}
                              name={"url"}
                            />
                          </div>
                          <div className="col-12 col-md-7 col-xl-6">
                            <div className="ps-0 ps-lg-4">
                              <label
                                id="description"
                                className="mb-2 dark_grey_color fn_Montserrat fw_500 fs_14 ps-2"
                              >
                                Description
                              </label>
                              <Field name={"description"}>
                                {({ field }) => (
                                  <textarea
                                    id="description"
                                    {...field}
                                    type="text"
                                    placeholder="Enter Description"
                                    className={
                                      "w-100 opportunities_create_summary_text_area p-2 "
                                    }
                                  ></textarea>
                                )}
                              </Field>

                              <ErrorMsg name={"description"} />
                            </div>
                          </div>
                        </div>
                      </Form>
                    )}
                  </Formik>
                </div>
              </LoadAndError>
            )}
          </InnerContainer>
        </div>
        {/* <DeleteModal
          loader={delLoading}
          handleClose={() => setShow(false)}
          deleteFn={handleDelete}
          data={deleteData}
          show={show}
        /> */}
      </InnerLayout>
    </>
  );
};

export default MediaRecogonitionEdit;

export const selectDesignation = [
  { value: "long term", label: "long term" },
  { value: "short term", label: "short term" },
];
