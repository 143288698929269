import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import React, { useEffect, useRef, useState } from "react";
import "../our-partners.css"
import InnerLayout from "../../../../components/layouts/innerLayout/innerLayout";
import InnerContainer from "../../../../components/layouts/innerContainer/innerContainer";
import VersionHistoryBtn from "../../../../components/buttons/versionHistoryBtn";
import EditBtn from "../../../../components/buttons/editBtn";
import { viewParnterAction } from "../../../../redux/actions/ourParnterActions";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import errorToast from "../../../../components/utilits/errorToast";
import DownloadIcon from "../../../../assets/icons/downloadIcon";
import LoadingSpinnerTable from "../../../../components/utilits/loadingSpinnerTable";
import LoadAndError from "../../../../components/utilits/LoadAndError";
import OurTeamProfileImage from "../../../../components/static-pages/our-team/modals/approvalProfileImage";

const OurPartnerView = () => {
  const [file, setFile] = useState(null);
  const [partnerData, setPartnerData] = useState([]);
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const [image, setImage] = useState("")
  const [logo, setLogo] = useState(false);
  const [profile, setProfile] = useState(false);
  const formRef = useRef();
  const dispatch = useDispatch();
  const params = useParams()
  const [imageShow, setImageShow] = useState(false)
  const handleBackImageShow = () => setImageShow(true);
  const handleImageClose = () => setImageShow(false);
  const handleSubmit = () => {
    if (formRef.current) {
      formRef.current.handleSubmit();
    }
  };
  const initialValues = {
    profileImage: "",
    Name: "User name here",
    Designation: "Designation text here",
    LinkedIn: "0123asdf456jkl7w",
    Prirority: "1",
    Description:
      "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd.",
  };
  const loginSchema = Yup.object().shape({
    profileImage: Yup.mixed().required("Upload an Image"),
    Name: Yup.string().trim().required("Required"),
    Designation: Yup.string().trim().required("Required"),
    LinkedIn: Yup.string().trim().required("Required"),
    Prirority: Yup.string().trim().required("Required"),
    Description: Yup.string().trim().required("Required"),
  });
  const onSubmit = (values, { resetForm }) => {
    resetForm();
  };

  function handleImageChange(e, param1) {
    console.log(param1);
    if (e.target?.files.length !== 0) {
      setFile(URL.createObjectURL(e.target.files[0]));
      e.target.value = null;
    }
  }


  useEffect(() => {
    setLoading(true);
    dispatch(viewParnterAction(params.id, onSuccess, onError)
    );
  }, [params.id]);
  const onSuccess = (data) => {
    setPartnerData(data.data);
    setLoading(false);
    console.log(data.data);
    setImage(data.data?.photo_url?.split("/").pop());
    setError(false)
  };
  const onError = (data) => {
    errorToast(data.data.data);
    setLoading(false);
    setError(true);
  };

  const buttonList = [
    <VersionHistoryBtn link={`/static-pages/our-partners/version-history`} />,
    partnerData.editable && <>
      <EditBtn link={`/static-pages/our-partners/edit/${params.id}`} />,
    </>
  ];
  const data = [
    {
      heading: "Partners id",
      title: partnerData.id,
    },
    {
      heading: "Name",
      title: partnerData.name,
    },
    {
      heading: " Designation",
      title: partnerData.designation,
    },
    {
      heading: "Website URL",
      title:
        partnerData.website_url,
    },
    {
      heading: "Description",
      title:
        partnerData.description,
    },
    {
      heading: "Prirority",
      title: partnerData.prirority,
    },

    {
      heading: "Approved At",
      title: partnerData.approvedAt,
    },

    {
      heading: "Updated At",
      title: partnerData.updatedAt,
    },
  ];
  return (
    <InnerLayout buttons={buttonList}>
      <div className="notify-me-container">
        <InnerContainer>
          {loading ? (
            <LoadingSpinnerTable />
          ) : (
            <LoadAndError loader={loading} error={error}>
              <Formik
                innerRef={formRef}
                initialValues={initialValues}
                onSubmit={onSubmit}
                validationSchema={loginSchema}
              >
                {({ formik, handleChange, values, setFieldValue }) => {
                  return (
                    <Form className="">
                      <div className="d-lg-flex flex-lg-row flex-sm-column">
                        <div className="pt-4 mt-2  ps-4 ps-lg-4 ms-3 col-6 ">
                          <div>
                            {data.map((item, index) => (
                              <div key={item.heading} className="mb-3 ">
                                <h5 className="dark_grey_color fs_13 fn_Montserrat fw_500 m-0">
                                  {item.heading}
                                </h5>
                                <p
                                  className={` fs_13 fn_Nunito fw_500  ${item.heading === "Opportunity ID" ||
                                    item.heading === "LLP ID" ||
                                    item.heading === "Registration Number" ||
                                    item.heading === "Member Count"
                                    ? "secondary_color"
                                    : "dark_title_color"
                                    } `}
                                >
                                  {item.title}
                                </p>
                              </div>
                            ))}
                          </div>
                        </div>
                        <div className=" pt-4 mt-2  ps-4 ps-lg-4 mx-3 col-6">
                          <div className="d-flex flex-xl-row flex-column gap-5">
                            <div>
                              <label className="dark_grey_color fn_Montserrat fw_500 fs_13 ">
                                Profile Picture
                              </label>
                              <div className="personal_information_image_container mt-2 position-relative">
                                <img
                                  onClick={() => {
                                    handleBackImageShow()
                                    setLogo(false)
                                    setProfile(true)
                                  }}
                                  src={partnerData?.photo_url}
                                  className="personal_information_img_size rounded-2"
                                />
                                <a href={partnerData?.photo_url} download="file">
                                  <button
                                    type="button"
                                    className="position-absolute border-0 bg-white d-flex align-items-center fs_10 top-0 end-0"
                                  >
                                    <DownloadIcon width={8} />
                                    <span className="ms-1">Download</span>
                                  </button>
                                </a>
                              </div>
                            </div>
                            <div>
                              <label className="dark_grey_color fn_Montserrat fw_500 fs_13 ">
                                Website Logo
                              </label>
                              <div className="personal_information_image_container mt-2 position-relative">
                                <img
                                  onClick={() => {
                                    handleBackImageShow()
                                    setLogo(true)
                                    setProfile(false)
                                  }}
                                  src={partnerData?.website_icon}
                                  className="personal_information_img_size rounded-2"
                                />
                                <a href={partnerData?.website_icon} download="file">
                                  <button
                                    type="button"
                                    className="position-absolute border-0 bg-white d-flex align-items-center fs_10 top-0 end-0"
                                  >
                                    <DownloadIcon width={8} />
                                    <span className="ms-1">Download</span>
                                  </button>
                                </a>
                              </div>

                            </div>
                          </div>
                        </div>
                      </div>
                    </Form>
                  );
                }}
              </Formik>
            </LoadAndError>
          )}
        </InnerContainer>
      </div>
      {
        logo === true && (
          <OurTeamProfileImage
            handleClose={handleImageClose}
            show={imageShow}
            Profileimage={partnerData?.website_icon}
            modalHeading={"LOGO"}
          />
        )
      }
      {
        profile === true && (
          <OurTeamProfileImage
            handleClose={handleImageClose}
            show={imageShow}
            Profileimage={partnerData?.photo_url}
            modalHeading={"PROFILE PICTURE"}
          />
        )
      }
    </InnerLayout >
  )
}
export default OurPartnerView