import React, { useState } from 'react'
import CreateBtn from '../../../../components/buttons/createBtn'
import ExportBtn from '../../../../components/buttons/exportBtn'
import FilterBtn from '../../../../components/buttons/filterBtn'
import VersionHistoryBtn from '../../../../components/buttons/versionHistoryBtn'
import InnerLayout from '../../../../components/layouts/innerLayout/innerLayout'
import { useDispatch } from "react-redux";
import BlogCategoryFilter from '../../../../components/filter/blog-pagesFilter.js/blogCategoryFilter'
import ReferredUsersApprovalTableContent from '../../../../components/static-pages/referred-users/tables/referredUsersApprovalTableContent'
import { referedUserApprovalsExport } from '../../../../redux/actions/referedUserActions'
import VersionTablesFilter from '../../../../components/filter/versionTableFilter'

const ReferredUsersApprovalTable = () => {
  const [page, setPage] = useState(0);
  const [showFilter, setShowFilter] = useState(false);
  const [search, setSearch] = useState({ value: "" });
  const dispatch = useDispatch();
  const handleFilterClose = () => {
    setShowFilter(false);
  };
  const handleFilterOpen = () => {
    setShowFilter(true);
  };
  const handleSearch = (value) => {
    setSearch({ value: value });
    setPage(0)
  };
  const handlePage = (type) => {
    if (type === "+") {
      setPage((prev) => prev + 1)
    }
    else {
      setPage((prev) => prev - 1)
    }
  }
  const buttonList = [
    <VersionHistoryBtn link='/static-pages/referred-users/version-history' />,
    <CreateBtn link='/static-pages/referred-users/create' />,
    <ExportBtn dispatchAction={referedUserApprovalsExport} />,
    <FilterBtn filterFn={handleFilterOpen} />
  ]
  return (
    <InnerLayout buttons={buttonList}>
      <VersionTablesFilter
        title='Refer id'
        placeholder='Enter refer id'
        filter={handleSearch}
        showFilter={showFilter}
        closeFilter={handleFilterClose}
      />
      <ReferredUsersApprovalTableContent page={page} changePage={handlePage} search={search} />
    </InnerLayout>
  )
}

export default ReferredUsersApprovalTable