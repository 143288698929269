import React, { useRef } from 'react';
import Modal from "react-bootstrap/Modal";
import CloseIcon from "../../assets/icons/closeIcon";
import "./modals.css"


const ResetPassword = ({ show, email, handleClose, popuHeading = "USER PASSWORD RESET" }) => {
    return (
        <Modal
            centered
            show={show}
            onHide={handleClose}
            dialogClassName={`delete_modals_body mx-2 px-1 mx-sm-auto`}
            contentClassName={'delete_modals_body_content  p-1'}
        >
            <Modal.Body>
                <>
                    <div className="d-flex justify-content-between  ">
                        <h2 className='green_color fs_15 fs_sm_13 fn_Montserrat fw_600 pt-2'>{popuHeading}</h2>
                        <button onClick={handleClose}
                            className='light_grey_color  fs_15 fs_sm_13 fn_Montserrat bg-transparent border-0 fw_600 '><span >DISMISS</span>
                            <span className="ms-2"><CloseIcon width={13} height={13} fill="#A5A5A5" /></span>
                        </button>
                    </div>
                    <h1 className='popup_title_color mt-3 fn_Montserrat fw_400 fs_20 reset_pswrd_heading'>Password Reset link has been sent to user {email}</h1>
                    <p className='light_grey_color fw_600 fn_Nunito fs_15'>Lorem ipsum dolor sit amet sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyamerat,</p>
                    <div className='d-flex justify-content-end mt-2'>
                        <button type='submit' onClick={handleClose} className='secondary_color fs_15  fn_Montserrat fw_600 border-0 tertiary_bg px-4 px-lg-5 py-2 py-lg-2 rounded-2'>SUBMIT</button>
                    </div>
                </>
            </Modal.Body>
        </Modal>
    );
};
export default ResetPassword;
