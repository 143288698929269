import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { deleteFaq, viewFaqList } from "../../../../redux/actions/faqAction";
import { TableContainer } from "../../../table/tableContainer";
import { tablebadgesHeader, tableFaqsData, tableFaqsHeader, tableReferHeader } from "../../../table/tableData";
import moment from "moment";
import TablePagination from "../../../table/tablePagination";
import DeleteModal from "../../../modals/deleteModal";
import { deleteFaqApi } from "../../../../redux/apis/faqApi";
import successToast from "../../../utilits/successToast";
import errorToast from "../../../utilits/errorToast";
import usePermissionChecker from "../../../utilits/usePermissionChecker";
import LoadAndError from "../../../utilits/LoadAndError";
import LoadingSpinnerTable from "../../../utilits/loadingSpinnerTable";
import { deleteRefer, viewReferList } from "../../../../redux/actions/referAction";
import { deleteBadges, viewBadgesList } from "../../../../redux/actions/badgesAction";

const BadgesTableContent = ({
  tableContainerClassName = "adminuser_table__container",
  containerWidth = "90%",
  page, search, changePage,
  containerMargin = "1.5rem 0rem",
}) => {
  const access = usePermissionChecker("maker");
  const [userListData, setUserListData] = useState([]);
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);
  const [show, setShow] = useState(false);
  const [deleteData, setDeleteData] = useState({ name: "", id: "" });
  const [loadingTable, setLoadingTable] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [pageData, setPageData] = useState({ noOfItems: 0, noOfPages: 1 })
  console.log(search);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  console.log(search?.name);
  console.log(search?.grtytry);
  useEffect(() => {
    setLoadingTable(true);
    dispatch(viewBadgesList(
      {
        search: {
          badges_id: search?.value?.Id
        }
      },
      page,
      onSuccess,
      onError));
  }, [search, page, loading]);

  const onSuccess = (data) => {
    setUserListData(data?.data);
    console.log(data);
    setLoadingTable(false);
    setPageData({
      noOfItems: data.count,
      noOfPages: data.pages
    })
    setError(false)
    setLoading(false)
  };
  const onError = (data) => {
    console.log(data);
    setLoadingTable(false);
    setError(true)
    setLoading(false)
  };

  function handlePagination(type) {
    if (type === "+") {
      if (page + 1 < pageData.noOfPages) changePage("+");
    }
    else if (type === "-")
      if (page > 0) changePage("-");
  }

  const handleDelete = () => {
    setLoadingDelete(true);
    dispatch(deleteBadges(deleteData?.id, onDeleteSuccess, onDeleteError));
  };
  const onDeleteSuccess = (data) => {
    successToast(data.message);
    setLoadingDelete(false);
    setShow(false);
    setError(false)
    setLoading(false)
  };
  const onDeleteError = (data) => {
    errorToast(data.message);
    setLoadingDelete(false);
    setError(true)
    setLoading(false)
  };
  return (
    <TableContainer width={containerWidth} margin={containerMargin}>
      <div className={`${tableContainerClassName}`}>
        {loading && <LoadingSpinnerTable />}
        <table className={"adminuser_table"}>
          <thead className="adminuser_table__header">
            <tr className="adminuser_table__row">
              {tablebadgesHeader.map((entry) => {
                return (
                  <th
                    key={entry}
                    className={`${entry === "Status" ? `${access ? "" : "text-start"} position-sticky end-0` : null
                      } text-nowrap adminuser_table__heading `}
                  >
                    {entry}
                  </th>
                );
              })}
            </tr>
          </thead>
          {loading == false && (
            <tbody className="adminuser_table__body">
              <LoadAndError loader={loading} error={error} status={userListData.length === 0}>
                {userListData.map((item, index) => {
                  return (
                    <tr key={item?.id} className="adminuser_table__row">
                      <td className="text-nowrap dark_title_color adminuser_table__data ">
                        {page > 0 ? 12 * page + (index + 1) : index + 1}
                      </td>

                      <td className="text-nowrap dark_title_color adminuser_table__data">
                        {item?.id === null ? "--" : item?.id}
                      </td>

                      <td title={item?.question} className="text-nowrap adminuser_table_ellipsis  dark_title_color adminuser_table__data">
                        {item?.question === null ? "--" : item?.question}
                      </td>
                      <td title={item?.answer} className="text-nowrap adminuser_table_ellipsis  dark_title_color adminuser_table__data">
                        {item?.answer === null ? "--" : item?.answer}
                      </td>

                      <td className="text-nowrap secondary_color  adminuser_table__data">
                        {item.badges_faq_checkers?.[0]?.approved_date === null
                          ? "-"
                          : moment(
                            item.badges_faq_checkers?.[0]?.approved_date
                          ).format("DD-MM-YYYY HH:mm:ss")}
                      </td>
                      <td className="text-nowrap  dark_title_color adminuser_table__data">
                        {item.badges_faq_checkers?.[0]?.updatedAt === null
                          ? "-"
                          : moment(
                            item.badges_faq_checkers?.[0]?.updatedAt
                          ).format("DD-MM-YYYY HH:mm:ss")}
                      </td>

                      <td className="text-nowrap  position-sticky end-0 adminuser_table__data">
                        <div className="d-flex w-100   gap-2 justify-content-start">
                          <Link
                            className="text-decoration-none primary_color"
                            to={{
                              pathname: `/static-pages/badges/view/${item?.id}`,
                            }}
                          >
                            View
                          </Link>
                          {
                            access && <>
                              {
                                item.editable && <>
                                  <span className="vr"></span>
                                  <Link
                                    className="text-decoration-none secondary_color"
                                    to={{
                                      pathname: `/static-pages/badges/edit/${item?.id}`,
                                    }}
                                  >
                                    Edit
                                  </Link>
                                  {item.lastActionApproved && (
                                    <>
                                      <span className="vr"></span>{" "}
                                      <button
                                        onClick={() => {
                                          setDeleteData({
                                            name: item?.question,
                                            id: item?.id,
                                          });
                                          handleShow();
                                        }}
                                        className="text-decoration-none border-0 bg-transparent primary_color light_red_color"
                                      >
                                        Delete
                                      </button>
                                    </>)}
                                </>
                              }
                            </>
                          }
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </LoadAndError>
            </tbody>
          )}
        </table>
      </div>
      <DeleteModal
        loader={loadingDelete}
        handleClose={handleClose}
        deleteFn={handleDelete}
        data={deleteData}
        show={show}
      />
      <TablePagination
        paginate={handlePagination}
        currentPage={page}
        pageData={pageData}
      />
    </TableContainer>
  );
};

export default BadgesTableContent;
