
import React from "react";

const SaveIcon = ({ fill = "#112F6A", width = 10, height = 16 }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 14.933 14.933">
      <path id="Icon_material-save" data-name="Icon material-save" d="M16.114,4.5H6.159A1.659,1.659,0,0,0,4.5,6.159V17.773a1.659,1.659,0,0,0,1.659,1.659H17.773a1.664,1.664,0,0,0,1.659-1.659V7.818ZM11.966,17.773a2.489,2.489,0,1,1,2.489-2.489A2.485,2.485,0,0,1,11.966,17.773Zm2.489-8.3h-8.3V6.159h8.3Z" transform="translate(-4.5 -4.5)" fill={fill} />
    </svg>
  );
};

export default SaveIcon;
