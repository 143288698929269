import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import LoadingSpinnerTable from "../../../utilits/loadingSpinnerTable";
import errorToast from "../../../utilits/errorToast";
import successToast from "../../../utilits/successToast";
import DeleteModal from "../../../modals/deleteModal";
import { TableContainer } from "../../../table/tableContainer";
import TablePagination from "../../../table/tablePagination";
import "../../../admin/tables/admintable.css";
import {
  tableNotifyMeHeader,
} from "../../../table/tableData";
import { Link } from "react-router-dom";
import {
  deleteNotifyMe,
  viewAllNotifyMe,
} from "../../../../redux/actions/notifyMeActions";
import moment from "moment";
import { access } from "../../../utilits/access";

const OpportunitiesNotifyMeContent = ({
  tableContainerClassName = "adminuser_table__container",
  notifyMe = [],
  search,
  containerWidth = "90%",
  containerMargin = "1.5rem 0rem",
}) => {
  const dispatch = useDispatch();
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);
  const [show, setShow] = useState(false);
  const [notifyData, setNotifyData] = useState([]);
  const [deleteData, setDeleteData] = useState({ name: "", id: "" });
  const [loading, setLoading] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [page, setPage] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const handleDelete = () => {
    setLoadingDelete(true);
    dispatch(deleteNotifyMe(deleteData.id, onDeleteSuccess, onDeleteError));
  };
  const onDeleteSuccess = (data) => {
    successToast(data.message);
    setLoadingDelete(false);
    setShow(false);
  };
  const onDeleteError = (data) => {
    errorToast(data.message);
    setLoadingDelete(false);
  };
  //pagination
  const pages = Math.ceil(notifyMe?.length === 0 ? 0 : notifyMe.length / 10);
  const notifyMeCount = notifyMe?.length;
  const handlePagination = (value) => {
    if (notifyMeCount > 10) {
      if (value === "+") {
        if (currentPage + 1 !== pages) {
          setCurrentPage((prev) => prev + 1);
        }
      } else if (value === "-")
        if (currentPage > 0) {
          setCurrentPage((prev) => prev - 1);
        }
    }
  };



  console.log(notifyMeCount);


  return (
    <TableContainer width={containerWidth} margin={containerMargin}>
      <div className={`${tableContainerClassName}`}>
        {loading && <LoadingSpinnerTable />}
        <table className={"adminuser_table"}>
          <thead className="adminuser_table__header">
            <tr className="adminuser_table__row">
              {tableNotifyMeHeader.map((item) => {
                return (
                  <th
                    key={item}
                    className={`${item === "Status" ? "position-sticky end-0" : null
                      } text-nowrap adminuser_table__heading`}
                  >
                    {item}
                  </th>
                );
              })}
            </tr>
          </thead>
          {loading == false && (
            <tbody className="adminuser_table__body">
              {notifyMe.map((item, index) => {
                if (currentPage === 0 && index < 10) {
                  return (
                    <tr key={item.id} className="adminuser_table__row">
                      <td className="text-nowrap dark_title_color adminuser_table__data ">
                        {index + 1}
                      </td>
                      <td className="text-nowrap dark_title_color adminuser_table__data">
                        {item.id}
                      </td>
                      <td className="text-nowrap secondary_color adminuser_table__data">
                        {item.opportunity_id === null ? "-" : item.opportunity_id}
                      </td>
                      <td className="text-nowrap secondary_color  adminuser_table__data">
                        {item.user_id === null ? "-" : item.user_id}
                      </td>
                      <td className="text-nowrap  dark_title_color adminuser_table__data">
                        {item.user_name}
                      </td>
                      <td className="text-nowrap  dark_title_color adminuser_table__data">
                        {moment(item.createdAt).format("DD-MM-YYYY HH:mm:ss")}
                      </td>
                      <td className="text-nowrap  dark_title_color adminuser_table__data">
                        {moment(item.updatedAt).format("DD-MM-YYYY HH:mm:ss")}
                      </td>
                      <td className="text-nowrap    adminuser_table__data adminrole_table_empty__data"></td>
                      {access?.opportunity?.maker === true ? (
                        <td className="text-nowrap  position-sticky end-0 adminuser_table__data">
                          <div className="d-flex w-100   gap-2">
                            <Link
                              className="text-decoration-none primary_color"
                              to={{
                                pathname: `/opportunity/notify-me/view/${item.id}`,
                              }}
                            >
                              View
                            </Link>{" "}
                            <Link
                              className="text-decoration-none secondary_color"
                              to={{
                                pathname: `/opportunity/notify-me/edit/${item.id}`,
                              }}
                            >
                              Edit
                            </Link>{" "}
                            <span className="vr"></span>{" "}
                            <button
                              onClick={() => {
                                setDeleteData({
                                  name: item.user_name,
                                  id: item.id,
                                });
                                handleShow();
                              }}
                              className="text-decoration-none border-0 bg-transparent primary_color light_red_color"
                            >
                              Delete
                            </button>
                          </div>
                        </td>
                      ) : (
                        <td className="text-nowrap  position-sticky end-0 adminuser_table__data">
                          <div className="d-flex w-100   gap-2">
                            <Link
                              className="text-decoration-none primary_color"
                              to={{
                                pathname: `/notify-me/notify-me-read/${item.id}`,
                              }}
                            >
                              View
                            </Link>{" "}
                          </div>
                        </td>
                      )}
                    </tr>
                  );
                }
                else if (
                  currentPage > 0 &&
                  index + 1 >= currentPage * 10 + 1 &&
                  index + 1 < (currentPage + 1) * 10 + 1
                ) {
                  return (
                    <tr key={item.id} className="adminuser_table__row">
                      <td className="text-nowrap dark_title_color adminuser_table__data ">
                        {index + 1}
                      </td>
                      <td className="text-nowrap dark_title_color adminuser_table__data">
                        {item.id}
                      </td>
                      <td className="text-nowrap secondary_color adminuser_table__data">
                        {item.opportunity_id === null ? "-" : item.opportunity_id}
                      </td>
                      <td className="text-nowrap secondary_color  adminuser_table__data">
                        {item.user_id === null ? "-" : item.user_id}
                      </td>
                      <td className="text-nowrap  dark_title_color adminuser_table__data">
                        {item.user_name}
                      </td>
                      <td className="text-nowrap  dark_title_color adminuser_table__data">
                        {moment(item.createdAt).format("DD-MM-YYYY HH:mm:ss")}
                      </td>
                      <td className="text-nowrap  dark_title_color adminuser_table__data">
                        {moment(item.updatedAt).format("DD-MM-YYYY HH:mm:ss")}
                      </td>
                      <td className="text-nowrap    adminuser_table__data adminrole_table_empty__data"></td>
                      {access?.opportunity?.maker === true ? (
                        <td className="text-nowrap  position-sticky end-0 adminuser_table__data">
                          <div className="d-flex w-100   gap-2">
                            <Link
                              className="text-decoration-none primary_color"
                              to={{
                                pathname: `/opportunity/notify-me/view/${item.id}`,
                              }}
                            >
                              View
                            </Link>{" "}
                            <Link
                              className="text-decoration-none secondary_color"
                              to={{
                                pathname: `/opportunity/notify-me/edit/${item.id}`,
                              }}
                            >
                              Edit
                            </Link>{" "}
                            <span className="vr"></span>{" "}
                            <button
                              onClick={() => {
                                setDeleteData({
                                  name: item.user_name,
                                  id: item.id,
                                });
                                handleShow();
                              }}
                              className="text-decoration-none border-0 bg-transparent primary_color light_red_color"
                            >
                              Delete
                            </button>
                          </div>
                        </td>
                      ) : (
                        <td className="text-nowrap  position-sticky end-0 adminuser_table__data">
                          <div className="d-flex w-100   gap-2">
                            <Link
                              className="text-decoration-none primary_color"
                              to={{
                                pathname: `/notify-me/notify-me-read/${item.id}`,
                              }}
                            >
                              View
                            </Link>{" "}
                          </div>
                        </td>
                      )}
                    </tr>
                  );
                }

              })}
            </tbody>
          )}
        </table>
      </div>
      <DeleteModal
        loader={loadingDelete}
        handleClose={handleClose}
        deleteFn={handleDelete}
        data={deleteData}
        show={show}
      />
      <TablePagination
        limit={10}
        paginate={handlePagination}
        currentPage={currentPage}
        pageData={{
          noOfItems: notifyMeCount ? notifyMeCount : 0,
          noOfPages: pages === 0 ? 1 : pages,
        }}
      />
    </TableContainer>
  );
};

export default OpportunitiesNotifyMeContent;
