import { call, takeLatest } from "redux-saga/effects";
import { APPROVE_INVITED, CREATE_INVITED, DELETE_INVITED, EDIT_INVITED, INVITED_APPROVALS_EXPORT, INVITED_APPROVAL_TABLE, INVITED_APPROVAL_VIEW, INVITED_EXPORT, INVITED_OPTIONS, INVITED_REFERRAL_OPPOR_ID, INVITED_SUBMIT, INVITED_TABLE, INVITED_VERSION_EXPORT, INVITED_VERSION_HISTORY, INVITED_VERSION_HISTORY_TABLE, REJECT_INVITED, VIEW_INVITED } from "../actions/types";
import { approveInvitedApi, createInvitedApi, deleteInvitedApi, editInvitedApi, invitedApprovalByIdApi, invitedApprovalListApi, invitedApprovalsExportApi, invitedReferralOptionsApi, invitedSubmitApi, invitedTableExportApi, invitedVersionExportApi, rejectInvitedApi, versionHistoryInvitedDetailApi, versionHistoryInvitedListApi, viewInvitedDetailApi, viewInvitedListApi } from "../apis/invitedApi";

export function* createInvitedWorker(action) {
    try {
        const res = yield call(createInvitedApi,
            action.data
        );
        console.log(res);

        if (res.status === 200 && res.data.status === "success") {
            yield action.onSuccess(res.data);
        } else {
            yield action.onError(res.data);
        }
    } catch (error) { }
}

export function* approveInvitedWorker(action) {
    try {
        const res = yield call(approveInvitedApi, {
            refer_id: action.id,
        });
        console.log(res);
        if (res.status === 200 && res.data.status === "success") {
            yield action.onSuccess(res.data);
        } else {
            yield action.onError(res.data);
        }
    } catch (error) { }
}

export function* rejectInvitedWorker(action) {
    try {
        const res = yield call(rejectInvitedApi, action.id);
        console.log(res);
        if (res.status === 200 && res.data.status === "success") {
            yield action.onSuccess(res.data);
        } else {
            yield action.onError(res.data);
        }
    } catch (error) { }
}


export function* viewInvitedDetailWorker(action) {
    try {
        const res = yield call(viewInvitedDetailApi, action.id);
        console.log(res);
        if (res.status === 200 && res.data.status === "success") {
            yield action.onSuccess(res.data);
        } else {
            yield action.onError(res.data);
        }
    } catch (error) { }
}


export function* viewInvitedListWorker(action) {
    try {

        const res = yield call(viewInvitedListApi,
            action.page_no,
            action.data,
        );
        console.log(res);
        if (res.status === 200 && res.data.status === "success") {
            yield action.onSuccess(res.data.data);
            console.log(res.data.data);
        } else {
            yield action.onError(res.data);
        }
    } catch (error) { }
}

export function* editInvitedWorker(action) {
    try {
        const res = yield call(editInvitedApi, action.id, action.data);
        console.log(res);
        if (res.status === 200 && res.data.status === "success") {
            yield action.onSuccess(res.data);
        } else {
            yield action.onError(res.data);
        }
    } catch (error) { }
}


export function* deleteInvitedWorker(action) {
    try {
        const res = yield call(deleteInvitedApi, action.id);
        console.log(res);
        if (res.status === 200 && res.data.status === "success") {
            yield action.onSuccess(res.data);
        } else {
            yield action.onError(res.data);
        }
    } catch (error) { }
}




export function* invitedApprovalListWorker(action) {
    console.log(action.search,
        action.page_no,)
    try {
        const res = yield call(invitedApprovalListApi,
            action.page_no,
            action.data,
        );
        if (res.status === 200 && res.data.status === "success") {
            yield action.onSuccess(res.data.data);
            console.log(res.data);
        } else {
            yield action.onError(res.data);
        }
    } catch (error) { }
}



export function* invitedApprovalByIdWorker(action) {
    try {
        const res = yield call(invitedApprovalByIdApi, action.id);
        console.log(res);
        if (res.status === 200 && res.data.status === "success") {
            yield action.onSuccess(res.data);
            console.log(res.data)
        } else {
            yield action.onError(res.data);
        }
    } catch (error) { }
}



export function* versionHistoryInvitedListWorker(action) {
    try {
        const res = yield call(versionHistoryInvitedListApi,
            action.page,
            action.data,
        );
        console.log(res);
        if (res.status === 200 && res.data.status === "success") {
            yield action.onSuccess(res.data);
        } else {
            yield action.onError(res.data);
        }
    } catch (error) { }
}

export function* versionHistoryInvitedDetailWorker(action) {
    try {
        const res = yield call(versionHistoryInvitedDetailApi,
            action.id,
        );
        console.log(res);
        if (res.status === 200 && res.data.status === "success") {
            yield action.onSuccess(res.data);
        } else {
            yield action.onError(res.data);
        }
    } catch (error) { }
}




/*********WATCHERS********/

export function* invitedTableExportWorker(action) {
    try {
        const res = yield call(invitedTableExportApi,

        );
        if (res.status === 200 && res.data.status === "success") {
            yield action.onSuccess(res.data);
        } else {
            yield action.onError(res.data);
        }
    } catch (error) { }
}


export function* invitedApprovalsExportWorker(action) {
    try {
        const res = yield call(invitedApprovalsExportApi);
        console.log(res);
        if (res.status === 200 && res.data.status === "success") {
            console.log(res.data);
            yield action.onSuccess(res.data.data);
        } else {
            yield action.onError(res.data);
        }
    } catch (error) { }
}

export function* invitedVersionExportWorker(action) {
    try {
        const res = yield call(invitedVersionExportApi);
        console.log(res);
        if (res.status === 200 && res.data.status === "success") {
            yield action.onSuccess(res.data);
        } else {
            yield action.onError(res.data);
        }
    } catch (error) { }
}



export function* invitedSubmitWorker(action) {
    try {
        const res = yield call(invitedSubmitApi, action.id);
        console.log(res);
        if (res.status === 200 && res.data.status === "success") {
            yield action.onSuccess(res.data);
        } else {
            yield action.onError(res.data);
        }
    } catch (error) { }
}
//referral opp id

export function* invitedReferralOptionsWorker(action) {
    try {
        const res = yield call(invitedReferralOptionsApi);
        console.log(res);
        if (res.status === 200 && res.data.status === "success") {
            yield action.onSuccess(res.data);
        } else {
            yield action.onError(res.data);
        }
    } catch (error) { }
}

export function* createInvitedWorkerWatcher() {
    yield takeLatest(CREATE_INVITED, createInvitedWorker);
}
export function* approveInvitedWorkerWatcher() {
    yield takeLatest(APPROVE_INVITED, approveInvitedWorker);
}
export function* rejectInvitedWorkerWatcher() {
    yield takeLatest(REJECT_INVITED, rejectInvitedWorker);
}
export function* viewInvitedDetailWatcherWorker() {
    yield takeLatest(VIEW_INVITED, viewInvitedDetailWorker);
}
export function* viewInvitedListWorkerWatcher() {
    yield takeLatest(INVITED_TABLE, viewInvitedListWorker);
}
export function* editInvitedWorkerWatcher() {
    yield takeLatest(EDIT_INVITED, editInvitedWorker);
}
export function* deleteInvitedWorkerWatcher() {
    yield takeLatest(DELETE_INVITED, deleteInvitedWorker);
}
export function* invitedApprovalListWatcherWorker() {
    yield takeLatest(INVITED_APPROVAL_TABLE, invitedApprovalListWorker);
}
export function* invitedApprovalByIdWatcher() {
    yield takeLatest(INVITED_APPROVAL_VIEW, invitedApprovalByIdWorker);
}
export function* versionHistoryInvitedListWatcher() {
    yield takeLatest(INVITED_VERSION_HISTORY_TABLE, versionHistoryInvitedListWorker);
}
export function* versionHistoryInvitedDetailWatcher() {
    yield takeLatest(
        INVITED_VERSION_HISTORY,
        versionHistoryInvitedDetailWorker
    );
}
/****exports*****/
export function* invitedTableExportWatcher() {
    yield takeLatest(
        INVITED_EXPORT,
        invitedTableExportWorker
    );
}
export function* invitedApprovalsExportWatcher() {
    yield takeLatest(INVITED_APPROVALS_EXPORT, invitedApprovalsExportWorker);
}
export function* invitedVersionExportWorkerWatcher() {
    yield takeLatest(INVITED_VERSION_EXPORT, invitedVersionExportWorker);
}
export function* invitedSubmitWorkerWatcher() {
    yield takeLatest(INVITED_SUBMIT, invitedSubmitWorker);
}
export function* invitedReferralOptionsWorkerWatcher() {
    yield takeLatest(INVITED_REFERRAL_OPPOR_ID, invitedReferralOptionsWorker);
}