import { call, takeLatest } from "redux-saga/effects";
import { APPROVE_REFER, CREATE_REFER, DELETE_REFER, EDIT_REFER, REFER_APPROVALS_EXPORT, REFER_APPROVAL_TABLE, REFER_APPROVAL_VIEW, REFER_EXPORT, REFER_OPTIONS, REFER_SUBMIT, REFER_TABLE, REFER_VERSION_EXPORT, REFER_VERSION_HISTORY, REFER_VERSION_HISTORY_TABLE, REJECT_REFER, VIEW_REFER } from "../actions/types";
import { approveReferApi, createReferApi, deleteReferApi, editReferApi, referApprovalByIdApi, referApprovalListApi, referApprovalsExportApi, referSubmitApi, referTableExportApi, referVersionExportApi, rejectReferApi, versionHistoryReferDetailApi, versionHistoryReferListApi, viewReferDetailApi, viewReferListApi } from "../apis/referApi";

export function* createReferWorker(action) {
    try {
        const res = yield call(createReferApi,
            action.data
        );
        console.log(res);

        if (res.status === 200 && res.data.status === "success") {
            yield action.onSuccess(res.data);
        } else {
            yield action.onError(res.data);
        }
    } catch (error) { }
}

export function* approveReferWorker(action) {
    try {
        const res = yield call(approveReferApi, {
            refer_id: action.id,
        });
        console.log(res);
        if (res.status === 200 && res.data.status === "success") {
            yield action.onSuccess(res.data);
        } else {
            yield action.onError(res.data);
        }
    } catch (error) { }
}

export function* rejectReferWorker(action) {
    try {
        const res = yield call(rejectReferApi, action.id);
        console.log(res);
        if (res.status === 200 && res.data.status === "success") {
            yield action.onSuccess(res.data);
        } else {
            yield action.onError(res.data);
        }
    } catch (error) { }
}


export function* viewReferDetailWorker(action) {
    try {
        const res = yield call(viewReferDetailApi, action.id);
        console.log(res);
        if (res.status === 200 && res.data.status === "success") {
            yield action.onSuccess(res.data);
        } else {
            yield action.onError(res.data);
        }
    } catch (error) { }
}


export function* viewReferListWorker(action) {
    try {

        const res = yield call(viewReferListApi,
            action.page_no,
            action.data,
        );
        console.log(res);
        if (res.status === 200 && res.data.status === "success") {
            yield action.onSuccess(res.data.data);
            console.log(res.data.data);
        } else {
            yield action.onError(res.data);
        }
    } catch (error) { }
}

export function* editReferWorker(action) {
    try {
        const res = yield call(editReferApi, action.id, action.data);
        console.log(res);
        if (res.status === 200 && res.data.status === "success") {
            yield action.onSuccess(res.data);
        } else {
            yield action.onError(res.data);
        }
    } catch (error) { }
}


export function* deleteReferWorker(action) {
    try {
        const res = yield call(deleteReferApi, action.id);
        console.log(res);
        if (res.status === 200 && res.data.status === "success") {
            yield action.onSuccess(res.data);
        } else {
            yield action.onError(res.data);
        }
    } catch (error) { }
}




export function* referApprovalListWorker(action) {
    console.log(action.search,
        action.page_no,)
    try {
        const res = yield call(referApprovalListApi,
            action.page_no,
            action.data,
        );
        if (res.status === 200 && res.data.status === "success") {
            yield action.onSuccess(res.data.data);
            console.log(res.data);
        } else {
            yield action.onError(res.data);
        }
    } catch (error) { }
}



export function* referApprovalByIdWorker(action) {
    try {
        const res = yield call(referApprovalByIdApi, action.id);
        console.log(res);
        if (res.status === 200 && res.data.status === "success") {
            yield action.onSuccess(res.data);
            console.log(res.data)
        } else {
            yield action.onError(res.data);
        }
    } catch (error) { }
}



export function* versionHistoryReferListWorker(action) {
    try {
        const res = yield call(versionHistoryReferListApi,
            action.page,
            action.data,
        );
        console.log(res);
        if (res.status === 200 && res.data.status === "success") {
            yield action.onSuccess(res.data);
        } else {
            yield action.onError(res.data);
        }
    } catch (error) { }
}

export function* versionHistoryReferDetailWorker(action) {
    try {
        const res = yield call(versionHistoryReferDetailApi,
            action.id,
        );
        console.log(res);
        if (res.status === 200 && res.data.status === "success") {
            yield action.onSuccess(res.data);
        } else {
            yield action.onError(res.data);
        }
    } catch (error) { }
}




/*********WATCHERS********/

export function* referTableExportWorker(action) {
    try {
        const res = yield call(referTableExportApi,

        );
        if (res.status === 200 && res.data.status === "success") {
            yield action.onSuccess(res.data);
        } else {
            yield action.onError(res.data);
        }
    } catch (error) { }
}


export function* referApprovalsExportWorker(action) {
    try {
        const res = yield call(referApprovalsExportApi);
        console.log(res);
        if (res.status === 200 && res.data.status === "success") {
            console.log(res.data);
            yield action.onSuccess(res.data.data);
        } else {
            yield action.onError(res.data);
        }
    } catch (error) { }
}

export function* referVersionExportWorker(action) {
    try {
        const res = yield call(referVersionExportApi);
        console.log(res);
        if (res.status === 200 && res.data.status === "success") {
            yield action.onSuccess(res.data);
        } else {
            yield action.onError(res.data);
        }
    } catch (error) { }
}



export function* referSubmitWorker(action) {
    try {
        const res = yield call(referSubmitApi, action.id);
        console.log(res);
        if (res.status === 200 && res.data.status === "success") {
            yield action.onSuccess(res.data);
        } else {
            yield action.onError(res.data);
        }
    } catch (error) { }
}






export function* createReferWorkerWatcher() {
    yield takeLatest(CREATE_REFER, createReferWorker);
}

export function* approveReferWorkerWatcher() {
    yield takeLatest(APPROVE_REFER, approveReferWorker);
}
export function* rejectReferWorkerWatcher() {
    yield takeLatest(REJECT_REFER, rejectReferWorker);
}
export function* viewReferDetailWatcherWorker() {
    yield takeLatest(VIEW_REFER, viewReferDetailWorker);
}
export function* viewReferListWorkerWatcher() {
    yield takeLatest(REFER_TABLE, viewReferListWorker);
}
export function* editReferWorkerWatcher() {
    yield takeLatest(EDIT_REFER, editReferWorker);

}
export function* deleteReferWorkerWatcher() {
    yield takeLatest(DELETE_REFER, deleteReferWorker);
}

export function* ReferApprovalListWatcherWorker() {
    yield takeLatest(REFER_APPROVAL_TABLE, referApprovalListWorker);
}

export function* ReferApprovalByIdWatcher() {
    yield takeLatest(REFER_APPROVAL_VIEW, referApprovalByIdWorker);
}



export function* versionHistoryReferListWatcher() {
    yield takeLatest(REFER_VERSION_HISTORY_TABLE, versionHistoryReferListWorker);
}
export function* versionHistoryReferDetailWatcher() {
    yield takeLatest(
        REFER_VERSION_HISTORY,
        versionHistoryReferDetailWorker
    );
}


/****exports*****/

export function* ReferTableExportWatcher() {
    yield takeLatest(
        REFER_EXPORT,
        referTableExportWorker
    );
}
export function* ReferApprovalsExportWatcher() {
    yield takeLatest(REFER_APPROVALS_EXPORT, referApprovalsExportWorker);
}

export function* ReferVersionExportWorkerWatcher() {
    yield takeLatest(REFER_VERSION_EXPORT, referVersionExportWorker);
}



export function* ReferSubmitWorkerWatcher() {
    yield takeLatest(REFER_SUBMIT, referSubmitWorker);
}