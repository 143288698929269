import { ADMIN_LOGIN, ADMIN_LOGOUT } from "./types";

export const login = (email, password, onSuccess, onError) => {
  return {
    type: ADMIN_LOGIN,
    email,
    password,
    onSuccess,
    onError,
  };
};

export const logout = (onSuccess, onError) => {
  return{
    type: ADMIN_LOGOUT,
    onSuccess,
    onError,
  };
}
