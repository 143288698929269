import { TableContainer } from '../../../table/tableContainer';
import TablePagination from '../../../table/tablePagination';
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import "../../../admin/tables/admintable.css"
import { Link } from 'react-router-dom';
import { tableNotificationApprovalsHeader, tableNotifyMeApprovalsHeader, tableNotifyMeApprovalsRow, tableSocialApprovalsHeader } from '../../../table/tableData';
import { notifyMeApprovalsTable } from '../../../../redux/actions/notifyMeActions';
import errorToast from '../../../utilits/errorToast';
import LoadingSpinnerTable from '../../../utilits/loadingSpinnerTable';
import moment from "moment";
import { notificationApprovalsTable } from '../../../../redux/actions/notificationActions';
import { socialApprovalsTable } from '../../../../redux/actions/socialActions';
const SocialApprovalsTableContent = ({ page, search, changePage }) => {
    const dispatch = useDispatch();
    const [NotifyMeApprovalsData, setNotifyMeApprovalsData] = useState([])
    const [loading, setLoading] = useState(false);
    const [pageData, setPageData] = useState({ noOfItems: 0, noOfPages: 1 })

    useEffect(() => {
        setLoading(true)
        dispatch(
            socialApprovalsTable(
                {
                    "search": {
                        "social_id": search?.value?.Id
                    }
                },
                page,
                onSuccess,
                onError)
        );
    }, [page, search])
    console.log(search.notifyId);
    console.log(search.opportunityId);
    console.log(search.oppName);
    console.log(search.userId);
    console.log(search.user_name);
    const onSuccess = (data) => {
        setNotifyMeApprovalsData(data.data.data);
        setLoading(false)
        setPageData({
            noOfItems: data.data.count,
            noOfPages: data.data.pages
        })
    };
    const onError = (data) => {
        errorToast(data.message)
        setLoading(false)
    };
    console.log(NotifyMeApprovalsData);

    //pagination
    function handlePagination(type) {
        if (type === "+") {
            if (page + 1 < pageData.noOfPages) changePage("+");
        }
        else if (type === "-")
            if (page > 0) changePage("-");
    }
    return (
        <TableContainer width="90%">
            <div className="adminuser_table__container">
                {loading &&
                    <LoadingSpinnerTable />}
                <table className={"adminuser_table"}>
                    <thead className="adminuser_table__header">
                        <tr className="adminuser_table__row">
                            {tableSocialApprovalsHeader.map((item) => {
                                return (
                                    <th
                                        key={item + "x"}
                                        className={`${item === "Status" ? " position-sticky end-0 text-center" : ""} text-nowrap adminuser_table__heading`}
                                    >
                                        {item}
                                    </th>
                                );
                            })}
                        </tr>
                    </thead>
                    <tbody className="adminuser_table__body">
                        {NotifyMeApprovalsData.map((item, index) => {
                            return (
                                <tr key={item.id} className="adminuser_table__row">
                                    <td className="text-nowrap dark_title_color adminuser_table__data ">
                                        {page > 0 ? 12 * page + (index + 1) : index + 1}
                                    </td>
                                    <td className="text-nowrap dark_title_color adminuser_table__data ">
                                        {item.id == null ? "-" : item.id}
                                    </td>
                                    <td className="text-nowrap dark_title_color adminuser_table__data ">
                                        {item.social_id == null ? "-" : item.social_id}
                                    </td>
           
                                    {/* <td className="text-nowrap secondary_color adminuser_table__data">
                                        {item?.new_data == null ? item?.old_data?.user_name : item?.new_data?.user_name}
                                    </td> */}
                                    <td className="text-nowrap dark_title_color  adminuser_table__data">
                                        {item?.new_data == null ? item?.old_data?.is_testimonial === true ? "True" : "False" : item?.new_data?.is_testimonial === true ? "True" : "False"}
                                    </td>
                                    <td className="text-nowrap dark_title_color  adminuser_table__data">
                                        {item?.new_data == null ? item?.old_data?.link_url  : item?.new_data?.link_url}
                                    </td>
                                    <td className="text-nowrap dark_title_color  adminuser_table__data">
                                        {item.updated_by === null ? "-" : item.updated_by}
                                    </td>
                                    <td className="text-nowrap dark_title_color  adminuser_table__data">
                                        {moment(item.updatedAt).format("DD-MM-YYYY HH:mm:ss")}
                                    </td>
                                    <td className="text-nowrap  position-sticky end-0 adminuser_table__data">
                                        <div className="d-flex w-100  justify-content-center gap-2">
                                            <Link
                                                className="text-decoration-none primary_color"
                                                to={{
                                                    pathname: `/static-pages/social/approvals/view/${item.id}`,
                                                }}
                                            >
                                                View
                                            </Link>
                                        </div>
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>
            <TablePagination
                paginate={handlePagination}
                currentPage={page}
                pageData={pageData}
            />
        </TableContainer>
    )
}

export default SocialApprovalsTableContent