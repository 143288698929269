import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "../../../admin/tables/admintable.css";
import errorToast from "../../../utilits/errorToast";
import { TableContainer } from "../../../table/tableContainer";
import TablePagination from "../../../table/tablePagination";
import LoadingSpinnerTable from "../../../utilits/loadingSpinnerTable";
import {
  tableLlpMemberHeader,
  llpMemberTableData,
} from "../../../table/tableData";
import DeleteModal from "../../../modals/deleteModal";
import { useDispatch } from "react-redux";
import { llpMemberDeleteAction } from "../../../../redux/actions/llpActions";
import successToast from "../../../utilits/successToast";
import moment from "moment";

const LlpMemberTable = ({ llpdata = [], showModal, updated, access }) => {
  const [loading, setLoading] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const dispatch = useDispatch();
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);
  const [show, setShow] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [deleteData, setDeleteData] = useState({ name: "", id: "" });
  const handleDelete = () => {
    setLoadingDelete(true);
    dispatch(
      llpMemberDeleteAction(deleteData?.id, onDeleteSuccess, onDeleteError)
    );
  };
  const onDeleteSuccess = (data) => {
    successToast(data.data.message);
    setLoadingDelete(false);
    setDeleteData({ name: "", id: "" });
    updated();
    handleClose();
  };
  const onDeleteError = (data) => {
    errorToast(data.data.data);
    setLoadingDelete(false);
  };
  const pages = Math.ceil(llpdata?.length === 0 ? 0 : llpdata.length / 10);
  const llpDataCount = llpdata?.length;
  const handlePagination = (value) => {
    if (llpDataCount > 10) {
      if (value === "+") {
        if (currentPage + 1 !== pages) {
          setCurrentPage((prev) => prev + 1);
        }
      } else if (value === "-")
        if (currentPage > 0) {
          setCurrentPage((prev) => prev - 1);
        }
    }
  };
  return (
    <TableContainer width="90%">
      <div className="memberuser-table-container">
        {loading && <LoadingSpinnerTable />}
        <table className={"adminuser_table"}>
          <thead className="adminuser_table__header">
            <tr className="adminuser_table__row">
              {tableLlpMemberHeader.map((item) => {
                return (
                  <th
                    key={item + "x"}
                    className={`${
                      item === "Status"
                        ? " position-sticky end-0 text-center"
                        : ""
                    } text-nowrap adminuser_table__heading`}
                  >
                    {item}
                  </th>
                );
              })}
            </tr>
          </thead>
          {loading === false && (
            <tbody className="adminuser_table__body">
              {llpdata?.map((item, index) => {
                if (currentPage === 0 && index < 10) {
                  return (
                    <tr key={item?.id} className="adminuser_table__row">
                      <td className="text-nowrap dark_title_color adminuser_table__data ">
                        {index + 1}
                      </td>
                      <td className="text-nowrap dark_title_color  adminuser_table__data">
                        {item?.user?.user_name}
                      </td>
                      <td className="text-nowrap dark_title_color  adminuser_table__data">
                        {item?.amount_invested}
                      </td>
                      <td className="text-nowrap  adminuser_table__data ">
                        <Link
                          className="secondary_color text-decoration-none "
                          to={`/user/user-list/view/${item?.user_id}`}
                        >
                          {item?.user_id}
                        </Link>
                      </td>
                      <td className="text-nowrap dark_title_color  adminuser_table__data">
                        {item?.traded_date
                          ? moment(item.payout_start_date).format(
                              "DD-MM-YYYY HH:mm:ss"
                            )
                          : "-"}
                      </td>

                     
                      <td className="text-nowrap dark_title_color  adminuser_table__data">
                        {item?.traded_date
                          ? moment(item.traded_date).format(
                              "DD-MM-YYYY HH:mm:ss"
                            )
                          : "-"}
                      </td>
                      <td className="text-nowrap dark_title_color  adminuser_table__data">
                        {item?.traded_amount ?? "-"}
                      </td>

                      <td className="text-nowrap  position-sticky end-0 adminuser_table__data">
                        <div className="d-flex w-100  justify-content-center gap-2">
                          <span
                            className="text-decoration-none cursor_pointer primary_color"
                            onClick={() => showModal("view", item)}
                          >
                            View
                          </span>
                          {access && (
                            <>
                              <span className="vr"></span>
                              <span
                                className="text-decoration-none cursor_pointer secondary_color"
                                onClick={() => showModal("edit", item)}
                              >
                                Edit
                              </span>
                              <span className="vr"></span>
                              <button
                                onClick={() => {
                                  setDeleteData({
                                    name: item?.user?.user_name,
                                    id: item?.id,
                                  });
                                  handleShow();
                                }}
                                className="text-decoration-none border-0 bg-transparent primary_color light_red_color"
                              >
                                Delete
                              </button>
                            </>
                          )}
                        </div>
                      </td>
                    </tr>
                  );
                } else if (
                  currentPage > 0 &&
                  index + 1 >= currentPage * 10 + 1 &&
                  index + 1 < (currentPage + 1) * 10 + 1
                ) {
                  return (
                    <tr key={item?.id} className="adminuser_table__row">
                      <td className="text-nowrap dark_title_color adminuser_table__data ">
                        {index + 1}
                      </td>
                      <td className="text-nowrap dark_title_color  adminuser_table__data">
                        {item?.user?.user_name}
                      </td>
                      <td className="text-nowrap dark_title_color  adminuser_table__data">
                        {item?.amount_invested}
                      </td>{" "}
                      <td className="text-nowrap  adminuser_table__data ">
                        <Link
                          className="secondary_color text-decoration-none "
                          to={`/user/user-list/view/${item?.user_id}`}
                        >
                          {item?.user_id}
                        </Link>
                      </td>
                      <td className="text-nowrap dark_title_color  adminuser_table__data">
                        {item?.traded_date
                          ? moment(item.traded_date).format(
                              "DD-MM-YYYY HH:mm:ss"
                            )
                          : "-"}
                      </td>
                      <td className="text-nowrap dark_title_color  adminuser_table__data">
                        {item?.traded_amount ?? "-"}
                      </td>
                      <td className="text-nowrap  position-sticky end-0 adminuser_table__data">
                        <div className="d-flex w-100  justify-content-center gap-2">
                          <span
                            className="text-decoration-none primary_color cursor_pointer"
                            onClick={() => showModal("view", item)}
                          >
                            View
                          </span>
                          {access && (
                            <>
                              <span className="vr"></span>
                              <span
                                className="text-decoration-none secondary_color cursor_pointer"
                                onClick={() => showModal("edit", item)}
                              >
                                Edit
                              </span>
                              <span className="vr"></span>
                              <button
                                onClick={() => {
                                  setDeleteData({
                                    name: item?.user?.user_name,
                                    id: item?.id,
                                  });
                                  handleShow();
                                }}
                                className="text-decoration-none border-0 bg-transparent primary_color light_red_color"
                              >
                                Delete
                              </button>
                            </>
                          )}
                        </div>
                      </td>
                    </tr>
                  );
                }
              })}
            </tbody>
          )}
        </table>
      </div>
      <DeleteModal
        loader={loadingDelete}
        handleClose={handleClose}
        deleteFn={handleDelete}
        data={deleteData}
        show={show}
      />
      <TablePagination
        limit={10}
        paginate={handlePagination}
        currentPage={currentPage}
        pageData={{
          noOfItems: llpdata?.length ? llpdata?.length : 0,
          noOfPages: pages === 0 ? 1 : pages,
        }}
      />
    </TableContainer>
  );
};

export default LlpMemberTable;
