import React, { useRef, useEffect, useState } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import InnerLayout from "../../../../components/layouts/innerLayout/innerLayout";
import InnerContainer from "../../../../components/layouts/innerContainer/innerContainer";
import SelectFields from "../../../../components/form/selectFields/selectFields";
import { useNavigate } from "react-router-dom";
import SaveBtn from "../../../../components/buttons/saveBtn";
import DeleteBtn from "../../../../components/buttons/deleteBtn";
import InputField from "../../../../components/form/inputFields/inputField";
import ErrorMsg from "../../../../components/form/errorMsg";
import { createFaq, faqOptions, FaqOptions } from "../../../../redux/actions/faqAction";
import { useDispatch } from "react-redux";
import successToast from "../../../../components/utilits/successToast";
import errorToast from "../../../../components/utilits/errorToast";
import { createRefer } from "../../../../redux/actions/referAction";
import { opportunitiesNameListAction } from "../../../../redux/actions/opportunitiesActions";
import { createInvited, invitedReferralOptions } from "../../../../redux/actions/invitedAction";

const InvitedCreate = () => {
  const phoneRegExp = /^[0-9]{10}$/;
  const navigate = useNavigate();
  const formRef = useRef();
  const dispatch = useDispatch()
  const [opportunityType, setOpportunityType] = useState([]);
  // useEffect(() => {
  //   dispatch(faqOptions(onNotifyMeSuccess, onNotifyMeError));
  // }, []);
  // const opportunityTypeOptions = [];
  // const onNotifyMeSuccess = (data) => {
  //   data?.data.map((item, index) => {
  //     opportunityTypeOptions[index] = { value: item.id, label: item.name };
  //   });
  //   console.log(opportunityTypeOptions);
  //   setOpportunityType(opportunityTypeOptions);
  //   console.log("SUCCESS");
  // };
  // const onNotifyMeError = (data) => {
  //   console.log("ERROR");
  // };

  const initialValues = {
    name: "",
    phone: "",
    email: "",
    opportunity: "",
  };

  const interestEditSchema = Yup.object().shape({
    name: Yup.string("").required("Required"),
    phone: Yup.string()
      .required("Required")
      .matches(phoneRegExp, "Please Enter Valid Phone Number"),
    email: Yup.string().trim().email().required("Required"),

  });

  const handleSubmit = () => {
    if (formRef.current) {
      formRef.current.handleSubmit();
    }
  };
  const onSubmit = (values, { resetForm }) => {
    console.log(values);
    dispatch(
      createInvited(
        {
          "user_name": values.name,
          "phone_number": values.phone,
          "email": values.email,
          "opportunity_id": values.opportunity
        },
        onSuccess,
        onError
      )
    );
  };


  const onSuccess = (data) => {
    successToast(data.message);
    navigate(`/static-pages/invited/edit/${data?.data?.id}`)
  };

  const onError = (data) => {
    errorToast(data.data);
  };

  const buttonList = [<SaveBtn submitFn={handleSubmit} />];
  //opp id
  const [opportunitiesList, setOpportunitiesList] = useState([])
  useEffect(() => {
    dispatch(invitedReferralOptions(onSuccessOpt, onErrorOpt));
  }, []);
  const onSuccessOpt = (data) => {
    const categoryList = [];
    data?.data?.AllOpportunities?.map((item, index) => {
      categoryList[index] = { value: item.id, label: `${item.title} id: ${item.id}` };
    });
    setOpportunitiesList(categoryList);
    console.log("SUCCESS");
  };
  const onErrorOpt = (data) => {
    console.log(data);
  };
  return (
    <InnerLayout buttons={buttonList}>
      <div className="user_view_container">
        <InnerContainer>
          <div className="row mt-2">
            <div className="col-12 col-sm-12 col-md-9 col-lg-9 col-xl-7">
              <div className="row ps-1 ms-1 ps-lg-4 ms-lg-1">
                <div className="ps-2  pt-3">
                  <Formik
                    innerRef={formRef}
                    initialValues={initialValues}
                    onSubmit={onSubmit}
                    enableReinitialize={true}
                    validationSchema={interestEditSchema}
                  >
                    {({ formik, handleChange, values, setFieldValue }) => {
                      return (
                        <Form className="">
                          <div className=" express-form-width">
                            <InputField
                              id={"name"}
                              label={"Name"}
                              type={"text"}
                              placeholder={"Enter Name"}
                              name={"name"}
                            />
                            <InputField
                              id={"phone"}
                              label={"Phone Number"}
                              type={"text"}
                              placeholder={"Enter Phone Number"}
                              name={"phone"}
                            />
                            <InputField
                              id={"email"}
                              label={"E-Mail"}
                              type={"text"}
                              placeholder={"Enter Email ID"}
                              name={"email"}
                            />
                            <SelectFields
                              label={"Opportunity Name (Optional)"}
                              placeholder={"Select Opportunity"}
                              name={"opportunity"}
                              options={opportunitiesList}
                              id={"opportunity"}
                            />
                          </div>
                        </Form>
                      );
                    }}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </InnerContainer>
      </div>
    </InnerLayout>
  );
};
export default InvitedCreate;
