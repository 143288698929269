import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import ArrowRightIcon from "../../../../assets/icons/arrowrightIcon";
import ApproveBtn from "../../../../components/buttons/approveBtn";
import RejectBtn from "../../../../components/buttons/rejectBtn";
import InnerContainer from "../../../../components/layouts/innerContainer/innerContainer";
import InnerLayout from "../../../../components/layouts/innerLayout/innerLayout";
import errorToast from "../../../../components/utilits/errorToast";
import LoadAndError from "../../../../components/utilits/LoadAndError";
import LoadingSpinnerTable from "../../../../components/utilits/loadingSpinnerTable";
import { versionViewAdvisorAction } from "../../../../redux/actions/ourAdvisorAction";

const Advisorversioinhistoryview = () => {
  const [versionHistoyData, setVersionHistoyData] = useState({
    id: "",
    createdAt: "",
    updatedAt: "",
    new_data: { updated_by: "", updated_At: "" },
  });
  const buttonList = [<ApproveBtn />, <RejectBtn />];
  const dispatch = useDispatch();
  const params = useParams();
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)

  useEffect(() => {
    setLoading(true);
    dispatch(versionViewAdvisorAction(params.id, onSuccess, onError));
  }, [params.id]);
  const onSuccess = (data) => {
    // console.log(data);
    // successToast(data.message)
    setVersionHistoyData(data.data.data);
    setLoading(false);
    setError(false)
  };
  const onError = (data) => {
    // console.log(data);
    errorToast(data.message);
    setLoading(false);
    setError(true)
  };
  const data = [
    {
      heading: "Advisors ID",
      title: versionHistoyData?.id === null ? "--" : versionHistoyData?.id,
    },
    {
      heading: "Created At",
      title: versionHistoyData?.createdAt === null ? "--" : moment(versionHistoyData?.createdAt).format("DD-MM-YYYY HH:mm:ss"),
    },
    {
      heading: "Updated At",
      title: versionHistoyData?.updatedAt === null ? "--" : moment(versionHistoyData?.updatedAt).format("DD-MM-YYYY HH:mm:ss"
      ),
    },
    {
      heading: "Updated By",
      title: versionHistoyData?.updated_by === null ? "--" : versionHistoyData?.updated_by,
    },
  ];

  const newdata = [
    {
      heading: "Name",
      title: versionHistoyData?.new_data?.name === null ? "--" : versionHistoyData?.new_data?.name,
    },
    {
      heading: "Description",
      title: versionHistoyData?.new_data?.description === null ? "--" : versionHistoyData?.new_data?.description,
    },
    {
      heading: "Designation",
      title: versionHistoyData?.new_data?.designation === null ? "--" : versionHistoyData?.new_data?.designation,
    },
    {
      heading: "Website Url",
      title: versionHistoyData?.new_data?.website_url === null ? "--" : versionHistoyData?.new_data?.website_url,
    },
    {
      heading: "Approved Date",
      title: versionHistoyData?.new_data?.approved_date === null ? "--" : moment(versionHistoyData?.new_data?.approved_date).format("DD-MM-YYYY HH:mm:ss"),
    },


    {
      heading: "Priority",
      title: versionHistoyData?.new_data?.priority === null ? "--" : versionHistoyData?.new_data?.priority,
    },
    {
      heading: "Updated Date",
      title: versionHistoyData?.new_data?.update_date === null ? "--" : moment(versionHistoyData?.new_data?.update_date).format("DD-MM-YYYY HH:mm:ss"),
    },
  ];
  const olddata = [
    {
      heading: "Name",
      title: versionHistoyData?.old_data?.name === null ? "--" : versionHistoyData?.old_data?.name,
    },
    {
      heading: "Description",
      title: versionHistoyData?.old_data?.description === null ? "--" : versionHistoyData?.old_data?.description,
    },
    {
      heading: "Designation",
      title: versionHistoyData?.old_data?.designation === null ? "--" : versionHistoyData?.old_data?.designation,
    },
    {
      heading: "Website Url",
      title: versionHistoyData?.old_data?.website_url === null ? "--" : versionHistoyData?.old_data?.website_url,
    },
    {
      heading: "Approved Date",
      title: versionHistoyData?.old_data?.approved_date === null ? "--" : moment(versionHistoyData?.old_data?.approved_date).format("DD-MM-YYYY HH:mm:ss"),
    },
    {
      heading: "Priority",
      title: versionHistoyData?.old_data?.priority === null ? "--" : versionHistoyData?.old_data?.priority,
    },
    {
      heading: "Updated Date",
      title: versionHistoyData?.old_data?.update_date === null ? "--" : moment(versionHistoyData?.old_data?.update_date).format("DD-MM-YYYY HH:mm:ss"),
    },
  ];

  console.log(versionHistoyData);

  return (
    <InnerLayout >
      <div className="notify-me-container ">
        <InnerContainer>
          {loading ? (
            <LoadingSpinnerTable />
          ) : (
            <LoadAndError loader={loading} error={error}>
              <div className="pt-3 ps-5 row">
                {data.map((item, index) => (
                  <div className="mb-2  col-sm-4 col-md-3  col-xl-2 col-lg-3">
                    <h5 className="dark_grey_color  fs_13 fn_Montserrat fw_500 m-0">
                      {item.heading}
                    </h5>
                    <p
                      className={` fs_13 fn_Nunito  fw_500 ${item.heading === "Notify Me ID"
                        ? "secondary_color"
                        : "dark_title_color"
                        }`}
                    >
                      {item.title}
                    </p>
                  </div>
                ))}
              </div>
              <div className="white_shade_bg  py-1 d-flex">
                <div className="w-50">
                  <h5 className="fw_500 ps-4 dark_grey_color pt-2 text-nowrap fs_16 fn_Montserrat">
                    JSON Old Data
                  </h5>
                </div>
                <div className="w-50  d-none d-lg-block">
                  <h5 className="fw_500 ps-4  dark_grey_color pt-2 text-nowrap fs_16 fn_Montserrat">
                    JSON New Data
                  </h5>
                </div>
              </div>

              <div className="row mt-3 ps-0 ps-lg-4">
                <div className="col-12 col-lg-6">
                  <div className="notify_me_aprvls_vrsn_hstry_data_container  mt-3 pt-3 px-3">
                    <div className="d-flex justify-content-between">
                      <h5 className="fs_13 fn_Nunito fw_400 dark_title_color text-nowrap">
                        Old Data
                      </h5>
                      <ArrowRightIcon width={25} height={25} fill={"#A5A5A5"} />
                    </div>
                    <>
                      {
                        versionHistoyData?.old_data == null ? (<>Empty data</>) : (<>

                          {olddata.map((item, index) => {
                            return (
                              <div className="d-flex flex-column">
                                <h5 className="dark_grey_color  fs_13 fn_Montserrat fw_500 m-0">
                                  {item.heading}
                                </h5>
                                <p
                                  className={` fs_13 fn_Nunito  fw_500 ${item.heading === "Notify Me ID"
                                    ? "secondary_color"
                                    : "dark_title_color"
                                    }`}
                                >
                                  {item.title}
                                </p>
                              </div>
                            );
                          })}
                        </>)
                      }

                    </>
                  </div>
                </div>
                <div className="col-12 mt-5 mt-lg-0 col-lg-6">
                  <h5 className="fw_500 ps-4 d-block d-lg-none white_shade_bg  dark_grey_color py-3 text-nowrap fs_16 fn_Montserrat">
                    JSON New Data
                  </h5>
                  <div className="notify_me_aprvls_vrsn_hstry_data_container    mt-4 mt-lg-3 pt-3 px-3">
                    <div className="d-flex justify-content-between">
                      <h5 className="fs_13 fn_Nunito fw_400 dark_title_color py-2 text-nowrap">
                        New Data
                      </h5>
                      <ArrowRightIcon width={25} height={25} fill={"#A5A5A5"} />
                    </div>
                    {
                      versionHistoyData?.new_data == null ? (<>Empty data</>) : (<>
                        {newdata.map((item, index) => {
                          return (
                            <div className="d-flex flex-column">
                              <h5 className="dark_grey_color  fs_13 fn_Montserrat fw_500 m-0">
                                {item.heading}
                              </h5>
                              <p
                                className={` fs_13 fn_Nunito  fw_500 ${item.heading === "Notify Me ID"
                                  ? "secondary_color"
                                  : "dark_title_color"
                                  }`}
                              >
                                {item.title}
                              </p>
                            </div>
                          );
                        })}</>)}

                  </div>
                </div>
              </div>
            </LoadAndError>
          )}
        </InnerContainer>
      </div>
    </InnerLayout>
  );
};

export default Advisorversioinhistoryview;
