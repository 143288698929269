import React, { useState } from "react";
import ApprovalsBtn from "../../../../components/buttons/approvalsBtn";
import CreateBtn from "../../../../components/buttons/createBtn";
import ExportBtn from "../../../../components/buttons/exportBtn";
import FilterBtn from "../../../../components/buttons/filterBtn";
import VersionHistoryBtn from "../../../../components/buttons/versionHistoryBtn";
import AdminUserFilter from "../../../../components/filter/adminUserFilter";
import ExpressFilter from "../../../../components/filter/opportunity-pagesFilter.js/expressInterestFilter";
import InnerLayout from "../../../../components/layouts/innerLayout/innerLayout";
import ExpressInterestTableContent from "../../../../components/opportunity/expressInterest/tables/expressInterestTableContent";
import { access } from "../../../../components/utilits/access";
import { expressTableExport } from "../../../../redux/actions/expressInterestAction";
                                
const ExpressInterestTable = () => {
  const [page, setPage] = useState(0);
  const [showFilter, setShowFilter] = useState(false);
  const [search, setSearch] = useState({ value: "" });
  const handleFilterClose = () => {
    setShowFilter(false);
  };
  const handleFilterOpen = () => {
    setShowFilter(true);
  };
  const handleSearch = (value) => {
    setSearch({ value: value });
    setPage(0)
  };
  const handlePage = (type) => {
    if (type === "+") {
      setPage((prev) => prev + 1)
    }
    else {
      setPage((prev) => prev - 1)
    }
  }
  console.log(search);
  const buttonList = [
    <ApprovalsBtn link="/opportunity/express-interest/approvals" />,
    <VersionHistoryBtn link="/opportunity/express-interest/version-history" />,
    <CreateBtn link="/opportunity/express-interest/create" />,
    <ExportBtn  dispatchAction={expressTableExport}/>,
    <FilterBtn filterFn={handleFilterOpen} />,
  ];


  return (
    <InnerLayout buttons={
      buttonList            
    }>
      <ExpressFilter
        filter={handleSearch}
        showFilter={showFilter}
        closeFilter={handleFilterClose}
      />

      <ExpressInterestTableContent page={page} changePage={handlePage} search={search} />
    </InnerLayout>
  );
};

export default ExpressInterestTable;
