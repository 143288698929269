import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {  useLocation, useParams } from "react-router-dom";
import RejectBtn from "../../../../components/buttons/rejectBtn";
import VersionHistoryBtn from "../../../../components/buttons/versionHistoryBtn";
import InnerContainer from "../../../../components/layouts/innerContainer/innerContainer";
import InnerLayout from "../../../../components/layouts/innerLayout/innerLayout";
import errorToast from "../../../../components/utilits/errorToast";
import successToast from "../../../../components/utilits/successToast";
import * as opporTypeActions from "../../../../redux/actions/opportunityTypeActions";
import ApproveBtn from "../../../../components/buttons/approveBtn";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { approvalsViewContactAction, approveContactAction, rejectContactAction } from "../../../../redux/actions/contactUsActions";
import LoadAndError from "../../../../components/utilits/LoadAndError";
                    
const ContactUsApprovalView = () => {
    const params = useParams();
    const [updated, setUpdated] = useState(false);
    const navigate = useNavigate();
    const [approvalData, setApprovalData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const dispatch = useDispatch();
    useEffect(() => {
        setLoading(true);
        dispatch(
            approvalsViewContactAction(params.id, onSuccess, onError)
        );
    }, [updated]);
    console.log(params.id);
    const onSuccess = (data) => {
        setApprovalData(data?.data)
        console.log(data);
        setLoading(false);
        setError(false);

    };
    const onError = (data) => {
        errorToast(data.message);
        setLoading(false);
        setError(true);
    };

    ///Approve

    const approveContact = () => {
        setUpdated(false)
        dispatch(
            approveContactAction(params.id, onSuccessApr, onErrorApr)
        );
    };
    const onSuccessApr = (data) => {
        setUpdated(true);
        successToast(data.message);
        navigate("/static-pages/contact-us-query/approvals");
        console.log("data is", data.data);
        setLoading(false);
        setError(false);
    };
    const onErrorApr = (data) => {
        console.log(data);
        errorToast(data.message);
        setLoading(false);
        setError(true);
    };

    //Reject
    const rejectContact = () => {
        setUpdated(false)
        dispatch(
            rejectContactAction(params.id, onRejectSuccess, onRejectError)
        );
    };

    const onRejectSuccess = (data) => {
        setUpdated(true);
        successToast("Rejected Successfully");
        navigate("/static-pages/contact-us-query/approvals");
        setLoading(false);
        setError(false);
    };
    const onRejectError = (data) => {
        console.log(data);
        errorToast(data.message);
        setLoading(false);
        setError(true);
    };
    const data = [
        {
            heading: "Approval ID",
            title: approvalData?.id,
        },
        {
            heading: "Contact Us Query ID",
            title: approvalData?.new_data == null ? approvalData?.old_data?.id: approvalData?.new_data?.id
            ,
        },
        {
            heading: "Query Name",
            title: approvalData?.new_data == null ? approvalData?.old_data?.query_name: approvalData?.new_data?.query_name
            ,
        },

        {
            heading: "Phone Number",
            title: approvalData?.new_data == null ? approvalData?.old_data?.phone_number: approvalData?.new_data?.phone_number
        },
        {
            heading: "Email",
            title: approvalData?.new_data == null ? approvalData?.old_data?.email: approvalData?.new_data?.email
        },

        {
            heading: "Approved At",
            title: moment(approvalData?.createdAt).format("DD-MM-YYYY HH:mm:ss")
        },
        {
            heading: "Updated At",
            title: moment(approvalData?.updatedAt).format("DD-MM-YYYY HH:mm:ss")
        },
        {
            heading: "Operation Type",
            title: approvalData?.new_data == null ? "Delete" : approvalData?.new_data && approvalData?.old_data ? "Edit" : approvalData?.old_data == null ? "Create" : ""
          }
    ];


    const buttonList = [
        <VersionHistoryBtn link={"/static-pages/contact-us-query/version-history"} />,
        <ApproveBtn approveFn={approveContact} />,
        <RejectBtn showModal={rejectContact} />,
    ];
    return (
        <InnerLayout buttons={buttonList}>
            <div className="opportunity-type-container">
                <InnerContainer error={error} oldData={approvalData?.old_data} newData={approvalData?.new_data}>
          <LoadAndError loader={loading} error={error}>
                    <div className="pt-3  ps-4 ps-lg-4 ms-3">
                        {data.map((item, index) => (
                            <div className="mb-2">
                                <h5 className="dark_grey_color fs_13 fn_Montserrat fw_500 m-0">
                                    {item.heading}
                                </h5>
                                <p className={` fs_13 fn_Nunito fw_500 dark_title_color  `}>
                                    {item.title}
                                </p>
                            </div>
                        ))}
                    </div>
                    </LoadAndError>
                </InnerContainer>
            </div>
        </InnerLayout>
    );
}

export default ContactUsApprovalView