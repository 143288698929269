import {
  ACTIVATE_INSIGHTS_OPTIONS,
  INSIGHTS_APPROVALS_EXPORT,
  INSIGHTS_APPROVALS_LIST,
  INSIGHTS_APPROVALS_VIEW,
  INSIGHTS_APPROVE,
  INSIGHTS_CREATE,
  INSIGHTS_DELETE,
  INSIGHTS_EDIT,
  INSIGHTS_LIST,
  INSIGHTS_MAIN_EXPORT,
  INSIGHTS_READ,
  INSIGHTS_REJECT,
  INSIGHTS_SUBMIT,
  INSIGHTS_VERSION_EXPORT,
  INSIGHTS_VERSION_LIST,
  INSIGHTS_VERSION_VIEW,
} from "./types";

export const addInsightsAction = (data, onSuccess, onError) => {
  return {
    type: INSIGHTS_CREATE,
    data,
    onSuccess,
    onError,
  };
};
export const approvalsInsightsListAction = (data, page, onSuccess, onError) => {
  return {
    type: INSIGHTS_APPROVALS_LIST,
    data,
    page,
    onSuccess,
    onError,
  };
};
export const approvalsViewInsightsAction = (id, onSuccess, onError) => {
  return {
    type: INSIGHTS_APPROVALS_VIEW,
    id,
    onSuccess,
    onError,
  };
};
export const approveInsightsAction = (id, onSuccess, onError) => {
  return {
    type: INSIGHTS_APPROVE,
    id,
    onSuccess,
    onError,
  };
};
export const rejectInsightsAction = (id, onSuccess, onError) => {
  return {
    type: INSIGHTS_REJECT,
    id,
    onSuccess,
    onError,
  };
};
export const viewInsightsListAction = (data, page, onSuccess, onError) => {
  return {
    type: INSIGHTS_LIST,
    data,
    page,
    onSuccess,
    onError,
  };
};
export const viewInsightsAction = (id, onSuccess, onError) => {
  return {
    type: INSIGHTS_READ,
    id,
    onSuccess,
    onError,
  };
};

export const editInsightsAction = (id, data, onSuccess, onError) => {
  return {
    type: INSIGHTS_EDIT,
    id,
    data,
    onSuccess,
    onError,
  };
};
export const deleteInsightsAction = (id, onSuccess, onError) => {
  return {
    type: INSIGHTS_DELETE,
    id,
    onSuccess,
    onError,
  };
};

export const versionListInsightsAction = (data, page, onSuccess, onError) => {
  return {
    type: INSIGHTS_VERSION_LIST,
    data,
    page,
    onSuccess,
    onError,
  };
};
export const versionViewInsightsAction = (id, onSuccess, onError) => {
  return {
    type: INSIGHTS_VERSION_VIEW,
    id,
    onSuccess,
    onError,
  };
};
export const activateInsightsAction = (id, onSuccess, onError) => {
  return {
    type: ACTIVATE_INSIGHTS_OPTIONS,
    id,
    onSuccess,
    onError,
  };
};

export const InsightsMainExportAction = (onSuccess, onError) => {
  return {
    type: INSIGHTS_MAIN_EXPORT,
    onSuccess,
    onError,
  };
};
export const InsightsApprovalsExportAction = (onSuccess, onError) => {
  return {
    type: INSIGHTS_APPROVALS_EXPORT,
    onSuccess,
    onError,
  };
};
export const InsightsVersionExportAction = (onSuccess, onError) => {
  return {
    type: INSIGHTS_VERSION_EXPORT,
    onSuccess,
    onError,
  };
};
export const InsightsCategorySubmit = (id, onSuccess, onError) => {
  return {
    type: INSIGHTS_SUBMIT,
    id,
    onSuccess,
    onError,
  };
};
