import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import errorToast from "../../utilits/errorToast";
import moment from "moment";
import LoadingSpinnerTable from "../../utilits/loadingSpinnerTable";
import LoadAndError from "../../utilits/LoadAndError";
import { viewReferedUserAction } from "../../../redux/actions/referedUserActions";

const ReferredUsersViewDetails = ({ id, handleEditable }) => {
  const params = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [wishlistData, setWishlistData] = useState({
    id: "",
    name: "",
    createdAt: "",
    updatedAt: "",
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  useEffect(() => {
    setLoading(true);
    dispatch(
      viewReferedUserAction(id, onSuccess, onError)
      //viewWishlist("52decfd4-3d70-4239-815d-1650e294d45f", onSuccess, onError)
    );
  }, [id]);
  const onSuccess = (data) => {
    console.log(data.data);
    if (data.data.editable) {
      handleEditable(data.data.editable);
    }
    setWishlistData(data.data);
    setLoading(false);
    setError(false);
  };
  const onError = (data) => {
    errorToast(data.message);
    setLoading(false);
    setError(true);
  };

  const ViewDetails = [
    {
      heading: "Referred User ID",
      title: wishlistData?.id ? wishlistData?.id : "--",
    },
    {
      heading: "User name",
      title: wishlistData?.user_name ? wishlistData?.user_name : "--",
    },
    {
      heading: "User ID",
      title: wishlistData?.user_id ? wishlistData?.user_id : "--",
    },
    {
      heading: "Referal Code",
      title: wishlistData?.referral_code ? wishlistData?.referral_code : "--",
    },
    {
      heading: "Opportunity ID",
      title: wishlistData?.opportunity_id ? wishlistData?.opportunity_id : "--",
    },
    {
      heading: "Updated At",
      title: wishlistData?.updatedAt
        ? moment(wishlistData?.updatedAt).format("DD-MM-YYYY HH:mm:ss")
        : "--",
    },
    {
      heading: "Created At",
      title: wishlistData?.createdAt
        ? moment(wishlistData?.createdAt).format("DD-MM-YYYY HH:mm:ss")
        : "--",
    },
  ];
  return (
    <>
      {loading ? (
        <LoadingSpinnerTable />
      ) : (
        <LoadAndError loader={loading} error={error}>
          <div className="row mt-4">
            <div className="col-12 col-sm-12 col-md-9 col-lg-9 col-xl-7">
              <div className="row ps-1 ms-1 ps-lg-4 ms-lg-1">
                {ViewDetails.map((item, index) => (
                  <div className="mb-2 ">
                    <h5 className="dark_grey_color text-nowrap fs_13 fn_Montserrat fw_500">
                      {item.heading}
                    </h5>
                    <p
                      className={` fs_13 fn_Nunito  fw_500 "dark_title_color"
                `}
                    >
                      {item.title}
                    </p>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </LoadAndError>
      )}
    </>
  );
};

export default ReferredUsersViewDetails;
