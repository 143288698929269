import React, { useRef, useState } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import InnerLayout from "../../../../components/layouts/innerLayout/innerLayout";
import InnerContainer from "../../../../components/layouts/innerContainer/innerContainer";
import { useNavigate } from "react-router-dom";
import SaveBtn from "../../../../components/buttons/saveBtn";
import InputField from "../../../../components/form/inputFields/inputField";
import ErrorMsg from "../../../../components/form/errorMsg";
import { useDispatch } from "react-redux";
import successToast from "../../../../components/utilits/successToast";
import errorToast from "../../../../components/utilits/errorToast";
import UploadIcon from "../../../../assets/icons/upload";
import { uploadAddressProofFrontImageAction } from "../../../../redux/actions/userAction";
import CloseIcon from "../../../../assets/icons/closeIcon";
import { addHowItWorksAction } from "../../../../redux/actions/howItWorksAction";
import HomeCounterTable from "../../../../components/static-pages/home-page/tables/homeCounterTable";
import HomeFeaturesTable from "../../../../components/static-pages/home-page/tables/homeCardsTable";
import { addHomeAction } from "../../../../redux/actions/homeActions";

const HomeCreate = () => {
  const navigate = useNavigate();
  const formRef = useRef();
  const dispatch = useDispatch();

  const [thumbNail, setThumbNail] = useState({
    img: "",
    error: false,
  });
  const initialValues = {
    headerTitle: "",
    headerSubtitle: "",
    buttonText: "",
    opportunityTitle: "",
    opportunitySubtitle: "",
    opportunityButtonText: "",
    counterTitle: "",
    counterSubtitle: "",
    counters: [],
    featureTitle: "",
    featureSubtitle: "",
    features: [],
    videoUrl: "",
    videoTitle: "",
    videoSubtitle: "",
    insightsTitle: "",
    insightsSubtitle: "",
    testimonialsTitle: "",
    testimonialsSubtitle: "",
  };

  const interestEditSchema = Yup.object().shape({
    headerTitle: Yup.string().trim().required("Required"),
    headerSubtitle: Yup.string().trim().required("Required"),
    buttonText: Yup.string().trim().required("Required"),
    opportunityTitle: Yup.string().trim().required("Required"),
    opportunitySubtitle: Yup.string().trim().required("Required"),
    opportunityButtonText: Yup.string().trim().required("Required"),
    counterTitle: Yup.string().trim().required("Required"),
    counterSubtitle: Yup.string().trim().required("Required"),
    counters: Yup.array()
      .min(5, "Five items are required")
      .required("Required"),
    featureTitle: Yup.string().trim().required("Required"),
    featureSubtitle: Yup.string().trim().required("Required"),
    features: Yup.array()
      .min(4, "Four items are required")
      .required("Required"),
    videoUrl: Yup.string().trim().required("Required"),
    videoTitle: Yup.string().trim().required("Required"),
    videoSubtitle: Yup.string().trim().required("Required"),
    insightsTitle: Yup.string().trim().required("Required"),
    insightsSubtitle: Yup.string().trim().required("Required"),
    testimonialsTitle: Yup.string().trim().required("Required"),
    testimonialsSubtitle: Yup.string().trim().required("Required"),
  });
  const handleSubmit = () => {
    if (!thumbNail.img) {
      setThumbNail({ img: "", error: true });
    }

    if (formRef.current) {
      formRef.current.handleSubmit();
    }
  };
  const onSubmit = (values, { resetForm }) => {
    if (thumbNail.img) {
      dispatch(
        addHomeAction(
          {
            header_title: values.headerTitle,
            header_subtitle: values.headerSubtitle,
            header_button: values.buttonText,
            section2_title: values.opportunityTitle,
            section2_sub_title: values.opportunitySubtitle,
            section2_button: values.opportunityButtonText,
            section3_title: values.counterTitle,
            section3_sub_title: values.counterSubtitle,
            section3_counter: values.counters,
            section4_title: values.featureTitle,
            section4_sub_title: values.featureSubtitle,
            section4_counter: values.features,
            video_url: values.videoUrl,
            video_tumbnail_image: thumbNail.img,
            video_title: values.videoTitle,
            video_description: values.videoSubtitle,
            insights_title: values.insightsTitle,
            insights_sub_title: values.insightsSubtitle,
            testimonial_title: values.testimonialsTitle,
            testimonial_sub_title: values.testimonialsSubtitle,
          },
          onSuccess,
          onError
        )
      );
    }
  };
  const onSuccess = (data) => {
    // console.log("====================================");
    // console.log(data.data.id);
    // ebd5f4a6-1479-4225-a88a-10c02ca99292
    // console.log("====================================");
    successToast(data.message);
    navigate(`/static-pages/home/edit/${data.data.id}`);
  };
  const onError = (data) => {
    console.log("====================================");
    console.log(data);
    console.log("====================================");
    errorToast(data.data);
  };

  const imageUpload = (e) => {
    if (e.target.name === "thumbNail") {
      dispatch(
        uploadAddressProofFrontImageAction(
          e.target.files[0],
          onthumbNailUploadSucess,
          onthumbNailUploadError
        )
      );
    }
    e.target.value = null;
  };

  const onthumbNailUploadSucess = (data) => {
    setThumbNail({ img: data.data.data.location, error: false });
  };
  const onthumbNailUploadError = (data) => {
    setThumbNail({ img: "", error: true });
    console.log(data);
  };

  const buttonList = [<SaveBtn submitFn={handleSubmit} />];
  return (
    <InnerLayout buttons={buttonList}>
      <div className="user_view_container mb-4">
        <InnerContainer>
          <Formik
            innerRef={formRef}
            initialValues={initialValues}
            onSubmit={onSubmit}
            enableReinitialize={true}
            validationSchema={interestEditSchema}
          >
            {({ values, errors, setFieldValue }) => {
              return (
                <Form className="px-4 py-3 col-12  col-xxl-10">
                  <div className="d-flex flex-column gap-0 gap-lg-5 flex-lg-row fs_14">
                    <div className="col-12 col-lg-6 pe-0 pe-lg-5">
                      <h3 className="dark_grey_color fn_Montserrat fw_500 mt-4 mb-3 fs_13 py-2 rounded-1 white_shade_bg">
                        <span className="ps-2">Header</span>
                      </h3>
                      <InputField
                        id={"headerTitle"}
                        label={"Header Title"}
                        type={"text"}
                        placeholder={"Enter home header Title"}
                        name={"headerTitle"}
                      />
                      <label
                        className="mb-1 ms-2 fw_400"
                        htmlFor="headerSubtitle"
                      >
                        Header Subtitle
                      </label>
                      <Field name={"headerSubtitle"}>
                        {({ field }) => (
                          <textarea
                            style={{ height: "3.5rem" }}
                            id="headerSubtitle"
                            {...field}
                            type="text"
                            placeholder="Enter the home subtitle"
                            className={"w-100 mb-0 faq_message_container p-2 "}
                          ></textarea>
                        )}
                      </Field>
                      <ErrorMsg name={"headerSubtitle"} />
                      <InputField
                        id={"buttonText"}
                        label={"Button Text"}
                        type={"text"}
                        placeholder={"Enter Button Text"}
                        name={"buttonText"}
                      />
                      <h3 className="dark_grey_color fn_Montserrat fw_500 mt-3 mb-3 fs_13 py-2 rounded-1 white_shade_bg">
                        <span className="ps-2">Opportunity Section</span>
                      </h3>
                      <InputField
                        id={"opportunityTitle"}
                        label={"Opportunity Section Title"}
                        type={"text"}
                        placeholder={"Enter Opportunity Section Title"}
                        name={"opportunityTitle"}
                      />
                      <label
                        className="mb-1 ms-2"
                        htmlFor="opportunitySubtitle"
                      >
                        Opportunity Section SubTitle
                      </label>
                      <Field name={"opportunitySubtitle"}>
                        {({ field }) => (
                          <textarea
                            style={{ height: "3.5rem" }}
                            id="opportunitySubtitle"
                            {...field}
                            type="text"
                            placeholder="Enter Video description"
                            className={"w-100 mb-0 faq_message_container p-2 "}
                          ></textarea>
                        )}
                      </Field>
                      <ErrorMsg name={"opportunitySubtitle"} />
                      <InputField
                        id={"opportunityButtonText"}
                        label={"Opportunity Button Text"}
                        type={"text"}
                        placeholder={"Enter Opportunity Button Text"}
                        name={"opportunityButtonText"}
                      />
                      <h3 className="dark_grey_color fn_Montserrat fw_500 mt-3 mb-3 fs_13 py-2 rounded-1 white_shade_bg">
                        <span className="ps-2">Counter Section</span>
                      </h3>
                      <InputField
                        id={"counterTitle"}
                        label={"Counter Title"}
                        type={"text"}
                        placeholder={"Enter Counter title"}
                        name={"counterTitle"}
                      />{" "}
                      <label className="mb-1 ms-2" htmlFor="counterSubtitle">
                        Counter Subtitle
                      </label>
                      <Field name={"counterSubtitle"}>
                        {({ field }) => (
                          <textarea
                            style={{ height: "3.5rem" }}
                            id="counterSubtitle"
                            {...field}
                            type="text"
                            placeholder="Enter counter Subtitle"
                            className={"w-100 mb-0 faq_message_container p-2 "}
                          ></textarea>
                        )}
                      </Field>
                      <ErrorMsg name={"counterSubtitle"} />
                      <HomeCounterTable
                        setFieldValue={setFieldValue}
                        formikValues={values}
                        name={"counters"}
                        tableTitle={"Counter Values"}
                      />
                      <h3 className="dark_grey_color fn_Montserrat fw_500 mt-3 mb-3 fs_13 py-2 rounded-1 white_shade_bg">
                        <span className="ps-2">Feature Section</span>
                      </h3>
                      <InputField
                        id={"featureTitle"}
                        label={"Feature Title"}
                        type={"text"}
                        placeholder={"Enter feature title here"}
                        name={"featureTitle"}
                      />
                      <label className="mb-1 ms-2" htmlFor="featureSubtitle">
                        Feature Subtitle
                      </label>
                      <Field name={"featureSubtitle"}>
                        {({ field }) => (
                          <textarea
                            style={{ height: "3.5rem" }}
                            id="featureSubtitle"
                            {...field}
                            type="text"
                            placeholder="Enter feature subtitle here"
                            className={"w-100 mb-0 faq_message_container p-2 "}
                          ></textarea>
                        )}
                      </Field>
                      <ErrorMsg name={"featureSubtitle"} />
                      <HomeFeaturesTable
                        setFieldValue={setFieldValue}
                        formikValues={values}
                        name={"features"}
                        tableTitle={"Features Values"}
                      />
                    </div>

                    <div className="col-12 col-lg-6 pe-0 pe-lg-5">
                      <h3 className="dark_grey_color fn_Montserrat fw_500 mt-3 mb-3 fs_13 py-2 rounded-1 white_shade_bg">
                        <span className="ps-2">Youtube Video Section</span>
                      </h3>
                      <div className="mb-3">
                        <span className="">Thumb Nail Image</span>
                        <div className="blog_create_image_container mt-2 position-relative d-flex  justify-content-center align-items-center">
                          <input
                            multiple={false}
                            style={{ visibility: "hidden" }}
                            type="file"
                            id="thumbNail"
                            className="upload_document_input"
                            name="thumbNail"
                            accept="image/* ,capture=camera"
                            onChange={(e) => {
                              imageUpload(e);
                            }}
                          />
                          {thumbNail.img ? (
                            <>
                              <img
                                src={thumbNail.img}
                                className={"user-edit-profile-picture"}
                              />
                              <button
                                type="button"
                                onClick={(e) => {
                                  setThumbNail({ img: "", error: true });
                                }}
                                className="position-absolute border-0 cursor_pointer white_bg d-flex gap-2 py-1 px-2 user_edit_profile_picture_remove_container"
                              >
                                <CloseIcon fill="#666666" />
                                <span className="dark_grey_color fs_13 fn_Montserrat fw_500 ">
                                  Remove
                                </span>
                              </button>
                            </>
                          ) : (
                            <label
                              htmlFor="thumbNail"
                              className="d-flex flex-column align-items-center mt-2 justify-content-center fs_12"
                            >
                              <UploadIcon width={30} height={30} />
                              Choose Picture
                            </label>
                          )}
                        </div>
                        {thumbNail.error && (
                          <span className="fs_12 ms-2 text-danger fw-semibold fn_Nunito">
                            Required!
                          </span>
                        )}
                      </div>
                      <InputField
                        id={"videoUrl"}
                        label={"Video Url"}
                        type={"text"}
                        placeholder={"Enter video url here"}
                        name={"videoUrl"}
                      />
                      <InputField
                        id={"videoTitle"}
                        label={"Video title"}
                        type={"text"}
                        placeholder={"Enter video title here"}
                        name={"videoTitle"}
                      />
                      <label className="mb-1 ms-2" htmlFor="videoSubtitle">
                        Video Subtitle
                      </label>
                      <Field name={"videoSubtitle"}>
                        {({ field }) => (
                          <textarea
                            style={{ height: "3.5rem" }}
                            id="videoSubtitle"
                            {...field}
                            type="text"
                            placeholder="Enter video Subtitle"
                            className={"w-100 mb-0 faq_message_container p-2 "}
                          ></textarea>
                        )}
                      </Field>
                      <ErrorMsg name={"videoSubtitle"} />
                      <h3 className="dark_grey_color fn_Montserrat fw_500 mt-3 mb-3 fs_13 py-2 rounded-1 white_shade_bg">
                        <span className="ps-2">Insights Section</span>
                      </h3>
                      <InputField
                        id={"insightsTitle"}
                        label={"Insights title"}
                        type={"text"}
                        placeholder={"Enter insights title here"}
                        name={"insightsTitle"}
                      />
                      <label className="mb-1 ms-2" htmlFor="insightsSubtitle">
                        Insights Subtitle
                      </label>
                      <Field name={"insightsSubtitle"}>
                        {({ field }) => (
                          <textarea
                            style={{ height: "3.5rem" }}
                            id="insightsSubtitle"
                            {...field}
                            type="text"
                            placeholder="Enter insights Subtitle"
                            className={"w-100 mb-0 faq_message_container p-2 "}
                          ></textarea>
                        )}
                      </Field>
                      <ErrorMsg name={"insightsSubtitle"} />

                      <h3 className="dark_grey_color fn_Montserrat fw_500 mt-3 mb-3 fs_13 py-2 rounded-1 white_shade_bg">
                        <span className="ps-2">Testimonials Section</span>
                      </h3>
                      <InputField
                        id={"testimonialsTitle"}
                        label={"Tesimonials title"}
                        type={"text"}
                        placeholder={"Enter Tesimonials title here"}
                        name={"testimonialsTitle"}
                      />
                      <label
                        className="mb-1 ms-2"
                        htmlFor="testimonialsSubtitle"
                      >
                        Testimonials Subtitle
                      </label>
                      <Field name={"testimonialsSubtitle"}>
                        {({ field }) => (
                          <textarea
                            style={{ height: "3.5rem" }}
                            id="testimonialsSubtitle"
                            {...field}
                            type="text"
                            placeholder="Enter testimonials Subtitle"
                            className={"w-100 mb-0 faq_message_container p-2 "}
                          ></textarea>
                        )}
                      </Field>
                      <ErrorMsg name={"testimonialsSubtitle"} />
                    </div>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </InnerContainer>
      </div>
    </InnerLayout>
  );
};

export default HomeCreate;
