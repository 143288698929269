import React, { useEffect, useState } from "react";
import InnerLayout from "../../../../components/layouts/innerLayout/innerLayout";
import InnerContainer from "../../../../components/layouts/innerContainer/innerContainer";
import VersionHistoryBtn from "../../../../components/buttons/versionHistoryBtn";
import ApprovalsBtn from "../../../../components/buttons/approvalsBtn";
import RejectBtn from "../../../../components/buttons/rejectBtn";
import ApproveBtn from "../../../../components/buttons/approveBtn";
import { useDispatch } from "react-redux";
import errorToast from "../../../../components/utilits/errorToast";
import successToast from "../../../../components/utilits/successToast";
import moment from "moment";
import { useParams, useNavigate } from "react-router-dom";
import {
  approveCategory,
  CategoryApprovalById,
  rejectCategory,
} from "../../../../redux/actions/faqCategoryActions";
import {
  approveBlogCategoryAction,
  blogCategoryApprovalByIdAction,
  rejectBlogCategoryAction,
} from "../../../../redux/actions/blogCategoryActions";
import DownloadIcon from "../../../../assets/icons/downloadIcon";
import {
  approveBlogAction,
  blogApprovalByIdAction,
  rejectBlogAction,
} from "../../../../redux/actions/blogActions";
import CreateTable from "../../../../components/opportunity/opportunties/tables/opporttunitiesCreateTable";
import LoadingSpinnerTable from "../../../../components/utilits/loadingSpinnerTable";
import LoadAndError from "../../../../components/utilits/LoadAndError";
import { JSONTree } from "react-json-tree";
import ArrowRightIcon from "../../../../assets/icons/arrowrightIcon";

const BlogApprovalsDetails = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [wishlistData, setWishlistData] = useState([]);
  const [wishlistNewData, setWishlistNewData] = useState({});
  const [wishlistOldData, setWishlistOldData] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    setLoading(true);
    console.log("the id", params.id);
    dispatch(blogApprovalByIdAction(params.id, onSuccess, onError));
  }, []);

  const onSuccess = (data) => {
    setWishlistData(data?.data);
    setWishlistNewData(data?.data.new_data);
    setWishlistOldData(data?.data.old_data);
    setLoading(false);
    setError(false);
  };
  const onError = (data) => {
    errorToast(data.data.message);
    //errorToast("went wrong")
    setLoading(false);
    setError(true);
  };

  console.log("The dtate", wishlistData);

  const FaqViewApprovalDetails = [
    {
      heading: "Approval ID",
      title: wishlistData?.id ? wishlistData?.id : "-",
    },
    {
      heading: "Blog ID",
      title:
        wishlistData?.new_data === null
          ? wishlistData?.old_data?.id
          : wishlistData?.new_data?.id,
    },
    {
      heading: "Blog Title",
      title:
        wishlistData?.new_data === null
          ? wishlistData?.old_data?.title
          : wishlistData?.new_data?.title,
    },
    {
      heading: "Created At",
      title: wishlistData?.createdAt
        ? moment(wishlistData?.createdAt).format("DD-MM-YYYY HH:mm:ss")
        : "--",
    },
    {
      heading: "Updated At",
      title: wishlistData?.updatedAt
        ? moment(wishlistData?.updatedAt).format("DD-MM-YYYY HH:mm:ss")
        : "--",
    },
    {
      heading: "Operation Type",
      title:
        wishlistData?.new_data == null
          ? "Delete"
          : wishlistData?.new_data && wishlistData?.old_data
          ? "Edit"
          : wishlistData?.old_data == null
          ? "Create"
          : "",
    },
  ];

  //Approval , reject sections

  const approveRole = () => {
    dispatch(
      approveBlogAction(wishlistData?.id, onApproveSuccess, onApproveError)
    );
  };

  const onApproveSuccess = (data) => {
    console.log(data.data);
    successToast(data.message);
    setLoading(false);
    setError(false);
    navigate("/blog/approval");
  };
  const onApproveError = (data) => {
    errorToast(data.message);
    //errorToast("went wrong")
    setLoading(false);
    setError(true);
  };
  //Reject Section
  const rejectWishListFn = () => {
    dispatch(rejectBlogAction(params?.id, onRejectSuccess, onRejectError));
  };

  const onRejectSuccess = (data) => {
    successToast(data.message);
    setLoading(false);
    navigate("/blog/approval");
    setError(false);
  };
  const onRejectError = (data) => {
    errorToast(data.message);
    setLoading(false);
    setError(true);
  };

  console.log(wishlistData, "adsgfsaf");

  const buttonList = [
    <VersionHistoryBtn link="/blog/version-history" />,
    <ApproveBtn approveFn={approveRole} />,
    <RejectBtn showModal={rejectWishListFn} />,
  ];
  const theme = {
    scheme: "monokai",
    author: "wimer hazenberg (http://www.monokai.nl)",
    base00: "#272822",
    base01: "#383830",
    base02: "#49483e",
    base03: "#75715e",
    base04: "#a59f85",
    base05: "#f8f8f2",
    base06: "#f5f4f1",
    base07: "#f9f8f5",
    base08: "#f92672",
    base09: "#464646",
    base0A: "#f4bf75",
    base0B: "#464646",
    base0C: "#a1efe4",
    base0D: "#464646",
    base0E: "#ae81ff",
    base0F: "#cc6633",
  };
  return (
    <InnerLayout buttons={buttonList}>
      <div className="user_view_container">
        <InnerContainer
          error={error}
          oldData={wishlistData?.old_data}
          newData={wishlistData?.new_data}
        >
          {loading ? (
            <LoadingSpinnerTable />
          ) : (
            <LoadAndError loader={loading} error={error}>
              <div className="px-4 py-3">
                <div className="d-flex flex-column flex-lg-row fs_14">
                  <div className="col-12 col-lg-6">
                    <div className="">
                      <span>Blog ID</span>
                      <p>{wishlistData.id}</p>
                    </div>
                    <div className="">
                      <span>Blog Category</span>
                      <p>
                        {wishlistData?.new_data === null
                          ? wishlistData?.old_data?.blog_category_id
                          : wishlistData?.new_data?.blog_category_id}
                      </p>
                    </div>
                    <div className="">
                      <span>Blog Title</span>
                      <p>
                        {wishlistData?.new_data === null
                          ? wishlistData?.old_data?.title
                          : wishlistData?.new_data?.title}
                      </p>
                    </div>
                    <div className="">
                      <span>Description</span>
                      <p>
                        {wishlistData?.new_data === null
                          ? wishlistData?.old_data?.description
                          : wishlistData?.new_data?.description}
                      </p>
                    </div>
                    <div className="">
                      <span>Meta Tags</span>
                      <p>
                        {wishlistData?.new_data === null
                          ? wishlistData?.old_data?.meta_tags
                          : wishlistData?.new_data?.meta_tags}
                      </p>
                    </div>
                    <div className="">
                      <span>Meta Published Date</span>
                      <p>
                        {" "}
                        {wishlistData?.new_data === null
                          ? wishlistData?.old_data?.meta_published_date
                          : wishlistData?.new_data?.meta_published_date}
                      </p>
                    </div>
                    <div className="">
                      <span>Meta Description</span>
                      <p>
                        {wishlistData?.new_data === null
                          ? wishlistData?.old_data?.meta_description
                          : wishlistData?.new_data?.meta_description}
                      </p>
                    </div>
                    <div className="">
                      <span>Operation Type</span>
                      <p>
                        {wishlistData?.new_data == null
                          ? "Delete"
                          : wishlistData?.new_data && wishlistData?.old_data
                          ? "Edit"
                          : wishlistData?.old_data == null
                          ? "Create"
                          : ""}
                      </p>
                    </div>
                  </div>

                  <div className="col-12 col-lg-6">
                    <div className="">
                      <span>Meta Published Status</span>
                      <p>
                        {wishlistData?.new_data === null
                          ? wishlistData?.old_data?.meta_published_date
                          : wishlistData?.new_data?.meta_published_date}
                      </p>
                    </div>

                    <div className="d-flex gap-5 flex-wrap">
                      <div className="">
                        <span>Approved At</span>
                        <p>
                          {" "}
                          {wishlistData?.new_data === null
                            ? wishlistData?.old_data?.approvedAt
                            : wishlistData?.new_data?.approvedAt}
                        </p>
                      </div>{" "}
                      <div className="">
                        <span>Updated At</span>
                        <p>
                          {wishlistData?.new_data === null
                            ? wishlistData?.old_data?.updatedAt
                            : wishlistData?.new_data?.updatedAt}
                        </p>
                      </div>
                    </div>
                    <div>
                      <span className="">Card Image</span>
                      <div className="blog_create_image_container mt-1 position-relative d-flex  justify-content-center align-items-center">
                        <>
                          <img
                            src={
                              wishlistData?.new_data == null
                                ? wishlistData?.old_data?.card_image
                                : wishlistData?.new_data?.card_image
                            }
                            className={"user-edit-profile-picture"}
                          />
                          <button
                            type="button"
                            className="position-absolute border-0 bg-white d-flex align-items-center fs_10 top-0 end-0"
                            onClick={() => {
                              // downloadImage()
                            }}
                          >
                            <DownloadIcon width={8} />
                            <span className="ms-1">Download</span>
                          </button>
                        </>
                      </div>
                    </div>
                    <div className="mt-3 d-flex gap-5 flex-wrap">
                      <div>
                        <span className="">Display Image - 1</span>
                        <div className="blog_create_image_container mt-1 position-relative d-flex  justify-content-center align-items-center">
                          <>
                            <img
                              src={
                                wishlistData?.new_data == null
                                  ? wishlistData?.old_data?.display_image_1
                                  : wishlistData?.new_data?.display_image_1
                              }
                              className={"user-edit-profile-picture"}
                            />
                            <button
                              type="button"
                              className="position-absolute border-0 bg-white d-flex align-items-center fs_10 top-0 end-0"
                              onClick={() => {
                                // downloadImage()
                              }}
                            >
                              <DownloadIcon width={8} />
                              <span className="ms-1">Download</span>
                            </button>
                          </>
                        </div>
                      </div>
                      <div>
                        <span className="">Display Image - 2</span>
                        <div className="blog_create_image_container mt-1 position-relative d-flex  justify-content-center align-items-center">
                          <>
                            <img
                              src={
                                wishlistData?.new_data == null
                                  ? wishlistData?.old_data?.display_image_2
                                  : wishlistData?.new_data?.display_image_2
                              }
                              className={"user-edit-profile-picture"}
                            />
                            <button
                              type="button"
                              className="position-absolute border-0 bg-white d-flex align-items-center fs_10 top-0 end-0"
                              onClick={() => {
                                // downloadImage()
                              }}
                            >
                              <a
                                href={wishlistData?.new_data?.display_image_2}
                                download="file"
                              ></a>
                              <DownloadIcon width={8} />
                              <span className="ms-1">Download</span>
                            </button>
                          </>
                        </div>
                      </div>
                      <div>
                        <span className="">Display Image - 3</span>
                        <div className="blog_create_image_container mt-1 position-relative d-flex  justify-content-center align-items-center">
                          <>
                            <img
                              src={
                                wishlistData?.new_data == null
                                  ? wishlistData?.old_data?.display_image_3
                                  : wishlistData?.new_data?.display_image_3
                              }
                              className={"user-edit-profile-picture"}
                            />
                            <button
                              type="button"
                              className="position-absolute border-0 bg-white d-flex align-items-center fs_10 top-0 end-0"
                              onClick={() => {
                                // downloadImage()
                              }}
                            >
                              <DownloadIcon width={8} />
                              <span className="ms-1">Download</span>
                            </button>
                          </>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <CreateTable
                  data={
                    wishlistData?.new_data == null
                      ? wishlistData?.old_data?.content
                      : wishlistData?.new_data?.content
                  }
                  editContent={false}
                  tableTitle={"Content"}
                  containerText="Add content"
                  link={`/blog/edit/content-edit/`}
                />
              </div>
              <div className="white_shade_bg  py-1 d-flex">
                <div className="w-50">
                  <h5 className="fw_500 ps-4 dark_grey_color pt-2 text-nowrap fs_16 fn_Montserrat">
                    JSON Old Data
                  </h5>
                </div>
                <div className="w-50  d-none d-lg-block">
                  <h5 className="fw_500 ps-4  dark_grey_color pt-2 text-nowrap fs_16 fn_Montserrat">
                    JSON New Data
                  </h5>
                </div>
              </div>
              <div className="row mt-3 ps-0 ps-lg-4">
                <div className="col-12 col-lg-6">
                  <div className="notify_me_aprvls_vrsn_hstry_data_container  mt-3 pt-3 px-3">
                    <div className="d-flex justify-content-between">
                      <h5 className="fs_13 fn_Nunito fw_400 dark_title_color text-nowrap">
                        Old Data
                      </h5>
                      <ArrowRightIcon width={25} height={25} fill={"#A5A5A5"} />
                    </div>
                    {wishlistData.old_data === null ? (
                      <div>Empty data</div>
                    ) : (
                      <>
                        <JSONTree theme={theme} data={wishlistOldData} />;
                      </>
                    )}
                  </div>
                </div>
                <div className="col-12 mt-5 mt-lg-0 col-lg-6">
                  <h5 className="fw_500 ps-4 d-block d-lg-none white_shade_bg  dark_grey_color py-3 text-nowrap fs_16 fn_Montserrat">
                    JSON New Data
                  </h5>
                  <div className="notify_me_aprvls_vrsn_hstry_data_container    mt-4 mt-lg-3 pt-3 px-3">
                    <div className="d-flex justify-content-between">
                      <h5 className="fs_13 fn_Nunito fw_400 dark_title_color py-2 text-nowrap">
                        New Data
                      </h5>
                      <ArrowRightIcon width={25} height={25} fill={"#A5A5A5"} />
                    </div>
                    <div className="h-100">
                      {wishlistData.new_data === null ? (
                        <div>Empty data</div>
                      ) : (
                        <>
                          <JSONTree theme={theme} data={wishlistNewData} />;
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </LoadAndError>
          )}
        </InnerContainer>
      </div>
    </InnerLayout>
  );
};

export default BlogApprovalsDetails;
