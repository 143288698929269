import React from "react";
import Offcanvas from "react-bootstrap/Offcanvas";
import "./opportunitiesNavbar.css";
import Select, { components } from "react-select";
import ScrollTop from "../../../../assets/icons/scroll-top.svg";
import ArrowDownIcon from "../../../../assets/icons/arrowdown";
const BottomNavOffcanvas = ({
    //   scrollPosition,
    scrollToTop,
    handleClose,
    handleShow,
    show,
    btnName = "BUY IN",
}) => {
    const DropdownIndicator = (props) => {
        return (
            <components.DropdownIndicator {...props}>
                <ArrowDownIcon width={20} height={20} />
            </components.DropdownIndicator>
        );
    };
    return (
        <>
            <div className={`w-100  py-3 d-flex justify-content-between opportunities_bottom_navbar_container mt-2`}>
                <div className={`me-5`}>
                </div>
                <button
                    onClick={handleShow}
                    className={`d-block align-self-center  border-0 secondary_bg white_color px-5  rounded-2 py-2 fs_14 fontFam_montserrat fw_500`}
                >
                    <span>{btnName}</span>
                </button>
                <button onClick={scrollToTop} className="border-0 bg-transparent">
                    <img src={ScrollTop} />
                </button>
            </div>
            <Offcanvas
                show={show}
                bsPrefix={`botton_nav_offcanvas  offcanvas`}
                onHide={handleClose}
                placement={"bottom"}
                // backdrop={false}
                backdropClassName={`botton_nav_backdrop`}
            >
                <Offcanvas.Body>
                    <Offcanvas.Header
                        bsPrefix={`bottom_nav_offcanvas_btn_close_container d-flex justify-content-end`}
                        closeButton
                    >
                        <Offcanvas.Title></Offcanvas.Title>
                    </Offcanvas.Header>
                    <div
                        className={`d-flex flex-column col-12 col-sm-6 col-md-5 col-lg-4 col-xl-3 bottom_nav_offcanvas_select mx-auto mb-5 mt-3 `}
                    >
                        <Select
                            options={InterestOptions}
                            placeholder="ENTER/SELECT INTEREST VALUE"
                            styles={reactSelectStyles}
                            components={{
                                IndicatorSeparator: () => null,
                                DropdownIndicator,
                            }}
                            menuPlacement="top"
                            menuPosition={"fixed"}
                            noOptionsMessage={() => null}
                        />

                        <span className="light_grey_color fontFam_montserrat fw_600 text-center fs_md_12 fs_14 ">
                            Minimum interest value: $ XX,XXX
                        </span>
                    </div>

                    <div className={`row mx-auto`}>
                        <div
                            className={`d-flex col-6 mb-4 flex-column justify-content-start align-items-center border_right text-center  `}
                        >
                            <span className="secondary_color fontFam_montserrat  fs_16 fs_md_14 fw_500">
                                6 Months
                            </span>
                            <span className="patina_color fontFam_montserrat fw_600 fs_md_12 fs_14  ">
                                TENURE
                            </span>
                        </div>
                        <div
                            className={`d-flex col-6 mb-4 flex-column justify-content-start align-items-center text-center `}
                        >
                            <span className="secondary_color fontFam_montserrat text-center fs_16 fs_md_14 fw_500">
                                ₹ XX,XXX
                            </span>
                            <span className="patina_color fontFam_montserrat fw_600 fs_md_12 fs_14 ">
                                AVG. MONTHLY PAYOUT
                            </span>
                        </div>
                        <div
                            className={`d-flex col-6 mb-4 flex-column justify-content-start align-items-center border_right text-center `}
                        >
                            <span className="secondary_color fontFam_montserrat text-center fs_16 fs_md_14 fw_500">
                                ₹ XX,XXX
                            </span>
                            <span className="patina_color fontFam_montserrat fw_600 fs_md_12 fs_14 ">
                                LAST MONTH PAYOUT
                            </span>
                        </div>
                        <div
                            className={`d-flex col-6 mb-4 flex-column justify-content-start align-items-center text-center  `}
                        >
                            <span className="secondary_color fontFam_montserrat text-center  fs_16 fs_md_14 fw_500">
                                XX.X%
                            </span>
                            <span className="patina_color fontFam_montserrat fw_600 fs_md_12 fs_14 ">
                                POST TAX IRR
                            </span>
                        </div>
                    </div>
                    <div className={`w-100`}>
                        <button
                            className={`d-block mx-auto mt-2 border_none secondary_bg white_color px-5 border_radius py-2 fs_14 fontFam_montserrat fw_500`}
                        >
                            <span>{btnName}</span>
                        </button>
                    </div>
                </Offcanvas.Body>
            </Offcanvas>
        </>
    );
};

export default BottomNavOffcanvas;

const reactSelectStyles = {
    control: (base, state) => ({
        ...base,
        background: "#FFFF",
        width: "100%",
        fontSize: "10px",
        borderBottom: "2px solid #a5a5a5",
        borderTop: "none",
        borderLeft: "none",
        borderRight: "none",
        borderRadius: "none",

        boxShadow: state.isFocused ? null : null,
        "&:hover": {
            borderColor: state.isFocused ? "#a0acc3" : "#e6e6e6",
        },
    }),
    option: (provided, state) => ({
        ...provided,
        backgroundColor: state.isSelected ? "#7082A6" : null,
        borderBottom: "1px solid #F5F5F5",
        fontFamily: "Nunito",
        "&:hover": {
            backgroundColor: state.isFocused ? "#A0ACC3" : "##7082A6",
        },
    }),
    placeholder: (defaultStyles) => {
        return {
            ...defaultStyles,
            color: "#000000DE",
            fontFamily: "Montserrat",
            fontSize: "12px",
            textAlign: "center",
            fontWeight: "700",
        };
    },

    dropdownIndicator: (provided) => ({
        ...provided,
        svg: {
            fill: "#333333",
        },
        paddingTop: "5px",
    }),
    valueContainer: (provided, state) => ({
        ...provided,
        paddingRight: 0,
    }),
    input: (provided, state) => ({
        ...provided,
        maxWidth: 30,
    }),
    dropdownIndicator: (provided) => ({
        ...provided,
        padding: "0px 0px 0px 1rem",
    }),
    indicatorsContainer: (provided) => ({
        ...provided,
        paddingRight: "0.8rem",
    }),

    singleValue: (provided) => ({
        ...provided,
        fontSize: "16px",
        fontFamily: "Montserrat",
        fontWeight: "700",
        color: "#000000DE",
    }),
};
export const InterestOptions = [
    {
        label: `5%`,
        value: `5%`,
    },
    {
        label: `6%`,
        value: `6%`,
    },
    {
        label: `7%`,
        value: `7%`,
    },
    {
        label: `8%`,
        value: `8%`,
    },
];

