import { authHeader } from "../config";
import { api } from "./api";

export const addNewsletterApi = (params) => {
    return api.post("/api/admin/newsletter/create-newsletter", params, {
        headers: {
            "x-access-token": localStorage.getItem("accessToken"),
            "Content-Type": "application/json",
        },
    });
};
export const viewNewsletterListApi = (page, params) => {
    return api.post(`/api/admin/newsletter/view-approvals?page_no=${page}`, params, {
        headers: {
            "x-access-token": localStorage.getItem("accessToken"),
            "Content-Type": "application/json",
        },
    });
};
export const viewNewsletterApi = (id, params) => {
    return api.post(`/api/admin/newsletter/view-approval/${id}`, params, {
        headers: {
            "x-access-token": localStorage.getItem("accessToken"),
            "Content-Type": "application/json",
        },
    });
};
export const editNewsletterApi = (id, params) => {
    return api.post(`/api/admin/newsletter/edit/${id}`, params, {
        headers: {
            "x-access-token": localStorage.getItem("accessToken"),
            "Content-Type": "application/json",
        },
    });
};
export const deleteNewsletterApi = (id, params) => {
    return api.post(`/api/admin/newsletter/delete/${id}`, params, {
        headers: {
            "x-access-token": localStorage.getItem("accessToken"),
            "Content-Type": "application/json",
        },
    });
};
export const approveNewsletterApi = (params) => {
    return api.post(`/api/admin/newsletter/approve`, params, {
        headers: {
            "x-access-token": localStorage.getItem("accessToken"),
            "Content-Type": "application/json",
        },
    });
}
export const rejectNewsletterApi = (id, params) => {
    return api.post(`/api/admin/newsletter/reject/${id}`, params, {
        headers: {
            "x-access-token": localStorage.getItem("accessToken"),
            "Content-Type": "application/json",
        },
    });
};
export const approvalsNewsletterListpApi = (page = 0, params) => {
    return api.post(`/api/admin/newsletter/view-checker?page_no=${page}`, params, {
        headers: {
            "x-access-token": localStorage.getItem("accessToken"),
            "Content-Type": "application/json",
        },
    });
}
export const viewApprovalNewsletterApi = (id, params) => {
    return api.post(`/api/admin/newsletter/view-checker/${id}`, params, {
        headers: {
            "x-access-token": localStorage.getItem("accessToken"),
            "Content-Type": "application/json",
        },
    });
};
export const versionNewsletterListpApi = (page, params) => {
    return api.post(`/api/admin/newsletter/view-version?page_no=${page}`, params, {
        headers: {
            "x-access-token": localStorage.getItem("accessToken"),
            "Content-Type": "application/json",
        },
    });
}
export const viewVersionNewsletterApi = (id, params) => {
    return api.post(`/api/admin/newsletter/view-version/${id}`, params, {
        headers: {
            "x-access-token": localStorage.getItem("accessToken"),
            "Content-Type": "application/json",
        },
    });
};



export const newsLetterMainExportApi = (page, params) => {
    return api.post(
        `/api/admin/newsletter/export-main-news-letter`,
        JSON.stringify(params),
        {
            headers: {
                "x-access-token": localStorage.getItem("accessToken"),
                "Content-Type": "application/json",
            },
        }
    );
};

export const newsLetterApprovalsExportApi = (page, params) => {
    return api.post(
        `/api/admin/newsletter/export-approval-news-letter`,
        JSON.stringify(params),
        {
            headers: {
                "x-access-token": localStorage.getItem("accessToken"),
                "Content-Type": "application/json",
            },
        }
    );
};

export const newsLetterVersionExportApi = (page, params) => {
    return api.post(
        `/api/admin/newsletter/export-version-news-letter`,
        JSON.stringify(params),
        {
            headers: {
                "x-access-token": localStorage.getItem("accessToken"),
                "Content-Type": "application/json",
            },
        }
    );
};


export const newsLetterSubmitApi = (id, params) => {
    return api.post(`/api/admin/newsletter/on-submit/${id}`, params, {
        headers: {
            "x-access-token": localStorage.getItem("accessToken"),
            "Content-Type": "application/json",
        },
    });
};