import React, { useRef, useState } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import InnerLayout from "../../../../components/layouts/innerLayout/innerLayout";
import InnerContainer from "../../../../components/layouts/innerContainer/innerContainer";
import { useNavigate } from "react-router-dom";
import SaveBtn from "../../../../components/buttons/saveBtn";
import InputField from "../../../../components/form/inputFields/inputField";
import ErrorMsg from "../../../../components/form/errorMsg";
import { useDispatch } from "react-redux";
import successToast from "../../../../components/utilits/successToast";
import errorToast from "../../../../components/utilits/errorToast";
import UploadIcon from "../../../../assets/icons/upload";
import { uploadAddressProofFrontImageAction } from "../../../../redux/actions/userAction";
import CloseIcon from "../../../../assets/icons/closeIcon";
import HomeCounterTable from "../../../../components/static-pages/home-page/tables/homeCounterTable";
import HomeCardsTable from "../../../../components/static-pages/home-page/tables/homeCardsTable";
import { addCampaignAction } from "../../../../redux/actions/campaignActions";
import PartnerWithUsFaq from "../../../../components/static-pages/partner-with-us/tables/partnerWithUsFaq";
import PartnerWithUsAnimation from "../../../../components/static-pages/partner-with-us/tables/partnerWithUsAnimation";
import { addPartnerWithUsAction } from "../../../../redux/actions/partnerWithUsAction";

const PartnerWithUsCreate = () => {
  const navigate = useNavigate();
  const formRef = useRef();
  const dispatch = useDispatch();
  const initialValues = {
    partnerHeader: "",
    partnerTitle: "",
    partnerDesc: "",
    opporTitle: "",
    opporSubTitle: "",
    partnerWithUsAni: [],
    partnerWithUsFaq1: [],
    partnerWithUsFaq2: [],
  };
  const interestEditSchema = Yup.object().shape({
    partnerHeader: Yup.string().trim().required("Required"),
    partnerTitle: Yup.string().trim().required("Required"),
    partnerDesc: Yup.string().trim().required("Required"),
    opporTitle: Yup.string().trim().required("Required"),
    opporSubTitle: Yup.string().trim().required("Required"),
    partnerWithUsAni: Yup.array()
      .min(1, "Atleast One is required")
      .required("Required"),
    partnerWithUsFaq1: Yup.array()
      .min(1, "Atleast One is required")
      .required("Required"),
    partnerWithUsFaq2: Yup.array()
      .min(1, "Atleast One is required")
      .required("Required"),

  });
  const handleSubmit = () => {
    if (formRef.current) {
      formRef.current.handleSubmit();
    }
  };
  const onSubmit = (values, { resetForm }) => {
    console.log(values);
    dispatch(
      addPartnerWithUsAction(
        {
          "section1_heading": values.partnerHeader,
          "section1_title": values.partnerTitle,
          "section2_heading": values.partnerDesc,
          "partners_guide": values.partnerWithUsAni,
          "opportunity_title": values.opporTitle,
          "opportunity_subtitle": values.opporSubTitle,
          "partner_faq_section1": values.partnerWithUsFaq1,
          "partner_faq_section2": values.partnerWithUsFaq2
        },
        onSuccess,
        onError
      )
    );
  };
  const onSuccess = (data) => {
    successToast(data.message);
    navigate(`/static-pages/partner-with-us/edit/${data.data.id}`);
  };
  const onError = (data) => {
    console.log("====================================");
    console.log(data);
    console.log("====================================");
    errorToast(data.data);
  };
  const buttonList = [<SaveBtn submitFn={handleSubmit} />];
  return (
    <InnerLayout buttons={buttonList}>
      <div className="user_view_container mb-4">
        <InnerContainer>
          <Formik
            innerRef={formRef}
            initialValues={initialValues}
            onSubmit={onSubmit}
            enableReinitialize={true}
            validationSchema={interestEditSchema}
          >
            {({ values, errors, setFieldValue }) => {
              return (
                <Form className="px-4 py-3 col-12  col-xxl-10">
                  <div className="d-flex flex-column gap-0 gap-lg-5 flex-lg-row fs_14">
                    <div className="col-12 col-lg-6 pe-0 pe-lg-5">
                      <InputField
                        id={"partnerHeader"}
                        label={"Partner with Us Heading"}
                        type={"text"}
                        placeholder={"Enter Partner with Us Heading"}
                        name={"partnerHeader"}
                      />
                      <label
                        className="mb-1 ms-2 fw_400"
                        htmlFor="partnerWithTitle"
                      >
                        Partner Title
                      </label>
                      <Field name={"partnerTitle"}>
                        {({ field }) => (
                          <textarea
                            style={{ height: "4.5rem" }}
                            id="partnerWithTitle"
                            {...field}
                            type="text"
                            placeholder="Enter the home subtitle"
                            className={"w-100 mb-0 faq_message_container p-2 "}
                          ></textarea>
                        )}
                      </Field>
                      <ErrorMsg name={"partnerWithTitle"} />

                      <label
                        className="mb-1 ms-2 fw_400"
                        htmlFor=" PartnerDescription"
                      >
                        Partner Description
                      </label>
                      <Field name={"partnerDesc"}>
                        {({ field }) => (
                          <textarea
                            style={{ height: "4.5rem" }}
                            id="PartnerDescription"
                            {...field}
                            type="text"
                            placeholder="Enter the home subtitle"
                            className={"w-100 mb-0 faq_message_container p-2 "}
                          ></textarea>
                        )}
                      </Field>
                      <ErrorMsg name={"PartnerDescription"} />

                      <label
                        className="mb-1 ms-2 fw_400"
                        htmlFor="opportunityTitle"
                      >
                        Opportunity Section Title
                      </label>
                      <Field name={"opporTitle"}>
                        {({ field }) => (
                          <textarea
                            style={{ height: "4.5rem" }}
                            id="opportunityTitle"
                            {...field}
                            type="text"
                            placeholder="Enter the home subtitle"
                            className={"w-100 mb-0 faq_message_container p-2 "}
                          ></textarea>
                        )}
                      </Field>
                      <ErrorMsg name={"opportunityTitle"} />
                      <label
                        className="mb-1 ms-2 fw_400"
                        htmlFor="opportunitySubTitle"
                      >
                        Opportunity Section Sub Title
                      </label>
                      <Field name={"opporSubTitle"}>
                        {({ field }) => (
                          <textarea
                            style={{ height: "4.5rem" }}
                            id="opportunitySubTitle"
                            {...field}
                            type="text"
                            placeholder="Enter the home subtitle"
                            className={"w-100 mb-0 faq_message_container p-2 "}
                          ></textarea>
                        )}
                      </Field>
                      <ErrorMsg name={"opportunitySubTitle"} />
                      <PartnerWithUsAnimation
                        setFieldValue={setFieldValue}
                        formikValues={values}
                        name={"partnerWithUsAni"}
                      />
                    </div>
                    <div className="col-12 col-lg-6 pe-0 pe-lg-5">
                      <PartnerWithUsFaq
                        setFieldValue={setFieldValue}
                        formikValues={values}
                        name={"partnerWithUsFaq1"}
                      />
                      <PartnerWithUsFaq
                        setFieldValue={setFieldValue}
                        formikValues={values}
                        name={"partnerWithUsFaq2"}
                      />
                    </div>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </InnerContainer>
      </div>
    </InnerLayout>
  );
};

export default PartnerWithUsCreate;
