import { APPROVE_EXPRESS_INTEREST, CREATE_EXPRESS_INTEREST, DELETE_EXPRESS_INTEREST, EDIT_EXPRESS_INTEREST, EXPRESS_APPROVALS_EXPORT, EXPRESS_EXPORT, EXPRESS_INTEREST_APPROVAL_TABLE, EXPRESS_INTEREST_APPROVAL_VIEW, EXPRESS_INTEREST_OPTIONS, EXPRESS_INTEREST_TABLE, EXPRESS_INTEREST_VERSION_HISTORY, EXPRESS_INTEREST_VERSION_HISTORY_TABLE, EXPRESS_SUBMIT, EXPRESS_VERSION_EXPORT, REJECT_EXPRESS_INTEREST, VIEW_EXPRESS_INTEREST } from "./types";


export const createExpress = (
    data,
    onSuccess,
    onError
) => {
    return {
        type: CREATE_EXPRESS_INTEREST,
        data,
        onSuccess,
        onError,
    };
};

export const approveExpress = (id, onSuccess, onError) => {
    return {
        type: APPROVE_EXPRESS_INTEREST,
        id,
        onSuccess,
        onError,
    };
};

export const rejectExpress = (id, onSuccess, onError) => {
    return {
        type: REJECT_EXPRESS_INTEREST,
        id,
        onSuccess,
        onError,
    };
};

export const viewExpressDetail = (id, onSuccess, onError) => {
    return {
        type: VIEW_EXPRESS_INTEREST,
        id,
        onSuccess,
        onError,
    };
};

export const viewExpressList = (data, page_no, onSuccess, onError) => {
    return {
        type: EXPRESS_INTEREST_TABLE,
        data,
        page_no,
        onSuccess,
        onError,
    };
};

export const editExpress = (
    id,
    data,
    onSuccess,
    onError
) => {
    return {
        type: EDIT_EXPRESS_INTEREST,
        id,
        data,
        onSuccess,
        onError,
    };
};


export const deleteExpress = (id, onSuccess, onError) => {
    return {
        type: DELETE_EXPRESS_INTEREST,
        id,
        onSuccess,
        onError,
    };
};



export const expressApprovalList = (data, page_no, onSuccess, onError) => {
    return {
        type: EXPRESS_INTEREST_APPROVAL_TABLE,
        data,
        page_no,
        onSuccess,
        onError,
    };
};

export const expressApprovalById = (id, onSuccess, onError) => {
    return {
        type: EXPRESS_INTEREST_APPROVAL_VIEW,
        id,
        onSuccess,
        onError,
    };
};


export const versionHistoryExpressList = (
    data,
    page,
    onSuccess,
    onError) => {
    return {
        type: EXPRESS_INTEREST_VERSION_HISTORY_TABLE,
        data,
        page,
        onSuccess,
        onError,
    };
};

export const versionHistoryExpressDetail = (id, onSuccess, onError) => {
    return {
        type: EXPRESS_INTEREST_VERSION_HISTORY,
        id,
        onSuccess,
        onError,
    };
};
export const expressOptions = (id, onSuccess, onError) => {
    return {
        type: EXPRESS_INTEREST_OPTIONS,
        id,
        onSuccess,
        onError,
    };
};

export const expressTableExport = (onSuccess, onError) => {
    return {
        type: EXPRESS_EXPORT,
        onSuccess,
        onError,
    };
};
export const expressApprovalsExport = (onSuccess, onError) => {
    return {
        type: EXPRESS_APPROVALS_EXPORT,
        onSuccess,
        onError,
    };
};
export const expressVersionExport = (onSuccess, onError) => {
    return {
        type: EXPRESS_VERSION_EXPORT,
        onSuccess,
        onError,
    };
};


export const expressSubmit = (id, onSuccess, onError) => {
    return {
        type: EXPRESS_SUBMIT,
        id,
        onSuccess,
        onError,
    };
};  