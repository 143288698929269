import { authHeader } from "../config";
import { api } from "./api";

// export const addpartnerWithUsFormApi = (params) => {
//     return api.post("/api/admin/partner-with-us/create-partner-with-us", params, {
//         headers: {
//             "x-access-token": localStorage.getItem("accessToken"),
//             "Content-Type": "application/json",
//         },
//     });
// };
export const viewpartnerWithUsFormListApi = (page = 0, params) => {
    return api.post(`/api/admin/partner-with-us-form/featch-partner-form?page_no=${page}`, params, {
        headers: {
            "x-access-token": localStorage.getItem("accessToken"),
            "Content-Type": "application/json"
        },
    });
};
export const viewpartnerWithUsFormApi = (id, params) => {
    return api.post(`/api/admin/partner-with-us-form/featch-partner-form/${id}`, params, {
        headers: {
            "x-access-token": localStorage.getItem("accessToken"),
            "Content-Type": "application/json",
        },
    });
};
// export const editpartnerWithUsApi = (id, params) => {
//     return api.post(`/api/admin/partner-with-us/edit/${id}`, params, {
//         headers: {
//             "x-access-token": localStorage.getItem("accessToken"),
//             "Content-Type": "application/json",
//         },
//     });
// };
// export const deletepartnerWithUsApi = (id, params) => {
//     return api.post(`/api/admin/partner-with-us/delete/${id}`, params, {
//         headers: {
//             "x-access-token": localStorage.getItem("accessToken"),
//             "Content-Type": "application/json",
//         },
//     });
// };
// export const approvepartnerWithUsApi = (params) => {
//     return api.post(`/api/admin/partner-with-us/approve`, params, {
//         headers: {
//             "x-access-token": localStorage.getItem("accessToken"),
//             "Content-Type": "application/json",
//         },
//     });
// }
// export const rejectpartnerWithUsApi = (id, params) => {
//     return api.post(`/api/admin/partner-with-us/reject/${id}`, params, {
//         headers: {
//             "x-access-token": localStorage.getItem("accessToken"),
//             "Content-Type": "application/json",
//         },
//     });
// };
// export const approvalspartnerWithUsListpApi = (page = 0, params) => {
//     return api.post(`/api/admin/partner-with-us/view-checker?page_no=${page}`, params, {
//         headers: {
//             "x-access-token": localStorage.getItem("accessToken"),
//             "Content-Type": "application/json",
//         },
//     });
// }
// export const viewApprovalpartnerWithUsApi = (id, params) => {
//     return api.post(`/api/admin/partner-with-us/view-checker/${id}`, params, {
//         headers: {
//             "x-access-token": localStorage.getItem("accessToken"),
//             "Content-Type": "application/json",
//         },
//     });
// };
// export const versionpartnerWithUsListpApi = (page = 0, params) => {
//     return api.post(`/api/admin/partner-with-us/view-version?page_no=${page}`, params, {
//         headers: {
//             "x-access-token": localStorage.getItem("accessToken"),
//             "Content-Type": "application/json",
//         },
//     });
// }
// export const viewVersionpartnerWithUsApi = (id, params) => {
//     return api.post(`/api/admin/partner-with-us/view-version/${id}`, params, {
//         headers: {
//             "x-access-token": localStorage.getItem("accessToken"),
//             "Content-Type": "application/json",
//         },
//     });
// };
// export const activatepartnerWithUsApi = (id, params) => {
//     return api.post(`/api/admin/partner-with-us/is-active-update/${id}`, params, {
//         headers: {
//             "x-access-token": localStorage.getItem("accessToken"),
//             "Content-Type": "application/json",
//         },
//     });
// };




export const partnerWithUsFormMainExportApi = (page, params) => {
    return api.post(
        `/api/admin/partner-with-us/export-main-partner-with-us`,
        JSON.stringify(params),
        {
            headers: {
                "x-access-token": localStorage.getItem("accessToken"),
                "Content-Type": "application/json",
            },
        }
    );
};

// export const partnerWithUsApprovalsExportApi = (page, params) => {
//     return api.post(
//         `/api/admin/partner-with-us/export-approval-partner-with-us`,
//         JSON.stringify(params),
//         {
//             headers: {
//                 "x-access-token": localStorage.getItem("accessToken"),
//                 "Content-Type": "application/json",
//             },
//         }
//     );
// };

// export const partnerWithUsVersionExportApi = (page, params) => {
//     return api.post(
//         `/api/admin/partner-with-us/export-version-partner-with-us`,
//         JSON.stringify(params),
//         {
//             headers: {
//                 "x-access-token": localStorage.getItem("accessToken"),
//                 "Content-Type": "application/json",
//             },
//         }
//     );
// };


// export const partnerWithUsFormSubmitApi = (id, params) => {
//     return api.post(
//         `/api/admin/partner-with-us/on-submit/${id}`, JSON.stringify(params),
//         {
//             headers: {
//                 "x-access-token": localStorage.getItem("accessToken"),
//                 "Content-Type": "application/json",
//             },
//         }
//     );
// };
