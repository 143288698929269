import {
  FETCH_OPPORTUNITES_TYPE,
  OPPORTUNITES_CREATE,
  FETCH_OPPORTUNITES_LIST,
  APPROVE_OPPORTUNITY,
  DELETE_OPPORTUNITY,
  EDIT_OPPORTUNITY,
  FETCH_OPPORTUNITY_DETAILS,
  FETCH_OPPORTUNITES_APPROVAL_LIST,
  OPPORTUNITES_VERSION_HISTORY_TABLE,
  OPPORTUNITES_ANALYTICS,
  OPPORTUNITES_VERSION_VIEW,
  OPPORTUNITES_NAME_LIST,
  OPPORTUNITES_APPROVALS_VIEW,
  REJECT_OPPORTUNITY,
  OPPORTUNITIES_EXPORT,
  OPPORTUNITIES_APPROVALS_EXPORT,
  OPPORTUNITIES_VERSION_EXPORT,
  OPPORTUNITIES_SUBMIT,
  PUBLISH_OPPORTUNITY,
} from "./types";
export const createOpportunitiesAction = (
  data,
  onSuccess,
  onError
) => {
  return {
    type: OPPORTUNITES_CREATE,
    data,
    onSuccess,
    onError,
  };
};

export const fetchOpportunitiesType = (onSuccess, onError) => {
  return {
    type: FETCH_OPPORTUNITES_TYPE,
    onSuccess,
    onError,
  };
};

export const fetchOpportunitiesList = (data, page_no, onSuccess, onError) => {
  return {
    type: FETCH_OPPORTUNITES_LIST,
    data,
    page_no,
    onSuccess,
    onError,
  };
};

export const fetchOpportunitiesApprovalList = (data, page_no, onSuccess, onError) => {
  return {
    type: FETCH_OPPORTUNITES_APPROVAL_LIST,
    data,
    page_no,
    onSuccess,
    onError,
  };
};
export const publishOpportunity = (opportunityId, onSuccess, onError) => {
  return {
    type: PUBLISH_OPPORTUNITY,
    opportunityId,
    onSuccess,
    onError,
  };
};
export const approveOpportunity = (opportunityId, onSuccess, onError) => {
  return {
    type: APPROVE_OPPORTUNITY,
    opportunityId,
    onSuccess,
    onError,
  };
};
export const rejectOpportunity = (id, onSuccess, onError) => {
  return {
    type: REJECT_OPPORTUNITY,
    id,
    onSuccess,
    onError,
  };
};

export const deleteOpportunity = (id, onSuccess, onError) => {
  return {
    type: DELETE_OPPORTUNITY,
    id,
    onSuccess,
    onError,
  };
};

export const editOpportunitiesAction = (
  opportunityId,
  data,
  onSuccess,
  onError
) => {
  return {
    type: EDIT_OPPORTUNITY,
    opportunityId,
    data,
    onSuccess,
    onError,
  };
};

export const fetchOpportunitiesDetails = (
  opportunityId,
  onSuccess,
  onError
) => {
  return {
    type: FETCH_OPPORTUNITY_DETAILS,
    opportunityId,
    onSuccess,
    onError,
  };
};
export const opportunitiesVersionTable = (
  data,
  page,
  onSuccess,
  onError
) => {
  return {
    type: OPPORTUNITES_VERSION_HISTORY_TABLE,
    data,
    page,
    onSuccess,
    onError,
  };
};


export const opportunitiesAnalyticsAction = (
  id,
  onSuccess,
  onError
) => {
  return {
    type: OPPORTUNITES_ANALYTICS,
    id,
    onSuccess,
    onError,
  };
};
export const opportunitiesVersionViewAction = (
  id,
  onSuccess,
  onError
) => {
  return {
    type: OPPORTUNITES_VERSION_VIEW,
    id,
    onSuccess,
    onError,
  };
};
export const opportunitiesApprovalsView = (
  id,
  onSuccess,
  onError
) => {
  return {
    type: OPPORTUNITES_APPROVALS_VIEW,
    id,
    onSuccess,
    onError,
  };
};
export const opportunitiesNameListAction = (
  onSuccess,
  onError
) => {
  return {
    type: OPPORTUNITES_NAME_LIST,
    onSuccess,
    onError,
  };
};

export const opportunitiesTableExport = (onSuccess, onError) => {
  return {
    type: OPPORTUNITIES_EXPORT,
    onSuccess,
    onError,
  };
};
export const opportunitiesApprovalsExport = (onSuccess, onError) => {
  return {
    type: OPPORTUNITIES_APPROVALS_EXPORT,
    onSuccess,
    onError,
  };
};
export const opportunitiesVersionExport = (onSuccess, onError) => {
  return {
    type: OPPORTUNITIES_VERSION_EXPORT,
    onSuccess,
    onError,
  };
};


export const opportunitiesSubmitAction = (id, onSuccess, onError) => {
  return {
    type: OPPORTUNITIES_SUBMIT,
    id,
    onSuccess,
    onError,
  };
};