import { call, takeLatest } from "redux-saga/effects";
import {
  NEWSLETTER_APPROVALS_LIST,
  NEWSLETTER_APPROVALS_VIEW,
  NEWSLETTER_APPROVAL_EXPORT,
  NEWSLETTER_APPROVE,
  NEWSLETTER_CREATE,
  NEWSLETTER_DELETE,
  NEWSLETTER_EDIT,
  NEWSLETTER_LIST,
  NEWSLETTER_MAIN_EXPORT,
  NEWSLETTER_READ,
  NEWSLETTER_REJECT,
  NEWSLETTER_SUBMIT,
  NEWSLETTER_VERSION_EXPORT,
  NEWSLETTER_VERSION_LIST,
  NEWSLETTER_VERSION_VIEW,
} from "../actions/types";
import * as newsletterApis from "../apis/newsletterApis";

export function* addNewsLetterWorker(action) {
  try {
    const res = yield call(newsletterApis.addNewsletterApi, action.data);
    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res.data);
      console.log(res.data);
    } else if (res.status === 200 && res.data.status === "fail") {
      console.log(res.data);
      yield action.onError(res);
    } else {
      yield action.onError("Something Went Wrong");
    }
  } catch (err) {
    console.log(err);
    yield action.onError("Something Went Wrong");
  }
}

export function* viewNewsLetterWorker(action) {
  try {
    const res = yield call(newsletterApis.viewNewsletterApi, action.id);
    // console.log(res, "AddAdminRole");
    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res.data);
      console.log(res.data);
    } else if (res.status === 200 && res.data.status === "fail") {
      console.log(res.data);
      yield action.onError(res);
    } else {
      yield action.onError("Something Went Wrong!");
      console.log(res.data);
    }
  } catch (err) {
    console.log(err);
    yield action.onError("Something Went Wrong!!");
  }
}
export function* editNewsLetterWorker(action) {
  try {
    const res = yield call(
      newsletterApis.editNewsletterApi,
      action.id,
      action.data
    );
    console.log(res, "AddAdminRole");
    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res.data);
      console.log(res.data);
    } else if (res.status === 200 && res.data.status === "fail") {
      console.log(res);
      yield action.onError(res);
    } else {
      yield action.onError("Something Went Wrong");
    }
  } catch (err) {
    console.log(err);
    yield action.onError("Something Went Wrong");
  }
}
export function* deleteNewsLetterRoleWorker(action) {
  try {
    const res = yield call(newsletterApis.deleteNewsletterApi, action.id);
    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res.data);
      console.log(res.data);
    } else if (res.status === 200 && res.data.status === "fail") {
      console.log(res.data);
      yield action.onError(res);
    } else {
      yield action.onError("Something Went Wrong");
    }
  } catch (err) {
    console.log(err);
    yield action.onError("Something Went Wrong");
  }
}
export function* viewNewsLetterListWorker(action) {
  try {
    const res = yield call(
      newsletterApis.viewNewsletterListApi,
      action.page,
      action.data
    );
    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res.data);
      console.log(res.data);
    } else if (res.status === 200 && res.data.status === "fail") {
      console.log(res);
      yield action.onError(res.data);
    } else {
      yield action.onError("Something Went Wrong");
    }
  } catch (err) {
    console.log(err);
    yield action.onError("Something Went Wrong");
  }
}

export function* approveNewsLetterWorker(action) {
  try {
    const res = yield call(newsletterApis.approveNewsletterApi, {
      newsletter_id: action.id,
    });
    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res.data);
      console.log(res.data);
    } else if (res.status === 200 && res.data.status === "fail") {
      console.log(res);
      yield action.onError(res.data);
    } else {
      yield action.onError("Something Went Wrong!");
      console.log(res.data);
    }
  } catch (err) {
    console.log(err);
    yield action.onError("Something Went Wrong!!");
  }
}

export function* rejectNewsLetterWorker(action) {
  try {
    const res = yield call(newsletterApis.rejectNewsletterApi, action.id);
    // console.log(res, "AddAdminRole");
    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res.data);
      console.log(res.data);
    } else if (res.status === 200 && res.data.status === "fail") {
      console.log(res);
      yield action.onError(res.data);
    } else {
      yield action.onError("Something Went Wrong!");
      console.log(res.data);
    }
  } catch (err) {
    console.log(err);
    yield action.onError("Something Went Wrong!!");
  }
}
export function* approvalsNewsLetterListWorker(action) {
  try {
    const res = yield call(
      newsletterApis.approvalsNewsletterListpApi,
      action.page,
      action.data
    );
    // console.log(res, "AddAdminRole");
    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res.data);
      console.log(res.data);
    } else if (res.status === 200 && res.data.status === "fail") {
      console.log(res);
      yield action.onError(res);
    } else {
      yield action.onError("Something Went Wrong!");
      console.log(res.data);
    }
  } catch (err) {
    console.log(err);
    yield action.onError("Something Went Wrong!!");
  }
}
export function* viewApprovalNewsLetterWorker(action) {
  try {
    const res = yield call(newsletterApis.viewApprovalNewsletterApi, action.id);
    console.log(res, "NewsLetter approvals");
    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res.data);
      console.log(res.data);
    } else if (res.status === 200 && res.data.status === "fail") {
      console.log(res);
      yield action.onError(res);
    } else {
      yield action.onError("Something Went Wrong!");
      console.log(res.data);
    }
  } catch (err) {
    console.log(err);
    yield action.onError("Something Went Wrong!!");
  }
}
export function* versionListNewsletterWorker(action) {
  try {
    const res = yield call(
      newsletterApis.versionNewsletterListpApi,
      action.page,
      action.data
    );
    // console.log(res, "AddAdminRole");
    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res.data);
      console.log(res.data);
    } else if (res.status === 200 && res.data.status === "fail") {
      console.log(res);
      yield action.onError(res);
    } else {
      yield action.onError("Something Went Wrong!");
      console.log(res.data);
    }
  } catch (err) {
    console.log(err);
    yield action.onError("Something Went Wrong!!");
  }
}
export function* versionViewNewsletterWorker(action) {
  try {
    const res = yield call(newsletterApis.viewVersionNewsletterApi, action.id);
    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res);
      console.log(res);
    } else if (res.status === 200 && res.data.status === "fail") {
      console.log(res);
      yield action.onError(res);
    } else {
      yield action.onError("Something Went Wrong!");
      console.log(res);
    }
  } catch (err) {
    console.log(err);
    yield action.onError("Something Went Wrong!!");
  }
}

export function* newsLetterMainExportWorker(action) {
  try {
    const res = yield call(newsletterApis.newsLetterMainExportApi);
    console.log(res);
    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res.data);
    }
  } catch (error) {}
}

export function* newsLetterApprovalsExportWorker(action) {
  try {
    const res = yield call(newsletterApis.newsLetterApprovalsExportApi);
    console.log(res);
    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res.data);
    }
  } catch (error) {}
}

export function* newsLetterVersionExportWorker(action) {
  try {
    const res = yield call(newsletterApis.newsLetterVersionExportApi);
    console.log(res);
    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res.data);
    }
  } catch (error) {}
}


export function* newsLetterSubmitWorker(action) {
  try {
      const res = yield call(newsletterApis.newsLetterSubmitApi, action.id);
      console.log(res);
      if (res.status === 200 && res.data.status === "success") {
          yield action.onSuccess(res.data);
      } else {
          yield action.onError(res.data);
      }
  } catch (error) { }
}





export function* newsLetterSubmitWorkerWatcher() {
  yield takeLatest(NEWSLETTER_SUBMIT, newsLetterSubmitWorker);
}
/**********************************/
/************WATHCHERS**************/
/**********************************/

export function* watchAddNewsLetterWorker() {
  yield takeLatest(NEWSLETTER_CREATE, addNewsLetterWorker);
}
export function* watchApprovalsNewsLetterListWorker() {
  yield takeLatest(NEWSLETTER_APPROVALS_LIST, approvalsNewsLetterListWorker);
}
export function* watchViewNewsLetterWorker() {
  yield takeLatest(NEWSLETTER_READ, viewNewsLetterWorker);
}
export function* watchViewNewsLetterListWorker() {
  yield takeLatest(NEWSLETTER_LIST, viewNewsLetterListWorker);
}
export function* watcheditNewsLetterWorker() {
  yield takeLatest(NEWSLETTER_EDIT, editNewsLetterWorker);
}
export function* watchApproveNewsLetterWorker() {
  yield takeLatest(NEWSLETTER_APPROVE, approveNewsLetterWorker);
}
export function* watchRejectNewsLetterWorker() {
  yield takeLatest(NEWSLETTER_REJECT, rejectNewsLetterWorker);
}

export function* watchViewApprovalNewsLetterWorker() {
  yield takeLatest(NEWSLETTER_APPROVALS_VIEW, viewApprovalNewsLetterWorker);
}
export function* watchDeleteNewsletterWorker() {
  yield takeLatest(NEWSLETTER_DELETE, deleteNewsLetterRoleWorker);
}
export function* watchversionListNewsletterWorker() {
  yield takeLatest(NEWSLETTER_VERSION_LIST, versionListNewsletterWorker);
}
export function* watchVersionViewNewsletterWorker() {
  yield takeLatest(NEWSLETTER_VERSION_VIEW, versionViewNewsletterWorker);
}

export function* watchNewsLetterMainExportWorker() {
  yield takeLatest(NEWSLETTER_MAIN_EXPORT, newsLetterMainExportWorker);
}
export function* watchNewsLetterApprovalsExportWorker() {
  yield takeLatest(NEWSLETTER_APPROVAL_EXPORT, newsLetterApprovalsExportWorker);
}
export function* watchNewsLetterVersionExportWorker() {
  yield takeLatest(NEWSLETTER_VERSION_EXPORT, newsLetterVersionExportWorker);
}
