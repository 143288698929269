import {
  REFERED_USER_APPROVALS_EXPORT,
  REFERED_USER_APPROVALS_LIST,
  REFERED_USER_APPROVALS_VIEW,
  REFERED_USER_APPROVE,
  REFERED_USER_CREATE,
  REFERED_USER_DELETE,
  REFERED_USER_EDIT,
  REFERED_USER_EXPORT,
  REFERED_USER_LIST,
  REFERED_USER_READ,
  REFERED_USER_REJECT,
  REFERED_USER_SUBMIT,
  REFERED_USER_VERSION_EXPORT,
  REFERED_USER_VERSION_LIST,
  REFERED_USER_VERSION_VIEW,
} from "./types";

export const createReferedUserAction = (data, onSuccess, onError) => {
  return {
    type: REFERED_USER_CREATE,
    data,
    onSuccess,
    onError,
  };
};
export const viewAllReferedUserAction = (pageNo, search, onSuccess, onError) => {
  return {
    type: REFERED_USER_LIST,
    pageNo, search,
    onSuccess,
    onError,
  };
};

export const viewReferedUserAction = (id, onSuccess, onError) => {
  return {
    type: REFERED_USER_READ,
    id,
    onSuccess,
    onError,
  };
};
export const editReferedUserAction = (id, data, onSuccess, onError) => {
  return {
    type: REFERED_USER_EDIT,
    id,
    data,
    onSuccess,
    onError,
  };
};
export const approvalsReferedUserActionList = (data, pageNo, onSuccess, onError) => {
  return {
    type: REFERED_USER_APPROVALS_LIST,
    data,
    pageNo,
    onSuccess,
    onError,
  };
};
export const viewApprovalReferedUserAction = (id, onSuccess, onError) => {
  return {
    type: REFERED_USER_APPROVALS_VIEW,
    id,
    onSuccess,
    onError,
  };
};
export const approveReferedUserAction = (data, onSuccess, onError) => {
  return {
    type: REFERED_USER_APPROVE,
    data,
    onSuccess,
    onError,
  };
};
export const rejectReferedUserAction = (id, onSuccess, onError) => {
  return {
    type: REFERED_USER_REJECT,
    id,
    onSuccess,
    onError,
  };
};
export const deleteReferedUserAction = (id, onSuccess, onError) => {
  return {
    type: REFERED_USER_DELETE,
    id,
    onSuccess,
    onError,
  };
};
export const versionHistoryListReferedUserAction = (
  data,
  pageNo,
  onSuccess,
  onError
) => {
  return {
    type: REFERED_USER_VERSION_LIST,
    data,
    pageNo,
    onSuccess,
    onError,
  };
};
export const viewVersionHistoryReferedUserAction = (id, onSuccess, onError) => {
  return {
    type: REFERED_USER_VERSION_VIEW,
    id,
    onSuccess,
    onError,
  };
};

export const referedUserTableExport = (onSuccess, onError) => {
  return {
    type: REFERED_USER_EXPORT,
    onSuccess,
    onError,
  };
};
export const referedUserApprovalsExport = (onSuccess, onError) => {
  return {
    type: REFERED_USER_APPROVALS_EXPORT,
    onSuccess,
    onError,
  };
};
export const referedUserVersionExport = (onSuccess, onError) => {
  return {
    type: REFERED_USER_VERSION_EXPORT,
    onSuccess,
    onError,
  };
};

export const referedUserSubmitAction = (id, onSuccess, onError) => {
  return {
    type: REFERED_USER_SUBMIT,
    id,
    onSuccess,
    onError,
  };
};
