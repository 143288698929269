import React, { useEffect, useRef, useState } from "react";
import { Formik, Form, Field, FastField } from "formik";
import * as Yup from "yup";
import SelectFields from "../../../../components/form/selectFields/selectFields";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import successToast from "../../../../components/utilits/successToast";
import errorToast from "../../../../components/utilits/errorToast";
import HowItWorksReasonTable from "../../../../components/howItWorks/howItWorks/tables/howItWorksReasonTable";
import { uploadAddressProofFrontImageAction } from "../../../../redux/actions/userAction";
import InnerLayout from "../../../../components/layouts/innerLayout/innerLayout";
import InnerContainer from "../../../../components/layouts/innerContainer/innerContainer";
import LoadAndError from "../../../../components/utilits/LoadAndError";
import SaveBtn from "../../../../components/buttons/saveBtn";
import SubmitBtn from "../../../../components/buttons/submit";
import InputField from "../../../../components/form/inputFields/inputField";
import ErrorMsg from "../../../../components/form/errorMsg";
import CloseIcon from "../../../../assets/icons/closeIcon";
import UploadIcon from "../../../../assets/icons/upload";
import {
  editInsightsAction,
  InsightsCategorySubmit,
  viewInsightsAction,
} from "../../../../redux/actions/insightsAction";

const InsightsEdit = () => {
  const formRef = useRef();
  const navigate = useNavigate();
  const params = useParams();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [submitData, setSubmitData] = useState();
  const [error, setError] = useState(false);
  const [insightsData, setInsightsData] = useState({});
  const [deskImageLink, setDeskImageLink] = useState({
    img: "",
    error: false,
  });
  useEffect(() => {
    setLoading(true);
    dispatch(viewInsightsAction(params.id, onSuccess, onError));
  }, []);
  const onSuccess = (data) => {
    console.log(data.data);
    setInsightsData(data.data);
    console.log("SUCCESS");
    setDeskImageLink({ img: data.data.video_tumbnail_image, error: false });
    setLoading(false);
    setError(false);
  };
  const onError = (data) => {
    console.log("ERROR");
    setLoading(false);
    setError(true);
  };

  const initialValues = {
    headerTitle: insightsData?.header_title ? insightsData?.header_title : "",
    insightsHeading: insightsData?.section1_heading
      ? insightsData?.section1_heading
      : "",
    insightsTitle: insightsData?.section1_title
      ? insightsData?.section1_title
      : "",
    videoTitle: insightsData?.section3_heading
      ? insightsData?.section3_heading
      : "",
    videoUrl: insightsData?.video_url ? insightsData?.video_url : "",
    videoDesc: insightsData?.section3_title ? insightsData?.section3_title : "",
    insightsCategoryTitle: insightsData?.section2_heading
      ? insightsData?.section2_heading
      : "",
    insightsCategorySubtitle: insightsData?.section2_title
      ? insightsData?.section2_title
      : "",
    opportunitiesHeading: insightsData?.section4_heading
      ? insightsData?.section4_heading
      : "",
    opportunitiesTitle: insightsData?.section4_title
      ? insightsData?.section4_title
      : "",
    reasons: insightsData?.reasons ? insightsData?.reasons : [],
  };

  const interestEditSchema = Yup.object().shape({
    headerTitle: Yup.string().trim().required("Required"),
    insightsHeading: Yup.string().trim().required("Required"),
    insightsTitle: Yup.string().trim().required("Required"),
    videoTitle: Yup.string().trim().required("Required"),
    videoUrl: Yup.string().trim().required("Required"),
    videoDesc: Yup.string().trim().required("Required"),
    insightsCategoryTitle: Yup.string().trim().required("Required"),
    insightsCategorySubtitle: Yup.string().trim().required("Required"),
    reasons: Yup.array().min(1, "Atleast One is required").required("Required"),
    opportunitiesHeading: Yup.string().trim().required("Required"),
    opportunitiesTitle: Yup.string().trim().required("Required"),
  });
  const handleSubmit = () => {
    if (!deskImageLink.img) {
      setDeskImageLink({ img: "", error: true });
    }
    if (formRef.current) {
      formRef.current.handleSubmit();
    }
  };
  const onSubmit = (values, { resetForm }) => {
    if (deskImageLink.img) {
      dispatch(
        editInsightsAction(
          params.id,
          {
            header_title: values.headerTitle,
            section1_heading: values.insightsHeading,
            section1_title: values.insightsTitle,
            section2_heading: values.insightsCategoryTitle,
            section2_title: values.insightsCategorySubtitle,
            section3_heading: values.videoTitle,
            section3_title: values.videoDesc,
            video_url: values.videoUrl,
            video_tumbnail_image: deskImageLink.img,
            reasons: values.reasons,
            section4_heading: values.opportunitiesHeading,
            section4_title: values.opportunitiesTitle,
          },
          onEditSuccess,
          onEditError
        )
      );
    }
  };
  const onEditSuccess = (data) => {
    console.log(data);
    // setNotifyMeData(data.data)
    successToast(data.message);
    setSubmitData(data.data.id);
    setSaveHide(true);
    // navigate("/static-pages/how-It-Works")
    setLoading(false);
    setError(false);
  };
  const onEditError = (data) => {
    console.log(data);
    errorToast(data.message);
    setLoading(false);
    setError(true);
  };

  const imageUpload = (e) => {
    if (e.target.name === "deskImage") {
      dispatch(
        uploadAddressProofFrontImageAction(
          e.target.files[0],
          onDeskImageUploadSucess,
          onDeskImageUploadError
        )
      );
    }
    e.target.value = null;
  };
  const onDeskImageUploadSucess = (data) => {
    setDeskImageLink({ img: data.data.data.location, error: false });
  };
  const onDeskImageUploadError = (data) => {
    setDeskImageLink({ img: "", error: true });
  };

  //on submit api
  console.log(submitData);
  const [saveHide, setSaveHide] = useState(false);

  const submitFnc = () => {
    dispatch(
      InsightsCategorySubmit(submitData, onSubmitSuccess, onSubmitError)
    );
  };
  const onSubmitSuccess = (data) => {
    console.log(data);
    navigate("/static-pages/insights");
    successToast(data.message);
  };
  const onSubmitError = (data) => {
    console.log("ERROR==================", data);
    errorToast(data.message);
  };

  const buttonList = [
    !saveHide && <SaveBtn submitFn={handleSubmit} loader={loading} />,
    <SubmitBtn submitFn={submitFnc} saveHide={saveHide} />,
  ];
  return (
    <InnerLayout buttons={buttonList}>
      <div className="user_view_container">
        <InnerContainer>
          <LoadAndError loader={loading} error={error}>
            <Formik
              innerRef={formRef}
              initialValues={initialValues}
              onSubmit={onSubmit}
              enableReinitialize={true}
              validationSchema={interestEditSchema}
            >
              {({ formik, handleChange, values, setFieldValue }) => {
                return (
                  <Form className="px-4 py-3 col-12  col-xxl-10">
                    <div className="d-flex flex-column gap-0 gap-lg-5 flex-lg-row fs_14">
                      <div className="col-12 col-lg-6 pe-0 pe-lg-5">
                        <label className="mb-1 ms-2" htmlFor="Subtitle">
                          Header Title
                        </label>
                        <Field name={"headerTitle"}>
                          {({ field }) => (
                            <textarea
                              style={{ height: "3.5rem" }}
                              id="headerTitle"
                              {...field}
                              type="text"
                              placeholder="Enter header Title"
                              className={
                                "w-100 mb-0 faq_message_container p-2 "
                              }
                            ></textarea>
                          )}
                        </Field>
                        <ErrorMsg name={"headerTitle"} />
                        <InputField
                          id={"insightsHeading"}
                          label={"Insights heading"}
                          type={"text"}
                          placeholder={"Enter insights heading"}
                          name={"insightsHeading"}
                        />
                        <label className="mb-1 ms-2" htmlFor="insightsTitle">
                          Insights Title
                        </label>
                        <Field name={"insightsTitle"}>
                          {({ field }) => (
                            <textarea
                              style={{ height: "3.5rem" }}
                              id="insightsTitle"
                              {...field}
                              type="text"
                              placeholder="Enter insights title"
                              className={
                                "w-100 mb-0 faq_message_container p-2 "
                              }
                            ></textarea>
                          )}
                        </Field>
                        <ErrorMsg name={"insightsTitle"} />
                        <InputField
                          id={"Video Title"}
                          label={"Video Title"}
                          type={"text"}
                          placeholder={"Enter Video Title"}
                          name={"videoTitle"}
                        />
                        <InputField
                          id={"Video URL"}
                          label={"Video URL"}
                          type={"text"}
                          placeholder={"Enter URL"}
                          name={"videoUrl"}
                        />
                        <label className="mb-1 ms-2" htmlFor="videoDescription">
                          Video description
                        </label>
                        <Field name={"videoDesc"}>
                          {({ field }) => (
                            <textarea
                              style={{ height: "3.5rem" }}
                              id="videoDescription"
                              {...field}
                              type="text"
                              placeholder="Enter Video description"
                              className={
                                "w-100 mb-0 faq_message_container p-2 "
                              }
                            ></textarea>
                          )}
                        </Field>
                        <ErrorMsg name={"videoDesc"} />
                        <InputField
                          id={"insightsCategoryTitle"}
                          label={"Insights category title"}
                          type={"text"}
                          placeholder={"Enter insights category title"}
                          name={"insightsCategoryTitle"}
                        />
                        <label
                          className="mb-1 ms-2"
                          htmlFor="Insights Category Subtitle"
                        >
                          Insights Category Subtitle
                        </label>
                        <Field name={"insightsCategorySubtitle"}>
                          {({ field }) => (
                            <textarea
                              style={{ height: "3.5rem" }}
                              id="insightsCategorySubtitle"
                              {...field}
                              type="text"
                              placeholder="Enter insights Category Subtitle"
                              className={
                                "w-100 mb-0 faq_message_container p-2 "
                              }
                            ></textarea>
                          )}
                        </Field>
                        <ErrorMsg name={"insightsCategorySubtitle"} />
                      </div>
                      <div className="col-12 col-lg-6 pe-0 pe-lg-5">
                        <InputField
                          id={"opportunitiesHeading"}
                          label={"Opportunities Heading"}
                          type={"text"}
                          placeholder={"Enter Opportunities heading"}
                          name={"opportunitiesHeading"}
                        />{" "}
                        <label
                          className="mb-1 ms-2"
                          htmlFor="opportunitiesTitle"
                        >
                          Opportunities Title
                        </label>
                        <Field name={"opportunitiesTitle"}>
                          {({ field }) => (
                            <textarea
                              style={{ height: "3.5rem" }}
                              id="opportunitiesTitle"
                              {...field}
                              type="text"
                              placeholder="Enter Opportunities Title"
                              className={
                                "w-100 mb-0 faq_message_container p-2 "
                              }
                            ></textarea>
                          )}
                        </Field>
                        <ErrorMsg name={"opportunitiesTitle"} />
                        <HowItWorksReasonTable
                          setFieldValue={setFieldValue}
                          formikValues={values}
                          name={"reasons"}
                        />
                        <div className="d-flex gap-5 flex-wrap">
                          <div>
                            <span className="">Thumb Nail Image</span>
                            <div className="blog_create_image_container mt-1 position-relative d-flex  justify-content-center align-items-center">
                              <input
                                multiple={false}
                                style={{ visibility: "hidden" }}
                                type="file"
                                id="deskImage"
                                className="upload_document_input"
                                name="deskImage"
                                accept="image/* ,capture=camera"
                                onChange={(e) => {
                                  imageUpload(e);
                                }}
                              />
                              {deskImageLink.img ? (
                                <>
                                  <img
                                    src={deskImageLink.img}
                                    className={"user-edit-profile-picture"}
                                  />
                                  <button
                                    type="button"
                                    onClick={(e) => {
                                      setDeskImageLink({
                                        img: "",
                                        error: true,
                                      });
                                    }}
                                    className="position-absolute border-0 cursor_pointer white_bg d-flex gap-2 py-1 px-2 user_edit_profile_picture_remove_container"
                                  >
                                    <CloseIcon fill="#666666" />
                                    <span className="dark_grey_color fs_13 fn_Montserrat fw_500 ">
                                      Remove
                                    </span>
                                  </button>
                                </>
                              ) : (
                                <label
                                  htmlFor="deskImage"
                                  className="d-flex flex-column align-items-center mt-2 justify-content-center fs_12"
                                >
                                  <UploadIcon width={30} height={30} />
                                  Choose Profile Picture
                                </label>
                              )}
                            </div>
                            {deskImageLink.error && (
                              <span className="fs_12 ms-2 text-danger fw-semibold fn_Nunito">
                                Required!
                              </span>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </Form>
                );
              }}
            </Formik>
          </LoadAndError>
        </InnerContainer>
      </div>
    </InnerLayout>
  );
};

export default InsightsEdit;
export const faqName = [
  { value: "Type1", label: "Type1" },
  { value: "Type2", label: "Type2" },
  { value: "Type3", label: "Type3" },
  { value: "Type4", label: "Type4" },
];
