import * as React from "react";
import { Link } from "react-router-dom";
import TablePagination from "../../table/tablePagination";
import "../../admin/tables/admintable.css";
import {
  tableUsersResetEmailHeader,
} from "../../table/tableData";
import { TableContainer } from "../../table/tableContainer";
import { useState } from "react";
import { useEffect } from "react";
import * as userAction from "../../../redux/actions/userAction";
import { useDispatch } from "react-redux";
import errorToast from "../../utilits/errorToast";
import LoadingSpinnerTable from "../../utilits/loadingSpinnerTable";
import moment from "moment";
import LoadAndError from "../../utilits/LoadAndError";

const UserResetPassEmailTableContent = ({ page, search, changePage }) => {
  const dispatch = useDispatch();
  const [resetPasswordEmailData, setResetPasswordEmailData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [pageData, setPageData] = useState({ noOfItems: 0, noOfPages: 1 })
  useEffect(() => {
    setLoading(true);
    dispatch(
      userAction.ResetPasswordEmailView({
        search: {
          user_id: search.userId,
          email: search.email,
        }
      }, page, onSuccess, onError)
    );
  }, [page, search]);
  const onSuccess = (data) => {
    setResetPasswordEmailData(data.data.data);
    setPageData({
      noOfItems: data.data.count,
      noOfPages: data.data.pages
    })
    setError(false)
    setLoading(false);

  };
  const onError = (data) => {
    errorToast(data.message);
    setError(true)
    setLoading(false);
  };

  function handlePagination(type) {
    if (type === "+") {
      if (page + 1 < pageData.noOfPages) changePage("+");
    }
    else if (type === "-")
      if (page > 0) changePage("-");
  }
  return (
    <TableContainer width="92%">
      <div className="adminuser_table__container">
        {loading && <LoadingSpinnerTable />}
        <table className={"adminuser_table"}>
          <thead className="adminuser_table__header">
            <tr className="adminuser_table__row">
              {tableUsersResetEmailHeader.map((item) => {
                return (
                  <th
                    key={item}
                    className="text-nowrap adminuser_table__heading"
                  >
                    {item}
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody className="adminuser_table__body">
            <LoadAndError loader={loading} error={error} status={resetPasswordEmailData.length === 0}>
              {resetPasswordEmailData.map((item, index) => {
                return (
                  <tr key={item.token} className="adminuser_table__row">
                    <td className="text-nowrap dark_title_color adminuser_table__data ">
                      {page > 0 ? 12 * page + (index + 1) : index + 1}
                    </td>
                    <td className="text-nowrap secondary_color adminuser_table__data">
                      {item?.id}
                    </td>
                    <td className="text-nowrap secondary_color adminuser_table__data">
                      {item?.user_id}
                    </td>
                    <td className="text-nowrap secondary_color adminuser_table__data">
                      {item?.email}
                    </td>
                    <td className="text-nowrap secondary_color adminuser_table__data">
                      {item?.token}
                    </td>
                    <td className="text-nowrap dark_title_color adminuser_table__data">
                      {item?.expiry_date === null
                        ? "-"
                        : moment(item.expiry_date).format("DD-MM-YYYY HH:mm:ss")}
                    </td>
                    <td className="text-nowrap dark_title_color adminuser_table__data">
                      {item?.is_used === true ? "True" : "False"
                      }
                    </td>
                    <td className="text-nowrap dark_title_color adminuser_table__data">
                      {item?.createdAt === null
                        ? "-"
                        : moment(item?.createdAt).format("DD-MM-YYYY HH:mm:ss")}
                    </td>
                    <td className="text-nowrap dark_title_color adminuser_table__data">
                      {item.updatedAt === null
                        ? "-"
                        : moment(item.updatedAt).format("DD-MM-YYYY HH:mm:ss")}
                    </td>
                  </tr>
                );
              })}</LoadAndError>
          </tbody>
        </table>
      </div>
      <TablePagination
        paginate={handlePagination}
        currentPage={page}
        pageData={pageData}
      />
    </TableContainer>
  );
};

export default UserResetPassEmailTableContent;
