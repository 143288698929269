import React, { useRef } from "react";
import Modal from "react-bootstrap/Modal";
import CloseIcon from "../../../../assets/icons/closeIcon";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import "./opportunitiesmodals.css";
import InputField from "../../../form/inputFields/inputField";

const Tags = ({
  show,
  handleClose,
  id,
  reasonUpdator, formikValues
}) => {
  const formRef = useRef();

  const initialValues = {
    name: id === 0 || id ? formikValues[id]?.name : "",
    priority: id === 0 || id ? formikValues[id]?.priority : "",
    color_code: id === 0 || id ? formikValues[id]?.color_code : "",
  };
  const loginSchema = Yup.object().shape({
    name: Yup.string().trim(),
    priority: Yup.string().trim().matches(numRegex, "Number Only"),
    color_code: Yup.string().trim(),
  });
  const handleSubmit = () => {
    if (formRef.current) {
      formRef.current.handleSubmit();
    }
  };
  const onSubmit = (values, { resetForm }) => {
    resetForm();
    reasonUpdator(id, values)
    handleClose()
  };
  return (
    <Modal
      centered
      show={show}
      onHide={handleClose}
      dialogClassName={`oppoptunities_modals_body mx-auto `}
      contentClassName={"oppoptunities_modals_body_content  p-2"}
    >
      <Modal.Body>
        <>
          <div className="d-flex justify-content-between  ">
            <h2 className="green_color fs_15 fn_Montserrat fw_600 pt-1">
              TAGS
            </h2>
            <button
              type="button"
              onClick={handleClose}
              className="light_grey_color  fs_15 fn_Montserrat bg-transparent border-0 fw_600 "
            >
              <span className="pt-1">DISMISS</span>
              <span className="ms-2">
                <CloseIcon width={13} height={13} fill="#A5A5A5" />
              </span>
            </button>
          </div>
          <Formik
            innerRef={formRef}
            initialValues={initialValues}
            onSubmit={onSubmit}
            validationSchema={loginSchema}
          >
            <Form className="mt-3">
              <InputField
                id={"name"}
                label={"Enter Name"}
                type={"text"}
                placeholder={"Enter Name"}
                name={"name"}
              />
              <InputField
                id={"priority"}
                label={"Enter Priority"}
                type={"text"}
                placeholder={"Enter Priority"}
                name={"priority"}
              />
              <InputField
                inputClassName={"admin-create-password"}
                id={"colorCode"}
                type={"text"}
                label={"Color Code"}
                placeholder={"Enter Hex Color Code"}
                name={"color_code"}
              />
              <div className="d-flex justify-content-end mt-4">
                <button
                  type="submit"
                  className="secondary_color fs_15  fn_Montserrat fw_600 border-0 tertiary_bg px-4 px-lg-5 py-2 py-lg-2 rounded-2"
                >
               {id === null ? "CREATE" : "UPDATE"}
                </button>
              </div>
            </Form>
          </Formik>
        </>
      </Modal.Body>
    </Modal>
  );
};
export default Tags;
export const selectPriority = [
  { value: "Priority1", label: "Priority1" },
  { value: "Priority2", label: "Priority2" },
  { value: "Priority2", label: "Priority3" },
  { value: "Priority4", label: "Priority4" },
];
export const numRegex = /^[0-9]*$/s;