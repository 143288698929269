import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { TableContainer } from "../../table/tableContainer";
import { tableUsersVersionHeader } from "../../table/tableData";
import TablePagination from "../../table/tablePagination";
import "../../admin/tables/admintable.css";
import * as userAction from "../../../redux/actions/userAction";
import { useDispatch } from "react-redux";
import errorToast from "../../utilits/errorToast";
import LoadingSpinnerTable from "../../utilits/loadingSpinnerTable";
import moment from "moment";
import LoadAndError from "../../utilits/LoadAndError";
const UserVersionTableContent = ({ page, search, changePage }) => {
  const dispatch = useDispatch();
  const [userVersionHistoryList, setUserVersionHistoryList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [pageData, setPageData] = useState({ noOfItems: 0, noOfPages: 1 });
  useEffect(() => {
    setLoading(true);
    dispatch(
      userAction.userVersionHistoryList(
        {
          search: {
            user_id: search.Id,
          },
        },
        page,
        onSuccess,
        onError
      )
    );
  }, [page, search]);
  const onSuccess = (data) => {
    setUserVersionHistoryList(data?.data?.data);
    setPageData({
      noOfItems: data.data.count,
      noOfPages: data.data.pages,
    });
    setError(false);
    setLoading(false);
  };
  const onError = (data) => {
    errorToast(data.message);
    setError(true);
    setLoading(false);
  };
  function handlePagination(type) {
    if (type === "+") {
      if (page + 1 < pageData.noOfPages) changePage("+");
    } else if (type === "-") if (page > 0) changePage("-");
  }
  return (
    <TableContainer width="93%">
      <div className="adminuser_table__container">
        {loading && <LoadingSpinnerTable />}
        <table className={"adminuser_table"}>
          <thead className="adminuser_table__header">
            <tr className="adminuser_table__row">
              {tableUsersVersionHeader.map((item) => {
                return (
                  <th
                    key={item}
                    className={`text-nowrap adminuser_table__heading ${
                      item === "Status"
                        ? "position-sticky end-0 text-center"
                        : ""
                    } `}
                  >
                    {item}
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody className="adminuser_table__body">
            <LoadAndError
              loader={loading}
              error={error}
              status={userVersionHistoryList.length === 0}
            >
              {userVersionHistoryList.map((item, index) => {
                return (
                  <tr key={item?.id} className="adminuser_table__row">
                    <td className="text-nowrap dark_title_color adminuser_table__data ">
                      {page > 0 ? 12 * page + (index + 1) : index + 1}
                    </td>
                    <td className="text-nowrap secondary_color adminuser_table__data">
                      {item?.id}
                    </td>
                    <td className="text-nowrap secondary_color adminuser_table__data">
                      {item.user_id ? item?.user_id : "-"}
                    </td>
                    <td className="text-nowrap dark_title_color adminuser_table__data">
                      {item?.new_data == null
                        ? item?.old_data?.user_name
                        : item?.new_data?.user_name}
                    </td>
                    <td className="text-nowrap dark_title_color adminuser_table__data">
                      {item?.updated_by}
                    </td>
                    <td className="text-nowrap dark_title_color adminuser_table__data">
                      {item?.updatedAt === null
                        ? "-"
                        : moment(item?.updatedAt).format("DD-MM-YYYY HH:mm:ss")}
                    </td>
                    <td className="text-nowrap dark_title_color adminuser_table__data">
                      {item?.approved_by}
                    </td>
                    <td className="text-nowrap dark_title_color adminuser_table__data">
                      {item?.approved_date === null
                        ? "-"
                        : moment(item?.approved_date).format(
                            "DD-MM-YYYY HH:mm:ss"
                          )}
                    </td>
                    <td className="text-nowrap dark_title_color adminuser_table__data adminuser_table_empty__data"></td>

                    <td className="text-nowrap  adminuser_table__data position-sticky end-0">
                      <div className="d-flex justify-content-center w-100 gap-2">
                        <Link
                          className="text-decoration-none primary_color"
                          to={{
                            pathname: `/user/user-list/version-history/view/${item?.id}`,
                          }}
                        >
                          View
                        </Link>{" "}
                      </div>
                    </td>
                  </tr>
                );
              })}
            </LoadAndError>
          </tbody>
        </table>
      </div>
      <TablePagination
        paginate={handlePagination}
        currentPage={page}
        pageData={pageData}
      />
    </TableContainer>
  );
};

export default UserVersionTableContent;
