import React from 'react'
import DeleteIcon from '../../assets/icons/deleteIcon'
import EditIcon from '../../assets/icons/editIcon'
import usePermissionChecker from '../utilits/usePermissionChecker'
import { AdminButton } from './versionHistoryBtn'

const DeleteBtn = ({ showModal }) => {
    const access = usePermissionChecker("maker");
    return (access &&
        <AdminButton onClick={() => showModal()} color="#D20000" padding="3px 15px">
            <DeleteIcon fill="#D20000" />
            <span className="ms-1 pdt_1">DELETE</span>
        </AdminButton>
    )
}

export default DeleteBtn