import React, { useRef, useState, useEffect } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import InnerLayout from "../../../../components/layouts/innerLayout/innerLayout";
import InnerContainer from "../../../../components/layouts/innerContainer/innerContainer";
import SelectFields from "../../../../components/form/selectFields/selectFields";
import ArrowDownIcon from "../../../../assets/icons/arrowdown";
import { useParams, useNavigate } from "react-router-dom";
import SaveBtn from "../../../../components/buttons/saveBtn";
import DeleteBtn from "../../../../components/buttons/deleteBtn";
import InputField from "../../../../components/form/inputFields/inputField";
import { useDispatch, useSelector } from "react-redux";
import successToast from "../../../../components/utilits/successToast";
import errorToast from "../../../../components/utilits/errorToast";
import {
  deleteCategory,
  editCategory,
  viewCategoryDetail,
} from "../../../../redux/actions/faqCategoryActions";
import ErrorMsg from "../../../../components/form/errorMsg";
import {
  blogCategoryOptionsAction,
  editBlogCategoryAction,
  viewBlogCategoryDetailAction,
} from "../../../../redux/actions/blogCategoryActions";
import {
  blogSubmit,
  editBlogAction,
  viewBlogDetailAction,
} from "../../../../redux/actions/blogActions";

import CloseIcon from "../../../../assets/icons/closeIcon";
import UploadIcon from "../../../../assets/icons/upload";
import PublishBtn from "../../../../components/buttons/publishBtn";
import { uploadAddressProofFrontImageAction } from "../../../../redux/actions/userAction";
import CreateTable from "../../../../components/opportunity/opportunties/tables/opporttunitiesCreateTable";
import OpportunitiesContentSection from "../../../../components/opportunity/opportunties/editSection/opportunitiesContentSection";
import * as opportunityEditorActions from "../../../../redux/actions/opportunityEditorActions";
import LoadingSpinnerTable from "../../../../components/utilits/loadingSpinnerTable";
import LoadAndError from "../../../../components/utilits/LoadAndError";
import SubmitBtn from "../../../../components/buttons/submit";
import Compressor from "compressorjs";
const BlogEdit = () => {
  const params = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [submitData, setSubmitData] = useState();
  const [error, setError] = useState(false);
  const [categories, setCategories] = useState([]);
  const [cardImg, setCardImg] = useState("");
  const [blogData, setBlogData] = useState("");
  const dispatch = useDispatch();
  const formRef = useRef();
  const amountRegex = /^[0-9]*$/s;
  const [cardImageLink, setCardImageLink] = useState({
    img: "",
    error: false,
  });
  const [disImage1Link, setDisImage1Link] = useState({
    img: "",
    error: false,
  });
  const [disImage2Link, setDisImage2Link] = useState({
    img: "",
    error: false,
  });
  const [disImage3Link, setDisImage3Link] = useState({
    img: "",
    error: false,
  });
  const Options = [
    { value: "tag1", label: "tag 1" },
    { value: "tag2", label: "tag 2" },
  ];

  const interestEditSchema = Yup.object().shape({
    blogCategory: Yup.string().trim().required("Required"),
    blogTitle: Yup.string().trim().required("Required"),
    blogDesc: Yup.string().trim(),
    cardImgLink: Yup.string().trim(),
    // metaTags: Yup.string().trim().required("Required"),
    meta_published_date: Yup.date(),
    metaDesc: Yup.string().trim(),
  });

  useEffect(() => {
    setLoading(false);
    dispatch(blogCategoryOptionsAction(onSuccessDrop, onErrorDrop));
    dispatch(viewBlogDetailAction(params.id, onSuccessFetch, onErrorFetch));
  }, []);

  function onSuccessFetch(data) {
    setBlogData(data.data);
    console.log(data.data.content.length);
    dispatch(opportunityEditorActions.updateOppJson(data.data.content));
    setCardImageLink({ img: data.data.card_image, error: false });
    setDisImage1Link({ img: data.data.display_image_1, error: false });
    setDisImage2Link({ img: data.data.display_image_2, error: false });
    setDisImage3Link({ img: data.data.display_image_3, error: false });
    setLoading(false);
    setError(false);
  }
  function onErrorFetch(data) {
    console.log(data);
    setLoading(false);
    setError(true);
  }
  // console.log(blogData);
  const initialValues = {
    blogCategory: blogData?.blog_category_id ? blogData?.blog_category_id : "",
    blogTitle: blogData?.title ? blogData?.title : "",
    blogDesc: blogData?.description ? blogData?.description : "",
    cardImgLink: blogData?.card_image ? blogData?.card_image : "",
    metaTags: "",
    metaDesc: blogData?.meta_description ? blogData?.meta_description : "",
    meta_published_date: blogData?.meta_published_date
      ? blogData?.meta_published_date
      : "",
  };

  console.log(blogData);
  //For dropDwon fetch
  const onSuccessDrop = (data) => {
    const categoryList = [];
    data?.data.map((item, index) => {
      categoryList[index] = { value: item.id, label: item.name };
    });
    setCategories(categoryList);
    console.log("SUCCESS");
  };
  const onErrorDrop = (data) => {
    console.log("ERROR");
  };
  const handleSubmit = () => {
    // if (
    //   !cardImageLink.img ||
    //   !disImage1Link.img ||
    //   !disImage2Link.img ||
    //   !disImage3Link.img
    // ) {
    //   if (!cardImageLink.img) {
    //     setCardImageLink({ img: "", error: true });
    //   }
    //   if (!disImage1Link.img) {
    //     setDisImage1Link({ img: "", error: true });
    //   }
    //   if (!disImage2Link.img) {
    //     setDisImage2Link({ img: "", error: true });
    //   }
    //   if (!disImage3Link.img) {
    //     setDisImage3Link({ img: "", error: true });
    //   }
    // }
    if (formRef.current) {
      formRef.current.handleSubmit();
    }
  };
  const onSubmit = (values, { resetForm }) => {
    // if (
    //   cardImageLink.img &&
    //   disImage1Link.img &&
    //   disImage2Link.img &&
    //   disImage3Link.img
    // ) {
    const value = opportunityJsonReducer.data;
    localStorage.setItem("value", JSON.stringify(value));
    try {
      dispatch(
        editBlogAction(
          params?.id,
          {
            blog_category_id: values.blogCategory,
            title: values.blogTitle,
            description: values.blogDesc,
            card_image: cardImageLink.img,
            content: value,
            meta_tags: values.metaTags,
            meta_description: values.metaDesc,
            display_image_1: disImage1Link.img,
            display_image_2: disImage2Link.img,
            display_image_3: disImage3Link.img,
            meta_published: true,
            meta_published_date: values.meta_published_date,
          },
          onSuccess,
          onError
        )
      );
    } catch (error) {
      console.log(error);
    }
    // }
  };

  //For deletion
  const onDeleteSuccess = (data) => {
    console.log(data.data);
    setLoading(false);
    setError(false);
    successToast(data.message);
    navigate("/blog/approval-table");
  };
  const onDeleteError = (data) => {
    setLoading(false);
    errorToast(data.data.message);
    setError(true);
  };

  const handleShow = () => {
    dispatch(deleteCategory(params?.id, onDeleteSuccess, onDeleteError));
  };
  const imageUpload = (e) => {
    if (e.target.name === "cardImage") {
      new Compressor(e.target.files[0], {
        quality: 0.8,
        success(result) {
          console.log(result);
          const file = new File([result], result.name, { type: result.type });
          dispatch(
            uploadAddressProofFrontImageAction(
              file,
              onCardImageSucess,
              onCardImageError
            )
          );
        },
      });
    } else if (e.target.name === "disImage1Link") {
      new Compressor(e.target.files[0], {
        quality: 0.8,
        success(result) {
          console.log(result);
          const file = new File([result], result.name, { type: result.type });
          dispatch(
            uploadAddressProofFrontImageAction(
              file,
              onDis1imgSucess,
              onDis1imgError
            )
          );
        },
      });
    } else if (e.target.name === "disImage2Link") {
      new Compressor(e.target.files[0], {
        quality: 0.8,
        success(result) {
          console.log(result);
          const file = new File([result], result.name, { type: result.type });
          dispatch(
            uploadAddressProofFrontImageAction(
              file,
              onDis2imgSucess,
              onDis2imgError
            )
          );
        },
      });
    } else if (e.target.name === "disImage3Link") {
      new Compressor(e.target.files[0], {
        quality: 0.8,
        success(result) {
          console.log(result);
          const file = new File([result], result.name, { type: result.type });
          dispatch(
            uploadAddressProofFrontImageAction(
              file,
              onDis3imgSucess,
              onDis3imgError
            )
          );
        },
      });
    }
    e.target.value = null;
  };
  const onCardImageSucess = (data) => {
    setCardImageLink({ img: data.data.data.location, error: false });
  };
  const onCardImageError = (data) => {
    setCardImageLink({ img: "", error: true });
    console.log(data);
  };
  const onDis1imgSucess = (data) => {
    setDisImage1Link({ img: data.data.data.location, error: false });
  };
  const onDis1imgError = (data) => {
    setDisImage1Link({ img: "", error: true });
    console.log(data);
  };
  const onDis2imgSucess = (data) => {
    setDisImage2Link({ img: data.data.data.location, error: false });
  };
  const onDis2imgError = (data) => {
    setDisImage2Link({ img: "", error: true });
    console.log(data);
  };
  const onDis3imgSucess = (data) => {
    setDisImage3Link({ img: data.data.data.location, error: false });
  };
  const onDis3imgError = (data) => {
    setDisImage3Link({ img: "", error: true });
    console.log(data);
  };
  const opportunityJsonReducer = useSelector(
    (state) => state.opportunityJsonReducer
  );
  function addNewSection() {
    dispatch(opportunityEditorActions.addSection());
  }
  function deleteSection(index) {
    console.log("deleteSection", index);
    dispatch(opportunityEditorActions.deleteSection(index));
  }
  const onSuccess = (data) => {
    // on success reset reducer
    // console.log(data, "updateddddddddddddddddddddd");
    setLoading(false);
    setError(false);
    setSubmitData(data.data.id);
    setSaveHide(true);
    dispatch(opportunityEditorActions.clearOppJson());
    successToast(data.message);
  };
  const onError = (data) => {
    console.log(data);
    errorToast(data.data);
    setError(true);
  };

  //on submit api
  console.log(submitData);
  const [saveHide, setSaveHide] = useState(false);

  const submitFnc = () => {
    dispatch(blogSubmit(submitData, onSubmitSuccess, onSubmitError));
  };
  const onSubmitSuccess = (data) => {
    console.log(data);
    navigate("/blog");
    successToast(data.message);
  };
  const onSubmitError = (data) => {
    console.log("ERROR==================", data);
    errorToast(data.message);
  };
  const buttonList = [
    // <PublishBtn />,
    !saveHide && <SaveBtn submitFn={handleSubmit} loader={loading} />,
    <SubmitBtn submitFn={submitFnc} saveHide={saveHide} />,
    // <DeleteBtn />
    // <DeleteBtn showModal={handleShow}/>
  ];

  // const defaultCatValue=
  const defaultCatValue = categories?.filter(
    (item) => blogData?.blog_category_id === item.value
  );
  return (
    <InnerLayout buttons={buttonList}>
      <div className="user_view_container">
        <InnerContainer>
          {loading ? (
            <LoadingSpinnerTable />
          ) : (
            <LoadAndError loader={loading} error={error}>
              <Formik
                innerRef={formRef}
                initialValues={initialValues}
                onSubmit={onSubmit}
                enableReinitialize={true}
                validationSchema={interestEditSchema}
              >
                {({ formik, handleChange, values, setFieldValue }) => {
                  return (
                    <Form className="px-4 py-3">
                      <div className="d-flex flex-column flex-lg-row w-100 fs_14">
                        <div className="w-50">
                          <div className="   express-form-width">
                            <SelectFields
                              default1={defaultCatValue}
                              label={"Blog Category"}
                              placeholder={"Select category"}
                              name={"blogCategory"}
                              options={categories}
                              id={"blogCategory"}
                            />
                            <InputField
                              id={"blogTitle"}
                              label={"Blog Title"}
                              type={"text"}
                              placeholder={"Enter Blog Category Name here"}
                              name={"blogTitle"}
                            />
                          </div>
                          <div className="express-form-width">
                            <label
                              id="blogDesc"
                              className="mb-2 dark_black_color fn_Montserrat mt-2 fw_400 fs_16 ps-2"
                            >
                              Description
                            </label>
                            <Field name={"blogDesc"}>
                              {({ field }) => (
                                <textarea
                                  id="blogDesc"
                                  {...field}
                                  type="text"
                                  placeholder="Enter the blog category description"
                                  className={"w-100 faq_message_container p-2 "}
                                ></textarea>
                              )}
                            </Field>
                            <ErrorMsg name={"blogDesc"} />
                          </div>

                          <div className="express-form-width">
                            <InputField
                              id={"metaTags"}
                              label={"Meta Tags"}
                              type={"metaTags"}
                              placeholder={"Enter meta tags here"}
                              name={"metaTags"}
                            />
                          </div>
                          <div className="express-form-width">
                            <label
                              id="metaDesc"
                              className="mb-2 dark_black_color fn_Montserrat mt-2 fw_400 fs_16 ps-2"
                            >
                              Meta Description
                            </label>
                            <Field name={"metaDesc"}>
                              {({ field }) => (
                                <textarea
                                  id="metaDesc"
                                  {...field}
                                  type="text"
                                  placeholder="Enter the Meta category description"
                                  className={"w-100 faq_message_container p-2 "}
                                ></textarea>
                              )}
                            </Field>
                            <ErrorMsg name={"metaDesc"} />
                          </div>
                          <div className="   express-form-width">
                            <InputField
                              id={"meta_published_date"}
                              label={"Meta Published Date"}
                              type={"date"}
                              placeholder={"Date"}
                              name={"meta_published_date"}
                            />
                          </div>
                        </div>
                        <div className="col-12 col-lg-6">
                          <div className="">
                            <span>Meta Published Status</span>
                            <p>{blogData.meta_published_date}</p>
                          </div>

                          <div className="d-flex gap-5 flex-wrap">
                            <div className="">
                              <span>Approved At</span>
                              <p>0123asdf456jkl7w</p>
                            </div>{" "}
                            <div className="">
                              <span>Updated At</span>
                              <p>{blogData.updatedAt}</p>
                            </div>
                          </div>

                          <div>
                            <span className="">Card Image</span>
                            <div className="blog_create_image_container mt-1 position-relative d-flex  justify-content-center align-items-center">
                              <input
                                multiple={false}
                                style={{ visibility: "hidden" }}
                                type="file"
                                id="cardImage"
                                className="upload_document_input"
                                name="cardImage"
                                accept="image/* ,capture=camera"
                                onChange={(e) => {
                                  imageUpload(e);
                                }}
                              />
                              {cardImageLink.img ? (
                                <>
                                  <img
                                    src={cardImageLink.img}
                                    className={"user-edit-profile-picture"}
                                  />
                                  <button
                                    type="button"
                                    onClick={(e) => {
                                      setCardImageLink({
                                        img: "",
                                        error: true,
                                      });
                                    }}
                                    className="position-absolute border-0 cursor_pointer white_bg d-flex gap-2 py-1 px-2 user_edit_profile_picture_remove_container"
                                  >
                                    <CloseIcon fill="#666666" />
                                    <span className="dark_grey_color fs_13 fn_Montserrat fw_500 ">
                                      Remove
                                    </span>
                                  </button>
                                </>
                              ) : (
                                <label
                                  htmlFor="cardImage"
                                  className="d-flex flex-column align-items-center mt-2 justify-content-center fs_12"
                                >
                                  <UploadIcon width={30} height={30} />
                                  Choose Profile Picture
                                </label>
                              )}
                            </div>
                            {cardImageLink.error && (
                              <span className="fs_12 ms-2 text-danger fw-semibold fn_Nunito">
                                Required!
                              </span>
                            )}
                          </div>
                          <div className="mt-3 d-flex gap-5 flex-wrap">
                            <div>
                              <span className="">Display Image 1</span>
                              <div className="blog_create_image_container mt-1 position-relative d-flex  justify-content-center align-items-center">
                                <input
                                  multiple={false}
                                  style={{ visibility: "hidden" }}
                                  type="file"
                                  id="disImage1Link"
                                  className="upload_document_input"
                                  name="disImage1Link"
                                  accept="image/* ,capture=camera"
                                  onChange={(e) => {
                                    imageUpload(e);
                                  }}
                                />
                                {disImage1Link.img ? (
                                  <>
                                    <img
                                      src={disImage1Link.img}
                                      className={"user-edit-profile-picture"}
                                    />
                                    <button
                                      type="button"
                                      onClick={(e) => {
                                        setDisImage1Link({
                                          img: "",
                                          error: true,
                                        });
                                      }}
                                      className="position-absolute border-0 cursor_pointer white_bg d-flex gap-2 py-1 px-2 user_edit_profile_picture_remove_container"
                                    >
                                      <CloseIcon fill="#666666" />
                                      <span className="dark_grey_color fs_13 fn_Montserrat fw_500 ">
                                        Remove
                                      </span>
                                    </button>
                                  </>
                                ) : (
                                  <label
                                    htmlFor="disImage1Link"
                                    className="d-flex flex-column align-items-center mt-2 justify-content-center fs_12"
                                  >
                                    <UploadIcon width={30} height={30} />
                                    Choose Profile Picture
                                  </label>
                                )}
                              </div>
                              {disImage1Link.error && (
                                <span className="fs_12 ms-2 text-danger fw-semibold fn_Nunito">
                                  Required!
                                </span>
                              )}
                            </div>

                            <div>
                              <span className="">Display Image 2</span>
                              <div className="blog_create_image_container mt-1 position-relative d-flex  justify-content-center align-items-center">
                                <input
                                  multiple={false}
                                  style={{ visibility: "hidden" }}
                                  type="file"
                                  id="disImage2Link"
                                  className="upload_document_input"
                                  name="disImage2Link"
                                  accept="image/* ,capture=camera"
                                  onChange={(e) => {
                                    imageUpload(e);
                                  }}
                                />
                                {disImage2Link.img ? (
                                  <>
                                    <img
                                      src={disImage2Link.img}
                                      className={"user-edit-profile-picture"}
                                    />
                                    <button
                                      type="button"
                                      onClick={(e) => {
                                        setDisImage2Link({
                                          img: "",
                                          error: true,
                                        });
                                      }}
                                      className="position-absolute border-0 cursor_pointer white_bg d-flex gap-2 py-1 px-2 user_edit_profile_picture_remove_container"
                                    >
                                      <CloseIcon fill="#666666" />
                                      <span className="dark_grey_color fs_13 fn_Montserrat fw_500 ">
                                        Remove
                                      </span>
                                    </button>
                                  </>
                                ) : (
                                  <label
                                    htmlFor="disImage2Link"
                                    className="d-flex flex-column align-items-center mt-2 justify-content-center fs_12"
                                  >
                                    <UploadIcon width={30} height={30} />
                                    Choose Profile Picture
                                  </label>
                                )}
                              </div>
                              {disImage2Link.error && (
                                <span className="fs_12 ms-2 text-danger fw-semibold fn_Nunito">
                                  Required!
                                </span>
                              )}
                            </div>

                            <div>
                              <span className="">Display Image 3</span>
                              <div className="blog_create_image_container mt-1 position-relative d-flex  justify-content-center align-items-center">
                                <input
                                  multiple={false}
                                  style={{ visibility: "hidden" }}
                                  type="file"
                                  id="disImage3Link"
                                  className="upload_document_input"
                                  name="disImage3Link"
                                  accept="image/* ,capture=camera"
                                  onChange={(e) => {
                                    imageUpload(e);
                                  }}
                                />
                                {disImage3Link.img ? (
                                  <>
                                    <img
                                      src={disImage3Link.img}
                                      className={"user-edit-profile-picture"}
                                    />
                                    <button
                                      type="button"
                                      onClick={(e) => {
                                        setDisImage3Link({
                                          img: "",
                                          error: true,
                                        });
                                      }}
                                      className="position-absolute border-0 cursor_pointer white_bg d-flex gap-2 py-1 px-2 user_edit_profile_picture_remove_container"
                                    >
                                      <CloseIcon fill="#666666" />
                                      <span className="dark_grey_color fs_13 fn_Montserrat fw_500 ">
                                        Remove
                                      </span>
                                    </button>
                                  </>
                                ) : (
                                  <label
                                    htmlFor="disImage3Link"
                                    className="d-flex flex-column align-items-center mt-2 justify-content-center fs_12"
                                  >
                                    <UploadIcon width={30} height={30} />
                                    Choose Profile Picture
                                  </label>
                                )}
                              </div>
                              {disImage3Link.error && (
                                <span className="fs_12 ms-2 text-danger fw-semibold fn_Nunito">
                                  Required!
                                </span>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* <CreateTable
                    data={opportunityJsonReducer?.data}
                    editContent={false}
                    tableTitle={"Content"}
                    containerText="Add content"
                    link={`/blog/edit/content-edit/`}
                  /> */}
                      {opportunityJsonReducer.data.map((item, index) => {
                        return (
                          <OpportunitiesContentSection
                            key={item.id}
                            index={index}
                            updateSectionData={() => {}}
                            numberOfSection={opportunityJsonReducer.data.length}
                            deleteSection={deleteSection}
                            addNewSection={addNewSection}
                          />
                        );
                      })}
                    </Form>
                  );
                }}
              </Formik>
            </LoadAndError>
          )}
        </InnerContainer>
      </div>
    </InnerLayout>
  );
};

export default BlogEdit;
