import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { TableContainer } from "../../table/tableContainer";
import {
  tableAdminRolesApprovalHeader,
} from "../../table/tableData";
import TablePagination from "../../table/tablePagination";
import "./admintable.css";
import * as adminRolesActions from "../../../redux/actions/adminRoleActions";
import { useDispatch } from "react-redux";
import errorToast from "../../utilits/errorToast";
import LoadingSpinnerTable from "../../utilits/loadingSpinnerTable";
import moment from "moment";
import usePermissionChecker from "../../utilits/usePermissionChecker";

const AdminRolesApprovalTableContent = ({ page, search, changePage }) => {
  const dispatch = useDispatch();
  const [approvalsListData, setApprovalsListData] = useState([])
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [pageData, setPageData] = useState({ noOfItems: 0, noOfPages: 1 })
  useEffect(() => {
    setLoading(true)
    dispatch(
      adminRolesActions.approvalsAdminRoleList(
        {
          search: {
            role_id: search.id,
            role_name: search.name
          }
        },
        page,
        onSuccess,
        onError)
    );
  }, [page, search])
  const onSuccess = (data) => {
    setApprovalsListData(data.data.data)
    setPageData({
      noOfItems: data.data.count,
      noOfPages: data.data.pages
    })
    setError(false)
    setLoading(false)
  };
  const onError = (data) => {
    errorToast(data.message)
    setError(true)
    setLoading(false)
  };
  function handlePagination(type) {
    if (type === "+") {
      if (page + 1 < pageData.noOfPages) changePage("+");
    }
    else if (type === "-")
      if (page > 0) changePage("-");
  }
  console.log(approvalsListData);
  return (
    <TableContainer maxWidth="93%" width="90%">
      <div className="adminuser_table__container ">
        {loading &&
          <LoadingSpinnerTable />}

        <table className={"adminuser_table"}>
          <thead className="adminuser_table__header">
            <tr className="adminuser_table__row">
              {tableAdminRolesApprovalHeader.map((item) => {
                return (
                  <th
                    key={item}
                    className={`text-nowrap adminuser_table__heading  ${item === "Status" ? "text-center position-sticky end-0" : null}`}
                  >
                    {item}
                  </th>
                );
              })}
            </tr>
          </thead>
          {loading === false &&
            <tbody className="adminuser_table__body">
              {approvalsListData.map((item, index) => {
                return (
                  <tr key={item.id} className="adminuser_table__row">
                    <td className="text-nowrap dark_title_color adminuser_table__data ">
                      {page > 0 ? 12 * page + (index + 1) : index + 1}
                    </td>
                    <td className="text-nowrap dark_title_color adminuser_table__data">
                      {item.id
                      }
                    </td>
                    <td className="text-nowrap dark_title_color adminuser_table__data">
                      {item.role_id
                      }
                    </td>
                    <td className="text-nowrap secondary_color adminuser_table__data">
                      {item.new_data === null ? item.old_data?.role_name : item.new_data?.role_name}
                    </td>
                    <td className="text-nowrap secondary_color adminuser_table__data">
                      <Link
                        className="text-decoration-none secondary_color"
                        to={{
                          pathname: `/admin-users/view/${item?.updated_by === null ? "-" : item?.updated_by
                            }`,
                        }}
                      >
                        {item?.updated_by === null ? "-" : item?.updated_by}
                      </Link>
                    </td>
                    <td className="text-nowrap dark_title_color adminuser_table__data">
                      {moment(item.updatedAt).format("DD-MM-YYYY HH:mm:ss")}
                    </td>
                    <td className="adminuser_table__data adminrole_table_empty__data ">
                    </td>

                    <td className="text-nowrap adminuser_table__data position-sticky end-0">
                      <div className="d-flex w-100 justify-content-center gap-2">
                        <Link
                          className="text-decoration-none primary_color"
                          to={{
                            pathname: `/admin-roles/approvals/view/${item.id}`,
                          }
                          }
                        >
                          View
                        </Link>{" "}
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>}
        </table>
      </div>
      <TablePagination paginate={handlePagination}
        currentPage={page}
        pageData={pageData} />
    </TableContainer>
  );
};

export default AdminRolesApprovalTableContent;
