import React, { useEffect, useState } from "react";
import ApprovalsBtn from "../../../../components/buttons/approvalsBtn";
import CreateBtn from "../../../../components/buttons/createBtn";
import ExportBtn from "../../../../components/buttons/exportBtn";
import FilterBtn from "../../../../components/buttons/filterBtn";
import VersionHistoryBtn from "../../../../components/buttons/versionHistoryBtn";
import AdminUserFilter from "../../../../components/filter/adminUserFilter";
import FaqFilter from "../../../../components/filter/static-pages filter/faqFilter";
import VersionTablesFilter from "../../../../components/filter/versionTableFilter";
import InnerLayout from "../../../../components/layouts/innerLayout/innerLayout";
import FaqTableContent from "../../../../components/static-pages/faqs/tables/faqTableContent";
import InvitedTableContent from "../../../../components/static-pages/invitedMembers/tables/invitedTableContent";
import ReferTableContent from "../../../../components/static-pages/referAndEarn/tables/referTableContent";
import { faqTableExport } from "../../../../redux/actions/faqAction";
import { invitedTableExport } from "../../../../redux/actions/invitedAction";
import { referTableExport } from "../../../../redux/actions/referAction";

const InvitedTable = () => {
  const [page, setPage] = useState(0);
  const [showFilter, setShowFilter] = useState(false);
  const [search, setSearch] = useState({ value: "" });
  const handleFilterClose = () => {
    setShowFilter(false);
  };
  const handleFilterOpen = () => {
    setShowFilter(true);
  };
  const handleSearch = (value) => {
    setSearch({ value: value });
    setPage(0)
  };
  const handlePage = (type) => {
    if (type === "+") {
      setPage((prev) => prev + 1)
    }
    else {
      setPage((prev) => prev - 1)
    }
  }
  const buttonList = [
    // <ApprovalsBtn link='/static-pages/invited/approvals' />,
    // <VersionHistoryBtn link='/static-pages/invited/version-history' />,
    // <CreateBtn link='/static-pages/invited/create' />,
    <ExportBtn dispatchAction={invitedTableExport} />,
    <FilterBtn filterFn={handleFilterOpen} />,
  ];
  return (
    <InnerLayout buttons={buttonList}>
      <VersionTablesFilter
        title='Invited Id'
        placeholder='Enter Invited Id'
        filter={handleSearch}
        showFilter={showFilter}
        closeFilter={handleFilterClose}
      />
      <InvitedTableContent page={page} changePage={handlePage} search={search} />
    </InnerLayout>
  );
};

export default InvitedTable;
