import { call, takeLatest } from "redux-saga/effects";
import {
  APPROVE_STATIC,
  CREATE_STATIC,
  DELETE_STATIC,
  EDIT_STATIC,
  REJECT_STATIC,
  STATIC_APPROVAL_EXPORT,
  STATIC_APPROVAL_TABLE,
  STATIC_APPROVAL_VIEW,
  STATIC_MAIN_EXPORT,
  STATIC_OPTIONS,
  STATIC_SUBMIT,
  STATIC_TABLE,
  STATIC_VERSION_EXPORT,
  STATIC_VERSION_HISTORY,
  STATIC_VERSION_HISTORY_TABLE,
  VIEW_STATIC,
} from "../actions/types";
import {
  approveStaticApi,
  createStaticApi,
  deleteStaticApi,
  editStaticApi,
  rejectStaticApi,
  staticApprovalByIdApi,
  staticApprovalListApi,
  staticApprovalsExportApi,
  staticMainExportApi,
  staticOptionsApi,
  staticSubmitApi,
  staticVersionExportApi,
  versionHistoryStaticDetailApi,
  versionHistoryStaticListApi,
  viewStaticDetailApi,
  viewStaticListApi,
} from "../apis/staticApi";

export function* createStaticWorker(action) {
  try {
    const res = yield call(createStaticApi, action.data);
    console.log(res);

    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res.data);
    }
  } catch (error) {}
}
export function* staticApprovalListWorker(action) {
  console.log(action.search, action.page_no);
  try {
    const res = yield call(staticApprovalListApi, action.page_no, action.data);
    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res.data.data);
      console.log(res.data);
    } else {
      yield action.onError(res.data);
    }
  } catch (error) {}
}

export function* staticApprovalByIdWorker(action) {
  try {
    const res = yield call(staticApprovalByIdApi, action.id);
    console.log(res);
    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res.data);
      console.log(res.data);
    } else {
      yield action.onError(res.data);
    }
  } catch (error) {}
}
export function* approveStaticWorker(action) {
  try {
    const res = yield call(approveStaticApi, {
      static_id: action.id,
    });
    console.log(res);
    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res.data);
    }
  } catch (error) {}
}
export function* rejectStaticWorker(action) {
  try {
    const res = yield call(rejectStaticApi, action.id);
    console.log(res);
    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res.data);
    }
  } catch (error) {}
}
export function* viewStaticListWorker(action) {
  try {
    const res = yield call(viewStaticListApi, action.page_no, action.data);
    console.log(res);
    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res.data.data);
      console.log(res.data.data);
    } else {
      yield action.onError(res.data);
    }
  } catch (error) {}
}
export function* viewStaticDetailWorker(action) {
  try {
    const res = yield call(viewStaticDetailApi, action.id);
    console.log(res);
    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res.data);
      console.log(res.data);
    } else {
      yield action.onError(res.data);
    }
  } catch (error) {}
}
export function* editStaticWorker(action) {
  try {
    const res = yield call(editStaticApi, action.id, action.data);
    console.log(res);
    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res.data);
      console.log(res.data);
    } else {
      yield action.onError(res.data);
    }
  } catch (error) {}
}

export function* deleteStaticWorker(action) {
  try {
    const res = yield call(deleteStaticApi, action.id);
    console.log(res);
    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res.data);
    }
  } catch (error) {}
}

export function* versionHistoryStaticListWorker(action) {
  try {
    const res = yield call(
      versionHistoryStaticListApi,
      action.page,
      action.data
    );
    console.log(res);
    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res.data);
    }
  } catch (error) {}
}

export function* versionHistoryStaticDetailWorker(action) {
  try {
    const res = yield call(versionHistoryStaticDetailApi, action.id);
    console.log(res);
    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res.data);
    }
  } catch (error) {}
}

export function* staticOptionsWorker(action) {
  try {
    const res = yield call(staticOptionsApi);
    console.log(res);
    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res.data);
      console.log(res.data);
    } else {
      yield action.onError(res.data);
    }
  } catch (error) {}
}

export function* staticMainExportWorker(action) {
  try {
    const res = yield call(staticMainExportApi);
    console.log(res);
    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res.data);
    }
  } catch (error) {}
}

export function* staticApprovalsExportWorker(action) {
  try {
    const res = yield call(staticApprovalsExportApi);
    console.log(res);
    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res.data);
    }
  } catch (error) {}
}

export function* staticVersionExportWorker(action) {
  try {
    const res = yield call(staticVersionExportApi);
    console.log(res);
    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res.data);
    }
  } catch (error) {}
}

export function* staticSubmitWorker(action) {
  try {
    const res = yield call(staticSubmitApi, action.id);
    console.log(res);
    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res.data);
    }
  } catch (error) {}
}

export function* createStaticWorkerWatcher() {
  yield takeLatest(CREATE_STATIC, createStaticWorker);
}
export function* staticApprovalListWatcherWorker() {
  yield takeLatest(STATIC_APPROVAL_TABLE, staticApprovalListWorker);
}

export function* staticApprovalByIdWatcher() {
  yield takeLatest(STATIC_APPROVAL_VIEW, staticApprovalByIdWorker);
}
export function* approveStaticWorkerWatcher() {
  yield takeLatest(APPROVE_STATIC, approveStaticWorker);
}
export function* rejectStaticWorkerWatcher() {
  yield takeLatest(REJECT_STATIC, rejectStaticWorker);
}
export function* viewStaticListWorkerWatcher() {
  yield takeLatest(STATIC_TABLE, viewStaticListWorker);
}
export function* viewStaticDetailWatcherWorker() {
  yield takeLatest(VIEW_STATIC, viewStaticDetailWorker);
}
export function* editStaticWorkerWatcher() {
  yield takeLatest(EDIT_STATIC, editStaticWorker);
}
export function* deleteStaticWorkerWatcher() {
  yield takeLatest(DELETE_STATIC, deleteStaticWorker);
}
export function* versionHistoryStaticListWatcher() {
  yield takeLatest(
    STATIC_VERSION_HISTORY_TABLE,
    versionHistoryStaticListWorker
  );
}
export function* versionHistoryStaticDetailWatcher() {
  yield takeLatest(STATIC_VERSION_HISTORY, versionHistoryStaticDetailWorker);
}
export function* staticOptionsWorkerWatcher() {
  yield takeLatest(STATIC_OPTIONS, staticOptionsWorker);
}

export function* watchStaticMainExportWorker() {
  yield takeLatest(STATIC_MAIN_EXPORT, staticMainExportWorker);
}
export function* watchStaticApprovalsExportWorker() {
  yield takeLatest(STATIC_APPROVAL_EXPORT, staticApprovalsExportWorker);
}
export function* watchStaticVersionExportWorker() {
  yield takeLatest(STATIC_VERSION_EXPORT, staticVersionExportWorker);
}

export function* watchStaticSubmitWorker() {
  yield takeLatest(STATIC_SUBMIT, staticSubmitWorker);
}
