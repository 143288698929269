import React, { useEffect, useState } from "react";
import errorToast from "../../../../components/utilits/errorToast";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import DeleteBtn from "../../../../components/buttons/deleteBtn";
import EditBtn from "../../../../components/buttons/editBtn";
import VersionHistoryBtn from "../../../../components/buttons/versionHistoryBtn";
import InnerContainer from "../../../../components/layouts/innerContainer/innerContainer";
import InnerLayout from "../../../../components/layouts/innerLayout/innerLayout";
import "../notify-me.css";
import { deleteNotifyMe, readNotifyMe } from "../../../../redux/actions/notifyMeActions";
import DeleteModal from "../../../../components/modals/deleteModal";
import successToast from "../../../../components/utilits/successToast";
import { deleteMemberTrade, readMemberTrade } from "../../../../redux/actions/memberTradeAction";
import LoadAndError from "../../../../components/utilits/LoadAndError";
import LoadingSpinnerTable from "../../../../components/utilits/loadingSpinnerTable";
const MemberTradeRead = () => {
    const navigate = useNavigate();
    const handleShow = () => setShow(true);
    const handleClose = () => setShow(false);
    const [show, setShow] = useState(false);
    const params = useParams()
    const dispatch = useDispatch()
    const [notifyMeViewData, SetNotifyMeViewData] = useState({ id: "", name: "", createdAt: "", updatedAt: "" })
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(false);
    const [loadingDelete, setLoadingDelete] = useState(false);

    useEffect(() => {
        setLoading(true)
        dispatch(
            readMemberTrade(params.id, onSuccess, onError)
        );
    }, [params.id])
    const onSuccess = (data) => {
        SetNotifyMeViewData(data.data);
        setLoading(false)
        console.log(data, "jdsgfdsugdsjgfdsjhgdshv")
        setError(false);
    };
    const onError = (data) => {
        errorToast(data.message)
        setLoading(false)
        console.log(data, "jdsgfdsugdsjgfdsjhgdshv")
        setError(true)
    };


    //delete
    const [deleteData, setDeleteData] = useState({ name: "", id: "" });

    const handleDelete = () => {
        setLoadingDelete(true);
        dispatch(
            deleteMemberTrade(
                notifyMeViewData.id,
                onDeleteSuccess,
                onDeleteError,
            )
        );
    };
    const onDeleteSuccess = (data) => {
        successToast(data.message);
        setLoadingDelete(false);
        setShow(false);
        navigate("/opportunity/member-trade")
        setLoading(false)
        setError(false)
    };
    const onDeleteError = (data) => {
        errorToast(data.message);
        setLoadingDelete(false);
        setLoading(false)
        setError(true)
    };
    console.log(notifyMeViewData);

    const deletenotifyMebyid = () => {
        setDeleteData({     
            name: notifyMeViewData?.user?.user_name,
            id: notifyMeViewData.opportunity_id,
        });
        handleShow();
    }
    const buttonList = [
        <VersionHistoryBtn link={`/opportunity/member-trade/version-history`} />,
        <DeleteBtn showModal={deletenotifyMebyid} />
    ];
    const data = [
        {
            heading: "Member Trade ID",
            title: notifyMeViewData.id,
        },
        {
            heading: "Opportunity ID",
            title: notifyMeViewData?.opportunity_id,
        },
        {
            heading: "Opportunity Name",
            title: notifyMeViewData?.opportunity?.title
            ,
        },
        {
            heading: "User ID",
            title: notifyMeViewData?.user_id,
        },
        {
            heading: "User Name",
            title: notifyMeViewData?.user?.user_name,
        },

        {
            heading: "Created At",
            title: notifyMeViewData?.createdAt,
        },

        {
            heading: "Updated At",
            title: notifyMeViewData?.updatedAt,
        },
    ];
    return (
        <InnerLayout buttons={buttonList}>
            <div className="notify-me-container">
                <InnerContainer>
                    {loading ? <LoadingSpinnerTable/>
                    :(
          <LoadAndError loader={loading} error={error}>
                    <div className="pt-3  ps-4 ps-lg-4 ms-3">
                        {data.map((item, index) => (
                            <div className="mb-2">
                                <h5 className="dark_grey_color fs_13 fn_Montserrat fw_500 m-0">
                                    {item.heading}
                                </h5>
                                <p
                                    className={` fs_13 fn_Nunito fw_500  ${item.heading === "Opportunity ID" || item.heading === "User ID" ? 'secondary_color' : 'dark_title_color'} `}>
                                    {item.title}
                                </p>
                            </div>
                        ))}
                    </div>
                    </LoadAndError>
                    )}
                </InnerContainer>
            </div>
            <DeleteModal
                loader={loadingDelete}
                handleClose={handleClose}
                deleteFn={handleDelete}
                data={deleteData}
                show={show}
            />
        </InnerLayout>
    );
};

export default MemberTradeRead;

