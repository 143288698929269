import { call, takeLatest } from "redux-saga/effects";
import { PARTNERS_APPROVALS_EXPORT, PARTNERS_APPROVALS_LIST, PARTNERS_APPROVALS_VIEW, PARTNERS_APPROVE, PARTNERS_CREATE, PARTNERS_DELETE, PARTNERS_EDIT, PARTNERS_EXPORT, PARTNERS_LIST, PARTNERS_READ, PARTNERS_REJECT, PARTNERS_SUBMIT, PARTNERS_VERSION_EXPORT, PARTNERS_VERSION_LIST, PARTNERS_VERSION_VIEW } from "../actions/types";
import * as partnersAPis from "../apis/ourPartnersApis";

export function* addPartnerWorker(action) {
    try {
        const res = yield call(partnersAPis.addPartnerApi, action.data);
        console.log(action.data);
        // console.log(res, "AddAdminRole");
        if (res.status === 200 && res.data.status === "success") {
            yield action.onSuccess(res.data);
            console.log(res.data);
        } else if (res.status === 200 && res.data.status === "fail") {
            console.log(res.data);
            yield action.onError(res);
        } else {
            yield action.onError("Something Went Wrong");
        }
    } catch (err) {
        console.log(err);
        yield action.onError("Something Went Wrong");
    }

}

export function* viewPartnerWorker(action) {
    try {
        const res = yield call(partnersAPis.viewPartnerApi,
            action.id,
        );
        // console.log(res, "AddAdminRole");
        if (res.status === 200 && res.data.status === "success") {
            yield action.onSuccess(res.data);
            console.log(res.data);
        } else if (res.status === 200 && res.data.status === "fail") {
            console.log(res.data);
            yield action.onError(res);
        } else {
            yield action.onError("Something Went Wrong!");
            console.log(res.data);
        }
    } catch (err) {
        console.log(err);
        yield action.onError("Something Went Wrong!!");
    }
}
export function* editPartnerWorker(action) {

    try {
        const res = yield call(partnersAPis.editPartnerApi,
            action.id,
            action.data
        );
        console.log(res, "AddAdminRole");
        if (res.status === 200 && res.data.status === "success") {
            yield action.onSuccess(res.data);
            console.log(res.data);
        } else if (res.status === 200 && res.data.status === "fail") {
            console.log(res);
            yield action.onError(res);
        } else {
            yield action.onError("Something Went Wrong");
        }
    } catch (err) {
        console.log(err);
        yield action.onError("Something Went Wrong");
    }

}
export function* deletePartnerWorker(action) {

    try {
        const res = yield call(partnersAPis.deletePartnerApi, action.id,
        );
        // console.log(res, "AddAdminRole");
        if (res.status === 200 && res.data.status === "success") {
            yield action.onSuccess(res.data);
            console.log(res.data);
        } else if (res.status === 200 && res.data.status === "fail") {
            console.log(res);
            yield action.onError(res);
        } else {
            yield action.onError("Something Went Wrong");
        }
    } catch (err) {
        console.log(err);
        yield action.onError("Something Went Wrong");
    }

}
export function* viewPartnerListWorker(action) {
    try {
        const res = yield call(partnersAPis.viewParnterListApi,
            action.pageNo,
            action.data,
        );
        // console.log(res, "AddAdminRole");
        if (res.status === 200 && res.data.status === "success") {
            yield action.onSuccess(res.data);
            console.log(res);
        } else if (res.status === 200 && res.data.status === "fail") {
            console.log(res);
            yield action.onError(res.data);
        } else {
            yield action.onError("Something Went Wrong");
        }
    } catch (err) {
        console.log(err);
        yield action.onError("Something Went Wrong");
    }

}


export function* approvePartnerWorker(action) {
    try {
        const res = yield call(partnersAPis.approvePartnerApi,
            action.id
        );
        // console.log(res, "AddAdminRole");
        if (res.status === 200 && res.data.status === "success") {
            yield action.onSuccess(res.data);
            console.log(res.data);
        } else if (res.status === 200 && res.data.status === "fail") {
            console.log(res);
            yield action.onError(res.data);
        } else {
            yield action.onError("Something Went Wrong!");
            console.log(res.data);
        }
    } catch (err) {
        console.log(err);
        yield action.onError("Something Went Wrong!!");
    }
}

export function* rejectPartnerWorker(action) {
    try {
        const res = yield call(partnersAPis.rejectPartnerApi,
            action.id
        );
        // console.log(res, "AddAdminRole");
        if (res.status === 200 && res.data.status === "success") {
            yield action.onSuccess(res.data);
            console.log(res.data);
        } else if (res.status === 200 && res.data.status === "fail") {
            console.log(res);
            yield action.onError(res.data);
        } else {
            yield action.onError("Something Went Wrong!");
            console.log(res.data);
        }
    } catch (err) {
        console.log(err);
        yield action.onError("Something Went Wrong!!");
    }
}
export function* approvalsPartnersListWorker(action) {
    try {
        const res = yield call(partnersAPis.approvalsPartnerListpApi,
            action.pageNo,
            action.data,
        );
        // console.log(res, "AddAdminRole");
        if (res.status === 200 && res.data.status === "success") {
            yield action.onSuccess(res.data);
            console.log(res.data);
        } else if (res.status === 200 && res.data.status === "fail") {
            console.log(res);
            yield action.onError(res);
        } else {
            yield action.onError("Something Went Wrong!");
            console.log(res.data);
        }
    } catch (err) {
        console.log(err);
        yield action.onError("Something Went Wrong!!");
    }
}
export function* viewApprovalsPartnerWorker(action) {
    try {
        const res = yield call(partnersAPis.viewApprovalPartnerApi, action.id
        );
        console.log(res, "Contact approvals");
        if (res.status === 200 && res.data.status === "success") {
            yield action.onSuccess(res.data);
            console.log(res.data);
        } else if (res.status === 200 && res.data.status === "fail") {
            console.log(res);
            yield action.onError(res);
        } else {
            yield action.onError("Something Went Wrong!");
            console.log(res.data);
        }
    } catch (err) {
        console.log(err);
        yield action.onError("Something Went Wrong!!");
    }
}
export function* versionHistoryListParnterWorker(action) {
    try {
        const res = yield call(partnersAPis.versionPartnerListpApi,
            action.pageNo,
            action.data,
        );
        // console.log(res, "AddAdminRole");
        if (res.status === 200 && res.data.status === "success") {
            yield action.onSuccess(res.data);
            console.log(res.data);
        } else if (res.status === 200 && res.data.status === "fail") {
            console.log(res);
            yield action.onError(res);
        } else {
            yield action.onError("Something Went Wrong!");
            console.log(res.data);
        }
    } catch (err) {
        console.log(err);
        yield action.onError("Something Went Wrong!!");
    }
}
export function* viewVersionHistoryPartnerWorker(action) {
    try {
        const res = yield call(partnersAPis.viewVersionPartnerApi, action.id,
        );
        if (res.status === 200 && res.data.status === "success") {
            yield action.onSuccess(res);
            console.log(res);
        } else if (res.status === 200 && res.data.status === "fail") {
            console.log(res);
            yield action.onError(res);
        } else {
            yield action.onError("Something Went Wrong!");
            console.log(res);
        }
    } catch (err) {
        console.log(err);
        yield action.onError("Something Went Wrong!!");
    }
}


/**********************************/
/************WATHCHERS**************/
/**********************************/


export function* partnersTableExportWorker(action) {
    try {
        const res = yield call(partnersAPis.partnersTableExportApi,

        );
        if (res.status === 200 && res.data.status === "success") {
            yield action.onSuccess(res.data);
        } else {
            yield action.onError(res.data);
        }
    } catch (error) { }
}


export function* partnersApprovalsExportWorker(action) {
    try {
        const res = yield call(partnersAPis.partnersApprovalsExportApi);
        console.log(res);
        if (res.status === 200 && res.data.status === "success") {
            console.log(res.data);
            yield action.onSuccess(res.data.data);
        } else {
            yield action.onError(res.data);
        }
    } catch (error) { }
}

export function* partnersVersionExportWorker(action) {
    try {
        const res = yield call(partnersAPis.partnersVersionExportApi);
        console.log(res);
        if (res.status === 200 && res.data.status === "success") {
            yield action.onSuccess(res.data);
        } else {
            yield action.onError(res.data);
        }
    } catch (error) { }
}



export function* partnersSubmitWorker(action) {
    try {
        const res = yield call(partnersAPis.partnersSubmitApi, action.id);
        console.log(res);
        if (res.status === 200 && res.data.status === "success") {
            yield action.onSuccess(res.data);
        } else {
            yield action.onError(res.data);
        }
    } catch (error) { }
}



export function* watchaddPartnerWorker() {
    yield takeLatest(PARTNERS_CREATE, addPartnerWorker);
}
export function* watchViewParnterWorker() {
    yield takeLatest(PARTNERS_READ, viewPartnerWorker);
}
export function* watchViewPartnerListWorker() {
    yield takeLatest(PARTNERS_LIST, viewPartnerListWorker);
}
export function* watcheditPartnerWorker() {
    yield takeLatest(PARTNERS_EDIT, editPartnerWorker);
}
export function* watchApprovePartnerWorker() {
    yield takeLatest(PARTNERS_APPROVE, approvePartnerWorker);
}
export function* watchRejectPartnerWorker() {
    yield takeLatest(PARTNERS_REJECT, rejectPartnerWorker);
}
export function* watchApprovalsPartnerListWorker() {
    yield takeLatest(PARTNERS_APPROVALS_LIST, approvalsPartnersListWorker);
}
export function* watchViewApprovalPartnerWorker() {
    yield takeLatest(PARTNERS_APPROVALS_VIEW, viewApprovalsPartnerWorker);
}
export function* watchDeletePartnerWorker() {
    yield takeLatest(PARTNERS_DELETE, deletePartnerWorker);
}
export function* watchVersionHistoryListPartnerWorker() {
    yield takeLatest(PARTNERS_VERSION_LIST, versionHistoryListParnterWorker);
}
export function* watchViewVersionHistoryPartnerWorker() {
    yield takeLatest(PARTNERS_VERSION_VIEW, viewVersionHistoryPartnerWorker);
}

/****exports*****/

export function* partnersTableExportWatcher() {
    yield takeLatest(
        PARTNERS_EXPORT,
        partnersTableExportWorker
    );
}
export function* partnersApprovalsExportWatcher() {
    yield takeLatest(PARTNERS_APPROVALS_EXPORT, partnersApprovalsExportWorker);
}

export function* partnersVersionExportWorkerWatcher() {
    yield takeLatest(PARTNERS_VERSION_EXPORT, partnersVersionExportWorker);
}


export function* partnersSubmitWorkerWatcher() {
    yield takeLatest(PARTNERS_SUBMIT, partnersSubmitWorker);
}