import React from 'react'
import { Spinner } from 'react-bootstrap'
import SaveIcon from '../../assets/icons/saveIcon'
import { AdminButton } from './versionHistoryBtn'

const SaveBtn = ({ loader = false, submitFn }) => {
    return (
        <AdminButton disabled={loader} type='submit' onClick={() => submitFn()} padding="5px 15px" color="#112F6A">
            {loader ?
                <Spinner as="span" size='sm' animation="border" className='mx-3' variant="secondary" role="status">
                    <span className="visually-hidden">Loading...</span>
                </Spinner>
                :
                <><SaveIcon width={13} height={13} />
                    <span className="ms-1 pdt_1">SAVE</span></>
            }
        </AdminButton >
    )
}

export default SaveBtn