
import React from "react";

const ArrowDownIcon = ({ fill = "rgba(165, 165, 165, 1)", width = 10, height = 16 }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={width} height={height}><path fill="none" d="M0 0h24v24H0z"/><path d="M12 13.172l4.95-4.95 1.414 1.414L12 16 5.636 9.636 7.05 8.222z" fill={fill}/></svg>


  );
};

export default ArrowDownIcon;