import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import MainLayout from "../components/layouts/mainLayout/mainLayout";
import { ADMIN_DETAILS } from "../redux/config";
const PrivateRoute = () => {
  return localStorage.getItem("accessToken") ? (
    <>
      <MainLayout>
        <Outlet />
      </MainLayout>
    </>
  ) : (
    <Navigate to="/login" />
  );
  
};

export default PrivateRoute;
