import React, { useRef, useState } from 'react';
import "../admin-roles.css";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import InputField from '../../../../components/form/inputFields/inputField';
import SaveBtn from '../../../../components/buttons/saveBtn';
import InnerLayout from '../../../../components/layouts/innerLayout/innerLayout';
import DeleteBtn from '../../../../components/buttons/deleteBtn';
import InnerContainer from '../../../../components/layouts/innerContainer/innerContainer';
import AdminRoleCheckBox from '../../../../components/utilits/adminRoleCheckbox';
import * as adminRolesActions from "../../../../redux/actions/adminRoleActions";
import { useDispatch } from 'react-redux';
import successToast from '../../../../components/utilits/successToast';
import errorToast from '../../../../components/utilits/errorToast';
import ApprovalsBtn from '../../../../components/buttons/approvalsBtn';
import { useNavigate } from 'react-router-dom';
import SubmitBtn from '../../../../components/buttons/submit';
const AdminRoleCreate = () => {
  const [loading, setLoading] = useState(false)
  const formRef = useRef();
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const [saved, setSaved] = useState(false);
  const [subLoading, setSubLoading] = useState(false);
  const [adminId, setAdminId] = useState("");
  const initialValues = {
    roleName: "",
    permissions: {
      "admin": {
        "checker": false,
        "maker": false,
        "read_only": false
      },
      "user": {
        "checker": false,
        "maker": false,
        "read_only": false
      },
      "opportunity": {
        "checker": false,
        "maker": false,
        "read_only": false
      },

      "role": {
        "checker": false,
        "maker": false,
        "read_only": false
      },
      "static_homepage": {
        "checker": false,
        "maker": false,
        "read_only": false
      }
    }
  };
  const loginSchema = Yup.object().shape({
    roleName: Yup.string().trim().required("Required"),
    // static_homepage: Yup.boolean().required("Required"),
  });
  const handleSubmit = () => {
    if (formRef.current) {
      formRef.current.handleSubmit();
    }
  };
  const onSubmit = (values, { resetForm }) => {
    setLoading(true)
    dispatch(
      adminRolesActions.addAdminRole({ "rolename": values.roleName, "permissions": values.permissions }, onSuccess, onError)
    );
  };
  const onSuccess = (data) => {
    console.log(data);
    setLoading(false)
    successToast(data.message)
    setAdminId(data.data.Role_Id.id)
    navigate(`/admin-roles/edit/${data.data.Role_Id.id}`);
    setSaved(true)

  };
  const onError = (data) => {
    setLoading(false)
    errorToast(data.data.data + " " + data.data.message)
  };
  const adminRoleSubmit = () => {
    setSubLoading(true);
    dispatch(
      adminRolesActions.adminRoleSubmitAction(
        adminId,
        onAdminSubmitSucess,
        onAdminSubmitError
      )
    );
  };
  const buttonList = [
    <ApprovalsBtn link={`/admin-roles/approvals`} />,
    <SaveBtn loader={loading} submitFn={handleSubmit} />,
  ];
  function onAdminSubmitSucess(data) {
    successToast(data.message);
    setSubLoading(false);

  }
  function onAdminSubmitError(data) {
    setSubLoading(false);
    errorToast(data.message);
  }
  return (
    <>
      <InnerLayout buttons={buttonList}>
        <div className="admin_role_container">
          <InnerContainer>
            <div className="pt-3 mb-5 ps-2 ps-md-2 ps-lg-4 ">
              <h5 className="dark_grey_color fs_13 fn_Montserrat fw_500 m-0">
                Role ID
              </h5>
              <p className={` fs_13 fn_Nunito fw_500  secondary_color `}>
                0123asdf456jkl7w
              </p>
              <Formik
                innerRef={formRef}
                initialValues={initialValues}
                onSubmit={onSubmit}
                validationSchema={loginSchema}
              >
                {({ values }) =>


                  <Form >
                    <div className="admin-role-form-width">
                      <InputField
                        id={"roleName"}
                        label={"Role Name"}
                        type={"text"}
                        placeholder={"Enter Role Name"}
                        name={"roleName"}
                      />
                    </div>
                    <div>
                      <h5 className="dark_grey_color fs_12 fn_Montserrat fw_500 m-0">
                        Permissions
                      </h5>
                      <div className="admin_role_view_permissions_width mt-3 mb-3">
                        <div className="row ms-0 ms-lg-3 ">
                          <div className="col-3">
                            <h5 className="dark_grey_color text-nowrap fs_12 fn_Montserrat fw_500 ">
                              Modules
                            </h5>
                          </div>
                          <div className="col-3">
                            <h5 className="dark_grey_color d-flex justify-content-center fs_12 text-nowrap fn_Montserrat fw_500 ">
                              Checker
                            </h5>
                          </div>
                          <div className="col-3">
                            <h5 className="dark_grey_color fs_12 d-flex justify-content-center  fn_Montserrat text-nowrap fw_500 ">
                              Maker
                            </h5>
                          </div>
                          <div className="col-3">
                            <h5 className="dark_grey_color text-nowrap d-flex justify-content-center fs_12 fn_Montserrat fw_500 ">
                              Read Only
                            </h5>
                          </div>
                        </div>
                        <div className="row ms-0 ms-lg-3 mt-2">
                          <div className="col-3">
                            <h5 className="dark_grey_color text-nowrap fs_12 fn_Montserrat fw_500 ">
                              Admin
                            </h5>
                          </div>
                          <div className="col-3">
                            <div className="d-flex justify-content-center">
                              <AdminRoleCheckBox name="permissions.admin.checker" />
                            </div>
                          </div>
                          <div className="col-3">
                            <div className="d-flex justify-content-center">
                              <AdminRoleCheckBox name="permissions.admin.maker" />
                            </div>
                          </div>
                          <div className="col-3">
                            <div className="d-flex justify-content-center">
                              <AdminRoleCheckBox name="permissions.admin.read_only" />
                            </div>
                          </div>
                        </div>
                        <div className="row ms-0 ms-lg-3 mt-2">
                          <div className="col-3">
                            <h5 className="dark_grey_color text-nowrap fs_12 fn_Montserrat fw_500 ">
                              User
                            </h5>
                          </div>
                          <div className="col-3">
                            <div className="d-flex justify-content-center">
                              <AdminRoleCheckBox name="permissions.user.checker" />
                            </div>
                          </div>
                          <div className="col-3">
                            <div className="d-flex justify-content-center">
                              <AdminRoleCheckBox name="permissions.user.maker" />
                            </div>
                          </div>
                          <div className="col-3">
                            <div className="d-flex justify-content-center">
                              <AdminRoleCheckBox name="permissions.user.read_only" />
                            </div>
                          </div>
                        </div>
                        <div className="row ms-0 ms-lg-3 mt-2">
                          <div className="col-3">
                            <h5 className="dark_grey_color text-nowrap fs_12 fn_Montserrat fw_500 ">
                              Opportunity
                            </h5>
                          </div>
                          <div className="col-3">
                            <div className="d-flex justify-content-center">
                              <AdminRoleCheckBox name="permissions.opportunity.checker" />
                            </div>
                          </div>
                          <div className="col-3">
                            <div className="d-flex justify-content-center">
                              <AdminRoleCheckBox name="permissions.opportunity.maker" />
                            </div>
                          </div>
                          <div className="col-3">
                            <div className="d-flex justify-content-center">
                              <AdminRoleCheckBox name="permissions.opportunity.read_only" />
                            </div>
                          </div>
                        </div>
                        <div className="row ms-0 ms-lg-3 mt-2">
                          <div className="col-3">
                            <h5 className="dark_grey_color text-nowrap fs_12 fn_Montserrat fw_500 ">
                              Role
                            </h5>
                          </div>
                          <div className="col-3">
                            <div className="d-flex justify-content-center">
                              <AdminRoleCheckBox name="permissions.role.checker" />
                            </div>
                          </div>
                          <div className="col-3">
                            <div className="d-flex justify-content-center">
                              <AdminRoleCheckBox name="permissions.role.maker" />
                            </div>
                          </div>
                          <div className="col-3">
                            <div className="d-flex justify-content-center">
                              <AdminRoleCheckBox name="permissions.role.read_only" />
                            </div>
                          </div>
                        </div>
                        <div className="row ms-0 ms-lg-3 mt-2">
                          <div className="col-3">
                            <h5 className="dark_grey_color text-nowrap fs_12 fn_Montserrat fw_500 ">
                              Static Pages
                            </h5>
                          </div>
                          <div className="col-3">
                            <div className="d-flex justify-content-center">
                              <AdminRoleCheckBox name="permissions.static_homepage.checker" />
                            </div>
                          </div>
                          <div className="col-3">
                            <div className="d-flex justify-content-center">
                              <AdminRoleCheckBox name="permissions.static_homepage.maker" />
                            </div>
                          </div>
                          <div className="col-3">
                            <div className="d-flex justify-content-center">
                              <AdminRoleCheckBox name="permissions.static_homepage.read_only" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Form>
                }
              </Formik>
            </div>
          </InnerContainer>
        </div>
      </InnerLayout>
    </>
  );
};

export default AdminRoleCreate;


