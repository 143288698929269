function createData(id, adminid, name, email, role, lastlogin, lastaction) {
  return { id, adminid, name, email, role, lastlogin, lastaction };
}

export const tableAdminUserRow = [
  createData(
    "01",
    "0123asdfasasfasgfsafasfasf456jkl73",
    "Lorem ipsum dolor",
    "email_id@company.com",
    "Lorem Ipsum Dolor sem",
    "13:00:00",
    "Lorem ipsum dolor"
  ),
  createData(
    "02",
    "0123asdf456jkl74",
    "Lorem ipsum dolor",
    "email_id@company.com",
    "Lorem Ipsum Dolor sem",
    "13:00:00",
    "Lorem ipsum dolor"
  ),
  createData(
    "03",
    "0123asdf456jkl75",
    "Lorem ipsum dolor",
    "email_id@company.com",
    "Lorem Ipsum Dolor sem",
    "13:00:00",
    "Lorem ipsum dolor"
  ),
  createData(
    "04",
    "0123asdf456jkl76",
    "Lorem ipsum dolor",
    "email_id@company.com",
    "Lorem Ipsum Dolor sem",
    "13:00:00",
    "Lorem ipsum dolor"
  ),
  createData(
    "05",
    "0123asdf456jkl77",
    "Lorem ipsum dolor",
    "email_id@company.com",
    "Lorem Ipsum Dolor sem",
    "13:00:00",
    "Lorem ipsum dolor"
  ),
  createData(
    "06",
    "0123asdf456jkl78",
    "Lorem ipsum dolor",
    "email_id@company.com",
    "Lorem Ipsum Dolor sem",
    "13:00:00",
    "Lorem ipsum dolor"
  ),
  createData(
    "07",
    "0123asdf456jkl89",
    "Lorem ipsum dolor",
    "email_id@company.com",
    "Lorem Ipsum Dolor sem",
    "13:00:00",
    "Lorem ipsum dolor"
  ),
  createData(
    "08",
    "0123asdf456jkl45",
    "Lorem ipsum dolor",
    "email_id@company.com",
    "Lorem Ipsum Dolor sem",
    "13:00:00",
    "Lorem ipsum dolor"
  ),
  createData(
    "09",
    "0123asdf456jkl23",
    "Lorem ipsum dolor",
    "email_id@company.com",
    "Lorem Ipsum Dolor sem",
    "13:00:00",
    "Lorem ipsum dolor"
  ),
  createData(
    "10",
    "0123asdf456jkl754",
    "Lorem ipsum dolor",
    "email_id@company.com",
    "Lorem Ipsum Dolor sem",
    "13:00:00",
    "Lorem ipsum dolor"
  ),
  createData(
    "11",
    "0123asdf456jkl657",
    "Lorem ipsum dolor",
    "email_id@company.com",
    "Lorem Ipsum Dolor sem",
    "13:00:00",
    "Lorem ipsum dolor"
  ),
  createData(
    "12",
    "0123asdf456jkl345",
    "Lorem ipsum dolor",
    "email_id@company.com",
    "Lorem Ipsum Dolor sem",
    "13:00:00",
    "Lorem ipsum dolor"
  ),
];

export const tableAdminUserHeader = [
  "#",
  "Admin id",
  "Name",
  "Email",
  "Role",
  "Last Login",
  "Last Action",
  "Status",
];
export const tableAdminVersionHeader = [
  "#",
  "Version ID",
  "Role ID",
  "Role Name",
  "Updated By",
  "Updated Date",
  "Approved By",
  "Approved Date",
  "",
  "Status",
];
export const tableAdminUsersVersionHeader = [
  "#",
  "Version ID",
  "Admin ID",
  " Name",
  "Updated By",
  "Updated Date",
  "Approved By",
  "Approved Date",
  "",
  "Status",
];

export const tableAdminApprovalHeader = [
  "#",
  "Approvals ID",
  "Admin ID",
  "Name",
  "E-Mail",
  "Role",
  "Last Login",
  "Last Action",
  "Updated By",
  "Updated Date",
  "Status",
];

export const tableAdminUserListHeader = [
  "#",
  "User ID",
  "Name",
  "Email",
  "Phone Number",
  "KYC",
  "Registration Date",
  "Last Login",
  "Status",
];

export const tableAdminRolesHeader = ["#", "Role ID", "Name", "", "Status"];

export const AdminRolesListRow = [
  {
    id: "01",
    roleId: "0123asdf456jkl7w",
    name: "Lorem ipsum Dolor",
  },
];

export const tableAdminRolesApprovalHeader = [
  "#",
  "Approvals ID",
  "Role ID",
  "Name",
  "Updated By",
  "Updated Date",
  "",
  "Status",
];

export const tableUsersApprovalHeader = [
  "#",
  "Approvals ID",
  "User ID",
  "Name",
  "Email",
  "Phone Number",
  "KYC",
  "Registration Date",
  "Last Login",
  "Status",
];
export const tableUsersVersionHeader = [
  "#",
  "Version ID",
  "User ID",
  "User Name",
  "Updated By",
  "Updated Date",
  "Approved By",
  "Approved Date",
  "",
  "Status",
];

export const tableUsersResetEmailHeader = [
  "#",
  "Id",
  "User ID",
  "E-Mail",
  "Token",
  "Valid Till",
  "Is Used",
  "Created At",
  "Updated At",
];

export const tableUsersEmailHeader = [
  "#",
  "Email ID",
  "User ID",
  "E-Mail",
  "Valid Till",
  // "Valid",
  "is Used",
  "Created At",
  "Updated At",
];

export const tableUsersPhoneHeader = [
  "#",
  "Phone Otp ID",
  "User ID",
  "Phone Number",
  "Country Code",
  "Valid Till",
  // "Valid",
  "is Used",
  "Created At",
  "Updated At",
];

//////////////////////////***************OPPORTUNITY********************///////////////////
export const tableOpportuniyTypeHeader = [
  "#",
  "Opportunity Type ID",
  "Opportunity Type Name",
  "Created At",
  "Updated At",
  "",
  "Status",
];

export const tableOpportuniyTypeApprovalsHeader = [
  "#",
  "Approvals ID",
  "Opprotunity Type Id",
  "Opportunity Type Name",
  "Updated By",
  "Updated Date",
  "",
  "Status",
];

export const tableOpportuniyTypeVersionHeader = [
  "#",
  "Version ID",
  "Opportunity ID",
  "Opportunity Type Name",
  "Updated By",
  "Updated Date",
  "Approved By",
  "Approved Date",
  "Status",
];

export const tableWishlistHeader = [
  "#",
  "Wishlist ID",
  "Opportunity ID",
  "User ID",
  "User Name",
  "Created At",
  "Updated At",
  "",
  "Status",
];
export const tableMemTradeSellHeader = [
  "#",
  "Member Trade ID",
  "Opportunity ID",
  "User Name",
  "Created At",
  "Updated At",
  "Status",
];
export const tableMemTradeSellApprovalHeader = [
  "#",
  "Approvals ID",
  "Sell Out ID",
  "Opportunity ID",
  "User Name",
  "Created At",
  "Updated At",
  "Status",
];

export const tableWishlistApprovalsHeader = [
  "#",
  "Approvals ID",
  "Wishlist ID",
  "Opportunity ID",
  "User Name",
  "Updated By",
  "Updated Date",
  "",
  "Status",
];

export const tableWishlistVersionHeader = [
  "#",
  "Version ID",
  "Wishlist ID",
  "Opportunity Name",
  "User Name",
  "Updated By",
  "Updated Date",
  "Approved By",
  "Approved Date",
  "Status",
];
export const tableMemTradeSellVersionHeader = [
  "#",
  "Version ID",
  "Sell Out",
  "Opportunity Name",
  "User Name",
  "Updated By",
  "Updated Date",
  "Approved By",
  "Approved Date",
  "Status",
];

export const tableNotifyMeHeader = [
  "#",
  "Notify Me ID",
  "Opportunity ID",
  "User ID",
  "User Name",
  "Created At",
  "Updated At",
  "Status",
];
export const tableNotificationHeader = [
  "#",
  "Notification ID",
  "User Id",
  "Title",
  "Sub Title",
  "Description",
  "Kyc status",
  "Is Important",
  "Created At",
  "Updated At",
  "Status",
];
export const tableSocialHeader = [
  "#",
  "Social Contribution ID",
  "User Name",
  "Link Url",
  "Created At",
  "Updated At",
  "Status",
];
export const tableMemberTradeHeader = [
  "#",
  "Member Trade ID",
  "Opportunity ID",
  "User ID",
  "User Name",
  "Created At",
  "Updated At",
  "Status",
];

export const tableNotifyMeApprovalsHeader = [
  "#",
  "Approvals ID",
  "Notifi Me ID",
  "Opportunity ID",
  "User Name",
  "Updated By",
  "Updated Date",
  "Status",
];
export const tableNotificationApprovalsHeader = [
  "#",
  "Approvals ID",
  "Notification ID",
  // "User ID",
  "Title",
  "Updated By",
  "Updated Date",
  "Status",
];
export const tableSocialApprovalsHeader = [
  "#",
  "Approvals ID",
  "Social ID",
  "Is Testimonial",
  "Link Url",
  "Updated By",
  "Updated Date",
  "Status",
];
export const tableMemberTradeApprovalsHeader = [
  "#",
  "Approvals ID",
  "Member Trade ID",
  "Opportunity ID",
  "User Name",
  "Updated By",
  "Updated Date",
  "Status",
];

export const tableNotifyMeVersionHeader = [
  "#",
  "Version Id",
  "Notify Me ID",
  "Opportunity Name",
  "User Name",
  "Updated By",
  "Updated Date",
  "Approved By",
  "Approved Date",
  "Status",
];
export const tableNotificationVersionHeader = [
  "#",
  "Version ID",
  "Notification ID",
  "User Id",
  "Title",
  "Updated By",
  "Updated Date",
  "Approved By",
  "Approved Date",
  "Status",
];
export const tableSocialVersionHeader = [
  "#",
  "Version Id",
  "Social Contribution ID",
  "Link Url",
  "Is Testimonial",
  "Updated By",
  "Updated Date",
  "Approved By",
  "Approved Date",
  "Status",
];
export const tableMemberTradeVersionHeader = [
  "#",
  "Version ID",
  "Member Trade Id",
  "Opportunity Name",
  "User Name",
  "Updated By",
  "Updated Date",
  "Approved By",
  "Approved Date",
  "Status",
];

export const tableMainOpportunitiesHeader = [
  "#",
  "Opportunity ID",
  "Opportunity Type Name",
  "Opportunity Name",
  "status",
  "Total Funding Amount",
  "Total Funded Amount",
  "Created At",
  "Updated At",
  "Available From",
  "Available To",
  "Status",
];
export const tableOpportunitiesHeader = [
  "#",
  "Approvals ID",
  "Opportunity ID",
  "Opportunity Type Name",
  "Opportunity Name",
  "status",
  "Total Funding Amount",
  "Total Funded Amount",
  "Created At",
  "Updated At",
  "Available From",
  "Available To",
  "Status",
];
export const tableOpportunitiesVersionHeader = [
  "#",
  "Version ID",
  "Opportunity Id",
  "Opportunity Type ID",
  "Opportunity Name",
  "Opportunity Type Name",
  "Status",
  "Total Funding Amount",
  "Total Funded Amount",
  "Available From",
  "Available To",
  "Created At",
  "Updated At",
  "Updated By",
  "Approved By",
  "Approved Date",
  "Status.",
];
export const tableOpportunitiesRow = [
  {
    id: "#",
    opportunityId: "0123asdf456jkl7w",
    opportunityTypeName: "Lorem ipsum Dolor",
    opportunityName:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
    status: "Active/Closed",
    totalFundingAmount: "Rs. 99999.999 Cr",
    totalFundedAmount: "Rs. 99999.999 Cr",
    createdDate: "06/09/2022 13:00:00",
    updatedDate: "06/09/2022 13:00:00",
    availableFrom: "06/09/2022 13:00:00",
    availableTo: "06/09/2022 13:00:00",
  },
];

/****OPPORTUNITIES CREATE AND EDIT TABLE****/

export const tableOpportunitiesTagsHeader = [
  "Priority",
  "Name",
  "Colors",
  "Actions",
];
export const tableOpportunitiesAssetsHeader = [
  "Priority",
  "Location",
  "Type",
  "Actions",
];
export const tableOpportunitiesOverviewHeader = [
  "Icon",
  "Title",
  "Subtitle",
  "Info box",
  "Actions",
];
export const tableOpportunitiesPayoutsHeader = [
  "SlNo",
  "Date",
  "Amount",
  "Paid?",
  "Paid on date",
  "Amount Paid",
  "Actions",
];
export const tableOpportunitiesOdometerHeader = [
  "Name",
  "Subtitle",
  "Angle",
  "Actions",
];
export const tableOpportunitiesInsightsHeader = ["Name", "Actions"];
export const tableOpportunitiesFaqHeader = ["Questions", "Answer", "Actions"];

export const tableOpportunitiesTagsdata = [
  {
    id: "01",
    name: "Invoice Discounting",
    colors: "Iman Khan",
  },
];
export const tableOpportunitiesAssetsdata = [
  {
    id: "01",
    name: "LocationFolderFile",
    type: "video",
  },
];
export const tableOpportunitiesOverviewdata = [
  {
    name: "LocationFolderFile",
    type: "video",
  },
];
export const tableOpportunitiesPayoutsdata = [
  {
    id: "01",
    date: "29/01/2000",
    amount: "2000",
    paid: "Yes",
    paidOnDate: "29/01/2000",
    amountPaid: "2000",
  },
];
export const tableOpportunitiesOdometerdata = [
  {
    name: "Iman",
    subtitle: "LocationFolderFile",
    angle: "high",
  },
];
export const tableOpportunitiesInsightsdata = [
  {
    name: "Iman",
  },
];
export const tableOpportunitiesFaqdata = [
  {
    question: "Iman",
  },
];

export const ourteamHeader = [
  "#",
  "Team Member ID",
  "Name",
  "Designation",
  "LinkedIn URL",
  "Description",
  "Prirority",
  "Approved At",
  "Updated At",
  "Status",
];

export const ourteamApprovalHeader = [
  "#",
  "Approvals ID",
  "Our Team ID",
  "Name",
  "Designation",
  "Prirority",
  "Approved At",
  "Updated At",
  "status",
];

export const ourteamversionHeader = [
  "#",
  "Version ID",
  "Member ID",
  "Name",
  "Designation",
  "Updated By",
  "Updated Date",
  "Approved By",
  "Approved Date",
  "Status",
];

export const testimonialsHeader = [
  "#",
  "Testimonials ID",
  "Name",
  "Designation",
  "User Type",
  "Description",
  "Approved At",
  "Updated At",
  "Status",
];

export const testimonialsapprovalHeader = [
  "#",
  "Approvals ID",
  "Testimonials ID",
  "Name",
  "Designation",
  "User Type",
  "Approved At",
  "Updated At",
  "Status",
];

/*******EXPRESS INTEREST *******************/

export const tableExpressInterestTableHeader = [
  "#",
  "Express Interest ID",
  "Opportunity ID",
  "Opportunity Name",
  "User ID",
  "User Name",
  "Amount",
  "Transaction Status",
  "Status",
];

export const tableExpressInterestVersionHistoryTableHeader = [
  "#",
  "Version ID",
  "Express Interest ID",
  "Opportunity Name",
  "Updated By",
  "Updated Date",
  "Approved By",
  "Approved Date",
  "Status",
];

export const tableExpressInterestApprovalHeader = [
  "#",
  "Approvals ID",
  "Express Interest ID",
  "Opportunity ID",
  "Updated By",
  "Updated Date",

  "",
  "",
  "",
  "Status",
];

/******LLP************ */

export const tableLlpHeader = [
  "#",
  "LLP ID",
  "Opportunity ID",
  "Llp Name",
  "Title",
  "Registration Number",
  "Status",
];
export const llpTableData = [
  {
    id: "01",
    LLPID: "1234asdf5678lkjh",
    OpportunityName: "0123asdf456jkl7wr",
    Name: "Lorem ipsum Dolor",
    Title: "Lorem ipsum Dolor",
    RegistrationNumber: "01234567890",
  },
];
export const tableLlpApprovalsHeader = [
  "#",
  "Approvals ID",
  "LLP ID",
  "Opportunity ID",
  "Updated By",
  "Updated Date",
  "Status",
];
export const llpApprovalsTableData = [
  {
    id: "01",
    LLPID: "1234asdf5678lkjh",
    OpportunityName: "0123asdf456jkl7wr",
    UpdatedBy: "Lorem ipsum Dolor",
    UpdatedDate: "06/09/2022 13:00:00",
  },
];
export const tableLlpVersionHeader = [
  "#",
  "Version ID",
  "LLP Id",
  "Opportunity Name",
  "Updated By",
  "Updated Date",
  "Approved By",
  "Approved Date",
  "Status",
];
export const llpVersionTableData = [
  {
    id: "01",
    LLPID: "1234asdf5678lkjh",
    OpportunityName: "0123asdf456jkl7wr",
    UpdatedBy: "Lorem ipsum Dolor",
    UpdatedDate: "06/09/2022 13:00:00",
    ApprovedBy: "Lorem ipsum Dolor",
    ApprovedDate: "06/09/2022 13:00:00",
  },
];
export const tableLlpMemberHeader = [
  "#",
  "Name",
  "Amount Invested",
  "User ID",
  "Invested Date",
  "Traded Date",
  "Traded Amount",
  "Status",
];
export const llpMemberTableData = [
  {
    id: "01",
    UserID: "1234asdf5678lkjh",
    Name: "Lorem ipsum Dolor",
    AmountInvested: "Rs. 99999.999 Cr",
  },
];
export const tableLlpDocumentHeader = [
  "#",
  "Name",
  "Description",
  "File Link",
  "Visibility",
  "User Count",
  "Downloadable",
  "Status",
];
export const llpDocumentTableData = [
  {
    id: "01",

    Name: "Lorem ipsum Dolor",
    Description: "Lorem ipsum Dolor",
    FileLink: "https://folder.folder.file.com",
    Visibility: "Public",
    UserCount: "123",
    Downloadable: "Yes",
  },
];
export const tableOurAdvisorHeader = [
  "#",
  "Advisors ID",
  "Name",
  "Designation",
  "Website URL",
  "Description",
  "Priority",
  "Approved At",
  "Updated At",
  "Status",
];

export const tableAdvisorApprovalsHeader = [
  "#",
  "Approvals ID",
  "Our Advisors ID",
  "Name",
  "Designation",
  "Prirority",
  "Approved At",
  "Updated At",
  "Status",
];

export const tableAdvisorVersionHeader = [
  "#",
  "Version ID",
  "Advosors ID",
  "Name",
  "Designation",
  "Updated BY",
  "Updated At",
  "Approved BY",
  "Approved At",
  "Status",
];

export const tableoourPartnerDocumentHeader = [
  "#",
  "PartnersID",
  "Name",
  "Designation",
  "Website url",
  "Description",
  "Prirority",
  "Approved At",
  "Updated At",
  "Status",
];

export const tableourPartnerApprovalHeader = [
  "#",
  "Approvals ID",
  "Our Partners ID",
  "Name",
  "Designation",
  "Prirority",
  "Approved At",
  "Updated At",
  "Status",
];
export const tableourPartnerVersionHeader = [
  "#",
  "Version ID",
  "Partners ID",
  "Name",
  "Designation",
  "Updated By",
  "Updated Date",
  "Approved By",
  "Approved Date",
  "Status",
];

/****LLP*********/

/****CONTACT US*********/

export const tableContactUsHeader = [
  "#",
  "Contact Us Query ID",
  "Query Name",
  "Phone Number",
  "Email",
  "Approved At",
  "Updated At",
  "Status",
];
export const tableContactUsApprovalsHeader = [
  "#",
  "Approval ID",
  "Contact Us Query ID",
  "Query Name",
  "Phone Number",
  "Email",
  "Approved At",
  "Updated At",
  "Status",
];
export const tableContactUsVersionHeader = [
  "#",
  "Version ID",
  "Contact Us ID",
  "Query Name",
  "Updated By",
  "Updated Date",
  "Approved By",
  "Approved Date",
  "Status",
];
/*******  FAQ CATEGORY******/

export const tableFaqCategoryHeader = [
  "#",
  "FAQ Category ID",
  "FAQ Category Name",
  "Approved At",
  "Upadated At",
  "Status",
];
export const tableFaqCategoryApprovalHeader = [
  "#",
  "Approval ID",
  "FAQ Category ID",
  "FAQ Category Name",
  "Approved At",
  "Upadated At",
  "Status",
];
export const tableFaqCategoryVersionHistoryHeader = [
  "#",
  "Version ID",
  "FAQ Category ID",
  "FAQ Category Name",
  "Updated By",
  "Updated Date",
  "Approved By",
  "Updated Date",
  "Status",
];

/*************/

/****NEWS LETTER*********/

export const tableNewsLetterHeader = [
  "#",
  "Newsletter ID",
  "Email Address",
  // "Status",
  "Approved At",
  "Updated At",
  "Status",
];
export const tableNewsLetterApprovalsHeader = [
  "#",
  "Approval ID",
  "Newsletter ID",
  "Email Address",
  // "Status",
  "Approved At",
  "Updated At",
  "Status",
];

export const tableNewsLettersVersionHeader = [
  "#",
  "Version ID",
  "Newsletter ID",
  "Email Address",
  // "Status",
  "Updated By",
  "Updated Date",
  "Approved At",
  "Approved Date",
  "Status",
];

/*************/

/******MEDIA RECOGONITION****** */

export const tableMediaRecogHeader = [
  "#",
  "Media & Recogonitions ID",
  "Name",
  "Title",
  "Description",
  "URL",
  "Approved Date",
  "Updated At",
  "Status",
];
export const tableMediaRecogApprovalsHeader = [
  "#",
  "Approval ID",
  "Media & Recogonitions ID",
  "Name",
  "Title",
  "Description",
  "URL",
  "Approved Date",
  "Updated At",
  "Status",
];
export const tableMediaRecogVersionHeader = [
  "#",
  "Version ID",
  "Media and Recognition ID",
  "Name",
  "Title",
  "Updated By",
  "Updated Date",
  "Approved By",
  "Approved Date",
  "Status",
];
export const tableFaqsHeader = [
  "#",
  "FAQ  ID",
  "FAQ Category ",
  "Questions",
  "Answers",
  "Approved At",
  "Updated At",
  "Status",
];
export const tableFaqsApprovalsHeader = [
  "#",
  "Approvals ID",
  "FAQ  ID",
  "FAQ Category ",
  "Questions",
  "Answers",
  "Approved At",
  "Updated At",
  "Status",
];
export const tableReferHeader = [
  "#",
  "REFER ID",
  "Questions",
  "Answers",
  "Approved At",
  "Updated At",
  "Status",
];
export const tableReferApprovalsHeader = [
  "#",
  "Approval Id",
  "REFER ID",
  "Questions",
  "Answers",
  "Approved At",
  "Updated At",
  "Status",
];
export const tableInvitedHeader = [
  "#",
  "Invited ID",
  "New user Name",
  "Email Id",
  "Phone Number",
  "Opportunity Id",
  "Referral Code",
  "Approved At",
  "Updated At",
  "Status",
];
export const tabledashboardApprovalsHeader = [
  "#",
  "Approval ID",
  "DASHBOARD ID",
  "Questions",
  "Answers",
  "Approved At",
  "Updated At",
  "Status",
];
export const tabledashboardHeader = [
  "#",
  "DASHBOARD ID",
  "Questions",
  "Answers",
  "Approved At",
  "Updated At",
  "Status",
];
// export const tablebadgesApprovalsHeader = [
//   "#",
//   "Approval ID",
//   "BADGES ID",
//   "Questions",
//   "Answers",
//   "Approved At",
//   "Updated At",
//   "Status",
// ];
export const tablebadgesHeader = [
  "#",
  "BADGES ID",
  "Questions",
  "Answers",
  "Approved At",
  "Updated At",
  "Status",
];
export const tablebadgesApprovalHeader = [
  "#",
  "Approval ID",
  "BADGES ID",
  "Questions",
  "Answers",
  "Approved At",
  "Updated At",
  "Status",
];
export const tableCampaignOpporApprovalHeader = [
  "#",
  "Approvals ID",
  "Campaign Opportunity ID",
  "Campaign Title ",
  "Approved At",
  "Updated At",
  "Status",
];
export const tableCampaignOpporHeader = [
  "#",
  "CAMPAIGN ID",
  "CAMPAIGN Title  ",
  "Approved At",
  "Updated At",
  "Status",
];
export const tableHowItWorksHeader = [
  "#",
  "HOW IT WORKS ID",
  "Title",
  "Subtiitle",
  "Active",
  "Approved At",
  "Updated At",
  "Status",
];
export const tableHpowItWorksHeader = [
  "#",
  "Approvals ID",
  "HOW IT WORKS ID",
  "Title",
  "Subtitle",
  "Updated By",
  "Updated At",
  "Status",
];
export const tableMainStaticHeader = [
  "#",
  "Static  ID",
  "Screen Name",
  "Content",
  "Approved At",
  "Updated At",
  "Status",
];
export const tableStaticHeader = [
  "#",
  "Approval ID",
  "Static  ID",
  "Screen Name",
  "Content Name",
  "Approved At",
  "Updated At",
  "Status",
];

export const tableFaqsVersionHistoryHeader = [
  "#",
  "Version  ID",
  "FAQ ID",
  "FAQ Category ",
  "Questions",
  "Updated By",
  "Updated Dated",
  "Approved By",
  "Approved At",
  "",
  "Status",
];

export const tableReferVersionHistoryHeader = [
  "#",
  "Version ID",
  "refer and earn ID",
  "Questions",
  "Updated By",
  "Updated Dated",
  "Approved By",
  "Approved At",
  "Status",
];
export const tableInvitedVersionHistoryHeader = [
  "#",
  "Version ID",
  "Name",
  "Phone Number",
  "Email",
  "Opportunity Id",
  "Updated By",
  "Updated Dated",
  "Approved By",
  "Approved At",
  "Status",
];
export const tablebadgesVersionHistoryHeader = [
  "#",
  "Version ID",
  "Badge ID",
  "Questions",
  "Updated By",
  "Updated Dated",
  "Approved By",
  "Approved At",
  "Status",
];
export const tableCampaignOpporVersionHistoryHeader = [
  "#",
  "Version ID",
  "Campaign Opportunity ID",
  "Campaign Title",
  "Updated By",
  "Updated Dated",
  "Approved By",
  "Approved At",
  "Status",
];
export const tableHowItWorksVersionHistoryHeader = [
  "#",
  "Version ID",
  "HOW IT WORKS ID",
  "Title",
  "Subtiitle",
  "Updated By",
  "Updated Dated",
  "Approved By",
  "Approved At",
  "Status",
];
export const tableStaticVersionHeader = [
  "#",
  "Version  ID",
  "Screen ID",
  "Screen Name",
  "Content",
  "Updated By",
  "Updated Dated",
  "Approved By",
  "Approved At",
  "Status",
];

/*********BLOG CATEGORY******** */
export const tableBlogMainCategoryHeader = [
  "#",
  "Blog Category ID",
  "Blog Category Name",
  "Priority",
  "Approved At",
  "Upadated At",
  "Status",
];
export const tableBlogCategoryHeader = [
  "#",
  "Approvals ID",
  "Blog Category ID",
  "Blog Category Name",
  "Priority",
  "Approved At",
  "Upadated At",
  "Status",
];
export const tableBlogCategoryVersionHistoryHeader = [
  "#",
  "Version ID",
  "Blog Category ID",
  "Blog Category Name",
  "Priority",
  "Updated By",
  "Updated Date",
  "Approved By",
  "Approved Date",
  "Status",
];

/*********BLOG ******** */
export const tableBlogMainHeader = [
  "#",
  "Blog ID",
  // "Blog Category ID",
  "Blog Title Name",
  "Approved At",
  "Upadated At",
  "Status",
];
export const tableBlogHeader = [
  "#",
  "Approvals ID",
  "Blog ID",
  // "Blog Category ID",
  "Blog Title Name",
  "Approved At",
  "Upadated At",
  "Status",
];
export const tableBlogVersionHistoryHeader = [
  "#",
  "Version ID",
  "Blog ID",
  "Blog Title",
  "Updated By",
  "Updated Date",
  "Approved By",
  "Approved Date",
  "Status",
];

/********HOW IT WORKS */

export const tableHowitWorksReasonHeader = [
  "Priority",
  "Reason Name",
  "Description",
  "Actions",
];
export const tableHowitWorksOpporHeader = [
  "Priority",
  "Opportunity ids",
  "Actions",
];
export const tableHowitWorksContentHeader = [
  "Priority",
  "Title",
  "Description",
  "Actions",
];
export const tablePartnerWithusFaqHeader = [
  "Priority",
  "Question",
  "Answer",
  "Actions",
];
export const tablePartnerWithusAnimationHeader = [
  "Priority",
  "Count",
  "Description",
  "Actions",
];
/********HOME*/

export const tableHomeCounterHeader = [
  "Sl No.",
  "Value",
  "Unit",
  "Description",
  "Actions",
];
export const tableHomeOpporHeader = [
  "Sl No.",
  "Title",
  "Description",
  "Actions",
];
export const tableHomeHeader = [
  "#",
  "Home Id",
  "Title",
  "Subtiitle",
  "Active",
  "Created At",
  "Updated At",
  "Status",
];
export const tableHomeApprovalHeader = [
  "#",
  "Approvals Id",
  "Home Id",
  "Title",
  "Subtiitle",
  // "Active",
  "Created At",
  "Updated By",
  "Updated At",
  "Status",
];

export const tableHomeVersionHistoryHeader = [
  "#",
  "Version Id",
  "Home Id",
  "Title",
  "Subtiitle",
  "Updated By",
  "Updated Dated",
  "Approved By",
  "Approved At",
  "Status",
];

/*****CAMPAIGN */
export const tableCampaignCardHeader = ["Sl No.", "Description", "Actions"];
export const tableHomeCardHeader = [
  "Sl No.",
  "title",
  "Description",
  "Actions",
];
export const tableCampaignHeader = [
  "#",
  "Campaign Id",
  "Title",
  "Active",
  "Created At",
  "Updated At",
  "Status",
];
export const tablePartnerWithUsHeader = [
  "#",
  "Partner With Us Id",
  "Title",
  "Active",
  "Created At",
  "Updated At",
  "Status",
];
export const tableCampaignApprovalsHeader = [
  "#",
  "Approvals Id",
  "Campaign Id",
  "Title",
  "Created At",
  "Updated By",
  "Updated At",
  "Status",
];
export const tablePartnerWithUsApprovalsHeader = [
  "#",
  "Approvals Id",
  "Partners Id",
  "Title",
  "Created At",
  "Updated By",
  "Updated At",
  "Status",
];
export const tableCampaignVersionHeader = [
  "#",
  "Version Id",
  "Campaign Id",
  "Title",
  "Updated By",
  "Updated Dated",
  "Approved By",
  "Approved At",
  "Status",
];
export const tablePartnerWithUsVersionHeader = [
  "#",
  "Version Id",
  "Partner Id",
  "Title",
  "Updated By",
  "Updated Dated",
  "Approved By",
  "Approved At",
  "Status",
];

/*******INSIGHTS *******/

export const tableInsightsReasonHeader = [
  "Priority",
  "Reason Name",
  "Description",
  "Actions",
];
export const tableInsightsOpporHeader = [
  "Priority",
  "Opportunity ids",
  "Actions",
];
export const tableInsightsContentHeader = [
  "Priority",
  "Title",
  "Description",
  "Actions",
];

export const tableInsightsCardHeader = ["Sl No.", "Description", "Actions"];
export const tableInsightsHeader = [
  "#",
  "INSIGHTS Id",
  "Title",
  "Active",
  "Created At",
  "Updated At",
  "Status",
];
export const tableInsightsApprovalsHeader = [
  "#",
  "Approvals Id",
  "Insights ID",
  "Title",
  "Updated By",
  "Updated At",
  "Status",
];
export const tableInsightsVersionHeader = [
  "#",
  "Version ID",
  "INSIGHTS Id",
  "Title",
  "Updated By",
  "Updated At",
  "Approved By",
  "Approved At",
  "Status",
];



/*******PARTNER WITH US FORM */
export const tablePartnerWithUsFormHeader = [
  "#",
  "Form Id",
  "Partner Type",
  // "Representaion",
  // "I want to",
  // "For",
  // "I Propose",
  // "File",
  "Name",
  "Email",
  "Mobile Number",
  "Status",
];

/********REFERRED USERS */
export const tableReferedUsersHeader = [
  "#",
  " ID",
  "User Id",
  "Referal Code",
  "User",
  "email",
  "Approved At",
  "Upadated At",
  "Status",
];
export const tableReferedUsersApprovalHeader = [
  "#",
  "Approvals ID",
  "Referred Users ID",
  "Referal Code",
  "User",
  "email",
  "Created At",
  "Updated At",
  "Status",
];
export const tableReferedUsersVersionHistoryHeader = [
  "#",
  "Version ID",
  "Referred Users ID",
  "Referal Code",
  "user name",
  "Updated By",
  "Updated Date",
  "Approved By",
  "Approved Date",
  "Status",
];

/********COMMUNITY BADGES */
export const tableCommunityBadgesHeader = [
  "#",
  "Badge Id",
  "User Name",
  "Points",
  "Points Type",
  "Created At",
  "Upadated At",
  "Status",
];
export const tableCommunityBadgesApprovalHeader = [
  "#",
  "Approvals Id",
  "Badge ID",
  "Points",
  "Points Type",
  "Created At",
  "Updated At",
  "Status",
];
export const tableCommunityBadgesVersionHistoryHeader = [
  "#",
  "Version ID",
  "Badge ID",
  "Points",
  "Points Type",
  "Updated By",
  "Updated Date",
  "Approved By",
  "Approved Date",
  "Status",
];


/********MEMBER TRADE BUY IN */
export const tableMemberTradeBuyInHeader = [
  "#",
  "Buy In Id",
  "User Id",
  "Oppoortunity Id",
  "Amount",
  "Disclose Identity",
  "Created At",
  "Upadated At",
  "Status",
];
export const tableMemberTradeBuyInApprovalHeader = [
  "#",
  "Approval ID",
  "Buy In ID",
  "User Id",
  "Opportunity Id",
  "Disclosed Identity",
  "Updated By",
  "Created At",
  "Updated At",
  "Status",
];
export const tableMemberTradeBuyInVersionHistoryHeader = [
  "#",
  "Version ID",
  "Buy In Id",
  "User Id",
  "Opportunity Id",
  "Amount",
  "Disclosed Identity",
  "Updated By",
  "Updated Date",
  "Approved By",
  "Approved Date",
  "Status",
];

/*******USER REFERRAL *******/

export const tableUserReferralReasonHeader = [
  "Priority",
  "Reason Name",
  "Description",
  "Actions",
];
export const tableUserReferralOpporHeader = [
  "Priority",
  "Opportunity ids",
  "Actions",
];
export const tableUserReferralContentHeader = [
  "Priority",
  "Title",
  "Description",
  "Actions",
];

export const tableUserReferralCardHeader = ["Sl No.", "Description", "Actions"];
export const tableUserReferralHeader = [
  "#",
  "Refer Id",
  "User Id",
  "Opportunity Id",
  "Referral Code",
  "Created At",
  "Updated At",
  // "Status",
];
export const tableUserReferralApprovalsHeader = [
  "#",
  "Refer Id",
  "User Id",
  "Opportunity Id",
  "Updated By",
  "Updated At",
  "Status",
];
export const tableUserReferralVersionHeader = [
  "#",
  "Refer Id",
  "User Id",
  "Opportunity Id",
  "Updated By",
  "Updated At",
  "Approved By",
  "Approved At",
  "Status",
];
