import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import CreateBtn from '../../../../components/buttons/createBtn'
import ExportBtn from '../../../../components/buttons/exportBtn'
import FilterBtn from '../../../../components/buttons/filterBtn'
import VersionHistoryBtn from '../../../../components/buttons/versionHistoryBtn'
import AdminUserFilter from '../../../../components/filter/adminUserFilter'
import LlpFilter from '../../../../components/filter/opportunity-pagesFilter.js/llpFilter'
import InnerLayout from '../../../../components/layouts/innerLayout/innerLayout'
import LlpApprovalsTableContent from '../../../../components/opportunity/llp/tables/llpApprovalsTableContent'
import { llpApprovalsExport } from '../../../../redux/actions/llpActions'


const LlpApprovalsTable = () => {
  const [page, setPage] = useState(0);
  const [showFilter, setShowFilter] = useState(false);
  const [search, setSearch] = useState({ value: "" });
  const dispatch = useDispatch();
  const handleFilterClose = () => {
    setShowFilter(false);
  };
  const handleFilterOpen = () => {
    setShowFilter(true);
  };
  const handleSearch = (value) => {
    setSearch({ value: value });
    setPage(0)
  };
  const handlePage = (type) => {
    if (type === "+") {
      setPage((prev) => prev + 1)
    }
    else {
      setPage((prev) => prev - 1)
    }
  }
  const buttonList = [
    <VersionHistoryBtn link={`/opportunity/llp/version-history`} />,
    <CreateBtn link={`/opportunity/llp/create`} />,
    <ExportBtn dispatchAction={llpApprovalsExport} />,
    <FilterBtn filterFn={handleFilterOpen} />,
  ];
  return (
    <InnerLayout buttons={buttonList}>
      <LlpFilter
        filter={handleSearch}
        showFilter={showFilter}
        closeFilter={handleFilterClose}
      />
      <LlpApprovalsTableContent page={page} changePage={handlePage} search={search} />

    </InnerLayout>
  )
}

export default LlpApprovalsTable