import { call, takeLatest } from "redux-saga/effects";
import { CREATE_SocialME_OPTIONS, SOCIAL_APPROVALS_EXPORT, SOCIAL_APPROVALS_LIST, SOCIAL_APPROVALS_VIEW, SOCIAL_APPROVE, SOCIAL_CREATE, SOCIAL_DELETE, SOCIAL_EDIT, SOCIAL_EXPORT, SOCIAL_LIST, SOCIAL_READ, SOCIAL_REJECT, SOCIAL_SUBMIT, SOCIAL_VERSION_EXPORT, SOCIAL_VERSION_LIST, SOCIAL_VERSION_VIEW } from "../actions/types";
import { approveSocialApi, deleteSocialApi, editSocialApi, readApprovalSocialApi, readSocialApi, rejectSocialApi, socialApprovalsExportApi, socialApprovalsTableApi, socialCreateApi, socialSubmitApi, socialTableExportApi, socialVersionExportApi, socialVersionHistoryViewApi, versionTableSocialApi, viewAllSocialApi } from "../apis/socialApis";
export function* deleteSocialWorker(action) {

    try {
        const res = yield call(deleteSocialApi, action.id,
        );
        // console.log(res, "AddAdminRole");
        if (res.status === 200 && res.data.status === "success") {
            yield action.onSuccess(res.data);
            console.log(res.data);
        } else if (res.status === 200 && res.data.status === "fail") {
            console.log(res);
            yield action.onError(res);
        } else {
            yield action.onError("Something Went Wrong");
        }
    } catch (err) {
        console.log(err);
        yield action.onError("Something Went Wrong");
    }

}
export function* readSocialWorker(action) {
    try {
        const res = yield call(readSocialApi,
            action.id,
        );
        // console.log(res, "AddAdminRole");
        if (res.status === 200 && res.data.status === "success") {
            yield action.onSuccess(res.data);
            console.log(res.data);
        } else if (res.status === 200 && res.data.status === "fail") {
            console.log(res.data);
            yield action.onError(res);
        } else {
            yield action.onError("Something Went Wrong!");
            console.log(res.data);
        }
    } catch (err) {
        console.log(err);
        yield action.onError("Something Went Wrong!!");
    }
}
export function* readApprovalSocialWorker(action) {
    try {
        const res = yield call(readApprovalSocialApi,
            action.id,
        );
        // console.log(res, "AddAdminRole");
        if (res.status === 200 && res.data.status === "success") {
            yield action.onSuccess(res.data);
            console.log(res.data);
        } else if (res.status === 200 && res.data.status === "fail") {
            console.log(res.data);
            yield action.onError(res);
        } else {
            yield action.onError("Something Went Wrong!");
            console.log(res.data);
        }
    } catch (err) {
        console.log(err);
        yield action.onError("Something Went Wrong!!");
    }
}
export function* viewAllSocialWorker(action) {
    try {
        const res = yield call(viewAllSocialApi,
            action.pageNo,
            action.data,
        );
        // console.log(res, "AddAdminRole");
        if (res.status === 200 && res.data.status === "success") {
            yield action.onSuccess(res.data);
            console.log(res, "hgcdhgvdh");
        } else if (res.status === 200 && res.data.status === "fail") {
            console.log(res);
            yield action.onError(res.data);
        } else {
            yield action.onError("Something Went Wrong");
        }
    } catch (err) {
        console.log(err);
        yield action.onError("Something Went Wrong");
    }
}

export function* versionTableSocialWorker(action) {
    try {
        const res = yield call(versionTableSocialApi,
            action.pageNo,
            action.data,
        );
        if (res.status === 200 && res.data.status === "success") {
            yield action.onSuccess(res.data);
            console.log(res.data);
        } else if (res.status === 200 && res.data.status === "fail") {
            console.log(res);
            yield action.onError(res);
        } else {
            yield action.onError("Something Went Wrong!");
            console.log(res.data);
        }
    } catch (err) {
        console.log(err);
        yield action.onError("Something Went Wrong!!");
    }
}

export function* socialVersionHistoryWorker(action) {
    try {
        const res = yield call(socialVersionHistoryViewApi, action.id
        );
        if (res.status === 200 && res.data.status === "success") {
            yield action.onSuccess(res);
            console.log(res);
        } else if (res.status === 200 && res.data.status === "fail") {
            console.log(res);
            yield action.onError(res);
        } else {
            yield action.onError("Something Went Wrong!");
            console.log(res);
        }
    } catch (err) {
        console.log(err);
        yield action.onError("Something Went Wrong!!");
    }
}

export function* socialApprovalsTableWorker(action) {
    try {
        const res = yield call(socialApprovalsTableApi,
            action.pageNo,
            action.data,
        );
        if (res.status === 200 && res.data.status === "success") {
            yield action.onSuccess(res.data);
            console.log(res.data);
        } else if (res.status === 200 && res.data.status === "fail") {
            console.log(res);
            yield action.onError(res);
        } else {
            yield action.onError("Something Went Wrong!");
            console.log(res.data);
        }
    } catch (err) {
        console.log(err);
        yield action.onError("Something Went Wrong!!");
    }
}
export function* socialCreateWorker(action) {
    try {
        const res = yield call(socialCreateApi, action.data);

        if (res.status === 200 && res.data.status === "success") {
            console.log(res.data);
            yield action.onSuccess(res.data);
        } else {
            yield action.onError(res.data);
        }
    } catch (error) { }
}



export function* editSocialWorker(action) {

    try {
        const res = yield call(editSocialApi, action.id, action.data);
        if (res.status === 200 && res.data.status === "success") {
            yield action.onSuccess(res.data);
            console.log(res.data);
        } else if (res.status === 200 && res.data.status === "fail") {
            console.log(res);
            yield action.onError(res);
        } else {
            yield action.onError("Something Went Wrong");
        }
    } catch (err) {
        console.log(err);
        yield action.onError("Something Went Wrong");
    }

}

export function* approveSocialWorker(action) {
    try {
        const res = yield call(approveSocialApi,
            { social_id: action.id }
        );
        // console.log(res, "AddAdminRole");
        if (res.status === 200 && res.data.status === "success") {
            yield action.onSuccess(res.data);
            console.log(res.data);
        } else if (res.status === 200 && res.data.status === "fail") {
            console.log(res);
            yield action.onError(res.data);
        } else {
            yield action.onError("Something Went Wrong!");
            console.log(res.data);
        }
    } catch (err) {
        console.log(err);
        yield action.onError("Something Went Wrong!!");
    }
}


export function* rejectSocialWorker(action) {
    try {
        const res = yield call(rejectSocialApi,
            action.id
        );
        // console.log(res, "AddAdminRole");
        if (res.status === 200 && res.data.status === "success") {
            yield action.onSuccess(res.data);
            console.log(res.data);
        } else if (res.status === 200 && res.data.status === "fail") {
            console.log(res);
            yield action.onError(res.data);
        } else {
            yield action.onError("Something Went Wrong!");
            console.log(res.data);
        }
    } catch (err) {
        console.log(err);
        yield action.onError("Something Went Wrong!!");
    }
}


//export
export function* socialTableExportWorker(action) {
    try {
        const res = yield call(socialTableExportApi,

        );
        if (res.status === 200 && res.data.status === "success") {
            yield action.onSuccess(res.data);
        } else {
            yield action.onError(res.data);
        }
    } catch (error) { }
}


export function* socialApprovalsExportWorker(action) {
    try {
        const res = yield call(socialApprovalsExportApi);
        console.log(res);
        if (res.status === 200 && res.data.status === "success") {
            console.log(res.data);
            yield action.onSuccess(res.data.data);
        } else {
            yield action.onError(res.data);
        }
    } catch (error) { }
}

export function* socialVersionExportWorker(action) {
    try {
        const res = yield call(socialVersionExportApi);
        console.log(res);
        if (res.status === 200 && res.data.status === "success") {
            yield action.onSuccess(res.data);
        } else {
            yield action.onError(res.data);
        }
    } catch (error) { }
}

export function* socialSubmitWorker(action) {
    try {
        const res = yield call(socialSubmitApi, action.id);
        console.log(res);
        if (res.status === 200 && res.data.status === "success") {
            yield action.onSuccess(res.data);
        } else {
            yield action.onError(res.data);
        }
    } catch (error) { }
}



export function* watchViewAllSocialWorker() {
    yield takeLatest(SOCIAL_LIST, viewAllSocialWorker);
}
export function* watchSocialWorker() {
    yield takeLatest(SOCIAL_DELETE, deleteSocialWorker);
}
export function* watchreadSocialWorker() {
    yield takeLatest(SOCIAL_READ, readSocialWorker);
}
export function* watchreadApprovalSocialWorker() {
    yield takeLatest(SOCIAL_APPROVALS_VIEW, readApprovalSocialWorker);
}

export function* watchversionTableSocialWorker() {
    yield takeLatest(SOCIAL_VERSION_LIST, versionTableSocialWorker);
}
export function* watchSocialVersionHistoryWorker() {
    yield takeLatest(SOCIAL_VERSION_VIEW, socialVersionHistoryWorker);
}
export function* watchSocialApprovalsTableWorker() {
    yield takeLatest(SOCIAL_APPROVALS_LIST, socialApprovalsTableWorker);
}
export function* WatcherSocialCreateWorker() {
    yield takeLatest(SOCIAL_CREATE, socialCreateWorker);
}
export function* watcheditSocialWorker() {
    yield takeLatest(SOCIAL_EDIT, editSocialWorker);
}
export function* watchapproveSocialWorker() {
    yield takeLatest(SOCIAL_APPROVE, approveSocialWorker);
}
export function* watchrejectSocialWorker() {
    yield takeLatest(SOCIAL_REJECT, rejectSocialWorker);
}
//export
export function* socialTableExportWatcher() {
    yield takeLatest(
        SOCIAL_EXPORT,
        socialTableExportWorker
    );
}
export function* socialApprovalsExportWatcher() {
    yield takeLatest(SOCIAL_APPROVALS_EXPORT, socialApprovalsExportWorker);
}

export function* socialVersionExportWorkerWatcher() {
    yield takeLatest(SOCIAL_VERSION_EXPORT, socialVersionExportWorker);
}
export function* socialSubmitWorkerWatcher() {
    yield takeLatest(SOCIAL_SUBMIT, socialSubmitWorker);
}
