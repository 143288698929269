import * as React from "react";
import { Link } from "react-router-dom";
import { TableContainer } from "../../table/tableContainer";
import { tableAdminUserListHeader } from "../../table/tableData";
import TablePagination from "../../table/tablePagination";
import "../../admin/tables/admintable.css";
import * as userAction from "../../../redux/actions/userAction";
import { useDispatch } from "react-redux";
import errorToast from "../../utilits/errorToast";
import LoadingSpinnerTable from "../../utilits/loadingSpinnerTable";
import { useState } from "react";
import { useEffect } from "react";
import moment from "moment";
import LoadAndError from "../../utilits/LoadAndError";
import usePermissionChecker from "../../utilits/usePermissionChecker";
const UserListTableContent = ({ page, search, changePage }) => {
  const dispatch = useDispatch();
  const access = usePermissionChecker("maker");
  const [approvalsUserListData, setApprovalsUserListData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);
  const [pageData, setPageData] = useState({ noOfItems: 0, noOfPages: 1 });

  useEffect(() => {
    setLoading(true);
    dispatch(
      userAction.UserList(
        {
          search: {
            user_id: search.userId,
            name: search.name,
            email: search.email,
            phone_number: search.phoneNumber,
            kyc: search.kyc,
            from_date: search.fromDate,
            to_date: search.todate,
          },
        },
        page,
        onSuccess,
        onError
      )
    );
  }, [search, page]);
  const onSuccess = (data) => {
    setApprovalsUserListData(data.data.data);
    setPageData({
      noOfItems: data.data.count,
      noOfPages: data.data.pages,
    });
    setError(false);
    setLoading(false);
  };
  const onError = (data) => {
    errorToast(data.message);
    setError(true);
    setLoading(false);
  };

  function handlePagination(type) {
    if (type === "+") {
      if (page + 1 < pageData.noOfPages) changePage("+");
    } else if (type === "-") if (page > 0) changePage("-");
  }

  return (
    <TableContainer width="93%">
      <div className="adminuser_table__container">
        {loading && <LoadingSpinnerTable />}
        <table className={"adminuser_table"}>
          <thead className="adminuser_table__header">
            <tr className="adminuser_table__row">
              {tableAdminUserListHeader.map((item) => {
                return (
                  <th
                    key={item}
                    className={`text-nowrap adminuser_table__heading ${item === "Status" ? "position-sticky end-0" : null
                      }`}
                  >
                    {item}
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody className="adminuser_table__body">
            <LoadAndError
              loader={loading}
              error={error}
              status={approvalsUserListData.length === 0}
            >
              {approvalsUserListData.map((item, index) => {
                return (
                  <tr key={item.id} className="adminuser_table__row">
                    <td className="text-nowrap dark_title_color adminuser_table__data ">
                      {page > 0 ? 12 * page + (index + 1) : index + 1}
                    </td>
                    <td className="text-nowrap secondary_color adminuser_table__data">
                      {item?.id}
                    </td>
                    <td className="text-nowrap secondary_color adminuser_table__data">
                      {item?.user_name}
                    </td>
                    <td className="text-nowrap  dark_title_color adminuser_table__data">
                      {item?.email}
                    </td>
                    <td className="text-nowrap dark_title_color adminuser_table__data">
                      {item?.phone_number}
                    </td>
                    <td className="text-nowrap dark_title_color adminuser_table__data">
                      {item?.kyc_process_status ? item?.kyc_process_status : "-"}
                    </td>
                    <td className="text-nowrap dark_title_color adminuser_table__data">
                      {moment(item?.createdAt).format("DD/MM/YYYY")}
                    </td>
                    <td className="text-nowrap dark_title_color adminuser_table__data">
                      {item?.last_login === null
                        ? "-"
                        : moment(item.last_login).format("DD/MM/YYYY HH:mm:ss")}
                    </td>
                    <td className="text-nowrap adminuser_table__data position-sticky end-0">
                      <div className="d-flex w-100 gap-2">
                        <Link
                          className="text-decoration-none primary_color"
                          to={{
                            pathname: `/user/user-list/view/${item?.id}`,
                          }}
                          state={{
                            id: item?.id,
                          }}
                        >
                          View
                        </Link>
                        {access && (
                          <>
                            <span className="vr "></span>{" "}
                            <Link
                              className="text-decoration-none secondary_color"
                              to={{
                                pathname: `/user/user-list/edit/${item.id}`,
                              }}
                              state={{
                                id: item?.id,
                              }}
                            >
                              Edit
                            </Link>
                          </>
                        )}
                      </div>
                    </td>
                  </tr>
                );
              })}
            </LoadAndError>
          </tbody>
        </table>
      </div>
      <TablePagination
        paginate={handlePagination}
        currentPage={page}
        pageData={pageData}
      />
    </TableContainer>
  );
};

export default UserListTableContent;
