import React, { useState } from 'react'
import ExportBtn from '../../../../components/buttons/exportBtn'
import FilterBtn from '../../../../components/buttons/filterBtn'
import InnerLayout from '../../../../components/layouts/innerLayout/innerLayout'
import FaqCategoryVersionHistoryTableContent from '../../../../components/static-pages/faqCategory/tables/faqCategoryVersionHistoryTableContent'
import { useDispatch } from "react-redux";
import AdminUserFilter from "../../../../components/filter/adminUserFilter";
import VersionTablesFilter from '../../../../components/filter/versionTableFilter'
import { faqcategoryVersionExport } from '../../../../redux/actions/faqCategoryActions'

const FaqCategoryVersionHistoryTable = () => {
  const [page, setPage] = useState(0);
  const [showFilter, setShowFilter] = useState(false);
  const [search, setSearch] = useState({ value: "" });
  const dispatch = useDispatch();
  const handleFilterClose = () => {
    setShowFilter(false);
  };
  const handleFilterOpen = () => {
    setShowFilter(true);
  };
  const handleSearch = (value) => {
    setSearch({ value: value });
    setPage(0)
  };
  const handlePage = (type) => {
    if (type === "+") {
      setPage((prev) => prev + 1)
    }
    else {
      setPage((prev) => prev - 1)
    }
  }

  const buttonList = [
    <ExportBtn  dispatchAction={faqcategoryVersionExport}/>,
    <FilterBtn filterFn={handleFilterOpen} />
  ]
  return (
    <InnerLayout buttons={buttonList}>
      <VersionTablesFilter
        title='Faq Category Id'
        placeholder='Enter Faq Category Id'
        filter={handleSearch}
        showFilter={showFilter}
        closeFilter={handleFilterClose}
      />
      <FaqCategoryVersionHistoryTableContent page={page} changePage={handlePage} search={search} />
    </InnerLayout>
  )
}

export default FaqCategoryVersionHistoryTable