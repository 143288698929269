import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import InnerLayout from "../../../../components/layouts/innerLayout/innerLayout";
import InnerContainer from "../../../../components/layouts/innerContainer/innerContainer";
import VersionHistoryBtn from "../../../../components/buttons/versionHistoryBtn";
import EditBtn from "../../../../components/buttons/editBtn";
import DeleteBtn from "../../../../components/buttons/deleteBtn";
import { deleteFaq, viewFaqDetail } from "../../../../redux/actions/faqAction";
import DeleteModal from "../../../../components/modals/deleteModal";
import successToast from "../../../../components/utilits/successToast";
import errorToast from "../../../../components/utilits/errorToast";
import LoadingSpinnerTable from "../../../../components/utilits/loadingSpinnerTable";
import LoadAndError from "../../../../components/utilits/LoadAndError";
import { viewCampaignOpporDetail } from "../../../../redux/actions/campaignOpporAction";
import ShareableLink from "../../../../components/utilits/shareableLink";
const CampaignOpporView = () => {
  const navigate = useNavigate();
  const [faqDetails, setFaqDetails] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const dispatch = useDispatch();
  const params = useParams();

  useEffect(() => {
    setLoading(true);
    dispatch(viewCampaignOpporDetail(params.id, onSuccess, onError));
  }, []);

  const onSuccess = (data) => {
    console.log(data.data);
    setFaqDetails(data.data);
    console.log("SUCCESS");
    setLoading(false);
    setError(false);
  };
  const onError = (data) => {
    console.log("ERROR");
    setLoading(false);
    setError(true);
  };




  //delete FAQ
  const [deleteData, setDeleteData] = useState({ name: "", id: "" });
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);

  const handleDelete = () => {
    setLoadingDelete(true);
    dispatch(
      deleteFaq(
        faqDetails.id,
        onDeleteSuccess,
        onDeleteError
      )
    );
  };
  const onDeleteSuccess = (data) => {
    successToast(data.message);
    setLoadingDelete(false);
    setShow(false);
    navigate("/faqs")

  };
  const onDeleteError = (data) => {
    errorToast(data.message);
    setLoadingDelete(false);
  };
  const deletenotifyMebyid = () => {
    setDeleteData({
      name: "Category Name",
      id: faqDetails.id,
    });
    handleShow();
  }
  const buttonList = [
    <VersionHistoryBtn link="/static-pages/campaignOppor/version-history" />,

    faqDetails.editable === true ?
      <>
        <EditBtn link={`/static-pages/campaignOppor/edit/${faqDetails.id}`} />,
        <DeleteBtn showModal={deletenotifyMebyid} />
      </> : null

  ];
  const FaqViewData = [
    {
      heading: "CAMPAIGN  ID",
      title: faqDetails?.id == null ? "--" : faqDetails?.id,
    },
    {
      heading: "CAMPAIGN Heading",
      title: faqDetails?.header == null ? "--" : faqDetails?.header,
    },
    {
      heading: "Opportunity Heading",
      title: faqDetails?.section1_heading,
    },
    {
      heading: "Opportunity SubTitle",
      title: faqDetails?.section1_title,
    },
    {
      heading: "Opportunity Button Text",
      title: faqDetails?.section1_button,
    },
    {
      heading: "Sign Up Title",
      title: faqDetails?.sign_up_title,
    },
    {
      heading: "Testimonial Title",
      title: faqDetails?.testimonial_title,
    },
    {
      heading: "Testimonial SubTitle",
      title: faqDetails?.testimonial_sub_title,
    },
    {
      heading: "Approved At",
      title: faqDetails.createdAt === null ? "-" : moment(faqDetails.createdAt).format("DD-MM-YYYY HH:mm:ss"),
    },
    {
      heading: "Updated At",
      title: faqDetails.updatedAt === null ? "-" : moment(faqDetails.updatedAt).format("DD-MM-YYYY HH:mm:ss"),
    },

  ];


  return (
    <InnerLayout buttons={buttonList}>
      <div className="user_view_container">
        <InnerContainer>
          {loading ? (
            <LoadingSpinnerTable />
          ) : (
            <LoadAndError loader={loading} error={error}>
              <div className="row mt-4">
                <div className="col-12 col-sm-12 col-md-9 col-lg-9 col-xl-7">
                  <div className="row ps-1 ms-1 ps-lg-4 ms-lg-1">
                    {FaqViewData.map((item, index) => (
                      <div className="mb-2 ">
                        <h5 className="dark_grey_color text-nowrap fs_13 fn_Montserrat fw_500">
                          {item.heading}
                        </h5>
                        <p
                          className={` fs_13 fn_Nunito  fw_500 "dark_title_color"
                `}
                        >
                          {item.title}
                        </p>
                      </div>
                    ))}
                  </div>
                </div>
                <ShareableLink title="Campaign opportunity" link={"/campaign/opportunity"} />
              </div>
            </LoadAndError>
          )}
        </InnerContainer>
      </div>
      <DeleteModal
        loader={loadingDelete}
        handleClose={handleClose}
        deleteFn={handleDelete}
        data={deleteData}
        show={show}
      />
    </InnerLayout>
  );
};

export default CampaignOpporView;
