import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { deleteMediaRecAction, viewMediaRecListAction } from "../../../../redux/actions/mediaRecogonitionActions";
import { TableContainer } from "../../../table/tableContainer";
import {
  tableMediaRecogHeader,
  testimonialsapprovalData,
} from "../../../table/tableData";
import TablePagination from "../../../table/tablePagination";
import errorToast from "../../../utilits/errorToast";
import DeleteModal from "../../../modals/deleteModal";
import successToast from '../../../utilits/successToast';
import usePermissionChecker from "../../../utilits/usePermissionChecker";
import LoadingSpinnerTable from "../../../utilits/loadingSpinnerTable";
import LoadAndError from "../../../utilits/LoadAndError";

const MediaRecogonitionTableContent = ({ page, search, changePage }) => {
  const access = usePermissionChecker("maker");
  const [mediaData, setMediaData] = useState([]);
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [deleteData, setDeleteData] = useState({ name: "", id: "" });
  const [loadingTable, setLoadingTable] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [pageData, setPageData] = useState({ noOfItems: 0, noOfPages: 1 })
  const dispatch = useDispatch();
  useEffect(() => {
    setLoading(true);
    console.log("The search", search);
    dispatch(viewMediaRecListAction(
      {
        search: {
          media_recogn_id: search?.value?.mediaId,
          name: search?.value?.name
        }
      },
      page,
      onSuccess,
      onError));
  }, [search, page]);

  const onSuccess = (data) => {
    setMediaData(data?.data?.data);
    console.log("SUCCESS");
    setPageData({
      noOfItems: data.data.count,
      noOfPages: data.data.pages
    })
    setError(false)
    setLoading(false)
  };

  const onError = (data) => {
    errorToast(data.data.data);
    console.log("ERROR");
    setError(false)
    setLoading(false)
  };

  const handleDelete = () => {
    setLoadingDelete(true);
    dispatch(
      deleteMediaRecAction(
        deleteData.id,
        onDeleteSuccess,
        onDeleteError
      )
    );
  };
  const onDeleteSuccess = (data) => {
    successToast(data.message);
    setLoadingDelete(false);
    setShow(false);
    setError(false)
    setLoading(false)
  };
  const onDeleteError = (data) => {
    errorToast(data.message);
    setLoadingDelete(false);
    setError(false)
    setLoading(false)
  };
  function handlePagination(type) {
    if (type === "+") {
      if (page + 1 < pageData.noOfPages) changePage("+");
    }
    else if (type === "-")
      if (page > 0) changePage("-");
  }
  return (
    <TableContainer maxWidth="100%" width="90%">
      <div className="adminuser_table__container position-relative">
        {loading && <LoadingSpinnerTable />}
        <table className={"adminuser_table"}>
          <thead className="adminuser_table__header">
            <tr className="adminuser_table__row">
              {tableMediaRecogHeader.map((item) => {
                return (
                  <th
                    key={item}
                    className={`text-nowrap adminuser_table__heading adminuser_approvals_table__heading ${item === "Status" ? `${access ? "" : "text-start"} position-sticky end-0 text-start` : null
                      } `}
                  >
                    {item}
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody className="adminuser_table__body">
            <LoadAndError loader={loading} error={error} status={mediaData.length === 0}>
              {mediaData.map((item, index) => {
                return (
                  <tr key={item.id} className="adminuser_table__row">
                    <td className="text-nowrap dark_title_color adminuser_table__data ">
                      {page > 0 ? 12 * page + (index + 1) : index + 1}
                    </td>
                    <td className="text-nowrap secondary_color adminuser_table__data">
                      {item?.id == null ? "--" : item?.id}
                    </td>
                    <td className="text-nowrap secondary_color adminuser_table__data">
                      {item?.name == null ? "--" : item?.name}
                    </td>
                    <td className="text-nowrap secondary_color adminuser_table__data">
                      {item?.title == null ? "--" : item?.title}
                    </td>

                    <td title={item?.description} className="text-nowrap cursor_pointer adminuser_table_ellipsis dark_title_color adminuser_table__data">
                      {item?.description == null ? "--" : item?.description}
                    </td>
                    <td className="text-nowrap dark_title_color adminuser_table__data">
                      {item?.know_more_url == null ? "--" : item?.know_more_url}
                    </td>
                    <td className="text-nowrap dark_title_color adminuser_table__data">
                      {item?.media_recognition_checkkers?.[0]?.approved_date == null ? "--" : moment(item?.media_recognition_checkkers?.[0]?.approved_date).format("DD-MM-YYYY HH:mm:ss")}
                    </td>
                    <td className="text-nowrap dark_title_color adminuser_table__data">
                      {item?.media_recognition_checkkers?.[0]?.updatedAt == null ? "--" : moment(item?.media_recognition_checkkers?.[0]?.updatedAt).format("DD-MM-YYYY HH:mm:ss")}
                    </td>
                    <td className="text-nowrap adminuser_table__data position-sticky end-0">
                      <div className="d-flex w-100 gap-2 justify-content-start">
                        <Link
                          className="text-decoration-none primary_color"
                          to={{
                            pathname: `/static-pages/media-recogonition/view/${item.id}`,
                          }}
                        >
                          View
                        </Link>{" "}
                        {
                          access && <>
                            {
                              item.editable && <>
                                <span className="vr"></span>{" "}
                                <Link
                                  className="text-decoration-none secondary_color"
                                  to={{
                                    pathname: `/static-pages/media-recogonition/edit/${item.id}`,
                                  }}
                                  state={item}
                                >
                                  Edit
                                </Link>

                                {item.lastActionApproved && (
                                    <>
                                {" "} <span className="vr"></span>{" "}
                                <button
                                  onClick={() => {
                                    setDeleteData({
                                      name: item?.name,
                                      id: item?.id,
                                    });
                                    handleShow();
                                  }}
                                  className="text-decoration-none primary_color light_red_color border-0 bg-transparent "
                                >
                                  Delete
                                </button>
                                </>)}
                                </>
                            }

                          </>
                        }
                      </div>
                    </td>
                  </tr>
                );
              })}
            </LoadAndError>
          </tbody>
        </table>
      </div>
      <DeleteModal
        loader={loadingDelete}
        handleClose={handleClose}
        deleteFn={handleDelete}
        data={deleteData}
        show={show}
      />
      <TablePagination
        paginate={handlePagination}
        currentPage={page}
        pageData={pageData}
      />
    </TableContainer>
  );
};

export default MediaRecogonitionTableContent;
