import React from 'react'
import { Spinner } from 'react-bootstrap'
import SaveIcon from '../../assets/icons/saveIcon'
import { AdminButton } from './versionHistoryBtn'

const SubmitBtn = ({ loader = false, submitFn, saveHide }) => {
    return (
        <AdminButton disabled={!saveHide} type='button' onClick={() => submitFn()} padding="5px 15px" color={`${!saveHide ? "grey" : "green"}`}>
            <span className="ms-1 pdt_1">SUBMIT</span>
        </AdminButton >
    )
}

export default SubmitBtn