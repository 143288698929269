import React, { useState } from "react";
import CreateBtn from "../../../../components/buttons/createBtn";
import ExportBtn from "../../../../components/buttons/exportBtn";
import FilterBtn from "../../../../components/buttons/filterBtn";
import VersionHistoryBtn from "../../../../components/buttons/versionHistoryBtn";
import AdminUserFilter from "../../../../components/filter/adminUserFilter";
import VersionTablesFilter from "../../../../components/filter/versionTableFilter";
import InnerLayout from "../../../../components/layouts/innerLayout/innerLayout";
import UserReferralApprovalTableContent from "../../../../components/static-pages/userReferral/tables/userReferralApprovalTableContent";
import { UserReferralApprovalsExportAction } from "../../../../redux/actions/userReferralActions";

const UserReferralApprovalTable = () => {
  const [showFilter, setShowFilter] = useState(false);
  const [search, setSearch] = useState();
  const [page, setPage] = useState(0);
  const handleFilterClose = () => {
    setShowFilter(false);
  };
  const handleFilterOpen = () => {
    setShowFilter(true);
  };
  const handleSearch = (value) => {
    setSearch({ value: value });
    setPage(0);
  };
  const handlePage = (type) => {
    if (type === "+") {
      setPage((prev) => prev + 1);
    } else {
      setPage((prev) => prev - 1);
    }
  };
  const buttonList = [
    <VersionHistoryBtn link="/static-pages/userReferral/version-history" />,
    <CreateBtn link="/static-pages/userReferral/create" />,
    <ExportBtn dispatchAction={UserReferralApprovalsExportAction} />,
    <FilterBtn filterFn={handleFilterOpen} />,
  ];
  return (
    <InnerLayout buttons={buttonList}>
      <VersionTablesFilter
        title="Insights Id"
        placeholder="Enter insights Id"
        filter={handleSearch}
        showFilter={showFilter}
        closeFilter={handleFilterClose}
      />
      <UserReferralApprovalTableContent
        page={page}
        changePage={handlePage}
        search={search}
      />
    </InnerLayout>
  );
};
export default UserReferralApprovalTable;
