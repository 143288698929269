import React, { useEffect, useState } from "react";
import DeleteBtn from "../../../components/buttons/deleteBtn";
import EditBtn from "../../../components/buttons/editBtn";
import ResetPswrdBtn from "../../../components/buttons/resetPswrdBtn";
import VersionHistoryBtn from "../../../components/buttons/versionHistoryBtn";
import InnerContainer from "../../../components/layouts/innerContainer/innerContainer";
import InnerLayout from "../../../components/layouts/innerLayout/innerLayout";
import ResetPassword from "../../../components/modals/resetPassword";
import Kyc from "../../../components/user/kyc/kyc";
import PersonalInformation from "../../../components/user/personalInformation/PersonalInformationUserView";
import "../user-view.css";
import * as userAction from "../../../redux/actions/userAction";
import { useDispatch } from "react-redux";
import errorToast from "../../../components/utilits/errorToast";
import LoadingSpinnerTable from "../../../components/utilits/loadingSpinnerTable";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Address from "../../../components/user/UserViewAddressPanBank/address";
import successToast from "../../../components/utilits/successToast";
import Bank from "../../../components/user/UserViewAddressPanBank/bank";
import Pan from "../../../components/user/UserViewAddressPanBank/pan";
import usePermissionChecker from "../../../components/utilits/usePermissionChecker";
import DeleteModal from "../../../components/modals/deleteModal";
import LoadAndError from "../../../components/utilits/LoadAndError";
const UserView = () => {
  const [delLoading, setDelLoading] = useState(false);
  const [show, setShow] = useState(false);
  const handleShows = () => setShow(true);
  const handleClose = () => setShow(false);
  const [deleteshow, setDeleteShow] = useState(false);
  const deleteHandleShow = () => setDeleteShow(true);
  const deleteHandleClose = () => setDeleteShow(false);
  const location = useLocation();
  const params = useParams();
  const [updated, setUpdated] = useState(false);
  const [individualData, setIndividualData] = useState({});
  const [userDetails, setUserDetails] = useState(null);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [loadingForgot, setLoadingForgot] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [deleteData, setDeleteData] = useState({ name: "", id: "" });
  const [image, setImage] = useState("");
  const checkerAccess = usePermissionChecker("checker");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    setLoading(true);
    dispatch(userAction.UsersView(params?.id, onSuccess, onError));
    setImage("");
  }, [updated]);
  const onSuccess = (data) => {
    setIndividualData(data.data);
    setUpdated(true);
    setImage("");
    setLoading(false);
    setError(false);
  };
  const onError = (data) => {
    errorToast(data.message);
    setLoading(false);
    setError(true);
  };
  const handleShow = () => {
    setDeleteData({ roleName: userDetails?.user_name, id: params.id });
    setShow(true);
  };
  const handleDelete = () => {
    setDelLoading(true);
    dispatch(
      userAction.deleteUserAction(params.id, onDeleteSuccess, onDeleteError)
    );
  };
  const onDeleteSuccess = (data) => {
    setDelLoading(false);
    setDeleteShow(false);
    successToast(data.message);
    navigate("/");
    setLoading(false);
    setError(false);
  };
  const onDeleteError = () => {
    setDelLoading(false);
    setDeleteShow(false);
    setLoading(false);
    setError(true);
  };
  const resetPassword = () => {
    setLoadingForgot(true);
    dispatch(
      userAction.forgotPasswordSendEmailAction(
        individualData?.email,
        onForgotSendSuccess,
        onForgotSendError
      )
    );
  };
  const onForgotSendSuccess = (data) => {
    handleShows();
    setLoadingForgot(false);
  };
  const onForgotSendError = (data) => {
    errorToast(data.data);
    setLoadingForgot(false);
  };

  // useMemo(() => first, [second])

  const buttonList = [
    <VersionHistoryBtn link="/user/user-list/version-history" />,
    <ResetPswrdBtn loader={loadingForgot} showModal={resetPassword} />,
    individualData?.editable && (
      <EditBtn link={`/user/user-list/edit/${params?.id}`} />
    ),
    // individualData?.editable && userDetails?.lastActionApproved && (
    <DeleteBtn
      showModal={() => {
        setDeleteShow(true);
        setDeleteData({ name: individualData?.user_name, id: params.id });
      }}
    />,
    // ),
  ];
  return (
    <>
      <InnerLayout buttons={buttonList}>
        <div className="user_view_container">
          <InnerContainer>
            {loading ? (
              <LoadingSpinnerTable />
            ) : (
              <LoadAndError loader={loading} error={error}>
                <div>
                  {individualData?.reject_message == null ? (
                    ""
                  ) : (
                    <div className="ps-2 ms-2 ps-lg-4 ms-lg-3">
                      <h1 className="fn_Montserrat fw_600 fs_13 dark_red_color">
                        Rejected Message:
                      </h1>
                      <p className="dark_title_color fs_13 fw_400 fn_Nunito col-12 col-lg-6">
                        {individualData?.reject_message}
                      </p>
                    </div>
                  )}
                  <PersonalInformation
                    individualData={individualData}
                    image={image}
                  />
                  <Kyc
                    individualData={individualData}
                    value={setUpdated}
                    label={updated}
                  />
                  {individualData?.address_proof === null ? (
                    <></>
                  ) : (
                    <>
                      <Address
                        individualData={individualData}
                        value={setUpdated}
                        label={updated}
                      />
                    </>
                  )}
                  {individualData?.pan === null ? (
                    <></>
                  ) : (
                    <>
                      <Pan
                        individualData={individualData}
                        value={setUpdated}
                        label={updated}
                      />
                    </>
                  )}

                  {individualData?.bank_account_details === null ? (
                    <></>
                  ) : (
                    <>
                      <Bank
                        individualData={individualData}
                        value={setUpdated}
                        label={updated}
                      />
                    </>
                  )}
                </div>
              </LoadAndError>
            )}
          </InnerContainer>
        </div>
      </InnerLayout>
      <DeleteModal
        loader={delLoading}
        handleClose={() => setDeleteShow(false)}
        deleteFn={handleDelete}
        data={deleteData}
        show={deleteshow}
      />
      <ResetPassword
        email={individualData?.email}
        handleClose={handleClose}
        show={show}
      />
    </>
  );
};

export default UserView;
