import { call, takeLatest } from "redux-saga/effects";
import {
  ACTIVATE_CAMPAIGN_OPTIONS,
  CAMPAIGN_APPROVALS_EXPORT,
  CAMPAIGN_APPROVALS_LIST,
  CAMPAIGN_APPROVALS_VIEW,
  CAMPAIGN_APPROVE,
  CAMPAIGN_CREATE,
  CAMPAIGN_DELETE,
  CAMPAIGN_EDIT,
  CAMPAIGN_LIST,
  CAMPAIGN_MAIN_EXPORT,
  CAMPAIGN_READ,
  CAMPAIGN_REJECT,
  CAMPAIGN_SUBMIT,
  CAMPAIGN_VERSION_EXPORT,
  CAMPAIGN_VERSION_LIST,
  CAMPAIGN_VERSION_VIEW,
} from "../actions/types";
import {
  activateCampaignApi,
  addCampaignApi,
  approvalsCampaignListpApi,
  approveCampaignApi,
  campaignApprovalsExportApi,
  campaignMainExportApi,
  campaignSubmitApi,
  campaignVersionExportApi,
  deleteCampaignApi,
  editCampaignApi,
  rejectCampaignApi,
  versionCampaignListpApi,
  viewApprovalCampaignApi,
  viewCampaignApi,
  viewCampaignListApi,
  viewVersionCampaignApi,
} from "../apis/campaignApis";

export function* addCampaignWorker(action) {
  try {
    const res = yield call(addCampaignApi, action.data);
    console.log(res);
    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res.data);
      console.log(res.data);
    } else if (res.status === 200 && res.data.status === "fail") {
      console.log(res.data);
      yield action.onError(res);
    } else {
      yield action.onError("Something Went Wrong");
    }
  } catch (err) {
    console.log(err);
    yield action.onError("Something Went Wrong");
  }
}

export function* viewCampaignWorker(action) {
  try {
    const res = yield call(viewCampaignApi, action.id);
    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res.data);
      console.log(res.data);
    } else if (res.status === 200 && res.data.status === "fail") {
      console.log(res.data);
      yield action.onError(res);
    } else {
      yield action.onError("Something Went Wrong!");
      console.log(res.data);
    }
  } catch (err) {
    console.log(err);
    yield action.onError("Something Went Wrong!!");
  }
}
export function* editCampaignWorker(action) {
  try {
    const res = yield call(editCampaignApi, action.id, action.data);
    console.log(res);
    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res.data);
      console.log(res.data);
    } else if (res.status === 200 && res.data.status === "fail") {
      console.log(res);
      yield action.onError(res);
    } else {
      yield action.onError("Something Went Wrong");
    }
  } catch (err) {
    console.log(err);
    yield action.onError("Something Went Wrong");
  }
}
export function* deleteCampaignWorker(action) {
  try {
    const res = yield call(deleteCampaignApi, action.id);
    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res.data);
      console.log(res.data);
    } else if (res.status === 200 && res.data.status === "fail") {
      console.log(res.data);
      yield action.onError(res);
    } else {
      yield action.onError("Something Went Wrong");
    }
  } catch (err) {
    console.log(err);
    yield action.onError("Something Went Wrong");
  }
}
export function* viewCampaignListWorker(action) {
  try {
    const res = yield call(viewCampaignListApi, action.page, action.search);
    console.log(res);
    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res.data);
      console.log(res.data);
    } else if (res.status === 200 && res.data.status === "fail") {
      console.log(res);
      yield action.onError(res.data);
    } else {
      yield action.onError("Something Went Wrong");
    }
  } catch (err) {
    console.log(err);
    yield action.onError("Something Went Wrong");
  }
}

export function* approveCampaignWorker(action) {
  try {
    const res = yield call(approveCampaignApi, { campaign_id: action.id });
    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res.data);
      console.log(res.data);
    } else if (res.status === 200 && res.data.status === "fail") {
      console.log(res);
      yield action.onError(res.data);
    } else {
      yield action.onError("Something Went Wrong!");
      console.log(res.data);
    }
  } catch (err) {
    console.log(err);
    yield action.onError("Something Went Wrong!!");
  }
}

export function* rejectCampaignWorker(action) {
  try {
    const res = yield call(rejectCampaignApi, action.id);
    console.log(res, "rekect CAMPAIGN");
    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res.data);
      console.log(res.data);
    } else if (res.status === 200 && res.data.status === "fail") {
      console.log(res);
      yield action.onError(res.data);
    } else {
      yield action.onError("Something Went Wrong!");
      console.log(res.data);
    }
  } catch (err) {
    console.log(err);
    yield action.onError("Something Went Wrong!!");
  }
}
export function* approvalsCampaignListWorker(action) {
  try {
    const res = yield call(
      approvalsCampaignListpApi,
      action.page,
      action.search
    );
    // console.log(res, "AddAdminRole");
    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res.data);
      console.log(res.data);
    } else if (res.status === 200 && res.data.status === "fail") {
      console.log(res);
      yield action.onError(res);
    } else {
      yield action.onError("Something Went Wrong!");
      console.log(res.data);
    }
  } catch (err) {
    console.log(err);
    yield action.onError("Something Went Wrong!!");
  }
}
export function* viewApprovalCampaignWorker(action) {
  try {
    const res = yield call(viewApprovalCampaignApi, action.id);
    console.log(res, "CAMPAIGN approvals");
    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res.data);
      console.log(res.data);
    } else if (res.status === 200 && res.data.status === "fail") {
      console.log(res);
      yield action.onError(res);
    } else {
      yield action.onError("Something Went Wrong!");
      console.log(res.data);
    }
  } catch (err) {
    console.log(err);
    yield action.onError("Something Went Wrong!!");
  }
}
export function* versionListCampaignWorker(action) {
  try {
    const res = yield call(versionCampaignListpApi, action.page, action.search);
    // console.log(res, "AddAdminRole");
    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res.data);
      console.log(res.data);
    } else if (res.status === 200 && res.data.status === "fail") {
      console.log(res);
      yield action.onError(res);
    } else {
      yield action.onError("Something Went Wrong!");
      console.log(res.data);
    }
  } catch (err) {
    console.log(err);
    yield action.onError("Something Went Wrong!!");
  }
}
export function* activateCampaignWorker(action) {
  try {
    const res = yield call(activateCampaignApi, action.id);
    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res);
      console.log(res.data);
    } else if (res.status === 200 && res.data.status === "fail") {
      console.log(res);
      yield action.onError(res);
    } else {
      yield action.onError("Something Went Wrong!");
      console.log(res);
    }
  } catch (err) {
    console.log(err);
    yield action.onError("Something Went Wrong!!");
  }
}
export function* versionViewCampaignWorker(action) {
  try {
    const res = yield call(viewVersionCampaignApi, action.id);
    // console.log('====================================');
    // console.log(res);
    // console.log('====================================');
    if (res.status === 200 && res.data?.status === "success") {
      yield action.onSuccess(res);
      // console.log(res.data);
    } else if (res.status === 200 && res.data?.status === "fail") {
      // console.log(res);
      yield action.onError(res);
    } else {
      yield action.onError("Something Went Wrong!");
      console.log(res);
    }
  } catch (err) {
    console.log(err);
    yield action.onError("Something Went Wrong!!");
  }
}

export function* campaignMainExportWorker(action) {
  try {
    const res = yield call(campaignMainExportApi);
    console.log(res);
    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res.data);
    }
  } catch (error) {}
}

export function* campaignApprovalsExportWorker(action) {
  try {
    const res = yield call(campaignApprovalsExportApi);
    console.log(res);
    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res.data);
    }
  } catch (error) {}
}

export function* campaignVersionExportWorker(action) {
  try {
    const res = yield call(campaignVersionExportApi);
    console.log(res);
    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res.data);
    }
  } catch (error) {}
}

export function* campaignSubmitWorker(action) {
  try {
    const res = yield call(campaignSubmitApi, action.id);
    console.log(res);
    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res.data);
    }
  } catch (error) {}
}

/**********************************/
/************WATHCHERS**************/
/**********************************/

export function* watchAddCampaignWorker() {
  yield takeLatest(CAMPAIGN_CREATE, addCampaignWorker);
}
export function* watchApprovalsCampaignListWorker() {
  yield takeLatest(CAMPAIGN_APPROVALS_LIST, approvalsCampaignListWorker);
}
export function* watchViewApprovalCampaignWorker() {
  yield takeLatest(CAMPAIGN_APPROVALS_VIEW, viewApprovalCampaignWorker);
}
export function* watchViewCampaignWorker() {
  yield takeLatest(CAMPAIGN_READ, viewCampaignWorker);
}
export function* watchViewCampaignListWorker() {
  yield takeLatest(CAMPAIGN_LIST, viewCampaignListWorker);
}
export function* watchEditCampaignWorker() {
  yield takeLatest(CAMPAIGN_EDIT, editCampaignWorker);
}
export function* watchApproveCampaignWorker() {
  yield takeLatest(CAMPAIGN_APPROVE, approveCampaignWorker);
}
export function* watchRejectCampaignWorker() {
  yield takeLatest(CAMPAIGN_REJECT, rejectCampaignWorker);
}
export function* watchDeleteCampaignWorker() {
  yield takeLatest(CAMPAIGN_DELETE, deleteCampaignWorker);
}
export function* watchVersionListCampaignWorker() {
  yield takeLatest(CAMPAIGN_VERSION_LIST, versionListCampaignWorker);
}
export function* watchVersionViewCampaignWorker() {
  yield takeLatest(CAMPAIGN_VERSION_VIEW, versionViewCampaignWorker);
}
export function* WatchActivateCampaignWorker() {
  yield takeLatest(ACTIVATE_CAMPAIGN_OPTIONS, activateCampaignWorker);
}

export function* watchCampaignMainExportWorker() {
  yield takeLatest(CAMPAIGN_MAIN_EXPORT, campaignMainExportWorker);
}
export function* watchCampaignApprovalsExportWorker() {
  yield takeLatest(CAMPAIGN_APPROVALS_EXPORT, campaignApprovalsExportWorker);
}
export function* watchCampaignVersionExportWorker() {
  yield takeLatest(CAMPAIGN_VERSION_EXPORT, campaignVersionExportWorker);
}
export function* watchCampaignSubmitWorker() {
  yield takeLatest(CAMPAIGN_SUBMIT, campaignSubmitWorker);
}
