import React, { useEffect, useRef, useState } from "react";
import DeleteBtn from "../../../../components/buttons/deleteBtn";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useNavigate, } from 'react-router-dom'
import successToast from "../../../../components/utilits/successToast";
import errorToast from "../../../../components/utilits/errorToast";
import InnerContainer from "../../../../components/layouts/innerContainer/innerContainer";
import InnerLayout from "../../../../components/layouts/innerLayout/innerLayout";
import "../notify-me.css";
import SelectFields from "../../../../components/form/selectFields/selectFields";
import SaveBtn from "../../../../components/buttons/saveBtn";
import { deleteNotifyMe, editNotifyMe, notifyMeCreateOptions, notifySubmitAction, readNotifyMe } from "../../../../redux/actions/notifyMeActions";
import DeleteModal from "../../../../components/modals/deleteModal";
import { opportunitiesNameListAction } from "../../../../redux/actions/opportunitiesActions";
import LoadingSpinnerTable from "../../../../components/utilits/loadingSpinnerTable";
import LoadAndError from "../../../../components/utilits/LoadAndError";
import SubmitBtn from "../../../../components/buttons/submit";
import InputField from "../../../../components/form/inputFields/inputField";
import ErrorMsg from "../../../../components/form/errorMsg";
import AdminRoleCheckBox from "../../../../components/utilits/adminRoleCheckbox";
import { deleteNotification, editNotification, notificationSubmitAction, readNotification } from "../../../../redux/actions/notificationActions";
import { deleteSocial, editSocial, readSocial, socialSubmitAction } from "../../../../redux/actions/socialActions";
const SocialEdit = () => {
    const navigate = useNavigate();
    const handleShow = () => setShow(true);
    const handleClose = () => setShow(false);
    const [show, setShow] = useState(false);
    const params = useParams()
    const [updated, setUpdated] = useState(false)
    const formRef = useRef()
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false)
    const [saved, setSaved] = useState(false);
    const [subLoading, setSubLoading] = useState(false);
    // for default value
    const [notifyMeViewData, SetNotifyMeViewData] = useState({ id: "", name: "", createdAt: "", updatedAt: "" })
    useEffect(() => {
        setLoading(true);
        dispatch(
            readSocial(params.id, onSuccess, onError)
        );
    }, [params.id]);
    const onSuccess = (data) => {
        SetNotifyMeViewData(data.data)
        console.log(data.data);
        setLoading(false);
        setError(false)
    };
    const onError = (data) => {
        errorToast(data.message)
        setLoading(false);
        setError(true)
    };


    /** sropdown* */
    const [userName, setUserName] = useState([]);
    useEffect(() => {
        dispatch(notifyMeCreateOptions(onNotifyMeSuccess, onNotifyMeError));
    }, []);
    const userNameOptions = [];
    const onNotifyMeSuccess = (data) => {
        data?.data?.AllUsers.map((item, index) => {
            userNameOptions[index] = { value: item.id, label: item.user_name };
            console.log();
        });

        setUserName(userNameOptions);
    };
    const onNotifyMeError = (data) => {
        console.log("ERROR");
    };
    // for edit api
    const [notifyMeData, setNotifyMeData] = useState()
    const initialValues = {
        userId: notifyMeViewData.user_id,
        testimonial: notifyMeViewData.is_testimonial,
        redirectionUrl: notifyMeViewData.link_url
    };
    const loginSchema = Yup.object().shape({
        userId: Yup.string().trim().required("Required"),
        redirectionUrl: Yup.string().trim().required("Required"),
    });
    const handleSubmit = () => {
        if (formRef.current) {
            formRef.current.handleSubmit()
        }
    }
    const onSubmit = (values, { resetForm }) => {
        dispatch(
            editSocial(
                params.id,
                {
                    "user_id": values.userId,
                    "is_testimonial": values.testimonial,
                    "link_url": values.redirectionUrl
                },
                onEditSuccess, onEditError)
        );
        setUpdated(!updated)
    };
    const onEditSuccess = (data) => {
        setNotifyMeData(data.data)
        successToast(data.message)
        setSaved(true);
        setLoading(false)
        navigate("/static-pages/social")
        setError(false)
    };
    const onEditError = (data) => {
        console.log(data);
        errorToast(data.message)
        setLoading(false)
        setError(true)
    };

    console.log(notifyMeViewData?.user_id);
    console.log(notifyMeViewData?.opportunity?.id);
    //delete
    const [deleteData, setDeleteData] = useState({ name: "", id: "" });
    const [loadingDelete, setLoadingDelete] = useState(false);
    const handleDelete = () => {
        setLoadingDelete(true);
        dispatch(
            deleteSocial(
                notifyMeViewData.id,
                onDeleteSuccess,
                onDeleteError
            )
        );
    };
    const onDeleteSuccess = (data) => {
        successToast(data.message);
        setLoadingDelete(false);
        setShow(false);
        navigate("/static-pages/social")
        setLoading(false)
        setError(false)
    };
    const onDeleteError = (data) => {
        errorToast(data.message);
        setLoadingDelete(false);
        setLoading(false)
        setError(true)
    };
    const deletenotifyMebyid = () => {
        setDeleteData({
            name: notifyMeViewData.user_name,
            id: notifyMeViewData.opportunity_id,
        });
        handleShow();
    }

    //opp id
    const [opportunitiesList, setOpportunitiesList] = useState([])
    useEffect(() => {
        dispatch(opportunitiesNameListAction(onSuccessOpt, onErrorOpt));
    }, []);
    const onSuccessOpt = (data) => {
        const categoryList = [];
        data?.data.map((item, index) => {
            categoryList[index] = { value: item.id, label: `${item.title} id: ${item.id}` };
        });
        setOpportunitiesList(categoryList);
        console.log("SUCCESS");
    };
    const onErrorOpt = (data) => {
        console.log(data);
    };

    const notifySubmit = () => {
        setSubLoading(true);
        dispatch(
            socialSubmitAction(params.id, onAdminSubmitSucess, onAdminSubmitError)
        );
    };
    const buttonList = [
        <SaveBtn submitFn={handleSubmit} />,
        // <DeleteBtn showModal={deletenotifyMebyid} />,
        // <SubmitBtn saveHide={saved} submitFn={notifySubmit} />
    ];

    function onAdminSubmitSucess(data) {
        successToast(data.message);
        setSubLoading(false);
        navigate("/static-pages/social")
    }
    function onAdminSubmitError(data) {
        setSubLoading(false);
        errorToast(data.message);
    }
    return (
        <InnerLayout buttons={buttonList}>
            <div className="notify-me-container">
                <InnerContainer>
                    {loading ? (
                        <LoadingSpinnerTable />
                    ) : (
                        <LoadAndError loader={loading} error={error}>
                            <div className="pt-3  ps-4 ps-lg-4 ms-3">
                                <h5 className="dark_grey_color fs_13 fn_Montserrat fw_500 m-0">
                                    Notification ID
                                </h5>
                                <p
                                    className={` fs_13 fn_Nunito fw_500 dark_title_color  `}>
                                    {notifyMeViewData.id}
                                </p>
                            </div>
                            <div className="  ps-4 ps-lg-4 ms-3">
                                <Formik
                                    innerRef={formRef}
                                    initialValues={initialValues}
                                    onSubmit={onSubmit}
                                    validationSchema={loginSchema}
                                >
                                    <Form className='notify-me-form-width'>
                                        <SelectFields
                                            default1={{ value: notifyMeViewData.user_id, label: notifyMeViewData?.user?.user_name, }}
                                            label={"User Name"}
                                            placeholder={"Select User"}
                                            name={"userId"}
                                            options={userName}
                                            id={"userId"}
                                        />
                                        <InputField
                                            id={"redirectionUrl"}
                                            label={"Redirection Url"}
                                            type={"text"}
                                            placeholder={"Enter Redirection Url"}
                                            name={"redirectionUrl"}
                                        />
                                        <div>
                                            <label htmlFor="testimonial" className='mb-2 dark_grey_color fn_Montserrat fw_500 fs_14'>Is Testimonial</label>
                                            <AdminRoleCheckBox name="testimonial" id="testimonial" />
                                        </div>
                                    </Form>
                                </Formik>
                            </div>
                            <DeleteModal
                                loader={loadingDelete}
                                handleClose={handleClose}
                                deleteFn={handleDelete}
                                data={deleteData}
                                show={show}
                            />
                        </LoadAndError>
                    )}
                </InnerContainer>
            </div>
        </InnerLayout>
    );
};

export default SocialEdit;



