import { call, takeLatest } from "redux-saga/effects";
import { APPROVE_FAQ_CATEGORY, CREATE_FAQ_CATEGORY, REJECT_FAQ_CATEGORY, VIEW_FAQ_CATEGORY, FAQ_CATEGORY_TABLE, EDIT_FAQ_CATEGORY, DELETE_FAQ_CATEGORY, FAQ_CATEGORY_APPROVAL_TABLE, FAQ_CATEGORY_APPROVAL_VIEW, FAQ_CATEGORY_VERSION_HISTORY_TABLE, FAQ_CATEGORY_VERSION_HISTORY, FAQ_CATEGORY_OPTIONS, FAQ_CATEGORY_EXPORT, FAQ_CATEGORY_APPROVALS_EXPORT, FAQ_CATEGORY_VERSION_EXPORT, FAQ_CATEGORY_SUBMIT } from "../actions/types";
import { approveFaqCatgoryApi, createFaqCatgoryApi, rejectFaqCatgoryApi, viewFaqCatgoryDetailApi, viewFaqCatgoryListApi, editFaqCatgoryApi, deleteFaqCatgoryApi, faqCatgoryApprovalListApi, faqCatgoryApprovalByIdApi, versionHistoryFaqCatgoryListApi, versionHistoryFaqCatgoryDetailApi, faqCatgoryOptionsApi, faqcategoryTableExportApi, faqcategoryApprovalsExportApi, faqcategoryVersionExportApi, faqcategorySubmitApi } from "../apis/faqCategoryApis";

export function* createFaqWorker(action) {
    try {
        const res = yield call(createFaqCatgoryApi,
            action.data
        );
        console.log(res);

        if (res.status === 200 && res.data.status === "success") {
            yield action.onSuccess(res.data);
        } else {
            yield action.onError(res.data);
        }
    } catch (error) { }
}

export function* approveFaqWorker(action) {
    try {
        const res = yield call(approveFaqCatgoryApi, {
            user_Id: action.userID,
        });
        console.log(res);
        if (res.status === 200 && res.data.status === "success") {
            yield action.onSuccess(res.data);
        } else {
            yield action.onError(res.data);
        }
    } catch (error) { }
}

export function* rejectFaqWorker(action) {
    try {
        const res = yield call(rejectFaqCatgoryApi, action.userID);
        console.log(res);
        if (res.status === 200 && res.data.status === "success") {
            yield action.onSuccess(res.data);
        } else {
            yield action.onError(res.data);
        }
    } catch (error) { }
}


export function* viewFaqDetailWorker(action) {
    try {
        const res = yield call(viewFaqCatgoryDetailApi, action.userID);
        console.log(res);
        if (res.status === 200 && res.data.status === "success") {
            yield action.onSuccess(res.data);
        } else {
            yield action.onError(res.data);
        }
    } catch (error) { }
}


export function* viewFaqListWorker(action) {
    try {
        const res = yield call(viewFaqCatgoryListApi,
            action.page_no,
            action.data,
        );
        console.log(res);
        if (res.status === 200 && res.data.status === "success") {
            yield action.onSuccess(res.data.data);
            console.log(res.data.data);
        } else {
            yield action.onError(res.data);
        }
    } catch (error) { }
}

export function* editFaqWorker(action) {
    try {
        const res = yield call(editFaqCatgoryApi, action.userID,
            action.data
        );
        console.log(res);
        if (res.status === 200 && res.data.status === "success") {
            yield action.onSuccess(res.data);
        } else {
            yield action.onError(res.data);
        }
    } catch (error) { }
}


export function* deleteFaqWorker(action) {
    try {
        const res = yield call(deleteFaqCatgoryApi, action.userID);
        console.log(res);
        if (res.status === 200 && res.data.status === "success") {
            yield action.onSuccess(res.data);
        } else {
            yield action.onError(res.data);
        }
    } catch (error) { }
}




export function* faqApprovalListWorker(action) {

    try {
        const res = yield call(faqCatgoryApprovalListApi,
            action.page_no,
            action.data,
        );
        if (res.status === 200 && res.data.status === "success") {
            yield action.onSuccess(res.data.data);
        } else {
            yield action.onError(res.data);
        }
    } catch (error) { }
}



export function* faqApprovalByIdWorker(action) {
    try {
        const res = yield call(faqCatgoryApprovalByIdApi, action.userID);
        console.log(res);
        if (res.status === 200 && res.data.status === "success") {
            yield action.onSuccess(res.data);
        } else {
            yield action.onError(res.data);
        }
    } catch (error) { }
}

export function* versionHistoryFaqListWorker(action) {
    try {
        const res = yield call(versionHistoryFaqCatgoryListApi, action.pageNo, action.data);
        console.log(res);
        if (res.status === 200 && res.data.status === "success") {
            yield action.onSuccess(res.data);
            console.log(res.data);
        } else {
            yield action.onError(res.data);
        }
    } catch (error) { }
}

export function* versionHistoryFaqDetailWorker(action) {
    try {
        const res = yield call(versionHistoryFaqCatgoryDetailApi,
            action.id,
        );
        console.log(res);
        if (res.status === 200 && res.data.status === "success") {
            yield action.onSuccess(res.data);
        } else {
            yield action.onError(res.data);
        }
    } catch (error) { }
}

export function* faqOptionsWorker(action) {
    try {
        const res = yield call(faqCatgoryOptionsApi, {});
        console.log(res);
        if (res.status === 200 && res.data.status === "success") {
            yield action.onSuccess(res.data);
        } else {
            yield action.onError(res.data);
        }
    } catch (error) { }
}



/******WATCHERS******/


export function* faqcategoryTableExportWorker(action) {
    try {
        const res = yield call(faqcategoryTableExportApi,

        );
        if (res.status === 200 && res.data.status === "success") {
            yield action.onSuccess(res.data);
        } else {
            yield action.onError(res.data);
        }
    } catch (error) { }
}


export function* faqcategoryApprovalsExportWorker(action) {
    try {
        const res = yield call(faqcategoryApprovalsExportApi);
        console.log(res);
        if (res.status === 200 && res.data.status === "success") {
            console.log(res.data);
            yield action.onSuccess(res.data.data);
        } else {
            yield action.onError(res.data);
        }
    } catch (error) { }
}

export function* faqcategoryVersionExportWorker(action) {
    try {
        const res = yield call(faqcategoryVersionExportApi);
        console.log(res);
        if (res.status === 200 && res.data.status === "success") {
            yield action.onSuccess(res.data);
        } else {
            yield action.onError(res.data);
        }
    } catch (error) { }
}


export function* faqcategorySubmitWorker(action) {
    try {
        const res = yield call(faqcategorySubmitApi, action.id);
        console.log(res);
        if (res.status === 200 && res.data.status === "success") {
            yield action.onSuccess(res.data);
        } else {
            yield action.onError(res.data);
        }
    } catch (error) { }
}



export function* createFaqCategoryWorkerWatcher() {
    yield takeLatest(CREATE_FAQ_CATEGORY, createFaqWorker);
}

export function* approveFaqCategoryWorkerWatcher() {
    yield takeLatest(APPROVE_FAQ_CATEGORY, approveFaqWorker);
}
export function* rejectFaqCategoryCaWorkerWatcher() {
    yield takeLatest(REJECT_FAQ_CATEGORY, rejectFaqWorker);
}
export function* viewFaqCategoryDetailWatcherWorker() {
    yield takeLatest(VIEW_FAQ_CATEGORY, viewFaqDetailWorker);
}
export function* viewFaqCategoryListWorkerWatcher() {
    yield takeLatest(FAQ_CATEGORY_TABLE, viewFaqListWorker);
}
export function* editFaqCategoryWorkerWatcher() {
    yield takeLatest(EDIT_FAQ_CATEGORY, editFaqWorker);

}
export function* deleteFaqCategoryWorkerWatcher() {
    yield takeLatest(DELETE_FAQ_CATEGORY, deleteFaqWorker);
}

export function* faqCategoryApprovalListWatcherWorker() {
    yield takeLatest(FAQ_CATEGORY_APPROVAL_TABLE, faqApprovalListWorker);
}

export function* faqCategoryApprovalByIdWatcher() {
    yield takeLatest(FAQ_CATEGORY_APPROVAL_VIEW, faqApprovalByIdWorker);
}



export function* versionHistoryFaqCategoryListWatcher() {
    yield takeLatest(FAQ_CATEGORY_VERSION_HISTORY_TABLE, versionHistoryFaqListWorker);
}
export function* versionHistoryFaqCategoryDetailWatcher() {
    yield takeLatest(
        FAQ_CATEGORY_VERSION_HISTORY,
        versionHistoryFaqDetailWorker
    );
}

export function* faqCategoryOptionsWorkerWatcher() {
    yield takeLatest(FAQ_CATEGORY_OPTIONS, faqOptionsWorker);
}

/****exports*****/

export function* faqcategoryTableExportWatcher() {
    yield takeLatest(
        FAQ_CATEGORY_EXPORT,
        faqcategoryTableExportWorker
    );
}
export function* faqcategoryApprovalsExportWatcher() {
    yield takeLatest(FAQ_CATEGORY_APPROVALS_EXPORT, faqcategoryApprovalsExportWorker);
}

export function* faqcategoryVersionExportWorkerWatcher() {
    yield takeLatest(FAQ_CATEGORY_VERSION_EXPORT, faqcategoryVersionExportWorker);
}

export function* faqcategorySubmitWorkerWatcher() {
    yield takeLatest(FAQ_CATEGORY_SUBMIT, faqcategorySubmitWorker);
}