import { CREATE_MEMBER_TRADE_BUY_IN_OPTIONS, CREATE_MemberTradeBuyIn_OPTIONS, MEMBER_TRADE_BUY_IN_APPROVALS_LIST, MEMBER_TRADE_BUY_IN_APPROVALS_VIEW, MEMBER_TRADE_BUY_IN_APPROVE, MEMBER_TRADE_BUY_IN_CREATE, MEMBER_TRADE_BUY_IN_DELETE, MEMBER_TRADE_BUY_IN_EDIT, MEMBER_TRADE_BUY_IN_LIST, MEMBER_TRADE_BUY_IN_READ, MEMBER_TRADE_BUY_IN_REJECT, MEMBER_TRADE_BUY_IN_VERSION_LIST, MEMBER_TRADE_BUY_IN_VERSION_VIEW, MEMBER_TRADE_BUY_IN_EXPORT, MEMBER_TRADE_BUY_IN_APPROVALS_EXPORT, MEMBER_TRADE_BUY_IN_VERSION_EXPORT, MEMBER_TRADE_BUY_IN_SUBMIT } from "./types";

export const viewAllMemberTradeBuyIn = (data, pageNo, onSuccess, onError) => {
    return {
        type: MEMBER_TRADE_BUY_IN_LIST,
        data,
        pageNo,
        onSuccess,
        onError,
    };
};
export const readApprovalMemberTradeBuyIn = (id, onSuccess, onError) => {
    return {
        type: MEMBER_TRADE_BUY_IN_APPROVALS_VIEW,
        id,
        onSuccess,
        onError,
    };
}
export const readMemberTradeBuyIn = (id, onSuccess, onError) => {
    return {
        type: MEMBER_TRADE_BUY_IN_READ,
        id,
        onSuccess,
        onError,
    };
}
export const versionTableMemberTradeBuyIn = (data, pageNo, onSuccess, onError) => {
    return {
        type: MEMBER_TRADE_BUY_IN_VERSION_LIST,
        data,
        pageNo,
        onSuccess,
        onError,
    };
}
export const deleteMemberTradeBuyIn = (id, onSuccess, onError) => {
    return {
        type: MEMBER_TRADE_BUY_IN_DELETE,
        id,
        onSuccess,
        onError,
    };
}
export const memberTradeBuyInVersionHistoryview = (id, onSuccess, onError) => {
    return {
        type: MEMBER_TRADE_BUY_IN_VERSION_VIEW,
        id,
        onSuccess,
        onError,
    };
}
export const memberTradeBuyInApprovalsTable = (data, pageNo = 0, onSuccess, onError) => {
    return {
        type: MEMBER_TRADE_BUY_IN_APPROVALS_LIST,
        data,
        pageNo,
        onSuccess,
        onError,
    };
}


export const memberTradeBuyInCreate = (
    data,
    onSuccess,
    onError
) => {
    return {
        type: MEMBER_TRADE_BUY_IN_CREATE,
        data,
        onSuccess,
        onError,
    };
};

export const memberTradeBuyInCreateOptions = (onSuccess, onError) => {
    return {
        type: CREATE_MEMBER_TRADE_BUY_IN_OPTIONS,
        onSuccess,
        onError,
    };
};
export const editMemberTradeBuyIn = (id, data, onSuccess, onError) => {
    return {
        type: MEMBER_TRADE_BUY_IN_EDIT,
        id,
        data,
        onSuccess,
        onError,
    }
};                     

export const approveMemberTradeBuyIn = (id, onSuccess, onError) => {
    return {
        type: MEMBER_TRADE_BUY_IN_APPROVE,
        id,
        onSuccess,
        onError,
    };
}

export const rejectMemberTradeBuyIn = (id, onSuccess, onError) => {
    return {
        type: MEMBER_TRADE_BUY_IN_REJECT,
        id,
        onSuccess,
        onError,
    };
}


export const memberTradeBuyInTableExport = (onSuccess, onError) => {
    return {
        type: MEMBER_TRADE_BUY_IN_EXPORT,
        onSuccess,
        onError,
    };
};
export const memberTradeBuyInApprovalsExport = (onSuccess, onError) => {
    return {
        type: MEMBER_TRADE_BUY_IN_APPROVALS_EXPORT,
        onSuccess,
        onError,
    };
};
export const memberTradeBuyInVersionExport = (onSuccess, onError) => {
    return {
        type: MEMBER_TRADE_BUY_IN_VERSION_EXPORT,
        onSuccess,
        onError,
    };
};  



export const memberTradeBuyInSubmitAction = (id, onSuccess, onError) => {
    return {
        type: MEMBER_TRADE_BUY_IN_SUBMIT,
        id,
        onSuccess,
        onError,
    };
};  