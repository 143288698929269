import { authHeader } from "../config";
import { api } from "./api";

export const addOpporTypeApi = (params) => {
    return api.post("/api/opportunity/create-opportunity", JSON.stringify(params), {
        headers: {
            "x-access-token": localStorage.getItem("accessToken"),
            "Content-Type": "application/json",
        },
    });
};
export const editOpporTypeApi = (id, params) => {
    return api.post(`/api/opportunity/edit-opportunity/${id}`, JSON.stringify(params), {
        headers: {
            "x-access-token": localStorage.getItem("accessToken"),
            "Content-Type": "application/json",
        },
    });
};
export const deleteOpporTypeApi = (id, params) => {
    return api.post(`/api/opportunity/delete-opportunity/${id}`, JSON.stringify(params), {
        headers: {
            "x-access-token": localStorage.getItem("accessToken"),
            "Content-Type": "application/json",
        },
    });
};

export const viewAllOpporTypeApi = (pageNo, params) => {
    return api.post(`/api/opportunity/view-opportunities?page_no=${pageNo}`, JSON.stringify(params), {
        headers: {
            "x-access-token": localStorage.getItem("accessToken"),
            "Content-Type": "application/json",
        },
        headers: {
            "x-access-token": localStorage.getItem("accessToken"),
            "Content-Type": "application/json",
        },
    });
};


export const viewOpporTypeApi = (id = "", params) => {
    return api.post(`/api/opportunity/view-opportunities/${id}`, JSON.stringify(params), {
        headers: {
            "x-access-token": localStorage.getItem("accessToken"),
            "Content-Type": "application/json",
        },
    });
};
export const rejectOpporTypeApi = (id = "", params) => {
    return api.post(`/api/opportunity/reject-opportunity/${id}`, JSON.stringify(params), {
        headers: {
            "x-access-token": localStorage.getItem("accessToken"),
            "Content-Type": "application/json",
        },
    });
};
export const approveOpporTypeApi = (params) => {
    return api.post(`/api/opportunity/approve-opportunity`, JSON.stringify(params), {
        headers: {
            "x-access-token": localStorage.getItem("accessToken"),
            "Content-Type": "application/json",
        },
    });
};

export const approvalsListOpporTypeApi = (pageNo, params) => {
    return api.post(`/api/opportunity/opportunity-approvals?page_no=${pageNo}`, JSON.stringify(params), {
        headers: {
            "x-access-token": localStorage.getItem("accessToken"),
            "Content-Type": "application/json",
        },
        headers: {
            "x-access-token": localStorage.getItem("accessToken"),
            "Content-Type": "application/json",
        },
    });
};
export const viewApprovalOpporTypeApi = (id, params) => {
    return api.post(`/api/opportunity/opportunity-approval-id/${id}`, JSON.stringify(params), {
        headers: {
            "x-access-token": localStorage.getItem("accessToken"),
            "Content-Type": "application/json",
        },
    });
};

export const versionHistoryListOpporTypeApi = (pageNo = 0, params) => {
    return api.post(`/api/opportunity/view-version-history?page_no=${pageNo}`, JSON.stringify(params), {
        headers: {
            "x-access-token": localStorage.getItem("accessToken"),
            "Content-Type": "application/json",
        },
    });
};
export const viewVersionHistoryOpporTypeApi = (id, params) => {
    return api.post(`/api/opportunity/version-history/${id}`, JSON.stringify(params), {
        headers: {
            "x-access-token": localStorage.getItem("accessToken"),
            "Content-Type": "application/json",
        },
    });
};



export const opporTypeTableExportApi = (params) => {
    return api.post(
        `/api/opportunity/export-main-opportunity`,
        JSON.stringify(params),
        {
            headers: {
                "x-access-token": localStorage.getItem("accessToken"),
                "Content-Type": "application/json",
            },
        }
    );
};
export const opporTypeApprovalsExportApi = (params) => {
    return api.post(
        `/api/opportunity/export-approval-opportunity`,
        JSON.stringify(params),
        {
            headers: {
                "x-access-token": localStorage.getItem("accessToken"),
                "Content-Type": "application/json",
            },
        }
    );
};
export const opporTypeVersionExportApi = (params) => {
    return api.post(
        `/api/opportunity/export-version-opportunity`,
        JSON.stringify(params),
        {
            headers: {
                "x-access-token": localStorage.getItem("accessToken"),
                "Content-Type": "application/json",
            },
        }
    );
};





export const opporTypeSubmitApi = (id, params) => {
    return api.post(
        `/api/opportunity/on-submit/${id}`, JSON.stringify(params),
        {
            headers: {
                "x-access-token": localStorage.getItem("accessToken"),
                "Content-Type": "application/json",
            },
        }
    );
};
