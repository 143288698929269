import { act } from "react-dom/test-utils";
import { call, takeLatest } from "redux-saga/effects";
import { APPROVE_EXPRESS_INTEREST, CREATE_EXPRESS_INTEREST, DELETE_EXPRESS_INTEREST, EDIT_EXPRESS_INTEREST, EXPRESS_APPROVALS_EXPORT, EXPRESS_EXPORT, EXPRESS_INTEREST_APPROVAL_TABLE, EXPRESS_INTEREST_APPROVAL_VIEW, EXPRESS_INTEREST_OPTIONS, EXPRESS_INTEREST_TABLE, EXPRESS_INTEREST_VERSION_HISTORY, EXPRESS_INTEREST_VERSION_HISTORY_TABLE, EXPRESS_SUBMIT, EXPRESS_VERSION_EXPORT, REJECT_EXPRESS_INTEREST, VIEW_EXPRESS_INTEREST } from "../actions/types";
import { approveExpressApi, createExpressApi, deleteExpressApi, editExpressApi, expressApprovalByIdApi, ExpressApprovalByIdApi, ExpressApprovalListApi, expressApprovalsExportApi, ExpressOptionsApi, expressSubmitApi, expressTableExportApi, expressVersionExportApi, rejectExpressApi, versionHistoryExpressDetailApi, versionHistoryExpressListApi, viewExpressDetailApi, viewExpressListApi } from "../apis/expessApi";

export function* createExpressWorker(action) {
    try {
        const res = yield call(createExpressApi,
            action.data
        );
        if (res.status === 200 && res.data.status === "success") {
            yield action.onSuccess(res.data);
            console.log(res.data)
        } else {
            yield action.onError(res.data);
        }
    } catch (error) { }
}

export function* approveExpressWorker(action) {
    try {
        const res = yield call(approveExpressApi, {
            express_intrest_id: action.id
        });
        console.log(res);
        if (res.status === 200 && res.data.status === "success") {
            console.log(res.data);
            yield action.onSuccess(res.data);
        } else {
            yield action.onError(res.data);
        }
    } catch (error) { }
}

export function* rejectExpressWorker(action) {
    try {
        const res = yield call(rejectExpressApi, action.id);
        console.log(res);
        if (res.status === 200 && res.data.status === "success") {
            console.log(res.data);
            yield action.onSuccess(res.data);
        } else {
            yield action.onError(res.data);
        }
    } catch (error) { }
}


export function* viewExpressDetailWorker(action) {
    try {
        const res = yield call(viewExpressDetailApi, action.id);
        console.log(res);
        if (res.status === 200 && res.data.status === "success") {
            yield action.onSuccess(res.data);
        } else {
            yield action.onError(res.data);
        }
    } catch (error) { }
}


export function* viewExpressListWorker(action) {
    try {
        const res = yield call(viewExpressListApi,
            action.page_no,
            action.data,
        );
        if (res.status === 200 && res.data.status === "success") {
            yield action.onSuccess(res.data.data);
            console.log(res.data);
        } else {
            yield action.onError(res.data);
        }
    } catch (error) { }
}

export function* editExpressWorker(action) {
    try {
        const res = yield call(editExpressApi, action.id, action.data);
        console.log(res);
        if (res.status === 200 && res.data.status === "success") {
            yield action.onSuccess(res.data);
            console.log(res.data);
        } else {
            yield action.onError(res.data);
        }
    } catch (error) { }
}


export function* deleteExpressWorker(action) {
    try {
        const res = yield call(deleteExpressApi, action.id);
        console.log(res);
        if (res.status === 200 && res.data.status === "success") {
            yield action.onSuccess(res.data);
            console.log(res.data);
        } else {
            yield action.onError(res.data);
        }
    } catch (error) { }
}




export function* ExpressApprovalListWorker(action) {
    try {
        const res = yield call(ExpressApprovalListApi,
            action.page_no,
            action.data,
        );
        if (res.status === 200 && res.data.status === "success") {
            yield action.onSuccess(res.data.data);
            console.log(res.data);
        } else {
            yield action.onError(res.data);
        }
    } catch (error) { }
}



export function* expressApprovalByIdWorker(action) {
    try {
        const res = yield call(expressApprovalByIdApi, action.id);
        console.log(res);
        if (res.status === 200 && res.data.status === "success") {
            console.log(res.data)
            yield action.onSuccess(res.data);
        } else {
            yield action.onError(res.data);
        }
    } catch (error) { }
}



export function* versionHistoryExpressListWorker(action) {
    try {
        const res = yield call(versionHistoryExpressListApi,
            action.page,
            action.data,
        );
        console.log(res);
        if (res.status === 200 && res.data.status === "success") {
            yield action.onSuccess(res.data);
        } else {
            yield action.onError(res.data);
        }
    } catch (error) { }
}

export function* versionHistoryExpressDetailWorker(action) {
    try {
        const res = yield call(versionHistoryExpressDetailApi,
            action.id,
        );
        console.log(res);
        if (res.status === 200 && res.data.status === "success") {
            yield action.onSuccess(res.data);
        } else {
            yield action.onError(res.data);
        }
    } catch (error) { }
}

export function* ExpressOptionsWorker(action) {
    try {
        const res = yield call(ExpressOptionsApi, {});
        console.log(res);
        if (res.status === 200 && res.data.status === "success") {
            yield action.onSuccess(res.data);
        } else {
            yield action.onError(res.data);
        }
    } catch (error) { }
}
export function* expressSubmitWorker(action) {
    try {
        const res = yield call(expressSubmitApi, action.id);
        console.log(res);
        if (res.status === 200 && res.data.status === "success") {
            yield action.onSuccess(res.data);
        } else {
            yield action.onError(res.data);
        }
    } catch (error) { }
}


//export
export function* expressTableExportWorker(action) {
    try {
        const res = yield call(expressTableExportApi,

        );
        if (res.status === 200 && res.data.status === "success") {
            yield action.onSuccess(res.data);
        } else {
            yield action.onError(res.data);
        }
    } catch (error) { }
}


export function* expressApprovalsExportWorker(action) {
    try {
        const res = yield call(expressApprovalsExportApi);
        console.log(res);
        if (res.status === 200 && res.data.status === "success") {
            console.log(res.data);
            yield action.onSuccess(res.data.data);
        } else {
            yield action.onError(res.data);
        }
    } catch (error) { }
}

export function* expressVersionExportWorker(action) {
    try {
        const res = yield call(expressVersionExportApi);
        console.log(res);
        if (res.status === 200 && res.data.status === "success") {
            yield action.onSuccess(res.data);
        } else {
            yield action.onError(res.data);
        }
    } catch (error) { }
}





export function* createExpressWorkerWatcher() {
    yield takeLatest(CREATE_EXPRESS_INTEREST, createExpressWorker);
}

export function* approveExpressWorkerWatcher() {
    yield takeLatest(APPROVE_EXPRESS_INTEREST, approveExpressWorker);
}
export function* rejectExpressWorkerWatcher() {
    yield takeLatest(REJECT_EXPRESS_INTEREST, rejectExpressWorker);
}
export function* viewExpressDetailWatcherWorker() {
    yield takeLatest(VIEW_EXPRESS_INTEREST, viewExpressDetailWorker);
}
export function* viewExpressListWorkerWatcher() {
    yield takeLatest(EXPRESS_INTEREST_TABLE, viewExpressListWorker);
}
export function* editExpressWorkerWatcher() {
    yield takeLatest(EDIT_EXPRESS_INTEREST, editExpressWorker);

}
export function* deleteExpressWorkerWatcher() {
    yield takeLatest(DELETE_EXPRESS_INTEREST, deleteExpressWorker);
}

export function* ExpressApprovalListWatcherWorker() {
    yield takeLatest(EXPRESS_INTEREST_APPROVAL_TABLE, ExpressApprovalListWorker);
}

export function* expressApprovalByIdWatcher() {
    yield takeLatest(EXPRESS_INTEREST_APPROVAL_VIEW, expressApprovalByIdWorker);
}

export function* versionHistoryExpressListWatcher() {
    yield takeLatest(EXPRESS_INTEREST_VERSION_HISTORY_TABLE, versionHistoryExpressListWorker);
}
export function* versionHistoryExpressDetailWatcher() {
    yield takeLatest(
        EXPRESS_INTEREST_VERSION_HISTORY,
        versionHistoryExpressDetailWorker
    );
}

export function* ExpressOptionsWorkerWatcher() {
    yield takeLatest(EXPRESS_INTEREST_OPTIONS, ExpressOptionsWorker);
}
export function* expressSubmitWorkerWatcher() {
    yield takeLatest(EXPRESS_SUBMIT, expressSubmitWorker);
}


//export
export function* expressTableExportWatcher() {
    yield takeLatest(
        EXPRESS_EXPORT,
        expressTableExportWorker
    );
}
export function* expressApprovalsExportWatcher() {
    yield takeLatest(EXPRESS_APPROVALS_EXPORT, expressApprovalsExportWorker);
}

export function* expressVersionExportWorkerWatcher() {
    yield takeLatest(EXPRESS_VERSION_EXPORT, expressVersionExportWorker);
}
