

import React from "react";

const RejectIcon = ({ fill = "#d20000", width = 10, height = 16 }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 18 18">
      <path id="Icon_material-block" data-name="Icon material-block" d="M12,3a9,9,0,1,0,9,9A9,9,0,0,0,12,3ZM4.8,12A7.2,7.2,0,0,1,12,4.8a7.112,7.112,0,0,1,4.41,1.521L6.321,16.41A7.112,7.112,0,0,1,4.8,12ZM12,19.2a7.112,7.112,0,0,1-4.41-1.521L17.679,7.59A7.112,7.112,0,0,1,19.2,12,7.2,7.2,0,0,1,12,19.2Z" transform="translate(-3 -3)" fill={fill} />
    </svg>


  );
};

export default RejectIcon;
