import axios from "axios";
import queryString from "query-string";
import { ADMIN_LOGOUT } from "../actions/types";
import { url } from "../config";
import store from "../store";

export const api = axios.create({
  baseURL: url,
  timeout: 60000,
});
api.interceptors.response.use(
  async (config) => {
    if (config.data.message == "Authentication failed" || config.data.message == "Authentication Failed")
      store.dispatch({
        type: ADMIN_LOGOUT,
        onSuccess: () => { },
        onError: () => { },
      });
    // {
    //   localStorage.clear();
    //   window.location.reload();
    // }
    return config;
  },
  async (error) => { }
);
