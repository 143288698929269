import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import ArrowRightIcon from '../../../../assets/icons/arrowrightIcon'
import ApproveBtn from '../../../../components/buttons/approveBtn'
import RejectBtn from '../../../../components/buttons/rejectBtn'
import InnerContainer from '../../../../components/layouts/innerContainer/innerContainer'
import InnerLayout from '../../../../components/layouts/innerLayout/innerLayout'
import errorToast from '../../../../components/utilits/errorToast';
import successToast from '../../../../components/utilits/successToast';
import { versionViewLLPAction } from '../../../../redux/actions/llpActions';
import moment from "moment";
import LoadingSpinnerTable from '../../../../components/utilits/loadingSpinnerTable';
import LoadAndError from '../../../../components/utilits/LoadAndError';

const Llpversioinhistoryview = () => {
    const params = useParams();
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(false)
    // Notify me veersion history view 
    const [llpVersionView, setLlpVersionView] = useState({
        id: "",
        createdAt: "",
        updatedAt: "",
        new_data: { updated_by: "", updated_At: "" },
    });
    const dispatch = useDispatch();
    useEffect(() => {
        setLoading(true);
        dispatch(
            versionViewLLPAction(
                params.id,
                onSuccess,
                onError
            )
        );
    }, [params.id]);
    const onSuccess = (data) => {
        setLlpVersionView(data?.data?.data);
        setLoading(false);
        setError(false)
    };
    console.log(llpVersionView);

    const onError = (data) => {
        errorToast(data.message);
        setLoading(false);
        setError(true)
    };

    const data = [
        {
            heading: "LLP ID",
            title: llpVersionView?.id
        },
        {
            heading: "Created At",
            title: llpVersionView?.createdAt
        },
        {
            heading: "Updated At",
            title: llpVersionView?.updatedAt
        },
        {
            heading: "Updated By",
            title: llpVersionView?.updated_by
        }
    ]

    const newdata = [
      
        {
            heading: "Llp Name",
            title: llpVersionView?.new_data?.name
        },
        {
            heading: "Register Number",
            title: llpVersionView?.new_data?.reg_no
        },
        {
            heading: "Opportunity Id",
            title: llpVersionView?.new_data?.opportunity_id
        },
        {
            heading: "Opportunity Name",
            title: llpVersionView?.new_data?.title
        },
    ]
    const olddata = [
        {
            heading: "Llp Name",
            title: llpVersionView?.old_data?.name
        },
        {
            heading: "Register Number",
            title: llpVersionView?.old_data?.reg_no
        },
        {
            heading: "Opportunity Id",
            title: llpVersionView?.old_data?.opportunity_id
        },
        {
            heading: "Opportunity Name",
            title: llpVersionView?.old_data?.title
        },
    ]
    return (
        <InnerLayout >
            <div className="notify-me-container ">
                <InnerContainer>
                    {loading ?
                        (
                            <LoadingSpinnerTable />
                        ) : (
                            <LoadAndError loader={loading} error={error}>
                                <div className='pt-3 ps-5 row'>
                                    {
                                        data.map((item, index) => (
                                            <div className='mb-2  col-sm-4 col-md-3  col-xl-2 col-lg-3'>
                                                <h5 className='dark_grey_color  fs_13 fn_Montserrat fw_500 m-0'>{item.heading}</h5>
                                                <p className={` fs_13 fn_Nunito  fw_500 ${item.heading === "Notify Me ID" ? 'secondary_color' : 'dark_title_color'}`}>{item.title}</p>
                                            </div>
                                         ))
                                    }
                                </div>
                                <div className='white_shade_bg  py-1 d-flex'>
                                    <div className='w-50'>
                                        <h5 className='fw_500 ps-4 dark_grey_color pt-2 text-nowrap fs_16 fn_Montserrat'>JSON Old Data</h5>
                                    </div>
                                    <div className='w-50  d-none d-lg-block'>
                                        <h5 className='fw_500 ps-4  dark_grey_color pt-2 text-nowrap fs_16 fn_Montserrat'>JSON New Data</h5>
                                    </div>
                                </div>
                                <div className='row mt-3 ps-0 ps-lg-4'>
                                    <div className='col-12 col-lg-6'>
                                        <div className='notify_me_aprvls_vrsn_hstry_data_container  mt-3 pt-3 px-3'>
                                            <div className='d-flex justify-content-between'>
                                                <h5 className='fs_13 fn_Nunito fw_400 dark_title_color text-nowrap'>Old Data</h5>
                                                <ArrowRightIcon width={25} height={25} fill={"#A5A5A5"} />
                                            </div>
                                            {
                                                llpVersionView?.old_data === null ? (
                                                    <div>Empty data</div>
                                                ) : (<>
                                                    {
                                                        olddata.map((item, index) => {
                                                            return (
                                                                <div className='d-flex flex-column'>
                                                                    <h5 className='dark_grey_color  fs_13 fn_Montserrat fw_500 m-0'>{item.heading}</h5>
                                                                    <p className={` fs_13 fn_Nunito  fw_500 ${item.heading === "Notify Me ID" ? 'secondary_color' : 'dark_title_color'}`}>{item.title}</p>
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </>)
                                            }
                                        </div>
                                    </div>
                                    <div className='col-12 mt-5 mt-lg-0 col-lg-6'>
                                        <h5 className='fw_500 ps-4 d-block d-lg-none white_shade_bg  dark_grey_color py-3 text-nowrap fs_16 fn_Montserrat'>JSON New Data</h5>
                                        <div className='notify_me_aprvls_vrsn_hstry_data_container    mt-4 mt-lg-3 pt-3 px-3'>
                                            <div className='d-flex justify-content-between'>
                                                <h5 className='fs_13 fn_Nunito fw_400 dark_title_color py-2 text-nowrap'>New Data</h5>
                                                <ArrowRightIcon width={25} height={25} fill={"#A5A5A5"} />
                                            </div>
                                            {
                                                llpVersionView?.new_data === null ? (
                                                    <div>Empty data</div>
                                                ) : (<>
                                                    {
                                                        newdata.map((item, index) => {
                                                            return (
                                                                <div className='d-flex flex-column'>
                                                                    <h5 className='dark_grey_color  fs_13 fn_Montserrat fw_500 m-0'>{item.heading}</h5>
                                                                    <p className={` fs_13 fn_Nunito  fw_500 ${item.heading === "Notify Me ID" ? 'secondary_color' : 'dark_title_color'}`}>{item.title}</p>
                                                                </div>
                                                            )
                                                        })

                                                    }
                                                </>)}
                                        </div>
                                    </div>
                                </div>
                            </LoadAndError>
                        )}
                </InnerContainer>
            </div>
        </InnerLayout>
    )
}

export default Llpversioinhistoryview