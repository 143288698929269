import React, { useState, useEffect } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "./opportunitiesEditContent.css";
import CloseIcon from "../../../../assets/icons/closeIcon";

import { useDispatch, useSelector } from "react-redux";
import * as opportunityEditorActions from "../../../../redux/actions/opportunityEditorActions";
import * as defaultSectionValues from "../../../../redux/constants/opportunityJsonState";
import {
  uploadAddressProofFrontImageAction,
  uploadProfileImageAction,
} from "../../../../redux/actions/userAction";

const OpportunitiesContectEditFile = ({
  id,
  deleteTheTextSection,
  sectionIndex,
  subSectionIndex,
  index,
}) => {
  const [value, setValue] = useState("");
  const [fileName, setFileName] = useState("");
  const [error, setError] = useState("");
  const [fileUrl, setFileUrl] = useState("");
  const [file, setFile] = useState("");
  const dispactch = useDispatch();

  const opportunityJsonReducer = useSelector(
    (state) => state.opportunityJsonReducer
  );

  const [data, setData] = useState(
    opportunityJsonReducer.data[sectionIndex]?.subsections[subSectionIndex]
      ?.content[index]
  );

  useEffect(() => {
    var newData =
      opportunityJsonReducer?.data[sectionIndex]?.subsections[subSectionIndex]
        ?.content[index];
    setData(newData);
    setFileUrl(newData.fileUrl);
    setValue(newData.description);
    setFileName(newData.fileName);
  }, []);
  useEffect(() => {
    updateReducer();
  }, [data]);

  const updateReducer = () => {
    // stateDate.data.
    dispactch(
      opportunityEditorActions.updateContentSection(
        index,
        subSectionIndex,
        sectionIndex,
        data
      )
    );
  };

  const onDescriptionUpdate = (text) => {
    setValue(text);
    setData({
      ...data,
      description: text,
    });
  };

  const handleChange = (content, delta, source, editor) => {
    setValue(content);
    onDescriptionUpdate(content);
    console.log(
      "OpportunitiesContectEditText handleChange",
      content,
      editor.getContents()
    );
  };

  const onFileUpdate = (url) => {
    setFileUrl(url);
    setData({
      ...data,
      fileUrl: url,
    });
  };

  function handleUploadOverviewFile(e) {
    if (e.target?.files.length !== 0) {
      if (e.target.files[0].size / 1024 < 5120) {
        setError("");
        setFile(e.target.files[0]);
        dispactch(
          uploadAddressProofFrontImageAction(
            e.target.files[0],
            onUploadFileSuccess,
            onUploadFileError
          )
        );
      } else {
        setError("max file size is 5MB");
      }
    }
    e.target.value = null;
  }
  const onUploadFileSuccess = (data) => {
    onFileUpdate(data.data.data.location);
  };
  const onUploadFileError = (data) => {
    onFileUpdate("");
    setError("failed to upload file");
    //something went wrong
    console.log(data);
  };
  function handleInputChange(value) {
    setFileName(value);
    setData({
      ...data,
      fileName: value,
    });
  }

  return (
    <div key={id} className="mt-4 mb-1">
      <div className="w-100 d-flex justify-content-between ">
        <span></span>
        <button
          type="button"
          onClick={() => {
            deleteTheTextSection(index);
          }}
          className="light_red_color fs_13 fw_500 border-0 white_shade_bg"
        >
          Delete
        </button>
      </div>
      <div className="oppor_content_edit__borde p-2">
        <h6 className="mt-3">File Name</h6>
        <input
          value={fileName}
          onChange={(event) => {
            handleInputChange(event.target.value);
          }}
          id="fileName"
          type="text"
          className="oppor_content_edit__input col-xxl-4 col-lg-5 col-12 col-sm-8 rounded-3 "
        />

        <h6 className="mt-3">Select File to Link</h6>

        <input
          className="position-absolute"
          type={"file"}
          style={{ visibility: "hidden" }}
          id={`overviewFile${index}`}
          name="fileUrl"
          // value={fileUrl}
          onChange={(e) => {
            handleUploadOverviewFile(e);
          }}
        />
        <label className=" w-75 py-2 ps-3  " htmlFor={`overviewFile${index}`}>
          {<span>{fileUrl != "" ? fileUrl : "+ Select File to Link"}</span>}
        </label>
        {fileUrl != "" && (
          <span
            onClick={() => {
              onFileUpdate("");
              setFile("");
            }}
          >
            <CloseIcon width={10} height={10} fill="#A5A5A5" />
          </span>
        )}

        {error && <span className="text-danger d-block">{error}</span>}
        <h6 className="mt-2">FootNotes/Pointers</h6>
        {/* <div className="oppor_content_edit__border"> */}

        <ReactQuill onChange={handleChange} value={value} theme="snow" />
      </div>
    </div>
  );
};

export default OpportunitiesContectEditFile;
