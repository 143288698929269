import { authHeader } from "../config";
import { api } from "./api";

export const addPartnerApi = (params) => {
    return api.post("/api/homestatic/create-our-partner", params, {
        headers: {
            "x-access-token": localStorage.getItem("accessToken"),
            "Content-Type": "application/json",
        },
    });
};
export const viewParnterListApi = (pageNo, params) => {
    return api.post(`/api/homestatic/views-our-partners?page_no=${pageNo}`, params,
        {
            headers: {
                "x-access-token": localStorage.getItem("accessToken"),
                "Content-Type": "application/json",
            },
        }
    );
};
export const viewPartnerApi = (id, params) => {
    return api.post(`/api/homestatic/view-our-partner/${id}`, params,
        {
            headers: {
                "x-access-token": localStorage.getItem("accessToken"),
                "Content-Type": "application/json",
            },
        }
    );
};
export const editPartnerApi = (id, params) => {
    return api.post(`/api/homestatic/edit-our-partner/${id}`, params, {
        headers: {
            "x-access-token": localStorage.getItem("accessToken"),
            "Content-Type": "application/json",
        },
    });
};
export const deletePartnerApi = (id, params) => {
    return api.post(`/api/homestatic/delete-our-partner/${id}`, params, {
        headers: {
            "x-access-token": localStorage.getItem("accessToken"),
            "Content-Type": "application/json",
        },
    });
};
export const approvePartnerApi = (params) => {
    return api.post("/api/homestatic/approve-our-partner", params, {
        headers: {
            "x-access-token": localStorage.getItem("accessToken"),
            "Content-Type": "application/json",
        },
    });
}
export const rejectPartnerApi = (id, params) => {
    return api.post(`/api/homestatic/reject-our-partner/${id}`, params, {
        headers: {
            "x-access-token": localStorage.getItem("accessToken"),
            "Content-Type": "application/json",
        },
    });
};



export const approvalsPartnerListpApi = (page = 0, params) => {
    return api.post(`/api/homestatic/views-all-our-partner-checkkers?page_no=${page}`, params,
        {
            headers: {
                "x-access-token": localStorage.getItem("accessToken"),
                "Content-Type": "application/json",
            },
        }
    );
}
export const viewApprovalPartnerApi = (id, params) => {
    return api.post(`/api/homestatic/view-one-our-partner-checkker/${id}`, params, {
        headers: {
            "x-access-token": localStorage.getItem("accessToken"),
            "Content-Type": "application/json",
        },
    });
};
export const versionPartnerListpApi = (page, params) => {
    return api.post(`/api/homestatic/views-partners-versions?search=&page_no=${page}`, params, {
        headers: {
            "x-access-token": localStorage.getItem("accessToken"),
            "Content-Type": "application/json",
        },
    });
}
export const viewVersionPartnerApi = (id, params) => {
    return api.post(`/api/homestatic/view-our-partner-version/${id}`, params, {
        headers: {
            "x-access-token": localStorage.getItem("accessToken"),
            "Content-Type": "application/json",
        },
    }
    );
};

export const partnersTableExportApi = ( params) => {
    return api.post(
        `/api/homestatic/export-main-our-partner`,
        JSON.stringify(params),
        {
            headers: {
                "x-access-token": localStorage.getItem("accessToken"),
                "Content-Type": "application/json",
            },
        }
    );
};
export const partnersApprovalsExportApi = ( params) => {
    return api.post(
        `/api/homestatic/export-approval-our-partner`,
        JSON.stringify(params),
        {
            headers: {
                "x-access-token": localStorage.getItem("accessToken"),
                "Content-Type": "application/json",
            },
        }                   
    );
};
export const partnersVersionExportApi = ( params) => {
    return api.post(
        `/api/homestatic/export-version-our-partner`,
        JSON.stringify(params),
        {
            headers: {
                "x-access-token": localStorage.getItem("accessToken"),
                "Content-Type": "application/json",
            },
        }
    );
};

//on submit
export const partnersSubmitApi = (id, params) => {
    return api.post(`/api/homestatic/on-submito/${id}`, params, {
        headers: {
            "x-access-token": localStorage.getItem("accessToken"),
            "Content-Type": "application/json",
        },
    });
};
