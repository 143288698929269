import React, { useState } from 'react'
import { useDispatch } from 'react-redux';
import CreateBtn from '../../../../components/buttons/createBtn';
import ExportBtn from '../../../../components/buttons/exportBtn';
import FilterBtn from '../../../../components/buttons/filterBtn';
import VersionHistoryBtn from '../../../../components/buttons/versionHistoryBtn';
import NotifyApprovalFilter from '../../../../components/filter/opportunity-pagesFilter.js/notifyApprovalFilter';
import VersionTablesFilter from '../../../../components/filter/versionTableFilter';
import InnerLayout from '../../../../components/layouts/innerLayout/innerLayout';
import NotifyMeApprovalsTableContent from '../../../../components/opportunity/notifyMe/tables/notifyMeApprovalsTableContent';
import NotificationApprovalsTableContent from '../../../../components/static-pages/notification/tables/notificationApprovalsTableContent';
import SocialApprovalsTableContent from '../../../../components/static-pages/social/tables/socialApprovalsTableContent';
import { notificationApprovalsExport } from '../../../../redux/actions/notificationActions';
import { notifyApprovalsExport } from '../../../../redux/actions/notifyMeActions';
import { socialApprovalsExport } from '../../../../redux/actions/socialActions';

const SocialApprovalsTable = () => {
    const [page, setPage] = useState(0);
    const [showFilter, setShowFilter] = useState(false);
    const [search, setSearch] = useState({ value: "" });
    const dispatch = useDispatch();
    const handleFilterClose = () => {
        setShowFilter(false);
    };
    const handleFilterOpen = () => {
        setShowFilter(true);
    };
    const handleSearch = (value) => {
        setSearch({ value: value });
        setPage(0)
    };
    const handlePage = (type) => {
        if (type === "+") {
            setPage((prev) => prev + 1)
        }
        else {
            setPage((prev) => prev - 1)
        }
    }
    const buttonList = [
        <VersionHistoryBtn link={`/static-pages/social/version-history`} />,
        <CreateBtn link={`/static-pages/social/create`} />,
        <ExportBtn dispatchAction={socialApprovalsExport} />,
        <FilterBtn filterFn={handleFilterOpen} />,
    ];
    return (
        <InnerLayout buttons={buttonList}>
            <VersionTablesFilter
                title='Social Id'
                placeholder='Enter Social Id'
                filter={handleSearch}
                showFilter={showFilter}
                closeFilter={handleFilterClose}
            />
            <SocialApprovalsTableContent page={page} changePage={handlePage} search={search} />
        </InnerLayout>
    )
}
export default SocialApprovalsTable