import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { TableContainer } from "../../../table/tableContainer";
import TablePagination from "../../../table/tablePagination";
import { tableOpportunitiesHeader } from "../../../table/tableData";
import errorToast from "../../../utilits/errorToast";
import { useDispatch } from "react-redux";
import { fetchOpportunitiesApprovalList } from "../../../../redux/actions/opportunitiesActions";
import moment from "moment";
import LoadingSpinnerTable from "../../../utilits/loadingSpinnerTable";
import LoadAndError from "../../../utilits/LoadAndError";

const OpportunitiesApprovalTableContent = ({ page, search, changePage }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [opportunitiesData, setOpportunitiesData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [pageData, setPageData] = useState({ noOfItems: 0, noOfPages: 1 });
  useEffect(() => {
    setLoading(true);
    dispatch(
      fetchOpportunitiesApprovalList(
        {
          search: {
            opportunities_id: search.opportunityId,
            opportunities_name: search.oppName,
          },
        },
        page,
        onSuccess,
        onError
      )
    );
  }, [page, search]);

  const onSuccess = (data) => {
    setOpportunitiesData(data.data.data);
    setPageData({
      noOfItems: data.data.count,
      noOfPages: data.data.pages,
    });
    setError(false);
    setLoading(false);
  };

  const onError = (data) => {
    errorToast(data?.data?.data + " " + data?.data?.message);
    data?.data?.data === "You are not authorized to view" &&
      navigate("/opportunity/opportunities", { replace: true });
    setError(true);
    setLoading(false);
  };
  function handlePagination(type) {
    if (type === "+") {
      if (page + 1 < pageData.noOfPages) changePage("+");
    } else if (type === "-") if (page > 0) changePage("-");
  }
  return (
    <TableContainer width="90%">
      <div className="adminuser_table__container">
        {loading && <LoadingSpinnerTable />}
        <table className={"adminuser_table"}>
          <thead className="adminuser_table__header">
            <tr className="adminuser_table__row">
              {tableOpportunitiesHeader.map((item) => {
                return (
                  <th
                    key={item}
                    className={`${item === "Status" ? " position-sticky end-0" : null
                      } text-nowrap adminuser_table__heading`}
                  >
                    {item}
                  </th>
                );
              })}
            </tr>
          </thead>

          <tbody className="adminuser_table__body">
            <LoadAndError
              loader={loading}
              error={error}
              status={opportunitiesData.length === 0}
            >
              {opportunitiesData.map((item, index) => {
                return (
                  <tr key={item.id} className="adminuser_table__row">
                    <td className="text-nowrap dark_title_color adminuser_table__data ">
                      {page > 0 ? 12 * page + (index + 1) : index + 1}
                    </td>
                    <td className="text-nowrap dark_title_color adminuser_table__data">
                      {item.id}
                    </td>
                    <td className="text-nowrap dark_title_color adminuser_table__data">
                      {item.opportunities_id}
                    </td>
                    <td
                      title={item.title}
                      className="text-nowrap secondary_color adminuser_table_ellipsis adminuser_table__data"
                    >
                      {item.new_data === null
                        ? item.old_data?.opportunity_type_name
                        : item.new_data?.opportunity_type_name}
                    </td>
                    <td className="text-nowrap dark_title_color  adminuser_table__data adminuser_table_ellipsis">
                      {item.new_data === null
                        ? item.old_data?.title
                        : item.new_data?.title}
                    </td>
                    <td className="text-nowrap  dark_title_color adminuser_table__data">
                      {item.new_data === null
                        ? item.old_data?.status
                        : item.new_data?.status}
                    </td>
                    <td className="text-nowrap  dark_title_color adminuser_table__data">
                      {item.new_data === null
                        ? item.old_data?.total_funding_amount
                        : item.new_data?.total_funding_amount}
                    </td>
                    <td className="text-nowrap  dark_title_color adminuser_table__data">
                      {item.new_data === null
                        ? item.old_data?.total_funded_amount
                        : item.new_data?.total_funded_amount}
                    </td>
                    <td className="text-nowrap  dark_title_color adminuser_table__data">
                      {item.new_data === null
                        ? moment(item.old_data?.createdAt).format(
                          "DD/MM/YYYY HH:mm:ss"
                        )
                        : moment(item.new_data?.createdAt).format(
                          "DD/MM/YYYY HH:mm:ss"
                        )}
                    </td>
                    <td className="text-nowrap  dark_title_color adminuser_table__data">
                      {item.new_data === null
                        ? moment(item.old_data?.updatedAt).format(
                          "DD/MM/YYYY HH:mm:ss"
                        )
                        : moment(item.new_data?.updatedAt).format(
                          "DD/MM/YYYY HH:mm:ss"
                        )}
                    </td>
                    <td className="text-nowrap  dark_title_color adminuser_table__data">
                      {item.new_data === null
                        ? moment(item.old_data?.available_from_date).format(
                          "DD/MM/YYYY HH:mm:ss"
                        )
                        : moment(item.new_data?.available_from_date).format(
                          "DD/MM/YYYY HH:mm:ss"
                        )}
                    </td>
                    <td className="text-nowrap  dark_title_color adminuser_table__data">
                      {item.new_data === null
                        ? moment(item.old_data?.available_till_date).format(
                          "DD/MM/YYYY HH:mm:ss"
                        )
                        : moment(item.new_data?.available_till_date).format(
                          "DD/MM/YYYY HH:mm:ss"
                        )}
                    </td>
                    <td className="text-nowrap  position-sticky end-0 adminuser_table__data">
                      <span className="d-flex w-100   gap-2">
                        <Link
                          className="text-decoration-none primary_color "
                          to={{
                            pathname: `/opportunity/opportunities/approvals/view/${item.id}`,
                          }}
                        >
                          View
                        </Link>{" "}
                      </span>
                    </td>
                  </tr>
                );
              })}
            </LoadAndError>
          </tbody>
        </table>
      </div>
      <TablePagination
        paginate={handlePagination}
        currentPage={page}
        pageData={pageData}
      />
    </TableContainer>
  );
};

export default OpportunitiesApprovalTableContent;
