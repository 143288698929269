import React, { useEffect, useState } from "react";
import ApproveBtn from "../../../components/buttons/approveBtn";
import RejectBtn from "../../../components/buttons/rejectBtn";
import VersionHistoryBtn from "../../../components/buttons/versionHistoryBtn";
import InnerContainer from "../../../components/layouts/innerContainer/innerContainer";
import InnerLayout from "../../../components/layouts/innerLayout/innerLayout";
// import AddressPanBank from "../../../components/user/AddressPanBank/AddressPanBank";
import Kyc from "../../../components/user/kyc/kyc";
import "../user-view.css";
import * as userAction from "../../../redux/actions/userAction";
import { useDispatch } from "react-redux";
import errorToast from "../../../components/utilits/errorToast";
import successToast from "../../../components/utilits/successToast";
import LoadingSpinnerTable from "../../../components/utilits/loadingSpinnerTable";
import {
  Navigate,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import PersonalInformationApprovalView from "../../../components/user/personalInformation/personalInformationApprovalView";
import KycApprovalView from "../../../components/user/kyc/kycApprovalView";
import AddressApproval from "../../../components/user/userApprovalViewAddrssPanBank/addressApproval";
import PanDetailsApproval from "../../../components/user/userApprovalViewAddrssPanBank/PanApprovalDetails";
import BankDetailsApproval from "../../../components/user/userApprovalViewAddrssPanBank/bankApprovalDetails";
import { access } from "../../../components/utilits/access";
import LoadAndError from "../../../components/utilits/LoadAndError";

const UserApprovalsDetails = () => {
  const location = useLocation();
  const [updated, setUpdated] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [individualApprovalData, setIndividualApprovalData] = useState();
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const approveUser = () => {
    dispatch(
      userAction.ApproveUser(params.id, onApproveSuccess, onApproveError)
    );
  };

  const onApproveSuccess = (data) => {
    successToast(data.message);
    console.log(data);
    navigate(`/user/user-list/approvals`);
    setLoading(false);
    setError(false);
  };
  const onApproveError = (data) => {
    errorToast(data.data.message);
    console.log(data);
    setLoading(false);
    setError(true);
  };
  const rejectUser = () => {
    dispatch(
      userAction.rejectUserAction(params.id, onRejectSuccess, onRejectError)
    );
  };
  const onRejectSuccess = (data) => {
    // setUpdated(!updated)
    successToast(data.message);
    navigate("/user/user-list/approvals");
    setLoading(false);
    setError(false);
  };
  const onRejectError = (data) => {
    console.log(data);
    setLoading(false);
    setError(true);
    // errorToast(data.message);
  };

  const buttonList = [
    <VersionHistoryBtn link="/user/user-list/version-history" />,
    <ApproveBtn loader={loading} approveFn={approveUser} />,
    <RejectBtn showModal={rejectUser} />,
  ];
  useEffect(() => {
    setLoading(true);
    dispatch(
      userAction.individualUserApprovalDetail(params?.id, onSuccess, onError)
    );
  }, []);
  const onSuccess = (data) => {
    setIndividualApprovalData(data.data.data);
    setUpdated(true);
    setLoading(false);
    setError(false);
  };
  const onError = (data) => {
    console.log(data);
    errorToast(data.message);
    setLoading(false);
    setError(true);
  };
  return (
    <>
      <InnerLayout buttons={buttonList}>
        <div className="user_view_container">
          <InnerContainer  error={error} oldData={individualApprovalData?.old_data} newData={individualApprovalData?.new_data}>
            {loading ? (
              <LoadingSpinnerTable />
            ) : (
              <LoadAndError loader={loading} error={error}>
                <div>
                  <PersonalInformationApprovalView
                    individualApprovalData={individualApprovalData}
                  />
                  <KycApprovalView
                    individualApprovalData={individualApprovalData}
                  />

                  {individualApprovalData?.new_data?.address_proof === null ||
                  individualApprovalData?.old_data?.address_proof === null ? (
                    <></>
                  ) : (
                    <>
                      {" "}
                      <AddressApproval
                        individualApprovalData={individualApprovalData}
                      />
                    </>
                  )}
                  {individualApprovalData?.new_data?.pan === null ||
                  individualApprovalData?.old_data?.pan === null ? (
                    <></>
                  ) : (
                    <>
                      {" "}
                      <PanDetailsApproval
                        individualApprovalData={individualApprovalData}
                      />
                    </>
                  )}
                  {individualApprovalData?.new_data?.bank_account_details ===
                    null ||
                  individualApprovalData?.old_data?.bank_account_details ===
                    null ? (
                    <></>
                  ) : (
                    <>
                      {" "}
                      <BankDetailsApproval
                        individualApprovalData={individualApprovalData}
                      />
                    </>
                  )}
                </div>
              </LoadAndError>
            )}
          </InnerContainer>
        </div>
      </InnerLayout>
    </>
  );
};
export default UserApprovalsDetails;
