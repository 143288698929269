import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";

import moment from "moment";

import "./ourTeam.css";
import { TableContainer } from "../../../table/tableContainer";
import TablePagination from "../../../table/tablePagination";
import {
  ourteamversionData,
  ourteamversionHeader,
  tableAdminVersionHeader,
} from "../../../table/tableData";
import LoadingSpinnerTable from "../../../utilits/loadingSpinnerTable";
import * as ourTeamAction from "../../../../redux/actions/ourTeamAction";
import successToast from "../../../utilits/successToast";
import errorToast from "../../../utilits/errorToast";
import LoadAndError from "../../../utilits/LoadAndError";
const OurTeamVersionTableContent = ({ page, search, changePage }) => {
  const dispatch = useDispatch();
  const [versionHistoryList, setVersionHistoryList] = useState([]);
  const [loadingTable, setLoadingTable] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [ourTeamversionhistoryListData, setOutversionhistoryTeamUserListData] =
    useState([]);
  const [deleteData, setDeleteData] = useState({ roleName: "", id: "" });
  const [pageData, setPageData] = useState({ noOfItems: 0, noOfPages: 1 });

  // useEffect(() => {
  //   setLoadingTable(true);
  //   dispatch(versionHistoryAdminList(onSuccess, onError));
  // }, []);

  // const onSuccess = (data) => {
  //   console.log("dtaattat", data?.data?.data);
  //   setVersionHistoryList(data?.data?.data)

  //   setLoadingTable(false)
  // };

  // console.log(versionHistoryList);
  // const onError = (data) => {
  //   console.log(data);
  //   setLoadingTable(false)
  // };

  useEffect(() => {
    setLoading(true);
    dispatch(
      ourTeamAction.versionHistoryOurTeamList(
        {
          search: {
            team_member_id: search?.value?.Id,
          },
        },
        page,
        onSuccess,
        onError
      )
    );
  }, [page, search]);

  const onSuccess = (data) => {
    setOutversionhistoryTeamUserListData(data?.data?.data);
    // successToast(data.message);
    setPageData({
      noOfItems: data.data.count,
      noOfPages: data.data.pages,
    });
    setLoading(false);
    setError(false);
    console.log(ourTeamversionhistoryListData);
  };
  console.log(ourTeamversionhistoryListData);
  const onError = (data) => {
    errorToast(data.message, "jkdsfujdbjid");
    setLoading(false);
    setError(true);
  };

  function handlePagination(type) {
    if (type === "+") {
      if (page + 1 < pageData.noOfPages) changePage("+");
    } else if (type === "-") if (page > 0) changePage("-");
  }

  console.log(ourTeamversionhistoryListData);
  return (
    <TableContainer maxWidth="100%" width="90%">
      <div className="adminuser_table__container position-relative">
        {loadingTable && <LoadingSpinnerTable />}
        <table className={"adminuser_table"}>
          <thead className="adminuser_table__header">
            <tr className="adminuser_table__row">
              {ourteamversionHeader.map((item) => {
                return (
                  <th
                    key={item}
                    className={`text-nowrap adminuser_table__heading ${
                      item === "Status" && "text-center position-sticky end-0"
                    } `}
                  >
                    {item}
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody className="adminuser_table__body">
            <LoadAndError
              loader={loading}
              error={error}
              status={ourTeamversionhistoryListData.length === 0}
            >
              {ourTeamversionhistoryListData.map((item, index) => {
                return (
                  <tr key={item.id} className="adminuser_table__row">
                    <td className="text-nowrap dark_title_color adminuser_table__data ">
                      {page > 0 ? 12 * page + (index + 1) : index + 1}
                    </td>
                    <td className="text-nowrap dark_title_color adminuser_table__data ">
                      {item?.id}
                    </td>
                    <td className="text-nowrap dark_title_color adminuser_table__data ">
                      {item?.our_team_id == null ? "--" : item?.our_team_id}
                    </td>
                    <td className="text-nowrap dark_title_color adminuser_table__data ">
                      {item?.new_data === null
                        ? item?.old_data?.name
                        : item?.new_data?.name}
                    </td>
                    <td className="text-nowrap dark_title_color adminuser_table__data ">
                      {item?.new_data === null
                        ? item?.old_data?.designation
                        : item?.new_data?.designation}
                    </td>
                    <td className="text-nowrap dark_title_color adminuser_table__data ">
                      {item?.updated_by === null ? "-" : item?.updated_by}
                    </td>
                    <td className="text-nowrap dark_title_color adminuser_table__data ">
                      {item?.updatedAt === null
                        ? "-"
                        : moment(item?.updatedAt).format("DD/MM/YYYY HH:mm:ss")}
                    </td>
                    <td className="text-nowrap dark_title_color adminuser_table__data ">
                      {item?.approved_by === null ? "-" : item?.approved_by}
                    </td>
                    <td className="text-nowrap dark_title_color adminuser_table__data ">
                      {item?.approved_date === null
                        ? "-"
                        : moment(item?.approved_date).format(
                            "DD/MM/YYYY HH:mm:ss"
                          )}
                    </td>

                    <td className="text-nowrap  adminuser_table__data position-sticky end-0">
                      <div className="d-flex justify-content-center w-100 gap-2">
                        <Link
                          className="text-decoration-none primary_color"
                          to={{
                            pathname: `/static-pages/our-team/version-history/view/${item?.id}`,
                          }}
                        >
                          View
                        </Link>{" "}
                      </div>
                    </td>
                  </tr>
                );
              })}
            </LoadAndError>
          </tbody>
        </table>
      </div>
      <TablePagination
        paginate={handlePagination}
        currentPage={page}
        pageData={pageData}
      />
    </TableContainer>
  );
};

export default OurTeamVersionTableContent;
