import { authHeader, authHeaderFile } from "../config";
import { api, qs } from "./api";

export const addUserApi = (params) => {
  return api.post(`/api/users/create-user`, JSON.stringify(params), {
    headers: {
      "x-access-token": localStorage.getItem("accessToken"),
      "Content-Type": "application/json",
    },
  });
};
export const EditUserApi = (id, params) => {
  return api.post(`/api/users/edit-user/${id}`, (params), {
    headers: {
      "x-access-token": localStorage.getItem("accessToken"),
      "Content-Type": "application/json",
    },
  });
};
export const deleteUserApi = (id, params) => {
  return api.post(`/api/users/delete-user/${id}`, JSON.stringify(params), {
    headers: {
      "x-access-token": localStorage.getItem("accessToken"),
      "Content-Type": "application/json",
    },
  });
};

export const rejectUserApi = (id, params) => {
  return api.post(`/api/users/reject-user/${id}`, JSON.stringify(params), {
    headers: {
      "x-access-token": localStorage.getItem("accessToken"),
      "Content-Type": "application/json",
    }
  });
};
export const userEmailOtpApi = (pageNo = 0, params) => {
  return api.post(
    `/api/users/list-email-otp?page_no=${pageNo}`,
    JSON.stringify(params),
    {
      headers: {
        "x-access-token": localStorage.getItem("accessToken"),
        "Content-Type": "application/json",
      },
    }
  );
};
export const userPhoneOtpApi = (pageNo = 0, params) => {
  return api.post(
    `/api/users/list-phone-otp?page_no=${pageNo}`,
    JSON.stringify(params),
    {
      headers: {
        "x-access-token": localStorage.getItem("accessToken"),
        "Content-Type": "application/json",
      },
    }
  );
};
export const UserListApi = (pageNo = 0, params) => {
  return api.post(
    `/api/users/list-all-users?page_no=${pageNo}`,
    JSON.stringify(params),
    {
      headers: {
        "x-access-token": localStorage.getItem("accessToken"),
        "Content-Type": "application/json",
      },
    }
  );
};

export const ApprovalUserListApi = (pageNo, params) => {
  return api.post(
    `/api/users/list-all-user-approvals?page_no=${pageNo}`,
    JSON.stringify(params),
    {
      headers: {
        "x-access-token": localStorage.getItem("accessToken"),
        "Content-Type": "application/json",
      },
    }
  );
};
export const ApprovalUserapi = (params) => {
  return api.post(`/api/users/approve-user`, params, {
    headers: {
      "x-access-token": localStorage.getItem("accessToken"),
      "Content-Type": "application/json",
    },
  });
};

export const userVersionHistoryListApi = (pageNo = 0, params) => {
  return api.post(
    `/api/users/version-history?page_no=${pageNo}`,
    JSON.stringify(params),
    {
      headers: {
        "x-access-token": localStorage.getItem("accessToken"),
        "Content-Type": "application/json",
      },
    }
  );
};
// export const notifyMeVersionHistoryViewApi = (id, params) => {
//   return api.post(`/api/opportunity-notify/view-version-history-notifyme-findone/${id}`, JSON.stringify(params), {
//         headers: {
// "x-access-token": localStorage.getItem("accessToken"),
//   "Content-Type": "application/json",
//     },
//   });
// };
export const UserVersionHistoryViewApi = (id, params) => {
  return api.post(
    `/api/users/version-history-findone/${id}`,

    JSON.stringify(params),
    {
      headers: {
        "x-access-token": localStorage.getItem("accessToken"),
        "Content-Type": "application/json",
      },
    }
  );
};

export const individualUserApprovalDetailApi = (id = "", params) => {
  return api.post(
    `/api/users/view-approvals-id/${id}`,
    JSON.stringify(params),
    {
      headers: {
        "x-access-token": localStorage.getItem("accessToken"),
        "Content-Type": "application/json",
      },
    }
  );
};
export const UserViewDetailApi = (params) => {
  return api.post(`/api/users/list-user`, JSON.stringify(params), {
    headers: {
      "x-access-token": localStorage.getItem("accessToken"),
      "Content-Type": "application/json",
    },
  });
};

export const ResetPasswordEmailViewapi = (pageNo = 0, params) => {
  return api.post(
    `/api/users/reset-password-email?page_no=${pageNo}`,
    JSON.stringify(params),
    {
      headers: {
        "x-access-token": localStorage.getItem("accessToken"),
        "Content-Type": "application/json",
      },
    }
  );
};

/*****IMAGE UPLOAD *********/
export const profileImageUploadApi = (params) => {
  return api.post(`/api/users/profile-image`, params, {
    headers: {
      "x-access-token": localStorage.getItem("accessToken"),
      "Content-Type": "multipart/form-data",
    },
  });
};

export const uploadAddressProofFrontImageApi = (params) => {
  return api.post(`/api/users/update-kyc-address-proof-front-image`, params, {
    headers: {
      "x-access-token": localStorage.getItem("accessToken"),
      "Content-Type": "multipart/form-data",
    }
  });
};
export const uploadAddressProofBackImageApi = (params) => {
  return api.post(`/api/kyc/update-kyc-address-proof-back-image`, params, {
    headers: {
      "x-access-token": localStorage.getItem("accessToken"),
      "Content-Type": "multipart/form-data",
    },
  });
};

export const panImageUploadApi = (params) => {
  return api.post(`/api/kyc/update-pan-image`, params, {
    headers: {
      "x-access-token": localStorage.getItem("accessToken"),
      "Content-Type": "multipart/form-data",
    },
  });
};

export const bankImageUploadApi = (params) => {
  return api.post(`/api/users/update-bank-image`, params, {
    headers: {
      "x-access-token": localStorage.getItem("accessToken"),
      "Content-Type": "multipart/form-data",
    },
  });
};

export const getProfileImagelApi = (id = "", params) => {
  return api.post(`/api/users/get-profile/1667742967860.svg`, params, {
    headers: {
      "x-access-token": localStorage.getItem("accessToken"),
      "Content-Type": "multipart/form-data",
    },
  });
};

export const userExportApi = (params) => {
  return api.post(`/api/users/export-main-user`, JSON.stringify(params), {
    headers: {
      "x-access-token": localStorage.getItem("accessToken"),
      "Content-Type": "application/json",
    }
  });
};

export const approvedAddreddUserApi = (params) => {
  return api.post(`/api/users/Address-Proof-Approved`, JSON.stringify(params), {
    headers: {
      "x-access-token": localStorage.getItem("accessToken"),
      "Content-Type": "application/json",
    }
  });
};

export const rejectedApproveAddreddUserApi = (params) => {
  return api.post(`/api/users/Address-Proof-Details`, JSON.stringify(params), {
    headers: {
      "x-access-token": localStorage.getItem("accessToken"),
      "Content-Type": "application/json",
    }
  });
};

export const approvedPanUserApi = (params) => {
  return api.post(`/api/users/Pan-Approved`, JSON.stringify(params), {
    headers: {
      "x-access-token": localStorage.getItem("accessToken"),
      "Content-Type": "application/json",
    }
  });
};

export const rejectedPanUserApi = (params) => {
  return api.post(`/api/users/Pan-Reject`, JSON.stringify(params), {
    headers: {
      "x-access-token": localStorage.getItem("accessToken"),
      "Content-Type": "application/json",
    }
  });
};

export const approvedBankUserApi = (params) => {
  return api.post(`/api/users/Bank-Details-Approved`, JSON.stringify(params), {
    headers: {
      "x-access-token": localStorage.getItem("accessToken"),
      "Content-Type": "application/json",
    }
  });
};

export const rejectedBankUserApi = (params) => {
  return api.post(`/api/users/Bank-Details-rejected`, JSON.stringify(params), {
    headers: {
      "x-access-token": localStorage.getItem("accessToken"),
      "Content-Type": "application/json",
    }
  });
};
export const kycStatusUserApi = (params) => {
  return api.post(`/api/admin/kyc/kyc-status`, params, {
    headers: {
      "x-access-token": localStorage.getItem("accessToken"),
      "Content-Type": "application/json",
    }
  });
};
export const PanApproveRejectUserApi = (params) => {
  return api.post(`/api/users/Pan-Details`, JSON.stringify(params), {
    headers: {
      "x-access-token": localStorage.getItem("accessToken"),
      "Content-Type": "application/json",
    }
  });
};
export const rejectedApproveBankUserApi = (params) => {
  return api.post(`/api/users/Bank-Details`, JSON.stringify(params), {
    headers: {
      "x-access-token": localStorage.getItem("accessToken"),
      "Content-Type": "application/json",
    }
  });
};
//export

export const userTableExportApi = (params) => {
  return api.post(`/api/users/export-main-user`,
    JSON.stringify(params),
    {
      headers: {
        "x-access-token": localStorage.getItem("accessToken"),
        "Content-Type": "application/json",
      },
    }
  );
};
export const userApprovalsExportApi = (params) => {
  return api.post(
    `/api/users/export-approval-user`,
    JSON.stringify(params),
    {
      headers: {
        "x-access-token": localStorage.getItem("accessToken"),
        "Content-Type": "application/json",
      },
    }
  );
};
export const userVersionExportApi = (params) => {
  return api.post(
    `/api/users/export-version-user`,
    JSON.stringify(params),
    {
      headers: {
        "x-access-token": localStorage.getItem("accessToken"),
        "Content-Type": "application/json",
      },
    }
  );
};




export const userPhoneExportApi = (page, params) => {
  return api.post(
    `/api/users/export-phone-user`,
    JSON.stringify(params),
    {
      headers: {
        "x-access-token": localStorage.getItem("accessToken"),
        "Content-Type": "application/json",
      },
    }
  );
};

export const userResetExportApi = (page, params) => {
  return api.post(
    `/api/users/export-reset-password-user`,
    JSON.stringify(params),
    {
      headers: {
        "x-access-token": localStorage.getItem("accessToken"),
        "Content-Type": "application/json",
      },
    }
  );
};
export const userEmailExportApi = (page, params) => {
  return api.post(
    `/api/users/export-email-user`,
    JSON.stringify(params),
    {
      headers: {
        "x-access-token": localStorage.getItem("accessToken"),
        "Content-Type": "application/json",
      },
    }
  );
};



export const forgotPasswordSendEmailApi = (params) => {
  return api.post(
    `/api/auth/forgot-password`,
    JSON.stringify(params),
    {
      headers: { "Content-Type": "application/json" },
    }
  );
};


export const userSubmitApi = (id, params) => {
  return api.post(
    `/api/users/on-submit/${id}`, JSON.stringify(params),
    {
      headers: {
        "x-access-token": localStorage.getItem("accessToken"),
        "Content-Type": "application/json",
      },
    }
  );
};
