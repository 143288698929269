import React, { Children } from 'react';
import "../innerContainer/oppotunitiesViewInnerContainer.css";
import arrow_left from "../../../assets/icons/arrowleft.svg"
import { useNavigate } from 'react-router-dom';

const OpportunitiesViewInnerContainer = ({ children, containerClassName = "", overflowClassName = `admin_view_table_overFlow_container` }) => {
    const navigate = useNavigate()
    return (

        <div className={`admin_opportunities_view_table_container   mt-4 mb-1 ${containerClassName}`} >
            <div className=' admin_view_table_container_top_position white_shade_bg'>
                <button onClick={() => navigate(-1)} className='admin_view_back_button d-flex align-items-center ps-4 pt-3'>
                    <img src={arrow_left} alt="user_photo" />
                    <span className='ps-3 dark_grey_color fs_13 fn_Montserrat fw_500'>Back</span>
                </button>
                <hr className='admin_view_hr_line'></hr>
            </div>
            <div className={`${overflowClassName}`}>
                {children}
            </div >
        </div>
    )
}

export default OpportunitiesViewInnerContainer