import {
  APPROVE_OUR_TEAM,
  CREATE_OUR_TEAM,
  DELETE_OUR_TEAM,
  EDIT_OUR_TEAM,
  OUR_TEAM_APPROVALS_EXPORT,
  OUR_TEAM_APPROVAL_TABLE,
  OUR_TEAM_APPROVAL_VIEW,
  OUR_TEAM_EXPORT,
  OUR_TEAM_OPTIONS,
  OUR_TEAM_SUBMIT,
  OUR_TEAM_TABLE,
  OUR_TEAM_VERSION_EXPORT,
  OUR_TEAM_VERSION_HISTORY,
  OUR_TEAM_VERSION_HISTORY_TABLE,
  REJECT_OUR_TEAM,
  VIEW_OUR_TEAM,
} from "./types";

export const createOutTeam = (data, onSuccess, onError) => {
  return {
    type: CREATE_OUR_TEAM,
    data,
    onSuccess,
    onError,
  };
};

export const approveOurTeam = (id, onSuccess, onError) => {
  return {
    type: APPROVE_OUR_TEAM,
    id,
    onSuccess,
    onError,
  };
};

export const rejectOurTeam = (id, onSuccess, onError) => {
  return {
    type: REJECT_OUR_TEAM,
    id,
    onSuccess,
    onError,
  };
};

export const viewOurTeamDetail = (id, onSuccess, onError) => {
  return {
    type: VIEW_OUR_TEAM,
    id,
    onSuccess,
    onError,
  };
};

export const viewOurTeamList = (data, page_no, onSuccess, onError) => {
  return {
    type: OUR_TEAM_TABLE,
    data,
    page_no,
    onSuccess,
    onError,
  };
};

export const editOurTeam = (id, data, onSuccess, onError) => {
  return {
    type: EDIT_OUR_TEAM,
    id,
    data,
    onSuccess,
    onError,
  };
};

export const deleteOurTeam = (id, onSuccess, onError) => {
  return {
    type: DELETE_OUR_TEAM,
    id,
    onSuccess,
    onError,
  };
};

export const OurTeamApprovalList = (data, page_no, onSuccess, onError) => {
  return {
    type: OUR_TEAM_APPROVAL_TABLE,
    data,
    page_no,
    onSuccess,
    onError,
  };
};

export const OurTeamApprovalById = (id, onSuccess, onError) => {
  return {
    type: OUR_TEAM_APPROVAL_VIEW,
    id,
    onSuccess,
    onError,
  };
};

export const versionHistoryOurTeamList = (
  data,
  page_no,
  onSuccess,
  onError
) => {
  return {
    type: OUR_TEAM_VERSION_HISTORY_TABLE,
    data,
    page_no,
    onSuccess,
    onError,
  };
};

export const versionHistoryOurTeamDetail = (id, onSuccess, onError) => {
  return {
    type: OUR_TEAM_VERSION_HISTORY,
    id,
    onSuccess,
    onError,
  };
};
export const ourTeamOptions = (id, onSuccess, onError) => {
  return {
    type: OUR_TEAM_OPTIONS,
    id,
    onSuccess,
    onError,
  };
};


export const ourTeamTableExport = (onSuccess, onError) => {
  return {
    type: OUR_TEAM_EXPORT,
    onSuccess,
    onError,
  };
};
export const ourTeamApprovalsExport = (onSuccess, onError) => {
  return {
    type: OUR_TEAM_APPROVALS_EXPORT,
    onSuccess,
    onError,
  };
};
export const ourTeamVersionExport = (onSuccess, onError) => {
  return {
    type: OUR_TEAM_VERSION_EXPORT,
    onSuccess,
    onError,
  };
};


export const ourTeamSubmit = (id, onSuccess, onError) => {
  return {
      type: OUR_TEAM_SUBMIT,
      id,
      onSuccess,
      onError,
  };
};  
