import { act } from "react-dom/test-utils";
import { call, takeLatest } from "redux-saga/effects";
import {
  ACTIVATE_INSIGHTS_OPTIONS,
  INSIGHTS_APPROVALS_EXPORT,
  INSIGHTS_APPROVALS_LIST,
  INSIGHTS_APPROVALS_VIEW,
  INSIGHTS_APPROVE,
  INSIGHTS_DELETE,
  INSIGHTS_EDIT,
  INSIGHTS_LIST,
  INSIGHTS_MAIN_EXPORT,
  INSIGHTS_READ,
  INSIGHTS_REJECT,
  INSIGHTS_SUBMIT,
  INSIGHTS_VERSION_EXPORT,
  INSIGHTS_VERSION_LIST,
  INSIGHTS_VERSION_VIEW,
  INSIGHTS_CREATE,
} from "../actions/types.js";
import {
  activateInsightsApi,
  addInsightsApi,
  approvalsInsightsListpApi,
  approveInsightsApi,
  deleteInsightsApi,
  editInsightsApi,
  InsightsApprovalsExportApi,
  InsightsMainExportApi,
  InsightsSubmitApi,
  InsightsVersionExportApi,
  rejectInsightsApi,
  versionInsightsListpApi,
  viewApprovalInsightsApi,
  viewInsightsApi,
  viewInsightsListApi,
  viewVersionInsightsApi,
} from "../apis/insightsApi";

export function* addInsightsWorker(action) {
  try {
    const res = yield call(addInsightsApi, action.data);
    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res.data);
      console.log(res.data);
    } else if (res.status === 200 && res.data.status === "fail") {
      console.log(res.data);
      yield action.onError(res);
    } else {
      yield action.onError("Something Went Wrong");
    }
  } catch (err) {
    console.log(err);
    yield action.onError("Something Went Wrong");
  }
}

export function* viewInsightsWorker(action) {
  try {
    const res = yield call(viewInsightsApi, action.id);
    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res.data);
      console.log(res.data);
    } else if (res.status === 200 && res.data.status === "fail") {
      console.log(res.data);
      yield action.onError(res);
    } else {
      yield action.onError("Something Went Wrong!");
      console.log(res.data);
    }
  } catch (err) {
    console.log(err);
    yield action.onError("Something Went Wrong!!");
  }
}
export function* editInsightsWorker(action) {
  try {
    const res = yield call(editInsightsApi, action.id, action.data);
    console.log(res);
    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res.data);
      console.log(res.data);
    } else if (res.status === 200 && res.data.status === "fail") {
      console.log(res);
      yield action.onError(res);
    } else {
      yield action.onError("Something Went Wrong");
    }
  } catch (err) {
    console.log(err);
    yield action.onError("Something Went Wrong");
  }
}
export function* deleteInsightsRoleWorker(action) {
  try {
    const res = yield call(deleteInsightsApi, action.id);
    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res.data);
      console.log(res.data);
    } else if (res.status === 200 && res.data.status === "fail") {
      console.log(res.data);
      yield action.onError(res);
    } else {
      yield action.onError("Something Went Wrong");
    }
  } catch (err) {
    console.log(err);
    yield action.onError("Something Went Wrong");
  }
}
export function* viewInsightsListWorker(action) {
  try {
    const res = yield call(viewInsightsListApi, action.page, action.data);
    console.log(res);
    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res.data);
      console.log(res.data);
    } else if (res.status === 200 && res.data.status === "fail") {
      console.log(res);
      yield action.onError(res.data);
    } else {
      yield action.onError("Something Went Wrong");
    }
  } catch (err) {
    console.log(err);
    yield action.onError("Something Went Wrong");
  }
}

export function* approveInsightsWorker(action) {
  try {
    const res = yield call(approveInsightsApi, { insights_id: action.id });
    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res.data);
      console.log(res.data);
    } else if (res.status === 200 && res.data.status === "fail") {
      console.log(res);
      yield action.onError(res.data);
    } else {
      yield action.onError("Something Went Wrong!");
      console.log(res.data);
    }
  } catch (err) {
    console.log(err);
    yield action.onError("Something Went Wrong!!");
  }
}

export function* rejectInsightsWorker(action) {
  try {
    const res = yield call(rejectInsightsApi, action.id);
    console.log(res, "rekect Insights");
    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res.data);
      console.log(res.data);
    } else if (res.status === 200 && res.data.status === "fail") {
      console.log(res);
      yield action.onError(res.data);
    } else {
      yield action.onError("Something Went Wrong!");
      console.log(res.data);
    }
  } catch (err) {
    console.log(err);
    yield action.onError("Something Went Wrong!!");
  }
}
export function* approvalsInsightsListWorker(action) {
  try {
    const res = yield call(approvalsInsightsListpApi, action.page, action.data);
    // console.log(res, "AddAdminRole");
    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res.data);
      console.log(res.data);
    } else if (res.status === 200 && res.data.status === "fail") {
      console.log(res);
      yield action.onError(res);
    } else {
      yield action.onError("Something Went Wrong!");
      console.log(res.data);
    }
  } catch (err) {
    console.log(err);
    yield action.onError("Something Went Wrong!!");
  }
}
export function* viewApprovalInsightsWorker(action) {
  try {
    const res = yield call(viewApprovalInsightsApi, action.id);
    console.log(res, "Insights approvals");
    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res.data);
      console.log(res.data);
    } else if (res.status === 200 && res.data.status === "fail") {
      console.log(res);
      yield action.onError(res);
    } else {
      yield action.onError("Something Went Wrong!");
      console.log(res.data);
    }
  } catch (err) {
    console.log(err);
    yield action.onError("Something Went Wrong!!");
  }
}
export function* versionListInsightsWorker(action) {
  try {
    const res = yield call(versionInsightsListpApi, action.page, action.data);
    // console.log(res, "AddAdminRole");
    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res.data);
      console.log(res.data);
    } else if (res.status === 200 && res.data.status === "fail") {
      console.log(res);
      yield action.onError(res);
    } else {
      yield action.onError("Something Went Wrong!");
      console.log(res.data);
    }
  } catch (err) {
    console.log(err);
    yield action.onError("Something Went Wrong!!");
  }
}
export function* activateInsightsWorker(action) {
  try {
    const res = yield call(activateInsightsApi, action.id);
    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res);
      console.log(res.data);
    } else if (res.status === 200 && res.data.status === "fail") {
      console.log(res);
      yield action.onError(res);
    } else {
      yield action.onError("Something Went Wrong!");
      console.log(res);
    }
  } catch (err) {
    console.log(err);
    yield action.onError("Something Went Wrong!!");
  }
}
export function* versionViewInsightsWorker(action) {
  try {
    const res = yield call(viewVersionInsightsApi, action.id);
    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res);
      console.log(res.data);
    } else if (res.status === 200 && res.data.status === "fail") {
      console.log(res);
      yield action.onError(res);
    } else {
      yield action.onError("Something Went Wrong!");
      console.log(res);
    }
  } catch (err) {
    console.log(err);
    yield action.onError("Something Went Wrong!!");
  }
}

export function* InsightsMainExportWorker(action) {
  try {
    const res = yield call(InsightsMainExportApi);
    console.log(res);
    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res.data);
    }
  } catch (error) {}
}

export function* InsightsApprovalsExportWorker(action) {
  try {
    const res = yield call(InsightsApprovalsExportApi);
    console.log(res);
    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res.data);
    }
  } catch (error) {}
}

export function* InsightsVersionExportWorker(action) {
  try {
    const res = yield call(InsightsVersionExportApi);
    console.log(res);
    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res.data);
    }
  } catch (error) {}
}
export function* InsightsSubmitWorker(action) {
  try {
    const res = yield call(InsightsSubmitApi, action.id);
    console.log(res);
    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res.data);
    }
  } catch (error) {}
}

export function* watchInsightsSubmitWorker() {
  yield takeLatest(INSIGHTS_SUBMIT, InsightsSubmitWorker);
}

/**********************************/
/************WATHCHERS**************/
/**********************************/

export function* watchAddInsightsWorker() {
  yield takeLatest(INSIGHTS_CREATE, addInsightsWorker);
}
export function* watchApprovalsInsightsListWorker() {
  yield takeLatest(INSIGHTS_APPROVALS_LIST, approvalsInsightsListWorker);
}
export function* watchViewApprovalInsightsWorker() {
  yield takeLatest(INSIGHTS_APPROVALS_VIEW, viewApprovalInsightsWorker);
}
export function* watchViewInsightsWorker() {
  yield takeLatest(INSIGHTS_READ, viewInsightsWorker);
}
export function* watchViewInsightsListWorker() {
  yield takeLatest(INSIGHTS_LIST, viewInsightsListWorker);
}
export function* watcheditInsightsWorker() {
  yield takeLatest(INSIGHTS_EDIT, editInsightsWorker);
}
export function* watchApproveInsightsWorker() {
  yield takeLatest(INSIGHTS_APPROVE, approveInsightsWorker);
}
export function* watchRejectInsightsWorker() {
  yield takeLatest(INSIGHTS_REJECT, rejectInsightsWorker);
}
export function* watchDeleteInsightsWorker() {
  yield takeLatest(INSIGHTS_DELETE, deleteInsightsRoleWorker);
}
export function* watchversionListInsightsWorker() {
  yield takeLatest(INSIGHTS_VERSION_LIST, versionListInsightsWorker);
}
export function* watchVersionViewInsightsWorker() {
  yield takeLatest(INSIGHTS_VERSION_VIEW, versionViewInsightsWorker);
}
export function* WatchActivateInsightsWorker() {
  yield takeLatest(ACTIVATE_INSIGHTS_OPTIONS, activateInsightsWorker);
}

export function* watchInsightsMainExportWorker() {
  yield takeLatest(INSIGHTS_MAIN_EXPORT, InsightsMainExportWorker);
}
export function* watchInsightsApprovalsExportWorker() {
  yield takeLatest(INSIGHTS_APPROVALS_EXPORT, InsightsApprovalsExportWorker);
}
export function* watchInsightsVersionExportWorker() {
  yield takeLatest(INSIGHTS_VERSION_EXPORT, InsightsVersionExportWorker);
}
