import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import ArrowRightIcon from '../../../../assets/icons/arrowrightIcon';
import ApprovalsBtn from '../../../../components/buttons/approvalsBtn';
import RejectBtn from '../../../../components/buttons/rejectBtn';
import InnerContainer from '../../../../components/layouts/innerContainer/innerContainer';
import InnerLayout from '../../../../components/layouts/innerLayout/innerLayout';
import errorToast from '../../../../components/utilits/errorToast';
import LoadAndError from '../../../../components/utilits/LoadAndError';
import LoadingSpinnerTable from '../../../../components/utilits/loadingSpinnerTable';
import * as opporTypeActions from "../../../../redux/actions/opportunityTypeActions";
import "../opportunity-type.css"

const OpportunityTypeVersionHistoryView = () => {
    const params = useParams();
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(false)
    const [versionHistoyData, setVersionHistoyData] = useState({
        id: "",
        createdAt: "",
        updatedAt: "",
        new_data: { updated_by: "", updated_At: "" },
    });
    const dispatch = useDispatch();
    useEffect(() => {
        setLoading(true);
        dispatch(
            opporTypeActions.viewVersionHistoryOpporType(
                params.id,
                onSuccess,
                onError
            )
        );
    }, [params.id]);
    const onSuccess = (data) => {
        setVersionHistoyData(data.data.data);
    setLoading(false);
    setError(false)
    };
    const onError = (data) => {
        // console.log(data);

        errorToast(data.message);
    setLoading(false);
    setError(true)
    };
    const data = [
        {
            heading: "Role ID",
            title: versionHistoyData.id,
        },
        {
            heading: "Created At",
            title: versionHistoyData.createdAt,
        },
        {
            heading: "Updated At",
            title: moment(versionHistoyData.updatedAt).format("DD-MM-YYYY HH:mm:ss"),
        },
        {
            heading: "Updated By",
            title: versionHistoyData.updated_by == null ? "--" : versionHistoyData.updated_by,
        },
        {
            heading: "Approved By",
            title: versionHistoyData?.approved_by,
        },
        {
            heading: "Approved Date",
            title: moment(versionHistoyData?.new_data?.approved_date).format("DD-MM-YYYY HH:mm:ss"),
        },
    ];



    console.log(versionHistoyData, "one");
    return (
        <>
            <InnerLayout >
                <div className="opportunity-type-container">
                    <InnerContainer>
                        {loading ? (
                            <LoadingSpinnerTable/>
                        ) : (
          <LoadAndError loader={loading} error={error}>
                        <div className="pt-3 ps-5 row">
                            {data.map((item, index) => (
                                <div className="mb-2  col">
                                    <h5 className="dark_grey_color text-nowrap fs_13 fn_Montserrat fw_500 m-0">
                                        {item.heading}
                                    </h5>
                                    <p
                                        className={` fs_13 fn_Nunito text-nowrap fw_500 ${item.heading === "Role ID"
                                            ? "secondary_color"
                                            : "dark_title_color"
                                            }`}
                                    >
                                        {item.title}
                                    </p>
                                </div>
                            ))}
                        </div>
                        <div className="white_shade_bg  py-1 d-flex">
                            <div className="w-50">
                                <h5 className="fw_500 ps-4 dark_grey_color pt-2 text-nowrap fs_16 fn_Montserrat">
                                    JSON Old Data
                                </h5>
                            </div>
                            <div className="w-50  d-none d-lg-block">
                                <h5 className="fw_500 ps-4  dark_grey_color pt-2 text-nowrap fs_16 fn_Montserrat">
                                    JSON New Data
                                </h5>
                            </div>
                        </div>

                        <div className="row mt-3 ps-0 ps-lg-4">
                            <div className="col-12 col-lg-6">
                                <div className="admin_role_aprvls_vrsn_hstry_data_container  mt-3 pt-4   px-3">
                                    <div className="d-flex justify-content-between align-items-center">
                                        <h5 className="fs_13 fn_Nunito fw_400 dark_title_color text-nowrap">
                                            Old Data
                                        </h5>
                                        <ArrowRightIcon width={25} height={25} fill={"#A5A5A5"} />
                                    </div>

                                    {versionHistoyData.old_data == null ? (
                                        <span>"empty"</span>
                                    ) : (
                                        <div>
                                            {Object.entries(versionHistoyData.old_data).map(
                                                ([key, value]) => (
                                                    <>
                                                        <div className="">
                                                            <span>{key !== "permissions" ? key : ""}</span>
                                                            <p>
                                                                {`${key !== "permissions"
                                                                    ? value === null
                                                                        ? "-"
                                                                        : typeof value === "string" ||
                                                                            typeof value === "number" ||
                                                                            typeof value === "boolean"
                                                                            ? value
                                                                            : "-"
                                                                    : ""
                                                                    }`}
                                                            </p>
                                                        </div>
                                                    </>
                                                )
                                            )}

                                        </div>
                                    )}
                                </div>
                                <div></div>
                            </div>
                            <div className="col-12 mt-5 mt-lg-0 col-lg-6">
                                <h5 className="fw_500 ps-4 d-block d-lg-none white_shade_bg  dark_grey_color py-3 text-nowrap fs_16 fn_Montserrat">
                                    JSON New Data
                                </h5>
                                <div className="admin_role_aprvls_vrsn_hstry_data_container    mt-4 mt-lg-3 pt-3 px-3">
                                    <div className="d-flex justify-content-between">
                                        <h5 className="fs_13 fn_Nunito fw_400 dark_title_color text-nowrap">
                                            New Data
                                        </h5>
                                        <ArrowRightIcon width={25} height={25} fill={"#A5A5A5"} />
                                    </div>
                                    {versionHistoyData.new_data === null ? (
                                        <span>Empty</span>
                                    ) : (
                                        <div>
                                            {Object.entries(versionHistoyData.new_data).map(
                                                ([key, value]) => (
                                                    <>
                                                        <div className="">
                                                            <span>{key !== "permissions" ? key : ""}</span>
                                                            <p>
                                                                {`${key !== "permissions"
                                                                    ? value === null
                                                                        ? "-"
                                                                        : typeof value === "string" ||
                                                                            typeof value === "number" ||
                                                                            typeof value === "boolean"
                                                                            ? value
                                                                            : "-"
                                                                    : ""
                                                                    }`}
                                                            </p>
                                                        </div>
                                                    </>
                                                )
                                            )}

                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                        </LoadAndError>
                        )}
                    </InnerContainer>
                </div>
            </InnerLayout>
        </>
    )
}
export default OpportunityTypeVersionHistoryView;
