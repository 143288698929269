import { authHeader } from "../config";
import { api } from "./api";

export const addHowItWorksApi = (params) => {
  return api.post("/api/admin/how-it-works/create-how-it-works", params, {
    headers: {
      "x-access-token": localStorage.getItem("accessToken"),
      "Content-Type": "application/json",
    },
  });
};
export const viewHowItWorksListApi = (page = 0, params) => {
  return api.post(
    `/api/admin/how-it-works/views-how-it-works?page_no=${page}`,
    params,
    {
      headers: {
        "x-access-token": localStorage.getItem("accessToken"),
        "Content-Type": "application/json",
      },
    }
  );
};
export const viewHowItWorksApi = (id, params) => {
  return api.post(`/api/admin/how-it-works/view-one-approved/${id}`, params, {
    headers: {
      "x-access-token": localStorage.getItem("accessToken"),
      "Content-Type": "application/json",
    },
  });
};
export const editHowItWorksApi = (id, params) => {
  return api.post(`/api/admin/how-it-works/edit-how-it-work/${id}`, params, {
    headers: {
      "x-access-token": localStorage.getItem("accessToken"),
      "Content-Type": "application/json",
    },
  });
};
export const deleteHowItWorksApi = (id, params) => {
  return api.post(`/api/admin/HowItWorks/delete/${id}`, params, {
    headers: {
      "x-access-token": localStorage.getItem("accessToken"),
      "Content-Type": "application/json",
    },
  });
};
export const approveHowItWorksApi = (params) => {
  return api.post(`/api/admin/how-it-works/approve-how-it-works`, params, {
    headers: {
      "x-access-token": localStorage.getItem("accessToken"),
      "Content-Type": "application/json",
    },
  });
};
export const rejectHowItWorksApi = (params) => {
  return api.post(`/api/admin/how-it-works/reject-how-it-works`, params, {
    headers: {
      "x-access-token": localStorage.getItem("accessToken"),
      "Content-Type": "application/json",
    },
  });
};
export const approvalsHowItWorksListpApi = (search = "", page = 0, params) => {
  return api.post(
    `/api/admin/how-it-works/view-checkkers?search=${search}&page_no=${page}`,
    params,
    {
      headers: {
        "x-access-token": localStorage.getItem("accessToken"),
        "Content-Type": "application/json",
      },
    }
  );
};
export const viewApprovalHowItWorksApi = (id, params) => {
  return api.post(`/api/admin/how-it-works/view-one-checker/${id}`, params, {
    headers: {
      "x-access-token": localStorage.getItem("accessToken"),
      "Content-Type": "application/json",
    },
  });
};
export const versionHowItWorksListpApi = (page = 0, params) => {
  return api.post(
    `/api/admin/how-it-works/views-versions?page_no=${page}`,
    params,
    {
      headers: {
        "x-access-token": localStorage.getItem("accessToken"),
        "Content-Type": "application/json",
      },
    }
  );
};
export const viewVersionHowItWorksApi = (params) => {
  return api.post(`/api/admin/how-it-works/view-version`, params, {
    headers: {
      "x-access-token": localStorage.getItem("accessToken"),
      "Content-Type": "application/json",
    },
  });
};
export const activateHowItWorksApi = (id, params) => {
  return api.post(`/api/admin/how-it-works/is-active-update/${id}`, params, {
    headers: {
      "x-access-token": localStorage.getItem("accessToken"),
      "Content-Type": "application/json",
    },
  });
};
export const howItWorksSubmitApi = (id, params) => {
  return api.post(`/api/admin/how-it-works/on-submit/${id}`, params, {
    headers: {
      "x-access-token": localStorage.getItem("accessToken"),
      "Content-Type": "application/json",
    },
  });
};

export const howItWorksMainExportApi = (page, params) => {
  return api.post(
    `/api/admin/how-it-works/export-main-how-it-works`,
    JSON.stringify(params),
    {
      headers: {
        "x-access-token": localStorage.getItem("accessToken"),
        "Content-Type": "application/json",
      },
    }
  );
};

export const howItWorksApprovalsExportApi = (page, params) => {
  return api.post(
    `/api/admin/how-it-works/export-approval-how-it-works`,
    JSON.stringify(params),
    {
      headers: {
        "x-access-token": localStorage.getItem("accessToken"),
        "Content-Type": "application/json",
      },
    }
  );
};

export const howItWorksVersionExportApi = (page, params) => {
  return api.post(
    `/api/admin/how-it-works/export-version-how-it-works`,
    JSON.stringify(params),
    {
      headers: {
        "x-access-token": localStorage.getItem("accessToken"),
        "Content-Type": "application/json",
      },
    }
  );
};
