import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import errorToast from "../../utilits/errorToast";
import successToast from "../../utilits/successToast";
import moment from "moment";
import { viewCategoryDetail } from "../../../redux/actions/faqCategoryActions";
import { viewBlogCategoryDetailAction } from "../../../redux/actions/blogCategoryActions";
import { viewBlogDetailAction } from "../../../redux/actions/blogActions";
import CloseIcon from "../../../assets/icons/closeIcon";
import UploadIcon from "../../../assets/icons/upload";
import DownloadIcon from "../../../assets/icons/downloadIcon";
import LoadingSpinnerTable from "../../utilits/loadingSpinnerTable";
import LoadAndError from "../../utilits/LoadAndError";
import CreateTable from "../../opportunity/opportunties/tables/opporttunitiesCreateTable";

const BlogViewDetails = ({ id }) => {
  const params = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [cardImg, setCardImg] = useState("");
  const [wishlistData, setWishlistData] = useState({
    id: "",
    name: "",
    createdAt: "",
    updatedAt: "",
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  useEffect(() => {
    setLoading(true);
    dispatch(
      viewBlogDetailAction(params.id, onSuccess, onError)
      //viewWishlist("52decfd4-3d70-4239-815d-1650e294d45f", onSuccess, onError)
    );
  }, [id]);
  const onSuccess = (data) => {
    console.log(data.data);
    setWishlistData(data.data);
    setLoading(false);
    setError(false);
  };
  const onError = (data) => {
    errorToast(data.message);
    setLoading(false);
    setError(true);
  };

  const ViewDetails = [
    {
      heading: "Blog Id",
      title: wishlistData?.id ? wishlistData?.id : "--",
    },
    {
      heading: "Blog Category id",
      title: wishlistData?.blog_category_id
        ? wishlistData?.blog_category_id
        : "--",
    },
    {
      heading: "Blog Category id",
      title: wishlistData?.blog_category_id
        ? wishlistData?.blog_category_id
        : "--",
    },
    {
      heading: "Approved At",
      title: wishlistData?.updatedAt
        ? moment(wishlistData?.updatedAt).format("DD-MM-YYYY HH:mm:ss")
        : "--",
    },
    {
      heading: "Updated At",
      title: wishlistData?.createdAt
        ? moment(wishlistData?.createdAt).format("DD-MM-YYYY HH:mm:ss")
        : "--",
    },
  ];

  console.log(wishlistData);
  return (

    <>
      {loading ? (
        <LoadingSpinnerTable />
      ) : (
        <LoadAndError loader={loading} error={error}>
          <div className="px-4 py-3">
            <div className="d-flex flex-column flex-lg-row fs_14">
              <div className="col-12 col-lg-6 pe-0 pe-lg-4">
                <div className="">
                  <span>Blog ID</span>
                  <p>{wishlistData.id}</p>
                </div>
                <div className="">
                  <span>Blog Category</span>
                  <p>{wishlistData.blog_category_id}</p>
                </div>
                <div className="">
                  <span>Blog Title</span>
                  <p>{wishlistData.title}</p>
                </div>
                <div className="">
                  <span>Description</span>
                  <p>{wishlistData.description}</p>
                </div>
                <div className="">
                  <span>Meta Tags</span>
                  <p>{wishlistData.meta_tags
                  }</p>
                </div>
                <div className="">
                  <span>Meta Published Date</span>
                  <p>{wishlistData.meta_published_date ? moment(wishlistData.meta_published_date).format("DD-MM-YYYY HH:mm:ss") : "-"
                  }</p>
                </div>
                <div className="">
                  <span>Meta Description</span>
                  <p>{wishlistData?.meta_description ? wishlistData?.meta_description : "-"}</p>
                </div>
              </div>

              <div className="col-12 col-lg-6">
                <div className="">
                  <span>Meta Published Status</span>
                  <p>{wishlistData.meta_published ? "true" : "false"
                  }</p>
                </div>

                <div className="d-flex gap-5 flex-wrap">
                  <div className="">
                    <span>Approved At</span>
                    <p>{wishlistData.approvedAt ? moment(wishlistData.approvedAt).format("DD-MM-YYYY HH:mm:ss") : "-"
                    }</p>
                  </div>{" "}
                  <div className="">
                    <span>Updated At</span>
                    <p>{wishlistData.updatedAt ? moment(wishlistData.updatedAt).format("DD-MM-YYYY HH:mm:ss") : "-"
                    }


                    </p>
                  </div>
                </div>
                <div>
                  <span className="">Card Image</span>
                  <div className="blog_create_image_container mt-1 position-relative d-flex  justify-content-center align-items-center">

                    <>
                      <img src={wishlistData.card_image} className={"user-edit-profile-picture"} />
                      <button
                        type="button"
                        className="position-absolute border-0 bg-white d-flex align-items-center fs_10 top-0 end-0"
                        onClick={() => {
                          // downloadImage()
                        }}
                      >
                        <DownloadIcon width={8} />
                        <span className="ms-1">Download</span>
                      </button>
                    </>

                  </div>
                </div>
                <div className="mt-3 d-flex gap-5 flex-wrap">
                  <div>
                    <span className="">Display Image - 1</span>
                    <div className="blog_create_image_container mt-1 position-relative d-flex  justify-content-center align-items-center">
                      <>
                        <img src={wishlistData.display_image_1} className={"user-edit-profile-picture"} />
                        <button
                          type="button"
                          className="position-absolute border-0 bg-white d-flex align-items-center fs_10 top-0 end-0"
                          onClick={() => {
                            // downloadImage()

                          }}
                        >
                          <DownloadIcon width={8} />
                          <span className="ms-1">Download</span>
                        </button>
                      </>

                    </div>
                  </div>
                  <div>
                    <span className="">Display Image - 2</span>
                    <div className="blog_create_image_container mt-1 position-relative d-flex  justify-content-center align-items-center">
                      <>
                        <img src={wishlistData.display_image_2} className={"user-edit-profile-picture"} />
                        <button
                          type="button"
                          className="position-absolute border-0 bg-white d-flex align-items-center fs_10 top-0 end-0"
                          onClick={() => {
                            // downloadImage()
                          }}
                        >
                          <a
                            href={wishlistData.display_image_2}
                            download="file.png"
                            target={"_blank"}
                            className="text-decoration-none text-dark"
                          >
                            <DownloadIcon width={8} />
                            <span className="ms-1">Download</span></a>
                        </button>
                      </>

                    </div>
                  </div>
                  <div>
                    <span className="">Display Image - 3</span>
                    <div className="blog_create_image_container mt-1 position-relative d-flex  justify-content-center align-items-center">
                      <>
                        <img src={wishlistData.display_image_3} className={"user-edit-profile-picture"} />
                        <button
                          type="button"
                          className="position-absolute border-0 bg-white d-flex align-items-center fs_10 top-0 end-0"
                          onClick={() => {
                            // downloadImage()
                          }}
                        >
                          <DownloadIcon width={8} />
                          <span className="ms-1">Download</span>
                        </button>
                      </>

                    </div>
                  </div>
                </div>
              </div>
            </div>
            <CreateTable
              data={
                wishlistData?.content
              }
              editContent={false}
              tableTitle={"Content"}
              containerText="Add content"
              link={`/blog/edit/content-edit/`}
            />

          </div>
        </LoadAndError>
      )}
    </>
  );
};

export default BlogViewDetails;
