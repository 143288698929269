import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import RejectBtn from "../../../../components/buttons/rejectBtn";
import VersionHistoryBtn from "../../../../components/buttons/versionHistoryBtn";
import InnerContainer from "../../../../components/layouts/innerContainer/innerContainer";
import InnerLayout from "../../../../components/layouts/innerLayout/innerLayout";
import errorToast from "../../../../components/utilits/errorToast";
import successToast from "../../../../components/utilits/successToast";
import "../memberTradeBuyIn.css";
import * as opporTypeActions from "../../../../redux/actions/opportunityTypeActions";
import ApproveBtn from "../../../../components/buttons/approveBtn";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import LoadingSpinnerTable from "../../../../components/utilits/loadingSpinnerTable";
import LoadAndError from "../../../../components/utilits/LoadAndError";
import {
  approveMemberTradeBuyIn,
  readApprovalMemberTradeBuyIn,
  rejectMemberTradeBuyIn,
} from "../../../../redux/actions/memberTradeBuyInAction";

const MemberTradeBuyInApprovalView = () => {
  const params = useParams();
  const [updated, setUpdated] = useState(false);
  const navigate = useNavigate();
  const [approvalData, setApprovalData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const dispatch = useDispatch();
  useEffect(() => {
    setLoading(true);
    dispatch(readApprovalMemberTradeBuyIn(params.id, onSuccess, onError));
  }, [updated]);

  const approveMemberTradeBuyInFn = () => {
    setUpdated(false);
    dispatch(approveMemberTradeBuyIn(params.id, onSuccessApr, onErrorApr));
  };

  const rejectMemberTradeBuyInFn = () => {
    setUpdated(false);
    dispatch(rejectMemberTradeBuyIn(params.id, onRejectSuccess, onRejectError));
  };

  const onSuccess = (data) => {
    setApprovalData(data.data);
    console.log(data);
    setLoading(false);
    setError(false);
  };
  const onError = (data) => {
    errorToast(data.message);
    setLoading(false);
    setError(true);
  };
  const onSuccessApr = (data) => {
    setUpdated(true);
    successToast(data.message);
    console.log("data is", data.data);
    navigate("/opportunity/memberTrade-buy-in/approvals");
    setLoading(false);
    setError(false);
  };
  const onErrorApr = (data) => {
    console.log(data);
    errorToast(data.message);
    setLoading(false);
    setError(true);
  };
  const onRejectSuccess = (data) => {
    setUpdated(true);
    successToast("Rejected Successfully");
    navigate("/opportunity/memberTrade-buy-in/approvals");
    setLoading(false);
    setError(false);
  };
  const onRejectError = (data) => {
    console.log(data);
    errorToast(data.message);
    setLoading(false);
    setError(true);
  };
  const data = [
    {
      heading: "Approval ID",
      title: approvalData?.id == null ? "--" : approvalData?.id,
    },
    {
      heading: "User Id",
      title:
        approvalData?.new_data == null
          ? approvalData?.old_data?.user_id
          : approvalData?.new_data?.user_id,
    },
    {
      heading: "Opportunity Id",
      title:
        approvalData?.new_data == null
          ? approvalData?.old_data?.opportunity_id
          : approvalData?.new_data?.opportunity_id,
    },
    {
      heading: "Amount",
      title:
        approvalData?.new_data == null
          ? approvalData?.old_data?.amount
          : approvalData?.new_data?.amount,
    },
    {
      heading: "Disclosed Identity",
      title:
        approvalData?.new_data == null
          ? approvalData?.old_data?.disclosed_identity === true
            ? "true"
            : "false"
          : approvalData?.new_data?.disclosed_identity === true
          ? "true"
          : "false",
    },
    {
      heading: "Member Trade Buy In Id",
      title: approvalData?.old_data?.id === null
          ? approvalData?.new_data?.id
          : approvalData?.old_data?.id,
    },

    {
      heading: "Created At",
      title: moment(approvalData?.createdAt).format("DD-MM-YYYY HH:mm:ss"),
    },

    {
      heading: "Updated At",
      title: moment(approvalData?.updatedAt).format("DD-MM-YYYY HH:mm:ss"),
    },
    {
      heading: "Operation Type",
      title:
        approvalData?.new_data == null
          ? "Delete"
          : approvalData?.new_data && approvalData?.old_data
          ? "Edit"
          : approvalData?.old_data == null
          ? "Create"
          : "",
    },
  ];

  const buttonList = [
    <VersionHistoryBtn
      link={"/opportunity/memberTrade-buy-in/version-history"}
    />,
    <ApproveBtn approveFn={approveMemberTradeBuyInFn} />,
    <RejectBtn showModal={rejectMemberTradeBuyInFn} />,
  ];
  return (
    <InnerLayout buttons={buttonList}>
      <div className="opportunity-type-container">
        <InnerContainer
          error={error}
          oldData={approvalData?.old_data}
          newData={approvalData?.new_data}
        >
          {loading ? (
            <LoadingSpinnerTable />
          ) : (
            <LoadAndError loader={loading} error={error}>
              <div className="pt-3  ps-4 ps-lg-4 ms-3">
                {data.map((item, index) => (
                  <div className="mb-2">
                    <h5 className="dark_grey_color fs_13 fn_Montserrat fw_500 m-0">
                      {item.heading}
                    </h5>
                    <p className={` fs_13 fn_Nunito fw_500 dark_title_color  `}>
                      {item.title}
                    </p>
                  </div>
                ))}
              </div>
            </LoadAndError>
          )}
        </InnerContainer>
      </div>
    </InnerLayout>
  );
};

export default MemberTradeBuyInApprovalView;
