import {
  APPROVE_STATIC,
  CREATE_STATIC,
  DELETE_STATIC,
  EDIT_STATIC,
  REJECT_STATIC,
  STATIC_APPROVAL_EXPORT,
  STATIC_APPROVAL_TABLE,
  STATIC_APPROVAL_VIEW,
  STATIC_MAIN_EXPORT,
  STATIC_OPTIONS,
  STATIC_SUBMIT,
  STATIC_TABLE,
  STATIC_VERSION_EXPORT,
  STATIC_VERSION_HISTORY,
  STATIC_VERSION_HISTORY_TABLE,
  VIEW_STATIC,
} from "./types";

export const createStatic = (data, onSuccess, onError) => {
  return {
    type: CREATE_STATIC,
    data,
    onSuccess,
    onError,
  };
};

export const staticApprovalList = (data, page_no = 0, onSuccess, onError) => {
  return {
    type: STATIC_APPROVAL_TABLE,
    data,
    page_no,
    onSuccess,
    onError,
  };
};

export const StaticApprovalById = (id, onSuccess, onError) => {
  return {
    type: STATIC_APPROVAL_VIEW,
    id,
    onSuccess,
    onError,
  };
};
export const approveStatic = (id, onSuccess, onError) => {
  return {
    type: APPROVE_STATIC,
    id,
    onSuccess,
    onError,
  };
};

export const rejectStatic = (id, onSuccess, onError) => {
  return {
    type: REJECT_STATIC,
    id,
    onSuccess,
    onError,
  };
};
export const viewStaticList = (data, page_no = 0, onSuccess, onError) => {
  return {
    type: STATIC_TABLE,
    data,
    page_no,
    onSuccess,
    onError,
  };
};
export const viewStaticDetail = (id, onSuccess, onError) => {
  return {
    type: VIEW_STATIC,
    id,
    onSuccess,
    onError,
  };
};

export const editStatic = (id, data, onSuccess, onError) => {
  return {
    type: EDIT_STATIC,
    id,
    data,
    onSuccess,
    onError,
  };
};

export const deleteStatic = (id, onSuccess, onError) => {
  return {
    type: DELETE_STATIC,
    id,
    onSuccess,
    onError,
  };
};

export const versionHistoryStaticList = (data, page, onSuccess, onError) => {
  return {
    type: STATIC_VERSION_HISTORY_TABLE,
    data,
    page,
    onSuccess,
    onError,
  };
};

export const versionHistoryStaticDetail = (id, onSuccess, onError) => {
  return {
    type: STATIC_VERSION_HISTORY,
    id,
    onSuccess,
    onError,
  };
};
export const StaticOptions = (onSuccess, onError) => {
  return {
    type: STATIC_OPTIONS,
    onSuccess,
    onError,
  };
};

export const staticMainExportAction = (onSuccess, onError) => {
  return {
    type: STATIC_MAIN_EXPORT,
    onSuccess,
    onError,
  };
};
export const staticApprovalsExportAction = (onSuccess, onError) => {
  return {
    type: STATIC_APPROVAL_EXPORT,
    onSuccess,
    onError,
  };
};
export const staticVersionExportAction = (onSuccess, onError) => {
  return {
    type: STATIC_VERSION_EXPORT,
    onSuccess,
    onError,
  };
};
export const staticSubmit = (id, onSuccess, onError) => {
  return {
    type: STATIC_SUBMIT,
    id,
    onSuccess,
    onError,
  };
};
