import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import DeleteModal from "../../modals/deleteModal";
import { TableContainer } from "../../table/tableContainer";
import { tableAdminRolesHeader } from "../../table/tableData";
import TablePagination from "../../table/tablePagination";
import LoadingSpinnerTable from "../../utilits/loadingSpinnerTable";
import "./admintable.css";
import * as adminRolesActions from "../../../redux/actions/adminRoleActions";
import successToast from "../../utilits/successToast";
import errorToast from "../../utilits/errorToast";
import usePermissionChecker from "../../utilits/usePermissionChecker";
const AdminRolesTableContent = ({ page, search, changePage }) => {
  const access = usePermissionChecker("maker");
  const dispatch = useDispatch();
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);
  const [roleListData, setRoleListData] = useState([]);
  const [deleteData, setDeleteData] = useState({ name: "", id: "" });
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingTable, setLoadingTable] = useState(false);
  const [error, setError] = useState(false);
  const [pageData, setPageData] = useState({ noOfItems: 0, noOfPages: 1 })
  useEffect(() => {
    setLoadingTable(true);
    dispatch(
      adminRolesActions.viewAllAdminRole(
        {
          search: {
            role_id: search.id,
            role_name: search.name,
          },
        },
        page,
        onSuccess,
        onError
      )
    );
  }, [page, search, loading]);
  const handleDelete = () => {
    setLoading(true);
    dispatch(
      adminRolesActions.deleteAdminRole(
        deleteData.id,
        onDeleteSuccess,
        onDeleteError
      )
    );
  };
  const onDeleteSuccess = (data) => {
    console.log(data);
    successToast(data.data);
    setLoading(false);
    setShow(false);
  };
  const onDeleteError = (data) => {
    errorToast(data.message);
    setLoading(false);
  };
  const onSuccess = (data) => {
    setRoleListData(data.data.data);
    setPageData({
      noOfItems: data.data.count,
      noOfPages: data.data.pages
    })
    setError(false)
    setLoadingTable(false);
  };
  const onError = (data) => {
    errorToast(data.message);
    setError(true)
    setLoadingTable(false);
  };

  function handlePagination(type) {
    if (type === "+") {
      if (page + 1 < pageData.noOfPages) changePage("+");
    }
    else if (type === "-")
      if (page > 0) changePage("-");
  }
  return (
    <TableContainer maxWidth="100%" width="90%">
      <div className="adminuser_table__container ">
        {loadingTable && <LoadingSpinnerTable />}
        <table className={"adminuser_table"}>
          <thead className="adminuser_table__header">
            <tr className="adminuser_table__row">
              {tableAdminRolesHeader.map((item) => {
                return (
                  <th
                    key={item}
                    className={`${item === "Status" ? `${access ? "" : "text-start"} position-sticky end-0 text-start` : null
                      } text-nowrap adminuser_table__heading`}
                  >
                    {item}
                  </th>
                );
              })}
            </tr>
          </thead>
          {loadingTable === false && (
            <tbody className="adminuser_table__body">
              {roleListData.map((item, index) => {
                return (
                  <tr key={item.id} className="adminuser_table__row">
                    <td className="text-nowrap dark_title_color adminuser_table__data ">
                      {page > 0 ? 12 * page + (index + 1) : index + 1}
                    </td>
                    <td className="text-nowrap dark_title_color adminuser_table__data">
                      {item.id}
                    </td>
                    <td className="text-nowrap secondary_color adminuser_table__data">
                      {item.role_name}
                    </td>
                    <td className="text-nowrap   secondary_color adminuser_table__data adminrole_table_empty__data"></td>

                    <td className="text-nowrap  position-sticky end-0 adminuser_table__data">
                      <div className="d-flex w-100 justify-content-start align-items-center   gap-2">
                        <Link
                          className="text-decoration-none primary_color"
                          to={{
                            pathname: `/admin-roles/view/${item.id}`,
                          }}
                        >
                          View
                        </Link>{" "}
                        {access && item?.editable &&
                          <>
                            <span className="vr"></span>{" "}
                            <Link
                              className="text-decoration-none secondary_color"
                              to={{
                                pathname: `/admin-roles/edit/${item.id}`,
                              }}
                              state={item}
                            >
                              Edit
                            </Link>{" "}
                            <span className="vr"></span>{" "}
                            <button
                              onClick={() => {
                                setDeleteData({
                                  name: item.role_name,
                                  id: item.id,
                                });
                                handleShow();
                              }}
                              className="text-decoration-none primary_color light_red_color border-0 bg-transparent "
                            >
                              Delete
                            </button></>}
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          )}
        </table>
      </div>
      <DeleteModal
        loader={loading}
        handleClose={handleClose}
        deleteFn={handleDelete}
        data={deleteData}
        show={show}
      />
      <TablePagination
        paginate={handlePagination}
        currentPage={page}
        pageData={pageData}
      />
    </TableContainer>
  );
};

export default AdminRolesTableContent;
