import React, { useEffect, useRef, useState } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import InnerLayout from "../../../../components/layouts/innerLayout/innerLayout";
import InnerContainer from "../../../../components/layouts/innerContainer/innerContainer";
import SaveBtn from "../../../../components/buttons/saveBtn";
import InputField from "../../../../components/form/inputFields/inputField";
import ErrorMsg from "../../../../components/form/errorMsg";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import successToast from "../../../../components/utilits/successToast";
import errorToast from "../../../../components/utilits/errorToast";
import UploadIcon from "../../../../assets/icons/upload";
import CloseIcon from "../../../../assets/icons/closeIcon";
import { } from "../../../../redux/actions/howItWorksAction";
import { uploadAddressProofFrontImageAction } from "../../../../redux/actions/userAction";
import HomeCounterTable from "../../../../components/static-pages/home-page/tables/homeCounterTable";
import SubmitBtn from "../../../../components/buttons/submit";
import { viewHomeAction } from "../../../../redux/actions/homeActions";
import {
  campaignSubmitAction,
  editCampaignAction,
  viewCampaignAction,
} from "../../../../redux/actions/campaignActions";
import HomeCardsTable from "../../../../components/static-pages/home-page/tables/homeCardsTable";

const CampaignEdit = () => {
  const formRef = useRef();
  
  const navigate = useNavigate();
  const params = useParams();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [editloading, setEditLoading] = useState(false);
  const [error, setError] = useState(false);
  const [saved, setSaved] = useState(false);
  const [subLoading, setSubLoading] = useState(false);
  const [campaignData, setCampaign] = useState({});
  const [cardOneImage, setCardOneImage] = useState({
    img: "",
    error: false,
  });
  const [cardTwoImage, setCardTwoImage] = useState({
    img: "",
    error: false,
  });
  const [cardThreeImage, setCardThreeImage] = useState({
    img: "",
    error: false,
  });
  useEffect(() => {
    setLoading(true);
    dispatch(viewCampaignAction(params.id, onSuccess, onError));
  }, [editloading]);
  const onSuccess = (data) => {
    console.log(data.data);
    setCampaign(data.data);
    console.log("SUCCESS");
    setCardOneImage({ img: data.data.section1_card_image1, error: false });
    setCardThreeImage({ img: data.data.section1_card_image3, error: false });
    setCardTwoImage({ img: data.data.section1_card_image2, error: false });
    setLoading(false);
    setError(false);
  };
  const onError = (data) => {
    console.log("ERROR");
    setLoading(false);
    setError(true);
  };
  const initialValues = {
    navbarTitle: campaignData.header_title ? campaignData.header_title : "",
    headerTitle: campaignData.section1_title ? campaignData.section1_title : "",
    headerDesc: campaignData.section1_description
      ? campaignData.section1_description
      : "",
    headerButton: campaignData.section1_button
      ? campaignData.section1_button
      : "",
    cardOneTitle: campaignData.card_image1_title
      ? campaignData.card_image1_title
      : "",
    cardTwoTitle: campaignData.card_image2_title
      ? campaignData.card_image2_title
      : "",
    cardThreeTitle: campaignData.card_image3_title
      ? campaignData.card_image3_title
      : "",
    cardOneValues: campaignData.card1_description
      ? campaignData.card1_description
      : [],
    cardTwoValues: campaignData.card2_description
      ? campaignData.card2_description
      : [],
    cardThreeValues: campaignData.card3_description
      ? campaignData.card3_description
      : [],
    opporTitle: campaignData.opportunities_title
      ? campaignData.opportunities_title
      : "",
    opporSubtitle: campaignData.opportunities_subtitle
      ? campaignData.opportunities_subtitle
      : "",
    opporButton: campaignData.opportunities_button
      ? campaignData.opportunities_button
      : "",
    counterTitle: campaignData.section2_title
      ? campaignData.section2_title
      : "",
    counterDesc: campaignData.section2_description
      ? campaignData.section2_description
      : "",
    counters: campaignData.section2_counter
      ? campaignData.section2_counter
      : [],
  };

  const interestEditSchema = Yup.object().shape({
    navbarTitle: Yup.string().trim().required("Required"),
    headerTitle: Yup.string().trim().required("Required"),
    headerDesc: Yup.string().trim().required("Required"),
    headerButton: Yup.string().trim().required("Required"),
    cardOneTitle: Yup.string().trim().required("Required"),
    cardTwoTitle: Yup.string().trim().required("Required"),
    cardThreeTitle: Yup.string().trim().required("Required"),
    opporTitle: Yup.string().trim().required("Required"),
    opporSubtitle: Yup.string().trim().required("Required"),
    opporButton: Yup.string().trim().required("Required"),
    counterTitle: Yup.string().trim().required("Required"),
    counterDesc: Yup.string().trim().required("Required"),
    // counters: Yup.array()
    //   .min(5, "Five items are required")
    //   .required("Required"),
  });
  const handleSubmit = () => {
    if (!cardOneImage.img || !cardTwoImage.img || !cardThreeImage.img) {
      if (!cardOneImage.img) {
        setCardOneImage({ img: "", error: true });
      }
      if (!cardTwoImage.img) {
        setCardTwoImage({ img: "", error: true });
      }
      if (!cardThreeImage.img) {
        setCardThreeImage({ img: "", error: true });
      }
    }

    if (formRef.current) {
      formRef.current.handleSubmit();
    }
  };
  const onSubmit = (values, { resetForm }) => {
    if (cardOneImage.img && cardTwoImage.img && cardThreeImage.img) {
      setEditLoading(true);
      dispatch(
        editCampaignAction(
          params.id,
          {
            header_title: values.navbarTitle,
            section1_title: values.headerTitle,
            section1_description: values.headerDesc,
            section1_button: values.headerButton,
            section1_card_image1: cardOneImage.img,
            card_image1_title: values.cardOneTitle,
            section1_card_image2: cardTwoImage.img,
            card_image2_title: values.cardTwoTitle,
            section1_card_image3: cardThreeImage.img,
            card_image3_title: values.cardThreeTitle,
            card1_description: values.cardOneValues,
            card2_description: values.cardTwoValues,
            card3_description: values.cardThreeValues,
            opportunities_title: values.opporTitle,
            opportunities_subtitle: values.opporSubtitle,
            opportunities_button: values.opporButton,
            section2_title: values.counterTitle,
            section2_description: values.counterDesc,
            section2_counter: values.counters,
          },
          onEditSuccess,
          onEditError
        )
      );
    }
  };
  const onEditSuccess = (data) => {
    console.log(data);
    successToast(data.message);
    setSaved(true);
    setEditLoading(false);
    setError(false);
  };
  const onEditError = (data) => {
    console.log(data);
    errorToast(data.message);
    setEditLoading(false);
    setError(true);
  };

  const imageUpload = (e) => {
    if (e.target.name === "cardOneImage") {
      dispatch(
        uploadAddressProofFrontImageAction(
          e.target.files[0],
          onCardOneImageUploadSucess,
          onCardOneImageUploadError
        )
      );
    } else if (e.target.name === "cardTwoImage") {
      dispatch(
        uploadAddressProofFrontImageAction(
          e.target.files[0],
          onCardTwoImageUploadSucess,
          onCardTwoImageUploadError
        )
      );
    } else if (e.target.name === "cardThreeImage") {
      dispatch(
        uploadAddressProofFrontImageAction(
          e.target.files[0],
          onCardThreeImageUploadSucess,
          onCardThreeImageUploadError
        )
      );
    }
    e.target.value = null;
  };

  const onCardOneImageUploadSucess = (data) => {
    setCardOneImage({ img: data.data.data.location, error: false });
  };
  const onCardOneImageUploadError = (data) => {
    setCardOneImage({ img: "", error: true });
    // console.log(data);
  };
  const onCardTwoImageUploadSucess = (data) => {
    setCardTwoImage({ img: data.data.data.location, error: false });
  };
  const onCardTwoImageUploadError = (data) => {
    setCardTwoImage({ img: "", error: true });
    // console.log(data);
  };
  const onCardThreeImageUploadSucess = (data) => {
    setCardThreeImage({ img: data.data.data.location, error: false });
  };
  const onCardThreeImageUploadError = (data) => {
    setCardThreeImage({ img: "", error: true });
    // console.log(data);
  };
  const homeSubmit = () => {
    setSubLoading(true);
    dispatch(
      campaignSubmitAction(params.id, onAdminSubmitSucess, onAdminSubmitError)
    );
  };
  const buttonList = [
    <SaveBtn loader={editloading} submitFn={handleSubmit} />,
    <SubmitBtn saveHide={saved} submitFn={homeSubmit} />,
  ];

  function onAdminSubmitSucess(data) {
    successToast(data.message);
    setSubLoading(false);
    navigate("/static-pages/campaign");
  }
  function onAdminSubmitError(data) {
    setSubLoading(false);
    errorToast(data.message);
  }

  console.log();
  return (
    <InnerLayout buttons={buttonList}>
      <div className="user_view_container mb-4">
        <InnerContainer>
          <Formik
            innerRef={formRef}
            initialValues={initialValues}
            onSubmit={onSubmit}
            enableReinitialize={true}
            validationSchema={interestEditSchema}
          >
            {({ values, errors, setFieldValue }) => {
              return (
                <Form className="px-4 py-3 col-12  col-xxl-10">
                  <div className="d-flex flex-column gap-0 gap-lg-5 flex-lg-row fs_14">
                    <div className="col-12 col-lg-6 pe-0 pe-lg-5">
                      <h3 className="dark_grey_color fn_Montserrat fw_500 mt-4 mb-3 fs_13 py-2 rounded-1 white_shade_bg">
                        <span className="ps-2">Navbar</span>
                      </h3>
                      <InputField
                        id={"navbarTitle"}
                        label={"Navbar Title"}
                        type={"text"}
                        placeholder={"Enter home header Title"}
                        name={"navbarTitle"}
                      />
                      <h3 className="dark_grey_color fn_Montserrat fw_500 mt-4 mb-3 fs_13 py-2 rounded-1 white_shade_bg">
                        <span className="ps-2">Header </span>
                      </h3>
                      <InputField
                        id={"headerTitle"}
                        label={"Header Title"}
                        type={"text"}
                        placeholder={"Enter home header Title"}
                        name={"headerTitle"}
                      />
                      <label
                        className="mb-1 ms-2 fw_400"
                        htmlFor="headerSubtitle"
                      >
                        Header description
                      </label>
                      <Field name={"headerDesc"}>
                        {({ field }) => (
                          <textarea
                            style={{ height: "4.5rem" }}
                            id="headerSubtitle"
                            {...field}
                            type="text"
                            placeholder="Enter the home subtitle"
                            className={"w-100 mb-0 faq_message_container p-2 "}
                          ></textarea>
                        )}
                      </Field>
                      <ErrorMsg name={"headerSubtitle"} />
                      <InputField
                        id={"headerButton"}
                        label={"Header Button Text"}
                        type={"text"}
                        placeholder={"Enter header Button Text"}
                        name={"headerButton"}
                      />
                      <h3 className="dark_grey_color fn_Montserrat fw_500 mt-3 mb-3 fs_13 py-2 rounded-1 white_shade_bg">
                        <span className="ps-2">Header Cards Section</span>
                      </h3>
                      <InputField
                        id={"cardOneTitle"}
                        label={"Card One Title"}
                        type={"text"}
                        placeholder={"Enter title"}
                        name={"cardOneTitle"}
                      />
                      <div className="mb-3">
                        <span className="">Card One Image</span>
                        <div className="blog_create_image_container mt-2 position-relative d-flex  justify-content-center align-items-center">
                          <input
                            multiple={false}
                            style={{ visibility: "hidden" }}
                            type="file"
                            id="cardOneImage"
                            className="upload_document_input"
                            name="cardOneImage"
                            accept="image/* ,capture=camera"
                            onChange={(e) => {
                              imageUpload(e);
                            }}
                          />
                          {cardOneImage.img ? (
                            <>
                              <img
                                src={cardOneImage.img}
                                className={"user-edit-profile-picture"}
                              />
                              <button
                                type="button"
                                onClick={(e) => {
                                  setCardOneImage({ img: "", error: true });
                                }}
                                className="position-absolute border-0 cursor_pointer white_bg d-flex gap-2 py-1 px-2 user_edit_profile_picture_remove_container"
                              >
                                <CloseIcon fill="#666666" />
                                <span className="dark_grey_color fs_13 fn_Montserrat fw_500 ">
                                  Remove
                                </span>
                              </button>
                            </>
                          ) : (
                            <label
                              htmlFor="cardOneImage"
                              className="d-flex flex-column align-items-center mt-2 justify-content-center fs_12"
                            >
                              <UploadIcon width={30} height={30} />
                              Choose Picture
                            </label>
                          )}
                        </div>
                        {cardOneImage.error && (
                          <span className="fs_12 ms-2 text-danger fw-semibold fn_Nunito">
                            Required!
                          </span>
                        )}
                      </div>
                      <HomeCardsTable
                        setFieldValue={setFieldValue}
                        formikValues={values}
                        name={"cardOneValues"}
                        tableTitle={"Card One Description"}
                      />
                      <InputField
                        id={"cardTwoTitle"}
                        label={"Card Two Title"}
                        type={"text"}
                        placeholder={"Enter title"}
                        name={"cardTwoTitle"}
                      />
                      <div className="mb-3">
                        <span className="">Card Two Image</span>
                        <div className="blog_create_image_container mt-2 position-relative d-flex  justify-content-center align-items-center">
                          <input
                            multiple={false}
                            style={{ visibility: "hidden" }}
                            type="file"
                            id="cardTwoImage"
                            className="upload_document_input"
                            name="cardTwoImage"
                            accept="image/* ,capture=camera"
                            onChange={(e) => {
                              imageUpload(e);
                            }}
                          />
                          {cardTwoImage.img ? (
                            <>
                              <img
                                src={cardTwoImage.img}
                                className={"user-edit-profile-picture"}
                              />
                              <button
                                type="button"
                                onClick={(e) => {
                                  setCardTwoImage({ img: "", error: true });
                                }}
                                className="position-absolute border-0 cursor_pointer white_bg d-flex gap-2 py-1 px-2 user_edit_profile_picture_remove_container"
                              >
                                <CloseIcon fill="#666666" />
                                <span className="dark_grey_color fs_13 fn_Montserrat fw_500 ">
                                  Remove
                                </span>
                              </button>
                            </>
                          ) : (
                            <label
                              htmlFor="cardTwoImage"
                              className="d-flex flex-column align-items-center mt-2 justify-content-center fs_12"
                            >
                              <UploadIcon width={30} height={30} />
                              Choose Picture
                            </label>
                          )}
                        </div>
                        {cardTwoImage.error && (
                          <span className="fs_12 ms-2 text-danger fw-semibold fn_Nunito">
                            Required!
                          </span>
                        )}
                      </div>
                      <HomeCardsTable
                        setFieldValue={setFieldValue}
                        formikValues={values}
                        name={"cardTwoValues"}
                        tableTitle={"Card Two Description"}
                      />
                      <InputField
                        id={"cardThreeTitle"}
                        label={"Card Three Title"}
                        type={"text"}
                        placeholder={"Enter title"}
                        name={"cardThreeTitle"}
                      />
                      <div className="mb-3">
                        <span className="">Card Three Image</span>
                        <div className="blog_create_image_container mt-2 position-relative d-flex  justify-content-center align-items-center">
                          <input
                            multiple={false}
                            style={{ visibility: "hidden" }}
                            type="file"
                            id="cardThreeImage"
                            className="upload_document_input"
                            name="cardThreeImage"
                            accept="image/* ,capture=camera"
                            onChange={(e) => {
                              imageUpload(e);
                            }}
                          />
                          {cardThreeImage.img ? (
                            <>
                              <img
                                src={cardThreeImage.img}
                                className={"user-edit-profile-picture"}
                              />
                              <button
                                type="button"
                                onClick={(e) => {
                                  setCardThreeImage({ img: "", error: true });
                                }}
                                className="position-absolute border-0 cursor_pointer white_bg d-flex gap-2 py-1 px-2 user_edit_profile_picture_remove_container"
                              >
                                <CloseIcon fill="#666666" />
                                <span className="dark_grey_color fs_13 fn_Montserrat fw_500 ">
                                  Remove
                                </span>
                              </button>
                            </>
                          ) : (
                            <label
                              htmlFor="cardThreeImage"
                              className="d-flex flex-column align-items-center mt-2 justify-content-center fs_12"
                            >
                              <UploadIcon width={30} height={30} />
                              Choose Picture
                            </label>
                          )}
                        </div>
                        {cardThreeImage.error && (
                          <span className="fs_12 ms-2 text-danger fw-semibold fn_Nunito">
                            Required!
                          </span>
                        )}
                      </div>
                      <HomeCardsTable
                        setFieldValue={setFieldValue}
                        formikValues={values}
                        name={"cardThreeValues"}
                        tableTitle={"Card Three Description"}
                      />
                    </div>

                    <div className="col-12 col-lg-6 pe-0 pe-lg-5">
                      <h3 className="dark_grey_color fn_Montserrat fw_500 mt-3 mb-3 fs_13 py-2 rounded-1 white_shade_bg">
                        <span className="ps-2">Opportunity Section</span>
                      </h3>
                      <InputField
                        id={"opporTitle"}
                        label={"Opportunites Title"}
                        type={"text"}
                        placeholder={"Enter title"}
                        name={"opporTitle"}
                      />
                      <label className="mb-1 ms-2" htmlFor="opporSubtitle">
                        Opportunity Section SubTitle
                      </label>
                      <Field name={"opporSubtitle"}>
                        {({ field }) => (
                          <textarea
                            style={{ height: "3.5rem" }}
                            id="opporSubtitle"
                            s
                            {...field}
                            type="text"
                            placeholder="Enter Opportunities subtitle"
                            className={"w-100 mb-0 faq_message_container p-2 "}
                          ></textarea>
                        )}
                      </Field>
                      <ErrorMsg name={"opporSubtitle"} />
                      <InputField
                        id={"opporButton"}
                        label={"Opportunity Button Text"}
                        type={"text"}
                        placeholder={"Enter Opportunity Button Text"}
                        name={"opporButton"}
                      />
                      <h3 className="dark_grey_color fn_Montserrat fw_500 mt-3 mb-3 fs_13 py-2 rounded-1 white_shade_bg">
                        <span className="ps-2">Counter Section</span>
                      </h3>
                      <InputField
                        id={"counterTitle"}
                        label={"Counter Title"}
                        type={"text"}
                        placeholder={"Enter Counter title"}
                        name={"counterTitle"}
                      />{" "}
                      <label className="mb-1 ms-2" htmlFor="counterDesc">
                        Counter Description
                      </label>
                      <Field name={"counterDesc"}>
                        {({ field }) => (
                          <textarea
                            style={{ height: "3.5rem" }}
                            id="counterDesc"
                            {...field}
                            type="text"
                            placeholder="Enter counter Subtitle"
                            className={"w-100 mb-0 faq_message_container p-2 "}
                          ></textarea>
                        )}
                      </Field>
                      <ErrorMsg name={"counterDesc"} />
                      <HomeCounterTable
                        setFieldValue={setFieldValue}
                        formikValues={values}
                        name={"counters"}
                        tableTitle={"Counter Values"}
                      />
                    </div>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </InnerContainer>
      </div>
    </InnerLayout>
  );
};

export default CampaignEdit;
export const faqName = [
  { value: "Type1", label: "Type1" },
  { value: "Type2", label: "Type2" },
  { value: "Type3", label: "Type3" },
  { value: "Type4", label: "Type4" },
];
