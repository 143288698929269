import { authHeader } from "../config";
import { api } from "./api";
export const createBadgesApi = (params) => {
    return api.post("/api/admin/badge-faq/create-badge", params, {
        headers: {
            "x-access-token": localStorage.getItem("accessToken"),
            "Content-Type": "application/json",
        },
    });
};
export const approveBadgesApi = (params) => {
    return api.post("/api/admin/badge-faq/approve", JSON.stringify(params), {
        headers: {
            "x-access-token": localStorage.getItem("accessToken"),
            "Content-Type": "application/json",
        },
    });
};

export const rejectBadgesApi = (id, params) => {
    return api.post(
        `/api/admin/badge-faq/reject/${id}`,
        JSON.stringify(params),
        {
            headers: {
                "x-access-token": localStorage.getItem("accessToken"),
                "Content-Type": "application/json",
            },
        }
    );
};
export const viewBadgesDetailApi = (id, params) => {
    return api.post(
        `/api/admin/badge-faq/view-approval/${id}`,
        JSON.stringify(params),
        {
            headers: {
                "x-access-token": localStorage.getItem("accessToken"),
                "Content-Type": "application/json",
            },
        }
    );
};
export const viewBadgesListApi = (pageNo = 0, params) => {
    return api.post(
        `/api/admin/badge-faq/view-approvals?page_no=${pageNo}`,
        params,
        {
            headers: {
                "x-access-token": localStorage.getItem("accessToken"),
                "Content-Type": "application/json",
            },
        }
    );
};
export const editBadgesApi = (id, params) => {
    return api.post(
        `/api/admin/badge-faq/edit/${id}`,
        JSON.stringify(params),
        {
            headers: {
                "x-access-token": localStorage.getItem("accessToken"),
                "Content-Type": "application/json",
            },
        }
    );
};
export const deleteBadgesApi = (id, params) => {
    return api.post(
        `/api/admin/badge-faq/delete/${id}`,
        JSON.stringify(params),
        {
            headers: {
                "x-access-token": localStorage.getItem("accessToken"),
                "Content-Type": "application/json",
            },
        }
    );
};
export const badgesApprovalListApi = (pageNo, params) => {
    return api.post(
        `/api/admin/badge-faq/view-checker?page_no=${pageNo}`,
        JSON.stringify(params),
        {
            headers: {
                "x-access-token": localStorage.getItem("accessToken"),
                "Content-Type": "application/json",
            },
        }
    );
};
export const badgesApprovalByIdApi = (id, params) => {
    return api.post(
        `/api/admin/badge-faq/view-checker/${id}`,
        JSON.stringify(params),
        {
            headers: {
                "x-access-token": localStorage.getItem("accessToken"),
                "Content-Type": "application/json",
            },
        }
    );
};
export const versionHistoryBadgesListApi = (pageNo, params) => {
    return api.post(
        `/api/admin/badge-faq/view-version?page_no=${pageNo}`,
        JSON.stringify(params),
        {
            headers: {
                "x-access-token": localStorage.getItem("accessToken"),
                "Content-Type": "application/json",
            },
        }
    );
};
export const versionHistoryBadgesDetailApi = (id, params) => {
    return api.post(
        `/api/admin/badge-faq/view-version/${id}`,
        JSON.stringify(params),
        {
            headers: {
                "x-access-token": localStorage.getItem("accessToken"),
                "Content-Type": "application/json",
            },
        }
    );
};
export const badgesTableExportApi = (params) => {
    return api.post(
        `/api/admin/badge-faq/export-main-badge`,
        JSON.stringify(params),
        {
            headers: {
                "x-access-token": localStorage.getItem("accessToken"),
                "Content-Type": "application/json",
            },
        }
    );
};
export const badgesApprovalsExportApi = (params) => {
    return api.post(
        `/api/admin/badge-faq/export-approval-badge`,
        JSON.stringify(params),
        {
            headers: {
                "x-access-token": localStorage.getItem("accessToken"),
                "Content-Type": "application/json",
            },
        }
    );
};
export const badgesVersionExportApi = (params) => {
    return api.post(
        `/api/admin/badge-faq/export-version-badge`,
        JSON.stringify(params),
        {
            headers: {
                "x-access-token": localStorage.getItem("accessToken"),
                "Content-Type": "application/json",
            },
        }
    );
};
export const badgesSubmitApi = (id, params) => {
    return api.post(`/api/admin/badge-faq/on-submit/${id}`, params, {
        headers: {
            "x-access-token": localStorage.getItem("accessToken"),
            "Content-Type": "application/json",
        },
    });
};
