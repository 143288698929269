import React, { useRef, useEffect, useState } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import InnerLayout from "../../../../components/layouts/innerLayout/innerLayout";
import InnerContainer from "../../../../components/layouts/innerContainer/innerContainer";
import { useNavigate } from "react-router-dom";
import SaveBtn from "../../../../components/buttons/saveBtn";
import InputField from "../../../../components/form/inputFields/inputField";
import ErrorMsg from "../../../../components/form/errorMsg";
import {
  createFaq,
  faqOptions,
  FaqOptions,
} from "../../../../redux/actions/faqAction";
import { useDispatch, useSelector } from "react-redux";
import successToast from "../../../../components/utilits/successToast";
import errorToast from "../../../../components/utilits/errorToast";
import {
  createStatic,
  staticSubmit,
} from "../../../../redux/actions/staticAction";
import CreateTable from "../../../../components/opportunity/opportunties/tables/opporttunitiesCreateTable";
import OpportunitiesContentSection from "../../../../components/opportunity/opportunties/editSection/opportunitiesContentSection";
import * as opportunityEditorActions from "../../../../redux/actions/opportunityEditorActions";
import { blogCategoryOptionsAction } from "../../../../redux/actions/blogCategoryActions";
import SubmitBtn from "../../../../components/buttons/submit";
const StaticCreate = () => {
  const navigate = useNavigate();
  const formRef = useRef();
  const dispatch = useDispatch();
  const [submitData, setSubmitData] = useState();
  const [loading, setLoading] = useState(true);
  const initialValues = {
    name: "",
    content: [],
  };
  const interestEditSchema = Yup.object().shape({
    name: Yup.string().trim().required("Required"),
  });

  const handleSubmit = () => {
    if (formRef.current) {
      formRef.current.handleSubmit();
    }
  };
  const onSubmit = (values, { resetForm }) => {
    console.log(values);
    dispatch(
      createStatic(
        {
          screen_name: values.name,
          content: opportunityJsonReducer.data,
        },
        onSuccess,
        onError
      )
    );
  };
  const onSuccess = (data) => {
    successToast(data.message);
    setSubmitData(data.data.id);
    setSaveHide(true);
    navigate(`/static-pages/static-screen/edit/${data.data.id}`);
  };
  const onError = (data) => {
    errorToast(data.data);
  };

  useEffect(() => {
    dispatch(opportunityEditorActions.clearOppJson());
    setLoading(true);
    dispatch(blogCategoryOptionsAction(onSuccessDrop, onErrorDrop));
  }, []);

  const onSuccessDrop = (data) => {
    setLoading(false);
    console.log("SUCCESS");
  };
  const onErrorDrop = (data) => {
    setLoading(false);
  };

  const opportunityJsonReducer = useSelector(
    (state) => state.opportunityJsonReducer
  );
  function addNewSection() {
    dispatch(opportunityEditorActions.addSection());
  }
  function deleteSection(index) {
    console.log("deleteSection", index);
    dispatch(opportunityEditorActions.deleteSection(index));
  }

  //on submit api
  console.log(submitData);
  const [saveHide, setSaveHide] = useState(false);

  const submitFnc = () => {
    dispatch(staticSubmit(submitData, onSubmitSuccess, onSubmitError));
  };
  const onSubmitSuccess = (data) => {
    console.log(data);
    // navigate("/static-pages/static-screen");
    successToast(data.message);
  };
  const onSubmitError = (data) => {
    console.log("ERROR==================", data);
    errorToast(data.message);
  };

  const buttonList = [
    !saveHide && <SaveBtn submitFn={handleSubmit} loader={loading} />,
    // <SubmitBtn submitFn={submitFnc} saveHide={saveHide} />,
  ];
  return (
    <InnerLayout buttons={buttonList}>
      {!loading && (
        <div div className="user_view_container">
          <InnerContainer>
            <div className="px-5">
              <Formik
                innerRef={formRef}
                initialValues={initialValues}
                onSubmit={onSubmit}
                enableReinitialize={true}
                validationSchema={interestEditSchema}
              >
                {({ formik, handleChange, values, setFieldValue }) => {
                  return (
                    <Form className="">
                      <div className="col-12 col-sm-8 col-lg-6 col-xxl-3">
                        <InputField
                          id={"name"}
                          label={"Name"}
                          type={"text"}
                          placeholder={"Enter Name here"}
                          name={"name"}
                        />
                      </div>
                      <div className={`w-100`}>
                        {/* <CreateTable
                        data={opportunityJsonReducer.data}
                        editContent={false}
                        tableTitle={"Content"}
                        containerText="Add content"
                        link={`/blog/edit/content-edit/`}
                      /> */}
                        {opportunityJsonReducer?.data?.subsections?.length !==
                          0 &&
                          opportunityJsonReducer?.data?.map((item, index) => {
                            return (
                              <OpportunitiesContentSection
                                key={item.id}
                                index={index}
                                type="create"
                                // updateSectionData={handleSectionSubmit}
                                numberOfSection={
                                  opportunityJsonReducer.data.length
                                }
                                deleteSection={deleteSection}
                                addNewSection={addNewSection}
                              />
                            );
                          })}
                      </div>
                    </Form>
                  );
                }}
              </Formik>
            </div>
          </InnerContainer>
        </div>
      )}
    </InnerLayout>
  );
};

export default StaticCreate;
export const faqName = [
  { value: "Type1", label: "Type1" },
  { value: "Type2", label: "Type2" },
  { value: "Type3", label: "Type3" },
  { value: "Type4", label: "Type4" },
];
