import React, { useEffect, useRef, useState } from "react";
import "./opportunities.css";
import InnerLayout from "../../../components/layouts/innerLayout/innerLayout";
import OpportunitiesReadHeadTag from "../../../components/opportunity/opportunties/opportunitiesRead/opportunitiesReadHeadTag";
import OpportunitiesReadTitle from "../../../components/opportunity/opportunties/opportunitiesRead/opportunitiesReadTitle";
import OpportunitiesList from "../../../components/opportunity/opportunties/opportunitiesRead/opportunitiesSectionList";
import OpportunitiesOverview from "../../../components/opportunity/opportunties/opportunitiesRead/opportunities-overview";
import Financials from "../../../components/opportunity/opportunties/opportunitiesRead/opportunitiesFinancials";
import OpportunitiesKeyhighlights from "../../../components/opportunity/opportunties/opportunitiesRead/opportunitiesKeyHighlights";
import OpportunitiesNote from "../../../components/opportunity/opportunties/opportunitiesRead/opportunitiesNotes";
import Resources from "../../../components/opportunity/opportunties/opportunitiesRead/opportunitiesResources";
import Faqs from "../../../components/opportunity/opportunties/opportunitiesRead/opportunitiesFaq";
import Something from "../../../components/opportunity/opportunties/opportunitiesRead/opportunities-card-header";
import AnalyticsBtn from "../../../components/buttons/analyticsBtn";
import ShareBtn from "../../../components/buttons/sharableBtn";
import VersionHistoryBtn from "../../../components/buttons/versionHistoryBtn";
import DeleteBtn from "../../../components/buttons/deleteBtn";
import EditBtn from "../../../components/buttons/editBtn";
import PauseBtn from "../../../components/buttons/pauseBtn";
import OpportunitiesBottomNavbar from "../../../components/opportunity/opportunties/opportunitiesRead/opportunitiesBottomNavbar";
import Slider from "../../../components/opportunity/opportunties/opportunitiesRead/Carousel";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  approveOpportunity,
  fetchOpportunitiesDetails,
  opportunitiesApprovalsView,
  rejectOpportunity,
} from "../../../redux/actions/opportunitiesActions";
import errorToast from "../../../components/utilits/errorToast";
import InsightsCardSection from "../../../components/opportunity/opportunties/opportunitiesRead/InsightsCardSection";
import Opportunity_Card from "../../../components/opportunity/opportunties/opportunitiesRead/opportunityCard/opportunity_Card";
import OpportunitiesViewInnerContainer from "../../../components/layouts/innerContainer/opportunitiesViewInnerContainer";
import ApprovalsBtn from "../../../components/buttons/approvalsBtn";
import ApproveBtn from "../../../components/buttons/approveBtn";
import successToast from "../../../components/utilits/successToast";
import { Link } from "react-scroll";
import StickyBox from "react-sticky-box";
import styles from "../../../components/opportunity/opportunties/opportunitiesRead/opportunitiesReadHeadTag";
import RejectBtn from "../../../components/buttons/rejectBtn";
import LoadingSpinnerTable from "../../../components/utilits/loadingSpinnerTable";
import FaqFilter from "../../../components/filter/static-pages filter/faqFilter";
import LoadAndError from "../../../components/utilits/LoadAndError";
import PublishBtn from "../../../components/buttons/publishBtn";
import ArrowRightIcon from "../../../assets/icons/arrowrightIcon";
import { JSONTree } from "react-json-tree";
const OpportunitiesApprovalView = ({ id = "container" }) => {
  const params = useParams();
  const dispatch = useDispatch();
  const [opportunitiesDetails, setOpportunitiesDetails] = useState([]);
  const [operationType, setOperationType] = useState("");
  const [howItWorksData, setHowItWorksData] = useState({});
  const [opporApprovalId, setOpporApprovalId] = useState([]);
  const [approved, setApproved] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [old_data, setOld_Data] = useState({});
  const [new_data, setNew_Data] = useState({});
  const [newData, setNewData] = useState({});
  const [oldData, setOldData] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    setLoading(true);
    dispatch(opportunitiesApprovalsView(params?.id, onSuccess, onError));
  }, []);
  useEffect(() => {
    var flag = false;
    if (newData == null) {
      setNew_Data({});
      flag = true;
      if (oldData != null) {
        setOld_Data(oldData);
      }
    }
    if (oldData == null) {
      if (newData != null) {
        setNew_Data(newData);
      }
      setOld_Data({});
      flag = true;
    }

    // if(!flag){

    // }
    if (oldData != null && newData != null) {
      var changedOldData = {};
      var changedNewData = {};
      var arrayList = oldData == null || oldData == {} ? newData : oldData;
      for (const key in arrayList) {
        if (newData[key] != oldData[key]) {
          changedOldData[key] = oldData[key];
          changedNewData[key] = newData[key];
        }
      }
      setNew_Data(changedNewData);
      setOld_Data(changedOldData);
    }
  }, [JSON.stringify(oldData), JSON.stringify(newData)]);

  const onSuccess = (data) => {
    setOpporApprovalId(data.data.id);
    setApproved(data?.data?.approved)
    setOpportunitiesDetails(
      data.data.new_data === null ? data.data.old_data : data.data.new_data
    );
    setOperationType(
      data?.data?.new_data == null
        ? "Delete"
        : data?.data?.new_data && data?.data?.old_data
          ? "Edit"
          : data?.data?.old_data == null
            ? "Create"
            : ""
    );
    setNewData(data.data.new_data);
    setOldData(data.data.old_data);
    setLoading(false);
    setError(false);
  };
  console.log(opporApprovalId);

  const onError = (data) => {
    console.log(data);
    errorToast(data.data.data);
    setLoading(false);
    setError(true);
  };
  const approveOppor = () => {
    dispatch(approveOpportunity(params?.id, onApproveSuccess, onApproveError));
  };
  function onApproveSuccess(data) {
    console.log(data);
    successToast("Opportunity Approved successfully");
    setApproved("approved")
    setLoading(false);
    setError(false);
  }
  function onApproveError(data) {
    console.log(data);
    errorToast(data.data);
    setLoading(false);
    setError(true);
  }
  const rejectOppor = () => {
    dispatch(rejectOpportunity(params?.id, onRjectSuccess, onRjectError));
  };
  function onRjectSuccess(data) {
    console.log(data);
    successToast("Opportunity Rejectd successfully");
    navigate("/opportunity/opportunities/approvals/");
    setLoading(false);
    setError(false);
  }
  function onRjectError(data) {
    console.log(data);
    errorToast(data.data);
    setLoading(false);
    setError(true);
  }

  const containerRef = useRef();
  const buttonList = [
    // <ShareBtn />,
    // <AnalyticsBtn link={`/opportunities/analytics/${opportunitiesDetails?.id}`} />,
    // <EditBtn link={`/opportunities/edit/${params?.id}`} />,
    <VersionHistoryBtn link="/opportunity/opportunities/version-history" />,
    <PublishBtn id={params.id} disabled={approved === "approved"} />,
    approved === "pending" && <ApproveBtn approveFn={approveOppor} />,
    // <ApproveBtn approveFn={approveOppor} />,
    approved === "pending" && <RejectBtn showModal={rejectOppor} />,
  ];
  const data = [];
  const theme = {
    scheme: "monokai",
    author: "wimer hazenberg (http://www.monokai.nl)",
    base00: "#272822",
    base01: "#383830",
    base02: "#49483e",
    base03: "#75715e",
    base04: "#a59f85",
    base05: "#f8f8f2",
    base06: "#f5f4f1",
    base07: "#f9f8f5",
    base08: "#f92672",
    base09: "#464646",
    base0A: "#f4bf75",
    base0B: "#464646",
    base0C: "#a1efe4",
    base0D: "#464646",
    base0E: "#ae81ff",
    base0F: "#cc6633",
  };
  console.log(opportunitiesDetails);
  return (
    <InnerLayout buttons={buttonList}>
      <OpportunitiesViewInnerContainer
        error={error}
        oldData={opportunitiesDetails?.old_data}
        newData={opportunitiesDetails?.new_data}
        containerClassName="oppor_edit_inner__container position-relative"
        overflowClassName=""
      >
        {" "}
        {loading ? (
          <LoadingSpinnerTable />
        ) : (
          <LoadAndError loader={loading} error={error}>
            <div className="w-100 fn_Montserrat position-relative">
              <div
                ref={containerRef}
                id={id}
                className="opportunities_over_flow "
              >
                <div className="row ps-1 ms-1 ps-lg-4 ms-lg-1">
                  <div className=" my-2">
                    <div>Approval Id</div>
                    <div>{opporApprovalId}</div>
                  </div>
                  <div className=" my-2">
                    <div>Opportunities Id</div>
                    {newData === null ? oldData?.id : newData?.id}
                  </div>
                </div>
                <OpportunitiesReadHeadTag data={opportunitiesDetails?.tags} />
                <div className=" mt-md-0 pt-md-0 p-3">
                  <Slider
                    operationType={operationType}
                    data={opportunitiesDetails}
                  />
                  <OpportunitiesReadTitle data={opportunitiesDetails} />
                  <OpportunitiesList data={opportunitiesDetails?.content} />
                  {opportunitiesDetails?.content?.map((item) => {
                    return (
                      <div
                        className="d-lg-flex ms-lg-5 mt-lg-3 mt-3 justify-content-center"
                        id="overview"
                      >
                        <div className="col-lg-3  me-lg-5">
                          <StickyBox offsetBottom={30}>
                            <h3 className="mb-4 fn_Montserrat fw_600 secondary_color pt-3">
                              {item?.section_heading}
                            </h3>
                            <ul
                              className={`d-lg-block d-none list-unstyled p-0`}
                            >
                              {item?.subsections.map((subItem) => {
                                return (
                                  <Link
                                    smooth={false}
                                    to={subItem.title}
                                    activeClass="active"
                                    spy={true}
                                    isDynamic={true}
                                    // offset={-130}
                                    className="text-decoration-none  cursor_pointer"
                                  >
                                    <li className="mb-2 fn_Montserrat fw_600 light_green_color">
                                      {subItem.title}
                                    </li>
                                  </Link>
                                );
                              })}
                            </ul>
                          </StickyBox>
                        </div>
                        <div className="col-lg-6 col-12  ms-lg-5">
                          {" "}
                          {item?.subsections.map((subsectionsItems) => {
                            return (
                              <div className="" id={subsectionsItems?.title}>
                                <h4
                                  className={`${styles.overview_title} fs_20  fn_Montserrat fw_400 light_green_color bor w-25 pb-2 pt-4`}
                                >
                                  {subsectionsItems?.title}
                                </h4>
                                {subsectionsItems?.content.map(
                                  (contentItem) => {
                                    if (contentItem?.type === "text")
                                      return (
                                        <div
                                          className="fn_Nunito fs_15 popup_title_color fw_600 p-0 ms-3"
                                          dangerouslySetInnerHTML={{
                                            __html: contentItem?.value,
                                          }}
                                        ></div>
                                      );
                                    else if (contentItem?.type === "table") {
                                      // if (contentItem?.table_type === "type1") {
                                      //   return <>table</>;
                                      // } '

                                      if (contentItem?.table_type) {
                                        return (
                                          <>
                                            {" "}
                                            {contentItem?.heading.col1 ? (
                                              <>
                                                <div className="d-flex mb-3 gap-lg-5">
                                                  <div className="col-lg-5 col-6 col-md-6 col-sm-6">
                                                    <span className="secondary_color fn_Nunito fw_600 fs_16 ">
                                                      {
                                                        contentItem?.heading
                                                          .col1
                                                      }
                                                    </span>
                                                  </div>

                                                  <div className="col-lg-5 col-6 col-md-6 col-sm-6  d-flex flex-column">
                                                    <span className="secondary_color fn_Montserrat fw_600 fs_16   ps-3 ps-lg-0 ">
                                                      {
                                                        contentItem?.heading
                                                          .col2
                                                      }
                                                    </span>
                                                  </div>
                                                </div>
                                                <hr className="w-100" />{" "}
                                              </>
                                            ) : null}
                                            {contentItem?.body.map(
                                              (tableContent) => {
                                                return (
                                                  <div className="d-flex mb-3 gap-lg-5">
                                                    <div className="col-lg-5 col-6 col-md-6 col-sm-6  d-flex flex-column">
                                                      <span className="plankton_green fn_Nunito fw_600 fs_16   ">
                                                        {
                                                          tableContent.col1_title
                                                        }
                                                      </span>
                                                      {tableContent.col1_caption && (
                                                        <span className="fs_14 fw_400 fn_Nunito black_color">
                                                          {
                                                            tableContent.col1_caption
                                                          }
                                                        </span>
                                                      )}
                                                    </div>
                                                    <div className="col-lg-5 col-6 col-md-6 col-sm-6  d-flex flex-column">
                                                      <span className="secondary_color fn_Montserrat fw_600 fs_16   ">
                                                        {
                                                          tableContent.col2_title
                                                        }{" "}
                                                        <span className="light_green_color"></span>
                                                      </span>
                                                      {tableContent.col2_caption && (
                                                        <span className="fs_14 fw_400 fn_Nunito popup_title_color   fs_md_12">
                                                          {
                                                            tableContent.col2_caption
                                                          }
                                                        </span>
                                                      )}
                                                    </div>
                                                  </div>
                                                );
                                              }
                                            )}
                                            <hr className="w-100" />
                                            <div className="d-flex mb-3 gap-lg-5">
                                              <div className="col-lg-5 col-6 col-md-6 col-sm-6">
                                                <span className="secondary_color fn_Montserrat fw_600 fs_16 ">
                                                  {contentItem?.footer.col1}
                                                </span>
                                              </div>
                                              <div className="col-lg-5 col-6 col-md-6 col-sm-6">
                                                <span className="secondary_color fn_Montserrat fw_600 fs_16  ">
                                                  {contentItem?.footer.col2}
                                                </span>
                                              </div>
                                            </div>
                                            <div
                                              dangerouslySetInnerHTML={{
                                                __html:
                                                  contentItem?.footerNotes,
                                              }}
                                            ></div>
                                            {/* <ol
                                  className={` popup_title_color fs_15  fn_Nunito fw_600 p-0 ms-3`}
                                >
                                  <li className="mb-2"> {text}</li>
                                  <li className="mb-2"> {text}</li>
                                  <li className="mb-2"> {text}</li>
                                </ol> */}
                                          </>
                                        );
                                      }
                                    } else if (contentItem?.type === "type") {
                                      <OpportunitiesNote data={contentItem} />;
                                    }
                                  }
                                )}

                                {/* <ul
                        className={`fn_Nunito fs_15 popup_title_color fw_600 p-0 ms-3`}
                      >
                        <li className="mb-2"> {item.detail}</li>
                        <li className="mb-2"> {item.detail}</li>
                        <li className="mb-2"> {item.detail}</li>
                      </ul> */}
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    );
                  })}

                  {/* <OpportunitiesList />
                                <OpportunitiesOverview />
                         
                            <Financials />
                            <OpportunitiesKeyhighlights />
                            <OpportunitiesNote /> */}
                  {/* <InsightsCardSection /> */}
                  {/* <Resources /> */}
                  <Faqs data={opportunitiesDetails?.faq} />
                </div>
                <Something />
                {/* <div className=" overflow_x_scroll scroll_none mx-3 px-3 my-4 row mx-auto justify-content-center py-3 gap-5">
              <Opportunity_Card data />
              <Opportunity_Card />
              <Opportunity_Card />
            </div> */}
                <div className="white_shade_bg  py-1 d-flex">
                  <div className="w-50">
                    <h5 className="fw_500 ps-4 dark_grey_color pt-2 text-nowrap fs_16 fn_Montserrat">
                      JSON Old Data
                    </h5>
                  </div>
                  <div className="w-50  d-none d-lg-block">
                    <h5 className="fw_500 ps-4  dark_grey_color pt-2 text-nowrap fs_16 fn_Montserrat">
                      JSON New Data
                    </h5>
                  </div>
                </div>
                <div className="row mt-3 ps-0 ps-lg-4">
                  <div className="col-12 col-lg-6">
                    <div className="notify_me_aprvls_vrsn_hstry_data_container  mt-3 pt-3 px-3">
                      <div className="d-flex justify-content-between">
                        <h5 className="fs_13 fn_Nunito fw_400 dark_title_color text-nowrap">
                          Old Data
                        </h5>
                        <ArrowRightIcon
                          width={25}
                          height={25}
                          fill={"#A5A5A5"}
                        />
                      </div>
                      {opportunitiesDetails?.old_data === null ? (
                        <div>Empty data</div>
                      ) : (
                        <>
                          <JSONTree theme={theme} data={old_data} />;
                        </>
                      )}
                    </div>
                  </div>
                  <div className="col-12 mt-5 mt-lg-0 col-lg-6">
                    <h5 className="fw_500 ps-4 d-block d-lg-none white_shade_bg  dark_grey_color py-3 text-nowrap fs_16 fn_Montserrat">
                      JSON New Data
                    </h5>
                    <div className="notify_me_aprvls_vrsn_hstry_data_container    mt-4 mt-lg-3 pt-3 px-3">
                      <div className="d-flex justify-content-between">
                        <h5 className="fs_13 fn_Nunito fw_400 dark_title_color py-2 text-nowrap">
                          New Data
                        </h5>
                        <ArrowRightIcon
                          width={25}
                          height={25}
                          fill={"#A5A5A5"}
                        />
                      </div>
                      <div className="h-100">
                        {opportunitiesDetails?.new_data === null ? (
                          <div>Empty data</div>
                        ) : (
                          <>
                            <JSONTree theme={theme} data={new_data} />;
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className="pt-auto">
            <OpportunitiesBottomNavbar position={containerRef} id={id} />
          </div> */}
            </div>
          </LoadAndError>
        )}
      </OpportunitiesViewInnerContainer>
    </InnerLayout>
  );
};

export default OpportunitiesApprovalView;
