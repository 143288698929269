import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import AdminRoleCreate from "../modules/admin/admin-roles/admin-role/admin-role-create";
import AdminRoleApprovalsTable from "../modules/admin/admin-roles/admin-role-approvals/admin-role-approvals-table";
import AdminRoleVersionTable from "../modules/admin/admin-roles/admin-role-version-history/admin-role-version-table";
import AdminRolesTable from "../modules/admin/admin-roles/admin-role/admin-role-table";
import AdminApprovalsTable from "../modules/admin/admin-users/admin-approvals/admin-approvals-table";
import AdminRoleEdit from "../modules/admin/admin-roles/admin-role/admin-role-edit";
import AdminRoleView from "../modules/admin/admin-roles/admin-role/admin-role-view";
import AdminCreate from "../modules/admin/admin-users/admin-user/admin-create";
import AdminEdit from "../modules/admin/admin-users/admin-user/admin-edit";
import AdminUsersTable from "../modules/admin/admin-users/admin-user/admin-users-table";
import AdminView from "../modules/admin/admin-users/admin-user/admin-view";
import AdminVersionHistoryTable from "../modules/admin/admin-users/admin-version-history/admin-version-history-table";
import AdminApprovalsVersionHistory from "../modules/admin/admin-users/admin-version-history/admin-version-history-view";
import Login from "../modules/auth/login";
import UserApprovalsTable from "../modules/user/user-approvals/user-approvals-table";
import UserEmailOtpTable from "../modules/user/user-userlist/user-email-otp-table";
import UserPhoneOtpTable from "../modules/user/user-userlist/user-phone-otp-table";
import UserView from "../modules/user/user-userlist/user-view";
import UserlistTable from "../modules/user/user-userlist/userlist-table";
import UserVersionTable from "../modules/user/user-version-history/user-version-history-table";
import UserVersionHistoryView from "../modules/user/user-version-history/user-version-history-view";
import OpportunityTypeRead from "../modules/opportunity/opportunity-type/opportunity-type-list/opportunity-type-read";
import OpportunityTypeEdit from "../modules/opportunity/opportunity-type/opportunity-type-list/opportunity-type-edit";
import OpportunityTypeCreate from "../modules/opportunity/opportunity-type/opportunity-type-list/opportunity-type-create";
import OpportunityTypeApprovalView from "../modules/opportunity/opportunity-type/opportunity-type-approvals/opportunity-type-approval-view";
import OpportunityTypeVersionHistoryView from "../modules/opportunity/opportunity-type/opportunity-version-history/opportunity-type-version-history";
import WishlistRead from "../modules/opportunity/wishlist/wishlist-list/wishlist-read";
import WishlistEdit from "../modules/opportunity/wishlist/wishlist-list/wishlist-edit";
import WishlistCreate from "../modules/opportunity/wishlist/wishlist-list/wishlist-create";
import WishlistApprovalView from "../modules/opportunity/wishlist/wishlist-approvals/wishlist-approval-view";
import WishlistVersionHistory from "../modules/opportunity/wishlist/wishlist-version-history/wishlist-version-history";
import NotifyMeRead from "../modules/opportunity/notify-me/notify-me-list/notify-me-read";
import NotifyMeEdit from "../modules/opportunity/notify-me/notify-me-list/notify-me-edit";
import NotifyMeCreate from "../modules/opportunity/notify-me/notify-me-list/notify-me-create";
import NotifyMeApprovalView from "../modules/opportunity/notify-me/notify-me-approvals/notify-me-approval-view";
import NotifyMeVersionHistory from "../modules/opportunity/notify-me/notify-me-version-history/notify-me-version-history";
import AdminApprovalView from "../modules/admin/admin-users/admin-approvals/admin-approval-view";
import UserResetPassEmailTable from "../modules/user/user-userlist/user-reset-pass-email-table";
import OpportunityTypeTable from "../modules/opportunity/opportunity-type/opportunity-type-list/opportunity-type-table";
import OpportunityTypeApprovalsTable from "../modules/opportunity/opportunity-type/opportunity-type-approvals/opportunity-type-approvals-table";
import OpportunityVersionTable from "../modules/opportunity/opportunity-type/opportunity-version-history/opportunity-version-table";
import WishlistTable from "../modules/opportunity/wishlist/wishlist-list/wishlist-table";
import WishlistApprovalsTable from "../modules/opportunity/wishlist/wishlist-approvals/wishlist-approvals-table";
import WishlistVersionTable from "../modules/opportunity/wishlist/wishlist-version-history/wishlist-version-table";
import NotifyMeTable from "../modules/opportunity/notify-me/notify-me-list/notify-me-table";
import NotifyMeApprovalsTable from "../modules/opportunity/notify-me/notify-me-approvals/notify-me-approvals-table";
import NotifyMeVersionTable from "../modules/opportunity/notify-me/notify-me-version-history/notify-me-version-table";
import AdminRoleVersionHistoryView from "../modules/admin/admin-roles/admin-role-version-history/admin-role-version-view";
import UserEdit from "../modules/user/user-userlist/user-edit";
import UserCreate from "../modules/user/user-userlist/user-create";
import UserApprovalsDetails from "../modules/user/user-approvals/user-approval-details";
import OpportunitiesTable from "../modules/opportunity/opportunities/opportunities-table";
import OpportunitiesContentEdit from "../modules/opportunity/opportunities/opportunities-content-edit";
import OpportunitiesCreate from "../modules/opportunity/opportunities/opportunities-create";
import OpportunitiesAnalytics from "../modules/opportunity/opportunities/opportunities-analytics";
import OpportunitiesRead from "../modules/opportunity/opportunities/opportunities-read";
import Sample from "../modules/sample";
import PrivateRoute from "./privateRoute";
import ProtectedRoute from "./protectedRoute";
import { ToastContainer } from "react-toastify";
import AdminRoleApprovalView from "../modules/admin/admin-roles/admin-role-approvals/admin-role-approvals-view";
import OpportunitiesEdit from "../modules/opportunity/opportunities/opportunitiesEdit";
import OpportunitiesApprovalTable from "../modules/opportunity/opportunities/opportunites-approval-table";
import ExpressInterestTable from "../modules/opportunity/express-interest/express-interest-list/express-interest-table";
import ExpressInterestView from "../modules/opportunity/express-interest/express-interest-list/express-interest-view";
import ExpressInterestEdit from "../modules/opportunity/express-interest/express-interest-list/express-interest-edit";
import ExpressInterestCreate from "../modules/opportunity/express-interest/express-interest-list/express-interest-create";
import ExpressInterestVersionHistoryTable from "../modules/opportunity/express-interest/express-interest-version-history/express-interest-version-history-table";
import ExpressInterestVersionHistoryDetails from "../modules/opportunity/express-interest/express-interest-version-history/express-interest-version-history-details";
import ExpressInterestApprovalsTable from "../modules/opportunity/express-interest/express-interest-approvals/express-interest-approvals-table";
import ExpressInterestApprovalsDetails from "../modules/opportunity/express-interest/express-interest-approvals/express-interest-approvals-details";
import OurTeamCreate from "../modules/StaticPagesAndHome/our-team/our-team-list/out-team-create";
import OurTeamEdit from "../modules/StaticPagesAndHome/our-team/our-team-list/our-team-edit";
import OurTeamRead from "../modules/StaticPagesAndHome/our-team/our-team-list/our-team-view";
import OurTeamTable from "../modules/StaticPagesAndHome/our-team/our-team-list/our-team-table";
import OurTeamApprovalTable from "../modules/StaticPagesAndHome/our-team/our-team-approvals/our-team-approvals-table";
import OurTeamVersionHistoryTable from "../modules/StaticPagesAndHome/our-team/our-team-version-history/our-team-version-history-table";
import OurTeamVersionHistoryView from "../modules/StaticPagesAndHome/our-team/our-team-version-history/our-team-version-history-view";
import OurTeamApprovalView from "../modules/StaticPagesAndHome/our-team/our-team-approvals/our-team-approval-view";
import TestimonialsCreate from "../modules/StaticPagesAndHome/testimonials/testimonials-list/testimonials-create";
import TestimonialsEdit from "../modules/StaticPagesAndHome/testimonials/testimonials-list/testimonials-edit";
import TestimonialsRead from "../modules/StaticPagesAndHome/testimonials/testimonials-list/testimonials-view";
import TestimonialsApprovalView from "../modules/StaticPagesAndHome/testimonials/testimonials-approvals/testimonials-approval-view";
import TestimonialsVersionHistoryView from "../modules/StaticPagesAndHome/testimonials/testimonials-version-history/testimonials-version-history-view";
import TestimonialsTable from "../modules/StaticPagesAndHome/testimonials/testimonials-list/testimonials-table";
import TestimonialsApprovalTable from "../modules/StaticPagesAndHome/testimonials/testimonials-approvals/testimonials-approvals-table";
import TestimonialsVersionHistoryTable from "../modules/StaticPagesAndHome/testimonials/testimonials-version-history/testimonials-version-history-table";
import LlpTable from "../modules/opportunity/llp/llp-list/llp-table";
import LlpCreate from "../modules/opportunity/llp/llp-list/llp-create";
import LlpEdit from "../modules/opportunity/llp/llp-list/llp-edit";
import LlpView from "../modules/opportunity/llp/llp-list/llp-view";
import Llpversioinhistoryview from "../modules/opportunity/llp/llp-version-history/llp-versioin-history-view";
import LlpVersionHistoryTable from "../modules/opportunity/llp/llp-version-history/llp-version-history-table";
import LlpApprovalsView from "../modules/opportunity/llp/llp-approvals/llp-approvals-view";
import LlpApprovalsTable from "../modules/opportunity/llp/llp-approvals/llp-approvals-table";
import OurAdvisorsTable from "../modules/StaticPagesAndHome/our-advisors/our-advisors-list/our-advisors-table";
import OurAdvisorsCreate from "../modules/StaticPagesAndHome/our-advisors/our-advisors-list/our-advisors-create";
import OurAdvisorsEdit from "../modules/StaticPagesAndHome/our-advisors/our-advisors-list/our-advisors-edit";
import OurAdvisorsRead from "../modules/StaticPagesAndHome/our-advisors/our-advisors-list/our-advisors-read";
import OurAdvisorsApprovalsView from "../modules/StaticPagesAndHome/our-advisors/our-advisors-approvals/ouradvisors-approvals-view";
import OurAdvisorApprovalsTable from "../modules/StaticPagesAndHome/our-advisors/our-advisors-approvals/ouradvisors-approvals-table";
import AdvisorVersionTableContent from "../components/static-pages/our-advisors/ouradvisor-version-table-content";
import Advisorversioinhistoryview from "../modules/StaticPagesAndHome/our-advisors/our-advisors-history/ouradvisors-versioin-history-view";
import OurPartnersTable from "../modules/StaticPagesAndHome/our-partners/our-partners-list/our-partners-table";
import OurPartnersEdit from "../modules/StaticPagesAndHome/our-partners/our-partners-list/our-partners-edit";
import OurPartnersCreate from "../modules/StaticPagesAndHome/our-partners/our-partners-list/our-partners-create";
import OurPartnerVersionHistoryTable from "../modules/StaticPagesAndHome/our-partners/our-partners-history/our-partner-version-history-table";
import OurPartnerVersionHistoryView from "../modules/StaticPagesAndHome/our-partners/our-partners-history/our-partner-version-history-view";
import OurPartnersApprovalTable from "../modules/StaticPagesAndHome/our-partners/our-partners-approvals/our-partners-table";
import OurPartnerApprovalTableContent from "../components/static-pages/our-partners/tables/our-partner-approval-table-content";
import OurPartnersApprovalView from "../modules/StaticPagesAndHome/our-partners/our-partners-approvals/our-partners-approval-view";
import ContactUsTable from "../modules/StaticPagesAndHome/contact-us/contact-us-list/contact-us-list-table";
import ContactUsCreate from "../modules/StaticPagesAndHome/contact-us/contact-us-list/contact-us-create";
import ContactUsEdit from "../modules/StaticPagesAndHome/contact-us/contact-us-list/contact-us-edit";
import ContactUsView from "../modules/StaticPagesAndHome/contact-us/contact-us-list/contact-us-view";
import FaqCategoryTable from "../modules/StaticPagesAndHome/faq-category/faq-category-list/faq-category-table";
import FaqCategoryView from "../modules/StaticPagesAndHome/faq-category/faq-category-list/faq-category-view";
import FaqCategoryEdit from "../modules/StaticPagesAndHome/faq-category/faq-category-list/faq-category-edit";
import FaqCategoryApprovalTable from "../modules/StaticPagesAndHome/faq-category/faq-category-approvals/faq-category-approvals-table";
import FaqCategoryApprovalsDetails from "../modules/StaticPagesAndHome/faq-category/faq-category-approvals/faq-category-approvals-details";
import FaqCategoryCreate from "../modules/StaticPagesAndHome/faq-category/faq-category-list/faq-category-create";
import FaqCategoryVersionHistoryTable from "../modules/StaticPagesAndHome/faq-category/faq-category-version-history/faq-category-version-history-table";
import FaqCategoryVersionHistoryDetails from "../modules/StaticPagesAndHome/faq-category/faq-category-version-history/faq-cateogry-version-history-details";
import FaqsTable from "../modules/StaticPagesAndHome/faq/faq-list/faq-table";
import FaqView from "../modules/StaticPagesAndHome/faq/faq-list/faq-view";
import FaqEdit from "../modules/StaticPagesAndHome/faq/faq-list/faq-edit";
import FaqApprovalTable from "../modules/StaticPagesAndHome/faq/faqs-approvals/faq-approvals-table";
import FaqsApprovalDetails from "../modules/StaticPagesAndHome/faq/faqs-approvals/faqs-approvals-details";
import FaqCreate from "../modules/StaticPagesAndHome/faq/faq-list/faq-create";
import FaqVersionHistoryTable from "../modules/StaticPagesAndHome/faq/faq-version-history/faq-version-history-table";
import FaqVersionHistoryDetails from "../modules/StaticPagesAndHome/faq/faq-version-history/faq-version-history-details";
import ContactUsApprovalsTable from "../modules/StaticPagesAndHome/contact-us/contact-us-approvals/contact-us-approvals-table";
import ContactUsApprovalView from "../modules/StaticPagesAndHome/contact-us/contact-us-approvals/contact-us-approvals-view";
import ContactUsVersionHistoryTable from "../modules/StaticPagesAndHome/contact-us/contact-us-version-history/contact-us-version-history";
import ContactUsVersionView from "../modules/StaticPagesAndHome/contact-us/contact-us-version-history/contact-us-version-view";
import MediaRecogonitionCreate from "../modules/StaticPagesAndHome/media-recogonition/media-recogonition-list/media-recogonition-create";
import MediaRecogonitionEdit from "../modules/StaticPagesAndHome/media-recogonition/media-recogonition-list/media-recogonition-edit";
import MediaRecogonitionRead from "../modules/StaticPagesAndHome/media-recogonition/media-recogonition-list/media-recogonition-view";
import MediaRecogonitionApprovalView from "../modules/StaticPagesAndHome/media-recogonition/media-recogonition-approvals/media-recogonition-approval-view";
import MediaRecogonitionVersionView from "../modules/StaticPagesAndHome/media-recogonition/media-recogonition-history/media-recogonition-version-view";
import MediaRecogonitionTable from "../modules/StaticPagesAndHome/media-recogonition/media-recogonition-list/media-recogonition-table";
import MediaRecogonitionApprovalsTable from "../modules/StaticPagesAndHome/media-recogonition/media-recogonition-approvals/media-recogonition-approval-table.js";
import MediaRecogonitionVersionTable from "../modules/StaticPagesAndHome/media-recogonition/media-recogonition-history/media-recogonition-version-table";
import AdvisorVersionHistoryTable from "../modules/StaticPagesAndHome/our-advisors/our-advisors-history/ouradvisors-history-table";
import NewsLetterTable from "../modules/StaticPagesAndHome/news-letter/news-letter-list/news-letter-list-table";
import NewsLetterApprovalTable from "../modules/StaticPagesAndHome/news-letter/news-letter-approvals/news-letter-approvals-table";
import NewsLetterVersionHistoryTable from "../modules/StaticPagesAndHome/news-letter/news-letter-version-history/news-letter-version-history";
import NewsLetterEdit from "../modules/StaticPagesAndHome/news-letter/news-letter-list/news-letter-edit";
import NewsLetterView from "../modules/StaticPagesAndHome/news-letter/news-letter-list/news-letter-view";
import NewsLetterCreate from "../modules/StaticPagesAndHome/news-letter/news-letter-list/news-letter-create";
import OurPartnerView from "../modules/StaticPagesAndHome/our-partners/our-partners-list/our-partner-view";
import NewsLetterApprovalView from "../modules/StaticPagesAndHome/news-letter/news-letter-approvals/news-letter-approvals-view";
import NewsLetterVersionView from "../modules/StaticPagesAndHome/news-letter/news-letter-version-history/news-letter-version-view";
import Error from "../404";
import { access } from "../components/utilits/access";
import StaticCreate from "../modules/StaticPagesAndHome/static-screen/static-list/static-create";
import StaticApprovalTable from "../modules/StaticPagesAndHome/static-screen/static-approvals/static-approvals-table";
import StaticApprovalDetails from "../modules/StaticPagesAndHome/static-screen/static-approvals/static-approvals-details";
import StaticTable from "../modules/StaticPagesAndHome/static-screen/static-list/static-table";
import StaticView from "../modules/StaticPagesAndHome/static-screen/static-list/static-view";
import StaticVersionHistoryTable from "../modules/StaticPagesAndHome/static-screen/static-version-history/static-version-history-table";
import StaticEdit from "../modules/StaticPagesAndHome/static-screen/static-list/static-edit";
import StaticVersionHistoryDetails from "../modules/StaticPagesAndHome/static-screen/static-version-history/static-version-history-details";
import OpportunitiesVersionHistoryTable from "../modules/opportunity/opportunities/opportunities-version-history/opportunities-version-history-table";
import BlogCategoryCreate from "../modules/StaticPagesAndHome/blog-category/blog-category-list/blog-category-create";
import BlogCategoryApprovalTable from "../modules/StaticPagesAndHome/blog-category/blog-category-approvals/blog-category-approvals-table";
import BlogCategoryTable from "../modules/StaticPagesAndHome/blog-category/blog-category-list/blog-category-table";
import BlogCategoryVersionHistoryTable from "../modules/StaticPagesAndHome/blog-category/blog-category-version-history/blog-category-version-history-table";
import BlogCategoryView from "../modules/StaticPagesAndHome/blog-category/blog-category-list/blog-category-view";
import BlogCategoryEdit from "../modules/StaticPagesAndHome/blog-category/blog-category-list/blog-category-edit";
import BlogCategoryApprovalsDetails from "../modules/StaticPagesAndHome/blog-category/blog-category-approvals/blog-category-approvals-details";
import BlogTable from "../modules/StaticPagesAndHome/blog/blog-list/blog-table";
import BlogApprovalTable from "../modules/StaticPagesAndHome/blog/blog-approvals/blog-approvals-table";
import BlogVersionHistoryTable from "../modules/StaticPagesAndHome/blog/blog-version-history/blog-version-history-table";
import BlogCategoryVersionHistoryDetails from "../modules/StaticPagesAndHome/blog-category/blog-category-version-history/blog-cateogry-version-history-details";
import BlogCreate from "../modules/StaticPagesAndHome/blog/blog-list/blog-create";
import BlogView from "../modules/StaticPagesAndHome/blog/blog-list/blog-view";
import BlogEdit from "../modules/StaticPagesAndHome/blog/blog-list/blog-edit";
import BlogApprovalsDetails from "../modules/StaticPagesAndHome/blog/blog-approvals/blog-approvals-details";
import BlogVersionHistoryDetails from "../modules/StaticPagesAndHome/blog/blog-version-history/blog-version-history-details";
import HowItWorksApprovalTable from "../modules/howItWorks/how-it-works/howItWorks-approvals/howItWorks-approvals-table";
import HowItWorksCreate from "../modules/howItWorks/how-it-works/howITWorks-list/howItWorks-create";
import HowItWorksTable from "../modules/howItWorks/how-it-works/howITWorks-list/howItWorks-table";
import OpportunitiesVersionHistoryDetails from "../modules/opportunity/opportunities/opportunities-version-history/opportunities-version-history-details";
import HOwItWorksVersionHistoryTable from "../modules/howItWorks/how-it-works/howItWorks-version-history/howItWorks-version-history-table";
import HowItWorksVersionHistoryDetails from "../modules/howItWorks/how-it-works/howItWorks-version-history/howItWorks-version-history-details";
import HowItWorksView from "../modules/howItWorks/how-it-works/howITWorks-list/howItWorks-view";
import HowItWorksApprovalDetails from "../modules/howItWorks/how-it-works/howItWorks-approvals/howItWorks-approvals-details";
import HowItWorksEdit from "../modules/howItWorks/how-it-works/howITWorks-list/howItWorks-edit";
import OpportunitiesApprovalView from "../modules/opportunity/opportunities/opportunities-approval-view";
import { permissions } from "../redux/config";
import BlogContentEdit from "../modules/StaticPagesAndHome/blog/blog-list/blog-content-edit";
import MemberTradeTable from "../modules/opportunity/member-trade/memberTrade-list/memberTrade-table";
import MemberTradeCreate from "../modules/opportunity/member-trade/memberTrade-list/memberTrade-create";
import MemberTradeApprovalsTable from "../modules/opportunity/member-trade/member-approvals/memberTrade-approvals-table";
import MemberTradeVersionTable from "../modules/opportunity/member-trade/memberTrade-version-history/memberTrade-version-table";
import MemberTradeApprovalView from "../modules/opportunity/member-trade/member-approvals/memberTrade-approval-view";
import MemberTradeVersionHistory from "../modules/opportunity/member-trade/memberTrade-version-history/memberTrade-version-history";
import MemberTradeEdit from "../modules/opportunity/member-trade/memberTrade-list/memberTrade-edit";
import MemberTradeRead from "../modules/opportunity/member-trade/memberTrade-list/memberTrade-read";
import HomeCreate from "../modules/StaticPagesAndHome/home-page/home-page-list/home-create";
import HomeEdit from "../modules/StaticPagesAndHome/home-page/home-page-list/home-edit";
import HomeApprovalsDetails from "../modules/StaticPagesAndHome/home-page/home-page-approvals/home-approvals-details";
import HomeVersionHistoryDetails from "../modules/StaticPagesAndHome/home-page/home-page-version-history/home-version-history-details";
import HomeTable from "../modules/StaticPagesAndHome/home-page/home-page-list/home-table";
import HomeView from "../modules/StaticPagesAndHome/home-page/home-page-list/home-view";
import HomeApprovalsTable from "../modules/StaticPagesAndHome/home-page/home-page-approvals/home-approvals-table";
import HomeVersionHistoryTableContent from "../components/static-pages/home-page/tables/homeVersionHistoryTableContent";
import HomeVersionTable from "../modules/StaticPagesAndHome/home-page/home-page-version-history/home-version-history-table";
import CampaignOpporTable from "../modules/StaticPagesAndHome/campaignOpportunity/campaignOppor-list/campaignOppor-table";
import CampaignOpporCreate from "../modules/StaticPagesAndHome/campaignOpportunity/campaignOppor-list/campaignOppor-create";
import CampaignOpporView from "../modules/StaticPagesAndHome/campaignOpportunity/campaignOppor-list/campaignOppor-view";
import CampaignOpporEdit from "../modules/StaticPagesAndHome/campaignOpportunity/campaignOppor-list/campaignOppor-edit";
import CampaignOpporApprovalTable from "../modules/StaticPagesAndHome/campaignOpportunity/campaignOppor-approvals/campaignOppor-approvals-table";
import CampaignOpporApprovalDetails from "../modules/StaticPagesAndHome/campaignOpportunity/campaignOppor-approvals/campaignOppor-approvals-details";
import CampaignOpporVersionHistoryTable from "../modules/StaticPagesAndHome/campaignOpportunity/campaignOppor-version-history/campaignOppor-version-history-table";
import CampaignOpporVersionHistoryDetails from "../modules/StaticPagesAndHome/campaignOpportunity/campaignOppor-version-history/campaignOppor-version-history-details";
import CampaignCreate from "../modules/StaticPagesAndHome/campaign/campaign-list/campaign-create";
import CampaignView from "../modules/StaticPagesAndHome/campaign/campaign-list/campaign-view";
import CampaignApprovalsDetails from "../modules/StaticPagesAndHome/campaign/campaign-approvals/campaign-approvals-details";
import CampaignVersionDetails from "../modules/StaticPagesAndHome/campaign/campaign-version-history/campaign-history-details";
import CampaignEdit from "../modules/StaticPagesAndHome/campaign/campaign-list/campaign-edit";
import CampaignTable from "../modules/StaticPagesAndHome/campaign/campaign-list/campaign-table";
import CampaignApprovalsTable from "../modules/StaticPagesAndHome/campaign/campaign-approvals/campaign-approvals-table";
import CampaignVersionTable from "../modules/StaticPagesAndHome/campaign/campaign-version-history/campaign-history-table";
import InsightsTable from "../modules/StaticPagesAndHome/insights/insights-list/insights-table";
import InsightsVersionHistoryTable from "../modules/StaticPagesAndHome/insights/insights-version-history/insights-version-history-table";
import InsightsVersionHistoryDetails from "../modules/StaticPagesAndHome/insights/insights-version-history/insights-version-history-details";
import InsightsCreate from "../modules/StaticPagesAndHome/insights/insights-list/insights-create";
import InsightsEdit from "../modules/StaticPagesAndHome/insights/insights-list/insights-edit";
import InsightsView from "../modules/StaticPagesAndHome/insights/insights-list/insights-view";
import InsightsApprovalDetails from "../modules/StaticPagesAndHome/insights/insights-approvals/insights-approvals-details";
import InsightsApprovalTable from "../modules/StaticPagesAndHome/insights/insights-approvals/insights-approvals-table";
import PartnerWithUsApprovalsTable from "../modules/StaticPagesAndHome/partner-with-us/partnerWithUs-approvals/partnerWithUs-approvals-table";
import PartnerWithUsCreate from "../modules/StaticPagesAndHome/partner-with-us/partnerWithUs-list/partnerWithUs-create";
import PartnerWithUsTable from "../modules/StaticPagesAndHome/partner-with-us/partnerWithUs-list/partnerWithUs-table";
import PartnerWithUsVersionTable from "../modules/StaticPagesAndHome/partner-with-us/partnerWithUs-version-history/partnerWithUs-history-table";
import PartnerWithUsVersionDetails from "../modules/StaticPagesAndHome/partner-with-us/partnerWithUs-version-history/partnerWithUs-history-details";
import PartnerWithUsEdit from "../modules/StaticPagesAndHome/partner-with-us/partnerWithUs-list/partnerWithUs-edit";
import PartnerWithUsView from "../modules/StaticPagesAndHome/partner-with-us/partnerWithUs-list/partnerWithUs-view";
import PartnerWithUsApprovalsDetails from "../modules/StaticPagesAndHome/partner-with-us/partnerWithUs-approvals/partnerWithUs-approvals-details";
import HomePage from "../modules/homepage";
import ReferTable from "../modules/StaticPagesAndHome/refer-and-earn/refer-and-earn-list/refer-table";
import ReferCreate from "../modules/StaticPagesAndHome/refer-and-earn/refer-and-earn-list/refer-create";
import ReferView from "../modules/StaticPagesAndHome/refer-and-earn/refer-and-earn-list/refer-view";
import ReferEdit from "../modules/StaticPagesAndHome/refer-and-earn/refer-and-earn-list/refer-edit";
import ReferApprovalTable from "../modules/StaticPagesAndHome/refer-and-earn/refer-and-earn-approvals/refer-approvals-table";
import ReferApprovalDetails from "../modules/StaticPagesAndHome/refer-and-earn/refer-and-earn-approvals/refer-approvals-details";
import ReferVersionHistoryTable from "../modules/StaticPagesAndHome/refer-and-earn/refer-and-earn-version-history/refer-version-history-table";
import ReferVersionHistoryDetails from "../modules/StaticPagesAndHome/refer-and-earn/refer-and-earn-version-history/refer-version-history-details";
import BadgesTable from "../modules/StaticPagesAndHome/badges/badges-list/badges-table";
import BadgesCreate from "../modules/StaticPagesAndHome/badges/badges-list/badges-create";
import BadgesView from "../modules/StaticPagesAndHome/badges/badges-list/badges-view";
import BadgesEdit from "../modules/StaticPagesAndHome/badges/badges-list/badges-edit";
import BadgesApprovalTable from "../modules/StaticPagesAndHome/badges/badges-approvals/badges-approvals-table";
import BadgesApprovalDetails from "../modules/StaticPagesAndHome/badges/badges-approvals/badges-approvals-details";
import BadgesVersionHistoryTable from "../modules/StaticPagesAndHome/badges/badges-version-history/badges-version-history-table";
import BadgesVersionHistoryDetails from "../modules/StaticPagesAndHome/badges/badges-version-history/badges-version-history-details";
import DocumentTable from "../modules/StaticPagesAndHome/document/document-list/document-table";
import DocumentCreate from "../modules/StaticPagesAndHome/document/document-list/document-create";
import DocumentView from "../modules/StaticPagesAndHome/document/document-list/document-view";
import DocumentEdit from "../modules/StaticPagesAndHome/document/document-list/document-edit";
import DocumentApprovalTable from "../modules/StaticPagesAndHome/document/document-approvals/document-approvals-table";
import DocumentApprovalDetails from "../modules/StaticPagesAndHome/document/document-approvals/document-approvals-details";
import DocumentVersionHistoryTable from "../modules/StaticPagesAndHome/document/document-version-history/document-version-history-table";
import DocumentVersionHistoryDetails from "../modules/StaticPagesAndHome/document/document-version-history/document-version-history-details";
import ReferredUsersCreate from "../modules/StaticPagesAndHome/referred-users/reffered-users-list/reffered-users-create";
import ReferredUsersEdit from "../modules/StaticPagesAndHome/referred-users/reffered-users-list/reffered-users-edit";
import ReferredUsersView from "../modules/StaticPagesAndHome/referred-users/reffered-users-list/reffered-users-view";
import ReferredUsersApprovalsDetails from "../modules/StaticPagesAndHome/referred-users/reffered-users-approvals/reffered-users-approvals-details";
import ReferredUsersVersionHistoryDetails from "../modules/StaticPagesAndHome/referred-users/reffered-users-version-history/reffered-users-version-history-details";
import ReferredUsersTable from "../modules/StaticPagesAndHome/referred-users/reffered-users-list/reffered-users-table";
import ReferredUsersApprovalTable from "../modules/StaticPagesAndHome/referred-users/reffered-users-approvals/reffered-users-approvals-table";
import InvitedTable from "../modules/StaticPagesAndHome/invited-members/invited-list/invited-table";
import InvitedCreate from "../modules/StaticPagesAndHome/invited-members/invited-list/invited-create";
import InvitedView from "../modules/StaticPagesAndHome/invited-members/invited-list/invited-view";
import InvitedEdit from "../modules/StaticPagesAndHome/invited-members/invited-list/invited-edit";
import InvitedApprovalTable from "../modules/StaticPagesAndHome/invited-members/invited-approvals/invited-approvals-table";
import InvitedApprovalDetails from "../modules/StaticPagesAndHome/invited-members/invited-approvals/invited-approvals-details";
import InvitedVersionHistoryTable from "../modules/StaticPagesAndHome/invited-members/invited-version-history/invited-version-history-table";
import InvitedVersionHistoryDetails from "../modules/StaticPagesAndHome/invited-members/invited-version-history/invited-version-history-details";
import ReferredUsersVersionHistoryTable from "../modules/StaticPagesAndHome/referred-users/reffered-users-version-history/reffered-users-version-history-table";
import NotificationTable from "../modules/StaticPagesAndHome/notification/notification-list/notification-table";
import NotificationApprovalsTable from "../modules/StaticPagesAndHome/notification/notification-approvals/notification-approvals-table";
import NotificationVersionTable from "../modules/StaticPagesAndHome/notification/notification-version-history/notification-version-table";
import NotificationRead from "../modules/StaticPagesAndHome/notification/notification-list/notification-read";
import NotificationEdit from "../modules/StaticPagesAndHome/notification/notification-list/notification-edit";
import NotificationCreate from "../modules/StaticPagesAndHome/notification/notification-list/notification-create";
import NotificationApprovalView from "../modules/StaticPagesAndHome/notification/notification-approvals/notification-approval-view";
import NotificationVersionHistory from "../modules/StaticPagesAndHome/notification/notification-version-history/notification-version-history";
import UserReferralApprovalTable from "../modules/StaticPagesAndHome/userReferral/userReferral-approvals/userReferral-approvals-table";
import UserReferralCreate from "../modules/StaticPagesAndHome/userReferral/userReferral-list/userReferral-create";
import UserReferralTable from "../modules/StaticPagesAndHome/userReferral/userReferral-list/userReferral-table";
import UserReferralVersionHistoryTable from "../modules/StaticPagesAndHome/userReferral/userReferral-version-history/userReferral-version-history-table";
import UserReferralVersionHistoryDetails from "../modules/StaticPagesAndHome/userReferral/userReferral-version-history/userReferral-version-history-details";
import UserReferralEdit from "../modules/StaticPagesAndHome/userReferral/userReferral-list/userReferral-edit";
import UserReferralView from "../modules/StaticPagesAndHome/userReferral/userReferral-list/userReferral-view";
import UserReferralApprovalDetails from "../modules/StaticPagesAndHome/userReferral/userReferral-approvals/userReferral-approvals-details";
import SocialTable from "../modules/StaticPagesAndHome/social/social-list/social-table";
import SocialApprovalsTable from "../modules/StaticPagesAndHome/social/social-approvals/social-approvals-table";
import SocialVersionTable from "../modules/StaticPagesAndHome/social/social-version-history/social-version-table";
import SocialRead from "../modules/StaticPagesAndHome/social/social-list/social-read";
import SocialEdit from "../modules/StaticPagesAndHome/social/social-list/social-edit";
import SocialCreate from "../modules/StaticPagesAndHome/social/social-list/social-create";
import SocialApprovalView from "../modules/StaticPagesAndHome/social/social-approvals/social-approval-view";
import SocialVersionHistory from "../modules/StaticPagesAndHome/social/social-version-history/social-version-history";
import CommunityBadgesApprovalTable from "../modules/StaticPagesAndHome/community-badges/community-badges-approvals/community-badges-approvals-table";
import CommunityBadgesCreate from "../modules/StaticPagesAndHome/community-badges/community-badges-list/community-badges-create";
import CommunityBadgesTable from "../modules/StaticPagesAndHome/community-badges/community-badges-list/community-badges-table";
import CommunityBadgesVersionHistoryTable from "../modules/StaticPagesAndHome/community-badges/community-badges-version-history/community-badges-version-history-table";
import CommunityBadgesVersionHistoryDetails from "../modules/StaticPagesAndHome/community-badges/community-badges-version-history/community-badges-version-history-details";
import CommunityBadgesEdit from "../modules/StaticPagesAndHome/community-badges/community-badges-list/community-badges-edit";
import CommunityBadgesView from "../modules/StaticPagesAndHome/community-badges/community-badges-list/community-badges-view";
import CommunityBadgesApprovalsDetails from "../modules/StaticPagesAndHome/community-badges/community-badges-approvals/community-badges-approvals-details";
import MemberTradeBuyInTable from "../modules/opportunity/memberTradeBuyIn/memberTradeBuyIn-list/memberTradeBuyIn-table";
import MemberTradeBuyInApprovalsTable from "../modules/opportunity/memberTradeBuyIn/memberTradeBuyIn-approvals/memberTradeBuyIn-approvals-table";
import MemberTradeBuyInVersionTable from "../modules/opportunity/memberTradeBuyIn/memberTradeBuyIn-version-history/memberTradeBuyIn-version-table";
import MemberTradeBuyInRead from "../modules/opportunity/memberTradeBuyIn/memberTradeBuyIn-list/memberTradeBuyIn-read";
import MemberTradeBuyInEdit from "../modules/opportunity/memberTradeBuyIn/memberTradeBuyIn-list/memberTradeBuyIn-edit";
import MemberTradeBuyInCreate from "../modules/opportunity/memberTradeBuyIn/memberTradeBuyIn-list/memberTradeBuyIn-create";
import MemberTradeBuyInApprovalView from "../modules/opportunity/memberTradeBuyIn/memberTradeBuyIn-approvals/memberTradeBuyIn-approval-view";
import MemberTradeBuyInVersionHistoryView from "../modules/opportunity/memberTradeBuyIn/memberTradeBuyIn-version-history/memberTradeBuyIn-version-history";
import MemTradeSellTable from "../modules/opportunity/memTradeSellOut/memTradeSellOut-list/memTradeSellOut-table";
import MemTradeSellApprovalsTable from "../modules/opportunity/memTradeSellOut/memTradeSellOut-approvals/memTradeSellOut-approvals-table";
import MemTradeSellVersionTable from "../modules/opportunity/memTradeSellOut/memTradeSellOut-version-history/memTradeSellOut-version-table";
import MemTradeSellRead from "../modules/opportunity/memTradeSellOut/memTradeSellOut-list/memTradeSellOut-read";
import MemTradeSellEdit from "../modules/opportunity/memTradeSellOut/memTradeSellOut-list/memTradeSellOut-edit";
import MemTradeSellCreate from "../modules/opportunity/memTradeSellOut/memTradeSellOut-list/memTradeSellOut-create";
import MemTradeSellApprovalView from "../modules/opportunity/memTradeSellOut/memTradeSellOut-approvals/memTradeSellOut-approval-view";
import MemTradeSellVersionHistory from "../modules/opportunity/memTradeSellOut/memTradeSellOut-version-history/memTradeSellOut-version-history";
import PartnerWithUsFormCreate from "../modules/StaticPagesAndHome/partnerWithUSForm/partnerWithUsForm-list/partnerWithUsForm-create";
import PartnerWithUsFormTable from "../modules/StaticPagesAndHome/partnerWithUSForm/partnerWithUsForm-list/partnerWithUsForm-table";
import PartnerWithUsFormEdit from "../modules/StaticPagesAndHome/partnerWithUSForm/partnerWithUsForm-list/partnerWithUsForm-edit";
import PartnerWithUsFormView from "../modules/StaticPagesAndHome/partnerWithUSForm/partnerWithUsForm-list/partnerWithUsForm-view";

const MainRoute = () => {
  return (
    <>
      <Router>
        <Routes>
          <Route path="*" element={<Error />} />
          <Route element={<PrivateRoute />}>
            {/**ROUTES FOR ADMIN PAGES**/}
            <Route path="/admin-users/view/:id" element={<AdminView />} />
            <Route path="/admin-users/edit/:id" element={<AdminEdit />} />
            <Route
              path="/admin-users/create"
              element={true ? <AdminCreate /> : <div>You dont have access</div>}
            />
            <Route
              path="/admin-users/approvals/view/:id"
              element={<AdminApprovalView />}
            />
            <Route
              path="/admin-users/version-history/view/:id"
              element={<AdminApprovalsVersionHistory />}
            />
            <Route path="/admin-users" element={<AdminUsersTable />} />
            <Route
              path="/admin-users/approvals"
              element={<AdminApprovalsTable />}
            />
            <Route
              path="/admin-users/version-history"
              element={<AdminVersionHistoryTable />}
            />
            {/**ROUTES FOR ADMIN ROLES PAGES**/}
            <Route path="/admin-roles" element={<AdminRolesTable />} />
            <Route path="/admin-roles/view/:id" element={<AdminRoleView />} />
            <Route path="/admin-roles/edit/:id" element={<AdminRoleEdit />} />
            <Route path="/admin-roles/create" element={<AdminRoleCreate />} />
            <Route
              path="/admin-roles/approvals"
              element={<AdminRoleApprovalsTable />}
            />
            <Route
              path="/admin-roles/version-history"
              element={<AdminRoleVersionTable />}
            />
            <Route
              path="/admin-roles/version-history/view/:id"
              element={<AdminRoleVersionHistoryView />}
            />
            <Route
              path="/admin-roles/approvals/view/:id"
              element={<AdminRoleApprovalView />}
            />
            {/**ROUTES FOR USER PAGES**/}
            <Route path="/user/user-list/view/:id" element={<UserView />} />
            <Route path="/user/user-list/edit/:id" element={<UserEdit />} />
            <Route path="/user/user-list/create" element={<UserCreate />} />
            <Route
              path="/user/user-list/approvals/view/:id"
              element={<UserApprovalsDetails />}
            />
            <Route
              path="/user/user-list/version-history/view/:id"
              element={<UserVersionHistoryView />}
            />
            <Route path="/user/user-list" element={<UserlistTable />} />
            <Route path="/" element={<HomePage />} />
            <Route
              path="/user/reset-password-email"
              element={<UserResetPassEmailTable />}
            />
            <Route path="/user/email-otp" element={<UserEmailOtpTable />} />
            <Route path="/user/phone-otp" element={<UserPhoneOtpTable />} />
            <Route
              path="/user/user-list/approvals"
              element={<UserApprovalsTable />}
            />
            <Route
              path="/user/user-list/version-history"
              element={<UserVersionTable />}
            />
            {/**ROUTES FOR OPPORTUNITY TYPE PAGES**/}
            <Route
              path="/opportunity/opportunity-type"
              element={<OpportunityTypeTable />}
            />
            <Route
              path="/opportunity/opportunity-type/approvals"
              element={<OpportunityTypeApprovalsTable />}
            />
            <Route
              path="/opportunity/opportunity-type/version-history"
              element={<OpportunityVersionTable />}
            />
            <Route
              path="/opportunity/opportunity-type/view/:id"
              element={<OpportunityTypeRead />}
            />
            <Route
              path="/opportunity/opportunity-type/edit/:id"
              element={<OpportunityTypeEdit />}
            />
            <Route
              path="/opportunity/opportunity-type/create"
              element={<OpportunityTypeCreate />}
            />
            <Route
              path="/opportunity/opportunity-type/approvals/view/:id"
              element={<OpportunityTypeApprovalView />}
            />
            f
            <Route
              path="/opportunity/opportunity-type/version-history/view/:id"
              element={<OpportunityTypeVersionHistoryView />}
            />
            {/*ROUTES FOR WISHLIST PAGES */}
            <Route path="/opportunity/wishlist" element={<WishlistTable />} />
            <Route
              path="/opportunity/wishlist/approvals"
              element={<WishlistApprovalsTable />}
            />
            <Route
              path="/opportunity/wishlist/version-history"
              element={<WishlistVersionTable />}
            />
            <Route
              path="/opportunity/wishlist/view/:id"
              element={<WishlistRead />}
            />
            <Route
              path="/opportunity/wishlist/edit/:id"
              element={<WishlistEdit />}
            />
            <Route
              path="/opportunity/wishlist/create"
              element={<WishlistCreate />}
            />
            {/* <Route path="/opportunity/wishlist/read/:id" element={<WishlistRead />} />
            <Route path="/opportunity/wishlist/edit/:id" element={<WishlistEdit />} /> */}
            <Route
              path="/opportunity/wishlist/approvals/view/:id"
              element={<WishlistApprovalView />}
            />
            <Route
              path="/opportunity/wishlist/version-history/view/:id"
              element={<WishlistVersionHistory />}
            />
            {/*ROUTES FOR NOTIFY ME PAGES */}
            <Route path="/opportunity/notify-me" element={<NotifyMeTable />} />
            <Route
              path="/opportunity/notify-me/approvals"
              element={<NotifyMeApprovalsTable />}
            />
            <Route
              path="/opportunity/notify-me/version-history"
              element={<NotifyMeVersionTable />}
            />
            <Route
              path="/opportunity/notify-me/view/:id"
              element={<NotifyMeRead />}
            />
            <Route
              path="/opportunity/notify-me/edit/:id"
              element={<NotifyMeEdit />}
            />
            <Route
              path="/opportunity/notify-me/create"
              element={<NotifyMeCreate />}
            />
            <Route
              path="/opportunity/notify-me/approvals/view/:id"
              element={<NotifyMeApprovalView />}
            />
            <Route
              path="/opportunity/notify-me/version-history/view/:id"
              element={<NotifyMeVersionHistory />}
            />
            {/*ROUTES FOR Member Trade Pages PAGES */}
            <Route
              path="/opportunity/member-trade"
              element={<MemberTradeTable />}
            />
            <Route
              path="/opportunity/member-trade/approvals"
              element={<MemberTradeApprovalsTable />}
            />
            <Route
              path="/opportunity/member-trade/version-history"
              element={<MemberTradeVersionTable />}
            />
            <Route
              path="/opportunity/member-trade/view/:id"
              element={<MemberTradeRead />}
            />
            <Route
              path="/opportunity/member-trade/edit/:id"
              element={<MemberTradeEdit />}
            />
            <Route
              path="/opportunity/member-trade/create"
              element={<MemberTradeCreate />}
            />
            <Route
              path="/opportunity/member-trade/approvals/view/:id"
              element={<MemberTradeApprovalView />}
            />
            <Route
              path="/opportunity/member-trade/version-history/view/:id"
              element={<MemberTradeVersionHistory />}
            />
            {/***********OPPORTUNITIES**************/}
            <Route
              path="/opportunity/opportunities"
              element={<OpportunitiesTable />}
            />
            <Route
              path="/opportunity/opportunities/approvals"
              element={<OpportunitiesApprovalTable />}
            />
            <Route
              path="/opportunity/opportunities/approvals/view/:id"
              element={<OpportunitiesApprovalView />}
            />
            <Route
              path="/opportunity/opportunities/analytics/:id"
              element={<OpportunitiesAnalytics />}
            />
            <Route
              path="/opportunity/opportunities/edit/:id"
              element={<OpportunitiesEdit />}
            />
            <Route
              path="/opportunity/opportunties/edit/content-edit/:id"
              element={<OpportunitiesContentEdit />}
            />
            <Route
              path="/opportunity/opportunities/view/:id"
              element={<OpportunitiesRead />}
            />
            <Route
              path="/opportunity/opportunities/create"
              element={<OpportunitiesCreate />}
            />
            <Route
              path="/opportunity/opportunities/version-history"
              element={<OpportunitiesVersionHistoryTable />}
            />
            <Route
              path="/opportunity/opportunities/version-history/view/:id"
              element={<OpportunitiesVersionHistoryDetails />}
            />
            {/*******EXPRESS INTEREST */}
            <Route
              path="/opportunity/express-interest"
              element={<ExpressInterestTable />}
            />
            <Route
              path="/opportunity/express-interest/view/:id"
              element={<ExpressInterestView />}
            />
            <Route
              path="/opportunity/express-interest/edit/:id"
              element={<ExpressInterestEdit />}
            />
            <Route
              path="/opportunity/express-interest/create"
              element={<ExpressInterestCreate />}
            />
            <Route
              path="/opportunity/express-interest/version-history"
              element={<ExpressInterestVersionHistoryTable />}
            />
            <Route
              path="/opportunity/express-interest/version-history/view/:id"
              element={<ExpressInterestVersionHistoryDetails />}
            />
            <Route
              path="/opportunity/express-interest/approvals"
              element={<ExpressInterestApprovalsTable />}
            />
            <Route
              path="/opportunity/express-interest/approvals/view/:id"
              element={<ExpressInterestApprovalsDetails />}
            />
            {/**************our teanm************** */}
            <Route path="/static-pages/our-team" element={<OurTeamTable />} />
            <Route
              path="/static-pages/our-team/approvals"
              element={<OurTeamApprovalTable />}
            />
            <Route
              path="/static-pages/our-team/version-history"
              element={<OurTeamVersionHistoryTable />}
            />
            <Route
              path="/static-pages/our-team/create"
              element={<OurTeamCreate />}
            />
            <Route
              path="/static-pages/our-team/edit/:id"
              element={<OurTeamEdit />}
            />
            <Route
              path="/static-pages/our-team/view/:id"
              element={<OurTeamRead />}
            />
            <Route
              path="/static-pages/our-team/version-history/view/:id"
              element={<OurTeamVersionHistoryView />}
            />
            <Route
              path="/static-pages/our-team/approvals/view/:id"
              element={<OurTeamApprovalView />}
            />
            {/**************TESTIMONIALS************** */}
            <Route
              path="/static-pages/testimonials/create"
              element={<TestimonialsCreate />}
            />
            <Route
              path="/static-pages/testimonials/edit/:id"
              element={<TestimonialsEdit />}
            />
            <Route
              path="/static-pages/testimonials/view/:id"
              element={<TestimonialsRead />}
            />
            <Route
              path="/static-pages/testimonials/approvals/view/:id"
              element={<TestimonialsApprovalView />}
            />
            <Route
              path="/static-pages/testimonials/version-history/view/:id"
              element={<TestimonialsVersionHistoryView />}
            />
            <Route
              path="/static-pages/testimonials"
              element={<TestimonialsTable />}
            />
            <Route
              path="/static-pages/testimonials/approvals"
              element={<TestimonialsApprovalTable />}
            />
            <Route
              path="/static-pages/testimonials/version-history"
              element={<TestimonialsVersionHistoryTable />}
            />
            {/**************MEDIA AND RECOGONITION************** */}
            <Route
              path="/static-pages/media-recogonition/create"
              element={<MediaRecogonitionCreate />}
            />
            <Route
              path="/static-pages/media-recogonition/edit/:id"
              element={<MediaRecogonitionEdit />}
            />
            <Route
              path="/static-pages/media-recogonition/view/:id"
              element={<MediaRecogonitionRead />}
            />
            <Route
              path="/static-pages/media-recogonition/approvals/view/:id"
              element={<MediaRecogonitionApprovalView />}
            />
            <Route
              path="/static-pages/media-recogonition/version-history/view/:id"
              element={<MediaRecogonitionVersionView />}
            />
            <Route
              path="/static-pages/media-recogonition/version-history"
              element={<MediaRecogonitionVersionTable />}
            />
            <Route
              path="/static-pages/media-recogonition"
              element={<MediaRecogonitionTable />}
            />
            <Route
              path="/static-pages/media-recogonition/approvals"
              element={<MediaRecogonitionApprovalsTable />}
            />
            {/*ROUTES FOR  LLP PAGES */}
            <Route path="/opportunity/llp" element={<LlpTable />} />
            <Route path="/opportunity/llp/create" element={<LlpCreate />} />
            <Route path="/opportunity/llp/edit/:id" element={<LlpEdit />} />
            <Route path="/opportunity/llp/view/:id" element={<LlpView />} />
            <Route
              path="/opportunity/llp/version-history/view/:id"
              element={<Llpversioinhistoryview />}
            />
            <Route
              path="/opportunity/llp/version-history"
              element={<LlpVersionHistoryTable />}
            />
            <Route
              path="/opportunity/llp/approvals/view/:id"
              element={<LlpApprovalsView />}
            />
            <Route
              path="/opportunity/llp/approvals"
              element={<LlpApprovalsTable />}
            />
            {/*ROUTES FOR  Our partners */}
            <Route
              path="/static-pages/our-partners"
              element={<OurPartnersTable />}
            />
            <Route
              path="/static-pages/our-partners/edit/:id"
              element={<OurPartnersEdit />}
            />
            <Route
              path="/static-pages/our-partners/create"
              element={<OurPartnersCreate />}
            />
            <Route
              path="/static-pages/our-partners/version-history"
              element={<OurPartnerVersionHistoryTable />}
            />
            <Route
              path="/static-pages/our-partners/approvals"
              element={<OurPartnersApprovalTable />}
            />
            <Route
              path="/static-pages/our-partners/approvals/view/:id"
              element={<OurPartnersApprovalView />}
            />
            <Route
              path="/static-pages/our-partners/view/:id"
              element={<OurPartnerView />}
            />
            <Route
              path="/static-pages/our-partners/version-history/view/:id"
              element={<OurPartnerVersionHistoryView />}
            />
            {/**************  our teanm************** */}
            {/*ROUTES FOR  CONTACT US PAGES */}
            <Route
              path="/static-pages/contact-us-query"
              element={<ContactUsTable />}
            />
            <Route
              path="/static-pages/contact-us-query/create"
              element={<ContactUsCreate />}
            />
            <Route
              path="/static-pages/contact-us-query/edit/:id"
              element={<ContactUsEdit />}
            />
            <Route
              path="/static-pages/contact-us-query/view/:id"
              element={<ContactUsView />}
            />
            <Route
              path="/static-pages/contact-us-query/approvals"
              element={<ContactUsApprovalsTable />}
            />
            <Route
              path="/static-pages/contact-us-query/approvals/view/:id"
              element={<ContactUsApprovalView />}
            />
            <Route
              path="/static-pages/contact-us-query/version-history"
              element={<ContactUsVersionHistoryTable />}
            />
            <Route
              path="/static-pages/contact-us-query/version-history/view/:id"
              element={<ContactUsVersionView />}
            />
            {/*ROUTES FOR  NEWSLETTER */}
            <Route
              path="/static-pages/newsletter"
              element={<NewsLetterTable />}
            />
            <Route
              path="/static-pages/newsletter/create"
              element={<NewsLetterCreate />}
            />
            <Route
              path="/static-pages/newsletter/edit/:id"
              element={<NewsLetterEdit />}
            />
            <Route
              path="/static-pages/newsletter/view/:id"
              element={<NewsLetterView />}
            />
            <Route
              path="/static-pages/newsletter/approvals"
              element={<NewsLetterApprovalTable />}
            />
            <Route
              path="/static-pages/newsletter/approvals/view/:id"
              element={<NewsLetterApprovalView />}
            />
            <Route
              path="/static-pages/newsletter/version-history"
              element={<NewsLetterVersionHistoryTable />}
            />
            <Route
              path="/static-pages/newsletter/version-history/view/:id"
              element={<NewsLetterVersionView />}
            />
            <Route
              path="/contact-us-query/contact-us-query-approvals"
              element={<LlpApprovalsTable />}
            />
            {/*******FAQ CATEGORY *********/}
            <Route
              path="/static-pages/faq-category"
              element={<FaqCategoryTable />}
            />
            <Route
              path="/static-pages/faq-category/create"
              element={<FaqCategoryCreate />}
            />
            <Route
              path="/static-pages/faq-category/view/:id"
              element={<FaqCategoryView />}
            />
            <Route
              path="/static-pages/faq-category/edit/:id"
              element={<FaqCategoryEdit />}
            />
            <Route
              path="/static-pages/faq-category/approvals"
              element={<FaqCategoryApprovalTable />}
            />
            <Route
              path="/static-pages/faq-category/approvals/view/:id"
              element={<FaqCategoryApprovalsDetails />}
            />
            <Route
              path="/static-pages/faq-category/version-history"
              element={<FaqCategoryVersionHistoryTable />}
            />
            <Route
              path="/static-pages/faq-category/version-history/view/:id"
              element={<FaqCategoryVersionHistoryDetails />}
            />
            {/***********FAQS*********/}
            <Route path="/static-pages/faqs" element={<FaqsTable />} />
            <Route path="/static-pages/faqs/create" element={<FaqCreate />} />
            <Route path="/static-pages/faqs/view/:id" element={<FaqView />} />
            <Route path="/static-pages/faqs/edit/:id" element={<FaqEdit />} />
            <Route
              path="/static-pages/faqs/approvals"
              element={<FaqApprovalTable />}
            />
            <Route
              path="/static-pages/faqs/approvals/view/:id"
              element={<FaqsApprovalDetails />}
            />
            <Route
              path="/static-pages/faqs/version-history"
              element={<FaqVersionHistoryTable />}
            />
            <Route
              path="/static-pages/faqs/version-history/view/:id"
              element={<FaqVersionHistoryDetails />}
            />
            {/**************  our advisors************** */}
            <Route
              path="/static-pages/our-advisors"
              element={<OurAdvisorsTable />}
            />
            <Route
              path="/static-pages/our-advisors/create"
              element={<OurAdvisorsCreate />}
            />
            <Route
              path="/static-pages/our-advisors/edit/:id"
              element={<OurAdvisorsEdit />}
            />
            <Route
              path="/static-pages/our-advisors/view/:id"
              element={<OurAdvisorsRead />}
            />
            <Route
              path="/static-pages/our-advisors/approvals/view/:id"
              element={<OurAdvisorsApprovalsView />}
            />
            <Route
              path="/static-pages/our-advisors/approvals"
              element={<OurAdvisorApprovalsTable />}
            />
            <Route
              path="/static-pages/our-advisors/version-history"
              element={<AdvisorVersionHistoryTable />}
            />
            <Route
              path="/static-pages/our-advisors/version-history/view/:id"
              element={<Advisorversioinhistoryview />}
            />
            {/**************STATIC SCREEN************** */}
            <Route
              path="/static-pages/static-screen"
              element={<StaticTable />}
            />
            <Route
              path="/static-pages/static-screen/create"
              element={<StaticCreate />}
            />
            <Route
              path="/static-pages/static-screen/approvals"
              element={<StaticApprovalTable />}
            />
            <Route
              path="/static-pages/static-screen/approvals/view/:id"
              element={<StaticApprovalDetails />}
            />
            <Route
              path="/static-pages/static-screen/view/:id"
              element={<StaticView />}
            />
            <Route
              path="/static-pages/static-screen/version-history"
              element={<StaticVersionHistoryTable />}
            />
            <Route
              path="/static-pages/static-screen/version-history/view/:id"
              element={<StaticVersionHistoryDetails />}
            />
            <Route
              path="/static-pages/static-screen/edit/:id"
              element={<StaticEdit />}
            />
            {/*******Blog CATEGORY *********/}
            <Route path="/blog-category" element={<BlogCategoryTable />} />
            <Route
              path="/blog-category/create"
              element={<BlogCategoryCreate />}
            />
            <Route
              path="/blog-category/view/:id"
              element={<BlogCategoryView />}
            />
            <Route
              path="/blog-category/edit/:id"
              element={<BlogCategoryEdit />}
            />
            <Route
              path="/blog-category/approval"
              element={<BlogCategoryApprovalTable />}
            />
            <Route
              path="/blog-category/approval/view/:id"
              element={<BlogCategoryApprovalsDetails />}
            />
            <Route
              path="/blog-category/version-history"
              element={<BlogCategoryVersionHistoryTable />}
            />
            <Route
              path="/blog-category/version-history/view/:id"
              element={<BlogCategoryVersionHistoryDetails />}
            />
            {/*******BLOG *********/}
            <Route path="/blog" element={<BlogTable />} />
            <Route path="/blog/create" element={<BlogCreate />} />
            <Route path="/blog/view/:id" element={<BlogView />} />
            <Route path="/blog/edit/:id" element={<BlogEdit />} />
            <Route
              path="/blog/edit/content-edit/:id"
              element={<BlogContentEdit />}
            />
            <Route path="/blog/approval" element={<BlogApprovalTable />} />
            <Route
              path="/blog/approval/view/:id"
              element={<BlogApprovalsDetails />}
            />
            <Route
              path="/blog/version-history"
              element={<BlogVersionHistoryTable />}
            />
            <Route
              path="/blog/version-history/version-history-view/:id"
              element={<BlogVersionHistoryDetails />}
            />
            {/*******HOW IT WORKS*********/}
            <Route
              path="/static-pages/how-It-Works/approvals"
              element={<HowItWorksApprovalTable />}
            />
            <Route
              path="/static-pages/how-It-Works/create"
              element={<HowItWorksCreate />}
            />
            <Route
              path="/static-pages/how-It-Works"
              element={<HowItWorksTable />}
            />
            <Route
              path="/static-pages/how-It-Works/version-history"
              element={<HOwItWorksVersionHistoryTable />}
            />
            <Route
              path="/static-pages/how-It-Works/version-history/view/:id"
              element={<HowItWorksVersionHistoryDetails />}
            />
            <Route
              path="/static-pages/how-It-Works/edit/:id"
              element={<HowItWorksEdit />}
            />
            {/* <Route
              path="/howItWorks"
              element={<HowItWorksTable />}
            /> */}
            <Route
              path="/static-pages/how-It-Works/view/:id"
              element={<HowItWorksView />}
            />
            <Route
              path="/static-pages/how-It-Works/approvals/view/:id"
              element={<HowItWorksApprovalDetails />}
            />
            {/*************HOME********** */}
            <Route
              path="/static-pages/home/approvals"
              element={<HomeApprovalsTable />}
            />
            <Route path="/static-pages/home/create" element={<HomeCreate />} />
            <Route path="/static-pages/home" element={<HomeTable />} />
            <Route
              path="/static-pages/home/version-history"
              element={<HomeVersionTable />}
            />
            <Route
              path="/static-pages/home/version-history/view/:id"
              element={<HomeVersionHistoryDetails />}
            />
            <Route path="/static-pages/home/edit/:id" element={<HomeEdit />} />
            <Route path="/static-pages/home/view/:id" element={<HomeView />} />
            <Route
              path="/static-pages/home/approvals/view/:id"
              element={<HomeApprovalsDetails />}
            />
            {/***********CAMPAIGN OPPOR*********/}
            <Route
              path="/static-pages/campaignOppor"
              element={<CampaignOpporTable />}
            />
            <Route
              path="/static-pages/campaignOppor/create"
              element={<CampaignOpporCreate />}
            />
            <Route
              path="/static-pages/campaignOppor/view/:id"
              element={<CampaignOpporView />}
            />
            <Route
              path="/static-pages/campaignOppor/edit/:id"
              element={<CampaignOpporEdit />}
            />
            <Route
              path="/static-pages/campaignOppor/approvals"
              element={<CampaignOpporApprovalTable />}
            />
            <Route
              path="/static-pages/campaignOppor/approvals/view/:id"
              element={<CampaignOpporApprovalDetails />}
            />
            <Route
              path="/static-pages/campaignOppor/version-history"
              element={<CampaignOpporVersionHistoryTable />}
            />
            <Route
              path="/static-pages/campaignOppor/version-history/view/:id"
              element={<CampaignOpporVersionHistoryDetails />}
            />
            {/*************CAMPAIGN********** */}
            <Route
              path="/static-pages/campaign/approvals"
              element={<CampaignApprovalsTable />}
            />
            <Route
              path="/static-pages/campaign/create"
              element={<CampaignCreate />}
            />
            <Route path="/static-pages/campaign" element={<CampaignTable />} />
            <Route
              path="/static-pages/campaign/version-history"
              element={<CampaignVersionTable />}
            />
            <Route
              path="/static-pages/campaign/version-history/view/:id"
              element={<CampaignVersionDetails />}
            />
            <Route
              path="/static-pages/campaign/edit/:id"
              element={<CampaignEdit />}
            />
            <Route
              path="/static-pages/campaign/view/:id"
              element={<CampaignView />}
            />
            <Route
              path="/static-pages/campaign/approvals/view/:id"
              element={<CampaignApprovalsDetails />}
            />
            {/*******INSIGHTS*********/}
            <Route
              path="/static-pages/insights/approvals"
              element={<InsightsApprovalTable />}
            />
            <Route
              path="/static-pages/insights/create"
              element={<InsightsCreate />}
            />
            <Route path="/static-pages/insights" element={<InsightsTable />} />
            <Route
              path="/static-pages/insights/version-history"
              element={<InsightsVersionHistoryTable />}
            />
            <Route
              path="/static-pages/insights/version-history/view/:id"
              element={<InsightsVersionHistoryDetails />}
            />
            <Route
              path="/static-pages/insights/edit/:id"
              element={<InsightsEdit />}
            />
            <Route
              path="/static-pages/insights/view/:id"
              element={<InsightsView />}
            />
            <Route
              path="/static-pages/insights/approvals/view/:id"
              element={<InsightsApprovalDetails />}
            />


            {/*******USER REFERRAL*********/}
            <Route
              path="/static-pages/userReferral/approvals"
              element={<UserReferralApprovalTable />}
            />
            <Route
              path="/static-pages/userReferral/create"
              element={<UserReferralCreate />}
            />
            <Route
              path="/static-pages/userReferral"
              element={<UserReferralTable />}
            />
            <Route
              path="/static-pages/userReferral/version-history"
              element={<UserReferralVersionHistoryTable />}
            />
            <Route
              path="/static-pages/userReferral/version-history/view/:id"
              element={<UserReferralVersionHistoryDetails />}
            />
            <Route
              path="/static-pages/userReferral/edit/:id"
              element={<UserReferralEdit />}
            />

            <Route
              path="/static-pages/userReferral/view/:id"
              element={<UserReferralView />}
            />
            <Route
              path="/static-pages/userReferral/approvals/view/:id"
              element={<UserReferralApprovalDetails />}
            />

            {/* ************PARTNER WITH US********** */}
            <Route
              path="/static-pages/partner-with-us/approvals"
              element={<PartnerWithUsApprovalsTable />}
            />
            <Route
              path="/static-pages/partner-with-us/create"
              element={<PartnerWithUsCreate />}
            />
            <Route
              path="/static-pages/partner-with-us"
              element={<PartnerWithUsTable />}
            />
            <Route
              path="/static-pages/partner-with-us/version-history"
              element={<PartnerWithUsVersionTable />}
            />
            <Route
              path="/static-pages/partner-with-us/version-history/view/:id"
              element={<PartnerWithUsVersionDetails />}
            />
            <Route
              path="/static-pages/partner-with-us/edit/:id"
              element={<PartnerWithUsEdit />}
            />
            <Route
              path="/static-pages/partner-with-us/view/:id"
              element={<PartnerWithUsView />}
            />
            <Route
              path="/static-pages/partner-with-us/approvals/view/:id"
              element={<PartnerWithUsApprovalsDetails />}
            />


            {/* ************PARTNER WITH US FORM********** */}
            {/* <Route
              path="/static-pages/partner-with-us/approvals"
              element={<PartnerWithUsApprovalsTable />}
            /> */}
            <Route
              path="/static-pages/partnerWithUsForm/create"
              element={<PartnerWithUsFormCreate />}
            />
            <Route
              path="/static-pages/partnerWithUsForm"
              element={<PartnerWithUsFormTable />}
            />
            {/* <Route
              path="/static-pages/partnerWithUsForm/version-history"
              element={<PartnerWithUsVersionTable />}
            /> */}
            {/* <Route
              path="/static-pages/partnerWithUsForm/version-history/view/:id"
              element={<PartnerWithUsVersionDetails />}
            /> */}
            <Route
              path="/static-pages/partnerWithUsForm/edit/:id"
              element={<PartnerWithUsFormEdit />}
            />
            <Route
              path="/static-pages/partnerWithUsForm/view/:id"
              element={<PartnerWithUsFormView />}
            />
            {/* <Route
              path="/static-pages/partnerWithUsForm/approvals/view/:id"
              element={<PartnerWithUsApprovalsDetails />}
            /> */}
            {/***********REFER AND EARN*********/}
            <Route path="/static-pages/refer" element={<ReferTable />} />
            <Route
              path="/static-pages/refer/create"
              element={<ReferCreate />}
            />
            <Route
              path="/static-pages/refer/view/:id"
              element={<ReferView />}
            />
            <Route
              path="/static-pages/refer/edit/:id"
              element={<ReferEdit />}
            />
            <Route
              path="/static-pages/refer/approvals"
              element={<ReferApprovalTable />}
            />
            <Route
              path="/static-pages/refer/approvals/view/:id"
              element={<ReferApprovalDetails />}
            />
            <Route
              path="/static-pages/refer/version-history"
              element={<ReferVersionHistoryTable />}
            />
            <Route
              path="/static-pages/refer/version-history/view/:id"
              element={<ReferVersionHistoryDetails />}
            />
            {/***********BADGES*********/}
            <Route path="/static-pages/badges" element={<BadgesTable />} />
            <Route
              path="/static-pages/badges/create"
              element={<BadgesCreate />}
            />
            <Route
              path="/static-pages/badges/view/:id"
              element={<BadgesView />}
            />
            <Route
              path="/static-pages/badges/edit/:id"
              element={<BadgesEdit />}
            />
            <Route
              path="/static-pages/badges/approvals"
              element={<BadgesApprovalTable />}
            />
            <Route
              path="/static-pages/badges/approvals/view/:id"
              element={<BadgesApprovalDetails />}
            />
            <Route
              path="/static-pages/badges/version-history"
              element={<BadgesVersionHistoryTable />}
            />
            <Route
              path="/static-pages/badges/version-history/view/:id"
              element={<BadgesVersionHistoryDetails />}
            />
            {/***********DOCUMENT*********/}
            <Route path="/static-pages/dashboard" element={<DocumentTable />} />
            <Route
              path="/static-pages/dashboard/create"
              element={<DocumentCreate />}
            />
            <Route
              path="/static-pages/dashboard/view/:id"
              element={<DocumentView />}
            />
            <Route
              path="/static-pages/dashboard/edit/:id"
              element={<DocumentEdit />}
            />
            <Route
              path="/static-pages/dashboard/approvals"
              element={<DocumentApprovalTable />}
            />
            <Route
              path="/static-pages/dashboard/approvals/view/:id"
              element={<DocumentApprovalDetails />}
            />
            <Route
              path="/static-pages/dashboard/version-history"
              element={<DocumentVersionHistoryTable />}
            />
            <Route
              path="/static-pages/dashboard/version-history/view/:id"
              element={<DocumentVersionHistoryDetails />}
            />



            {/********REFERRED USERS********* */}
            <Route
              path="/static-pages/referred-users/approvals"
              element={<ReferredUsersApprovalTable />}
            />
            <Route
              path="/static-pages/referred-users/create"
              element={<ReferredUsersCreate />}
            />
            <Route path="/static-pages/referred-users" element={<ReferredUsersTable />} />
            <Route
              path="/static-pages/referred-users/version-history"
              element={<ReferredUsersVersionHistoryTable />}
            />
            <Route
              path="/static-pages/referred-users/version-history/view/:id"
              element={<ReferredUsersVersionHistoryDetails />}
            />
            <Route
              path="/static-pages/referred-users/edit/:id"
              element={<ReferredUsersEdit />}
            />
            <Route
              path="/static-pages/referred-users/view/:id"
              element={<ReferredUsersView />}
            />
            <Route
              path="/static-pages/referred-users/approvals/view/:id"
              element={<ReferredUsersApprovalsDetails />} />
            {/***********INVITED MEMBERS*********/}
            <Route path="/static-pages/invited" element={<InvitedTable />} />
            <Route path="/static-pages/invited/create" element={<InvitedCreate />} />
            <Route path="/static-pages/invited/view/:id" element={<InvitedView />} />
            <Route path="/static-pages/invited/edit/:id" element={<InvitedEdit />} />
            <Route
              path="/static-pages/invited/approvals"
              element={<InvitedApprovalTable />}
            />
            <Route
              path="/static-pages/invited/approvals/view/:id"
              element={<InvitedApprovalDetails />}
            />
            <Route
              path="/static-pages/invited/version-history"
              element={<InvitedVersionHistoryTable />}
            />
            <Route
              path="/static-pages/invited/version-history/view/:id"
              element={<InvitedVersionHistoryDetails />}
            />


            {/********COMMUNITY BADGES********* */}
            <Route
              path="/static-pages/community-badges/approvals"
              element={<CommunityBadgesApprovalTable />}
            />
            <Route
              path="/static-pages/community-badges/create"
              element={<CommunityBadgesCreate />}
            />
            <Route path="/static-pages/community-badges" element={<CommunityBadgesTable />} />
            <Route
              path="/static-pages/community-badges/version-history"
              element={<CommunityBadgesVersionHistoryTable />}
            />
            <Route
              path="/static-pages/community-badges/version-history/view/:id"
              element={<CommunityBadgesVersionHistoryDetails />}
            />
            <Route
              path="/static-pages/community-badges/edit/:id"
              element={<CommunityBadgesEdit />}
            />
            <Route
              path="/static-pages/community-badges/view/:id"
              element={<CommunityBadgesView />}
            />
            <Route
              path="/static-pages/community-badges/approvals/view/:id"
              element={<CommunityBadgesApprovalsDetails />} />



            {/*ROUTES FOR NOTIFY ME PAGES */}
            <Route path="/static-pages/notification" element={<NotificationTable />} />
            <Route
              path="/static-pages/notification/approvals"
              element={<NotificationApprovalsTable />}
            />
            <Route
              path="/static-pages/notification/version-history"
              element={<NotificationVersionTable />}
            />
            <Route
              path="/static-pages/notification/view/:id"
              element={<NotificationRead />}
            />
            <Route
              path="/static-pages/notification/edit/:id"
              element={<NotificationEdit />}
            />
            <Route
              path="/static-pages/notification/create"
              element={<NotificationCreate />}
            />
            <Route
              path="/static-pages/notification/approvals/view/:id"
              element={<NotificationApprovalView />}
            />
            <Route
              path="/static-pages/notification/version-history/view/:id"
              element={<NotificationVersionHistory />}
            />


            {/* */}
            <Route path="/static-pages/social" element={<SocialTable />} />
            <Route
              path="/static-pages/social/approvals"
              element={<SocialApprovalsTable />}
            />
            <Route
              path="/static-pages/social/version-history"
              element={<SocialVersionTable />}
            />
            <Route
              path="/static-pages/social/view/:id"
              element={<SocialRead />}
            />
            <Route
              path="/static-pages/social/edit/:id"
              element={<SocialEdit />}
            />
            <Route
              path="/static-pages/social/create"
              element={<SocialCreate />}
            />
            <Route
              path="/static-pages/social/approvals/view/:id"
              element={<SocialApprovalView />}
            />
            <Route
              path="/static-pages/social/version-history/view/:id"
              element={<SocialVersionHistory />}
            />
            {/*ROUTES FOR MEMBER TRADE BUY IN PAGES */}
            <Route path="/opportunity/memberTrade-buy-in" element={<MemberTradeBuyInTable />} />
            <Route
              path="/opportunity/memberTrade-buy-in/approvals"
              element={<MemberTradeBuyInApprovalsTable />}
            />
            <Route
              path="/opportunity/memberTrade-buy-in/version-history"
              element={<MemberTradeBuyInVersionTable />}
            />
            <Route
              path="/opportunity/memberTrade-buy-in/view/:id"
              element={<MemberTradeBuyInRead />}
            />
            <Route
              path="/opportunity/memberTrade-buy-in/edit/:id"
              element={<MemberTradeBuyInEdit />}
            />
            <Route
              path="/opportunity/memberTrade-buy-in/create"
              element={<MemberTradeBuyInCreate />}
            />
            <Route
              path="/opportunity/memberTrade-buy-in/approvals/view/:id"
              element={<MemberTradeBuyInApprovalView />}
            />
            <Route
              path="/opportunity/memberTrade-buy-in/version-history/view/:id"
              element={<MemberTradeBuyInVersionHistoryView />}
            />
            
            {/*ROUTES FOR MEMBERTRADE SELL OUT PAGES */}
            <Route path="/opportunity/memTradeSell" element={<MemTradeSellTable />} />
            <Route
              path="/opportunity/memTradeSell/approvals"
              element={<MemTradeSellApprovalsTable />}
            />
            <Route
              path="/opportunity/memTradeSell/version-history"
              element={<MemTradeSellVersionTable />}
            />
            <Route
              path="/opportunity/memTradeSell/view/:id"
              element={<MemTradeSellRead />}
            />
            <Route
              path="/opportunity/memTradeSell/edit/:id"
              element={<MemTradeSellEdit />}
            />
            <Route
              path="/opportunity/memTradeSell/create"
              element={<MemTradeSellCreate />}
            />
            <Route
              path="/opportunity/memTradeSell/approvals/view/:id"
              element={<MemTradeSellApprovalView />}
            />
            <Route
              path="/opportunity/memTradeSell/version-history/view/:id"
              element={<MemTradeSellVersionHistory />}
            />
          </Route>
          <Route element={<ProtectedRoute />}>
            <Route path="/login" element={<Login />} />
          </Route>
        </Routes>
        <ToastContainer limit={3} />
      </Router>
    </>
  );
};
export default MainRoute;
