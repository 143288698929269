import React, { useEffect, useRef, useState } from "react";
import DeleteBtn from "../../../../components/buttons/deleteBtn";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useNavigate, } from 'react-router-dom'
import successToast from "../../../../components/utilits/successToast";
import errorToast from "../../../../components/utilits/errorToast";
import InnerContainer from "../../../../components/layouts/innerContainer/innerContainer";
import InnerLayout from "../../../../components/layouts/innerLayout/innerLayout";
import "../notify-me.css";
import SelectFields from "../../../../components/form/selectFields/selectFields";
import SaveBtn from "../../../../components/buttons/saveBtn";
import { deleteNotifyMe, editNotifyMe, notifyMeCreateOptions, notifySubmitAction, readNotifyMe } from "../../../../redux/actions/notifyMeActions";
import DeleteModal from "../../../../components/modals/deleteModal";
import { opportunitiesNameListAction } from "../../../../redux/actions/opportunitiesActions";
import LoadingSpinnerTable from "../../../../components/utilits/loadingSpinnerTable";
import LoadAndError from "../../../../components/utilits/LoadAndError";
import SubmitBtn from "../../../../components/buttons/submit";
import InputField from "../../../../components/form/inputFields/inputField";
import ErrorMsg from "../../../../components/form/errorMsg";
import AdminRoleCheckBox from "../../../../components/utilits/adminRoleCheckbox";
import { deleteNotification, editNotification, notificationSubmitAction, readNotification } from "../../../../redux/actions/notificationActions";
import SelectMultiFields from "../../../../components/form/selectFields/selectMultiFields";
import ArrowDownIcon from "../../../../assets/icons/arrowdown";
const NotificationEdit = () => {
    const navigate = useNavigate();
    const handleShow = () => setShow(true);
    const handleClose = () => setShow(false);
    const [show, setShow] = useState(false);
    const params = useParams()
    const [updated, setUpdated] = useState(false)
    const formRef = useRef()
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false)
    const [saved, setSaved] = useState(false);
    const [subLoading, setSubLoading] = useState(false);
    const [userName, setUserName] = useState([]);
    const [notifyMeViewData, SetNotifyMeViewData] = useState({ id: "", name: "", createdAt: "", updatedAt: "" })
    const [defaultUsersList, setDefaultUsersList] = useState([]);
    const [allUsers, setAllUsers] = useState([])
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [notiUserName, setNotiUserName] = useState([])
    console.log(selectedOptions);
    //Initial values
    useEffect(() => {
        setLoading(true);
        dispatch(
            readNotification(params.id, onSuccess, onError)
        );
    }, [params.id,]);
    const userNameOptions = [];
    const onSuccess = (data) => {
        SetNotifyMeViewData(data.data?.[0])
        setNotiUserName(data.data)
        console.log(data.data, "fjdsgdshdfggfdgfgf");
        setLoading(false);
        setError(false)
    };
    console.log(notiUserName);
    const onError = (data) => {
        errorToast(data.message)
        setLoading(false);
        setError(true)
    };

    //initial user options
    useEffect(() => {
        dispatch(notifyMeCreateOptions(onNotifyMeSuccess, onNotifyMeError));

    }, [notifyMeViewData.id]);
    const onNotifyMeSuccess = (data) => {
        data?.data?.AllUsers.map((item, index) => {
            userNameOptions[index] = { value: item.id, label: item.user_name };
            const defaultListItems = data?.data?.AllUsers?.filter((item) =>
                notifyMeViewData?.user_id?.includes(item?.id)
            ).map((item) => {
                return { label: item.user_name, value: item.id };
            });
            setDefaultUsersList(defaultListItems);
            setSelectedOptions(defaultListItems.map((item) => {
                return item.value
            }))
        });
        setUserName(userNameOptions);
        const defaultListItems = data?.data?.AllUsers?.map((item) => {
            return item.id
        })
        setAllUsers(defaultListItems);

    };
    const onNotifyMeError = (data) => {
    };


    // for edit api
    const [notifyMeData, setNotifyMeData] = useState()
    const initialValues = {
        userId: defaultUsersList,
        title: notifyMeViewData.title,
        Subtitle: notifyMeViewData.sub_title,
        description: notifyMeViewData.description,
        kyc: notifyMeViewData.is_kyc,
        isImportant: notifyMeViewData.is_important,
        redirectionUrl: notifyMeViewData.redirection_url,
        visibility: notifyMeViewData?.user_id?.length === allUsers.length ? "public" : "user_oriented"
    };

    const loginSchema = Yup.object().shape({
        // userId: Yup.string().trim().required("Required"),
        title: Yup.string().trim().required("Required"),
        Subtitle: Yup.string().trim().required("Required"),
        description: Yup.string().trim().required("Required"),
        redirectionUrl: Yup.string().trim().required("Required"),
    });
    const handleSubmit = () => {
        if (formRef.current) {
            formRef.current.handleSubmit()
        }
    }
    const onSubmit = (values, { resetForm }) => {
        dispatch(
            editNotification(
                params.id,
                {
                    "user_id": values.visibility === "user_oriented" ? selectedOptions : allUsers,
                    "title": values.title,
                    "sub_title": values.Subtitle,
                    "description": values.description,
                    "is_kyc": values.kyc,
                    "is_important": values.isImportant,
                    "redirection_url": values.redirectionUrl
                },
                onEditSuccess, onEditError)
        );
        setUpdated(!updated)
    };
    const onEditSuccess = (data) => {
        setNotifyMeData(data.data)
        successToast(data.message)
        setSaved(true);
        setLoading(false)
        setError(false)
    };
    const onEditError = (data) => {
        console.log(data);
        errorToast(data.message)
        setLoading(false)
        setError(true)
    };

    //opp id drop down
    const [opportunitiesList, setOpportunitiesList] = useState([])
    useEffect(() => {
        dispatch(opportunitiesNameListAction(onSuccessOpt, onErrorOpt));
    }, []);
    const onSuccessOpt = (data) => {
        const categoryList = [];
        data?.data.map((item, index) => {
            categoryList[index] = { value: item.id, label: `${item.title} id: ${item.id}` };
        });
        setOpportunitiesList(categoryList);
        console.log("SUCCESS");
    };
    const onErrorOpt = (data) => {
        console.log(data);
    };


    //submit api
    const notifySubmit = () => {
        setSubLoading(true);
        dispatch(
            notificationSubmitAction(params.id, onAdminSubmitSucess, onAdminSubmitError)
        );
    };
    function onAdminSubmitSucess(data) {
        successToast(data.message);
        setSubLoading(false);
        navigate("/static-pages/notification")
    }
    function onAdminSubmitError(data) {
        setSubLoading(false);
        errorToast(data.message);
    }


    const buttonList = [
        <SaveBtn submitFn={handleSubmit} />,
        <SubmitBtn saveHide={saved} submitFn={notifySubmit} />
    ];

    const handleSelectedOptions = (value) => {
        setSelectedOptions(value.map((item) => item.value));
    };

    console.log(selectedOptions);
    return (
        <InnerLayout buttons={buttonList}>
            <div className="notify-me-container">
                <InnerContainer>
                    {loading ? (
                        <LoadingSpinnerTable />
                    ) : (
                        <LoadAndError loader={loading} error={error}>
                            <div className="pt-3  ps-4 ps-lg-4 ms-3">
                                <h5 className="dark_grey_color fs_13 fn_Montserrat fw_500 m-0">
                                    Notification ID
                                </h5>
                                <p
                                    className={` fs_13 fn_Nunito fw_500 dark_title_color  `}>
                                    {notifyMeViewData.id}
                                </p>
                            </div>
                            <div className="  ps-4 ps-lg-4 ms-3">
                                <Formik
                                    innerRef={formRef}
                                    initialValues={initialValues}
                                    onSubmit={onSubmit}
                                    validationSchema={loginSchema}
                                    enableReinitialize={true}
                                >
                                    {({ formik, handleChange, values, setFieldValue }) => {
                                        return (

                                            <Form className='notify-me-form-width'>
                                                <div>
                                                    <h5 className="fs_16 ms-2 mt-3 fw_400 dark_grey_color">
                                                        Send Notification to
                                                    </h5>
                                                    <div className="d-flex align-items-center gap-2 ms-3 mb-2">
                                                        <label>
                                                            <Field
                                                                disabled={false}
                                                                className={"llp_document_radio"}
                                                                type="radio"
                                                                name="visibility"
                                                                value="public"
                                                            />
                                                            <span className="ms-2">All Users</span>
                                                        </label>
                                                        <label className="">
                                                            <Field
                                                                disabled={false}
                                                                className={"llp_document_radio"}
                                                                type="radio"
                                                                name="visibility"
                                                                value="user_oriented"
                                                            />
                                                            <span className="ms-2">User Oriented</span>
                                                        </label>
                                                    </div>
                                                </div>
                                                {
                                                    values.visibility === "user_oriented" && (
                                                        <SelectMultiFields
                                                            disabled={false}
                                                            updateSelected={handleSelectedOptions}
                                                            selectedOptions={selectedOptions}

                                                            Icon={
                                                                <ArrowDownIcon
                                                                    width={20}
                                                                    height={20}
                                                                    fill={"#A5A5A5"}
                                                                />
                                                            }
                                                            default1={defaultUsersList}
                                                            label={"Select Users"}
                                                            placeholder={"Select Users"}
                                                            name={"userId"}
                                                            options={userName}
                                                        />
                                                    )}
                                                <InputField
                                                    id={"title"}
                                                    label={"title"}
                                                    type={"text"}
                                                    placeholder={"Enter Title"}
                                                    name={"title"}
                                                />
                                                <div className=''>

                                                    <label id="Subtitle" className='mb-2 dark_grey_color fn_Montserrat fw_500 fs_14 ps-2'>Subtitle</label>
                                                    <Field
                                                        name={"Subtitle"}>
                                                        {({ field }) => (
                                                            <textarea id="Subtitle" {...field} type="text" className={'w-100 opportunities_create_summary_text_area p-2 '}  >
                                                            </textarea>
                                                        )}
                                                    </Field>
                                                    <ErrorMsg
                                                        name={"Subtitle"} />
                                                </div>
                                                <div className=''>
                                                    <label id="description" className='mb-2 dark_grey_color fn_Montserrat fw_500 fs_14 ps-2'>Description</label>
                                                    <Field
                                                        name={"description"}>
                                                        {({ field }) => (
                                                            <textarea id="description" {...field} type="text" className={'w-100 opportunities_create_summary_text_area p-2 '}  >
                                                            </textarea>
                                                        )}
                                                    </Field>
                                                    <ErrorMsg
                                                        name={"description"} />
                                                </div>
                                                <InputField
                                                    id={"redirectionUrl"}
                                                    label={"Redirection Url"}
                                                    type={"text"}
                                                    placeholder={"Enter Redirection Url"}
                                                    name={"redirectionUrl"}
                                                />
                                                <div className="d-flex flex-column flex-md-row gap-5">
                                                    <div>
                                                        <label htmlFor="kyc" className='mb-2 dark_grey_color fn_Montserrat fw_500 fs_14'>Kyc Status</label>
                                                        <AdminRoleCheckBox name="kyc" id="kyc" />
                                                    </div>
                                                    <div>
                                                        <label htmlFor="isImportant" className='mb-2 dark_grey_color fn_Montserrat fw_500 fs_14'>Is Important</label>
                                                        <AdminRoleCheckBox name="isImportant" id="isImportant" />
                                                    </div>
                                                </div>
                                            </Form>

                                        )
                                    }}
                                </Formik>
                                {
                                    notiUserName.map((item, index) => {
                                        if (index > 0) return (
                                            <div>
                                                {item}
                                            </div>
                                        )
                                    })
                                }
                            </div>

                        </LoadAndError>
                    )}
                </InnerContainer>
            </div>
        </InnerLayout>
    );
};

export default NotificationEdit;



