import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import ApproveBtn from "../../../../components/buttons/approveBtn";
import RejectBtn from "../../../../components/buttons/rejectBtn";
import VersionHistoryBtn from "../../../../components/buttons/versionHistoryBtn";
import InnerContainer from "../../../../components/layouts/innerContainer/innerContainer";
import InnerLayout from "../../../../components/layouts/innerLayout/innerLayout";
import OurTeamViewProfileImage from "../../../../components/static-pages/our-team/modals/viewProfileImage";
import errorToast from "../../../../components/utilits/errorToast";
import successToast from "../../../../components/utilits/successToast";
import DownloadIcon from "../../../../assets/icons/downloadIcon";
import OurTeamProfileImage from "../../../../components/static-pages/our-team/modals/approvalProfileImage";
import { approvalsViewByIdMediaRecAction, approveMediaRecAction, rejectMediaRecAction } from "../../../../redux/actions/mediaRecogonitionActions";
import LoadingSpinnerTable from "../../../../components/utilits/loadingSpinnerTable";
import LoadAndError from "../../../../components/utilits/LoadAndError";
const MediaRecogonitionApprovalView = () => {
    const navigate = useNavigate();
    const handleShow = () => setShow(true);
    const handleClose = () => setShow(false);
    const [show, setShow] = useState(false);
    const [imageShow, setImageShow] = useState(false)
    const params = useParams()
    const dispatch = useDispatch()
    const handleBackImageShow = () => setImageShow(true);
    const handleImageClose = () => setImageShow(false);
    const [notifyMeViewData, SetNotifyMeViewData] = useState({ id: "", name: "", createdAt: "", updatedAt: "" })
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [logo, setLogo] = useState(false);
    const [profile, setProfile] = useState(false);

    useEffect(() => {
        setLoading(true)
        dispatch(    
            approvalsViewByIdMediaRecAction(params?.id, onSuccess, onError)
        );
    }, [params.id])
    const onSuccess = (data) => {
        SetNotifyMeViewData(data?.data);
        setLoading(false);
        setError(false);
    };
    const onError = (data) => {
        errorToast(data.message)
        setLoading(false)
        setError(true);
    };

    console.log("The data", notifyMeViewData);


    //delete
    const [deleteData, setDeleteData] = useState({ name: "", id: "" });


    // useEffect(() => {
    //     console.log(params);
    //     dispatch(viewApprovalById(params.id, onSuccess, onError));
    // }, []);

    // const onSuccess = (data) => {
    //     console.log(data.data);
    //     setAdminDetails(data.data);
    //     console.log("SUCCESS");
    // };

    // const onError = (data) => {
    //     console.log(data);
    //     console.log("ERROR");
    // };
    const approveAdminFunc = () => {
        //console.log("The id :",params?.id);
        dispatch(approveMediaRecAction(params?.id, onApproveSuccess, onApproveError));
    };
    const rejectAdminFunc = () => {
        dispatch(rejectMediaRecAction(params?.id, onRejectSuccess, onRejectError));
    };
    const buttonList = [
        <VersionHistoryBtn link={`/static-pages/media-recogonition/version-history`} />,
        <ApproveBtn approveFn={approveAdminFunc} />,
        <RejectBtn showModal={rejectAdminFunc} />,
    ];

    const onApproveSuccess = (data) => {
        successToast(data.message);
        navigate("/static-pages/media-recogonition/approvals")
        setLoading(false);
        setError(false);
    };
    const onApproveError = (data) => {
        errorToast(data.message);
        console.log(data);
        setLoading(false);
        setError(true);
    };

    const onRejectSuccess = (data) => {
        successToast(data.message);
        setLoading(false);
        setError(false);
        console.log(data);
        navigate("/static-pages/media-recogonition/approvals")
    };

    const onRejectError = (data) => {
        errorToast(data.message);
        console.log(data);
        setLoading(false);
        setError(true);
    };


    const data = [
        {
            heading: "Approval ID",
            title: notifyMeViewData?.id === null ? "--" : notifyMeViewData?.id,
        },
        {
            heading: "Name",
            title: notifyMeViewData?.new_data === null ? notifyMeViewData?.old_data?.name : notifyMeViewData?.new_data?.name,
        },
        {
            heading: "Media & Recognitions ID",
            title: notifyMeViewData?.new_data === null ? notifyMeViewData?.old_data?.id : notifyMeViewData?.new_data?.id,
        },
        {
            heading: "Title",
            title: notifyMeViewData?.new_data === null ? notifyMeViewData?.old_data?.title : notifyMeViewData?.new_data?.title,
        },
        {
            heading: "Description",
            title: notifyMeViewData?.new_data === null ? notifyMeViewData?.old_data?.description : notifyMeViewData?.new_data?.description,
        },
        {
            heading: "URL",
            title: notifyMeViewData?.new_data === null ? notifyMeViewData?.old_data?.url : notifyMeViewData?.new_data?.url,
        },
        {
            heading: "Operation Type",
            title: notifyMeViewData?.new_data == null ? "Delete" : notifyMeViewData?.new_data && notifyMeViewData?.old_data ? "Edit" : notifyMeViewData?.old_data == null ? "Create" : ""
          }
    ];
    return (
        <InnerLayout buttons={buttonList}>
            <div className="admin_user_container">
                <InnerContainer error={error} oldData={notifyMeViewData?.old_data} newData={notifyMeViewData?.new_data}>
                    {loading ? (
                        <LoadingSpinnerTable/>
                    ) : (
          <LoadAndError loader={loading} error={error}>
                    <div className="row">
                        <div className='col-12 col-md-5'>
                            <div className="pt-3  ps-4 ps-lg-4 ms-3">
                                {data.map((item, index) => (
                                    <div className="mb-2">
                                        <h5 className="dark_grey_color fs_13 fn_Montserrat fw_500 m-0">
                                            {item.heading}
                                        </h5>
                                        <p
                                            className={` fs_13 fn_Nunito fw_500  ${item.heading === "LinkedIn URL" || item.heading === "User ID" ? 'secondary_color' : 'dark_title_color'} `}>
                                            {item.title}
                                        </p>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className='col-12 col-md-5'>
                            <div className="ps-5">
                                <h5 className="dark_grey_color text-nowrap fs_13 fn_Montserrat fw_500">
                                    Logo
                                </h5>
                                <div className="personal_information_image_container mt-2 position-relative">
                                    <img
                                        onClick={() => {
                                            handleBackImageShow()
                                            setLogo(true)
                                            setProfile(false)
                                        }}
                                        // onClick={setLogo(true); setLogo(true)}
                                        src={notifyMeViewData?.new_data === null ? notifyMeViewData?.old_data?.logo_url : notifyMeViewData?.new_data?.logo_url}
                                        className="personal_information_img_size rounded-2"
                                    />
                                    <a
                                        href={DownloadIcon}
                                        download="file"
                                    >
                                        <button
                                            // onClick={downloadImage}
                                            type="button"
                                            className="position-absolute border-0 bg-white d-flex align-items-center fs_10 top-0 end-0"
                                        >
                                            <DownloadIcon width={8} />
                                            <span className="ms-1">Download</span>
                                        </button>
                                    </a>
                                </div>
                            </div>
                            <div className="ps-5 mt-3">
                                <h5 className="dark_grey_color text-nowrap fs_13 fn_Montserrat fw_500">
                                    Profile Picture
                                </h5>

                                <div className="personal_information_image_container mt-2 position-relative">
                                    <img
                                        onClick={() => {
                                            handleBackImageShow()
                                            setLogo(false)
                                            setProfile(true)
                                        }}
                                        src={notifyMeViewData?.new_data === null ? notifyMeViewData?.old_data?.photo_url : notifyMeViewData?.new_data?.photo_url}
                                        className="personal_information_img_size rounded-2"
                                    />
                                    <a
                                        href={DownloadIcon}
                                        download="file"
                                    >
                                        <button
                                            type="button"
                                            className="position-absolute border-0 bg-white d-flex align-items-center fs_10 top-0 end-0"
                                        >
                                            <DownloadIcon width={8} />
                                            <span className="ms-1">Download</span>
                                        </button>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    </LoadAndError>
                    )}
                </InnerContainer>
            </div>
            {
                logo === true && (
                    <OurTeamProfileImage
                        handleClose={handleImageClose}
                        show={imageShow}
                        Profileimage={notifyMeViewData?.new_data?.logo}
                        modalHeading={"LOGO"}
                    />
                )
            }
            {  
                profile === true && (
                    <OurTeamProfileImage
                        handleClose={handleImageClose}
                        show={imageShow}
                        Profileimage={notifyMeViewData?.new_data?.profile_picture}
                        modalHeading={"PROFILE PICTURE"}
                    />
                )
            }

        </InnerLayout>
    );
};

export default MediaRecogonitionApprovalView;
