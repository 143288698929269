import React, { useEffect, useState } from "react";
import { TableContainer } from "../../../table/tableContainer";
import TablePagination from "../../../table/tablePagination";
import { Link } from "react-router-dom";
import "../../../admin/tables/admintable.css";
import {
    tableWishlistHeader,
    tableWishlistRow,
} from "../../../table/tableData";
import { useDispatch } from "react-redux";
import errorToast from "../../../utilits/errorToast";
import successToast from "../../../utilits/successToast";
import LoadingSpinnerTable from "../../../utilits/loadingSpinnerTable";
import DeleteModal from "../../../modals/deleteModal";
import * as wishlistActions from "../../../../redux/actions/wishlistActions";
import moment from "moment";
import { access } from "../../../utilits/access";

const OpportunitiesWishlist = ({
    tableContainerClassName = "adminuser_table__container",
    search,
    wishlist = [],
    containerWidth = "90%",
    containerMargin = "1.5rem 0rem",
}) => {
    const dispatch = useDispatch();
    const handleShow = () => setShow(true);
    const handleClose = () => setShow(false);
    const [show, setShow] = useState(false);
    const [deleteData, setDeleteData] = useState({ name: "", id: "" });
    const [loading, setLoading] = useState(false);
    const [loadingDelete, setLoadingDelete] = useState(false);
    const [page, setPage] = useState(0);
    const [currentPage, setCurrentPage] = useState(0);
    console.log(search);
    const handleDelete = () => {
        setLoadingDelete(true);
        dispatch(
            wishlistActions.deleteWishlist(
                deleteData.id,
                onDeleteSuccess,
                onDeleteError
            )
        );
    };
    const onDeleteSuccess = (data) => {
        successToast(data.message);
        setLoadingDelete(false);
        setShow(false);
    };
    const onDeleteError = (data) => {
        errorToast(data.message);
        setLoadingDelete(false);
    };
    //pagination
    const pages = Math.ceil(wishlist?.length === 0 ? 0 : wishlist.length / 10);
    const wishlistCount = wishlist?.length;
    const handlePagination = (value) => {
        if (wishlistCount > 10) {
            if (value === "+") {
                if (currentPage + 1 !== pages) {
                    setCurrentPage((prev) => prev + 1);
                }
            } else if (value === "-")
                if (currentPage > 0) {
                    setCurrentPage((prev) => prev - 1);
                }
        }
    };

    return (
        <TableContainer width={containerWidth} margin={containerMargin}>
            <div className={`${tableContainerClassName}`}>
                {loading && <LoadingSpinnerTable />}
                <table className={"adminuser_table"}>
                    <thead className="adminuser_table__header">
                        <tr className="adminuser_table__row">
                            {tableWishlistHeader.map((item) => {
                                return (
                                    <th
                                        key={item}
                                        className={`${item === "Status" ? " position-sticky end-0" : null
                                            } text-nowrap adminuser_table__heading`}
                                    >
                                        {item}
                                    </th>
                                );
                            })}
                        </tr>
                    </thead>
                    {loading == false && (
                        <tbody className="adminuser_table__body">
                            {wishlist.map((item, index) => {
                                if (currentPage === 0 && index < 10) {
                                    return (
                                        <tr key={item.id} className="adminuser_table__row">
                                            <td className="text-nowrap dark_title_color adminuser_table__data ">
                                                {page > 0 ? 12 * page + (index + 1) : index + 1}
                                            </td>
                                            <td className="text-nowrap dark_title_color adminuser_table__data">
                                                {item?.id === null ? "-" : item?.id}
                                            </td>
                                            <td className="text-nowrap secondary_color adminuser_table__data">
                                                {item?.opportunity_id === null
                                                    ? "-"
                                                    : item?.opportunity_id}
                                            </td>
                                            <td className="text-nowrap secondary_color  adminuser_table__data">
                                                {item?.user_id === null ? "-" : item?.user_id}
                                            </td>
                                            <td className="text-nowrap  dark_title_color adminuser_table__data">
                                                {item?.user?.user_name === null
                                                    ? "-"
                                                    : item?.user?.user_name}
                                            </td>
                                            <td className="text-nowrap  dark_title_color adminuser_table__data">
                                                {item?.createdAt === null
                                                    ? "-"
                                                    : moment(item?.createdAt).format("DD-MM-YYYY HH:mm:ss")}
                                            </td>
                                            <td className="text-nowrap  dark_title_color adminuser_table__data">
                                                {item?.updatedAt === null
                                                    ? "-"
                                                    : moment(item?.updatedAt).format("DD-MM-YYYY HH:mm:ss")}
                                            </td>
                                            <td className="text-nowrap    adminuser_table__data adminrole_table_empty__data"></td>
                                            {access?.opportunity?.maker === true ? (
                                                <td className="text-nowrap  position-sticky end-0 adminuser_table__data">
                                                    <div className="d-flex w-100   gap-2">
                                                        <Link
                                                            className="text-decoration-none primary_color"
                                                            to={{
                                                                pathname: `/opportunity/wishlist/view/${item.id}`,
                                                            }}
                                                        >
                                                            View
                                                        </Link>{" "}
                                                        <span className="vr"></span>{" "}
                                                        <Link
                                                            className="text-decoration-none secondary_color"
                                                            to={{
                                                                pathname: `/opportunity/wishlist/edit/${item.id}`,
                                                            }}
                                                        >
                                                            Edit
                                                        </Link>
                                                        <span className="vr"></span>
                                                        <button
                                                            onClick={() => {
                                                                setDeleteData({
                                                                    name: item.role_name,
                                                                    id: item.id,
                                                                });
                                                                handleShow();
                                                            }}
                                                            className="text-decoration-none primary_color light_red_color border-0 bg-transparent "
                                                        >
                                                            Delete
                                                        </button>
                                                    </div>
                                                </td>
                                            ) : (
                                                <td className="text-nowrap  position-sticky end-0 adminuser_table__data">
                                                    <div className="d-flex w-100   gap-2">
                                                        <Link
                                                            className="text-decoration-none primary_color"
                                                            to={{
                                                                pathname: `/wishlist/wishlist-read/${item.id}`,
                                                            }}
                                                        >
                                                            View
                                                        </Link>{" "}
                                                        {/* <span className="vr"></span>{" "}
                                            <Link
                                                className="text-decoration-none secondary_color"
                                                to={{
                                                    pathname: `/wishlist/wishlist-edit/${item.id}`,
                                                }}
                                            >
                                                Edit
                                            </Link>
                                            <span className="vr"></span>
                                            <button
                                                onClick={() => {
                                                    setDeleteData({
                                                        name: item.role_name,
                                                        id: item.id,
                                                    });
                                                    handleShow();
                                                }}
                                                className="text-decoration-none primary_color light_red_color border-0 bg-transparent "
                                            >
                                                Delete
                                            </button> */}
                                                    </div>
                                                </td>
                                            )}
                                        </tr>
                                    );
                                }
                                else if (
                                    currentPage > 0 &&
                                    index + 1 >= currentPage * 10 + 1 &&
                                    index + 1 < (currentPage + 1) * 10 + 1
                                ) {
                                    return (
                                        <tr key={item.id} className="adminuser_table__row">
                                            <td className="text-nowrap dark_title_color adminuser_table__data ">
                                                {page > 0 ? 12 * page + (index + 1) : index + 1}
                                            </td>
                                            <td className="text-nowrap dark_title_color adminuser_table__data">
                                                {item?.id === null ? "-" : item?.id}
                                            </td>
                                            <td className="text-nowrap secondary_color adminuser_table__data">
                                                {item?.opportunity_id === null
                                                    ? "-"
                                                    : item?.opportunity_id}
                                            </td>
                                            <td className="text-nowrap secondary_color  adminuser_table__data">
                                                {item?.user_id === null ? "-" : item?.user_id}
                                            </td>
                                            <td className="text-nowrap  dark_title_color adminuser_table__data">
                                                {item?.user?.user_name === null
                                                    ? "-"
                                                    : item?.user?.user_name}
                                            </td>
                                            <td className="text-nowrap  dark_title_color adminuser_table__data">
                                                {item?.createdAt === null
                                                    ? "-"
                                                    : moment(item?.createdAt).format("DD-MM-YYYY HH:mm:ss")}
                                            </td>
                                            <td className="text-nowrap  dark_title_color adminuser_table__data">
                                                {item?.updatedAt === null
                                                    ? "-"
                                                    : moment(item?.updatedAt).format("DD-MM-YYYY HH:mm:ss")}
                                            </td>
                                            <td className="text-nowrap    adminuser_table__data adminrole_table_empty__data"></td>
                                            {access?.opportunity?.maker === true ? (
                                                <td className="text-nowrap  position-sticky end-0 adminuser_table__data">
                                                    <div className="d-flex w-100   gap-2">
                                                        <Link
                                                            className="text-decoration-none primary_color"
                                                            to={{
                                                                pathname: `/opportunity/wishlist/view/${item.id}`,
                                                            }}
                                                        >
                                                            View
                                                        </Link>{" "}
                                                        <span className="vr"></span>{" "}
                                                        <Link
                                                            className="text-decoration-none secondary_color"
                                                            to={{
                                                                pathname: `/opportunity/wishlist/edit/${item.id}`,
                                                            }}
                                                        >
                                                            Edit
                                                        </Link>
                                                        <span className="vr"></span>
                                                        <button
                                                            onClick={() => {
                                                                setDeleteData({
                                                                    name: item.role_name,
                                                                    id: item.id,
                                                                });
                                                                handleShow();
                                                            }}
                                                            className="text-decoration-none primary_color light_red_color border-0 bg-transparent "
                                                        >
                                                            Delete
                                                        </button>
                                                    </div>
                                                </td>
                                            ) : (
                                                <td className="text-nowrap  position-sticky end-0 adminuser_table__data">
                                                    <div className="d-flex w-100   gap-2">
                                                        <Link
                                                            className="text-decoration-none primary_color"
                                                            to={{
                                                                pathname: `/wishlist/wishlist-read/${item.id}`,
                                                            }}
                                                        >
                                                            View
                                                        </Link>{" "}
                                                        {/* <span className="vr"></span>{" "}
                                            <Link
                                                className="text-decoration-none secondary_color"
                                                to={{
                                                    pathname: `/wishlist/wishlist-edit/${item.id}`,
                                                }}
                                            >
                                                Edit
                                            </Link>
                                            <span className="vr"></span>
                                            <button
                                                onClick={() => {
                                                    setDeleteData({
                                                        name: item.role_name,
                                                        id: item.id,
                                                    });
                                                    handleShow();
                                                }}
                                                className="text-decoration-none primary_color light_red_color border-0 bg-transparent "
                                            >
                                                Delete
                                            </button> */}
                                                    </div>
                                                </td>
                                            )}
                                        </tr>
                                    );
                                }
                            })}
                        </tbody>
                    )}
                </table>
            </div>
            <DeleteModal
                loader={loadingDelete}
                handleClose={handleClose}
                deleteFn={handleDelete}
                data={deleteData}
                show={show}
            />
            <TablePagination
                limit={10}
                paginate={handlePagination}
                currentPage={currentPage}
                pageData={{
                    noOfItems: wishlistCount ? wishlistCount : 0,
                    noOfPages: pages === 0 ? 1 : pages,
                }}
            />
        </TableContainer>
    );
};

export default OpportunitiesWishlist;
