import React, { useState } from 'react'
import ApprovalsBtn from '../../../../components/buttons/approvalsBtn';
import ExportBtn from '../../../../components/buttons/exportBtn';
import FilterBtn from '../../../../components/buttons/filterBtn';
import AdminUserFilter from '../../../../components/filter/adminUserFilter';
import OurPartnersFilter from '../../../../components/filter/static-pages filter/ourPartnersFilter';
import InnerLayout from '../../../../components/layouts/innerLayout/innerLayout';
import OurPartnerApprovalTableContent from '../../../../components/static-pages/our-partners/tables/our-partner-approval-table-content';
import { rolesApprovalsExport } from '../../../../redux/actions/adminRoleActions';

const OurPartnersApprovalTable = () => {
    const [page, setPage] = useState(0);
    const [showFilter, setShowFilter] = useState(false);
    const [search, setSearch] = useState({ value: "" });

    const handleFilterClose = () => {
      setShowFilter(false);     
    };
    const handleFilterOpen = () => {
      setShowFilter(true);
    };
    const handleSearch = (value) => {
      setSearch({ value: value });
      setPage(0)
    };
    const handlePage = (type) => {
      if (type === "+") {
        setPage((prev) => prev + 1)
      }
      else {
        setPage((prev) => prev - 1)
      }                                 
    }
    const buttonList = [
        <ExportBtn  dispatchAction={rolesApprovalsExport}/>,
        <FilterBtn filterFn={handleFilterOpen} />,
    ];                         
    return (
        <InnerLayout buttons={buttonList}>
            <OurPartnersFilter
                filter={handleSearch}
                showFilter={showFilter}
                closeFilter={handleFilterClose}
            />
            <OurPartnerApprovalTableContent  page={page} changePage={handlePage} search={search}  />
        </InnerLayout>
    )
}

export default OurPartnersApprovalTable