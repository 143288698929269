import React, { useState } from "react";
import CreateBtn from "../../../../components/buttons/createBtn";
import ExportBtn from "../../../../components/buttons/exportBtn";
import FilterBtn from "../../../../components/buttons/filterBtn";
import InnerLayout from "../../../../components/layouts/innerLayout/innerLayout";
import AdminUserFilter from "../../../../components/filter/adminUserFilter";
import AdminUserApprovalTableContent from "../../../../components/admin/tables/adminUserApprovalTableContent";
import { exportAdmin } from "../../../../redux/actions/AdminUserAction";
import { useDispatch } from "react-redux";
import FileSaver from 'file-saver';
import OurTeamApprovalTableContent from "../../../../components/static-pages/our-team/tables/ourTeamApprovalTableContent";
import TestimonialsApprovalTableContent from "../../../../components/static-pages/testimonials/tables/testimonialsApprovalTableContent";
import TestimonialFilter from "../../../../components/filter/static-pages filter/testimonialFilter";
import { testimonialsApprovalsExport } from "../../../../redux/actions/testimonialsAction";
const TestimonialsApprovalTable = () => {
    const [page, setPage] = useState(0);
    const [showFilter, setShowFilter] = useState(false);
    const [search, setSearch] = useState({ value: "" });
    const handleFilterClose = () => {
      setShowFilter(false);     
    };
    const handleFilterOpen = () => {
      setShowFilter(true);
    };
    const handleSearch = (value) => {
      setSearch({ value: value });
      setPage(0)
    };
    const handlePage = (type) => {
      if (type === "+") {
        setPage((prev) => prev + 1)
      }
      else {
        setPage((prev) => prev - 1)
      }                                 
    }
    // const exportFn = () => {
    //     dispatch(exportAdmin(
    //         onSuccess, onError
    //     ));
    // }
    // const onSuccess = (data) => {
    //     console.log("SUCCESS =====================================", data);
    // };
    // const onError = (data) => {
    //     console.log("ERROR==================", data);
    //     var type = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8";
    //     var blob = new Blob([data], { type: type });
    //     FileSaver.saveAs(blob, "file.xlsx");
    // };
    const buttonList = [
        <CreateBtn link={`/static-pages/testimonials/create`} />,
        <ExportBtn dispatchAction={testimonialsApprovalsExport}/>,
        <FilterBtn filterFn={handleFilterOpen} />,
    ];

    return (
        <InnerLayout containerWidth="95%" buttons={buttonList}>
            <TestimonialFilter
                filter={handleSearch}
                showFilter={showFilter}
                closeFilter={handleFilterClose}
            />
            <TestimonialsApprovalTableContent page={page} changePage={handlePage} search={search} />
        </InnerLayout>
    );
}
export default TestimonialsApprovalTable




