import React, { useRef, useState, useEffect } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import InnerLayout from "../../../../components/layouts/innerLayout/innerLayout";
import InnerContainer from "../../../../components/layouts/innerContainer/innerContainer";
import SelectFields from "../../../../components/form/selectFields/selectFields";
import ArrowDownIcon from "../../../../assets/icons/arrowdown";
import { useParams, useNavigate } from "react-router-dom";
import SaveBtn from "../../../../components/buttons/saveBtn";
import DeleteBtn from "../../../../components/buttons/deleteBtn";
import InputField from "../../../../components/form/inputFields/inputField";
import { useDispatch } from "react-redux";
import successToast from "../../../../components/utilits/successToast";
import errorToast from "../../../../components/utilits/errorToast";
import ErrorMsg from "../../../../components/form/errorMsg";
import LoadingSpinnerTable from "../../../../components/utilits/loadingSpinnerTable";
import LoadAndError from "../../../../components/utilits/LoadAndError";
import SubmitBtn from "../../../../components/buttons/submit";
import {
  deleteReferedUserAction,
  editReferedUserAction,
  referedUserSubmitAction,
  viewReferedUserAction,
} from "../../../../redux/actions/referedUserActions";
import { viewWishListFindAll } from "../../../../redux/actions/wishlistActions";
import { invitedReferralOptions } from "../../../../redux/actions/invitedAction";

const ReferredUsersEdit = () => {
  const params = useParams();
  const navigate = useNavigate();
  const [submitData, setSubmitData] = useState();
  const [allUsers, setAllUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [deleteData, setDeleteData] = useState();
  const [wishlistData, setWishlistData] = useState([]);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const dispatch = useDispatch();
  const [opporList, setOpporList] = useState([]);
  useEffect(() => {
    setLoading(true);
    dispatch(viewReferedUserAction(params?.id, onSuccess, onError));
    dispatch(viewWishListFindAll(onSuccessDrop, onErrorDrop));
    dispatch(invitedReferralOptions(onOpporSuccessDrop, onOpporErrorDrop));
  }, []);

  const onOpporSuccessDrop = (data) => {
    const categoryList = [];
    data?.data?.AllOpportunities?.map((item, index) => {
      categoryList[index] = {
        value: item.id,
        label: `${item.title} id: ${item.id}`,
      };
    });
    setOpporList(categoryList);
    console.log("SUCCESS");
  };
  const onOpporErrorDrop = (data) => {
    errorToast(data.message);
  };
  //For viewing
  const onSuccess = (data) => {
    console.log("Hey", data.data);
    setLoading(false);
    setError(false);
    setWishlistData(data.data);
  };
  const onError = (data) => {
    setLoading(false);
    setError(true);
    errorToast(data.message);
  };
  const onSuccessDrop = (data) => {
    const roleTypes = [];
    data?.data.AllUsers.map((el, index) => {
      roleTypes[index] = { value: el.id, label: el.user_name };
    });
    setAllUsers(roleTypes);
  };
  const onErrorDrop = (data) => {
    console.log("ERROR");
  };
  const formRef = useRef();
  const initialValues = {
    user: wishlistData?.user_id ? wishlistData?.user_id : "",
    // phoneNumber: "",
    // email: "",
    referralCode: wishlistData?.referral_code
      ? wishlistData?.referral_code
      : "",
    opportunity: wishlistData?.opportunity_id
      ? wishlistData?.opportunity_id
      : null,
  };

  const interestEditSchema = Yup.object().shape({
    user: Yup.string().trim().required("Required"),
    referralCode: Yup.string().trim().required("Required"),
    opportunity: Yup.string().trim().nullable(true),
  });

  const handleSubmit = () => {
    if (formRef.current) {
      formRef.current.handleSubmit();
    }
  };
  const onSubmit = (values) => {
    console.log(values);
    setLoading(true);
    dispatch(
      editReferedUserAction(
        params?.id,
        {
          user_id: values.user,
          referral_code: values.referralCode,
          opportunity_id: values.opportunity ? values.opportunity : null,
        },
        onSuccessEdit,
        onErrorEdit
      )
    );
  };

  //Edit
  const onSuccessEdit = (data) => {
    console.log(data.data);
    setLoading(false);
    setError(false);
    setSubmitData(data.data.id);
    setSaveHide(true);
    setWishlistData(data.data);
    successToast(data.message);
    navigate("/static-pages/referred-users");
  };
  const onErrorEdit = (data) => {
    setLoading(false);
    errorToast(data.data.message);
    setError(true);
    // errorToast(data.data.message)
  };

  //For deletion
  const onDeleteSuccess = (data) => {
    console.log(data.data);
    setLoading(false);
    setLoading(false);
    successToast(data.message);
    navigate("/static-pages/referred-users");
  };
  const onDeleteError = (data) => {
    setLoading(false);
    errorToast(data.data.message);
    setError(true);
  };

  const handleDelete = () => {
    setLoadingDelete(true);
    dispatch(
      deleteReferedUserAction(deleteData.id, onDeleteSuccess, onDeleteError)
    );
  };

  //on submit api
  console.log(submitData);
  const [saveHide, setSaveHide] = useState(false);

  const submitFnc = () => {
    dispatch(
      referedUserSubmitAction(params.id, onSubmitSuccess, onSubmitError)
    );
  };
  const onSubmitSuccess = (data) => {
    console.log(data);
    successToast(data.message);
    navigate("/static-pages/referred-users");
  };
  const onSubmitError = (data) => {
    console.log("ERROR==================", data);
    errorToast(data.message);
  };

  const defaultOppor = opporList.filter((item) => {
    return item.value === wishlistData?.opportunity_id;
  });

  const buttonList = [
    !saveHide && <SaveBtn submitFn={handleSubmit} loader={loading} />,
    // <SubmitBtn submitFn={submitFnc} saveHide={saveHide} />,
    // <DeleteBtn showModal={handleShow}/>
  ];
  return (
    <InnerLayout buttons={buttonList}>
      <div className="user_view_container">
        <InnerContainer>
          {loading ? (
            <LoadingSpinnerTable />
          ) : (
            <LoadAndError loader={loading} error={error}>
              <div className="row mt-2">
                <div className="col-12 col-sm-12 col-md-9 col-lg-9 col-xl-7">
                  <div className="row ps-1 ms-1 ps-lg-4 ms-lg-1">
                    <div className="ps-2  pt-3">
                      <h5 className="dark_grey_color text-nowrap fs_13 fn_Montserrat fw_500">
                        Refered User ID
                      </h5>
                      <p
                        className={` fs_13 fn_Nunito text-nowrap fw_500 secondary_color`}
                      >
                        {wishlistData?.id}
                      </p>

                      <Formik
                        innerRef={formRef}
                        initialValues={initialValues}
                        onSubmit={onSubmit}
                        enableReinitialize={true}
                        validationSchema={interestEditSchema}
                      >
                        {({}) => {
                          return (
                            <Form className="">
                              <div className="express-form-width">
                                <SelectFields
                                  default1={{
                                    label: wishlistData?.user_name,
                                    value: wishlistData?.user_id,
                                  }}
                                  label={"User"}
                                  placeholder={"select the user"}
                                  name={"user"}
                                  options={allUsers}
                                  id={"user"}
                                />{" "}
                                <InputField
                                  id={"referralCode"}
                                  label={"Enter Referral code"}
                                  type={"text"}
                                  placeholder={"Enter code"}
                                  name={"referralCode"}
                                />
                                <SelectFields
                                  default1={defaultOppor}
                                  options={opporList}
                                  label={"Select Opportunity"}
                                  placeholder={"Opportunity here"}
                                  name={"opportunity"}
                                  id={"opportunity"}
                                />
                              </div>
                            </Form>
                          );
                        }}
                      </Formik>
                    </div>
                  </div>
                </div>
              </div>
            </LoadAndError>
          )}
        </InnerContainer>
      </div>
    </InnerLayout>
  );
};

export default ReferredUsersEdit;
