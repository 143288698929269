import React from "react";
import { useLocation } from "react-router-dom";

const EmptyTable = ({ status = false, type = "table", error = false }) => {
  const location = useLocation()
  return error && !(location.pathname.includes("/edit/")) && !(location.pathname.includes("/view/")) ?
    status ? <tr className="fs_sm_13 fs_16 position-absolute top-50 start-50 translate-middle ">
      <td> ---This table is empty ---</td>
    </tr> :
      <tr className="position-absolute translate-middle fs_sm_13 fs_16 top-50 start-50 ">
        <td> ---Failed to load data---</td>
      </tr>
    : <div className="position-absolute translate-middle fs_sm_13 fs_16 top-50 start-50 ">
      <span> ---Failed to load data---</span>
    </div>
};

export default EmptyTable;
