import React, { useRef } from "react";
import Modal from "react-bootstrap/Modal";
import CloseIcon from "../../../../assets/icons/closeIcon";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import "./opportunitiesmodals.css";
import InputField from "../../../form/inputFields/inputField";
import SelectFields from "../../../form/selectFields/selectFields";
import ArrowDownIcon from "../../../../assets/icons/arrowdown";

const OdometerHighlights = ({
  show,
  handleClose,
  formikValues,
  id,
  reasonUpdator,
}) => {
  const formRef = useRef();
  const initialValues = {
    title: id === 0 || id ? formikValues[id]?.title : "",
    sub_title: id === 0 || id ? formikValues[id]?.sub_title : "",
    odometer_reading: id === 0 || id ? formikValues[id]?.odometer_reading : "",
  };
  const loginSchema = Yup.object().shape({
    title: Yup.string().trim(),
    sub_title: Yup.string().trim(),
    odometer_reading: Yup.string().trim(),
  });
  const handleSubmit = () => {
    if (formRef.current) {
      formRef.current.handleSubmit();
    }
  };
  const onSubmit = (values, { resetForm }) => {
    resetForm();
    handleClose();
    reasonUpdator(id, values);
  };
  return (
    <Modal
      centered
      show={show}
      onHide={handleClose}
      dialogClassName={`oppoptunities_modals_body mx-auto `}
      contentClassName={"oppoptunities_modals_body_content  p-2"}
    >
      <Modal.Body>
        <>
          <div className="d-flex justify-content-between  ">
            <h2 className="green_color fs_15 fs_sm_13 fn_Montserrat text-nowrap fw_600 pt-1">
              ODOMETER HIGHLIGHTS
            </h2>
            <button
              type="button"
              onClick={handleClose}
              className="light_grey_color text-nowrap fs_15 fs_sm_13  fn_Montserrat bg-transparent border-0 fw_600 "
            >
              <span className="pt-1">DISMISS</span>
              <span className="ms-2">
                <CloseIcon width={13} height={13} fill="#A5A5A5" />
              </span>
            </button>
          </div>
          <Formik
            innerRef={formRef}
            initialValues={initialValues}
            onSubmit={onSubmit}
            validationSchema={loginSchema}
          >
            <Form className="mt-3">
              <InputField
                id={"title"}
                label={"Title"}
                type={"text"}
                placeholder={"Enter Title"}
                name={"title"}
              />
              <InputField
                id={"subtitle"}
                label={"Subtitle"}
                type={"text"}
                placeholder={"Enter Subtitle"}
                name={"sub_title"}
              />
              <SelectFields
                Icon={<ArrowDownIcon width={20} height={20} fill={"#A5A5A5"} />}
                label={"Reading"}
                placeholder={"Select Reading Value"}
                name={"odometer_reading"}
                options={selectReadingValue}
                default1={
                  formikValues[id]?.odometer_reading
                    ? {
                      label: formikValues[id]?.odometer_reading,
                      value: formikValues[id]?.odometer_reading,
                    }
                    : ""
                }
                id={"readingValue"}
              />
              <div className="d-flex justify-content-end mt-4">
                <button
                  type="submit"
                  className="secondary_color fs_15  fn_Montserrat fw_600 border-0 tertiary_bg px-4 px-lg-5 py-2 py-lg-2 rounded-2"
                >
                  {id === null ? "CREATE" : "UPDATE"}
                </button>
              </div>
            </Form>
          </Formik>
        </>
      </Modal.Body>
    </Modal>
  );
};
export default OdometerHighlights;
export const selectReadingValue = [
  { value: "low", label: "Low" },
  { value: "high", label: "High" },
  { value: "mid", label: "Mid" },
  { value: "hide", label: "Hide" },
];
