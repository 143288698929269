import { call, takeLatest } from "redux-saga/effects";
import { MEMTRADE_SELL_APPROVALS_LIST, MEMTRADE_SELL_APPROVE, MEMTRADE_SELL_DELETE, MEMTRADE_SELL_EDIT, MEMTRADE_SELL_LIST, MEMTRADE_SELL_READ, MEMTRADE_SELL_VERSION_LIST, MEMTRADE_SELL_CREATE, MEMTRADE_SELL_FIND_ALL, VIEW_APPROVALS_MEMTRADE_SELL_ID, MEMTRADE_SELL_VERSION_HISTORY_BY_ID, MEMTRADE_SELL_REJECT, MEMTRADE_SELL_EXPORT, MEMTRADE_SELL_APPROVALS_EXPORT, MEMTRADE_SELL_VERSION_EXPORT, MEMTRADE_SELL_SUBMIT } from "../actions/types";
import { addMemTradeSellApi, approvalsListMemTradeSellApi, approvalsMemTradeSellByIdApi, approveMemTradeSellApi, createMemTradeSellApi, deleteMemTradeSellApi, editMemTradeSellApi, memTradeSellApprovalsExportApi, memTradeSellSubmitApi, memTradeSellTableExportApi, memTradeSellVersionExportApi, rejectMemTradeSellApi, versionHistoryByIdMemTradeSellApi, versionHistoryListMemTradeSellApi, viewAllMemTradeSellApi, viewFindAllMemTradeSellApi, viewMemTradeSellApi, viewMemTradeSellExportApi } from "../apis/memTradeSellApis";
import { versionHistoryByIdApi } from "../apis/wishlistApis";

export function* addMemTradeSellWorker(action) {
    try {
        const res = yield call(addMemTradeSellApi, {
            name: action.name
        });
        // console.log(res, "AddAdminRole");
        if (res.status === 200 && res.data.status === "success") {
            yield action.onSuccess(res.data);
            console.log(res.data);
        } else if (res.status === 200 && res.data.status === "fail") {
            console.log(res.data);
            yield action.onError(res);
        } else {
            yield action.onError("Something Went Wrong");
        }
    } catch (err) {
        console.log(err);
        yield action.onError("Something Went Wrong");
    }

}
export function* editMemTradeSellWorker(action) {

    try {
        const res = yield call(editMemTradeSellApi, action.id, action.data);
        if (res.status === 200 && res.data.status === "success") {
            yield action.onSuccess(res.data);
            console.log(res.data);
        } else if (res.status === 200 && res.data.status === "fail") {
            console.log(res);
            yield action.onError(res);
        } else {
            yield action.onError("Something Went Wrong");
        }
    } catch (err) {
        console.log(err);
        yield action.onError("Something Went Wrong");
    }

}

export function* deleteMemTradeSellWorker(action) {

    try {
        const res = yield call(deleteMemTradeSellApi, action.id,
        );
        // console.log(res, "AddAdminRole");
        if (res.status === 200 && res.data.status === "success") {
            yield action.onSuccess(res.data);
            console.log(res.data);
        } else if (res.status === 200 && res.data.status === "fail") {
            console.log(res);
            yield action.onError(res);
        } else {
            yield action.onError("Something Went Wrong");
        }
    } catch (err) {
        console.log(err);
        yield action.onError("Something Went Wrong");
    }

}

export function* viewMemTradeSellWorker(action) {
    try {
        const res = yield call(viewMemTradeSellApi,
            action.id,
        );
        // console.log(res, "AddAdminRole");
        if (res.status === 200 && res.data.status === "success") {
            yield action.onSuccess(res.data);
            console.log(res.data);
        } else if (res.status === 200 && res.data.status === "fail") {
            console.log(res.data);
            yield action.onError(res);
        } else {
            yield action.onError("Something Went Wrong!");
            console.log(res.data);
        }
    } catch (err) {
        console.log(err);
        yield action.onError("Something Went Wrong!!");
    }
}
export function* viewAllMemTradeSellWorker(action) {

    try {
        const res = yield call(viewAllMemTradeSellApi,
            action.page_no,
            action.data,
        );
        // console.log(res, "AddAdminRole");
        if (res.status === 200 && res.data.status === "success") {
            yield action.onSuccess(res.data);
            console.log(res);
        } else if (res.status === 200 && res.data.status === "fail") {
            console.log(res);
            yield action.onError(res.data);
        } else {
            yield action.onError("Something Went Wrong");
        }
    } catch (err) {
        console.log(err);
        yield action.onError("Something Went Wrong");
    }

}
export function* approveMemTradeSellWorker(action) {
    try {
        const res = yield call(approveMemTradeSellApi,
            { member_trade_id: action.id }
        );
        // console.log(res, "AddAdminRole");
        if (res.status === 200 && res.data.status === "success") {
            yield action.onSuccess(res.data);
            console.log(res.data);
        } else if (res.status === 200 && res.data.status === "fail") {
            console.log(res);
            yield action.onError(res.data);
        } else {
            yield action.onError("Something Went Wrong!");
            console.log(res.data);
        }
    } catch (err) {
        console.log(err);
        yield action.onError("Something Went Wrong!!");
    }
}
export function* approvalsListMemTradeSellWorker(action) {
    try {
        const res = yield call(approvalsListMemTradeSellApi,
            action.pageNo,
            action.data,
        );
        if (res.status === 200 && res.data.status === "success") {
            yield action.onSuccess(res.data);
            console.log(res.data);
        } else if (res.status === 200 && res.data.status === "fail") {
            console.log(res);
            yield action.onError(res);
        } else {
            yield action.onError("Something Went Wrong!");
            console.log(res.data);
        }
    } catch (err) {
        console.log(err);
        yield action.onError("Something Went Wrong!!");
    }
}
export function* versionHistoryListMemTradeSellWorker(action) {
    try {
        const res = yield call(versionHistoryListMemTradeSellApi,
            action.pageNo,
            action.data,
        );
        if (res.status === 200 && res.data.status === "success") {
            yield action.onSuccess(res.data);
            console.log(res.data);
        } else if (res.status === 200 && res.data.status === "fail") {
            console.log(res);
            yield action.onError(res);
        } else {
            yield action.onError("Something Went Wrong!");
            console.log(res.data);
        }
    } catch (err) {
        yield action.onError("Something Went Wrong!!");
    }
}
export function* createMemTradeSellWorker(action) {
    try {
        const res = yield call(createMemTradeSellApi, action.data
        );
        if (res.status === 200 && res.data.status === "success") {
            yield action.onSuccess(res);
            console.log(res);
        } else if (res.status === 200 && res.data.status === "fail") {
            console.log(res);
            yield action.onError(res.data);
        } else {
            yield action.onError("Something Went Wrong!");
            console.log(res);
        }
    } catch (err) {
        console.log(err);
        yield action.onError("Something Went Wrong!!");
    }
}

export function* memTradeSellFindAllWorker(action) {

    try {
        const res = yield call(viewFindAllMemTradeSellApi);
        // console.log(res, "AddAdminRole");
        if (res.status === 200 && res.data.status === "success") {
            yield action.onSuccess(res.data);
            console.log(res.data);
        } else if (res.status === 200 && res.data.status === "fail") {
            console.log(res);
            yield action.onError(res.data);
        } else {
            yield action.onError("Something Went Wrong");
        }
    } catch (err) {
        console.log(err);
        yield action.onError("Something Went Wrong");
    }

}


export function* memTradeSellApprovalViewByIdWorker(action) {
    try {
        const res = yield call(approvalsMemTradeSellByIdApi, action.id,
        );
        if (res.status === 200 && res.data.status === "success") {
            yield action.onSuccess(res);
            console.log(res);
        } else if (res.status === 200 && res.data.status === "fail") {
            console.log(res);
            yield action.onError(res);
        } else {
            yield action.onError("Something Went Wrong!");
            console.log(res);
        }
    } catch (err) {
        console.log(err);
        yield action.onError("Something Went Wrong!!");
    }
}

export function* memTradeSellVersionHistoryByIdWorker(action) {
    try {
        const res = yield call(versionHistoryByIdMemTradeSellApi, action.id,
        );
        if (res.status === 200 && res.data.status === "success") {
            yield action.onSuccess(res);
        } else if (res.status === 200 && res.data.status === "fail") {
            yield action.onError(res);
        } else {
            yield action.onError("Something Went Wrong!");
        }
    } catch (err) {
        console.log(err);
        yield action.onError("Something Went Wrong!!");
    }
}


export function* memTradeSellRejectByIdWorker(action) {
    try {
        const res = yield call(rejectMemTradeSellApi, action.id,
        );
        if (res.status === 200 && res.data.status === "success") {
            yield action.onSuccess(res);
            console.log(res);
        } else if (res.status === 200 && res.data.status === "fail") {
            console.log(res);
            yield action.onError(res);
        } else {
            yield action.onError("Something Went Wrong!");
            console.log(res);
        }
    } catch (err) {
        console.log(err);
        yield action.onError("Something Went Wrong!!");
    }
}


export function* memTradeSellExportWorker(action) {

    try {
        const res = yield call(viewMemTradeSellExportApi);
        // console.log(res, "AddAdminRole");
        if (res.status === 200 && res.data.status === "success") {
            yield action.onSuccess(res.data);
            console.log(res);
        } else if (res.status === 200 && res.data.status === "fail") {
            console.log(res);
            yield action.onError(res.data);
        } else {
            yield action.onError("Something Went Wrong");
        }
    } catch (err) {
        console.log(err);
        yield action.onError("Something Went Wrong");
    }

}

//export
export function* memTradeSellTableExportWorker(action) {
    try {
        const res = yield call(memTradeSellTableExportApi,

        );
        if (res.status === 200 && res.data.status === "success") {
            yield action.onSuccess(res.data);
        } else {
            yield action.onError(res.data);
        }
    } catch (error) { }
}


export function* memTradeSellApprovalsExportWorker(action) {
    try {
        const res = yield call(memTradeSellApprovalsExportApi);
        console.log(res);
        if (res.status === 200 && res.data.status === "success") {
            console.log(res.data);
            yield action.onSuccess(res.data.data);
        } else {
            yield action.onError(res.data);
        }
    } catch (error) { }
}

export function* memTradeSellVersionExportWorker(action) {
    try {
        const res = yield call(memTradeSellVersionExportApi);
        console.log(res);
        if (res.status === 200 && res.data.status === "success") {
            yield action.onSuccess(res.data);
        } else {
            yield action.onError(res.data);
        }
    } catch (error) { }
}


export function* memTradeSellSubmitWorker(action) {
    try {
      const res = yield call(memTradeSellSubmitApi, action.id);
      console.log(res);
      if (res.status === 200 && res.data.status === "success") {
        yield action.onSuccess(res.data);
      } else {
        yield action.onError(res.data);
      }
    } catch (error) { }
  }
  

// export function* watchAddMemTradeSellWorker() {
//     yield takeLatest(OPPOR_TYPE_CREATE, addMemTradeSellWorker);
// }
export function* watchCreateMemTradeSellWorker() {
    yield takeLatest(MEMTRADE_SELL_CREATE, createMemTradeSellWorker);
}

export function* watchEditMemTradeSellWorker() {
    yield takeLatest(MEMTRADE_SELL_EDIT, editMemTradeSellWorker);
}
export function* watchDeleteMemTradeSellWorker() {
    yield takeLatest(MEMTRADE_SELL_DELETE, deleteMemTradeSellWorker);
}
export function* watchViewMemTradeSellWorker() {
    yield takeLatest(MEMTRADE_SELL_READ, viewMemTradeSellWorker);
}

export function* watchViewAllMemTradeSellWorker() {
    yield takeLatest(MEMTRADE_SELL_LIST, viewAllMemTradeSellWorker);
}

export function* watchApproveMemTradeSellWorker() {
    yield takeLatest(MEMTRADE_SELL_APPROVE, approveMemTradeSellWorker);
}
export function* watchApprovalsListMemTradeSellWorker() {
    yield takeLatest(MEMTRADE_SELL_APPROVALS_LIST, approvalsListMemTradeSellWorker);
}

export function* watchVersionHistoryListMemTradeSellWorker() {
    yield takeLatest(MEMTRADE_SELL_VERSION_LIST, versionHistoryListMemTradeSellWorker);
}

//Find all section
export function* watchMemTradeSellFindAllWorker() {
    yield takeLatest(MEMTRADE_SELL_FIND_ALL, memTradeSellFindAllWorker);
}


export function* watchViewMemTradeSellApprovalByIdWorker() {
    yield takeLatest(VIEW_APPROVALS_MEMTRADE_SELL_ID, memTradeSellApprovalViewByIdWorker);
}

export function* watchMemTradeSellViewVersionHistorylByIdWorker() {
    yield takeLatest(MEMTRADE_SELL_VERSION_HISTORY_BY_ID, memTradeSellVersionHistoryByIdWorker);
}

export function* watchMemTradeSellRejectByIdWorker() {
    yield takeLatest(MEMTRADE_SELL_REJECT, memTradeSellRejectByIdWorker);
}





//export
export function* memTradeSellTableExportWatcher() {
    yield takeLatest(
        MEMTRADE_SELL_EXPORT,
        memTradeSellTableExportWorker
    );
}
export function* memTradeSellApprovalsExportWatcher() {
    yield takeLatest(MEMTRADE_SELL_APPROVALS_EXPORT, memTradeSellApprovalsExportWorker);
}

export function* memTradeSellVersionExportWorkerWatcher() {
    yield takeLatest(MEMTRADE_SELL_VERSION_EXPORT, memTradeSellVersionExportWorker);
}
export function* memTradeSellSubmitWorkerWatcher() {
    yield takeLatest(MEMTRADE_SELL_SUBMIT, memTradeSellSubmitWorker);
}


