import React, { useEffect, useRef, useState } from "react";
import "../our-advisors.css";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import SaveBtn from "../../../../components/buttons/saveBtn";
import InnerContainer from "../../../../components/layouts/innerContainer/innerContainer";
import InnerLayout from "../../../../components/layouts/innerLayout/innerLayout";
import DeleteBtn from "../../../../components/buttons/deleteBtn";
import SelectFields from "../../../../components/form/selectFields/selectFields";
import InputField from "../../../../components/form/inputFields/inputField";
// import ArrowDownIcon from "../../../../assets/icons/arrowdown";
import ErrorMsg from "../../../../components/form/errorMsg";
import CloseIcon from "../../../../assets/icons/closeIcon";
import UploadIcon from "../../../../assets/icons/upload";
import ArrowRightIcon from "../../../../assets/icons/arrowrightIcon";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { addAdvisorsAction } from "../../../../redux/actions/ourAdvisorAction";
import { uploadAddressProofFrontImageAction, uploadProfileImageAction } from "../../../../redux/actions/userAction";
import successToast from "../../../../components/utilits/successToast";
import errorToast from "../../../../components/utilits/errorToast";

const OurAdvisorsCreate = () => {
  const [file, setFile] = useState(null);
  const [profilePicture, setProfilePicture] = useState("");
  const [loading, setLoading] = useState(false);
  const formRef = useRef();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleSubmit = () => {
    if (!deskImageLink.img) {
      if (!deskImageLink.img) {
        setDeskImageLink({ img: "", error: true });
      }
      if (!mobImageLink.img) {
        setMobImageLink({ img: "", error: true });
      }
    }
    if (formRef.current) {
      formRef.current.handleSubmit();
    }
  };

  function handleImageChange(e, param1) {
    console.log(param1);
    if (e.target?.files.length !== 0) {
      setFile(URL.createObjectURL(e.target.files[0]));

      dispatch(
        uploadProfileImageAction(
          e.target.files[0],
          onProfileUploadImageSuccess,
          onProfileUploadImageError
        )
      );
      e.target.value = null;
    }
  }

  const onProfileUploadImageSuccess = (data) => {
    successToast(data?.message)
    console.log(data.data.data);
    setProfilePicture(data.data.data.location);
  };
  const onProfileUploadImageError = (data) => {
    console.log(data);
  };

  const initialValues = {
    profileImage: "",
    Name: "",
    website_url: "",
    Designation: "",
    Prirority: "",
    Description: "",
  };
  const loginSchema = Yup.object().shape({
    Name: Yup.string().trim().required("Required"),
    Designation: Yup.string().trim().required("Required"),
    website_url: Yup.string().trim().required("Required"),
    Prirority: Yup.number().positive("Priority should be greater than  zero").required("Required"),
    Description: Yup.string().trim().required("Required"),
  });
  const options = [
    { label: "Active", value: "Active" },
    { label: "InActive", value: "InActive" },
  ];
  const onSubmit = (values, { resetForm }) => {
    if (mobImageLink.img && deskImageLink.img)
      dispatch(
        addAdvisorsAction(
          {
            name: values.Name,
            website_url: values.website_url,
            website_icon: mobImageLink.img,
            description: values.Description,
            designation: values.Designation,
            photo_url: deskImageLink.img,
            priority: values.Prirority,
          },
          onSuccess,
          onError
        )
      );
  };

  const onSuccess = (data) => {
    console.log(data);
    setLoading(false);
    successToast(data.message);
    navigate(`/static-pages/our-advisors/edit/${data?.data?.id}`);
  };
  const onError = (data) => {
    console.log(data);
    setLoading(false);
    errorToast(data?.data?.data);
  };
  // image
  const [deskImageLink, setDeskImageLink] = useState({
    img: "",
    error: false,
  });
  const [mobImageLink, setMobImageLink] = useState({
    img: "",
    error: false,
  });
  const imageUpload = (e) => {
    if (e.target.name === "deskImage") {
      dispatch(
        uploadAddressProofFrontImageAction(
          e.target.files[0],
          onDeskImageUploadSucess,
          onDeskImageUploadError
        )
      );
    }
    else if (e.target.name === "mobImage") {
      dispatch(
        uploadAddressProofFrontImageAction(
          e.target.files[0],
          onMobImageUploadSucess,
          onMobImageUploadError
        )
      );
    }
    e.target.value = null;
    e.target.value = null;
  };
  const onDeskImageUploadSucess = (data) => {
    setDeskImageLink({ img: data.data.data.location, error: false });
  };
  const onDeskImageUploadError = (data) => {
    setDeskImageLink({ img: "", error: true });
    console.log(data);
  };
  const onMobImageUploadSucess = (data) => {
    setMobImageLink({ img: data.data.data.location, error: false });
  };
  const onMobImageUploadError = (data) => {
    setMobImageLink({ img: "", error: true });
    console.log(data);
  };

  const buttonList = [<SaveBtn submitFn={handleSubmit} loader={loading} />];

  return (
    <InnerLayout buttons={buttonList}>
      <div className="our-advisors-container">
        <InnerContainer>
          <div className="  ps-4 ps-lg-4 ms-3">
            <Formik
              innerRef={formRef}
              initialValues={initialValues}
              onSubmit={onSubmit}
              validationSchema={loginSchema}
            >
              {({ formik, handleChange, values, setFieldValue }) => {
                return (
                  <Form className="">
                    <div className="our-advisors-form-width">
                      <div className="row">
                        <div className="col-12 col-md-6">
                          <div className="mb-3">
                            <span className="mb-2 dark_grey_color fn_Montserrat fw_500 fs_14 ps-2">Profile picture</span>
                            <div className="blog_create_image_container mt-1 position-relative d-flex  justify-content-center align-items-center">
                              <input
                                multiple={false}
                                style={{ visibility: "hidden" }}
                                type="file"
                                id="deskImage"
                                className="upload_document_input"
                                name="deskImage"
                                accept="image/* ,capture=camera"
                                onChange={(e) => {
                                  imageUpload(e);
                                }}
                              />
                              {deskImageLink.img ? (
                                <>
                                  <img
                                    src={deskImageLink.img}
                                    className={"user-edit-profile-picture"}
                                  />
                                  <button
                                    type="button"
                                    onClick={(e) => {
                                      setDeskImageLink({ img: "", error: true });
                                    }}
                                    className="position-absolute border-0 cursor_pointer white_bg d-flex gap-2 py-1 px-2 user_edit_profile_picture_remove_container"
                                  >
                                    <CloseIcon fill="#666666" />
                                    <span className="dark_grey_color fs_13 fn_Montserrat fw_500 ">
                                      Remove
                                    </span>
                                  </button>
                                </>
                              ) : (
                                <label
                                  htmlFor="deskImage"
                                  className="d-flex flex-column align-items-center mt-2 justify-content-center fs_12"
                                >
                                  <UploadIcon width={30} height={30} />
                                  Choose Profile Picture
                                </label>
                              )}
                            </div>
                            {deskImageLink.error && (
                              <span className="fs_12 ms-2 text-danger fw-semibold fn_Nunito">
                                Required!
                              </span>
                            )}
                          </div>
                          <InputField
                            id={"Name"}
                            label={"Name"}
                            type={"text"}
                            placeholder={"Enter Name"}
                            name={"Name"}
                          />
                          <InputField
                            id={"Designation"}
                            label={"Designation"}
                            type={"text"}
                            placeholder={"Enter designation"}
                            name={"Designation"}
                          />

                          <InputField
                            id={"website_url"}
                            label={"Website URL"}
                            type={"text"}
                            placeholder={"Enter Website URL"}
                            name={"website_url"}
                          />
                          <div className="">
                            <span className="mb-3">Website Icon</span>
                            <div className="blog_create_image_container mt-3 position-relative d-flex  justify-content-center align-items-center">
                              <input
                                multiple={false}
                                style={{ visibility: "hidden" }}
                                type="file"
                                id="mobImage"
                                className="upload_document_input"
                                name="mobImage"
                                accept="image/* ,capture=camera"
                                onChange={(e) => {
                                  imageUpload(e);
                                }}
                              />
                              {mobImageLink.img ? (
                                <>
                                  <img
                                    src={mobImageLink.img}
                                    className={"user-edit-profile-picture"}
                                  />
                                  <button
                                    type="button"
                                    onClick={(e) => {
                                      setMobImageLink({ img: "", error: true });
                                    }}
                                    className="position-absolute border-0 cursor_pointer white_bg d-flex gap-2 py-1 px-2 user_edit_profile_picture_remove_container"
                                  >
                                    <CloseIcon fill="#666666" />
                                    <span className="dark_grey_color fs_13 fn_Montserrat fw_500 ">
                                      Remove
                                    </span>
                                  </button>
                                </>
                              ) : (
                                <label
                                  htmlFor="mobImage"
                                  className="d-flex flex-column align-items-center mt-2 justify-content-center fs_12"
                                >
                                  <UploadIcon width={30} height={30} />
                                  Choose Profile Picture
                                </label>
                              )}
                            </div>
                            {mobImageLink.error && (
                              <span className="fs_12 ms-2 text-danger fw-semibold fn_Nunito">
                                Required!
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="col-12 col-md-6">
                          <div className="ps-0 ps-lg-4">
                            <InputField
                              id={"Prirority"}
                              label={"Prirority"}
                              type={"number"}
                              placeholder={"Enter Prirority"}
                              name={"Prirority"}
                            />
                            <label
                              id="Description"
                              className="mb-2 dark_grey_color fn_Nunito mt-2 fw_500 fs_15 ps-1 ms-1"
                            >
                              Description
                            </label>
                            <Field name={"Description"}>
                              {({ field }) => (
                                <textarea
                                  id="Description"
                                  {...field}
                                  placeholder={"Enter Description"}
                                  type="text"
                                  rows="4"
                                  className="w-100 llp_message_container p-2 "
                                ></textarea>
                              )}
                            </Field>
                            <ErrorMsg name={"Description"} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </Form>
                );
              }}
            </Formik>
          </div>
        </InnerContainer>
      </div>
    </InnerLayout>
  );
};

export default OurAdvisorsCreate;

export const designation = [
  { value: "Project Manager", label: "Project Manager" },
  { value: "Manager", label: "Manager" },
  { value: "Advisor", label: "Advisor" },
];
export const priority = [
  { value: "1", label: "1" },
  { value: "2", label: "2" },
  { value: "3", label: "3" },
  { value: "4", label: "4" },
  { value: "5", label: "5" },
];
