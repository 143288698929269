import React, { useEffect, useRef, useState } from "react";
import "./opportunities.css";
import InnerLayout from "../../../components/layouts/innerLayout/innerLayout";
import OpportunitiesReadHeadTag from "../../../components/opportunity/opportunties/opportunitiesRead/opportunitiesReadHeadTag";
import OpportunitiesReadTitle from "../../../components/opportunity/opportunties/opportunitiesRead/opportunitiesReadTitle";
import OpportunitiesList from "../../../components/opportunity/opportunties/opportunitiesRead/opportunitiesSectionList";
import OpportunitiesOverview from "../../../components/opportunity/opportunties/opportunitiesRead/opportunities-overview";
import Financials from "../../../components/opportunity/opportunties/opportunitiesRead/opportunitiesFinancials";
import OpportunitiesKeyhighlights from "../../../components/opportunity/opportunties/opportunitiesRead/opportunitiesKeyHighlights";
import OpportunitiesNote from "../../../components/opportunity/opportunties/opportunitiesRead/opportunitiesNotes";
import Resources from "../../../components/opportunity/opportunties/opportunitiesRead/opportunitiesResources";
import Faqs from "../../../components/opportunity/opportunties/opportunitiesRead/opportunitiesFaq";
import Something from "../../../components/opportunity/opportunties/opportunitiesRead/opportunities-card-header";
import AnalyticsBtn from "../../../components/buttons/analyticsBtn";
import ShareBtn from "../../../components/buttons/sharableBtn";
import VersionHistoryBtn from "../../../components/buttons/versionHistoryBtn";
import DeleteBtn from "../../../components/buttons/deleteBtn";
import EditBtn from "../../../components/buttons/editBtn";
import PauseBtn from "../../../components/buttons/pauseBtn";
import OpportunitiesBottomNavbar from "../../../components/opportunity/opportunties/opportunitiesRead/opportunitiesBottomNavbar";
import Slider from "../../../components/opportunity/opportunties/opportunitiesRead/Carousel";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { fetchOpportunitiesDetails } from "../../../redux/actions/opportunitiesActions";
import errorToast from "../../../components/utilits/errorToast";
import InsightsCardSection from "../../../components/opportunity/opportunties/opportunitiesRead/InsightsCardSection";
import Opportunity_Card from "../../../components/opportunity/opportunties/opportunitiesRead/opportunityCard/opportunity_Card";
import OpportunitiesViewInnerContainer from "../../../components/layouts/innerContainer/opportunitiesViewInnerContainer";
import { Link } from "react-scroll";
import StickyBox from "react-sticky-box";
import styles from "../../../components/opportunity/opportunties/opportunitiesRead/opportunitiesReadHeadTag";
import LoadingSpinnerTable from "../../../components/utilits/loadingSpinnerTable";
import LoadAndError from "../../../components/utilits/LoadAndError";
import CreateLlpBtn from "../../../components/buttons/createLlp";
import CreateLlpModal from "../../../components/opportunity/llp/modals/createLlpModal";
import 'react-quill/dist/quill.snow.css';
const OpportunitiesRead = ({ id = "container" }) => {
  const params = useParams();
  const dispatch = useDispatch();
  const [opportunitiesDetails, setOpportunitiesDetails] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [showLlpModal, setShowLlpModal] = useState(false);
  const handleShowLlpModal = () => setShowLlpModal(true);
  useEffect(() => {
    setLoading(true);
    dispatch(fetchOpportunitiesDetails(params?.id, onSuccess, onError));
  }, []);

  const onSuccess = (data) => {
    setLoading(false);
    setOpportunitiesDetails(data?.data);
    setError(false);
  };
  const onError = (data) => {
    console.log(data);
    setLoading(false);
    setError(true);
  };
  const containerRef = useRef();
  const buttonList = [
    // <ShareBtn />,
    <VersionHistoryBtn link="/opportunity/opportunities/version-history" />,
    <AnalyticsBtn
      link={`/opportunity/opportunities/analytics/${opportunitiesDetails?.id}`}
    />,
    opportunitiesDetails?.editable && (
      <EditBtn link={`/opportunity/opportunities/edit/${params?.id}`} />
    ),
    opportunitiesDetails?.status === "closed" &&
    opportunitiesDetails?.lastActionApproved && (
      <CreateLlpBtn showModal={handleShowLlpModal} />
    ),
    // <PauseBtn />,
    // <DeleteBtn />,
  ];
  return (
    <InnerLayout buttons={buttonList}>
      {loading ? (
        <LoadingSpinnerTable />
      ) : (
        <LoadAndError loader={loading} error={error}>
          <OpportunitiesViewInnerContainer
            containerClassName="oppor_edit_inner__container"
            overflowClassName=""
          >
            <div className="w-100 fn_Montserrat position-relative">
              <div
                ref={containerRef}
                id={id}
                className="opportunities_over_flow "
              >
                <OpportunitiesReadHeadTag data={opportunitiesDetails?.tags} />
                <div className=" mt-md-0 pt-md-0 p-3">
                  <Slider data={opportunitiesDetails} />
                  <OpportunitiesReadTitle data={opportunitiesDetails} />
                  <OpportunitiesList data={opportunitiesDetails?.content} />
                  {opportunitiesDetails?.content?.map((item) => {
                    return (
                      <div
                        className="d-lg-flex ms-lg-5 mt-lg-3 mt-3 justify-content-center"
                        id="overview"
                      >
                        <div className="col-lg-3  me-lg-5">
                          <StickyBox offsetBottom={30}>
                            <h3 className="mb-4 fn_Montserrat fw_600 secondary_color pt-3">
                              {item?.section_heading}
                            </h3>
                            <ul
                              className={`d-lg-block d-none list-unstyled p-0`}
                            >
                              {item?.subsections.map((subItem) => {
                                return (
                                  <Link
                                    smooth={false}
                                    to={subItem.title}
                                    activeClass="active"
                                    spy={true}
                                    isDynamic={true}
                                    // offset={-130}
                                    className="text-decoration-none  cursor_pointer"
                                  >
                                    <li className="mb-2 fn_Montserrat fw_600 light_green_color">
                                      {subItem.title}
                                    </li>
                                  </Link>
                                );
                              })}
                            </ul>
                          </StickyBox>
                        </div>
                        <div className="col-lg-6 col-12  ms-lg-5">
                          {" "}
                          {item?.subsections.map((subsectionsItems) => {
                            return (
                              <div className="" id={subsectionsItems?.title}>
                                <h4
                                  className={`${styles.overview_title} fs_20  fn_Montserrat fw_400 light_green_color bor w-25 pb-2 pt-4`}
                                >
                                  {subsectionsItems?.title}
                                </h4>
                                {subsectionsItems?.content.map(
                                  (contentItem) => {
                                    if (contentItem?.type === "text")
                                      return (
                                        <div
                                          className="fn_Nunito fs_15 popup_title_color fw_600 p-0 ms-3"
                                          dangerouslySetInnerHTML=
                                          {{
                                            __html: contentItem?.value.replace(
                                              /<p>/g,
                                              `<p class="mb-0">`
                                            ).replace(
                                              /<ul>/g,
                                              `<ul class="mb-0">`
                                            ).replace(
                                              /<li>/g,
                                              `<li class="mb-0">`
                                            ).replace(
                                              /<h6>/g,
                                              `<h6 class="mb-0">`
                                            ),
                                          }}
                                        ></div>
                                      );
                                    else if (contentItem?.type === "table") {
                                      // if (contentItem?.table_type === "type1") {
                                      //   return <>table</>;
                                      // } '

                                      if (contentItem?.table_type) {
                                        return (
                                          <>
                                            {" "}
                                            {contentItem?.heading.col1 ? (
                                              <>
                                                <div className="d-flex mb-3 gap-lg-5">
                                                  <div className="col-lg-5 col-6 col-md-6 col-sm-6">
                                                    <span className="secondary_color fn_Nunito fw_600 fs_16 ">
                                                      {
                                                        contentItem?.heading
                                                          .col1
                                                      }
                                                    </span>
                                                  </div>

                                                  <div className="col-lg-5 col-6 col-md-6 col-sm-6  d-flex flex-column">
                                                    <span className="secondary_color fn_Montserrat fw_600 fs_16   ps-3 ps-lg-0 ">
                                                      {
                                                        contentItem?.heading
                                                          .col2
                                                      }
                                                    </span>
                                                  </div>
                                                </div>
                                                <hr className="w-100" />{" "}
                                              </>
                                            ) : null}
                                            {contentItem?.body.map(
                                              (tableContent) => {
                                                return (
                                                  <div className="d-flex mb-3 gap-lg-5">
                                                    <div className="col-lg-5 col-6 col-md-6 col-sm-6  d-flex flex-column">
                                                      <span className="plankton_green fn_Nunito fw_600 fs_16   ">
                                                        {
                                                          tableContent.col1_title
                                                        }
                                                      </span>
                                                      {tableContent.col1_caption && (
                                                        <span className="fs_14 fw_400 fn_Nunito black_color">
                                                          {
                                                            tableContent.col1_caption
                                                          }
                                                        </span>
                                                      )}
                                                    </div>
                                                    <div className="col-lg-5 col-6 col-md-6 col-sm-6  d-flex flex-column">
                                                      <span className="secondary_color fn_Montserrat fw_600 fs_16   ">
                                                        {
                                                          tableContent.col2_title
                                                        }{" "}
                                                        <span className="light_green_color"></span>
                                                      </span>
                                                      {tableContent.col2_caption && (
                                                        <span className="fs_14 fw_400 fn_Nunito popup_title_color   fs_md_12">
                                                          {
                                                            tableContent.col2_caption
                                                          }
                                                        </span>
                                                      )}
                                                    </div>
                                                  </div>
                                                );
                                              }
                                            )}
                                            <hr className="w-100" />
                                            <div className="d-flex mb-3 gap-lg-5">
                                              <div className="col-lg-5 col-6 col-md-6 col-sm-6">
                                                <span className="secondary_color fn_Montserrat fw_600 fs_16 ">
                                                  {contentItem?.footer.col1}
                                                </span>
                                              </div>
                                              <div className="col-lg-5 col-6 col-md-6 col-sm-6">
                                                <span className="secondary_color fn_Montserrat fw_600 fs_16  ">
                                                  {contentItem?.footer.col2}
                                                </span>
                                              </div>
                                            </div>
                                            <div
                                              dangerouslySetInnerHTML={{
                                                __html:
                                                  contentItem?.footerNotes,
                                              }}

                                            ></div>
                                            {/* <ol
                                  className={` popup_title_color fs_15  fn_Nunito fw_600 p-0 ms-3`}
                                >
                                  <li className="mb-2"> {text}</li>
                                  <li className="mb-2"> {text}</li>
                                  <li className="mb-2"> {text}</li>
                                </ol> */}
                                          </>
                                        );
                                      }
                                    } else if (contentItem?.type === "file") {
                                      return (
                                        <OpportunitiesNote data={contentItem} />
                                      );
                                    }
                                  }
                                )}

                                {/* <ul
                        className={`fn_Nunito fs_15 popup_title_color fw_600 p-0 ms-3`}
                      >
                        <li className="mb-2"> {item.detail}</li>
                        <li className="mb-2"> {item.detail}</li>
                        <li className="mb-2"> {item.detail}</li>
                      </ul> */}
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    );
                  })}

                  <Faqs data={opportunitiesDetails?.faq} />
                </div>
                <Something />
                {/* <div className=" overflow_x_scroll scroll_none mx-3 px-3 my-4 row mx-auto justify-content-center py-3 gap-5">
              <Opportunity_Card data />
              <Opportunity_Card />
              <Opportunity_Card />
            </div> */}
              </div>
              {/* <div className="pt-auto">
            <OpportunitiesBottomNavbar position={containerRef} id={id} />
          </div> */}
            </div>
          </OpportunitiesViewInnerContainer>
          <CreateLlpModal
            closeModal={() => setShowLlpModal(false)}
            show={showLlpModal}
          />
        </LoadAndError>
      )}
    </InnerLayout>
  );
};

export default OpportunitiesRead;
