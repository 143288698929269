import { authHeader } from "../config";
import { api } from "./api";

export const addMediaRecApi = (params) => {
    return api.post("/api/homestatic/create-media-recognition", params, {
        headers: {
            "x-access-token": localStorage.getItem("accessToken"),
            "Content-Type": "application/json",
        },
    });
};
export const viewMediaRecListApi = (id, params) => {
    return api.post(`/api/homestatic/view-media-recognition/${id}`, params, {
        headers: {
            "x-access-token": localStorage.getItem("accessToken"),
            "Content-Type": "application/json",
        },
    });
};
export const viewMediaRecApi = (pageNo = 0, params) => {
    return api.post(`/api/homestatic/views-media-recognitions?page_no=${pageNo}`, params, {
        headers: {
            "x-access-token": localStorage.getItem("accessToken"),
            "Content-Type": "application/json",
        },
    });
};
export const editMediaRecApi = (id, params) => {
    return api.post(`/api/homestatic/edit-media-recognition/${id}`, params, {
        headers: {
            "x-access-token": localStorage.getItem("accessToken"),
            "Content-Type": "application/json",
        },
    });
};
export const deleteMediaRecApi = (id, params) => {
    return api.post(`/api/homestatic/delete-media-recognition/${id}`, params, {
        headers: {
            "x-access-token": localStorage.getItem("accessToken"),
            "Content-Type": "application/json",
        },
    });
};
export const approveMediaRecApi = (params) => {
    return api.post("/api/homestatic/approve-media-recognition", JSON.stringify(params), {
        headers: {
            "x-access-token": localStorage.getItem("accessToken"),
            "Content-Type": "application/json",
        },
    });
}
export const rejectMediaRecApi = (id, params) => {
    return api.post(`/api/homestatic/reject-media-recognition/${id}`, params, {
        headers: {
            "x-access-token": localStorage.getItem("accessToken"),
            "Content-Type": "application/json",
        },
    });
};
export const approvalsMediaRecListpApi = (page = 0, params) => {
    return api.post(`/api/homestatic/views-all-media-recognition-checkkers?page_no=${page}`, params, {
        headers: {
            "x-access-token": localStorage.getItem("accessToken"),
            "Content-Type": "application/json",
        },
    });
}
export const viewApprovalMediaRecApi = (id, params) => {
    return api.post(`/api/homestatic/view-one-media-recognition-checkker/${id}`, params, {
        headers: {
            "x-access-token": localStorage.getItem("accessToken"),
            "Content-Type": "application/json",
        },
    });
};
export const versionHistoryByIdApi = (id, params) => {
    return api.post(`/api/homestatic/view-media-recognition-version/${id}`, params, {
        headers: {
            "x-access-token": localStorage.getItem("accessToken"),
            "Content-Type": "application/json",
        },
    });
}
export const viewVersionHistoryAllMediaRecApi = (page = 0, params) => {
    return api.post(`/api/homestatic/views-media-recognition-versions?page_no=${page}`, params, {
        headers: {
            "x-access-token": localStorage.getItem("accessToken"),
            "Content-Type": "application/json",
        },
    });
};



export const mediaRecMainExportApi = (page, params) => {
    return api.post(
        `/api/homestatic/export-main-media-recog`,
        JSON.stringify(params),
        {
            headers: {
                "x-access-token": localStorage.getItem("accessToken"),
                "Content-Type": "application/json",
            },
        }
    );
};

export const mediaRecApprovalsExportApi = (page, params) => {
    return api.post(
        `/api/homestatic/export-approval-media-recog`,
        JSON.stringify(params),
        {
            headers: {
                "x-access-token": localStorage.getItem("accessToken"),
                "Content-Type": "application/json",
            },
        }
    );
};

export const mediaRecVersionExportApi = (page, params) => {
    return api.post(
        `/api/homestatic/export-version-media-recog`,
        JSON.stringify(params),
        {
            headers: {
                "x-access-token": localStorage.getItem("accessToken"),
                "Content-Type": "application/json",
            },
        }
    );
};


export const mediaRecSubmitApi = (id, params) => {
    return api.post(`/api/homestatic/on-submitm/${id}`, params, {
        headers: {
            "x-access-token": localStorage.getItem("accessToken"),
            "Content-Type": "application/json",
        },
    });
};
