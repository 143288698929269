import React, { useRef, useState } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import InnerLayout from "../../../../components/layouts/innerLayout/innerLayout";
import InnerContainer from "../../../../components/layouts/innerContainer/innerContainer";
import { useNavigate } from "react-router-dom";
import SaveBtn from "../../../../components/buttons/saveBtn";
import InputField from "../../../../components/form/inputFields/inputField";
import successToast from "../../../../components/utilits/successToast";
import errorToast from "../../../../components/utilits/errorToast";
import { useDispatch } from "react-redux";
import ErrorMsg from "../../../../components/form/errorMsg";
import {
  blogCategorySubmit,
  createBlogCategoryAction,
} from "../../../../redux/actions/blogCategoryActions";
import LoadingSpinnerTable from "../../../../components/utilits/loadingSpinnerTable";

const BlogCategoryCreate = () => {
  const navigate = useNavigate();
  const [submitData, setSubmitData] = useState();
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const formRef = useRef();
  const initialValues = {
    blogCategoryName: "",
    blogCategoryTitle: "",
    blogCategorySubtitle: "",
    blogCategoryDesc: "",
    priority: "",
  };
  const interestEditSchema = Yup.object().shape({
    blogCategoryName: Yup.string().trim().required("Required"),
    blogCategoryTitle: Yup.string().trim(),
    blogCategorySubtitle: Yup.string().trim(),
    blogCategoryDesc: Yup.string().trim(),
    priority:Yup.number()
    .positive("Priority should be greater than  zero")
  });

  const handleSubmit = () => {
    if (formRef.current) {
      formRef.current.handleSubmit();
    }
  };
  const onSubmit = (values) => {
    dispatch(
      createBlogCategoryAction(
        {
          name: values.blogCategoryName,
          title: values.blogCategoryTitle,
          subtitle: values.blogCategorySubtitle,
          description: values.blogCategoryDesc,
          priority :values.priority? values.priority : null
        },
        onSuccess,
        onError
      )
    );
  };

  const onSuccess = (data) => {
    successToast("blog category created,waiting for approval");
    setSubmitData(data.data.id);
    setSaveHide(true);
    navigate(`/blog-category/edit/${data.data.id}`);
    setLoading(false);
  };
  const onError = (data) => {
    console.log(data);
    errorToast(data.message);
    setLoading(false);
  };

  //on submit api
  console.log(submitData);
  const [saveHide, setSaveHide] = useState(false);

  const submitFnc = () => {
    dispatch(blogCategorySubmit(submitData, onSubmitSuccess, onSubmitError));
  };
  const onSubmitSuccess = (data) => {
    console.log(data);
    // navigate("/blog-category");
    successToast(data.message);
  };
  const onSubmitError = (data) => {
    console.log("ERROR==================", data);
    errorToast(data.message);
  };

  const buttonList = [
    !saveHide && <SaveBtn submitFn={handleSubmit} loader={loading} />,
    // <SubmitBtn submitFn={submitFnc} saveHide={saveHide} />,
  ];
  return (
    <InnerLayout buttons={buttonList}>
      <div className="user_view_container">
        <InnerContainer>
          {loading ? (
            <LoadingSpinnerTable />
          ) : (
            <div className="row mt-2">
              <div className="col-12 col-sm-12 col-md-9 col-lg-9 col-xl-7">
                <div className="row ps-1 ms-1 ps-lg-4 ms-lg-1">
                  <div className="ps-2  pt-3">
                    <Formik
                      innerRef={formRef}
                      initialValues={initialValues}
                      onSubmit={onSubmit}
                      enableReinitialize={true}
                      validationSchema={interestEditSchema}
                    >
                      {({ formik, handleChange, values, setFieldValue }) => {
                        return (
                          <Form className="">
                            <div className="   express-form-width">
                              <InputField
                                id={"blogCategoryName"}
                                label={"Blog Category Name"}
                                type={"text"}
                                placeholder={"Enter Blog Category Name here"}
                                name={"blogCategoryName"}
                              />
                              <InputField
                                id={"blogCategoryTitle"}
                                label={"Blog Category Title"}
                                type={"text"}
                                placeholder={"Enter Blog Category Title"}
                                name={"blogCategoryTitle"}
                              />
                              <InputField
                                id={"blogCategorySubtitle"}
                                label={"Blog Category Subtitle"}
                                type={"text"}
                                placeholder={"Enter Blog Category Subtitle"}
                                name={"blogCategorySubtitle"}
                              />
                          
                              <InputField
                                id={"priority"}
                                label={"Priority"}
                                type={"text"}
                                placeholder={"Enter Priority"}
                                name={"priority"}
                              />
                            </div>
                            <div className="   express-form-width">
                              <label
                                id="blogCategoryDesc"
                                className="mb-2 dark_black_color fn_Montserrat mt-2 fw_400 fs_16 ps-2"
                              >
                                Blog Category Description
                              </label>
                              <Field name={"blogCategoryDesc"}>
                                {({ field }) => (
                                  <textarea
                                    id="blogCategoryDesc"
                                    {...field}
                                    type="text"
                                    placeholder="Enter the blog category description"
                                    className={
                                      "w-100 faq_message_container p-2 "
                                    }
                                  ></textarea>
                                )}
                              </Field>
                              <ErrorMsg name={"blogCategoryDesc"} />
                            </div>
                          </Form>
                        );
                      }}
                    </Formik>
                  </div>
                </div>
              </div>
            </div>
          )}
        </InnerContainer>
      </div>
    </InnerLayout>
  );
};

export default BlogCategoryCreate;
