import { APPROVE_TESTIMONIALS, CREATE_TESTIMONIALS, DELETE_TESTIMONIALS, EDIT_TESTIMONIALS, REJECT_TESTIMONIALS, TESTIMONIALS_APPROVALS_EXPORT, TESTIMONIALS_APPROVAL_TABLE, TESTIMONIALS_APPROVAL_VIEW, TESTIMONIALS_EXPORT, TESTIMONIALS_OPTIONS, TESTIMONIALS_SUBMIT, TESTIMONIALS_TABLE, TESTIMONIALS_VERSION_EXPORT, TESTIMONIALS_VERSION_HISTORY, TESTIMONIALS_VERSION_HISTORY_TABLE, VIEW_TESTIMONIALS } from "./types";


export const createTestimonials = (
    data,
    onSuccess,
    onError
) => {
    return {
        type: CREATE_TESTIMONIALS,
        data,
        onSuccess,
        onError,
    };
};

export const approveTestimonials = (id, onSuccess, onError) => {
    return {
        type: APPROVE_TESTIMONIALS,
        id,
        onSuccess,
        onError,
    };
};

export const rejectTestimonials = (id, onSuccess, onError) => {
    return {
        type: REJECT_TESTIMONIALS,
        id,
        onSuccess,
        onError,
    };
};

export const viewTestimonialsDetail = (id, onSuccess, onError) => {
    return {
        type: VIEW_TESTIMONIALS,
        id,
        onSuccess,
        onError,
    };
};

export const viewTestimonialsList = (data, page_no, onSuccess, onError) => {
    return {
        type: TESTIMONIALS_TABLE,
        data,
        page_no,
        onSuccess,
        onError,
    };
};

export const editTestimonials = (
    id,
    data,
    onSuccess,
    onError
) => {
    return {
        type: EDIT_TESTIMONIALS,
        id,
        data,
        onSuccess,
        onError
    };
};

export const deleteTestimonials = (id, onSuccess, onError) => {
    return {
        type: DELETE_TESTIMONIALS,
        id,
        onSuccess,
        onError,
    };
};



export const TestimonialsApprovalList = (data, page_no = 0, onSuccess, onError) => {
    return {
        type: TESTIMONIALS_APPROVAL_TABLE,
        data,
        page_no,
        onSuccess,
        onError,
    };
};

export const testimonialsApprovalById = (id, onSuccess, onError) => {
    return {
        type: TESTIMONIALS_APPROVAL_VIEW,
        id,
        onSuccess,
        onError,
    };
};


export const versionHistoryTestimonialsList = (data, page, onSuccess, onError) => {
    return {
        type: TESTIMONIALS_VERSION_HISTORY_TABLE,
        data,
        page,
        onSuccess,
        onError,
    };
};

export const versionHistoryTestimonialsDetail = (id, onSuccess, onError) => {
    return {
        type: TESTIMONIALS_VERSION_HISTORY,
        id,
        onSuccess,
        onError,
    };
};
export const TestimonialsOptions = (id, onSuccess, onError) => {
    return {
        type: TESTIMONIALS_OPTIONS,
        id,
        onSuccess,
        onError,
    };
};

export const testimonialsTableExport = (onSuccess, onError) => {
    return {
        type: TESTIMONIALS_EXPORT,
        onSuccess,
        onError,
    };
};
export const testimonialsApprovalsExport = (onSuccess, onError) => {
    return {
        type: TESTIMONIALS_APPROVALS_EXPORT,
        onSuccess,
        onError,
    };
};


export const testimonialsVersionExport = (onSuccess, onError) => {
    return {
        type: TESTIMONIALS_VERSION_EXPORT,
        onSuccess,
        onError,
    };
};


export const testimonialsSubmit = (id, onSuccess, onError) => {
    return {
        type: TESTIMONIALS_SUBMIT,
        id,
        onSuccess,
        onError,
    };
};  