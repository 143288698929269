import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";

import moment from "moment";

import "./testimonials.css";
import { TableContainer } from "../../../table/tableContainer";
import TablePagination from "../../../table/tablePagination";
import {
  ourteamversionData,
  ourteamversionHeader,
  tableAdminVersionHeader,
  testimonialsapprovalData,
  testimonialsapprovalHeader,
} from "../../../table/tableData";
import LoadingSpinnerTable from "../../../utilits/loadingSpinnerTable";
import { versionHistoryTestimonialsList } from "../../../../redux/actions/testimonialsAction";
import LoadAndError from "../../../utilits/LoadAndError";

const TestimonialsVersionTableContent = ({ page, search, changePage }) => {
  const dispatch = useDispatch();
  const [versionHistoryList, setVersionHistoryList] = useState([]);
  const [loadingTable, setLoadingTable] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [pageData, setPageData] = useState({ noOfItems: 0, noOfPages: 1 });
  useEffect(() => {
    setLoadingTable(true);
    dispatch(
      versionHistoryTestimonialsList(
        {
          search: {
            testimonial_id: search?.value?.Id,
          },
        },
        page,
        onSuccess,
        onError
      )
    );
  }, [search, page]);

  const onSuccess = (data) => {
    console.log("dtaattat", data?.data?.data);
    setVersionHistoryList(data?.data?.data);
    setPageData({
      noOfItems: data.data.count,
      noOfPages: data.data.pages,
    });
    setLoadingTable(false);
    setLoading(false);
    setError(false);
  };

  console.log(versionHistoryList);
  const onError = (data) => {
    console.log(data);
    setLoadingTable(false);
    setLoading(false);
    setError(true);
  };
  function handlePagination(type) {
    if (type === "+") {
      if (page + 1 < pageData.noOfPages) changePage("+");
    } else if (type === "-") if (page > 0) changePage("-");
  }
  return (
    <TableContainer maxWidth="100%" width="90%">
      <div className="adminuser_table__container position-relative">
        {loadingTable && <LoadingSpinnerTable />}
        <table className={"adminuser_table"}>
          <thead className="adminuser_table__header">
            <tr className="adminuser_table__row">
              {testimonialsapprovalHeader.map((item) => {
                return (
                  <th
                    key={item}
                    className={`text-nowrap adminuser_table__heading ${
                      item === "Status" && "text-center position-sticky end-0"
                    } `}
                  >
                    {item}
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody className="adminuser_table__body">
            <LoadAndError
              loader={loading}
              error={error}
              status={versionHistoryList.length === 0}
            >
              {versionHistoryList.map((item, index) => {
                return (
                  <tr key={index} className="adminuser_table__row">
                    <td className="text-nowrap dark_title_color adminuser_table__data ">
                      {page > 0 ? 12 * page + (index + 1) : index + 1}
                    </td>
                    <td className="text-nowrap dark_title_color adminuser_table__data ">
                      {item?.id}
                    </td>
                    <td className="text-nowrap dark_title_color adminuser_table__data ">
                      {item?.testimonial_id == null
                        ? "--"
                        : item?.testimonial_id}
                    </td>
                    <td className="text-nowrap dark_title_color adminuser_table__data ">
                      {item?.new_data === null
                        ? item?.old_data?.name
                        : item?.new_data?.name}
                    </td>
                    <td className="text-nowrap dark_title_color adminuser_table__data ">
                      {item?.new_data === null
                        ? item?.old_data?.designation
                        : item?.new_data?.designation}
                    </td>
                    <td className="text-nowrap dark_title_color adminuser_table__data ">
                      {item?.new_data === null
                        ? item?.old_data?.user_type
                        : item?.new_data?.user_type}
                    </td>
                    <td className="text-nowrap dark_title_color adminuser_table__data ">
                      {moment(
                        item?.approved_date == null ? "--" : item?.approved_date
                      ).format("DD-MM-YYYY HH:mm:ss")}
                    </td>
                    <td className="text-nowrap dark_title_color adminuser_table__data ">
                      {moment(
                        item?.updatedAt == null ? "--" : item?.updatedAt
                      ).format("DD-MM-YYYY HH:mm:ss")}
                    </td>

                    <td className="text-nowrap  adminuser_table__data position-sticky end-0">
                      <div className="d-flex justify-content-center w-100 gap-2">
                        <Link
                          className="text-decoration-none primary_color"
                          to={{
                            pathname: `/static-pages/testimonials/version-history/view/${item?.id}`,
                          }}
                        >
                          View
                        </Link>{" "}
                      </div>
                    </td>
                  </tr>
                );
              })}
            </LoadAndError>
          </tbody>
        </table>
      </div>
      <TablePagination
        paginate={handlePagination}
        currentPage={page}
        pageData={pageData}
      />
    </TableContainer>
  );
};

export default TestimonialsVersionTableContent;
