import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import errorToast from "../../utilits/errorToast";
import successToast from "../../utilits/successToast";
import moment from "moment";
import { viewCategoryDetail } from "../../../redux/actions/faqCategoryActions";
import { viewBlogCategoryDetailAction } from "../../../redux/actions/blogCategoryActions";
import LoadingSpinnerTable from "../../utilits/loadingSpinnerTable";
import LoadAndError from "../../utilits/LoadAndError";


const BlogCategoryViewDetails = ({ id }) => {
  const params = useParams()
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [wishlistData, setWishlistData] = useState({ id: "", name: "", createdAt: "", updatedAt: "" })
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false);
  useEffect(() => {
    setLoading(true)
    dispatch(
      viewBlogCategoryDetailAction(id, onSuccess, onError)
      //viewWishlist("52decfd4-3d70-4239-815d-1650e294d45f", onSuccess, onError)
    );
  }, [id])
  const onSuccess = (data) => {
    console.log(data.data);
    setWishlistData(data.data);
    setLoading(false)
    setError(false);
  };
  const onError = (data) => {
    errorToast(data.message);
    setLoading(false)
    setError(true);
  };

  const ViewDetails = [
    {
      heading: "Blog Category ID",
      title: wishlistData?.id ? wishlistData?.id : "--",
    },
    {
      heading: "Blog Category Name",
      title: wishlistData?.name ? wishlistData?.name : "--",
    },
    {
      heading: "Priority",
      title: wishlistData?.priority ? wishlistData?.priority : "--",
    },
    {
      heading: "Approved At",
      title: (wishlistData?.updatedAt) ? moment(wishlistData?.updatedAt).format("DD-MM-YYYY HH:mm:ss") : "--",
    },
    {
      heading: "Updated At",
      title: (wishlistData?.createdAt) ? moment(wishlistData?.createdAt).format("DD-MM-YYYY HH:mm:ss") : "--",
    },

  ];
  return (
    <>
    {loading ? (
      <LoadingSpinnerTable/>
    )
  :
  (
    <LoadAndError loader={loading} error={error}>
    <div className="row mt-4">
      <div className="col-12 col-sm-12 col-md-9 col-lg-9 col-xl-7">
        <div className="row ps-1 ms-1 ps-lg-4 ms-lg-1">
          {ViewDetails.map((item, index) => (
            <div className="mb-2 ">
              <h5 className="dark_grey_color text-nowrap fs_13 fn_Montserrat fw_500">
                {item.heading}
              </h5>
              <p
                className={` fs_13 fn_Nunito  fw_500 "dark_title_color"
                `}
              >
                {item.title}
              </p>
            </div>
          ))}
        </div>
      </div>
    </div>
    </LoadAndError>
  )}
    </>
  );
};

export default BlogCategoryViewDetails;
