import React, { useState } from 'react'
import CreateBtn from '../../../../components/buttons/createBtn';
import ExportBtn from '../../../../components/buttons/exportBtn';
import FilterBtn from '../../../../components/buttons/filterBtn';
import VersionHistoryBtn from '../../../../components/buttons/versionHistoryBtn';
import AdminUserFilter from '../../../../components/filter/adminUserFilter';
import InnerLayout from '../../../../components/layouts/innerLayout/innerLayout';
import WishlistApprovalsTableContent from '../../../../components/opportunity/wishlist/tables/WishlistApprovalsTableContent';
import FileSaver from 'file-saver';
import { wishlistApprovalsExport, wishListExport } from '../../../../redux/actions/wishlistActions'
import { useDispatch } from "react-redux";
import errorToast from "../../../../components/utilits/errorToast";
import WishlistFilter from '../../../../components/filter/opportunity-pagesFilter.js/wishlistFilter';
                    
const WishlistApprovalsTable = () => {
    const [page, setPage] = useState(0);
  const [showFilter, setShowFilter] = useState(false);
  const [search, setSearch] = useState({ value: "" });
  const dispatch = useDispatch();
  const handleFilterClose = () => {
    setShowFilter(false);     
  };
  const handleFilterOpen = () => {
    setShowFilter(true);
  };
  const handleSearch = (value) => {
    setSearch({ value: value });
    setPage(0)
  };
  const handlePage = (type) => {
    if (type === "+") {
      setPage((prev) => prev + 1)
    }
    else {
      setPage((prev) => prev - 1)
    }
  }

    // const handleExport = () => {
    //     dispatch(
    //         wishListExport(onSuccess, onError)
    //     );
    // };
    //export call back states
    // const onSuccess = (data) => {
    //     setExportData(data.data);
    //     var type = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8";
    //     var blob = new Blob([exportData], { type: type });
    //     FileSaver.saveAs(blob, "file.xlsx");
    //     setLoading(false)
    // };
    // const onError = (data) => {
    //     // errorToast(data.data.message)
    //     setExportData(data.data);
    //     var type = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8";
    //     var blob = new Blob([exportData], { type: type });
    //     FileSaver.saveAs(blob, "file.xlsx");

    //     console.log("The log ", data);
    //     errorToast("Something went wrong")
    //     setLoading(false)
    // };


    const buttonList = [
        <VersionHistoryBtn link='/opportunity/wishlist/version-history' />,
        <ExportBtn dispatchAction={wishlistApprovalsExport}/>,
        <FilterBtn filterFn={handleFilterOpen} />,
    ];
    return (
        <InnerLayout buttons={buttonList}>
            <WishlistFilter
                filter={handleSearch}
                showFilter={showFilter}
                closeFilter={handleFilterClose}
            />
            <WishlistApprovalsTableContent  page={page} changePage={handlePage} search={search}/>
        </InnerLayout>
    )
}

export default WishlistApprovalsTable