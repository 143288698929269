import React, { useState } from 'react'
import ExportBtn from '../../../../components/buttons/exportBtn'
import FilterBtn from '../../../../components/buttons/filterBtn'
import AdminUserFilter from '../../../../components/filter/adminUserFilter'
import VersionTablesFilter from '../../../../components/filter/versionTableFilter'
import InnerLayout from '../../../../components/layouts/innerLayout/innerLayout'
import OpportunityTypeVersionTableContent from '../../../../components/opportunity/opportunityType/tables/opportunityTypeVersionTableContent'
import ContactUsVersionHistoryTableContent from '../../../../components/static-pages/contact-us/tables/ContactUsVersionHistoryTableContent'
import { contactVersionExportAction } from '../../../../redux/actions/contactUsActions'
const ContactUsVersionHistoryTable = () => {
  const [page, setPage] = useState(0);
  const [showFilter, setShowFilter] = useState(false);
  const [search, setSearch] = useState({ value: "" });
  const handleFilterClose = () => {
    setShowFilter(false);
  };
  const handleFilterOpen = () => {
    setShowFilter(true);
  };
  const handleSearch = (value) => {
    setSearch({ value: value });
    setPage(0)
  };
  const handlePage = (type) => {
    if (type === "+") {
      setPage((prev) => prev + 1)
    }
    else {
      setPage((prev) => prev - 1)
    }
  }
  const buttonList = [

    <ExportBtn dispatchAction={contactVersionExportAction} />,
    <FilterBtn filterFn={handleFilterOpen} />,
  ];
  return (
    <InnerLayout buttons={buttonList}>
      <VersionTablesFilter
        title='Contact Us Id'
        placeholder='Enter Contact Us Id'
        filter={handleSearch}
        showFilter={showFilter}
        closeFilter={handleFilterClose}
      />
      <ContactUsVersionHistoryTableContent page={page} changePage={handlePage} search={search} />
    </InnerLayout>
  )
}

export default ContactUsVersionHistoryTable