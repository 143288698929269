import React, { useState } from "react";
import VersionHistoryBtn from "../../../../components/buttons/versionHistoryBtn";
import CreateBtn from "../../../../components/buttons/createBtn";
import ExportBtn from "../../../../components/buttons/exportBtn";
import ApprovalsBtn from "../../../../components/buttons/approvalsBtn";
import FilterBtn from "../../../../components/buttons/filterBtn";
import InnerLayout from "../../../../components/layouts/innerLayout/innerLayout";
import AdminUserTableContent from "../../../../components/admin/tables/adminUserTableContent";
import { adminTableExport } from "../../../../redux/actions/AdminUserAction";
import AdminAdminFilter from "../../../../components/filter/adminFilter/adminFilter";
export default function AdminUsersTable() {
  const [page, setPage] = useState(0);
  const [showFilter, setShowFilter] = useState(false);
  const [search, setSearch] = useState({ value: "" });
  const handleFilterClose = () => {
    setShowFilter(false);
  };
  const handleFilterOpen = () => {
    setShowFilter(true);
  };
  const handleSearch = (value) => {
    setSearch({ value: value });
    setPage(0)
  };
  const handlePage = (type) => {
    if (type === "+") {
      setPage((prev) => prev + 1)
    }
    else {
      setPage((prev) => prev - 1)
    }
  }

  const buttonList = [
    <VersionHistoryBtn link="/admin-users/version-history" />,
    <ApprovalsBtn link="/admin-users/approvals" />,
    <CreateBtn link="/admin-users/create" />,
    <ExportBtn dispatchAction={adminTableExport} />,
    <FilterBtn filterFn={handleFilterOpen} />,
  ];

  return (
    <InnerLayout
      buttons={buttonList}
    >
      <AdminAdminFilter
        filter={handleSearch}
        showFilter={showFilter}
        closeFilter={handleFilterClose}
        searchValue={search}
      />
      <AdminUserTableContent page={page} changePage={handlePage} search={search} />
    </InnerLayout>
  );
}
