import React, { useState } from "react";
import CreateBtn from "../../../components/buttons/createBtn";
import ExportBtn from "../../../components/buttons/exportBtn";
import FilterBtn from "../../../components/buttons/filterBtn";
import OpportunitiesFilter from "../../../components/filter/opportunity-pagesFilter.js/opportunitiesFilter";
import InnerLayout from "../../../components/layouts/innerLayout/innerLayout";
import OpportunitiesApprovalTableContent from "../../../components/opportunity/opportunties/tables/opportunitesApprovalTableContent";
import { opportunitiesApprovalsExport } from "../../../redux/actions/opportunitiesActions";
const OpportunitiesApprovalTable = () => {
  const [page, setPage] = useState(0);
  const [showFilter, setShowFilter] = useState(false);
  const [search, setSearch] = useState([]);
  const handleFilterClose = () => {
    setShowFilter(false);
  };
  const handleFilterOpen = () => {
    setShowFilter(true);
  };
  const handleSearch = (value) => { setSearch(value); setPage(0) };
  const handlePage = (type) => {
    if (type === "+") {
      setPage((prev) => prev + 1)
    }
    else {
      setPage((prev) => prev - 1)
    }
  }
  const buttonList = [
    <CreateBtn link="/opportunity/opportunities/create" />,
    <ExportBtn dispatchAction={opportunitiesApprovalsExport} />,
    <FilterBtn filterFn={handleFilterOpen} />,
  ];
  return (
    <InnerLayout buttons={buttonList}>
      <OpportunitiesFilter
        filter={handleSearch}
        showFilter={showFilter}
        closeFilter={handleFilterClose}
      />
      <OpportunitiesApprovalTableContent page={page} changePage={handlePage} search={search} />
    </InnerLayout>
  );
};
export default OpportunitiesApprovalTable;
