import { call, takeLatest } from "redux-saga/effects";
import {
  REFERED_USER_APPROVALS_EXPORT,
  REFERED_USER_APPROVALS_LIST,
  REFERED_USER_APPROVALS_VIEW,
  REFERED_USER_APPROVE,
  REFERED_USER_CREATE,
  REFERED_USER_DELETE,
  REFERED_USER_EDIT,
  REFERED_USER_EXPORT,
  REFERED_USER_LIST,
  REFERED_USER_READ,
  REFERED_USER_REJECT,
  REFERED_USER_SUBMIT,
  REFERED_USER_VERSION_EXPORT,
  REFERED_USER_VERSION_LIST,
  REFERED_USER_VERSION_VIEW,
} from "../actions/types";
import * as referedUserApi from "../apis/referedUserApis";

export function* addReferedUserWorker(action) {
  try {
    const res = yield call(referedUserApi.addReferedUserApi, action.data);
    console.log(res, "AddAdminRole");
    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res.data);
      console.log(res.data);
    } else if (res.status === 200 && res.data.status === "fail") {
      console.log(res.data);
      yield action.onError(res);
    } else {
      yield action.onError("Something Went Wrong");
    }
  } catch (err) {
    console.log(err);
    yield action.onError("Something Went Wrong");
  }
}
export function* editReferedUserWorker(action) {
  try {
    const res = yield call(
      referedUserApi.editReferedUserApi,
      action.id,
      action.data
    );
    // console.log(res, "AddAdminRole");
    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res.data);
      console.log(res.data);
    } else if (res.status === 200 && res.data.status === "fail") {
      console.log(res);
      yield action.onError(res);
    } else {
      yield action.onError("Something Went Wrong");
    }
  } catch (err) {
    console.log(err);
    yield action.onError("Something Went Wrong");
  }
}

export function* deleteReferedUserWorker(action) {
  try {
    const res = yield call(referedUserApi.deleteReferedUserApi, action.id);
    // console.log(res, "AddAdminRole");
    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res.data);
      console.log(res.data);
    } else if (res.status === 200 && res.data.status === "fail") {
      console.log(res);
      yield action.onError(res);
    } else {
      yield action.onError("Something Went Wrong");
    }
  } catch (err) {
    console.log(err);
    yield action.onError("Something Went Wrong");
  }
}

export function* viewReferedUserWorker(action) {
  try {
    const res = yield call(referedUserApi.viewReferedUserApi, action.id);
    // console.log(res, "AddAdminRole");
    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res.data);
      console.log(res.data);
    } else if (res.status === 200 && res.data.status === "fail") {
      console.log(res.data);
      yield action.onError(res);
    } else {
      yield action.onError("Something Went Wrong!");
      console.log(res.data);
    }
  } catch (err) {
    console.log(err);
    yield action.onError("Something Went Wrong!!");
  }
}
export function* viewAllReferedUserWorker(action) {
  try {
    const res = yield call(
      referedUserApi.viewAllReferedUserApi,
      action.pageNo,
      action.search
    );
    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res.data);
      console.log(res);
    } else if (res.status === 200 && res.data.status === "fail") {
      console.log(res);
      yield action.onError(res.data);
    } else {
      yield action.onError("Something Went Wrong");
    }
  } catch (err) {
    console.log(err);
    yield action.onError("Something Went Wrong");
  }
}
export function* rejectReferedUserWorker(action) {
  try {
    const res = yield call(referedUserApi.rejectReferedUserApi, action.id);
    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res.data);
      console.log(res.data);
    } else if (res.status === 200 && res.data.status === "fail") {
      console.log(res);
      yield action.onError(res.data);
    } else {
      yield action.onError("Something Went Wrong!");
      console.log(res.data);
    }
  } catch (err) {
    console.log(err);
    yield action.onError("Something Went Wrong!!");
  }
}
export function* approveReferedUserWorker(action) {
  try {
    const res = yield call(referedUserApi.approveReferedUserApi, action.data);
    // console.log(res, "AddAdminRole");
    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res.data);
      console.log(res.data);
    } else if (res.status === 200 && res.data.status === "fail") {
      console.log(res);
      yield action.onError(res.data);
    } else {
      yield action.onError("Something Went Wrong!");
      console.log(res.data);
    }
  } catch (err) {
    console.log(err);
    yield action.onError("Something Went Wrong!!");
  }
}
export function* viewApprovalReferedUserWorker(action) {
  try {
    const res = yield call(
      referedUserApi.viewApprovalReferedUserApi,
      action.id
    );
    // console.log(res, "AddAdminRole");
    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res.data);
      console.log(res.data);
    } else if (res.status === 200 && res.data.status === "fail") {
      console.log(res);
      yield action.onError(res);
    } else {
      yield action.onError("Something Went Wrong!");
      console.log(res.data);
    }
  } catch (err) {
    console.log(err);
    yield action.onError("Something Went Wrong!!");
  }
}
export function* approvalsListReferedUserWorker(action) {
  try {
    const res = yield call(
      referedUserApi.approvalsListReferedUserApi,
      action.pageNo,
      action.data
    );
    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res.data);
      console.log(res.data);
    } else if (res.status === 200 && res.data.status === "fail") {
      console.log(res);
      yield action.onError(res);
    } else {
      yield action.onError("Something Went Wrong!");
      console.log(res.data);
    }
  } catch (err) {
    console.log(err);
    yield action.onError("Something Went Wrong!!");
  }
}
export function* versionHistoryListReferedUserWorker(action) {
  try {
    const res = yield call(
      referedUserApi.versionHistoryListReferedUserApi,
      action.pageNo,
      action.data
    );
    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res.data);
      console.log(res.data);
    } else if (res.status === 200 && res.data.status === "fail") {
      console.log(res);
      yield action.onError(res);
    } else {
      yield action.onError("Something Went Wrong!");
      console.log(res.data);
    }
  } catch (err) {
    console.log(err);
    yield action.onError("Something Went Wrong!!");
  }
}
export function* viewVersionHistoryReferedUserWorker(action) {
  try {
    const res = yield call(
      referedUserApi.viewVersionHistoryReferedUserApi,
      action.id
    );
    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res);
      console.log(res);
    } else if (res.status === 200 && res.data.status === "fail") {
      console.log(res);
      yield action.onError(res);
    } else {
      yield action.onError("Something Went Wrong!");
      console.log(res);
    }
  } catch (err) {
    console.log(err);
    yield action.onError("Something Went Wrong!!");
  }
}

//export
export function* referedUserTableExportWorker(action) {
  try {
    const res = yield call(referedUserApi.referedUserTableExportApi);
    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res.data);
    }
  } catch (error) {}
}

export function* referedUserApprovalsExportWorker(action) {
  try {
    const res = yield call(referedUserApi.referedUserApprovalsExportApi);
    console.log(res);
    if (res.status === 200 && res.data.status === "success") {
      console.log(res.data);
      yield action.onSuccess(res.data.data);
    } else {
      yield action.onError(res.data);
    }
  } catch (error) {}
}

export function* referedUserVersionExportWorker(action) {
  try {
    const res = yield call(referedUserApi.referedUserVersionExportApi);
    console.log(res);
    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res.data);
    }
  } catch (error) {}
}

export function* referedUserSubmitWorker(action) {
  try {
    const res = yield call(referedUserApi.referedUserSubmitApi, action.id);
    console.log(res);
    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res.data);
    }
  } catch (error) {}
}

export function* watchAddReferedUserWorker() {
  yield takeLatest(REFERED_USER_CREATE, addReferedUserWorker);
}
export function* watchEditReferedUserWorker() {
  yield takeLatest(REFERED_USER_EDIT, editReferedUserWorker);
}
export function* watchDeleteReferedUserWorker() {
  yield takeLatest(REFERED_USER_DELETE, deleteReferedUserWorker);
}
export function* watchViewReferedUserWorker() {
  yield takeLatest(REFERED_USER_READ, viewReferedUserWorker);
}

export function* watchViewAllReferedUsersWorker() {
  yield takeLatest(REFERED_USER_LIST, viewAllReferedUserWorker);
}

export function* watchRejectReferedUserWorker() {
  yield takeLatest(REFERED_USER_REJECT, rejectReferedUserWorker);
}

export function* watchApproveReferedUserWorker() {
  yield takeLatest(REFERED_USER_APPROVE, approveReferedUserWorker);
}
export function* watchViewApprovalReferedUserWorker() {
  yield takeLatest(REFERED_USER_APPROVALS_VIEW, viewApprovalReferedUserWorker);
}
export function* watchApprovalsListReferedUserWorker() {
  yield takeLatest(REFERED_USER_APPROVALS_LIST, approvalsListReferedUserWorker);
}

export function* watchVersionHistoryListReferedUserWorker() {
  yield takeLatest(
    REFERED_USER_VERSION_LIST,
    versionHistoryListReferedUserWorker
  );
}
export function* watchViewVersionHistoryReferedUserWorker() {
  yield takeLatest(
    REFERED_USER_VERSION_VIEW,
    viewVersionHistoryReferedUserWorker
  );
}
//export
export function* referedUserTableExportWatcher() {
  yield takeLatest(REFERED_USER_EXPORT, referedUserTableExportWorker);
}
export function* referedUserApprovalsExportWatcher() {
  yield takeLatest(
    REFERED_USER_APPROVALS_EXPORT,
    referedUserApprovalsExportWorker
  );
}
export function* referedUserVersionExportWorkerWatcher() {
  yield takeLatest(REFERED_USER_VERSION_EXPORT, referedUserVersionExportWorker);
}
export function* referedUserSubmitWorkerWatcher() {
  yield takeLatest(REFERED_USER_SUBMIT, referedUserSubmitWorker);
}
