import {
  ADD_USER,
  EDIT_USER,
  USER_EMAIL_OTP,
  USER_PHONE_OTP,
  INDIVIDUAL_USER_APPROVAL_DETAILS,
  USER_APPROVAL_LIST,
  USER_LIST,
  USER_VIEW_DETAILS,
  RESET_PASSWORD_EMAIL_VIEW,
  USER_VERSION_HISTORY_LIST,
  USER_VERSION_HISTORY_VIEW,
  APPROVE_USER,
  DELETE_USER,
  ADD_PROFILE_IMAGE,
  UPLOAD_ADDRESS_PROOF_FRONT_IMAGE,
  UPLOAD_ADDRESS_PROOF_BACK_IMAGE,
  ADD_PAN_IMAGE,
  ADD_BANK_IMAGE,
  GET_PROFILE_IMAGE,
  ADDRESS_APPROVED,
  USER_EXPORT,
  ADDRESS_REJECTED,
  PAN_APPROVED,
  PAN_REJECTED,
  BANK_APPROVED,
  BANK_REJECTED,
  REJECT_USER,
  KYC_STATUS_USER,
  PAN_APPROVE_REJECT,
  BANK_APPROVE_REJECT,
  USER_APPROVALS_EXPORT,
  USER_VERSION_EXPORT,
  USER_PHONE_EXPORT,
  USER_EMAIL_EXPORT,
  USER_RESET_EXPORT,
  FORGOT_PASS_SEND_EMAIL,
  USER_SUBMIT,
} from "./types";

export const AddUser = (data, onSuccess, onError) => {
  return {
    type: ADD_USER,
    data,
    onSuccess,
    onError,
  };
};

export const EditUser = (
  id,
  data,
  onSuccess,
  onError
) => {
  return {
    type: EDIT_USER,
    id,
    data,
    onSuccess,
    onError,
  };
};
export const deleteUserAction = (id, onSuccess, onError) => {
  return {
    type: DELETE_USER,
    id,
    onSuccess,
    onError,
  };
};
export const userEmailOtp = (search, pageNo, onSuccess, onError) => {
  return {
    type: USER_EMAIL_OTP,
    search,
    pageNo,
    onSuccess,
    onError,
  };
};
export const userPhoneOtp = (search, pageNo, onSuccess, onError) => {
  return {
    type: USER_PHONE_OTP,
    search, pageNo,
    onSuccess,
    onError,
  };
};

export const UserList = (data, current_page, onSuccess, onError) => {
  return {
    type: USER_LIST,
    data,
    current_page,
    onSuccess,
    onError,
  };
};
export const approvalsUserList = (data, current_page, onSuccess, onError) => {
  return {
    type: USER_APPROVAL_LIST,
    data,
    current_page,
    onSuccess,
    onError,
  };
};
export const userVersionHistoryList = (
  data,
  current_page,
  onSuccess,
  onError
) => {
  return {
    type: USER_VERSION_HISTORY_LIST,
    data,
    current_page,
    onSuccess,
    onError,
  };
};
export const UserVersionHistoryView = (id, onSuccess, onError) => {
  return {
    type: USER_VERSION_HISTORY_VIEW,
    id,
    onSuccess,
    onError,
  };
};

export const individualUserApprovalDetail = (id, onSuccess, onError) => {
  return {
    type: INDIVIDUAL_USER_APPROVAL_DETAILS,
    id,
    onSuccess,
    onError,
  };
};

export const UsersView = (id, onSuccess, onError) => {
  return {
    type: USER_VIEW_DETAILS,
    id,
    onSuccess,
    onError,
  };
};

export const ResetPasswordEmailView = (search, pageNo, onSuccess, onError) => {
  return {
    type: RESET_PASSWORD_EMAIL_VIEW,
    search, pageNo,
    onSuccess,
    onError,
  };
};

export const ApproveUser = (id, onSuccess, onError) => {
  return {
    type: APPROVE_USER,
    id,
    onSuccess,
    onError,
  };
};
export const rejectUserAction = (id, onSuccess, onError) => {
  return {
    type: REJECT_USER,
    id,
    onSuccess,
    onError,
  };
};

/*********IMAGE UPLOAD */
export const uploadProfileImageAction = (image, onSuccess, onError) => {
  return {
    type: ADD_PROFILE_IMAGE,
    image,
    onSuccess,
    onError,
  };
};

export const uploadAddressProofFrontImageAction = (
  image,
  onSuccess,
  onError
) => {
  return {
    type: UPLOAD_ADDRESS_PROOF_FRONT_IMAGE,
    image,
    onSuccess,
    onError,
  };
};

export const uploadPanImageAction = (image, onSuccess, onError) => {
  return {
    type: ADD_PAN_IMAGE,
    image,
    onSuccess,
    onError,
  };
};

export const uploadAddressProofBackImageAction = (
  image,
  onSuccess,
  onError
) => {
  return {
    type: UPLOAD_ADDRESS_PROOF_BACK_IMAGE,
    image,
    onSuccess,
    onError,
  };
};

export const uploadbankImageAction = (image, onSuccess, onError) => {
  return {
    type: ADD_BANK_IMAGE,
    image,
    onSuccess,
    onError,
  };
};

export const getProfileImageAction = (id, onSuccess, onError) => {
  return {
    type: GET_PROFILE_IMAGE,
    id,
    onSuccess,
    onError,
  };
};
/***********vikas*********** */

export const approvedAdressprofUserAction = (userId, onSuccess, onError) => {
  return {
    type: ADDRESS_APPROVED,
    userId,
    onSuccess,
    onError,
  };
};


////kyc pan address bank
export const rejectedApproveAdressprofUserAction = (
  data,
  onSuccess,
  onError
) => {
  return {
    type: ADDRESS_REJECTED,
    data,
    onSuccess,
    onError,
  };
};
export const rejectedApprovePanUserAction = (
  data,
  onSuccess,
  onError
) => {
  return {
    type: PAN_APPROVE_REJECT,
    data,
    onSuccess,
    onError,
  };
};
export const rejectedApproveBankUserAction = (
  data,
  onSuccess,
  onError
) => {
  return {
    type: BANK_APPROVE_REJECT,
    data,
    onSuccess,
    onError,
  };
};




export const kycStatusUserAction = (
  data,
  onSuccess,
  onError
) => {
  return {
    type: KYC_STATUS_USER,
    data,
    onSuccess,
    onError,
  };
};


//export
export const userTableExport = (onSuccess, onError) => {
  return {
    type: USER_EXPORT,
    onSuccess,
    onError,
  };
};
export const userApprovalsExport = (onSuccess, onError) => {
  return {
    type: USER_APPROVALS_EXPORT,
    onSuccess,
    onError,
  };
};
export const userVersionExport = (onSuccess, onError) => {
  return {
    type: USER_VERSION_EXPORT,
    onSuccess,
    onError,
  };
};
export const userResetExport = (onSuccess, onError) => {
  return {
    type: USER_RESET_EXPORT,
    onSuccess,
    onError,
  };
};
export const userEmailExport = (onSuccess, onError) => {
  return {
    type: USER_EMAIL_EXPORT,
    onSuccess,
    onError,
  };
};
export const userPhoneExport = (onSuccess, onError) => {
  return {
    type: USER_PHONE_EXPORT,
    onSuccess,
    onError,
  };
};

export const forgotPasswordSendEmailAction = (email, onSuccess, onError) => {
  return {
    type: FORGOT_PASS_SEND_EMAIL,
    email,
    onSuccess,
    onError,
  };
};


export const userSubmitAction = (id, onSuccess, onError) => {
  return {
    type: USER_SUBMIT,
    id,
    onSuccess,
    onError,
  };
};