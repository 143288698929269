import React, { useState } from 'react'
import ApprovalsBtn from '../../../../components/buttons/approvalsBtn';
import ExportBtn from '../../../../components/buttons/exportBtn';
import FilterBtn from '../../../../components/buttons/filterBtn';
import AdminUserFilter from '../../../../components/filter/adminUserFilter'
import VersionTablesFilter from '../../../../components/filter/versionTableFilter';
import InnerLayout from '../../../../components/layouts/innerLayout/innerLayout'
import LlpVersionTableContent from '../../../../components/opportunity/llp/tables/llpVersionTableContent';
import AdvisorVersionTableContent from '../../../../components/static-pages/our-advisors/ouradvisor-version-table-content';
import { advisorsVersionExport } from '../../../../redux/actions/ourAdvisorAction';


const AdvisorVersionHistoryTable = () => {
    const [page, setPage] = useState(0);
    const [showFilter, setShowFilter] = useState(false);
    const [search, setSearch] = useState({ value: "" });
    const handleFilterClose = () => {
        setShowFilter(false);
    };
    const handleFilterOpen = () => {
        setShowFilter(true);
    };
    const handleSearch = (value) => {
        setSearch({ value: value });
        setPage(0)
    };
    const handlePage = (type) => {
        if (type === "+") {
            setPage((prev) => prev + 1)
        }
        else {
            setPage((prev) => prev - 1)
        }
    }
    const buttonList = [
        <ApprovalsBtn link={`/static-pages/our-advisors/approvals`} />,
        <ExportBtn  dispatchAction={advisorsVersionExport}/>,
        <FilterBtn filterFn={handleFilterOpen} />,
    ];
    return (
        <InnerLayout buttons={buttonList}>
            <VersionTablesFilter
                title='Advisors Id'
                placeholder='Enter Advisors Id'
                filter={handleSearch}
                showFilter={showFilter}
                closeFilter={handleFilterClose}
            />
            <AdvisorVersionTableContent page={page} changePage={handlePage} search={search} />

        </InnerLayout>
    )
}

export default AdvisorVersionHistoryTable