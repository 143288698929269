import React, { useState } from "react";
import { useDispatch } from "react-redux";
import AdminRoleVersionTableContent from "../../../../components/admin/tables/adminRoleVersionTableContent";
import ExportBtn from "../../../../components/buttons/exportBtn";
import FilterBtn from "../../../../components/buttons/filterBtn";
import AdminUserFilter from "../../../../components/filter/adminUserFilter";
import VersionTablesFilter from "../../../../components/filter/versionTableFilter";
import InnerLayout from "../../../../components/layouts/innerLayout/innerLayout";
import { rolesVersionExport } from "../../../../redux/actions/adminRoleActions";

const AdminRoleVersionTable = () => {
  const dispatch = useDispatch()
  const [page, setPage] = useState(0);
  const [showFilter, setShowFilter] = useState(false);
  const [search, setSearch] = useState("");
  const handleFilterClose = () => {
    setShowFilter(false);
  };
  const handleFilterOpen = () => {
    setShowFilter(true);
  };
  const handleSearch = (value) => { console.log("running"); setSearch(value); setPage(0) };
  const handlePage = (type) => {
    if (type === "+") {
      setPage((prev) => prev + 1)
    }
    else {
      setPage((prev) => prev - 1)
    }
  }
  const buttonList = [
    <ExportBtn dispatchAction={rolesVersionExport} />,
    <FilterBtn filterFn={handleFilterOpen} />,
  ];
  return (
    <InnerLayout buttons={buttonList}>
      <VersionTablesFilter
        title='Role Id'
        placeholder='Enter Role Id'
        filter={handleSearch}
        showFilter={showFilter}
        closeFilter={handleFilterClose}
      />
      <AdminRoleVersionTableContent page={page} changePage={handlePage} search={search} />
    </InnerLayout>
  );
};

export default AdminRoleVersionTable;
