import { authHeader } from "../config";
import { api } from "./api";
export const createInvitedApi = (params) => {
    return api.post("/api/admin/referral_inviting_member/create", params, {
        headers: {
            "x-access-token": localStorage.getItem("accessToken"),
            "Content-Type": "application/json",
        },
    });
};
export const approveInvitedApi = (params) => {
    return api.post("/api/admin/referral_inviting_member/approve", JSON.stringify(params), {
        headers: {
            "x-access-token": localStorage.getItem("accessToken"),
            "Content-Type": "application/json",
        },
    });
};

export const rejectInvitedApi = (id, params) => {
    return api.post(
        `/api/admin/referral_inviting_member/reject/${id}`,
        JSON.stringify(params),
        {
            headers: {
                "x-access-token": localStorage.getItem("accessToken"),
                "Content-Type": "application/json",
            },
        }
    );
};

/*****New API for view detail  ******/
export const viewInvitedDetailApi = (id, params) => {
    return api.post(
        `/api/admin/admin-referral/view-referral/${id}`,
        JSON.stringify(params),
        {
            headers: {
                "x-access-token": localStorage.getItem("accessToken"),
                "Content-Type": "application/json",
            },
        }
    );
};

/*****New API for view table  ******/
export const viewInvitedListApi = (pageNo = 0, params) => {
    return api.post(
        `/api/admin/admin-referral/view-referrals?page_no=${pageNo}`,
        params,
        {
            headers: {
                "x-access-token": localStorage.getItem("accessToken"),
                "Content-Type": "application/json",
            },
        }
    );
};
export const editInvitedApi = (id, params) => {
    return api.post(
        `/api/admin/referral_inviting_member/edit/${id}`,
        JSON.stringify(params),
        {
            headers: {
                "x-access-token": localStorage.getItem("accessToken"),
                "Content-Type": "application/json",
            },
        }
    );
};
export const deleteInvitedApi = (id, params) => {
    return api.post(
        `/api/admin/referral_inviting_member/delete/${id}`,
        JSON.stringify(params),
        {
            headers: {
                "x-access-token": localStorage.getItem("accessToken"),
                "Content-Type": "application/json",
            },
        }
    );
};
export const invitedApprovalListApi = (pageNo, params) => {
    return api.post(
        `/api/admin/referral_inviting_member/view-checker?page_no=${pageNo}`,
        JSON.stringify(params),
        {
            headers: {
                "x-access-token": localStorage.getItem("accessToken"),
                "Content-Type": "application/json",
            },
        }
    );
};
export const invitedApprovalByIdApi = (id, params) => {
    return api.post(
        `/api/admin/referral_inviting_member/view-checker/${id}`,
        JSON.stringify(params),
        {
            headers: {
                "x-access-token": localStorage.getItem("accessToken"),
                "Content-Type": "application/json",
            },
        }
    );
};
export const versionHistoryInvitedListApi = (pageNo, params) => {
    return api.post(
        `/api/admin/referral_inviting_member/view-version?page_no=${pageNo}`,
        JSON.stringify(params),
        {
            headers: {
                "x-access-token": localStorage.getItem("accessToken"),
                "Content-Type": "application/json",
            },
        }
    );
};
export const versionHistoryInvitedDetailApi = (id, params) => {
    return api.post(
        `/api/admin/referral_inviting_member/view-version/${id}`,
        JSON.stringify(params),
        {
            headers: {
                "x-access-token": localStorage.getItem("accessToken"),
                "Content-Type": "application/json",
            },
        }
    );
};
export const invitedTableExportApi = (params) => {
    return api.post(
        `/api/admin/referral_inviting_member/export-main`,
        JSON.stringify(params),
        {
            headers: {
                "x-access-token": localStorage.getItem("accessToken"),
                "Content-Type": "application/json",
            },
        }
    );
};
export const invitedApprovalsExportApi = (params) => {
    return api.post(
        `/api/admin/referral_inviting_member/export-approval`,
        JSON.stringify(params),
        {
            headers: {
                "x-access-token": localStorage.getItem("accessToken"),
                "Content-Type": "application/json",
            },
        }
    );
};
export const invitedVersionExportApi = (params) => {
    return api.post(
        `/api/admin/referral_inviting_member/export-version`,
        JSON.stringify(params),
        {
            headers: {
                "x-access-token": localStorage.getItem("accessToken"),
                "Content-Type": "application/json",
            },
        }
    );
};
export const invitedReferralOptionsApi = (params) => {
    return api.post(
        `/api/admin/user_referral/featchOpportunity`,
        JSON.stringify(params),
        {
            headers: {
                "x-access-token": localStorage.getItem("accessToken"),
                "Content-Type": "application/json",
            },
        }
    );
};
export const invitedSubmitApi = (id, params) => {
    return api.post(`/api/admin/referral_inviting_member/on-submit/${id}`, params, {
        headers: {
            "x-access-token": localStorage.getItem("accessToken"),
            "Content-Type": "application/json",
        },
    });
};
