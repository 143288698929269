import React from "react";
import AddIcon from "../../assets/icons/addIcon";
import { AdminButton } from "./versionHistoryBtn";
import usePermissionChecker from "../utilits/usePermissionChecker";
const CreateLlpBtn = ({ showModal
}) => {
    const access = usePermissionChecker("maker");
    return (
        access &&
        <AdminButton onClick={() => showModal()} color="#666666" padding="5px 10px">
            <span className="ms-1">LLP CREATE</span>
        </AdminButton>
    );
};

export default CreateLlpBtn;