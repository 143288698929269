import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import InnerLayout from "../../../../components/layouts/innerLayout/innerLayout";
import InnerContainer from "../../../../components/layouts/innerContainer/innerContainer";
import VersionHistoryBtn from "../../../../components/buttons/versionHistoryBtn";
import EditBtn from "../../../../components/buttons/editBtn";
import DeleteBtn from "../../../../components/buttons/deleteBtn";
import { deleteFaq, viewFaqDetail } from "../../../../redux/actions/faqAction";
import DeleteModal from "../../../../components/modals/deleteModal";
import successToast from "../../../../components/utilits/successToast";
import errorToast from "../../../../components/utilits/errorToast";
import LoadingSpinnerTable from "../../../../components/utilits/loadingSpinnerTable";
import LoadAndError from "../../../../components/utilits/LoadAndError";
import { deleteRefer, viewReferDetail, viewReferList } from "../../../../redux/actions/referAction";
import { deleteDocument, viewDocumentDetail } from "../../../../redux/actions/documentAction";

const DocumentView = () => {

  const navigate = useNavigate();
  const [faqDetails, setFaqDetails] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const dispatch = useDispatch();
  const params = useParams();
  useEffect(() => {
    setLoading(true);
    dispatch(viewDocumentDetail(params.id, onSuccess, onError));
  }, []);

  const onSuccess = (data) => {
    console.log(data.data);
    setFaqDetails(data.data);
    console.log("SUCCESS");
    setLoading(false);
    setError(false);
  };
  const onError = (data) => {
    console.log("ERROR");
    setLoading(false);
    setError(true);
  };




  //delete FAQ
  const [deleteData, setDeleteData] = useState({ name: "", id: "" });
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);

  const handleDelete = () => {
    setLoadingDelete(true);
    dispatch(
      deleteDocument(
        faqDetails.id,
        onDeleteSuccess,
        onDeleteError
      )
    );
  };
  const onDeleteSuccess = (data) => {
    successToast(data.message);
    setLoadingDelete(false);
    setShow(false);
    navigate("/static-pages/dashboard")

  };
  const onDeleteError = (data) => {
    errorToast(data.message);
    setLoadingDelete(false);
  };
  const deletenotifyMebyid = () => {
    setDeleteData({
      name: faqDetails?.question,
      id: faqDetails.id,
    });
    handleShow();
  }
  const buttonList = [
    <VersionHistoryBtn link="/static-pages/dashboard/version-history" />,
    faqDetails?.editable && <>
      <EditBtn link={`/static-pages/dashboard/edit/${faqDetails.id}`} />,
      <DeleteBtn showModal={deletenotifyMebyid} />
      ,
    </>

  ];
  const FaqViewData = [
    {
      heading: "DASHBOARD ID",
      title: faqDetails?.id == null ? "--" : faqDetails?.id,
    },
    {
      heading: "Questions",
      title: faqDetails?.question,
    },
    {
      heading: "Answers",
      title: faqDetails?.answer,
    },
    {
      heading: "Approved At",
      title: faqDetails.createdAt === null ? "-" : moment(faqDetails.createdAt).format("DD-MM-YYYY HH:mm:ss"),
    },
    {
      heading: "Updated At",
      title: faqDetails.updatedAt === null ? "-" : moment(faqDetails.updatedAt).format("DD-MM-YYYY HH:mm:ss"),
    },

  ];
  return (
    <InnerLayout buttons={buttonList}>
      <div className="user_view_container">
        <InnerContainer>
          {loading ? (
            <LoadingSpinnerTable />
          ) : (
            <LoadAndError loader={loading} error={error}>
              <div className="row mt-4">
                <div className="col-12 col-sm-12 col-md-9 col-lg-9 col-xl-7">
                  <div className="row ps-1 ms-1 ps-lg-4 ms-lg-1">
                    {FaqViewData.map((item, index) => (
                      <div className="mb-2 ">
                        <h5 className="dark_grey_color text-nowrap fs_13 fn_Montserrat fw_500">
                          {item.heading}
                        </h5>
                        <p
                          className={` fs_13 fn_Nunito  fw_500 "dark_title_color"
                `}
                        >
                          {item.title}
                        </p>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </LoadAndError>
          )}
        </InnerContainer>
      </div>
      <DeleteModal
        loader={loadingDelete}
        handleClose={handleClose}
        deleteFn={handleDelete}
        data={deleteData}
        show={show}
      />
    </InnerLayout>
  );
};

export default DocumentView;
