import {
  MEMBER_TRADE_BUY_IN_LIST,
  MEMBER_TRADE_BUY_IN_DELETE,
  MEMBER_TRADE_BUY_IN_READ,
  MEMBER_TRADE_BUY_IN_APPROVALS_VIEW,
  MEMBER_TRADE_BUY_IN_VERSION_LIST,
  MEMBER_TRADE_BUY_IN_VERSION_VIEW,
  MEMBER_TRADE_BUY_IN_APPROVALS_LIST,
  MEMBER_TRADE_BUY_IN_CREATE,
  CREATE_MEMBER_TRADE_BUY_IN_OPTIONS,
  MEMBER_TRADE_BUY_IN_EDIT,
  MEMBER_TRADE_BUY_IN_APPROVE,
  MEMBER_TRADE_BUY_IN_REJECT,
  MEMBER_TRADE_BUY_IN_EXPORT,
  MEMBER_TRADE_BUY_IN_APPROVALS_EXPORT,
  MEMBER_TRADE_BUY_IN_VERSION_EXPORT,
  MEMBER_TRADE_BUY_IN_SUBMIT,
} from "../actions/types";
import { call, takeLatest } from "redux-saga/effects";
import {
  approveMemberTradeBuyInApi,
  deleteMemberTradeBuyInApi,
  editMemberTradeBuyInApi,
  memberTradeBuyInApprovalsExportApi,
  memberTradeBuyInApprovalsTableApi,
  memberTradeBuyInCreateApi,
  memberTradeBuyInCreateOptionsApi,
  memberTradeBuyInSubmitApi,
  memberTradeBuyInTableExportApi,
  memberTradeBuyInVersionExportApi,
  memberTradeBuyInVersionHistoryViewApi,
  readApprovalMemberTradeBuyInApi,
  readMemberTradeBuyInApi,
  rejectMemberTradeBuyInApi,
  versionTableMemberTradeBuyInApi,
  viewAllMemberTradeBuyInApi,
} from "../apis/memberTradeBuyInApi";
export function* deleteMemberTradeBuyInWorker(action) {
  try {
    const res = yield call(deleteMemberTradeBuyInApi, action.id);
    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res.data);
      console.log(res.data);
    } else if (res.status === 200 && res.data.status === "fail") {
      console.log(res);
      yield action.onError(res);
    } else {
      yield action.onError("Something Went Wrong");
    }
  } catch (err) {
    console.log(err);
    yield action.onError("Something Went Wrong");
  }
}
export function* readMemberTradeBuyInWorker(action) {
  try {
    const res = yield call(readMemberTradeBuyInApi, action.id);
    // console.log(res, "AddAdminRole");
    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res.data);
      console.log(res.data);
    } else if (res.status === 200 && res.data.status === "fail") {
      console.log(res.data);
      yield action.onError(res);
    } else {
      yield action.onError("Something Went Wrong!");
      console.log(res.data);
    }
  } catch (err) {
    console.log(err);
    yield action.onError("Something Went Wrong!!");
  }
}
export function* readApprovalMemberTradeBuyInWorker(action) {
  try {
    const res = yield call(readApprovalMemberTradeBuyInApi, action.id);
    // console.log(res, "AddAdminRole");
    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res.data);
      console.log(res.data);
    } else if (res.status === 200 && res.data.status === "fail") {
      console.log(res.data);
      yield action.onError(res);
    } else {
      yield action.onError("Something Went Wrong!");
      console.log(res.data);
    }
  } catch (err) {
    console.log(err);
    yield action.onError("Something Went Wrong!!");
  }
}
export function* viewAllMemberTradeBuyInWorker(action) {
  try {
    const res = yield call(
      viewAllMemberTradeBuyInApi,
      action.pageNo,
      action.data
    );
    // console.log(res, "AddAdminRole");
    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res.data);
      console.log(res, "hgcdhgvdh");
    } else if (res.status === 200 && res.data.status === "fail") {
      console.log(res);
      yield action.onError(res.data);
    } else {
      yield action.onError("Something Went Wrong");
    }
  } catch (err) {
    console.log(err);
    yield action.onError("Something Went Wrong");
  }
}

export function* versionTableMemberTradeBuyInWorker(action) {
  try {
    const res = yield call(
      versionTableMemberTradeBuyInApi,
      action.pageNo,
      action.data
    );
    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res.data);
      console.log(res.data);
    } else if (res.status === 200 && res.data.status === "fail") {
      console.log(res);
      yield action.onError(res);
    } else {
      yield action.onError("Something Went Wrong!");
      console.log(res.data);
    }
  } catch (err) {
    console.log(err);
    yield action.onError("Something Went Wrong!!");
  }
}

export function* memberTradeBuyInVersionHistoryWorker(action) {
  try {
    const res = yield call(memberTradeBuyInVersionHistoryViewApi, action.id);
    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res);
      console.log(res);
    } else if (res.status === 200 && res.data.status === "fail") {
      console.log(res);
      yield action.onError(res);
    } else {
      yield action.onError("Something Went Wrong!");
      console.log(res);
    }
  } catch (err) {
    console.log(err);
    yield action.onError("Something Went Wrong!!");
  }
}

export function* memberTradeBuyInApprovalsTableWorker(action) {
  try {
    const res = yield call(
      memberTradeBuyInApprovalsTableApi,
      action.pageNo,
      action.data
    );
    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res.data);
      console.log(res.data);
    } else if (res.status === 200 && res.data.status === "fail") {
      console.log(res);
      yield action.onError(res);
    } else {
      yield action.onError("Something Went Wrong!");
      console.log(res.data);
    }
  } catch (err) {
    console.log(err);
    yield action.onError("Something Went Wrong!!");
  }
}
export function* memberTradeBuyInCreateWorker(action) {
  try {
    const res = yield call(memberTradeBuyInCreateApi, action.data);

    if (res.status === 200 && res.data.status === "success") {
      console.log(res.data);
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res.data);
      console.log(res.data);
    }
  } catch (error) {}
}

export function* memberTradeBuyInCreateOptionsWorker(action) {
  try {
    const res = yield call(memberTradeBuyInCreateOptionsApi, {});
    console.log(res);
    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res.data);
      console.log(res.data);
    } else {
      yield action.onError(res.data);
    }
  } catch (error) {}
}

export function* editMemberTradeBuyInWorker(action) {
  try {
    const res = yield call(editMemberTradeBuyInApi, action.id, action.data);
    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res.data);
      console.log(res.data);
    } else if (res.status === 200 && res.data.status === "fail") {
      console.log(res);
      yield action.onError(res);
    } else {
      yield action.onError("Something Went Wrong");
    }
  } catch (err) {
    console.log(err);
    yield action.onError("Something Went Wrong");
  }
}

export function* approveMemberTradeBuyInWorker(action) {
  try {
    const res = yield call(approveMemberTradeBuyInApi, {
      member_trade_id: action.id,
    });
    // console.log(res, "AddAdminRole");
    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res.data);
      console.log(res.data);
    } else if (res.status === 200 && res.data.status === "fail") {
      console.log(res);
      yield action.onError(res.data);
    } else {
      yield action.onError("Something Went Wrong!");
      console.log(res.data);
    }
  } catch (err) {
    console.log(err);
    yield action.onError("Something Went Wrong!!");
  }
}

export function* rejectMemberTradeBuyInWorker(action) {
  try {
    const res = yield call(rejectMemberTradeBuyInApi, action.id);
    // console.log(res, "AddAdminRole");
    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res.data);
      console.log(res.data);
    } else if (res.status === 200 && res.data.status === "fail") {
      console.log(res);
      yield action.onError(res.data);
    } else {
      yield action.onError("Something Went Wrong!");
      console.log(res.data);
    }
  } catch (err) {
    console.log(err);
    yield action.onError("Something Went Wrong!!");
  }
}

//export
export function* memberTradeBuyInTableExportWorker(action) {
  try {
    const res = yield call(memberTradeBuyInTableExportApi);
    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res.data);
    }
  } catch (error) {}
}

export function* memberTradeBuyInApprovalsExportWorker(action) {
  try {
    const res = yield call(memberTradeBuyInApprovalsExportApi);
    console.log(res);
    if (res.status === 200 && res.data.status === "success") {
      console.log(res.data);
      yield action.onSuccess(res.data.data);
    } else {
      yield action.onError(res.data);
    }
  } catch (error) {}
}

export function* memberTradeBuyInVersionExportWorker(action) {
  try {
    const res = yield call(memberTradeBuyInVersionExportApi);
    console.log(res);
    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res.data);
    }
  } catch (error) {}
}

export function* memberTradeBuyInSubmitWorker(action) {
  try {
    const res = yield call(memberTradeBuyInSubmitApi, action.id);
    console.log(res);
    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res.data);
    }
  } catch (error) {}
}

export function* watchViewAllMemberTradeBuyInWorker() {
  yield takeLatest(MEMBER_TRADE_BUY_IN_LIST, viewAllMemberTradeBuyInWorker);
}
export function* watchMemberTradeBuyInWorker() {
  yield takeLatest(MEMBER_TRADE_BUY_IN_DELETE, deleteMemberTradeBuyInWorker);
}
export function* watchreadMemberTradeBuyInWorker() {
  yield takeLatest(MEMBER_TRADE_BUY_IN_READ, readMemberTradeBuyInWorker);
}
export function* watchreadApprovalMemberTradeBuyInWorker() {
  yield takeLatest(
    MEMBER_TRADE_BUY_IN_APPROVALS_VIEW,
    readApprovalMemberTradeBuyInWorker
  );
}

export function* watchversionTableMemberTradeBuyInWorker() {
  yield takeLatest(
    MEMBER_TRADE_BUY_IN_VERSION_LIST,
    versionTableMemberTradeBuyInWorker
  );
}
export function* watchMemberTradeBuyInVersionHistoryWorker() {
  yield takeLatest(
    MEMBER_TRADE_BUY_IN_VERSION_VIEW,
    memberTradeBuyInVersionHistoryWorker
  );
}
export function* watchMemberTradeBuyInApprovalsTableWorker() {
  yield takeLatest(
    MEMBER_TRADE_BUY_IN_APPROVALS_LIST,
    memberTradeBuyInApprovalsTableWorker
  );
}
export function* watcherMemberTradeBuyInCreateWorker() {
  yield takeLatest(MEMBER_TRADE_BUY_IN_CREATE, memberTradeBuyInCreateWorker);
}
export function* WatcherMemberTradeBuyInCreateOptionsWorker() {
  yield takeLatest(
    CREATE_MEMBER_TRADE_BUY_IN_OPTIONS,
    memberTradeBuyInCreateOptionsWorker
  );
}

export function* watcheditMemberTradeBuyInWorker() {
  yield takeLatest(MEMBER_TRADE_BUY_IN_EDIT, editMemberTradeBuyInWorker);
}

export function* watchapproveMemberTradeBuyInWorker() {
  yield takeLatest(MEMBER_TRADE_BUY_IN_APPROVE, approveMemberTradeBuyInWorker);
}

export function* watchrejectMemberTradeBuyInWorker() {
  yield takeLatest(MEMBER_TRADE_BUY_IN_REJECT, rejectMemberTradeBuyInWorker);
}

//export
export function* memberTradeBuyInTableExportWatcher() {
  yield takeLatest(
    MEMBER_TRADE_BUY_IN_EXPORT,
    memberTradeBuyInTableExportWorker
  );
}
export function* memberTradeBuyInApprovalsExportWatcher() {
  yield takeLatest(
    MEMBER_TRADE_BUY_IN_APPROVALS_EXPORT,
    memberTradeBuyInApprovalsExportWorker
  );
}

export function* memberTradeBuyInVersionExportWorkerWatcher() {
  yield takeLatest(
    MEMBER_TRADE_BUY_IN_VERSION_EXPORT,
    memberTradeBuyInVersionExportWorker
  );
}

export function* memberTradeBuyInSubmitWorkerWatcher() {
  yield takeLatest(MEMBER_TRADE_BUY_IN_SUBMIT, memberTradeBuyInSubmitWorker);
}
