import React, { useRef, useState } from "react";
import SaveBtn from "../../../../components/buttons/saveBtn";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import InnerContainer from "../../../../components/layouts/innerContainer/innerContainer";
import InnerLayout from "../../../../components/layouts/innerLayout/innerLayout";

import InputField from "../../../../components/form/inputFields/inputField";
import * as opporTypeActions from "../../../../redux/actions/opportunityTypeActions";
import successToast from "../../../../components/utilits/successToast";
import errorToast from "../../../../components/utilits/errorToast";
import { useDispatch } from "react-redux";
import { addContactAction, contactSubmit } from "../../../../redux/actions/contactUsActions";
import { useNavigate } from "react-router-dom";
import { email } from "../../../../redux/config";
import SubmitBtn from "../../../../components/buttons/submit";

const ContactUsCreate = () => {
  const [loading, setLoading] = useState(false);
  const [submitData, setSubmitData] = useState();
  const navigate = useNavigate();
  const formRef = useRef();
  const dispatch = useDispatch();
  const phoneRegex = /^[0-9]{10}$/;
  const initialValues = {
    queryName: "",
    phoneNumber: "",
    email: "",
  };
  const loginSchema = Yup.object().shape({
    queryName: Yup.string().trim().required("Required"),
    email: Yup.string().trim().email().required("Required"),
    phoneNumber: Yup.string()
      .matches(phoneRegex, "not a valid number")
      .required("Required"),
  });
  const handleSubmit = () => {
    if (formRef.current) {
      formRef.current.handleSubmit();
    }
  };
  const onSubmit = (values) => {
    setLoading(true);
    dispatch(
      addContactAction(
        {
          query_name: values.queryName,
          phone_number: values.phoneNumber,
          email: values.email,
        },
        onSuccess,
        onError
      )
    );
  };
  const onSuccess = (data) => {
    console.log(data);
    setLoading(false);
    successToast(data.message);
    setSubmitData(data.data.id)
    navigate(`/static-pages/contact-us-query/edit/${data.data.id}`);
    setSaveHide(true)
  };
  const onError = (data) => {
    setLoading(false);
    errorToast(data.message);
  };


   //on submit api
   console.log(submitData);
   const [saveHide, setSaveHide] = useState(false);
 
   const submitFnc = () => {
     dispatch(contactSubmit
       (
         submitData,
         onSubmitSuccess,
         onSubmitError,
       ));
   };
   const onSubmitSuccess = (data) => {
     console.log(data);
    //  navigate("/static-pages/contact-us-query");
     successToast(data.message)
   };
   const onSubmitError = (data) => {
     console.log("ERROR==================", data);
     errorToast(data.message)
 
   };
  const buttonList = [
    !saveHide && <SaveBtn submitFn={handleSubmit}
    loader={loading} />,
  // <SubmitBtn submitFn={submitFnc} saveHide={saveHide} />,
  ];
  return (
    <InnerLayout buttons={buttonList}>
      <div className="opportunity-type-container">
        <InnerContainer>
          <div className="pt-3  ps-4 ps-lg-4 ms-3">
            <Formik
              innerRef={formRef}
              initialValues={initialValues}
              onSubmit={onSubmit}
              validationSchema={loginSchema}
            >
              <Form className="opportunity-type-form-width">
                <InputField
                  id={"queryName"}
                  label={"Query Name"}
                  type={"text"}
                  placeholder={"Enter query name"}
                  name={"queryName"}
                />
                <InputField
                  id={"phoneNumber"}
                  label={"Phone Number"}
                  type={"text"}
                  placeholder={"Enter phone number"}
                  name={"phoneNumber"}
                />
                <InputField
                  id={"email"}
                  label={"Email"}
                  type={"text"}
                  placeholder={"Enter Email"}
                  name={"email"}
                />
              </Form>
            </Formik>
          </div>
        </InnerContainer>
      </div>
    </InnerLayout>
  );
};

export default ContactUsCreate;
