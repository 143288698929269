import React, { useState, useEffect, useRef, useCallback } from 'react';
import "./opportunitiesNavbar.css";
import Select, { components } from 'react-select';
import ScrollTop from "../../../../assets/icons/scroll-top.svg";
import ArrowDownIcon from '../../../../assets/icons/arrowdown';
import InfoIcon from "../../../../assets/icons/info-icon.svg";
import useScreenSizeDetector from '../../../utilits/useScreenSizeDetector';
import BottomNavOffcanvas from './bottom-navbar-offcanvas';

const OpportunitiesBottomNavbar = ({ id, position }) => {
    const { width } = useScreenSizeDetector();
    const [showTopBtn, setShowTopBtn] = useState(true);
    useEffect(() => {
        window.addEventListener("scroll", () => {
            if (window.scrollY > 400) {
                setShowTopBtn(true);
            } else {
                setShowTopBtn(false);
            }
        });
    }, []);

    const goToTop = () => {
        document.getElementById(id).scrollTo({
            top: 0,
            behavior: "smooth",
        });
    };


    const DropdownIndicator = props => {
        return (
            <components.DropdownIndicator {...props}>
                <ArrowDownIcon width={25} height={25} />
            </components.DropdownIndicator>
        );
    };
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    return (
        <>
            {
                width > 1500 ? (<div className='opportunities_bottom_navbar_container d-flex align-items-center p-3 justify-content-between '>
                    <div className='d-flex flex-column border_right pe-4 '>
                        <Select
                            options={InterestValue}
                            placeholder="ENTER/SELECT INTEREST VALUE"
                            styles={reactSelectInterestValue}
                            components={{
                                IndicatorSeparator: () => null,
                                DropdownIndicator,
                            }}
                            className="me-2 "
                            menuPlacement="top"
                            noOptionsMessage={() => null}
                        />
                        <span className="light_grey_color fn_Montserrat fw_600 text-center  fs_12 mt-2 ">
                            Minimum interest value: $ XX,XXX
                        </span>
                    </div>
                    <div className={`d-flex  text-center justify-content-center  flex-column  border_right pe-4 `}>
                        <span className="secondary_color fn_Montserrat  fs_14  fw_500">
                            6 Months
                        </span>
                        <span className="light_green_color fn_Montserrat fw_600 fs_13 ">
                            TENURE
                        </span>
                    </div>
                    <div className={`d-flex  text-center justify-content-center  flex-column  border_right pe-4 `}>
                        <span className="secondary_color fn_Montserrat  fs_14  fw_500">
                            6₹ XX,XXX
                        </span>
                        <span className="light_green_color fn_Montserrat fw_600 fs_13 ">
                            AVG. MONTHLY PAYOUT
                        </span>
                    </div>
                    <div className={`d-flex  text-center justify-content-center  flex-column  border_right pe-4 `}>
                        <span className="secondary_color fn_Montserrat  fs_14  fw_500">
                            ₹ XX,XXX
                        </span>
                        <span className="light_green_color fn_Montserrat fw_600 fs_13 ">
                            LAST MONTH PAYOUT
                        </span>
                    </div>
                    <div className={`d-flex  text-center justify-content-center  flex-column  border_right pe-4 `}>
                        <span className="secondary_color fn_Montserrat  fs_14  fw_500">
                            XX.X%
                        </span>
                        <span className="light_green_color fn_Montserrat fw_600 fs_13 ">
                            POST TAX IRR
                            <span className='ps-2'><img src={InfoIcon} alt="Logo" /></span>
                        </span>
                    </div>
                    <button className='text-white py-2 px-3 border-0 fn_Montserrat fw_500 fs_11 secondary_bg rounded-2'>EXPRESS INTEREST</button>

                    <button onClick={goToTop} className='border-0 bg-transparent'><img src={ScrollTop} width={30} height={30} /></button>


                </div>) :
                    (
                        <>
                            <BottomNavOffcanvas
                                scrollToTop={goToTop}
                                //   scrollPosition={showTopBtn}
                                //   btnName={btnName}
                                handleClose={handleClose}
                                handleShow={handleShow}
                                show={show}
                            />
                        </>
                    )
            }

        </>


    )
}

export default OpportunitiesBottomNavbar;
const reactSelectInterestValue = {
    control: (base, state) => ({
        ...base,
        background: "#FFFF",
        width: "100%",
        fontSize: "10px",
        borderBottom: "2px solid #a5a5a5",
        borderTop: "none",
        borderLeft: "none",
        borderRight: "none",
        borderRadius: "none",

        boxShadow: state.isFocused ? null : null,
        "&:hover": {
            borderColor: state.isFocused ? "#a0acc3" : "#e6e6e6",
        },
    }),
    option: (provided, state) => ({
        ...provided,
        backgroundColor: state.isSelected ? "#7082A6" : null,
        borderBottom: "1px solid #F5F5F5",
        fontFamily: "Nunito",
        "&:hover": {
            backgroundColor: state.isFocused ? "#A0ACC3" : "##7082A6",
        },
    }),
    placeholder: (defaultStyles) => {
        return {
            ...defaultStyles,
            color: "#000000DE",
            fontFamily: "Montserrat",
            fontSize: "12px",
            textAlign: "center",
            fontWeight: "700",
        };
    },

    dropdownIndicator: (provided) => ({
        ...provided,
        svg: {
            fill: "#333333",
        },
        paddingTop: "5px",
    }),
    valueContainer: (provided, state) => ({
        ...provided,
        paddingRight: 0,
    }),
    input: (provided, state) => ({
        ...provided,
        maxWidth: 30,
    }),
    dropdownIndicator: (provided) => ({
        ...provided,
        padding: "0px 0px 0px 1rem",
    }),
    indicatorsContainer: (provided) => ({
        ...provided,
        paddingRight: "0.8rem",
    }),

    singleValue: (provided) => ({
        ...provided,
        fontSize: "16px",
        fontFamily: "Montserrat",
        fontWeight: "700",
        color: "#000000DE",
    }),
};

export const InterestValue = [
    {
        label: `5%`,
        value: `5%`,
    },
    {
        label: `6%`,
        value: `6%`,
    },
    {
        label: `7%`,
        value: `7%`,
    },
    {
        label: `8%`,
        value: `8%`,
    },
];

