import React, { useRef, useState } from "react";
import DeleteBtn from "../../../../components/buttons/deleteBtn";
import SaveBtn from "../../../../components/buttons/saveBtn";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import InnerContainer from "../../../../components/layouts/innerContainer/innerContainer";
import InnerLayout from "../../../../components/layouts/innerLayout/innerLayout";
import "../memberTradeBuyIn.css";
import InputField from "../../../../components/form/inputFields/inputField";
import * as opporTypeActions from "../../../../redux/actions/opportunityTypeActions";
import successToast from "../../../../components/utilits/successToast";
import errorToast from "../../../../components/utilits/errorToast";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import SubmitBtn from "../../../../components/buttons/submit";
import SelectFields from "../../../../components/form/selectFields/selectFields";
import { useEffect } from "react";
import { notifyMeCreateOptions } from "../../../../redux/actions/notifyMeActions";
import { viewWishListFindAll } from "../../../../redux/actions/wishlistActions";
import RadioField from "../../../../components/form/radioFields/radioField";
import ErrorMsg from "../../../../components/form/errorMsg";
import { memberTradeBuyInCreate } from "../../../../redux/actions/memberTradeBuyInAction";
import AdminRoleCheckBox from "../../../../components/utilits/adminRoleCheckbox";
import { opportunitiesNameListAction } from "../../../../redux/actions/opportunitiesActions";

const MemberTradeBuyInCreate = () => {
  const [loading, setLoading] = useState(false);
  const formRef = useRef();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [subLoading, setSubLoading] = useState(false);
  const [adminId, setAdminId] = useState("");
  const [saved, setSaved] = useState(false);
  const [userName, setUserName] = useState([]);
  const [llpName, setLlpName] = useState([]);
  const [allUsers, setAllUsers] = useState([]);
  const regExp = /^[0-9]+$/;

  useEffect(() => {
    dispatch(notifyMeCreateOptions(onNotifyMeSuccess, onNotifyMeError));
  }, []);
  const userNameOptions = [];
  //   const llpNameOptions = [];
  //   const categoryList = [];
  const onNotifyMeSuccess = (data) => {
    data?.data.AllUsers.map((item, index) => {
      userNameOptions[index] = { value: item.id, label: item.user_name };
    });
    console.log(userNameOptions);
    setUserName(userNameOptions);
    // setLlpName(llpNameOptions);
  };
  const onNotifyMeError = (data) => {
    console.log("ERROR");
  };

  const initialValues = {
    userId: "",
    opportunity: "",
    amount: "",
    disclosedIdentity: false,
  };
  const loginSchema = Yup.object().shape({
    userId: Yup.string().required("Required"),
    opportunity: Yup.string().required("Required"),
    amount: Yup.string()
      .matches(regExp, "Enter valid amount!")
      .required("Required"),
  });
  const handleSubmit = () => {
    if (formRef.current) {
      formRef.current.handleSubmit();
    }
  };
  const onSubmit = (values) => {
    setLoading(true);
    console.log(values);
    dispatch(
      memberTradeBuyInCreate(

        {
          "user_id":values.userId,
          "opportunity_id":values.opportunity,
          "amount":values.amount,
          "disclosed_identity":values.disclosedIdentity
      },
        onSuccess,
        onError
      )
    );
  };
  const onSuccess = (data) => {
    console.log(data);
    setLoading(false);
    setAdminId(data.data.id);
    setSaved(true);
    successToast(data.message);
    navigate(`/opportunity/memberTrade-buy-in`);
  };
  const onError = (data) => {
    setLoading(false);
    errorToast(data.message);
  };

  const [opportunitiesList, setOpportunitiesList] = useState([])
    useEffect(() => {
        dispatch(opportunitiesNameListAction(onSuccessOpt, onErrorOpt));
    }, []);
    const onSuccessOpt = (data) => {
        const categoryList = [];
        data?.data.map((item, index) => {
            categoryList[index] = { value: item.id, label: `${item.title} id: ${item.id}` };
        });
        setOpportunitiesList(categoryList);
        console.log("SUCCESS");
    };
    const onErrorOpt = (data) => {
        console.log(data);
    };
  const buttonList = [<SaveBtn loader={loading} submitFn={handleSubmit} />];


  return (
    <InnerLayout buttons={buttonList}>
      <div className="opportunity-type-container">
        <InnerContainer>
          <div className="pt-3  ps-4 ps-lg-4 ms-3">
      
            <Formik
              innerRef={formRef}
              initialValues={initialValues}
              onSubmit={onSubmit}
              validationSchema={loginSchema}
            >
              <Form className="opportunity-type-form-width">
                <SelectFields
                  label={"User Name"}
                  placeholder={"select the user name"}
                  name={"userId"}
                  options={userName}
                  id={"userId"}
                />
                <SelectFields
                  label={"Opportunity"}
                  placeholder={"Opportunity here"}
                  name={"opportunity"}
                  options={opportunitiesList}
                  id={"opportunity"}
                />
                <InputField
                  id={"amount"}
                  label={"Amount"}
                  type={"text"}
                  placeholder={"Enter amount"}
                  name={"amount"}
                />
                <div>
                  <label
                    htmlFor="disclosedIdentity"
                    className="mb-2 dark_grey_color fn_Montserrat fw_500 fs_14"
                  >
                    Is Disclosed Identity
                  </label>
                  <AdminRoleCheckBox
                    name="disclosedIdentity"
                    id="disclosedIdentity"
                  />
                </div>
              </Form>
            </Formik>
          </div>
        </InnerContainer>
      </div>
    </InnerLayout>
  );
};

export default MemberTradeBuyInCreate;
