



import React from "react";

const FilterIcon = ({ fill = "#bcc7ce", width = 10, height = 16 }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 20.863 13.909">
      <path id="Icon_material-filter-list" data-name="Icon material-filter-list" d="M12.613,22.909h4.636V20.59H12.613ZM4.5,9v2.318H25.363V9Zm3.477,8.113H21.886V14.8H7.977Z" transform="translate(-4.5 -9)" fill={fill} />
    </svg>
  );
};

export default FilterIcon;
