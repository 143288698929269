import {
  BLOG_CATEGORY_OPTIONS,
  APPROVE_BLOG,
  BLOG_APPROVAL_TABLE,
  BLOG_APPROVAL_VIEW,
  BLOG_OPTIONS,
  BLOG_TABLE,
  BLOG_VERSION_HISTORY,
  BLOG_VERSION_HISTORY_TABLE,
  CREATE_BLOG,
  DELETE_BLOG,
  EDIT_BLOG,
  REJECT_BLOG,
  VIEW_BLOG,
  BLOG_MAIN_EXPORT,
  BLOG_APPROVALS_EXPORT,
  BLOG_VERSION_EXPORT,
  BLOG_SUBMIT,
} from "./types";

export const createBlogAction = (data, onSuccess, onError) => {
  return {
    type: CREATE_BLOG,
    data,
    onSuccess,
    onError,
  };
};
export const approveBlogAction = (id, onSuccess, onError) => {
  return {
    type: APPROVE_BLOG,
    id,
    onSuccess,
    onError,
  };
};

export const rejectBlogAction = (id, onSuccess, onError) => {
  return {
    type: REJECT_BLOG,
    id,
    onSuccess,
    onError,
  };
};

export const viewBlogDetailAction = (id, onSuccess, onError) => {
  return {
    type: VIEW_BLOG,
    id,
    onSuccess,
    onError,
  };
};

export const viewBlogListAction = (data, page_no = 0, onSuccess, onError) => {
  return {
    type: BLOG_TABLE,
    data,
    page_no,
    onSuccess,
    onError,
  };
};

export const editBlogAction = (id, data, onSuccess, onError) => {
  return {
    type: EDIT_BLOG,
    id,
    data,
    onSuccess,
    onError,
  };
};

export const deleteBlogAction = (id, onSuccess, onError) => {
  return {
    type: DELETE_BLOG,
    id,
    onSuccess,
    onError,
  };
};

export const blogApprovalListAction = (
  data,
  page_no = 0,
  onSuccess,
  onError
) => {
  return {
    type: BLOG_APPROVAL_TABLE,
    data,
    page_no,
    onSuccess,
    onError,
  };
};

export const blogApprovalByIdAction = (id, onSuccess, onError) => {
  return {
    type: BLOG_APPROVAL_VIEW,
    id,
    onSuccess,
    onError,
  };
};

export const versionHistoryBlogListAction = (
  data,
  page,
  onSuccess,
  onError
) => {
  return {
    type: BLOG_VERSION_HISTORY_TABLE,
    data,
    page,
    onSuccess,
    onError,
  };
};

export const versionHistoryBlogDetailAction = (id, onSuccess, onError) => {
  return {
    type: BLOG_VERSION_HISTORY,
    id,
    onSuccess,
    onError,
  };
};
export const blogCategoryOptionsAction = (onSuccess, onError) => {
  return {
    type: BLOG_CATEGORY_OPTIONS,
    onSuccess,
    onError,
  };
};
export const blogOptionsAction = (onSuccess, onError) => {
  return {
    type: BLOG_OPTIONS,
    onSuccess,
    onError,
  };
};

export const blogMainExportAction = (onSuccess, onError) => {
  return {
    type: BLOG_MAIN_EXPORT,
    onSuccess,
    onError,
  };
};
export const blogApprovalsExportAction = (onSuccess, onError) => {
  return {
    type: BLOG_APPROVALS_EXPORT,
    onSuccess,
    onError,
  };
};
export const blogVersionExportAction = (onSuccess, onError) => {
  return {
    type: BLOG_VERSION_EXPORT,
    onSuccess,
    onError,
  };
};
export const blogSubmit = (id, onSuccess, onError) => {
  return {
    type: BLOG_SUBMIT,
    id,
    onSuccess,
    onError,
  };
};
