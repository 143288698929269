import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { versionHistoryAdminList } from "../../../redux/actions/AdminUserAction";
import { TableContainer } from "../../table/tableContainer";
import {
  tableAdminUsersVersionHeader,
  tableAdminVersionHeader,
  tableAdminVersionRow,
} from "../../table/tableData";
import TablePagination from "../../table/tablePagination";
import LoadingSpinnerTable from "../../utilits/loadingSpinnerTable";
import "./admintable.css";
import moment from "moment";
import EmptyTable from "../../table/emptyTable";
import LoadAndError from "../../utilits/LoadAndError";
const AdminUserVersionTableContent = ({ page, search, changePage }) => {
  const dispatch = useDispatch();
  const [versionHistoryList, setVersionHistoryList] = useState([]);
  const [loadingTable, setLoadingTable] = useState(false);
  const [error, setError] = useState(false);
  const [pageData, setPageData] = useState({ noOfItems: 0, noOfPages: 1 });
  useEffect(() => {
    setLoadingTable(true);
    dispatch(
      versionHistoryAdminList(
        {
          search: {
            admin_id: search.Id,
          },
        },
        page,
        onSuccess,
        onError
      )
    );
  }, [search, page]);

  const onSuccess = (data) => {
    setVersionHistoryList(data?.data.data);
    setPageData({
      noOfItems: data.data.count,
      noOfPages: data.data.pages,
    });
    setError(false);
    setLoadingTable(false);
  };

  const onError = (data) => {
    console.log(data);
    setError(true);
    setLoadingTable(false);
  };
  function handlePagination(type) {
    if (type === "+") {
      if (page + 1 < pageData.noOfPages) changePage("+");
    } else if (type === "-") if (page > 0) changePage("-");
  }
  return (
    <TableContainer maxWidth="100%" width="90%">
      <div className="adminuser_table__container position-relative">
        {loadingTable && <LoadingSpinnerTable />}
        <table className={"adminuser_table"}>
          <thead className="adminuser_table__header">
            <tr className="adminuser_table__row">
              {tableAdminUsersVersionHeader.map((item) => {
                return (
                  <th
                    key={item}
                    className={`text-nowrap adminuser_table__heading ${
                      item === "Status" && "text-center position-sticky end-0"
                    } `}
                  >
                    {item}
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody className="adminuser_table__body">
            <LoadAndError
              loader={loadingTable}
              error={error}
              status={versionHistoryList.length === 0}
            >
              {versionHistoryList?.map((item, index) => {
                return (
                  <tr key={item.id} className="adminuser_table__row">
                    <td className="text-nowrap dark_title_color adminuser_table__data ">
                      {page > 0 ? 12 * page + (index + 1) : index + 1}
                    </td>
                    <td className="text-nowrap secondary_color adminuser_table__data">
                      {item?.id}
                    </td>
                    <td className="text-nowrap secondary_color adminuser_table__data">
                      {item.admin_id ? item.admin_id : "-"}
                    </td>
                    <td className="text-nowrap secondary_color adminuser_table__data">
                      {item?.new_data === null
                        ? item?.old_data?.name
                        : item?.new_data?.name}
                    </td>
                    <td className="text-nowrap secondary_color adminuser_table__data">
                      {item?.updated_by === null ? (
                        "-"
                      ) : (
                        <Link
                          className="text-decoration-none secondary_color"
                          to={{
                            pathname: `/admin-users/view/${item?.new_data?.updated_by}`,
                          }}
                        >
                          {item?.updated_by}
                        </Link>
                      )}
                    </td>
                    <td className="text-nowrap dark_title_color adminuser_table__data">
                      {item?.new_data?.update_date === null
                        ? "-"
                        : moment(item?.new_data?.update_date).format(
                            "DD-MM-YYYY HH:mm:ss"
                          )}
                    </td>
                    <td className="text-nowrap dark_title_color adminuser_table__data">
                      {item?.approved_by}
                    </td>
                    <td className="text-nowrap dark_title_color adminuser_table__data">
                      {item?.approved_date === null
                        ? "-"
                        : moment(item?.approved_date).format(
                            "DD-MM-YYYY HH:mm:ss"
                          )}
                    </td>
                    <td className="text-nowrap dark_title_color adminuser_table__data adminuser_table_empty__data"></td>

                    <td className="text-nowrap  adminuser_table__data position-sticky end-0">
                      <div className="d-flex justify-content-center w-100 gap-2">
                        <Link
                          className="text-decoration-none primary_color"
                          to={{
                            pathname: `/admin-users/version-history/view/${item?.id}`,
                          }}
                        >
                          View
                        </Link>{" "}
                      </div>
                    </td>
                  </tr>
                );
              })}
            </LoadAndError>
          </tbody>
        </table>
      </div>
      <TablePagination
        paginate={handlePagination}
        currentPage={page}
        pageData={pageData}
      />
    </TableContainer>
  );
};

export default AdminUserVersionTableContent;
