import React, { useState } from 'react'
import ExportBtn from '../../../../components/buttons/exportBtn';
import FilterBtn from '../../../../components/buttons/filterBtn';
import AdminUserFilter from '../../../../components/filter/adminUserFilter'
import InnerLayout from '../../../../components/layouts/innerLayout/innerLayout'
import WishlistVersionTableContent from '../../../../components/opportunity/wishlist/tables/wishlistVersionTableContent';
import FileSaver from 'file-saver';
import { wishListExport, wishlistVersionExport } from '../../../../redux/actions/wishlistActions'
import { useDispatch } from "react-redux";
import errorToast from "../../../../components/utilits/errorToast";
import VersionTablesFilter from '../../../../components/filter/versionTableFilter';

const WishlistVersionTable = () => {
  const [page, setPage] = useState(0);
  const [showFilter, setShowFilter] = useState(false);
  const [search, setSearch] = useState({ value: "" });
  const dispatch = useDispatch();
  const handleFilterClose = () => {
    setShowFilter(false);
  };
  const handleFilterOpen = () => {
    setShowFilter(true);
  };
  const handleSearch = (value) => {
    setSearch({ value: value });
    setPage(0)
  };
  const handlePage = (type) => {
    if (type === "+") {
      setPage((prev) => prev + 1)
    }
    else {
      setPage((prev) => prev - 1)
    }
  }
  console.log(search);
  // const handleExport = () => {
  //     setLoading(true);
  //     dispatch(
  //         wishListExport(onSuccess, onError)
  //     );
  // };

  // //export call back states
  // const onSuccess = (data) => {
  //     setExportData(data.data);
  //     var type = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8";
  //     var blob = new Blob([exportData], { type: type });
  //     FileSaver.saveAs(blob, "file.xlsx");
  //     setLoading(false)
  // };
  // const onError = (data) => {
  //     // errorToast(data.data.message)
  //     setExportData(data.data);
  //     var type = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8";
  //     var blob = new Blob([exportData], { type: type });
  //     FileSaver.saveAs(blob, "file.xlsx");

  //     console.log("The log ", data);
  //     errorToast("Something went wrong")
  //     setLoading(false)
  // };


  const buttonList = [

    <ExportBtn dispatchAction={wishlistVersionExport} />,
    <FilterBtn filterFn={handleFilterOpen} />,
  ];
  return (
    <InnerLayout buttons={buttonList}>
      <VersionTablesFilter
        title='Wishlist Id'
        placeholder='Enter Wishlist Id'
        filter={handleSearch}
        showFilter={showFilter}
        closeFilter={handleFilterClose}
      />
      <WishlistVersionTableContent page={page} changePage={handlePage} search={search} />
    </InnerLayout>
  )
}

export default WishlistVersionTable