import { APPROVE_CAMPAIGN_OPPOR, CREATE_CAMPAIGN_OPPOR, DELETE_CAMPAIGN_OPPOR, EDIT_CAMPAIGN_OPPOR, CAMPAIGN_OPPOR_APPROVALS_EXPORT, CAMPAIGN_OPPOR_APPROVAL_TABLE, CAMPAIGN_OPPOR_APPROVAL_VIEW, CAMPAIGN_OPPOR_EXPORT, CAMPAIGN_OPPOR_OPTIONS, CAMPAIGN_OPPOR_SUBMIT, CAMPAIGN_OPPOR_TABLE, CAMPAIGN_OPPOR_VERSION_EXPORT, CAMPAIGN_OPPOR_VERSION_HISTORY, CAMPAIGN_OPPOR_VERSION_HISTORY_TABLE, REJECT_CAMPAIGN_OPPOR, VIEW_CAMPAIGN_OPPOR, CAMPAIGN_OPPOR_ACTIVE } from "./types";


export const createCampaignOppor = (
    data,
    onSuccess,
    onError
) => {
    return {
        type: CREATE_CAMPAIGN_OPPOR,
        data,
        onSuccess,
        onError,
    };
};

export const approveCampaignOppor = (id, onSuccess, onError) => {
    return {
        type: APPROVE_CAMPAIGN_OPPOR,
        id,
        onSuccess,
        onError,
    };
};

export const rejectCampaignOppor = (id, onSuccess, onError) => {
    return {
        type: REJECT_CAMPAIGN_OPPOR,
        id,
        onSuccess,
        onError,
    };
};

export const viewCampaignOpporDetail = (id, onSuccess, onError) => {
    return {
        type: VIEW_CAMPAIGN_OPPOR,
        id,
        onSuccess,
        onError,
    };
};

export const viewCampaignOpporList = (data, page_no = 0, onSuccess, onError) => {
    return {
        type: CAMPAIGN_OPPOR_TABLE,
        data,
        page_no,
        onSuccess,
        onError,
    };
};

export const editCampaignOppor = (
    id,
    data,
    onSuccess,
    onError
) => {
    return {
        type: EDIT_CAMPAIGN_OPPOR,
        id,
        data,
        onSuccess,
        onError,
    };
};


export const deleteCampaignOppor = (id, onSuccess, onError) => {
    return {
        type: DELETE_CAMPAIGN_OPPOR,
        id,
        onSuccess,
        onError,
    };
};



export const campaignOpporApprovalList = (data, page_no = 0, onSuccess, onError) => {
    return {
        type: CAMPAIGN_OPPOR_APPROVAL_TABLE,
        data,
        page_no,
        onSuccess,
        onError,
    };
};

export const CampaignOpporApprovalById = (id, onSuccess, onError) => {
    return {
        type: CAMPAIGN_OPPOR_APPROVAL_VIEW,
        id,
        onSuccess,
        onError,
    };
};


export const versionHistoryCampaignOpporList = (data, page, onSuccess, onError) => {
    return {
        type: CAMPAIGN_OPPOR_VERSION_HISTORY_TABLE,
        data,
        page,
        onSuccess,
        onError,
    };
};

export const versionHistoryCampaignOpporDetail = (id, onSuccess, onError) => {
    return {
        type: CAMPAIGN_OPPOR_VERSION_HISTORY,
        id,
        onSuccess,
        onError,
    };
};
export const campaignOpporOptions = (onSuccess, onError) => {
    return {
        type: CAMPAIGN_OPPOR_OPTIONS,
        onSuccess,
        onError,
    };
};

export const campaignOpporTableExport = (onSuccess, onError) => {
    return {
        type: CAMPAIGN_OPPOR_EXPORT,
        onSuccess,
        onError,
    };
};
export const campaignOpporApprovalsExport = (onSuccess, onError) => {
    return {
        type: CAMPAIGN_OPPOR_APPROVALS_EXPORT,
        onSuccess,
        onError,
    };
};
export const campaignOpporVersionExport = (onSuccess, onError) => {
    return {
        type: CAMPAIGN_OPPOR_VERSION_EXPORT,
        onSuccess,
        onError,
    };
};



export const campaignOpporSubmit = (id, onSuccess, onError) => {
    return {
        type: CAMPAIGN_OPPOR_SUBMIT,
        id,
        onSuccess,
        onError,
    };
};

export const activateCampaignOpporAction = (id, onSuccess, onError) => {
    return {
      type: CAMPAIGN_OPPOR_ACTIVE,
      id,
      onSuccess,
      onError,
    };
  };


