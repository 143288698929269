import { authHeader } from "../config";
import { api } from "./api";
    
export const deleteSocialApi = (id, params) => {
    return api.post(`/api/admin/social_contributor/delete/${id}`, JSON.stringify(params), {
        headers: {
            "x-access-token": localStorage.getItem("accessToken"),
            "Content-Type": "application/json",
        },
    });
};

export const viewAllSocialApi = (pageNo = 0, params) => {
    return api.post(`/api/admin/social_contributor/view-approvals?page_no=${pageNo}`, JSON.stringify(params), {
        headers: {
            "x-access-token": localStorage.getItem("accessToken"),
            "Content-Type": "application/json",
        },
    });
};
export const readSocialApi = (id = "", params) => {
    return api.post(`/api/admin/social_contributor/view-approval/${id}`, JSON.stringify(params), {
        headers: {
            "x-access-token": localStorage.getItem("accessToken"),
            "Content-Type": "application/json",
        },
    });
};
export const readApprovalSocialApi = (id = "", params) => {
    return api.post(`/api/admin/social_contributor/view-checker/${id}`, JSON.stringify(params), {
        headers: {
            "x-access-token": localStorage.getItem("accessToken"),
            "Content-Type": "application/json",
        },
    });
};

export const versionTableSocialApi = (pageNo = 0, params) => {
    return api.post(`/api/admin/social_contributor/view-version?page_no=${pageNo}`, JSON.stringify(params), {
        headers: {
            "x-access-token": localStorage.getItem("accessToken"),
            "Content-Type": "application/json",
        },
    });
};
export const socialVersionHistoryViewApi = (id, params) => {
    return api.post(`/api/admin/social_contributor/view-version/${id}`, JSON.stringify(params), {
        headers: {
            "x-access-token": localStorage.getItem("accessToken"),
            "Content-Type": "application/json",
        },
    });
};

export const socialApprovalsTableApi = (pageNo = 0, params) => {
    return api.post(`/api/admin/social_contributor/view-checker?page_no=${pageNo}`, JSON.stringify(params), {
        headers: {
            "x-access-token": localStorage.getItem("accessToken"),
            "Content-Type": "application/json",
        },
    });
};

export const socialCreateApi = (params) => {
    return api.post("/api/admin/social_contributor/create", JSON.stringify(params), {
        headers: {
            "x-access-token": localStorage.getItem("accessToken"),
            "Content-Type": "application/json",
        },
    });
};



export const editSocialApi = (id, params) => {
    return api.post(`/api/admin/social_contributor/edit/${id}`, JSON.stringify(params), {
        headers: {
            "x-access-token": localStorage.getItem("accessToken"),
            "Content-Type": "application/json",
        },
    });
};

export const approveSocialApi = (params) => {
    return api.post(`/api/admin/social_contributor/approve`, JSON.stringify(params), {
        headers: {
            "x-access-token": localStorage.getItem("accessToken"),
            "Content-Type": "application/json",
        },
    });
};


export const rejectSocialApi = (id, params) => {
    return api.post(`/api/admin/social_contributor/reject/${id}`, JSON.stringify(params), {
        headers: {
            "x-access-token": localStorage.getItem("accessToken"),
            "Content-Type": "application/json",
        },
    });
};


                                         
export const socialTableExportApi = (params) => {
    return api.post(
        `/api/admin/social_contributor/export-main`,
        JSON.stringify(params),
        {
            headers: {
                "x-access-token": localStorage.getItem("accessToken"),
                "Content-Type": "application/json",
            },
        }
    );
};
export const socialApprovalsExportApi = (params) => {
    return api.post(
        `/api/admin/social_contributor/export-approval`,
        JSON.stringify(params),
        {
            headers: {
                "x-access-token": localStorage.getItem("accessToken"),
                "Content-Type": "application/json",
            },
        }
    );
};
export const socialVersionExportApi = (params) => {
    return api.post(
        `/api/admin/social_contributor/export-version`,
        JSON.stringify(params),
        {
            headers: {
                "x-access-token": localStorage.getItem("accessToken"),
                "Content-Type": "application/json",
            },
        }
    );
};




export const socialSubmitApi = (id, params) => {
    return api.post(
      `/api/admin/social_contributor/on-submit/${id}`, JSON.stringify(params),
      {
        headers: {
          "x-access-token": localStorage.getItem("accessToken"),
          "Content-Type": "application/json",
        },
      }
    );
  };
