import React, { useEffect, useRef, useState } from "react";
import "../our-advisors.css";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import SaveBtn from "../../../../components/buttons/saveBtn";
import InnerContainer from "../../../../components/layouts/innerContainer/innerContainer";
import InnerLayout from "../../../../components/layouts/innerLayout/innerLayout";
import DeleteBtn from "../../../../components/buttons/deleteBtn";
import SelectFields from "../../../../components/form/selectFields/selectFields";
import InputField from "../../../../components/form/inputFields/inputField";
import ArrowDownIcon from "../../../../assets/icons/arrowdown";
import ErrorMsg from "../../../../components/form/errorMsg";
import CloseIcon from "../../../../assets/icons/closeIcon";
import UploadIcon from "../../../../assets/icons/upload";
import ArrowRightIcon from "../../../../assets/icons/arrowrightIcon";
import errorToast from "../../../../components/utilits/errorToast";
import { useDispatch } from "react-redux";
import {
  deleteAdvisorAction,
  editAdvisorAction,
  viewAdvisorAction,
  advisorsSubmit,
} from "../../../../redux/actions/ourAdvisorAction";
import { useNavigate, useParams } from "react-router-dom";
import { uploadAddressProofFrontImageAction, uploadProfileImageAction } from "../../../../redux/actions/userAction";
import successToast from "../../../../components/utilits/successToast";
import DeleteModal from "../../../../components/modals/deleteModal";
import LoadingSpinnerTable from "../../../../components/utilits/loadingSpinnerTable";
import LoadAndError from "../../../../components/utilits/LoadAndError";
import SubmitBtn from "../../../../components/buttons/submit";
import { advisorsSubmitApi } from "../../../../redux/apis/advisorsApis";

const OurAdvisorsEdit = () => {
  const [submitData, setSubmitData] = useState();
  const [file, setFile] = useState(null);
  const [advisorRead, setAdvisorRead] = useState();
  const [profilePicture, setProfilePicture] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [deleteData, setDeleteData] = useState({ name: "", id: "" });
  const [show, setShow] = useState(false);

  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [image, setImage] = useState("");
  const formRef = useRef();
  const params = useParams();

  const handleSubmit = () => {
    if (!deskImageLink.img) {
      if (!deskImageLink.img) {
        setDeskImageLink({ img: "", error: true });
      }
      if (!mobImageLink.img) {
        setMobImageLink({ img: "", error: true });
      }
    }
    if (formRef.current) {
      formRef.current.handleSubmit();
    }
  };

  useEffect(() => {
    setLoading(true);
    dispatch(viewAdvisorAction(params?.id, onSuccess, onError));
    // setImage(
    //   individualData?.new_data?.profile_picture_location
    //     .split("/")
    //     .pop()
    // );
  }, []);

  const onSuccess = (data) => {
    setAdvisorRead(data.data);
    setLoading(false);
    setDeskImageLink({ img: data?.data?.photo_url, error: false })
    setMobImageLink({ img: data.data.website_icon, error: false })
    setError(false);
  };

  const onError = (data) => {
    console.log(data);
    errorToast(data.message);
    setLoading(false);
    setError(true);
  };

  function handleImageChange(e, param1) {
    console.log(param1);
    if (e.target?.files.length !== 0) {
      setFile(URL.createObjectURL(e.target.files[0]));

      dispatch(
        uploadProfileImageAction(
          e.target.files[0],
          onProfileUploadImageSuccess,
          onProfileUploadImageError
        )
      );
      e.target.value = null;
    }
  }
  const onProfileUploadImageSuccess = (data) => {
    console.log(data.data.data);

    setProfilePicture(data.data.data.location);
  };
  const onProfileUploadImageError = (data) => {
    console.log(data);
  };
  const initialValues = {

    Name: advisorRead?.name ? advisorRead?.name : "",
    Designation: advisorRead?.designation ? advisorRead?.designation : "",
    website_url: advisorRead?.website_url ? advisorRead?.website_url : "",
    Prirority: advisorRead?.priority ? advisorRead?.priority : "",
    Description: advisorRead?.description ? advisorRead?.description : "",
  };
  const loginSchema = Yup.object().shape({

    Name: Yup.string().trim().required("Required"),
    Designation: Yup.string().trim().required("Required"),
    website_url: Yup.string().trim().required("Required"),

    Prirority: Yup.number().positive("Priority should be greater than  zero").required("Required"),
    Description: Yup.string().trim().required("Required"),
  });

  // editAdvisorAction
  const onSubmit = (values, { resetForm }) => {

    console.log(values);
    if (mobImageLink.img && deskImageLink.img)
      dispatch(
        editAdvisorAction(
          params?.id,
          {
            name: values.Name,
            website_url: values.website_url,
            website_icon: mobImageLink.img,
            description: values.Description,
            designation: values.Designation,
            photo_url: deskImageLink.img,
            priority: values.Prirority,
          },
          onEditSuccess,
          onEditError
        )
      );
  };

  const onEditSuccess = (data) => {
    console.log(data);
    setLoading(false);
    successToast(data.message);
    setSubmitData(data?.data?.id)
    setSaveHide(true)
    setError(false);
  };
  const onEditError = (data) => {
    console.log(data);
    setLoading(false);
    errorToast(data.message);
    setError(true);
  };

  const handleDelete = () => {
    setLoading(true);
    dispatch(deleteAdvisorAction(params?.id, onDeleteSuccess, onDeleteError));
  };
  const onDeleteSuccess = (data) => {
    console.log(data);
    successToast(data.message);
    setLoading(false);
    setShow(false);
    setError(false);
  };
  const onDeleteError = (data) => {
    errorToast(data.message);
    setLoading(false);
    setError(true);
  };


  //image
  const [deskImageLink, setDeskImageLink] = useState({
    img: "",
    error: false,
  });
  const [mobImageLink, setMobImageLink] = useState({
    img: "",
    error: false,
  });
  const imageUpload = (e) => {
    if (e.target.name === "deskImage") {
      dispatch(
        uploadAddressProofFrontImageAction(
          e.target.files[0],
          onDeskImageUploadSucess,
          onDeskImageUploadError
        )
      );
    } else if (e.target.name === "mobImage") {
      dispatch(
        uploadAddressProofFrontImageAction(
          e.target.files[0],
          onMobImageUploadSucess,
          onMobImageUploadError
        )
      );
    }
    e.target.value = null;
  };
  const onDeskImageUploadSucess = (data) => {
    setDeskImageLink({ img: data.data.data.location, error: false });
  };
  const onDeskImageUploadError = (data) => {
    setDeskImageLink({ img: "", error: true });
  };
  const onMobImageUploadSucess = (data) => {
    setMobImageLink({ img: data.data.data.location, error: false });
  };
  const onMobImageUploadError = (data) => {
    setMobImageLink({ img: "", error: true });
  };

  //on submit api
  console.log(submitData);
  const [saveHide, setSaveHide] = useState(false);

  const submitFnc = () => {
    dispatch(advisorsSubmit
      (
        submitData,
        onSubmitsSuccess,
        onSubmitsError,
      ));
  };
  const onSubmitsSuccess = (data) => {
    console.log(data);
    successToast(data.message)
    navigate("/static-pages/our-advisors");
  };
  const onSubmitsError = (data) => {
    console.log("ERROR==================", data);
    errorToast(data.message)

  };
  const buttonList = [
    !saveHide && <SaveBtn submitFn={handleSubmit}
      loader={loading} />,
    <SubmitBtn submitFn={submitFnc} saveHide={saveHide} />];
  return (
    <InnerLayout buttons={buttonList}>
      <div className="our-advisors-container">
        <InnerContainer>
          {loading ? (
            <LoadingSpinnerTable />
          ) : (
            <LoadAndError loader={loading} error={error}>
              <div className="pt-3  ps-4 ps-lg-4 ms-4">
                <h5 className="dark_grey_color fs_13 fn_Montserrat fw_500 m-0">
                  Advisors ID
                </h5>
                <p className={` fs_13 fn_Nunito fw_500 dark_title_color  `}>
                  {advisorRead?.id}
                </p>
              </div>
              <div className="  ps-4 ps-lg-4 ms-3">
                <Formik
                  innerRef={formRef}
                  initialValues={initialValues}
                  onSubmit={onSubmit}
                  validationSchema={loginSchema}
                  enableReinitialize={true}
                >
                  {({ formik, handleChange, values, setFieldValue }) => {
                    return (
                      <Form className="">
                        <div className="our-advisors-form-width">
                          <div className="row">
                            <div className="col-12 col-md-6">
                              <div className="mb-3">
                                <span className="mb-2 dark_grey_color fn_Montserrat fw_500 fs_14 ps-2">Profile Image</span>
                                <div className="blog_create_image_container mt-1 position-relative d-flex  justify-content-center align-items-center">
                                  <input
                                    multiple={false}
                                    style={{ visibility: "hidden" }}
                                    type="file"
                                    id="deskImage"
                                    className="upload_document_input"
                                    name="deskImage"
                                    accept="image/* ,capture=camera"
                                    onChange={(e) => {
                                      imageUpload(e);
                                    }}
                                  />
                                  {deskImageLink.img ? (
                                    <>
                                      <img
                                        src={deskImageLink.img}
                                        className={"user-edit-profile-picture"}
                                      />
                                      <button
                                        type="button"
                                        onClick={(e) => {
                                          setDeskImageLink({ img: "", error: true });
                                        }}
                                        className="position-absolute border-0 cursor_pointer white_bg d-flex gap-2 py-1 px-2 user_edit_profile_picture_remove_container"
                                      >
                                        <CloseIcon fill="#666666" />
                                        <span className="dark_grey_color fs_13 fn_Montserrat fw_500 ">
                                          Remove
                                        </span>
                                      </button>
                                    </>
                                  ) : (
                                    <label
                                      htmlFor="deskImage"
                                      className="d-flex flex-column align-items-center mt-2 justify-content-center fs_12"
                                    >
                                      <UploadIcon width={30} height={30} />
                                      Choose Profile Picture
                                    </label>
                                  )}
                                </div>
                                {deskImageLink.error && (
                                  <span className="fs_12 ms-2 text-danger fw-semibold fn_Nunito">
                                    Required!
                                  </span>
                                )}
                              </div>
                              <InputField
                                id={"Name"}
                                label={"Name"}
                                type={"text"}
                                placeholder={"Enter Name"}
                                name={"Name"}
                              />
                              <SelectFields
                                label={"Designation"}
                                placeholder={"Select designation"}
                                name={"Designation"}
                                default1={{
                                  label: advisorRead?.designation,
                                  value: advisorRead?.designation,
                                }}
                                options={designation}
                                id={"Designation"}
                              />
                              <InputField
                                id={"website_url"}
                                label={"Website URL"}
                                type={"text"}
                                placeholder={"Enter Website URL"}
                                name={"website_url"}
                              />

                              <div className="mb-3">
                                <span className="dark_grey_color fn_Montserrat fw_500 fs_14">Website Logo</span>
                                <div className="blog_create_image_container mt-2 position-relative d-flex  justify-content-center align-items-center">
                                  <input
                                    multiple={false}
                                    style={{ visibility: "hidden" }}
                                    type="file"
                                    id="mobImage"
                                    className="upload_document_input"
                                    name="mobImage"
                                    accept="image/* ,capture=camera"
                                    onChange={(e) => {
                                      imageUpload(e);
                                    }}
                                  />
                                  {mobImageLink.img ? (
                                    <>
                                      <img
                                        src={mobImageLink.img}
                                        className={"user-edit-profile-picture"}
                                      />
                                      <button
                                        type="button"
                                        onClick={(e) => {
                                          setMobImageLink({ img: "", error: true });
                                        }}
                                        className="position-absolute border-0 cursor_pointer white_bg d-flex gap-2 py-1 px-2 user_edit_profile_picture_remove_container"
                                      >
                                        <CloseIcon fill="#666666" />
                                        <span className="dark_grey_color fs_13 fn_Montserrat fw_500 ">
                                          Remove
                                        </span>
                                      </button>
                                    </>
                                  ) : (
                                    <label
                                      htmlFor="mobImage"
                                      className="d-flex flex-column align-items-center mt-2 justify-content-center fs_12"
                                    >
                                      <UploadIcon width={30} height={30} />
                                      Choose Profile Picture
                                    </label>
                                  )}
                                </div>
                                {mobImageLink.error && (
                                  <span className="fs_12 ms-2 text-danger fw-semibold fn_Nunito">
                                    Required!
                                  </span>
                                )}
                              </div>
                            </div>
                            <div className="col-12 col-md-6">
                              <div className="ps-0 ps-lg-4">
                                <SelectFields
                                  label={"Prirority"}
                                  placeholder={"Select prirority"}
                                  default1={{
                                    label: advisorRead?.priority,
                                    value: advisorRead?.priority,
                                  }}
                                  name={"Prirority"}
                                  options={priority}
                                  id={"Prirority"}
                                />
                                <label
                                  id="Description"
                                  className="mb-2 dark_grey_color fn_Nunito mt-2 fw_500 fs_15 ps-1 ms-1"
                                >
                                  Description
                                </label>
                                <Field name={"Description"}>
                                  {({ field }) => (
                                    <textarea
                                      id="Description"
                                      {...field}
                                      placeholder={"Enter Description"}
                                      type="text"
                                      rows="4"
                                      className="w-100 llp_message_container p-2 "
                                    ></textarea>
                                  )}
                                </Field>
                                <ErrorMsg name={"Description"} />
                              </div>
                            </div>
                          </div>
                        </div>
                      </Form>
                    );
                  }}
                </Formik>
              </div>
            </LoadAndError>
          )}
        </InnerContainer>
      </div>
      <DeleteModal
        loader={loading}
        handleClose={handleClose}
        deleteFn={handleDelete}
        data={deleteData}
        show={show}
      />
    </InnerLayout>
  );
};

export default OurAdvisorsEdit;

export const designation = [
  { value: "Project Manager", label: "Project Manager" },
  { value: "Manager", label: "Manager" },
  { value: "Advisor", label: "Advisor" },
];
export const priority = [
  { value: 1, label: 1 },
  { value: 2, label: 2 },
  { value: 3, label: 3 },
  { value: 4, label: 4 },
  { value: 5, label: 5 },
];
