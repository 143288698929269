import React, { useRef, useEffect, useState } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import InnerLayout from "../../../../components/layouts/innerLayout/innerLayout";
import InnerContainer from "../../../../components/layouts/innerContainer/innerContainer";
import SelectFields from "../../../../components/form/selectFields/selectFields";
import { useNavigate } from "react-router-dom";
import SaveBtn from "../../../../components/buttons/saveBtn";
import DeleteBtn from "../../../../components/buttons/deleteBtn";
import InputField from "../../../../components/form/inputFields/inputField";
import ErrorMsg from "../../../../components/form/errorMsg";
import { createFaq, faqOptions, FaqOptions } from "../../../../redux/actions/faqAction";
import { useDispatch } from "react-redux";
import successToast from "../../../../components/utilits/successToast";
import errorToast from "../../../../components/utilits/errorToast";
import ReactQuill from "react-quill";

const FaqCreate = () => {
  const navigate = useNavigate();
  const formRef = useRef();
  const dispatch = useDispatch()
  const [opportunityType, setOpportunityType] = useState([]);
  useEffect(() => {
    dispatch(faqOptions(onNotifyMeSuccess, onNotifyMeError));
  }, []);
  const opportunityTypeOptions = [];
  const onNotifyMeSuccess = (data) => {
    data?.data.map((item, index) => {
      opportunityTypeOptions[index] = { value: item.id, label: item.name };
    });
    console.log(opportunityTypeOptions);
    setOpportunityType(opportunityTypeOptions);
    console.log("SUCCESS");
  };
  const onNotifyMeError = (data) => {
    console.log("ERROR");
  };

  const initialValues = {
    faqCategory: "",
    Question: "",
    Answer: "",
  };

  const interestEditSchema = Yup.object().shape({
    faqCategory: Yup.string().trim().required("Required"),
    Question: Yup.string().trim().required("Required"),
    Answer: Yup.string().trim().required("Required"),
  });

  const handleSubmit = () => {
    if (formRef.current) {
      formRef.current.handleSubmit();
    }
  };
  const onSubmit = (values, { resetForm }) => {
    console.log(values);
    dispatch(
      createFaq(
        {
          faq_category_id: values.faqCategory,
          question: values.Question,
          answer: values.Answer
        },
        onSuccess,
        onError
      )
    );
  };


  const onSuccess = (data) => {
    successToast(data.message);
    navigate(`/static-pages/faqs/edit/${data?.data?.id}`)
  };

  const onError = (data) => {
    errorToast(data.data);
  };

  const buttonList = [<SaveBtn submitFn={handleSubmit} />];
  return (
    <InnerLayout buttons={buttonList}>
      <div className="user_view_container">
        <InnerContainer>
          <div className="row mt-2">
            <div className="col-12 col-sm-12 col-md-9 col-lg-9 col-xl-7">
              <div className="row ps-1 ms-1 ps-lg-4 ms-lg-1">
                <div className="ps-2  pt-3">
                  <h5 className="dark_grey_color text-nowrap fs_13 fn_Montserrat fw_500">
                    FAQ ID
                  </h5>
                  <p
                    className={` fs_13 fn_Nunito text-nowrap fw_500 secondary_color`}
                  >
                    sdaksdj32847
                  </p>
                  <Formik
                    innerRef={formRef}
                    initialValues={initialValues}
                    onSubmit={onSubmit}
                    enableReinitialize={true}
                    validationSchema={interestEditSchema}
                  >
                    {({ values, setFieldTouched, setFieldValue, touched, errors }) => (

                      <Form className="">
                        <div className="   express-form-width">
                          <SelectFields
                            label={"FAQ Category"}
                            placeholder={"Category Name"}
                            name={"faqCategory"}
                            options={opportunityType}
                            id={"FAQ Category"}
                          />
                          <InputField
                            id={"Question"}
                            label={"Question"}
                            type={"text"}
                            placeholder={"Enter Question here"}
                            name={"Question"}
                          />
                          <label
                            id="answer"
                            className="mb-2 dark_grey_color fn_Montserrat fw_500 fs_14 ps-2"
                          >
                            Answer
                          </label>
                          <div
                            style={{ height: "300px" }}
                            className="oppor_content_edit__border overflow-auto rounded-2 pt-3 mb-0"
                          >
                            <ReactQuill
                              theme={"snow"}
                              name={"Answer"}
                              id={"answer"}
                              value={values.Answer}
                              onChange={(event) => setFieldValue("Answer", event)}
                              onBlur={() => setFieldTouched("Answer", true)}
                              valid={!errors.Answer}
                              invalid={touched.Answer && !!errors.Answer}
                              autoFocus={true}
                            /></div>
                          {/* <Field name={"Answer"}>
                              {({ field }) => (
                                <textarea
                                  id="answer"
                                  {...field}
                                  type="text"
                                  className={
                                    "w-100 opportunities_create_summary_text_area p-2 "
                                  }
                                ></textarea>
                              )}
                            </Field> */}
                          <ErrorMsg name={"Answer"} />
                        </div>
                      </Form>

                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </InnerContainer>
      </div>
    </InnerLayout>
  );
};

export default FaqCreate;
export const faqName = [
  { value: "Type1", label: "Type1" },
  { value: "Type2", label: "Type2" },
  { value: "Type3", label: "Type3" },
  { value: "Type4", label: "Type4" },
];
