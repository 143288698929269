import React, { useEffect, useRef, useState } from "react";
import "../our-team.css";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import InputField from "../../../../components/form/inputFields/inputField";
import SelectFields from "../../../../components/form/selectFields/selectFields";
import SaveBtn from "../../../../components/buttons/saveBtn";
import InnerLayout from "../../../../components/layouts/innerLayout/innerLayout";
import InnerContainer from "../../../../components/layouts/innerContainer/innerContainer";
import { useDispatch } from "react-redux";
import errorToast from "../../../../components/utilits/errorToast";
import { useNavigate } from "react-router-dom";
import successToast from "../../../../components/utilits/successToast";
import UploadIcon from "../../../../assets/icons/upload";
import CloseIcon from "../../../../assets/icons/closeIcon";
import ErrorMsg from "../../../../components/form/errorMsg";
import { createOutTeam } from "../../../../redux/actions/ourTeamAction";
import { uploadAddressProofFrontImageAction, uploadProfileImageAction } from "../../../../redux/actions/userAction";

const OurTeamCreate = () => {
  const formRef = useRef();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const initialValues = {
    profileImage: "",
    name: "",
    designation: "",
    linkedIn: "",
    priority: "",
    description: "",
  };
  const loginSchema = Yup.object().shape({
    name: Yup.string("").required("Required"),
    designation: Yup.string().trim().required("Required"),
    linkedIn: Yup.string().trim().required("Required"),
    priority: Yup.number().positive("Priority should be greater than  zero").required("Required"),
    description: Yup.string().trim().required("Required"),
  });
  const handleSubmit = () => {
    if (!deskImageLink.img) {
      if (!deskImageLink.img) {
        setDeskImageLink({ img: "", error: true });
      }

    }
    if (formRef.current) {
      formRef.current.handleSubmit();
    }
  };
  const onSubmit = (values, { resetForm }) => {
    if (deskImageLink.img) {
      dispatch(
        createOutTeam(
          {
            name: values.name,
            linkedin_url: values.linkedIn,
            description: values.description,
            designation: values.designation,
            photo_url: deskImageLink.img,
            priority: values.priority
          },
          onSuccess,
          onError
        )
      );
    }
    console.log(values);
  };

  const onSuccess = (data) => {
    console.log(data.data.id);
    successToast(data?.data?.message);
    navigate(`/static-pages/our-team/edit/${data?.data?.id}`);
  };

  const onError = (data) => {
    errorToast(data.message);
    console.log("fdskfdshgds");
  };
  // image
  const [deskImageLink, setDeskImageLink] = useState({
    img: "",
    error: false,
  });
  const imageUpload = (e) => {
    if (e.target.name === "deskImage") {
      dispatch(
        uploadAddressProofFrontImageAction(
          e.target.files[0],
          onDeskImageUploadSucess,
          onDeskImageUploadError
        )
      );
    }
    e.target.value = null;
  };
  const onDeskImageUploadSucess = (data) => {
    setDeskImageLink({ img: data.data.data.location, error: false });
  };
  const onDeskImageUploadError = (data) => {
    setDeskImageLink({ img: "", error: true });
    console.log(data);
  };

  console.log(deskImageLink?.img);

  const buttonList = [<SaveBtn submitFn={handleSubmit} />];
  return (
    <>
      <InnerLayout buttons={buttonList}>
        <div className="admin_user_container">
          <InnerContainer>
            <div className="pt-3 mb-5 ps-2 ps-md-2 ps-lg-4 ">
              <Formik
                innerRef={formRef}
                initialValues={initialValues}
                onSubmit={onSubmit}
                validationSchema={loginSchema}
              >
                {({ }) => (
                  <Form className="our-team-form-width">
                    <div className="row">
                      <div className="col-12 col-md-6">
                        <div className="mb-3">
                          <span className="mb-2 dark_grey_color fn_Montserrat fw_500 fs_14 ps-2">Profile picture</span>
                          <div className="blog_create_image_container mt-1 position-relative d-flex  justify-content-center align-items-center">
                            <input
                              multiple={false}
                              style={{ visibility: "hidden" }}
                              type="file"
                              id="deskImage"
                              className="upload_document_input"
                              name="deskImage"
                              accept="image/* ,capture=camera"
                              onChange={(e) => {
                                imageUpload(e);
                              }}
                            />
                            {deskImageLink.img ? (
                              <>
                                <img
                                  src={deskImageLink.img}
                                  className={"user-edit-profile-picture"}
                                />
                                <button
                                  type="button"
                                  onClick={(e) => {
                                    setDeskImageLink({ img: "", error: true });
                                  }}
                                  className="position-absolute border-0 cursor_pointer white_bg d-flex gap-2 py-1 px-2 user_edit_profile_picture_remove_container"
                                >
                                  <CloseIcon fill="#666666" />
                                  <span className="dark_grey_color fs_13 fn_Montserrat fw_500 ">
                                    Remove
                                  </span>
                                </button>
                              </>
                            ) : (
                              <label
                                htmlFor="deskImage"
                                className="d-flex flex-column align-items-center mt-2 justify-content-center fs_12"
                              >
                                <UploadIcon width={30} height={30} />
                                Choose Profile Picture
                              </label>
                            )}
                          </div>
                          {deskImageLink.error && (
                            <span className="fs_12 ms-2 text-danger fw-semibold fn_Nunito">
                              Required!
                            </span>
                          )}
                        </div>
                        <InputField
                          id={"name"}
                          label={"Name"}
                          type={"text"}
                          placeholder={"Enter Name"}
                          name={"name"}
                        />

                        <InputField
                          id={"designation"}
                          label={"Designation"}
                          type={"text"}
                          placeholder={"Select designation "}
                          name={"designation"}
                        />

                        <InputField
                          id={"linkedIn"}
                          label={"LinkedIn URL"}
                          type={"text"}
                          placeholder={"Enter LinkedIn URL"}
                          name={"linkedIn"}
                        />
                      </div>
                      <div className="col-12 col-md-6">
                        <div className="ps-0 ps-lg-4">
                          {/* <SelectFields
                            label={"Prirority"}
                            placeholder={"Select prirority"}
                            name={"pripority"}
                            options={selectPrirority}
                            id={"pripority"}
                          /> */}

                          <InputField
                            id={"priority"}
                            label={"priority"}
                            type={"text"}
                            placeholder={"Select priority"}
                            name={"priority"}
                          />
                          <label
                            id="description"
                            className="mb-2 dark_grey_color fn_Montserrat fw_500 fs_14 ps-2"
                          >
                            Description
                          </label>
                          <Field name={"description"}>
                            {({ field }) => (
                              <textarea
                                id="description"
                                {...field}
                                type="text"
                                className={
                                  "w-100 opportunities_create_summary_text_area p-2 "
                                }
                              ></textarea>
                            )}
                          </Field>

                          <ErrorMsg name={"description"} />
                        </div>
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </InnerContainer>
        </div>
      </InnerLayout>
    </>
  );
};

export default OurTeamCreate;

export const selectDesignation = [
  { value: "Founder", label: "Founder" },
  { value: "Co Founder", label: "Co Founder" },
  { value: "Cheif of staff", label: "Cheif of staff" },
  { value: "Cheif Growth officer", label: "Cheif Growth officer" },
];

export const selectPrirority = [
  { value: "1", label: "1" },
  { value: "2", label: "2" },
  { value: "3", label: "3" },
  { value: "4", label: "4" },
  { value: "5", label: "5" },
];
