import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { FaqApprovalList } from "../../../../redux/actions/faqAction";
import { TableContainer } from "../../../table/tableContainer";
import {
  tableFaqsApprovalsHeader,
  tableFaqsHeader,
} from "../../../table/tableData";
import TablePagination from "../../../table/tablePagination";
import errorToast from "../../../utilits/errorToast";
import LoadAndError from "../../../utilits/LoadAndError";
import LoadingSpinnerTable from "../../../utilits/loadingSpinnerTable";
const FaqApprovalTableContent = ({
  tableContainerClassName = "adminuser_table__container",
  page,
  search,
  changePage,
  containerWidth = "90%",
  containerMargin = "1.5rem 0rem",
}) => {
  const dispatch = useDispatch();
  const [NotifyMeApprovalsData, setNotifyMeApprovalsData] = useState([]);
  const [pageData, setPageData] = useState({ noOfItems: 0, noOfPages: 1 });
  console.log(search);
  useEffect(() => {
    setLoading(true);
    dispatch(
      FaqApprovalList(
        {
          search: {
            faq_id: search?.value?.faqId,
            faq_name: search?.value?.name,
            question: search?.value?.question,
          },
        },
        page,
        onSuccess,
        onError
      )
    );
  }, [page, search]);
  const onSuccess = (data) => {
    setNotifyMeApprovalsData(data.data);
    setPageData({
      noOfItems: data.count,
      noOfPages: data.pages,
    });
    setLoading(false);
    setError(false);
  };
  const onError = (data) => {
    errorToast(data.message);
    setLoading(false);
    setError(false);
  };
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  function handlePagination(type) {
    if (type === "+") {
      if (page + 1 < pageData.noOfPages) changePage("+");
    } else if (type === "-") if (page > 0) changePage("-");
  }
  console.log(NotifyMeApprovalsData);
  return (
    <TableContainer width={containerWidth} margin={containerMargin}>
      <div className={`${tableContainerClassName}`}>
        {loading && <LoadingSpinnerTable />}
        <table className={"adminuser_table"}>
          <thead className="adminuser_table__header">
            <tr className="adminuser_table__row">
              {tableFaqsApprovalsHeader.map((entry) => {
                return (
                  <th
                    key={entry}
                    className={`${
                      entry === "Status" ? "position-sticky end-0 " : null
                    } text-nowrap adminuser_table__heading`}
                  >
                    {entry}
                  </th>
                );
              })}
            </tr>
          </thead>
          {loading == false && (
            <tbody className="adminuser_table__body">
              <LoadAndError
                loader={loading}
                error={error}
                status={NotifyMeApprovalsData.length === 0}
              >
                {NotifyMeApprovalsData.map((item, index) => {
                  return (
                    <tr key={item.id} className="adminuser_table__row">
                      <td className="text-nowrap dark_title_color adminuser_table__data ">
                        {page > 0 ? 12 * page + (index + 1) : index + 1}
                      </td>

                      <td className="text-nowrap dark_title_color adminuser_table__data">
                        {item?.id}
                      </td>
                      <td className="text-nowrap secondary_color adminuser_table__data">
                        {item?.faq_static_id == null
                          ? "--"
                          : item?.faq_static_id}
                      </td>
                      <td className="text-nowrap secondary_color adminuser_table__data">
                        {item.new_data == null
                          ? item.old_data?.faq_category_name
                          : item.new_data?.faq_category_name}
                      </td>
                      <td
                        title={item.new_data?.question}
                        className="text-nowrap adminuser_table_ellipsis  dark_title_color adminuser_table__data"
                      >
                        {item.new_data == null
                          ? item.old_data?.question
                          : item.new_data?.question}
                      </td>
                      <td
                        title={item.new_data?.answer}
                        className="text-nowrap adminuser_table_ellipsis  dark_title_color adminuser_table__data"
                      >
                        {item.new_data == null
                          ? item.old_data?.answer
                          : item.new_data?.answer}
                      </td>
                      <td className="text-nowrap secondary_color  adminuser_table__data">
                        {item.approved_date == null ? "--" : item.approved_date}
                      </td>
                      <td className="text-nowrap  dark_title_color adminuser_table__data">
                        {item.updatedAt}
                      </td>

                      <td className="text-nowrap  position-sticky end-0 adminuser_table__data">
                        <div className="d-flex w-100   gap-2">
                          <Link
                            className="text-decoration-none primary_color"
                            to={{
                              pathname: `/static-pages/faqs/approvals/view/${item.id}`,
                            }}
                          >
                            View
                          </Link>
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </LoadAndError>
            </tbody>
          )}
        </table>
      </div>

      <TablePagination
        paginate={handlePagination}
        currentPage={page}
        pageData={pageData}
      />
    </TableContainer>
  );
};

export default FaqApprovalTableContent;
