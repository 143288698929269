import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import CloseIcon from "../../../../assets/icons/closeIcon";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import "../modals/llpModal.css";
import InputField from "../../../form/inputFields/inputField";
import ErrorMsg from "../../../form/errorMsg";
import ArrowDownIcon from "../../../../assets/icons/arrowdown";
import SelectMultiFields from "../../../form/selectFields/selectMultiFields";
import { uploadAddressProofFrontImageAction } from "../../../../redux/actions/userAction";
import { useDispatch } from "react-redux";
import AdminRoleCheckBox from "../../../utilits/adminRoleCheckbox";
import { notifyMeCreateOptions } from "../../../../redux/actions/notifyMeActions";
import { useParams } from "react-router-dom";
import {
  llpDocCreateAction,
  llpDocEditAction,
} from "../../../../redux/actions/llpActions";
import successToast from "../../../utilits/successToast";
import errorToast from "../../../utilits/errorToast";

const DocumentModal = ({ data, updated, show, closeModal }) => {
  const params = useParams();
  const [file, setFile] = useState(
    data?.data?.file_src ? data?.data?.file_src : ""
  );
  const [selectedOptions, setSelectedOptions] = useState([]);

  const [fileUrl, setFileUrl] = useState({
    url: data?.data?.file_src ? data?.data?.file_src : "",
    error: false,
  });
  const [defaultUsersList, setDefaultUsersList] = useState([]);
  const dispatch = useDispatch();
  const [userList, setUserList] = useState([]);
  const initialValues = {
    Name: data?.data?.name ? data?.data?.name : "",
    Description: data?.data?.description ? data?.data?.description : "",
    visibility: data?.data?.visiblity ? data?.data?.visiblity : "public",
    downloadable: data?.data?.downloadable ? data?.data?.downloadable : false,
  };

  useEffect(() => {
    dispatch(notifyMeCreateOptions(onSuccessDrop, onErrorDrop));
    setFile(data?.data?.file_src ? data?.data?.file_src : "");
    setFileUrl({
      url: data?.data?.file_src ? data?.data?.file_src : "",
      error: false,
    });
  }, [show]);
  const onSuccessDrop = (dropData) => {
    const categoryList = [];
    dropData?.data?.AllUsers?.map((item, index) => {
      categoryList[index] = { value: item.id, label: item.user_name };
    });
    setUserList(categoryList);
    const defaultListItems = dropData?.data?.AllUsers?.filter((item) =>
      data?.data?.visiblity_users?.includes(item.id)
    ).map((item) => {
      return { label: item.user_name, value: item.id };
    });
    setDefaultUsersList(defaultListItems);
    setSelectedOptions(defaultListItems?.map((item) => item.id));
  };
  const onErrorDrop = (data) => {
    console.log(data);
  };

  const loginSchema = Yup.object().shape({
    Name: Yup.string().trim().required("Required"),
    Description: Yup.string().trim().required("Required"),
    // SelectUsers: Yup.array().when("visibility", {
    //   is: "user_oriented",
    //   then: Yup.array().min(1, "SELECT ATLEAST ONE USER").required("Required"),
    // }),
  });

  const onSubmit = (values) => {
    if (fileUrl.url) {
      if (data.type === "edit") {
        dispatch(
          llpDocEditAction(
            data?.data?.id,
            {
              llp_id: params.id,
              name: values.Name,
              description: values.Description,
              file_src: fileUrl.url,
              visiblity: values.visibility,
              visiblity_users:
                values.visibility === "public" ? [] : selectedOptions,
              downloadable: values.downloadable,
            },
            onSuccess,
            onError
          )
        );
      } else
        dispatch(
          llpDocCreateAction(
            {
              llp_id: params.id,
              name: values.Name,
              description: values.Description,
              file_src: fileUrl.url,
              visiblity: values.visibility,
              visiblity_users:
                values.visibility === "public" ? [] : selectedOptions,
              downloadable: values.downloadable,
            },
            onSuccess,
            onError
          )
        );
    } else {
      setFileUrl({ url: "", error: true });
    }
  };
  const onSuccess = (data) => {
    successToast(data.data.message);
    updated();
    closeModal();
  };
  const onError = (data) => {
    errorToast(data.data.data + " " + data.data.message);
  };

  function handleUploadFile(e) {
    if (e.target?.files.length !== 0) {
      setFile(e.target.files[0]);
      dispatch(
        uploadAddressProofFrontImageAction(
          e.target.files[0],
          onUploadFileSuccess,

          onUploadFileError
        )
      );
    }
    e.target.value = null;
  }
  const onUploadFileSuccess = (data) => {
    setFileUrl({ url: data.data.data.location, error: false });
    // console.log(data.data.data);
  };
  const onUploadFileError = (data) => {
    setFileUrl({ url: "", error: true });
    console.log(data);
  };

  const handleSelectedOptions = (value) => {
    setSelectedOptions(value.map((item) => item.value));
  };

  return (
    <>
      <Modal
        centered
        show={show}
        onHide={closeModal}
        dialogClassName={`oppoptunities_modals_body mx-auto `}
        contentClassName={"oppoptunities_modals_body_content  p-2"}
      >
        <Modal.Body>
          <>
            <div className="d-flex justify-content-between  ">
              <h2 className="green_color fs_15 fn_Montserrat fw_600 pt-1">
                DOCUMENT NAME
              </h2>
              <button
                onClick={closeModal}
                className="light_grey_color  fs_15 fn_Montserrat bg-transparent border-0 fw_600 "
              >
                <span className="pt-1">DISMISS</span>
                <span className="ms-2">
                  <CloseIcon width={13} height={13} fill="#A5A5A5" />
                </span>
              </button>
            </div>
            <Formik
              initialValues={initialValues}
              onSubmit={onSubmit}
              validationSchema={loginSchema}
              enableReinitialize={true}
            >
              {({ formik, handleChange, values, setFieldValue }) => {
                return (
                  <Form className="mt-3">
                    <InputField
                      id={"Name"}
                      label={"Document Name"}
                      type={"text"}
                      placeholder={"Enter Title"}
                      name={"Name"}
                    />

                    <label
                      id="Description"
                      className="mb-2 dark_grey_color fn_Nunito mt-2 fw_500 fs_15 ps-1 ms-1"
                    >
                      Description
                    </label>
                    <Field name={"Description"}>
                      {({ field }) => (
                        <textarea
                          disabled={data.type === "view"}
                          id="Description"
                          {...field}
                          placeholder={"Enter Description"}
                          type="text"
                          rows="2"
                          className="w-100 llp_message_container p-2 "
                        ></textarea>
                      )}
                    </Field>
                    <ErrorMsg name={"Description"} />
                    <div>
                      <span className="dark_grey_color fn_Montserrat fw_500 fs_14  ps-2">
                        Upload
                      </span>
                      <div className="llp_document_upload d-flex justify-content-between align-items-center pe-3">
                        <input
                          className="position-absolute"
                          type={"file"}
                          style={{ visibility: "hidden" }}
                          id="file"
                          name="fileUrl"
                          onChange={(e) => {
                            handleUploadFile(e);
                          }}
                        />
                        <label className=" w-75 py-2 ps-3  " htmlFor="file">
                          {
                            <span className="word_wrap">
                              {fileUrl.url ? fileUrl.url : "Click to upload"}
                            </span>
                          }
                        </label>
                        {fileUrl.url && data.type !== "view" && (
                          <span
                            onClick={() => {
                              setFileUrl({ url: "", error: true });
                              setFile("");
                            }}
                          >
                            <CloseIcon width={10} height={10} fill="#A5A5A5" />
                          </span>
                        )}
                      </div>
                      {fileUrl.error && (
                        <span className="fs_12 ms-2 text-danger fw-semibold fn_Nunito">
                          Required!
                        </span>
                      )}
                    </div>

                    <div>
                      <h5 className="fs_16 ms-2 mt-3 fw_400 dark_grey_color">
                        Visibility
                      </h5>

                      <div className="d-flex align-items-center gap-2 ms-3 mb-2">
                        <label>
                          <Field
                            disabled={data.type === "view"}
                            className={"llp_document_radio"}
                            type="radio"
                            name="visibility"
                            value="public"
                          />
                          <span className="ms-2">Public</span>
                        </label>
                        <label className="">
                          <Field
                            disabled={data.type === "view"}
                            className={"llp_document_radio"}
                            type="radio"
                            name="visibility"
                            value="user_oriented"
                          />
                          <span className="ms-2">User Oriented</span>
                        </label>
                      </div>
                    </div>
                    {values.visibility === "user_oriented" && (
                      <SelectMultiFields
                        disabled={data.type === "view"}
                        updateSelected={handleSelectedOptions}
                        selectedOptions={selectedOptions}
                        Icon={
                          <ArrowDownIcon
                            width={20}
                            height={20}
                            fill={"#A5A5A5"}
                          />
                        }
                        default1={defaultUsersList}
                        label={"Select Users"}
                        placeholder={"Select Users"}
                        name={"SelectUsers"}
                        options={userList}
                      />
                    )}
                    <div className="d-flex align-items-center ms-2">
                      <AdminRoleCheckBox
                        disabled={data.type === "view"}
                        name="downloadable"
                      />
                      <span className="mb-1">Downloadable?</span>
                    </div>

                    <div className="d-flex justify-content-end mt-4">
                      {data.type !== "view" && (
                        <button
                          type="submit"
                          className="secondary_color fs_15  fn_Montserrat fw_600 border-0 tertiary_bg px-4 px-lg-5 py-2 py-lg-2 rounded-2"
                        >
                          {data.type === "edit" ? "UPDATE" : "CREATE"}
                        </button>
                      )}
                    </div>
                  </Form>
                );
              }}
            </Formik>
          </>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default DocumentModal;
