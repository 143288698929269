import React, { useState } from "react";
import { Data } from "./data";
import styles from "./opportunitiesread.module.css";
import minus from "../../../../assets/icons/minus.svg"
import AddIcon from "../../../../assets/icons/addIcon";
import 'react-quill/dist/quill.snow.css';
const Faqs = ({ data = [] }) => {
    const [clicked, setClicked] = useState(0);

    const toggle = (index) => {
        if (clicked === index) {
            return setClicked(null);
        }

        setClicked(index);
    };
    return (
        <>
            <div
                className="d-lg-flex ms-lg-5 mt-lg-3 mt-3 justify-content-center"
                id="faqs"
            >
                <div className="col-lg-3 me-lg-5">
                    <h3 className="mb-4 fn_Montserrat fw_600 secondary_color ">
                        FAQs
                    </h3>
                </div>
                <div className="col-lg-6  col-12 ms-lg-5 ">
                    <div className="ms-lg-3">
                        <div className={`${styles.accordion_section}`}>
                            {data.map((item, index) => {

                                return (
                                    <div className={`${styles.accordian_container} w-100 mb-4`}>
                                        <div className={`${styles.accordion_list}   p-2 `}>
                                            <div
                                                className={`${styles.wrap}`}
                                                onClick={() => toggle(index)}
                                                key={index}
                                            >
                                                <span>
                                                    {clicked === index ? <img src={minus} /> : <AddIcon fill="#005E54" width={16} height={16} />}
                                                </span>
                                                <h1 className="fs_20 px-2 secondary_color">

                                                    {item.question}

                                                </h1>
                                            </div>
                                            {clicked === index ? (
                                                <div dangerouslySetInnerHTML=
                                                    {{
                                                        __html: item?.answer.replace(
                                                            /<p>/g,
                                                            `<p class="mb-0">`
                                                        ).replace(
                                                            /<ul>/g,
                                                            `<ul class="mb-0">`
                                                        ).replace(
                                                            /<li>/g,
                                                            `<li class="mb-0">`
                                                        ).replace(
                                                            /<h6>/g,
                                                            `<h6 class="mb-0">`
                                                        ),
                                                    }} className={`${styles.drop_down}`}>

                                                </div>
                                            ) : null}
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Faqs;
