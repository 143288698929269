import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

const usePermissionChecker = (type = "checker") => {
  const location = useLocation();
  const [access, setAccess] = useState(false);
  useEffect(() => {
    const permissions = localStorage.getItem("permissions") && JSON.parse(localStorage.getItem("permissions"));
    if (
      location.pathname.startsWith("/admin-users") &&
      permissions?.admin &&
      permissions?.admin[type]
    )
      return setAccess(true);
    if (permissions?.user && permissions?.user[type] && location.pathname.startsWith("/user"))
      return setAccess(true);
    if (permissions?.opportunity &&
      permissions?.opportunity[type] &&
      location.pathname.startsWith("/opportunity")
    )
      return setAccess(true);
    if (permissions?.static_homepage &&
      permissions?.static_homepage[type] &&
      location.pathname.startsWith("/static-pages")
    )
      return setAccess(true);
    if (permissions?.role && permissions?.role[type] && location.pathname.startsWith("/admin-roles"))
      return setAccess(true);
    if (permissions?.static_homepage && permissions?.static_homepage[type] && location.pathname.startsWith("/blog"))
      return setAccess(true);
    else setAccess(false);
    return () => setAccess(false);
  }, [location, type]);
  return access;
};

export default usePermissionChecker;
