import { LLP_APPROVALS_EXPORT, LLP_APPROVALS_LIST, LLP_APPROVALS_VIEW, LLP_APPROVE, LLP_CREATE, LLP_DELETE, LLP_DOC_CREATE, LLP_DOC_DELETE, LLP_DOC_EDIT, LLP_EDIT, LLP_EXPORT, LLP_IMPORT_USERS, LLP_LIST, LLP_MEMBER_CREATE, LLP_MEMBER_DELETE, LLP_MEMBER_EDIT, LLP_READ, LLP_REJECT, LLP_SUBMIT, LLP_VERSION_EXPORT, LLP_VERSION_LIST, LLP_VERSION_VIEW } from "./types";




export const viewLLPListAction = (
    data,
    pageNo,
    onSuccess,
    onError) => {
    return {
        type: LLP_LIST,
        data,
        pageNo,
        onSuccess,
        onError,
    };
};
export const viewLLPAction = (id, onSuccess, onError) => {
    return {
        type: LLP_READ,
        id,
        onSuccess,
        onError,
    };
};
export const addLLPAction = (data, onSuccess, onError) => {
    return {
        type: LLP_CREATE,
        data,
        onSuccess,
        onError,
    };
};
export const editLLPAction = (id, data, onSuccess, onError) => {
    return {
        type: LLP_EDIT,
        id,
        data,
        onSuccess,
        onError,
    };
};
export const deleteLLPAction = (id, onSuccess, onError) => {
    return {
        type: LLP_DELETE,
        id,
        onSuccess,
        onError,
    };
};
export const approveLLPAction = (llpId, onSuccess, onError) => {
    return {
        type: LLP_APPROVE,
        llpId,
        onSuccess,
        onError,
    };
};
export const rejectLLPAction = (id, onSuccess, onError) => {
    return {
        type: LLP_REJECT,
        id,
        onSuccess,
        onError,
    };
};
export const approvalsLLPListAction = (data,
    page, onSuccess, onError) => {
    return {
        type: LLP_APPROVALS_LIST,
        data,
        page,
        onSuccess,
        onError,
    };
};
export const approvalsViewLLPAction = (id, onSuccess, onError) => {
    return {
        type: LLP_APPROVALS_VIEW,
        id,
        onSuccess,
        onError,
    };
};
export const versionListLLPAction = (
    data,
    page_no,
    onSuccess,
    onError) => {
    return {
        type: LLP_VERSION_LIST,
        data,
        page_no,
        onSuccess,
        onError,
    };
};
export const versionViewLLPAction = (id, onSuccess, onError) => {
    return {
        type: LLP_VERSION_VIEW,
        id,
        onSuccess,
        onError,
    };
};



///MEMBER DETAILS

export const llpMemberDeleteAction = (id, onSuccess, onError) => {
    return {
        type: LLP_MEMBER_DELETE,
        id,
        onSuccess,
        onError,
    };
};
export const llpMemberEditAction = (id, data, onSuccess, onError) => {
    return {
        type: LLP_MEMBER_EDIT,
        id,
        data,
        onSuccess,
        onError,
    };
};
export const llpMemberAddAction = (data, onSuccess, onError) => {
    return {
        type: LLP_MEMBER_CREATE,
        data,
        onSuccess,
        onError,
    };
};
///DOCUMENT

export const llpDocDeleteAction = (id, onSuccess, onError) => {
    return {
        type: LLP_DOC_DELETE,
        id,
        onSuccess,
        onError,
    };
};
export const llpDocEditAction = (id, data, onSuccess, onError) => {
    return {
        type: LLP_DOC_EDIT,
        id,
        data,
        onSuccess,
        onError,
    };
};
export const llpDocCreateAction = (data, onSuccess, onError) => {
    return {
        type: LLP_DOC_CREATE,
        data,
        onSuccess,
        onError,
    };
};




export const llpTableExport = (onSuccess, onError) => {
    return {
        type: LLP_EXPORT,
        onSuccess,
        onError,
    };
};
export const llpApprovalsExport = (onSuccess, onError) => {
    return {
        type: LLP_APPROVALS_EXPORT,
        onSuccess,
        onError,
    };
};
export const llpVersionExport = (onSuccess, onError) => {
    return {
        type: LLP_VERSION_EXPORT,
        onSuccess,
        onError,
    };
};


export const llpSubmit = (id, onSuccess, onError) => {
    return {
        type: LLP_SUBMIT,
        id,
        onSuccess,
        onError,
    };
};
export const llpImportUsersAction = (id, onSuccess, onError) => {
    return {
        type: LLP_IMPORT_USERS,
        id,
        onSuccess,
        onError,
    };
};  