import { authHeader } from "../config";
import { api } from "./api";

export const createBlogCatgoryApi = (params) => {
  return api.post("/api/admin/blog-category/create-blog-category", params, {
    headers: {
      "x-access-token": localStorage.getItem("accessToken"),
      "Content-Type": "application/json",
    },
  });
};

export const approveBlogCatgoryApi = (params) => {
  return api.post(
    "/api/admin/blog-category/approve-blog-category",
    JSON.stringify(params),
    {
      headers: {
        "x-access-token": localStorage.getItem("accessToken"),
        "Content-Type": "application/json",
      },
    }
  );
};

export const rejectBlogCatgoryApi = (id, params) => {
  return api.post(
    `/api/admin/blog-category/reject-blog-category/${id}`,
    JSON.stringify(params),
    {
      headers: {
        "x-access-token": localStorage.getItem("accessToken"),
        "Content-Type": "application/json",
      },
    }
  );
};

export const viewBlogCatgoryDetailApi = (id, params) => {
  return api.post(
    `/api/admin/blog-category/find-one-blog-category/${id}`,
    JSON.stringify(params),
    {
      headers: {
        "x-access-token": localStorage.getItem("accessToken"),
        "Content-Type": "application/json",
      },
    }
  );
};

export const viewBlogCatgoryListApi = (page = 0, params) => {
  return api.post(
    `/api/admin/blog-category/find-all-blog-category?page_no=${page}`,
    JSON.stringify(params),
    {
      headers: {
        "x-access-token": localStorage.getItem("accessToken"),
        "Content-Type": "application/json",
      },
    }
  );
};

export const editBlogCatgoryApi = (id, params) => {
  return api.post(
    `/api/admin/blog-category/edit-blog-category/${id}`,
    JSON.stringify(params),
    {
      headers: {
        "x-access-token": localStorage.getItem("accessToken"),
        "Content-Type": "application/json",
      },
    }
  );
};

export const deleteBlogCatgoryApi = (id, params) => {
  return api.post(
    `/api/admin/blog-category/delete-blog-category/${id}`,
    JSON.stringify(params),
    {
      headers: {
        "x-access-token": localStorage.getItem("accessToken"),
        "Content-Type": "application/json",
      },
    }
  );
};

export const blogCategorySubmitApi = (id, params) => {
  return api.post(`/api/admin/blog-category/on-submit/${id}`, params, {
    headers: {
      "x-access-token": localStorage.getItem("accessToken"),
      "Content-Type": "application/json",
    },
  });
};

export const blogCatgoryApprovalListApi = (page = 0, params) => {
  return api.post(
    `/api/admin/blog-category/find-all-checkker-blog-category?page_no=${page}`,
    JSON.stringify(params),
    {
      headers: {
        "x-access-token": localStorage.getItem("accessToken"),
        "Content-Type": "application/json",
      },
    }
  );
};

export const blogCatgoryApprovalByIdApi = (id, params) => {
  return api.post(
    `/api/admin/blog-category/find-one-checkker-blog-category/${id}`,
    JSON.stringify(params),
    {
      headers: {
        "x-access-token": localStorage.getItem("accessToken"),
        "Content-Type": "application/json",
      },
    }
  );
};

export const versionHistoryBlogCatgoryListApi = (pageNo = 0, params) => {
  return api.post(
    `/api/admin/blog-category/views-blog-category-versions?page_no=${pageNo}`,
    JSON.stringify(params),

    {
      headers: {
        "x-access-token": localStorage.getItem("accessToken"),
        "Content-Type": "application/json",
      },
    }
  );
};

export const versionHistoryBlogCatgoryDetailApi = (id, params) => {
  return api.post(
    `/api/admin/blog-category/view-blog-category-version/${id}`,
    JSON.stringify(params),
    {
      headers: {
        "x-access-token": localStorage.getItem("accessToken"),
        "Content-Type": "application/json",
      },
    }
  );
};
export const blogCatgoryOptionsApi = (id, params) => {
  return api.post(`/api/admins/version-admin/${id}`, JSON.stringify(params), {
    headers: {
      "x-access-token": localStorage.getItem("accessToken"),
      "Content-Type": "application/json",
    },
  });
};

export const blogCategoryMainExportApi = (page, params) => {
  return api.post(
    `/api/admin/blog-category/export-main-blog-category`,
    JSON.stringify(params),
    {
      headers: {
        "x-access-token": localStorage.getItem("accessToken"),
        "Content-Type": "application/json",
      },
    }
  );
};

export const blogCategoryApprovalsExportApi = (page, params) => {
  return api.post(
    `/api/admin/blog-category/export-approval-blog-category`,
    JSON.stringify(params),
    {
      headers: {
        "x-access-token": localStorage.getItem("accessToken"),
        "Content-Type": "application/json",
      },
    }
  );
};

export const blogCategoryVersionExportApi = (page, params) => {
  return api.post(
    `/api/admin/blog-category/export-version-blog-category`,
    JSON.stringify(params),
    {
      headers: {
        "x-access-token": localStorage.getItem("accessToken"),
        "Content-Type": "application/json",
      },
    }
  );
};
