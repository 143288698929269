import React, { useState } from "react";
import DownloadIcon from "../../../assets/icons/downloadIcon";
import "../../../modules/user/user-view.css";
import RejectBtn from "../../buttons/rejectBtn";
import { saveAs } from "file-saver";
import RejectPanDescription from "../../modals/rejectPanDescription";
import ApproveBtn from "../../buttons/approveBtn";
import ViewImageModal from "../modals/viewImageModal";
import ApprovalViewBankImageModal from "../modals/approvalsViewModals/bankAccountImageModal";

const BankDetailsApproval = ({ individualApprovalData, bankImage = "" }) => {
  console.log(individualApprovalData);

  const bankAccountDetails = [
    {
      heading: "Bank Account Number",
      title: individualApprovalData?.new_data == null ? individualApprovalData?.old_data?.bank_account_details?.account_number : individualApprovalData?.new_data?.bank_account_details?.account_number,
    },
    {
      heading: "Bank Account Holder Name",
      title: individualApprovalData?.new_data == null ? individualApprovalData?.old_data?.bank_account_details?.bank_holder_name : individualApprovalData?.new_data?.bank_account_details?.bank_holder_name,
    },
    {
      heading: "Account Type",
      title: individualApprovalData?.new_data == null ? individualApprovalData?.old_data?.bank_account_details?.account_type : individualApprovalData?.new_data?.bank_account_details?.account_type,
    },
    {
      heading: "Verified",
      title: individualApprovalData?.new_data == null ? individualApprovalData?.old_data?.bank_account_details?.verified == true ? "Yes" : "No" : individualApprovalData?.new_data?.bank_account_details?.verified == true ? "Yes" : "No",
    },
    {
      heading: "Rejected",
      title: individualApprovalData?.new_data == null ? individualApprovalData?.old_data?.bank_account_details?.rejected == true ? "Yes" : "No" : individualApprovalData?.new_data?.bank_account_details?.rejected == true ? "Yes" : "No",

    },
    {
      heading: "Bank Name",
      title: individualApprovalData?.new_data == null ? individualApprovalData?.old_data?.bank_account_details?.bank_name : individualApprovalData?.new_data?.bank_account_details?.bank_name,

    },
    {
      heading: "Bank IFSC",
      title: individualApprovalData?.new_data == null ? individualApprovalData?.old_data?.bank_account_details?.ifsc_code : individualApprovalData?.new_data?.bank_account_details?.ifsc_code,

    },
    {
      heading: "Proof Type",
      title: individualApprovalData?.new_data == null ? individualApprovalData?.old_data?.bank_account_details?.document_type : individualApprovalData?.new_data?.bank_account_details?.document_type,
    },


  ];

  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);
  const [imageShow, setImageShow] = useState(false);
  const handleImageShow = () => setImageShow(true);
  const handleImageClose = () => setImageShow(false);
  const downloadImage = () => {
    saveAs(
      "https://upload.wikimedia.org/wikipedia/commons/thumb/c/c8/Julia_2022-10-09_0710Z.jpg/183px-Julia_2022-10-09_0710Z.jpg"
    ); // Put your image url here.
  };

  return (
    <>
      <h3 className="dark_grey_color fn_Montserrat ps-4 ps-lg-5 mt-3 py-2 fw_500 white_shade_bg fs_13 ">
        Bank Account Details
      </h3>
      <div className="row mt-3 ps-1 ms-1 ps-lg-4 ms-lg-1">
        <div className="col-lg-12 col-xl-9">
          <div className="row">
            {bankAccountDetails.map((item, index) => (
              <div className="address_pan_bank_data_width mt-2">
                <h5 className="dark_grey_color fs_13 fn_Montserrat fw_500">
                  {item.heading}
                </h5>
                <p className={` fs_13 fn_Nunito fw_500 'dark_title_color'}`}>
                  {item.title}
                </p>
              </div>
            ))}
          </div>
          <div className="d-flex gap-4 gap-lg-5 flex-column flex-lg-row mt-0 mb-4">
            <div>
              <h5 className="dark_grey_color text-nowrap fs_13 fn_Montserrat fw_500">
                Front Image
              </h5>

              <div
                onClick={handleImageShow}
                className="personal_information_image_container mt-2 position-relative"
              >
                <img
                  src={
                    individualApprovalData?.new_data == null ? individualApprovalData?.old_data?.bank_account_details?.image_location : individualApprovalData?.new_data?.bank_account_details?.image_location
                  }
                  className="personal_information_img_size rounded-2"
                />
                <a
                  href={individualApprovalData?.new_data == null ? individualApprovalData?.old_data?.bank_account_details?.image_location : individualApprovalData?.new_data?.bank_account_details?.image_location}
                  download="file"
                >
                  <button
                    // onClick={downloadImage}
                    type="button"
                    className="position-absolute border-0 bg-white d-flex align-items-center fs_10 top-0 end-0"
                  >
                    <DownloadIcon width={8} />
                    <span className="ms-1">Download</span>
                  </button>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-3 col-lg-12">
          <div className="d-flex  gap-5 gap-lg-3 mb-3"></div>
        </div>
        <h5 className="dark_grey_color fs_13 fn_Montserrat fw_500">
        Operation Type
        </h5>
        <p className={` fs_13 fn_Nunito fw_500 'dark_title_color'}`}>
          {individualApprovalData?.new_data == null ? "Delete" : individualApprovalData?.new_data && individualApprovalData?.old_data ? "Edit" : individualApprovalData?.old_data == null ? "Create" : ""}
        </p>
      </div>
      <RejectPanDescription handleClose={handleClose} show={show} />
      <ApprovalViewBankImageModal
        handleClose={handleImageClose}
        show={imageShow}
        bank={individualApprovalData?.new_data}
        image={individualApprovalData?.new_data == null ? individualApprovalData?.old_data?.bank_account_details?.image_location : individualApprovalData?.new_data?.bank_account_details?.image_location}

      />
    </>
  );
};

export default BankDetailsApproval;
