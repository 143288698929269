import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { TableContainer } from "../../../table/tableContainer";
import {
  tableFaqCategoryApprovalHeader,
  tableFaqCategoryData,
  tableFaqCategoryHeader,
} from "../../../table/tableData";
import TablePagination from "../../../table/tablePagination";
import { useDispatch } from "react-redux";
import errorToast from "../../../../components/utilits/errorToast";
import successToast from "../../../../components/utilits/successToast";
import moment from "moment";
import { useParams, useNavigate } from "react-router-dom";
import { CategoryApprovalList } from "../../../../redux/actions/faqCategoryActions";
import LoadingSpinnerTable from "../../../utilits/loadingSpinnerTable";
import LoadAndError from "../../../utilits/LoadAndError";

const FaqCategoryApprovalTableContent = ({
  tableContainerClassName = "adminuser_table__container",
  page, search, changePage,
  containerWidth = "90%",
  containerMargin = "1.5rem 0rem",
}) => {

  const params = useParams()
  const dispatch = useDispatch()
  const navigate = useNavigate();

  const [wishlistData, setWishlistData] = useState([]);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [pageData, setPageData] = useState({ noOfItems: 0, noOfPages: 1 })
  useEffect(() => {
    setLoading(true)
    console.log("the id", params.id);
    dispatch(
      CategoryApprovalList(
        {
          search: {
            faq_category_id: search?.value?.faqCategoryId,
            name: search?.value?.name,
          }
        },
        page,
        onSuccess,
        onError)
    );
  }, [search, page])
console.log(wishlistData);
  const onSuccess = (data) => {
    setWishlistData(data?.data);
    setLoading(false)
    setPageData({
      noOfItems: data.count,
      noOfPages: data.pages
    })
    setLoading(false);
    setError(false);
  };
  const onError = (data) => {
    errorToast(data.data.message)
    //errorToast("went wrong")
    setLoading(false)
    setError(true);
  };

  function handlePagination(type) {
    if (type === "+") {
      if (page + 1 < pageData.noOfPages) changePage("+");
    }
    else if (type === "-")
      if (page > 0) changePage("-");
  }
  return (
    <TableContainer width={containerWidth} margin={containerMargin}>
      <div className={`${tableContainerClassName}`}>
        {loading && <LoadingSpinnerTable />}
        <table className={"adminuser_table"}>
          <thead className="adminuser_table__header">
            <tr className="adminuser_table__row">
              {tableFaqCategoryApprovalHeader.map((item) => {
                return (
                  <th
                    key={item}
                    className={`${item === "Status" ? "position-sticky  end-0" : null
                      } text-nowrap adminuser_table__heading`}
                  >
                    {item}
                  </th>
                );
              })}
            </tr>
          </thead>
          {loading == false && (
            <tbody className="adminuser_table__body">
            <LoadAndError loader={loading} error={error} status={wishlistData.length === 0}>
              {wishlistData.map((item, index) => {
                return (
                  <tr key={item.id} className="adminuser_table__row">
                    <td className="text-nowrap dark_title_color adminuser_table__data ">
                      {page > 0 ? 12 * page + (index + 1) : index + 1}
                    </td>
                    <td className="text-nowrap dark_title_color adminuser_table__data">
                      {item?.id === null ? "--" : item?.id}
                    </td>
                    <td className="text-nowrap dark_title_color adminuser_table__data">
                      {item?.faq_category_id === null ? "--" : item?.faq_category_id}
                    </td>
                    <td className="text-nowrap secondary_color adminuser_table__data">
                      {item?.new_data == null ? item?.old_data?.name : item?.new_data?.name}
                    </td>
                    <td className="text-nowrap  dark_title_color adminuser_table__data">
                      {item?.approved_date === null ? "--" : moment(item?.approved_date).format("DD-MM-YYYY HH:mm:ss")}
                    </td>
                    <td className="text-nowrap  dark_title_color adminuser_table__data">
                      {item?.updatedAt === null ? "--" : moment(item?.updatedAt).format("DD-MM-YYYY HH:mm:ss")}
                    </td>


                    <td className="text-nowrap  position-sticky end-0 adminuser_table__data">

                      <Link
                        className="text-decoration-none primary_color"
                        to={{
                          pathname: `/static-pages/faq-category/approvals/view/${item.id}`,
                        }}
                      >
                        View
                      </Link>

                    </td>
                  </tr>
                );
              })}
              </LoadAndError>
            </tbody>
          )}
        </table>
      </div>

      <TablePagination
        paginate={handlePagination}
        currentPage={page}
        pageData={pageData}
      />
    </TableContainer>
  );
};

export default FaqCategoryApprovalTableContent;
