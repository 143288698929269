import React, { useState } from 'react'
import CreateBtn from '../../../../components/buttons/createBtn'
import ExportBtn from '../../../../components/buttons/exportBtn'
import FilterBtn from '../../../../components/buttons/filterBtn'
import VersionHistoryBtn from '../../../../components/buttons/versionHistoryBtn'
import AdminUserFilter from '../../../../components/filter/adminUserFilter'
import CampaignOpporFilter from '../../../../components/filter/static-pages filter/campaignOpporFilter'
import FaqFilter from '../../../../components/filter/static-pages filter/faqFilter'
import InnerLayout from '../../../../components/layouts/innerLayout/innerLayout'
import CampaignOpporApprovalTableContent from '../../../../components/static-pages/campaignOppor/tables/campaignOpporApprovalTableContent'
import FaqApprovalTableContent from '../../../../components/static-pages/faqs/tables/faqApprovalTableContent'
import { campaignOpporApprovalsExport } from '../../../../redux/actions/campaignOpporAction'
import { faqApprovalsExport } from '../../../../redux/actions/faqAction'

const CampaignOpporApprovalTable = () => {
  const [page, setPage] = useState(0);
  const [showFilter, setShowFilter] = useState(false);
  const [search, setSearch] = useState({ value: "" });
  const handleFilterClose = () => {
    setShowFilter(false);
  };
  const handleFilterOpen = () => {
    setShowFilter(true);
  };
  const handleSearch = (value) => {
    setSearch({ value: value });
    setPage(0)
  };
  const handlePage = (type) => {
    if (type === "+") {
      setPage((prev) => prev + 1)
    }
    else {
      setPage((prev) => prev - 1)
    }
  }
  const buttonList = [
    <VersionHistoryBtn link='/static-pages/campaignOppor/version-history' />,
    <CreateBtn link='/static-pages/campaignOppor/create' />,
    <ExportBtn dispatchAction={campaignOpporApprovalsExport} />,
    <FilterBtn filterFn={handleFilterOpen}
    />
  ]
  return (
    <InnerLayout buttons={buttonList}>
      <CampaignOpporFilter
        filter={handleSearch}
        showFilter={showFilter}
        closeFilter={handleFilterClose}
      />
      <CampaignOpporApprovalTableContent page={page} changePage={handlePage} search={search} />
    </InnerLayout>
  )
}

export default CampaignOpporApprovalTable;  