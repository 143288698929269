import { call, takeLatest } from "redux-saga/effects";
import {
  APPROVE_BLOG,
  CREATE_BLOG,
  REJECT_BLOG,
  VIEW_BLOG,
  BLOG_TABLE,
  EDIT_BLOG,
  DELETE_BLOG,
  BLOG_APPROVAL_TABLE,
  BLOG_APPROVAL_VIEW,
  BLOG_VERSION_HISTORY_TABLE,
  BLOG_VERSION_HISTORY,
  BLOG_OPTIONS,
  BLOG_CATEGORY_OPTIONS,
  BLOG_MAIN_EXPORT,
  BLOG_APPROVALS_EXPORT,
  BLOG_VERSION_EXPORT,
  BLOG_SUBMIT,
} from "../actions/types";
import {
  approveBlogApi,
  blogApprovalByIdApi,
  blogApprovalListApi,
  blogApprovalsExportApi,
  blogCategoryOptionsApi,
  blogMainExportApi,
  blogOptionsApi,
  blogSubmitApi,
  blogVersionExportApi,
  createBlogApi,
  deleteBlogApi,
  editBlogApi,
  rejectBlogApi,
  versionHistoryBlogDetailApi,
  versionHistoryBlogListApi,
  viewBlogDetailApi,
  viewBlogListApi,
} from "../apis/blogApis";

export function* createBlogWorker(action) {
  try {
    const res = yield call(createBlogApi, action.data);
    console.log(res);

    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res.data);
    }
  } catch (error) {}
}

export function* approveBlogWorker(action) {
  try {
    const res = yield call(approveBlogApi, {
      blog_id: action.id,
    });
    console.log(res);
    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res.data);
    }
  } catch (error) {}
}

export function* rejectBlogWorker(action) {
  try {
    const res = yield call(rejectBlogApi, action.id);
    console.log(res);
    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res.data);
    }
  } catch (error) {}
}

export function* viewBlogDetailWorker(action) {
  try {
    const res = yield call(viewBlogDetailApi, action.id);
    console.log(res);
    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res.data);
    }
  } catch (error) {}
}

export function* viewBlogListWorker(action) {
  try {
    const res = yield call(viewBlogListApi, action.page_no, action.data);
    console.log(res);
    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res.data.data);
      console.log(res.data.data);
    } else {
      yield action.onError(res.data);
    }
  } catch (error) {}
}

export function* editBlogWorker(action) {
  try {
    const res = yield call(editBlogApi, action.id, action.data);
    console.log(res);
    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res.data);
    }
  } catch (error) {}
}

export function* deleteBlogWorker(action) {
  try {
    const res = yield call(deleteBlogApi, action.id);
    console.log(res);
    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res.data);
    }
  } catch (error) {}
}

export function* blogApprovalListWorker(action) {
  try {
    const res = yield call(blogApprovalListApi, action.page_no, action.data);
    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res.data.data);
    } else {
      yield action.onError(res.data);
    }
  } catch (error) {}
}

export function* blogApprovalByIdWorker(action) {
  try {
    const res = yield call(blogApprovalByIdApi, action.id);
    console.log(res);
    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res.data);
    }
  } catch (error) {}
}

export function* versionHistoryBlogListWorker(action) {
  try {
    const res = yield call(versionHistoryBlogListApi, action.page, action.data);
    console.log(res);
    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res.data);
    }
  } catch (error) {}
}

export function* versionHistoryBlogDetailWorker(action) {
  try {
    const res = yield call(versionHistoryBlogDetailApi, action.id);
    console.log(res);
    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res.data);
    }
  } catch (error) {}
}

export function* blogCategoryOptionsWorker(action) {
  try {
    const res = yield call(blogCategoryOptionsApi);
    console.log(res);
    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res.data);
    }
  } catch (error) {}
}
export function* blogOptionsWorker(action) {
  try {
    const res = yield call(blogOptionsApi);
    console.log(res);
    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res.data);
    }
  } catch (error) {}
}

export function* blogMainExportWorker(action) {
  try {
    const res = yield call(blogMainExportApi);
    console.log(res);
    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res.data);
    }
  } catch (error) {}
}

export function* blogApprovalsExportWorker(action) {
  try {
    const res = yield call(blogApprovalsExportApi);
    console.log(res);
    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res.data);
    }
  } catch (error) {}
}

export function* blogSubmitWorker(action) {
  try {
    const res = yield call(blogSubmitApi, action.id);
    console.log(res);
    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res.data);
    }
  } catch (error) {}
}

export function* blogVersionExportWorker(action) {
  try {
    const res = yield call(blogVersionExportApi);
    console.log(res);
    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res.data);
    }
  } catch (error) {}
}

export function* createBlogWorkerWatcher() {
  yield takeLatest(CREATE_BLOG, createBlogWorker);
}

export function* approveBlogWorkerWatcher() {
  yield takeLatest(APPROVE_BLOG, approveBlogWorker);
}
export function* rejectBlogWorkerWatcher() {
  yield takeLatest(REJECT_BLOG, rejectBlogWorker);
}
export function* viewBlogDetailWatcherWorker() {
  yield takeLatest(VIEW_BLOG, viewBlogDetailWorker);
}
export function* viewBlogListWorkerWatcher() {
  yield takeLatest(BLOG_TABLE, viewBlogListWorker);
}
export function* editBlogWorkerWatcher() {
  yield takeLatest(EDIT_BLOG, editBlogWorker);
}
export function* deleteBlogWorkerWatcher() {
  yield takeLatest(DELETE_BLOG, deleteBlogWorker);
}

export function* blogApprovalListWatcherWorker() {
  yield takeLatest(BLOG_APPROVAL_TABLE, blogApprovalListWorker);
}

export function* blogApprovalByIdWorkerWatcher() {
  yield takeLatest(BLOG_APPROVAL_VIEW, blogApprovalByIdWorker);
}

export function* versionHistoryBlogListWorkerWatcher() {
  yield takeLatest(BLOG_VERSION_HISTORY_TABLE, versionHistoryBlogListWorker);
}
export function* versionHistoryBlogDetailWatcher() {
  yield takeLatest(BLOG_VERSION_HISTORY, versionHistoryBlogDetailWorker);
}

export function* watchBlogCategoryOptionsWorker() {
  yield takeLatest(BLOG_CATEGORY_OPTIONS, blogCategoryOptionsWorker);
}
export function* watchBlogOptionsWorker() {
  yield takeLatest(BLOG_OPTIONS, blogOptionsWorker);
}

export function* watchBlogMainExportWorker() {
  yield takeLatest(BLOG_MAIN_EXPORT, blogMainExportWorker);
}
export function* watchBlogApprovalsExportWorker() {
  yield takeLatest(BLOG_APPROVALS_EXPORT, blogApprovalsExportWorker);
}
export function* watchBlogVersionExportWorker() {
  yield takeLatest(BLOG_VERSION_EXPORT, blogVersionExportWorker);
}

export function* watchblogSubmitWorker() {
  yield takeLatest(BLOG_SUBMIT, blogSubmitWorker);
}
