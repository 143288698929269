import React, { useEffect, useState } from 'react'
import InnerLayout from "../../../../components/layouts/innerLayout/innerLayout";
import InnerContainer from "../../../../components/layouts/innerContainer/innerContainer";
import VersionHistoryBtn from "../../../../components/buttons/versionHistoryBtn";
import ApprovalsBtn from "../../../../components/buttons/approvalsBtn";
import RejectBtn from "../../../../components/buttons/rejectBtn";
import ApproveBtn from "../../../../components/buttons/approveBtn";
import { useDispatch } from 'react-redux';
import errorToast from '../../../../components/utilits/errorToast';
import successToast from '../../../../components/utilits/successToast';
import moment from 'moment';
import { useParams, useNavigate } from "react-router-dom";
import { approveCategory, CategoryApprovalById, rejectCategory } from '../../../../redux/actions/faqCategoryActions';
import LoadingSpinnerTable from '../../../../components/utilits/loadingSpinnerTable';
import LoadAndError from '../../../../components/utilits/LoadAndError';

const FaqCategoryApprovalsDetails = () => {
  const params = useParams()
  const dispatch = useDispatch()
  const navigate = useNavigate();
  const [wishlistData, setWishlistData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    setLoading(true)
    console.log("the id", params.id);
    dispatch(
      CategoryApprovalById(params.id, onSuccess, onError)
    );
  }, [])

  const onSuccess = (data) => {
    setWishlistData(data?.data);
    setLoading(false)
    setError(false);
  };
  const onError = (data) => {
    errorToast(data.data.message)
    //errorToast("went wrong")
    setLoading(false)
    setError(true);
  };

  console.log("The dtate", wishlistData);

  const FaqViewApprovalDetails = [
    {
      heading: "Approval ID",
      title: wishlistData?.id ? wishlistData?.id : "-",
    },
    {
      heading: "FAQ Category Name",
      title: wishlistData?.new_data == null? wishlistData?.old_data?.name : wishlistData?.new_data?.name,
    },
    {
      heading: "FAQ Category ID",
      title: wishlistData?.new_data == null? wishlistData?.old_data?.id : wishlistData?.new_data?.id,
    },
    {
      heading: "Created At",
      title: (wishlistData?.createdAt) ? moment(wishlistData?.createdAt).format("DD-MM-YYYY HH:mm:ss") : "--",
    },
    {
      heading: "Updated At",
      title: (wishlistData?.updatedAt) ? moment(wishlistData?.updatedAt).format("DD-MM-YYYY HH:mm:ss") : "--",
    },
    {
      heading: "Operation Type",
      title: wishlistData?.new_data == null ? "Delete" : wishlistData?.new_data && wishlistData?.old_data ? "Edit" : wishlistData?.old_data == null ? "Create" : ""
    }
  ];


  //Approval , reject sections

  const approveRole = () => {
    dispatch(
      approveCategory(wishlistData?.id, onApproveSuccess, onApproveError)
    );
  }

  const onApproveSuccess = (data) => {
    console.log(data.data);
    successToast(data.message)
    setLoading(false)
    navigate("/static-pages/faq-category/approvals")
    setError(false);
  };
  const onApproveError = (data) => {
    errorToast(data.message)
    //errorToast("went wrong")
    setLoading(false)
    setError(true);
  };

  //Reject Section
  const rejectWishListFn = () => {
    dispatch(
      rejectCategory(wishlistData?.id, onRejectSuccess, onRejectError)
    );
  }

  const onRejectSuccess = (data) => {
    successToast(data.message)
    setLoading(false)
    navigate("/static-pages/faq-category/approvals")
    setError(false);
  };
  const onRejectError = (data) => {
    errorToast(data.message)
    setLoading(false)
    setError(true);
  };


  const buttonList = [<VersionHistoryBtn link='/static-pages/faq-category/version-history' />, <ApproveBtn approveFn={approveRole}/>, <RejectBtn showModal={rejectWishListFn}/>];
  return (
    <InnerLayout buttons={buttonList}>
      <div className="user_view_container">
        <InnerContainer error={error} oldData={wishlistData?.old_data} newData={wishlistData?.new_data}>
          {loading ? (
            <LoadingSpinnerTable/>
          ) : (
          <LoadAndError loader={loading} error={error}>
          <div className="row mt-4">
            <div className="col-12 col-sm-12 col-md-9 col-lg-9 col-xl-7">
              <div className="row ps-1 ms-1 ps-lg-4 ms-lg-1">
                {FaqViewApprovalDetails.map((item, index) => (
                  <div className="mb-2 ">
                    <h5 className="dark_grey_color text-nowrap fs_13 fn_Montserrat fw_500">
                      {item.heading}
                    </h5>
                    <p
                      className={` fs_13 fn_Nunito  fw_500 ${item.heading === "Express Interest ID" ||
                        item.heading === "Opportunity ID" ||
                        item.heading === "Opportunity Name" ||
                        item.heading === "User ID" ||
                        item.heading === "User Name" ||
                        item.heading === "Order ID"
                        ? "secondary_color"
                        : "dark_title_color"
                        }`}
                    >
                      {item.title}
                    </p>
                  </div>
                ))}
              </div>
            </div>
          </div>
          </LoadAndError>
          )}
        </InnerContainer>
      </div>
    </InnerLayout>
  );
};

export default FaqCategoryApprovalsDetails;
