import React from "react";
import { useDispatch } from "react-redux";
import ExportIcon from "../../assets/icons/exportIcon";
import { AdminButton } from "./versionHistoryBtn";
import FileSaver from "file-saver";
import errorToast from "../../components/utilits/errorToast";
const ExportBtn = ({ dispatchAction, exportFn = false }) => {
  const dispatch = useDispatch()
  const exportFnc = () => {
    if (exportFn) {
      exportFn()
    }
    else dispatch(dispatchAction
      (
        onSuccess,
        onError,
      ));
  };
  const onSuccess = (data) => {
    console.log(data);
    var type =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8";
    var blob = new Blob([data?.data === undefined ? data : data?.data], { type: type });
    FileSaver.saveAs(blob, "file.csv");
  };
  const onError = (data) => {
    console.log("ERROR==================", data);
    errorToast("Failed to export data")

  };
  return (
    <AdminButton onClick={() => exportFnc()} type="submit" padding="5px 10px" color="#666666">
      <ExportIcon fill="#666666" />
      <span className="ms-1">EXPORT</span>
    </AdminButton>
  );
};

export default ExportBtn;
