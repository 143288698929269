import React from "react";
import AddIcon from "../../../../assets/icons/addIcon";

const OpportunitiesAddSubBarSection = ({ addSubSection }) => {
  return (
    <div
      className={`fn_Nunito oppor_cont_edit_addsec__container fw_600 d-flex justify-content-between align-items-center py-3 fs_14  mx-auto`}
    >
      <button type="button" onClick={() => addSubSection("text")} className="bg-white border-0   d-flex align-items-center  ">
        <AddIcon fill="#CCCCCC" width={16} height={16} />
        <span className="dark_title_color mt-1 ms-2">Text</span>
      </button>{" "}
      <div className="oppor_content_edit__vrline"></div>
      <button type="button" onClick={() => addSubSection("table")} className="bg-white border-0 d-flex align-items-center">
        <AddIcon fill="#CCCCCC" width={16} height={16} />
        <span className="dark_title_color mt-1 ms-2">Table</span>
      </button>
      <div className="oppor_content_edit__vrline"></div>
      <button type="button" onClick={() => addSubSection("file")} className="bg-white border-0 d-flex align-items-center">
        <AddIcon fill="#CCCCCC" width={16} height={16} />
        <span className="dark_title_color mt-1 ms-2">file</span>
      </button>
    </div>
  );
};

export default OpportunitiesAddSubBarSection;
