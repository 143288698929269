import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import DeleteBtn from "../../../../components/buttons/deleteBtn";
import EditBtn from "../../../../components/buttons/editBtn";
import VersionHistoryBtn from "../../../../components/buttons/versionHistoryBtn";
import InnerContainer from "../../../../components/layouts/innerContainer/innerContainer";
import InnerLayout from "../../../../components/layouts/innerLayout/innerLayout";
import AdminRoleCheckBox from "../../../../components/utilits/adminRoleCheckbox";
import "../admin-roles.css";
import * as adminRolesActions from "../../../../redux/actions/adminRoleActions";
import errorToast from "../../../../components/utilits/errorToast";
import { Form, Formik } from "formik";
import { useLocation } from 'react-router-dom';
import { addAdminRoleDefaultValue } from "./admin-role-edit";
import moment from "moment";
import { access } from "../../../../components/utilits/access";
import LoadingSpinnerTable from "../../../../components/utilits/loadingSpinnerTable";
import LoadAndError from "../../../../components/utilits/LoadAndError";
const AdminRoleView = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [adminRoleData, setAdminRoleData] = useState(location.state ?? addAdminRoleDefaultValue)
    const params = useParams();
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(false)
    const dispatch = useDispatch();
    useEffect(() => {
        setLoading(true);
        dispatch(
            adminRolesActions.viewAdminRole(params.id, onSuccess, onError)
        );
    }, [params.id]);
    const onSuccess = (data) => {
        setAdminRoleData(data.data)
    setLoading(false);
    setError(false)
    };
    const onError = (data) => {
        console.log(data);
        errorToast(data.message)
    setLoading(false);
    setError(true)
    };
    const buttonList = [
        <VersionHistoryBtn link={`/admin-roles/version-history/`} />,
        <EditBtn link={`/admin-roles/edit/${adminRoleData?.id}`} />,
        <DeleteBtn />
    ];


    const initialValues = {
        id: adminRoleData?.id,
        roleName: adminRoleData?.role_name,
        permissions: {
            "admin": {
                "checker": adminRoleData?.permissions?.admin?.checker,
                "maker": adminRoleData?.permissions?.admin?.maker,
                "read_only": adminRoleData?.permissions?.admin?.read_only
            },
            "user": {
                "checker": adminRoleData?.permissions?.user?.checker,
                "maker": adminRoleData?.permissions?.user?.maker,
                "read_only": adminRoleData?.permissions?.user?.read_only
            },
            "opportunity": {
                "checker": adminRoleData?.permissions?.opportunity?.checker,
                "maker": adminRoleData?.permissions?.opportunity?.maker,
                "read_only": adminRoleData?.permissions?.opportunity?.read_only
            },

            "role": {
                "checker": adminRoleData?.permissions?.role?.checker,
                "maker": adminRoleData?.permissions?.role?.maker,
                "read_only": adminRoleData?.permissions?.role?.read_only
            },
            "static_homepage": {
                "checker": adminRoleData?.permissions?.static_homepage?.checker,
                "maker": adminRoleData?.permissions?.static_homepage?.maker,
                "read_only": adminRoleData?.permissions?.static_homepage?.read_only
            }
        }
    };
    return (
        <InnerLayout buttons={
            buttonList
        }>
            <div className="admin_role_container">
                <InnerContainer>
                    {loading ? (
                        <LoadingSpinnerTable/>
                    ) : (
          <LoadAndError loader={loading} error={error}>
                    <div className="pt-3  ps-2 ps-lg-4">
                        <div className="mb-2">
                            <h5 className="dark_grey_color fs_12 fn_Montserrat fw_500 m-0">
                                Role ID
                            </h5>
                            <p className={` fs_12 fn_Nunito fw_500 mt-1 dark_grey_color `}>
                                {adminRoleData?.id}
                            </p>
                        </div>
                        <div>
                            <h5 className="dark_grey_color fs_12 fn_Montserrat fw_500 m-0">
                                Role Name
                            </h5>
                            <p className={` fs_12 fn_Nunito fw_500 mt-1 secondary_color `}>
                                {adminRoleData?.role_name}

                            </p>
                        </div>
                        <Formik
                            enableReinitialize={true}
                            initialValues={initialValues}
                        >
                            <Form>
                                <div>
                                    <h5 className="dark_grey_color fs_12 fn_Montserrat fw_500 m-0">
                                        Permissions
                                    </h5>
                                    <div className="admin_role_view_permissions_width mt-3 mb-3">
                                        <div className="row ms-0 ms-lg-3 ">
                                            <div className="col-3">
                                                <h5 className="dark_grey_color text-nowrap fs_12 fn_Montserrat fw_500 ">
                                                    Modules
                                                </h5>
                                            </div>
                                            <div className="col-3">
                                                <h5 className="dark_grey_color d-flex justify-content-center fs_12 text-nowrap fn_Montserrat fw_500 ">
                                                    Checker
                                                </h5>
                                            </div>
                                            <div className="col-3">
                                                <h5 className="dark_grey_color fs_12 d-flex justify-content-center  fn_Montserrat text-nowrap fw_500 ">
                                                    Maker
                                                </h5>
                                            </div>
                                            <div className="col-3">
                                                <h5 className="dark_grey_color text-nowrap d-flex justify-content-center fs_12 fn_Montserrat fw_500 ">
                                                    Read Only
                                                </h5>
                                            </div>
                                        </div>
                                        <div className="row ms-0 ms-lg-3 mt-2">
                                            <div className="col-3">
                                                <h5 className="dark_grey_color text-nowrap fs_12 fn_Montserrat fw_500 ">
                                                    Admin
                                                </h5>
                                            </div>
                                            <div className="col-3">
                                                <div className="d-flex justify-content-center">
                                                    <AdminRoleCheckBox disabled={true} name="permissions.admin.checker" />
                                                </div>
                                            </div>
                                            <div className="col-3">
                                                <div className="d-flex justify-content-center">
                                                    <AdminRoleCheckBox disabled={true} name="permissions.admin.maker" />
                                                </div>
                                            </div>
                                            <div className="col-3">
                                                <div className="d-flex justify-content-center">
                                                    <AdminRoleCheckBox disabled={true} name="permissions.admin.read_only" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row ms-0 ms-lg-3 mt-2">
                                            <div className="col-3">
                                                <h5 className="dark_grey_color text-nowrap fs_12 fn_Montserrat fw_500 ">
                                                    User
                                                </h5>
                                            </div>
                                            <div className="col-3">
                                                <div className="d-flex justify-content-center">
                                                    <AdminRoleCheckBox disabled={true} name="permissions.user.checker" />
                                                </div>
                                            </div>
                                            <div className="col-3">
                                                <div className="d-flex justify-content-center">
                                                    <AdminRoleCheckBox disabled={true} name="permissions.user.maker" />
                                                </div>
                                            </div>
                                            <div className="col-3">
                                                <div className="d-flex justify-content-center">
                                                    <AdminRoleCheckBox disabled={true} name="permissions.user.read_only" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row ms-0 ms-lg-3 mt-2">
                                            <div className="col-3">
                                                <h5 className="dark_grey_color text-nowrap fs_12 fn_Montserrat fw_500 ">
                                                    Opportunity
                                                </h5>
                                            </div>
                                            <div className="col-3">
                                                <div className="d-flex justify-content-center">
                                                    <AdminRoleCheckBox disabled={true} name="permissions.opportunity.checker" />
                                                </div>
                                            </div>
                                            <div className="col-3">
                                                <div className="d-flex justify-content-center">
                                                    <AdminRoleCheckBox disabled={true} name="permissions.opportunity.maker" />
                                                </div>
                                            </div>
                                            <div className="col-3">
                                                <div className="d-flex justify-content-center">
                                                    <AdminRoleCheckBox disabled={true} name="permissions.opportunity.read_only" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row ms-0 ms-lg-3 mt-2">
                                            <div className="col-3">
                                                <h5 className="dark_grey_color text-nowrap fs_12 fn_Montserrat fw_500 ">
                                                    Role
                                                </h5>
                                            </div>
                                            <div className="col-3">
                                                <div className="d-flex justify-content-center">
                                                    <AdminRoleCheckBox disabled={true} name="permissions.role.checker" />
                                                </div>
                                            </div>
                                            <div className="col-3">
                                                <div className="d-flex justify-content-center">
                                                    <AdminRoleCheckBox disabled={true} name="permissions.role.maker" />
                                                </div>
                                            </div>
                                            <div className="col-3">
                                                <div className="d-flex justify-content-center">
                                                    <AdminRoleCheckBox disabled={true} name="permissions.role.read_only" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row ms-0 ms-lg-3 mt-2">
                                            <div className="col-3">
                                                <h5 className="dark_grey_color text-nowrap fs_12 fn_Montserrat fw_500 ">
                                                    static homepage
                                                </h5>
                                            </div>
                                            <div className="col-3">
                                                <div className="d-flex justify-content-center">
                                                    <AdminRoleCheckBox disabled={true} name="permissions.static_homepage.checker" />
                                                </div>
                                            </div>
                                            <div className="col-3">
                                                <div className="d-flex justify-content-center">
                                                    <AdminRoleCheckBox disabled={true} name="permissions.static_homepage.maker" />
                                                </div>
                                            </div>
                                            <div className="col-3">
                                                <div className="d-flex justify-content-center">
                                                    <AdminRoleCheckBox disabled={true} name="permissions.static_homepage.read_only" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="mb-2">
                                    <h5 className="dark_grey_color fs_12 fn_Montserrat fw_500 m-0">
                                        Created At
                                    </h5>
                                    <p className={` fs_12 fn_Nunito fw_500 mt-1 dark_title_color `}>
                                        {adminRoleData?.createdAt ? moment(adminRoleData?.createdAt).format("DD/MM/YYYY HH:MM:ss") : "--"}
                                    </p>
                                </div>
                                <div className="mb-2">
                                    <h5 className="dark_grey_color fs_12 fn_Montserrat fw_500 m-0">
                                        Updated At
                                    </h5>
                                    <p className={` fs_12 fn_Nunito fw_500 mt-1 dark_title_color `}>
                                        {adminRoleData?.createdAt ? moment(adminRoleData?.createdAt).format("DD/MM/YYYY HH:MM:ss") : "--"}
                                    </p>
                                </div>
                            </Form>
                        </Formik>
                    </div>
                    </LoadAndError>
                    )}
                </InnerContainer>
            </div>
        </InnerLayout>
    );
};

export default AdminRoleView;

