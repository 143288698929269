import React, { useState, useRef, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import CloseIcon from "../../../../assets/icons/closeIcon";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import "./llpModal.css";
import InputField from "../../../form/inputFields/inputField";
import { useDispatch } from "react-redux";
import SelectFields from "../../../form/selectFields/selectFields";
import { addLLPAction } from "../../../../redux/actions/llpActions";
import successToast from "../../../utilits/successToast";
import errorToast from "../../../utilits/errorToast";
import { opportunitiesNameListAction } from "../../../../redux/actions/opportunitiesActions";
import ArrowDownIcon from "../../../../assets/icons/arrowdown";
import { useParams } from "react-router-dom";
import { Spinner } from "react-bootstrap";

const CreateLlpModal = ({ show, closeModal }) => {
  const formRef = useRef();
  const dispatch = useDispatch();
  const amountRegex = /^[0-9]*$/s;
  const [loading, setLoading] = useState(false);
  const [opportunitiesList, setOpportunitiesList] = useState([]);
  const params = useParams();
  useEffect(() => {
    dispatch(opportunitiesNameListAction(onSuccessDrop, onErrorDrop));
  }, []);
  const onSuccessDrop = (data) => {
    const categoryList = [];
    data?.data.map((item, index) => {
      categoryList[index] = {
        value: item.id,
        label: `${item.title}${" "}id:${item.id}`,
      };
    });
    setOpportunitiesList(categoryList);
    console.log("SUCCESS");
  };
  const onErrorDrop = (data) => {
    console.log(data);
  };
  const options = [
    { label: "active", value: "active" },
    { label: "completed", value: "completed" },
    { label: "traded", value: "traded" },
    { label: "ended", value: "ended" },
  ];
  const initialValues = {
    llpName: "",
    llpTitle: "",
    llpRegistrationNumber: "",
    llpopportunity: params.id,
    status: "",
  };
  const loginSchema = Yup.object().shape({
    llpName: Yup.string().trim().required("Required"),
    llpTitle: Yup.string().trim().required("Required"),
    llpRegistrationNumber: Yup.string()
      .matches(amountRegex, "Number only")
      .required("Required"),
    llpopportunity: Yup.string().trim().required("Required"),
    status: Yup.string().trim().required("Required"),
  });
  const onSubmit = (values) => {
    setLoading(true);
    dispatch(
      addLLPAction(
        {
          name: values.llpName,
          title: values.llpTitle,
          reg_no: values.llpRegistrationNumber,
          status: values.status,
          opportunity_id: values.llpopportunity,
        },
        onSuccess,
        onError
      )
    );
  };

  const onSuccess = (data) => {
    successToast(data.message);
    closeModal();
    setLoading(false);
  };
  const onError = (data) => {
    setLoading(false);
    errorToast(data.message);
  };

  return (
    <>
      <Modal
        centered
        show={show}
        onHide={closeModal}
        dialogClassName={`oppoptunities_modals_body mx-auto `}
        contentClassName={"oppoptunities_modals_body_content  p-2"}
      >
        <Modal.Body>
          <>
            <div className="d-flex justify-content-between  ">
              <h2 className="green_color fs_15 fn_Montserrat fw_600 pt-1">
                {"Create LLP"}
              </h2>
              <button
                onClick={closeModal}
                className="light_grey_color  fs_15 fn_Montserrat bg-transparent border-0 fw_600 "
              >
                <span className="pt-1">DISMISS</span>
                <span className="ms-2">
                  <CloseIcon width={13} height={13} fill="#A5A5A5" />
                </span>
              </button>
            </div>
            <Formik
              enableReinitialize={true}
              innerRef={formRef}
              initialValues={initialValues}
              onSubmit={onSubmit}
              validationSchema={loginSchema}
            >
              <Form className="mt-3">
                <InputField
                  id={"llpName"}
                  label={"LLP Name"}
                  type={"text"}
                  placeholder={"Enter Name"}
                  name={"llpName"}
                />
                <InputField
                  id={"llpTitle"}
                  label={"Title"}
                  type={"text"}
                  placeholder={"Enter Title"}
                  name={"llpTitle"}
                />
                <InputField
                  id={"llpRegistrationNumber"}
                  label={"Registration Number"}
                  type={"text"}
                  placeholder={"Enter Registration Number"}
                  name={"llpRegistrationNumber"}
                />
                <SelectFields
                  Icon={<ArrowDownIcon width={15} fill={"#A5A5A5"} />}
                  label={"Opportunity"}
                  placeholder={"Select Opportunity"}
                  name={"llpopportunity"}
                  disabled={true}
                  //   options={opportunitiesList}
                  default1={opportunitiesList?.filter(
                    (item) => item.value === params.id
                  )}
                  id={"llpopportunity"}
                />
                <SelectFields
                  Icon={<ArrowDownIcon width={15} fill={"#A5A5A5"} />}
                  label={"Status"}
                  placeholder={"Select Status"}
                  name={"status"}
                  options={options}
                  id={"status"}
                />
                <div className="d-flex justify-content-end mt-4">
                  <button
                    disabled={loading}
                    type="submit"
                    className="secondary_color fs_15  fn_Montserrat fw_600 border-0 tertiary_bg px-4 px-lg-5 py-2 py-lg-2 rounded-2"
                  >
                    {loading ? (
                      <Spinner
                        as="span"
                        size="sm"
                        animation="border"
                        variant="secondary"
                        role="status"
                      >
                        <span className="visually-hidden">Loading...</span>
                      </Spinner>
                    ) : (
                      "CREATE"
                    )}{" "}
                  </button>
                </div>
              </Form>
            </Formik>
          </>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default CreateLlpModal;
