import React, { useEffect, useState } from "react";
import InnerLayout from "../../../../components/layouts/innerLayout/innerLayout";
import InnerContainer from "../../../../components/layouts/innerContainer/innerContainer";
import VersionHistoryBtn from "../../../../components/buttons/versionHistoryBtn";
import RejectBtn from "../../../../components/buttons/rejectBtn";
import ApproveBtn from "../../../../components/buttons/approveBtn";
import { useDispatch } from "react-redux";
import errorToast from "../../../../components/utilits/errorToast";
import successToast from "../../../../components/utilits/successToast";
import moment from "moment";
import { useParams, useNavigate } from "react-router-dom";
import LoadingSpinnerTable from "../../../../components/utilits/loadingSpinnerTable";
import LoadAndError from "../../../../components/utilits/LoadAndError";
import {
  approveCommunityBadgesAction,
  rejectCommunityBadgesAction,
  viewApprovalCommunityBadgesAction,
} from "../../../../redux/actions/communityBadgesActions";

const CommunityBadgesApprovalsDetails = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [wishlistData, setWishlistData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    setLoading(true);
    dispatch(viewApprovalCommunityBadgesAction(params.id, onSuccess, onError));
  }, []);

  const onSuccess = (data) => {
    console.log(data, "fkfkkf");
    setWishlistData(data?.data);
    setLoading(false);
    setError(false);
  };
  const onError = (data) => {
    errorToast(data.data.message);
    //errorToast("went wrong")
    setLoading(false);
    setError(true);
  };

  const FaqViewApprovalDetails = [
    {
      heading: "Approval Id",
      title: wishlistData?.id,
    },
    {
      heading: "BADGE ID",
      title:
        wishlistData?.new_data == null
          ? wishlistData?.old_data?.id
          : wishlistData?.new_data?.id,
    },
    {
      heading: "Points",
      title:
        wishlistData?.new_data == null
          ? wishlistData?.old_data?.points
          : wishlistData?.new_data?.points,
    },
    {
      heading: "Points Type",
      title:
        wishlistData?.new_data == null
          ? wishlistData?.old_data?.points_type
          : wishlistData?.new_data?.points_type,
    },
    {
      heading: "User ID",
      title:
        wishlistData?.new_data == null
          ? wishlistData?.old_data?.user_id
          : wishlistData?.new_data?.user_id,
    },
    {
      heading: "Created At",
      title: wishlistData?.createdAt
        ? moment(wishlistData?.createdAt).format("DD-MM-YYYY HH:mm:ss")
        : "--",
    },
    {
      heading: "Updated At",
      title: wishlistData?.updatedAt
        ? moment(wishlistData?.updatedAt).format("DD-MM-YYYY HH:mm:ss")
        : "--",
    },
    {
      heading: "Operation Type",
      title:
        wishlistData?.new_data == null
          ? "Delete"
          : wishlistData?.new_data && wishlistData?.old_data
          ? "Edit"
          : wishlistData?.old_data == null
          ? "Create"
          : "",
    },
  ];

  //Approval , reject sections

  const approveRole = () => {
    dispatch(
      approveCommunityBadgesAction(
        {
          badge_id: params.id,
        },
        onApproveSuccess,
        onApproveError
      )
    );
  };

  const onApproveSuccess = (data) => {
    console.log(data.data);
    successToast(data.message);
    setLoading(false);
    setError(false);
    navigate("/static-pages/community-badges/approvals");
  };
  const onApproveError = (data) => {
    errorToast(data.message);
    //errorToast("went wrong")
    setLoading(false);
    setError(true);
  };

  //Reject Section
  const rejectWishListFn = () => {
    dispatch(
      rejectCommunityBadgesAction(params?.id, onRejectSuccess, onRejectError)
    );
  };

  const onRejectSuccess = (data) => {
    successToast("Rejected successfully");
    setLoading(false);
    setError(false);
    navigate("/static-pages/community-badges/approvals");
  };
  const onRejectError = (data) => {
    errorToast(data.message);
    setLoading(false);
    setError(true);
  };
  const buttonList = [
    <VersionHistoryBtn link="/static-pages/community-badges/version-history" />,
    <ApproveBtn approveFn={approveRole} />,
    <RejectBtn showModal={rejectWishListFn} />,
  ];
  return (
    <InnerLayout buttons={buttonList}>
      <div className="user_view_container">
        <InnerContainer
          oldData={wishlistData?.old_data}
          newData={wishlistData?.new_data}
        >
          {loading ? (
            <LoadingSpinnerTable />
          ) : (
            <LoadAndError loader={loading} error={error}>
              <div className="row mt-4">
                <div className="col-12 col-sm-12 col-md-9 col-lg-9 col-xl-7">
                  <div className="row ps-1 ms-1 ps-lg-4 ms-lg-1">
                    {FaqViewApprovalDetails.map((item, index) => (
                      <div className="mb-2 ">
                        <h5 className="dark_grey_color text-nowrap fs_13 fn_Montserrat fw_500">
                          {item.heading}
                        </h5>
                        <p
                          className={` fs_13 fn_Nunito  fw_500 ${
                            item.heading === "Express Interest ID" ||
                            item.heading === "BADGE ID" ||
                            item.heading === "User ID"
                              ? "secondary_color"
                              : "dark_title_color"
                          }`}
                        >
                          {item.title}
                        </p>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </LoadAndError>
          )}
        </InnerContainer>
      </div>
    </InnerLayout>
  );
};

export default CommunityBadgesApprovalsDetails;
