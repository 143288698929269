import React from "react";
import "../filtersidebar.css";
import { Formik, Form } from "formik";
import { Field } from "formik";
import Select, { components } from "react-select";
import * as Yup from "yup";
import ArrowRightIcon from "../../../assets/icons/arrowrightIcon";
import InputField from "../../form/inputFields/inputField";
import ArrowDownIcon from "../../../assets/icons/arrowdown";
import ErrorMsg from "../../form/errorMsg";
import SelectFields from "../../form/selectFields/selectFields";
const UserFilter = ({
    showFilter = false, closeFilter, filter, title = "Search", placeholder = "Enter Id, Name, date, ....." }) => {
    const alphaLettersRegex = /^[A-Za-z]+$/;
    const phoneRegex = /^[0-9]{10}$/

    const initialValues = {
        userId: "",
        name: "",
        email: "",
        phoneNumber: "",
        kyc: "",
        fromDate: "",
        todate: "",
    };
    const filterSchema = Yup.object().shape({
        name: Yup.string(),
        email: Yup.string().trim().email(),
        kyc: Yup.string(),
        phoneNumber: Yup.string().trim().matches(phoneRegex, "Not a valid Number!"),
        todate: Yup.string(),
        atleastOneIsRequired: Yup.string().trim().when(["userId", "name", "email", "phoneNumber", "kyc", "fromDate", "todate"], {
            is: (userId, name, email, phoneNumber, kyc, fromDate, todate) => !userId && !name && !email && !phoneNumber && !kyc && !fromDate && !todate,
            then: Yup.string().trim().required("Atleast one is required"),
            otherwise: Yup.string()
        })
    });
    const onSubmit = (values, { resetForm }) => {
        filter(values)
        console.log(values);
    };
    const DropdownIndicator = (props) => {
        return (
            <components.DropdownIndicator {...props}>
                <ArrowDownIcon width={25} height={25} />
            </components.DropdownIndicator>
        );
    };

    return (
        showFilter && (
            <div
                className={` filtersidebar_main__container p-4 bg-white fs_Montserrat`}
            >
                <div className="d-flex align-items-center justify-content-between mb-3">
                    <h5 className="secondary_color fs_20 fw_400 mb-1">Filters</h5>
                    <button
                        onClick={closeFilter}
                        className="d-flex align-items-center border-0  bg-white"
                    >
                        <span className="fs_14 dark_grey_color">Back</span>
                        <ArrowRightIcon width={20} height={20} fill="#666666" />
                    </button>
                </div>
                <Formik
                    initialValues={initialValues}
                    onSubmit={onSubmit}
                    validationSchema={filterSchema}
                >
                    <Form className="filtersidebar_form__container h-100 position-relative d-flex flex-column">
                        <div>
                            <InputField
                                placeholder="Enter User ID"
                                label="User Id"
                                name="userId"
                                labelClassName="mb-1 ms-2 dark_grey_color fn_Montserrat fw_500 fs_14"
                                containerClassName=" d-flex flex-column "
                                errMsgContainerClassName="mt-1 justify-content-end"
                            />
                            <InputField
                                placeholder="Enter Name"
                                label="Name"
                                name="name"
                                labelClassName="mb-1 ms-2 dark_grey_color fn_Montserrat fw_500 fs_14"
                                containerClassName=" d-flex flex-column "
                                errMsgContainerClassName="mt-1 justify-content-end"
                            />
                            <InputField
                                placeholder="Enter Email"
                                label="Email"
                                name="email"
                                labelClassName="mb-1 ms-2 dark_grey_color fn_Montserrat fw_500 fs_14"
                                containerClassName=" d-flex flex-column "
                                errMsgContainerClassName="mt-1 justify-content-end"
                            />
                            <InputField
                                placeholder="Enter Phone Number"
                                label="Phone Number"
                                name="phoneNumber"
                                labelClassName="mb-1 ms-2 dark_grey_color fn_Montserrat fw_500 fs_14"
                                containerClassName=" d-flex flex-column "
                                errMsgContainerClassName="mt-1 justify-content-end"
                            />
                            {/* <InputField
                                placeholder="Enter kyc"
                                label="KYC"
                                name="kyc"
                                labelClassName="mb-1 ms-2 dark_grey_color fn_Montserrat fw_500 fs_14"
                                containerClassName=" d-flex flex-column "
                                errMsgContainerClassName="mt-1 justify-content-end"
                            /> */}
                            {/* <div className={``}>
                                <label>KYC </label>
                      <Field name="kyc">
                        {({ form, meta, Field, default1 }) => (
                          <Select
                            Icon={
                              <ArrowRightIcon width={15} fill={"#A5A5A5"} />
                            }
                            //  defaultValue={default1}
                            name="kyc"
                            id="kyc"
                            {...Field}
                            components={{ DropdownIndicator }}
                            styles={customStylesKycFilter}
                            type="text"
                            className={
                              meta.touched && meta.error
                                ? `kyc_select-input-field w-100 kyc_selectField__border       focus_outline__none`
                                : `kyc_select-input-field w-100     kyc_selectField__border   focus_outline__none`
                            }
                            placeholder="SELECT KYC STATUS"
                            options={kycstatus}
                            onChange={(selectedOption) =>
                              form.setFieldValue("kyc", selectedOption.value)
                            }
                            // value={individualData.data.kyc_process_status}
                          />
                        )}
                      </Field>
                      <ErrorMsg name="kyc" />
                    </div> */}
                            <SelectFields
                                placeholder="Enter kyc"
                                label="KYC"
                                name="kyc"
                                labelClassName="mb-1 ms-2 dark_grey_color fn_Montserrat fw_500 fs_14"
                                containerClassName=" d-flex flex-column "
                                errMsgContainerClassName="mt-1 justify-content-end"
                                options={kycOptions} />

                            <InputField
                                type="date"
                                placeholder="Enter From Date"
                                label="From Date"
                                name="fromDate"
                                labelClassName="mb-1 ms-2 dark_grey_color fn_Montserrat fw_500 fs_14"
                                containerClassName=" d-flex flex-column "
                                errMsgContainerClassName="mt-1 justify-content-end"
                            />
                            <InputField
                                type="date"
                                placeholder="Enter To Date"
                                label="To Date"
                                name="todate"
                                labelClassName="mb-1 ms-2 dark_grey_color fn_Montserrat fw_500 fs_14"
                                containerClassName=" d-flex flex-column "
                                errMsgContainerClassName="mt-1 justify-content-end"
                            />
                            <ErrorMsg
                                containerClassName={"mt - 1 justify-content-end justify-content-lg-start"}
                                name="atleastOneIsRequired" />
                        </div>
                        <div className=" mt-3  mt-lg-auto  mb-5 d-flex gap-4 fs_Nunito py-2 fs_12">
                            <button
                                type="submit"
                                className={`secondary_bg text-nowrap px-4 py-2 border-0 rounded-2 text-white`}
                            >
                                Apply Changes
                            </button>
                            <button
                                type="reset"
                                onClick={() => filter("")}
                                className="bg-white text-nowrap fw_500 p-0 border-0 dark_grey_color"
                            >
                                Reset Filters
                            </button>
                        </div>
                    </Form>
                </Formik>
            </div>
        )
    );
};

export default UserFilter;

const kycOptions = [
    { value: "processing", label: "processing" },
    { value: "pending", label: "pending" },
    { value: "completed", label: "completed" },
    { value: "failed", label: "failed" },
    { value: "verified", label: "verified" },
    { value: "incompleted", label: "incompleted" },
]