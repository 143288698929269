import React, { useState } from "react";
import {
  tableOpportunitiesTagsdata,
  tableOpportunitiesTagsHeader,
} from "../../../table/tableData";
import { Link } from "react-router-dom";
import EditIcon from "../../../../assets/icons/editIcon";
import DeleteIcon from "../../../../assets/icons/deleteIcon";
import Tags from "../modals/tags";
import ErrorMsg from "../../../form/errorMsg";

const OpportunitiesTagTable = ({
  name = "",
  tableTitle,
  setFieldValue,
  formikValues
}) => {
  const [show, setShow] = useState(false);
  const [reasonEditId, setReasonEditId] = useState(null);
  function handleModalOpen(id) {
    if (id === 0 || id) {
      setReasonEditId(id)
      setShow(true)
      console.log(reasonEditId)
    }
    else {
      setReasonEditId(null)
      setShow(true)
    }
  }
  function handleReasonUpdator(id, values) {
    if (id === 0 || id) {
      let array = [...formikValues[name]];
      array[id] = { name: values.name, priority: values.priority, color_code: values.color_code };
      setFieldValue(name, array)
    }
    else {
      setFieldValue(name, [...formikValues[name], { name: values.name, priority: values.priority, color_code: values.color_code }])
    }
  }
  function reasonDeleter(index) {
    let array = [...formikValues[name]];
    if (index !== -1) {
      array.splice(index, 1);
      setFieldValue(name, array);
    }
  }


  return (
    <div className="mb-4">
      <div className="d-flex justify-content-between">
        <h2 className="dark_grey_color fs_13 fn_Montserrat fw_500">
          {tableTitle}
        </h2>
        <button
          type="button"
          onClick={() => handleModalOpen(null)}
          className="light_green_color fs_13 fn_Montserrat bg-transparent border-0 fw_500"
        >
          Create Table
        </button>
      </div>
      <div className={`opportunities_create_table_container`}>
        <table className={"adminuser_table"}>
          <thead className="adminuser_table__header">
            <tr className="adminuser_table__row">
              {tableOpportunitiesTagsHeader.map((item) => {
                return (
                  <th
                    key={item}
                    className={`text-nowrap ${item == "Priority" ? "ps-2" : "ps-4  ps-sm-5"
                      }  adminuser_table__heading`}
                  >
                    {item}
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody className="adminuser_table__body">
            {formikValues[name].length > 0 && formikValues[name]?.map((item, index) => {
              return (
                <tr key={index} className="">
                  <td className="text-nowrap ps-2 dark_title_color adminuser_table__data  ">
                    {item.priority}
                  </td>
                  <td className="text-nowrap ps-4 dark_title_color adminuser_table__data ">
                    {item.name}
                  </td>
                  <td className="text-nowrap ps-4 dark_title_color  adminuser_table__data">
                    {item.color_code ? item.color_code : item.color_code}
                  </td>

                  <td className="text-nowrap ps-4 adminuser_table__data  end-0">
                    <div className="d-flex gap-3">
                      <button
                        className="border-0 bg-transparent"
                        onClick={() => {
                          handleModalOpen(index)
                        }}
                        type="button"
                      >
                        <EditIcon fill="#112F6A" />
                      </button>
                      <button
                        className="border-0 bg-transparent"
                        type="button"
                        onClick={() =>
                          reasonDeleter(index)
                        }
                      >
                        <DeleteIcon fill="#D20000" />
                      </button>
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <ErrorMsg name={name} />
      <Tags
        formikValues={formikValues[name]}
        reasonUpdator={handleReasonUpdator}
        id={reasonEditId}
        setFieldValue={setFieldValue}
        handleClose={() => setShow(false)}
        show={show}
      />
    </div>
  );
};

export default OpportunitiesTagTable;
