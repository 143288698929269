import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import React, { useEffect, useRef, useState } from "react";
import "../our-partners.css"
import SaveBtn from "../../../../components/buttons/saveBtn";
import DeleteBtn from "../../../../components/buttons/deleteBtn";
import InnerLayout from "../../../../components/layouts/innerLayout/innerLayout";
import InnerContainer from "../../../../components/layouts/innerContainer/innerContainer";
import ArrowRightIcon from "../../../../assets/icons/arrowrightIcon"
import VersionHistoryBtn from "../../../../components/buttons/versionHistoryBtn";
import ApproveBtn from "../../../../components/buttons/approveBtn";
import RejectBtn from "../../../../components/buttons/rejectBtn";
import { useDispatch } from "react-redux";
import { parnterApproveAction, parnterRejectAction, viewParnterAction, viewParnterApprovalAction } from "../../../../redux/actions/ourParnterActions";
import { useNavigate, useParams } from "react-router-dom";
import errorToast from "../../../../components/utilits/errorToast";
import DownloadIcon from "../../../../assets/icons/downloadIcon";
import successToast from "../../../../components/utilits/successToast";
import LoadingSpinnerTable from "../../../../components/utilits/loadingSpinnerTable";
import moment from "moment";
import useScreenSizeDetector from "../../../../components/utilits/useScreenSizeDetector";
import LoadAndError from "../../../../components/utilits/LoadAndError";
import OurTeamProfileImage from "../../../../components/static-pages/our-team/modals/approvalProfileImage";

const OurPartnersApprovalView = () => {
  const navigate = useNavigate()
  const params = useParams()
  const [imageShow, setImageShow] = useState(false)
  const [file, setFile] = useState(null);
  const [image, setImage] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [updated, setUpdated] = useState(false);
  const [logo, setLogo] = useState(false);
  const [profile, setProfile] = useState(false);
  const handleBackImageShow = () => setImageShow(true);
  const handleImageClose = () => setImageShow(false);
  const [partnerData, setPartnerData] = useState({
    new_data: {
      name: "",
      id: "",
      name: "",
      designation: "",
      description: "",
      priority: "",
      createdAt: "",
      updatedAt: "",
    }
  });
  const formRef = useRef();
  const dispatch = useDispatch();
  const handleSubmit = () => {
    if (formRef.current) {
      formRef.current.handleSubmit();
    }
  };

  useEffect(() => {
    setLoading(true);
    dispatch(viewParnterApprovalAction(params.id, onSuccess, onError)
    );
  }, [params.id, updated]);
  const onSuccess = (data) => {
    setPartnerData(data.data);
    setLoading(false);
    console.log(data.data);
    setImage(data.data?.new_data?.photo_url
      .split("/").pop());
    setError(false)
  };
  const onError = (data) => {
    errorToast(data.data.data);
    setLoading(false);
    setError(true)
  };

  const approveParnter = () => {
    dispatch(
      parnterApproveAction({ user_Id: params?.id }, onApproveSuccess, onApproveError)
    );
  }
  const onApproveSuccess = (data) => {
    setUpdated(!updated)
    successToast(data.message)
    navigate('/static-pages/our-partners/approvals')
    setLoading(false)
    setError(false)
  };
  const onApproveError = (data) => {
    console.log(data);
    errorToast(data.data)
    setLoading(false)
    setError(true)
  };
  const rejectRole = () => {
    dispatch(
      parnterRejectAction(params?.id, onRejectSuccess, onRejectError)
    );
  }
  const onRejectSuccess = (data) => {
    setUpdated(!updated)
    successToast(data.message)
    navigate('/static-pages/our-partners/approvals')
    setLoading(false)
    setError(false)
  };
  const onRejectError = (data) => {
    console.log(data);
    errorToast(data.message)
    setLoading(false)
    setError(true)
  };

  console.log(partnerData);
  const initialValues = {
    profileImage: "",
    Name: "User name here",
    Designation: "Designation text here",
    LinkedIn: "0123asdf456jkl7w",
    Prirority: "1",
    Description:
      "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd.",
  };
  const loginSchema = Yup.object().shape({
    profileImage: Yup.mixed().required("Upload an Image"),
    Name: Yup.string().trim().required("Required"),
    Designation: Yup.string().trim().required("Required"),
    LinkedIn: Yup.string().trim().required("Required"),
    Prirority: Yup.string().trim().required("Required"),
    Description: Yup.string().trim().required("Required"),
  });
  const onSubmit = (values, { resetForm }) => {
    resetForm();
  };

  const buttonList = [
    <VersionHistoryBtn link="/static-pages/our-partners/version-history" />,
    <ApproveBtn approveFn={approveParnter} />,
    <RejectBtn showModal={rejectRole} />,
  ];
  const data = [
    {
      heading: "Approval ID",
      title: partnerData.id,
    },
    {
      heading: "Name",
      title: partnerData?.new_data == null ? partnerData?.old_data?.name : partnerData?.new_data?.name,
    },
    {
      heading: "Designation",
      title: partnerData?.new_data == null ? partnerData?.old_data?.designation : partnerData?.new_data?.designation,
    },
    {
      heading: "Linkdin URL",
      title: partnerData?.new_data == null ? partnerData?.old_data?.linkedin_url : partnerData?.new_data?.linkedin_url,
    },
    {
      heading: "Description",
      title: partnerData?.new_data == null ? partnerData?.old_data?.description : partnerData?.new_data?.description,
    },
    {
      heading: "Prirority",
      title: partnerData?.new_data == null ? partnerData?.old_data?.priority : partnerData?.new_data?.priority,
    },
    {
      heading: "Partners Id",
      title: partnerData?.new_data == null ? partnerData?.old_data?.id : partnerData?.new_data?.id,
    },
    {
      heading: "Approved At",
      title: moment(partnerData?.createdAt).format("DD-MM-YYYY HH:mm:ss"),
    },

    {
      heading: "Updated At",
      title: moment(partnerData?.updatedAt).format("DD-MM-YYYY HH:mm:ss"),
    },
    {
      heading: "Operation Type",
      title: partnerData?.new_data == null ? "Delete" : partnerData?.new_data && partnerData?.old_data ? "Edit" : partnerData?.old_data == null ? "Create" : ""
    }
  ];
  return (
    <InnerLayout buttons={buttonList}>
      <div className="notify-me-container">
        <InnerContainer error={error} oldData={partnerData?.old_data} newData={partnerData?.new_data}>
          {loading ? (
            <LoadingSpinnerTable />
          ) : (
            <LoadAndError loader={loading} error={error}>
              <Formik
                innerRef={formRef}
                initialValues={initialValues}
                onSubmit={onSubmit}
                validationSchema={loginSchema}
              >
                {({ formik, handleChange, values, setFieldValue }) => {
                  return (
                    <Form className="">
                      <div className="d-lg-flex">
                        <div className="pt-4 mt-2  ps-4 ps-lg-4 ms-3 col-6 ">
                          <div>
                            {data.map((item, index) => (
                              <div className="mb-3 ">
                                <h5 className="dark_grey_color fs_13 fn_Montserrat fw_500 m-0">
                                  {item.heading}
                                </h5>
                                <p
                                  className={` fs_13 fn_Nunito fw_500  ${item.heading === "Opportunity ID" ||
                                    item.heading === "LLP ID" ||
                                    item.heading === "Registration Number" ||
                                    item.heading === "Member Count"
                                    ? "secondary_color"
                                    : "dark_title_color"
                                    } `}
                                >
                                  {item.title}
                                </p>
                              </div>
                            ))}
                          </div>
                        </div>
                        <div className="ps-5">
                          <h5 className="dark_grey_color text-nowrap fs_13 fn_Montserrat fw_500">
                            Logo
                          </h5>
                          <div className="personal_information_image_container mt-2 position-relative">
                            <img
                              onClick={() => {
                                handleBackImageShow()
                                setLogo(true)
                                setProfile(false)
                              }}
                              // onClick={setLogo(true); setLogo(true)}
                              src={partnerData?.new_data == null ? partnerData?.old_data?.website_icon : partnerData?.new_data?.website_icon}
                              className="personal_information_img_size rounded-2"
                            />
                            <a
                              href={DownloadIcon}
                              download="file"
                            >
                              <button
                                // onClick={downloadImage}
                                type="button"
                                className="position-absolute border-0 bg-white d-flex align-items-center fs_10 top-0 end-0"
                              >
                                <DownloadIcon width={8} />
                                <span className="ms-1">Download</span>
                              </button>
                            </a>
                          </div>
                        </div>
                        <div className="ps-5">
                          <h5 className="dark_grey_color text-nowrap fs_13 fn_Montserrat fw_500">
                            Profile Picture
                          </h5>
                          <div className="personal_information_image_container mt-2 position-relative">
                            <img
                              onClick={() => {
                                handleBackImageShow()
                                setLogo(false)
                                setProfile(true)
                              }}
                              src={partnerData?.new_data == null ? partnerData?.old_data?.photo_url : partnerData?.new_data?.photo_url}
                              className="personal_information_img_size rounded-2"
                            />
                            <a
                              href={DownloadIcon}
                              download="file"
                            >
                              <button
                                type="button"
                                className="position-absolute border-0 bg-white d-flex align-items-center fs_10 top-0 end-0"
                              >
                                <DownloadIcon width={8} />
                                <span className="ms-1">Download</span>
                              </button>
                            </a>
                          </div>
                        </div>
                      </div>
                    </Form>
                  );
                }}
              </Formik>
            </LoadAndError>
          )}
        </InnerContainer>
      </div>
      {
        logo === true && (
          <OurTeamProfileImage
            handleClose={handleImageClose}
            show={imageShow}
            Profileimage={partnerData?.new_data == null ? partnerData?.old_data?.website_icon : partnerData?.new_data?.website_icon}
            modalHeading={"LOGO"}
          />
        )
      }
      {
        profile === true && (
          <OurTeamProfileImage
            handleClose={handleImageClose}
            show={imageShow}
            Profileimage={partnerData?.new_data == null ? partnerData?.old_data?.photo_url : partnerData?.new_data?.photo_url}
            modalHeading={"PROFILE PICTURE"}
          />
        )
      }
    </InnerLayout>
  );
};

export default OurPartnersApprovalView;