import { authHeader } from "../config";
import { api } from "./api";
export const addCommunityBadgesApi = (params) => {
  return api.post("/api/admin/badge/create", params, {
    headers: {
      "x-access-token": localStorage.getItem("accessToken"),
      "Content-Type": "application/json",
    },
  });
};
export const editCommunityBadgesApi = (id, params) => {
  return api.post(`/api/admin/badge/edit/${id}`, params, {
    headers: {
      "x-access-token": localStorage.getItem("accessToken"),
      "Content-Type": "application/json",
    },
  });
};
export const deleteCommunityBadgesApi = (id, params) => {
  return api.post(`/api/admin/badge/delete/${id}`, JSON.stringify(params), {
    headers: {
      "x-access-token": localStorage.getItem("accessToken"),
      "Content-Type": "application/json",
    },
  });
};
export const viewAllCommunityBadgesApi = (pageNo, params) => {
  return api.post(`/api/admin/badge/view-approvals?page_no=${pageNo}`, params, {
    headers: {
      "x-access-token": localStorage.getItem("accessToken"),
      "Content-Type": "application/json",
    },
  });
};
export const viewCommunityBadgesApi = (id = "", params) => {
  return api.post(
    `/api/admin/badge/view-approval/${id}`,
    JSON.stringify(params),
    {
      headers: {
        "x-access-token": localStorage.getItem("accessToken"),
        "Content-Type": "application/json",
      },
    }
  );
};
export const rejectCommunityBadgesApi = (id = "", params) => {
  return api.post(`/api/admin/badge/reject/${id}`, JSON.stringify(params), {
    headers: {
      "x-access-token": localStorage.getItem("accessToken"),
      "Content-Type": "application/json",
    },
  });
};
export const approveCommunityBadgesApi = (params) => {
  return api.post(`/api/admin/badge/approve`, params, {
    headers: {
      "x-access-token": localStorage.getItem("accessToken"),
      "Content-Type": "application/json",
    },
  });
};
export const approvalsListCommunityBadgesApi = (pageNo, params) => {
  return api.post(`/api/admin/badge/view-checker?page_no=${pageNo}`, params, {
    headers: {
      "x-access-token": localStorage.getItem("accessToken"),
      "Content-Type": "application/json",
    },
  });
};
export const viewApprovalCommunityBadgesApi = (id, params) => {
  return api.post(
    `/api/admin/badge/view-checker/${id}`,
    JSON.stringify(params),
    {
      headers: {
        "x-access-token": localStorage.getItem("accessToken"),
        "Content-Type": "application/json",
      },
    }
  );
};
export const versionHistoryListCommunityBadgesApi = (pageNo = 0, params) => {
  return api.post(`/api/admin/badge/view-version?page_no=${pageNo}`, params, {
    headers: {
      "x-access-token": localStorage.getItem("accessToken"),
      "Content-Type": "application/json",
    },
  });
};
export const viewVersionHistoryCommunityBadgesApi = (id, params) => {
  return api.post(
    `/api/admin/badge/view-version/${id}`,
    JSON.stringify(params),
    {
      headers: {
        "x-access-token": localStorage.getItem("accessToken"),
        "Content-Type": "application/json",
      },
    }
  );
};
export const communityBadgesTableExportApi = (params) => {
  return api.post(`/api/admin/badge/export-main`, JSON.stringify(params), {
    headers: {
      "x-access-token": localStorage.getItem("accessToken"),
      "Content-Type": "application/json",
    },
  });
};
export const communityBadgesApprovalsExportApi = (params) => {
  return api.post(`/api/admin/badge/export-approval`, JSON.stringify(params), {
    headers: {
      "x-access-token": localStorage.getItem("accessToken"),
      "Content-Type": "application/json",
    },
  });
};
export const communityBadgesVersionExportApi = (params) => {
  return api.post(`/api/admin/badge/export-version`, JSON.stringify(params), {
    headers: {
      "x-access-token": localStorage.getItem("accessToken"),
      "Content-Type": "application/json",
    },
  });
};
export const communityBadgesSubmitApi = (id, params) => {
  return api.post(`/api/admin/badge/on-submit/${id}`, JSON.stringify(params), {
    headers: {
      "x-access-token": localStorage.getItem("accessToken"),
      "Content-Type": "application/json",
    },
  });
};
