import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import LoadingSpinnerTable from "../../utilits/loadingSpinnerTable";
import errorToast from "../../utilits/errorToast";
import TablePagination from "../../table/tablePagination";
import "../../admin/tables/admintable.css";
import {
  tableUserPhoneRow,
  tableUsersPhoneHeader,
} from "../../table/tableData";
import { TableContainer } from "../../table/tableContainer";
import { userPhoneOtp } from "../../../redux/actions/userAction";
import LoadAndError from "../../utilits/LoadAndError";
import moment from "moment";

const UserPhoneOtpTableContent = ({ page, search, changePage }) => {
  const dispatch = useDispatch();
  const [phoneOtpData, setPhoneOtpData] = useState([]);
  const [loadingTable, setLoadingTable] = useState(false);
  const [error, setError] = useState(false);
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);
  const [pageData, setPageData] = useState({ noOfItems: 0, noOfPages: 1 })

  useEffect(() => {
    setLoadingTable(true);
    dispatch(userPhoneOtp({
      search: {
        user_id: search.userId,
        phone_number: search.phoneNumber,
      }
    }, page, onSuccess, onError));
  }, [page, search]);
  const onSuccess = (data) => {
    setPhoneOtpData(data.data.data);
    setPageData({
      noOfItems: data.data.count,
      noOfPages: data.data.pages
    })
    setError(false)
    setLoadingTable(false)
  };
  const onError = (data) => {
    errorToast(data.message);
    setError(true)
    setLoadingTable(false);
  };
  function handlePagination(type) {
    if (type === "+") {
      if (page + 1 < pageData.noOfPages) changePage("+");
    }
    else if (type === "-")
      if (page > 0) changePage("-");
  }
  return (
    <TableContainer width="88%">
      <div className="adminuser_table__container">
        {loadingTable && <LoadingSpinnerTable />}
        <table className={"adminuser_table"}>
          <thead className="adminuser_table__header">
            <tr className="adminuser_table__row">
              {tableUsersPhoneHeader.map((item) => {
                return (
                  <th
                    key={item}
                    className="text-nowrap adminuser_table__heading"
                  >
                    {item}
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody className="adminuser_table__body">
            <LoadAndError loader={loadingTable} error={error} status={phoneOtpData.length === 0}>
              {phoneOtpData.map((item, index) => {
                return (
                  <tr key={index} className="adminuser_table__row">
                    <td className="text-nowrap dark_title_color adminuser_table__data ">
                      {(page > 0) ? (12 * page + (index + 1)) : (index + 1)}
                    </td>
                    <td className="text-nowrap secondary_color adminuser_table__data">
                      {item.id}
                    </td>
                    <td className="text-nowrap secondary_color adminuser_table__data">
                      {item.user_id}
                    </td>
                    <td className="text-nowrap secondary_color adminuser_table__data">
                      {item.phone_number}
                    </td>
                    <td className="text-nowrap dark_title_color adminuser_table__data">
                      {item.country_code}
                    </td>
                    <td className="text-nowrap dark_title_color adminuser_table__data">
                      {moment(item.expiry_date).format("DD-MM-YYYY HH:mm:ss")}
                    </td>
                    <td className="text-nowrap dark_title_color adminuser_table__data">
                      {item.is_used === false ? "False" : "True"}
                    </td>
                    <td className="text-nowrap dark_title_color adminuser_table__data">
                      {moment(item.createdAt).format("DD-MM-YYYY HH:mm:ss")}
                    </td>
                    <td className="text-nowrap dark_title_color adminuser_table__data">
                      {moment(item.updatedAt).format("DD-MM-YYYY HH:mm:ss")}
                    </td>
                  </tr>
                );
              })}</LoadAndError>
          </tbody>
        </table>
      </div>
      <TablePagination
        paginate={handlePagination}
        currentPage={page}
        pageData={pageData}
      />
    </TableContainer>
  );
};

export default UserPhoneOtpTableContent;
