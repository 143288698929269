import React from 'react'
import EmptyTable from '../table/emptyTable'

const LoadAndError = ({ children, loader = false, error = false, status = false }) => {
    return (
        <>
            {loader === false && error? <EmptyTable status={status} error={error} /> : children}
        </>
    )
}

export default LoadAndError