import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "../../../admin/tables/admintable.css";
import errorToast from "../../../utilits/errorToast";
import { TableContainer } from "../../../table/tableContainer";
import TablePagination from "../../../table/tablePagination";
import LoadingSpinnerTable from "../../../utilits/loadingSpinnerTable";
import { tableLlpHeader, llpTableData } from "../../../table/tableData";
import { access } from "../../../utilits/access";
import { useDispatch } from "react-redux";
import {
  deleteLLPAction,
  viewLLPListAction,
} from "../../../../redux/actions/llpActions";
import DeleteModal from "../../../modals/deleteModal";
import successToast from "../../../utilits/successToast";
import usePermissionChecker from "../../../utilits/usePermissionChecker";
import LoadAndError from "../../../utilits/LoadAndError";

const LlpTableContent = ({ page, search, changePage }) => {
  const access = usePermissionChecker("maker");
  const [userListData, setUserListData] = useState([]);
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);
  const [show, setShow] = useState(false);
  const [deleteData, setDeleteData] = useState({ name: "", id: "" });
  const [loadingTable, setLoadingTable] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [pageData, setPageData] = useState({ noOfItems: 0, noOfPages: 1 });
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {
    setLoadingTable(true);
    dispatch(
      viewLLPListAction(
        {
          search: {
            llp_id: search?.value?.llpId,
            opportunity_id: search?.value?.opportunityId,
            name: search?.value?.name,
            registration_id: search?.value?.regId,
          },
        },
        page,
        onSuccess,
        onError
      )
    );
  }, [search, page]);
  const onSuccess = (data) => {
    setUserListData(data?.data?.data);
    console.log(data);
    setLoadingTable(false);
    setPageData({
      noOfItems: data.data.count,
      noOfPages: data.data.pages,
    });
    setError(false);
    setLoading(false);
  };
  const onError = (data) => {
    console.log(data);
    setLoadingTable(false);
    setError(true);
    setLoading(false);
  };
  //delete

  const handleDelete = () => {
    setLoadingDelete(true);
    dispatch(deleteLLPAction(deleteData?.id, onDeleteSuccess, onDeleteError));
  };
  const onDeleteSuccess = (data) => {
    successToast(data.message);
    setLoadingDelete(false);
    setShow(false);
    setError(false);
    setLoading(false);
  };
  const onDeleteError = (data) => {
    errorToast(data.message);
    setLoadingDelete(false);
    setError(true);
    setLoading(false);
  };

  // pagination
  function handlePagination(type) {
    if (type === "+") {
      if (page + 1 < pageData.noOfPages) changePage("+");
    } else if (type === "-") if (page > 0) changePage("-");
  }
  return (
    <TableContainer width="90%">
      <div className="adminuser_table__container">
        {loading && <LoadingSpinnerTable />}
        <table className={"adminuser_table"}>
          <thead className="adminuser_table__header">
            <tr className="adminuser_table__row">
              {tableLlpHeader.map((item) => {
                return (
                  <th
                    key={item + "x"}
                    className={`${
                      item === "Status"
                        ? `${access ? "" : "text-start"} position-sticky end-0 text-start`
                        : ""
                    } text-nowrap adminuser_table__heading `}
                  >
                    {item}
                  </th>
                );
              })}
            </tr>
          </thead>
          {loading === false && (
            <tbody className="adminuser_table__body">
              <LoadAndError
                loader={loading}
                error={error}
                status={userListData.length === 0}
              >
                {userListData.map((item, index) => {
                  return (
                    <tr key={item.id} className="adminuser_table__row">
                      <td className="text-nowrap dark_title_color adminuser_table__data ">
                        {index + 1}
                      </td>
                      <td className="text-nowrap secondary_color  adminuser_table__data ">
                        {item.id}
                      </td>
                      <td className="text-nowrap dark_title_color adminuser_table__data">
                        {item.opportunity_id}
                      </td>
                      <td className="text-nowrap dark_title_color  adminuser_table__data">
                        {item.name}
                      </td>
                      <td className="text-nowrap dark_title_color  adminuser_table__data">
                        {item.title}
                      </td>
                      <td className="text-nowrap  dark_title_color adminuser_table__data">
                        {item.reg_no}
                      </td>
                      <td className="text-nowrap  position-sticky end-0 adminuser_table__data">
                        <div className="d-flex w-100  justify-content-start gap-2">
                          <Link
                            className="text-decoration-none primary_color"
                            to={{
                              pathname: `/opportunity/llp/view/${item.id}`,
                            }}
                          >
                            View
                          </Link>
                          {access && (
                            <>
                              {item.editable && (
                                <>
                                  <span className="vr"></span>
                                  <Link
                                    className="text-decoration-none secondary_color"
                                    to={{
                                      pathname: `/opportunity/llp/edit/${item.id}`,
                                    }}
                                  >
                                    Edit
                                  </Link>  <span className="vr"></span>
                                  {item.lastActionApproved && (
                                    <>
                                      <button
                                        onClick={() => {
                                          setDeleteData({
                                            name: item.name,
                                            id: item.id,
                                          });
                                          handleShow();
                                        }}
                                        className="text-decoration-none border-0 bg-transparent primary_color light_red_color"
                                      >
                                        Delete
                                      </button>
                                    </>)}
                                </>)
                              }
                            </>
                          )}
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </LoadAndError>
            </tbody>
          )}
        </table>
      </div>

      <DeleteModal
        loader={loadingDelete}
        handleClose={handleClose}
        deleteFn={handleDelete}
        data={deleteData}
        show={show}
      />
      <TablePagination
        paginate={handlePagination}
        currentPage={page}
        pageData={pageData}
      />
    </TableContainer>
  );
};

export default LlpTableContent;
