import styled from "@emotion/styled";
export const TableContainer = styled('section')({
    maxWidth: "100%",
    boxShadow: " 0px 0px 10px #0000002E",
    borderRadius: "16px",
    margin: "1.5rem 0rem",
    "@media (max-width: 576px)": {
        maxWidth: "95%",
        width: "95%"
    },
    "@media (min-width: 2500px)": {
        width: "1800px",
    },
},
    props => ({
        maxWidth: props.maxWidth,
        minWidth: props.minWidth,
        width: props.width,
        margin: props.margin,
    })
);
