import React from "react";
import { useNavigate } from "react-router-dom";
import { AdminButton } from "./versionHistoryBtn";

const OpportunityBtn = ({ link }) => {
    const navigate = useNavigate()
    return (
        <AdminButton onClick={() => navigate(link)} padding="5px 10px" color="#112F6A">
            <span className="ms-2">OPPORTUNITY</span>
        </AdminButton>
    );
};

export default OpportunityBtn;
