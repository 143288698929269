import React from "react";
import styles from "./opportunitiesread.module.css";
import FileOpen from "../../../../assets/icons/key-icon.svg";

const OpportunitiesNote = ({ data }) => {
    const text = `Lorem ipsum dolor sit amet, consetetur sadipscing elitr,
    sed diam nonumy`;
    const openInNewTab = url => {
        window.open(url, '_blank', 'noopener,noreferrer');
    };
    return (
        <>
            <div
                className="d-lg-flex ms-lg-4 mt-lg-5 mt-4 justify-content-center "
                id="opportunitynote"
            >
                <div className="col-12 ">



                    <div
                        onClick={() =>
                            openInNewTab(FileOpen)

                        }
                        className={`d-flex align-items-center me-5 ${styles.input_file_field}  py-1  ps-3 `}
                    >
                        <button className="text-decoration-none bg-transparent border-0">
                            <span>
                                <img src={FileOpen} />
                            </span>

                            <span
                                className={` ${styles.fileopen_text} fn_Nunito text-nowrap fw_400 fs_14  ps-4 secondary_color `}
                            >
                                Click Here to open file - File Name Here
                            </span>
                        </button>
                    </div>
                    <ul
                        className={`popup_title_color fs_15  fn_Nunito fw_600 fw_600 p-0 ms-3 mb-lg-5`}
                    >
                        <li className="mb-2 pt-3">      <div
                            className="fn_Nunito fs_15 popup_title_color fw_600 p-0 ms-3"
                            dangerouslySetInnerHTML={{
                                __html: data?.description,
                            }}
                        ></div></li>
                    </ul>
                </div>

            </div>
        </>
    );
};

export default OpportunitiesNote;
