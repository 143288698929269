import React, { useState } from 'react'
import ApprovalsBtn from '../../../../components/buttons/approvalsBtn';
import CreateBtn from '../../../../components/buttons/createBtn';
import ExportBtn from '../../../../components/buttons/exportBtn';
import FilterBtn from '../../../../components/buttons/filterBtn';
import VersionHistoryBtn from '../../../../components/buttons/versionHistoryBtn';
import AdminUserFilter from '../../../../components/filter/adminUserFilter';
import NewsLtrFilter from '../../../../components/filter/static-pages filter/NewsLtrFilter';
import InnerLayout from '../../../../components/layouts/innerLayout/innerLayout';
import NewsLetterVersionHistoryTableContent from '../../../../components/static-pages/newsletter/tables/newLetterVersionHistoryTableContent';
import NewsLetterApprovalsTableContent from '../../../../components/static-pages/newsletter/tables/newsLetterApprovalsTableContent';
import NewsLetterTableContent from '../../../../components/static-pages/newsletter/tables/newsLettertableContent';
import { newsLetterApprovalsExportAction } from '../../../../redux/actions/newsletterActions';
                       
const NewsLetterApprovalTable = () => {
    const [page, setPage] = useState(0);
    const [showFilter, setShowFilter] = useState(false);
    const [search, setSearch] = useState({ value: "" });
    const handleFilterClose = () => {
      setShowFilter(false);     
    };
    const handleFilterOpen = () => {
      setShowFilter(true);
    };
    const handleSearch = (value) => {
      setSearch({ value: value });
      setPage(0)
    };
    const handlePage = (type) => {
      if (type === "+") {
        setPage((prev) => prev + 1)
      }
      else {
        setPage((prev) => prev - 1)
      }                                 
    }
    const buttonList = [

        <VersionHistoryBtn link={`/static-pages/newsletter/version-history`} />,
        <CreateBtn link={`/static-pages/newsletter/create`} />,
        <ExportBtn dispatchAction={newsLetterApprovalsExportAction} />,
        <FilterBtn filterFn={handleFilterOpen} />,
    ];
    return (
        <InnerLayout buttons={buttonList}>
            <NewsLtrFilter
                filter={handleSearch}
                showFilter={showFilter}
                closeFilter={handleFilterClose}
            />
            <NewsLetterApprovalsTableContent page={page} changePage={handlePage} search={search}  />
        </InnerLayout>
    )
}

export default NewsLetterApprovalTable