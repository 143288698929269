import { WISHLIST_APPROVALS_LIST, WISHLIST_APPROVALS_VIEW, WISHLIST_APPROVE, WISHLIST_CREATE, WISHLIST_DELETE, WISHLIST_EDIT, WISHLIST_LIST, WISHLIST_READ, WISHLIST_REJECT, WISHLIST_VERSION_LIST, WISHLIST_FIND_ALL, VIEW_APPROVALS_WISHLIST_ID, WISHLIST_VERSION_HISTORY_BY_ID, WISHLIST_EXPORT, WISHLIST_APPROVALS_EXPORT, WISHLIST_VERSION_EXPORT, WISHLIST_SUBMIT } from "./types";

export const createWishlist = (data, onSuccess, onError) => {
    return {
        type: WISHLIST_CREATE,
        data,
        onSuccess,
        onError,
    };
};
export const viewAllWishlist = (data, page_no = 0, onSuccess, onError) => {
    return {
        type: WISHLIST_LIST,
        data,
        page_no,
        onSuccess,
        onError,
    };
};

export const viewWishlist = (id, onSuccess, onError) => {
    return {
        type: WISHLIST_READ,
        id,
        onSuccess,
        onError,
    };
}
export const editWishlist = (id, data, onSuccess, onError) => {
    return {
        type: WISHLIST_EDIT,
        id,
        data,
        onSuccess,
        onError,
    };
}
export const approvalsWishlist = (data, pageNo = 0, onSuccess, onError) => {
    return {
        type: WISHLIST_APPROVALS_LIST,
        data,
        pageNo,
        onSuccess,
        onError,
    };
}
export const approvalsViewWishlist = (id, onSuccess, onError) => {
    return {
        type: WISHLIST_APPROVALS_VIEW,
        id,
        onSuccess,
        onError,
    };
}
export const approveWishlist = (id, onSuccess, onError) => {
    return {
        type: WISHLIST_APPROVE,
        id,
        onSuccess,
        onError,
    };
}
export const rejectWishlist = (id, onSuccess, onError) => {
    return {
        type: WISHLIST_REJECT,
        id,
        onSuccess,
        onError,
    };
}
export const deleteWishlist = (id, onSuccess, onError) => {
    return {
        type: WISHLIST_DELETE,
        id,
        onSuccess,
        onError,
    };
}
export const versionHistoryListWishlist = (data, pageNo, onSuccess, onError) => {
    return {
        type: WISHLIST_VERSION_LIST,
        data,
        pageNo,
        onSuccess,
        onError,
    };
}
export const viewVersionWishlist = (id, onSuccess, onError) => {
    return {
        type: WISHLIST_APPROVALS_VIEW,
        id,
        onSuccess,
        onError,
    };
}
//For dropdown of wishlist
export const viewWishListFindAll = (onSuccess, onError) => {
    return {
        type: WISHLIST_FIND_ALL,
        onSuccess,
        onError,
    };
}

export const viewApprovalWishlistById = (id, onSuccess, onError) => {
    return {
        type: VIEW_APPROVALS_WISHLIST_ID,
        id,
        onSuccess,
        onError,
    };
}

export const viewVersionHistoryById = (id, onSuccess, onError) => {
    return {
        type: WISHLIST_VERSION_HISTORY_BY_ID,
        id,
        onSuccess,
        onError,
    };
}


export const wishlistTableExport = (onSuccess, onError) => {
    return {
        type: WISHLIST_EXPORT,
        onSuccess,
        onError,
    };
};
export const wishlistApprovalsExport = (onSuccess, onError) => {
    return {
        type: WISHLIST_APPROVALS_EXPORT,
        onSuccess,
        onError,
    };
};
export const wishlistVersionExport = (onSuccess, onError) => {
    return {
        type: WISHLIST_VERSION_EXPORT,
        onSuccess,
        onError,
    };
};
export const wishlistSubmitAction = (id, onSuccess, onError) => {
    return {
      type: WISHLIST_SUBMIT,
      id,
      onSuccess,
      onError,
    };
  };