import React, { useEffect, useState } from 'react'
import InnerLayout from "../../../../components/layouts/innerLayout/innerLayout";
import InnerContainer from "../../../../components/layouts/innerContainer/innerContainer";
import VersionHistoryBtn from "../../../../components/buttons/versionHistoryBtn";
import ApprovalsBtn from "../../../../components/buttons/approvalsBtn";
import RejectBtn from "../../../../components/buttons/rejectBtn";
import ApproveBtn from "../../../../components/buttons/approveBtn";
import { useDispatch } from 'react-redux';
import errorToast from '../../../../components/utilits/errorToast';
import successToast from '../../../../components/utilits/successToast';
import moment from 'moment';
import { useParams, useNavigate } from "react-router-dom";
import { approveCategory, CategoryApprovalById, rejectCategory } from '../../../../redux/actions/faqCategoryActions';
import { approveBlogCategoryAction, blogCategoryApprovalByIdAction, rejectBlogCategoryAction } from '../../../../redux/actions/blogCategoryActions';
import LoadingSpinnerTable from '../../../../components/utilits/loadingSpinnerTable';
import LoadAndError from '../../../../components/utilits/LoadAndError';
import ArrowRightIcon from '../../../../assets/icons/arrowrightIcon';
import { JSONTree } from 'react-json-tree';

const BlogCategoryApprovalsDetails = () => {
  const params = useParams()
  const dispatch = useDispatch()
  const navigate = useNavigate();
  const [wishlistData, setWishlistData] = useState([]);
  const [wishlistNewData, setWishlistNewData] = useState({});
  const [wishlistOldData, setWishlistOldData] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    setLoading(true)
    dispatch(
      blogCategoryApprovalByIdAction(params.id, onSuccess, onError)
    );
  }, [])

  const onSuccess = (data) => {
    console.log(data, "fkfkkf");
    setWishlistData(data?.data);
    setWishlistNewData(data?.data.new_data);
    setWishlistOldData(data?.data.old_data);
    setLoading(false)
    setError(false);
  };
  const onError = (data) => {
    errorToast(data.data.message)
    //errorToast("went wrong")
    setLoading(false);
    setError(true);
  };

  console.log("The dtate", wishlistData);

  const FaqViewApprovalDetails = [
    {
      heading: "Approval ID",
      title: wishlistData?.id ? wishlistData?.id : "-",
    },
    {
      heading: "Blog Category ID",
      title: wishlistData?.new_data == null ? wishlistData?.old_data?.id : wishlistData?.new_data?.id,
    },
    {
      heading: "Blog Category Name",
      title: wishlistData?.new_data == null ? wishlistData?.old_data?.name : wishlistData?.new_data?.name,
    },
    {
      heading: "Priority",
      title: wishlistData?.new_data == null ? wishlistData?.old_data?.priority : wishlistData?.new_data?.priority,
    },
    {
      heading: "Created At",
      title: (wishlistData?.createdAt) ? moment(wishlistData?.createdAt).format("DD-MM-YYYY HH:mm:ss") : "--",
    },
    {
      heading: "Updated At",
      title: (wishlistData?.updatedAt) ? moment(wishlistData?.updatedAt).format("DD-MM-YYYY HH:mm:ss") : "--",
    },
    {
      heading: "Operation Type",
      title: wishlistData?.new_data == null ? "Delete" : wishlistData?.new_data && wishlistData?.old_data ? "Edit" : wishlistData?.old_data == null ? "Create" : ""
    }
  ];


  //Approval , reject sections

  const approveRole = () => {
    dispatch(
      approveBlogCategoryAction(wishlistData?.id, onApproveSuccess, onApproveError)
    );
  }

  const onApproveSuccess = (data) => {
    console.log(data.data);
    successToast(data.message)
    setLoading(false)
    setError(false);
    navigate("/blog-category/approval")
  };
  const onApproveError = (data) => {
    errorToast(data.message)
    //errorToast("went wrong")
    setLoading(false);
    setError(true);
  };

  //Reject Section
  const rejectWishListFn = () => {
    dispatch(
      rejectBlogCategoryAction(wishlistData?.id, onRejectSuccess, onRejectError)
    );
  }

  const onRejectSuccess = (data) => {
    successToast("Rejected successfully")
    setLoading(false)
    setError(false)
    navigate("/blog-category/approval")

  };
  const onRejectError = (data) => {
    errorToast(data.message)
    setLoading(false)
    setError(true);
  };
  const theme = {
    scheme: 'monokai',
    author: 'wimer hazenberg (http://www.monokai.nl)',
    base00: '#272822',
    base01: '#383830',
    base02: '#49483e',
    base03: '#75715e',
    base04: '#a59f85',
    base05: '#f8f8f2',
    base06: '#f5f4f1',
    base07: '#f9f8f5',
    base08: '#f92672',
    base09: '#464646',
    base0A: '#f4bf75',
    base0B: '#464646',
    base0C: '#a1efe4',
    base0D: '#464646',
    base0E: '#ae81ff',
    base0F: '#cc6633',
  };

  const buttonList = [<VersionHistoryBtn link='/blog-category/version-history' />, <ApproveBtn approveFn={approveRole} />, <RejectBtn showModal={rejectWishListFn} />];
  return (
    <InnerLayout buttons={buttonList}>
      <div className="user_view_container">
        <InnerContainer error={error} oldData={wishlistData?.old_data} newData={wishlistData?.new_data}>
          {loading ? (
            <LoadingSpinnerTable/>
          ) : (
          <LoadAndError loader={loading} error={error}>
          <div className="row mt-4">
            <div className="col-12 col-sm-12 col-md-9 col-lg-9 col-xl-7">
              <div className="row ps-1 ms-1 ps-lg-4 ms-lg-1">
                {FaqViewApprovalDetails.map((item, index) => (
                  <div className="mb-2 ">
                    <h5 className="dark_grey_color text-nowrap fs_13 fn_Montserrat fw_500">
                      {item.heading}
                    </h5>
                    <p
                      className={` fs_13 fn_Nunito  fw_500 ${item.heading === "Express Interest ID" ||
                        item.heading === "Opportunity ID" ||
                        item.heading === "Opportunity Name" ||
                        item.heading === "User ID" ||
                        item.heading === "User Name" ||
                        item.heading === "Order ID"
                        ? "secondary_color"
                        : "dark_title_color"
                        }`}
                    >
                      {item.title}
                    </p>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="white_shade_bg  py-1 d-flex">
                <div className="w-50">
                  <h5 className="fw_500 ps-4 dark_grey_color pt-2 text-nowrap fs_16 fn_Montserrat">
                    JSON Old Data
                  </h5>
                </div>
                <div className="w-50  d-none d-lg-block">
                  <h5 className="fw_500 ps-4  dark_grey_color pt-2 text-nowrap fs_16 fn_Montserrat">
                    JSON New Data
                  </h5>
                </div>
              </div>0
              <div className="row mt-3 ps-0 ps-lg-4">
                <div className="col-12 col-lg-6">
                  <div className="notify_me_aprvls_vrsn_hstry_data_container  mt-3 pt-3 px-3">
                    <div className="d-flex justify-content-between">
                      <h5 className="fs_13 fn_Nunito fw_400 dark_title_color text-nowrap">
                        Old Data
                      </h5>
                      <ArrowRightIcon width={25} height={25} fill={"#A5A5A5"} />
                    </div>
                    {
                      wishlistData.old_data === null ? (
                        <div>Empty data</div>
                      ) : (<>
                        <JSONTree theme={theme} data={wishlistOldData} />;
                      </>)
                    }
                  </div>
                </div>
                <div className="col-12 mt-5 mt-lg-0 col-lg-6">
                  <h5 className="fw_500 ps-4 d-block d-lg-none white_shade_bg  dark_grey_color py-3 text-nowrap fs_16 fn_Montserrat">
                    JSON New Data
                  </h5>
                  <div className="notify_me_aprvls_vrsn_hstry_data_container    mt-4 mt-lg-3 pt-3 px-3">
                    <div className="d-flex justify-content-between">
                      <h5 className="fs_13 fn_Nunito fw_400 dark_title_color py-2 text-nowrap">
                        New Data
                      </h5>
                      <ArrowRightIcon width={25} height={25} fill={"#A5A5A5"} />
                    </div>
                    <div className='h-100'>
                      {
                        wishlistData.new_data === null ? (
                          <div>Empty data</div>
                        ) : (<>
                          <JSONTree theme={theme} data={wishlistNewData} />;
                        </>)}
                    </div>
                  </div>
                </div>
              </div>
          </LoadAndError>
          )}
        </InnerContainer>
      </div>
    </InnerLayout>
  );
};

export default BlogCategoryApprovalsDetails;
