import React, { useState, useEffect } from "react";
import OpportunitiesAddSubBarSection from "./OpportunitiesAddSubBarSection";
import OpportunitiesContectEditFile from "./opportunitiesContectEditFile";
import OpportunitiesContectEditTable from "./opportunitiesContectEditTable";
import OpportunitiesContectEditText from "./opportunitiesContectEditText";
import { v4 as uuidv4 } from "uuid";

import { useDispatch, useSelector } from "react-redux";
import * as opportunityEditorActions from "../../../../redux/actions/opportunityEditorActions";
import * as defaultSectionValues from "../../../../redux/constants/opportunityJsonState";
import {
  uploadAddressProofFrontImageAction,
  uploadProfileImageAction,
} from "../../../../redux/actions/userAction";
import CloseIcon from "../../../../assets/icons/closeIcon";

const OpportunitiesSubSection = ({
  id,
  deleteSubSection,
  index,
  parentIndex,
  type = "edit"
}) => {

  const dispactch = useDispatch();
  const opportunityJsonReducer = useSelector(
    (state) => state.opportunityJsonReducer
  );

  const [data, setData] = useState(
    opportunityJsonReducer.data[parentIndex]?.subsections[index]
  );

  const [title, setTitle] = useState("");
  const [imageUpload, setImageUpload] = useState("");
  const [file, setFile] = useState("");

  useEffect(() => {
    var newData = opportunityJsonReducer.data[parentIndex]?.subsections[index];
    setData(newData);
    setTitle(newData?.title);
    setImageUpload(newData?.title_image);
  }, []);

  useEffect(() => {
    updateReducer();
  }, [data]);

  useEffect(() => {
    console.log(
      "OpportunitiesSubSection opportunityJsonReducer",
      opportunityJsonReducer
    );
  }, [opportunityJsonReducer]);

  const updateReducer = () => {
    // stateDate.data.
    dispactch(
      opportunityEditorActions.updateSubSection(index, parentIndex, data)
    );
  };
  const onTitleUpdate = (text) => {
    setTitle(text);
    setData({
      ...data,
      title: text,
    });
  };

  //call this function on image upload
  const onImageUpload = (url) => {
    setImageUpload(url);
    setData({
      ...data,
      title_image: url,
    });
  };

  const handleAddsubSection = (subSection) => {
    var val = { ...data };

    if (subSection == "text") {
      val?.content?.push({ ...defaultSectionValues.textSection, id: Date.now() });
    }
    if (subSection == "table") {
      val?.content?.push({
        ...defaultSectionValues.tableSection,
        id: Date.now(),
      });
    }
    if (subSection == "file") {
      val?.content?.push({ ...defaultSectionValues.fileSection, id: Date.now() });
    }
    setData({
      ...val,
    });
  };
  const handleDeletesubSection = (sIndex) => {
    var val = data?.content;
    val.splice(sIndex, 1);
    setData({
      ...data,
      content: [...val],
    });
  };

  function handleUploadOverviewFile(e) {
    if (e.target?.files.length !== 0) {
      setFile(e.target.files[0]);
      dispactch(
        uploadAddressProofFrontImageAction(
          e.target.files[0],
          onUploadFileSuccess,
          onUploadFileError
        )
      );
    }
    e.target.value = null;
  }
  const onUploadFileSuccess = (data) => {
    onImageUpload(data.data.data.location);
  };
  const onUploadFileError = (data) => {
    onImageUpload("");
    //something went wrong
    console.log(data);
  };


  return (
    <>
      <div className="w-100 d-flex justify-content-between mt-3">
        <span>Subsection Title Goes Here</span>

        <button type="button"
          onClick={() => {
            deleteSubSection(id);
          }}
          className="light_red_color fs_13 fw_500 border-0 white_shade_bg"
        >
          Delete
        </button>
      </div>

      <div className="oppor_content_edit__border">
        <div className="d-flex flex-column flex-sm-row">
          <div className="d-flex flex-column col-11 col-sm-5 me-4">
            <label htmlFor="subSectionTitle" className="ms-2 mb-2 mt-2">
              SubSection Title
            </label>
            <input
              value={title}
              onChange={(event) => {
                onTitleUpdate(event.target.value);
              }}
              id="subSectionTitle"
              type="text"
              className="oppor_content_edit__input rounded-3 "
            />
          </div>
          <div className="mt-2 mt-sm-0  d-flex  flex-column justify-content-center col-11 col-sm-5">
            <h6 className="mb-2 mt-2 ms-2">Icon</h6>
            <label
              className="oppor_content_edit_label__upload
              w-100 rounded-3 "
              htmlFor="icon"
            >
              {<span>{imageUpload != '' ? imageUpload : "+ Select File to Link"}</span>}
            </label>
            <input
              style={{ height: "0", visibility: "hidden" }}
              id="icon"
              type="file"
              className="position-absolute"
              onChange={(e) => {
                handleUploadOverviewFile(e);
              }}
            />
            {imageUpload != '' && (
              <span
                onClick={() => {
                  onImageUpload("");
                  setFile("");
                }}
              >
                <CloseIcon width={10} height={10} fill="#A5A5A5" />
              </span>
            )}
          </div>
        </div>

        {data?.content?.length === 0 && <hr className="mx-3 mt-4 mb-0" />}
        {data?.content?.length === 0 && (
          <OpportunitiesAddSubBarSection addSubSection={handleAddsubSection} />
        )}
        {data?.content?.length > 0 &&
          data?.content?.map((item, sIndex) => {
            if (item.type === "text") {
              // console.log(item.id);
              return (
                <OpportunitiesContectEditText
                  key={item.id}
                  deleteTheTextSection={handleDeletesubSection}
                  id={item.id}
                  index={sIndex}
                  sectionIndex={parentIndex}
                  subSectionIndex={index}
                />
              );
            }
            if (item.type === "table") {
              return (
                <OpportunitiesContectEditTable
                  key={item.id}
                  id={item.id}
                  index={sIndex}
                  sectionIndex={parentIndex}
                  subSectionIndex={index}
                  deleteTheTextSection={handleDeletesubSection}
                  data={item}
                />
              );
            }

            if (item.type === "file") {
              return (
                <OpportunitiesContectEditFile
                  key={item.id}
                  id={item.id}
                  index={sIndex}
                  sectionIndex={parentIndex}
                  subSectionIndex={index}
                  deleteTheTextSection={handleDeletesubSection}
                  data={item}
                />
              );
            }
          })}
        {data?.content?.length > 0 && (
          <OpportunitiesAddSubBarSection addSubSection={handleAddsubSection} />
        )}
      </div>
    </>
  );
};

export default OpportunitiesSubSection;
