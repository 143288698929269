import React, { useState } from 'react'
import CreateBtn from '../../../../components/buttons/createBtn'
import ExportBtn from '../../../../components/buttons/exportBtn'
import FilterBtn from '../../../../components/buttons/filterBtn'
import VersionHistoryBtn from '../../../../components/buttons/versionHistoryBtn'
import InnerLayout from '../../../../components/layouts/innerLayout/innerLayout'
import FaqCategoryApprovalTableContent from '../../../../components/static-pages/faqCategory/tables/faqCategoryApprovalTableContent'
import { useDispatch } from "react-redux";
import successToast from "../../../../components/utilits/successToast";
import errorToast from "../../../../components/utilits/errorToast";
import AdminUserFilter from "../../../../components/filter/adminUserFilter";
import BlogCategoryApprovalTableContent from '../../../../components/static-pages/blogCategory/tables/blogCategoryApprovalTableContent'
import BlogCategoryFilter from '../../../../components/filter/blog-pagesFilter.js/blogCategoryFilter'
import { blogCategoryApprovalsExportAction } from '../../../../redux/actions/blogCategoryActions'

const BlogCategoryApprovalTable = () => {
  const [page, setPage] = useState(0);                                           
  const [showFilter, setShowFilter] = useState(false);
  const [search, setSearch] = useState({ value: "" });
  const dispatch = useDispatch();
  const handleFilterClose = () => {
    setShowFilter(false);
  };
  const handleFilterOpen = () => {
    setShowFilter(true);
  };
  const handleSearch = (value) => {
    setSearch({ value: value });
    setPage(0)
  };
  const handlePage = (type) => {
    if (type === "+") {
      setPage((prev) => prev + 1)
    }
    else {
      setPage((prev) => prev - 1)
    }
  }
  const buttonList = [
    <VersionHistoryBtn link='/blog-category/version-history' />,
    <CreateBtn link='/blog-category/create' />,
    <ExportBtn dispatchAction={blogCategoryApprovalsExportAction} />,
    <FilterBtn filterFn={handleFilterOpen} />
  ]
  return (                                                 
    <InnerLayout buttons={buttonList}>
      <BlogCategoryFilter
        filter={handleSearch}
        showFilter={showFilter}
        closeFilter={handleFilterClose}
      />
      <BlogCategoryApprovalTableContent page={page} changePage={handlePage} search={search} />
    </InnerLayout>
  )
}

export default BlogCategoryApprovalTable