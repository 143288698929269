import React, { useEffect, useRef, useState } from "react";
import DeleteBtn from "../../../components/buttons/deleteBtn";
import PreviewBtn from "../../../components/buttons/previewBtn";
import PublishBtn from "../../../components/buttons/publishBtn";
import SaveBtn from "../../../components/buttons/saveBtn";
import InputField from "../../../components/form/inputFields/inputField";
import InnerContainer from "../../../components/layouts/innerContainer/innerContainer";
import InnerLayout from "../../../components/layouts/innerLayout/innerLayout";
import "./opportunities.css";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import SelectFields from "../../../components/form/selectFields/selectFields";
import CreateTable from "../../../components/opportunity/opportunties/tables/opporttunitiesCreateTable";
import ErrorMsg from "../../../components/form/errorMsg";
import successToast from "../../../components/utilits/successToast";
import * as opportunitiesAction from "../../../redux/actions/opportunitiesActions";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import OpportunitiesTagTable from "../../../components/opportunity/opportunties/tables/opportunitiesTagTable";
import Tags from "../../../components/opportunity/opportunties/modals/tags";
import OpportunitiesAssetsTable from "../../../components/opportunity/opportunties/tables/opportunitiesAssetsTable";
import OpportunitiesOverviewTable from "../../../components/opportunity/opportunties/tables/opportunitiesOverviewTable";
import OpportunitiesOverviewCardTable from "../../../components/opportunity/opportunties/tables/opportunitiesOverviewCardTable";
import OpportunitiesPayoutsTable from "../../../components/opportunity/opportunties/tables/opportunitiesPayoutsTable";
import OpportunitiesOdometerTable from "../../../components/opportunity/opportunties/tables/opportunitiesOdometerTable";
import OpportunitiesInsightsTable from "../../../components/opportunity/opportunties/tables/opportunitiesInsightsTable";
import OpportunitiesFaqTable from "../../../components/opportunity/opportunties/tables/opportunitiesfaqTable";
import { uploadAddressProofFrontImageAction } from "../../../redux/actions/userAction";
import CloseIcon from "../../../assets/icons/closeIcon";
import EmptyTable from "../../../components/table/emptyTable";
import LoadingSpinnerTable from "../../../components/utilits/loadingSpinnerTable";
import AdminRoleCheckBox from "../../../components/utilits/adminRoleCheckbox";
import Switch from "react-switch";
import SubmitBtn from "../../../components/buttons/submit";
import errorToast from "../../../components/utilits/errorToast";
import Compressor from 'compressorjs';
const OpportunitiesCreate = ({ data = {} }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const formRef = useRef();
  const amountRegex = /^[0-9]*$/;
  const [opportunitiesType, setOpportunitiesType] = useState([]);
  const [checked, setChecked] = useState(false);
  const handleChange = (nextChecked) => {
    setChecked(nextChecked);
  };
  const [file, setFile] = useState("");
  const [fileUrl, setFileUrl] = useState({
    url: "",
    error: false,
  });
  const statusOptions = [
    { label: "open", value: "open" },
    { label: "closed", value: "closed" },
    { label: "soon", value: "soon" },
    { label: "membertrade", value: "membertrade" },
  ];
  const initialValues = {
    title: "",
    status: "",
    tagline: "",
    assets: [],
    overview: [],
    payouts: [],
    odometer_section: [],
    insights: [],
    content: [],
    summary: "",
    member_trade: false,
    opportunity_type_name: "",
    available_from_date: "",
    available_till_date: "",
    referral: false,
    total_funding_amount: null,
    total_funded_amount: null,
    interest_value_interval: null,
    min_investment_value: null,
    payout_duration: "",
    tenure: null,
    monthly_payout: null,
    last_month_payout: null,
    post_tax_irr: null,
    display_image: "",
    card_icons: [],
    faq: [],
    tags: [],
    shareable_link: "",
    // insights:""
  };
  const today = new Date();
  today.setHours(0, 0, 0, 0);
  const loginSchema = Yup.object().shape({
    title: Yup.string().trim().required("Required"),
    opportunity_type_name: Yup.string().trim().required("Required"),
    status: Yup.string().trim().required("Required"),
    // tagline: Yup.string().trim().required("Required"),
    // summary: Yup.string().trim().required("Required"),
    available_from_date: Yup.date()
      // .min(today, "Date cannot be in the past")
      .required("Required"),
    available_till_date: Yup.date()
      .min(
        Yup.ref("available_from_date"),
        "End date can't be before Start date"
      )
      .required("Required"),
    // referral: Yup.string().trim().required("Required"),
    total_funding_amount: Yup.number().typeError("invalid value").nullable(),
    // .moreThan(
    //   Yup.ref("total_funded_amount"),
    //   "Must be greater than Total Funded Amount"
    // ),
    // .required("Required"),
    total_funded_amount: Yup.number().typeError("invalid value").nullable(),
    // .required("Required"),
    //   .matches(amountRegex, "Please Enter Valid Amount"),
    // interest_value_interval: Yup.string()
    //   .required("Required")
    //   .matches(amountRegex, "Please Enter Valid Amount"),
    // tenure: Yup.string()
    //   .required("Required")
    //   .matches(amountRegex, "Please Enter Valid Months"),
    // monthly_payout: Yup.string()
    //   .required("Required")
    //   .matches(amountRegex, "Please Enter Valid Amount"),
    // last_month_payout: Yup.string()
    //   .required("Required")
    //   .matches(amountRegex, "Please Enter Valid Amount"),
    // post_tax_irr: Yup.number()
    //   .typeError("you must specify a number")
    //   .min(0, "Min value 0.")
    //   .max(100, "Max value 100.")
    //   .required("Required"),
    display_image: Yup.string(),
    // payout_duration: Yup.string().trim().required("Required"),
    // assets: Yup.array().min(1, "assets table can't be empty"),
    // tags: Yup.array().min(1, "Tags table can't be empty"),
    // overview: Yup.array().min(1, "Overview table can't be empty"),
    // payouts: Yup.array().min(1, "Payouts table can't be empty"),
    // odometer_section: Yup.array().min(1, "Odometer table can't be empty"),
    // card_icons: Yup.array().min(1, "Overview card table can't be empyty"),
    // faq: Yup.array().min(1, "FAQ table can't be empyty"),
    // tags: Yup.array().min(1, "Tags table can't be empyty"),
  });
  const handleSubmit = () => {
    if (formRef.current) {
      if (!fileUrl.url) {
        console.log("afgasf");
        setFileUrl({ url: "", error: true });
      } else {
        formRef.current.handleSubmit();
      }
    }
  };
  const onSubmit = (values) => {
    console.log(values);
    if (fileUrl.url) {
      dispatch(
        opportunitiesAction.createOpportunitiesAction(
          {
            title: values.title,
            status: values.status,
            tagline: values.tagline,
            assets: values.assets,
            overview: values.overview,
            payouts: values.payouts,
            odometer_section: values.odometer_section,
            content: values.content,
            summary: values.summary,
            member_trade: values.member_trade,
            member_trade_coming_soon: checked,
            // insights: values.insights,
            opportunity_type_id: values.opportunity_type_name,
            available_from_date: values.available_from_date,
            available_till_date: values.available_till_date,
            referral: values.referral,
            total_funding_amount: values.total_funding_amount,
            total_funded_amount: values.total_funded_amount,
            interest_value_interval: values.interest_value_interval,
            payout_duration: values.payout_duration,
            tenure: values.tenure,
            monthly_payout: values.monthly_payout,
            last_month_payout: values.last_month_payout,
            post_tax_irr: values.post_tax_irr,
            display_image: fileUrl.url,
            card_icons: values.card_icons,
            faq: values.faq,
            tags: values.tags,
            shareable_link: values.shareable_link,
          },
          onSubmitSuccess,
          onSubmitError
        )
      );
      setFileUrl({ url: "", error: false });
      setFile("");
    } else {
      setFileUrl({ url: "", error: true });
    }
  };
  useEffect(() => {
    dispatch(
      opportunitiesAction.fetchOpportunitiesType(onDropSuccess, onDropError)
    );
  }, []);

  const onDropSuccess = (data) => {
    const oppTypes = [];
    data?.data.map((item, index) => {
      oppTypes[index] = { value: item.id, label: item.name };
    });
    setOpportunitiesType(oppTypes);
  };
  const onDropError = (msg) => {
    console.log(msg);
  };
  const onSubmitSuccess = (data) => {
    successToast(data.message);
    navigate(`/opportunity/opportunities/edit/${data.data.Opportunities_id}`);
  };
  const onSubmitError = (data) => {
    console.log(data);
  };
  function handleUploadDisplayFile(e) {
    if (e.target?.files.length !== 0) {
      setFile(e.target.files[0]);
      new Compressor(e.target.files[0], {
        quality: 0.8,
        success(result) {
          console.log(result);
          const file = new File([result], result.name, { type: result.type })
          dispatch(
            uploadAddressProofFrontImageAction(
              file,
              onUploadFileSuccess,
              onUploadFileError
            )
          );
        }
      })
    }
    e.target.value = null;
  }
  const onUploadFileSuccess = (data) => {
    setFileUrl({ url: data.data.data.location, error: false });
  };
  const onUploadFileError = (data) => {
    setFileUrl({ url: "", error: true });
    console.log(data);
  };
  const buttonList = [<SaveBtn submitFn={handleSubmit} />];
  return (
    <>
      <InnerLayout buttons={buttonList}>
        <InnerContainer containerClassName={"oppor_edit_inner__container"}>
          <div className="px-3 px-lg-4 mx-0 mx-lg-3 ">
            <Formik
              innerRef={formRef}
              initialValues={initialValues}
              onSubmit={onSubmit}
              validationSchema={loginSchema}
              enableReinitialize={true}
            >
              {({ setFieldValue, values }) => (
                <Form>
                  <div className="opportunities-form-width">
                    <div className="row">
                      <div className="col-12 col-md-6">
                        <InputField
                          id={"title"}
                          label={"Title"}
                          type={"text"}
                          placeholder={"Enter Title"}
                          name={"title"}
                        />
                        <SelectFields
                          label={"Opportunity Type"}
                          placeholder={"Select Opportunity Type"}
                          name={"opportunity_type_name"}
                          options={opportunitiesType}
                          id={"opportunity_type_name"}
                        />
                        <SelectFields
                          label={"Status"}
                          placeholder={"Select Opportunity Status"}
                          name={"status"}
                          options={statusOptions}
                          id={"status"}
                        />
                        <InputField
                          id={"tagline"}
                          label={"Tagline / Special Offer"}
                          type={"text"}
                          placeholder={"Enter TaglineTagline / Special Offer"}
                          name={"tagline"}
                        />
                        <OpportunitiesTagTable
                          tableTitle={"Tags"}
                          setFieldValue={setFieldValue}
                          formikValues={values}
                          name={"tags"}
                        />
                        <OpportunitiesAssetsTable
                          tableTitle={"Assets"}
                          setFieldValue={setFieldValue}
                          formikValues={values}
                          name={"assets"}
                        />
                        <OpportunitiesOverviewTable
                          tableTitle={"Overview"}
                          setFieldValue={setFieldValue}
                          formikValues={values}
                          name={"overview"}
                        />
                      </div>
                      <div className="col-12 col-md-6">
                        <div className="d-flex flex-column ms-4 mb-4">
                          <span className="dark_grey_color fn_Montserrat fw_500 fs_14">
                            Member Trade
                          </span>
                          <div className="d-flex align-items-center">
                            <span className="me-2">
                              {checked ? "ON" : "OFF"}
                            </span>
                            <Switch
                              height={20}
                              width={50}
                              borderRadius={20}
                              uncheckedIcon={false}
                              checkedIcon={false}
                              offColor="#A5A5A5"
                              onColor="#A5A5A5"
                              offHandleColor="#FFFFFF"
                              onHandleColor="#FFFFFF"
                              onChange={handleChange}
                              checked={checked}
                            />
                          </div>
                        </div>
                        <div className="ps-0 ps-lg-4">
                          <label
                            id="summary"
                            className="mb-2 dark_grey_color fn_Montserrat fw_500 fs_14 ps-2"
                          >
                            Summary
                          </label>
                          <Field name={"summary"}>
                            {({ field }) => (
                              <textarea
                                id="summary"
                                {...field}
                                type="text"
                                placeholder="Enter text"
                                className={
                                  "w-100 opportunities_create_summary_text_area p-2 ps-3   "
                                }
                              ></textarea>
                            )}
                          </Field>
                          <ErrorMsg name={"summary"} />

                          <div className="row">
                            <div className="col-xl-6 col-lg-12 col-md-12">
                              <InputField
                                id={"available_from_date"}
                                label={"Available from"}
                                type={"date"}
                                placeholder={"Select from date"}
                                name={"available_from_date"}
                              />
                            </div>
                            <div className="col-xl-6 col-lg-12 col-md-12">
                              <InputField
                                id={"available_till_date"}
                                label={"Available Till"}
                                type={"date"}
                                placeholder={"Select Till date"}
                                name={"available_till_date"}
                              />
                            </div>
                          </div>
                          <SelectFields
                            label={"Referral"}
                            placeholder={"Referal Enable/Disable"}
                            name={"referral"}
                            options={referralType}
                            id={"referral"}
                          />
                          <div className="row">
                            <div className="col-xl-6 col-lg-12 col-md-12">
                              <InputField
                                id={"total_funding_amount"}
                                label={"Total Funding Amount"}
                                type={"text"}
                                placeholder={"Enter Funding Amount"}
                                name={"total_funding_amount"}
                              />
                            </div>
                            <div className="col-xl-6 col-lg-12 col-md-12">
                              <InputField
                                id={"total_funded_amount"}
                                label={"Total Funded Amount"}
                                type={"text"}
                                placeholder={"Enter Funded Amount"}
                                name={"total_funded_amount"}
                              />
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-xl-6 col-lg-12 col-md-12">
                              <InputField
                                id={"interest_value_interval"}
                                label={"Interest Value"}
                                type={"text"}
                                placeholder={"Enter Amount"}
                                name={"interest_value_interval"}
                              />
                            </div>
                            <div className="col-xl-6 col-lg-12 col-md-12">
                              <InputField
                                id={"tenure"}
                                label={"Tenure"}
                                type={"text"}
                                placeholder={"Enter Number Months"}
                                name={"tenure"}
                              />
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-xl-6 col-lg-12 col-md-12">
                              <InputField
                                id={"monthly_payout"}
                                label={"Monthly Payout"}
                                type={"text"}
                                placeholder={"Enter Funding Amount"}
                                name={"monthly_payout"}
                              />
                            </div>
                            <div className="col-xl-6 col-lg-12 col-md-12">
                              <InputField
                                id={"last_month_payout"}
                                label={"Last Month payout"}
                                type={"text"}
                                placeholder={"Enter Funded Amount"}
                                name={"last_month_payout"}
                              />
                            </div>
                          </div>
                          <InputField
                            id={"post_tax_irr"}
                            label={"Post Tax IRR"}
                            type={"text"}
                            placeholder={"Enter Percentage %"}
                            name={"post_tax_irr"}
                          />
                          <div className="mb-4">
                            <span className="dark_grey_color fn_Montserrat fw_500 fs_14   ps-2">
                              Display Image
                            </span>
                            <div className="llp_document_upload d-flex justify-content-between mt-2  align-items-center pe-3">
                              <input
                                className="position-absolute"
                                type={"file"}
                                style={{ visibility: "hidden" }}
                                id="displayfile"
                                name="fileUrl"
                                onChange={(e) => {
                                  handleUploadDisplayFile(e);
                                }}
                              />
                              <label
                                className=" w-75 py-2 ps-3  "
                                htmlFor="displayfile"
                              >
                                {
                                  <span className="fs_14 word_wrap">
                                    {fileUrl.url
                                      ? file.name
                                      : "Select Display image"}
                                  </span>
                                }
                              </label>
                              {fileUrl.url && (
                                <span
                                  onClick={() => {
                                    setFileUrl({ url: "", error: true });
                                    setFile("");
                                  }}
                                >
                                  <CloseIcon
                                    width={10}
                                    height={10}
                                    fill="#A5A5A5"
                                  />
                                </span>
                              )}
                            </div>
                            {fileUrl.error && (
                              <span className="fs_12 ms-2 text-danger fw-semibold fn_Nunito">
                                Required!
                              </span>
                            )}
                          </div>
                          <OpportunitiesOverviewCardTable
                            tableTitle={"Overview-Card"}
                            setFieldValue={setFieldValue}
                            formikValues={values}
                            name={"card_icons"}
                          />
                          <SelectFields
                            label={"Payout Duration"}
                            placeholder={"Select Payout Duration"}
                            name={"payout_duration"}
                            options={payoutDuration}
                            id={"payout_duration"}
                          />
                        </div>
                      </div>
                    </div>
                    <OpportunitiesPayoutsTable
                      tableTitle={"Payouts"}
                      name={"payouts"}
                      setFieldValue={setFieldValue}
                      formikValues={values}
                    />
                    <div className="row">
                      <div className="col-12 col-md-6">
                        <OpportunitiesOdometerTable
                          tableTitle={"Odometer Highlights"}
                          setFieldValue={setFieldValue}
                          formikValues={values}
                          name={"odometer_section"}
                        />
                        <OpportunitiesInsightsTable
                          tableTitle={"insights"}
                          setFieldValue={setFieldValue}
                          formikValues={values}
                          name={"insights"}
                        />
                      </div>
                      <div className="col-12 col-md-6">
                        <OpportunitiesFaqTable
                          tableTitle={"FAQ"}
                          setFieldValue={setFieldValue}
                          formikValues={values}
                          name={"faq"}
                        />
                      </div>
                    </div>
                  </div>
                  {/* <CreateTable
                    tableTitle={"Content"}
                    containerText="Add content"
                  /> */}
                </Form>
              )}
            </Formik>
          </div>
        </InnerContainer>
      </InnerLayout>
    </>
  );
};

export default OpportunitiesCreate;

// export const opportunitiesType = [
//   { value: "Type1", label: "Type1" },
//   { value: "Type2", label: "Type2" },
//   { value: "Type3", label: "Type3" },
//   { value: "Type4", label: "Type4" },
// ];
export const status = [
  { value: "pending", label: "pending" },
  { value: "verified", label: "verified" },
];
export const referralType = [
  { value: true, label: "Enable" },
  { value: false, label: "Disable" },
];
export const imageType = [
  { value: "image1", label: "image1" },
  { value: "image2", label: "image2" },
];
export const payoutDuration = [
  { value: "monthly", label: "monthly" },
  { value: "quarterly", label: "Quarterly" },
  { value: "half", label: "Half" },
  { value: "yearly", label: "Yearly" },
];
