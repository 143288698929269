import React, { useRef, useState, useEffect, } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import InnerLayout from "../../../../components/layouts/innerLayout/innerLayout";
import InnerContainer from "../../../../components/layouts/innerContainer/innerContainer";
import SelectFields from "../../../../components/form/selectFields/selectFields";
import ArrowDownIcon from "../../../../assets/icons/arrowdown";
import { useParams, useNavigate } from "react-router-dom";
import SaveBtn from "../../../../components/buttons/saveBtn";
import DeleteBtn from "../../../../components/buttons/deleteBtn";
import InputField from "../../../../components/form/inputFields/inputField";
import { useDispatch } from 'react-redux';
import successToast from "../../../../components/utilits/successToast";
import errorToast from "../../../../components/utilits/errorToast";
import { deleteCategory, editCategory, viewCategoryDetail } from "../../../../redux/actions/faqCategoryActions";
import ErrorMsg from "../../../../components/form/errorMsg";
import { blogCategorySubmit, deleteBlogCategoryAction, editBlogCategoryAction, viewBlogCategoryDetailAction } from "../../../../redux/actions/blogCategoryActions";
import LoadingSpinnerTable from "../../../../components/utilits/loadingSpinnerTable";
import LoadAndError from "../../../../components/utilits/LoadAndError";
import SubmitBtn from "../../../../components/buttons/submit";

const BlogCategoryEdit = () => {
  const params = useParams();
  const navigate = useNavigate();
  const [submitData, setSubmitData] = useState();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [deleteData, setDeleteData] = useState();
  const [wishlistData, setWishlistData] = useState([]);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const dispatch = useDispatch();

  useEffect((id) => {
    setLoading(true)
    dispatch(viewBlogCategoryDetailAction(params?.id, onSuccess, onError));
  }, [])

  //For viewing
  const onSuccess = (data) => {
    console.log("Hey", data.data);
    setLoading(false);
    setError(false);
    setWishlistData(data.data);

  };
  const onError = (data) => {
    setLoading(false);
    setError(true);
    errorToast(data.message)
  };

  const formRef = useRef();

  const initialValues = {
    faqCategoryName: wishlistData?.name,
    blogCategoryTitle: wishlistData?.title,
    blogCategorySubtitle: wishlistData?.subtitle,
    blogCategoryDesc: wishlistData?.description,
    priority: wishlistData?.priority,
  };

  const interestEditSchema = Yup.object().shape({
    faqCategoryName: Yup.string().trim().required("Required"),
    blogCategoryTitle: Yup.string().trim(),
    blogCategorySubtitle: Yup.string().trim(),
    blogCategoryDesc: Yup.string().trim(),
    priority:Yup.number()
    .positive("Priority should be greater than  zero").nullable()
  });
  const handleSubmit = () => {
    if (formRef.current) {
      formRef.current.handleSubmit();
    }
  };
  const onSubmit = (values, { resetForm }) => {
    setLoading(true)
    dispatch(
      editBlogCategoryAction(params?.id, {
        "name": values.blogCategoryName,
        "title": values.blogCategoryTitle,
        "subtitle": values.blogCategorySubtitle,
        "description": values.blogCategoryDesc,
        priority :values.priority? values.priority : null

      }, onSuccessEdit, onErrorEdit)
    );
  };
  //Edit
  const onSuccessEdit = (data) => {
    console.log(data.data);
    setLoading(false);
    setError(false);
    setSubmitData(data.data.id)
    setSaveHide(true)
    setWishlistData(data.data)
    successToast(data.message)
  };
  const onErrorEdit = (data) => {
    setLoading(false)
    errorToast(data.data.message)
    setError(true);
    // errorToast(data.data.message)
  };

  //For deletion
  const onDeleteSuccess = (data) => {
    console.log(data.data);
    setLoading(false);
    setLoading(false);
    successToast(data.message)
    navigate("/blog-category/approval-table")
  };
  const onDeleteError = (data) => {
    setLoading(false)
    errorToast(data.data.message)
    setError(true);
  };

  const handleDelete = () => {
    setLoadingDelete(true);
    dispatch(
      deleteBlogCategoryAction(
        deleteData.id,
        onDeleteSuccess,
        onDeleteError
      )
    );
  };

  //on submit api
  console.log(submitData);
  const [saveHide, setSaveHide] = useState(false);

  const submitFnc = () => {
    dispatch(blogCategorySubmit
      (
        submitData,
        onSubmitSuccess,
        onSubmitError,
      ));
  };
  const onSubmitSuccess = (data) => {
    console.log(data);
    successToast(data.message)
    navigate("/blog-category")
  };
  const onSubmitError = (data) => {
    console.log("ERROR==================", data);
    errorToast(data.message)

  };

  const buttonList = [!saveHide && <SaveBtn submitFn={handleSubmit}
    loader={loading} />,
  <SubmitBtn submitFn={submitFnc} saveHide={saveHide} />
    // <DeleteBtn showModal={handleShow}/>
  ];
  return (
    <InnerLayout buttons={buttonList}>
      <div className="user_view_container">
        <InnerContainer>
          {loading ? (
            <LoadingSpinnerTable />
          )
            :
            (
              <LoadAndError loader={loading} error={error}>
                <div className="row mt-2">
                  <div className="col-12 col-sm-12 col-md-9 col-lg-9 col-xl-7">
                    <div className="row ps-1 ms-1 ps-lg-4 ms-lg-1">
                      <div className="ps-2  pt-3">
                        <h5 className="dark_grey_color text-nowrap fs_13 fn_Montserrat fw_500">
                          Blog Category ID
                        </h5>
                        <p
                          className={` fs_13 fn_Nunito text-nowrap fw_500 secondary_color`}
                        >
                          {wishlistData?.id}
                        </p>

                        <Formik
                          innerRef={formRef}
                          initialValues={initialValues}
                          onSubmit={onSubmit}
                          enableReinitialize={true}
                          validationSchema={interestEditSchema}
                        >
                          {({ formik, handleChange, values, setFieldValue }) => {
                            return (
                              <Form className="">
                                <div className="   express-form-width">
                                  <InputField
                                    id={"faqCategoryName"}
                                    label={"Blog Category Name"}
                                    type={"text"}
                                    placeholder={"Enter FAQ Category Name here"}
                                    name={"faqCategoryName"}
                                  />
                                  <InputField
                                    id={"blogCategoryTitle"}
                                    label={"Blog Category Title"}
                                    type={"text"}
                                    placeholder={"Enter Blog Category Title"}
                                    name={"blogCategoryTitle"}
                                  />
                                  <InputField
                                    id={"blogCategorySubtitle"}
                                    label={"Blog Category Subtitle"}
                                    type={"text"}
                                    placeholder={"Enter Blog Category Subtitle"}
                                    name={"blogCategorySubtitle"}
                                  />
                                  <InputField
                                    id={"priority"}
                                    label={"Priority"}
                                    type={"text"}
                                    placeholder={"Enter Priority"}
                                    name={"priority"}
                                  />
                                </div>
                                <div className="   express-form-width">
                                  <label
                                    id="blogCategoryDesc"
                                    className="mb-2 dark_black_color fn_Montserrat mt-2 fw_400 fs_16 ps-2"
                                  >
                                    Blog Category Description
                                  </label>
                                  <Field name={"blogCategoryDesc"}>
                                    {({ field }) => (
                                      <textarea
                                        id="blogCategoryDesc"
                                        {...field}
                                        type="text"
                                        placeholder="Enter the blog category description"
                                        className={"w-100 faq_message_container p-2 "}
                                      ></textarea>
                                    )}
                                  </Field>
                                  <ErrorMsg name={"blogCategoryDesc"} />
                                </div>
                              </Form>
                            );
                          }}
                        </Formik>
                      </div>
                    </div>
                  </div>
                </div>
              </LoadAndError>
            )}
        </InnerContainer>
      </div>
    </InnerLayout>
  );
};

export default BlogCategoryEdit;
