import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import InnerLayout from "../../../../components/layouts/innerLayout/innerLayout";
import InnerContainer from "../../../../components/layouts/innerContainer/innerContainer";
import VersionHistoryBtn from "../../../../components/buttons/versionHistoryBtn";
import RejectBtn from "../../../../components/buttons/rejectBtn";
import ApproveBtn from "../../../../components/buttons/approveBtn";
import errorToast from "../../../../components/utilits/errorToast";
import successToast from "../../../../components/utilits/successToast";
import moment from "moment";

import DownloadIcon from "../../../../assets/icons/downloadIcon";
import LoadAndError from "../../../../components/utilits/LoadAndError";
import {
  approveHomeAction,
  rejectHomeAction,
} from "../../../../redux/actions/homeActions";
import HomeCounterTable from "../../../../components/static-pages/home-page/tables/homeCounterTable";
import HomeCardsTable from "../../../../components/static-pages/home-page/tables/homeCardsTable";
import { approvalsViewCampaignAction, approveCampaignAction, rejectCampaignAction } from "../../../../redux/actions/campaignActions";
import { approvalsViewPartnerWithUsAction, approvePartnerWithUsAction, rejectPartnerWithUsAction } from "../../../../redux/actions/partnerWithUsAction";
// import PartnerWithUsFaq from "../../../../components/static-pages/partnerWithUs/tables/partnerWithUsFaq";
// import PartnerWithUsAnimation from "../../../../components/static-pages/partnerWithUs/tables/partnerWithUsAnimation";
import PartnerWithUsFaq from "../../../../components/static-pages/partner-with-us/tables/partnerWithUsFaq";
import PartnerWithUsAnimation from "../../../../components/static-pages/partner-with-us/tables/partnerWithUsAnimation";

const PartnerWithUsApprovalsDetails = () => {
  const navigate = useNavigate();
  const [howItWorksData, setHowItWorksData] = useState({});
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [operation, setOperation] = useState("");
  const params = useParams();
  const [howItWorksDataId, SetHowItWorksDataId] = useState({ id: "" });

  useEffect(() => {
    setLoading(true);
    dispatch(approvalsViewPartnerWithUsAction(params.id, onSuccess, onError));
  }, [params.id]);
  const onSuccess = (data) => {
    SetHowItWorksDataId(data);
    setOperation(
      data?.data?.new_data == null
        ? "Delete"
        : data?.data?.new_data && data?.data?.old_data
          ? "Edit"
          : data?.data?.old_data == null
            ? "Create"
            : ""
    );
    setHowItWorksData(
      data?.data?.new_data === null
        ? data?.data?.old_data
        : data?.data?.new_data
    );
    console.log(data.data);
    setLoading(false);
    setError(false);
  };
  const onError = (data) => {
    errorToast(data.message);
    console.log(data, "error");
    setLoading(false);
    setError(true);
  };

  // Approve Notify Me
  const [updated, setUpdated] = useState(false);

  const approveHowItWorksFn = () => {
    dispatch(approvePartnerWithUsAction(params.id, onApproveSuccess, onApproveError));
  };

  const onApproveSuccess = (data) => {
    navigate("/static-pages/partner-with-us/approvals");
    successToast(data.message);
    setLoading(false);
    setError(false);
  };
  const onApproveError = (data) => {
    console.log(data);
    errorToast(data.data + " " + data.message);
    setLoading(false);
    setError(true);
  };
  // Reject  Notify Me Api
  const rejectHowItWorksFn = () => {
    dispatch(rejectPartnerWithUsAction(params.id, onRejectSuccess, onRejectError));
  };

  const onRejectSuccess = (data) => {
    successToast(data.message);
    navigate("/static-pages/partner-with-us/approvals");
    setLoading(false);
    setError(false);
  };
  const onRejectError = (data) => {
    console.log(data);
    errorToast(data.data + " " + data.message);
    setLoading(false);
    setError(true);
  };

  function ViewItemGenerator(title, details) {
    return (
      <>
        <span className="dark_grey_color">{title}</span>
        <p className="dark_title_color">{details}</p>
      </>
    );
  }
  const data1 = [
    {
      heading: "Approval ID",
      title: howItWorksDataId?.data?.id,
    },
  ];

  const buttonList = [
    <VersionHistoryBtn link={`/static-pages/partner-with-us/version-history`} />,
    <ApproveBtn approveFn={approveHowItWorksFn} />,
    <RejectBtn showModal={rejectHowItWorksFn} />,
  ];
  return (
    <InnerLayout buttons={buttonList}>
      <div className="user_view_container mb-4">
        <div className="user_view_container mb-4">
          <InnerContainer error={error} oldData={howItWorksData?.old_data} newData={howItWorksData?.new_data}>
            <LoadAndError loader={loading} error={error}>
              <div className="d-flex flex-column flex-lg-row gap-4 fs_14 py-3 px-3 px-lg-5">
                <div className="col-12 col-lg-6 pe-0 pe-lg-5">
                {data1.map((item) => (
                  <div className="mb-2">
                    <h5 className=" fs_13 fn_Montserrat fw_500 m-0">
                      {item.heading}
                    </h5>
                    <>
                      {" "}
                      <p
                        className={` fs_13 fn_Nunito fw_500  ${
                          item.heading === "LinkedIn URL" ||
                          item.heading === "User ID"
                            ? "secondary_color"
                            : "dark_title_color"
                        } `}
                      >
                        {item.title}
                      </p>
                    </>
                  </div>
                ))}
                <div>
                    {ViewItemGenerator(
                      "Partner With Us ID",
                      howItWorksData?.id
                    )}
                  </div>
                  <div>
                    {ViewItemGenerator(
                      "Partner With Us Header",
                      howItWorksData?.section1_heading
                    )}
                  </div>
                  <div>
                    {ViewItemGenerator(
                      "Partner With Us Title",
                      howItWorksData?.section1_title
                    )}
                  </div>
                  <div>
                    {ViewItemGenerator(
                      "Partner With Us description",
                      howItWorksData?.section2_heading
                    )}
                  </div>
                  <div>
                    {ViewItemGenerator(
                      "Opportunity Section Heading",
                      howItWorksData?.opportunity_title
                    )}
                  </div>
                  <div>
                    {ViewItemGenerator(
                      "Opportunity Section Description",
                      howItWorksData?.opportunity_subtitle
                    )}
                  </div>
                  <PartnerWithUsAnimation
                    tableTitle="Partners Guide"
                    editable={false}
                    formikValues={howItWorksData}
                    name={"partners_guide"}
                  />
                  <PartnerWithUsFaq
                    tableTitle="Faq Type 1"
                    formikValues={howItWorksData}
                    name={"partner_faq_section1"}
                    editable={false}
                  />
                  <PartnerWithUsFaq
                    tableTitle="Faq Type 2"
                    formikValues={howItWorksData}
                    name={"partner_faq_section2"}
                    editable={false}
                  />
                  {/* <HomeCounterTable
                    formikValues={howItWorksData}
                    name={"section2_counter"}
                    editable={false}
                  /> */}
                </div>
              </div>
            </LoadAndError>
          </InnerContainer>
        </div>
      </div>
    </InnerLayout>
  );
};

export default PartnerWithUsApprovalsDetails;
