import React, { useState, useEffect } from "react";
import OpportunitiesContentEditTableTypeOne from "./opportunitiesContentEditTableTypeOne";
import OpportunitiesContentEditTableTypeTwo from "./opportunitiesContentEditTableTypeTwo";
import "./opportunitiesEditContent.css";

import { useDispatch, useSelector } from "react-redux";
import * as opportunityEditorActions from "../../../../redux/actions/opportunityEditorActions";
import * as defaultSectionValues from "../../../../redux/constants/opportunityJsonState";

const OpportunitiesContectEditTable = ({
  id,
  deleteTheTextSection,
  sectionIndex,
  subSectionIndex,
  index,
}) => {
  const [tabelType, setTableType] = useState("type1");

  const [columnHeading1, setColumnHeading1] = useState("");
  const [columnHeading2, setColumnHeading2] = useState("");

  const [columnFooter1, setColumnFooter1] = useState("");
  const [columnFooter2, setColumnFooter2] = useState("");
  const dispactch = useDispatch();

  const opportunityJsonReducer = useSelector(
    (state) => state.opportunityJsonReducer
  );

  const [data, setData] = useState(
    opportunityJsonReducer.data[sectionIndex]?.subsections[subSectionIndex]?.content[index]
  );

  useEffect(() => {
    var newData =
      opportunityJsonReducer.data[sectionIndex]?.subsections[subSectionIndex]?.content[index];
    setData(newData);
    setTableType(newData.table_type);
    setColumnFooter1(newData.footer.col1);
    setColumnFooter2(newData.footer.col2);
    setColumnHeading1(newData.heading.col1);
    setColumnHeading2(newData.heading.col2);
  }, []);

  useEffect(() => {
    updateReducer();
  }, [data]);

  //table type
  useEffect(() => {
    setData({
      ...data,
      table_type: tabelType,
    });
  }, [tabelType]);

  const updateReducer = () => {
    // stateDate.data.
    dispactch(
      opportunityEditorActions.updateContentSection(
        index,
        subSectionIndex,
        sectionIndex,
        data
      )
    );
  };

  const onRowUpdate = (value, index, typeOf) => {
    var vals = data;
    var rowVal = vals.body;
    rowVal[index][typeOf] = value;
    vals.body = [...rowVal];
    setData({ ...vals });
  };

  function addRow() {
    var vals = data;
    var rowVal = vals.body;

    rowVal.push({ ...defaultSectionValues.rowItem, id: Date.now() });
    vals.body = [...rowVal];
    setData({ ...vals });
  }
  function deleteRow(rowIndex) {
    var vals = data;
    var rowVal = vals.body;
    rowVal.splice(rowIndex, 1);
    vals.body = [...rowVal];
    setData({ ...vals });
  }

  const handleRadioChange = (e) => {
    setTableType(e.target.value);
  };

  const onColumnHeading1Update = (text) => {
    setColumnHeading1(text);
    setData({
      ...data,
      heading: {
        ...data.heading,
        col1: text,
      },
    });
  };

  const onColumnHeading2Update = (text) => {
    setColumnHeading2(text);
    setData({
      ...data,
      heading: {
        ...data.heading,
        col2: text,
      },
    });
  };

  const onColumnFooter1Update = (text) => {
    setColumnFooter1(text);
    setData({
      ...data,
      footer: {
        ...data.footer,
        col1: text,
      },
    });
  };

  const onColumnFooter2Update = (text) => {
    setColumnFooter2(text);
    setData({
      ...data,
      footer: {
        ...data.footer,
        col2: text,
      },
    });
  };
  return (
    <div key={data.id} className="dark_grey_color mt-4 mb-1">
      <div className="w-100 d-flex justify-content-between ">
        <span></span>

        <button type="button"
          onClick={() => {
            deleteTheTextSection(index);
          }}
          className="light_red_color fs_13 fw_500 border-0 white_shade_bg"
        >
          Delete
        </button>
      </div>

      <div className="oppor_content_edit__border rounded-2 mb-3 px-4 px-lg-3 px-xl-4 pt-3">
        <div className=" order-2 order-md-1 mx-3 mx-xxl-5 mt-3 mt-md-0 mb-4">
          <h6 className="text-nowrap mt-2 mb-3">Select Table Type</h6>
          <div className="d-flex flex-wrap flex-xxl-nowrap justify-content-start col-4  justify-content-md-between">
            <div className="d-flex me-4 ">
              <input
                onChange={handleRadioChange}
                id={"tableType1"}
                name="tableType"
                value={"type1"}
                type="radio"
                className="me-2 oppor_content_edit_table_radiobtn"
                checked={tabelType === "type1"}
              />
              <label
                onClick={() => setTableType("type1")}
                htmlFor="tableType1"
                className="text-nowrap"
              >
                Table Type 1
              </label>
            </div>
            <div className="d-flex">
              <input
                onChange={handleRadioChange}
                id="tableType2"
                type="radio"
                value={"type2"}
                name="tableType"
                className="me-2 oppor_content_edit_table_radiobtn"
                checked={tabelType === "type2"}
              />
              <label
                onClick={() => setTableType("type2")}
                htmlFor="tableType2"
                className="text-nowrap"
              >
                Table Type 2
              </label>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="d-flex flex-column col-6 col-sm-5 ">
            <label htmlFor="columnHeading1" className="ms-2 mb-2 mt-2">
              Column Heading 1
            </label>
            <input
              value={columnHeading1}
              onChange={(event) => {
                onColumnHeading1Update(event.target.value);
              }}
              id="columnHeading1"
              type="text"
              className="oppor_content_edit__input rounded-3 "
            />
          </div>
          <div className="d-flex flex-column col-6 col-sm-5">
            <label htmlFor="columnHeading2" className="ms-2 mb-2 mt-2">
              Column Heading 2
            </label>
            <input
              value={columnHeading2}
              onChange={(event) => {
                onColumnHeading2Update(event.target.value);
              }}
              id="columnHeading2"
              type="text"
              className="oppor_content_edit__input rounded-3 "
            />
          </div>
        </div>

        {data.body.map((item, index) => {
          return (
            <>
              <OpportunitiesContentEditTableTypeOne
                item={item}
                key={item.id}
                deleteTheRow={deleteRow}
                index={index}
                onRowUpdate={onRowUpdate}
              />
            </>
          );
        })}

        <button type="button"
          onClick={() => addRow()}
          className="oppor_content_edit__border bg-white rounded w-100 fs_14 mt-4 mb-5 py-2"
        >
          + Add Row
        </button>

        <div className="row">
          <div className="d-flex flex-column col-6 col-sm-5 ">
            <label htmlFor="columnFooter1" className="ms-2 mb-2 mt-2">
              Column Footer 1
            </label>
            <input
              value={columnFooter1}
              onChange={(event) => {
                onColumnFooter1Update(event.target.value);
              }}
              id="columnHeading1"
              type="text"
              className="oppor_content_edit__input rounded-3 "
            />
          </div>
          <div className="d-flex flex-column col-6 col-sm-5">
            <label htmlFor="columnFooter2" className="ms-2 mb-2 mt-2">
              Column Footer 2
            </label>
            <input
              value={columnFooter2}
              onChange={(event) => {
                onColumnFooter2Update(event.target.value);
              }}
              id="columnHeading2"
              type="text"
              className="oppor_content_edit__input rounded-3 "
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default OpportunitiesContectEditTable;
