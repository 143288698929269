import { call, takeLatest } from "redux-saga/effects";
import {
  ADVISORS_APPROVALS_EXPORT,
  ADVISORS_APPROVALS_LIST,
  ADVISORS_APPROVALS_VIEW,
  ADVISORS_APPROVE,
  ADVISORS_CREATE,
  ADVISORS_DELETE,
  ADVISORS_EDIT,
  ADVISORS_EXPORT,
  ADVISORS_LIST,
  ADVISORS_READ,
  ADVISORS_REJECT,
  ADVISORS_SUBMIT,
  ADVISORS_VERSION_EXPORT,
  ADVISORS_VERSION_LIST,
  ADVISORS_VERSION_VIEW,
} from "../actions/types";
import * as advisorApis from "../apis/advisorsApis";

export function* addAdvisorWorker(action) {
  try {
    const res = yield call(advisorApis.addAdvisorApi, action.data);
    console.log(action.data);
    // console.log(res, "AddAdminRole");
    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res.data);
      console.log(res.data);
    } else if (res.status === 200 && res.data.status === "fail") {
      console.log(res.data);
      yield action.onError(res);
    } else {
      yield action.onError("Something Went Wrong");
    }
  } catch (err) {
    console.log(err);
    yield action.onError("Something Went Wrong");
  }
}

export function* viewAdvisorWorker(action) {
  try {
    const res = yield call(advisorApis.viewAdvisorApi, action.id);
    // console.log(res, "AddAdminRole");
    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res.data);
      console.log(res.data);
    } else if (res.status === 200 && res.data.status === "fail") {
      console.log(res.data);
      yield action.onError(res);
    } else {
      yield action.onError("Something Went Wrong!");
      console.log(res.data);
    }
  } catch (err) {
    console.log(err);
    yield action.onError("Something Went Wrong!!");
  }
}
export function* editAdvisorWorker(action) {
  try {
    const res = yield call(advisorApis.editAdvisorApi, action.id, action.data);
    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res.data);
      console.log(res.data);
    } else if (res.status === 200 && res.data.status === "fail") {
      console.log(res);
      yield action.onError(res);
    } else {
      yield action.onError("Something Went Wrong");
    }
  } catch (err) {
    console.log(err);
    yield action.onError("Something Went Wrong");
  }
}
export function* deleteAdvisorWorker(action) {
  try {
    const res = yield call(advisorApis.deleteAdvisorApi, action.id);
    // console.log(res, "AddAdminRole");
    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res.data);
      console.log(res.data);
    } else if (res.status === 200 && res.data.status === "fail") {
      console.log(res);
      yield action.onError(res);
    } else {
      yield action.onError("Something Went Wrong");
    }
  } catch (err) {
    console.log(err);
    yield action.onError("Something Went Wrong");
  }
}

export function* viewAdvisorListWorker(action) {
  try {
    console.log(action.search,
      action.pageNo,);
    const res = yield call(
      advisorApis.viewAdvisorListApi,
      action.pageNo,
      action.data,
    );
    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res.data);
      console.log(res);
    } else if (res.status === 200 && res.data.status === "fail") {
      console.log(res);
      yield action.onError(res.data);
    } else {
      yield action.onError("Something Went Wrong");
    }
  } catch (err) {
    console.log(err);
    yield action.onError("Something Went Wrong");
  }
}

export function* approveAdvisorWorker(action) {
  try {
    const res = yield call(advisorApis.approveAdvisorApi, {
      user_Id: action.user_Id,
    });
    console.log(res);
    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res.data);
      console.log(res.data);
    } else if (res.status === 200 && res.data.status === "fail") {
      console.log(res);
      yield action.onError(res.data);
    } else {
      yield action.onError("Something Went Wrong!");
      console.log(res.data);
    }
  } catch (err) {
    console.log(err);
    yield action.onError("Something Went Wrong!!");
  }
}

export function* rejectAdvisorWorker(action) {
  try {
    const res = yield call(advisorApis.rejectAdvisorApi, action.id);
    // console.log(res, "AddAdminRole");
    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res.data);
      console.log(res.data);
    } else if (res.status === 200 && res.data.status === "fail") {
      console.log(res);
      yield action.onError(res.data);
    } else {
      yield action.onError("Something Went Wrong!");
      console.log(res.data);
    }
  } catch (err) {
    console.log(err);
    yield action.onError("Something Went Wrong!!");
  }
}
export function* approvalsAdvisorsListWorker(action) {
  try {
    const res = yield call(
      advisorApis.approvalsAdvisorListpApi,
      action.page,
      action.data,
    );
    // console.log(res, "AddAdminRole");
    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res.data);
      console.log(res.data);
    } else if (res.status === 200 && res.data.status === "fail") {
      console.log(res);
      yield action.onError(res);
    } else {
      yield action.onError("Something Went Wrong!");
      console.log(res.data);
    }
  } catch (err) {
    console.log(err);
    yield action.onError("Something Went Wrong!!");
  }
}
export function* viewApproveAdvisorWorker(action) {
  try {
    const res = yield call(advisorApis.viewApprovalAdvisorApi, action.id);
    console.log(res, "Contact approvals");
    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res.data);
      console.log(res.data);
    } else if (res.status === 200 && res.data.status === "fail") {
      console.log(res);
      yield action.onError(res);
    } else {
      yield action.onError("Something Went Wrong!");
      console.log(res.data);
    }
  } catch (err) {
    console.log(err);
    yield action.onError("Something Went Wrong!!");
  }
}
export function* versionHistoryListAdvisorWorker(action) {
  try {
    const res = yield call(
      advisorApis.versionAdvisorListpApi,
      action.page,
      action.data
    );
    // console.log(res, "AddAdminRole");
    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res.data);
      console.log(res.data);
    } else if (res.status === 200 && res.data.status === "fail") {
      console.log(res);
      yield action.onError(res);
    } else {
      yield action.onError("Something Went Wrong!");
      console.log(res.data);
    }
  } catch (err) {
    console.log(err);
    yield action.onError("Something Went Wrong!!");
  }
}
export function* viewVersionHistoryAdvisorWorker(action) {
  try {
    const res = yield call(advisorApis.viewVersionAdvisorApi, action.id);
    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res);
      console.log(res);
    } else if (res.status === 200 && res.data.status === "fail") {
      console.log(res);
      yield action.onError(res);
    } else {
      yield action.onError("Something Went Wrong!");
      console.log(res);
    }
  } catch (err) {
    console.log(err);
    yield action.onError("Something Went Wrong!!");
  }
}

/**********************************/
/************WATHCHERS**************/
/**********************************/

export function* advisorsTableExportWorker(action) {
  try {
      const res = yield call(advisorApis.advisorsTableExportApi,

      );
      if (res.status === 200 && res.data.status === "success") {
          yield action.onSuccess(res.data);
      } else {
          yield action.onError(res.data);
      }
  } catch (error) { }
}


export function* advisorsApprovalsExportWorker(action) {
  try {
      const res = yield call(advisorApis.advisorsApprovalsExportApi);
      console.log(res);
      if (res.status === 200 && res.data.status === "success") {
          console.log(res.data);
          yield action.onSuccess(res.data.data);
      } else {
          yield action.onError(res.data);
      }
  } catch (error) { }
}

export function* advisorsVersionExportWorker(action) {
  try {
      const res = yield call(advisorApis.advisorsVersionExportApi);
      console.log(res);
      if (res.status === 200 && res.data.status === "success") {
          yield action.onSuccess(res.data);
      } else {
          yield action.onError(res.data);
      }
  } catch (error) { }
}


export function* advisorsSubmitWorker(action) {
  try {
      const res = yield call(advisorApis.advisorsSubmitApi, action.id);
      console.log(res);
      if (res.status === 200 && res.data.status === "success") {
          yield action.onSuccess(res.data);
      } else {
          yield action.onError(res.data);
      }
  } catch (error) { }
}






export function* watchaddAdvisorWorker() {
  yield takeLatest(ADVISORS_CREATE, addAdvisorWorker);
}
export function* watchViewAdvisorWorker() {
  yield takeLatest(ADVISORS_READ, viewAdvisorWorker);
}
export function* watchViewAdvisorListWorker() {
  yield takeLatest(ADVISORS_LIST, viewAdvisorListWorker);
}
export function* watcheditAdvisorWorker() {
  yield takeLatest(ADVISORS_EDIT, editAdvisorWorker);
}
export function* watchApproveAdvisorWorker() {
  yield takeLatest(ADVISORS_APPROVE, approveAdvisorWorker);
}
export function* watchRejectAdvisorWorker() {
  yield takeLatest(ADVISORS_REJECT, rejectAdvisorWorker);
}
export function* watchApprovalsAdvisorListWorker() {
  yield takeLatest(ADVISORS_APPROVALS_LIST, approvalsAdvisorsListWorker);
}
export function* watchViewApprovalAdvisorWorker() {
  yield takeLatest(ADVISORS_APPROVALS_VIEW, viewApproveAdvisorWorker);
}
export function* watchDeleteAdvisorWorker() {
  yield takeLatest(ADVISORS_DELETE, deleteAdvisorWorker);
}
export function* watchVersionHistoryListAdvisorWorker() {
  yield takeLatest(ADVISORS_VERSION_LIST, versionHistoryListAdvisorWorker);
}
export function* watchViewVersionHistoryAdvisorWorker() {
  yield takeLatest(ADVISORS_VERSION_VIEW, viewVersionHistoryAdvisorWorker);
}

/****exports*****/

export function* advisorsTableExportWatcher() {
  yield takeLatest(
      ADVISORS_EXPORT,
      advisorsTableExportWorker
  );
}
export function* advisorsApprovalsExportWatcher() {
  yield takeLatest(ADVISORS_APPROVALS_EXPORT, advisorsApprovalsExportWorker);
}

export function* advisorsVersionExportWorkerWatcher() {
  yield takeLatest(ADVISORS_VERSION_EXPORT, advisorsVersionExportWorker);
}


export function* advisorsSubmitWorkerWatcher() {
  yield takeLatest(ADVISORS_SUBMIT, advisorsSubmitWorker);
}