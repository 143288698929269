import React, { useEffect, useRef, useState } from "react";
import DeleteBtn from "../../../../components/buttons/deleteBtn";
import SaveBtn from "../../../../components/buttons/saveBtn";
import InnerContainer from "../../../../components/layouts/innerContainer/innerContainer";
import InnerLayout from "../../../../components/layouts/innerLayout/innerLayout";
import OpportunitiesContentSection from "../../../../components/opportunity/opportunties/editSection/opportunitiesContentSection";
import { useDispatch, useSelector } from "react-redux";
import * as opportunityEditorActions from "../../../../redux/actions/opportunityEditorActions";
import * as opportunityActions from "../../../../redux/actions/opportunitiesActions";

import "../../../opportunity/opportunities/opportunities.css";
import { useParams } from "react-router-dom";
import moment from "moment";
import LoadingSpinnerTable from '../../../../components/utilits/loadingSpinnerTable'
import { opportunityJsonStateData } from "../../../../redux/constants/opportunityJsonState";
import { useNavigate } from "react-router-dom";
import { editBlogAction, viewBlogDetailAction } from "../../../../redux/actions/blogActions";

const BlogContentEdit = () => {
    const params = useParams();
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate()
    const [opportunityData, setOpportinityData] = useState(false);
    const opportunityJsonReducer = useSelector(
        (state) => state.opportunityJsonReducer
    );
    const dispatch = useDispatch();
    useEffect(() => {
        setLoading(true);
        dispatch(viewBlogDetailAction(params.id,
            onDataSuccess,
            onDataError
        )
        );
    }, []);

    const onDataSuccess = (data) => {
        console.log(data.data);
        setOpportinityData(data.data);
        setLoading(false);
        dispatch(opportunityEditorActions.updateOppJson(data.data.content));
    };
    const onDataError = (error) => {
        console.log(error);
    };
    function addNewSection() {
        dispatch(opportunityEditorActions.addSection());
    }
    function deleteSection(index) {
        console.log("deleteSection", index);
        dispatch(opportunityEditorActions.deleteSection(index));
    }

    function contentSubmitter() {
        // setLoading(!loading);
        // console.log("Main submitter Runnig....");
        save();
    }

    const save = () => {
        //on save dispatch with value;
        const value = opportunityJsonReducer.data;
        localStorage.setItem("value", JSON.stringify(value));
        let newContent = opportunityData;
        console.log(newContent);
        try {
            dispatch(
                editBlogAction(
                    params?.id,
                    {
                        blogCategory: newContent?.blog_category_id ? newContent?.blog_category_id : "",
                        blogTitle: newContent?.title ? newContent?.title : "",
                        blogDesc: newContent?.description
                            ? newContent?.description : "",
                        cardImgLink: newContent?.card_image
                            ? newContent?.card_image : "",
                        metaTags: "",
                        metaDesc: newContent?.meta_description ? newContent?.meta_description : "",
                        display_image_1: newContent.display_image_1,
                        display_image_2: newContent.display_image_2,
                        display_image_3: newContent.display_image_3,
                        content: value

                        // title: newContent.title,
                        // status: newContent.status,
                        // tagline: newContent.tagline,
                        // assets: newContent.assets,
                        // overview: newContent.overview,
                        // payouts: newContent.payouts,
                        // odometer_section: newContent.odometer_section,
                        // insights: newContent.insights,
                        // content: value,
                        // summary: newContent.summary,
                        // opportunity_type_id: newContent.opportunity_type_id,
                        // available_from_date: moment(newContent.available_from_date).format("YYYY-MM-DD"),
                        // available_till_date: moment(newContent.available_till_date).format("YYYY-MM-DD"),
                        // referal: newContent.referral,
                        // total_funding_amount: newContent.total_funding_amount,
                        // total_funded_amount: newContent.total_funded_amount,
                        // interest_value_interval: newContent.interest_value_interval,
                        // tenure: newContent.tenure,
                        // payout_duration: newContent.payout_duration,
                        // monthly_payout: newContent.monthly_payout,
                        // last_month_payout: newContent.last_month_payout,
                        // post_tax_irr: newContent.post_tax_irr,
                        // display_image: newContent.display_image,
                        // card_icons: newContent.card_icons,
                        // faq: newContent.faq,
                        // tags: newContent.tags,
                        // shareable_link: newContent.shareable_link,
                    },
                    onSuccess,
                    onError
                )
            );
        } catch (error) {
            console.log(error);
        }
    };

    const onSuccess = (data) => {
        // on success reset reducer
        console.log(data, "updateddddddddddddddddddddd");
        setLoading(false);
        navigate(`/blog/edit/${params.id}`)

        // dispatch(opportunityEditorActions.clearOppJson());
    };

    const onError = (error) => {
        console.log(error);
    };

    function handleSectionSubmit(index, section) {


    }
    const buttonList = [
        // <PublishBtn />,
        // <PreviewBtn />,
        <SaveBtn loader={false} submitFn={contentSubmitter} />,
        <DeleteBtn />,
    ];
    return (
        <InnerLayout buttons={buttonList}>
            {loading ? (
                <LoadingSpinnerTable />
            ) : (
                <InnerContainer containerClassName="oppor_edit_inner__container ">
                    <section className="oppor_edit__container   oppor_edit__container_table">
                        <div>
                            <h5 className="fs_14 dark_grey_color mb-0">Blog ID</h5>
                            <span className="secondary_color fs_12 fn_Nunito">
                                {params?.id}
                            </span>
                        </div>
                        {opportunityJsonReducer.data.map((item, index) => {
                            return (
                                <OpportunitiesContentSection
                                    key={item.id}
                                    index={index}
                                    updateSectionData={handleSectionSubmit}
                                    numberOfSection={opportunityJsonReducer.data.length}
                                    deleteSection={deleteSection}
                                    addNewSection={addNewSection}
                                />
                            );
                        })}
                    </section>
                </InnerContainer>
            )}
        </InnerLayout>
    );
}

export default BlogContentEdit