import React, { useRef, useState } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import InnerLayout from "../../../../components/layouts/innerLayout/innerLayout";
import InnerContainer from "../../../../components/layouts/innerContainer/innerContainer";
import { useNavigate } from "react-router-dom";
import SaveBtn from "../../../../components/buttons/saveBtn";
import InputField from "../../../../components/form/inputFields/inputField";
import successToast from "../../../../components/utilits/successToast";
import errorToast from "../../../../components/utilits/errorToast";
import { useDispatch } from "react-redux";
import ErrorMsg from "../../../../components/form/errorMsg";
import LoadingSpinnerTable from "../../../../components/utilits/loadingSpinnerTable";
import SelectFields from "../../../../components/form/selectFields/selectFields";
import { createReferedUserAction } from "../../../../redux/actions/referedUserActions";
import { viewWishListFindAll } from "../../../../redux/actions/wishlistActions";
import { useEffect } from "react";
import { invitedReferralOptions } from "../../../../redux/actions/invitedAction";

const ReferredUsersCreate = () => {
  const navigate = useNavigate();
  const [allUsers, setAllUsers] = useState([]);
  const [opporList, setOpporList] = useState([]);
  const [submitData, setSubmitData] = useState();
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const formRef = useRef();
  useEffect(() => {
    dispatch(viewWishListFindAll(onSuccessDrop, onErrorDrop));
    dispatch(invitedReferralOptions(onOpporSuccessDrop, onOpporErrorDrop));
  }, []);

  const onOpporSuccessDrop = (data) => {
    const categoryList = [];
    data?.data?.AllOpportunities?.map((item, index) => {
      categoryList[index] = {
        value: item.id,
        label: `${item.title} id: ${item.id}`,
      };
    });
    setOpporList(categoryList);
    console.log("SUCCESS");
  };
  const onOpporErrorDrop = (data) => {
    errorToast(data.message);
  };
  const onSuccessDrop = (data) => {
    const roleTypes = [];
    data?.data.AllUsers.map((el, index) => {
      roleTypes[index] = { value: el.id, label: el.user_name };
    });
    setAllUsers(roleTypes);
  };
  const onErrorDrop = (data) => {
    console.log("ERROR");
  };

  const initialValues = {
    user: "",
    // phoneNumber: "",
    // email: "",
    referralCode: "",
    opportunity: "",
  };
  const interestEditSchema = Yup.object().shape({
    user: Yup.string().trim().required("Required"),
    // phoneNumber: Yup.string().trim().required("Required"),
    // email: Yup.email().required("Required"),
    referralCode: Yup.string().trim().required("Required"),
    // user: Yup.string().trim().required("Required"),
    opportunity: Yup.string().trim().nullable(true),
  });

  const handleSubmit = () => {
    if (formRef.current) {
      formRef.current.handleSubmit();
    }
  };
  const onSubmit = (values) => {
    dispatch(
      createReferedUserAction(
        {
          user_id: values.user,
          referral_code: values.referralCode,
          opportunity_id: values.opportunity ? values.opportunity : null,
        },
        onSuccess,
        onError
      )
    );
  };

  const onSuccess = (data) => {
    successToast("Refered User Created");
    navigate(`/static-pages/referred-users`);
    setLoading(false);
  };
  const onError = (data) => {
    console.log(data);
    errorToast(data.message);
    setLoading(false);
  };
  const buttonList = [
    <SaveBtn submitFn={handleSubmit} loader={loading} />,
    // <SubmitBtn submitFn={submitFnc} saveHide={saveHide} />,
  ];
  return (
    <InnerLayout buttons={buttonList}>
      <div className="user_view_container">
        <InnerContainer>
          {loading ? (
            <LoadingSpinnerTable />
          ) : (
            <div className="row mt-2">
              <div className="col-12 col-sm-12 col-md-9 col-lg-9 col-xl-7">
                <div className="row ps-1 ms-1 ps-lg-4 ms-lg-1">
                  <div className="ps-2  pt-3">
                    <Formik
                      innerRef={formRef}
                      initialValues={initialValues}
                      onSubmit={onSubmit}
                      enableReinitialize={true}
                      validationSchema={interestEditSchema}
                    >
                      <Form className="">
                        <div className="express-form-width">
                          {/* <InputField
                            id={"userName"}
                            label={"Users Name"}
                            type={"text"}
                            placeholder={"Enter user name"}
                            name={"userName"}
                          />
                          <InputField
                            id={"phoneNumber"}
                            label={"Phone number"}
                            type={"text"}
                            placeholder={"Enter phone number"}
                            name={"phoneNumber"}
                          />
                          <InputField
                            id={"email"}
                            label={"Email address"}
                            type={"text"}
                            placeholder={"Enter email address"}
                            name={"email"}
                          /> */}
                          <SelectFields
                            label={"User"}
                            placeholder={"select the user"}
                            name={"user"}
                            options={allUsers}
                            id={"user"}
                          />{" "}
                          <InputField
                            id={"referralCode"}
                            label={"Enter Referral code"}
                            type={"text"}
                            placeholder={"Enter code"}
                            name={"referralCode"}
                          />
                          <SelectFields
                            options={opporList}
                            label={"Select Opportunity"}
                            placeholder={"Opportunity here"}
                            name={"opportunity"}
                            // options={opportunitiesList}
                            id={"opportunity"}
                          />
                        </div>
                      </Form>
                    </Formik>
                  </div>
                </div>
              </div>
            </div>
          )}
        </InnerContainer>
      </div>
    </InnerLayout>
  );
};

export default ReferredUsersCreate;
