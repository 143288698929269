import "./mainlayout.css";
import menuIcon from "../../../assets/icons/menu.svg";

import { useEffect, useState } from "react";
import DotMenuIcon from "../../../assets/icons/dotMenuIcon";
import LayoutSibarBar from "./layoutSideBar";
import * as loginAction from "../../../redux/actions/loginAction";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import successToast from "../../utilits/successToast";

const MainLayout = ({ children }) => {
  const [sideBarOpen, setSideBarOpen] = useState(false);

  const [menuOptionOpen, setMenuOptionOpen] = useState(false);

  const handleSidebarClose = () => {
    setSideBarOpen(false);
  };

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const email = localStorage.getItem("email");

  const handleLogout = () => {
    dispatch(loginAction.logout());
    successToast("You have been logged out");
  };
  return (
    <div className="fn_Montserrat mainlayout_container mx-auto flex-wrap   d-flex">
      <LayoutSibarBar
        sideBarStatus={sideBarOpen}
        handleClose={handleSidebarClose}
      />
      <div className="mainlayout_maincontent_container ms-auto">
        <div className="mainlayout_topbar_container w-100 d-flex justify-content-end bg-white">
          <div className="mainlayout_topbar_inner__container justify-content-between d-flex  col-12 col-lg-auto pe-3">
            <span
              onClick={() => setSideBarOpen(!sideBarOpen)}
              className={`d-inline-block d-lg-none`}
            >
              <img src={menuIcon} alt="" />
            </span>
            <div>
              <span className="me-2 black_color fs_14 fw_600 ">{email}</span>{" "}
              <span
                className="cursor_pointer "
                onClick={() => {
                  setMenuOptionOpen(!menuOptionOpen);
                }}
              >
                <DotMenuIcon fill="#4D4D4D" />
              </span>
            </div>
            {menuOptionOpen ? (
              <div className="position-relative">
                <div className="menu_option_contianer  py-2 px-4">
                  <span
                    className=" fw_600 fs_14 text-left cursor_pointer"
                    onClick={() => {
                      handleLogout();
                    }}
                  >
                    Logout
                  </span>
                </div>
              </div>
            ) : null}
          </div>
        </div>
        <main
          className={`mainlayout_main_children d-flex flex-column align-items-center w-100`}
        >
          {children}

        </main>
      </div>
    </div>
  );
};

export default MainLayout;
