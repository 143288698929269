import { CREATE_MEMBER_TRADE_OPTIONS, member_APPROVALS_EXPORT, member_EXPORT, member_VERSION_EXPORT, MEMBER_TRADE_APPROVALS_LIST, MEMBER_TRADE_APPROVALS_VIEW, MEMBER_TRADE_APPROVE, MEMBER_TRADE_CREATE, MEMBER_TRADE_DELETE, MEMBER_TRADE_EDIT, MEMBER_TRADE_LIST, MEMBER_TRADE_READ, MEMBER_TRADE_REJECT, MEMBER_TRADE_VERSION_LIST, MEMBER_TRADE_VERSION_VIEW, MEMBER_EXPORT, MEMBER_APPROVALS_EXPORT, MEMBER_VERSION_EXPORT, MEMBER_SUBMIT } from "../actions/types";
import { call, takeLatest } from "redux-saga/effects";
import { approveMemberTradeApi, deleteMemberTradeApi, editMemberTradeApi, memberApprovalsExportApi, memberSubmitApi, memberTableExportApi, memberTradeApprovalsTableApi, memberTradeCreateApi, memberTradeCreateOptionsApi, memberTradeVersionHistoryViewApi, memberVersionExportApi, readApprovalMemberTradeApi, readMemberTradeApi, rejectMemberTradeApi, versionTableMemberTradeApi, viewAllMemberTradeApi } from "../apis/memberTradeApi";
export function* deleteMemberTradeWorker(action) {

    try {
        const res = yield call(deleteMemberTradeApi, action.id,
        );
        if (res.status === 200 && res.data.status === "success") {
            yield action.onSuccess(res.data);
            console.log(res.data);
        } else if (res.status === 200 && res.data.status === "fail") {
            console.log(res);
            yield action.onError(res);
        } else {
            yield action.onError("Something Went Wrong");
        }
    } catch (err) {
        console.log(err);
        yield action.onError("Something Went Wrong");
    }

}
export function* readMemberTradeWorker(action) {
    try {
        const res = yield call(readMemberTradeApi,
            action.id,
        );
        // console.log(res, "AddAdminRole");
        if (res.status === 200 && res.data.status === "success") {
            yield action.onSuccess(res.data);
            console.log(res.data);
        } else if (res.status === 200 && res.data.status === "fail") {
            console.log(res.data);
            yield action.onError(res);
        } else {
            yield action.onError("Something Went Wrong!");
            console.log(res.data);
        }
    } catch (err) {
        console.log(err);
        yield action.onError("Something Went Wrong!!");
    }
}
export function* readApprovalMemberTradeWorker(action) {
    try {
        const res = yield call(readApprovalMemberTradeApi,
            action.id,
        );
        // console.log(res, "AddAdminRole");
        if (res.status === 200 && res.data.status === "success") {
            yield action.onSuccess(res.data);
            console.log(res.data);
        } else if (res.status === 200 && res.data.status === "fail") {
            console.log(res.data);
            yield action.onError(res);
        } else {
            yield action.onError("Something Went Wrong!");
            console.log(res.data);
        }
    } catch (err) {
        console.log(err);
        yield action.onError("Something Went Wrong!!");
    }
}
export function* viewAllMemberTradeWorker(action) {
    try {
        const res = yield call(viewAllMemberTradeApi,
            action.pageNo,
            action.data,
        );
        // console.log(res, "AddAdminRole");
        if (res.status === 200 && res.data.status === "success") {
            yield action.onSuccess(res.data);
            console.log(res, "hgcdhgvdh");
        } else if (res.status === 200 && res.data.status === "fail") {
            console.log(res);
            yield action.onError(res.data);
        } else {
            yield action.onError("Something Went Wrong");
        }
    } catch (err) {
        console.log(err);
        yield action.onError("Something Went Wrong");
    }
}

export function* versionTableMemberTradeWorker(action) {
    try {
        const res = yield call(versionTableMemberTradeApi,
            action.pageNo,
            action.data
        );
        if (res.status === 200 && res.data.status === "success") {
            yield action.onSuccess(res.data);
            console.log(res.data);
        } else if (res.status === 200 && res.data.status === "fail") {
            console.log(res);
            yield action.onError(res);
        } else {
            yield action.onError("Something Went Wrong!");
            console.log(res.data);
        }
    } catch (err) {
        console.log(err);
        yield action.onError("Something Went Wrong!!");
    }
}

export function* memberTradeVersionHistoryWorker(action) {
    try {
        const res = yield call(memberTradeVersionHistoryViewApi, action.id
        );
        if (res.status === 200 && res.data.status === "success") {
            yield action.onSuccess(res);
            console.log(res);
        } else if (res.status === 200 && res.data.status === "fail") {
            console.log(res);
            yield action.onError(res);
        } else {
            yield action.onError("Something Went Wrong!");
            console.log(res);
        }
    } catch (err) {
        console.log(err);
        yield action.onError("Something Went Wrong!!");
    }
}

export function* memberTradeApprovalsTableWorker(action) {
    try {
        const res = yield call(memberTradeApprovalsTableApi,
            action.pageNo,
            action.data,
        );
        if (res.status === 200 && res.data.status === "success") {
            yield action.onSuccess(res.data);
            console.log(res.data);
        } else if (res.status === 200 && res.data.status === "fail") {
            console.log(res);
            yield action.onError(res);
        } else {
            yield action.onError("Something Went Wrong!");
            console.log(res.data);
        }
    } catch (err) {
        console.log(err);
        yield action.onError("Something Went Wrong!!");
    }
}
export function* memberTradeCreateWorker(action) {
    try {
        const res = yield call(memberTradeCreateApi, action.data);

        if (res.status === 200 && res.data.status === "success") {
            console.log(res.data);
            yield action.onSuccess(res.data);
        } else {
            yield action.onError(res.data);
        }
    } catch (error) { }
}

export function* memberTradeCreateOptionsWorker(action) {
    try {
        const res = yield call(memberTradeCreateOptionsApi, {});
        console.log(res);
        if (res.status === 200 && res.data.status === "success") {
            yield action.onSuccess(res.data);
            console.log(res.data);
        } else {
            yield action.onError(res.data);
        }
    } catch (error) { }
}


export function* editMemberTradeWorker(action) {

    try {
        const res = yield call(editMemberTradeApi, action.id, action.data);
        if (res.status === 200 && res.data.status === "success") {
            yield action.onSuccess(res.data);
            console.log(res.data);
        } else if (res.status === 200 && res.data.status === "fail") {
            console.log(res);
            yield action.onError(res);
        } else {
            yield action.onError("Something Went Wrong");
        }
    } catch (err) {
        console.log(err);
        yield action.onError("Something Went Wrong");
    }

}

export function* approveMemberTradeWorker(action) {
    try {
        const res = yield call(approveMemberTradeApi,
            { member_trade_notify_me_id: action.id }
        );
        // console.log(res, "AddAdminRole");
        if (res.status === 200 && res.data.status === "success") {
            yield action.onSuccess(res.data);
            console.log(res.data);
        } else if (res.status === 200 && res.data.status === "fail") {
            console.log(res);
            yield action.onError(res.data);
        } else {
            yield action.onError("Something Went Wrong!");
            console.log(res.data);
        }
    } catch (err) {
        console.log(err);
        yield action.onError("Something Went Wrong!!");
    }
}


export function* rejectMemberTradeWorker(action) {
    try {
        const res = yield call(rejectMemberTradeApi,
            action.id
        );
        // console.log(res, "AddAdminRole");
        if (res.status === 200 && res.data.status === "success") {
            yield action.onSuccess(res.data);
            console.log(res.data);
        } else if (res.status === 200 && res.data.status === "fail") {
            console.log(res);
            yield action.onError(res.data);
        } else {
            yield action.onError("Something Went Wrong!");
            console.log(res.data);
        }
    } catch (err) {
        console.log(err);
        yield action.onError("Something Went Wrong!!");
    }
}



//export
export function* memberTableExportWorker(action) {
    try {
        const res = yield call(memberTableExportApi,
        );
        if (res.status === 200 && res.data.status === "success") {
            yield action.onSuccess(res.data);
        } else {
            yield action.onError(res.data);
        }
    } catch (error) { }
}


export function* memberApprovalsExportWorker(action) {
    try {
        const res = yield call(memberApprovalsExportApi);
        console.log(res);
        if (res.status === 200 && res.data.status === "success") {
            console.log(res.data);
            yield action.onSuccess(res.data.data);
        } else {
            yield action.onError(res.data);
        }
    } catch (error) { }
}

export function* memberVersionExportWorker(action) {
    try {
        const res = yield call(memberVersionExportApi);
        console.log(res);
        if (res.status === 200 && res.data.status === "success") {
            yield action.onSuccess(res.data);
        } else {
            yield action.onError(res.data);
        }
    } catch (error) { }
}


export function* memberSubmitWorker(action) {
    try {
        const res = yield call(memberSubmitApi, action.id);
        console.log(res);
        if (res.status === 200 && res.data.status === "success") {
            yield action.onSuccess(res.data);
        } else {
            yield action.onError(res.data);
        }
    } catch (error) { }
}






export function* watchViewAllMemberTradeWorker() {
    yield takeLatest(MEMBER_TRADE_LIST, viewAllMemberTradeWorker);
}
export function* watchMemberTradeWorker() {
    yield takeLatest(MEMBER_TRADE_DELETE, deleteMemberTradeWorker);
}
export function* watchreadMemberTradeWorker() {
    yield takeLatest(MEMBER_TRADE_READ, readMemberTradeWorker);
}
export function* watchreadApprovalMemberTradeWorker() {
    yield takeLatest(MEMBER_TRADE_APPROVALS_VIEW, readApprovalMemberTradeWorker);
}

export function* watchversionTableMemberTradeWorker() {
    yield takeLatest(MEMBER_TRADE_VERSION_LIST, versionTableMemberTradeWorker);
}
export function* watchMemberTradeVersionHistoryWorker() {
    yield takeLatest(MEMBER_TRADE_VERSION_VIEW, memberTradeVersionHistoryWorker);
}
export function* watchMemberTradeApprovalsTableWorker() {
    yield takeLatest(MEMBER_TRADE_APPROVALS_LIST, memberTradeApprovalsTableWorker);
}
export function* WatcherMemberTradeCreateWorker() {
    yield takeLatest(MEMBER_TRADE_CREATE, memberTradeCreateWorker);
}
export function* WatcherMemberTradeCreateOptionsWorker() {
    yield takeLatest(CREATE_MEMBER_TRADE_OPTIONS, memberTradeCreateOptionsWorker);
}

export function* watcheditMemberTradeWorker() {
    yield takeLatest(MEMBER_TRADE_EDIT, editMemberTradeWorker);
}

export function* watchapproveMemberTradeWorker() {
    yield takeLatest(MEMBER_TRADE_APPROVE, approveMemberTradeWorker);
}


export function* watchrejectMemberTradeWorker() {
    yield takeLatest(MEMBER_TRADE_REJECT, rejectMemberTradeWorker);
}


//export
export function* memberTableExportWatcher() {
    yield takeLatest(
        MEMBER_EXPORT,
        memberTableExportWorker
    );
}
export function* memberApprovalsExportWatcher() {
    yield takeLatest(MEMBER_APPROVALS_EXPORT, memberApprovalsExportWorker);
}

export function* memberVersionExportWorkerWatcher() {
    yield takeLatest(MEMBER_VERSION_EXPORT, memberVersionExportWorker);
}

export function* memberSubmitWorkerWatcher() {
    yield takeLatest(MEMBER_SUBMIT, memberSubmitWorker);
}