import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { TableContainer } from "../../table/tableContainer";
import { tableUsersApprovalHeader } from "../../table/tableData";
import TablePagination from "../../table/tablePagination";
import "../../admin/tables/admintable.css";
import * as userAction from "../../../redux/actions/userAction";
import { useDispatch } from "react-redux";
import errorToast from "../../utilits/errorToast";
import LoadingSpinnerTable from "../../utilits/loadingSpinnerTable";
import moment from "moment";
import LoadAndError from "../../utilits/LoadAndError";

const UserApprovalsTableContent = ({ page, search, changePage }) => {
  const dispatch = useDispatch();
  const [approvalsUserListData, setApprovalsUserListData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingTable, setLoadingTable] = useState(false);
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);
  const [error, setError] = useState(false);
  const [pageData, setPageData] = useState({ noOfItems: 0, noOfPages: 1 });
  useEffect(() => {
    setLoadingTable(true);
    dispatch(
      userAction.approvalsUserList(
        {
          search: {
            user_id: search.userId,
            name: search.name,
            email: search.email,
            phone_number: search.phoneNumber,
            kyc: search.kyc,
            from_dat: search.fromDate,
            to_date: search.todate,
          },
        },
        page,
        onSuccess,
        onError
      )
    );
  }, [page, search, loading]);
  const onSuccess = (data) => {
    setApprovalsUserListData(data?.data?.data);
    // successToast(data.message);
    setPageData({
      noOfItems: data.data.count,
      noOfPages: data.data.pages,
    });
    setError(false);
    setLoadingTable(false);
  };
  const onError = (data) => {
    errorToast(data.message);
    setError(true);
    setLoadingTable(false);
  };
  function handlePagination(type) {
    if (type === "+") {
      if (page + 1 < pageData.noOfPages) changePage("+");
    } else if (type === "-") if (page > 0) changePage("-");
  }
  return (
    <TableContainer width="93%">
      <div className="adminuser_table__container">
        {loadingTable && <LoadingSpinnerTable />}
        <table className={"adminuser_table"}>
          <thead className="adminuser_table__header">
            <tr className="adminuser_table__row">
              {tableUsersApprovalHeader.map((item) => {
                return (
                  <th
                    key={item}
                    className={`text-nowrap  adminuser_table__heading ${item === "Status"
                      ? "text-center position-sticky end-0"
                      : null
                      }`}
                  >
                    {item}
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody className="adminuser_table__body">
            <LoadAndError
              loader={loadingTable}
              error={error}
              status={approvalsUserListData.length === 0}
            >
              {approvalsUserListData.map((item, index) => {
                return (
                  <tr key={item?.id} className="adminuser_table__row">
                    <td className="text-nowrap dark_title_color adminuser_table__data ">
                      {page > 0 ? 12 * page + (index + 1) : index + 1}
                    </td>
                    <td className="text-nowrap secondary_color adminuser_table__data">
                      {item?.id}
                    </td>
                    <td className="text-nowrap secondary_color adminuser_table__data">
                      {item?.user_id ? item?.user_id : "-"}
                    </td>
                    <td className="text-nowrap secondary_color adminuser_table__data">
                      {item.new_data == null
                        ? item.old_data?.user_name
                        : item.new_data?.user_name}
                    </td>
                    <td className="text-nowrap dark_title_color   adminuser_table__data">
                      {item.new_data == null
                        ? item.old_data?.email
                        : item.new_data?.email}
                    </td>
                    <td className="text-nowrap dark_title_color adminuser_table__data">
                      {item.new_data == null
                        ? item.old_data?.phone_number
                        : item.new_data?.phone_number}
                    </td>
                    <td className="text-nowrap dark_title_color adminuser_table__data">
                      {item.new_data == null
                        ? item.new_data?.kyc_process_status ? item.new_data?.kyc_process_status : "-"
                        : item.new_data?.kyc_process_status ? item.new_data?.kyc_process_status : "-"}
                    </td>
                    <td className="text-nowrap dark_title_color adminuser_table__data">
                      {item.createdAt === null
                        ? "-"
                        : moment(item?.createdAt).format("DD-MM-YYYY HH:mm:ss")}
                    </td>
                    <td className="text-nowrap dark_title_color adminuser_table__data">
                      {item.last_login === null
                        ? "-"
                        : moment(item?.last_login).format(
                          "DD-MM-YYYY HH:mm:ss"
                        )}
                    </td>

                    <td className="text-nowrap adminuser_table__data position-sticky end-0">
                      <div className="d-flex w-100 justify-content-center gap-2">
                        <Link
                          className="text-decoration-none primary_color"
                          to={{
                            pathname: `/user/user-list/approvals/view/${item?.id}`,
                          }}
                        >
                          View
                        </Link>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </LoadAndError>
          </tbody>
        </table>
      </div>
      <TablePagination
        paginate={handlePagination}
        currentPage={page}
        pageData={pageData}
      />
    </TableContainer>
  );
};

export default UserApprovalsTableContent;
