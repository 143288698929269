import React from 'react'
import usePermissionChecker from '../utilits/usePermissionChecker';
import { AdminButton } from './versionHistoryBtn'

const ApproveBtn = ({ approveFn }) => {
    const access = usePermissionChecker("checker");
    return access &&
        < AdminButton type='submit' onClick={() => approveFn()} color="#112F6A" padding="3px 15px" > <span className='pdt_1'>APPROVE</span></ AdminButton>
}

export default ApproveBtn