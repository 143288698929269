import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import DeleteBtn from "../../../../components/buttons/deleteBtn";
import EditBtn from "../../../../components/buttons/editBtn";
import ExportBtn from "../../../../components/buttons/exportBtn";
import ImportUserBtn from "../../../../components/buttons/importUsersBtn";
import VersionHistoryBtn from "../../../../components/buttons/versionHistoryBtn";
import InnerContainer from "../../../../components/layouts/innerContainer/innerContainer";
import InnerLayout from "../../../../components/layouts/innerLayout/innerLayout";
import DeleteModal from "../../../../components/modals/deleteModal";
import AddMemberLLP from "../../../../components/opportunity/llp/modals/AddMemberLLP";
import DocumentModal from "../../../../components/opportunity/llp/modals/Documentmodal";
import ImportUsersModal from "../../../../components/opportunity/llp/modals/importUserLlpModal";
import LlpDocumnetTable from "../../../../components/opportunity/llp/tables/llp-document";
import LlpMemberTable from "../../../../components/opportunity/llp/tables/llp-member-details";
import errorToast from "../../../../components/utilits/errorToast";
import LoadAndError from "../../../../components/utilits/LoadAndError";
import LoadingSpinnerTable from "../../../../components/utilits/loadingSpinnerTable";
import successToast from "../../../../components/utilits/successToast";
import usePermissionChecker from "../../../../components/utilits/usePermissionChecker";
import {
  llpImportUsersAction,
  viewLLPAction,
} from "../../../../redux/actions/llpActions";
const LlpView = () => {
  const [memberDetails, setMemberDetails] = useState([]);
  const [llpDetails, setLlpDetails] = useState([]);
  const [documentDetails, setDocumentDetails] = useState([]);
  const [show, setShow] = useState(false);
  const [showImportModal, setShowImportModal] = useState(false);
  const [showMember, setShowMember] = useState(false);
  const [loading, setLoading] = useState(false);
  const [importLoading, setImportLoading] = useState(false);
  const [error, setError] = useState(false);
  const [updated, setUpdated] = useState(false);
  const [docModalData, setDocModalData] = useState({ type: "add", data: "" });
  const [memModalData, setMemModalData] = useState({ type: "add", data: "" });
  const access = usePermissionChecker("maker");
  const handleShow = (type, data) => {
    setDocModalData({ type: type, data: data });
    setShow(true);
  };
  const handleMemShow = (type, data) => {
    setMemModalData({ type: type, data: data });
    setShowMember(true);
  };
  const handleClose = () => setShow(false);
  const handleUpdate = () => setUpdated(!updated);
  const handleMemberClose = () => setShowMember(false);
  const dispatch = useDispatch();
  const params = useParams();

  useEffect(() => {
    setLoading(true);
    dispatch(viewLLPAction(params.id, onSuccess, onError));
  }, [updated]);
  const onSuccess = (data) => {
    setMemberDetails(data?.data?.member_details);
    setDocumentDetails(data?.data?.document_detail);
    setLlpDetails(data?.data?.LLP_details);
    setLoading(false);
    setError(false);
  };

  const onError = (data) => {
    console.log(data);
    setLoading(false);
    setError(true);
  };

  const handleImportUser = () => {
    setImportLoading(true);
    dispatch(
      llpImportUsersAction(
        { llp_id: params.id },
        onImportSuccess,
        onImportError
      )
    );
  };

  const onImportSuccess = (data) => {
    successToast(data.message);
    setShowImportModal(false);
    setImportLoading(false);
    setUpdated(!updated);
  };
  const onImportError = (data) => {
    errorToast(data.message);
    setImportLoading(false);
    setShowImportModal(false);
    setUpdated(!updated);
  };

  const buttonList = [
    <VersionHistoryBtn link={`/opportunity/llp/version-history`} />,
    llpDetails?.editable && (
      <EditBtn link={`/opportunity/llp/edit/${params.id}`} />
    ),
  ];

  const data = [
    {
      heading: "LLP ID",
      title: llpDetails?.id,
    },
    {
      heading: "Opportunity ID",
      title: llpDetails?.opportunity_id,
    },
    {
      heading: " Name",
      title: llpDetails?.name,
    },
    {
      heading: "Title",
      title: llpDetails?.title,
    },
    {
      heading: "Registration Number",
      title: llpDetails?.reg_no,
    },

    {
      heading: "Status",
      title: llpDetails?.status,
    },
    {
      heading: "Member Count",
      title: memberDetails?.length,
    },

    {
      heading: "Created At",
      title: moment(
        llpDetails?.createdAt == null ? "--" : llpDetails?.createdAt
      ).format("DD-MM-YYYY HH:mm:ss"),
    },
    {
      heading: "Updated At",
      title: moment(
        llpDetails?.updatedAt == null ? "--" : llpDetails?.updatedAt
      ).format("DD-MM-YYYY HH:mm:ss"),
    },
  ];

  return (
    <InnerLayout buttons={buttonList}>
      <div className="notify-me-container">
        <InnerContainer>
          {loading ? (
            <LoadingSpinnerTable />
          ) : (
            <LoadAndError loader={loading} error={error}>
              <div className="pt-2  ps-4 ps-lg-4 ms-3">
                <h5 className="dark_grey_color fs_14 fn_Montserrat fw_500 m-0">
                  LLP Details
                </h5>
              </div>
              <div className="pt-4 mt-2  ps-4 ps-lg-4 ms-3">
                <div>
                  {data.map((item, index) => (
                    <div className="mb-3 ">
                      <h5 className="dark_grey_color fs_13 fn_Montserrat fw_500 m-0">
                        {item.heading}
                      </h5>
                      <p
                        className={` fs_14 fn_Nunito fw_500  ${
                          item.heading === "Opportunity ID" ||
                          item.heading === "LLP ID" ||
                          item.heading === "Registration Number" ||
                          item.heading === "Member Count"
                            ? "secondary_color"
                            : "dark_title_color"
                        } `}
                      >
                        {item.title}
                      </p>
                    </div>
                  ))}
                </div>
              </div>
              <div className="pt-3  px-4 ps-lg-4 ms-3">
                <div className="d-flex justify-content-between align-items-center">
                  <div className="d-flex gap-5 align-items-center">
                    <h5 className="dark_grey_color fs_14 fn_Montserrat fw_500 m-0">
                      Member Details
                    </h5>
                    <ExportBtn />
                    {access && llpDetails?.lastActionApproved && (
                      <ImportUserBtn
                        importFn={() => setShowImportModal(true)}
                      />
                    )}
                  </div>
                  <div className="">
                    {access && llpDetails?.lastActionApproved && (
                      <button
                        onClick={() => handleMemShow("add", null)}
                        className="secondary_color border-0 bg-transparent fs_13 fw_500 me-5"
                      >
                        + Add Member
                      </button>
                    )}
                  </div>
                </div>
                <div className="col-8">
                  <LlpMemberTable
                    access={access}
                    updated={handleUpdate}
                    showModal={handleMemShow}
                    llpdata={memberDetails}
                  />
                </div>
              </div>
              <div className="pt-3  px-4 ps-lg-4 ms-3">
                <div className="d-flex justify-content-between">
                  <div>
                    <h5 className="dark_grey_color fs_14 fn_Montserrat fw_500 m-0">
                      Documents
                    </h5>
                  </div>
                  {access && llpDetails?.lastActionApproved && (
                    <button
                      className="border-0 bg-transparent"
                      onClick={() => handleShow("add", null)}
                    >
                      <h5 className="secondary_color fs_13 fw_500 me-5">
                        + Add New Document
                      </h5>
                    </button>
                  )}
                </div>
                <div className="col-12">
                  <LlpDocumnetTable
                    access={access}
                    updated={handleUpdate}
                    showModal={handleShow}
                    documentData={documentDetails}
                  />
                </div>
              </div>
              <ImportUsersModal
                importFn={handleImportUser}
                loader={importLoading}
                handleClose={() => setShowImportModal(false)}
                show={showImportModal}
              />
            </LoadAndError>
          )}
        </InnerContainer>
      </div>
      <DocumentModal
        updated={handleUpdate}
        data={docModalData}
        show={show}
        closeModal={handleClose}
      />
      <AddMemberLLP
        updated={handleUpdate}
        data={memModalData}
        show={showMember}
        closeModal={handleMemberClose}
      />
    </InnerLayout>
  );
};

export default LlpView;
