import { NEWSLETTER_APPROVALS_LIST, NEWSLETTER_APPROVALS_VIEW, NEWSLETTER_APPROVAL_EXPORT, NEWSLETTER_APPROVE, NEWSLETTER_CREATE, NEWSLETTER_DELETE, NEWSLETTER_EDIT, NEWSLETTER_LIST, NEWSLETTER_MAIN_EXPORT, NEWSLETTER_READ, NEWSLETTER_REJECT, NEWSLETTER_SUBMIT, NEWSLETTER_VERSION_EXPORT, NEWSLETTER_VERSION_LIST, NEWSLETTER_VERSION_VIEW } from "./types";



export const addNewsletterAction = (data, onSuccess, onError) => {
    return {
        type: NEWSLETTER_CREATE,
        data,
        onSuccess,
        onError,
    };
};
export const approvalsNewsletterListAction = (data, page, onSuccess, onError) => {
    return {
        type: NEWSLETTER_APPROVALS_LIST,
        data,
        page,
        onSuccess,
        onError,
    };
};
export const approvalsViewNewsletterAction = (id, onSuccess, onError) => {
    return {
        type: NEWSLETTER_APPROVALS_VIEW,
        id,
        onSuccess,
        onError,
    };
};
export const approveNewsletterAction = (id, onSuccess, onError) => {
    return {
        type: NEWSLETTER_APPROVE,
        id,
        onSuccess,
        onError,
    };
};
export const rejectNewsletterAction = (id, onSuccess, onError) => {
    return {
        type: NEWSLETTER_REJECT,
        id,
        onSuccess,
        onError,
    };
};
export const viewNewsletterListAction = (data, page, onSuccess, onError) => {
    return {
        type: NEWSLETTER_LIST,
        data,
        page,
        onSuccess,
        onError,
    };
};
export const viewNewsletterAction = (id, onSuccess, onError) => {
    return {
        type: NEWSLETTER_READ,
        id,
        onSuccess,
        onError,
    };
};

export const editNewsletterAction = (id, data, onSuccess, onError) => {
    return {
        type: NEWSLETTER_EDIT,
        id,
        data,
        onSuccess,
        onError,
    };
};
export const deleteNewsletterAction = (id, onSuccess, onError) => {
    return {
        type: NEWSLETTER_DELETE,
        id,
        onSuccess,
        onError,
    };
};

export const versionListNewsletterAction = (data, page, onSuccess, onError) => {
    return {
        type: NEWSLETTER_VERSION_LIST,
        data,
        page,
        onSuccess,
        onError,
    };
};
export const versionViewNewsletterAction = (id, onSuccess, onError) => {
    return {
        type: NEWSLETTER_VERSION_VIEW,
        id,
        onSuccess,
        onError,
    };
};



export const newsLetterMainExportAction = (onSuccess, onError) => {
    return {
        type: NEWSLETTER_MAIN_EXPORT,
        onSuccess,
        onError,
    };
};
export const newsLetterApprovalsExportAction = (onSuccess, onError) => {
    return {
        type: NEWSLETTER_APPROVAL_EXPORT,
        onSuccess,
        onError,
    };
};
export const newsLetterVersionExportAction = (onSuccess, onError) => {
    return {
        type: NEWSLETTER_VERSION_EXPORT,
        onSuccess,
        onError,
    };
};


export const newsLetterSubmit = (id, onSuccess, onError) => {
    return {
        type: NEWSLETTER_SUBMIT,
        id,
        onSuccess,
        onError,
    };
};  