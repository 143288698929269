import {
  ACTIVATE_PARTNER_WITH_US_OPTIONS,
  PARTNER_WITH_US_APPROVALS_EXPORT,
  PARTNER_WITH_US_APPROVALS_LIST,
  PARTNER_WITH_US_APPROVALS_VIEW,
  PARTNER_WITH_US_APPROVE,
  PARTNER_WITH_US_CREATE,
  PARTNER_WITH_US_DELETE,
  PARTNER_WITH_US_EDIT,
  PARTNER_WITH_US_LIST,
  PARTNER_WITH_US_MAIN_EXPORT,
  PARTNER_WITH_US_READ,
  PARTNER_WITH_US_REJECT,
  PARTNER_WITH_US_SUBMIT,
  PARTNER_WITH_US_VERSION_EXPORT,
  PARTNER_WITH_US_VERSION_LIST,
  PARTNER_WITH_US_VERSION_VIEW,
} from "./types";

export const addPartnerWithUsAction = (data, onSuccess, onError) => {
  return {
    type: PARTNER_WITH_US_CREATE,
    data,
    onSuccess,
    onError,
  };
};
export const approvalsPartnerWithUsListAction = (
  page,
  search,
  onSuccess,
  onError
) => {
  return {
    type: PARTNER_WITH_US_APPROVALS_LIST,

    page,
    search,
    onSuccess,
    onError,
  };
};
export const approvalsViewPartnerWithUsAction = (id, onSuccess, onError) => {
  return {
    type: PARTNER_WITH_US_APPROVALS_VIEW,
    id,
    onSuccess,
    onError,
  };
};
export const approvePartnerWithUsAction = (id, onSuccess, onError) => {
  return {
    type: PARTNER_WITH_US_APPROVE,
    id,
    onSuccess,
    onError,
  };
};
export const rejectPartnerWithUsAction = (id, onSuccess, onError) => {
  return {
    type: PARTNER_WITH_US_REJECT,
    id,
    onSuccess,
    onError,
  };
};
export const viewPartnerWithUsListAction = (page, search, onSuccess, onError) => {
  return {
    type: PARTNER_WITH_US_LIST,
    page,
    search,
    onSuccess,
    onError,
  };
};
export const viewPartnerWithUsAction = (id, onSuccess, onError) => {
  return {
    type: PARTNER_WITH_US_READ,
    id,
    onSuccess,
    onError,
  };
};

export const editPartnerWithUsAction = (id, data, onSuccess, onError) => {
  return {
    type: PARTNER_WITH_US_EDIT,
    id,
    data,
    onSuccess,
    onError,
  };
};
export const deletePartnerWithUsAction = (id, onSuccess, onError) => {
  return {
    type: PARTNER_WITH_US_DELETE,
    id,
    onSuccess,
    onError,
  };
};

export const versionListPartnerWithUsAction = (page, search, onSuccess, onError) => {
  return {
    type: PARTNER_WITH_US_VERSION_LIST,
    page,
    search,
    onSuccess,
    onError,
  };
};
export const versionViewPartnerWithUsAction = (id, onSuccess, onError) => {
  return {
    type: PARTNER_WITH_US_VERSION_VIEW,
    id,
    onSuccess,
    onError,
  };
};
export const activatePartnerWithUsAction = (id, onSuccess, onError) => {
  return {
    type: ACTIVATE_PARTNER_WITH_US_OPTIONS,
    id,
    onSuccess,
    onError,
  };
};

export const partnerWithUsMainExportAction = (onSuccess, onError) => {
  return {
    type: PARTNER_WITH_US_MAIN_EXPORT,
    onSuccess,
    onError,
  };
};
export const partnerWithUsApprovalsExportAction = (onSuccess, onError) => {
  return {
    type: PARTNER_WITH_US_APPROVALS_EXPORT,
    onSuccess,
    onError,
  };
};
export const partnerWithUsVersionExportAction = (onSuccess, onError) => {
  return {
    type: PARTNER_WITH_US_VERSION_EXPORT,
    onSuccess,
    onError,
  };
};

export const partnerWithUsSubmitAction = (id, onSuccess, onError) => {
  return {
    type: PARTNER_WITH_US_SUBMIT,
    id,
    onSuccess,
    onError,
  };
};
