import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "../../admin/tables/admintable.css";
import errorToast from "../../utilits/errorToast";
import { TableContainer } from "../../table/tableContainer";
import TablePagination from "../../table/tablePagination";
import LoadingSpinnerTable from "../../utilits/loadingSpinnerTable";
import {
  tableAdvisorApprovalsHeader,
  AdvisorApprovalsTableData,
} from "../../table/tableData";
import { useDispatch } from "react-redux";
import { approvalsAdvisorListAction } from "../../../redux/actions/ourAdvisorAction";
import moment from "moment";
import LoadAndError from "../../utilits/LoadAndError";

const OurAdvisorApprovalsTableContent = ({ page, search, changePage }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [loadingTable, setLoadingTable] = useState(false);

  const dispatch = useDispatch();
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);
  const [show, setShow] = useState(false);
  const [deleteData, setDeleteData] = useState({ name: "", id: "" });
  const [pageData, setPageData] = useState({ noOfItems: 0, noOfPages: 1 });
  const [advisorApprovalData, setAdvisorApprovalData] = useState([]);
  // const [llpVersionTableData, setLlpVersionTableData] = useState([]);

  useEffect(() => {
    setLoadingTable(true);
    dispatch(
      approvalsAdvisorListAction(
        {
          search: {
            advisors_id: search?.value?.advisorId,
            name: search?.value?.name,
            designation: search?.value?.designation,
            priority: search?.value?.priority,
          },
        },
        page,
        onSuccess,
        onError
      )
    );
  }, [page, search, loading]);
  const onSuccess = (data) => {
    setAdvisorApprovalData(data?.data?.data);
    setPageData({
      noOfItems: data.data.count,
      noOfPages: data.data.pages,
    });
    setLoadingTable(false);
    setLoading(false);
    setError(false);
  };
  const onError = (data) => {
    errorToast(data.message);
    setLoadingTable(false);
    setLoading(true);
    setLoading(false);
  };

  function handlePagination(type) {
    if (type === "+") {
      if (page + 1 < pageData.noOfPages) changePage("+");
    } else if (type === "-") if (page > 0) changePage("-");
  }
  return (
    <TableContainer width="90%">
      <div className="adminuser_table__container">
        {loading && <LoadingSpinnerTable />}
        <table className={"adminuser_table"}>
          <thead className="adminuser_table__header">
            <tr className="adminuser_table__row">
              {tableAdvisorApprovalsHeader.map((item) => {
                return (
                  <th
                    key={item + "x"}
                    className={`${
                      item === "Status"
                        ? " position-sticky end-0 text-center"
                        : ""
                    } text-nowrap adminuser_table__heading`}
                  >
                    {item}
                  </th>
                );
              })}
            </tr>
          </thead>
          {loading === false && (
            <tbody className="adminuser_table__body">
              <LoadAndError
                loader={loading}
                error={error}
                status={advisorApprovalData.length === 0}
              >
                {advisorApprovalData.map((item, index) => {
                  return (
                    <tr key={item?.id} className="adminuser_table__row">
                      <td className="text-nowrap dark_title_color adminuser_table__data ">
                        {page > 0 ? 12 * page + (index + 1) : index + 1}
                      </td>
                      <td className="text-nowrap secondary_color  adminuser_table__data ">
                        {item.id}
                      </td>
                      <td className="text-nowrap dark_title_color adminuser_table__data">
                        {item?.our_advisor_id == null
                          ? "--"
                          : item?.our_advisor_id}
                      </td>
                      <td className="text-nowrap dark_title_color adminuser_table__data">
                        {item?.new_data == null
                          ? item?.old_data?.name
                          : item?.new_data?.name}
                      </td>
                      <td className="text-nowrap dark_title_color  adminuser_table__data">
                        {item?.new_data == null
                          ? item?.old_data?.designation
                          : item?.new_data?.designation}
                      </td>
                      <td className="text-nowrap dark_title_color  adminuser_table__data">
                        {item?.new_data == null
                          ? item?.old_data?.priority
                          : item?.new_data?.priority}
                      </td>
                      <td className="text-nowrap dark_title_color  adminuser_table__data">
                        {moment(item[0]?.approved_date).format(
                          "DD-MM-YYYY HH:mm:ss"
                        )}
                      </td>
                      <td className="text-nowrap dark_title_color  adminuser_table__data">
                        {moment(item[0]?.updatedAt).format(
                          "DD-MM-YYYY HH:mm:ss"
                        )}
                      </td>

                      <td className="text-nowrap  position-sticky end-0 adminuser_table__data">
                        <div className="d-flex w-100  justify-content-center gap-2">
                          <Link
                            className="text-decoration-none primary_color"
                            to={{
                              pathname: `/static-pages/our-advisors/approvals/view/${item.id}`,
                            }}
                          >
                            View
                          </Link>
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </LoadAndError>
            </tbody>
          )}
        </table>
      </div>
      <TablePagination
        paginate={handlePagination}
        currentPage={page}
        pageData={pageData}
      />
    </TableContainer>
  );
};

export default OurAdvisorApprovalsTableContent;
