import { act } from "react-dom/test-utils";
import { call, takeLatest } from "redux-saga/effects";
import { ACTIVATE_HOW_IT_WORKS_OPTIONS, HOW_IT_WORKS_APPROVALS_EXPORT, HOW_IT_WORKS_APPROVALS_LIST, HOW_IT_WORKS_APPROVALS_VIEW, HOW_IT_WORKS_APPROVE, HOW_IT_WORKS_CREATE, HOW_IT_WORKS_DELETE, HOW_IT_WORKS_EDIT, HOW_IT_WORKS_LIST, HOW_IT_WORKS_MAIN_EXPORT, HOW_IT_WORKS_READ, HOW_IT_WORKS_REJECT, HOW_IT_WORKS_SUBMIT, HOW_IT_WORKS_VERSION_EXPORT, HOW_IT_WORKS_VERSION_LIST, HOW_IT_WORKS_VERSION_VIEW, NEWSLETTER_APPROVALS_LIST, NEWSLETTER_APPROVALS_VIEW, NEWSLETTER_APPROVE, NEWSLETTER_CREATE, NEWSLETTER_DELETE, NEWSLETTER_EDIT, NEWSLETTER_LIST, NEWSLETTER_READ, NEWSLETTER_REJECT, NEWSLETTER_VERSION_LIST, NEWSLETTER_VERSION_VIEW } from "../actions/types";
import { activateHowItWorksApi, addHowItWorksApi, approvalsHowItWorksListpApi, approveHowItWorksApi, deleteHowItWorksApi, editHowItWorksApi, howItWorksApprovalsExportApi, howItWorksMainExportApi, howItWorksSubmitApi, howItWorksVersionExportApi, rejectHowItWorksApi, versionHowItWorksListpApi, viewApprovalHowItWorksApi, viewHowItWorksApi, viewHowItWorksListApi, viewVersionHowItWorksApi } from "../apis/howItWorksApi";

export function* addHowItWorksWorker(action) {
    try {
        const res = yield call(addHowItWorksApi,
            action.data);
        if (res.status === 200 && res.data.status === "success") {
            yield action.onSuccess(res.data);
            console.log(res.data);
        } else if (res.status === 200 && res.data.status === "fail") {
            console.log(res.data);
            yield action.onError(res);
        } else {
            yield action.onError("Something Went Wrong");
        }
    } catch (err) {
        console.log(err);
        yield action.onError("Something Went Wrong");
    }

}

export function* viewHowItWorksWorker(action) {
    try {
        const res = yield call(viewHowItWorksApi,
            action.id,
        );
        if (res.status === 200 && res.data.status === "success") {
            yield action.onSuccess(res.data);
            console.log(res.data);
        } else if (res.status === 200 && res.data.status === "fail") {
            console.log(res.data);
            yield action.onError(res);
        } else {
            yield action.onError("Something Went Wrong!");
            console.log(res.data);
        }
    } catch (err) {
        console.log(err);
        yield action.onError("Something Went Wrong!!");
    }
}
export function* editHowItWorksWorker(action) {

    try {
        const res = yield call(editHowItWorksApi, action.id, action.data);
        console.log(res);
        if (res.status === 200 && res.data.status === "success") {
            yield action.onSuccess(res.data);
            console.log(res.data);
        } else if (res.status === 200 && res.data.status === "fail") {
            console.log(res);
            yield action.onError(res);
        } else {
            yield action.onError("Something Went Wrong");
        }
    } catch (err) {
        console.log(err);
        yield action.onError("Something Went Wrong");
    }

}
export function* deleteHowItWorksRoleWorker(action) {

    try {
        const res = yield call(deleteHowItWorksApi,
            action.id,
        );
        if (res.status === 200 && res.data.status === "success") {
            yield action.onSuccess(res.data);
            console.log(res.data);
        } else if (res.status === 200 && res.data.status === "fail") {
            console.log(res.data);
            yield action.onError(res);
        } else {
            yield action.onError("Something Went Wrong");
        }
    } catch (err) {
        console.log(err);
        yield action.onError("Something Went Wrong");
    }

}
export function* viewHowItWorksListWorker(action) {
    try {
        const res = yield call(viewHowItWorksListApi, action.page);
        console.log(res);
        if (res.status === 200 && res.data.status === "success") {
            yield action.onSuccess(res.data);
            console.log(res.data);
        } else if (res.status === 200 && res.data.status === "fail") {
            console.log(res);
            yield action.onError(res.data);
        } else {
            yield action.onError("Something Went Wrong");
        }
    } catch (err) {
        console.log(err);
        yield action.onError("Something Went Wrong");
    }

}


export function* approveHowItWorksWorker(action) {
    try {
        const res = yield call(approveHowItWorksApi,
            { checker_id: action.id }
        );
        if (res.status === 200 && res.data.status === "success") {
            yield action.onSuccess(res.data);
            console.log(res.data);
        } else if (res.status === 200 && res.data.status === "fail") {
            console.log(res);
            yield action.onError(res.data);
        } else {
            yield action.onError("Something Went Wrong!");
            console.log(res.data);
        }
    } catch (err) {
        console.log(err);
        yield action.onError("Something Went Wrong!!");
    }
}

export function* rejectHowItWorksWorker(action) {
    try {
        const res = yield call(rejectHowItWorksApi,
            { id: action.id }
        );
        console.log(res, "rekect howitworks");
        if (res.status === 200 && res.data.status === "success") {
            yield action.onSuccess(res.data);
            console.log(res.data);
        } else if (res.status === 200 && res.data.status === "fail") {
            console.log(res);
            yield action.onError(res.data);
        } else {
            yield action.onError("Something Went Wrong!");
            console.log(res.data);
        }
    } catch (err) {
        console.log(err);
        yield action.onError("Something Went Wrong!!");
    }
}
export function* approvalsHowItWorksListWorker(action) {
    try {
        const res = yield call(approvalsHowItWorksListpApi, action.page
        );
        // console.log(res, "AddAdminRole");
        if (res.status === 200 && res.data.status === "success") {
            yield action.onSuccess(res.data);
            console.log(res.data);
        } else if (res.status === 200 && res.data.status === "fail") {
            console.log(res);
            yield action.onError(res);
        } else {
            yield action.onError("Something Went Wrong!");
            console.log(res.data);
        }
    } catch (err) {
        console.log(err);
        yield action.onError("Something Went Wrong!!");
    }
}
export function* viewApprovalHowItWorksWorker(action) {
    try {
        const res = yield call(viewApprovalHowItWorksApi, action.id
        );
        console.log(res, "HowItWorks approvals");
        if (res.status === 200 && res.data.status === "success") {
            yield action.onSuccess(res.data);
            console.log(res.data);
        } else if (res.status === 200 && res.data.status === "fail") {
            console.log(res);
            yield action.onError(res);
        } else {
            yield action.onError("Something Went Wrong!");
            console.log(res.data);
        }
    } catch (err) {
        console.log(err);
        yield action.onError("Something Went Wrong!!");
    }
}
export function* versionListHowItWorksWorker(action) {
    try {
        const res = yield call(versionHowItWorksListpApi,
            action.page,
            action.data
        );
        // console.log(res, "AddAdminRole");
        if (res.status === 200 && res.data.status === "success") {
            yield action.onSuccess(res.data);
            console.log(res.data);
        } else if (res.status === 200 && res.data.status === "fail") {
            console.log(res);
            yield action.onError(res);
        } else {
            yield action.onError("Something Went Wrong!");
            console.log(res.data);
        }
    } catch (err) {
        console.log(err);
        yield action.onError("Something Went Wrong!!");
    }
}
export function* activateHowItWorksWorker(action) {
    try {
        const res = yield call(activateHowItWorksApi, action.id,
        );
        if (res.status === 200 && res.data.status === "success") {
            yield action.onSuccess(res);
            console.log(res.data);
        } else if (res.status === 200 && res.data.status === "fail") {
            console.log(res);
            yield action.onError(res);
        } else {
            yield action.onError("Something Went Wrong!");
            console.log(res);
        }
    } catch (err) {
        console.log(err);
        yield action.onError("Something Went Wrong!!");
    }
}
export function* versionViewHowItWorksWorker(action) {
    try {
        const res = yield call(viewVersionHowItWorksApi, action.data,
        );
        if (res.status === 200 && res.data.status === "success") {
            yield action.onSuccess(res);
            console.log(res.data);
        } else if (res.status === 200 && res.data.status === "fail") {
            console.log(res);
            yield action.onError(res);
        } else {
            yield action.onError("Something Went Wrong!");
            console.log(res);
        }
    } catch (err) {
        console.log(err);
        yield action.onError("Something Went Wrong!!");
    }
}

export function* howItWorksMainExportWorker(action) {
    try {
        const res = yield call(howItWorksMainExportApi);
        console.log(res);
        if (res.status === 200 && res.data.status === "success") {
            yield action.onSuccess(res.data);
        } else {
            yield action.onError(res.data);
        }
    } catch (error) { }
}


export function* howItWorksApprovalsExportWorker(action) {
    try {
        const res = yield call(howItWorksApprovalsExportApi);
        console.log(res);
        if (res.status === 200 && res.data.status === "success") {
            yield action.onSuccess(res.data);
        } else {
            yield action.onError(res.data);
        }
    } catch (error) { }
}




export function* howItWorksVersionExportWorker(action) {
    try {
        const res = yield call(howItWorksVersionExportApi);
        console.log(res);
        if (res.status === 200 && res.data.status === "success") {
            yield action.onSuccess(res.data);
        } else {
            yield action.onError(res.data);
        }
    } catch (error) { }
}
export function* howItWorksSubmitWorker(action) {
    try {
        const res = yield call(howItWorksSubmitApi, action.id);
        console.log(res);
        if (res.status === 200 && res.data.status === "success") {
            yield action.onSuccess(res.data);
        } else {
            yield action.onError(res.data);
        }
    } catch (error) { }
}

export function* watchHowItWorksSubmitWorker() {
    yield takeLatest(
        HOW_IT_WORKS_SUBMIT,
        howItWorksSubmitWorker
    );
}














/**********************************/
/************WATHCHERS**************/
/**********************************/

export function* watchAddHowItWorksWorker() {
    yield takeLatest(HOW_IT_WORKS_CREATE, addHowItWorksWorker);
}
export function* watchApprovalsHowItWorksListWorker() {
    yield takeLatest(HOW_IT_WORKS_APPROVALS_LIST, approvalsHowItWorksListWorker);
}
export function* watchViewApprovalHowItWorksWorker() {
    yield takeLatest(HOW_IT_WORKS_APPROVALS_VIEW, viewApprovalHowItWorksWorker);
}
export function* watchViewHowItWorksWorker() {
    yield takeLatest(HOW_IT_WORKS_READ, viewHowItWorksWorker);
}
export function* watchViewHowItWorksListWorker() {
    yield takeLatest(HOW_IT_WORKS_LIST, viewHowItWorksListWorker);
}
export function* watcheditHowItWorksWorker() {
    yield takeLatest(HOW_IT_WORKS_EDIT, editHowItWorksWorker);
}
export function* watchApproveHowItWorksWorker() {
    yield takeLatest(HOW_IT_WORKS_APPROVE, approveHowItWorksWorker);
}
export function* watchRejectHowItWorksWorker() {
    yield takeLatest(HOW_IT_WORKS_REJECT, rejectHowItWorksWorker);
}
export function* watchDeleteHowItWorksWorker() {
    yield takeLatest(HOW_IT_WORKS_DELETE, deleteHowItWorksRoleWorker);
}
export function* watchversionListHowItWorksWorker() {
    yield takeLatest(HOW_IT_WORKS_VERSION_LIST, versionListHowItWorksWorker);
}
export function* watchVersionViewHowItWorksWorker() {
    yield takeLatest(HOW_IT_WORKS_VERSION_VIEW, versionViewHowItWorksWorker);
}
export function* WatchActivateHowItWorksWorker() {
    yield takeLatest(ACTIVATE_HOW_IT_WORKS_OPTIONS, activateHowItWorksWorker);
}

export function* watchhowItWorksMainExportWorker() {
    yield takeLatest(HOW_IT_WORKS_MAIN_EXPORT, howItWorksMainExportWorker);
}
export function* watchhowItWorksApprovalsExportWorker() {
    yield takeLatest(HOW_IT_WORKS_APPROVALS_EXPORT, howItWorksApprovalsExportWorker);
}
export function* watchhowItWorksVersionExportWorker() {
    yield takeLatest(
        HOW_IT_WORKS_VERSION_EXPORT,
        howItWorksVersionExportWorker
    );
}