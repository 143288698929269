import React from "react";
import AddIcon from "../../assets/icons/addIcon";
import { AdminButton } from "./versionHistoryBtn";
import { useNavigate } from "react-router-dom";
import usePermissionChecker from "../utilits/usePermissionChecker";
const CreateBtn = ({ link = "/"
}) => {
  const navigate = useNavigate()
  const access = usePermissionChecker("maker");
  return (
    access &&
    <AdminButton onClick={() => navigate(link)} color="#666666" padding="5px 10px">
      <AddIcon fill="#666666" />
      <span className="ms-1">CREATE</span>
    </AdminButton>
  );
};

export default CreateBtn;
