import React, { useState } from "react";
import ApprovalsBtn from "../../../../components/buttons/approvalsBtn";
import CreateBtn from "../../../../components/buttons/createBtn";
import ExportBtn from "../../../../components/buttons/exportBtn";
import FilterBtn from "../../../../components/buttons/filterBtn";
import VersionHistoryBtn from "../../../../components/buttons/versionHistoryBtn";
import HomeFilter from "../../../../components/filter/static-pages filter/homeFilter";
import VersionTablesFilter from "../../../../components/filter/versionTableFilter";
import InnerLayout from "../../../../components/layouts/innerLayout/innerLayout";
import CampaignTableContent from "../../../../components/static-pages/campaign/tables/campaignTableContent";
import PartnerWithUsTableContent from "../../../../components/static-pages/partner-with-us/tables/partnerWithUsTableContent";
import { campaignMainExportAction } from "../../../../redux/actions/campaignActions";
import { partnerWithUsMainExportAction } from "../../../../redux/actions/partnerWithUsAction";


const PartnerWithUsTable = () => {
  const [showFilter, setShowFilter] = useState(false);
  const [search, setSearch] = useState({ value: "" });
  const [page, setPage] = useState(0);
  const handleFilterClose = () => {
    setShowFilter(false);
  };
  const handleFilterOpen = () => {
    setShowFilter(true);
  };
  const handleSearch = (value) => {
    setSearch({ value: value });
    setPage(0)
  };
  const handlePage = (type) => {
    if (type === "+") {
      setPage((prev) => prev + 1)
    }
    else {
      setPage((prev) => prev - 1)
    }
  }
  const buttonList = [
    <ApprovalsBtn link='/static-pages/partner-with-us/approvals' />,
    <VersionHistoryBtn link='/static-pages/partner-with-us/version-history' />,
    <CreateBtn link='/static-pages/partner-with-us/create' />,
    <ExportBtn dispatchAction={campaignMainExportAction} />,
    <FilterBtn filterFn={handleFilterOpen} />,
  ];
  return (
    <InnerLayout buttons={buttonList}>
      <VersionTablesFilter
        title='Partners Id'
        placeholder='Enter Partners Id'
        filter={handleSearch}
        showFilter={showFilter}
        closeFilter={handleFilterClose}
      />
      <PartnerWithUsTableContent page={page} changePage={handlePage} search={search} />
    </InnerLayout>
  );
};

export default PartnerWithUsTable;
