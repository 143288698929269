import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import moment from "moment";
import { useParams } from "react-router-dom";
import errorToast from "../../../../components/utilits/errorToast";
import { JSONTree } from "react-json-tree";
import InnerLayout from "../../../../components/layouts/innerLayout/innerLayout";
import InnerContainer from "../../../../components/layouts/innerContainer/innerContainer";
import LoadAndError from "../../../../components/utilits/LoadAndError";
import ArrowRightIcon from "../../../../assets/icons/arrowrightIcon";
import { versionViewUserReferralAction } from "../../../../redux/actions/userReferralActions";
const UserReferralVersionHistoryDetails = () => {
  const params = useParams();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  // Notify me veersion history view
  console.log(params.id);
  const [versionHistoyNotifyMeData, setVersionHistoyNotifyMeData] = useState({
    id: "",
    createdAt: "",
    updatedAt: "",
    new_data: { updated_by: "", updated_At: "" },
  });
  const [versionHistoyNewData, setVersionHistoyNewData] = useState({});
  const [versionHistoyOldData, setVersionHistoyOldData] = useState({});
  const dispatch = useDispatch();
  useEffect(() => {
    // setLoading(true);
    dispatch(versionViewUserReferralAction(params.id, onSuccess, onError));
  }, [params.id]);
  console.log(params.id);
  console.log(versionHistoyNotifyMeData);
  const onSuccess = (data) => {
    setVersionHistoyNotifyMeData(data?.data?.data);
    setVersionHistoyNewData(data?.data?.data.new_data);
    setVersionHistoyOldData(data?.data?.data.old_data);
    console.log(data);
    setLoading(false);
    setError(false);
  };
  const onError = (data) => {
    errorToast(data.message);
    setLoading(false);
    setError(true);
  };
  console.log(versionHistoyNewData);
  console.log(versionHistoyOldData);
  console.log(versionHistoyNotifyMeData);
  const data = [
    {
      heading: "User ID",
      title: versionHistoyNotifyMeData.id,
    },
    {
      heading: "Created At",
      title: moment(versionHistoyNotifyMeData.createdAt).format(
        "DD-MM-YYYY HH:mm:ss"
      ),
    },
    {
      heading: "Updated At",
      title: moment(versionHistoyNotifyMeData.updatedAt).format(
        "DD-MM-YYYY HH:mm:ss"
      ),
    },
    {
      heading: "Updated By",
      title: versionHistoyNotifyMeData.updated_by,
    },
  ];

  const new_data = [
    {
      heading: "User ID",
      title:
        versionHistoyNotifyMeData?.user_id == null
          ? "--"
          : versionHistoyNotifyMeData?.user_id,
    },

    {
      heading: "Opportunity ID",
      title:
        versionHistoyNotifyMeData?.new_data?.opportunity_id == null
          ? "--"
          : versionHistoyNotifyMeData?.new_data?.opportunity_id,
    },

    {
      heading: "Update Date",
      title: moment(versionHistoyNotifyMeData?.updatedAt).format(
        "DD-MM-YYYY HH:mm:ss"
      ),
    },
    {
      heading: "Approved Date",
      title: moment(versionHistoyNotifyMeData?.approved_date).format(
        "DD-MM-YYYY HH:mm:ss"
      ),
    },
  ];

  const old_data = [
    {
      heading: "User ID",
      title:
        versionHistoyNotifyMeData?.user_id == null
          ? "--"
          : versionHistoyNotifyMeData?.user_id,
    },
    {
      heading: "Opportunity ID",
      title:
        versionHistoyNotifyMeData?.old_data?.opportunity_id == null
          ? "--"
          : versionHistoyNotifyMeData?.old_data?.opportunity_id,
    },

    {
      heading: "Update Date",
      title: moment(versionHistoyNotifyMeData?.old_data?.updatedAt).format(
        "DD-MM-YYYY HH:mm:ss"
      ),
    },
    {
      heading: "Approved Date",
      title: moment(versionHistoyNotifyMeData?.old_data?.approved_date).format(
        "DD-MM-YYYY HH:mm:ss"
      ),
    },
  ];

  const theme = {
    scheme: "monokai",
    author: "wimer hazenberg (http://www.monokai.nl)",
    base00: "#272822",
    base01: "#383830",
    base02: "#49483e",
    base03: "#75715e",
    base04: "#a59f85",
    base05: "#f8f8f2",
    base06: "#f5f4f1",
    base07: "#f9f8f5",
    base08: "#f92672",
    base09: "#464646",
    base0A: "#f4bf75",
    base0B: "#464646",
    base0C: "#a1efe4",
    base0D: "#464646",
    base0E: "#ae81ff",
    base0F: "#cc6633",
  };

  const json = {
    array: [1, 2, 3],
    bool: true,
    object: {
      foo: "bar",
    },
  };

  return (
    <InnerLayout>
      <div className="notify-me-container ">
        <InnerContainer>
          <LoadAndError loader={loading} error={error}>
            <div className="pt-3 ps-5 row">
              {data.map((item, index) => (
                <div className="mb-2  col-sm-4 col-md-3  col-xl-2 col-lg-3">
                  <h5 className="dark_grey_color  fs_13 fn_Montserrat fw_500 ">
                    {item.heading}
                  </h5>
                  <p
                    className={` fs_13 fn_Nunito  fw_500 ${
                      item.heading === "Notify Me ID"
                        ? "secondary_color"
                        : "dark_title_color"
                    }`}
                  >
                    {item.title}
                  </p>
                </div>
              ))}
            </div>
            <div className="white_shade_bg  py-1 d-flex">
              <div className="w-50">
                <h5 className="fw_500 ps-4 dark_grey_color pt-2 text-nowrap fs_16 fn_Montserrat">
                  JSON Old Data
                </h5>
              </div>
              <div className="w-50  d-none d-lg-block">
                <h5 className="fw_500 ps-4  dark_grey_color pt-2 text-nowrap fs_16 fn_Montserrat">
                  JSON New Data
                </h5>
              </div>
            </div>
            <div className="row mt-3 ps-0 ps-lg-4">
              <div className="col-12 col-lg-6">
                <div className="notify_me_aprvls_vrsn_hstry_data_container  mt-3 pt-3 px-3">
                  <div className="d-flex justify-content-between">
                    <h5 className="fs_13 fn_Nunito fw_400 dark_title_color text-nowrap">
                      Old Data
                    </h5>
                    <ArrowRightIcon width={25} height={25} fill={"#A5A5A5"} />
                  </div>
                  {versionHistoyNotifyMeData.old_data === null ? (
                    <div>Empty data</div>
                  ) : (
                    <>
                      <JSONTree
                        theme={theme}
                        data={versionHistoyNotifyMeData?.old_data}
                      />
                      ;
                    </>
                  )}
                </div>
              </div>
              <div className="col-12 mt-5 mt-lg-0 col-lg-6">
                <h5 className="fw_500 ps-4 d-block d-lg-none white_shade_bg  dark_grey_color py-3 text-nowrap fs_16 fn_Montserrat">
                  JSON New Data
                </h5>
                <div className="notify_me_aprvls_vrsn_hstry_data_container    mt-4 mt-lg-3 pt-3 px-3">
                  <div className="d-flex justify-content-between">
                    <h5 className="fs_13 fn_Nunito fw_400 dark_title_color py-2 text-nowrap">
                      New Data
                    </h5>
                    <ArrowRightIcon width={25} height={25} fill={"#A5A5A5"} />
                  </div>
                  <div className="h-100">
                    {versionHistoyNotifyMeData.new_data === null ? (
                      <div>Empty data</div>
                    ) : (
                      <>
                        <JSONTree
                          theme={theme}
                          data={versionHistoyNotifyMeData?.new_data}
                        />
                        ;
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </LoadAndError>
        </InnerContainer>
      </div>
    </InnerLayout>
  );
};

export default UserReferralVersionHistoryDetails;
