import { authHeader } from "../config";
import { api } from "./api";

export const createCampaignOpporApi = (params) => {
    return api.post("/api/admin/campaign-opportunities/create-campaign", params, {
        headers: {
            "x-access-token": localStorage.getItem("accessToken"),
            "Content-Type": "application/json",
        },
    });
};

export const approveCampaignOpporApi = (params) => {
    return api.post("/api/admin/campaign-opportunities/approve", JSON.stringify(params), {
        headers: {
            "x-access-token": localStorage.getItem("accessToken"),
            "Content-Type": "application/json",
        },
    });
};

export const rejectCampaignOpporApi = (id, params) => {
    return api.post(
        `/api/admin/campaign-opportunities/reject/${id}`,
        JSON.stringify(params),
        {
            headers: {
                "x-access-token": localStorage.getItem("accessToken"),
                "Content-Type": "application/json",
            },
        }
    );
};

export const viewCampaignOpporDetailApi = (id, params) => {
    return api.post(
        `/api/admin/campaign-opportunities/view-campaign/${id}`,
        JSON.stringify(params),
        {
            headers: {
                "x-access-token": localStorage.getItem("accessToken"),
                "Content-Type": "application/json",
            },
        }
    );
};



export const viewCampaignOpporListApi = (pageNo = 0, params) => {
    return api.post(
        `/api/admin/campaign-opportunities/view-campaign?page_no=${pageNo}`,
        params,
        {
            headers: {
                "x-access-token": localStorage.getItem("accessToken"),
                "Content-Type": "application/json",
            },
        }
    );
};

export const editCampaignOpporApi = (id, params) => {
    return api.post(
        `/api/admin/campaign-opportunities/edit/${id}`,
        JSON.stringify(params),
        {
            headers: {
                "x-access-token": localStorage.getItem("accessToken"),
                "Content-Type": "application/json",
            },
        }
    );
};

export const deleteCampaignOpporApi = (id, params) => {
    return api.post(
        `/api/admin/campaign-opportunities/delete/${id}`,
        JSON.stringify(params),
        {
            headers: {
                "x-access-token": localStorage.getItem("accessToken"),
                "Content-Type": "application/json",
            },
        }
    );
};


export const campaignOpporApprovalListApi = (pageNo, params) => {
    return api.post(
        `/api/admin/campaign-opportunities/view-checker?page_no=${pageNo}`,
        JSON.stringify(params),
        {
            headers: {
                "x-access-token": localStorage.getItem("accessToken"),
                "Content-Type": "application/json",
            },
        }
    );
};



export const campaignOpporApprovalByIdApi = (id, params) => {
    return api.post(
        `/api/admin/campaign-opportunities/view-checker/${id}`,
        JSON.stringify(params),
        {
            headers: {
                "x-access-token": localStorage.getItem("accessToken"),
                "Content-Type": "application/json",
            },
        }
    );
};

export const versionHistoryCampaignOpporListApi = (pageNo, params) => {
    return api.post(
        `/api/admin/campaign-opportunities/view-version?page_no=${pageNo}`,
        JSON.stringify(params),
        {
            headers: {
                "x-access-token": localStorage.getItem("accessToken"),
                "Content-Type": "application/json",
            },
        }
    );
};

export const versionHistoryCampaignOpporDetailApi = (id, params) => {
    return api.post(
        `/api/admin/campaign-opportunities/view-version/${id}`,
        JSON.stringify(params),
        {
            headers: {
                "x-access-token": localStorage.getItem("accessToken"),
                "Content-Type": "application/json",
            },
        }
    );
};
export const campaignOpporTableExportApi = (params) => {
    return api.post(
        `/api/admin/campaign-opportunities/export-main-campaign`,
        JSON.stringify(params),
        {
            headers: {
                "x-access-token": localStorage.getItem("accessToken"),
                "Content-Type": "application/json",
            },
        }
    );
};
export const campaignOpporApprovalsExportApi = (params) => {
    return api.post(
        `/api/admin/campaign-opportunities/export-approval-campaign`,
        JSON.stringify(params),
        {
            headers: {
                "x-access-token": localStorage.getItem("accessToken"),
                "Content-Type": "application/json",
            },
        }
    );
};
export const campaignOpporVersionExportApi = (params) => {
    return api.post(
        `/api/admin/campaign-opportunities/export-version-campaign`,
        JSON.stringify(params),
        {
            headers: {
                "x-access-token": localStorage.getItem("accessToken"),
                "Content-Type": "application/json",
            },
        }
    );
};

export const campaignOpporSubmitApi = (id, params) => {
    return api.post(`/api/admin/campaign-opportunities/on-submit/${id}`, params, {
        headers: {
            "x-access-token": localStorage.getItem("accessToken"),
            "Content-Type": "application/json",
        },
    });
};
export const campaignOpporActiveApi = (id, params) => {
    return api.post(`/api/admin/campaign-opportunities/is-active-update/${id}`, params, {
        headers: {
            "x-access-token": localStorage.getItem("accessToken"),
            "Content-Type": "application/json",
        },
    });
};
