import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { deleteFaq, viewFaqList } from "../../../../redux/actions/faqAction";
import { TableContainer } from "../../../table/tableContainer";
import {
  tableFaqsData,
  tableFaqsHeader,
  tableHowItWorksHeader,
} from "../../../table/tableData";
import moment from "moment";
import TablePagination from "../../../table/tablePagination";
import DeleteModal from "../../../modals/deleteModal";
import { deleteFaqApi } from "../../../../redux/apis/faqApi";
import successToast from "../../../utilits/successToast";
import errorToast from "../../../utilits/errorToast";
import {
  activateHowItWorksAction,
  viewHowItWorksListAction,
} from "../../../../redux/actions/howItWorksAction";
import LoadingSpinnerTable from "../../../utilits/loadingSpinnerTable";
import LoadAndError from "../../../utilits/LoadAndError";
import usePermissionChecker from "../../../utilits/usePermissionChecker";

const HowItWorksTableContent = ({
  tableContainerClassName = "adminuser_table__container",
  containerWidth = "90%",
  page,
  search,
  changePage,
  containerMargin = "1.5rem 0rem",
}) => {
  const [userListData, setUserListData] = useState([]);
  const access = usePermissionChecker("maker");
  const checkerAccess = usePermissionChecker("checker")
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);
  const [show, setShow] = useState(false);
  const [deleteData, setDeleteData] = useState({ name: "", id: "" });
  const [loadingTable, setLoadingTable] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [pageData, setPageData] = useState({ noOfItems: 0, noOfPages: 1 });
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [activeStatus, setActiveStatus] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    setLoading(true);
    dispatch(viewHowItWorksListAction(page, onSuccess, onError));
  }, [page, activeStatus]);

  const onSuccess = (data) => {
    console.log(data.data.data);
    setUserListData(data.data.data);
    setPageData({
      noOfItems: data.data.count,
      noOfPages: data.data.pages,
    });
    setLoading(false);
  };
  const onError = (data) => {
    console.log(data);
    setLoading(false);
  };

  function handlePagination(type) {
    if (type === "+") {
      if (page + 1 < pageData.noOfPages) changePage("+");
    } else if (type === "-") if (page > 0) changePage("-");
  }

  const handleDelete = () => {
    setLoadingDelete(true);
    dispatch(deleteFaq(deleteData?.id, onDeleteSuccess, onDeleteError));
  };
  const onDeleteSuccess = (data) => {
    successToast(data.message);
    setLoadingDelete(false);
    setShow(false);
    setError(false);
    setLoading(false);
  };
  const onDeleteError = (data) => {
    errorToast(data.message);
    setLoadingDelete(false);
    setError(true);
    setLoading(false);
  };

  const handleActivate = (id) => {
    dispatch(activateHowItWorksAction(id, onActivateSuccess, onActivateError));
  };
  function onActivateSuccess(data) {
    console.log(data.data.data);
    successToast(data.data.data);
    setActiveStatus(!activeStatus)
  }
  function onActivateError(data) {
    errorToast(data.message);
    console.log(data);
  }
  return (
    <TableContainer width={containerWidth} margin={containerMargin}>
      <div className={`${tableContainerClassName}`}>
        <table className={"adminuser_table"}>
          <thead className="adminuser_table__header">
            <tr className="adminuser_table__row">
              {tableHowItWorksHeader.map((entry) => {
                return (
                  <th
                    key={entry}
                    className={`${entry === "Status" ? `${access ? "" : "text-start"} position-sticky end-0` : null
                      } text-nowrap adminuser_table__heading `}
                  >
                    {entry}
                  </th>
                );
              })}
            </tr>
          </thead>
          {loading == false && (
            <tbody className="adminuser_table__body">
              {userListData?.map((item, index) => {
                return (
                  <tr key={item?.id} className="adminuser_table__row">
                    <td className="text-nowrap dark_title_color adminuser_table__data ">
                      {page > 0 ? 12 * page + (index + 1) : index + 1}
                    </td>

                    <td className="text-nowrap dark_title_color adminuser_table__data">
                      {item?.id === null ? "--" : item?.id}
                    </td>
                    <td className="text-nowrap secondary_color adminuser_table__data">
                      {item?.title === null ? "--" : item?.title}
                    </td>
                    <td className="text-nowrap adminuser_table_ellipsis dark_title_color adminuser_table__data">
                      {item?.subtitle === null ? "--" : item?.subtitle}
                    </td>
                    <td className={`text-nowrap ${item?.is_active ? "text-success" : ""}  dark_title_color adminuser_table__data`}>
                      {item?.is_active ? "true" : "false"}
                    </td>
                    <td className="text-nowrap  dark_title_color adminuser_table__data">
                      {item?.how_it_work_checkers[0]?.approved_date === null
                        ? "--"
                        : moment(
                          item?.how_it_work_checkers[0]?.approved_date
                        ).format("DD-MM-YYYY HH:mm:ss")}
                    </td>

                    <td className="text-nowrap secondary_color  adminuser_table__data">
                      {item.updatedAt === null
                        ? "-"
                        : moment(item.updatedAt).format("DD-MM-YYYY HH:mm:ss")}
                    </td>
                    <td className="text-nowrap  position-sticky end-0 adminuser_table__data">
                      <div className="d-flex w-100 justify-content-start  gap-2">
                        <Link
                          className="text-decoration-none primary_color"
                          to={{
                            pathname: `/static-pages/how-It-Works/view/${item?.id}`,
                          }}
                        >
                          View
                        </Link>

                        {access && (
                          <>
                            {
                              item.editable && <>
                                <span className="vr"></span>
                                <Link
                                  className="text-decoration-none secondary_color"
                                  to={{
                                    pathname: `/static-pages/how-It-Works/edit/${item?.id}`,
                                  }}
                                >
                                  Edit
                                </Link></>
                            }


                          </>
                        )}  {checkerAccess &&
                          <>
                            <span className="vr"></span><button
                              disabled={item?.is_active}
                              onClick={() => { !item?.is_active && handleActivate(item.id) }}
                              className={`border-0 bg-transparent ${item?.is_active ? "text-success fw_600" : ""} `}
                            >
                              {item?.is_active ? "Active" : "Activate"}
                            </button>     </>}
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          )}
        </table>
      </div>
      <DeleteModal
        loader={loadingDelete}
        handleClose={handleClose}
        deleteFn={handleDelete}
        data={deleteData}
        show={show}
      />
      <TablePagination
        paginate={handlePagination}
        currentPage={page}
        pageData={pageData}
      />
    </TableContainer>
  );
};
export default HowItWorksTableContent;
