import React from 'react'
import "../../components/admin/tables/admintable.css"
import arrowLeftIcon from "../../assets/icons/arrowleft.svg";
import ArrowRightIcon from "../../assets/icons/arrowrightIcon";
const TablePagination = ({ currentPage, pageData, paginate, limit = 12 }) => {
    return (
        <div className="adminuser_table_pagination__container rounded-4 white_shade_bg py-2  w-100">
            <div className="fn-Roboto ms-auto align-items-center justify-content-end gap-4 gap-sm-5 d-flex fs_13 fw_500 me-0 pe-1 me-sm-4 pe-sm-2 darker_grey_color">
                <span className="ps-1 ">Rows Per Page: {limit}</span>
                <span className="">
                    {pageData?.noOfPages === 0 || pageData?.noOfPages === currentPage + 1 ? `${pageData?.noOfItems} of ${pageData?.noOfItems}` :
                        ` ${limit * (currentPage + 1)} 
                    of ${pageData?.noOfItems}`}</span>
                <div className="d-flex align-items-center gap-4 ">
                    <span onClick={() => { paginate("-"); }}>
                        <img
                            src={arrowLeftIcon}
                            width={10}
                            height={10}
                            alt="previous"
                        />
                    </span>
                    <span onClick={() => { paginate("+"); }} className="">
                        <ArrowRightIcon fill="#707070" width={19} height={19} />
                    </span>
                </div>
            </div>
        </div>
    )
}
export default TablePagination
