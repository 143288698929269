import React, { useState } from 'react'
import ApprovalsBtn from '../../../../components/buttons/approvalsBtn'
import CreateBtn from '../../../../components/buttons/createBtn'
import ExportBtn from '../../../../components/buttons/exportBtn'
import FilterBtn from '../../../../components/buttons/filterBtn'
import VersionHistoryBtn from '../../../../components/buttons/versionHistoryBtn'
import AdminUserFilter from '../../../../components/filter/adminUserFilter'
import OurAdvisorsFilter from '../../../../components/filter/static-pages filter/ourAdvisorsFilter'
import InnerLayout from '../../../../components/layouts/innerLayout/innerLayout'
import OurAdvisorsTableContent from '../../../../components/static-pages/our-advisors/OurAdvisorsTableContent'
import { advisorsTableExport } from '../../../../redux/actions/ourAdvisorAction'
                           
   
const OurAdvisorsTable = () => {
  const [page, setPage] = useState(0);
  const [showFilter, setShowFilter] = useState(false);
  const [search, setSearch] = useState({ value: "" });
  const handleFilterClose = () => {
    setShowFilter(false);
  };
  const handleFilterOpen = () => {
    setShowFilter(true);
  };
  const handleSearch = (value) => {
    setSearch({ value: value });
    setPage(0)
  };
  const handlePage = (type) => {
    if (type === "+") {
      setPage((prev) => prev + 1)
    }
    else {
      setPage((prev) => prev - 1)
    }
  }
    const buttonList = [
        <ApprovalsBtn link={`/static-pages/our-advisors/approvals`} />,
        <VersionHistoryBtn link={`/static-pages/our-advisors/version-history`} />,
        <CreateBtn link={`/static-pages/our-advisors/create`} />,
        <ExportBtn dispatchAction={advisorsTableExport}/>,
        <FilterBtn filterFn={handleFilterOpen} />,
    ];
    return (
        <InnerLayout buttons={buttonList}>
            <OurAdvisorsFilter
                filter={handleSearch}
                showFilter={showFilter}
                closeFilter={handleFilterClose}
            />
            <OurAdvisorsTableContent page={page} changePage={handlePage} search={search}  />
        </InnerLayout>
    )
}

export default OurAdvisorsTable 