import React from "react";
import InnerLayout from "../../../../components/layouts/innerLayout/innerLayout";
import InnerContainer from "../../../../components/layouts/innerContainer/innerContainer";
import { useParams } from "react-router-dom";
import VersionHistoryBtn from "../../../../components/buttons/versionHistoryBtn";
import EditBtn from "../../../../components/buttons/editBtn";
import { useState } from "react";
import CommunityBadgesViewDetails from "../../../../components/static-pages/community-badges/communityBadgesViewDetails";

const CommunityBadgesView = () => {
  const params = useParams();
  const [editable, setEditable] = useState(false);
  const buttonList = [
    <VersionHistoryBtn link="/static-pages/referred-users/version-history" />,
    editable && (
      <EditBtn link={`/static-pages/referred-users/edit/${params.id}`} />
    ),
  ];

  return (
    <InnerLayout buttons={buttonList}>
      <div className="user_view_container">
        <InnerContainer>
          <CommunityBadgesViewDetails
            handleEditable={(value) => setEditable(value)}
            id={params.id}
          />
        </InnerContainer>
      </div>
    </InnerLayout>
  );
};

export default CommunityBadgesView;
