import { authHeader } from "../config";
import { api } from "./api";

export const addContactApi = (params) => {
  return api.post("/api/admin/contactus/create-contactus", params, {
    headers: {
      "x-access-token": localStorage.getItem("accessToken"),
      "Content-Type": "application/json",
    },
  });
};
export const approvalsContactListpApi = (page = 0, params) => {
  return api.post(`/api/admin/contactus/view-checker?page_no=${page}`, params, {
    headers: {
      "x-access-token": localStorage.getItem("accessToken"),
      "Content-Type": "application/json",
    },
  });
};
export const viewApprovalContactApi = (id, params) => {
  return api.post(`/api/admin/contactus/view-checker/${id}`, params, {
    headers: {
      "x-access-token": localStorage.getItem("accessToken"),
      "Content-Type": "application/json",
    },
  });
};
export const approveContactApi = (params) => {
  return api.post("/api/admin/contactus/approve", params, {
    headers: {
      "x-access-token": localStorage.getItem("accessToken"),
      "Content-Type": "application/json",
    },
  });
};
export const rejectContactApi = (id, params) => {
  return api.post(`/api/admin/contactus/reject/${id}`, params, {
    headers: {
      "x-access-token": localStorage.getItem("accessToken"),
      "Content-Type": "application/json",
    },
  });
};
export const viewContactListApi = (id, params) => {
  return api.post(`/api/admin/contactus/view-approval/${id}`, params, {
    headers: {
      "x-access-token": localStorage.getItem("accessToken"),
      "Content-Type": "application/json",
    },
  });
};
//opp
export const viewContactApi = (page = 0, params) => {
  return api.post(
    `/api/admin/contactus/view-approvals?page_no=${page}`,
    params,
    {
      headers: {
        "x-access-token": localStorage.getItem("accessToken"),
        "Content-Type": "application/json",
      },
    }
  );
};
export const editContactApi = (id, params) => {
  return api.post(`/api/admin/contactus/edit/${id}`, params, {
    headers: {
      "x-access-token": localStorage.getItem("accessToken"),
      "Content-Type": "application/json",
    },
  });
};
export const deleteContactApi = (id, params) => {
  return api.post(`/api/admin/contactus/delete/${id}`, params, {
    headers: {
      "x-access-token": localStorage.getItem("accessToken"),
      "Content-Type": "application/json",
    },
  });
};

export const versionContactListpApi = (page, params) => {
  return api.post(`/api/admin/contactus/view-version?page_no=${page}`, params, {
    headers: {
      "x-access-token": localStorage.getItem("accessToken"),
      "Content-Type": "application/json",
    },
  });
};
export const viewVersionContactApi = (id, params) => {
  return api.post(`/api/admin/contactus/view-version/${id}`, params, {
    headers: {
      "x-access-token": localStorage.getItem("accessToken"),
      "Content-Type": "application/json",
    },
  });
};

export const contactSubmitApi = (id, params) => {
  return api.post(`/api/admin/contactus/on-submit/${id}`, params, {
    headers: {
      "x-access-token": localStorage.getItem("accessToken"),
      "Content-Type": "application/json",
    },
  });
};

export const contactMainExportApi = (page, params) => {
  return api.post(
    `/api/admin/contactus/export-main-contact-us`,
    JSON.stringify(params),
    {
      headers: {
        "x-access-token": localStorage.getItem("accessToken"),
        "Content-Type": "application/json",
      },
    }
  );
};

export const contactApprovalsExportApi = (page, params) => {
  return api.post(
    `/api/admin/contactus/export-approval-contact-us`,
    JSON.stringify(params),
    {
      headers: {
        "x-access-token": localStorage.getItem("accessToken"),
        "Content-Type": "application/json",
      },
    }
  );
};

export const contactVersionExportApi = (page, params) => {
  return api.post(
    `/api/admin/contactus/export-version-contact-us`,
    JSON.stringify(params),
    {
      headers: {
        "x-access-token": localStorage.getItem("accessToken"),
        "Content-Type": "application/json",
      },
    }
  );
};
