import { call, takeLatest } from "redux-saga/effects";
import {
  ADD_ADMIN,
  ADMIN_APPROVALS_EXPORT,
  ADMIN_APPROVAL_DETAILS,
  ADMIN_APPROVAL_LIST,
  ADMIN_DETAILS_VERSION_HISTORY,
  ADMIN_EXPORT,
  ADMIN_SUBMIT,
  ADMIN_VERSION_EXPORT,
  APPROVE_ADMIN,
  DELETE_ADMIN,
  EDIT_ADMIN,
  EXPORT_ADMIN,
  REJECT_ADMIN,
  ROLE_TYPE_DATA,
  VERSION_HISTORY_LIST,
  VIEW_ADMIN,
  VIEW_ADMIN_LIST,
} from "../actions/types";
import {
  addAdminApi,
  adminApprovalListApi,
  adminApprovalsExportApi,
  adminSubmitApi,
  adminTableExportApi,
  adminVersionExportApi,
  approveAdminCheckerApi,
  deleteAdminApi,
  editAdminApi,
  exportAdminApi,
  rejectAdminApi,
  roleTypeDataApi,
  versionHistoryAdminDetailApi,
  versionHistoryListApi,
  viewAdminApi,
  viewAdminApprovalApi,
  viewAdminListApi,
} from "../apis/adminUserApis";

export function* addAdminWorker(action) {
  try {
    const res = yield call(addAdminApi, action.data);

    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res.data);
    }
  } catch (error) { }
}

export function* viewAdminListWorker(action) {
  try {
    const res = yield call(viewAdminListApi,
      action.page_no,
      action.data,
    );
    console.log(res);

    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res.data.data);
    } else {
      yield action.onError(res.data);
    }
  } catch (error) { }
}

export function* viewAdminDetailWorker(action) {
  try {
    const res = yield call(viewAdminApi, {
      adminId: action.userID,
    });
    console.log(res);
    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res.data);
    }
  } catch (error) { }
}

export function* deleteAdminWorker(action) {
  try {
    const res = yield call(deleteAdminApi, {
      adminId: action.userID,
    });
    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res.data);
    } else if (res.status === 200 && res.data.status === "fail") {
      console.log(res);
      yield action.onError(res);
    } else {
      yield action.onError("Something Went Wrong!");
      console.log(res.data);
    }
  } catch (err) {
    console.log(err);
    yield action.onError("Something Went Wrong!!");
  }
}

export function* roleTypeWorker(action) {
  try {
    const res = yield call(roleTypeDataApi, {});
    console.log(res);
    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res.data);
    }
  } catch (error) { }
}

export function* editAdminWorker(action) {
  try {
    const res = yield call(editAdminApi, action.id, action.data);
    console.log(res);
    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res.data);
    }
  } catch (error) { }
}

export function* viewAdminApprovalListWorker(action) {
  try {
    const res = yield call(adminApprovalListApi,
      action.page_no,
      action.data,
    );
    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res.data.data);
      console.log(res.data);
    } else {
      yield action.onError(res.data);
    }
  } catch (error) { }
}

export function* viewAdminApprovalDetailWorker(action) {
  try {
    const res = yield call(viewAdminApprovalApi, {
      adminId: action.userID,
    });
    console.log(res);
    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res.data);
    }
  } catch (error) { }
}

export function* approveAdminWorker(action) {
  try {
    const res = yield call(approveAdminCheckerApi, {
      admin_id: action.userID,
    });
    console.log(res);
    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res.data);
    }
  } catch (error) { }
}

export function* rejectAdminWorker(action) {
  try {
    const res = yield call(rejectAdminApi, {
      adminId: action.userID,
    });
    console.log(res);
    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res.data);
    }
  } catch (error) { }
}

export function* versionHistoryListWorker(action) {
  try {
    const res = yield call(versionHistoryListApi,
      action.page_no,
      action.data
      ,);
    console.log(res.data);
    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res.data);
      console.log(res.data);
    } else {
      yield action.onError(res.data);
    }
  } catch (error) { }
}

export function* versionHistoryAdminDetailWorker(action) {
  try {
    const res = yield call(versionHistoryAdminDetailApi,
      action.id,
    );
    console.log(res);
    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res.data);
    }
  } catch (error) { }
}


//export
export function* adminTableExportWorker(action) {
  try {
    const res = yield call(adminTableExportApi,

    );
    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res.data);
    }
  } catch (error) { }
}


export function* adminApprovalsExportWorker(action) {
  try {
    const res = yield call(adminApprovalsExportApi);
    console.log(res);
    if (res.status === 200 && res.data.status === "success") {
      console.log(res.data);
      yield action.onSuccess(res.data.data);
    } else {
      yield action.onError(res.data);
    }
  } catch (error) { }
}

export function* adminVersionExportWorker(action) {
  try {
    const res = yield call(adminVersionExportApi);
    console.log(res);
    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res.data);
    }
  } catch (error) { }
}


export function* adminSubmitWorker(action) {
  try {
    const res = yield call(adminSubmitApi, action.id);
    console.log(res);
    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res.data);
    }
  } catch (error) { }
}





export function* viewAdminListWorkerWatcher() {
  yield takeLatest(VIEW_ADMIN_LIST, viewAdminListWorker);
}
export function* addAdminWorkerWatcher() {
  yield takeLatest(ADD_ADMIN, addAdminWorker);
}

export function* viewAdminDetailWatcherWorker() {
  yield takeLatest(VIEW_ADMIN, viewAdminDetailWorker);
}

export function* deleteAdminWorkerWatcher() {
  yield takeLatest(DELETE_ADMIN, deleteAdminWorker);
}

export function* roleTypeWorkerWatcher() {
  yield takeLatest(ROLE_TYPE_DATA, roleTypeWorker);
}

export function* editAdminWorkerWatcher() {
  yield takeLatest(EDIT_ADMIN, editAdminWorker);
}

export function* viewAdminApprovalListWatcher() {
  yield takeLatest(ADMIN_APPROVAL_LIST, viewAdminApprovalListWorker);
}

export function* viewAdminApprovalDetailWatcher() {
  yield takeLatest(ADMIN_APPROVAL_DETAILS, viewAdminApprovalDetailWorker);
}

export function* approveAdminWatcher() {
  yield takeLatest(APPROVE_ADMIN, approveAdminWorker);
}
export function* rejectAdminWatcher() {
  yield takeLatest(REJECT_ADMIN, rejectAdminWorker);
}

export function* versionHistoryListWatcher() {
  yield takeLatest(VERSION_HISTORY_LIST, versionHistoryListWorker);
}
export function* versionHistoryAdminDetailWatcher() {
  yield takeLatest(
    ADMIN_DETAILS_VERSION_HISTORY,
    versionHistoryAdminDetailWorker
  );
}
//export
export function* adminTableExportWatcher() {
  yield takeLatest(
    ADMIN_EXPORT,
    adminTableExportWorker
  );
}
export function* adminApprovalsExportWatcher() {
  yield takeLatest(ADMIN_APPROVALS_EXPORT, adminApprovalsExportWorker);
}

export function* adminVersionExportWorkerWatcher() {
  yield takeLatest(ADMIN_VERSION_EXPORT, adminVersionExportWorker);
}
export function* watchAdminSubmitWorker() {
  yield takeLatest(ADMIN_SUBMIT, adminSubmitWorker);
}
