import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import * as adminUsersAction from "../../../redux/actions/AdminUserAction";
import DeleteModal from "../../modals/deleteModal";
import { TableContainer } from "../../table/tableContainer";
import { tableAdminUserHeader } from "../../table/tableData";
import TablePagination from "../../table/tablePagination";
import errorToast from "../../utilits/errorToast";
import LoadAndError from "../../utilits/LoadAndError";
import LoadingSpinnerTable from "../../utilits/loadingSpinnerTable";
import successToast from "../../utilits/successToast";
import usePermissionChecker from "../../utilits/usePermissionChecker";
import "./admintable.css";
const AdminUserTableContent = ({ page, search, changePage }) => {
  const access = usePermissionChecker("maker");
  const [userListData, setUserListData] = useState([]);
  const [loadingTable, setLoadingTable] = useState(false);
  const [deleteData, setDeleteData] = useState({ name: "", id: "" });
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [pageData, setPageData] = useState({ noOfItems: 0, noOfPages: 1 })
  const handleClose = () => setShow(false);
  const dispatch = useDispatch();
  useEffect(() => {
    setLoadingTable(true);
    dispatch(
      adminUsersAction.viewAdminList(
        {
          search: {
            admin_id: search?.value?.adminId,
            name: search?.value?.name,
            email: search?.value?.email,
            role_name: search?.value?.roleName,
            role_id: search?.value?.roleId,
          },
        },
        page,
        onSuccess,
        onError
      )
    );
  }, [page, search, loading]);
  const handleDelete = (id) => {
    setLoading(true);
    dispatch(adminUsersAction.deleteAdmin(id, onDeleteSuccess, onDeleteError));
  };
  const onDeleteSuccess = (data) => {
    successToast("Delete request successfully added");
    setLoading(false);
    setShow(false);
  };
  const onDeleteError = (data) => {
    setLoading(false);
    errorToast(data.data.data);
  };
  const onSuccess = (data) => {
    setUserListData(data.data);
    setPageData({
      noOfItems: data.count,
      noOfPages: data.pages
    })
    setLoadingTable(false);
    setError(false);
  };
  const onError = (data) => {
    setUserListData([]);
    errorToast(data.data);
    setError(true);
    setLoadingTable(false);
  };
  function handlePagination(type) {
    if (type === "+") {
      if (page + 1 < pageData.noOfPages) changePage("+");
    }
    else if (type === "-")
      if (page > 0) changePage("-");
  }
  return (
    <>
      <TableContainer maxWidth="100%" width="90%">
        <div className="adminuser_table__container position-relative">
          {loadingTable && <LoadingSpinnerTable />}
          <table className={"adminuser_table"}>
            <thead className="adminuser_table__header">
              <tr className="adminuser_table__row">
                {tableAdminUserHeader.map((item) => {
                  return (
                    <th
                      key={item}
                      className={`text-nowrap adminuser_table__heading   ${item === "Status" ? `position-sticky end-0 ${access ? "" : "text-center"}` : null
                        }`}
                    >
                      {item}
                    </th>
                  );
                })}
              </tr>
            </thead>

            <tbody className="adminuser_table__body">
              <LoadAndError
                loader={loading}
                error={error}
                status={userListData.length === 0}
              >
                {userListData.map((item, index) => {
                  return (
                    <tr key={item.id} className="adminuser_table__row">
                      <td className="text-nowrap dark_title_color adminuser_table__data ">
                        {page > 0 ? 12 * page + (index + 1) : index + 1}
                      </td>
                      <td className="text-nowrap  adminuser_table__data">
                        {item.id === null ? "-" : item.id}
                      </td>
                      <td className="text-nowrap  adminuser_table__data">
                        {item.name === null ? "-" : item.name}
                      </td>
                      <td title={item?.email} className="text-nowrap  adminuser_table__data adminuser_table_mid_ellipsis">
                        {item.email === null ? "-" : item.email}
                      </td>
                      <td title={item?.role?.role_name} className="text-nowrap dark_title_color adminuser_table__data adminuser_table_mid_ellipsis">
                        <Link
                          className="text-decoration-none secondary_color"
                          to={{
                            pathname: `/admin-roles/view/${item.role?.id}`,
                          }}
                        >
                          {item?.role?.role_name === null
                            ? "-"
                            : item?.role?.role_name}
                        </Link>

                      </td>
                      <td className="text-nowrap dark_title_color adminuser_table__data">
                        {item.last_login_time
                          ? moment(item.last_login_time).format("h:mm:ss")
                          : "--"}
                      </td>
                      <td className="text-nowrap dark_title_color adminuser_table__data">
                        {item.admin_checkkers?.[0]?.last_action
                          === null ? "-" : item.admin_checkkers?.[0]?.last_action
                        }
                      </td>

                      <td className="text-nowrap  adminuser_table__data position-sticky end-0">
                        <div className="d-flex justify-content-center w-100 gap-2">
                          <Link
                            className="text-decoration-none primary_color"
                            to={{
                              pathname: `/admin-users/view/${item.id}`,
                            }}
                          >
                            View
                          </Link>{" "}
                          {access && 
                            <>
                              <span className="vr "></span>
                              <Link
                                className="text-decoration-none secondary_color"
                                to={{
                                  pathname: `/admin-users/edit/${item.id}`,
                                }}
                              >
                                Edit
                              </Link>{" "}
                              <span className="vr"></span>{" "}
                              <button
                                onClick={() => {
                                  setDeleteData({
                                    name: item.name,
                                    id: item.id,
                                  });
                                  setShow(true);
                                }}
                                className="text-decoration-none primary_color light_red_color border-0 bg-transparent "
                              >
                                Delete
                              </button></>}
                        </div>
                      </td>
                    </tr>
                  )
                })}
              </LoadAndError>


            </tbody>

          </table>
        </div>
        <DeleteModal
          loader={loading}
          handleClose={handleClose}
          deleteFn={handleDelete}
          data={deleteData}
          show={show}
        />
        <TablePagination
          paginate={handlePagination}
          currentPage={page}
          pageData={pageData}
        />
      </TableContainer>
    </>
  );
};
export default AdminUserTableContent;
