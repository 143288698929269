import React, { useRef, useState } from "react";
import DeleteBtn from "../../../../components/buttons/deleteBtn";
import SaveBtn from "../../../../components/buttons/saveBtn";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import InnerContainer from "../../../../components/layouts/innerContainer/innerContainer";
import InnerLayout from "../../../../components/layouts/innerLayout/innerLayout";
import InputField from "../../../../components/form/inputFields/inputField";
import * as opporTypeActions from "../../../../redux/actions/opportunityTypeActions";
import successToast from "../../../../components/utilits/successToast";
import errorToast from "../../../../components/utilits/errorToast";
import { useDispatch } from "react-redux";
import { addNewsletterAction } from "../../../../redux/actions/newsletterActions";
import SelectFields from "../../../../components/form/selectFields/selectFields";
import { useNavigate } from "react-router-dom";
import LoadingSpinnerTable from "../../../../components/utilits/loadingSpinnerTable";

const NewsLetterCreate = () => {
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false)
    const formRef = useRef();
    const dispatch = useDispatch();
    const initialValues = {
        email: "",
        status: "",
    };
    const loginSchema = Yup.object().shape({
        email: Yup.string().trim().email().required("Required"),
        status: Yup.string().trim().required("Required"),
    });
    const handleSubmit = () => {
        if (formRef.current) {
            formRef.current.handleSubmit()
        }
    }
    const onSubmit = (values) => {
        setLoading(true)
        dispatch(
            addNewsletterAction(
                {
                    email: values.email,
                    status: "active"
                },
                onSuccess,
                onError)
        );
        console.log(values);
    };
    const onSuccess = (data) => {
        console.log(data);
        setLoading(false)
        successToast(data.message)
        navigate(`/static-pages/newsletter/edit/${data.data.id}`)
    };
    const onError = (data) => {
        setLoading(false)
        errorToast(data.message)
    };
    const buttonList = [
        <SaveBtn loader={loading} submitFn={handleSubmit} />,
    ];

    return (
        <InnerLayout buttons={buttonList}>
            <div className="opportunity-type-container">
                <InnerContainer>
                    {loading ? (
                        <LoadingSpinnerTable />
                    ) : (
                        <div className="pt-3  ps-4 ps-lg-4 ms-3">

                            <Formik
                                innerRef={formRef}
                                initialValues={initialValues}
                                onSubmit={onSubmit}
                                validationSchema={loginSchema}
                            >
                                <Form className='opportunity-type-form-width'>
                                    <InputField
                                        id={"email"}
                                        label={"Email Address"}
                                        type={"text"}
                                        placeholder={"Enter Email"}
                                        name={"email"}
                                    />
                                </Form>
                            </Formik>
                        </div>
                    )}
                </InnerContainer>
            </div>
        </InnerLayout>
    );
}

export default NewsLetterCreate;
export const currentStatus = [
    { value: "active", label: "active" },
    { value: "pending", label: "pending" }
];
