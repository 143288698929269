import React, { useEffect, useRef, useState } from "react";
import DeleteBtn from "../../../../components/buttons/deleteBtn";
import SaveBtn from "../../../../components/buttons/saveBtn";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import InnerContainer from "../../../../components/layouts/innerContainer/innerContainer";
import InnerLayout from "../../../../components/layouts/innerLayout/innerLayout";
import InputField from "../../../../components/form/inputFields/inputField";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import successToast from "../../../../components/utilits/successToast";
import errorToast from "../../../../components/utilits/errorToast";
import * as opporTypeActions from "../../../../redux/actions/opportunityTypeActions";
import DeleteModal from "../../../../components/modals/deleteModal";
import SelectFields from "../../../../components/form/selectFields/selectFields";
import { editNewsletterAction, viewNewsletterAction, newsLetterSubmit } from "../../../../redux/actions/newsletterActions";
import LoadingSpinnerTable from "../../../../components/utilits/loadingSpinnerTable";
import LoadAndError from "../../../../components/utilits/LoadAndError";
import SubmitBtn from "../../../../components/buttons/submit";
import { NEWSLETTER_SUBMIT } from "../../../../redux/actions/types";
const NewsLetterEdit = () => {
    const [submitData, setSubmitData] = useState();
    const navigate = useNavigate()
    const [updated, setUpdated] = useState(false)
    const [deleteData, setDeleteData] = useState({ name: "", id: "" });
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [loadingDelete, setLoadingDelete] = useState(false);
    const [show, setShow] = useState(false);
    const [opporTypeData, setOpporTypeData] = useState({ name: "" })
    const [newsData, setNewsData] = useState()
    const params = useParams();
    const dispatch = useDispatch();
    const phoneRegex = /^[0-9]{10}$/;
    const formRef = useRef()
    const handleShow = () => {
        setDeleteData({
            name: opporTypeData.name,
            id: opporTypeData.id,
        }); setShow(true)
    };

    useEffect(() => {
        setLoading(true);
        dispatch(
            viewNewsletterAction(params.id, onSuccess, onError)
        );
    }, [updated]);
    const onSuccess = (data) => {
        setNewsData(data.data)
        successToast(data.message)
        setLoading(false);
        setError(false);
    };
    const onError = (data) => {
        errorToast(data.message);
        setLoading(false);
        setError(true);
    };

    const handleClose = () => setShow(false);
    const initialValues = {
        email: newsData?.email,
        status: newsData?.status,
    };
    const loginSchema = Yup.object().shape({
        email: Yup.string().trim().email().required("Required"),
        status: Yup.string().trim().required("Required"),
    });
    const handleSubmit = () => {
        if (formRef.current) {
            formRef.current.handleSubmit()
        }
    }
    const onSubmit = (values, { resetForm }) => {
        setLoading(true)
        dispatch(
            editNewsletterAction(
                params.id,
                {
                    email: values.email,
                    status: values.status
                },
                onEditSuccess,
                onEditError)
        );
        setUpdated(!updated)
    };


    const onEditSuccess = (data) => {
        setLoading(false)
        setOpporTypeData(data.data)
        successToast(data.message)

        setSubmitData(data.data.id)
        setSaveHide(true)
        setLoading(false);
        setError(false);
    };
    const onEditError = (data) => {
        setLoading(false)
        console.log(data);
        errorToast(data.message)
        setLoading(false);
        setError(true);
    };

    const handleDelete = () => {
        setLoadingDelete(true);
        dispatch(
            opporTypeActions.deleteOpporType(
                params.id,
                onDeleteSuccess,
                onDeleteError
            )
        );
    };
    const onDeleteSuccess = (data) => {
        setLoadingDelete(false);
        successToast(data.data.data);

        setShow(false);
    };
    const onDeleteError = (data) => {
        errorToast(data.data.data);
        setLoadingDelete(false);
    };

    //on submit api
    console.log(submitData);
    const [saveHide, setSaveHide] = useState(false);

    const submitFnc = () => {
        dispatch(newsLetterSubmit
            (
                submitData,
                onSubmitSuccess,
                onSubmitError,
            ));
    };
    const onSubmitSuccess = (data) => {
        console.log(data);
        successToast(data.message)
        navigate("/static-pages/newsletter")
    };
    const onSubmitError = (data) => {
        console.log("ERROR==================", data);
        errorToast(data.message)

    };
    const buttonList = [
        !saveHide && <SaveBtn submitFn={handleSubmit}
            loader={loading} />,
        <SubmitBtn submitFn={submitFnc} saveHide={saveHide} />];
    return (
        <InnerLayout buttons={buttonList}>
            <div className="opportunity-type-container">
                <InnerContainer>
                    {loading ? (
                        <LoadingSpinnerTable />
                    ) : (
                        <LoadAndError loader={loading} error={error}>
                            <div className="pt-3  ps-4 ps-lg-4 ms-3">
                                <h5 className="dark_grey_color fs_13 fn_Montserrat fw_500 m-0">
                                    Newsletter ID
                                </h5>
                                <p className={` fs_13 dark_title_color fn_Nunito fw_500  secondary_color `}>
                                    0123asdf456jkl7w
                                </p>
                                <Formik
                                    enableReinitialize={true}
                                    innerRef={formRef}
                                    initialValues={initialValues}
                                    onSubmit={onSubmit}
                                    validationSchema={loginSchema}
                                >
                                    <Form className='opportunity-type-form-width'>
                                        <InputField
                                            id={"email"}
                                            label={"Email Address"}
                                            type={"text"}
                                            placeholder={"Enter query name"}
                                            name={"email"}
                                        />
                                        <SelectFields
                                            default1={{
                                                label: newsData?.status,
                                                value: newsData?.status,
                                            }}
                                            label={"Status"}
                                            placeholder={"Select Status"}
                                            name={"status"}
                                            options={currentStatus}
                                            id={"status"}
                                        />
                                    </Form>
                                </Formik>
                            </div>
                        </LoadAndError>
                    )}
                </InnerContainer>
            </div>
            <DeleteModal
                loader={loadingDelete}
                handleClose={handleClose}
                deleteFn={handleDelete}
                data={deleteData}
                show={show}
            />

        </InnerLayout>
    );
}

export default NewsLetterEdit;
export const currentStatus = [
    { value: "active", label: "active" },
    { value: "pending", label: "pending" }
];