import React, { useRef } from 'react';
import { Formik, Form } from "formik";
import { Field } from "formik";
import Select, { components } from 'react-select';
import * as Yup from "yup";
import "../../../modules/user/user-view.css"
import ErrorMsg from '../../form/errorMsg';
import ApplyBtn from '../../buttons/applyBtn';
import ReverifyBtn from '../../buttons/reverifyBtn';
import ArrowDownIcon from '../../../assets/icons/arrowdown';
import ArrowRightIcon from '../../../assets/icons/arrowrightIcon';
import usePermissionChecker from '../../utilits/usePermissionChecker';



const KycApprovalView = ({ individualApprovalData }) => {
    const kycdetails = [
        {
            heading: "KYC Process Status",
            title: individualApprovalData?.new_data === null ? individualApprovalData?.old_data?.kyc_process_status : individualApprovalData?.new_data?.kyc_process_status
        },
        
        {
            heading: "KYC Last Updated At",
            title: individualApprovalData?.new_data === null ? individualApprovalData?.old_data?.kyc_last_updated_at : individualApprovalData?.new_data?.kyc_last_updated_at
        },
    ]
    const formRef = useRef()
    const initialValues = {
        kyc: "",
    };
    const loginSchema = Yup.object().shape({
        kyc: Yup.string().trim().required("Required"),
        value: Yup.string().trim().required("Required"),
        label: Yup.string().trim().required("Required"),

    });
    const handleSubmit = () => {
        if (formRef.current) {
            formRef.current.handleSubmit()
        }
    }
    const onSubmit = (values, { resetForm }) => {
        resetForm();
    };
    const DropdownIndicator = props => {
        return (
            <components.DropdownIndicator {...props}>
                <ArrowDownIcon width={25} height={25} />
            </components.DropdownIndicator>
        );
    };
    return (
        <>
            <h3 className='dark_grey_color fn_Montserrat ps-4 ps-lg-5 mt-2 py-2 fw_500 fs_13 white_shade_bg'>KYC</h3>
            <div className='row mt-4'>
                <div className='col-12 col-lg-12 col-xl-6   '>
                    <div className='row ps-1 ms-1 ps-lg-4 ms-lg-1'>
                        {
                            kycdetails.map((item, index) => (
                                <div className='mt-2 kyc_information_details_width'>
                                    <h5 className='dark_grey_color  fs_13 fn_Montserrat fw_500'>{item.heading}</h5>
                                    <p className={` fs_13 fn_Nunito  fw_500 ${item.heading === "User ID" || item.heading === "E-Mail" || item.heading === "Phone Number" ? 'secondary_color' : 'dark_title_color'}`}>{item.title}</p>
                                </div>
                            ))
                        }
                    </div>
                </div>

            </div>
        </>
    )
}

export default KycApprovalView;


export const customStyles = {
    indicatorSeparator: () => ({ display: 'none' }),
    control: (base, state) => ({
        ...base,
        color: "#112F6A",
        fontFamily: "Montserrat",
        fontWeight: "600",
        fontSize: "15px",

        width: "100%",
        // Overwrittes the different states of border
        borderColor: state.isFocused ? "transparent" : "transparent",
        // Removes weird border around container
        boxShadow: state.isFocused ? null : null,
        "&:hover": {
            // Overwrittes the different states of border
            // borderColor: state.isFocused ? "#1D72DB" : "#F4F8FB",
        },
    }),
    option: (provided, state) => ({
        ...provided,
        backgroundColor: state.isSelected ? "#1D72DB" : null,
        borderBottom: "1px solid #F5F5F5",
        fontFamily: "Montserrat",
        fontSize: "15px",
        fontWeight: "500",
        "&:hover": {
            backgroundColor: state.isFocused ? "#1D72DB" : "#1D72DB",
        },
    }),
    placeholder: (defaultStyles) => {
        return {
            ...defaultStyles,
            color: "#000000DE",
            fontFamily: "Montserrat",
            fontSize: "13px",
            fontWeight: "600",
            // top: "0.6rem"
        };
    },
    dropdownIndicator: (provided) => ({
        ...provided,
        svg: {

            fill: "#323D5A",
        },
    }),

};

const kycstatus = [
    { value: "verified", label: "verified" },
    { value: "pending", label: "pending" },
];
