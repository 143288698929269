import React, { useState } from "react";
import CreateBtn from "../../../../components/buttons/createBtn";
import ExportBtn from "../../../../components/buttons/exportBtn";
import FilterBtn from "../../../../components/buttons/filterBtn";
import InnerLayout from "../../../../components/layouts/innerLayout/innerLayout";
import AdminUserApprovalTableContent from "../../../../components/admin/tables/adminUserApprovalTableContent";
import { adminApprovalsExport } from "../../../../redux/actions/AdminUserAction";
import AdminAdminFilter from "../../../../components/filter/adminFilter/adminFilter";

const AdminRoleApprovalTable = () => {
  const [showFilter, setShowFilter] = useState(false);
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(0);
  const handleFilterClose = () => {
    setShowFilter(false);
  };
  const handleFilterOpen = () => {
    setShowFilter(true);
  };
  const handleSearch = (value) => {
    console.log("running");
    setSearch(value);
    setPage(0);
  };
  const handlePage = (type) => {
    if (type === "+") {
      setPage((prev) => prev + 1);
    } else {
      setPage((prev) => prev - 1);
    }
  };

  const buttonList = [
    <CreateBtn link={`/admin-users/create`} />,
    <ExportBtn  dispatchAction={adminApprovalsExport} />,
    <FilterBtn filterFn={handleFilterOpen} />,
  ];

  return (
    <InnerLayout containerWidth="95%" buttons={buttonList}>
      <AdminAdminFilter
        filter={handleSearch}
        showFilter={showFilter}
        closeFilter={handleFilterClose}
      />
      <AdminUserApprovalTableContent
        page={page}
        changePage={handlePage}
        search={search}                              
      />
    </InnerLayout>
  );
};
export default AdminRoleApprovalTable;
