import React, { useEffect, useRef, useState } from "react";
import "../llp.css";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import SaveBtn from "../../../../components/buttons/saveBtn";
import InnerContainer from "../../../../components/layouts/innerContainer/innerContainer";
import InnerLayout from "../../../../components/layouts/innerLayout/innerLayout";
import DeleteBtn from "../../../../components/buttons/deleteBtn";
import SelectFields from "../../../../components/form/selectFields/selectFields";
import InputField from "../../../../components/form/inputFields/inputField";
import ArrowDownIcon from "../../../../assets/icons/arrowdown";
import DocumentModal from "../../../../components/opportunity/llp/modals/Documentmodal";
import { addLLPAction, llpSubmit } from "../../../../redux/actions/llpActions";
import successToast from "../../../../components/utilits/successToast";
import errorToast from "../../../../components/utilits/errorToast";
import { useDispatch } from "react-redux";
import { viewWishListFindAll } from "../../../../redux/actions/wishlistActions";
import AddMemberLLP from "../../../../components/opportunity/llp/modals/AddMemberLLP";
import { opportunitiesNameListAction } from "../../../../redux/actions/opportunitiesActions";
import { useNavigate } from "react-router-dom";
import SubmitBtn from "../../../../components/buttons/submit";

const LlpCreate = () => {
  const [submitData, setSubmitData] = useState();
  const formRef = useRef();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate()
  const [allOpportunities, setOpportunities] = useState([]); //For dropdown of user name
  const handleSubmit = () => {
    if (formRef.current) {
      formRef.current.handleSubmit();
    }
  };

  const [opportunitiesList, setOpportunitiesList] = useState([])
  useEffect(() => {
    dispatch(opportunitiesNameListAction(onSuccessDrop, onErrorDrop));
  }, []);
  const onSuccessDrop = (data) => {
    const categoryList = [];
    data?.data.map((item, index) => {
      categoryList[index] = { value: item.id, label: `${item.title}${" "}id:${item.id}` };
    });
    setOpportunitiesList(categoryList);
    console.log("SUCCESS");
  };
  const onErrorDrop = (data) => {
    console.log(data);
  };
  const initialValues = {
    llpName: "",
    llpTitle: "",
    llpRegistrationNumber: "",
    llpopportunity: "",
    status: "",
  };
  const loginSchema = Yup.object().shape({
    llpName: Yup.string().trim().required("Required"),
    llpTitle: Yup.string().trim().required("Required"),
    llpRegistrationNumber: Yup.string()
      .required("Required"),
    llpopportunity: Yup.string().trim().required("Required"),
    status: Yup.string().trim().required("Required"),
  });
  const onSubmit = (values) => {
    setLoading(true);
    dispatch(
      addLLPAction(
        {
          name: values.llpName,
          title: values.llpTitle,
          reg_no: values.llpRegistrationNumber,
          status: values.status,
          opportunity_id: values.llpopportunity,
        },
        onSuccess,
        onError
      )
    );
  };

  const onSuccess = (data) => {
    console.log(data);
    setLoading(false);
    navigate(`/opportunity/llp/edit/${data?.data?.id}`)
    setSubmitData(data.data.id)
    // setSaveHide(true)
    successToast(data.message);
  };
  const onError = (data) => {
    setLoading(false);
    errorToast(data.message);
  };

  ///oppp id


  const options = [
    { label: "active", value: "active" },
    { label: "completed", value: "completed" },
    { label: "traded", value: "traded" },
    { label: "ended", value: "ended" },
  ];

  //on submit api
  // console.log(submitData);
  // const [saveHide, setSaveHide] = useState(false);

  // const submitFnc = () => {
  //   dispatch(llpSubmit
  //     (
  //       submitData,
  //       onSubmitSuccess,
  //       onSubmitError,
  //     ));
  // };
  // const onSubmitSuccess = (data) => {
  //   console.log(data);
  //   successToast(data.message)
  //   navigate("/opportunity/llp")
  // };
  // const onSubmitError = (data) => {
  //   console.log("ERROR==================", data);
  //   errorToast(data.message)

  // };
  const buttonList = [
    <SaveBtn submitFn={handleSubmit}
      loader={loading} />,
    // <SubmitBtn submitFn={submitFnc} saveHide={saveHide} />
  ];
  return (
    <InnerLayout buttons={buttonList}>
      <div className="llp-container">
        <InnerContainer>
          <div className="  ps-4 ps-lg-4 ms-3">
            <Formik
              innerRef={formRef}
              initialValues={initialValues}
              onSubmit={onSubmit}
              validationSchema={loginSchema}
            >
              <Form className="llp-create-form-width">
                <InputField
                  id={"llpName"}
                  label={"LLP Name"}
                  type={"text"}
                  placeholder={"Enter Name"}
                  name={"llpName"}
                />
                <InputField
                  id={"llpTitle"}
                  label={"Title"}
                  type={"text"}
                  placeholder={"Enter Title"}
                  name={"llpTitle"}
                />
                <InputField
                  id={"llpRegistrationNumber"}
                  label={"Registration Number"}
                  type={"text"}
                  placeholder={"Enter Registration Number"}
                  name={"llpRegistrationNumber"}
                />

                <SelectFields
                  Icon={<ArrowDownIcon width={15} fill={"#A5A5A5"} />}
                  label={"Opportunity"}
                  placeholder={"Select Opportunity"}
                  name={"llpopportunity"}
                  options={opportunitiesList}
                  id={"llpopportunity"}
                />
                <SelectFields
                  Icon={<ArrowDownIcon width={15} fill={"#A5A5A5"} />}
                  label={"Status"}
                  placeholder={"Select Status"}
                  name={"status"}
                  options={options}
                  id={"status"}
                />
              </Form>
            </Formik>
          </div>
          <DocumentModal />
          <AddMemberLLP />
        </InnerContainer>
      </div>
    </InnerLayout>
  );
};

export default LlpCreate;
