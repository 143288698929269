import { authHeader } from "../config";
import { api } from "./api";

export const createStaticApi = (params) => {
  return api.post("/api/admin/static-screen/create-static-screen", params, {
    headers: {
      "x-access-token": localStorage.getItem("accessToken"),
      "Content-Type": "application/json",
    },
  });
};

export const staticApprovalListApi = (pageNo, params) => {
  return api.post(
    `/api/admin/static-screen/find-all-checkker-static-screen?page_no=${pageNo}`,
    JSON.stringify(params),
    {
      headers: {
        "x-access-token": localStorage.getItem("accessToken"),
        "Content-Type": "application/json",
      },
    }
  );
};

export const staticApprovalByIdApi = (id, params) => {
  return api.post(
    `/api/admin/static-screen/find-one-checkker-static-screen/${id}`,
    JSON.stringify(params),
    {
      headers: {
        "x-access-token": localStorage.getItem("accessToken"),
        "Content-Type": "application/json",
      },
    }
  );
};
export const approveStaticApi = (params) => {
  return api.post(
    "/api/admin/static-screen/approve-static-screen",
    JSON.stringify(params),
    {
      headers: {
        "x-access-token": localStorage.getItem("accessToken"),
        "Content-Type": "application/json",
      },
    }
  );
};

export const rejectStaticApi = (id, params) => {
  return api.post(
    `/api/admin/static-screen/reject-static-screen/${id}`,
    JSON.stringify(params),
    {
      headers: {
        "x-access-token": localStorage.getItem("accessToken"),
        "Content-Type": "application/json",
      },
    }
  );
};

export const viewStaticListApi = (pageNo, params) => {
  return api.post(
    `/api/admin/static-screen/find-all-static-screen?page_no=${pageNo}`,
    JSON.stringify(params),
    {
      headers: {
        "x-access-token": localStorage.getItem("accessToken"),
        "Content-Type": "application/json",
      },
    }
  );
};

export const viewStaticDetailApi = (id, params) => {
  return api.post(
    `/api/admin/static-screen/find-one-static-screen/${id}`,
    JSON.stringify(params),
    {
      headers: {
        "x-access-token": localStorage.getItem("accessToken"),
        "Content-Type": "application/json",
      },
    }
  );
};

export const editStaticApi = (id, params) => {
  return api.post(
    `/api/admin/static-screen/edit-static-screen/${id}`,
    JSON.stringify(params),
    {
      headers: {
        "x-access-token": localStorage.getItem("accessToken"),
        "Content-Type": "application/json",
      },
    }
  );
};

export const deleteStaticApi = (id, params) => {
  return api.post(
    `/api/admin/static-screen/delete-static-screen/${id}`,
    JSON.stringify(params),
    {
      headers: {
        "x-access-token": localStorage.getItem("accessToken"),
        "Content-Type": "application/json",
      },
    }
  );
};

export const staticSubmitApi = (id, params) => {
  return api.post(`/api/admin/static-screen/on-submit/${id}`, params, {
    headers: {
      "x-access-token": localStorage.getItem("accessToken"),
      "Content-Type": "application/json",
    },
  });
};

export const versionHistoryStaticListApi = (pageNo, params) => {
  return api.post(
    `/api/admin/static-screen/find-all-version-static-screen?page_no=${pageNo}`,
    JSON.stringify(params),
    {
      headers: {
        "x-access-token": localStorage.getItem("accessToken"),
        "Content-Type": "application/json",
      },
    }
  );
};

export const versionHistoryStaticDetailApi = (id, params) => {
  return api.post(
    `/api/admin/static-screen/find-one-static-screen-version/${id}`,
    JSON.stringify(params),
    {
      headers: {
        "x-access-token": localStorage.getItem("accessToken"),
        "Content-Type": "application/json",
      },
    }
  );
};
export const staticOptionsApi = (params) => {
  return api.post(
    `/api/homestatic/views-all-Static-category-Names`,
    JSON.stringify(params),
    {
      headers: {
        "x-access-token": localStorage.getItem("accessToken"),
        "Content-Type": "application/json",
      },
    }
  );
};

export const staticMainExportApi = (page, params) => {
  return api.post(
    `/api/admin/static-screen/export-main-static`,
    JSON.stringify(params),
    {
      headers: {
        "x-access-token": localStorage.getItem("accessToken"),
        "Content-Type": "application/json",
      },
    }
  );
};

export const staticApprovalsExportApi = (page, params) => {
  return api.post(
    `/api/admin/static-screen/export-approval-static`,
    JSON.stringify(params),
    {
      headers: {
        "x-access-token": localStorage.getItem("accessToken"),
        "Content-Type": "application/json",
      },
    }
  );
};

export const staticVersionExportApi = (page, params) => {
  return api.post(
    `/api/admin/static-screen/export-version-static`,
    JSON.stringify(params),
    {
      headers: {
        "x-access-token": localStorage.getItem("accessToken"),
        "Content-Type": "application/json",
      },
    }
  );
};
