import { call, takeLatest } from "redux-saga/effects";
import { CREATE_NOTIFICATIONME_OPTIONS, NOTIFICATION_APPROVALS_EXPORT, NOTIFICATION_APPROVALS_LIST, NOTIFICATION_APPROVALS_VIEW, NOTIFICATION_APPROVE, NOTIFICATION_CREATE, NOTIFICATION_DELETE, NOTIFICATION_EDIT, NOTIFICATION_EXPORT, NOTIFICATION_LIST, NOTIFICATION_READ, NOTIFICATION_REJECT, NOTIFICATION_SUBMIT, NOTIFICATION_VERSION_EXPORT, NOTIFICATION_VERSION_LIST, NOTIFICATION_VERSION_VIEW } from "../actions/types";
import { approveNotificationApi, deleteNotificationApi, editNotificationApi, notificationApprovalsExportApi, notificationApprovalsTableApi, notificationCreateApi, notificationCreateOptionsApi, notificationSubmitApi, notificationTableExportApi, notificationVersionExportApi, notificationVersionHistoryViewApi, readApprovalNotificationApi, readNotificationApi, rejectNotificationApi, versionTableNotificationApi, viewAllNotificationApi } from "../apis/nitificationApis";
export function* deleteNotificationWorker(action) {

    try {
        const res = yield call(deleteNotificationApi, action.id,
        );
        // console.log(res, "AddAdminRole");
        if (res.status === 200 && res.data.status === "success") {
            yield action.onSuccess(res.data);
            console.log(res.data);
        } else if (res.status === 200 && res.data.status === "fail") {
            console.log(res);
            yield action.onError(res);
        } else {
            yield action.onError("Something Went Wrong");
        }
    } catch (err) {
        console.log(err);
        yield action.onError("Something Went Wrong");
    }

}
export function* readNotificationWorker(action) {
    try {
        const res = yield call(readNotificationApi,
            action.id,
        );
        // console.log(res, "AddAdminRole");
        if (res.status === 200 && res.data.status === "success") {
            yield action.onSuccess(res.data);
            console.log(res.data);
        } else if (res.status === 200 && res.data.status === "fail") {
            console.log(res.data);
            yield action.onError(res);
        } else {
            yield action.onError("Something Went Wrong!");
            console.log(res.data);
        }
    } catch (err) {
        console.log(err);
        yield action.onError("Something Went Wrong!!");
    }
}
export function* readApprovalNotificationWorker(action) {
    try {
        const res = yield call(readApprovalNotificationApi,
            action.id,
        );
        // console.log(res, "AddAdminRole");
        if (res.status === 200 && res.data.status === "success") {
            yield action.onSuccess(res.data);
            console.log(res.data);
        } else if (res.status === 200 && res.data.status === "fail") {
            console.log(res.data);
            yield action.onError(res);
        } else {
            yield action.onError("Something Went Wrong!");
            console.log(res.data);
        }
    } catch (err) {
        console.log(err);
        yield action.onError("Something Went Wrong!!");
    }
}
export function* viewAllNotificationWorker(action) {
    try {
        const res = yield call(viewAllNotificationApi,
            action.pageNo,
            action.data,
        );
        // console.log(res, "AddAdminRole");
        if (res.status === 200 && res.data.status === "success") {
            yield action.onSuccess(res.data);
            console.log(res, "hgcdhgvdh");
        } else if (res.status === 200 && res.data.status === "fail") {
            console.log(res);
            yield action.onError(res.data);
        } else {
            yield action.onError("Something Went Wrong");
        }
    } catch (err) {
        console.log(err);
        yield action.onError("Something Went Wrong");
    }
}

export function* versionTableNotificationWorker(action) {
    try {
        const res = yield call(versionTableNotificationApi,
            action.pageNo,
            action.data,
        );
        if (res.status === 200 && res.data.status === "success") {
            yield action.onSuccess(res.data);
            console.log(res.data);
        } else if (res.status === 200 && res.data.status === "fail") {
            console.log(res);
            yield action.onError(res);
        } else {
            yield action.onError("Something Went Wrong!");
            console.log(res.data);
        }
    } catch (err) {
        console.log(err);
        yield action.onError("Something Went Wrong!!");
    }
}

export function* NotificationVersionHistoryWorker(action) {
    try {
        const res = yield call(notificationVersionHistoryViewApi, action.id
        );
        if (res.status === 200 && res.data.status === "success") {
            yield action.onSuccess(res);
            console.log(res);
        } else if (res.status === 200 && res.data.status === "fail") {
            console.log(res);
            yield action.onError(res);
        } else {
            yield action.onError("Something Went Wrong!");
            console.log(res);
        }
    } catch (err) {
        console.log(err);
        yield action.onError("Something Went Wrong!!");
    }
}

export function* NotificationApprovalsTableWorker(action) {
    try {
        const res = yield call(notificationApprovalsTableApi,
            action.pageNo,
            action.data,
        );
        if (res.status === 200 && res.data.status === "success") {
            yield action.onSuccess(res.data);
            console.log(res.data);
        } else if (res.status === 200 && res.data.status === "fail") {
            console.log(res);
            yield action.onError(res);
        } else {
            yield action.onError("Something Went Wrong!");
            console.log(res.data);
        }
    } catch (err) {
        console.log(err);
        yield action.onError("Something Went Wrong!!");
    }
}
export function* NotificationCreateWorker(action) {
    try {
        console.log(action.data);
        const res = yield call(notificationCreateApi, action.data);

        if (res.status === 200 && res.data.status === "success") {
            console.log(res.data);
            yield action.onSuccess(res.data);
        } else {
            yield action.onError(res.data);
        }
    } catch (error) { }
}



export function* editNotificationWorker(action) {

    try {
        const res = yield call(editNotificationApi, action.id, action.data);
        if (res.status === 200 && res.data.status === "success") {
            yield action.onSuccess(res.data);
            console.log(res.data);
        } else if (res.status === 200 && res.data.status === "fail") {
            console.log(res);
            yield action.onError(res);
        } else {
            yield action.onError("Something Went Wrong");
        }
    } catch (err) {
        console.log(err);
        yield action.onError("Something Went Wrong");
    }

}

export function* approveNotificationWorker(action) {
    try {
        const res = yield call(approveNotificationApi,
            { notification_id: action.id }
        );
        // console.log(res, "AddAdminRole");
        if (res.status === 200 && res.data.status === "success") {
            yield action.onSuccess(res.data);
            console.log(res.data);
        } else if (res.status === 200 && res.data.status === "fail") {
            console.log(res);
            yield action.onError(res.data);
        } else {
            yield action.onError("Something Went Wrong!");
            console.log(res.data);
        }
    } catch (err) {
        console.log(err);
        yield action.onError("Something Went Wrong!!");
    }
}


export function* rejectNotificationWorker(action) {
    try {
        const res = yield call(rejectNotificationApi,
            action.id
        );
        // console.log(res, "AddAdminRole");
        if (res.status === 200 && res.data.status === "success") {
            yield action.onSuccess(res.data);
            console.log(res.data);
        } else if (res.status === 200 && res.data.status === "fail") {
            console.log(res);
            yield action.onError(res.data);
        } else {
            yield action.onError("Something Went Wrong!");
            console.log(res.data);
        }
    } catch (err) {
        console.log(err);
        yield action.onError("Something Went Wrong!!");
    }
}


//export
export function* notificationTableExportWorker(action) {
    try {
        const res = yield call(notificationTableExportApi,

        );
        if (res.status === 200 && res.data.status === "success") {
            yield action.onSuccess(res.data);
        } else {
            yield action.onError(res.data);
        }
    } catch (error) { }
}


export function* notificationApprovalsExportWorker(action) {
    try {
        const res = yield call(notificationApprovalsExportApi);
        console.log(res);
        if (res.status === 200 && res.data.status === "success") {
            console.log(res.data);
            yield action.onSuccess(res.data.data);
        } else {
            yield action.onError(res.data);
        }
    } catch (error) { }
}

export function* notificationVersionExportWorker(action) {
    try {
        const res = yield call(notificationVersionExportApi);
        console.log(res);
        if (res.status === 200 && res.data.status === "success") {
            yield action.onSuccess(res.data);
        } else {
            yield action.onError(res.data);
        }
    } catch (error) { }
}

export function* notificationSubmitWorker(action) {
    try {
        const res = yield call(notificationSubmitApi, action.id);
        console.log(res);
        if (res.status === 200 && res.data.status === "success") {
            yield action.onSuccess(res.data);
        } else {
            yield action.onError(res.data);
        }
    } catch (error) { }
}



export function* watchViewAllNotificationWorker() {
    yield takeLatest(NOTIFICATION_LIST, viewAllNotificationWorker);
}
export function* watchNotificationWorker() {
    yield takeLatest(NOTIFICATION_DELETE, deleteNotificationWorker);
}
export function* watchreadNotificationWorker() {
    yield takeLatest(NOTIFICATION_READ, readNotificationWorker);
}
export function* watchreadApprovalNotificationWorker() {
    yield takeLatest(NOTIFICATION_APPROVALS_VIEW, readApprovalNotificationWorker);
}

export function* watchversionTableNotificationWorker() {
    yield takeLatest(NOTIFICATION_VERSION_LIST, versionTableNotificationWorker);
}
export function* watchNotificationVersionHistoryWorker() {
    yield takeLatest(NOTIFICATION_VERSION_VIEW, NotificationVersionHistoryWorker);
}
export function* watchNotificationApprovalsTableWorker() {
    yield takeLatest(NOTIFICATION_APPROVALS_LIST, NotificationApprovalsTableWorker);
}
export function* WatcherNotificationCreateWorker() {
    yield takeLatest(NOTIFICATION_CREATE, NotificationCreateWorker);
}
export function* watcheditNotificationWorker() {
    yield takeLatest(NOTIFICATION_EDIT, editNotificationWorker);
}
export function* watchapproveNotificationWorker() {
    yield takeLatest(NOTIFICATION_APPROVE, approveNotificationWorker);
}
export function* watchrejectNotificationWorker() {
    yield takeLatest(NOTIFICATION_REJECT, rejectNotificationWorker);
}

//export
export function* notificationTableExportWatcher() {
    yield takeLatest(
        NOTIFICATION_EXPORT,
        notificationTableExportWorker
    );
}
export function* notificationApprovalsExportWatcher() {
    yield takeLatest(NOTIFICATION_APPROVALS_EXPORT, notificationApprovalsExportWorker);
}

export function* notificationVersionExportWorkerWatcher() {
    yield takeLatest(NOTIFICATION_VERSION_EXPORT, notificationVersionExportWorker);
}
export function* notificationSubmitWorkerWatcher() {
    yield takeLatest(NOTIFICATION_SUBMIT, notificationSubmitWorker);
}
