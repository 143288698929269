import React, { useEffect, useRef, useState } from "react";
import "../llp.css";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import SaveBtn from "../../../../components/buttons/saveBtn";
import InnerContainer from "../../../../components/layouts/innerContainer/innerContainer";
import InnerLayout from "../../../../components/layouts/innerLayout/innerLayout";
import DeleteBtn from "../../../../components/buttons/deleteBtn";
import SelectFields from "../../../../components/form/selectFields/selectFields";
import InputField from "../../../../components/form/inputFields/inputField";
import ArrowDownIcon from "../../../../assets/icons/arrowdown";
import PreviewBtn from "../../../../components/buttons/previewBtn";
import { editLLPAction, llpSubmit, viewLLPAction } from "../../../../redux/actions/llpActions";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import successToast from "../../../../components/utilits/successToast";
import errorToast from "../../../../components/utilits/errorToast";
import { opportunitiesNameListAction } from "../../../../redux/actions/opportunitiesActions";
import { useNavigate } from "react-router-dom";
import LoadingSpinnerTable from "../../../../components/utilits/loadingSpinnerTable";
import LoadAndError from "../../../../components/utilits/LoadAndError";
import SubmitBtn from "../../../../components/buttons/submit";

const LlpEdit = () => {
  const [submitData, setSubmitData] = useState();
  const params = useParams()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [llpDetails, setLlpDetails] = useState([]);
  const [llpData, setLlpData] = useState([]);
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const [memberCount, setMemberCount] = useState(0)

  // const [selectValue, setSelectValue] = useState({})
  useEffect(() => {
    setLoading(true);
    dispatch(viewLLPAction(params.id, onSuccess, onError));
  }, []);

  console.log(params.id);
  const onSuccess = (data) => {
    console.log(data.data);
    setLlpDetails(data?.data?.LLP_details);
    setMemberCount(data?.data?.member_details?.length
    )
    console.log("SUCCESS");
    setLoading(false);
    setError(false)
  };

  const onError = (data) => {
    console.log("ERROR");
    setLoading(false);
    setError(true)
  };

  console.log(llpDetails?.name);
  console.log(llpDetails?.title);
  console.log(llpDetails?.reg_no);
  console.log(llpDetails?.opportunity_id);
  console.log(llpDetails?.status);
  const formRef = useRef();
  const handleSubmit = () => {
    if (formRef.current) {
      formRef.current.handleSubmit();
    }
  };
  const initialValues = {
    llpName: llpDetails.name,
    llpTitle: llpDetails.title,
    llpRegistrationNumber: llpDetails.reg_no,
    llpopportunity: llpDetails.opportunity_id,
    status: llpDetails.status,
  };
  const loginSchema = Yup.object().shape({
    llpName: Yup.string().trim().required("Required"),
    llpTitle: Yup.string().trim().required("Required"),
    llpRegistrationNumber: Yup.string().trim().required("Required"),
    llpopportunity: Yup.string().trim().required("Required"),
    status: Yup.string().trim().required("Required"),
  });
  const onSubmit = (values, { resetForm }) => {
    console.log(values);
    dispatch(
      editLLPAction(
        params.id,
        {
          name: values.llpName,
          title: values.llpTitle,
          reg_no: values.llpRegistrationNumber,
          status: values.status,
          opportunity_id: values.llpopportunity
        },
        onEditSuccess,
        onEditError
      )
    );
  };
  const onEditSuccess = (data) => {
    setSubmitData(data.data.id)
    setSaveHide(true)
    setLlpData(data.data)
    successToast(data.message)
    // navigate("/opportunity/llp")
    setLoading(false)
    setError(false)
  };
  const onEditError = (data) => {
    console.log(data);
    errorToast(data.message)
    setLoading(false)
    setError(true)
  };

  //llp opp options
  const [opportunitiesList, setOpportunitiesList] = useState([])
  useEffect(() => {
    dispatch(opportunitiesNameListAction(onSuccessDrop, onErrorDrop));
  }, []);
  const onSuccessDrop = (data) => {
    const categoryList = [];
    data?.data.map((item, index) => {
      categoryList[index] = { value: item.id, label: `${item.title}${" "}id:${item.id}` };
    });
    setOpportunitiesList(categoryList);
    console.log("SUCCESS");
  };
  const onErrorDrop = (data) => {
    console.log(data);
  };


  //on submit api
  console.log(submitData);
  const [saveHide, setSaveHide] = useState(false);

  const submitFnc = () => {
    dispatch(llpSubmit
      (
        submitData,
        onSubmitSuccess,
        onSubmitError,
      ));
  };
  const onSubmitSuccess = (data) => {
    console.log(data);
    successToast(data.message)
    navigate("/opportunity/llp")
  };
  const onSubmitError = (data) => {
    console.log("ERROR==================", data);
    errorToast(data.message)

  };
  const buttonList = [
    !saveHide && <SaveBtn submitFn={handleSubmit}
      loader={loading} />,
    <SubmitBtn submitFn={submitFnc} saveHide={saveHide} />];

  const options = [
    { label: "active", value: "active" },
    { label: "completed", value: "completed" },
    { label: "traded", value: "traded" },
    { label: "ended", value: "ended" },
  ];

  // const handleSelected = (value) => {
  //   setSelectValue(value)
  //   console.log(selectValue);

  // }


  return (
    <InnerLayout buttons={buttonList}>
      <div className="llp-container">
        <InnerContainer>
          {loading ? (
            <LoadingSpinnerTable />
          ) : (
            <LoadAndError loader={loading} error={error}>
              <div className="pt-3 d-flex gap-4  ps-4 ps-lg-4 ms-4">
                <div>
                  <h5 className="dark_grey_color fs_13 fn_Montserrat fw_500 m-0">
                    LLP ID
                  </h5>
                  <p className={` fs_13 fn_Nunito fw_500 dark_title_color  `}>
                    {llpDetails.id}
                  </p>
                </div>
                <div>
                  <h5 className="dark_grey_color fs_13 fn_Montserrat fw_500 m-0">
                    Member Count
                  </h5>
                  <p className={` fs_13 fn_Nunito fw_600 secondary_color  `}>
                    {memberCount}
                  </p>
                </div>
              </div>

              <div className="  ps-4 ps-lg-4 ms-3">
                <Formik
                  innerRef={formRef}
                  initialValues={initialValues}
                  onSubmit={onSubmit}
                  enableReinitialize={true}
                  validationSchema={loginSchema}
                >
                  <Form className="llp-create-form-width">
                    <InputField
                      id={"llpName"}
                      label={"LLP Name"}
                      type={"text"}
                      placeholder={"Enter Name"}
                      name={"llpName"}
                    />
                    <InputField
                      id={"llpTitle"}
                      label={"Title"}
                      type={"text"}
                      placeholder={"Enter Title"}
                      name={"llpTitle"}
                    />
                    <InputField
                      id={"llpRegistrationNumber"}
                      label={"Registration Number"}
                      type={"text"}
                      placeholder={"Enter Registration Number"}
                      name={"llpRegistrationNumber"}
                    />

                    <SelectFields
                      // title={selectValue.label}
                      // handleSelected={handleSelected}
                      default1={{
                        label:
                          `${llpDetails?.opportunity?.title}${" "}id:${llpDetails?.opportunity?.id}`
                        , value: llpDetails?.opportunity?.id
                      }}
                      Icon={<ArrowDownIcon width={15} fill={"#A5A5A5"} />}
                      label={"Opportunity"}
                      placeholder={"Select Opportunity"}
                      name={"llpopportunity"}
                      options={opportunitiesList}
                      id={"llpopportunity"}
                    />
                    <SelectFields
                      default1={{ label: llpDetails?.status, value: llpDetails?.status }}
                      Icon={<ArrowDownIcon width={15} fill={"#A5A5A5"} />}
                      label={"Status"}
                      placeholder={"Select Status"}
                      name={"status"}
                      options={options}
                      id={"status"}
                    />
                  </Form>
                </Formik>
              </div>
            </LoadAndError>
          )}
        </InnerContainer>
      </div>
    </InnerLayout>
  );
};

export default LlpEdit;
