import React, { useState } from "react";
import DownloadIcon from "../../../assets/icons/downloadIcon";
import "../../../modules/user/user-view.css";
import RejectBtn from "../../buttons/rejectBtn";
import { saveAs } from "file-saver";
import RejectPanDescription from "../../modals/rejectPanDescription";
import ApproveBtn from "../../buttons/approveBtn";
import AddressBackImageModal from "../modals/addressbackImageModal";
import AddressFrontImageModal from "../modals/addressFrontImageModal";
import * as userAction from "../../../redux/actions/userAction";
import successToast from "../../utilits/successToast";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import errorToast from "../../utilits/errorToast";
import RejectaddressDescription from "../../modals/rejectaddressdescription";
import { access } from "../../utilits/access";
import usePermissionChecker from "../../utilits/usePermissionChecker";
import { Document, Page } from "react-pdf";
import ViewImageModal from "../modals/viewImageModal";
import DummyImage from "../../../assets/images/defaultImage.png";
const Address = ({ view, value, label, details, dataType, individualData }) => {
  // console.log(individualData);
  const access = usePermissionChecker("checker")

  const addressProof = [
    {
      heading: "Proof Type",
      title: individualData?.address_proof?.proof_type,
    },
    {
      heading: "Verified",
      title:
        individualData?.address_proof?.verified === true
          ? "Verified"
          : "Not Verified",
    },
    {
      heading: "Rejected",
      title:
        individualData?.address_proof?.rejected === true
          ? "Rejected"
          : "Not Rejected",
    },
  ];


  const [addreesApproved, setAddressApproved] = useState(false);
  const [addreesRejected, setAddressRejected] = useState(false);
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);
  const [imageShow, setImageShow] = useState(false);
  const [backimageShow, setBackImageShow] = useState(false);
  const handleImageShow = () => setImageShow(true);
  const handleImageClose = () => setImageShow(false);
  const handleBackImageShow = () => setBackImageShow(true);
  const handleBackImageClose = () => setBackImageShow(false);
  const [loadingApproved, setLoadingApproved] = useState(false);
  const [loadingRejected, setLoadingRejected] = useState(false);
  const [button, setButton] = useState(false);
  const [adressApprovalViewData, SetAdressMeApprovalView] = useState({
    id: "",
  });
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const params = useParams();
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    setPageNumber(1);
  }
  const downloadImage = () => {
    saveAs(
      "https://upload.wikimedia.org/wikipedia/commons/thumb/c/c8/Julia_2022-10-09_0710Z.jpg/183px-Julia_2022-10-09_0710Z.jpg"
    ); // Put your image url here.
  };


  const handleAddressaproved = () => {
    setLoadingApproved(true);
    dispatch(
      userAction.rejectedApproveAdressprofUserAction(
        {
          id: individualData?.id,
          kyc_status: "verified",
        },
        onAddressrejectedSuccess,
        onAddressrejectedError
      )
    );
  };

  const onAddressrejectedSuccess = (data) => {
    successToast(data.message);
    setLoadingApproved(false);
    setAddressApproved(true)
    setButton(true);
    console.log("address aproved");
  };
  const onAddressrejectedError = (data) => {
    errorToast(data.message);
    setLoadingApproved(false);
  };

  return (
    <>
      <h3 className="dark_grey_color fn_Montserrat ps-4 ps-lg-5 mt-3 py-2 fw_500 white_shade_bg fs_13 ">
        Address
      </h3>
      <div className="row mt-3 ps-1 ms-1 ps-lg-4 ms-lg-1">
        <div className="col-lg-12 col-xl-9">
          <div className="row">
            {addressProof.map((item, index) => (
              <div className="address_pan_bank_data_width mt-2">
                <h5 className="dark_grey_color fs_13 fn_Montserrat fw_500">
                  {item.heading}
                </h5>
                <p className={` fs_13 fn_Nunito fw_500 'dark_title_color'}`}>
                  {item.title}
                </p>
              </div>
            ))}
          </div>
          <div className="d-flex gap-4 gap-lg-5 flex-column flex-lg-row mt-0 mb-4">
            <div>
              <h5 className="dark_grey_color text-nowrap fs_13 fn_Montserrat fw_500">
                Front Image
              </h5>

              <div className="personal_information_image_container mt-2 position-relative">

                {individualData?.address_proof?.front_image_location?.endsWith(".pdf") ? (
                  <div
                    onClick={handleImageShow}
                    className={`w-100 h-100 overflow-hidden`}
                  >
                    <Document
                      file={individualData?.address_proof?.front_image_location}
                      onLoadSuccess={onDocumentLoadSuccess}
                    >
                      {Array.from({ length: numPages }, (_, index) => (
                        <Page
                          key={`page_${index + 1}`}
                          pageNumber={index + 1}
                          renderAnnotationLayer={false}
                          renderTextLayer={false}
                        />
                      ))}
                    </Document>
                  </div>
                ) : (
                  <img
                    onClick={handleImageShow}
                    src={individualData?.address_proof?.front_image_location??DummyImage}
                    cross-origin="use-credentials"
                    className="personal_information_img_size rounded-2"
                  />)}
                {/* <a
                  href={`${individualData?.address_proof?.front_image_location}`}
                  download="file"
                >
                  <button
                    type="button"
                    className="position-absolute border-0 bg-white d-flex align-items-center fs_10 top-0 end-0"
                  >
                    <DownloadIcon width={8} />
                    <span className="ms-1">Download</span>
                  </button>
                </a> */}
              </div>
            </div>

            <div>
              <h5 className="dark_grey_color text-nowrap fs_13 fn_Montserrat fw_500">
                Back Image
              </h5>

              <div className="personal_information_image_container mt-2 position-relative">
                {individualData?.address_proof?.back_image_location?.endsWith(".pdf") ? (
                  <div
                    onClick={handleImageShow}
                    className={`w-100 h-100 overflow-hidden`}
                  >
                    <Document
                      file={individualData?.address_proof?.back_image_location}
                      onLoadSuccess={onDocumentLoadSuccess}
                    >
                      {Array.from({ length: numPages }, (_, index) => (
                        <Page
                          key={`page_${index + 1}`}
                          pageNumber={index + 1}
                          renderAnnotationLayer={false}
                          renderTextLayer={false}
                        />
                      ))}
                    </Document>
                  </div>
                ) : (
                  <img
                    onClick={handleBackImageShow}
                    src={individualData?.address_proof?.back_image_location??DummyImage}
                    className="personal_information_img_size rounded-2"
                  />)}
                {/* <a
                  href={`${individualData?.address_proof?.back_image_location}`}
                  download="file"
                >
                  <button
                    // onClick={downloadImage}
                    type="button"
                    className="position-absolute border-0 bg-white d-flex align-items-center fs_10 top-0 end-0"
                  >
                    <DownloadIcon width={8} />
                    <span className="ms-1">Download</span>
                  </button>
                </a> */}
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-3 col-lg-12">
          <div className="d-flex  gap-5 gap-lg-3 mb-3">
            {
              individualData?.address_proof?.status === "verified" || addreesApproved ? (
                <span className="">Approved</span>
              ) : individualData?.address_proof?.status === "failed" || addreesRejected ? (
                "Rejected"
              ) : (
                <span className="d-flex gap-2">
                  {
                    access == true && <>
                      <ApproveBtn approveFn={handleAddressaproved} />
                      <RejectBtn showModal={handleShow} />{" "}</>
                  }

                </span>
              )
            }
          </div>
        </div>
      </div>
      <RejectPanDescription
        handleClose={handleClose}
        popup="address"
        show={show}
        label={label}
        value={value}
        data={individualData}
        deleteContent="Reject Address description"
      />
      {/* <AddressFrontImageModal
        handleClose={handleImageClose}
        show={imageShow}
        adress={individualData?.address_proof}
      /> */}
      {/* <AddressBackImageModal
        handleClose={handleBackImageClose}
        show={backimageShow}
        adress={individualData?.address_proof}
      /> */}
      <ViewImageModal
        popuHeading="ADDRESS PROF IMAGE"
        handleClose={handleImageClose}
        show={imageShow}
        Profileimage={individualData?.address_proof?.front_image_location}
      />
      <ViewImageModal
        popuHeading="ADDRESS PROF IMAGE"
        handleClose={handleBackImageClose}
        show={backimageShow}
        Profileimage={individualData?.address_proof?.back_image_location}
      />
    </>
  );
};

export default Address;
