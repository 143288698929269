import { call, takeLatest } from "redux-saga/effects";
import {
  ADD_USER,
  EDIT_USER,
  USER_EMAIL_OTP,
  USER_PHONE_OTP,
  USER_APPROVAL_LIST,
  INDIVIDUAL_USER_APPROVAL_DETAILS,
  USER_LIST,
  USER_VIEW_DETAILS,
  RESET_PASSWORD_EMAIL_VIEW,
  USER_VERSION_HISTORY_LIST,
  USER_VERSION_HISTORY_VIEW,
  APPROVE_USER,
  DELETE_USER,
  ADD_PROFILE_IMAGE,
  UPLOAD_ADDRESS_PROOF_FRONT_IMAGE,
  UPLOAD_ADDRESS_PROOF_BACK_IMAGE,
  ADD_BANK_IMAGE,
  ADD_PAN_IMAGE,
  ADDRESS_APPROVED,
  USER_EXPORT,
  GET_PROFILE_IMAGE,
  ADDRESS_REJECTED,
  PAN_APPROVED,
  PAN_REJECTED,
  BANK_APPROVED,
  BANK_REJECTED,
  REJECT_USER,
  KYC_STATUS_USER,
  PAN_APPROVE_REJECT,
  BANK_APPROVE_REJECT,
  USER_VERSION_EXPORT,
  USER_APPROVALS_EXPORT,
  USER_PHONE_EXPORT,
  USER_EMAIL_EXPORT,
  USER_RESET_EXPORT,
  FORGOT_PASS_SEND_EMAIL,
  USER_SUBMIT,
} from "../actions/types";

import {
  addUserApi,
  EditUserApi,
  userEmailOtpApi,
  userPhoneOtpApi,
  approvalsUserListApi,
  individualUserApprovalDetailApi,
  UserListApi,
  ApprovalUserListApi,
  UserApprovalDetailApi,
  UserViewDetailApi,
  ResetPasswordEmailViewapi,
  userVersionHistoryListApi,
  UserVersionHistoryViewApi,
  ApprovalUserapi,
  deleteUserApi,
  profileImageUploadApi,
  uploadAddressProofFrontImageApi,
  uploadAddressProofBackImageApi,
  panImageUploadApi,
  bankImageUploadApi,
  approvedAddreddUserApi,
  userExportApi,
  getProfileImagelApi,
  rejectedAddreddUserApi,
  approvedPanUserApi,
  rejectedPanUserApi,
  approvedBankUserApi,
  rejectedBankUserApi,
  rejectUserApi,
  kycStatusUserApi,
  rejectedApproveAddreddUserApi,
  PanApproveRejectUserApi,
  rejectedApproveBankUserApi,
  userApprovalsExportApi,
  userTableExportApi,
  userVersionExportApi,
  forgotPasswordSendEmailApi,
  userSubmitApi,
} from "../apis/userApis";

import image from "../../assets/icons/arrowleft.svg";
import { act } from "react-dom/test-utils";

export function* addUserWorker(action) {
  try {
    const res = yield call(
      addUserApi,

      action.data
    );
    console.log(res);
    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res);
      console.log(res);
    } else if (res.status === 200 && res.data.status === "fail") {
      console.log(res);
      yield action.onError(res);
    } else {
      yield action.onError("Something Went Wrong!");
      console.log(res);
    }
  } catch (err) {
    console.log(err);
    yield action.onError("Something Went Wrong");
  }
}

export function* editUserWorker(action) {
  try {
    const res = yield call(EditUserApi, action.id, action.data);
    console.log(res);

    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res.data);
      console.log(res.data);
    } else if (res.status === 200 && res.data.status === "fail") {
      console.log(res.data);
      yield action.onError(res);
    } else {
      yield action.onError("Something Went Wrong!");
      console.log(res.data);
    }
  } catch (err) {
    console.log(err);
    yield action.onError("Something Went Wrong!!");
  }
}

export function* deleteUserWorker(action) {
  try {
    const res = yield call(deleteUserApi, action.id);
    console.log(res, "delete");
    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res.data);
      console.log(res);
    } else if (res.status === 200 && res.data.status === "fail") {
      console.log(res);
      yield action.onError(res.data);
    } else {
      yield action.onError("Something Went Wrong");
    }
  } catch (err) {
    console.log(err);
    yield action.onError("Something Went Wrong");
  }
}

export function* rejectUserWorker(action) {
  try {
    const res = yield call(rejectUserApi, action.id);
    console.log(res, "");
    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res.data);
      console.log(res);
    } else if (res.status === 200 && res.data.status === "fail") {
      console.log(res);
      yield action.onError(res.data);
    } else {
      yield action.onError("Something Went Wrong");
    }
  } catch (err) {
    console.log(err);
    yield action.onError("Something Went Wrong");
  }
}
export function* userEmailOtpWorker(action) {
  try {
    const res = yield call(userEmailOtpApi, action.pageNo, action.search,);
    // console.log(res, "AddAdminRole");
    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res.data);
      console.log(res);
    } else if (res.status === 200 && res.data.status === "fail") {
      console.log(res);
      yield action.onError(res.data);
    } else {
      yield action.onError("Something Went Wrong");
    }
  } catch (err) {
    console.log(err);
    yield action.onError("Something Went Wrong");
  }
}
export function* userPhoneOtpWorker(action) {
  try {
    const res = yield call(userPhoneOtpApi, action.pageNo, action.search,);
    // console.log(res, "AddAdminRole");
    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res.data);
      console.log(res);
    } else if (res.status === 200 && res.data.status === "fail") {
      console.log(res);
      yield action.onError(res.data);
    } else {
      yield action.onError("Something Went Wrong");
    }
  } catch (err) {
    console.log(err);
    yield action.onError("Something Went Wrong");
  }
}
export function* ResetPasswordEmailViewWorker(action) {
  try {
    const res = yield call(
      ResetPasswordEmailViewapi,
      action.pageNo,
      action.search
    );
    // console.log(res, "AddAdminRole");
    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res.data);
      console.log(res);
    } else if (res.status === 200 && res.data.status === "fail") {
      console.log(res);
      yield action.onError(res.data);
    } else {
      yield action.onError("Something Went Wrong");
    }
  } catch (err) {
    console.log(err);
    yield action.onError("Something Went Wrong");
  }
}

export function* UserListWorker(action) {
  try {
    const res = yield call(UserListApi,
      action.current_page,
      action.data);
    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res.data);
      console.log(res);
    } else if (res.status === 200 && res.data.status === "fail") {
      console.log(res);
      yield action.onError(res.data);
    } else {
      yield action.onError("Something Went Wrong");
    }
  } catch (err) {
    console.log(err);
    yield action.onError("Something Went Wrong");
  }
}
export function* ApprovalUserListWorker(action) {
  try {
    const res = yield call(ApprovalUserListApi,
      action.current_page,
      action.data,
    );

    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res.data);
      console.log(res);
    } else if (res.status === 200 && res.data.status === "fail") {
      console.log(res);
      yield action.onError(res.data);
    } else {
      yield action.onError("Something Went Wrong");
    }
  } catch (err) {
    console.log(err);
    yield action.onError("Something Went Wrong");
  }
}
export function* individualUserDetailWorker(action) {
  try {
    const res = yield call(UserViewDetailApi, { id: action.id });

    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res.data);
      console.log(res.data);
    } else if (res.status === 200 && res.data.status === "fail") {
      console.log(res.data);
      yield action.onError(res);
    } else {
      yield action.onError("Something Went Wrong!");
      console.log(res.data);
    }
  } catch (err) {
    console.log(err);
    yield action.onError("Something Went Wrong!!");
  }
}

export function* userVersionHistoryListWorker(action) {
  try {
    const res = yield call(
      userVersionHistoryListApi,
      action.current_page,
      action.data
    );

    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res.data);
      console.log(res);
    } else if (res.status === 200 && res.data.status === "fail") {
      console.log(res);
      yield action.onError(res.data);
    } else {
      yield action.onError("Something Went Wrong");
    }
  } catch (err) {
    console.log(err);
    yield action.onError("Something Went Wrong");
  }
}
export function* viewUserVersionHistoryWorker(action) {
  try {
    const res = yield call(UserVersionHistoryViewApi, action.id);
    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res);
      console.log(res);
    } else if (res.status === 200 && res.data.status === "fail") {
      console.log(res);
      yield action.onError(res);
    } else {
      yield action.onError("Something Went Wrong!");
      console.log(res);
    }
  } catch (err) {
    console.log(err);
    yield action.onError("Something Went Wrong!!");
  }
}

export function* viewUserApprovalVersionHistoryWorker(action) {
  try {
    const res = yield call(individualUserApprovalDetailApi, action.id);
    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res);
      console.log(res);
    } else if (res.status === 200 && res.data.status === "fail") {
      console.log(res);
      yield action.onError(res);
    } else {
      yield action.onError("Something Went Wrong!");
      console.log(res);
    }
  } catch (err) {
    console.log(err);
    yield action.onError("Something Went Wrong!!");
  }
}

export function* approveUserWorker(action) {
  try {
    const res = yield call(ApprovalUserapi, { userid: action.id });
    console.log(res);
    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res);
      console.log(res);
    } else if (res.status === 200 && res.data.status === "fail") {
      console.log(res);
      yield action.onError(res);
    } else {
      yield action.onError("Something Went Wrong!");
      console.log(res);
    }
  } catch (err) {
    console.log(err);
    yield action.onError("Something Went Wrong!!");
  }
}

/****IMAGE UPLOAD ********/

export function* profileImageUploadWorker(action) {
  try {
    const address_proof = new FormData();
    address_proof.append("address_proof", action.image);
    const res = yield call(profileImageUploadApi, address_proof);
    console.log(res);
    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res);
    } else {
      yield action.onError(res);
    }
  } catch (error) {
    console.log(error);
  }
}

export function* uploadAddressProofFrontImageWorker(action) {
  try {
    const address_proof = new FormData();
    address_proof.append("address_proof", action.image);
    const res = yield call(uploadAddressProofFrontImageApi, address_proof);
    console.log(res);
    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res);
    } else {
      yield action.onError(res);
    }
  } catch (error) {
    console.log(error);
  }
}

export function* getProfileIMageWorker(action) {
  try {
    const res = yield call(getProfileImagelApi, action.id);
    console.log(res);
    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res);
      console.log(res);
    } else if (res.status === 200 && res.data.status === "fail") {
      console.log(res);
      yield action.onError(res);
    } else {
      yield action.onError("Something Went Wrong!");
      console.log(res);
    }
  } catch (err) {
    console.log(err);
    yield action.onError("Something Went Wrong!!");
  }
}


export function* panImageUploadWorker(action) {
  try {
    const address_proof = new FormData();
    address_proof.append("address_proof", action.image);
    const res = yield call(panImageUploadApi, address_proof);
    console.log(res);
    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res);
    } else {
      yield action.onError(res);
    }
  } catch (error) {
    console.log(error);
  }
}

export function* uploadAddressProofBackImageeWorker(action) {
  try {
    const address_proof = new FormData();
    address_proof.append("address_proof", action.image);
    const res = yield call(uploadAddressProofBackImageApi, address_proof);
    console.log(res);
    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res);
    } else {
      yield action.onError(res);
    }
  } catch (error) {
    console.log(error);
  }
}
export function* bankImageUploadWorker(action) {
  try {
    const address_proof = new FormData();
    address_proof.append("address_proof", action.image);
    const res = yield call(bankImageUploadApi, address_proof);
    console.log(res);
    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res);
    } else {
      yield action.onError(res);
    }
  } catch (error) {
    console.log(error);
  }
}



/*****watcher******* */

export function* addUserWorkerWatcher() {
  yield takeLatest(ADD_USER, addUserWorker);
}

export function* EditUserWorkerWatcher() {
  yield takeLatest(EDIT_USER, editUserWorker);
}
export function* deleteUserWorkerWatcher() {
  yield takeLatest(DELETE_USER, deleteUserWorker);
}

export function* watchUserEmailOtpWorker() {
  yield takeLatest(USER_EMAIL_OTP, userEmailOtpWorker);
}
export function* watchUserPhoneOtpWorker() {
  yield takeLatest(USER_PHONE_OTP, userPhoneOtpWorker);
}
export function* watchResetPasswordEmailViewWorker() {
  yield takeLatest(RESET_PASSWORD_EMAIL_VIEW, ResetPasswordEmailViewWorker);
}
export function* UserListWatcher() {
  yield takeLatest(USER_LIST, UserListWorker);
}
export function* ApprovalUserListWatcher() {
  yield takeLatest(USER_APPROVAL_LIST, ApprovalUserListWorker);
}

export function* watchindividualUserDetailWatcher() {
  yield takeLatest(USER_VIEW_DETAILS, individualUserDetailWorker);
}

export function* UserVersionHistoryListWatcher() {
  yield takeLatest(USER_VERSION_HISTORY_LIST, userVersionHistoryListWorker);
}

export function* UserVersionHistoryViewWatcher() {
  yield takeLatest(USER_VERSION_HISTORY_VIEW, viewUserVersionHistoryWorker);
}
export function* UserApprovalViewViewWatcher() {
  yield takeLatest(
    INDIVIDUAL_USER_APPROVAL_DETAILS,
    viewUserApprovalVersionHistoryWorker
  );
}

export function* ApproveUserViewWatcher() {
  yield takeLatest(APPROVE_USER, approveUserWorker);
}

export function* watchprofileImageUploadWorker() {
  yield takeLatest(ADD_PROFILE_IMAGE, profileImageUploadWorker);
}
export function* watchuploadAddressProofFrontImageWorker() {
  yield takeLatest(
    UPLOAD_ADDRESS_PROOF_FRONT_IMAGE,
    uploadAddressProofFrontImageWorker
  );
}

export function* watchuploadAddressProofBackImageWorker() {
  yield takeLatest(
    UPLOAD_ADDRESS_PROOF_BACK_IMAGE,
    uploadAddressProofBackImageeWorker
  );
}

export function* watchgetProfileImageWorker() {
  yield takeLatest(GET_PROFILE_IMAGE, getProfileIMageWorker);
}

export function* watchpanImageUploadWorker() {
  yield takeLatest(ADD_PAN_IMAGE, panImageUploadWorker);
}

export function* watchbankImageUploadWorker() {
  yield takeLatest(ADD_BANK_IMAGE, bankImageUploadWorker);
}




export function* approvedAddressProfuser(action) {
  try {
    const res = yield call(approvedAddreddUserApi, { userId: action.userId });

    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res.data);
      console.log(res.data);
    } else if (res.status === 200 && res.data.status === "fail") {
      console.log(res.data);
      yield action.onError(res);
    } else {
      yield action.onError("Something Went Wrong!");
      console.log(res.data);
    }
  } catch (err) {
    console.log(err);
    yield action.onError("Something Went Wrong!!");
  }
}

export function* rejectedAddressProfuser(action) {
  try {
    const res = yield call(rejectedApproveAddreddUserApi, action.data);
    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res.data);
      console.log(res.data);
    } else if (res.status === 200 && res.data.status === "fail") {
      console.log(res.data);
      yield action.onError(res);
    } else {
      yield action.onError("Something Went Wrong!");
      console.log(res.data);
    }
  } catch (err) {
    console.log(err);
    yield action.onError("Something Went Wrong!!");
  }
}

export function* approvedPanProfuser(action) {
  try {
    const res = yield call(approvedPanUserApi, { id: action.id });

    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res.data);
      console.log(res.data);
    } else if (res.status === 200 && res.data.status === "fail") {
      console.log(res.data);
      yield action.onError(res);
    } else {
      yield action.onError("Something Went Wrong!");
      console.log(res.data);
    }
  } catch (err) {
    console.log(err);
    yield action.onError("Something Went Wrong!!");
  }
}

export function* rejectedPanProfuser(action) {
  try {
    const res = yield call(rejectedPanUserApi, {
      id: action.id,
      rejected_message: action.rejected_message,
    });

    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res.data);
      console.log(res.data);
    } else if (res.status === 200 && res.data.status === "fail") {
      console.log(res.data);
      yield action.onError(res);
    } else {
      yield action.onError("Something Went Wrong!");
      console.log(res.data);
    }
  } catch (err) {
    console.log(err);
    yield action.onError("Something Went Wrong!!");
  }
}

export function* approvedbankProfuser(action) {
  try {
    const res = yield call(approvedBankUserApi, { id: action.id });

    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res.data);
      console.log(res.data);
    } else if (res.status === 200 && res.data.status === "fail") {
      console.log(res.data);
      yield action.onError(res);
    } else {
      yield action.onError("Something Went Wrong!");
      console.log(res.data);
    }
  } catch (err) {
    console.log(err);
    yield action.onError("Something Went Wrong!!");
  }
}

export function* rejectedbankProfuser(action) {
  try {
    const res = yield call(rejectedBankUserApi, {
      id: action.id,
      rejected_message: action.rejected_message,
    });

    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res.data);
      console.log(res.data);
    } else if (res.status === 200 && res.data.status === "fail") {
      console.log(res.data);
      yield action.onError(res);
    } else {
      yield action.onError("Something Went Wrong!");
      console.log(res.data);
    }
  } catch (err) {
    console.log(err);
    yield action.onError("Something Went Wrong!!");
  }
}



export function* kycStatusUserWorker(action) {
  try {
    const res = yield call(kycStatusUserApi, action.data);
    console.log(res);
    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res.data);
      console.log(res.data);
    } else if (res.status === 200 && res.data.status === "fail") {
      console.log(res.data);
      yield action.onError(res);
    } else {
      yield action.onError("Something Went Wrong!");
      console.log(res.data);
    }
  } catch (err) {
    console.log(err);
    yield action.onError("Something Went Wrong!!");
  }
}
export function* rejectedApprovePanUserWorker(action) {
  try {
    const res = yield call(PanApproveRejectUserApi, action.data);

    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res.data);
      console.log(res.data);
    } else if (res.status === 200 && res.data.status === "fail") {
      console.log(res.data);
      yield action.onError(res);
    } else {
      yield action.onError("Something Went Wrong!");
      console.log(res.data);
    }
  } catch (err) {
    console.log(err);
    yield action.onError("Something Went Wrong!!");
  }
}
export function* rejectedApproveBankUserWorker(action) {
  try {
    const res = yield call(rejectedApproveBankUserApi, action.data);

    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res.data);
      console.log(res.data);
    } else if (res.status === 200 && res.data.status === "fail") {
      console.log(res.data);
      yield action.onError(res);
    } else {
      yield action.onError("Something Went Wrong!");
      console.log(res.data);
    }
  } catch (err) {
    console.log(err);
    yield action.onError("Something Went Wrong!!");
  }
}

//export
export function* userTableExportWorker(action) {
  try {
    const res = yield call(userTableExportApi,
    );
    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res.data);
      console.log(res.data);
    } else {
      yield action.onError(res.data);
      console.log(res);
    }
  } catch (error) { }
}

export function* userApprovalsExportWorker(action) {
  try {
    const res = yield call(userApprovalsExportApi);
    console.log(res);
    if (res.status === 200 && res.data.status === "success") {
      console.log(res.data);
      yield action.onSuccess(res.data.data);
    } else {
      yield action.onError(res.data);
    }
  } catch (error) { }
}

export function* userVersionExportWorker(action) {
  try {
    const res = yield call(userVersionExportApi);
    console.log(res);
    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res.data);
    }
  } catch (error) { }
}

export function* userResetExportWorker(action) {
  try {
    const res = yield call(userVersionExportApi);
    console.log(res);
    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res.data);
    }
  } catch (error) { }
}


export function* userEmailExportWorker(action) {
  try {
    const res = yield call(userVersionExportApi);
    console.log(res);
    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res.data);
    }
  } catch (error) { }
}


export function* userPhoneExportWorker(action) {
  try {
    const res = yield call(userVersionExportApi);
    console.log(res);
    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res.data);
    }
  } catch (error) { }
}
export function* forgotPasswordSendEmailWorker(action) {
  try {
    const res = yield call(forgotPasswordSendEmailApi, { email: action.email });
    console.log('====================================');
    console.log(res);
    console.log('====================================');
    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res.data);
      console.log(res);
    } else if (res.status === 200 && res.data.status === "fail") {
      console.log(res);
      yield action.onError(res.data);
    } else {
      yield action.onError("Something Went Wrong!");
      console.log(res.data);
    }
  } catch (err) {
    console.log(err);
    yield action.onError("Something Went Wrong!!");
  }
}


export function* userSubmitWorker(action) {
  try {
    const res = yield call(userSubmitApi, action.id);
    console.log(res);
    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res.data);
    }
  } catch (error) { }
}

export function* addessApprovedWorkerWatcher() {
  yield takeLatest(ADDRESS_APPROVED, approvedAddressProfuser);
}

export function* addessRejectedWorkerWatcher() {
  yield takeLatest(ADDRESS_REJECTED, rejectedAddressProfuser);
}

export function* panApprovedWorkerWatcher() {
  yield takeLatest(PAN_APPROVED, approvedPanProfuser);
}

export function* panRejectedWorkerWatcher() {
  yield takeLatest(PAN_REJECTED, rejectedPanProfuser);
}

export function* bankApprovedWorkerWatcher() {
  yield takeLatest(BANK_APPROVED, approvedbankProfuser);
}

export function* bankRejectedWorkerWatcher() {
  yield takeLatest(BANK_REJECTED, rejectedbankProfuser);
}
export function* RejectWorkerWatcher() {
  yield takeLatest(REJECT_USER, rejectUserWorker);
}


//KYC
export function* kycStatusUserWorkerWatcher() {
  yield takeLatest(KYC_STATUS_USER, kycStatusUserWorker);
}
export function* rejectedApprovePanUserWorkerWatcher() {
  yield takeLatest(PAN_APPROVE_REJECT, rejectedApprovePanUserWorker);
}
export function* rejectedApproveBankUserWorkerWatcher() {
  yield takeLatest(BANK_APPROVE_REJECT, rejectedApproveBankUserWorker);
}


//export
//export
export function* userTableExportWatcher() {
  yield takeLatest(
    USER_EXPORT, userTableExportWorker
  );
}
export function* userApprovalsExportWatcher() {
  yield takeLatest(USER_APPROVALS_EXPORT, userApprovalsExportWorker);
}

export function* userVersionExportWorkerWatcher() {
  yield takeLatest(USER_VERSION_EXPORT, userVersionExportWorker);
}



export function* userResetExportWatcher() {
  yield takeLatest(
    USER_RESET_EXPORT,
    userResetExportWorker
  );
}
export function* userEmailExportWatcher() {
  yield takeLatest(USER_EMAIL_EXPORT, userEmailExportWorker);
}

export function* userPhoneExportWorkerWatcher() {
  yield takeLatest(USER_PHONE_EXPORT, userPhoneExportWorker);
}

export function* watchForgotPasswordSendEmailWorker() {
  yield takeLatest(FORGOT_PASS_SEND_EMAIL, forgotPasswordSendEmailWorker);
}
export function* watchUserSubmitWorker() {
  yield takeLatest(USER_SUBMIT, userSubmitWorker);
}