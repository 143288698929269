import React, { useState } from "react";
import copiedIcon from "../../assets/icons/doubletick.svg";
import copyIcon from "../../assets/icons/copy-icon.svg";
import "./customstyles.css";
import usePermissionChecker from "./usePermissionChecker";

const ShareableLink = ({ link = "", title = "", conatinerClassName = "" }) => {
  const access = usePermissionChecker();
  const [copied, setCopied] = useState(false);

  const copyText = (e) => {
    e.preventDefault();
    navigator?.clipboard?.writeText(`https://upcide.com${link}`);
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 1000);
  };

  return (
    <>
      {" "}
      {access && (
        <div className={`${conatinerClassName}  shareablemodal_container`}>
          <div className={`w-100 d-flex flex-column`}>
            <label htmlFor={link}>{title}</label>
            <input
              type=""
              id={link}
              readOnly
              className={`shareablemodal_input mt-1`}
              value={`https://upcide.com${link}`}
            />
          </div>
          <div className={`d-flex justify-content-end w-100 mt-2`}>
            <button
              onClick={copyText}
              className={`d-flex align-items-center ${`shareablemodal_btn_copy`}`}
            >
              <span className={`d-block `}>
                <img
                  src={copied ? copiedIcon : copyIcon}
                  alt="copy link icon"
                  className="shareablemodal_icon_copy"
                />
              </span>{" "}
              <span
                className={
                  copied
                    ? `fontFam_montserrat fw_500 fs_10 ps-2 text-success lh-small`
                    : `fontFam_montserrat fw_500 fs_10 ps-2 lh-small `
                }
              >
                {copied ? "COPIED" : "COPY"}
              </span>
            </button>
          </div>{" "}
        </div>
      )}
    </>
  );
};

export default ShareableLink;
