import { call, takeLatest } from "redux-saga/effects";
import {
  MEDIA_REC_APPROVALS_LIST,
  MEDIA_REC_CREATE,
  MEDIA_REC_LIST,
  MEDIA_REC_READ,
  MEDIA_REC_DELETE,
  MEDIA_REC_VERSION_LIST,
  MEDIA_REC_VERSION_VIEW,
  MEDIA_REC_APPROVALS_VIEW,
  MEDIA_REC_APPROVE,
  MEDIA_REC_REJECT,
  MEDIA_REC_EDIT,
  MEDIA_REC_MAIN_EXPORT,
  MEDIA_REC_APPROVAL_EXPORT,
  MEDIA_REC_VERSION_EXPORT,
  MEDIA_REC_SUBMIT,
} from "../actions/types";

import * as mediaRecApis from "../apis/mediaRecogonitionApis";

export function* addMediaRecWorker(action) {
  try {
    const res = yield call(mediaRecApis.addMediaRecApi, action.data);
    console.log(action.data);
    console.log(res, "AddAdminRole");
    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res.data);
      console.log(res.data);
    } else if (res.status === 200 && res.data.status === "fail") {
      console.log(res.data);
      yield action.onError(res);
    } else {
      yield action.onError("Something Went Wrong");
    }
  } catch (err) {
    console.log(err);
    yield action.onError("Something Went Wrong");
  }
}

export function* viewMediaRecWorker(action) {
  try {
    const res = yield call(
      mediaRecApis.viewMediaRecApi,
      action.page,
      action.data
    );
    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res.data);
      console.log(res.data);
    } else if (res.status === 200 && res.data.status === "fail") {
      console.log(res.data);
      yield action.onError(res);
    } else {
      yield action.onError("Something Went Wrong!");
      console.log(res.data);
    }
  } catch (err) {
    console.log(err);
    yield action.onError("Something Went Wrong!!");
  }
}

export function* deleteMediaRecWorker(action) {
  try {
    const res = yield call(mediaRecApis.deleteMediaRecApi, action.id);
    // console.log(res, "AddAdminRole");
    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res.data);
      console.log(res.data);
    } else if (res.status === 200 && res.data.status === "fail") {
      console.log(res);
      yield action.onError(res);
    } else {
      yield action.onError("Something Went Wrong");
    }
  } catch (err) {
    console.log(err);
    yield action.onError("Something Went Wrong");
  }
}
export function* viewMediaRecListWorker(action) {
  try {
    const res = yield call(mediaRecApis.viewMediaRecListApi, action.id);
    // console.log(res, "AddAdminRole");
    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res.data);
      console.log(res);
    } else if (res.status === 200 && res.data.status === "fail") {
      console.log(res);
      yield action.onError(res.data);
    } else {
      yield action.onError("Something Went Wrong");
    }
  } catch (err) {
    console.log(err);
    yield action.onError("Something Went Wrong");
  }
}

export function* viewVersionHistoryAllMediaWorker(action) {
  try {
    const res = yield call(
      mediaRecApis.viewVersionHistoryAllMediaRecApi,
      action.pageNo,
      action.data
    );
    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res.data);
      console.log(res.data);
    } else if (res.status === 200 && res.data.status === "fail") {
      console.log(res);
      yield action.onError(res);
    } else {
      yield action.onError("Something Went Wrong!");
      console.log(res.data);
    }
  } catch (err) {
    console.log(err);
    yield action.onError("Something Went Wrong!!");
  }
}

export function* viewVersionHistoryByIdMediaWorker(action) {
  try {
    const res = yield call(mediaRecApis.versionHistoryByIdApi, action.id);
    // console.log(res, "AddAdminRole");
    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res.data);
      console.log(res);
    } else if (res.status === 200 && res.data.status === "fail") {
      console.log(res);
      yield action.onError(res.data);
    } else {
      yield action.onError("Something Went Wrong");
    }
  } catch (err) {
    console.log(err);
    yield action.onError("Something Went Wrong");
  }
}

export function* viewApprovalsTableMediaWorker(action) {
  try {
    const res = yield call(
      mediaRecApis.approvalsMediaRecListpApi,
      action.page,
      action.data
    );
    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res.data);
      console.log(res.data);
    } else if (res.status === 200 && res.data.status === "fail") {
      console.log(res);
      yield action.onError(res);
    } else {
      yield action.onError("Something Went Wrong!");
      console.log(res.data);
    }
  } catch (err) {
    console.log(err);
    yield action.onError("Something Went Wrong!!");
  }
}

export function* viewApprovalsByIdMediaWorker(action) {
  try {
    const res = yield call(mediaRecApis.viewApprovalMediaRecApi, action.id);
    // console.log(res, "AddAdminRole");
    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res.data);
      console.log(res);
    } else if (res.status === 200 && res.data.status === "fail") {
      console.log(res);
      yield action.onError(res.data);
    } else {
      yield action.onError("Something Went Wrong");
    }
  } catch (err) {
    console.log(err);
    yield action.onError("Something Went Wrong");
  }
}

export function* viewApproveMediaWorker(action) {
  try {
    const res = yield call(mediaRecApis.approveMediaRecApi, {
      user_Id: action.id,
    });
    // console.log(res, "AddAdminRole");
    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res.data);
      console.log(res);
    } else if (res.status === 200 && res.data.status === "fail") {
      console.log(res);
      yield action.onError(res.data);
    } else {
      yield action.onError("Something Went Wrong");
    }
  } catch (err) {
    console.log(err);
    yield action.onError("Something Went Wrong");
  }
}

export function* viewRejectMediaWorker(action) {
  try {
    const res = yield call(mediaRecApis.rejectMediaRecApi, action.id);
    // console.log(res, "AddAdminRole");
    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res.data);
      console.log(res);
    } else if (res.status === 200 && res.data.status === "fail") {
      console.log(res);
      yield action.onError(res.data);
    } else {
      yield action.onError("Something Went Wrong");
    }
  } catch (err) {
    console.log(err);
    yield action.onError("Something Went Wrong");
  }
}

export function* viewEditMediaWorker(action) {
  try {
    const res = yield call(
      mediaRecApis.editMediaRecApi,
      action.id,
      action.data
    );
    console.log(action.data);
    console.log(res, "AddAdminRole");
    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res.data);
      console.log(res.data);
    } else if (res.status === 200 && res.data.status === "fail") {
      console.log(res.data);
      yield action.onError(res);
    } else {
      yield action.onError("Something Went Wrong");
    }
  } catch (err) {
    console.log(err);
    yield action.onError("Something Went Wrong");
  }
}

export function* mediaRecMainExportWorker(action) {
  try {
    const res = yield call(mediaRecApis.mediaRecMainExportApi);
    console.log(res);
    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res.data);
    }
  } catch (error) {}
}

export function* mediaRecApprovalsExportWorker(action) {
  try {
    const res = yield call(mediaRecApis.mediaRecApprovalsExportApi);
    console.log(res);
    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res.data);
    }
  } catch (error) {}
}

export function* mediaRecVersionExportWorker(action) {
  try {
    const res = yield call(mediaRecApis.mediaRecVersionExportApi);
    console.log(res);
    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res.data);
    }
  } catch (error) {}
}


export function* mediaRecSubmitWorker(action) {
  try {
      const res = yield call(mediaRecApis.mediaRecSubmitApi, action.id);
      console.log(res);
      if (res.status === 200 && res.data.status === "success") {
          yield action.onSuccess(res.data);
      } else {
          yield action.onError(res.data);
      }
  } catch (error) { }
}





export function* mediaRecSubmitWorkerWatcher() {
  yield takeLatest(MEDIA_REC_SUBMIT, mediaRecSubmitWorker);
}

/**********************************/
/************WATHCHERS**************/
/**********************************/

export function* watchAddMediaRecWorker() {
  yield takeLatest(MEDIA_REC_CREATE, addMediaRecWorker);
}
export function* watchViewMediaRecWorker() {
  yield takeLatest(MEDIA_REC_LIST, viewMediaRecWorker);
}
export function* watchDeleteMediaRecWorker() {
  yield takeLatest(MEDIA_REC_DELETE, deleteMediaRecWorker);
}
export function* watchViewMediaRecListWorker() {
  yield takeLatest(MEDIA_REC_READ, viewMediaRecListWorker);
}
export function* watchMediaQueryVersionHistoryWorker() {
  yield takeLatest(MEDIA_REC_VERSION_LIST, viewVersionHistoryAllMediaWorker);
}

export function* watchMediaVersionHistoryByIdWorker() {
  yield takeLatest(MEDIA_REC_VERSION_VIEW, viewVersionHistoryByIdMediaWorker);
}

export function* watchMediaApprovalsTableWorker() {
  yield takeLatest(MEDIA_REC_APPROVALS_LIST, viewApprovalsTableMediaWorker);
}

export function* watchMediaApprovalsByIdWorker() {
  yield takeLatest(MEDIA_REC_APPROVALS_VIEW, viewApprovalsByIdMediaWorker);
}
export function* watchMediaApprovedWorker() {
  yield takeLatest(MEDIA_REC_APPROVE, viewApproveMediaWorker);
}

export function* watchMediaRejectWorker() {
  yield takeLatest(MEDIA_REC_REJECT, viewRejectMediaWorker);
}
export function* watchMediaEditWorker() {
  yield takeLatest(MEDIA_REC_EDIT, viewEditMediaWorker);
}

export function* watchMediaRecMainExportWorker() {
  yield takeLatest(MEDIA_REC_MAIN_EXPORT, mediaRecMainExportWorker);
}
export function* watchMediaRecApprovalsExportWorker() {
  yield takeLatest(MEDIA_REC_APPROVAL_EXPORT, mediaRecApprovalsExportWorker);
}
export function* watchMediaRecVersionExportWorker() {
  yield takeLatest(MEDIA_REC_VERSION_EXPORT, mediaRecVersionExportWorker);
}
