import {
  ADD_ADMIN,
  ADMIN_APPROVALS_EXPORT,
  ADMIN_APPROVAL_DETAILS,
  ADMIN_APPROVAL_LIST,
  ADMIN_DETAILS_VERSION_HISTORY,
  ADMIN_EXPORT,
  ADMIN_SUBMIT,
  ADMIN_VERSION_EXPORT,
  APPROVE_ADMIN,
  DELETE_ADMIN,
  EDIT_ADMIN,
  EDIT_ADMIN_CHECKER,
  EXPORT_ADMIN,
  REJECT_ADMIN,
  ROLE_TYPE_DATA,
  VERSION_HISTORY_LIST,
  VIEW_ADMIN,
  VIEW_ADMIN_LIST,
} from "./types";

export const addAdmin = (
  data,
  onSuccess,
  onError
) => {
  return {
    type: ADD_ADMIN,
    data,
    onSuccess,
    onError,
  };
};

export const approveAdmin = (userID, onSuccess, onError) => {
  return {
    type: APPROVE_ADMIN,
    userID,
    onSuccess,
    onError,
  };
};

export const rejectAdmin = (userID, onSuccess, onError) => {
  return {
    type: REJECT_ADMIN,
    userID,
    onSuccess,
    onError,
  };
};

export const viewAdminDetail = (userID, onSuccess, onError) => {
  return {
    type: VIEW_ADMIN,
    userID,
    onSuccess,
    onError,
  };
};

export const viewAdminList = (data, page_no, onSuccess, onError) => {
  return {
    type: VIEW_ADMIN_LIST,
    data,
    page_no,
    onSuccess,
    onError,
  };
};


export const editAdmin = (
  id,
  data,
  onSuccess,
  onError
) => {
  return {
    type: EDIT_ADMIN,
    id,
    data,
    onSuccess,
    onError,
  };
};

export const deleteAdmin = (userID, onSuccess, onError) => {
  return {
    type: DELETE_ADMIN,
    userID,
    onSuccess,
    onError,
  };
};

export const editAdminChecker = (checkerId, adminId, onSuccess, onError) => {
  return {
    type: EDIT_ADMIN_CHECKER,
    checkerId,
    adminId,
    onSuccess,
    onError,
  };
};

export const adminApprovalList = (data, page_no, onSuccess, onError) => {
  return {
    type: ADMIN_APPROVAL_LIST,
    data,
    page_no,
    onSuccess,
    onError,
  };
};

export const viewApprovalById = (userID, onSuccess, onError) => {
  return {
    type: ADMIN_APPROVAL_DETAILS,
    userID,
    onSuccess,
    onError,
  };
};

export const roleTypeData = (onSuccess, onError) => {
  return {
    type: ROLE_TYPE_DATA,
    onSuccess,
    onError,
  };
};

export const versionHistoryAdminList = (data, page_no, onSuccess, onError) => {
  return {
    type: VERSION_HISTORY_LIST,
    data,
    page_no,
    onSuccess,
    onError,
  };
};

export const versionHistoryAdminDetail = (id, onSuccess, onError) => {
  return {
    type: ADMIN_DETAILS_VERSION_HISTORY,
    id,
    onSuccess,
    onError,
  };
};
export const exportAdmin = (onSuccess, onError) => {
  return {
    type: EXPORT_ADMIN,
    onSuccess,
    onError,
  };
};


export const adminTableExport = (onSuccess, onError) => {
  return {
    type: ADMIN_EXPORT,
    onSuccess,
    onError,
  };
};
export const adminApprovalsExport = (onSuccess, onError) => {
  return {
    type: ADMIN_APPROVALS_EXPORT,

    onSuccess,
    onError,
  };
};
export const adminVersionExport = (onSuccess, onError) => {
  return {
    type: ADMIN_VERSION_EXPORT,

    onSuccess,
    onError,
  };
};
export const adminSubmitAction = (id, onSuccess, onError) => {
  return {
    type: ADMIN_SUBMIT,
    id,
    onSuccess,
    onError,
  };
};