import React, { useRef, useState, useEffect, useInsertionEffect, useLayoutEffect } from "react";
import DeleteBtn from "../../../../components/buttons/deleteBtn";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { useParams, useNavigate } from "react-router-dom";
import InnerContainer from "../../../../components/layouts/innerContainer/innerContainer";
import InnerLayout from "../../../../components/layouts/innerLayout/innerLayout";
import "../wishlist.css";
import SelectFields from "../../../../components/form/selectFields/selectFields";
import SaveBtn from "../../../../components/buttons/saveBtn";
import { useDispatch } from 'react-redux';
import successToast from "../../../../components/utilits/successToast";
import errorToast from "../../../../components/utilits/errorToast";
import { deleteWishlist, editWishlist, viewWishlist, viewWishListFindAll, wishlistSubmitAction } from "../../../../redux/actions/wishlistActions";
import { opportunitiesNameListAction } from "../../../../redux/actions/opportunitiesActions";
import LoadingSpinnerTable from "../../../../components/utilits/loadingSpinnerTable";
import LoadAndError from "../../../../components/utilits/LoadAndError";
import SubmitBtn from "../../../../components/buttons/submit";

const WishlistEdit = () => {
    const params = useParams();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false)
    const [deleteData, setDeleteData] = useState();
    const [wishlistData, setWishlistData] = useState([]);
    const [allUsers, setAllUsers] = useState([]); //For dropdown of user name
    const [allOpportunities, setOpportunities] = useState([]); //For dropdown of user name
    const [dropDown, setDropDown] = useState([]);
    const dispatch = useDispatch();
    const [saved, setSaved] = useState(false);
    const [subLoading, setSubLoading] = useState(false);
    ;
    useEffect((id) => {
        setLoading(true)
        dispatch(viewWishlist(params?.id, onSuccess, onError));
        dispatch(viewWishListFindAll(onSuccessDrop, onErrorDrop));

    }, [])

    // For view wishlist by id
    const onSuccess = (data) => {
        console.log(data.data);
        setLoading(false);

        setWishlistData(data.data)
        setError(false)
    };
    const onError = (data) => {
        setLoading(false)
        errorToast(data.message)
        setError(true)
    };

    //For dropdown fetch
    const onSuccessDrop = (data) => {
        const roleTypes = [];
        console.log(data.data);
        data?.data.AllUsers.map((el, index) => {
            roleTypes[index] = { value: el.id, label: el.user_name };
        });
        setAllUsers(roleTypes);
    };

    const onErrorDrop = (data) => {
        console.log("ERROR");
    };

    //For deletion
    const onDeleteSuccess = (data) => {
        console.log(data.data);
        setLoading(false);
        navigate("/wishlist")
        setError(false)
    };
    const onDeleteError = (data) => {
        setLoading(false)
        errorToast(data.data.message)
        setError(true)
    };

    const formRef = useRef();



    const initialValues = {
        userName: wishlistData?.user_id?wishlistData?.user_id:"",
        opportunity: wishlistData?.opportunity_id?wishlistData?.opportunity_id:"",
    };


    const loginSchema = Yup.object().shape({
        userName: Yup.string().trim().required("Required"),
        opportunity: Yup.string().trim().required("Required"),
    });
    const handleShow = () => {
        dispatch(
            deleteWishlist(wishlistData.id, onDeleteSuccess, onDeleteError)
        );
    };
    const handleSubmit = () => {
        if (formRef.current) {
            formRef.current.handleSubmit()
        }
        // console.log("The user name selected", initialValues.userName)
    }
    const onSubmit = (values, { resetForm }) => {
        setLoading(true)
        console.log(values);
        dispatch(
            editWishlist(
                params?.id,
                {
                    user_id: values.userName,
                    opportunity_id: values.opportunity
                },
                onSuccessEdit,
                onErrorEdit)
        );
    };
    console.log(wishlistData.id);
    const onSuccessEdit = (data) => {
        console.log(data.data);
        setLoading(false);
        successToast(data.message)
        setSaved(true)
        setError(false)
    };
    const onErrorEdit = (data) => {
        setLoading(false)
        errorToast(data.data.message)
        setError(true)
        // errorToast(data.data.message)
    };

    //oppp id
    const [opportunitiesList, setOpportunitiesList] = useState([])
    useEffect(() => {
        dispatch(opportunitiesNameListAction(onSuccessOpt, onErrorOpt));
    }, []);
    const onSuccessOpt = (data) => {
        const categoryList = [];
        data?.data.map((item, index) => {
            categoryList[index] = { value: item.id, label: `${item.title} id: ${item.id}` };
        });
        setOpportunitiesList(categoryList);
        console.log("SUCCESS");
    };
    const onErrorOpt = (data) => {
        console.log(data);
    };
    const wishlistSubmit = () => {
        setSubLoading(true);
        dispatch(
            wishlistSubmitAction(params.id, onAdminSubmitSucess, onAdminSubmitError)
        );
    };
    const buttonList = [
        <SaveBtn submitFn={handleSubmit} />,
        <SubmitBtn saveHide={saved} submitFn={wishlistSubmit} />,
    ];
    function onAdminSubmitSucess(data) {
        successToast(data.message);
        setSubLoading(false);
        navigate("/opportunity/wishlist")
    }
    function onAdminSubmitError(data) {
        setSubLoading(false);
        errorToast(data.message);
    }
    return (
        <InnerLayout buttons={buttonList}>
            <div className="wishlist-container">
                <InnerContainer>
                    {loading ? (
                        <LoadingSpinnerTable />
                    ) : (
                        <LoadAndError loader={loading} error={error}>
                            <div className="pt-3  ps-4 ps-lg-4 ms-3">
                                <h5 className="dark_grey_color fs_13 fn_Montserrat fw_500 m-0">
                                    Wishlist ID
                                </h5>
                                <p
                                    className={` fs_13 fn_Nunito fw_500 dark_title_color  `}>
                                    {wishlistData.id}
                                </p>
                            </div>

                            <div className="  ps-4 ps-lg-4 ms-3">
                                <Formik
                                    enableReinitialize={true}
                                    innerRef={formRef}
                                    initialValues={initialValues}
                                    onSubmit={onSubmit}
                                    validationSchema={loginSchema}
                                >
                                    {({ values }) => (
                                        <Form className='wishlist-form-width'>
                                            <SelectFields
                                                values={values}
                                                default1={{ label: wishlistData?.user?.user_name, value: wishlistData?.user_id }}
                                                label={"User Name"}
                                                placeholder={"User name here"}
                                                name={"userName"}
                                                options={allUsers}
                                                id={"userName"}

                                            />
                                            <SelectFields
                                                default1={{ label: wishlistData?.opportunity?.title, value: wishlistData?.opportunity_id }}
                                                label={"Opportunity"}
                                                placeholder={"Opportunity here"}
                                                name={"opportunity"}
                                                options={opportunitiesList}
                                                id={"Opportunity"}
                                            />
                                        </Form>
                                    )}
                                </Formik>
                            </div>
                        </LoadAndError>
                    )}
                </InnerContainer>
            </div>
        </InnerLayout>
    );
};

export default WishlistEdit;




