import React, { useEffect, useRef, useState } from "react";
import { Formik, Form, Field, FastField } from "formik";
import * as Yup from "yup";
import SelectFields from "../../../../components/form/selectFields/selectFields";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import successToast from "../../../../components/utilits/successToast";
import errorToast from "../../../../components/utilits/errorToast";
import { uploadAddressProofFrontImageAction } from "../../../../redux/actions/userAction";
import InnerLayout from "../../../../components/layouts/innerLayout/innerLayout";
import InnerContainer from "../../../../components/layouts/innerContainer/innerContainer";
import LoadAndError from "../../../../components/utilits/LoadAndError";
import SaveBtn from "../../../../components/buttons/saveBtn";
import SubmitBtn from "../../../../components/buttons/submit";
import InputField from "../../../../components/form/inputFields/inputField";
import {
  editUserReferralAction,
  fetchOpportunitiesID,
  UserReferralCategorySubmit,
  viewUserReferralAction,
} from "../../../../redux/actions/userReferralActions";

const UserReferralEdit = () => {
  const formRef = useRef();
  const navigate = useNavigate();
  const params = useParams();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [submitData, setSubmitData] = useState();
  const [opportinitiesDetails, setOpportinitiesDetails] = useState(null);
  const [opportunitiesId, setOpportunitiesId] = useState([]);
  const [error, setError] = useState(false);
  const [userReferralData, setUserReferralData] = useState({});
  const [deskImageLink, setDeskImageLink] = useState({
    img: "",
    error: false,
  });
  useEffect(() => {
    setLoading(true);
    dispatch(viewUserReferralAction(params.id, onSuccess, onError));
  }, []);
  useEffect(() => {
    dispatch(fetchOpportunitiesID(params.id, onDropSuccess, onDropError));
  }, []);
  const onDropSuccess = (data) => {
    console.log(data);
    const oppId = [];
    data?.data.map((el, index) => {
      oppId[index] = { value: el.id, label: el.name };
    });
    setOpportunitiesId(oppId);
  };

  const onDropError = (msg) => {
    console.log(msg);
  };
  const onSuccess = (data) => {
    console.log(data.data);
    setUserReferralData(data.data);
    console.log("SUCCESS");
    setDeskImageLink({ img: data.data.video_tumbnail_image, error: false });
    setLoading(false);
    setError(false);
  };
  const onError = (data) => {
    console.log("ERROR");
    setLoading(false);
    setError(true);
  };

  const initialValues = {
    userID: userReferralData?.user_id ? userReferralData?.user_id : "",
    opportunityID: userReferralData?.opportunity_id
      ? userReferralData?.opportunity_id
      : "",
  };

  const interestEditSchema = Yup.object().shape({
    userID: Yup.string().trim().required("Required"),
    opportunityID: Yup.string().trim().required("Required"),
  });
  const handleSubmit = () => {
    if (!deskImageLink.img) {
      setDeskImageLink({ img: "", error: true });
    }
    if (formRef.current) {
      formRef.current.handleSubmit();
    }
  };
  const onSubmit = (values, { resetForm }) => {
    if (deskImageLink.img) {
      dispatch(
        editUserReferralAction(
          params.id,
          {
            user_id: values.userID,
            opportunity_id: values.opportunityID,
          },
          onEditSuccess,
          onEditError
        )
      );
    }
  };
  const onEditSuccess = (data) => {
    console.log(data);
    // setNotifyMeData(data.data)
    successToast(data.message);
    setSubmitData(data.data.id);
    setSaveHide(true);
    // navigate("/static-pages/how-It-Works")
    setLoading(false);
    setError(false);
  };
  const onEditError = (data) => {
    console.log(data);
    errorToast(data.message);
    setLoading(false);
    setError(true);
  };

  const imageUpload = (e) => {
    if (e.target.name === "deskImage") {
      dispatch(
        uploadAddressProofFrontImageAction(
          e.target.files[0],
          onDeskImageUploadSucess,
          onDeskImageUploadError
        )
      );
    }
    e.target.value = null;
  };
  const onDeskImageUploadSucess = (data) => {
    setDeskImageLink({ img: data.data.data.location, error: false });
  };
  const onDeskImageUploadError = (data) => {
    setDeskImageLink({ img: "", error: true });
  };

  //on submit api
  console.log(submitData);
  const [saveHide, setSaveHide] = useState(false);

  const submitFnc = () => {
    dispatch(
      UserReferralCategorySubmit(submitData, onSubmitSuccess, onSubmitError)
    );
  };
  const onSubmitSuccess = (data) => {
    console.log(data);
    navigate("/static-pages/userReferral");
    successToast(data.message);
  };
  const onSubmitError = (data) => {
    console.log("ERROR==================", data);
    errorToast(data.message);
  };

  const buttonList = [
    !saveHide && <SaveBtn submitFn={handleSubmit} loader={loading} />,
    <SubmitBtn submitFn={submitFnc} saveHide={saveHide} />,
  ];
  return (
    <InnerLayout buttons={buttonList}>
      <div className="user_view_container">
        <InnerContainer>
          <LoadAndError loader={loading} error={error}>
            <Formik
              innerRef={formRef}
              initialValues={initialValues}
              onSubmit={onSubmit}
              enableReinitialize={true}
              validationSchema={interestEditSchema}
            >
              {({ formik, handleChange, values, setFieldValue }) => {
                return (
                  <Form className="px-4 py-3 col-12  col-xxl-10">
                    <div className="d-flex flex-column gap-0 gap-lg-5 flex-lg-row fs_14">
                      <div className="col-12 col-lg-6 pe-0 pe-lg-5">
                        <InputField
                          id={"userID"}
                          label={"User ID"}
                          type={"text"}
                          placeholder={"Enter user ID"}
                          name={"userID"}
                        />
                        <SelectFields
                          label={"Opportunity ID"}
                          placeholder={"Select Opportunity ID"}
                          name={"opportunityID"}
                          options={opportunitiesId}
                          default1={
                            opportinitiesDetails?.opportunity_type_id &&
                            opportinitiesDetails?.opportunity_type?.name
                              ? {
                                  value:
                                    opportinitiesDetails?.opportunity_type_id,
                                  label:
                                    opportinitiesDetails?.opportunity_type
                                      ?.name,
                                }
                              : ""
                          }
                          id={"opportunityID"}
                        />
                      </div>
                    </div>
                  </Form>
                );
              }}
            </Formik>
          </LoadAndError>
        </InnerContainer>
      </div>
    </InnerLayout>
  );
};

export default UserReferralEdit;
export const faqName = [
  { value: "Type1", label: "Type1" },
  { value: "Type2", label: "Type2" },
  { value: "Type3", label: "Type3" },
  { value: "Type4", label: "Type4" },
];
