import { authHeader } from "../config";
import { api } from "./api";

export const addInsightsApi = (params) => {
  return api.post("/api/admin/insights/create-insights", params, {
    headers: {
      "x-access-token": localStorage.getItem("accessToken"),
      "Content-Type": "application/json",
    },
  });
};
export const viewInsightsListApi = (page = 0, params) => {
  return api.post(
    `/api/admin/insights/view-insights?page_no=${page}`,
    params,
    {
      headers: {
        "x-access-token": localStorage.getItem("accessToken"),
        "Content-Type": "application/json",
      },
    }
  );
};
export const viewInsightsApi = (id, params) => {
  return api.post(`/api/admin/insights/view-insights/${id}`, params, {
    headers: {
      "x-access-token": localStorage.getItem("accessToken"),
      "Content-Type": "application/json",
    },
  });
};
export const editInsightsApi = (id, params) => {
  return api.post(`/api/admin/insights/edit/${id}`, params, {
    headers: {
      "x-access-token": localStorage.getItem("accessToken"),
      "Content-Type": "application/json",
    },
  });
};
export const deleteInsightsApi = (id, params) => {
  return api.post(`/api/admin/insights/delete/${id}`, params, {
    headers: {
      "x-access-token": localStorage.getItem("accessToken"),
      "Content-Type": "application/json",
    },
  });
};
export const approveInsightsApi = (params) => {
  return api.post(`/api/admin/insights/approve`, params, {
    headers: {
      "x-access-token": localStorage.getItem("accessToken"),
      "Content-Type": "application/json",
    },
  });
};
export const rejectInsightsApi = (id, params) => {
  return api.post(`/api/admin/insights/reject/${id}`, params, {
    headers: {
      "x-access-token": localStorage.getItem("accessToken"),
      "Content-Type": "application/json",
    },
  });
};
export const approvalsInsightsListpApi = (page = 0, params) => {
  return api.post(`/api/admin/insights/view-checker?page_no=${page}`, params, {
    headers: {
      "x-access-token": localStorage.getItem("accessToken"),
      "Content-Type": "application/json",
    },
  });
};
export const viewApprovalInsightsApi = (id, params) => {
  return api.post(`/api/admin/insights/view-checker/${id}`, params, {
    headers: {
      "x-access-token": localStorage.getItem("accessToken"),
      "Content-Type": "application/json",
    },
  });
};
export const versionInsightsListpApi = (page = 0, params) => {
  return api.post(`/api/admin/insights/view-version?page_no=${page}`, params, {
    headers: {
      "x-access-token": localStorage.getItem("accessToken"),
      "Content-Type": "application/json",
    },
  });
};
export const viewVersionInsightsApi = (id, params) => {
  return api.post(`/api/admin/insights/view-version/${id}`, params, {
    headers: {
      "x-access-token": localStorage.getItem("accessToken"),
      "Content-Type": "application/json",
    },
  });
};
export const activateInsightsApi = (id, params) => {
  return api.post(`/api/admin/insights/is-active-update/${id}`, params, {
    headers: {
      "x-access-token": localStorage.getItem("accessToken"),
      "Content-Type": "application/json",
    },
  });
};
export const InsightsSubmitApi = (id, params) => {
  return api.post(`/api/admin/insights/on-submit/${id}`, params, {
    headers: {
      "x-access-token": localStorage.getItem("accessToken"),
      "Content-Type": "application/json",
    },
  });
};

export const InsightsMainExportApi = (params) => {
  return api.post(
    `/api/admin/insights/export-main-insights`,
    JSON.stringify(params),
    {
      headers: {
        "x-access-token": localStorage.getItem("accessToken"),
        "Content-Type": "application/json",
      },
    }
  );
};

export const InsightsApprovalsExportApi = (page, params) => {
  return api.post(
    `/api/admin/insights/export-approval-insights`,
    JSON.stringify(params),
    {
      headers: {
        "x-access-token": localStorage.getItem("accessToken"),
        "Content-Type": "application/json",
      },
    }
  );
};

export const InsightsVersionExportApi = (page, params) => {
  return api.post(
    `/api/admin/insights/export-version-insights`,
    JSON.stringify(params),
    {
      headers: {
        "x-access-token": localStorage.getItem("accessToken"),
        "Content-Type": "application/json",
      },
    }
  );
};
